export const AUTHENTICATION_CONSTANTS = {
  minimum_character_required: {
    key: "AUTHENTICATION-SETTINGS-MINIMUM-CHARACTER-REQUIRED-KEY",
    label: "Minimum character required",
    switch_mapping_key: "minimum_character_required",
    dialer_present: true,
    default_value: true,
    default_dialer_value: 8,
    dialer_label: "Number of characters",
    dialer_mapping_key: "count_minimum_character",
  },
  password_not_same_as_last_password: {
    key: "AUTHENTICATION-SETTINGS-PASSWORD-NOT-SAME-AS-LAST-PASSWORD-KEY",
    label: "Cannot be same as last password",
    switch_mapping_key: "password_not_same_as_last_password",
    dialer_present: true,
    default_value: false,
    dialer_label: "Number of password",
    dialer_mapping_key: "count_password_not_same_as_last_password",
  },
  mixed_case_letters: {
    key: "AUTHENTICATION-SETTINGS-MIXED-CASE-LETTERS-KEY",
    label: "Need mixed case letters",
    switch_mapping_key: "mixed_case_letters",
    dialer_present: false,
    default_value: true,
    dialer_label: "",
    dialer_mapping_key: "",
  },
  special_charecter: {
    key: "AUTHENTICATION-SETTINGS-NEED-SPECIAL-CHARACTER-KEY",
    label: "Need special character",
    switch_mapping_key: "special_charecter",
    dialer_present: false,
    default_value: true,
    dialer_label: "",
    dialer_mapping_key: "",
  },
  first_letter_alphabet: {
    key: "AUTHENTICATION-SETTINGS-FIRST-LETTER-ALPHABET-KEY",
    label: "First letter should be an alphabet",
    switch_mapping_key: "first_letter_alphabet",
    dialer_present: false,
    default_value: false,
    dialer_label: "",
    dialer_mapping_key: "",
  },
  one_alphaber_one_number: {
    key: "AUTHENTICATION-SETTINGS-ONE-ALPHABET-ONE-NUMBER-KEY",
    label: "Should have a minimum of one alphabet and one number",
    switch_mapping_key: "one_alphaber_one_number",
    dialer_present: false,
    default_value: true,
    dialer_label: "",
    dialer_mapping_key: "",
  },
  password_expires: {
    key: "AUTHENTICATION-SETTINGS-PASSWORD-EXPIRY-KEY",
    label: "Password expires",
    switch_mapping_key: "password_expires",
    dialer_present: true,
    default_value: false,
    dialer_label: "Validity ( in days )",
    dialer_mapping_key: "password_expiry_days",
  },
};
