import {
  CreateContractInput,
  GetContractInput,
  GetServiceEntriesInput,
  ReassignContractInput,
  UpdateContractInput,
} from "shared";
import { axiosClient } from "utilz";
import { generateUrlString, getQueryDataObject } from "utilz/helpers";

export const getAllCurrency = async () => {
  try {
    const url = "/v1/currency";
    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getAllContracts = async ({
  perPageCount,
  pageNumber,
  sortColumn = "created_at",
  sortDirection = "desc",
  searchQuery,
  customerId,
  isActive,
  contractType,
}: GetContractInput) => {
  try {
    const url = "/v1/contracts";
    const params = {
      searchQuery,
      sortDirection,
      sortColumn,
      page: pageNumber,
      per_page: perPageCount,
      contract_type: contractType ? contractType : null,
      customers: JSON.stringify(customerId ? customerId : []),
      is_active: isActive,
    };

    const response = await axiosClient.get(`${url}`, {
      params,
    });
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getContractById = async (id: string) => {
  try {
    const url = `/v1/contracts/${id}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const createContract = async (data: CreateContractInput) => {
  try {
    const url = "/v1/contracts";
    const response = await axiosClient.post(`${url}`, data);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    } else {
      throw resData?.message;
    }
  } catch (error) {
    throw error;
  }
};

export const updateContract = async ({
  contractId,
  data,
}: UpdateContractInput) => {
  try {
    const url = `/v1/contracts/${contractId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    } else {
      throw resData?.message;
    }
  } catch (error) {
    throw error;
  }
};

export const getBillingContractDropDownData = async (
  customerId: string,
  serviceType: string
) => {
  try {
    const url = await generateUrlString({
      baseUrl: "/v1/contract/dropdown",
      data: {
        customer_id: customerId,
        service_type: serviceType,
      },
    });
    // const url = "/v1/contract/dropdown";
    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getBilligDefaultContract = async (
  customerId: string,
  serviceType: string
) => {
  try {
    const url = await generateUrlString({
      baseUrl: "/v1/default/contracts",
      data: {
        customer_id: customerId,
        service_type: serviceType,
      },
    });
    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    }
    throw resData?.message;
  } catch (error) {
    throw error;
  }
};

export const updateDefaultContract = async (
  serviceId: string,
  serviceType: string,
  contractId: string
) => {
  try {
    const data = {
      contract_id: contractId,
    };
    const url = `/contract/${serviceType}/${serviceId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getServicesByContractId = async (contractId: string) => {
  try {
    const url = `/v1/billing/services?contract_id=${contractId}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getServiceEntries = async ({
  projectIds,
  ticketIds,
}: GetServiceEntriesInput) => {
  try {
    const data = {
      project_ids: projectIds,
      ticket_ids: ticketIds,
    };
    const url = "/v1/billing/services/entries";
    const response = await axiosClient.post(`${url}`, data);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const reassignContract = async ({
  contractId,
  reassignToId,
}: ReassignContractInput) => {
  try {
    const data = {
      contract_id: reassignToId,
    };
    const url = `/contract/inactive/re-assign/${contractId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteContract = async (contractId: string) => {
  try {
    const url = `/v1/contracts/${contractId}`;
    const response = await axiosClient.delete(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
