import { Box, Stack, Typography, useTheme } from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import { Avatar } from "components";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ChatNotificationSchema } from "shared/types";
import { isoToDateAndTime } from "utilz/helpers";
import { stripHtml } from "utilz/helpers/stripHtml";

export const MessageListCard = ({
  chatData,
  onClick,
  isSelected,
  onClose,
}: {
  isSelected: boolean;
  chatData: ChatNotificationSchema;
  onClick: () => void;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const onFullScreenButtonClick = () => {
    switch (chatData.service_type) {
      case "ticket":
        navigate(`/app/service-desk/tickets/${chatData.service_id}`);
        onClose();
        break;
      case "task":
        navigate(
          `/app/service-desk/projects/${chatData.project_id}/task/${chatData.service_id}`
        );
        onClose();
        break;
      default:
        return;
    }
  };
  return (
    <Stack
      onClick={onClick}
      direction={"row"}
      height={"80px"}
      sx={{
        backgroundPosition: "center",
        transition: "background 0.8s",
        cursor: "pointer",
        width: "100%",
        p: "12px 24px",
        pr: "19px",
        gap: "16px",
        justifyContent: "flex-start",
        alignItems: "center",
        bgcolor: isSelected
          ? theme.palette.secondary["purple-tint-3"]
          : "white",
        borderRight: `5px solid ${
          isSelected ? theme.palette.primary.main : "transparent"
        }`,
        "&:hover": {
          background: `${theme.palette.neutral["B-005"]} radial-gradient(circle, transparent 1%, ${theme.palette.neutral["B-005"]} 1%) center/15000%`,
        },
        "&:active": {
          backgroundColor: theme.palette.secondary["purple-tint-3"],
          backgroundSize: "100%",
          transition: "background 0s",
        },
      }}
    >
      <Avatar
        fullname={chatData.notification?.sender_details?.display_name}
        url={chatData.notification?.sender_details?.profile_pic}
      />
      <Stack
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          gap: "4px",
        }}
      >
        <Typography
          variant="caption-2"
          noWrap
          color={theme.palette.lightBg.medium}
          sx={{
            textDecoration: "underline",
            color: theme.palette.primary.main,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onFullScreenButtonClick();
            e.preventDefault();
          }}
        >
          {chatData.u_id}
        </Typography>
        <Typography variant="body-large" noWrap>
          {chatData.service_summary}
        </Typography>
        <Typography
          variant="body-small"
          noWrap
          color={theme.palette.lightBg.low}
        >
          {chatData.notification?.sender_details.display_name}
          {" : "}
          {stripHtml(chatData.notification?.message || "")}
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: "fit-content",
          height: "100%",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Typography
          variant="body-small"
          noWrap
          color={theme.palette.lightBg.low}
        >
          {isoToDateAndTime(chatData?.notification?.created_at || "", {
            format: dayjs(chatData?.notification?.created_at || "").isToday()
              ? "hh:mm A"
              : "MMM DD",
          })}
        </Typography>

        <Box
          sx={{
            backgroundColor: "red",
            borderRadius: "22px",
            width: "fit-content",
            height: "22px",
            minWidth: "22px",
            px: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            visibility: chatData.unseenCount > 0 ? "visible" : "hidden",
          }}
        >
          <Typography variant="caption-xs" color="white">
            {chatData.unseenCount < 100 ? chatData.unseenCount : "99+"}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};
