import { Stack, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { CheckCircleIcon, ClockFilledIcon, CloseIcon } from "components/icons";
import React from "react";

interface TableChipProps {
  content: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  containerStyle?: SxProps<Theme>;
  contentStyle?: SxProps<Theme>;
  type: "success" | "error" | "warning" | "neutral";
}
export const TableChip = ({
  content,
  startIcon,
  endIcon,
  containerStyle,
  type,
  contentStyle,
}: TableChipProps) => {
  const theme = useTheme();
  const chipColors = {
    success: {
      bg: theme.palette.success.tint,
      color: theme.palette.success.main,
    },
    error: {
      bg: theme.palette.error.tint,
      color: theme.palette.error.main,
    },
    warning: {
      bg: theme.palette["warning-minor"].tint,
      color: theme.palette["warning-minor"].main,
    },
    neutral: {
      bg: theme.palette.neutral["010"],
      color: theme.palette.lightBg.low,
    },
  };

  const getDefualtStartIcon = () => {
    switch (type) {
      case "success":
        return (
          <CheckCircleIcon
            fontSize="small"
            htmlColor={chipColors[type]?.color}
          />
        );
      case "error":
        return (
          <CloseIcon fontSize="small" htmlColor={chipColors[type]?.color} />
        );
      case "neutral":
        return (
          <ClockFilledIcon
            fontSize="small"
            htmlColor={chipColors[type]?.color}
          />
        );
      default:
        break;
    }
  };
  return (
    <Stack
      direction={"row"}
      gap="4px"
      sx={{
        alignItems: "center",
        padding: "4px 8px",
        width: "102px",
        borderRadius: "4px",
        height: "24px",
        backgroundColor: chipColors[type]?.bg,
        ...containerStyle,
      }}
    >
      {startIcon ? startIcon : getDefualtStartIcon()}
      <Typography
        variant="caption-3"
        color={theme.palette.lightBg.high}
        sx={{ textTransform: "capitalize", ...contentStyle }}
      >
        {content}
      </Typography>
      <Stack
        alignItems={"center"}
        direction={"row"}
        sx={{ alignSelf: "flex-end" }}
      >
        {endIcon}
      </Stack>
    </Stack>
  );
};
