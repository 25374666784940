import {
  Box,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Input } from "components";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
interface DurationFieldProps {
  label: string;
  duration: string;
  onChange: any;
  error?: boolean;
}
export const DurationField = ({
  label = "",
  duration = "",
  onChange,
  error = false,
}: DurationFieldProps) => {
  const theme = useTheme();
  const regex = /^-?\d{0,5}(\.\d{0,2})?$/;
  let isHour = true;

  if (duration && `${duration.toLowerCase()}`.includes("minutes")) {
    isHour = false;
  }

  const onChangeHandler = (event: any) => {
    const inputValue = event.target.value;
    if (regex.test(inputValue) || inputValue === "") {
      onChange(
        `${inputValue}${
          // inputValue === "" ? "" :
          isHour ? " hours" : " minutes"
        }`
      );
    }
  };

  const getDurationOnly = () => {
    const splittedValue = duration.toString().split(" ");
    if (splittedValue.length >= 2) {
      return splittedValue[0];
    }
    return "";
  };

  const durationTypeChangeHandler = () => {
    const splittedValue = duration.toString().split(" ");
    let inputValue = "";
    if (splittedValue.length >= 2) {
      inputValue = splittedValue[0];
    }
    if (regex.test(inputValue) || inputValue === "") {
      onChange(
        `${inputValue}${
          //  inputValue === "" ? "" :
          !isHour ? " hours" : " minutes"
        }`
      );
    }
  };

  return (
    <Stack>
      <Typography variant="subtitle1">{label}</Typography>
      <Input
        name=""
        error={error}
        value={getDurationOnly()}
        onChange={onChangeHandler}
        sx={{
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            p: 0,
            m: 0,
            "& .MuiInputBase-input": {
              p: 0,
              pl: "16px",
            },
            "& .MuiInputAdornment-root": {
              flexDirection: "row-reverse",
              justifyContent: "space-between",
            },
          },
        }}
        InputProps={{
          disableUnderline: true,

          // m: 0,
          // p: 0,
          inputProps: {
            min: 0,
            inputMode: "numeric",
            pattern: "[0-9]*",
          },
          endAdornment: (
            <InputAdornment
              component={Box}
              position="end"
              sx={{
                height: "inherit",
                p: 0,
                m: 0,
              }}
            >
              <Box
                sx={{
                  height: "inherit",
                  p: "5%",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    position: "relative",
                    height: "100%",
                    aspectRatio: 2,
                    bgcolor: "#B7BDFB",
                    borderRadius: "6px",
                    border: "1px solid #B7BDFB",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={durationTypeChangeHandler}
                >
                  <Typography
                    height={"100%"}
                    variant="body-small"
                    flex={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      transition: "visibility 0.2s ease-in-out",
                      visibility: isHour ? "hidden" : "visible",
                    }}
                  >
                    Hrs
                  </Typography>
                  <Typography
                    height={"100%"}
                    variant="body-small"
                    flex={1}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      transition: "visibility 0.2s ease-in-out",
                      visibility: !isHour ? "hidden" : "visible",
                    }}
                  >
                    Min
                  </Typography>

                  <Box
                    sx={{
                      position: "absolute",
                      transition: "transform 0.4s ease-in-out",
                      height: "100%",
                      aspectRatio: "1",
                      bgcolor: "#5563F5",
                      transform: isHour
                        ? "translateX(0px)"
                        : "translateX(calc( 100% + 2px ))",
                      borderRadius: "6px",
                    }}
                  >
                    <Typography
                      height={"100%"}
                      variant="body-small"
                      flex={1}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      color={"white"}
                    >
                      {isHour ? "Hrs" : "Min"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
