import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { CustomTypography } from "../../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../../library/icon-library";
import { Table_Model } from "../../constants";
import colorPalette from "../../../../../../../../../library/common/colors";
import { ConfigurationMenuItem } from "../../../../Components";
import PriorityItem from "../priority_item";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
import { FileIcon, PriorityBadge } from "components";
function TemplateTableTile(props) {
  const data = props.value;

  const onDeleteHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    props.onDeleteClicked();
  };

  return (
    <ThemeProvider>
      <Box
        onClick={props.onEditClicked}
        sx={{
          width: "100%",
          height: "48px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          px: "40px",
          columnGap: "16px",
          alignItems: "center",
          borderTop: "1px solid #E3E3E3",
          "&:hover": {
            backgroundColor: "#F3F5F9",
            cursor: "pointer",
          },
        }}
      >
        <Box
          key={Table_Model.template_name.key}
          width={Table_Model.template_name.size}
        >
          <Typography
            variant="body-small"
            sx={{
              maxWidth: "200px",
              overflowX: "clip",
              textOverflow: "ellipsis",
            }}
          >
            {data[Table_Model.template_name.name] || ""}
          </Typography>
        </Box>

        <Box
          key={Table_Model.template_summary.key}
          width={Table_Model.template_summary.size}
        >
          <Typography
            variant="body-small"
            key={Table_Model.template_summary.key}
            width={"200px"}
            sx={{
              overflow: "clip",
              maxHeight: "40px",
              textOverflow: "ellipsis",
              flexWrap: "wrap",
              maxLines: 1,
            }}
          >
            {data[Table_Model.template_summary.name] || ""}
          </Typography>
        </Box>

        <Box
          key={Table_Model.template_priority.key}
          width={Table_Model.template_priority.size}
        >
          <PriorityBadge
            type={data[Table_Model.template_priority.name] || ""}
          />
        </Box>

        <Box
          key={Table_Model.template_documents.key}
          sx={{
            display: "flex",
            flexGrow: "1",
            minWidth: Table_Model.template_documents.minWidth || 0,
            spacing: "16px",
            direction: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
            {data[Table_Model.template_documents.name] &&
              Array.isArray(data[Table_Model.template_documents.name]) &&
              data[Table_Model.template_documents.name].length > 0 &&
              data[Table_Model.template_documents.name][0].file_type !==
                null && (
                <FileIcon
                  type={data[Table_Model.template_documents.name][0].file_type}
                />
              )}
            {data[Table_Model.template_documents.name] &&
            Array.isArray(data[Table_Model.template_documents.name]) ? (
              <Stack
                direction={"row"}
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {data[Table_Model.template_documents.name].map(
                  (item, index) =>
                    item.original_name !== null &&
                    item.file_type !== null && (
                      <Typography
                        variant="body-small"
                        sx={{
                          maxWidth: "200px",
                          overflowX: "clip",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`${index > 0 ? ", " : ""}${item.original_name}`}
                      </Typography>
                    )
                )}
              </Stack>
            ) : null}
          </Stack>
          <Box
            onClick={onDeleteHandler}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <CustomIcon icon="delete" vertical color={"black"} size={"lg"} />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

TemplateTableTile.propTypes = {
  value: PropTypes.object.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
};

TemplateTableTile.defaultProps = {
  value: {},
  onEditClicked: () => {},
  onDeleteClicked: () => {},
};

export default TemplateTableTile;
