import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect } from "react";
import colorPalette from "../../common/colors";
import defaultSize from "./constants/defaultSizes";

function ToggleButton(props) {
  const handleClick = (event) => {
    props.onChange({ field: props.name, value: event.target.checked, event: event });
  };

  return (
    <Switch
      onChange={handleClick}
      checked={props.isChecked === true}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      disabled={props.isDisabled}

      sx={(theme) => ({
        height: {
          xs: `${defaultSize.sm.height}px`,
          sm: `${defaultSize.sm.height}px`,
          md: `${defaultSize.md.height}px`,
          lg: `${defaultSize.lg.height}px`,
          lx: `${defaultSize.xl.height}px`,
        },
        width: {
          xs: `${defaultSize.sm.width}px`,
          sm: `${defaultSize.sm.width}px`,
          md: `${defaultSize.md.width}px`,
          lg: `${defaultSize.lg.width}px`,
          lx: `${defaultSize.xl.width}px`,
        },
        padding: 0,
        "& .MuiSwitch-switchBase": {
          padding: 0,
          transitionDuration: "300ms",
          "&.Mui-checked": {
            transform: {
              xs: `translateX(${defaultSize.sm.width - defaultSize.sm.height
                }px)`,
              sm: `translateX(${defaultSize.sm.width - defaultSize.sm.height
                }px)`,
              md: `translateX(${defaultSize.sm.width - defaultSize.sm.height
                }px)`,
              lg: `translateX(${defaultSize.lg.width - defaultSize.lg.height
                }px)`,
              lx: `translateX(${defaultSize.xl.width - defaultSize.xl.height
                }px)`,
            },
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor:
                colorPalette.color.secondary.primaryTintSecondary,
              opacity: 1,
              border: 0, //`.6px solid ${colorPalette.sematics.lightBg.success}`
            },
            "& .MuiSwitch-thumb": {
              backgroundColor: colorPalette.color.primary.primary,
              border: 0,
              boxShadow: "-2px 0px 3px #838ACD",
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="5" width="6" viewBox="0 0 5 6"><line x1="0.5" y1="0.5" x2="0.5" y2="5.5" stroke="${encodeURIComponent(
                "#09471C"
              )}" strokeLinecap="round"/><line x1="2.5" y1="0.5" x2="2.5" y2="5.5" stroke="${encodeURIComponent(
                "#09471C"
              )}" strokeLinecap="round"/><line x1="4.5" y1="0.5" x2="4.5" y2="5.5" stroke="${encodeURIComponent(
                "#09471C"
              )}" strokeLinecap="round"/></svg>')`,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          // '&.Mui-focusVisible .MuiSwitch-thumb': {     color: '#33cf4d',     border:
          // '2px solid #fff', },
          "&.Mui-disabled .MuiSwitch-thumb": {
            backgroundColor: theme.palette.grey[500],
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
            backgroundColor: theme.palette.neutral["020"],
          },
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          height: {
            xs: `${defaultSize.sm.height}px`,
            sm: `${defaultSize.sm.height}px`,
            md: `${defaultSize.md.height}px`,
            lg: `${defaultSize.lg.height}px`,
            lx: `${defaultSize.xl.height}px`,
          },
          width: {
            xs: `${defaultSize.sm.height}px`,
            sm: `${defaultSize.sm.height}px`,
            md: `${defaultSize.md.height}px`,
            lg: `${defaultSize.lg.height}px`,
            lx: `${defaultSize.xl.height}px`,
          },
          boxShadow: "2px 0px 3px #0000001A",
          border: `1px solid ${colorPalette.neutrals.lightBg.quinary}`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="5" width="6" viewBox="0 0 5 6"><line x1="0.5" y1="0.5" x2="0.5" y2="5.5" stroke="${encodeURIComponent(
            colorPalette.neutrals.lightBg.quinary
          )}" strokeLinecap="round"/><line x1="2.5" y1="0.5" x2="2.5" y2="5.5" stroke="${encodeURIComponent(
            colorPalette.neutrals.lightBg.quinary
          )}" strokeLinecap="round"/><line x1="4.5" y1="0.5" x2="4.5" y2="5.5" stroke="${encodeURIComponent(
            colorPalette.neutrals.lightBg.quinary
          )}" strokeLinecap="round"/></svg>')`,
        },
        "& .MuiSwitch-track": {
          border: `.6px solid ${colorPalette.neutrals.lightBg.quinary}`,
          borderRadius: 26 / 2,
          backgroundColor: "#D9D9D9",
          opacity: 1,
          transition: theme.transitions.create(["background-color"], {
            duration: 500,
          }),
        },
      })}
      size="small"
    />
  );
}

ToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

ToggleButton.defaultProps = {
  name: "",
  isChecked: false,
  onChange: () => { },
  isDisabled: false,
};

export default ToggleButton;
