import dayjs from "dayjs";
import { Assignees, ScheduleResult, ServiceType } from "shared";

import { immer } from "zustand/middleware/immer";

interface TicketDetailsState extends ServiceType {
  ticketId: string;
  feedback?: string | null;
  rating?: number | null;
  // activeTimeEntryId: string;
  // activeTimeEntryState: boolean;
  // activeTimerValueInSeconds: number;
  // activeTimerInstanceId: any;
  // activeTimerStartTime: string | dayjs.Dayjs | null;
  assignees: Array<Assignees>;
  isChatExpanded: boolean;
  ticketDetails: any;
  merged_to_mapping_id?: string | null;
  merged_to_uid?: string | null;
  isDisabled: boolean;
  ticketSource?: string;
  // isTimeEntryModalOpen: boolean;
  // selectedTimeEntry?: any;
}

interface TicketDetailsAction {
  // startTime: (timeEntryId: string, startTime: string | dayjs.Dayjs) => void;
  // endTime: () => void;
  extractAssigneesFromSchedule: (schedule: Array<ScheduleResult>) => void;
  addTicketDetails: (
    data: Record<
      | "ticketId"
      | "feedback"
      | "rating"
      | "merged_to_mapping_id"
      | "merged_to_uid"
      | "ticketSource"
      | "ticketStatus",
      any
    >
  ) => void;
  // addTicketDetails: ({
  //   type,
  //   value,
  // }: {
  //   type: "ticketId" | "feedback" | "rating";
  //   value: string;
  // }) => void;
  toggleChat: () => void;
  resetTicketDetails: () => void;
  setTicketDetails: (data: any) => void;
  changeTicketDetails: ({
    type,
    value,
  }: {
    type:
      | "connectTimeFrom"
      | "connectTimeTo"
      | "workType"
      | "workRole"
      | "source"
      | "budgetHours"
      | "scheduleHours"
      | "actualHours"
      | "estimatedEndDateTime"
      | "ticketType"
      | "ticketSubType"
      | "ticketVariableAttribute"
      | "generalVariableAttribute"
      | "tags"
      | "startDateTime"
      | "timeEntries"
      | "location"
      | "customerId"
      | "siteId"
      | "isSlaExpired"
      | "userId";
    value: any;
  }) => void;
  // toggleTimeEntryModal: (value: boolean) => void;
  // setSelectedTimeEntry: (data: any) => void;
  resetOpenTicketDetails: () => void;
}

export interface TicketDetailsInterface
  extends TicketDetailsState,
    TicketDetailsAction {}

const ticketDetailsValueBuilder = (data: any) => {
  return {
    connectTimeFrom: data?.connect_time_from || null,
    connectTimeTo: data?.connect_time_to || null,
    workType: data?.work_type || "",
    workRole: data?.work_role || "",
    source: data?.source || "",
    budgetHours: data?.budgeted_hours || "",
    scheduledHours: data?.scheduled_hours || "",
    actualHours: data?.actual_hours || "",
    estimatedEndDateTime: data?.estimated_end_date || null,
    ticketType: data?.ticket_type_id || "",
    ticketSubType: data?.ticket_subtype_id || "",
    ticketVariableAttribute: data?.ticketVariableAttribute || [],
    generalVariableAttribute: data?.generalVariableAttribute || [],
    tags: data?.tags || [],
    startDateTime: data?.created_at || "",
    timeEntries: data.timeEntries || [],
    location: data?.location || "",
    status: data?.ticket_status || "",
    isBillable: data?.is_billable || false,
    customerId: data?.customer_id,
    sla: data?.sla || "",
    priority: data?.priority || "",
    isSlaExpired: data?.is_expired_sla || false,
    // siteId: data?.
    //sla time duration
    //ticket close date and time
    //
  };
};

const initialTicketDetailsValue = {
  ticketId: "",
  serviceType: "ticket",
  activeTimeEntryId: "",
  activeTimeEntryState: false,
  activeTimerValueInSeconds: 0,
  activeTimerInstanceId: null,
  activeTimerStartTime: null,
  assignees: [],
  isChatExpanded: false,
  ticketDetails: ticketDetailsValueBuilder({}),
  isDisabled: false,
  isTimeEntryModalOpen: false,
};

export const ticketDetailsSlice = immer<
  TicketDetailsState & TicketDetailsAction
>((set, get) => ({
  ...initialTicketDetailsValue,
  // startTime: (timeEntryId, startTime) => {
  //   set((state) => {
  //     state.activeTimeEntryId = timeEntryId;
  //     state.activeTimeEntryState = true;
  //     state.activeTimerStartTime = startTime;
  //   });
  //   const interval = setInterval(() => {
  //     const startTimeValue = get().activeTimerStartTime;
  //     if (startTimeValue != null) {
  //       set((state) => {
  //         const currentTime = new Date();
  //         state.activeTimerValueInSeconds = Math.floor(
  //           (currentTime.getTime() - new Date(`${startTimeValue}`).getTime()) /
  //             1000
  //         );
  //       });
  //     }
  //   }, 1000);
  //   set((state) => {
  //     state.activeTimerInstanceId = interval;
  //   });
  // },
  // endTime: () =>
  //   set((state) => {
  //     state.activeTimeEntryState = false;
  //     state.activeTimeEntryId = "";
  //     state.activeTimerValueInSeconds = 0;
  //     if (get().activeTimerInstanceId != null) {
  //       clearInterval(get().activeTimerInstanceId);
  //     }
  //   }),
  extractAssigneesFromSchedule: (schedules) =>
    set((state) => {
      const mappingArray: Array<string> = [];
      const assigneesArray: Array<Assignees> = [];
      const assignees = schedules.map((schedule) => {
        if (!mappingArray.includes(schedule.resource_id)) {
          assigneesArray.push({
            mapping_id: schedule.resource_id,
            first_name: schedule.first_name,
            last_name: schedule.last_name,
            display_name: schedule.display_name
              ? schedule.display_name
              : `${schedule.first_name} ${
                  schedule.last_name ? schedule.last_name : ""
                }`,
            profile_image: schedule.profile_image,
          });
        }
        mappingArray.push(schedule.resource_id);
      });
      state.assignees = assigneesArray;
    }),

  addTicketDetails: (data) =>
    set((state) => {
      state.feedback = data.feedback ? data.feedback : null;
      state.ticketSource = data.ticketSource ? data.ticketSource : null;
      state.rating = data.rating ? data.rating : null;
      state.ticketId = data.ticketId ? data.ticketId : null;

      if (
        data.feedback !== null ||
        data.rating !== null ||
        data.merged_to_mapping_id !== null ||
        data.merged_to_uid !== null ||
        data.ticketStatus === "da5be1f9-db9d-4c64-8e43-51b458746773" || //closed ticked
        data.ticketStatus === "953b24ef-2167-49f9-88d9-a06cca165753"
      ) {
        state.isDisabled = true;
        // if (get().activeTimerInstanceId != null) {
        //   state.activeTimeEntryState = false;
        //   state.activeTimeEntryId = "";
        //   state.activeTimerValueInSeconds = 0;
        //   clearInterval(get().activeTimerInstanceId);
        // }
      }
    }),
  toggleChat: () =>
    set((state) => {
      state.isChatExpanded = !state.isChatExpanded;
    }),
  resetOpenTicketDetails: () =>
    set((state) => {
      state.ticketId = "";
      state.serviceType = "ticket";
      state.assignees = [];
      state.isChatExpanded = false;
      state.ticketDetails = ticketDetailsValueBuilder({});
      state.isDisabled = false;
      state.feedback = null;
      state.rating = null;
    }),
  resetTicketDetails: () => {
    // if (get().activeTimerInstanceId != null) {
    //   clearInterval(get().activeTimerInstanceId);
    // }
    set((state) => {
      state.ticketId = "";
      state.serviceType = "ticket";
      // state.activeTimeEntryId = "";
      // state.activeTimeEntryState = false;
      // state.activeTimerValueInSeconds = 0;
      // state.activeTimerInstanceId = null;
      // state.activeTimerStartTime = null;
      state.assignees = [];
      state.isChatExpanded = false;
      state.ticketDetails = ticketDetailsValueBuilder({});
      // state.activeTimeEntryState = false;
      // state.activeTimeEntryId = "";
      // state.activeTimerValueInSeconds = 0;
      state.isDisabled = false;
      state.feedback = null;
      state.rating = null;
      // state.isTimeEntryModalOpen = false;
      state.ticketDetails = null;
    });
  },
  setTicketDetails(data) {
    const newTicketDetails = ticketDetailsValueBuilder(data);
    set((state) => {
      state.ticketDetails = newTicketDetails;
    });
  },

  changeTicketDetails({ type, value }) {
    set((state) => {
      state.ticketDetails[type] = value;
    });
  },

  // toggleTimeEntryModal(value) {
  //   set((state) => {
  //     state.isTimeEntryModalOpen = value;
  //   });
  // },

  // setSelectedTimeEntry(data) {
  //   set((state) => {
  //     state.selectedTimeEntry = data;
  //   });
  // },
}));
