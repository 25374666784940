import { Box } from "@mui/material";
import { DownArrowIcon, Input } from "components";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import toast from "react-hot-toast";
import { useCreateTaxConfig, useUpdateTaxConfig } from "controller";

const schema = yup.object({
  tax_name: yup.string().trim().required("Tax label is required"),
  tax_rate: yup
    .number()
    .typeError("Tax rate must be a number")
    .required("Tax rate is required")
    .min(0, "Tax rate must be 0 or higher")
    .max(100, "Tax rate should not exceed 100"),
  is_percentage: yup.boolean().required("Required"),
});

export const BillingTaxFields = forwardRef(
  // ({ control, errors }: any, ref) => {
  ({ data, closeDrawer, mode }: any, ref) => {
    useImperativeHandle(ref, () => ({
      saveButtonClick: () => {
        handleSubmit(async ({ tax_name, tax_rate, is_percentage }) => {
          const taxRate = Math.trunc(Number((tax_rate * 100).toFixed(2)));
          try {
            if (mode?.method === "create") {
              await createTaxConfig({
                tax_name,
                tax_rate: taxRate,
                is_percentage,
              });
            } else if (mode?.method === "update") {
              await updateTaxConfig({
                data: {
                  tax_name,
                  tax_rate: taxRate,
                  is_percentage,
                },
                id: mode?.data?.mapping_id,
              });
            }
            closeDrawer();
            reset();
            toast.success("Tax added successfully");
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
          }
        })();
      },
      cancelButtonClick: () => {
        closeDrawer();
        reset();
      },
    }));

    const {
      handleSubmit,
      control,
      reset,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        tax_name: data?.tax_label || "",
        tax_rate: data?.tax_rate || "",
        is_percentage: data?.is_percentage || true,
      },
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      if (mode?.data) {
        setValue("tax_name", mode?.data?.tax_name);
        setValue("tax_rate", (mode?.data?.tax_rate / 100).toFixed(2));
        setValue("is_percentage", mode?.data?.is_percentage);
      } else {
        reset();
      }
    }, [mode?.data, setValue, closeDrawer]);

    const { mutateAsync: createTaxConfig, isLoading } = useCreateTaxConfig();
    const { mutateAsync: updateTaxConfig } = useUpdateTaxConfig();

    return (
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Input
          label={"Tax label"}
          name="tax_name"
          control={control}
          step={0.01}
          error={errors?.tax_name ? true : false}
          isResponsive={false}
          // @ts-ignore
          errorMessage={errors?.tax_name?.message || ""}
        />
        <Input
          label={"Tax Rate (%)"}
          name="tax_rate"
          type="number"
          // InputProps={{
          //   endAdornment: "%"
          // }}
          control={control}
          error={errors?.tax_rate ? true : false}
          isResponsive={false}
          inputProps={{
            maxLength: 3,
            step: 0.01,
            max: 100.0,
            min: 0,
          }}
          // @ts-ignore
          errorMessage={errors?.tax_rate?.message || ""}
        />
      </Box>
    );
  }
);
