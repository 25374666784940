import { IconButton, IconButtonProps, useTheme } from "@mui/material";
import {
  PlayButtonIcon,
  PlayIcon,
  StopButtonIcon,
  StopIcon,
} from "components/icons";
import React from "react";

interface PlayStopButtonProps extends IconButtonProps {
  isPlaying: boolean;
}
export const PlayStopButton = ({
  isPlaying,
  sx,
  ...rest
}: PlayStopButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton
      sx={{
        width: "24px",
        height: "24px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFF",
        color: isPlaying
          ? theme.palette.error.main
          : theme.palette.success.main,
        padding: 0,
        boxShadow:
          "0px 0px 8px 0px rgba(0, 0, 0, 0.16),0px 0px 2px 0px rgba(0, 0, 0, 0.08)",

        "&:hover": {
          backgroundColor: "#FFF",
          color: isPlaying
            ? theme.palette.error.dark
            : theme.palette.success.dark,
        },
        ...sx,
      }}
      {...rest}
    >
      {isPlaying ? <StopIcon color="inherit" /> : <PlayIcon color="inherit" />}
    </IconButton>
  );
};
