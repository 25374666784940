import { UpdateTimesheetConfigInput } from "shared";
import { axiosClient } from "utilz";
import { apiErrorHandler } from "utilz/helpers";

export const getTimesheetConfigurations = async () => {
  try {
    const url = "/configuration/timesheet/settings";
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateTimesheetConfigurations = async (
  data: UpdateTimesheetConfigInput
) => {
  try {
    const url = "/configuration/timesheet/settings";
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
