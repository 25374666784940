import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="1.5"
        d="M19.496 6.672v3.635H7.289V5.2c0-1.215.986-2.2 2.201-2.2h6.1c.397 0 .779.146 1.073.411l2.3 2.067c.34.304.533.738.533 1.194ZM19.496 16.531v1.72a2.2 2.2 0 0 1-2.2 2.202H9.49a2.201 2.201 0 0 1-2.2-2.201v-1.72h12.206ZM19.485 10.31v6.22H6.008C5.45 16.53 5 16.08 5 15.523v-4.205c0-.557.451-1.008 1.008-1.008h13.477Z"
      />
      <path
        fill="#1C1E26"
        d="M11.354 15.022a1.592 1.592 0 0 1-1.596-1.6 1.605 1.605 0 0 1 1.609-1.594c.86.006 1.586.734 1.585 1.593a1.6 1.6 0 0 1-1.599 1.6v.001Zm.959-1.579a.952.952 0 0 0-.953-.973.946.946 0 0 0-.962.94c-.002.542.397.96.928.972.547.012.98-.4.986-.938l.001-.001ZM15.884 13.744c-.138 0-.255.007-.372-.002-.196-.016-.32-.125-.315-.326.005-.19.125-.298.31-.305.253-.01.509-.017.761 0 .223.014.316.158.317.38.004.834-.55 1.48-1.322 1.529a1.592 1.592 0 0 1-1.681-1.402c-.069-.638.17-1.153.695-1.51.518-.354 1.077-.374 1.639-.088.267.136.35.313.242.498-.109.186-.26.205-.523.063a.98.98 0 0 0-1.11.135.961.961 0 0 0-.228 1.116c.16.358.543.58.95.547.337-.027.56-.235.636-.635h.001ZM8.54 13.187c0 .33.004.661-.003.992-.003.152.053.216.208.207a2.35 2.35 0 0 1 .38.003c.184.02.304.128.304.315 0 .186-.116.304-.304.31-.304.011-.61.012-.915 0-.204-.007-.302-.139-.302-.337-.002-.839-.003-1.678.001-2.516.001-.201.117-.325.315-.323.202.002.306.139.31.333.007.339.002.677.002 1.016h.003Z"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="1.2"
        d="M9.992 5.79h2.906M9.992 7.664h5.81"
      />
    </svg>
  </SvgIcon>
);
