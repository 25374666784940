function alignedRightIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="21"
        y="21"
        width="18"
        height="18"
        rx="5"
        transform="rotate(-180 21 21)"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12 15.4999L16.5 15.5001"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M16.5 8.5H7.5"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.5 12H16.5"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}
export default alignedRightIcon;