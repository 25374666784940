function databaseIcon(props) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 16C1 17.381 4.134 18.5 8 18.5C11.866 18.5 15 17.381 15 16V4C15 2.619 11.866 1.5 8 1.5C4.134 1.5 1 2.619 1 4V16Z"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4C15 5.381 11.866 6.5 8 6.5C4.134 6.5 1 5.381 1 4"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12C15 13.381 11.866 14.5 8 14.5C4.134 14.5 1 13.381 1 12"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8C15 9.381 11.866 10.5 8 10.5C4.134 10.5 1 9.381 1 8"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default databaseIcon;
