import { useEffect, useState } from "react";

// Legacy Imports
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";

import toast from "react-hot-toast";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
import {
  Button,
  CircularLoader,
  LockIcon,
  RecurringTaskIcon,
  UnLockIcon,
} from "components";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import {
  useGetMfa,
  useGetMspById,
  useGlobalStore,
  useResourceResendInvite,
  useUnenrollResourceMfa,
  useUpdateResource,
} from "controller";
import { debugConsole } from "utilz/helpers";
import { RESOURCE_TYPES } from "shared";

export const SecurityTab = ({
  data,
  isResourceDetailsLoading,
  refetchResourceDetails,
}: {
  data: any;
  isResourceDetailsLoading: boolean;
  refetchResourceDetails: any;
}) => {
  const theme = useTheme();
  const {
    mutateAsync: resourceResendInvite,
    isLoading: isResourceResendInviteLoading,
  } = useResourceResendInvite();
  const { mutateAsync: updateResource, isLoading: isUpdateResourceLoading } =
    useUpdateResource();
  const { data: getMfaData, isLoading: isGetMfaLoading } = useGetMfa() as any;
  const { mutateAsync: resetResourceMfa } = useUnenrollResourceMfa();

  const [isAccountUnLocked, setIsAccountUnlocked] = useState(
    data?.is_locked === false
  );

  const mspId = useGlobalStore((state) => state.mspId);
  const { data: mspDetails, isFetching: isMspLoading } = useGetMspById(
    mspId || ""
  );

  // handlers
  const resetInvitationHandler = async () => {
    const email = data?.email || "";
    if (email !== "") {
      try {
        const response = await resourceResendInvite({
          email,
          tenantId: mspId || "",
        });
        toast.success("Reset password link has been shared to your email");
      } catch (error: any) {
        if (typeof error === "string") {
          toast.error(error);
        } else {
          toast.error(
            error?.response?.data?.message || "Unable to reset password"
          );
        }
      }
    } else {
      toast.error("Invalid email");
    }
  };

  const handleResetMfa = async () => {
    const resourceId = data?.mapping_id ?? "";
    if (resourceId !== "") {
      try {
        await resetResourceMfa({ userId: resourceId });
        toast.success(
          "MFA reset successful. Resource is required to set up MFA on Authenticator app during next login."
        );
      } catch (error) {
        toast.error("Unable to reset MFA");
      }
    } else {
      toast.error("Invalid resource");
    }
  };

  const handleUnlockAccountToggleBtnChange = () => {
    setIsAccountUnlocked((prev) => !prev);
    const prevIsLocked = data?.is_locked;
    (async () => {
      try {
        // @ts-ignore
        const updateResourceData = await updateResource({
          resourceId: data?.mapping_id,
          is_locked: !data?.is_locked,
        });
        await refetchResourceDetails();
        const newIsLocked = data?.is_locked;
        setIsAccountUnlocked(newIsLocked === false);
        toast.success(
          `${
            prevIsLocked === false ? "Locked" : "Unlocked"
          } account successfully`
        );
      } catch (error) {
        debugConsole(error);
        toast.error(
          `Failed to ${prevIsLocked === false ? "lock" : "unlock"} account!`
        );
        setIsAccountUnlocked(data?.is_locked === false);
      }
    })();
  };

  useEffect(() => {
    setIsAccountUnlocked(data?.is_locked === false);
  }, [data?.is_locked]);

  return (
    <>
      <Box sx={{ px: "32px", pt: "24px" }}>
        <Stack
          sx={{
            borderRadius: "24px",
            border: `1px solid ${theme.palette.neutral["015"]}`,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stack spacing={0}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: "24px",
                  px: "24px",
                  borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
                }}
              >
                <Box>
                  <Typography variant="body-large">Reset Password</Typography>
                </Box>
                <Box>
                  <ThemeProvider>
                    <Button
                      disabled={data?.is_locked === true}
                      sx={{ width: "156px" }}
                      variant="secondary"
                      // startIcon={<RecurringTaskIcon />}
                      onClick={resetInvitationHandler}
                      loading={isResourceResendInviteLoading}
                    >
                      Reset Password
                    </Button>
                  </ThemeProvider>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pt: "24px",
                  pb: "32px",
                  px: "24px",
                  borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
                }}
              >
                <Box>
                  <Typography variant="body-large">
                    Multi-factor Authentication
                  </Typography>
                </Box>
                <Box>
                  {/* <ToggleButton
                    isDisabled={true}
                    name={"unlock"}
                    isChecked={state?.mfa}
                    onChange={({ field, value }) =>
                      setState({ ...state, mfa: value })
                    }
                  /> */}
                  <ThemeProvider>
                    <Box
                      width={"156px"}
                      height={"42px"}
                      sx={{ display: "grid", placeItems: "center" }}
                    >
                      {isGetMfaLoading ? (
                        <CircularProgress size={"18px"} />
                      ) : (
                        <Button
                          fullWidth
                          variant="secondary"
                          onClick={handleResetMfa}
                          disabled={getMfaData?.enforceMFAType !== "Force"}
                        >
                          Reset MFA
                        </Button>
                      )}
                    </Box>
                  </ThemeProvider>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: "24px",
                  px: "24px",
                }}
              >
                <Box>
                  <Typography variant="body-large">
                    {isAccountUnLocked ? "Account Unlocked" : "Account Locked"}
                  </Typography>
                </Box>
                <Box
                  width={"156px"}
                  sx={{ display: "grid", placeItems: "center" }}
                >
                  <Stack direction="row" gap="12px" alignItems={"center"}>
                    <Box
                      width={"156px"}
                      height={"42px"}
                      sx={{ display: "grid", placeItems: "center" }}
                    >
                      {isResourceDetailsLoading ? (
                        <CircularProgress size={"18px"} />
                      ) : (
                        <Button
                          startIcon={
                            isUpdateResourceLoading ||
                            isResourceDetailsLoading ||
                            isMspLoading ? (
                              <CircularLoader
                                size={"16px"}
                                sx={{
                                  "&.MuiCircularProgress-root": {
                                    color: theme.palette.lightBg.low,
                                  },
                                  display:
                                    isUpdateResourceLoading ||
                                    isResourceDetailsLoading
                                      ? "flex"
                                      : "none",
                                }}
                              />
                            ) : isAccountUnLocked ? (
                              <LockIcon />
                            ) : (
                              <UnLockIcon />
                            )
                          }
                          fullWidth
                          variant="secondary"
                          disabled={
                            isUpdateResourceLoading ||
                            isResourceDetailsLoading ||
                            isMspLoading ||
                            (data?.resource_type_id ===
                              RESOURCE_TYPES.SUPERADMIN &&
                              data?.email === mspDetails.business_email)
                          }
                          onClick={handleUnlockAccountToggleBtnChange}
                        >
                          {(!isUpdateResourceLoading ||
                            !isResourceDetailsLoading ||
                            !isMspLoading) &&
                          isAccountUnLocked
                            ? "Lock Account"
                            : "Unlock Account"}
                        </Button>
                      )}
                    </Box>
                    {/* {(isUpdateResourceLoading || isMspLoading) && (
                      <CircularLoader
                        size={"22px"}
                        sx={{
                          display: isUpdateResourceLoading ? "flex" : "none",
                        }}
                      />
                    )}
                    <ToggleButton
                      isDisabled={
                        isUpdateResourceLoading ||
                        isMspLoading ||
                        (data?.resource_type_id === RESOURCE_TYPES.SUPERADMIN &&
                          data?.email === mspDetails.business_email)
                      }
                      name={"unlock"}
                      isChecked={unlockAccountToggleBtnState.isChecked || false}
                      onChange={handleUnlockAccountToggleBtnChange}
                    /> */}
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
