import { SvgIcon, SvgIconProps } from "@mui/material";

export const InfoFilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-8.953-2.5a.75.75 0 1 1 .007-1.5.75.75 0 0 1-.007 1.5ZM12 10.8a.75.75 0 0 0-.75.75v5a.75.75 0 0 0 1.5 0v-5a.75.75 0 0 0-.75-.75Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
