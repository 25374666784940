export const CONSTANT = {
  board_name: {
    name: "board_name",
  },
  board_description: {
    name: "board_description",
  }
};


export const defaultValueBuilder = {
  board_name: "",
  board_description: "",
  board_filters: [],
};



export const newValueBuilder = (data) => ({
  ...data,
  board_name: data["board_name"] || "",
  board_description: data["board_description"] || "",
  board_filters: data["board_filters"] || [],
}
);