import { axiosClient } from "utilz";

export const createSampleData = async () => {
  try {
    const url = "/sampleData";
    const response = await axiosClient.post(url);

    const responseData = response.data;
    if (responseData) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const skipSampleData = async () => {
  try {
    const url = "/skip/sampleData";
    const response = await axiosClient.patch(url);

    const responseData = response.data;
    if (responseData) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteSampleData = async () => {
  try {
    const url = "/servicedesk/all/delete/sampledata";
    const response = await axiosClient.delete(url);

    const responseData = response.data;
    if (responseData) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
