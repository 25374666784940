function flagIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {props.isFilled === true ? <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H13.3333L12 5L13.3333 8H4"
        fill={props.disabled === true ? props.disabledcolor : props.color} /> : ""}
      <path
        d="M4 2H13.3333L12 5L13.3333 8H4"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5 13.5H3"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4 13V2"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeLinecap="square"
        strokeLinejoin="round" />
    </svg>


  );
}
export default flagIcon;