import { GetCitiesByStatesInput } from "shared/types/general";
import { axiosClient } from "utilz";

export const setDownloadAcknowledgement = async (resourceId: string) => {
  try {
    const url = `/acknowledgement/servicedeks/${resourceId}`;
    const response = await axiosClient.post(url);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData?.success &&
      responseData?.statusCode === 200
    ) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    throw error;
  }
};
