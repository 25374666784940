import { Stack, Typography } from "@mui/material";
import { CustomCheckBox } from "../../../../../../../library/system/custom-form";
import { Avatar } from "components";

interface ResourceMemberTileProps {
  data: any;
  isSelected: boolean;
}
export const ResourceMemberTile = ({
  data,
  isSelected,
}: ResourceMemberTileProps) => {
  return (
    <Stack
      direction={"row"}
      gap={"20px"}
      sx={{
        py: "8px",
        alignItems: "center",
      }}
    >
      <CustomCheckBox checked={isSelected} />

      <Avatar
        fullname={data.label}
        src={data.profile}
        sx={{
          width: "32px",
          height: "32px",
          aspectRatio: "1",
        }}
      />

      <Stack>
        <Typography variant="body-medium">{data.label || ""}</Typography>
        <Typography variant="body-medium">{data?.subLabel || ""}</Typography>
      </Stack>
    </Stack>
  );
};
