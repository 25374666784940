function attachmentIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8791 8.3743L8.39309 13.8603C7.56709 14.6863 7.56709 16.0263 8.39309 16.8523V16.8523C9.21909 17.6783 10.5591 17.6783 11.3851 16.8523L18.6171 9.6203C20.1321 8.1053 20.1321 5.6493 18.6171 4.1343V4.1343C17.1021 2.6193 14.6461 2.6193 13.1311 4.1343L5.89909 11.3663C3.69509 13.5703 3.69509 17.1423 5.89909 19.3463V19.3463C8.10309 21.5503 11.6751 21.5503 13.8791 19.3463L18.2681 14.9573"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}
export default attachmentIcon;
