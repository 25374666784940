import React, { useState } from "react";

// Legacy Imports
import Box from "@mui/material/Box";

// HOC Imports
import {
  CustomTypography,
  CustomButton,
  CustomIconButton,
} from "../../../../../../../../library/base";

// Components
import CreateResourceTypeModal from "./create-form";
import { services } from "services";
import { useGlobalStore } from "controller/store/globalStore";
import toast from "react-hot-toast";
import { SearchTextField } from "pages/modules/control-center/configurations/components/Components";

const ResourcesTypeHeadMount = (props) => {
  const [searchQuery, setSearchQuery] = props.data;
  const mspId = useGlobalStore((state) => state.mspId);
  // State
  const [state, setState] = useState({
    modalOpen: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Effects

  //api

  const createNewResourceType = (data) => {
    setIsLoading(true);
    services.controlCenterservices.resourcesService
      .createResourceType(mspId, data)
      .subscribe({
        next: (response) => {
          if (
            response &&
            response?.success === true &&
            response?.statusCode === 200
          ) {
            setIsLoading(false);
            toast.success("Resource type is ready");
            props.onCreateNew();
            return;
          }
          setIsLoading(false);
          toast.error("Unable to create at the moment. Try again later");
        },
        error: (error) => {
          setIsLoading(false);
          toast.error("Unable to create at the moment. Try again later");
        },
      });
  };

  // Event Handlers
  const closeModal = () => {
    setState({ ...state, modalOpen: false });
  };

  const updateSearch = (event) => {
    try {
      const newValue = event.value;
      setSearchQuery((oldState) => ({ ...oldState, queryString: newValue }));
      if (newValue.length === 0 || newValue.length > 2) {
        props.onUpdateFilter(newValue);
      }
    } catch (err) {}
  };

  const onSubmitHandler = (data) => {
    if (
      data.resource_type_name != null &&
      data.resource_type_name.length > 0 &&
      data.resource_type_description != null &&
      data.resource_type_description.length > 0
    ) {
      createNewResourceType(data);
      return;
    }
    toast.error("Fill all fields");
  };

  // Renderer
  return (
    <React.Fragment>
      {state?.modalOpen ? (
        <CreateResourceTypeModal
          isLoading={isLoading}
          open={state?.modalOpen}
          onSubmit={onSubmitHandler}
          closeHandle={() => closeModal()}
        />
      ) : null}

      <Box
        sx={{
          width: "100%",
          mb: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <CustomTypography
            size={"MEDIUM-ALPHA"}
            color={"PRIMARY-MAIN"}
            variant={"BOLD"}
            content={"Resource Type"}
          />
        </Box>
        {/* <Box>
          <Box sx={{ px: "12px" }}>
            {searchQuery.isFilter === false ? (
              <Box
                onClick={() =>
                  setSearchQuery({ ...searchQuery, isFilter: true })
                }
              >
                <CustomIconButton icon="search" varient="secondary" size="sm" />
              </Box>
            ) : (
              <Box>
                <SearchTextField
                  name={""}
                  label={""}
                  value={searchQuery.queryString}
                  adornment={true}
                  adornmentPosition={"start"}
                  adornmentValue={"search"}
                  disabled={false}
                  required={true}
                  error={false}
                  helperText={""}
                  placeholder={"Search"}
                  size={"small"}
                  changeHandler={updateSearch}
                  onReset={() => {
                    setSearchQuery((oldState) => ({
                      ...oldState,
                      isFilter: false,
                    }));
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <CustomButton
            varient={"primary"}
            size="sm"
            label="Type"
            disabled={true}
            isTrailingIcon={true}
            icon="plus"
            onClick={() => {
              setState({ ...state, modalOpen: true });
            }}
          />
        </Box> */}
      </Box>
    </React.Fragment>
  );
};

export default ResourcesTypeHeadMount;
