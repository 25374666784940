import React from "react";
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  useTheme,
  tooltipClasses,
  SxProps,
  Theme,
} from "@mui/material";

interface CustomTooltipProps extends TooltipProps {
  popperStyle?: SxProps<Theme>;
}
export const Tooltip = ({
  title,
  followCursor = false,
  placement = "top",
  children,
  popperStyle,
}: CustomTooltipProps) => {
  const theme = useTheme();
  return (
    <MuiTooltip
      title={title}
      placement={placement}
      followCursor={followCursor}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "#000",
            ...theme.typography["caption"],
            color: "#FFF",
          },
          ...popperStyle,
        },
      }}
    >
      {children}
    </MuiTooltip>
  );
};
