import { useQuery } from "@tanstack/react-query";
import { getAllTags } from "controller/api";
import { ApiErrorType, GetQueryProps } from "shared";
import { sentryLogger } from "utilz";

export const useGetAllTags = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["tags"],
    queryFn: () =>
      getAllTags({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllTags",
      }),
  });
};
