import { fetchCall } from "../../../utilz";
import { appConfig } from "../../../constants";

// Fetch MSP information by MSP-ID
const fetchMspById = (id) =>
  fetchCall(`/controlCenter/fetch/msp/${id}`, appConfig.requestMethods.GET);

// Fetch MSP billing details by MSP-ID
const fetchMspBillingDetailsById = (id) =>
  fetchCall("/controlCenter/msp_billing", appConfig.requestMethods.GET);

// Update MSP Details by MSP-ID
const updateMspById = (id, data) =>
  fetchCall(
    `/controlCenter/update/msp/${id}`,
    appConfig.requestMethods.PATCH,
    data
  );

// Update MSP Billing Details by MSP-ID
const updateMspBillingById = (id, data) =>
  fetchCall("/controlCenter/msp_billing", appConfig.requestMethods.PATCH, data);

export const mspServices = {
  fetchMspById,
  fetchMspBillingDetailsById,
  updateMspById,
  updateMspBillingById,
};
