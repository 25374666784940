import { fetchCall } from "../../utilz";
import { appConfig } from "../../constants";

// Fetch MSP information by MSP-ID
const getServer = ()=> 
  fetchCall(
    "",
    appConfig.requestMethods.GET
  );

export const server = {
  getServer
};