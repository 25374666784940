import { Box, useTheme } from "@mui/material";
import { PlusIcon } from "components";
import CustomIcon from "library/icon-library";
// import { MinusIcon, PlusIcon } from "components";
import PropTypes from "prop-types";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

export const AddRemoveIconButton = ({
  isDelete,
  onClick,
}: {
  isDelete?: boolean;
  onClick: () => void;
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider>
      <Box
        sx={{
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          border: `1px solid ${
            isDelete ? theme.palette.error.main : theme.palette.success.main
          }`,
        }}
        onClick={onClick}
      >
        {isDelete ? (
          <CustomIcon
            icon="minus"
            color={theme.palette.error.main}
            size={"lg"}
          />
        ) : (
          <CustomIcon
            icon="plus"
            color={theme.palette.success.main}
            size={"lg"}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};
