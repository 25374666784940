export const appConfig = {
  server: {
    baseUrl: import.meta.env["VITE_APP_BASE_URL"],
  },
  requestMethods: {
    GET: "get",
    POST: "post",
    PATCH: "patch",
    DELETE: "delete",
  },
  google_maps_api_key: import.meta.env["VITE_APP_GOOGLE_MAPS_API_KEY"],
  // msp_id: "07297868-585d-488c-87b5-22fdd1136f54",
  // customer_id: "683eb40d-47f2-4bca-a9c0-49d6276cfa94",
};
