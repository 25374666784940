import { Box, BoxProps, useTheme } from "@mui/material";
import React from "react";
import CustomIcon from "../../library/icon-library";
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos,
} from "@mui/icons-material";
interface PaginationButtonProps extends BoxProps {
  disabled?: boolean;
  type: "next" | "prev";
  onClick?: () => void;
}
export const PaginationButton = ({
  disabled = false,
  type,
  onClick,
}: PaginationButtonProps) => {
  const theme = useTheme();
  return (
    <Box
      component="button"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          disabled === true
            ? theme.palette.neutral["005"]
            : theme.palette.neutral.main,
        borderRadius: "50%",
        border: `1px solid ${theme.palette.neutral["010"]}`,
        padding: "16px",
        width: "32px",
        height: "32px",
        "&:hover": {
          backgroundColor:
            disabled === true
              ? theme.palette.neutral["005"]
              : theme.palette.purple.main,
          color:
            disabled === true
              ? theme.palette.lightBg.high
              : theme.palette.neutral.main,
          borderColor:
            disabled === true
              ? theme.palette.neutral["015"]
              : theme.palette.purple.main,
          cursor: disabled === true ? "default" : "pointer",
        },
      }}
      //   onMouseEnter={handleMouseEnter}
      //   onMouseLeave={handleMouseLeave}
      onClick={() => !disabled && onClick && onClick()}
    >
      {type === "next" ? (
        <ArrowForwardIos
          fontSize="small"
          sx={{ width: "12px", height: "12px", marginLeft: "2px" }}
        />
      ) : (
        <ArrowBackIos
          fontSize="small"
          sx={{ width: "12px", height: "12px", marginLeft: "4px" }}
        />
      )}
    </Box>
  );
};
