import React from "react";
import PropTypes from "prop-types";

// Legacy Imports
import Box from "@mui/material/Box";

// Custom Styles
import { designConfiguration } from "../../../constants";

const CustomBox = (props, {children})=> {
  const createCustomStyles = ()=> {
    try{
      const styles = {
        width: "100%",
        padding: {
          xs: designConfiguration?.spacing["SMALL"],
          md: designConfiguration?.spacing["MEDIUM"],
          lg: designConfiguration?.spacing["LARGE"],
        },
        gap: {
          xs: designConfiguration?.gutter["SMALL"],
          md: designConfiguration?.gutter["MEDIUM"],
          lg: designConfiguration?.gutter["LARGE"],
        },
      };
      if(props?.display === "block"){
        styles["display"] = "block";
      }
      if(props?.display === "flex"){
        styles["display"] = "flex";
        styles["alignItems"] = props?.orientation === "row"? "center": "flex-start";
        styles["justifyContent"] = props?.orientation === "row"? props?.isFlexEnd === true? "flex-end":"space-between": "center";
        styles["flexDirection"] = props?.orientation;
      }
      return styles;
    }catch(err) {
      console.log("[ERROR] Creating custom styles for Box");
      console.log(err);
    }
  };
  return(
    <React.Fragment>
      <Box
        sx={createCustomStyles()}
      >
        {props?.children}
      </Box>
    </React.Fragment>
  );
};

CustomBox.propTypes = {
  width: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,  
  isFlexEnd: PropTypes.bool.isRequired,      
};
CustomBox.defaultProps = {
  width: "",
  display: "",
  orientation: "",        
  isFlexEnd: false
};

export default CustomBox;