import { UpdateQaRulesInput } from "shared";
import { axiosClient } from "utilz";
import { apiErrorHandler, getMspId } from "utilz/helpers";

export const getQaRules = async () => {
  try {
    const url = `/configuration/fetch/qa_rules/${getMspId()}`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateServiceDeskQaRules = async ({
  type,
  data,
}: UpdateQaRulesInput) => {
  try {
    const url = `/configuration/update/qa_rules/${type}`;
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.updated_by;
    delete data.is_deleted;
    delete data.created_by;
    delete data.msp_id;
    const postData = { [type]: data };
    const response = await axiosClient.patch(`${url}`, postData);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
