
function CheckBoxCheckedIcon(props) {

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="3.25"
        fill={props.disabled ? "#CCCCCC" : props.varient === "primary" ? "url(#paint1_radial_6_191)" : "#5563F5"}
        stroke={props.disabled ? "#CCCCCC" : props.varient === "primary" ? "url(#paint1_radial_6_191)" : "#5563F5"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M20.1429 13L14.4328 18.7143L11 15.2871"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />


      <defs>
        <radialGradient id="paint0_radial_6_191" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.94079 9.3166) rotate(12.1814) scale(17.4561 705.459)">
          <stop stopColor="#4652CD" />
          <stop offset="1" stopColor="#46CBEA" />
        </radialGradient>
        <radialGradient id="paint1_radial_6_191" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.94079 9.3166) rotate(12.1814) scale(17.4561 705.459)">
          <stop stopColor="#4652CD" />
          <stop offset="1" stopColor="#46CBEA" />
        </radialGradient>
      </defs>
    </svg>


  );
}




export default CheckBoxCheckedIcon;