import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createServiceType,
  createServiceTypeSubtype,
  deleteServiceTypeSubtype,
  getServiceTypeSubtype,
  updateServiceTypeSubtype,
} from "controller/api";
import {
  ApiErrorType,
  CreateServiceTypeInput,
  CreateServiceTypeSubTypeInput,
  DeleteServiceTypeSubTypeInput,
  GetServiceTypeSubTypeInput,
  GetTypeSubtypeInput,
  UpdateServiceTypeSubTypeInput,
} from "shared";
import { sentryLogger } from "utilz";

export const useGetServiceTypeSubtype = ({
  serviceType,
}: GetTypeSubtypeInput) => {
  return useQuery({
    queryKey: ["attributes", serviceType, "type"],
    queryFn: () => getServiceTypeSubtype({ serviceType }),
    enabled: serviceType ? true : false,
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getServiceTypeSubtype",
      }),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useCreateServiceType = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: CreateServiceTypeInput) => createServiceType(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "attributes",
          variables.serviceType,
          "type",
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "createServiceType",
        });
      },
    }
  );
};

export const useCreateServiceTypeSubType = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: CreateServiceTypeSubTypeInput) => createServiceTypeSubtype(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "attributes",
          variables.serviceType,
          "type",
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "createServiceTypeSubtype",
        });
      },
    }
  );
};

export const useUpdateServiceTypeSubType = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: UpdateServiceTypeSubTypeInput) => updateServiceTypeSubtype(values),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "attributes",
          variables.serviceType,
          "type",
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateServiceTypeSubtype",
        });
      },
    }
  );
};

export const useDeleteServiceTypeSubType = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: DeleteServiceTypeSubTypeInput) => deleteServiceTypeSubtype(values),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "attributes",
          variables.serviceType,
          "type",
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "deleteServiceTypeSubtype",
        });
      },
    }
  );
};
