import {
  CreateTicketInput,
  GetQueryProps,
  MergeTicketInput,
  BulkAssignTicketInputProps,
  ChangeBillableStatusInput,
  UpdateTicketStatusInput,
  UpdateTicketPriorityInput,
  UpdateAdditionalTicketDetailsInput,
  DeleteTicketAttachmentInput,
  UpdateScheduleConnectTimeInput,
} from "shared";
import { axiosClient } from "utilz";
import {
  apiErrorHandler,
  combineDateAndTime,
  combineDateAndTimeV2,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

import axiosUploadCall from "utilz/axios-call";

export const getAllTickets = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });
    const url = await generateUrlString({
      baseUrl: "v2/servicedesk/tickets",
      data,
    });
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

// get ticket by id
export const getTicket = async (id: string) => {
  try {
    const url = `/v2/servicedesk/tickets/${id}`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};

export const createTicket = async ({
  isBillable,
  checklist,
  board,
  priority,
  status,
  summary,
  description,
  resources,
  urgency,
  workType,
  ticketType,
  ticketSubtype,
  source,
  impact,
  sla,
  customer,
  site,
  user,
  schedule,
  location,
  generalVariable,
  ticketVariable,
  budgetedHours,
  attachments,
  estimatedEndTime,
  tags,
  contract,
}: CreateTicketInput) => {
  const data = {
    ticket_summary: summary,
    ticket_description: description,
    board_id: board,
    priority: priority,
    is_billable: isBillable,
    ...(urgency && { urgency: urgency }),
    ...(workType && { work_type_id: workType }),
    ticket_type_id: ticketType,
    ticket_status: status,
    ticket_subtype_id: ticketSubtype,
    source_id: source,
    impact: impact,
    location_id: location,
    site_id: site,
    customer_id: customer,
    customer_user_id: user,
    budgeted_hours: budgetedHours,
    contract_id: contract,
    sla_id: sla,
    ...(generalVariable &&
      generalVariable.length > 0 && {
        general_variable_attributes: generalVariable,
      }),
    ...(ticketVariable &&
      ticketVariable.length > 0 && {
        ticket_variable_attributes: ticketVariable,
      }),
    checklists: checklist,
    attachments: attachments,
    estimated_end_date: estimatedEndTime,
    ...(tags && tags.length > 0 && { tags }),

    ...(schedule && schedule.length > 0 ? { schedulings: schedule } : {}),
  };

  try {
    const response = await axiosClient.post(
      `/servicedesk/create/ticket/${getMspId()}`,
      data
    );
    const resData = response.data;
    console.log(resData);
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const mergeTicket = async ({
  parentTcketId,
  mergeTickets,
}: MergeTicketInput) => {
  try {
    const url = `v2/servicedesk/merge/ticket/${parentTcketId}`;

    const response = await axiosClient.patch(`${url}`, { mergeTickets });
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const bulkAssignTickets = async ({
  service_id,
  resource_id,
  note,
}: BulkAssignTicketInputProps) => {
  try {
    const url = "/servicedesk/ticket/update/assign/bulk";
    const response = await axiosClient.patch(`${url}`, {
      service_id,
      resource_id,
      note,
    });
    const resp_data = response?.data;
    if (
      resp_data &&
      resp_data?.success === true &&
      resp_data?.statusCode === 200
    ) {
      return resp_data?.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const bulkModifyTickets = async (data: any) => {
  try {
    const url = "/servicedesk/bulk/ticket_details";
    const response = await axiosClient.patch(`${url}`, data);
    const resp_data = response?.data;
    if (
      resp_data &&
      resp_data?.success === true &&
      resp_data?.statusCode === 200
    ) {
      return resp_data?.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const changeBillableStatus = async ({
  ticketId,
  newValue,
}: ChangeBillableStatusInput) => {
  try {
    const url = `/servicedesk/update/ticket/${ticketId}/is_billable/${newValue}`;

    const response = await axiosClient.patch(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateTicketStatus = async ({
  ticketId,
  statusId,
}: UpdateTicketStatusInput) => {
  try {
    const url = `/servicedesk/update/ticket/${ticketId}/status/${statusId}`;

    const response = await axiosClient.patch(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateTicketPriority = async ({
  ticketId,
  priority,
}: UpdateTicketPriorityInput) => {
  try {
    const url = `/servicedesk/update/ticket/${ticketId}/proirity/${priority}`;

    const response = await axiosClient.patch(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const uploadTicketAttachments = ({
  formData,
  serviceType = "ticket",
  attachmentType = "general",
}: {
  formData: FormData;
  serviceType: "ticket" | "project" | "task";
  attachmentType: "general" | "notes";
}) =>
  axiosUploadCall(
    `/v2/servicedesk/attachments/${serviceType}/${attachmentType}`,
    formData
  );
export const deleteTicketAttachments = async ({
  attachmentId,
}: DeleteTicketAttachmentInput) => {
  const data = {
    attachment_ids: attachmentId,
  };
  try {
    const url = "/servicedesk/delete/ticket/attachments";
    const response = await axiosClient.delete(`${url}`, { data });
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateTicketDetails = async ({
  ticketSummary,
  ticketDescription,
  ticketId,
  workType,
  workRole,
  source,
  budgetedHours,
  estimatedEndDate,
  ticketType,
  ticketSubtype,
  location,
  ticketVariableAttribute,
  generalVariableAttribute,
  tags,
  ticketOwner,
  isSlaExpired,
  customerId,
  siteId,
  userId,
  board,
  urgency,
  impact,
  sla,
  attachments,
}: UpdateAdditionalTicketDetailsInput) => {
  try {
    const data = {
      ...(ticketSummary && ticketSummary !== null
        ? { ticket_summary: ticketSummary }
        : {}),
      ...(ticketDescription && ticketDescription !== null
        ? { ticket_description: ticketDescription }
        : {}),
      ...(ticketOwner && ticketOwner !== null
        ? { ticket_owner: ticketOwner }
        : {}),
      ...(workType && workType !== null ? { work_type: workType } : {}),
      ...(workRole && workRole !== null ? { work_role: workRole } : {}),
      ...(source && source !== null ? { source: source } : {}),
      ...(location && location !== null ? { location: location } : {}),
      ...(budgetedHours && budgetedHours !== null
        ? { budgeted_hours: budgetedHours }
        : {}),
      ...(estimatedEndDate && estimatedEndDate !== null
        ? { estimated_end_date: estimatedEndDate }
        : {}),
      ...(ticketType && ticketType !== null
        ? { ticket_type_id: ticketType }
        : {}),
      ...(ticketSubtype && ticketSubtype !== null
        ? { ticket_subtype_id: ticketSubtype }
        : {}),
      ...(ticketVariableAttribute && ticketVariableAttribute !== null
        ? { ticket_variable_attributes: ticketVariableAttribute }
        : {}),
      ...(generalVariableAttribute && generalVariableAttribute !== null
        ? { general_variable_attributes: generalVariableAttribute }
        : {}),
      ...(tags && tags !== null ? { tags: tags } : {}),
      ...(isSlaExpired && isSlaExpired !== null
        ? { is_expired_sla: isSlaExpired }
        : {}),
      ...(customerId && customerId !== null ? { customer_id: customerId } : {}),
      ...(siteId && siteId !== null ? { site_id: siteId } : {}),
      ...(userId && userId !== null ? { customer_user_id: userId } : {}),
      ...(board && board !== null ? { board: board } : {}),
      ...(urgency && urgency !== null ? { urgency: urgency } : {}),
      ...(impact && impact !== null ? { impact: impact } : {}),
      ...(sla && sla !== null ? { sla: sla } : {}),
      ...(attachments && attachments !== null
        ? { attachments: attachments }
        : {}),
    };

    if (Object.entries(data).length <= 0) {
      return "";
    }

    const url = `/servicedesk/update/ticket_details/${ticketId}`;

    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateScheduleConnectTime = async ({
  connectTimeFrom,
  connectTimeTo,
}: UpdateScheduleConnectTimeInput) => {
  try {
    const data = {
      connect_time_from: connectTimeFrom,
      connect_time_to: connectTimeTo,
    };

    const url = "/servicedesk/create/schedule/:msp_id/:ticket_id";
    const response = await axiosClient.post(url, data);

    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getTicketQaStatus = async (ticketId: string) => {
  try {
    const url = `${
      import.meta.env["VITE_APP_QA_BASE_URL"]
    }/qa_board/ticket/qa_health?service_id=${ticketId}`;
    const response = await axiosClient.get(url);
    const resData = response?.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const addProductToTicket = async (data: any) => {
  try {
    const url = `/v1/products/${data.service_type}/${data.service_id}`;
    const response = await axiosClient.post(url, data?.productAndExpenseData);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const addExpenseToTicket = async (data: any) => {
  try {
    const url = `/v1/expense/${data.service_type}/${data.service_id}`;
    const response = await axiosClient.post(url, data?.productAndExpenseData);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
