function syncIcon(props) {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3334 21.0034C15.2648 21.0036 17.9617 19.4013 19.3633 16.8267"  stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.3367 13.0002C20.3367 8.58004 16.7535 4.99683 12.3334 4.99683" stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3334 4.99683C9.40201 4.99658 6.70504 6.59895 5.30347 9.17357" stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.33002 13C4.33002 17.4201 7.91323 21.0033 12.3334 21.0033" stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.30347 9.17359C7.05294 5.95857 10.7605 4.34006 14.3076 5.24295C17.8547 6.14583 20.3373 9.33999 20.3367 13.0002" stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.33002 13C4.32946 16.6602 6.81204 19.8544 10.3591 20.7572C13.9062 21.6601 17.6138 20.0416 19.3633 16.8266" stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.3787 9.17346H4.84222V5.63599" stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.288 16.8267H19.8245V20.3641" stroke={props.disabled === true ? props.disabledcolor : props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
export default syncIcon;