export const tableDesigns = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  p: 0, m: 0,
  textAlign: "left",
  borderBottom: "none",
  "& .MuiTableRow-root": {
    width: "100%",
    p: 0,
    m: 0,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #E3E3E3",
    textOverflow: "ellipsis",
    alignContent: "center",
    height: {
      xs: "48px", md: "52px", xl: "56px",
    },
  },
  "& .MuiTableCell-root": {
    border: "none",
    textOverflow: "ellipsis",
    p: "0px",
    display: "inline-flex",
    flexDirection: "row",
    position: "relative",
    justifyContent: "normal",
    flexWrap: "nowrap",
    alignItems: "center",
    alignContent: "normal",
    gap: "8px",
  },
  "& .MuiTableHead-root": {
    width: "100%",
    p: 0,
    m: 0,
  },
  "& .MuiTableBody-root": {
    width: "100%",
    p: 0,
    m: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
};