import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useGlobalStore } from "controller/store/globalStore";
import { ResourceMemberTile } from "./ResourceMemberTile";
import { ResourceMemberChip } from "./ResourceMemberChip";
import { MultiSelectSearchTextField } from "components";
import { useGetAllResources } from "controller";
import { getFullName } from "utilz/helpers";
import { v1 } from "uuid";

interface ResourceGroupMembersProps {
  groupMembers: Array<any>;
  setGroupMembers: (members: Array<any>) => void;
}
export const ResourceGroupMembers = ({
  groupMembers,
  setGroupMembers,
}: ResourceGroupMembersProps) => {
  const mspId = useGlobalStore((state) => state.mspId);
  const { data: members } = useGetAllResources({});

  const dataChangeHandler = (data: { field: string; value: any }) => {
    setGroupMembers(data.value);
  };

  const deleteMemberHandler = (data: any) => {
    const index = groupMembers.findIndex((item) => item.value === data.value);
    const tempData = groupMembers;
    tempData.splice(index, 1);
    setGroupMembers(tempData);
  };

  const clearAllMmbers = () => {
    setGroupMembers([]);
  };

  return (
    <Stack gap={"24px"} sx={{ my: "24px", px: "24px" }}>
      <MultiSelectSearchTextField
        key={v1()}
        name="group_members"
        options={
          Array.isArray(members?.result)
            ? members.result.map((item: any) => ({
                key: `resource-and-permission-resource-type-${item?.mapping_id}-key`,
                value: item?.mapping_id,
                profile: item?.profile_image,
                subLabel: item?.resource_work_roles,
                label:
                  getFullName(
                    item?.first_name,
                    item?.middle_name,
                    item?.last_name
                  ) || "",
              }))
            : []
        }
        selectedOptions={groupMembers || []}
        cancelLabel={"Clear all"}
        submitLabel={"Add selected members"}
        onAddSelection={dataChangeHandler}
        onClear={clearAllMmbers}
        renderOption={(
          props: any,
          option: any,
          { selected = false }: { selected: boolean }
        ) => (
          <li
            {...props}
            style={{
              borderBottom: "1px solid #BFC4CD99",
              backgroundColor: "white",
            }}
          >
            <ResourceMemberTile data={option} isSelected={selected} />
          </li>
        )}
      />

      <Typography variant="body-medium">Members added</Typography>
      <Grid
        container
        {...{ lg: 12 }}
        spacing={"16px"}
        sx={{
          minHeight: "150px",
        }}
      >
        {groupMembers
          ? groupMembers.map((item: any, index: number) => (
              <Grid
                key={`group_member_index_${index}`}
                item
                {...{ xs: 6, sm: 4, md: 4, lg: 4, xl: 4 }}
              >
                <ResourceMemberChip
                  data={item}
                  onDelete={deleteMemberHandler}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </Stack>
  );
};
