import { SvgIcon, SvgIconProps } from "@mui/material";

export const BellIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M13.6 20h-3.2"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M17 10.032V10v0a5 5 0 0 0-5-5v0a5 5 0 0 0-5 5v2.504a.855.855 0 0 1-.472.764l-.503.251A1.857 1.857 0 0 0 5 15.178v0c0 1.024.83 1.854 1.854 1.854h10.292c1.024 0 1.854-.83 1.854-1.854v0c0-.702-.397-1.344-1.025-1.658l-.503-.251a.857.857 0 0 1-.472-.765v-2.472Z"
        clip-rule="evenodd"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M18.951 6.049a8.029 8.029 0 0 0-3-3M5.049 6.049a8.029 8.029 0 0 1 3-3"
      />
    </svg>
  </SvgIcon>
);
