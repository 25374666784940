import { create } from "zustand";
import {
  createTicketFilterSlice,
  TicketFilterInterface,
  createTicketStoreSlice,
  CreateTicketInterface,
  SelectTicketInterface,
  selectTicketsSlice,
  ticketDetailsSlice,
  TicketDetailsInterface,
} from "./ticket";
import { TimesheetInterface, timesheetSlice } from "./timesheet";
import { UserInterface, userSlice } from "./customer";

import { persist, createJSONStorage } from "zustand/middleware";
import { HomeScreenInterface, homeScreenSlice } from "./homescreen";
import { qaBoardFilterSlice, QaBoardFilterInterface } from "./qaBoard";
import {
  CreateProjectModalInterface,
  CreateProjectModalSlice,
  ProjectTableInterface,
  projectTableSlice,
} from "./project";
import { CommonDataInterface, CommonDataSlice } from "./common";
import {
  billingTemplateTableInterface,
  billingTemplateTableSlice,
} from "./billingConfiguration";

export const useMainStore = create<
  TicketFilterInterface &
    CreateTicketInterface &
    SelectTicketInterface &
    TicketDetailsInterface &
    UserInterface &
    HomeScreenInterface &
    QaBoardFilterInterface &
    CreateProjectModalInterface &
    ProjectTableInterface &
    CommonDataInterface &
    TimesheetInterface &
    billingTemplateTableInterface
>()(
  persist(
    (...a) => ({
      ...createTicketFilterSlice(...a),
      ...createTicketStoreSlice(...a),
      ...selectTicketsSlice(...a),
      ...ticketDetailsSlice(...a),
      ...userSlice(...a),
      ...homeScreenSlice(...a),
      ...qaBoardFilterSlice(...a),
      ...CreateProjectModalSlice(...a),
      ...projectTableSlice(...a),
      ...CommonDataSlice(...a),
      ...timesheetSlice(...a),
      ...billingTemplateTableSlice(...a),
    }),
    {
      name: "main-store",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
