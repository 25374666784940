import { SvgIcon, SvgIconProps } from "@mui/material";

export const ComputerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.311"
        d="M18.74 5H5.638A1.64 1.64 0 0 0 4 6.638v8.19a1.64 1.64 0 0 0 1.638 1.639H18.74a1.64 1.64 0 0 0 1.638-1.638v-8.19A1.64 1.64 0 0 0 18.74 5ZM8.915 19.74h6.552m-3.277-3.276v3.276"
      />
    </svg>
  </SvgIcon>
);
