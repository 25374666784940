import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { CustomCard } from "../../../../../../../library/layers";
import PropTypes from "prop-types";
import { CustomTypography } from "../../../../../../../library/base";
import { DeleteIcon, EditIcon } from "components";

interface SlaTileProps {
  title: string;
  details: string;
  isDefault: boolean;
  onDelete: () => void;
  onEdit: () => void;
}
export const SlaTiles = ({
  details,
  isDefault,
  onDelete,
  onEdit,
  title,
}: SlaTileProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <CustomCard
        spacing="24px"
        content={
          <Stack
            gap={"12px"}
            width={"100%"}
            height={"100%"}
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              flexFlow: "1",
              flexShrink: "1",
            }}
          >
            <Typography variant="body-medium">{title}</Typography>

            <Box
              sx={{
                width: "100%",
                height: "120px",
                display: "block",
                textOverflow: "ellipsis",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Typography
                variant="body-small"
                color={theme.palette.lightBg.low}
              >
                {details}
              </Typography>
            </Box>
            <Stack direction={"row-reverse"} width={"100%"}>
              {!isDefault && (
                <>
                  <IconButton
                    disabled={isDefault}
                    onClick={onEdit}
                    sx={{
                      width: "48px",
                      height: "48px",
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={onDelete}
                    sx={{
                      width: "48px",
                      height: "48px",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </Stack>
          </Stack>
        }
      />
    </Box>
  );
};
