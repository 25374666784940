import { styled } from "@mui/material";
import { TabUnstyled, tabUnstyledClasses } from "@mui/base";
const Tab = styled(TabUnstyled)`
  color: #767B85;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  padding: 16px 0px;
  margin-left: 32px;
  border: none;
  border-bottom : 4px solid transparent;

  &.${tabUnstyledClasses.selected} {
    border-bottom : 4px solid #5563F5;
    color: #5563F5;
    font-weight: 500;
  }
`;

export default Tab;