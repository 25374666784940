import { Box, Divider } from "@mui/material";

function TableSpaceFillers(props) {
  var menuItems = [];
  for (var i = 0; i < 20; i++) {

    menuItems.push(<Divider
      key={`space_filler_${i}`} sx={{
        marginTop: "52px",
      }} />);
  }

  return (<Box style={{ minWidth: "100%", overflow: "hidden", }}>
    {menuItems}

  </Box>);
}



export default TableSpaceFillers;
