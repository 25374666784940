import { Stack } from "@mui/material";
import { Input, Modal } from "components";

export const ResourceTypeModal = ({
  data,
  isOpen = false,
  onClose = () => {},
}: {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      open={isOpen}
      primaryButtonText="Save"
      isPrimaryButtonDisabled={true}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
      onClose={onClose}
      title="Resource Type"
      maxWidth="md"
    >
      <Stack m={"16px"}>
        <Input
          name="resource_type_name"
          label="Resource type"
          value={data?.resource_type_name || ""}
          disabled={true}
        />
        <Input
          multiline={true}
          minRows={4}
          maxRows={6}
          isResponsive={false}
          label="Description"
          name="resource_type_description"
          value={data?.resource_type_description || ""}
          disabled={true}
        />
      </Stack>
    </Modal>
  );
};
