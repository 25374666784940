import { GetNotificationsInput, UpdateNotificationStatusInput } from "shared";
import { axiosClient, fetchCall } from "utilz";
import { generateUrlString, getQueryDataObject } from "utilz/helpers";
const BASE_URL = import.meta.env.VITE_APP_NOTIFICATION_BASE_URL;
export const getAllNotifications = async ({
  resourceId,
  pageNumber,
  perPageCount,
}: GetNotificationsInput) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
    });

    // @ts-ignore
    delete data?.filters;

    const url = await generateUrlString({
      baseUrl: `${BASE_URL}/fetch/notifications/${resourceId}`,
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }

  // console.log(response.data);
};

export const updateNotificationStatus = async ({
  resourceId,
  notificationId,
}: UpdateNotificationStatusInput) => {
  try {
    const url = `${BASE_URL}/update/notification/seen/${resourceId}/${notificationId}`;
    // axiosClient.defaults.baseURL =
    //   import.meta.env["VITE_APP_NOTIFICATION_BASE_URL"];
    const response = await axiosClient.post(`${url}`);
    const resData = response.data;
    // axiosClient.defaults.baseURL = import.meta.env["VITE_APP_BASE_URL"];
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }

  // console.log(response.data);
};

export const getAllChatNotifications = async ({
  resourceId,
  pageNumber,
  perPageCount,
}: GetNotificationsInput) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
    });
    // @ts-ignore
    delete data?.filters;
    const url = await generateUrlString({
      baseUrl: `${BASE_URL}/v2/fetch/chat/notifications/${resourceId}`,
      data,
    });

    // const url = `${BASE_URL}/fetch/chat/notifications/${resourceId}?limit=50`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }

  // console.log(response.data);
};

export const updateChatNotificationStatus = async ({
  resourceId,
  notificationId,
}: UpdateNotificationStatusInput) => {
  try {
    const url = `${BASE_URL}/update/chat/notification/seen/${resourceId}/${notificationId}`;
    // axiosClient.defaults.baseURL =
    //   import.meta.env["VITE_APP_NOTIFICATION_BASE_URL"];
    const response = await axiosClient.post(`${url}`);
    const resData = response.data;
    // axiosClient.defaults.baseURL = import.meta.env["VITE_APP_BASE_URL"];
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }

  // console.log(response.data);
};

export const clearAllNotifications = async (notificationType: string) => {
  try {
    const url = `${BASE_URL}/notifications/clear/${notificationType}`;

    const response = await axiosClient.delete(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const markAllNotificationsAsSeen = async (notificationType: string) => {
  try {
    const url = `${BASE_URL}/v1/notifications/see/${notificationType}`;
    const response = await axiosClient.patch(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
