import { Grid, Stack } from "@mui/material";
import { Input } from "components";
import { AddRemoveIconButton } from "./AddRemoveIconButton";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

interface CertificateComponentProps {
  certificateData: any;
  onDataChange: (data: any) => void;
  onDelete: () => void;
  isLastElement: boolean;
  validate: boolean;
}
export const CertificateComponent = ({
  certificateData,
  onDataChange,
  onDelete,
  isLastElement,
  validate = false,
}: CertificateComponentProps) => {
  const onValueChange = (key: string, value: string) => {
    onDataChange({
      ...certificateData,
      [key]: value,
    });
  };
  const [errors, setErrors] = useState({
    certificate: false,
    version: false,
  });

  useEffect(() => {
    if (validate) {
      setErrors({
        certificate: !(
          certificateData?.certification_name &&
          certificateData?.certification_name !== null &&
          certificateData?.certification_name !== ""
        ),
        version: !(
          certificateData?.certification_version &&
          certificateData?.certification_version !== null &&
          certificateData?.certification_version !== ""
        ),
      });
    } else {
      setErrors({
        certificate: false,
        version: false,
      });
    }
  }, [validate]);

  // Renderer
  return (
    <Stack direction={"row"} gap={"32px"} justifyContent={"space-between"}>
      <Grid
        container
        flex={1}
        maxWidth={"646px"}
        columnSpacing={"32px"}
        rowSpacing={"32px"}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Input
            name="certificate"
            label={"Certificate"}
            fullWidth
            value={certificateData?.certification_name}
            onChange={(event) =>
              onValueChange("certification_name", event.target.value)
            }
            error={validate && errors.certificate}
            errorMessage={
              validate && errors.certificate
                ? "Certificate is required"
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Input
            name="version"
            label={"Version"}
            fullWidth
            value={certificateData?.certification_version}
            onChange={(event) =>
              onValueChange("certification_version", event.target.value)
            }
            error={validate && errors.version}
            errorMessage={
              validate && errors.version ? "Version is required" : undefined
            }
          />
        </Grid>
      </Grid>
      {isLastElement &&
      (certificateData.certification_name === "" ||
        certificateData.certification_name === null) &&
      (certificateData.certification_version === "" ||
        certificateData.certification_version === null) ? null : (
        <AddRemoveIconButton isDelete onClick={onDelete} />
      )}
    </Stack>
  );
};
