import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import {
  ChatIcon,
  DownArrowIcon,
  EditListIcon,
  GlobeCheckmarkIcon,
  LockIcon,
  Tooltip,
  UserIcon,
  ViewIcon,
  LogIcon,
  TimelineDrawer,
  CloseIcon,
} from "components";
import { useMainStore } from "controller/store/mainStore";
import React from "react";
import { AccessSpecifier, Assignees } from "shared/types";
import { dayjs } from "utilz";
import { AssigneesBlock } from "./AssigneesBlock";
const NotesMenuList = [
  {
    title: "all",
    tagline: "View all conversations",
    icon: <ChatIcon fontSize="small" />,
  },
  {
    title: "private",
    tagline: "View all private conversations",
    icon: <LockIcon fontSize="small" />,
  },
  {
    title: "public",
    tagline: "View all public conversations",
    icon: <GlobeCheckmarkIcon fontSize="small" />,
  },

  {
    title: "self",
    tagline: "View all self conversations",
    icon: <UserIcon fontSize="small" />,
  },
  {
    title: "resolution",
    tagline: "View all Resolution conversations",
    icon: <EditListIcon fontSize="small" />,
  },
];

export const ChatHeader = ({
  onFilterSelect,
  lastUpdated,
  serviceType,
  serviceId,
  section,
  onClose,
  assignees,
}: {
  onFilterSelect?: (filter?: AccessSpecifier) => void;
  lastUpdated?: string;
  serviceType: string;
  serviceId: string;
  onClose: () => void;
  assignees: Array<Assignees>;
  section: "serviceDetails" | "messageBar";
}) => {
  const [isTimelineOpen, setIsTimelineOpen] = React.useState(false);
  const theme = useTheme();

  // const assignees = useMainStore((state) => state.assignees);
  const [filterAccessSpecifier, setFilterAccessSpecifier] = React.useState<
    AccessSpecifier | undefined
  >();

  //
  const [notesAnchorEl, setNotesAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(notesAnchorEl);
  const handleNotesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotesAnchorEl(event.currentTarget);
  };

  const handleNotesMenuClose = () => {
    setNotesAnchorEl(null);
  };

  const handleNotesMenuOptionSelect = (
    type?: "public" | "private" | "self" | "all" | "resolution"
  ) => {
    // @ts-ignore
    setFilterAccessSpecifier(type);
    // @ts-ignore

    onFilterSelect && onFilterSelect(type);

    setNotesAnchorEl(null);
  };

  const getAssigneeName = () => {
    let names = "";
    assignees.map((assignee, index) => {
      names = `${names}${index !== 0 ? "," : ""} ${
        assignee.display_name
          ? assignee.display_name + " "
          : `${assignee.first_name && assignee.first_name} ${
              assignee.last_name && assignee.last_name
            } `
      }`;
    });
    return names;
  };
  return (
    <>
      <Stack
        px="24px"
        py="16px"
        sx={{ backgroundColor: "#FFF", flexShrink: 0 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* header */}
        <Stack direction="row" gap="8px">
          <AssigneesBlock assignees={assignees} />

          <Stack>
            <Typography
              maxWidth="200px"
              variant="subtitle1"
              noWrap
              textOverflow="ellipsis"
              sx={{ textTransform: "capitalize" }}
            >
              {getAssigneeName()}
            </Typography>
            <Typography variant="caption-3" color={theme.palette.lightBg.low}>
              Last updated {dayjs(lastUpdated).fromNow()}
            </Typography>
          </Stack>
        </Stack>

        {/* icons */}
        <Stack direction="row" gap="12px" alignItems={"center"}>
          {/* ticket timeline button */}
          {section === "serviceDetails" && (
            <IconButton
              onClick={() => setIsTimelineOpen(true)}
              sx={{
                padding: "0px",
                ":hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <LogIcon htmlColor={theme.palette.lightBg.medium} />
            </IconButton>
          )}
          {/* conversation filter button */}
          {serviceType === "ticket" && (
            <>
              <Tooltip title="View Conversations" placement="top">
                <IconButton
                  id="notes-button"
                  aria-controls={open ? "notes-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleNotesMenuClick}
                  sx={{
                    border: `1px solid ${theme.palette.neutral["020"]}`,
                    borderRadius: "4px",
                    color: theme.palette.lightBg.high,
                    height: "24px",
                    padding: "4px 4px 4px 8px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    gap={"4px"}
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Stack direction={"row"} gap={"6px"} alignItems={"center"}>
                      <ViewIcon fontSize="small" />
                      <Typography
                        color={theme.palette.lightBg.high}
                        variant="body-small"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {filterAccessSpecifier ? filterAccessSpecifier : "all"}
                      </Typography>
                    </Stack>
                    <DownArrowIcon fontSize="small" />
                  </Stack>
                </IconButton>
              </Tooltip>
              <Menu
                id="notes-menu"
                anchorEl={notesAnchorEl}
                open={open}
                onClose={handleNotesMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                MenuListProps={{
                  "aria-labelledby": "notes-button",
                }}
              >
                {NotesMenuList?.map((menu, index) => (
                  <Box sx={{ minWidth: "260px" }} key={menu.title}>
                    <MenuItem
                      sx={{
                        my: "6px",
                        mx: "12px",
                        py: "8px",
                        px: "10px",
                        textTransform: "capitalize",
                        border: `1px solid ${theme.palette.neutral["005"]}`,
                        borderRadius: "8px",
                      }}
                      key={menu.title}
                      selected={menu.title === filterAccessSpecifier}
                      onClick={() =>
                        handleNotesMenuOptionSelect(
                          // @ts-ignore
                          menu.title
                        )
                      }
                    >
                      <Stack
                        direction={"row"}
                        gap={"12px"}
                        alignItems={"center"}
                      >
                        <Box
                          sx={{
                            width: "32px",
                            height: "32px",
                            backgroundColor: "#E7E9FE",
                            borderRadius: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: theme.palette.purple.main,
                          }}
                        >
                          {menu.icon}
                        </Box>
                        <Stack>
                          <Typography variant="body-medium">
                            {menu.title}
                          </Typography>
                          <Typography
                            variant="body-small"
                            color={theme.palette.lightBg.low}
                          >
                            {menu.tagline}
                          </Typography>
                        </Stack>
                      </Stack>
                    </MenuItem>
                    {/* {index < NotesMenuList.length - 1 && <Divider />} */}
                  </Box>
                ))}
              </Menu>
            </>
          )}
          {section === "messageBar" && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {section === "serviceDetails" && isTimelineOpen && (
        <TimelineDrawer
          serviceId={serviceId}
          serviceType={serviceType as "ticket" | "project"}
          onClose={() => setIsTimelineOpen(false)}
          open={isTimelineOpen}
        />
      )}
    </>
  );
};
