import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  BellIcon,
  DollarCashIcon,
  GeneralSettingsIcon,
  LocationPinIcon,
  ShieldCheckmarkIcon,
  TriangleSelectIcon,
} from "components";
import { ClearSampleDataButton } from "layouts/component/top-bar/ClearSampleDataButton";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const CardList = [
  {
    name: "Resources",
    description:
      "Update your company's primary details and general settings in this section.",
    icon: <GeneralSettingsIcon className="setting-icon" />,
    url: "/app/control-center/resources-and-permissions/resources",
    isNew: false,
  },
  {
    name: "Resource Groups",
    description:
      "Provide your company's location details in this section, including your Location name, Address...",
    icon: <LocationPinIcon className="setting-icon" />,
    url: "/app/control-center/resources-and-permissions/resource-groups",
    isNew: false,
  },
  {
    name: "Resource Types",
    description:
      "Establish and customize your ticket channel settings by setting up channels for Email, Agent..",
    icon: <TriangleSelectIcon className="setting-icon" />,
    url: "/app/control-center/resources-and-permissions/resource-types",
    isNew: false,
  },
];
export const ResourcesHomeComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        px: "32px",
        py: "28px",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4">Resources And Permissions</Typography>
      </Stack>

      <Stack mt={"40px"} direction={"row"} gap="24px" flexWrap="wrap">
        {CardList.map((item, index) => (
          <Stack
            key={`resources_${item.name}`}
            onClick={() => {
              navigate(item.url);
            }}
            p="24px"
            sx={{
              position: "relative",
              aspectRatio: "1/1",
              width: "234px",
              border: `1px solid ${theme.palette.neutral["015"]}`,
              borderRadius: "12px",
              cursor: "pointer",
              transition: "all 0.5s ease",
              backgroundColor: "#FFF",
              justifyContent: "space-between",
              ":hover": {
                border: `1px solid ${theme.palette.purple.main}`,
                backgroundColor: "#EBFCFE",
                "& .setting-icon": {
                  color: "#FFF",
                },
                "& .setting-box": {
                  backgroundColor: theme.palette.purple.main,
                },
              },
            }}
          >
            <Box>
              <Stack direction={"row"} gap="12px" alignItems="center">
                <Typography variant="h6">{item.name}</Typography>
                {item.isNew && (
                  <Stack
                    sx={{
                      bgcolor: "#BAF4FC",
                      padding: "2px 6px 3px 6px",
                      borderRadius: "12px",
                    }}
                  >
                    <Typography
                      variant="caption-3"
                      color={theme.palette.lightBg.high}
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      New
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Typography
                mt={"8px"}
                variant="body-small"
                color={theme.palette.lightBg.low}
              >
                {item.description}
              </Typography>
            </Box>

            <Stack
              className="setting-box"
              sx={{
                mt: "32px",
                alignSelf: "flex-end",
                width: "48px",
                height: "48px",
                borderRadius: "12px",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#E7E9FE",
                transition: "all 0.5s ease",
                "& .setting-icon": {
                  color: theme.palette.purple.main,
                  width: "32px",
                  height: "32px",
                },
              }}
            >
              {item.icon}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
