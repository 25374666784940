import {
  CreateCannedNoteInput,
  GetQueryProps,
  UpdateCannedNoteInput,
} from "shared";
import { axiosClient, fetchCall } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const getAllCannedNotes = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: `/configuration/fetch/canned_notes/msp/${getMspId()}`,
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createCannedNote = async ({
  data,
  mspId,
}: CreateCannedNoteInput) => {
  try {
    const response = await axiosClient.post(
      `/configuration/create/canned_notes/${mspId}`,
      data
    );
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateCannedNote = async ({
  data,
  note_id,
}: UpdateCannedNoteInput) => {
  try {
    const response = await axiosClient.patch(
      `/configuration/update/canned_notes/${note_id}`,
      data
    );
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteCannedNotes = async (cannedNoteIds: string[]) => {
  try {
    const url = "/v2/configuration/canned-notes";
    const response = await axiosClient.delete(`${url}`, {
      data: {
        cannedNotes: cannedNoteIds,
      },
    });
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
