import {
  GetRemoteDeviceListInput,
  UpdateIntegrationStatusInput,
} from "shared/types/integrations";
import { axiosClient } from "utilz";

const BASE_URL = import.meta.env.VITE_APP_INTEGRATION_BASE_URL;

export const getIntegrationSources = async () => {
  try {
    const url = `${BASE_URL}/v1/list/integration/sources`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    //  TODO: add statusCode check
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateIntegrationStatus = async ({
  source,
  status,
}: UpdateIntegrationStatusInput) => {
  try {
    const url = `${BASE_URL}/${source}/msp/configuration/status/${status}`;

    const response = await axiosClient.patch(`${url}`);
    const resData = response.data;
    //  TODO: add statusCode check
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getRemoteDevicesList = async ({
  customerUserId,
  sourceName,
}: GetRemoteDeviceListInput) => {
  try {
    const url = `${BASE_URL}/v1/list/customer-user/remote-devices/${customerUserId}/${sourceName}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    //  TODO: add statusCode check
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
