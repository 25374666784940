import { default as MuiButton, ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { CustomButtonProps } from "./Button";

interface ButtonRootProps
  extends Omit<CustomButtonProps, "variant">,
    ButtonProps {}
export const ButtonRoot = styled(MuiButton)<ButtonRootProps>(
  ({
    theme,
    variant,
    size,
    startIcon,
    endIcon,
    color,
    isIconOnly,
    icon,
    loading,
    ...rest
  }) => {
    const { palette, typography } = theme;
    const isButtonWIthIcon = startIcon || endIcon ? true : false;
    const getIconButtonPadding = () => {
      let padding, height, width;
      switch (size) {
        case "large":
          padding = "10px";
          height = "48px";
          width = "48px";
          break;
        case "medium":
          padding = "8px";
          height = "40px";
          width = "40px";
          break;
        case "small":
          padding = "5px";
          height = "32px";
          width = "32px";
          break;
      }
      return {
        padding,
        height,
        width,
        minWidth: "0px",
        "&.MuiButtonRoot > span": {
          fontSize: "18px",
        },
      };
    };

    // get padding based on size and if icon is present
    const getPadding = () => {
      let padding;
      let minWidth;
      let minHeight;
      switch (size) {
        case "large":
          padding = isButtonWIthIcon
            ? endIcon
              ? "12px 12px 12px 16px"
              : "12px 20px 12px 16px"
            : "14px 20px 14px 16px";

          minWidth = "110px";
          minHeight = "48px";
          break;

        case "medium":
          padding = isButtonWIthIcon
            ? endIcon
              ? "8px 12px 8px 16px"
              : "8px 16px 8px 12px"
            : "10px 16px 10px 12px";

          minWidth = "102px";
          minHeight = "40px";
          break;

        case "small":
          padding = isButtonWIthIcon
            ? endIcon
              ? "4px 4px 4px 12px"
              : "4px 16px 4px 12px"
            : "6px 16px 6px 12px";
          minWidth = "102px";
          minHeight = "32px";

          break;

        default:
          break;
      }

      return {
        padding,
        minWidth,
        minHeight,
      };
    };

    const containedStyle = () => {
      return {
        backgroundColor: palette.purple.main,
        borderColor: palette.purple.main,
        color: palette.neutral.main,
        "&:hover": {
          backgroundColor: palette.purple.hover,
          borderColor: palette.purple.hover,
        },
        "&:active": {
          backgroundColor: palette.purple.pressed,
          borderColor: palette.purple.pressed,
        },
        "&.Mui-disabled": {
          backgroundColor: palette.darkBg.medium,
          borderColor: palette.darkBg.medium,
          color: palette.darkBg.low,
        },
      };
    };

    // TODO: style disabled -- add theme colors neutrals
    const outlinedStyle = () => {
      return {
        backgroundColor: "#FFF",
        borderColor: palette.neutral["020"],
        color: palette.lightBg.high,
        "&:hover": {
          backgroundColor: palette.neutral["010"],
          borderColor: palette.neutral["020"],
          color: palette.lightBg.high,
        },
        "&:active": {
          backgroundColor: "transparent",
          borderColor: palette.purple.pressed,
          color: palette.lightBg.high,
        },
        "&.Mui-disabled": {
          backgroundColor: "transparent",
          color: palette.darkBg.low,
        },
      };
    };
    // TODO: incomplete styling
    const textStyle = () => {
      return {
        backgroundColor: "transparent",
        color: palette.purple.main,
        "&:hover": {
          backgroundColor: palette.neutral["005"],
        },
        "&:active": {
          backgroundColor: palette.neutral["015"],
        },
        "&.Mui-disabled": {
          backgroundColor: "transparent",
          color: palette.darkBg.low,
        },
      };
    };
    return {
      ...(isIconOnly || icon ? getIconButtonPadding() : getPadding()),
      borderRadius: "4px",
      whiteSpace: "nowrap",
      borderWidth: "1px",
      ...typography["button-small"],
      ...(variant === "contained" && containedStyle()),
      ...(variant === "outlined" && outlinedStyle()),
      ...(variant === "text" && textStyle()),
      fontFamily: "SegoeMedium",
      gap: endIcon ? "16px" : "8px",
      textTransform: "none",
      // minWidth: "110px",
      "& .MuiButton-startIcon": {
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& .MuiButton-endIcon": {
        marginLeft: "0px",
        marginRight: "0px",
      },
    };
  }
);
