import { Typography, Stack, useTheme } from "@mui/material";
import {
  useCreateMfa,
  useDeleteMfa,
  useGetMfa,
  useUpdateMfa,
} from "controller";
import ToggleButton from "library/base/custom-toggle-button";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { debugConsole } from "utilz/helpers";
// @ts-ignore
import MfaImage from "assets/images/mfaImage.webp";
import { Button, CircularLoader, Modal } from "components";

export const SecurityTab = () => {
  const theme = useTheme();

  const { data: getMfaData, isLoading: isGetMfaLoading } = useGetMfa();

  const isMfaEnabled = getMfaData?.enforceMFAType === "Force";

  const { mutateAsync: createMfa, isLoading: isCreateMfaLoading } =
    useCreateMfa();
  const { mutateAsync: deleteMfa, isLoading: isDeleteMfaLoading } =
    useDeleteMfa();
  const { mutateAsync: updateMfa, isLoading: isUpdateMfaLoading } =
    useUpdateMfa();

  const [mfaToggleBtnState, setMfaToggleBtnState] = useState({
    isChecked: getMfaData?.enforceMFAType === "Force",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMfaUpdate = async () => {
    try {
      if (
        getMfaData?.enforceMFAType !== "Force" &&
        getMfaData?.enforceMFAType !== "DontForce"
        // calls createMfa if user has never enabled mfa before
      ) {
        const createMfaData = await createMfa();
        setMfaToggleBtnState((prev) => ({
          ...prev,
          isChecked: createMfaData?.enforceMFAType === "Force",
        }));
        setIsModalOpen(false);
        toast.success(
          "MFA enabled successfully. All resources are required to set up MFA on Authenticator app during next login."
        );
      } else if (getMfaData?.enforceMFAType === "DontForce") {
        const updateMfaData = await updateMfa({
          enforceMFAType: "Force",
          allowRememberMyDevice: false,
        });
        setMfaToggleBtnState((prev) => ({
          ...prev,
          isChecked: updateMfaData?.enforceMFAType === "Force",
        }));
        setIsModalOpen(false);
        toast.success(
          "MFA enabled successfully. All resources are required to set up MFA on Authenticator app during next login."
        );
      } else if (getMfaData?.enforceMFAType === "Force") {
        const deleteMfaData = await deleteMfa();
        setMfaToggleBtnState((prev) => ({
          ...prev,
          isChecked: deleteMfaData?.enforceMFAType === "Force",
        }));
        setIsModalOpen(false);
        toast.success("MFA disabled successfully");
      }
    } catch (error) {
      debugConsole("error", error);
      toast.error("Failed to update MFA. Please try again");
    }
  };

  const handleMfaToggleBtnChange = async () => {
    setMfaToggleBtnState((prev) => ({
      ...prev,
      isChecked: !prev.isChecked,
    }));
    setIsModalOpen(true);
  };

  useEffect(() => {
    setMfaToggleBtnState((prev) => ({
      ...prev,
      isChecked: getMfaData?.enforceMFAType === "Force",
    }));
  }, [getMfaData?.enforceMFAType]);

  return (
    <>
      <Stack gap={"38px"} padding={"32px"}>
        <Typography variant="h3">Security</Typography>
        <Stack
          width={"100%"}
          sx={{
            border: `1px solid ${theme.palette.neutral["015"]}`,
            borderRadius: "16px",
            pl: "16px",
            pr: "32px",
            py: "24px",
            gap: "16px",
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="button-large">
              Multi-Factor Authentication (MFA)
            </Typography>
            <Stack direction="row" gap="12px" alignItems={"center"}>
              {isGetMfaLoading && <CircularLoader size={"22px"} />}

              <ToggleButton
                isDisabled={
                  isGetMfaLoading ||
                  isCreateMfaLoading ||
                  isUpdateMfaLoading ||
                  isDeleteMfaLoading
                }
                isChecked={mfaToggleBtnState.isChecked || false}
                onChange={handleMfaToggleBtnChange}
              />
            </Stack>
          </Stack>
          <Typography
            variant="body-large"
            sx={{
              color: theme.palette.lightBg.medium,
              maxWidth: "830px",
              pr: "56px",
            }}
          >
            At DeskDay, we take the security of your account seriously. We
            understand the importance of safeguarding your sensitive
            information, and that's why we're excited to introduce Multi-Factor
            Authentication (MFA) to enhance the protection of your DeskDay
            account.
          </Typography>
        </Stack>
      </Stack>
      {isModalOpen && (
        <Modal
          onClose={() => {
            setIsModalOpen(false);
            setMfaToggleBtnState((prev) => ({
              ...prev,
              isChecked: getMfaData?.enforceMFAType === "Force",
            }));
          }}
          colseable
          headerStyles={{
            borderBottom: `1px solid ${theme.palette.neutral["010"]}`,
          }}
          footer={<></>}
          maxWidth="sm"
          onSecondaryButtonClick={() => {
            setIsModalOpen(false);
          }}
          title={
            isMfaEnabled
              ? "Are you sure you want to disable MFA ?"
              : "Are you sure you want to enable MFA ?"
          }
          titleStyles={{ textTransform: "unset" }}
          open={isModalOpen}
        >
          <Stack
            gap={"12px"}
            direction="row"
            // sx={{ borderTop: `1px solid ${theme.palette.neutral["010"]}` }}
          >
            <Stack sx={{ width: "50%", bgcolor: "#E7E9FE" }}>
              <img src={MfaImage} alt="cancel" />
            </Stack>
            <Stack
              p={"32px"}
              sx={{ width: "50%", justifyContent: "space-between" }}
            >
              <Stack gap={"24px"} pb={"32px"}>
                <Typography
                  variant="body-medium"
                  color={theme.palette.lightBg.low}
                >
                  {isMfaEnabled
                    ? "Disabling Multi-Factor Authentication (MFA) drastically undermines your overall security, leaving all your resources more vulnerable."
                    : "All resources associated with this account will be required to set up MFA on Authenticator app during their next login."}
                </Typography>
                <Typography
                  variant="body-medium"
                  color={theme.palette.lightBg.low}
                >
                  {isMfaEnabled
                    ? "This action not only deactivates MFA across the entire system but also increases the risk of unauthorized access and potential breaches."
                    : "By enabling Multi-Factor Authentication, you add an extra layer of security by requiring users to provide multiple forms of identification. This helps in reducing the risk of unauthorized access and mitigating the impact of credential theft."}
                </Typography>
              </Stack>

              <Stack direction={"row"} gap={"16px"} justifySelf={"flex-end"}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsModalOpen(false);
                    setMfaToggleBtnState((prev) => ({
                      ...prev,
                      isChecked: getMfaData?.enforceMFAType === "Force",
                    }));
                  }}
                >
                  Cancel
                </Button>
                {isMfaEnabled ? (
                  <Button
                    sx={{
                      bgcolor: theme.palette.error.main,
                      "&:hover": { backgroundColor: "#A9050E" },
                    }}
                    variant="primary"
                    fullWidth
                    onClick={handleMfaUpdate}
                    disabled={
                      isCreateMfaLoading ||
                      isUpdateMfaLoading ||
                      isGetMfaLoading ||
                      isDeleteMfaLoading
                    }
                  >
                    Disable MFA
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    onClick={handleMfaUpdate}
                    disabled={
                      isCreateMfaLoading ||
                      isUpdateMfaLoading ||
                      isGetMfaLoading ||
                      isDeleteMfaLoading
                    }
                  >
                    Enable MFA
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      )}
    </>
  );
};
