import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getReportGraphData,
  getReportMetaData,
  getReportTableData,
  getReportsList,
  sendAsEmail,
} from "controller/api";
import {
  GetReportGraphInputProps,
  GetReportTableInputProps,
  SendAsEmailInputType,
} from "shared";

export const useGetReportList = () => {
  return useQuery({
    queryKey: ["reports"],
    queryFn: () => getReportsList(),
  });
};

export const useGetReportMetadata = (reportName: string) => {
  return useQuery({
    queryKey: ["reportMetadata", reportName],
    queryFn: () => getReportMetaData(reportName),
    enabled: reportName ? true : false,
  });
};

export const useGetReportGraphData = (data: GetReportGraphInputProps) => {
  return useQuery({
    queryKey: [
      "reportGraph",
      data.reportName,
      data.timezone,
      data.serviceType,
      data.customer,
      data.timePeriod,
    ],
    queryFn: () => getReportGraphData(data),
    enabled: data.reportName ? true : false,
  });
};

export const useGetReportTableData = (data: GetReportTableInputProps) => {
  return useQuery({
    queryKey: [
      "reportTable",
      data.reportName,
      data.timezone,
      data.serviceType,
      data.customer,
      data.timePeriod,
    ],
    queryFn: () => getReportTableData(data),
    enabled: data.reportName ? true : false,
  });
};

export const useSendAsEmail = () => {
  return useMutation((value: SendAsEmailInputType) => sendAsEmail(value), {});
};
