import { SvgIcon, SvgIconProps } from "@mui/material";

export const PlayIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m9.683 7.173 5.77 3.768c.73.476.73 1.642 0 2.118l-5.77 3.768C8.939 17.312 8 16.72 8 15.767V8.233c0-.953.94-1.545 1.683-1.06Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
