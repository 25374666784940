import { Box, Skeleton, Stack, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  PieChart as RePieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Label,
  Customized,
  Sector,
  Legend,
  Tooltip,
} from "recharts";
import { LegendBuilder } from "../Utils/legend";
import { stringToNumber } from "utilz/helpers";
import { ChartProps, CustomTooltip, pieChartColor } from "../Utils";

interface PieChartProps extends ChartProps {
  radius?: number | string;
}

export const PieChart = ({
  data = [],
  width,
  height,
  labelKey = "name",
  valueKey = "value",
  radius,
  customTooltip,
  isLoading = false,
  selectedIndex = -1,
  onSelectedIndexChange,
  showLegends = true,
}: PieChartProps) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);
  const RADIAN = Math.PI / 180;
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    const updateSize = (): void => {
      if (boxRef.current && isLoading) {
        const box = boxRef.current;
        const boxSize = Math.min(box.offsetWidth, box.offsetHeight);
        setSize(boxSize);
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const activeContentBuilder = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      stroke,
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);

    const mx = cx + 15 * cos;
    const my = cy + 15 * sin;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.55;
    const x = mx + radius * Math.cos(-midAngle * RADIAN);
    const y = my + radius * Math.sin(-midAngle * RADIAN);

    const percentage = Math.max(0.3, (endAngle - startAngle) / 360);
    const colorOpacity = 0.3 * (1 - percentage) + percentage;
    return (
      <g>
        <Sector
          cx={mx}
          cy={my}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill || pieChartColor}
          stroke={theme.palette.lightBg.high}
          strokeWidth={"1px"}
          filter="drop-shadow(-5px 4px 7px rgba(0, 0, 0, 0.18))"
          // opacity={fill ? 1 : colorOpacity}
        />
        <Sector
          cx={mx}
          cy={my}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={"transparent"}
          stroke={theme.palette.lightBg.high}
          strokeWidth={"1px"}
        />
        <text
          x={x}
          y={y}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={theme.typography["body-medium"].fontSize}
          fontWeight={theme.typography["body-medium"].fontWeight}
          fontFamily={theme.typography["body-medium"].fontFamily}
          color={theme.typography["body-medium"].color}
        >
          {value}
        </text>
      </g>
    );
  };

  const inactiveContentBuilder = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
    } = props;

    const percentage = (endAngle - startAngle) / 360;
    const colorOpacity = 0.3 * (1 - percentage) + percentage;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill || pieChartColor}
          stroke={"white"}
          strokeWidth={fill ? "0px" : "1px"}
          // opacity={fill ? 1 : colorOpacity}
        />
      </g>
    );
  };

  return isLoading ? (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "8px",
      }}
    >
      <Box
        ref={boxRef}
        flex={1}
        width={"100%"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="circular"
          sx={{
            width: size + "px",
            height: size + "px",
          }}
        />
      </Box>

      <Stack
        direction={"row"}
        height={"32px"}
        width={"100%"}
        overflow={"clip"}
        gap={"16px"}
      >
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} height={"100%"} width={"80%"} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <Box
      width={width}
      height={height}
      sx={{
        outline: "none !important",
        border: "none !important",
        "& g,path": {
          outline: "none !important",
          border: "none !important",
          "&:focus": {
            outline: "none !important",
          },
        },
      }}
    >
      <ResponsiveContainer width={width} height={height}>
        <RePieChart
          margin={{ bottom: 0, top: 0 }}
          style={{ outline: "none !important" }}
        >
          <Tooltip
            content={
              customTooltip || (
                <CustomTooltip labelKey={labelKey} valueKey={valueKey} />
              )
            }
            cursor={{ fill: "white", stroke: "0px" }}
          />

          {data.length <= 0 && (
            <Pie
              style={{ outline: "none !important" }}
              activeIndex={selectedIndex || activeIndex}
              activeShape={
                selectedIndex > -1
                  ? activeContentBuilder
                  : inactiveContentBuilder
              }
              inactiveShape={inactiveContentBuilder}
              data={[{ [valueKey]: 100, [labelKey]: "Total" }]}
              outerRadius={radius}
              dataKey={valueKey}
              legendType="none"
            >
              {data.map((entry, index) => (
                <Cell
                  style={{ outline: "none !important" }}
                  key={`cell-${index}`}
                  fill={"#D9D9D9"} //
                />
              ))}
            </Pie>
          )}

          <Pie
            style={{ outline: "none !important" }}
            activeIndex={selectedIndex || activeIndex}
            activeShape={
              selectedIndex > -1 ? activeContentBuilder : inactiveContentBuilder
            }
            inactiveShape={inactiveContentBuilder}
            data={data}
            outerRadius={radius}
            dataKey={valueKey}
            onClick={
              selectedIndex > -1 && onSelectedIndexChange
                ? (_, index) => onSelectedIndexChange(index)
                : () => {}
            }
          >
            {data.map((entry, index) => (
              <Cell
                style={{ outline: "none !important" }}
                key={`cell-${index}`}
                fill={entry.color} //
              />
            ))}
          </Pie>

          {showLegends && (
            <Legend
              content={
                <LegendBuilder
                  data={data}
                  labelKey={labelKey}
                  color={pieChartColor}
                  selectedIndex={selectedIndex}
                  onSelectedIndexChange={onSelectedIndexChange}
                />
              }
            />
          )}
        </RePieChart>
      </ResponsiveContainer>
    </Box>
  );
};
