import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ConfigurationTable } from "../../../Components";
import TabHeadComponent from "../component/tab-head-component";
import TemplateTableTile from "../component/template-table-tile";
import { Table_Model } from "../constants";
import { TicketTemplateCreateModal } from "./components";
import { services } from "../../../../../../../../services";
//temp import
import { useGlobalStore } from "controller/store/globalStore";
import toast from "react-hot-toast";
import { BottomStickyLayer } from "library/layers";
import { PaginationControls } from "library/mounts";
import { DeleteConfirmationModal } from "components";
function TemplateTickets(props) {
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    data: [],
    isModalOpen: false,
    selectedData: {},
    pagination: { current: 1, total: 0 },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    data: null,
  });

  useEffect(() => {
    fetchAllTicketTemplates();
  }, []);

  function fetchAllTicketTemplates(
    pageNumber = state.pagination.current,
    searchValue = searchQuery
  ) {
    try {
      services.controlCenterservices.configurationService
        .fetchAllTicketTemplates(
          mspId,
          10,
          pageNumber,
          "template_name",
          "asc",
          searchValue
        )
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.success === true &&
              response?.statusCode === 200 &&
              response?.data &&
              response?.data?.result &&
              Array.isArray(response?.data?.result)
            ) {
              setState({
                ...state,
                data: response?.data?.result,
                selectedData: {},
                isModalOpen: false,
                pagination: {
                  ...state?.pagination,
                  total: response?.data?.totalPages || state.pagination.total,
                  current: pageNumber,
                },
              });
              return;
            }
          },
          error: (error) => {
            console.log(`GET ALL TICKET TEMPLATE ${error}`);
          },
        });
    } catch (error) {
      console.log(`GET ALL TICKET TEMPLATE ${error}`);
    }
  }

  const fetchTicketTemplateById = (templateId) => {
    services.controlCenterservices.configurationService
      .fetchTicketTemplateById(templateId)
      .subscribe({
        next: (response) => {
          if (
            response &&
            response.success === true &&
            response?.statusCode === 200 &&
            response?.data
          ) {
            setState({
              ...state,
              isModalOpen: true,
              selectedData: {
                ...response?.data,
              },
            });
            return;
          }

          toast.error(
            "Unable to fetch selected template at the moment. Please try again."
          );
        },
        error: (error) => {
          toast.error(
            "Unable to fetch selected template at the moment. Please try again."
          );
        },
      });
  };

  const createTicketTemplate = (data) => {
    setIsLoading(true);
    services.controlCenterservices.configurationService
      .createTicketTemplate(mspId, data)
      .subscribe({
        next: (response) => {
          if (
            response &&
            response.success === true &&
            response?.statusCode === 200
          ) {
            fetchAllTicketTemplates(1);
            setIsLoading(false);
            toast.success("Template is ready");
            return;
          }
          setIsLoading(false);
          toast.error("Unable to create at the moment. Please try again.");
        },
        error: (error) => {
          setIsLoading(false);
          toast.error("Unable to create at the moment. Please try again.");
        },
      });
  };

  const deleteTemplates = (templateId) => {
    services.controlCenterservices.configurationService
      .deleteTicketTemplate(mspId, templateId)
      .subscribe({
        next: (response) => {
          if (
            response &&
            response.success === true &&
            response?.statusCode === 200
          ) {
            fetchAllTicketTemplates();
            toast.success("Template is deleted");
            return;
          }
          toast.error("Unable to delete at the moment. Please try again.");
        },
        error: (error) => {
          toast.error("Unable to delete at the moment. Please try again.");
        },
      });
  };

  const updateTicketTemplates = (templateId, data) => {
    setIsLoading(true);
    services.controlCenterservices.configurationService
      .updateTicketTemplate(templateId, data)
      .subscribe({
        next: (response) => {
          if (
            response &&
            response.success === true &&
            response?.statusCode === 200
          ) {
            fetchAllTicketTemplates();
            setIsLoading(false);
            toast.success("Template is updated");
            return;
          }
          setIsLoading(false);
          toast.error("Unable to update at the moment. Please try again.");
        },
        error: (error) => {
          setIsLoading(false);
          toast.error("Unable to update at the moment. Please try again.");
        },
      });
  };

  const onSubmitHandler = (data) => {
    if (data["mapping_id"] === undefined) {
      createTicketTemplate(data);
      return;
    }
    var { mapping_id, ...templateData } = data;
    updateTicketTemplates(mapping_id, templateData);
  };

  const onEditHandler = (templateId) => {
    fetchTicketTemplateById(templateId);
  };

  const onDeleteHandler = (templateId) => {
    setDeleteModalState({
      open: true,
      data: templateId,
    });
  };

  return (
    <Stack gap={"32px"}>
      {deleteModalState.open && (
        <DeleteConfirmationModal
          open={deleteModalState.open}
          onClose={() =>
            setDeleteModalState({
              open: false,
              data: null,
            })
          }
          onSecondaryButtonClick={() =>
            setDeleteModalState({
              open: false,
              data: null,
            })
          }
          onPrimaryButtonClick={() => {
            deleteTemplates(deleteModalState.data);
            setDeleteModalState({
              open: false,
              data: null,
            });
          }}
        />
      )}
      {state.isModalOpen ? (
        <TicketTemplateCreateModal
          isLoading={isLoading}
          title={
            Object.entries(state.selectedData).length <= 0
              ? "Create New Ticket Template"
              : "Update Ticket Template"
          }
          isOpen={state.isModalOpen}
          data={state.selectedData}
          onCancel={() => {
            setState({
              ...state,
              selectedData: {},
              isModalOpen: false,
            });
          }}
          onSubmit={onSubmitHandler}
        />
      ) : null}

      <TabHeadComponent
        createNewHandler={() => {
          setState({
            ...state,
            selectedData: {},
            isModalOpen: true,
          });
        }}
        data={[searchQuery, setSearchQuery]}
        resetSearchHandler={() => {}}
        updateSearchHandler={(searchValue) =>
          fetchAllTicketTemplates(1, searchValue)
        }
      />
      <Box pb={"24px"}>
        <ConfigurationTable
          header={Table_Model}
          body={state.data.map((item, index) => (
            <TemplateTableTile
              key={`configuration_template_ticket_item_${item.mapping_id}`}
              value={item}
              onEditClicked={() => onEditHandler(item.mapping_id)}
              onDeleteClicked={() => onDeleteHandler(item.mapping_id)}
            />
          ))}
        />
      </Box>
      <BottomStickyLayer
        content={
          <PaginationControls
            currentPage={state.pagination.current}
            totalPages={state.pagination.total}
            getNewPage={(newPageNumber) =>
              fetchAllTicketTemplates(newPageNumber, searchQuery)
            }
          />
        }
      />
    </Stack>
  );
}

export default TemplateTickets;
