import { PERMISSION_CONSTANT, TYPE } from "shared";

export default function initialPermissionDataBuilder(data) {
  const perissions = {};

  PERMISSION_CONSTANT.forEach((page) => {
    Object.values(page).map((mainPermission) => {
      if (mainPermission.type === TYPE.SINGLE) {
        perissions[mainPermission.name] = {
          permission_name: mainPermission.name,
          permission_key: mainPermission.rule,
          is_enabled:
            data?.find((item) => item.permission_name === mainPermission.name)
              ?.is_enabled || false,
        };
      } else {
        Object.values(mainPermission.contents).map((permission) => {
          perissions[permission.name] = {
            permission_name: permission.name,
            permission_key: permission.rule,
            is_enabled:
              data?.find((item) => item.permission_name === permission.name)
                ?.is_enabled || false,
          };
        });
      }
    });
  });
  return perissions;
}
