import { Box, Grid, Stack, Typography } from "@mui/material";
// @ts-ignore
import settingsImg from "assets/images/settingsImg.svg";
import { ErrorLayout } from "./ErrorLayout";
import { Button } from "components/button";
import { useEffect } from "react";
import { sentryLogger } from "utilz";
export const ErrorFallbackComponent = ({ error }: { error: Error }) => {
  useEffect(() => {
    if (
      error.message.includes("Failed to fetch dynamically imported") ||
      error.message.includes("Loading chunk") ||
      error.message.includes("error loading dynamically imported module")
    ) {
      window.location.reload();
    }
    sentryLogger({
      error: {
        message: `${error.message}`,
        traceId: "",
        status: 400,
      },
      // message: `${error}`,
      data: error,
      level: "error",
      functionName: "ErrorFallbackComponent",
      workflow: "common",
    });
  }, [error]);

  // if error is of chunk loading error do a page reload and donst show oops screen
  if (
    error.message.includes("Failed to fetch dynamically imported") ||
    error.message.includes("Loading chunk") ||
    error.message.includes("error loading dynamically imported module")
  ) {
    window.location.reload();
    return <></>;
  }

  return (
    <Box width={"100vw"} height={"100vh"}>
      <ErrorLayout
        errorImg={settingsImg}
        leftSide={
          <Stack
            sx={{
              maxWidth: "360px",
              gap: "16px",
            }}
          >
            <Typography variant="h3">Oops! Something went wrong </Typography>
            <Typography
              variant="body-large"
              sx={{
                textAlign: "justify",
              }}
            >
              We're sorry, but an unexpected error has occurred. Please bear
              with us as we work to fix the issue and restore normal
              functionality.
            </Typography>
            <Button
              fullWidth
              onClick={() => {
                window.location.href = "/app/home";
              }}
            >
              Back to home
            </Button>
          </Stack>
        }
      />
    </Box>
  );
};
