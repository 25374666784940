import { GetAuditTrailInput } from "shared";
import { axiosClient } from "utilz";
import axiosUploadCall from "utilz/axios-call";
const BASE_URL = import.meta.env.VITE_APP_AUDIT_BASE_URL;

export const getAuditTrail = async ({
  serviceId,
  serviceType,
}: GetAuditTrailInput) => {
  try {
    const url = `${BASE_URL}/audit_trail/service/${serviceType}/${serviceId}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success) {
      console.log(response.data);
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
