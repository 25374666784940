function pdfDocumentIcon(props) {
  return (
    <svg
      width="100%"
      height="102%"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M11.1732 3.93985C10.7358 3.93942 10.3165 3.76549 10.0072 3.45623C9.69798 3.14698 9.52405 2.72766 9.52363 2.2903V0H2.15271C1.58178 0 1.03423 0.22681 0.630517 0.630522C0.226805 1.03423 0 1.58177 0 2.15271V15.1298C0.000847836 15.7002 0.228025 16.2469 0.631645 16.6499C1.03527 17.053 1.58233 17.2793 2.15271 17.2793H11.0012C11.5716 17.2793 12.1186 17.053 12.5222 16.6499C12.9259 16.2469 13.153 15.7002 13.1539 15.1298V3.93985H11.1732Z"
          fill={props.disabled === true ? props.disabledcolor : props.color} />
      </g>
      <path
        d="M13.1541 3.93985H11.1734C10.736 3.93942 10.3167 3.76549 10.0074 3.45623C9.69817 3.14698 9.52425 2.72766 9.52383 2.2903V0L13.1541 3.93985Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M10.4479 12.065H2.18905C2.10949 12.065 2.03318 12.0334 1.97692 11.9771C1.92067 11.9209 1.88906 11.8446 1.88906 11.765C1.88927 11.6856 1.92097 11.6095 1.97721 11.5534C2.03344 11.4973 2.10963 11.4658 2.18905 11.4658H10.4479C10.5273 11.4658 10.6034 11.4973 10.6595 11.5534C10.7156 11.6095 10.7471 11.6857 10.7471 11.765C10.7472 11.8044 10.7396 11.8434 10.7246 11.8797C10.7096 11.9161 10.6876 11.9492 10.6598 11.9771C10.632 12.0049 10.599 12.0271 10.5626 12.0421C10.5263 12.0572 10.4873 12.065 10.4479 12.065Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M10.4479 8.68755H2.18905C2.10963 8.68756 2.03344 8.65605 1.97721 8.59996C1.92097 8.54387 1.88927 8.46779 1.88906 8.38837C1.88906 8.30881 1.92067 8.23249 1.97692 8.17623C2.03318 8.11997 2.10949 8.08838 2.18905 8.08838H10.4479C10.4873 8.08838 10.5263 8.09614 10.5626 8.11123C10.599 8.12632 10.632 8.14842 10.6598 8.17629C10.6876 8.20415 10.7096 8.23723 10.7246 8.27363C10.7396 8.31002 10.7472 8.34901 10.7471 8.38837C10.7471 8.46772 10.7156 8.54381 10.6595 8.59992C10.6034 8.65603 10.5273 8.68755 10.4479 8.68755Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M10.4479 10.3775H2.18905C2.10949 10.3775 2.03318 10.3459 1.97692 10.2896C1.92067 10.2334 1.88906 10.1571 1.88906 10.0775C1.88927 9.99808 1.92097 9.92198 1.97721 9.8659C2.03344 9.80981 2.10963 9.77832 2.18905 9.77832H10.4479C10.5273 9.77832 10.6034 9.80984 10.6595 9.86595C10.7156 9.92206 10.7471 9.99816 10.7471 10.0775C10.7472 10.1169 10.7396 10.1559 10.7246 10.1922C10.7096 10.2286 10.6876 10.2617 10.6598 10.2896C10.632 10.3175 10.599 10.3396 10.5626 10.3546C10.5263 10.3697 10.4873 10.3775 10.4479 10.3775Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M7.19046 6.99761H2.18905C2.10963 6.99761 2.03344 6.96612 1.97721 6.91004C1.92097 6.85395 1.88927 6.77785 1.88906 6.69843C1.88906 6.61886 1.92067 6.54256 1.97692 6.48631C2.03318 6.43005 2.10949 6.39844 2.18905 6.39844H7.19046C7.26988 6.39865 7.34598 6.43034 7.40206 6.48658C7.45815 6.54281 7.48964 6.619 7.48964 6.69843C7.48943 6.77771 7.45784 6.85368 7.40178 6.90975C7.34572 6.96581 7.26974 6.9974 7.19046 6.99761V6.99761Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M7.19046 5.31011H2.18905C2.10963 5.31011 2.03344 5.2786 1.97721 5.22252C1.92097 5.16643 1.88927 5.09035 1.88906 5.01093C1.88906 4.93136 1.92067 4.85504 1.97692 4.79879C2.03318 4.74253 2.10949 4.71094 2.18905 4.71094H7.19046C7.26988 4.71115 7.34598 4.74284 7.40206 4.79908C7.45815 4.85531 7.48964 4.9315 7.48964 5.01093C7.48943 5.09021 7.45784 5.16618 7.40178 5.22225C7.34572 5.27831 7.26974 5.3099 7.19046 5.31011Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M14.7141 13.6475H4.71131C4.00176 13.6475 3.42656 14.2227 3.42656 14.9322V17.9145C3.42656 18.624 4.00176 19.1992 4.71131 19.1992H14.7141C15.4237 19.1992 15.9989 18.624 15.9989 17.9145V14.9322C15.9989 14.2227 15.4237 13.6475 14.7141 13.6475Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path d="M6.70822 17.0693V17.6509H6.10664V17.0693H6.70822Z" fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M7.59408 16.8052V17.6491H7.0125V15.2437H7.95486C8.24045 15.2437 8.45884 15.3148 8.60844 15.4564C8.68413 15.5308 8.74323 15.6204 8.78183 15.7192C8.82044 15.8181 8.83768 15.924 8.83243 16.03C8.83534 16.1699 8.80082 16.308 8.73243 16.43C8.66259 16.5493 8.55907 16.6453 8.43484 16.706C8.28501 16.7768 8.12051 16.8111 7.95486 16.806L7.59408 16.8052ZM8.24045 16.03C8.24045 15.8167 8.12366 15.71 7.89007 15.71H7.59408V16.3364H7.89007C8.12366 16.3385 8.24045 16.2364 8.24045 16.03Z"
        fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M11.1303 17.0696C11.0301 17.2515 10.8788 17.4 10.6951 17.4968C10.4884 17.6026 10.2585 17.6552 10.0263 17.6496H9.11836V15.2441H10.0263C10.2587 15.2383 10.4889 15.2896 10.6967 15.3937C10.8799 15.4884 11.0308 15.6354 11.1303 15.8161C11.2344 16.0079 11.2867 16.2235 11.2823 16.4417C11.2866 16.6606 11.2343 16.8769 11.1303 17.0696V17.0696ZM10.5047 16.9488C10.5697 16.8821 10.6201 16.8025 10.6526 16.7152C10.6851 16.6278 10.6991 16.5347 10.6935 16.4417C10.6991 16.3487 10.6852 16.2557 10.6527 16.1685C10.6202 16.0813 10.5698 16.0018 10.5047 15.9353C10.3592 15.8076 10.1691 15.7426 9.97593 15.7545H9.69994V17.1288H9.97593C10.169 17.1405 10.3589 17.0759 10.5047 16.9488V16.9488Z"
        fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M13.1808 15.2437V15.7068H12.1945V16.234H12.9561V16.6764H12.1945V17.6491H11.6121V15.2437H13.1808Z"
        fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M10.3017 4.71094H8.8874C8.64087 4.71094 8.44102 4.91079 8.44102 5.15732V6.57166C8.44102 6.8182 8.64087 7.01805 8.8874 7.01805H10.3017C10.5483 7.01805 10.7481 6.8182 10.7481 6.57166V5.15732C10.7481 4.91079 10.5483 4.71094 10.3017 4.71094Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
    </svg>

  );
}

export default pdfDocumentIcon;