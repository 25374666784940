import { Box, Skeleton, Stack, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Label,
  Customized,
  Sector,
  Legend,
  Tooltip,
} from "recharts";
import { LegendBuilder } from "../Utils/legend";
import { stringToNumber } from "utilz/helpers";
import { ChartProps, CustomTooltip, donutChartColor } from "../Utils";

interface DonutChartProps extends ChartProps {
  centerContent: string;
  centerSubContent: string;
  innerRadius: number | string;
  outerRadius: number | string;
}

export const DonutChart = ({
  data = [],
  width,
  height,
  labelKey,
  valueKey = "value",
  centerContent = "",
  centerSubContent = "",
  innerRadius,
  outerRadius,
  customTooltip,
  isLoading = false,
  selectedIndex = -1,
  onSelectedIndexChange,
  showLegends = true,
}: DonutChartProps) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const boxRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    const updateSize = (): void => {
      if (boxRef.current && isLoading) {
        const box = boxRef.current;
        const boxSize = Math.min(box.offsetWidth, box.offsetHeight);
        setSize(boxSize);
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const CustomizedLabel = React.createElement((props: any) => {
    const { x, y, stroke, value } = props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  });

  const InActiveContent = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      isSingle = true,
    } = props;

    const percentage = (endAngle - startAngle) / 360;
    const colorOpacity = 0.3 * (1 - percentage) + percentage;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill || donutChartColor}
          stroke={"white"}
          strokeWidth={isSingle ? "0px" : "1px"}
          // opacity={fill ? 1 : colorOpacity}
        />
      </g>
    );
  };

  const ActiveContent = (props: any) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      isSingle = true,
    } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);

    const mx = cx + 5 * cos;
    const my = cy + 5 * sin;

    const percentage = (endAngle - startAngle) / 360;
    const colorOpacity = 0.3 * (1 - percentage) + percentage;

    return (
      <g>
        <Sector
          cx={mx}
          cy={my}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill || donutChartColor}
          stroke={"black"}
          strokeWidth={"1px"}
          // opacity={fill ? 1 : colorOpacity}
        />
      </g>
    );
  };

  return isLoading ? (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "8px",
      }}
    >
      <Box
        flex={1}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        position={"relative"}
      >
        <Box
          ref={boxRef}
          flex={1}
          width={"100%"}
          height={"100%"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: size + "px",
              height: size + "px",
              position: "relative",
              p: "16px",
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",

                position: "absolute",
                WebkitTransform: "unset",
              }}
            ></Skeleton>
            <Box
              sx={{
                top: "15%",
                left: "15%",
                width: "70%",
                height: "70%",
                bgcolor: "white",
                position: "absolute",
                borderRadius: "50%",
              }}
            ></Box>
          </Box>
        </Box>
      </Box>

      <Stack
        direction={"row"}
        height={"32px"}
        width={"100%"}
        overflow={"clip"}
        gap={"16px"}
      >
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} height={"100%"} width={"80%"} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <Box
      width={width}
      height={height}
      sx={{
        outline: "none !important",
        border: "none !important",
        "& g,path": {
          outline: "none !important",
          border: "none !important",
          "&:focus": {
            outline: "none !important",
          },
        },
      }}
    >
      <ResponsiveContainer width={width} height={height}>
        <PieChart
          margin={{ bottom: 0, top: 0 }}
          style={{ outline: "none !important" }}
        >
          <Customized
            component={(props: any) => {
              const { cx, cy } = props;
              return (
                <g x={cx} y={cy} style={{ outline: "none !important" }}>
                  <text
                    x={cx}
                    y={cy}
                    dy={-12}
                    textAnchor="middle"
                    fontFamily={theme.typography.h2.fontFamily}
                    fontWeight={theme.typography.h2.fontWeight}
                    fontSize={theme.typography.h2.fontSize}
                  >
                    {centerContent}
                  </text>

                  <text
                    x={cx}
                    y={cy}
                    dy={12}
                    textAnchor="middle"
                    fontFamily={theme.typography["body-medium"].fontFamily}
                    fontWeight={theme.typography["body-medium"].fontWeight}
                    fontSize={theme.typography["body-medium"].fontSize}
                    fill={theme.palette.lightBg.low}
                  >
                    {centerSubContent}
                  </text>
                </g>
              );
            }}
          />
          <Tooltip
            content={
              customTooltip || (
                <CustomTooltip labelKey={labelKey} valueKey={valueKey} />
              )
            }
            cursor={{ fill: "white", stroke: "0px" }}
          />
          {data.length <= 0 && (
            <Pie
              style={{ outline: "none !important" }}
              data={[{ [valueKey]: 100 }]}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill="#8884d8"
              stroke="none"
              strokeWidth={0}
              dataKey={valueKey}
              nameKey={labelKey}
              activeIndex={selectedIndex || activeIndex}
              inactiveShape={<InActiveContent isSingle={data.length <= 0} />}
              activeShape={
                selectedIndex > -1 ? (
                  <ActiveContent isSingle={data.length <= 0} />
                ) : (
                  <InActiveContent isSingle={data.length <= 0} />
                )
              }
            >
              <Label position={"center"} content={CustomizedLabel} />
              {data.map((entry, index) => (
                <Cell
                  style={{ outline: "none !important" }}
                  key={`cell-${index}`}
                  fill={"#D9D9D9"}
                />
              ))}
            </Pie>
          )}

          <Pie
            style={{ outline: "none !important" }}
            activeIndex={selectedIndex || activeIndex}
            inactiveShape={<InActiveContent isSingle={data.length <= 0} />}
            activeShape={
              selectedIndex > -1 ? (
                <ActiveContent isSingle={data.length <= 0} />
              ) : (
                <InActiveContent isSingle={data.length <= 0} />
              )
            }
            data={data}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill="#8884d8"
            dataKey={valueKey}
            nameKey={labelKey}
            onClick={
              selectedIndex > -1 && onSelectedIndexChange
                ? (_, index) => onSelectedIndexChange(index)
                : () => {}
            }
          >
            <Label position={"center"} content={CustomizedLabel} />
            {data.map((entry, index) => (
              <Cell
                style={{ outline: "none !important" }}
                key={`cell-${index}`}
                fill={entry.color}
              />
            ))}
          </Pie>
          {showLegends && (
            <Legend
              content={LegendBuilder({
                data,
                labelKey,
                color: donutChartColor,
              })}
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
