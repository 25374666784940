import { create } from "zustand";

import {
  nonPersistantUiSlice,
  NonPersistantUiStoreInterface,
} from "./ui-event";
import { WatchListInterface, WatchListSlice } from "./watchlist";

export const useNonPersistantMainStore = create<
  NonPersistantUiStoreInterface & WatchListInterface
>()((...a) => ({
  ...nonPersistantUiSlice(...a),
  ...WatchListSlice(...a),
}));
