import { useEffect } from "react";

import mixpanel from "mixpanel-browser";

let initialized = false;

if (!initialized) {
  mixpanel.init(
    import.meta.env.MODE !== "development"
      ? import.meta.env.VITE_APP_MIXPANEL_TOKEN
      : "sd",
    {
      debug: false,
      track_pageview: false,
      persistence: "localStorage",
    }
  );
  initialized = true;
}

export { mixpanel };
