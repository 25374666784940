import { SvgIcon, SvgIconProps } from "@mui/material";

export const FullScreenIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M6 18.4 18.4 6M12.197 6h6.2v6.2M12.2 18.401H6v-6.2"
      />
    </svg>
  </SvgIcon>
);
