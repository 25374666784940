import { Box } from "@mui/material";
import { display } from "@mui/system";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import {
  CustomLink,
  CustomTypography,
} from "../../../../../../../library/base";
import CustomIcon from "../../../../../../../library/icon-library";

function DragAndDropArea(props) {
  const [dragState, setDragState] = useState(false);
  const inputRef = useRef();

  const onDragHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragleave") {
      setDragState(false);
      return;
    }
    setDragState(true);
  };

  const handleDrop = (event) => {
    // console.log("🚀 ~ file: index.js:25 ~ handleDrop ~ event:", event)
    event.preventDefault();
    event.stopPropagation();
    setDragState(false);
    // console.log("🚀 ~ file: index.js:30 ~ handleDrop ~ Array.isArray(event.dataTransfer.files):", typeof event.dataTransfer.files)
    // console.log("🚀 ~ file: index.js:29 ~ handleDrop ~ event.dataTransfer.files:", event.dataTransfer.files)
    if (
      event.dataTransfer.files &&
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0
    ) {
      var files = Object.values(event.dataTransfer.files).map((item) => item);
      props.onAddFiles({ field: props.name, value: files });
    }
  };

  const onChangeHandler = (event) => {
    // console.log("🚀 ~ file: index.js:37 ~ onChangeHandler ~ event:", event)
    if (
      event?.target &&
      event?.target?.files &&
      event?.target?.files.length > 0
    ) {
      // console.log("🚀 ~ file: index.js:39 ~ onChangeHandler ~ event?.target.files:", event?.target.files)
      var files = Object.values(event?.target.files).map((item) => item);
      props.onAddFiles({ field: props.name, value: files });
    }
  };

  const onClickHandler = () => {
    inputRef.current.click();
  };

  return (
    <Box
      sx={{
        cursor: "pointer",
        width: "100%",
        height: "fit-content",
        backgroundColor: "#EBFCFE",
        borderRadius: "16px",
        padding: "24px",
        border:
          dragState === true ? "1.5px solid #5563F5" : "1px dashed #5563F5",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "6px",
        ...props.sx,
      }}
      onDragEnter={onDragHandler}
      onDragLeave={onDragHandler}
      onDragOver={onDragHandler}
      onDrop={handleDrop}
      onClick={onClickHandler}
    >
      <CustomIcon icon="upload_file" size={"xl"} />
      <input
        ref={inputRef}
        hidden={true}
        multiple={true}
        type={"file"}
        onChange={onChangeHandler}
      />
      <CustomTypography
        content={props.label}
        size="EXTRA-SMALL"
        variant="REGULAR"
      />
      <Box
        sx={{
          "&>p:hover": {
            transition: "all 0.3s easeIn",
            cursor: "pointer",
            textDecoration: "underline",
          },
        }}
      >
        <p
          className={`typography-font-family-class-medium
                           typography-font-size-class-small-alpha
                           typography-font-weight-class-medium`}
          style={{
            lineHeight: "24px",
            textAlign: "center",
            color: "#5563F5",
            textUnderlineOffset: "4px",
          }}
        >
          browse
        </p>
      </Box>
      {props.isLogo && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "4px",
            position: "absolute",
            bottom: "16px",
            texAlign: "center",
          }}
        >
          {/* <CustomTypography
            size={"EXTRA-SMALL"}
            color={"SECONDARY-MAIN"}
            variant={"REGULAR"}
            content={"Supports PNG & JPG up to"}
          />
          <CustomTypography
            size={"EXTRA-SMALL"}
            color={"SECONDARY-MAIN"}
            variant={"MEDIUM"}
            content={` 2 MB`}
          /> */}
        </Box>
      )}
    </Box>
  );
}

DragAndDropArea.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  onAddFiles: PropTypes.func.isRequired,
  isLogo: PropTypes.bool,
  label: PropTypes.string,
};

DragAndDropArea.defaultProps = {
  sx: {},
  name: "",
  label: "Drop images here to attach or",
  isLogo: false,
  onAddFiles: (files) => {},
};

export default DragAndDropArea;
