import {
  AllignmentLeftIcon,
  AttachIcon,
  ClipboardIcon,
  ListDocumentIcon,
  MinusIcon,
  PlusIcon,
  RecordIcon,
  ScreenshotIcon,
  Tooltip,
} from "components";
import React, { useState } from "react";
import {
  Box,
  useTheme,
  Stack,
  Typography,
  Slide,
  Divider,
} from "@mui/material";
import { ChatAttachmentsModal } from "./ChatAttachmentModal";
import { ClickAwayListener } from "@mui/base";
import { useMainStore } from "controller/store/mainStore";
import { CannedNotesModal } from "./CannedNotesModal";
import CannedNoteDrawer from "./CannedNoteDrawer";

export const AttachmentButton = ({
  disabled = false,
  serviceId,
  serviceType,
  serviceSource,
  isDisabled,
}: {
  disabled?: boolean;
  serviceId: string;
  serviceType: string;
  serviceSource: string;
  isDisabled: boolean;
}) => {
  const theme = useTheme();

  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [isCannedNotesModalOpen, setIsCannedNotesModalOpen] = useState(false);

  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          px: "16px",
          gap: "16px",
          height: "fit-content",
        }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Tooltip title="Canned Notes">
          <Box>
            <AllignmentLeftIcon
              onClick={() => !isDisabled && setIsCannedNotesModalOpen(true)}
              sx={{ cursor: "pointer" }}
              htmlColor={isDisabled ? theme.palette.neutral["020"] : "inherit"}
            />
          </Box>
        </Tooltip>
        <Tooltip title="Attachment">
          <Box>
            <AttachIcon
              onClick={() => !isDisabled && setIsChatModalOpen(true)}
              sx={{ cursor: "pointer" }}
              htmlColor={isDisabled ? theme.palette.neutral["020"] : "inherit"}
            />
          </Box>
        </Tooltip>
      </Stack>

      {isChatModalOpen && (
        <ChatAttachmentsModal
          currentServiceId={serviceId}
          open={isChatModalOpen}
          onClose={() => setIsChatModalOpen(false)}
          serviceType={serviceType}
          serviceSource={serviceSource || ""}
          isDisabled={isDisabled}
        />
      )}
      {/* canned notes modal */}
      {isCannedNotesModalOpen && (
        <>
          {/* <CannedNotesModal
            currentServiceId={serviceId}
            open={isCannedNotesModalOpen}
            onClose={() => setIsCannedNotesModalOpen(false)}
            serviceType={serviceType}
            isDisabled={isDisabled}
            originSource={serviceSource || ""}
            onSendSelfMessage={onSendSelfMessage}
          /> */}
          <CannedNoteDrawer
            currentServiceId={serviceId}
            open={isCannedNotesModalOpen}
            onClose={() => setIsCannedNotesModalOpen(false)}
            serviceType={serviceType}
            isDisabled={isDisabled}
            originSource={serviceSource || ""}
          />
        </>
      )}
    </>
  );
};

const IconStack = ({
  type,
  status,
  onClick,
}: {
  type:
    | "Screenshots"
    | "Canned notes"
    | "Screen record"
    | "Attachments"
    | "Notes & time";
  status: "active" | "disabled";
  onClick?: () => void;
}) => {
  const theme = useTheme();
  let Icon: React.ReactNode;
  const color =
    status === "active"
      ? theme.palette.lightBg.high
      : theme.palette.neutral["020"];
  switch (type) {
    case "Screenshots":
      Icon = <ScreenshotIcon htmlColor={color} />;
      break;
    case "Canned notes":
      Icon = <ListDocumentIcon htmlColor={color} />;
      break;
    case "Screen record":
      Icon = <RecordIcon htmlColor={color} />;
      break;
    case "Attachments":
      Icon = <AttachIcon htmlColor={color} />;
      break;
    case "Notes & time":
      Icon = <ClipboardIcon htmlColor={color} />;
      break;
    default:
      break;
  }

  return (
    <Stack
      onClick={onClick}
      justifyContent="center"
      alignItems="center"
      component="button"
      sx={{
        backgroundColor: "transparent",
        border: 0,
        cursor: status === "active" ? "pointer" : "auto",
      }}
    >
      {Icon}

      <Typography variant="body-small" color={color} noWrap>
        {type}
      </Typography>
    </Stack>
  );
};
