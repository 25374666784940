import * as yup from "yup";

export interface OperatingHours {
  day: string;
  start_time: string;
  end_time: string;
}
export interface UpdateMspInput {
  phone_number?: string;
  company_name?: string;
  brand_name?: string;
  data_center?: string;
  country?: number;
  state?: number;
  city?: string;
  postal_code?: string;
  website?: string;
  address1?: string;
  address2?: string;
  time_zone?: string;
  date_format?: string;
  operating_hours_from?: string;
  operating_hours_to?: string;
  latitude?: string;
  longitude?: string;
  billing_name?: string;
  billing_building_name?: string;
  billing_floor?: string;
  billing_country?: number;
  billing_state?: number;
  billing_city?: string;
  billing_postal_code?: string;
  default_currency?: string;
  mspOperatingHours?: Array<OperatingHours>;
  is_24x7?: boolean;
}

export interface UpdateMspCustomNameInput {
  custom_name?: string;
}

export interface BulkAddResourcesInput {
  userData?: { email?: string; resource_type?: string; name?: string }[];
}

export interface UpdateResourceInput {
  resourceId: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  phone_number?: string;
  office_desk_number?: string;
  office_location?: string;
  resource_work_type?: string;
  resource_work_role?: string;
  department?: string;
  is_locked?: boolean;
  manager?: string;
}

export interface UpdateResourceActiveInput {
  resourceId: string;
  isActive: boolean;
}

export interface UpdateResourceLockInput {
  resourceId: string;
  isLocked: boolean;
}

export interface CreateResourceInput {
  email: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  display_name?: string;
  resource_type: string;
  location?: string;
  resource_work_type?: string;
  time_zone: string;
}

export interface UploadProfilePictureInput {
  resourceId: string;
  formData: FormData;
}

export interface UpdateProfileDetailsInput {
  department: string;
  first_name: string;
  last_name: string;
  location: string;
  middle_name: string;
  office_desk_number: string;
  phone_number: string;
  resource_work_role: string;
  resource_work_type: string;
}

export interface ResourceResendInviteInput {
  email: string;
  tenantId: string;
}

export interface UpdateMspNotificationInput {
  notificationType: string;
  notificationStatus: boolean;
  notificationSound: boolean;
  notifyMethods: Array<string>;
  notifyRoles: Array<Record<string, string | null>>;
}

export interface UpdateMspBillingInput {
  mspId: string;
  data: {
    billingName?: string;
    billingAddressLine1?: string;
    billingAddressLine2?: string;
    billingCountry?: number | null;
    billingState?: number | null;
    billingCity?: string | null;
    billingZipCode?: string | null;
  };
}

export interface CreateLocationInput {
  location_name: string;
  contact_person?: string | null;
  location_description?: string | null;
  email: string;
  contact_number?: string | null;
  country: number;
  state?: number | null;
  city?: string | null;
  street?: string | null;
  postal_code: string;
  timezone: string;
}

export interface UpdateLocationInput extends CreateLocationInput {
  locationId: string;
}

const LocationResultSchema = yup.object().shape({
  mapping_id: yup.string().required(),
  location_name: yup.string().required(),
  contact_person: yup.string().nullable(),
  location_description: yup.string().nullable(),
  email: yup.string().email().required(),
  contact_number: yup.string().nullable(),
  country: yup.number().nullable(),
  state: yup.number().nullable(),
  city: yup.string().nullable(),
  street: yup.string().nullable(),
  postal_code: yup.string().nullable(),
  timezone: yup.string().nullable(),
  meta: yup
    .object()
    .shape({
      is_default: yup.boolean().nullable(),
    })
    .nullable(),
});

export const GetAllLocationsResponseSchema = yup.object().shape({
  totalCount: yup.string().required(),
  totalPages: yup.number().nullable(),
  result: yup.array(LocationResultSchema),
});

export type LocationResultType = yup.InferType<typeof LocationResultSchema>;
export type getAllLocationsResponseType = yup.InferType<
  typeof GetAllLocationsResponseSchema
>;
