function saveIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.993 14V7.828C20.993 7.298 20.782 6.789 20.407 6.414L17.579 3.586C17.203 3.211 16.695 3 16.164 3H5.00701C3.89701 3 2.99901 3.904 3.00701 5.015L3.11101 19.015C3.11901 20.114 4.01201 21 5.11101 21H13" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.993 3V6.909C15.993 7.461 15.545 7.909 14.993 7.909H8.99304C8.44104 7.909 7.99304 7.461 7.99304 6.909V3" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 21V13.286C7 12.576 7.576 12 8.286 12H15.715C16.424 12 17 12.576 17 13.286V14" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 18L18 21L16 19" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export default saveIcon;