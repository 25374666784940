import { Box, Stack } from "@mui/material";
import React, { useContext } from "react";
import { CustomButton, CustomTypography } from "../../../../../../library/base";
import {
  BottomStickyLayer,
  CustomCard,
} from "../../../../../../library/layers";
import ServicedeskGeneralForm from "./components/service_desk_general_form";
import { useState, useEffect, useRef } from "react";
import { services } from "../../../../../../services";
import { Utils } from "./Utilz";
import toaster from "react-hot-toast";
// Temporary Imports
import { ReturnContext } from "../../../../../../contexts/return-context";
import { useGlobalStore } from "controller/store/globalStore";

const severity = { ERROR: "error", SUCCESS: "success", WARNING: "warning" };

const ServiceDeskConfigurationGeneralComponent = (props) => {
  const setIsTicketTImeFormat24Hour = useGlobalStore(
    (state) => state.setIsTicketTImeFormat24Hour
  );
  const setIsTicketTimeZoneIsResource = useGlobalStore(
    (state) => state.setIsTicketTimeZoneIsResource
  );
  const mspId = useGlobalStore((state) => state.mspId);
  const { setEnableReturn } = useContext(ReturnContext);
  const formRef = useRef();
  let initialData; //used to reset changed values to initial state;
  const [state, setState] = useState({
    general_details: Utils.defaultStateBuilder(),
    // key: v4(),
    toaster: {
      open: false,
      severity: severity["SUCCESS"],
      message: "",
    },
  });

  const [stickyState, setStickyState] = useState(false);

  useEffect(() => {
    setEnableReturn(true);
    getMspConfiguration();
  }, [props]);

  const getMspConfiguration = () => {
    try {
      services.controlCenterservices.configurationService
        .fetchMspConfiguration(mspId)
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.success === true &&
              response?.statusCode === 200 &&
              response?.data
            ) {
              // console.log("API CALLED");
              initialData = Utils.stateBuilder(response.data);
              // console.log(initialData);
              setState({
                ...state,
                general_details: Utils.stateBuilder(response.data),
              });
            }
          },
          error: (error) => {
            console.log("[ERROR: API] Fetching configurations by mspId");
            console.log(error);
          },
        });
    } catch (error) {
      console.log("[ERROR: API] Fetching configurations by mspId");
      console.log(error);
    }
  };

  const updateMspConfiguration = (data) => {
    // console.log(data);
    try {
      services.controlCenterservices.configurationService
        .updateMspConfiguration(mspId, data)
        .subscribe({
          next: (response) => {
            // console.log("RESPONCE");
            // console.log(response);
            if (
              response &&
              response?.success === true &&
              response.statusCode === 200
            ) {
              setIsTicketTImeFormat24Hour(data?.is_time_format_is_12_hr);
              setIsTicketTimeZoneIsResource(data?.is_time_zone_is_resources);
              toaster.success(response?.message || "Saved configuration");
              return;
            }
            toaster.error("Unable to save configuration");
          },
          error: (error) => {
            console.log("[ERROR] Updating MSP Configuration");
            console.log(error);
            toaster.error("Unexpected error occurred");
          },
        });
    } catch (error) {
      console.log("[ERROR] Updating MSP Configuration");
      console.log(error);
      toaster.error("Unexpected error occurred");
    }
  };

  const onFormValueChange = () => {
    if (stickyState !== true) {
      setStickyState(true);
    }
  };

  const handleSubmit = () => {
    const data = formRef.current.submit();
    // console.log(data);
    updateMspConfiguration(data);
  };

  const handleCancel = () => {
    setState({
      ...state,
      general_details: initialData,
    });

    setStickyState(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          px: "32px",
          py: "24px",
          marginBottom: {
            xl: "140px",
            lg: "80px",
            md: "80px",
            sm: "60px",
          },
        }}
      >
        <CustomTypography
          content="General Configurations"
          size="MEDIUM-ALPHA"
          variant="BOLD"
        />
        <Box
          sx={{
            marginTop: "24px",
          }}
        >
          <ServicedeskGeneralForm
            // key={state.key}
            ref={formRef}
            data={state.general_details}
            onChange={onFormValueChange}
          />
        </Box>
        {stickyState === true ? (
          <BottomStickyLayer
            content={
              <Stack
                width={"100%"}
                flexGrow={0}
                flexShrink={0}
                flexDirection={"row-reverse"}
                px={"24px"}
                columnGap={"16px"}
              >
                <CustomButton label="Save" onClick={handleSubmit} />
                <CustomButton
                  label="Cancel"
                  varient="secondary"
                  onClick={handleCancel}
                />
              </Stack>
            }
          />
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export default ServiceDeskConfigurationGeneralComponent;
