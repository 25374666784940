import { getHomeScreenV2Input } from "shared";
import { axiosClient } from "utilz";
import { getTimeZone } from "utilz/helpers";

export const getUpcomingActivities = async (resourceId: string) => {
  try {
    const url = `/msp/upcoming_activities/${resourceId}`;
    const responce = await axiosClient.get(url);
    const respData = responce?.data;
    if (respData.success === true && respData.statusCode === 200) {
      return respData.data;
    }
    throw new Error(respData.message);
  } catch (e) {
    throw e;
  }
};

export const getGeneralStatus = async (filter: string, timezone: string) => {
  try {
    const url = `/msp/home-screen/general/counts?filter=${filter}&time_zone=${timezone}`;
    const responce = await axiosClient.get(url);
    const respData = responce?.data;
    if (respData.success === true && respData.statusCode === 200) {
      return respData.data;
    }
    throw new Error(respData.message);
  } catch (e) {
    throw e;
  }
};

export const getOverview = async (
  resourceId: string,
  filter: string,
  timezone: string
) => {
  try {
    const url = `/msp/total-tickets-by-status/${resourceId}?filter=${filter}&time_zone=${timezone}`;
    const responce = await axiosClient.get(url);
    const respData = responce?.data;

    if (respData.success === true && respData.statusCode === 200) {
      return respData.data;
    }
    throw new Error(respData.message);
  } catch (e) {
    throw e;
  }
};

export const getTotalWorkHours = async (
  resourceId: string,
  filter: string,
  timezone: string
) => {
  try {
    const url = `/msp/total-hours-worked/${resourceId}?filter=${filter}&time_zone=${timezone}`;
    const responce = await axiosClient.get(url);
    const respData = responce?.data;

    if (respData.success === true && respData.statusCode === 200) {
      return respData.data;
    }
    throw new Error(respData.message);
  } catch (e) {
    throw e;
  }
};

export const getTotalService = async (
  resourceId: string,
  filter: string,
  timezone: string
) => {
  try {
    const url = `/msp/total-services-time-added/${resourceId}?filter=${filter}&time_zone=${timezone}`;
    const responce = await axiosClient.get(url);
    const respData = responce?.data;

    if (respData.success === true && respData.statusCode === 200) {
      return respData.data;
    }
    throw new Error(respData.message);
  } catch (e) {
    throw e;
  }
};

export const getTodaysTickets = async (timezone: string) => {
  try {
    const url = `/ticket/today?time_zone=${timezone}`;
    const responce = await axiosClient.get(url);
    const respData = responce?.data;

    if (respData.success === true && respData.statusCode === 200) {
      return respData.data;
    }
    throw new Error(respData.message);
  } catch (e) {
    throw e;
  }
};

export const getHomeScreenV2 = async ({
  resourceId,
  filter,
  timezone,
}: getHomeScreenV2Input) => {
  try {
    const url = `/v2/servicedesk/msp/home_screen_dashboard/${resourceId}?filter=${filter}&time_zone=${timezone}`;
    const response = await axiosClient.get(url);
    const resData = response?.data;
    if (resData?.success === true && resData?.statusCode === 200) {
      return resData?.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (e) {
    throw e;
  }
};
