import { Box, Menu, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { CreateTagTile } from "./CreateTagTile";
import { useUpdateAttributeTag } from "controller";
import { Vertical3DotsIcon } from "components";
import toast from "react-hot-toast";
import { ConfigurationMenuItem } from "../../../Components";
interface TagsTableTileProps {
  isEditable: boolean;
  data: any;
}
export const TagsTableTile = ({
  data,
  isEditable = false,
}: TagsTableTileProps) => {
  const anchorRef = useRef();
  const [state, setState] = useState({
    isEdit: false,
    isMenuOpen: false,
  });

  const { mutateAsync: upadteAttributetag } = useUpdateAttributeTag();
  // const { mutateAsync: deleteAttributeTag } = useDeleteAttributeTag();

  const updateTagHandler = (newData: any) => {
    upadteAttributetag({ tagId: data.mapping_id, data: newData })
      .then((response) => {
        setState({
          ...state,
          isEdit: false,
          isMenuOpen: false,
        });
        toast.success("Updated successfully");
      })
      .catch((error) => {
        toast.error("Unable to update");
      });
  };

  const deleteTagHandler = () => {
    // deleteAttributeTag(data.mapping_id)
    //   .then((response) => {
    //     setState({
    //       ...state,
    //       isEdit: false,
    //       isMenuOpen: false,
    //     });
    //     toast.success("Deleted successfully");
    //   })
    //   .catch((error) => {
    //     toast.error("Unable to delete");
    //   });
  };

  return (
    <Box>
      {state.isEdit === true ? (
        <CreateTagTile
          data={data?.tag_name || ""}
          onSubmit={updateTagHandler}
          onCancel={() => {
            setState({
              ...state,
              isEdit: false,
              isMenuOpen: false,
            });
          }}
        />
      ) : (
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            px: "24px",
            py: "24px",
            justifyContent: "space-between",
            borderBottom: "1px solid #cccccc",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              flex: "1",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography variant="body-medium">
              {data?.tag_name || ""}
            </Typography>
          </Box>
          {isEditable === true ? (
            <Box
              ref={anchorRef}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setState({
                  ...state,
                  isMenuOpen: true,
                });
              }}
            >
              <Vertical3DotsIcon />
            </Box>
          ) : null}

          <Menu
            open={isEditable === true && state.isMenuOpen === true}
            anchorEl={state.isMenuOpen === true ? anchorRef.current : null}
            onClose={() => {
              setState({
                ...state,
                isMenuOpen: false,
              });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                gap: "1px",
                p: 0,
                m: 0,
                border: "1px solid #EDEDED",
                minWidth: "180px",
                borderRadius: "0px  0px 8px 8px",
                color: "black",
                boxShadow:
                  "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                "& .MuiMenu-list": {
                  padding: "4px 0",
                },
                "& .MuiMenuItem-root": {
                  borderTop: "1px solid #EDEDED",
                  p: "16px",
                  "&:hover": {
                    backgroundColor: "#F3F5F9",
                  },
                },
              },
            }}
          >
            <ConfigurationMenuItem
              content="Edit"
              onClick={() => {
                setState({
                  ...state,
                  isEdit: true,
                  isMenuOpen: false,
                });
              }}
            />

            <ConfigurationMenuItem
              content="Delete"
              onClick={deleteTagHandler}
            />
          </Menu>
        </Stack>
      )}
    </Box>
  );
};

TagsTableTile.defaultProps = {
  isEditable: false,
  data: {},
  onRefresh: () => {},
};

export default TagsTableTile;
