export const GENERAL_FORM_FIELDS = {
  resource_type_name: {
    key: "form-field-resource_type_name-key",
    name: "resource_type_name",
    mappingId: "resource_type_name",
    type: "text",
    label: "Resource Type",
    placeholder: "Enter resource type",
    width: {xs: 12, md: 12},
    // hidden: false,
  },
  resource_type_description: {
    key: "form-field-resource_type_description-key",
    name: "resource_type_description",
    mappingId: "resource_type_description",
    type: "textarea",
    label: "Description",
    placeholder: "Enter description",
    width: {xs: 12, md: 12},
    // hidden: false,
  },
};