import { AllTicketResponse } from "shared";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface SelectTicketState {
  selectedTickets: Array<string>;
  selectedTicketData: Array<AllTicketResponse>;
}

interface SelectTicketAction {
  toggleSelectedTicket: (id: string, data: AllTicketResponse) => void;
  clearSelectedTickets: () => void;
}
export interface SelectTicketInterface
  extends SelectTicketState,
    SelectTicketAction {}

export const selectTicketsSlice = immer<SelectTicketState & SelectTicketAction>(
  (set) => ({
    selectedTickets: [],
    selectedTicketData: [],
    toggleSelectedTicket: (id, data) =>
      set((state) => {
        const index = state.selectedTickets.findIndex(
          (selectedId) => selectedId === id
        );
        if (index !== -1) {
          state.selectedTickets.splice(index, 1);
          state.selectedTicketData.splice(index, 1);
        } else {
          state.selectedTickets.push(id);
          state.selectedTicketData.push(data);
        }
      }),
    clearSelectedTickets: () =>
      set((state) => {
        state.selectedTicketData = [];
        state.selectedTickets = [];
      }),
  })
);
