import {
  AttachIcon,
  BulletPointsIcon,
  Button,
  Modal,
  ProjectBillingModal,
  Stepper,
} from "components";
import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { ProjectTemplateStep2 } from "./ProjectTemplateStep2";
import { ProjectTemplateStep1 } from "./ProjectTemplateStep1";
import { ProjectTemplateStep3 } from "./ProjectTemplateStep3";
import { useMainStore } from "controller";
import { AttachmentFileData } from "shared";
import { AttachmentsModal } from "components/modal/AttachmentsModal";

export const CreateProjectTemplateModal = ({
  onSubmit,
  isProjectModalOpen = false,
  data,
  onClose,
  isLoading = false,
  isEdit = false,
}: {
  onSubmit: (data: any) => void;
  isProjectModalOpen: boolean;
  data?: any;
  onClose: () => void;
  isLoading: boolean;
  isEdit: boolean;
}) => {
  const theme = useTheme();
  const [projectData, setProjectData] = useState<any>(data || {});
  const attachmentData = useMainStore(
    (state) => state.attachmentModalData.attachments
  );
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const addAttachment = useMainStore((state) => state.setAttachmentModalData);

  const resetAttachment = useMainStore(
    (state) => state.resetAttachmentModalData
  );

  const [billModalOpen, setBillModalOpen] = useState(false);
  const [step, setStep] = useState(1);

  const step1Ref = useRef<any>();
  const step2Ref = useRef<any>();
  const step3Ref = useRef<any>();
  const onNextClick = () => {
    if (step === 1) {
      if (step1Ref.current) {
        step1Ref?.current?.onSubmit();
      }
    } else if (step === 2) {
      step2Ref.current?.onSubmit();
    } else if (step === 3) {
      step3Ref.current?.onSubmit();
    }
  };

  useEffect(() => {
    const attachments: Array<AttachmentFileData> = (
      Array.isArray(data.attachments) ? data.attachments : []
    ).map((attachment: any) => ({
      key: attachment.mapping_id,
      name: attachment.original_name,
      size: attachment.size,
      type: attachment.file_type,
      status: "Uploaded",
      progress: 100,
      controller: null,
      file: "",
      responseData: {
        mapping_id: attachment.mapping_id,
        original_name: attachment.original_name,
        file_type: attachment.file_type,
        size: attachment.size,
        url: attachment.url,
      },
    }));

    addAttachment(attachments);
    return () => {
      resetAttachment();
    };
  }, []);

  const onDataChange = (data: any) => {
    setProjectData((prev: any) => ({ ...prev, ...data }));
  };

  return (
    <>
      <Modal
        open={isProjectModalOpen}
        height="md"
        title={
          isEdit ? "Update Project Template" : "Create New Project Template"
        }
        primaryButtonText={step === 3 ? (isEdit ? "Update" : "Create") : "Next"}
        secondaryButtonText={step === 1 ? "Cancel" : "Back"}
        onPrimaryButtonClick={onNextClick}
        onClose={onClose}
        isPrimaryButtonDisabled={isLoading}
        onSecondaryButtonClick={() => {
          if (step === 1) {
            return onClose();
          }
          setStep(step - 1);
        }}
        additionalHeaderComponent={
          <Box
            sx={{
              px: "32px",
              py: "16px",
              borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
            }}
          >
            <Stepper step={step} totalSteps={3} />
          </Box>
        }
        footerStyles={{
          borderTop: `1px solid ${theme.palette.neutral["015"]}`,
        }}
        footerLeftActionComponent={
          <Stack gap={"24px"} direction="row">
            {/* {projectData?.is_billable && (
              <Button
                variant="secondary"
                endIcon={<BulletPointsIcon />}
                onClick={() => {
                  setBillModalOpen(true);
                }}
              >
                Billing info
              </Button>
            )} */}
          </Stack>
        }
        footerRightActionComponent={
          <Box sx={{ position: "relative" }}>
            {attachmentData && attachmentData?.length > 0 && (
              <Box
                width={"20px"}
                height={"16px"}
                bgcolor={"red"}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  zIndex: 2,
                }}
              >
                <Typography variant="caption-3" color="#FFF">
                  {attachmentData?.length}
                </Typography>
              </Box>
            )}
            <IconButton onClick={() => setAttachmentModalOpen(true)}>
              <AttachIcon htmlColor={theme.palette.purple.main} />
            </IconButton>
          </Box>
        }
      >
        {step === 1 ? (
          <ProjectTemplateStep1
            ref={step1Ref}
            onSubmitData={(data, change) => {
              setProjectData((oldData: any) => ({
                ...oldData,
                ...data,
              }));
              change && setStep(2);
            }}
            projectData={projectData}
          />
        ) : step === 2 ? (
          <ProjectTemplateStep2
            ref={step2Ref}
            onSubmitData={(data: any) => {
              setProjectData((oldData: any) => ({
                ...oldData,
                ...data,
              }));
              setStep(3);
            }}
            projectData={projectData}
          />
        ) : (
          step === 3 && (
            <ProjectTemplateStep3
              ref={step3Ref}
              onSubmitData={(data: any) => {
                const attachment =
                  attachmentData?.length && attachmentData.length > 0
                    ? attachmentData?.map((file) => file.responseData)
                    : [];
                onSubmit({ ...projectData, ...data, attachments: attachment });
                setProjectData((oldData: any) => ({
                  ...oldData,
                  ...data,
                  attachments: attachment,
                }));
              }}
              projectData={projectData}
            />
          )
        )}
      </Modal>
      <ProjectBillingModal
        data={projectData}
        onSubmit={(data: any) => {
          onDataChange(data);
          setBillModalOpen(false);
        }}
        isOpen={billModalOpen}
        onClose={() => setBillModalOpen(false)}
      />

      {attachmentModalOpen && (
        <AttachmentsModal
          open={attachmentModalOpen}
          onClose={() => setAttachmentModalOpen(false)}
          serviceType="project"
          attachmentType="general"
        />
      )}
    </>
  );
};
