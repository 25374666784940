import { GetQueryProps } from "shared";
import { axiosClient } from "utilz";
import { generateUrlString, getQueryDataObject } from "utilz/helpers";

export const getAllProductConfigs = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });
    const url = await generateUrlString({
      baseUrl: "/v1/configuration/billing/products",
      data,
    });
    const response = await axiosClient.get(url);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const createProductConfig = async (data: any) => {
  try {
    const url = "/v1/configuration/billing/products";
    const response = await axiosClient.post(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteProductConfig = async (data: { product_ids: string[] }) => {
  try {
    const url = "/v1/configuration/billing/products";
    const response = await axiosClient.put(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateProductConfig = async (data: any) => {
  try {
    const url = `/v1/configuration/billing/products/${data.id}`;
    const response = await axiosClient.patch(url, data?.data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
