import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import {
  clearAllNotifications,
  getAllChatNotifications,
  getAllNotifications,
  markAllNotificationsAsSeen,
  updateChatNotificationStatus,
  updateNotificationStatus,
} from "controller/api/notification";
import { GetNotificationsInput, UpdateNotificationStatusInput } from "shared";

export const useGetAllNotification = ({
  resourceId,
  pageNumber,
  perPageCount,
}: GetNotificationsInput) => {
  return useInfiniteQuery(
    ["notifications", resourceId],
    ({ pageParam = 1 }) =>
      getAllNotifications({
        resourceId,
        perPageCount,
        pageNumber: pageParam,
      }),

    {
      getNextPageParam: (lastPage, allPages) => {
        // console.log(lastPage.result.length);
        if (lastPage?.result?.length === perPageCount) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: resourceId ? true : false,
      onError: (error) => console.log(error),
    }
  );
};

export const useUpdateNotificationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateNotificationStatusInput) => updateNotificationStatus(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["notifications", variables.resourceId]);
      },
    }
  );
};

export const useGetAllChatNotification = ({
  resourceId,
  pageNumber,
  perPageCount,
}: GetNotificationsInput) => {
  return useQuery({
    queryKey: ["chat_notifications", resourceId],
    queryFn: () =>
      getAllChatNotifications({ resourceId, perPageCount, pageNumber }),
    enabled: resourceId ? true : false,
    onError: (error) => console.log(error),
  });
};

export const useUpdateChatNotificationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateNotificationStatusInput) =>
      updateChatNotificationStatus(value),
    {
      onSuccess(data, variables, context) {
        // refech the chat notifications (but it will remove seen messages from the list)
        // queryClient.invalidateQueries([
        //   "chat_notifications",
        //   variables.resourceId,
        // ]);
      },
    }
  );
};

export const useClearAllNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ notificationType }: { notificationType: string; resourceId: string }) =>
      clearAllNotifications(notificationType),
    {
      onSuccess(data, variables, context) {
        if (variables.notificationType === "chat") {
          queryClient.invalidateQueries([
            "chat_notifications",
            variables.resourceId,
          ]);
        } else {
          queryClient.invalidateQueries([
            "notifications",
            variables.resourceId,
          ]);
        }
      },
    }
  );
};

export const useMarkAllNotificationsAsSeen = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ notificationType }: { notificationType: string; resourceId: string }) =>
      markAllNotificationsAsSeen(notificationType),
    {
      onSuccess(data, variables, context) {
        if (variables.notificationType === "chat") {
          queryClient.invalidateQueries([
            "chat_notifications",
            variables.resourceId,
          ]);
        } else {
          // queryClient.invalidateQueries([
          //   "notifications",
          //   variables.resourceId,
          // ]);
        }
      },
    }
  );
};
