export const MOCK_DATA = [
  {
    key: "RESOURCE-TYPE-1",
    mapping_id: "super-admin",        
    resource_type_name: "Super Admin",
    resource_type_description: "This is a super-admin resource type.This is a default resource-type. Can only be viewed. Its description can be changed but name is not editable and can not be deleted",
    disableSelection: true,
  },
  {
    key: "RESOURCE-TYPE-2",
    mapping_id: "admin",        
    resource_type_name: "Admin",
    resource_type_description: "This is an admin resource-type.This is a default resource-type. Can only be viewed. Its description can be changed but name is not editable and can not be deleted",
    disableSelection: true,
  },
  {
    key: "RESOURCE-TYPE-3",
    mapping_id: "resource",        
    resource_type_name: "Resource",
    resource_type_description: "This is a resource resource-type.This is a default resource-type. Can only be viewed. Its description can be changed but name is not editable and can not be deleted",
    disableSelection: true,
  },
  {
    key: "RESOURCE-TYPE-4",
    mapping_id: "general",        
    resource_type_name: "General",
    resource_type_description: "This is a general resource-type.This is not a default resource-type. Thus they can be viewed, deleted or edited",
    disableSelection: false,
  },
  {
    key: "RESOURCE-TYPE-5",
    mapping_id: "on-contract",        
    resource_type_name: "On Contract",
    resource_type_description: "This is an on-contract resource-type.This is not a default resource-type. Thus they can be viewed, deleted or edited",
    disableSelection: false,
  },
  {
    key: "RESOURCE-TYPE-6",
    mapping_id: "manager",        
    resource_type_name: "Manager",
    resource_type_description: "This is a manager resource-type.This is not a default resource-type. Thus they can be viewed, deleted or edited",
    disableSelection: false,
  },
  {
    key: "RESOURCE-TYPE-7",
    mapping_id: "investor",        
    resource_type_name: "Investor",
    resource_type_description: "This is an investor resource-type.This is not a default resource-type. Thus they can be viewed, deleted or edited",
    disableSelection: false,
  },
  {
    key: "RESOURCE-TYPE-8",
    mapping_id: "trainee",        
    resource_type_name: "Trainee",
    resource_type_description: "This is a trainee resource-type.This is not a default resource-type. Thus they can be viewed, deleted or edited",
    disableSelection: false,
  },
];