function jpgDocumentIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 27 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M18.6227 6.56534C17.8937 6.56499 17.1946 6.27522 16.679 5.75969C16.1635 5.24417 15.8737 4.54507 15.8734 3.81601V0H3.58803C3.11673 -3.25414e-08 2.65006 0.0928451 2.21465 0.273243C1.77925 0.45364 1.38362 0.71805 1.05043 1.05137C0.71723 1.38469 0.45299 1.78039 0.272755 2.21586C0.0925188 2.65133 -0.00017489 3.11805 2.47719e-07 3.58934V25.2174C-0.00017489 25.6887 0.0925188 26.1554 0.272755 26.5908C0.45299 27.0263 0.71723 27.422 1.05043 27.7553C1.38362 28.0886 1.77925 28.3531 2.21465 28.5335C2.65006 28.7139 3.11673 28.8067 3.58803 28.8067H18.3347C18.806 28.8067 19.2727 28.7139 19.7081 28.5335C20.1435 28.3531 20.5391 28.0886 20.8723 27.7553C21.2055 27.422 21.4697 27.0263 21.65 26.5908C21.8302 26.1554 21.9229 25.6887 21.9227 25.2174V6.56668L18.6227 6.56534Z"
          fill={props.disabled === true ? props.disabledcolor : props.color} />
      </g>
      <path
        d="M21.9224 6.56534H18.6224C17.8933 6.56499 17.1942 6.27522 16.6787 5.75969C16.1632 5.24417 15.8734 4.54507 15.873 3.81601V0L21.9224 6.56534Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M24.5243 22.7461H7.85228C6.66965 22.7461 5.71094 23.7048 5.71094 24.8874V29.8581C5.71094 31.0407 6.66965 31.9994 7.85228 31.9994H24.5243C25.7069 31.9994 26.6656 31.0407 26.6656 29.8581V24.8874C26.6656 23.7048 25.7069 22.7461 24.5243 22.7461Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path d="M11.2286 28.4492V29.4199H10.2246V28.4492H11.2286Z" fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M14.209 25.4102V28.1488C14.209 28.5693 14.0948 28.8924 13.8663 29.1182C13.7388 29.2358 13.5889 29.3265 13.4256 29.3849C13.2623 29.4433 13.0888 29.4682 12.9157 29.4582C12.726 29.4683 12.5362 29.4408 12.3572 29.3772C12.1783 29.3136 12.0137 29.2151 11.873 29.0875C11.6161 28.8395 11.4872 28.4839 11.4863 28.0208H12.445C12.445 28.3955 12.5876 28.5822 12.8703 28.5822C13.117 28.5822 13.2397 28.4382 13.2397 28.1515V25.4128L14.209 25.4102Z"
        fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M15.817 28.0128V29.4195H14.8477V25.4102H16.4183C16.8939 25.4102 17.2565 25.5279 17.5063 25.7635C17.6327 25.8874 17.7315 26.0366 17.796 26.2014C17.8606 26.3661 17.8896 26.5427 17.881 26.7195C17.8846 26.9525 17.8272 27.1823 17.7143 27.3862C17.5982 27.5849 17.4256 27.7446 17.2183 27.8448C16.9687 27.9633 16.6945 28.0209 16.4183 28.0128H15.817ZM16.8943 26.7195C16.8943 26.3639 16.6997 26.1862 16.3103 26.1862H15.817V27.2302H16.3103C16.6997 27.2337 16.8943 27.0635 16.8943 26.7195V26.7195Z"
        fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M21.4776 25.7306C21.8005 25.9734 22.0177 26.3309 22.0843 26.7293H21.0577C20.9882 26.5898 20.8786 26.4741 20.743 26.3973C20.5903 26.3066 20.4152 26.2604 20.2376 26.264C20.0998 26.2583 19.9624 26.2831 19.8352 26.3366C19.708 26.3901 19.5941 26.4709 19.5016 26.5733C19.3061 26.8065 19.2065 27.1054 19.223 27.4093C19.223 27.7906 19.3203 28.0835 19.515 28.288C19.6244 28.3941 19.7549 28.4762 19.8979 28.529C20.041 28.5818 20.1935 28.6042 20.3456 28.5946C20.5632 28.5986 20.7761 28.5317 20.9523 28.404C21.1322 28.2696 21.2622 28.0791 21.3217 27.8626H20.0963V27.1653H22.1323V28.1239C22.0545 28.3626 21.9314 28.584 21.7696 28.776C21.5945 28.9828 21.3766 29.1493 21.131 29.264C20.8533 29.3923 20.5502 29.4561 20.2443 29.4506C19.8723 29.4604 19.5043 29.3716 19.1776 29.1933C18.8788 29.0252 18.6355 28.7736 18.4776 28.4693C18.308 28.1398 18.2232 27.7732 18.231 27.4026C18.2243 27.0343 18.3091 26.6701 18.4776 26.3426C18.6353 26.039 18.8775 25.7875 19.175 25.6186C19.4969 25.4417 19.8597 25.3529 20.227 25.3613C20.6733 25.3418 21.1135 25.4718 21.4776 25.7306V25.7306Z"
        fill={props.color === "#FFFFFF" ? props.varient.default.color : "white"} />
      <path
        d="M13.9078 19.5199H8.01843C7.44189 19.5192 6.88916 19.2899 6.48148 18.8822C6.0738 18.4745 5.84446 17.9218 5.84375 17.3453V11.4559C5.84446 10.8794 6.0738 10.3267 6.48148 9.91898C6.88916 9.5113 7.44189 9.28196 8.01843 9.28125H13.9078C14.4842 9.28196 15.0368 9.51135 15.4442 9.91906C15.8517 10.3268 16.0807 10.8795 16.0811 11.4559V17.3453C16.0807 17.9217 15.8517 18.4744 15.4442 18.8821C15.0368 19.2898 14.4842 19.5192 13.9078 19.5199ZM8.01843 10.2426C7.69674 10.2429 7.38831 10.3709 7.16084 10.5984C6.93337 10.8258 6.80543 11.1342 6.80508 11.4559V17.3453C6.80543 17.6669 6.93337 17.9754 7.16084 18.2028C7.38831 18.4303 7.69674 18.5582 8.01843 18.5586H13.9078C14.2293 18.5582 14.5376 18.4303 14.7649 18.2027C14.9921 17.9752 15.1198 17.6668 15.1198 17.3453V11.4559C15.1198 11.1344 14.9921 10.8259 14.7649 10.5984C14.5376 10.3709 14.2293 10.2429 13.9078 10.2426H8.01843Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M15.6002 16.2272V17.3472C15.5999 17.7962 15.4213 18.2267 15.1039 18.5442C14.7864 18.8616 14.3559 19.0402 13.9069 19.0405H8.01755C7.56845 19.0405 7.13775 18.8621 6.82018 18.5446C6.50262 18.227 6.32422 17.7963 6.32422 17.3472V15.4672C7.26689 15.2645 8.70288 15.2005 10.4455 15.8965L11.5256 14.8672L12.2615 16.7339C12.2615 16.7339 12.4589 16.0472 13.0962 16.1445C13.7335 16.2419 14.7655 16.5872 15.2069 16.2925C15.3258 16.2239 15.4655 16.2007 15.6002 16.2272V16.2272Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
      <path
        d="M13.5375 12.6609C13.899 12.6609 14.1922 12.3678 14.1922 12.0062C14.1922 11.6447 13.899 11.3516 13.5375 11.3516C13.1759 11.3516 12.8828 11.6447 12.8828 12.0062C12.8828 12.3678 13.1759 12.6609 13.5375 12.6609Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
    </svg>

  );
}
export default jpgDocumentIcon;