import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Landingpage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("app/home");
  };
  useEffect(() => {
    handleClick();
  }, []);
  return <React.Fragment></React.Fragment>;
};

export default Landingpage;
