import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { Input, Modal, SelectField } from "components";
import {
  useCreateResource,
  useGetAllLocations,
  useGetAllResourceTypes,
  useGetAllTimezone,
  useGetGeneralAttributes,
  useGlobalStore,
} from "controller";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { TimeZoneList } from "shared";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().email().required("Email is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required("Last name is required"),
  display_name: yup.string().nullable(),
  resource_type: yup.string().required("Resource type is required"),
  location: yup.string().nullable(),
  resource_work_type: yup.string(),
  resource_work_role: yup.string().required("Work role is required"),
  time_zone: yup.string().required("Time zone is required"),
});

export const CreateResourceModal = ({
  open = false,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const mspId = useGlobalStore((state) => state.mspId);
  const { mutateAsync: createResource, isLoading } = useCreateResource();
  const { data: workType } = useGetGeneralAttributes({
    attributeName: "work_type",
  });
  const { data: workRole } = useGetGeneralAttributes({
    attributeName: "work_role",
  });
  const resourceTypes = useGetAllResourceTypes({ mspId });
  const { data: location } = useGetAllLocations({});

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      display_name: "",
      resource_type: "",
      location: "",
      resource_work_type: "",
      resource_work_role: "",
      time_zone: "",
    },
    resolver: yupResolver(schema),
  });

  const firstName = watch("first_name");
  const middleName = watch("middle_name");
  const lastName = watch("last_name");

  useEffect(() => {
    const displayName = `${firstName || ""}${
      middleName.length > 0 ? ` ${middleName}` : ""
    } ${lastName || ""}`.trim();
    setValue("display_name", displayName);
  }, [firstName, middleName, lastName]);

  const onCreateClicked = handleSubmit(async (data) => {
    try {
      await createResource(data);
      toast.success("Resource created successfully");
      onClose();
    } catch (e: any) {
      toast.error(e?.message || "Something went wrong");
    }
  });

  return (
    <Modal
      open={open}
      maxWidth="md"
      onClose={onClose}
      title="Create Resource"
      primaryButtonText="Create"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onCreateClicked}
      onSecondaryButtonClick={onClose}
      isPrimaryButtonLoading={isLoading}
    >
      <Grid container columnSpacing={"24px"} rowSpacing={"16px"} p={"32px"}>
        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            name="email"
            label="Email ID / Login ID"
            control={control}
            required
            error={errors.email ? true : false}
            errorMessage={errors.email?.message || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            name="first_name"
            label="First name"
            control={control}
            required
            error={errors.first_name ? true : false}
            errorMessage={errors.first_name?.message || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            name="middle_name"
            label="Middle name"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            name="last_name"
            label="Last name"
            required
            control={control}
            error={errors.last_name ? true : false}
            errorMessage={errors.last_name?.message || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            name="display_name"
            label="Display name"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="resource_type"
            label="Resource type"
            required
            valueMappingKey="mapping_id"
            labelMappingKey="resource_type_name"
            options={
              Array.isArray(resourceTypes?.data?.result)
                ? resourceTypes?.data?.result
                : []
            }
            control={control}
            error={errors.resource_type ? true : false}
            errorMessage={errors.resource_type?.message || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="location"
            label="Location"
            valueMappingKey="mapping_id"
            labelMappingKey="location_name"
            options={
              location?.result && Array.isArray(location?.result)
                ? location.result
                : []
            }
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="resource_work_type"
            label="Work type"
            valueMappingKey="mapping_id"
            labelMappingKey="work_type_name"
            options={Array.isArray(workType) ? workType : []}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            label="Work role"
            name="resource_work_role"
            fullWidth
            required
            valueMappingKey="mapping_id"
            labelMappingKey="work_role_name"
            control={control}
            options={Array.isArray(workRole) ? workRole : []}
            error={errors.resource_work_role ? true : false}
            errorMessage={errors.resource_work_role?.message || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="time_zone"
            label="Time zone"
            isSearchable
            required
            isGrouped={true}
            groupedOptions={TimeZoneList}
            labelMappingKey="label"
            valueMappingKey="value"
            control={control}
            error={errors.time_zone ? true : false}
            errorMessage={errors.time_zone?.message || ""}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
