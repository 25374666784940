import React, {useState} from "react";

// Legacy Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

// HOC Imports
import { CustomTypography } from "../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../library/icon-library";

const MultiFactorAuthenticationInfoComponent = ()=> {
  // States
  const [state, setState] = useState({
    elevation: null,
    open: false
  });

  // Responsive Getter
  const getResponsiveIconSize = ()=> {
    const width = window.screen.width;
    try{
      if (width <= 1024) {
        return "sm";
      }
      if (width > 1024 && width <= 1440) {
        return "md";
      }
      if (width > 1440 && width <= 1604) {
        return "lg";
      }
      if (width > 1604) {
        return "xl";
      }
    }catch(err) {
      console.log("[ERROR] Getting Responsive Icon Button Sizes");
      console.log(err);
    }
  };

  return(
    <React.Fragment>
      <Menu
        anchorEl={state?.elevation}
        open={state?.open}
        anchorOrigin={{vertical: "bottom",horizontal: "right",}}
        transformOrigin={{vertical: "top",horizontal: "right",}}
        onClose={()=> setState({...state, elevation: null, open: false})}
        PaperProps = {{
          sx:{
            borderRadius: "8px",
            backgroundColor: "#000",
            minHeight: "88px",
            width: "320px",
            mt: "8px",
            ml: "72px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "12px",
            overflowWrap: "break-word"
          }
        }}
      >
        {/* <MenuItem key={'data'} >  */}
        <Stack spacing = {0} sx={{overflowWrap: "break-word", width: "100%"}}>
          {/* <CustomTypography
                            size = {'SMALL-BETA'}
                            color = {'PRIMARY-FOCUS'}
                            variant = {'REGULAR'}
                            content = {'Each time you attempt to log in to your account, you will be prompted to provide your password and the security code sent to you.'}
                        /> */}
          <p className="typography-font-color-primary-focus typography-font-size-class-small-beta typography-font-family-class-regular-override-ellipsis">
                            Each time you attempt to log in to your account, you will be prompted to provide your password and the security code sent to you.
          </p>
        </Stack>
        {/* </MenuItem> */}
      </Menu>


      <Box
        sx={{"&:hover": {cursor: "pointer"}}}
        onClick = {(event)=> setState({...state, elevation: event?.currentTarget, open: true})}
      >
        <CustomIcon
          size = {"lg"}
          icon = {"info"}
          color = {"#323232"}
        />
      </Box>
    </React.Fragment>
  );
};

export default MultiFactorAuthenticationInfoComponent;