import {
  AvatarGroup,
  Box,
  Collapse,
  Fade,
  Grow,
  Stack,
  Typography,
} from "@mui/material";
import { Avatar, EyeCloseIcon, PinFilledIcon } from "components";
import React, { useEffect, useMemo } from "react";
import AvatarChat from "./components/AvatarChat";
import { usePinnedStore } from "controller/store/pinned-chat/pinnedChatStore";
import { useChatNotificationStore, useGetAllPinnedService } from "controller";
import theme from "utilz/theme/theme";

const PinnedChat = () => {
  const pinnedChat = usePinnedStore((state) => state.pinnedChat);
  const setPinnedChat = usePinnedStore((state) => state.setPinnedChats);
  const { data: currentPinnedChats } = useGetAllPinnedService();
  const showPinnedChats = usePinnedStore((state) => state.showPinnedChats);
  const toggleShowPinnedChats = usePinnedStore(
    (state) => state.toggleShowPinnedChats
  );

  const allChatMessages = useChatNotificationStore(
    (state) => state.allChatNotifications
  );
  const totalPinnedChatMessageCount = useMemo(() => {
    return allChatMessages
      .filter((item) => Boolean(pinnedChat[item.service_id]))
      .reduce((acc, item) => acc + (item?.unseenCount || 0), 0);
  }, [pinnedChat, allChatMessages]);
  useEffect(() => {
    setPinnedChat(
      currentPinnedChats && Array.isArray(currentPinnedChats)
        ? currentPinnedChats
        : []
    );
  }, [currentPinnedChats]);

  return Object.entries(pinnedChat).length > 0 ? (
    <Stack
      sx={{
        width: "fit-content",
        height: "fit-content",
        position: "absolute",
        bottom: "0px",
        left: "100px",
        zIndex: 501,
        maxWidth: "calc(100vw - 100px)",
        overflowX: "hidden",
        overflowY: "hidden",
        gap: "8px",
      }}
    >
      <Grow in={showPinnedChats} timeout={500}>
        <Stack
          sx={{
            flexDirection: "row",
            maxWidth: "100%",
            overflowX: "scroll",
            pl: "16px",
            pr: "32px",
            transition: "width 0.2s ease-in-out , height 0.2s ease-in-out",
            width: showPinnedChats ? "fit-content" : "0px",
            height: showPinnedChats ? "fit-content" : "0px",
          }}
        >
          <AvatarGroup max={7}>
            {Object.entries(pinnedChat).map(([key, value]) => {
              return (
                <AvatarChat
                  key={key}
                  serviceId={key}
                  serviceType={value.serviceType}
                  summary={value.summary}
                  assignees={value.assignees}
                  serviceUid={value.serviceUid}
                  customerName={value.customerName}
                  customerUserName={value.customerUserName}
                  primaryColor={value.colorCodePrimary}
                  tintColor={value.colorCodeTint}
                  profileImage={value.profileImage}
                  serviceStatus={value.serviceStatus}
                />
              );
            })}

            {/* <AvatarChat
          serviceId="0976a50b-5ee7-4b8e-862b-197bcc497d1c"
          serviceType="ticket"
        /> */}
          </AvatarGroup>
        </Stack>
      </Grow>
      <Box
        sx={{
          height: "32px",
          width: "97px",
          display: "flex",
          background:
            "linear-gradient(278.39deg, #0C1324 20.44%, #00163F 86.57%)",
          borderWidth: "1.5px 1.5px 0px 1.5px",
          borderStyle: "solid",
          borderRadius: "16px 16px 0px 0px",
          borderImageSource:
            "linear-gradient(109.75deg, #1449F7 11.83%, #0C1224 39.99%)",
          cursor: "pointer",
        }}
      >
        {showPinnedChats ? (
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              px: "12px",
            }}
            onClick={toggleShowPinnedChats}
          >
            <EyeCloseIcon
              sx={{
                width: "16px",
                height: "16px",
                color: "white",
              }}
            />
            <Typography variant="caption-2" color={"white"}>
              Hide
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              px: "12px",
            }}
            onClick={toggleShowPinnedChats}
          >
            <PinFilledIcon
              sx={{
                width: "16px",
                height: "16px",
                color: "white",
              }}
            />
            <Typography variant="caption-2" color={"white"}>
              Chats
            </Typography>
            {totalPinnedChatMessageCount > 0 && (
              <Box
                sx={{
                  backgroundColor: "red",
                  borderRadius: "16px",
                  width: "fit-content",
                  height: "16px",
                  minWidth: "16px",
                  px: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "white" }} variant="caption-xs">
                  {totalPinnedChatMessageCount < 100
                    ? totalPinnedChatMessageCount
                    : "99+"}
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  ) : null;
};

export default PinnedChat;
// bgcolor: "linear-gradient(278.39deg, #0C1324 20.44%, #00163F 86.57%)",
// border: "1.5px solid",
// borderImageSource:
//   "linear-gradient(109.75deg, #1449F7 11.83%, #0C1224 39.99%)",
