function serverDatabasesCursorSquareIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1722 7.73809C14.1722 6.91607 12.3067 6.25 10.0055 6.25C7.70434 6.25 5.83887 6.91607 5.83887 7.73809V12.2619C5.83887 13.0839 7.70434 13.75 10.0055 13.75"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M14.1722 8.51172C14.1722 9.33374 12.3067 9.99981 10.0055 9.99981C7.70434 9.99981 5.83887 9.33374 5.83887 8.51172"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M9.94306 17.5H6.66667C4.36548 17.5 2.5 15.6345 2.5 13.3333V6.66667C2.5 4.36548 4.36548 2.5 6.66667 2.5H13.3333C15.6345 2.5 17.5 4.36548 17.5 6.66667V10.117"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8218 15.3272L16.3769 15.9344C16.1784 16.0178 16.0205 16.1757 15.9369 16.3742L15.3279 17.822C15.1912 18.1468 14.865 18.3505 14.5131 18.3308C14.1613 18.3111 13.8599 18.0723 13.7604 17.7342L12.5339 13.5687C12.4478 13.2761 12.5284 12.9598 12.7441 12.7441C12.9597 12.5285 13.276 12.4478 13.5686 12.5339L17.7341 13.7595C18.0722 13.8591 18.3111 14.1605 18.3308 14.5124C18.3505 14.8643 18.1467 15.1906 17.8218 15.3272Z"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M14.1667 7.73532V8.50914"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

export default serverDatabasesCursorSquareIcon;