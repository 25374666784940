import { fetchCall } from "../../../utilz";
import { appConfig } from "../../../constants";

// Fetch all Locations by MSP-ID [PAGINATED]
// const fetchAllLocationsByMspId = (
//         id,
//         perPageCount,
//         pageNumber,
//         sortEntity,
//         sortOrder,
//         filter
//     )=>
//     fetchCall(
//         `/controlCenter/fetch/msp_locations/${id}?per_page=${perPageCount}&page=${pageNumber}&sortColumn=${sortEntity}&sortDirection=${sortOrder}&filters=${filter?.exist === true? [{name: filter?.value}]: []}`,
//         appConfig.requestMethods.GET
//     );
const fetchAllLocationsByMspId = (
  id,
  perPageCount,
  pageNumber,
  sortEntity,
  sortOrder,
  filter
) => {
  let searchQuery = "";
  let paramsString = `per_page=${perPageCount}&page=${pageNumber}&sortColumn=${sortEntity}&sortDirection=${sortOrder}`;
  if (filter?.status === true) {
    searchQuery = filter?.queryString;
  }
  paramsString = `${paramsString}&filters=[]&searchQuery=${searchQuery}`;
  return fetchCall(
    `/controlCenter/msp_locations?${paramsString}`,
    appConfig.requestMethods.GET
  );
};

// Fetch all locations without pagination
// const fetchCompleteLocationsByMspId = (id)=>
//     fetchCall(
//         `/controlCenter/fetch/msp_locations/${id}`,
//         appConfig.requestMethods.GET
//     )

const fetchCompleteLocationsByMspId = (id) =>
  fetchCall(
    "/controlCenter/msp_locations?filters=[]",
    appConfig.requestMethods.GET
  );

// Create new location
const createNewLocation = (id, data) =>
  fetchCall(
    "/controlCenter/msp_location",
    appConfig["requestMethods"]?.POST,
    data
  );

const fetchLocationByLocationId = (id) =>
  fetchCall(
    `/controlCenter/msp_location/${id}`,
    appConfig["requestMethods"]?.GET
  );

// Delete Locations [BULK DELETE]
const deleteLocationsByIdList = (idList) =>
  fetchCall(
    "/controlCenter/delete/msp/location",
    appConfig["requestMethods"]?.PATCH,
    idList
  );

// Update MSP Location Details by MSP_LocationId
const updateLocationByLocationId = (id, data) =>
  fetchCall(
    `/controlCenter/location/${id}`,
    appConfig["requestMethods"]?.PATCH,
    data
  );

export const locationServices = {
  fetchAllLocationsByMspId,
  createNewLocation,
  fetchLocationByLocationId,
  deleteLocationsByIdList,
  updateLocationByLocationId,
  fetchCompleteLocationsByMspId,
};
