import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import PropTypes from "prop-types";

// Legacy Imports
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// HOC Imports
import { CustomTypography } from "../../../base";
import CustomRadio from "./custom-radio-button";
import { Typography } from "@mui/material";

const CustomRadioGroup = (props) => {
  // States
  // const [value, setValue] = useState('');

  // // Effects
  // useEffect(() => {
  //     try {
  //         setValue(props?.value);
  //     } catch (err) {
  //         console.log('[ERROR: MOUNT] CustomRadioGroup');
  //         console.log(err);
  //     }
  // }, [props]);

  // Trackers
  // useEffect(() => { props.handle({ name: props.name, value: value }) }, [value]);
  // useEffect(()=> {console.log(render)}, [render]);

  // Handlers
  // const handleChange = (event, newValue)=> setValue(newValue);
  const handleChange = (event, newValue) => {
    props.handle({ name: props.name, value: newValue });
  };

  // Renderer
  return (
    <FormControl>
      <RadioGroup
        row
        value={props?.value}
        name={props?.name || "customized-radios"}
        onChange={handleChange}
      >
        {props?.options.map((element) => {
          return (
            <FormControlLabel
              key={element?.key}
              value={element?.value}
              disabled={
                props?.disabled === undefined
                  ? element?.disabled
                  : props?.disabled
              }
              control={<CustomRadio />}
              label={
                // <CustomTypography
                //   size={props.typographyProp?.size ?? "SMALL-ALPHA"}
                //   color={props.typographyProp?.color ?? "PRIMARY-MAIN"}
                //   variant={props.typographyProp?.varient ?? "REGULAR"}
                //   content={element?.label}
                // />
                <Typography variant="body-medium">{element?.label}</Typography>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

CustomRadioGroup.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  handle: PropTypes.func.isRequired,
  typographyProp: PropTypes.object,
};
CustomRadioGroup.defaultProps = {
  name: "",
  options: [],
  value: "",
  typographyProp: {},
  handle: () => {},
};

export default CustomRadioGroup;
