import { Box, Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CustomButton,
  CustomTypography,
} from "../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../library/icon-library";
import { CONSTANT, defaultValueBuilder, newValueBuilder } from "./constant";
import { CustomRoundCloseButton } from "../../../Components";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "components";
const schema = yup.object({
  board_name: yup.string().required("Board name is required"),
  board_description: yup.string().required("Board description is required"),
});

function BoardCreateModal(props) {
  const [state, setState] = useState({
    fullScreen: false,
    isOpen: false,
    data: props.selectedData || defaultValueBuilder,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      board_name: props.selectedData[CONSTANT.board_name.name] || "",
      board_description:
        props.selectedData[CONSTANT.board_description.name] || "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setState({
      ...state,
      isOpen: props.isOpen,
      data: props.selectedData,
    });
  }, [props]);

  const handleFullScreen = () => {
    setState({
      ...state,
      fullScreen: !state.fullScreen,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      isOpen: false,
      fullScreen: false,
    });
    props.onClose();
  };

  const handleSave = handleSubmit((data) => {
    if ("mapping_id" in props.selectedData) {
      data["mapping_id"] = props.selectedData["mapping_id"];
    }
    props.onSave(newValueBuilder(data));
  });

  return (
    <Dialog
      open={state.isOpen}
      maxWidth={"sm"}
      fullScreen={state.fullScreen}
      fullWidth={true}
      PaperProps={{
        style: {
          height: state.fullScreen === true ? "100%" : "fit-content",
          borderRadius: state.fullScreen === true ? "0px" : "24px",
        },
      }}
      onClose={props?.closeHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: "32px",
          rowGap: "22px",
          borderBottom: "1px solid #E3E3E3",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            columnGap: "40px",
          }}
        >
          <Box
            sx={{
              flexShrink: "1",
              flexGrow: "1",
            }}
          >
            {" "}
            <CustomTypography
              content={props.edit === true ? "Edit Board" : "Create New Board"}
              size="SMALL-BETA"
              variant="BOLD"
            />
          </Box>
          <Box
            sx={{
              "& :hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleFullScreen()}
          >
            <CustomIcon size={"md"} icon={"maximize"} color={"black"} />
          </Box>
          <CustomRoundCloseButton onClick={handleClose} />
        </Stack>
        <Input
          placeholder="Board name"
          disabled={props.isDisabled}
          name={CONSTANT.board_name.name}
          control={control}
          required={true}
          error={errors?.board_name}
          errorMessage={errors?.board_name?.message}
        />

        <Input
          name={CONSTANT.board_description.name}
          placeholder="Enter text here ..."
          disabled={props.isDisabled}
          control={control}
          multiline={true}
          required={true}
          isResponsive={false}
          minRows={4}
          maxRows={7}
          fullWidth={true}
          error={errors?.board_description}
          errorMessage={errors?.board_description?.message}
        />
      </Stack>
      <Stack
        flexGrow={0}
        flexShrink={0}
        flexDirection={"row-reverse"}
        px={"24px"}
        columnGap={"16px"}
        sx={{
          width: "100%",
          py: "16px",
          px: "32px",
        }}
      >
        <CustomButton
          label="Save"
          onClick={handleSave}
          disabled={props.isDisabled}
        />
        <CustomButton
          label="Cancel"
          varient="secondary"
          onClick={handleClose}
        />
      </Stack>
    </Dialog>
  );
}

BoardCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  edit: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

BoardCreateModal.defaultProps = {
  isOpen: false,
  onSave: () => {},
  onClose: () => {},
  selectedData: {},
  edit: false,
  isDisabled: false,
};

export default BoardCreateModal;
