import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createTicket,
  getAllTickets,
  getTicket,
  mergeTicket,
  bulkAssignTickets,
  bulkModifyTickets,
  changeBillableStatus,
  updateTicketStatus,
  updateTicketPriority,
  updateTicketDetails,
  deleteTicketAttachments,
  updateScheduleConnectTime,
  getTicketQaStatus,
  addProductToTicket,
  addExpenseToTicket,
} from "controller/api";
import { toast } from "react-hot-toast";
import {
  CreateTicketInput,
  GetQueryProps,
  MergeTicketInput,
  BulkAssignTicketInputProps,
  ChangeBillableStatusInput,
  UpdateTicketStatusInput,
  UpdateTicketPriorityInput,
  UpdateAdditionalTicketDetailsInput,
  DeleteTicketAttachmentInput,
  UpdateScheduleConnectTimeInput,
  ApiErrorType,
} from "shared";
import { addMixpanelEvent, debugConsole } from "utilz/helpers";
import { sentryLogger } from "utilz/logger/sentryLogger";

export const useGetAllTickets = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: [
      "tickets",
      pageNumber,
      searchQuery,
      filter,
      sortColumn,
      sortDirection,
      perPageCount,
    ],
    queryFn: () =>
      getAllTickets({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "getAllTickets",
      });
    },
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useGetTicket = (id?: string) => {
  return useQuery({
    queryKey: ["ticket", id],
    queryFn: () => getTicket(id || ""),
    enabled: id ? true : false,
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "getTicket",
      });
    },
  });
};

export const useCreateTicket = () => {
  const queryClient = useQueryClient();
  return useMutation((value: CreateTicketInput) => createTicket(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["tickets"]);
      addMixpanelEvent({
        event: "ticket_created",
        data: variables,
      });
    },
    onError: (error: ApiErrorType, variables) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "createTicket",
      });
      addMixpanelEvent({
        event: "ticket_creation_failed",
        data: variables,
      });
    },
  });
};

export const useMergeTicket = () => {
  const queryClient = useQueryClient();
  return useMutation((value: MergeTicketInput) => mergeTicket(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["ticket", variables.parentTcketId]);
    },
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "mergeTicket",
      });
    },
  });
};

export const useBulkAssignTicket = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: BulkAssignTicketInputProps) => bulkAssignTickets(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["tickets"]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "ticket",
          functionName: "bulkAssignTickets",
        });
      },
    }
  );
};

export const useBulkModifyTickets = () => {
  const queryClient = useQueryClient();
  return useMutation((value: any) => bulkModifyTickets(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["tickets"]);
      // FIXME: use another api for single ticket update
      queryClient.invalidateQueries(["ticket", variables.ticket_ids[0]]);
    },
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "bulkModifyTickets",
      });
    },
  });
};

export const useChangeBillableStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: ChangeBillableStatusInput) => changeBillableStatus(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["ticket", variables.ticketId]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "ticket",
          functionName: "changeBillableStatus",
        });
      },
    }
  );
};

export const useUpdateTicketStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateTicketStatusInput) => updateTicketStatus(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["ticket", variables.ticketId]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "ticket",
          functionName: "updateTicketStatus",
        });
      },
    }
  );
};

export const useUpdateTicketPriority = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateTicketPriorityInput) => updateTicketPriority(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["ticket", variables.ticketId]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "ticket",
          functionName: "updateTicketPriority",
        });
      },
    }
  );
};

export const useUpdateAdditionalDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateAdditionalTicketDetailsInput) => updateTicketDetails(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["ticket", variables.ticketId]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "ticket",
          functionName: "updateTicketDetails",
        });
      },
    }
  );
};

export const useDeleteTicketAttachments = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: DeleteTicketAttachmentInput) => deleteTicketAttachments(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["ticket", variables.ticketId]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "ticket",
          functionName: "deleteTicketAttachments",
        });
      },
    }
  );
};
export const useUpdateScheduleConnectTime = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateScheduleConnectTimeInput) => updateScheduleConnectTime(value),
    {
      onSuccess(data, variables, context) {
        toast.success("Updated schedule connect time");
        queryClient.invalidateQueries(["ticket", variables.ticketId]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "ticket",
          functionName: "updateScheduleConnectTime",
        });
      },
    }
  );
};

export const useGetTicketQaStatus = (ticketId?: string) => {
  return useQuery({
    enabled: !!ticketId,
    queryKey: ["ticket", "ticketQaStatus", ticketId],
    queryFn: () => getTicketQaStatus(ticketId || ""),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "getTicketQaStatus",
      });
    },
  });
};

export const useAddProductToTicket = () => {
  const queryClient = useQueryClient();
  return useMutation((value: any) => addProductToTicket(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["ticket", variables.service_id]);
    },
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "addProductToTicket",
      });
    },
  });
};

export const useAddExpenseToTicket = () => {
  const queryClient = useQueryClient();
  return useMutation((value: any) => addExpenseToTicket(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["ticket", variables.service_id]);
    },
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "ticket",
        functionName: "addExpenseToTicket",
      });
    },
  });
};
