import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";

// Constants Import
import TabsUnstyled from "@mui/base/TabsUnstyled";

import Box from "@mui/material/Box";
import { Tab, Tabs } from "components";
import { useTheme } from "@mui/material";

export const TABS = [
  {
    key: "EMAIL-TAB-KEY",
    label: "Email",
    route: "email",
  },
  {
    key: "CONVERSATION-TAB-KEY",
    label: "Teams",
    route: "teams",
  },
  {
    key: "MOBILE-TAB-KEY",
    label: "Mobile",
    route: "mobile",
  },
  {
    key: "CHAT-TAB-KEY",
    label: "System app",
    route: "chat",
  },

  // WEB: { key: "WEB-TAB-KEY", label: "Web", value: "WEB", route: "web" },
  // API_INTEGRATION: {
  //   key: "API_INTEGRATION-TAB-KEY",
  //   label: "API",
  //   value: "API_INTEGRATION",
  //   route: "api-integration",
  // },
];

const ChannelsPage = () => {
  const theme = useTheme();
  // Routing Constants
  const navigate = useNavigate();

  // Effects
  const currentUrl = window.location.href;
  const endpoint = currentUrl.split("/").pop();
  const getEndPointValue = (endpoint) => {
    switch (endpoint) {
      case "email":
        return 0;
      case "teams":
        return 1;
      case "mobile":
        return 2;
      case "chat":
        return 3;
      default:
        return 0;
    }
  };
  const endpointValue = getEndPointValue(endpoint);
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (endpointValue !== selectedTab) {
      setSelectedTab(endpointValue);
    }
  }, [endpointValue]);

  // Handlers
  const handleTabChange = (event, newTabValue) => {
    handleNavigate(TABS[newTabValue].route);
  };
  const handleNavigate = (newRoute) => navigate(newRoute);

  // Renderer
  return (
    <React.Fragment>
      <Tabs
        onChange={handleTabChange}
        value={selectedTab}
        sx={{
          px: "16px",
        }}
      >
        {TABS.map((tab) => (
          <Tab key={`channels_tab_${tab.key}`} label={tab.label} />
        ))}
      </Tabs>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 114px)",
          m: 0,
          p: 0,
          borderTop: `1px solid ${theme.palette.neutral["010"]}`,
        }}
      >
        <Outlet />
      </Box>
    </React.Fragment>
  );
};

export default ChannelsPage;
