import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  bulkDeleteResource,
  createResource,
  deleteResourceProfilePicture,
  getAllResources,
  getResourceById,
  getResourcePermissions,
  getResourceProficiency,
  getResourceStatusById,
  resourceResendInvite,
  resourceResetPassword,
  updateResource,
  updateResourceActive,
  updateResourceLock,
  updateResourceProficiency,
  uploadProfilePicture,
} from "controller/api";
import { toast } from "react-hot-toast";
import {
  ApiErrorType,
  CreateResourceInput,
  GetQueryProps,
  ResourceResendInviteInput,
  UpdateResourceActiveInput,
  UpdateResourceInput,
  UploadProfilePictureInput,
} from "shared";
import { sentryLogger } from "utilz";
import { debugConsole } from "utilz/helpers";

export const useGetAllResources = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["resources", searchQuery],
    queryFn: () =>
      getAllResources({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllResources",
      }),
  });
};

export const useGetResourceById = (resourceId: string) => {
  return useQuery({
    enabled: resourceId && resourceId !== null ? true : false,
    queryKey: ["resource", resourceId],
    queryFn: () => getResourceById(resourceId),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getResourceById",
      }),
  });
};

export const useCreateResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateResourceInput) => createResource(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["resource"]);
      queryClient.invalidateQueries(["resources"]);
      queryClient.invalidateQueries(["subscriptionDetails"]);
      queryClient.invalidateQueries(["activeCount"]);
    },
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "createResource",
      }),
  });
};

export const useUpdateResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updateResourceData: UpdateResourceInput) =>
      updateResource(updateResourceData),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["resource"]);
      queryClient.invalidateQueries(["resources"]);
      queryClient.invalidateQueries(["subscriptionDetails"]);
      queryClient.invalidateQueries(["activeCount"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateResource",
      });
    },
  });
};

export const useUploadProfilePicture = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (uploadProfilePictureData: UploadProfilePictureInput) =>
      uploadProfilePicture(uploadProfilePictureData),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["resource", variables.resourceId]);
      queryClient.invalidateQueries(["resources"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "uploadProfilePicture",
      });
    },
  });
};

export const useDeleteResourceProfilePicture = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteResourceProfilePicture,
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["resource", variables.resourceId]);
      queryClient.invalidateQueries(["resources"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteResourceProfilePicture",
      });
    },
  });
};

export const useGetResourceStatusById = (resourceId: string) => {
  return useQuery({
    initialData: true,
    enabled: resourceId && resourceId !== null ? true : false,
    queryKey: ["resource", resourceId, "status"],
    queryFn: () => getResourceStatusById(resourceId),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getResourceStatusById",
      }),
  });
};

export const useResourceResendInvite = () => {
  return useMutation({
    mutationFn: (data: ResourceResendInviteInput) => resourceResendInvite(data),
    onSuccess: (_, variables, __) => {},
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "resourceResendInvite",
      });
    },
  });
};

export const useResourceResetPassword = () => {
  return useMutation({
    mutationFn: resourceResetPassword,
    onError: (error: ApiErrorType, variables, context) => {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "resourceResetPassword",
      });
    },
  });
};

export const useGetResourcePermissions = (resourceId: string) => {
  return useQuery({
    queryKey: ["resource", resourceId, "permissions"],
    queryFn: () => getResourcePermissions(resourceId),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getResourcePermissions",
      }),
  });
};

export const useGetResourceProficiency = (resourceId: string) => {
  return useQuery({
    queryKey: ["resource", resourceId, "proficiency"],
    queryFn: () => getResourceProficiency(resourceId),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getResourceProficiency",
      }),
  });
};

export const useUpdateResourceProficiency = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: { resourceId: string; data: any }) =>
      updateResourceProficiency(values),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries([
        "resource",
        variables.resourceId,
        "proficiency",
      ]);
      queryClient.invalidateQueries(["resources"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateResourceProficiency",
      });
    },
  });
};

export const useBulkDeleteResources = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (resources: { resource_ids: Array<string> }) =>
      bulkDeleteResource(resources),
    onSuccess: (_, ___, __) => {
      queryClient.invalidateQueries(["resources"]);
      queryClient.invalidateQueries(["subscriptionDetails"]);
      queryClient.invalidateQueries(["activeCount"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "bulkDeleteResource",
      });
    },
  });
};

export const useUpdateResourceActive = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updateResourceActiveData: UpdateResourceActiveInput) =>
      updateResourceActive(updateResourceActiveData),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["resource"]);
      queryClient.invalidateQueries(["resources"]);
      queryClient.invalidateQueries(["subscriptionDetails"]);
      queryClient.invalidateQueries(["activeCount"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateResourceStatus",
      });
    },
  });
};

export const useUpdateResourceLock = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateResourceLock,
    onSuccess: () => {
      queryClient.invalidateQueries(["resource"]);
      queryClient.invalidateQueries(["resources"]);
      queryClient.invalidateQueries(["subscriptionDetails"]);
      queryClient.invalidateQueries(["activeCount"]);
    },
    onError: (err) => {
      debugConsole(err);
    },
  });
};
