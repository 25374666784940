import { Box, useTheme } from "@mui/material";
import {
  Button,
  CheckCircleIcon,
  CopyIcon,
  DownloadIcon,
  PlusIcon,
} from "components";
import {
  useGetDownloadLink,
  useGlobalStore,
  useSetUserAcknowledgement,
} from "controller";
import { useState } from "react";

const DownloadBtn = ({
  variant,
  disabled = false,
  closeDownloadDrawer,
}: {
  variant: string;
  disabled?: boolean;
  closeDownloadDrawer: () => void;
}) => {
  const theme = useTheme();

  const resourceId = useGlobalStore((state) => state.resourceId);
  const { mutateAsync: downloadLink } = useGetDownloadLink();
  const { mutateAsync: setAcknowledgement } = useSetUserAcknowledgement();

  const [showCopyLinkSuccess, setShowCopyLinkSuccess] = useState(false);

  const handleClick = async (variant: string, mode: string) => {
    const TEAMS_LINK = {
      data: "https://teams.microsoft.com/l/app/0c4388c5-bb07-433d-9ee1-d379351c6844?source=app-details-dialog",
    };
    const data = variant === "teams" ? TEAMS_LINK : await downloadLink(variant);
    if (mode === "download") {
      setAcknowledgement(resourceId || "");
      window.open(`${data?.data}`, "_blank");
      closeDownloadDrawer();
    } else if (mode === "copy_link") {
      navigator.clipboard.writeText(data?.data);
      setShowCopyLinkSuccess(true);
      setTimeout(() => setShowCopyLinkSuccess(false), 2000);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
      }}
    >
      <Button
        variant="primary"
        isResponsive={false}
        disabled={disabled}
        onClick={() => handleClick?.(variant, "download")}
        sx={{
          minWidth: "112px",
          borderRadius: "6px",
        }}
        endIcon={
          variant === "teams" ? <PlusIcon fontSize="large" /> : <DownloadIcon />
        }
      >
        {variant === "teams" ? "Add" : "Download"}
      </Button>
      <Button
        variant="secondary"
        isResponsive={false}
        size="small"
        disabled={disabled}
        onClick={() => handleClick?.(variant, "copy_link")}
        sx={{
          minWidth: showCopyLinkSuccess ? "124px" : "112px",
          borderRadius: "6px",
          border: `1px solid ${theme.palette.neutral["020"]}`,
        }}
        endIcon={
          showCopyLinkSuccess ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CopyIcon />
          )
        }
      >
        {showCopyLinkSuccess ? "Link Copied" : "Copy Link"}
      </Button>
    </Box>
  );
};

export { DownloadBtn };
