import React from "react";
import {
  LocalizationProvider,
  DesktopDatePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { CalendarIcon } from "components/icons";
import { useGlobalStore } from "controller/store/globalStore";
import { dayjs } from "utilz";

interface DateInputProps {
  sx?: SxProps<Theme>;
  value: Dayjs | string | null;
  label?: string;
  onChangeHandler: (value: Dayjs | null | string) => void;
  size?: "small" | "large" | "medium";
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: any;
  maxDate?: any;
  error?: boolean;
  timezone?: string;
  errorMessage?: string;
  hideHelperBox?: boolean;
  slotProps?: Record<string, any>;
}
export const DateInput = ({
  sx,
  value,
  onChangeHandler,
  label,
  size = "small",
  disabled = false,
  disableFuture = false,
  disablePast = false,
  minDate,
  maxDate,
  error = false,
  timezone,
  errorMessage,
  hideHelperBox = true,
  slotProps,
}: DateInputProps) => {
  const theme = useTheme();
  const dateFormat =
    useGlobalStore((state) => state.dateFormat) || "MM/DD/YYYY";

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={disabled}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        timezone={timezone}
        label={label}
        value={value ? dayjs(value) : undefined}
        format={dateFormat}
        onChange={(newValue) => onChangeHandler(newValue)}
        slots={{
          openPickerIcon: disabled ? Box : CalendarIcon,
        }}
        slotProps={{
          actionBar: {
            sx: { ...theme.typography.caption },
          },
          textField: {
            helperText: (
              <Box height={hideHelperBox ? "0px" : "16px"}>
                <Typography variant="caption-3" color="error">
                  {errorMessage}
                </Typography>
              </Box>
            ),
            size: size,
            error: error,
            // InputProps: {
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <CalendarIcon />
            //     </InputAdornment>
            //   ),
            // },
            sx: {
              ...theme.typography["body-medium"],
              "& .MuiOutlinedInput-root": {
                height: { sm: "32px", md: "40px", lg: "48px" },
                "& > fieldset": {
                  borderColor: theme.palette.neutral["020"],
                  borderRadius: "8px",
                },
                "& input::placeholder": { fontSize: "14px" },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                color: theme.palette.lightBg.high,
                "& > fieldset": { borderColor: theme.palette.purple.main },
              },
              "& label": {
                "&.Mui-focused": { color: theme.palette.purple.main },
              },
              ...sx,
            },
          },
          desktopPaper: {
            sx: {
              "& .MuiPickersDay-root": {
                borderRadius: "6px",
                ":hover": {
                  bgcolor: theme.palette.neutral["005"],
                },
                ...theme.typography["body-small"],
                "&.Mui-selected": {
                  backgroundColor: theme.palette.purple.main,
                  color: "#FFF",
                },
                "&.MuiPickersDay-today": {
                  border: `1px solid ${theme.palette.purple.main}`,
                },
              },
              borderRadius: "16px",
              boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
              // @ts-ignore
              boxShadow: "0px 8px 10px 1px rgba(0, 0, 0, 0.14)",
              // @ts-ignore
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
            },
          },
          ...slotProps,
        }}
      />
    </LocalizationProvider>
  );
};
