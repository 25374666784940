import { Box, Dialog, Grid, Stack, Typography } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { CustomRoundCloseButton } from "../../../../Components";
import {
  CustomButton,
  CustomTypography,
} from "../../../../../../../../../library/base";

import { useGlobalStore } from "controller/store/globalStore";
import { useGetAllChecklists } from "controller";
import { Modal, SearchInput } from "components";
import ChecklistLibraryItem from "./ChecklistLibraryItem";

interface ChecklistLibraryModalProps {
  isOpen: boolean;
  data: any[];
  onSubmit: (data: any[]) => void;
  onCancel: () => void;
}

export const ChecklistLibraryModal = forwardRef(
  (props: ChecklistLibraryModalProps, ref) => {
    const mspId = useGlobalStore((state) => state.mspId);
    const [state, setState] = useState({
      data: Array<any>([]),
      filteredData: Array<any>([]),
      selectedData: Array<any>([]),
    });
    const [search, setSearch] = useState("");

    const { data: checklist } = useGetAllChecklists({});

    useEffect(() => {
      if (Array.isArray(checklist?.result)) {
        setState({
          ...state,
          selectedData: props.data,
          data: checklist.result,
          filteredData: checklist.result,
        });
      }
    }, [checklist]);

    const handleUpdateSearch = (event: any) => {
      try {
        const newValue = event.target.value;
        setSearch(newValue);
        if (newValue.length > 0 && newValue.length <= 3) {
          return;
        }
        const filteredData = state.data.filter((item) =>
          item.checklist_title.toLowerCase().includes(newValue.toLowerCase())
        );
        setState({
          ...state,
          filteredData: [...filteredData],
        });
      } catch (err) {
        // console.log("[ERROR] Updating search");
        // console.log(err);
      }
    };

    const handleResetSearch = () => {
      setSearch("");
      setState({
        ...state,
        filteredData: state.data,
      });
    };

    const onCloseHandle = () => {
      setState({
        ...state,
        selectedData: props.data,
      });
      props.onCancel();
    };

    const onSubmitHandler = () => {
      props.onSubmit(state.selectedData);
    };

    const onSelectionChangeHandler = (data: any, isSelected: boolean) => {
      const selectedData = state.selectedData;
      if (isSelected === true) {
        selectedData.push(data);
      } else {
        const index = state.selectedData.findIndex(
          (item) => item.mapping_id === data.mapping_id
        );
        selectedData.splice(index, 1);
      }
      // console.log("🚀 ~ file: index.js:94 ~ onSelectionChangeHandler ~ selectedData:", selectedData)
      setState({
        ...state,
        selectedData: [...selectedData],
      });
    };

    return (
      <Modal
        open={props.isOpen}
        fullWidth={true}
        maxWidth={"md"}
        paperStyles={{ minHeight: "min(80%,900px)" }}
        title="Add from library"
        primaryButtonText="Add"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={onSubmitHandler}
        onSecondaryButtonClick={onCloseHandle}
        onClose={onCloseHandle}
        additionalHeaderComponent={
          <Box
            sx={{
              width: "100%",
              px: "24px",
              pb: "24px",
            }}
          >
            <SearchInput
              name={""}
              placeholder="Search"
              value={search}
              onChange={handleUpdateSearch}
              sx={{
                pt: "3px",
              }}
              fullWidth
              hideHelperBox
            />
          </Box>
        }
      >
        <Box
          sx={{
            padding: "0px 24px 24px 24px",
          }}
        >
          <Grid
            container
            columnSpacing={"24px"}
            rowSpacing={"24px"}
            height={"100%"}
          >
            {Object.values(state.filteredData).map((item) => (
              <Grid
                item
                key={`template-ticket-details-${item.mapping_id}`}
                {...{ xs: 6, sm: 4, md: 4, lg: 4, xl: 4 }}
              >
                <ChecklistLibraryItem
                  checklist={item}
                  isSelected={state.selectedData.includes(item)}
                  onSelectionChange={(isSelected) =>
                    onSelectionChangeHandler(item, isSelected)
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    );
  }
);
