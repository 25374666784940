import { Box, Grid, Stack } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomTypography,
  SizableTextField,
} from "../../../../../../library/base";
import { CustomCard } from "../../../../../../library/layers";
import { ReturnContext } from "../../../../../../contexts/return-context";
import { useGlobalStore } from "controller/store/globalStore";
import toast from "react-hot-toast";
import {
  Button,
  DeleteConfirmationModal,
  HomeScreenLayout,
  Pagination,
  PlusIcon,
  SearchInput,
} from "components";
import {
  useCreateSla,
  // useDeleteSla,
  useFetchSlaByIdUsingMutation,
  useGetAllSla,
  useUpdateSla,
} from "controller";
import { CreateSlaModal, SlaTiles } from "./components";
import { debugConsole } from "utilz/helpers";

export const CustomerConfigurationsSlaComponent = () => {
  const mspId = useGlobalStore((state) => state.mspId);
  const { setEnableReturn } = useContext<any>(ReturnContext);

  const { mutateAsync: createSla, isLoading: isCreateSlaLoading } =
    useCreateSla();
  const { mutateAsync: updateSla, isLoading: isUpdateSlaLoading } =
    useUpdateSla();
  // const { mutateAsync: deleteSla, isLoading: isDeleteSlaLoading } =
  //   useDeleteSla();

  const { mutateAsync: fetchSlaById } = useFetchSlaByIdUsingMutation();
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   searchQuery: "",
  // });

  const [modalState, setModalState] = useState<any>({
    selectedSla: {},
    open: false,
  });

  const [searchQuery, setSearchQuery] = useState("");

  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    id: "",
  });

  const { data: slaData, refetch: refetchSla } = useGetAllSla({
    sortColumn: "created_at",
    sortDirection: "asc",
    searchQuery: searchQuery,
  });

  useEffect(() => {
    setEnableReturn(true);
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0 && searchQuery.length <= 3) {
      return;
    }
    refetchSla();
  }, [searchQuery]);

  const fetchSlaByIdHandler = async (slaId: string) => {
    try {
      const response = await fetchSlaById(slaId);
      setModalState({
        open: true,
        selectedSla: response,
      });
    } catch (error) {
      toast.error("Unexpected error occured. Please try after sometime");
    }
  };

  const createSlaHandler = async (data: any) => {
    try {
      await createSla(data);
      toast.success("SLA is ready");
      setSearchQuery("");
      setModalState({
        selectedSla: {},
        open: false,
      });
    } catch (e: any) {
      toast.error(e?.message || "Unable to create SLA. try again later");
    }
  };

  const deleteSlaHandler = async (slaId: string) => {
    try {
      // await deleteSla(slaId);
      toast.success("Deleted SLA successfully");
    } catch (e: any) {
      toast.error(e?.message || "Unable to delete SLA. try again later");
    }
  };

  const UpdateSlaHandler = async (slaId: string, data: any) => {
    try {
      await updateSla({
        slaId,
        ...data,
      });
      setModalState({
        selectedSla: {},
        open: false,
      });
      toast.success("Updated SLA successfully");
    } catch (e: any) {
      toast.error(e?.message || "Unable to update SLA. try again later");
    }
  };

  const setModalVisbility = (isOpen: boolean) => {
    setModalState({
      open: isOpen,
      selectedSla: {},
    });
  };

  const onSaveHandler = (data: any) => {
    if (data?.mapping_id !== undefined) {
      const id = data?.mapping_id;
      delete data["mapping_id"];
      UpdateSlaHandler(id, data);
      return;
    }
    createSlaHandler(data);
  };

  const onDeleteHandler = (id: string) => {
    setDeleteModalState({
      open: false,
      id: "",
    });
    deleteSlaHandler(id);
  };

  const onEditHandler = (data: any) => {
    if (data.msp_id && data.msp_id != null && data.msp_id !== "") {
      fetchSlaByIdHandler(data.mapping_id);
    }
    debugConsole(
      "🚀 ~ file: CustomerConfigurationsSlaComponent.tsx:148 ~ onEditHandler ~ data.mapping_id:",
      data.mapping_id
    );
  };

  const handleSearchAction = (data: string) => {
    // setState({
    //   ...state,
    //   searchData: data,
    // });
    // getAllSLA(1, data);
  };

  return (
    <>
      <HomeScreenLayout
        title="Configure SLA"
        rightActionComponent={
          <Stack direction={"row"} gap={"24px"}>
            <SearchInput
              name="search"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              endIcon={<PlusIcon />}
              onClick={() => setModalVisbility(true)}
            >
              Policy
            </Button>
          </Stack>
        }
      >
        <Grid
          container
          spacing={{
            xs: "6px",
            sm: "7px",
            md: "14px",
            lg: "21px",
            xl: "42px",
          }}
          sx={{
            width: "100%",
            flex: 1,
            px: "32px",
            py: "24px",
            overflowY: "auto",
          }}
        >
          {(Array.isArray(slaData?.result) ? slaData.result : []).map(
            (item: any) => (
              <Grid
                item
                key={item["mapping_id"]}
                {...{ xs: 6, sm: 4, md: 3, lg: 3 }}
                sx={{
                  height: "260px",
                  display: "block",
                }}
              >
                <SlaTiles
                  title={item["sla_name"]}
                  details={item["description"]}
                  isDefault={item?.meta?.is_default ?? false}
                  onDelete={() =>
                    !item?.meta?.is_default &&
                    setDeleteModalState({
                      open: true,
                      id: item.mapping_id,
                    })
                  }
                  onEdit={() => {
                    !item?.meta?.is_default && onEditHandler(item);
                  }}
                />
              </Grid>
            )
          )}
        </Grid>
      </HomeScreenLayout>
      {deleteModalState.open ? (
        <DeleteConfirmationModal
          open={deleteModalState.open}
          onClose={() =>
            setDeleteModalState({
              open: false,
              id: "",
            })
          }
          onSecondaryButtonClick={() =>
            setDeleteModalState({
              open: false,
              id: "",
            })
          }
          onPrimaryButtonClick={() => onDeleteHandler(deleteModalState.id)}
          // isLoading={isDeleteSlaLoading}
          isLoading={false}
        />
      ) : null}
      {modalState.open ? (
        <CreateSlaModal
          onClose={() => setModalVisbility(false)}
          isOpen={modalState.open}
          isLoading={isCreateSlaLoading || isUpdateSlaLoading}
          isEdit={
            modalState.selectedSla?.mapping_id !== undefined ? true : false
          }
          selectedData={modalState.selectedSla}
          onSaveHandler={onSaveHandler}
        />
      ) : null}
      {/* <Box
        sx={{
          padding: "32px",
          flexDirection: "column",
          gap: "24px",
          display: "flex",
        }}
      >
        {deleteModalState.open ? (
          <DeleteConfirmationModal
            open={deleteModalState.open}
            onClose={() =>
              setDeleteModalState({
                open: false,
                id: "",
              })
            }
            onSecondaryButtonClick={() =>
              setDeleteModalState({
                open: false,
                id: "",
              })
            }
            onPrimaryButtonClick={() => onDeleteHandler(deleteModalState.id)}
          />
        ) : null}
        {state.open ? (
          <CreateSlaModal
            onClose={() => setModalVisbility(false)}
            isOpen={state.open}
            isLoading={isLoading}
            isEdit={
              modalState.selectedSla?.mapping_id !== undefined ? true : false
            }
            selectedData={state.selectedSla}
            onSaveHandler={onSaveHandler}
          />
        ) : null}
      </Box> */}
    </>
  );
};
