import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  Button,
  SearchInput,
  PlusIcon,
  Drawer,
  Input,
  DeleteConfirmationModal,
} from "components";
import { HomeScreenLayout } from "components/layout/HomeScreenLayout";
import { DataTable } from "components/table/DataTable";
import { useEffect, useState } from "react";
import { DrawerComponent } from "./components/DrawerComponent";
import {
  useDeleteExpenseConfig,
  useGetAllExpenseConfigs,
  useMainStore,
} from "controller";
import toast from "react-hot-toast";

const BillingHeaders = [
  {
    name: "Name",
    sortable: false,
    key: "expense_name",
  },
  {
    name: "Amount",
    sortable: false,
    key: "price",
  },
];
const cellBuilders = {
  price: (rowData: any) => rowData?.price / 100,
};

export const ServiceDeskBillingExpense = (data: any) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [mode, setMode] = useState<any>({ method: "create", data: null });
  const theme = useTheme();
  const [pageNo, setPageNumber] = useState(1);
  const [sortColumn, setSortColume] = useState("");
  const [sortDirection, setSortDirection] = useState<
    "asc" | "desc" | undefined
  >("desc");
  const [searchValue, setSearchValue] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [expenseDataResult, setExpenseDataResult] = useState<[]>([]);
  const perPageCount = useMainStore((state) => state.perPageCount);
  const setPerPageCount = useMainStore((state) => state.setPerPageCount);

  const {
    data: expenseData,
    isFetching: isExpenseDataLoading,
    refetch,
  } = useGetAllExpenseConfigs({
    filter: [],
    pageNumber: pageNo,
    perPageCount: perPageCount.billingExpense,
    sortColumn: "expense_name",
    sortDirection: "asc",
    searchQuery: searchValue,
  });

  const { mutateAsync: deleteExpenseConfig } = useDeleteExpenseConfig();
  useEffect(() => {
    if (expenseData) {
      setExpenseDataResult(expenseData?.result);
    }
  }, [expenseData]);
  const openDrawer = (method: "create" | "update", id: string | null) => {
    if (method === "create") {
      setMode({ method: "create", data: null });
      setIsDrawerOpen(true);
    } else if (method === "update") {
      const findDataById = expenseDataResult.find(
        (item: any) => item?.mapping_id === id
      );
      setMode({ method: "update", data: findDataById });
      setIsDrawerOpen(true);
    }
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  const handleItemClick = (id: string) => {
    const checkExistInTableData = expenseDataResult.findIndex(
      (item: any) => item?.mapping_id === id
    );
    if (checkExistInTableData !== -1) {
      const index = selectedRow.indexOf(id);
      // If ID is not in the selectedRow array, add it
      if (index === -1) {
        setSelectedRow([...selectedRow, id]);
      } else {
        // If ID is already in the selectedRow array, remove it
        const updatedSelectedRow = [...selectedRow];
        updatedSelectedRow.splice(index, 1);
        setSelectedRow(updatedSelectedRow);
      }
    }
  };

  const onHeaderCheckboxClick = (currentState: "checked" | "unchecked") => {
    if (currentState === "checked") {
      const selectedRowSet = new Set(selectedRow);
      const Ids = expenseDataResult
        .filter((item: any) => !selectedRowSet.has(item.mapping_id))
        .map((item: any) => item.mapping_id);
      setSelectedRow(Ids);
    } else {
      setSelectedRow([]);
    }
  };

  const handleDeleteClick = async () => {
    try {
      await deleteExpenseConfig({ expense_ids: selectedRow });
      setIsDeleteModalOpen(false);
      setSelectedRow([]);
      toast.success("Expense successfully deleted");
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const componentName = "Add Expense";
  return (
    <HomeScreenLayout
      title="Expense"
      rightActionComponent={
        <Stack direction={"row"} gap={"24px"}>
          <Button
            endIcon={<PlusIcon />}
            onClick={() => openDrawer("create", null)}
          >
            Expense
          </Button>
        </Stack>
      }
    >
      <DrawerComponent
        {...{ isDrawerOpen, closeDrawer, componentName, mode }}
      />
      <Box sx={{ paddingX: "32px" }}>
        <DataTable
          cellBuilders={cellBuilders}
          showPagination={true}
          headers={BillingHeaders}
          data={isExpenseDataLoading ? [] : expenseDataResult || []}
          selectedItems={selectedRow}
          totalItems={expenseData?.totalCount}
          totalPages={expenseData?.totalPages}
          currentPageNumber={pageNo}
          onItemClick={handleItemClick} //check box
          onAllSelect={(currentState: any) => {
            onHeaderCheckboxClick(currentState);
          }}
          isDataLoading={isExpenseDataLoading}
          itemsPerPage={perPageCount.billingExpense}
          onPerPageChange={(itemsPerPage) => {
            setPerPageCount({ type: "billingExpense", count: itemsPerPage });
          }}
          onPagination={(d) => {
            setPageNumber(d);
          }}
          multiselect={true}
          handleRowClick={(id: any) => {
            openDrawer("update", id?.mapping_id);
          }}
          actionContent={
            <Stack direction={"row"} gap={"24px"}>
              <Button
                onClick={() => setIsDeleteModalOpen(true)}
                variant="primary"
                sx={{
                  bgcolor: theme.palette.error.main,
                  "&:hover": { backgroundColor: "#A9050E" },
                }}
              >
                Delete
              </Button>
            </Stack>
          }
          // isCheckboxDisabled={(item: any) => {
          //   return false;
          // }}
        />
        {isDeleteModalOpen && (
          <DeleteConfirmationModal
            open={isDeleteModalOpen}
            onPrimaryButtonClick={handleDeleteClick}
            // onPrimaryButtonClick={() => {}}
            onSecondaryButtonClick={() => setIsDeleteModalOpen(false)}
            title="Delete Expense Template"
            tagline="Are you sure you want to delete?"
            // tagline={`Are you sure you want to delete ${
            //   selectedCustomers.length
            // } ${selectedCustomers.length > 1 ? "customers" : "customer"}?`}
          />
        )}
      </Box>
    </HomeScreenLayout>
  );
};
