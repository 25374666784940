import { Stack, Typography, useTheme } from "@mui/material";
import { DeleteConfirmationModal, HomeScreenLayout } from "components";
import React, { useEffect } from "react";
import { integrationsList } from "./integrationList";
import { IntegrationCard } from "./IntegrationCard";
import {
  useGetIntegrationSources,
  useUpdateIntegrationStatus,
} from "controller";
import toast from "react-hot-toast";

export const IntegrationHomeScreen = () => {
  const [transformedList, setTransformedList] = React.useState<any>([]);
  const [selectedSource, setSelectedSource] = React.useState<Record<
    string,
    any
  > | null>(null);
  const { data: integrationSources } = useGetIntegrationSources();
  const { mutateAsync: updateStatus, isLoading: isUpdating } =
    useUpdateIntegrationStatus();
  useEffect(() => {
    if (integrationSources && integrationsList) {
      transformList();
    }
  }, [integrationSources, integrationsList]);

  const transformList = () => {
    if (integrationSources.length < 1) {
      setTransformedList(integrationsList);
      return;
    }
    if (integrationSources && integrationsList) {
      const result = integrationsList.map((integration) => {
        const children = integration.children.map((child) => {
          const index = integrationSources.findIndex(
            (integrationSource: any) => integrationSource.name === child.value
          );
          if (index > -1) {
            return {
              ...child,
              isActive: integrationSources[index]?.is_active,
              isConnected: integrationSources[index]?.is_connected,
            };
          }
          return {
            ...child,
            isActive: false,
            isConnected: false,
          };
        });

        return {
          ...integration,
          children,
        };
      });
      setTransformedList(result);
    }
  };

  const handleToggleClick = async ({
    value,
    source,
  }: {
    value: boolean;
    source: string;
  }) => {
    try {
      await updateStatus({ source, status: value });
      setSelectedSource(null);
      toast.success(
        value === true
          ? "Integration activated successfully"
          : "Integration deactivated successfully"
      );
    } catch (error) {
      setSelectedSource(null);
      toast.error("Failed to update");
      console.log(error);
    }
  };
  return (
    <HomeScreenLayout title="Integrations">
      <Stack p={"32px"} gap="32px">
        {transformedList.map((integration: Record<string, any>) => (
          <Stack key={integration.type} sx={{ gap: "24px" }}>
            <Typography variant="h6">{integration.type}</Typography>
            <Stack direction={"row"} sx={{ gap: "24px", flexWrap: "wrap" }}>
              {integration.children.map((child: any) => (
                <IntegrationCard
                  key={child.value}
                  onClick={({ value, source }) =>
                    value
                      ? handleToggleClick({ value, source })
                      : setSelectedSource({ value, source })
                  }
                  {...child}
                  value={child.value}
                />
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {selectedSource && (
        <DeleteConfirmationModal
          onClose={() => setSelectedSource(null)}
          onSecondaryButtonClick={() => setSelectedSource(null)}
          open={selectedSource !== null}
          title="Are you sure you want to deactivate?"
          tagline="To do so, remove the PSA integration in N-able (Administration > PSA integration > Configure > Remove)"
          primaryButtonText="Deactivate"
          onPrimaryButtonClick={() =>
            handleToggleClick({
              value: selectedSource?.value,
              source: selectedSource?.source,
            })
          }
          paperStyles={{
            width: "370px",
          }}
          isLoading={isUpdating}
        />
      )}
    </HomeScreenLayout>
  );
};
