import colorPalette from "../../../../../../../../../../library/common/colors";
export const PRIORITY_CONSTANT = {
  critical: {
    value: "critical",
    backgroundColor: colorPalette.sematics.lightBg.error,
    color: "white",
    label: "Critical",
  },
  high: {
    value: "high",
    backgroundColor: colorPalette.sematics.lightBg.warningMajor,
    color: "white",
    label: "High",
  },
  medium: {
    value: "medium",
    backgroundColor: colorPalette.sematics.lightBg.warningMinor,
    color: "black",
    label: "Medium",
  },
  low: {
    value: "low",
    backgroundColor: colorPalette.neutrals.lightBg.quartary,
    color: "black",
    label: "Low",
  },

};