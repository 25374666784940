import React from "react";
import { Modal } from "./Modal";
import { Grid, useTheme, Box, Typography } from "@mui/material";
import { Avatar } from "components/avatar";

interface MemberModalProps {
  open: boolean;
  onClose: () => void;
  members: Array<any>;
}
export const MembersModal = ({ open, onClose, members }: MemberModalProps) => {
  const theme = useTheme();
  return (
    <Modal
      maxWidth="sm"
      height="sm"
      title="Members"
      open={open}
      onClose={onClose}
      footer={<></>}
      headerStyles={{
        border: `1px solid ${theme.palette.neutral["015"]}`,
      }}
    >
      <Grid container gap={"24px"} sx={{ padding: "32px 24px" }}>
        {members.map((item) => (
          <Grid item xs={2.5} key={item?.mapping_id}>
            <Box
              sx={{
                padding: "16px 12px",
                border: `1px solid ${theme.palette.neutral["B-020"]}`,
                borderRadius: "12px",
                height: "62px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "8px",
                width: "100%",
              }}
            >
              <Avatar
                key={item.mapping_id}
                fullname={
                  item.display_name
                    ? item.display_name
                    : `${item.first_name} ${item.last_name}`
                }
                tooltip
                url={item.profile_image}
              ></Avatar>
              <Typography
                noWrap
                variant="subtitle1"
                sx={{
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  textTransform: "capitalize",
                }}
              >
                {item.display_name
                  ? item.display_name
                  : `${item.first_name} ${item.last_name}`}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};
