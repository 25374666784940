import TableRow, { TableRowProps } from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React from "react";

interface CustomTableRowProps extends TableRowProps {
  header?: boolean;
}
export const TableRowRoot = styled(TableRow)<CustomTableRowProps>(
  ({ theme, header = false, sx }) => {
    return {
      ...(header
        ? {
            fontWeight: 700,
            ...theme.typography.caption,
            fontFamily: "SegoeBold",
          }
        : {
            fontWeight: 400,
            ...theme.typography["body-small"],
            fontFamily: "SegoeRegular",
          }),
      padding: "15px 8px",
      whiteSpace: "nowrap",
      border: "0px !important",
      color: theme.palette.lightBg.high,
      ":hover": {
        cursor: "pointer",
      },
      "&:nth-of-type(even)": {
        backgroundColor: `${theme.palette.neutral["B-002"]}`,
        ":hover": {
          backgroundColor: `${theme.palette.neutral["B-002"]}`,
          // boxShadow: "0px 6px 5px 0px rgba(0, 0, 0, 0.1)",
        },
      },
      "&:nth-of-type(odd)": {
        backgroundColor: "#FFF",
        ":hover": {
          backgroundColor: "#FFF",
          // boxShadow: "0px 6px 5px 0px rgba(0, 0, 0, 0.1)",
        },
      },
    };
  }
);
