export const AppBarHeight = "56px";

export const UrgencyData = [
  { label: "Critical", value: "critical" },
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];
export const PriorityData = [
  { label: "Critical", value: "critical" },
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

export enum statusData {
  NEW = "9dd09ea5-e30e-494a-9120-583caff52a3a",
  CLOSED = "da5be1f9-db9d-4c64-8e43-51b458746773",
  REOPEN = "30f434a9-4e66-4bea-86e3-86bb945035b3",
  CANCELLED = "953b24ef-2167-49f9-88d9-a06cca165753",
}

export const DisabledStatus: Array<string> = [
  statusData.CLOSED,
  statusData.CANCELLED,
];

export const YearOptions = [
  { label: "2024", value: 2024 },
  { label: "2023", value: 2023 },
  { label: "2022", value: 2022 },
];
export const MonthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const PaymentTermOptions = [
  { label: "NET 5", value: 5 },
  { label: "NET 7", value: 7 },
  { label: "NET 10", value: 10 },
  { label: "NET 15", value: 15 },
  { label: "NET 30", value: 30 },
  { label: "NET 45", value: 45 },
  { label: "NET 60", value: 60 },
];

export const NCENTRALID = "9ef2d0e7-815b-4d02-895c-9a8cc1b0105f";

export const TicketHeaders = [
  {
    name: "Ticket ID",
    sortable: false,
    key: "u_id",
  },
  {
    name: "Customer",
    sortable: true,
    key: "customer_name",
  },
  {
    name: "User",
    sortable: true,
    key: "customer_user_name",
  },
  {
    name: "Priority",
    sortable: true,
    key: "priority",
  },
  {
    name: "Summary",
    sortable: false,
    key: "ticket_summary",
  },
  {
    name: "Status",
    sortable: true,
    key: "status_name",
  },

  {
    name: "Age",
    sortable: true,
    key: "created_at",
  },
  {
    name: "SLA",
    sortable: false,
    key: "sla_rule",
  },
  {
    name: "Assignees",
    sortable: false,
    key: "assignees",
  },
  {
    name: "Board",
    sortable: true,
    key: "board_name",
  },
  {
    name: "Budgeted Hours",
    sortable: true,
    key: "budgeted_hours",
  },
  {
    name: "Actual Hours",
    sortable: true,
    key: "actual_hours",
  },
  {
    name: "Work Type",
    sortable: true,
    key: "work_type_name",
  },
  // {
  //   name: "Impact",
  //   sortable: true,
  //   key: "impact",
  // },
  {
    name: "Ticket Type",
    sortable: true,
    key: "type_name",
  },
  {
    name: "Feedback",
    sortable: true,
    key: "rating",
  },
];

export const TicketQaBoardHeaders = [
  {
    name: "ID Number",
    sortable: true,
    key: "uid",
  },
  {
    name: "Boards",
    sortable: true,
    key: "board",
  },
  {
    name: "Customers",
    sortable: true,
    key: "customer",
  },
  {
    name: "Summary",
    sortable: false,
    key: "summary",
  },
  {
    name: "QA Health Status",
    sortable: true,
    key: "qa_health_status",
  },
];

export const IndustryList = [
  {
    value: "IT",
    label: "IT",
  },
  {
    value: "Health Care",
    label: "Health Care",
  },
  {
    value: "Manufacturing",
    label: "Manufacturing",
  },
  {
    value: "Construction",
    label: "Construction",
  },
  {
    value: "Education",
    label: "Education",
  },
  {
    value: "Retail",
    label: "Retail",
  },
  {
    value: "Finance",
    label: "Finance",
  },
  {
    value: "Telecommunication",
    label: "Telecommunication",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const TimeZones = [
  {
    gmtOffsetName: "Africa/Abidjan",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Accra",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Addis_Ababa",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Algiers",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Asmara",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Asmera",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Bamako",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Bangui",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Banjul",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Blantyre",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Brazzaville",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Bujumbura",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Cairo",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Casablanca",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Ceuta",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Conakry",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Dakar",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Dar_es_Salaam",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Djibouti",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Douala",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/El_Aaiun",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Freetown",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Gaborone",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Harare",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Johannesburg",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Juba",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Kampala",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Khartoum",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Kigali",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Kinshasa",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Lagos",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Libreville",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Lome",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Luanda",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Lubumbashi",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Lusaka",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Malabo",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Maputo",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Maseru",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Mbabane",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Mogadishu",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Monrovia",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Nairobi",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Africa/Ndjamena",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Niamey",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Nouakchott",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Ouagadougou",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Porto-Novo",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Sao_Tome",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Timbuktu",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Africa/Tripoli",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Africa/Tunis",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Africa/Windhoek",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "America/Adak",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "America/Anchorage",
    zoneName: "-09:00",
  },
  {
    gmtOffsetName: "America/Anguilla",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Antigua",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Araguaina",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Buenos_Aires",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Catamarca",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/ComodRivadavia",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Cordoba",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Jujuy",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/La_Rioja",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Mendoza",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Rio_Gallegos",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Salta",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/San_Juan",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/San_Luis",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Tucuman",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Argentina/Ushuaia",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Aruba",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Asuncion",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Atikokan",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Atka",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "America/Bahia",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Bahia_Banderas",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Barbados",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Belem",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Belize",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Blanc-Sablon",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Boa_Vista",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Bogota",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Boise",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Buenos_Aires",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Cambridge_Bay",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Campo_Grande",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Cancun",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Caracas",
    zoneName: "-04:30",
  },
  {
    gmtOffsetName: "America/Catamarca",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Cayenne",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Cayman",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Chicago",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Chihuahua",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Coral_Harbour",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Cordoba",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Costa_Rica",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Creston",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Cuiaba",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Curacao",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Danmarkshavn",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "America/Dawson",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Dawson_Creek",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Denver",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Detroit",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Dominica",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Edmonton",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Eirunepe",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/El_Salvador",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Ensenada",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Fort_Wayne",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Fortaleza",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Glace_Bay",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Godthab",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Goose_Bay",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Grand_Turk",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Grenada",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Guadeloupe",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Guatemala",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Guayaquil",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Guyana",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Halifax",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Havana",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Hermosillo",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Indiana/Indianapolis",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Indiana/Knox",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Indiana/Marengo",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Indiana/Petersburg",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Indiana/Tell_City",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Indiana/Valparaiso",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Indiana/Vevay",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Indiana/Vincennes",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Indiana/Winamac",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Indianapolis",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Inuvik",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Iqaluit",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Jamaica",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Jujuy",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Juneau",
    zoneName: "-09:00",
  },
  {
    gmtOffsetName: "America/Kentucky/Louisville",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Kentucky/Monticello",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Knox_IN",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Kralendijk",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/La_Paz",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Lima",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Los_Angeles",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Louisville",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Lower_Princes",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Maceio",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Managua",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Manaus",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Marigot",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Martinique",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Matamoros",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Mazatlan",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Mendoza",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Menominee",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Merida",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Metlakatla",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Mexico_City",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Miquelon",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Moncton",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Monterrey",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Montevideo",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Montreal",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Montserrat",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Nassau",
    zoneName: "-05:00",
  },
  // {
  //   gmtOffsetName: "America/New_York",
  //   zoneName: "-05:00",
  // },
  {
    gmtOffsetName: "America/Nipigon",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Nome",
    zoneName: "-09:00",
  },
  {
    gmtOffsetName: "America/Noronha",
    zoneName: "-02:00",
  },
  {
    gmtOffsetName: "America/North_Dakota/Beulah",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/North_Dakota/Center",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/North_Dakota/New_Salem",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Ojinaga",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Panama",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Pangnirtung",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Paramaribo",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Phoenix",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Port_of_Spain",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Port-au-Prince",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Porto_Acre",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Porto_Velho",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Puerto_Rico",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Rainy_River",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Rankin_Inlet",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Recife",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Regina",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Resolute",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Rio_Branco",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Rosario",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Santa_Isabel",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Santarem",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Santiago",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Santo_Domingo",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Sao_Paulo",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "America/Scoresbysund",
    zoneName: "-01:00",
  },
  {
    gmtOffsetName: "America/Shiprock",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "America/Sitka",
    zoneName: "-09:00",
  },
  {
    gmtOffsetName: "America/St_Barthelemy",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/St_Johns",
    zoneName: "-03:30",
  },
  {
    gmtOffsetName: "America/St_Kitts",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/St_Lucia",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/St_Thomas",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/St_Vincent",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Swift_Current",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Tegucigalpa",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Thule",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Thunder_Bay",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Tijuana",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Toronto",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "America/Tortola",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Vancouver",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Virgin",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "America/Whitehorse",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "America/Winnipeg",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "America/Yakutat",
    zoneName: "-09:00",
  },
  {
    gmtOffsetName: "America/Yellowknife",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "Antarctica/Casey",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Antarctica/Davis",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Antarctica/DumontDUrville",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Antarctica/Macquarie",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Antarctica/Mawson",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Antarctica/McMurdo",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Antarctica/Palmer",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "Antarctica/Rothera",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "Antarctica/South_Pole",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Antarctica/Syowa",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Antarctica/Troll",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Antarctica/Vostok",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Arctic/Longyearbyen",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Asia/Aden",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Asia/Almaty",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Amman",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Anadyr",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Asia/Aqtau",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Aqtobe",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Ashgabat",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Ashkhabad",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Baghdad",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Asia/Bahrain",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Asia/Baku",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Asia/Bangkok",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Beirut",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Bishkek",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Brunei",
    zoneName: "+08:00",
  },
  // {
  //   gmtOffsetName: "Asia/Calcutta",
  //   zoneName: "+05:30",
  // },
  {
    gmtOffsetName: "Asia/Choibalsan",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Chongqing",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Chungking",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Colombo",
    zoneName: "+05:30",
  },
  {
    gmtOffsetName: "Asia/Dacca",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Damascus",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Dhaka",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Dili",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Asia/Dubai",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Asia/Dushanbe",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Gaza",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Harbin",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Hebron",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Ho_Chi_Minh",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Hong_Kong",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Hovd",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Irkutsk",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Istanbul",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Jakarta",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Jayapura",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Asia/Jerusalem",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Kabul",
    zoneName: "+04:30",
  },
  {
    gmtOffsetName: "Asia/Kamchatka",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Asia/Karachi",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Kashgar",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Kathmandu",
    zoneName: "+05:45",
  },
  {
    gmtOffsetName: "Asia/Katmandu",
    zoneName: "+05:45",
  },
  {
    gmtOffsetName: "Asia/Khandyga",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Asia/Kolkata",
    zoneName: "+05:30",
  },
  {
    gmtOffsetName: "Asia/Krasnoyarsk",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Kuala_Lumpur",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Kuching",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Kuwait",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Asia/Macao",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Macau",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Magadan",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Asia/Makassar",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Manila",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Muscat",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Asia/Nicosia",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Novokuznetsk",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Novosibirsk",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Omsk",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Oral",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Phnom_Penh",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Pontianak",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Pyongyang",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Asia/Qatar",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Asia/Qyzylorda",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Rangoon",
    zoneName: "+06:30",
  },
  {
    gmtOffsetName: "Asia/Riyadh",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Asia/Saigon",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Sakhalin",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Asia/Samarkand",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Seoul",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Asia/Shanghai",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Singapore",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Taipei",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Tashkent",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Tbilisi",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Asia/Tehran",
    zoneName: "+03:30",
  },
  {
    gmtOffsetName: "Asia/Tel_Aviv",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Asia/Thimbu",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Thimphu",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Asia/Tokyo",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Asia/Ujung_Pandang",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Ulaanbaatar",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Ulan_Bator",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Urumqi",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Asia/Ust-Nera",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Asia/Vientiane",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Asia/Vladivostok",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Asia/Yakutsk",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Asia/Yekaterinburg",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Asia/Yerevan",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Atlantic/Azores",
    zoneName: "-01:00",
  },
  {
    gmtOffsetName: "Atlantic/Bermuda",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "Atlantic/Canary",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Atlantic/Cape_Verde",
    zoneName: "-01:00",
  },
  {
    gmtOffsetName: "Atlantic/Faeroe",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Atlantic/Faroe",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Atlantic/Jan_Mayen",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Atlantic/Madeira",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Atlantic/Reykjavik",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Atlantic/South_Georgia",
    zoneName: "-02:00",
  },
  {
    gmtOffsetName: "Atlantic/St_Helena",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Atlantic/Stanley",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "Australia/ACT",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Adelaide",
    zoneName: "+09:30",
  },
  {
    gmtOffsetName: "Australia/Brisbane",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Broken_Hill",
    zoneName: "+09:30",
  },
  {
    gmtOffsetName: "Australia/Canberra",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Currie",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Darwin",
    zoneName: "+09:30",
  },
  {
    gmtOffsetName: "Australia/Eucla",
    zoneName: "+08:45",
  },
  {
    gmtOffsetName: "Australia/Hobart",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/LHI",
    zoneName: "+10:30",
  },
  {
    gmtOffsetName: "Australia/Lindeman",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Lord_Howe",
    zoneName: "+10:30",
  },
  {
    gmtOffsetName: "Australia/Melbourne",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/North",
    zoneName: "+09:30",
  },
  {
    gmtOffsetName: "Australia/NSW",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Perth",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Australia/Queensland",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/South",
    zoneName: "+09:30",
  },
  {
    gmtOffsetName: "Australia/Sydney",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Tasmania",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/Victoria",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Australia/West",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Australia/Yancowinna",
    zoneName: "+09:30",
  },
  {
    gmtOffsetName: "Brazil/Acre",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "Brazil/DeNoronha",
    zoneName: "-02:00",
  },
  {
    gmtOffsetName: "Brazil/East",
    zoneName: "-03:00",
  },
  // {
  //   gmtOffsetName: "Brazil/West",
  //   zoneName: "-04:00",
  // },
  {
    gmtOffsetName: "Canada/Atlantic",
    zoneName: "-04:00",
  },
  {
    gmtOffsetName: "Canada/Central",
    zoneName: "-06:00",
  },
  // {
  //   gmtOffsetName: "Canada/Eastern",
  //   zoneName: "-05:00",
  // },
  {
    gmtOffsetName: "Canada/East-Saskatchewan",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "Canada/Mountain",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "Canada/Newfoundland",
    zoneName: "-03:30",
  },
  {
    gmtOffsetName: "Canada/Pacific",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "Canada/Saskatchewan",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "Canada/Yukon",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "Chile/Continental",
    zoneName: "-03:00",
  },
  {
    gmtOffsetName: "Chile/EasterIsland",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "Cuba",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "Egypt",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Eire",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Etc/GMT",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Etc/GMT+0",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Etc/UCT",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Etc/Universal",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Etc/UTC",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Etc/Zulu",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Europe/Amsterdam",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Andorra",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Athens",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Belfast",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Europe/Belgrade",
    zoneName: "+01:00",
  },
  // {
  //   gmtOffsetName: "Europe/Berlin",
  //   zoneName: "+01:00",
  // },
  {
    gmtOffsetName: "Europe/Bratislava",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Brussels",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Bucharest",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Budapest",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Busingen",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Chisinau",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Copenhagen",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Dublin",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Europe/Gibraltar",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Guernsey",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Europe/Helsinki",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Isle_of_Man",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Europe/Istanbul",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Jersey",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Europe/Kaliningrad",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Kiev",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Lisbon",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Europe/Ljubljana",
    zoneName: "+01:00",
  },
  // {
  //   gmtOffsetName: "Europe/London",
  //   zoneName: "+00:00",
  // },
  {
    gmtOffsetName: "Europe/Luxembourg",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Madrid",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Malta",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Mariehamn",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Minsk",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Europe/Monaco",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Moscow",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Europe/Nicosia",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Oslo",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Paris",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Podgorica",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Prague",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Riga",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Rome",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Samara",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Europe/San_Marino",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Sarajevo",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Simferopol",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Europe/Skopje",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Sofia",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Stockholm",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Tallinn",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Tirane",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Tiraspol",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Uzhgorod",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Vaduz",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Vatican",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Vienna",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Vilnius",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Volgograd",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Europe/Warsaw",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Zagreb",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "Europe/Zaporozhye",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Europe/Zurich",
    zoneName: "+01:00",
  },
  {
    gmtOffsetName: "GB",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "GB-Eire",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "GMT",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "GMT+0",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "GMT0",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "GMT-0",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Greenwich",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Hongkong",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Iceland",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Indian/Antananarivo",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Indian/Chagos",
    zoneName: "+06:00",
  },
  {
    gmtOffsetName: "Indian/Christmas",
    zoneName: "+07:00",
  },
  {
    gmtOffsetName: "Indian/Cocos",
    zoneName: "+06:30",
  },
  {
    gmtOffsetName: "Indian/Comoro",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Indian/Kerguelen",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Indian/Mahe",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Indian/Maldives",
    zoneName: "+05:00",
  },
  {
    gmtOffsetName: "Indian/Mauritius",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Indian/Mayotte",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Indian/Reunion",
    zoneName: "+04:00",
  },
  {
    gmtOffsetName: "Iran",
    zoneName: "+03:30",
  },
  {
    gmtOffsetName: "Israel",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Jamaica",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "Japan",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Kwajalein",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Libya",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "Mexico/BajaNorte",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "Mexico/BajaSur",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "Mexico/General",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "Navajo",
    zoneName: "-07:00",
  },
  {
    gmtOffsetName: "NZ",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "NZ-CHAT",
    zoneName: "+12:45",
  },
  {
    gmtOffsetName: "Pacific/Apia",
    zoneName: "+13:00",
  },
  {
    gmtOffsetName: "Pacific/Auckland",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Chatham",
    zoneName: "+12:45",
  },
  {
    gmtOffsetName: "Pacific/Chuuk",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Pacific/Easter",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "Pacific/Efate",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Pacific/Enderbury",
    zoneName: "+13:00",
  },
  {
    gmtOffsetName: "Pacific/Fakaofo",
    zoneName: "+13:00",
  },
  {
    gmtOffsetName: "Pacific/Fiji",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Funafuti",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Galapagos",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "Pacific/Gambier",
    zoneName: "-09:00",
  },
  {
    gmtOffsetName: "Pacific/Guadalcanal",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Pacific/Guam",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Pacific/Honolulu",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "Pacific/Johnston",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "Pacific/Kiritimati",
    zoneName: "+14:00",
  },
  {
    gmtOffsetName: "Pacific/Kosrae",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Pacific/Kwajalein",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Majuro",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Marquesas",
    zoneName: "-09:30",
  },
  {
    gmtOffsetName: "Pacific/Midway",
    zoneName: "-11:00",
  },
  {
    gmtOffsetName: "Pacific/Nauru",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Niue",
    zoneName: "-11:00",
  },
  {
    gmtOffsetName: "Pacific/Norfolk",
    zoneName: "+11:30",
  },
  {
    gmtOffsetName: "Pacific/Noumea",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Pacific/Pago_Pago",
    zoneName: "-11:00",
  },
  {
    gmtOffsetName: "Pacific/Palau",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Pacific/Pitcairn",
    zoneName: "-08:00",
  },
  {
    gmtOffsetName: "Pacific/Pohnpei",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Pacific/Ponape",
    zoneName: "+11:00",
  },
  {
    gmtOffsetName: "Pacific/Port_Moresby",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Pacific/Rarotonga",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "Pacific/Saipan",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Pacific/Samoa",
    zoneName: "-11:00",
  },
  {
    gmtOffsetName: "Pacific/Tahiti",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "Pacific/Tarawa",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Tongatapu",
    zoneName: "+13:00",
  },
  {
    gmtOffsetName: "Pacific/Truk",
    zoneName: "+10:00",
  },
  {
    gmtOffsetName: "Pacific/Wake",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Wallis",
    zoneName: "+12:00",
  },
  {
    gmtOffsetName: "Pacific/Yap",
    zoneName: "+10:00",
  },
  // {
  //   gmtOffsetName: "Poland",
  //   zoneName: "+01:00",
  // },
  {
    gmtOffsetName: "Portugal",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "PRC",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "ROC",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "ROK",
    zoneName: "+09:00",
  },
  {
    gmtOffsetName: "Singapore",
    zoneName: "+08:00",
  },
  {
    gmtOffsetName: "Turkey",
    zoneName: "+02:00",
  },
  {
    gmtOffsetName: "UCT",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "Universal",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "US/Alaska",
    zoneName: "-09:00",
  },
  {
    gmtOffsetName: "US/Aleutian",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "US/Arizona",
    zoneName: "-07:00",
  },
  // {
  //   gmtOffsetName: "US/Central",
  //   zoneName: "-06:00",
  // },
  {
    gmtOffsetName: "US/Eastern",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "US/East-Indiana",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "US/Hawaii",
    zoneName: "-10:00",
  },
  {
    gmtOffsetName: "US/Indiana-Starke",
    zoneName: "-06:00",
  },
  {
    gmtOffsetName: "US/Michigan",
    zoneName: "-05:00",
  },
  {
    gmtOffsetName: "US/Mountain",
    zoneName: "-07:00",
  },
  // {
  //   gmtOffsetName: "US/Pacific",
  //   zoneName: "-08:00",
  // },
  {
    gmtOffsetName: "US/Samoa",
    zoneName: "-11:00",
  },
  {
    gmtOffsetName: "UTC",
    zoneName: "+00:00",
  },
  {
    gmtOffsetName: "W-SU",
    zoneName: "+03:00",
  },
  {
    gmtOffsetName: "Zulu",
    zoneName: "+00:00",
  },
];

const mostUserdTimeZones = [
  {
    label: "Pacific Time Zone (PT)",
    gmtOffsetName: "US/Pacific",
    zoneName: "-08:00",
  },
  {
    label: "Central America (CST)",
    gmtOffsetName: "US/Central",
    zoneName: "-06:00",
  },
  {
    label: "Central America (CDT)",
    gmtOffsetName: "America/New_York",
    zoneName: "-05:00",
  },
  {
    label: "Eastern Time Zone (EST)",
    gmtOffsetName: "Canada/Eastern",
    zoneName: "-05:00",
  },
  {
    label: "Eastern Time Zone (EDT)",
    gmtOffsetName: "Brazil/West",
    zoneName: "-04:00",
  },
  {
    label: "Greenwich Mean Time (GMT)",
    gmtOffsetName: "Europe/London",
    zoneName: "00:00",
  },
  {
    label: "British Summer Time (BST)",
    gmtOffsetName: "Poland",
    zoneName: "+01:00",
  },
  {
    label: "Central European Time (CET)",
    gmtOffsetName: "Europe/Berlin",
    zoneName: "+01:00",
  },
  {
    label: "Indian Standard Time (IST)",
    gmtOffsetName: "Asia/Calcutta",
    zoneName: "+05:30",
  },
];

function sortObjectsByZoneNameAscending(objects: any[]) {
  return objects.sort((a, b) => a.zoneName.localeCompare(b.zoneName));
}

const newTimeZones = sortObjectsByZoneNameAscending(TimeZones);

export const TimeZoneList = {
  "Most used": (mostUserdTimeZones || []).map((item: any) => {
    return {
      label: `${item?.zoneName} ${item?.label}`,
      value: item?.gmtOffsetName,
    };
  }),
  Others: (newTimeZones || []).map((item: any) => {
    return {
      label: `${item?.zoneName} ${item?.gmtOffsetName}`,
      value: item?.gmtOffsetName,
    };
  }),
};

export const TYPE = {
  SINGLE: "single",
  COLLECTION: "collection",
};

export const RESOURCE_TYPES = {
  SUPERADMIN: "b110abc3-0709-491f-ac04-86eb25159386",
  ADMIN: "9d6afd0e-b353-438d-a34d-1bb1cc5bbf03",
  RESOURCE: "ff1088b8-d50b-4d89-a033-94bf3b9f450d",
};

export const PERMISSION_CONSTANT = [
  {
    serviceDesk: {
      key: "serviceDesk",
      name: "serviceDesk",
      label: "Service desk",
      rule: "",
      type: TYPE.SINGLE,
    },
    tickets: {
      key: "tickets_collection",
      label: "Tickets",
      type: TYPE.COLLECTION,
      contents: {
        tickets: {
          key: "tickets",
          name: "tickets",
          label: "Tickets",
          rule: "",
          type: TYPE.SINGLE,
        },
        createTickets: {
          key: "createTickets",
          name: "createTickets",
          label: "Create ticket",
          rule: "servicedesk::ticket.create",
          type: TYPE.SINGLE,
        },
        addPublicNotes: {
          key: "addPublicNotes",
          name: "addPublicNotes",
          label: "Add public notes",
          rule: "servicedesk::publicnotes.create",
          type: TYPE.SINGLE,
        },
        mergeTickets: {
          key: "mergeTickets",
          name: "mergeTickets",
          label: "Merge tickets",
          rule: "servicedesk::ticket.merge",
          type: TYPE.SINGLE,
        },
        createChildTicket: {
          key: "createChildTicket",
          name: "createChildTicket",
          label: "Create a child ticket",
          rule: "servicedesk::ticket.child",
          type: TYPE.SINGLE,
        },
        linkAChildTicket: {
          key: "linkAChildTicket",
          name: "linkAChildTicket",
          label: "Link a child ticket",
          rule: "servicedesk::ticket.linkchild",
          type: TYPE.SINGLE,
        },
        sendATicketReply: {
          key: "sendATicketReply",
          name: "sendATicketReply",
          label: "Send a ticket reply",
          rule: "servicedesk::ticket.reply",
          type: TYPE.SINGLE,
        },
        editNotes: {
          key: "editNotes",
          name: "editNotes",
          label: "Edit notes",
          rule: "servicedesk::ticket.editnotes",
          type: TYPE.SINGLE,
        },
        editEveryonesNotes: {
          key: "editEveryonesNotes",
          name: "editEveryonesNotes",
          label: "Edit everyones notes",
          rule: "servicedesk::ticket.editeallnotes",
          type: TYPE.SINGLE,
        },
        editTicketProperties: {
          key: "editTicketProperties",
          name: "editTicketProperties",
          label: "Edit ticket properties",
          rule: "servicedesk::ticket.properties",
          type: TYPE.SINGLE,
        },
        addSchedules: {
          key: "addSchedules",
          name: "addSchedules",
          label: "Add schedules",
          rule: "servicedesk::ticket.addschedules",
          type: TYPE.SINGLE,
        },
        deleteSchedules: {
          key: "deleteSchedules",
          name: "deleteSchedules",
          label: "Delete schedules",
          rule: "servicedesk::ticket.deleteschedules",
          type: TYPE.SINGLE,
        },
        addTimeEntries: {
          key: "addTimeEntries",
          name: "addTimeEntries",
          label: "Add time entries",
          rule: "servicedesk::ticket.addtimeentries",
          type: TYPE.SINGLE,
        },
        editTimeEntries: {
          key: "editTimeEntries",
          name: "editTimeEntries",
          label: "Edit time entries",
          rule: "servicedesk::ticket.edittimeentries",
          type: TYPE.SINGLE,
        },
        editEveryonesTimeEntries: {
          key: "editEveryonesTimeEntries",
          name: "editEveryonesTimeEntries",
          label: "Edit every time entries",
          rule: "servicedesk::ticket.editeveryonestimeentries",
          type: TYPE.SINGLE,
        },
        closeATicket: {
          key: "closeATicket",
          name: "closeATicket",
          label: "Close a ticket",
          rule: "servicedesk::ticket.closeticket",
          type: TYPE.SINGLE,
        },
        exportTickets: {
          key: "exportTickets",
          name: "exportTickets",
          label: "Export ticket",
          rule: "servicedesk::ticket.exportticket",
          type: TYPE.SINGLE,
        },
      },
    },
    requests: {
      key: "requests_collection",
      label: "Requests",
      type: TYPE.COLLECTION,
      contents: {
        requests: {
          key: "requests",
          name: "requests",
          label: "Requests",
          rule: "",
          type: TYPE.SINGLE,
        },
        createRequests: {
          key: "createRequests",
          name: "createRequests",
          label: "Create request",
          rule: "servicedesk::request.create",
          type: TYPE.SINGLE,
        },
        sendARequestReply: {
          key: "sendARequestReply",
          name: "sendARequestReply",
          label: "Send a request reply",
          rule: "servicedesk::request.sendreply",
          type: TYPE.SINGLE,
        },
        editRequestProperties: {
          key: "editRequestProperties",
          name: "editRequestProperties",
          label: "Edit request properties",
          rule: "servicedesk::request.editproperties",
          type: TYPE.SINGLE,
        },
        editRequestNotes: {
          key: "editRequestNotes",
          name: "editRequestNotes",
          label: "Edit notes",
          rule: "servicedesk::request.editnotes",
          type: TYPE.SINGLE,
        },
        addRequestSchedules: {
          key: "addRequestSchedules",
          name: "addRequestSchedules",
          label: "Add schedules",
          rule: "servicedesk::request.addschedules",
          type: TYPE.SINGLE,
        },
        deleteRequestSchedules: {
          key: "deleteRequestSchedules",
          name: "deleteRequestSchedules",
          label: "Delete schedules",
          rule: "servicedesk::request.deleteschedules",
          type: TYPE.SINGLE,
        },
        addRequestTimeEntries: {
          key: "addRequestTimeEntries",
          name: "addRequestTimeEntries",
          label: "Add time entries",
          rule: "servicedesk::request.addtimeentries",
          type: TYPE.SINGLE,
        },

        editRequestTimeEntries: {
          key: "editRequestTimeEntries",
          name: "editRequestTimeEntries",
          label: "Edit time etry",
          rule: "servicedesk::request.edittimeentries",
          type: TYPE.SINGLE,
        },
        editEveryoneRequestTimeEntries: {
          key: "editEveryoneRequestTimeEntries",
          name: "editEveryoneRequestTimeEntries",
          label: "Edit everyone's time entry",
          rule: "servicedesk::request.editeveryonestimeentries",
          type: TYPE.SINGLE,
        },
        closeRequests: {
          key: "closeRequests",
          name: "closeRequests",
          label: "Close request",
          rule: "servicedesk::request.closerequests",
          type: TYPE.SINGLE,
        },
        exportRequests: {
          key: "exportRequests",
          name: "exportRequests",
          label: "Export request",
          rule: "servicedesk::request.exportrequests",
          type: TYPE.SINGLE,
        },
      },
    },
    projects: {
      key: "projects_collection",
      label: "Projects",
      type: TYPE.COLLECTION,
      contents: {
        projects: {
          key: "projects",
          name: "projects",
          label: "Projects",
          rule: "",
          type: TYPE.SINGLE,
        },
        createProject: {
          key: "createProject",
          name: "createProject",
          label: "Create project",
          rule: "servicedesk::project.create",
          type: TYPE.SINGLE,
        },
        createPhases: {
          key: "createPhases",
          name: "createPhases",
          label: "Create milestone",
          rule: "servicedesk::project.createphases",
          type: TYPE.SINGLE,
        },
        createTasks: {
          key: "createTasks",
          name: "createTasks",
          label: "Create tasks",
          rule: "servicedesk::project.cratetasks",
          type: TYPE.SINGLE,
        },
        editProjectProperties: {
          key: "editProjectProperties",
          name: "editProjectProperties",
          label: "Edit project properties",
          rule: "servicedesk::project.editprojectproperties",
          type: TYPE.SINGLE,
        },
        addAssigneesToProject: {
          key: "addAssigneesToProject",
          name: "addAssigneesToProject",
          label: "Add assignees to project",
          rule: "servicedesk::project.addassignees",
          type: TYPE.SINGLE,
        },
        sendTaskReply: {
          key: "sendTaskReply",
          name: "sendTaskReply",
          label: "Send task reply",
          rule: "servicedesk::project.sendtaskreply",
          type: TYPE.SINGLE,
        },
        addSchedulesToProject: {
          key: "addSchedulesToProject",
          name: "addSchedulesToProject",
          label: "Add schedules",
          rule: "servicedesk::project.addschedules",
          type: TYPE.SINGLE,
        },
        deleteSchedulesFromProject: {
          key: "deleteSchedulesFromProject",
          name: "deleteSchedulesFromProject",
          label: "Delete schedules",
          rule: "servicedesk::project.deleteschedules",
          type: TYPE.SINGLE,
        },
        editTaskProperties: {
          key: "editTaskProperties",
          name: "editTaskProperties",
          label: "Edit task properties",
          rule: "servicedesk::project.edittaskproperties",
          type: TYPE.SINGLE,
        },
        editProjectNotes: {
          key: "editProjectNotes",
          name: "editProjectNotes",
          label: "Edit notes",
          rule: "servicedesk::project.editnotes",
          type: TYPE.SINGLE,
        },
        addTimeEntriesToProject: {
          key: "addTimeEntriesToProject",
          name: "addTimeEntriesToProject",
          label: "Add time entries",
          rule: "servicedesk::project.addtimeentries",
          type: TYPE.SINGLE,
        },
        editTimeEntriesForProject: {
          key: "editTimeEntriesForProject",
          name: "editTimeEntriesForProject",
          label: "Edit time entries",
          rule: "servicedesk::project.edittimeentries",
          type: TYPE.SINGLE,
        },
        editEveryoneTimeEntriesForProject: {
          key: "editEveryoneTimeEntriesForProject",
          name: "editEveryoneTimeEntriesForProject",
          label: "Edit everyone's time entries",
          rule: "servicedesk::project.editeveryonestimeentries",
          type: TYPE.SINGLE,
        },
        closeTasksInProject: {
          key: "closeTasksInProject",
          name: "closeTasksInProject",
          label: "Close tasks",
          rule: "servicedesk::project.closetasks",
          type: TYPE.SINGLE,
        },
        closeProject: {
          key: "closeProject",
          name: "closeProject",
          label: "Close projects",
          rule: "servicedesk::project.closeproject",
          type: TYPE.SINGLE,
        },
        exportTasksInProject: {
          key: "exportTasksInProject",
          name: "exportTasksInProject",
          label: "Export tasks",
          rule: "servicedesk::project.exporttasks",
          type: TYPE.SINGLE,
        },
        exportProjects: {
          key: "exportProjects",
          name: "exportProjects",
          label: "Export projects",
          rule: "servicedesk::project.exportprojects",
          type: TYPE.SINGLE,
        },
      },
    },
    recurringTasks: {
      key: "recurring_tasks_collection",
      label: "Recurring task",
      type: TYPE.COLLECTION,
      contents: {
        recurringTasks: {
          key: "recurringTasks",
          name: "recurringTasks",
          label: "Recurring task",
          rule: "servicedesk::recurringtasks",
          type: TYPE.SINGLE,
        },
        createRecurringTasks: {
          key: "createRecurringTasks",
          name: "createRecurringTasks",
          label: "Create recurring task",
          rule: "servicedesk::recurringtasks.create",
          type: TYPE.SINGLE,
        },
        addScheduleForEveryone: {
          key: "addScheduleForEveryone",
          name: "addScheduleForEveryone",
          label: "Add schedule for everyone",
          rule: "servicedesk::recurringtasks.addscheduleforeveryone",
          type: TYPE.SINGLE,
        },
        scheduleOneTimeTask: {
          key: "scheduleOneTimeTask",
          name: "scheduleOneTimeTask",
          label: "Schedule one time task",
          rule: "servicedesk::recurringtasks.scheduleonetimetask",
          type: TYPE.SINGLE,
        },
        scheduleDailyTasks: {
          key: "scheduleDailyTasks",
          name: "scheduleDailyTasks",
          label: "Schedule daily task",
          rule: "servicedesk::recurringtasks.scheduledailytasks",
          type: TYPE.SINGLE,
        },
        scheduleWeeklyTask: {
          key: "scheduleWeeklyTask",
          name: "scheduleWeeklyTask",
          label: "Schedule weekly task",
          rule: "servicedesk::recurringtasks.scheduleweeklytasks",
          type: TYPE.SINGLE,
        },
        scheduleMonthlyTask: {
          key: "scheduleMonthlyTask",
          name: "scheduleMonthlyTask",
          label: "Schedule monthly task",
          rule: "servicedesk::recurringtasks.schedulemonthlytasks",
          type: TYPE.SINGLE,
        },
      },
    },
    calendarAndTimesheet: {
      key: "calendar_and_timesheet_collection",
      label: "Calendar & Timesheet",
      type: TYPE.COLLECTION,
      contents: {
        calendarAndTimesheet: {
          key: "calendarAndTimesheet",
          name: "calendarAndTimesheet",
          label: "Calendar & Timesheet",
          rule: "servicedesk::calenderandtimesheet",
          type: TYPE.SINGLE,
        },
        calendar: {
          key: "calendar",
          name: "calendar",
          label: "Calendar",
          rule: "servicedesk::calenderandtimesheet.calendar",
          type: TYPE.SINGLE,
        },
        timesheet: {
          key: "timesheet",
          name: "timesheet",
          label: "Timesheet",
          rule: "servicedesk::calenderandtimesheet.timesheet",
          type: TYPE.SINGLE,
        },
        viewEveryonesCalendar: {
          key: "viewEveryonesCalendar",
          name: "viewEveryonesCalendar",
          label: "View everyones calendar",
          rule: "servicedesk::calenderandtimesheet.vieweveryonescalendar",
          type: TYPE.SINGLE,
        },
        viewEveryonesTimesheet: {
          key: "viewEveryonesTimesheet",
          name: "viewEveryonesTimesheet",
          label: "View everyones timesheet",
          rule: "servicedesk::calenderandtimesheet.vieweveryonestimesheet",
          type: TYPE.SINGLE,
        },
        editEveryonesCalendar: {
          key: "editEveryonesCalendar",
          name: "editEveryonesCalendar",
          label: "Edit everyones calendar",
          rule: "servicedesk::calenderandtimesheet.editeveryonescalendar",
          type: TYPE.SINGLE,
        },
        editEveryonesTimesheet: {
          key: "editEveryonesTimesheet",
          name: "editEveryonesTimesheet",
          label: "Edit everyones timesheet",
          rule: "servicedesk::calenderandtimesheet.editeveryonestimesheet",
          type: TYPE.SINGLE,
        },
      },
    },
  },
  {
    customers: {
      key: "customers_collection",
      label: "Customers",
      type: TYPE.COLLECTION,
      contents: {
        customers: {
          key: "customers",
          name: "customers",
          label: "Customers",
          rule: "",
          type: TYPE.SINGLE,
        },
        addOrEditCustomers: {
          key: "addOrEditCustomers",
          name: "addOrEditCustomers",
          label: "Add/Edit customers",
          rule: "customers::customer.addcustomer",
          type: TYPE.SINGLE,
        },
        addBulkCustomers: {
          key: "addBulkCustomers",
          name: "addBulkCustomers",
          label: "Add bulk customers",
          rule: "customers::customer.bulkaddcustomer",
          type: TYPE.SINGLE,
        },
        sendBroadcastMessage: {
          key: "sendBroadcastMessage",
          name: "sendBroadcastMessage",
          label: "Send announcement",
          rule: "customers::customer.sendboradcastmessage",
          type: TYPE.SINGLE,
        },
        updateCustomerDetails: {
          key: "updateCustomerDetails",
          name: "updateCustomerDetails",
          label: "Update Customer details",
          rule: "customers::customer.updatecustomerdetails",
          type: TYPE.SINGLE,
        },
        denyCustomers: {
          key: "denyCustomers",
          name: "denyCustomers",
          label: "Deny customers",
          rule: "customers::customer.denycustomers",
          type: TYPE.SINGLE,
        },
        deleteCustomers: {
          key: "deleteCustomers",
          name: "deleteCustomers",
          label: "Delete customers",
          rule: "customers::customer.deletecustomers",
          type: TYPE.SINGLE,
        },
        exportCustomers: {
          key: "exportCustomers",
          name: "exportCustomers",
          label: "Export customers",
          rule: "customers::customer.exportcustomers",
          type: TYPE.SINGLE,
        },
      },
    },
    users: {
      key: "users_collection",
      label: "Users",
      type: TYPE.COLLECTION,
      contents: {
        addOrEditUsers: {
          key: "addOrEditUsers",
          name: "addOrEditUsers",
          label: "Add/Edit users",
          rule: "customers:::customer.adduser",
          type: TYPE.SINGLE,
        },
        addBulkUsers: {
          key: "addBulkUsers",
          name: "addBulkUsers",
          label: "Add bulk users",
          rule: "customers:::customer.addbulkuser",
          type: TYPE.SINGLE,
        },
        addSyncAccountforUsers: {
          key: "addSyncAccountforUsers",
          name: "addSyncAccountforUsers",
          label: "Add sync account for users",
          rule: "customers:::customer.syncaccount",
          type: TYPE.SINGLE,
        },
        updateSyncSettings: {
          key: "updateSyncSettings",
          name: "updateSyncSettings",
          label: "Update sync settings",
          rule: "customers:::customer.updatesyncsettings",
          type: TYPE.SINGLE,
        },
        manualSyncUsers: {
          key: "manualSyncUsers",
          name: "manualSyncUsers",
          label: "Manual sync users",
          rule: "customers:::customer.manualsyncuser",
          type: TYPE.SINGLE,
        },
        updateUserDetails: {
          key: "updateUserDetails",
          name: "updateUserDetails",
          label: "Update user details",
          rule: "customers:::customer.updateuserdetails",
          type: TYPE.SINGLE,
        },
        deniedUserGroups: {
          key: "deniedUserGroups",
          name: "deniedUserGroups",
          label: "Denied user groups",
          rule: "customers:::customer.deniedusergroups",
          type: TYPE.SINGLE,
        },
        deleteUsers: {
          key: "deleteUsers",
          name: "deleteUsers",
          label: "Delete users",
          rule: "customers:::customer.deleteusers",
          type: TYPE.SINGLE,
        },
        exportUsers: {
          key: "exportUsers",
          name: "exportUsers",
          label: "Export users",
          rule: "customers:::customer.exportusers",
          type: TYPE.SINGLE,
        },
      },
    },
    broadcastMessages: {
      key: "broadcast_Messages_collection",
      label: "Announcements",
      type: TYPE.COLLECTION,
      contents: {
        broadcastMessages: {
          key: "broadcastMessages",
          name: "broadcastMessages",
          label: "Announcements",
          rule: "customers:::customer.broadcastmessage",
          type: TYPE.SINGLE,
        },
        createNewBroadcastMessages: {
          key: "createNewBroadcastMessages",
          name: "createNewBroadcastMessages",
          label: "Create new announcement",
          rule: "customers:::customer.createnewbroadcastmessage",
          type: TYPE.SINGLE,
        },
        manageBroadcastMessages: {
          key: "manageBroadcastMessages",
          name: "manageBroadcastMessages",
          label: "Manage announcements",
          rule: "customers:::customer.managebroadcastmessages",
          type: TYPE.SINGLE,
        },
      },
    },
  },
  {
    controlCenter: {
      key: "control_center_collection",
      label: "Control center",
      type: TYPE.COLLECTION,
      blockedResource: [RESOURCE_TYPES.RESOURCE],
      contents: {
        addResource: {
          key: "addResource",
          name: "addResource",
          label: "Add resource",
          rule: "controlcenter::msp.addresources",
          type: TYPE.SINGLE,
        },
        addGroups: {
          key: "addGroups",
          name: "addGroups",
          label: "Add  resource groups",
          rule: "controlcenter::msp.addgroups",
          type: TYPE.SINGLE,
        },
        addRole: {
          key: "addRole",
          name: "addRole",
          label: "Add resource types",
          rule: "controlcenter::msp.addrole",
          type: TYPE.SINGLE,
        },
        addMembersToGroup: {
          key: "addMembersToGroup",
          name: "addMembersToGroup",
          label: "Add members to resource group",
          rule: "controlcenter::msp.addmemberstogroup",
          type: TYPE.SINGLE,
        },
        updateWhiteList: {
          key: "updateWhiteList",
          name: "updateWhiteList",
          label: "Update branding settings",
          rule: "controlcenter::msp.updatewhitelist",
          type: TYPE.SINGLE,
        },
        updateSecuritySettings: {
          key: "updateSecuritySettings",
          name: "updateSecuritySettings",
          label: "Update security settings",
          rule: "controlcenter::msp.updatesecuritysettings",
          type: TYPE.SINGLE,
        },
        updateNotificationSettings: {
          key: "updateNotificationSettings",
          name: "updateNotificationSettings",
          label: "Update notification settings",
          rule: "controlcenter::msp.updatenotificationsettings",
          type: TYPE.SINGLE,
        },
        updatePermissions: {
          key: "updatePermissions",
          name: "updatePermissions",
          label: "Update permission",
          rule: "controlcenter::msp.updatepermissions",
          type: TYPE.SINGLE,
        },
        updateResourceDetails: {
          key: "updateResourceDetails",
          name: "updateResourceDetails",
          label: "Update resource details",
          rule: "controlcenter::msp.updateresourcedetails",
          type: TYPE.SINGLE,
        },
        updateEmailSettings: {
          key: "updateEmailSettings",
          name: "updateEmailSettings",
          label: "Update email settings",
          rule: "controlcenter::msp.updateemailsettings",
          type: TYPE.SINGLE,
        },
        updateConversationSettings: {
          key: "updateConversationSettings",
          name: "updateConversationSettings",
          label: "Update conversation settings",
          rule: "controlcenter::msp.updateconversationsettings",
          type: TYPE.SINGLE,
        },
        accessAccountDetails: {
          key: "accessAccountDetails",
          name: "accessAccountDetails",
          label: "Access account details",
          rule: "controlcenter::msp.accessaccountdetails",
          type: TYPE.SINGLE,
        },
        updateAccountDetails: {
          key: "updateAccountDetails",
          name: "updateAccountDetails",
          label: "Update account details",
          rule: "controlcenter::msp.updateaccountdetails",
          type: TYPE.SINGLE,
        },
      },
    },

    servicedeskConfiguration: {
      key: "service_desk_Configuration_collection",
      label: "Service desk configuration",
      type: TYPE.COLLECTION,
      blockedResource: [RESOURCE_TYPES.ADMIN, RESOURCE_TYPES.RESOURCE],
      contents: {
        configuration: {
          key: "configuration",
          name: "configuration",
          label: "Configurations",
          rule: "controlcenter::customerconf.configuration",
          type: TYPE.SINGLE,
        },
        manageGeneralSettings: {
          key: "manageGeneralSettings",
          name: "manageGeneralSettings",
          label: "Manage general settings",
          rule: "controlcenter:configuration.generalsettings",
          type: TYPE.SINGLE,
        },
        manageWidgets: {
          key: "manageWidgets",
          name: "manageWidgets",
          label: "Manage widgets",
          rule: "controlcenter:configuration.widgets",
          type: TYPE.SINGLE,
        },
        manageAttributes: {
          key: "manageAttributes",
          name: "manageAttributes",
          label: "Manage attributes",
          rule: "controlcenter:configuration.attributes",
          type: TYPE.SINGLE,
        },
        manageTags: {
          key: "manageTags",
          name: "manageTags",
          label: "Manage tags",
          rule: "controlcenter:configuration.tags",
          type: TYPE.SINGLE,
        },
        manageBoards: {
          key: "manageBoards",
          name: "manageBoards",
          label: "Manage boards",
          rule: "controlcenter:configuration.boards",
          type: TYPE.SINGLE,
        },
        manageTicketTemplates: {
          key: "manageTicketTemplates",
          name: "manageTicketTemplates",
          label: "Manage ticket templates",
          rule: "controlcenter:configuration.tickettemplates",
          type: TYPE.SINGLE,
        },
        manageRequestTemplates: {
          key: "manageRequestTemplates",
          name: "manageRequestTemplates",
          label: "Manage request templates",
          rule: "controlcenter:configuration.requesttemplates",
          type: TYPE.SINGLE,
        },
        manageProjectTemplates: {
          key: "manageProjectTemplates",
          name: "manageProjectTemplates",
          label: "Manage project templates",
          rule: "controlcenter:configuration.projecttemplates",
          type: TYPE.SINGLE,
        },
        manageCannedResponses: {
          key: "manageCannedResponses",
          name: "manageCannedResponses",
          label: "Manage canned responses",
          rule: "controlcenter:configuration.cannedresponses",
          type: TYPE.SINGLE,
        },
        manageChecklistLibrary: {
          key: "manageChecklistLibrary",
          name: "manageChecklistLibrary",
          label: "Manage checklist library",
          rule: "controlcenter:configuration.library",
          type: TYPE.SINGLE,
        },
        manageAutomationRules: {
          key: "manageAutomationRules",
          name: "manageAutomationRules",
          label: "Manage automation rules",
          rule: "controlcenter:configuration.automationrules",
          type: TYPE.SINGLE,
        },
        manageQARules: {
          key: "manageQARules",
          name: "manageQARules",
          label: "Manage QA rules",
          rule: "controlcenter:configuration.qarules",
          type: TYPE.SINGLE,
        },
      },
    },

    customerConfiguration: {
      key: "customer_Configuration_collection",
      label: "Customer configuration",
      type: TYPE.COLLECTION,
      blockedResource: [RESOURCE_TYPES.ADMIN, RESOURCE_TYPES.RESOURCE],
      contents: {
        customerConfiguration: {
          key: "customerConfiguration",
          name: "customerConfiguration",
          label: "Configurations",
          rule: "controlcenter::customerconf.customerconfiguration",
          type: TYPE.SINGLE,
        },
        addSLA: {
          key: "addSLA",
          name: "addSLA",
          label: "Add SLA",
          rule: "controlcenter::customerconf.addsla",
          type: TYPE.SINGLE,
        },
        manageSLA: {
          key: "manageSLA",
          name: "manageSLA",
          label: "Manage SLA",
          rule: "controlcenter::customerconf.managesla",
          type: TYPE.SINGLE,
        },
        manageWidgetsCustomerConf: {
          key: "manageWidgetsCustomerConf",
          name: "manageWidgetsCustomerConf",
          label: "Manage widgets",
          rule: "controlcenter::customerconf.managewidgets",
          type: TYPE.SINGLE,
        },
      },
    },

    generalSettings: {
      key: "general_Settings_collection",
      label: "General settings",
      type: TYPE.COLLECTION,
      blockedResource: [RESOURCE_TYPES.ADMIN, RESOURCE_TYPES.RESOURCE],
      contents: {
        generalSettingsCustomerConf: {
          key: "generalSettingsCustomerConf",
          name: "generalSettingsCustomerConf",
          label: "Update status",
          rule: "controlcenter::customerconf.generalsettings",
          type: TYPE.SINGLE,
        },
        addStatusMessage: {
          key: "addStatusMessage",
          name: "addStatusMessage",
          label: "Add status message",
          rule: "controlcenter::customerconf.addstatusmessage",
          type: TYPE.SINGLE,
        },
        updateProfileSettings: {
          key: "updateProfileSettings",
          name: "updateProfileSettings",
          label: "Update profile settings",
          rule: "controlcenter::customerconf.updateprofilesettings",
          type: TYPE.SINGLE,
        },
        updateSecuritySettingsCustomerConf: {
          key: "updateSecuritySettingsCustomerConf",
          name: "updateSecuritySettingsCustomerConf",
          label: "Update security settings",
          rule: "controlcenter::customerconf.updatesecuritysettings",
          type: TYPE.SINGLE,
        },
        updateProfessionalBadge: {
          key: "updateProfessionalBadge",
          name: "updateProfessionalBadge",
          label: "Update professional badge",
          rule: "controlcenter::customerconf.updateprofessionalbadge",
          type: TYPE.SINGLE,
        },
        updateDelegation: {
          key: "updateDelegation",
          name: "updateDelegation",
          label: "Update delegation",
          rule: "controlcenter::customerconf.updatedelegation",
          type: TYPE.SINGLE,
        },
      },
    },
  },
];

export const ColorArray = [
  "#7967D5",
  "#907BFF",
  "#A595FD",
  "#BAC1FF",
  "#C9CEFB",
  "#D8DCFF",
  "#C4F0EA",
  "#CAFFF8",
  "#9DF4E9",
  "#A6E5F7",
  "#7BD8F2",
  "#55C2E0",
  "#1CA8CF",
  "#1DBAE5",
  "#168AA9",
  "#306F93",
  "#2B6180",
  "#25536D",
  "#053D5C",
];

export const pinnedChatColors = [
  {
    primary: "#907BFF",
    tint: "#E9D8FB",
  },
  {
    primary: "#5563F5",
    tint: "#E7E9FE",
  },
  {
    primary: "#19B1F7",
    tint: "#BEEBFC",
  },
  {
    primary: "#198038",
    tint: "#E0FFE0",
  },
  {
    primary: "#E3B309",
    tint: "#F8F1CF",
  },
  {
    primary: "#FF832B",
    tint: "#FFE6D5",
  },
  {
    primary: "#EF4C54",
    tint: "#FFEEED",
  },
];
