import { CreateCheckoutSessionInput } from "shared";
import { axiosClient } from "utilz";
import { apiErrorHandler } from "utilz/helpers";

export const getActiveResourceCount = async () => {
  try {
    const url = "/v1/subscription/active/resources/count";
    const result = await axiosClient.get(url);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const validatePromoCode = async ({
  code,
  planId,
}: {
  code: string;
  planId: string;
}) => {
  try {
    const url = `/v1/subscription/redeem/coupon/${code}?plan_id=${planId}`;
    const result = await axiosClient.post(url);
    const resData = result.data;
    return resData || {};
  } catch (error) {
    throw error;
  }
};

export const createCheckoutSession = async ({
  promocode,
  seatQuantity,
  planId,
  stripePriceId,
}: CreateCheckoutSessionInput) => {
  try {
    const url = "/v1/subscription/payment/checkout-session";
    const data = {
      ...(promocode && { promotion_code: promocode }),
      seat_quantity: seatQuantity,
      plan_id: planId,
      stripe_price_id: stripePriceId,
    };
    const result = await axiosClient.post(url, data);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw result;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateSubscription = async ({
  promocode,
  seatQuantity,
  planId,
  stripePriceId,
}: CreateCheckoutSessionInput) => {
  try {
    const url = "/v1/subscription";
    const data = {
      ...(promocode && { promotion_code: promocode }),
      seat_quantity: seatQuantity,
      plan_id: planId,
      // stripe_price_id: stripePriceId,
    };
    const result = await axiosClient.patch(url, data);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw result;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
export const getSubscriptionDetails = async () => {
  try {
    const url = "/v1/subscription/details";
    const result = await axiosClient.get(url);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw result;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getAllInvoice = async () => {
  try {
    const url = "/v1/subscription/invoices";
    const result = await axiosClient.get(url);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw result;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const cancelSubscription = async () => {
  try {
    const url = "/v1/cancel/subscription";
    const data = {};
    const result = await axiosClient.post(url, data);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw result;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getProratedAmount = async ({
  discountId,
  promoCode,
}: {
  discountId?: string;
  promoCode?: string;
}) => {
  try {
    console.log({
      promoCode,
      discountId,
    });
    const url = "/v1/subscription/prorate/amount";
    const result = await axiosClient.get(url, {
      params: {
        discount: discountId,
        promotion_code: promoCode,
      },
    });
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getCards = async () => {
  try {
    const url = "/v1/subscription/cards";
    const result = await axiosClient.get(url);
    const resData = result.data;
    return resData?.data || {};
    // if (resData.success && resData.statusCode === 200) {
    //   return resData?.data || {};
    // } else {
    //   throw new Error(resData?.message);
    // }
  } catch (error) {
    throw error;
  }
};

export const deleteCard = async (cardId: string) => {
  try {
    const url = `/v1/subscription/card/${cardId}`;
    const data = {};
    const result = await axiosClient.delete(url, data);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getSetupIntent = async () => {
  try {
    const url = "/v2/subscription/card/";
    const data = {};
    const result = await axiosClient.post(url, data);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const setDefaultCard = async (paymentId: string) => {
  try {
    const url = "v1/subscription/default-payment-method";
    const data = {
      payment_method: paymentId,
    };
    const result = await axiosClient.patch(url, data);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const retryPayment = async () => {
  try {
    const url = "v1/subscription/pay/invoice/due";

    const result = await axiosClient.post(url);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getActivePlans = async () => {
  try {
    const url = "v1/subscription/open/plans";
    const result = await axiosClient.get(url);
    const resData = result.data;
    if (resData.success && resData.statusCode === 200) {
      return resData?.data || {};
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
