export const stripHtml = (htmlString: string) => {
  htmlString = htmlString.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "");

  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = htmlString;

  const bodyElement = tempDivElement.querySelector("body");
  let textContent =
    bodyElement?.innerText ||
    bodyElement?.textContent ||
    tempDivElement.innerText ||
    tempDivElement.textContent ||
    "";

  textContent = textContent.replace(/<\/?[^>]+(>|$)/g, ""); // remove html opening, closing or unclosed tags if any

  return textContent.trim();
};
