import { GENERAL_FORM_CONSTANT } from "../../components/general-info-form/constants";
import { TICKET_DETAILS_CONSTANT } from "../../components/ticket-details-form/constants";
export default function validateFormDetails(data) {
  var CONSTANTS = [GENERAL_FORM_CONSTANT]; // TICKET_DETAILS_CONSTANT, TICKET_DETAILS_CONSTANT.top
  if (Object.entries(data).length <= 0) {
    return false;
  }
  for (const forModel of CONSTANTS) {
    for (const form of Object.values(forModel)) {
      if (form["name"] === undefined) {
        continue;
      }
      if (
        form.required &&
        (data[form.name] === undefined ||
          (!isNaN(data[form.name]) && data[form.name] <= 0) ||
          data[form.name] === "")
      ) {

        // console.log("🚀 ~ file: index.js:11 ~ validateFormDetails ~ data[form.name]:", data[form.name])
        return false;
      }
    }
  }
  return true;
}
