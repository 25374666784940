function BoardIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83317 15.4165V14.9998"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83313 12.7085L5.83321 12.2918"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83317 10V9.58333"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="15"
        y="17.5"
        width="12.5"
        height="10"
        rx="2.5"
        transform="rotate(-180 15 17.5)"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0002 12.5H16.2502C17.4008 12.5 18.3335 11.5673 18.3335 10.4167V4.58333C18.3335 3.43274 17.4008 2.5 16.2502 2.5H7.91683C6.76624 2.5 5.8335 3.43274 5.8335 4.58333V7.5"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default BoardIcon;
