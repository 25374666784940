import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControlProps,
  useTheme,
  SxProps,
  Theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import React from "react";

interface CustomRadioProps extends FormControlProps {
  row?: boolean;
  label?: string;
  data: Array<any>;
  labelMappingKey: string;
  valueMappingKey: string;
  value: any;
  onChange: (value: any) => void;
  RadioGroupProps?: SxProps<Theme> | undefined;
  radioButtonStyle?: SxProps<Theme> | undefined;
}
export const RadioButtonGroup = ({
  row = false,
  label,
  data,
  labelMappingKey,
  valueMappingKey,
  onChange,
  value,
  defaultValue,
  RadioGroupProps,
  radioButtonStyle,
}: CustomRadioProps) => {
  const theme = useTheme();
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        row={row}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue ? defaultValue : ""}
        name="radio-buttons-group"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        sx={{
          ...RadioGroupProps,
        }}
      >
        {data.map((item) => (
          <FormControlLabel
            key={item[valueMappingKey]}
            sx={{
              ...theme.typography["body-small"],
              color: theme.palette.lightBg.low,
            }}
            value={item[valueMappingKey]}
            control={
              <Radio
                sx={{ ...radioButtonStyle }}
                checkedIcon={<CustomRadioCheckedIcon />}
                icon={<CustomRadioIcon />}
                {...item?.radioButtonProps}
              />
            }
            label={item[labelMappingKey]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const CustomRadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  border: "2px solid #CCCCCC",
  backgroundColor: "#FFFFFF",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
  backgroundColor: "#FFFFFF",
  border: "6px solid #5563F5",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: "\"\"",
  },
});
