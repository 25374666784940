function signoutIcon(props) {


  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 15L22.5 12L19.5 9"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M18 12L12 12"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M18.364 18.364C15.79 20.938 11.9189 21.708 8.55585 20.3149C5.19278 18.9219 3 15.6402 3 12C3 8.35986 5.19278 5.07813 8.55585 3.6851C11.9189 2.29207 15.79 3.06208 18.364 5.63606"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}

export default signoutIcon;