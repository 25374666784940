const TABLE_COMPONENT_HEIGHT = { xs: "368px", md: "512px", xl: "816px" };

export const canvasBoxDesigns = {
  margin: 0,
  padding: 0,
  width: "100%",
  height: "calc(100vh - 256px)", ///{ xs: TABLE_COMPONENT_HEIGHT['xs'], md: TABLE_COMPONENT_HEIGHT['md'], xl: TABLE_COMPONENT_HEIGHT['xl'] },
  overflowX: "auto",
  display: "grid",
  borderRadius: "24px",
  "&:: -webkit-scrollbar": { display: "none" },
};
