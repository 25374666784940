import {
  Box,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomTypography } from "../../../../../../../library/base";
import CustomIcon from "../../../../../../../library/icon-library";
import { CustomCard } from "../../../../../../../library/layers";
import PropTypes from "prop-types";
import SpaceFiller from "./Components/space-filler";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
function ConfigurationTable(props) {
  return (
    <ThemeProvider>
      <CustomCard
        content={
          <Box
            width={"100%"}
            sx={{
              display: "grid",
              overflowX: "scroll",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "48px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                px: "40px",
                columnGap: "16px",
                alignItems: "center",
              }}
            >
              {Object.values(props.header).map((item) => {
                return item.expanded ? (
                  <Box
                    key={item.key}
                    sx={{
                      display: "block",
                      flexGrow: "1",
                      minWidth: item.minWidth || 0,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                        maxWidth: "200px",
                        overflowX: "clip",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.label}
                    </Typography>
                  </Box>
                ) : (
                  <Box key={item.key} width={item.size}>
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                        maxWidth: "200px",
                        overflowX: "clip",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            {props.body}
            {<SpaceFiller count={props?.body?.length || 0} />}
          </Box>
        }
      />
    </ThemeProvider>
  );
}
ConfigurationTable.propTypes = {
  header: PropTypes.object.isRequired,
  body: PropTypes.array.isRequired,
};

ConfigurationTable.defaultProps = {
  header: {},
  body: [],
};

export default ConfigurationTable;
