import MuiButton from "@mui/material/Button";
import PropTypes from "prop-types";
import defaultSizes from "./constants/defaultSizes";
import defaultColors from "./constants/defaultColors";
import classConfig from "../typography/constants/config.json";
import Icon from "../../icon-library";
import { styled } from "@mui/material/styles";
import { useState, useEffect, useContext } from "react";
import IconContext from "../../icon-library/config/iconContext";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

function Button(props) {
  const colorPalette = defaultColors[props.varient] ?? defaultColors.primary;
  const [color, setColor] = useState(colorPalette.default.color);

  const [state, setState] = useState({
    sizeClass: "extra-small",
    variantClass: "regular",
    faceClass: "regular",
  });

  useEffect(() => {
    try {
      let { sizeClass, variantClass, faceClass } = state;
      sizeClass = classConfig?.SIZE["SMALL-ALPHA"].map;

      variantClass = classConfig?.VARIANT.MEDIUM.map;
      faceClass = classConfig?.VARIANT.MEDIUM.faceMap;
      setState({
        ...state,
        sizeClass: sizeClass,
        variantClass: variantClass,
        faceClass: faceClass,
      });
    } catch (err) {
      console.log("[ERROR:BUUTON TYPOGRAPHY] Setting props");
      console.log(err);
    }
  }, []);

  function getIcon(icon) {
    if (icon) {
      return (
        <IconContext.Provider value={true}>
          <Icon
            icon={icon}
            color={color}
            disabled={props.disabled}
            varientcolorpalette={colorPalette}
            direction={props.direction}
          />
        </IconContext.Provider>
      );
    }
  }

  function getPadding(size) {
    var padding;
    switch (size) {
      case "xl":
        padding = "10px 24px 10px 24px";
        // sizeClass = classConfig?.SIZE["MEDIUM-ALPHA"].map;
        if (getIcon(props.icon)) {
          padding =
            props.isTrailingIcon === true
              ? "10px 16px 10px 20px"
              : "10px 20px 10px 16px";
        }
        break;
      case "lg":
        padding = "12px 24px 12px 24px";
        if (getIcon(props.icon)) {
          padding =
            props.isTrailingIcon === true
              ? "12px 16px 12px 20px"
              : "12px 20px 12px 16px";
        }
        break;
      case "md":
        padding = "8px 24px 8px 24px";
        if (getIcon(props.icon)) {
          padding =
            props.isTrailingIcon === true
              ? "8px 12px 8px 16px"
              : "8px 16px 8px 12px";
        }
        break;
      default:
        padding = "4px 24px 4px 24px";
        if (getIcon(props.icon)) {
          padding =
            props.isTrailingIcon === true
              ? "4px 12px 4px 16px"
              : "4px 16px 4px 12px";
        }
    }
    return padding;
  }

  return (
    <ThemeProvider>
      <MuiButton
        onMouseEnter={() => {
          setColor(colorPalette.hover.color);
        }}
        onMouseLeave={() => {
          setColor(colorPalette.default.color);
        }}
        disableRipple
        disabled={props.disabled}
        onClick={props.onClick}
        startIcon={!props.isTrailingIcon ? getIcon(props.icon) : null}
        endIcon={props.isTrailingIcon ? getIcon(props.icon) : null}
        sx={{
          width: props.fullWidth === true ? "100%" : "fit-content",
          height: {
            xs: defaultSizes.sm,
            sm: defaultSizes.sm,
            md: defaultSizes.md,
            lg: defaultSizes.lg,
            xl: defaultSizes.xl,
          },
          padding: {
            xs: getPadding("sm"),
            sm: getPadding("sm"),
            md: getPadding("md"),
            lg: getPadding("lg"),
            xl: getPadding("xl"),
          },
          gap: props.isTrailingIcon === true ? "8px" : "0px",
          borderRadius: "8px",
          borderWidth: "1px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderStyle: "solid",
          outlineWidth: "1px",
          outlineOffset: "-3px",
          outlineStyle: "solid",
          textTransform: "none",
          flexDirection: "row",
          // minWidth: '102px',

          borderColor: colorPalette.default.borderColor ?? "transparent",
          backgroundColor: colorPalette.default.backgroundColor,
          outlineColor: colorPalette.default.outlineColor ?? "transparent",
          color: colorPalette.default.color,
          "&.Mui-focusVisible": {
            backgroundColor: colorPalette.focused.backgroundColor,
            borderColor: colorPalette.focused.borderColor ?? "transparent",
            outlineColor: colorPalette.focused.outlineColor ?? "transparent",
            color: colorPalette.focused.color,
          },
          "&.MuiButton-startIcon": {
            marginLeft: "8px",
            mareginRight: "",
          },
          "&.MuiButton-endIcon": {
            marginLeft: "",
            mareginRight: "16px",
          },
          "&:hover": {
            borderColor: colorPalette.hover.borderColor ?? "transparent",
            backgroundColor: colorPalette.hover.backgroundColor,
            color: colorPalette.hover.color,
          },
          "&:active": {
            backgroundColor: colorPalette.pressed.backgroundColor,
            color: colorPalette.pressed.color,
          },
          "&.Mui-disabled": {
            backgroundColor: colorPalette.disabled.backgroundColor,
            borderColor: colorPalette.disabled.borderColor ?? "transparent",
            outlineColor: colorPalette.disabled.outlineColor ?? "transparent",
            color: colorPalette.disabled.color,
          },
        }}
      >
        <span
          className={`typography-font-family-class-${state?.faceClass} 
                 typography-font-size-class-${state?.sizeClass} 
                 typography-font-weight-class-${state?.variantClass}`}
          style={{ textTransform: "capitalize" }}
        >
          {props.label}
        </span>
      </MuiButton>
    </ThemeProvider>
  );
}

function ButtonStyled(colorPalette) {
  return styled((props) => <MuiButton disableRipple {...props} />)(
    ({ theme }) => ({
      borderRadius: "8px",
      borderWidth: "1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      fontWeight: "500",
      borderStyle: "solid",
      outlineWidth: "1px",
      outlineOffset: "-3px",
      outlineStyle: "solid",
      textTransform: "none",
      flexDirection: "row",
      height: "32px",
      // minWidth: '102px',

      borderColor: colorPalette.default.borderColor ?? "transparent",
      backgroundColor: colorPalette.default.backgroundColor,
      outlineColor: colorPalette.default.outlineColor ?? "transparent",
      color: colorPalette.default.color,
      "&.Mui-focusVisible": {
        backgroundColor: colorPalette.focused.backgroundColor,
        borderColor: colorPalette.focused.borderColor ?? "transparent",
        outlineColor: colorPalette.focused.outlineColor ?? "transparent",
        color: colorPalette.focused.color,
      },
      "&.MuiButton-startIcon": {
        marginLeft: "8px",
        mareginRight: "",
      },
      "&.MuiButton-endIcon": {
        marginLeft: "",
        mareginRight: "16px",
      },
      "&:hover": {
        backgroundColor: colorPalette.hover.backgroundColor,
        color: colorPalette.hover.color,
      },
      "&:active": {
        backgroundColor: colorPalette.pressed.backgroundColor,
        color: colorPalette.pressed.color,
      },
      "&.Mui-disabled": {
        backgroundColor: colorPalette.disabled.backgroundColor,
        borderColor: colorPalette.disabled.borderColor ?? "transparent",
        outlineColor: colorPalette.disabled.outlineColor ?? "transparent",
        color: colorPalette.disabled.color,
      },
    })
  );
}

Button.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
  //size: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  varient: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isTrailingIcon: PropTypes.bool.isRequired,
  icon: PropTypes.string,
};

Button.defaultProps = {
  fullWidth: false,
  // size: "sm",
  disabled: false,
  varient: "primary",
  label: "",
  onClick: () => {},
  isTrailingIcon: false,
};

export default Button;
