import React from "react";

// Legacy Imports
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";

// Custom Designs
const CustomRadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,  
  border: "2px solid #CCCCCC",
  backgroundColor: "#FFFFFF",
  backgroundImage:"linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "input:disabled ~ &": {
    boxShadow: "none",
    background:"rgba(206,217,224,.5)",
  },
}));
  
const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
  backgroundColor: "#FFFFFF",
  border: "6px solid #5563F5",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: "\"\"",
  },
});

export const CustomRadioBtn: React.FC = (props: any)=> {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomRadioCheckedIcon />}
      icon={<CustomRadioIcon />}
      {...props}
    />
  );
};