import { Stack, Typography, useTheme } from "@mui/material";
import appsForMspPreview from "./assets/images/appsForMspPreview.webp";

export const AppsForMsp = () => {
  const theme = useTheme();
  return (
    <Stack p="24px" gap="16px">
      <Typography variant="h6">DeskDay Resource Mobile App</Typography>
      <Stack
        p="16px 16px 0 16px"
        gap="10px"
        bgcolor={theme.palette.secondary["purple-tint-3"]}
        alignItems={"start"}
      >
        <Stack
          bgcolor={theme.palette.primary.main}
          border={`1px solid ${theme.palette.secondary["purple-tint"]}`}
          borderRadius={"6px"}
          p="4px 16px"
        >
          <Typography variant="body-xsmall" color="darkBg.main">
            Coming Soon
          </Typography>
        </Stack>
        <Stack alignSelf={"center"}>
          <img
            style={{ width: "196px", aspectRatio: 1 }}
            src={appsForMspPreview}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
