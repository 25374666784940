import { Box, Dialog, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTypography,
} from "../../../../../../../../../library/base";
import {
  CustomRoundCloseButton,
  DragAndDropArea,
} from "../../../../Components";
import { AttachmentItemTile } from "./components";
import { services } from "../../../../../../../../../services";
import { v4 } from "uuid";

import { CustomToaster } from "../../../../../../../../../library/system";
import { STATUS, ticketTemplateDataBuilder } from "./constant";
import { useGlobalStore } from "controller/store/globalStore";

function TemplateAttchmentModal(props) {
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    open: false,
    uploadingData: [],
  });
  const [toasterState, setToasterState] = useState({
    open: false,
    severity: "WARNING",
    message: "Cannot close while uploading file",
  });
  useEffect(() => {
    setState({
      ...state,
      open: props.isOpen,
      uploadingData: props.value,
    });
  }, [props]);

  const onAddAttachmentsHandler = (data) => {
    var newUploadingDatas = data.value.map((file) => {
      var formData = new FormData();
      formData.append("files", file);
      return ticketTemplateDataBuilder({
        key: v4(),
        file: file,
        status: STATUS.UPLOADING,
        uploader:
          services.controlCenterservices.configurationService.uploadticketTemplateAttachment(
            mspId,
            formData
          ),
      });
    });
    setState({
      ...state,
      uploadingData: [...state.uploadingData, ...newUploadingDatas],
    });
  };

  const onDeleteHandler = (data) => {
    var newUpld = state.uploadingData;
    const index = newUpld.indexOf(data);
    newUpld.splice(index, 1);
    setState({
      ...state,
      uploadingData: [...newUpld],
    });
  };

  const onErrorHandler = (data) => {
    var newUpld = state.uploadingData;
    const index = newUpld.indexOf(data);
    newUpld[index] = {
      ...newUpld[index],
      status: STATUS.ERROR,
    };
    setState({
      ...state,
      uploadingData: newUpld,
    });
  };

  const onUploadCompleteHandler = (upldData, data) => {
    var newUpld = state.uploadingData;

    const index = newUpld.indexOf(data);
    if (index < 0) {
      return;
    }

    // console.log("🚀 ~ file: index.js:83 ~ onUploadCompleteHandler ~ data:", data)
    newUpld[index] = {
      ...data,
      status: STATUS.COMPLETE,
      url: upldData.url,
      mapping_id: upldData.mapping_id,
      response: upldData,
    };

    setState({
      ...state,
      uploadingData: newUpld,
    });
  };

  const validateData = () => {
    for (var index = 0; index < state.uploadingData.length; index++) {
      if (state.uploadingData[index].status === STATUS.UPLOADING) {
        setToasterState({
          ...toasterState,
          open: true,
        });
        return false;
      }
    }

    return true;
  };

  const onSubmitHandler = () => {
    if (validateData() === true) {
      props.onSubmit(state.uploadingData);
    }
  };

  const handleClose = () => {
    if (validateData() === true) {
      props.onCancel();
    }
  };

  return (
    <Dialog
      open={state.open}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          borderRadius: "24px",
          maxWidth: "900px",
          height: "min(80%,900px)",
        },
      }}
    >
      <CustomToaster
        open={toasterState.open}
        severity={toasterState.severity}
        message={toasterState.message}
        callback={() => {
          setToasterState({
            ...toasterState,
            open: false,
          });
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          height: "100%",
        }}
      >
        {/* head */}

        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            alignItems: "center",
            columnGap: "40px",
            padding: "20px 32px 32px 32px",
          }}
        >
          <Box
            direction={"column"}
            sx={{
              flexShrink: "1",
              flexGrow: "1",
              justifyContent: "center",
            }}
          >
            <CustomTypography
              content="Attach Files"
              size="MEDIUM-ALPHA"
              variant="BOLD"
            />
          </Box>
          <CustomRoundCloseButton onClick={handleClose} />
        </Stack>

        {/* body */}

        <Stack
          sx={{
            flex: "1",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              px: "32px",
            }}
          >
            <DragAndDropArea
              name="attachments"
              label={"Drop files here to attach or"}
              onAddFiles={onAddAttachmentsHandler}
            />
          </Box>

          <Stack
            sx={{
              flexGrow: "1",
              flexShrink: "1",
              overflow: "scroll",
              pt: "16px",
            }}
          >
            {state.uploadingData.map((item, index) => (
              <AttachmentItemTile
                key={`attachment-key-${index}`}
                onDelete={() => onDeleteHandler(item)}
                onUploadComplete={(id) => onUploadCompleteHandler(id, item)}
                onError={() => onErrorHandler(item)}
                value={item}
              />
            ))}
          </Stack>
        </Stack>

        {/* bottom layer */}

        <Stack
          flexGrow={0}
          flexShrink={0}
          flexDirection={"row-reverse"}
          columnGap={"16px"}
          sx={{
            width: "100%",
            py: "24px",
            px: "32px",
            boxShadow: "0px -4px 20px rgba(0, 0, 0, 0.08)",
          }}
        >
          <CustomButton label="Save" onClick={onSubmitHandler} />
          <CustomButton
            label="Cancel"
            varient="secondary"
            onClick={handleClose}
          />
        </Stack>
      </Box>
    </Dialog>
  );
}

TemplateAttchmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

TemplateAttchmentModal.defaultProps = {
  isOpen: false,
  value: [],
  onSubmit: () => {},
  onCancel: () => {},
};

export default TemplateAttchmentModal;
