import { useGlobalStore } from "controller";
import React from "react";
import { Navigate, Route, RouteProps, useNavigate } from "react-router-dom";

const SuperAdminPrivateRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const userRole = useGlobalStore((state) => state.role) || "";

  return (
    <>
      {userRole === "SUPERADMIN" ? (
        children
      ) : (
        <Navigate replace to="/app/unauthorized" />
      )}
    </>
  );
};

export default SuperAdminPrivateRoute;
