import { immer } from "zustand/middleware/immer";

interface NonPersistantUiStoreState {
  isTicketTimelineOpen: boolean;
}

interface NonPersistantUiStoreAction {
  toggleTicketTimeline: () => void;
}
export interface NonPersistantUiStoreInterface
  extends NonPersistantUiStoreState,
    NonPersistantUiStoreAction {}

export const nonPersistantUiSlice = immer<
  NonPersistantUiStoreState & NonPersistantUiStoreAction
>((set) => ({
  isTicketTimelineOpen: false,
  selectedTicketData: [],
  toggleTicketTimeline() {
    set((state) => {
      state.isTicketTimelineOpen = !state.isTicketTimelineOpen;
    });
  },
}));
