import { Divider, Stack, Typography } from "@mui/material";
import { Avatar, CircularLoader } from "components";
import { useGetAllResources, useUpdateResource } from "controller";
import { CustomToggleButton } from "library/base";
import { useState } from "react";
import toast from "react-hot-toast";
import { getFullName } from "utilz/helpers";

export const ManageResourceModal = () => {
  const {
    data: resources,
    refetch: refetchResources,
    isLoading: isGetAllResourcesLoading,
  } = useGetAllResources({
    sortColumn: "users.first_name",
    sortDirection: "asc",
  });

  const { mutateAsync: updateResource, isLoading: isUpdateResourceLoading } =
    useUpdateResource();
  const [clickedToggleBtnIndex, setClickedToggleBtnIndex] = useState(0);
  const handleToggle = async (resourceData: any) => {
    try {
      await updateResource({
        ...resourceData,
        resourceId: resourceData?.mapping_id,
        is_locked: !resourceData?.status,
      });
      toast.success("Resource updated successfully");
    } catch (error: any) {
      toast.error(error || "Something went wrong");
    }
  };
  return (
    <Stack sx={{ width: "376px" }} divider={<Divider />}>
      <Stack
        direction={"row"}
        sx={{
          px: "16px",
          py: "16px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant={"caption"}>Resources</Typography>
        <Typography variant={"caption"}>Active</Typography>
      </Stack>
      {resources?.result?.map((resource: any, index: number) => {
        const fullname = getFullName(
          resource.first_name,
          resource.middle_name,
          resource.last_name
        );
        return (
          <Stack
            direction={"row"}
            key={resource.mapping_id}
            sx={{
              px: "16px",
              py: "16px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
              <Avatar fullname={fullname} />{" "}
              <Typography
                sx={{ textTransform: "capitalize" }}
                variant={"body-small"}
              >
                {fullname}
              </Typography>
            </Stack>
            <Stack direction="row" gap="12px" alignItems={"center"}>
              {(isUpdateResourceLoading || isGetAllResourcesLoading) &&
                clickedToggleBtnIndex === index && (
                  <CircularLoader
                    size={"22px"}
                    sx={{
                      display: isUpdateResourceLoading ? "flex" : "none",
                    }}
                  />
                )}
              <CustomToggleButton
                isDisabled={isGetAllResourcesLoading || isUpdateResourceLoading}
                isChecked={!resource?.status}
                onChange={() => {
                  handleToggle(resource);
                  setClickedToggleBtnIndex(index);
                }}
              />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
