import { Box, Divider, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomTypography,
} from "../../../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../../../library/icon-library";
import { ticketTemplateFormBuilder } from "./constant";
import { FormDataConverter } from "./constant";
import { validateFormDetails } from "./Utils";
import toast from "react-hot-toast";
import { Modal, Stepper } from "components";
import { GeneralInfoForm, TicketDetailsForm } from "./components";

import { GENERAL_FORM_CONSTANT } from "./components/general-info-form/constants";
import { TemplateAttchmentModal } from "../../../component";
import { TICKET_DETAILS_CONSTANT } from "./components/ticket-details-form/constants";
import { CustomCheckBox } from "library/system/custom-form";
import { NewCheckListModal } from "pages/modules/control-center/configurations/components/service-desk-checklist/components/new-checklist-modal";

function TicketTemplateCreateModal(props) {
  const checklistRef = useRef();
  const generalInfoRef = useRef();
  const ticketDetailsRef = useRef();
  const [templateData, setTemplateData] = useState({});
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    setTemplateData(ticketTemplateFormBuilder(props.data));
  }, []);

  const stepperMoveForward = (data) => {
    if (step === 2) {
      formSubmitHandler(data);
      return;
    }
    setTemplateData({ ...data });
    setStep(step + 1);
  };

  const stepperMoveBackward = () => {
    if (step === 1) {
      props.onCancel();
      return;
    }
    setStep(step - 1);
  };

  const onChecklistSubmitHandler = () => {
    var result = checklistRef.current.submit();
    var newData = templateData;
    if (result.checklist_items.length <= 0) {
      return;
    }
    for (var item of result.checklist_items) {
      if (item?.checklist_item_name?.length <= 0) {
        return;
      }
    }
    newData[GENERAL_FORM_CONSTANT.checklists.name] = result.checklist_items;
    setTemplateData(newData);
    setIsChecklistModalOpen(false);
  };

  const onAttachmentSubmitHandler = (data) => {
    var newData = templateData;
    newData[GENERAL_FORM_CONSTANT.attachments.name] = data;
    setTemplateData(newData);
    setIsAttachmentOpen(false);
  };

  function formSubmitHandler(data) {
    var submittedData = FormDataConverter(data);
    const result = validateFormDetails(submittedData);
    if (result === true) {
      props.onSubmit(submittedData);
      return;
    }
    toast.error("Fill all required fields");
  }

  const onPrimaryClick = () => {
    if (step === 1) {
      generalInfoRef.current.submit();
    } else {
      ticketDetailsRef.current.submit();
    }
  };

  return (
    <>
      <NewCheckListModal
        ref={checklistRef}
        open={isChecklistModalOpen}
        selectedData={GENERAL_FORM_CONSTANT.checklists.defaultValue(
          templateData[GENERAL_FORM_CONSTANT.checklists.name] || []
        )}
        onSubmit={onChecklistSubmitHandler}
        onClose={() => {
          setIsChecklistModalOpen(false);
        }}
        editable={true}
        isNewChecklist={true}
        isTemplate={true}
      />

      {isAttachmentOpen ? (
        <TemplateAttchmentModal
          isOpen={isAttachmentOpen}
          value={templateData[GENERAL_FORM_CONSTANT.attachments.name] || []}
          onSubmit={onAttachmentSubmitHandler}
          onCancel={() => {
            setIsAttachmentOpen(false);
          }}
        />
      ) : null}
      <Modal
        open={props.isOpen}
        maxWidth={"md"}
        fullWidth={true}
        // PaperProps={{
        //   style: {
        //     height: isFullScreen === true ? "100%" : "min(600px , 80%)",
        //     borderRadius: isFullScreen === true ? "0px" : "24px",
        //     display: "flex",
        //   },
        // }}
        title={props.title}
        onClose={props.onCancel}
        expandable={true}
        additionalHeaderComponent={
          <Box
            sx={{
              px: "24px",
              pb: "16px",
            }}
          >
            <Stepper step={step} />
          </Box>
        }
        footer={
          <Stack
            direction={"row"}
            sx={{
              gap: "24px",
              alignItems: "center",
              justifyContent: "space-between",
              px: "16px",
              py: "16px",
              backgroundColor: "white",
              borderTop: "1px solid #E3E3E3",
            }}
          >
            {step === 1 ? (
              <CustomButton
                label="Checklists"
                varient="secondary"
                isTrailingIcon={true}
                icon={"unordered_list"}
                onClick={() => {
                  setIsChecklistModalOpen(true);
                }}
              />
            ) : (
              <Stack direction={"row"} gap={"16px"}>
                <CustomCheckBox
                  checked={
                    templateData[TICKET_DETAILS_CONSTANT.show_users.name] ===
                    true
                  }
                  changeHandler={(data) =>
                    setTemplateData((oldData) => ({
                      ...oldData,
                      [TICKET_DETAILS_CONSTANT.show_users.name]: data,
                    }))
                  }
                />
                <CustomTypography
                  content={TICKET_DETAILS_CONSTANT.show_users.label}
                  size={"EXTRA-SMALL"}
                  variant={"REGULAR"}
                />
              </Stack>
            )}

            <Stack
              direction={"row"}
              sx={{
                gap: "24px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setIsAttachmentOpen(true);
                }}
              >
                {templateData[GENERAL_FORM_CONSTANT.attachments.data_name] &&
                templateData[GENERAL_FORM_CONSTANT.attachments.data_name]
                  .length > 0 ? (
                  <Box
                    width={"20px"}
                    height={"16px"}
                    bgcolor={"red"}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      zIndex: 2,
                    }}
                  >
                    <Typography variant="caption-3" color="#FFF">
                      {
                        templateData[
                          GENERAL_FORM_CONSTANT.attachments.data_name
                        ].length
                      }
                    </Typography>
                  </Box>
                ) : null}
                <CustomIcon icon="attachment" size={"lg"} />
              </Box>

              <CustomButton
                label={step === 1 ? "Cancel" : "Go back"}
                varient="secondary"
                onClick={stepperMoveBackward}
              />
              <CustomButton
                label={step === 1 ? "Next" : "Save"}
                onClick={onPrimaryClick}
              />
            </Stack>
          </Stack>
        }
      >
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <Divider />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flex: "1",
              flexGrow: "1",
              flexShrink: "1",
              overflow: "hidden",
              ...(props.isLoading && {
                filter: "grayscale(1)",
                opacity: "0.6",
                pointerEvents: "none",
              }),
            }}
          >
            {step === 1 ? (
              <GeneralInfoForm
                ref={generalInfoRef}
                data={templateData}
                onsubmit={stepperMoveForward}
                onCancel={stepperMoveBackward}
              />
            ) : (
              step === 2 && (
                <TicketDetailsForm
                  ref={ticketDetailsRef}
                  data={templateData}
                  onsubmit={stepperMoveForward}
                  onCancel={stepperMoveBackward}
                />
              )
            )}
          </Box>
        </Stack>
      </Modal>
    </>
  );
}

TicketTemplateCreateModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

TicketTemplateCreateModal.defaultProps = {
  title: "",
  isOpen: false,
  data: {},
  onSubmit: () => {},
  onCancel: () => {},
};

export default TicketTemplateCreateModal;
