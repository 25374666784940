function boardsIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3147 22.3817L15.9813 27.567C15.092 28.0604 14 27.4177 14 26.4017V16.119C14 15.635 14.2627 15.1884 14.6853 14.9537L24.0187 9.76836C24.908 9.27369 26 9.91636 26 10.9337V21.2164C26 21.7004 25.7373 22.1457 25.3147 22.3817Z"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M22 6L10.6747 12.432C10.2573 12.668 10 13.1107 10 13.5907V24.6667"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M18 3.3335L6.68533 9.61883C6.26267 9.85483 6 10.3002 6 10.7842V20.6668"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}
export default boardsIcon;