import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createMfa,
  deleteMfa,
  getMfa,
  unenrollResourceMfa,
  updateMfa,
} from "controller/api";
import { debugConsole } from "utilz/helpers";

export const useGetMfa = () => {
  return useQuery({
    queryKey: ["getMfa"],
    queryFn: () => getMfa(),
    onError: (error) => debugConsole(error),
  });
};

export const useCreateMfa = () => {
  const queryClient = useQueryClient();
  return useMutation(() => createMfa(), {
    onSuccess(data) {
      debugConsole(data);
      queryClient.invalidateQueries(["getMfa"]);
    },
  });
};

export const useDeleteMfa = () => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteMfa(), {
    onSuccess(data) {
      debugConsole(data);
      queryClient.invalidateQueries(["getMfa"]);
    },
  });
};

export const useUpdateMfa = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: {
      enforceMFAType: "DontForce" | "Force";
      allowRememberMyDevice: boolean;
    }) => updateMfa(data),
    {
      onSuccess(data) {
        debugConsole(data);
        queryClient.invalidateQueries(["getMfa"]);
      },
    }
  );
};

export const useUnenrollResourceMfa = () => {
  const queryClient = useQueryClient();
  return useMutation((data: { userId: string }) => unenrollResourceMfa(data), {
    onSuccess(data) {
      debugConsole(data);
      queryClient.invalidateQueries(["getMfa"]);
    },
  });
};
