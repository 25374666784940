import { SvgIcon, SvgIconProps } from "@mui/material";

export const AlarmIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M17.657 7.343A8 8 0 1 1 6.343 18.657 8 8 0 0 1 17.657 7.343Z"
        clip-rule="evenodd"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M12 9v4M10 2h4M21 6l-2-2 1 1-2.344 2.343"
      />
    </svg>
  </SvgIcon>
);
