import { Stack, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { SelectedCheckCircleIcon } from "components/icons";

interface SelectableChipProps {
  label: string;
  startIcon?: React.ReactNode;
  containerStyle?: SxProps<Theme>;
  isSelected?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
}
export const SelectableChip = ({
  label,
  startIcon,
  containerStyle,
  isSelected,
  isDisabled,
  onClick,
}: SelectableChipProps) => {
  const theme = useTheme();
  const color = isDisabled
    ? theme.palette.lightBg.low
    : isSelected
      ? theme.palette.purple.main
      : theme.palette.lightBg.high;
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
      onClick={() => !isDisabled && onClick && onClick()}
      sx={{
        gap: "8px",
        borderRadius: "8px",
        cursor: "pointer",
        border: `1px solid ${
          isSelected ? theme.palette.purple.main : theme.palette.neutral["020"]
        }`,
        color: color,
        backgroundColor: isSelected ? "#E7E9FE" : "transparent",
        padding: "6px 8px",
        ...containerStyle,
      }}
    >
      {startIcon && (
        <Stack sx={{ width: "18px", height: "18px" }}>{startIcon}</Stack>
      )}

      <Typography variant="body-medium" color={"inherit"}>
        {label}
      </Typography>
      <Stack sx={{ width: "18px", height: "18px" }}>
        {isSelected && (
          <SelectedCheckCircleIcon
            htmlColor={theme.palette.purple.main}
            sx={{ width: "18px", height: "18px" }}
          />
        )}
      </Stack>
    </Stack>
  );
};
