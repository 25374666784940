import nable from "assets/images/integration/nable.png";
import autotask from "assets/images/integration/autotask.png";
import datto from "assets/images/integration/datto.png";
import automate from "assets/images/integration/automate.png";
import connectwise from "assets/images/integration/connectwise.png";
import level from "assets/images/integration/level.png";
import nSight from "assets/images/integration/nsight.png";
import quickbooks from "assets/images/integration/quickbooks.png";
import acronis from "assets/images/integration/acronis.png";
import ninja from "assets/images/integration/ninja.png";

export const integrationsList = [
  {
    type: "RMM",
    children: [
      {
        label: "N-able N-central",
        value: "ncentral",
        description:
          "Effortlessly integrate N-central with the CSA platform, streamlining alert management into ticket resolution",
        logo: nable,
        isActive: false,
        isAvailable: true,
      },

      {
        label: "Datto RMM",
        value: "datto",
        description:
          "The integration of Datto RMM with our CSA platform seamlessly facilitates the handling and resolution of alerts into tickets.",
        logo: datto,
        isActive: false,
        isAvailable: false,
      },
      {
        label: "Connectwise Automate",
        value: "Connectwise-automate",
        description:
          "Our CSA platform seamlessly manages and resolves alerts into tickets through ConnectWise Automate integration.",
        logo: automate,
        isActive: false,
        isAvailable: false,
      },
      {
        label: "Level",
        value: "level",
        description:
          "Level IO seamlessly integrates with our CSA platform, enhancing alert handling and resolution into tickets.",
        logo: level,
        isActive: false,
        isAvailable: false,
      },
      {
        label: "N-sight RMM",
        value: "n-sight",
        description:
          "N-sight tool seamlessly integrates with CSA platform for alert-to-ticket transition and resolution.",
        logo: nSight,
        isActive: false,
        isAvailable: false,
      },
      {
        label: "Ninja RMM",
        value: "ninja",
        description:
          "Effortlessly integrates NinjaOne RMM, streamlining alert management and ticket resolution within DeskDay.",
        logo: ninja,
        isActive: false,
        isAvailable: false,
      },
    ],
  },
  {
    type: "PSA",
    children: [
      {
        label: "Autotask",
        value: "autotask",
        description:
          "By integrating Autotask into our CSA platform, we enable bidirectional synchronization of service items and any associated updates.",
        logo: autotask,
        isActive: false,
        isAvailable: false,
      },
      {
        label: "Connectwise Manage",
        value: "connectwise-manage",
        description:
          "By linking ConnectWise Manage with our CSA platform, we ensure two-way synchronization of service items and updates.",
        logo: connectwise,
        isActive: false,
        isAvailable: false,
      },
    ],
  },
  {
    type: "Accounting",
    children: [
      {
        label: "Quickbooks",
        value: "quickbooks",
        description:
          "Streamline invoicing with DeskDay-QuickBooks integration. Import, manage items, expenses, automate invoice delivery.",
        logo: quickbooks,
        isActive: false,
        isAvailable: false,
      },
    ],
  },
  {
    type: "BDR",
    children: [
      {
        label: "Acronis",
        value: "acronis",
        description:
          "Ensure the security of your clients' data by integrating Acronis with DeskDay to manage them effectively.",
        logo: acronis,
        isActive: false,
        isAvailable: false,
      },
    ],
  },
];
