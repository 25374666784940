import { Box, Divider } from "@mui/material";
import PropTypes from "prop-types";
export default function SpaceFiller(props) {
  let count = 10;
  try {
    count = 10 - props.count;
  } catch (e) {
    count = 0;
  }
  return (
    <Box>
      {[...Array(count > 0 ? count : 0)].map((_, index) => (
        <Divider
          key={`space_filler_${index}`}
          sx={{
            marginBottom: "48px",
          }}
        />
      ))}
    </Box>
  );
}

SpaceFiller.propTypes = {
  count: PropTypes.number.isRequired,
};
SpaceFiller.defaultProps = {
  count: 10,
};
