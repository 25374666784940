import { Box, Stack } from "@mui/material";
import ExpandableContainer from "../expandable-container";
import PropTypes from "prop-types";
import PermissionTile from "../permission-tile";
import { useState } from "react";
import { BottomPaginationBar } from "./components";
import { useGlobalStore } from "controller/store/globalStore";
import { RESOURCE_TYPES } from "../../constants";
import { PERMISSION_CONSTANT, TYPE } from "shared";
function PermissionComponent(props) {
  const [index, setIndex] = useState(0);
  const userRole = props.role;
  const onPermissionChangeHandler = (data) => {
    const updatedData = { ...props.data };

    // console.log("🚀 ~ file: index.js:17 ~ onPermissionChangeHandler ~ PERMISSION_CONSTANT[data.field]:", PERMISSION_CONSTANT[index][data.field])
    if (!PERMISSION_CONSTANT[index][data.field]) {
      return;
    }
    if (PERMISSION_CONSTANT[index][data.field].key === "serviceDesk") {
      for (const permissionList of Object.values(PERMISSION_CONSTANT[index])) {
        if (permissionList.type === TYPE.COLLECTION) {
          for (const permission in permissionList.contents) {
            updatedData[permission].is_enabled = data.value;
          }
        } else if (permissionList.rule !== "") {
          updatedData[permissionList].is_enabled = data.value;
        }
      }
    } else if (
      PERMISSION_CONSTANT[index][data.field].type === TYPE.COLLECTION
    ) {
      for (const permission in PERMISSION_CONSTANT[index][data.field]
        .contents) {
        updatedData[permission].is_enabled = data.value;
      }
    }

    updatedData[data.field].is_enabled = data.value;
    props.onChange({ field: data.field, value: updatedData });
  };

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          flex: "1",
          flexGrow: "1",
          flexShrink: "1",
          width: "100%",
          overflowY: "scroll",
          // filter: "grayscale(100%)",
          // opacity: "0.5",
        }}
      >
        {Object.values(PERMISSION_CONSTANT[index]).map((item) =>
          item.type === TYPE.SINGLE ? (
            <PermissionTile
              key={item.key}
              label={item.label}
              name={item.name}
              primary={true}
              value={props?.data[item.name]?.is_enabled || false}
              onChange={onPermissionChangeHandler}
            />
          ) : item?.blockedResource &&
            item?.blockedResource.includes(userRole) ? null : (
            <ExpandableContainer
              key={item.key}
              label={item.label}
              content={
                <Box>
                  {Object.values(item.contents).map((childItem) => (
                    <PermissionTile
                      key={childItem.key}
                      borderTop={true}
                      label={childItem.label}
                      name={childItem.name}
                      value={props?.data[childItem.name]?.is_enabled || false}
                      onChange={onPermissionChangeHandler}
                    />
                  ))}
                </Box>
              }
            />
          )
        )}
      </Stack>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
          py: "16px",
          borderBottom: "1px solid #cccccc",
          boxShadow: "0px -4px 40px rgba(0, 0, 0, 0.08)",
        }}
      >
        <BottomPaginationBar
          totalIndex={
            userRole === RESOURCE_TYPES.RESOURCE
              ? PERMISSION_CONSTANT.length - 1
              : PERMISSION_CONSTANT.length
          }
          currentIndex={index + 1}
          onChange={(newIndex) => setIndex(newIndex - 1)}
        />
      </Box>
    </Stack>
  );
}

PermissionComponent.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  role: PropTypes.string,
};
PermissionComponent.defaultProps = {
  role: RESOURCE_TYPES.RESOURCE,
  data: {},
  onChange: () => {},
};

export default PermissionComponent;
