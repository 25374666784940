import React from "react";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

// HOC Imports
import { CustomTypography } from "../../../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../../../library/icon-library";

const MicrosoftMailServerComponent = (props)=> {
  return(
    <React.Fragment>
      <Box sx={{width: "100%", padding: "60px"}}>
        {/* Header Section */}
        <Box sx={{width: "100"}}>
          <Stack direction = {"row"} spacing = {"12px"}>
            <Box sx={{display: "flex",alignItems: "center",justifyContent: "center"}}>
              <CustomIcon 
                size = {"xl"} 
                icon = {"microsoft"} 
                color = {"#323232"}
              />
            </Box>
                        
            <Box sx={{display: "flex",alignItems: "center",justifyContent: "center"}}>
              <CustomTypography
                size = {"SMALL-BETA"}
                color = {"PRIMARY-MAIN"}
                variant = {"BOLD"}
                content = {"Microsoft"}
              />
            </Box>
                        
          </Stack>
        </Box>
        <Box sx={{width: "100%", mt: "24px"}}>
          <Stack spacing = {"4px"}>
            <CustomTypography
              size = {"LARGE-BETA"}
              color = {"PRIMARY-MAIN"}
              variant = {"BOLD"}
              content = {"Sign in"}
            />
            <Stack direction = {"row"} spacing = {"2px"}>
              <Box sx={{display: "flex",alignItems: "center",justifyContent: "center"}}>
                <CustomTypography
                  size = {"SMALL-ALPHA"}
                  color = {"PRIMARY-MAIN"}
                  variant = {"REGULAR"}
                  content = {"to continue to"}
                />
              </Box>
              <Box sx={{display: "flex",alignItems: "center",justifyContent: "center"}}>
                <CustomTypography
                  size = {"SMALL-BETA"}
                  color = {"PRIMARY-MAIN"}
                  variant = {"BOLD"}
                  content = {"Deskday"}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
        {/* Header Section */}

        {/* Body Section */}
        <Box sx={{width: "100%", mt:"48px"}}>
          <TextField 
            label = {""} 
            variant = {"standard"} 
            fullWidth = {true}
          />
        </Box>
        <Box sx={{width: "100%", mt: "24px"}}>
          <Stack direction = {"row"} spacing = {"12px"}>
            <CustomTypography
              size = {"SMALL-ALPHA"}
              color = {"PRIMARY-MAIN"}
              variant = {"REGULAR"}
              content = {"No account?"}
            />
            <Box sx={{"&:hover": {cursor: "pointer"}}}>
              <CustomTypography
                size = {"SMALL-ALPHA"}
                color = {"BUTTON-MAIN"}
                variant = {"BOLD"}
                content = {"Create one"}
              />
            </Box>
          </Stack>
        </Box>
        <Box sx={{width: "100%", mt: "24px"}}>
          <CustomTypography
            size = {"SMALL-ALPHA"}
            color = {"BUTTON-MAIN"}
            variant = {"BOLD"}
            content = {"Cant access your account"}
          />
        </Box>
        <Box sx={{width: "100%", mt: "24px"}}>
          <CustomTypography
            size = {"SMALL-ALPHA"}
            color = {"BUTTON-MAIN"}
            variant = {"BOLD"}
            content = {"Sign-in options"}
          />
        </Box>
        {/* Body Section */}
      </Box>
    </React.Fragment>
  );
};

export default MicrosoftMailServerComponent;