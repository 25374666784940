import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

// HOC Imports
import { CustomCard } from "../../../../../../../library/layers";
import { MapComponent } from "../../../../../../../library/mounts";
import {
  CustomTextField,
  CustomTextAreaField,
} from "../../../../../../../library/system/custom-form";

// Constants Imports
import { FORM_FIELDS } from "./constants";
import { services } from "../../../../../../../services";
import { Input, SelectField } from "components";
import { useGetAllCountries, useGetAllTimezone } from "controller";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TimeZoneList } from "shared";

const schema = yup.object({
  location_name: yup.string().required("Please enter location name"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Please enter mail"),
  country: yup.string().required("Please select country"),
  postal_code: yup.string().required("Please enter zip code"),
});

const CreateNewFormComponent = forwardRef((props, ref) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      location_name: "",
      email: "",
      postal_code: "",
      country: "",
    },
    resolver: yupResolver(schema),
  });
  const watchCountry = watch("country");

  const { data: timezones } = useGetAllTimezone();
  const { data: countries } = useGetAllCountries();

  // States
  const [state, setState] = useState({
    fields: {
      contact_person: {
        ...FORM_FIELDS.contact_person,
        value: "",
      },
      location_description: {
        ...FORM_FIELDS.location_description,
        value: "",
      },
      contact_number: {
        ...FORM_FIELDS.contact_number,
        value: "",
      },

      state: { ...FORM_FIELDS.state, options: [], value: -1 },
      city: { ...FORM_FIELDS.city, options: [], value: "" },
      street: { ...FORM_FIELDS.street, value: "" },
      timezone: {
        ...FORM_FIELDS.timezone,
        options: [],
        value: "",
      },
      latitude: { ...FORM_FIELDS.latitude, value: "" },
      longitude: { ...FORM_FIELDS.longitude, value: "" },
    },
  });

  // Imperative Handle Configs
  useImperativeHandle(
    ref,
    () => {
      const submit = handleSubmit(
        ({ country, email, location_name, postal_code }) => {
          const data = {};
          Object.values(state?.fields).forEach((element) => {
            data[`${element.mappingId}`] =
              state.fields[`${element.name}`].value;
          });
          data[FORM_FIELDS.location_name.name] = location_name;
          data[FORM_FIELDS.email.name] = email;
          data[FORM_FIELDS.country.name] = country;
          data[FORM_FIELDS.postal_code.name] = postal_code;

          props.onSubmit(data);
        }
      );
      return { submit };
    },
    [state]
  );
  // Effects

  const fetchState = async () => {
    const states = await FORM_FIELDS.state.getData([watchCountry]);

    const value = -1;
    const isDisabled = false;
    setState((oldState) => ({
      fields: {
        ...oldState.fields,
        state: {
          ...oldState.fields.state,
          value,
          isDisabled,
          options: states,
        },
        city: {
          ...oldState.fields.city,
          isDisabled,
          options: [],
        },
      },
    }));
  };

  // check if country or state list is empty
  // if true then that means there is no city to select
  //so we set -1 to state if the list is empty and
  // set country or state name to city based on available value

  const fetchCity = async () => {
    const city = await FORM_FIELDS.city.getData([
      watchCountry,
      state?.fields["state"]?.value,
    ]);

    let value = "";
    let isDisabled = false;
    if (Array.isArray(city) && city.length <= 0) {
      isDisabled = true;
      if (
        Array.isArray(state?.fields["state"]?.options) &&
        state?.fields["state"]?.options.length > 0
      ) {
        const states = (state?.fields["state"]?.options || []).find(
          (item) => item.value === state?.fields["state"]?.value
        );
        if (states !== undefined) {
          value = states.label;
        }
      } else {
        const country = (countries || []).find(
          (item) => item.id === state?.fields["country"]?.value
        );
        if (country !== undefined) {
          value = country.name;
        }
      }
    }
    setState((oldState) => ({
      fields: {
        ...oldState.fields,
        city: { ...oldState.fields.city, value, isDisabled, options: city },
      },
    }));
  };

  useEffect(() => {
    if (watchCountry !== "") {
      fetchState();
      setState({
        ...state,
        fields: {
          ...state.fields,
          state: { ...state.fields.state, value: -1 },
          city: { ...state.fields.city, value: "" },
        },
      });
    }
  }, [watchCountry]);

  useEffect(() => {
    if (state?.fields?.state.value !== "") {
      fetchCity();
    }
  }, [state?.fields?.state.value]);

  useEffect(() => {
    if (state.fields.city.value.length > 0) {
      updateCityCoordinates();
    }
  }, [state?.fields?.city.value]);

  // Event Handlers
  const handleFieldChange = (data) => {
    const { field, value } = data;
    try {
      const currentValues = state?.fields;
      currentValues[`${field}`] = {
        ...currentValues[`${field}`],
        value: value,
      };
      setState({ ...state, fields: currentValues });

      if (field === "state") {
        setState({
          ...state,
          fields: {
            ...state.fields,
            // state: {...state.fields.state, value: '', },
            city: { ...state.fields.city, value: "" },
          },
        });
      }
      // if(field === 'city') {
      //     updateCityCoordinates();
      // }
    } catch (err) {
      console.log("[ERROR] Handling change in select-field");
      console.log(err);
    }
  };
  const handleTextFieldChange = (data) => {
    const { field, value } = data;
    try {
      const currentValues = state?.fields;
      currentValues[`${field}`] = {
        ...currentValues[`${field}`],
        value: value,
      };
      setState({ ...state, fields: currentValues });
    } catch (err) {
      console.log("[ERROR] Handling change in text-field");
      console.log(err);
    }
  };

  const updateCityCoordinates = () => {
    try {
      services.generalServices
        .fetchAllCitiesByStateCode(watchCountry, state?.fields["state"]?.value)
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true &&
              response?.data &&
              response?.data?.result &&
              Array.isArray(response?.data?.result)
            ) {
              const data = response?.data?.result.find(
                (element) => element?.name === state?.fields["city"]?.value
              );

              setState({
                ...state,
                fields: {
                  ...state?.fields,
                  latitude: {
                    ...state?.fields?.latitude,
                    value: data["latitude"],
                  },
                  longitude: {
                    ...state?.fields?.longitude,
                    value: data["longitude"],
                  },
                },
              });
            }
          },
          error: (error) => {
            console.log("[ERROR] Updating City coordeinates");
            console.log(error);
          },
        });
    } catch (err) {
      console.log("[ERROR] Updating City coordeinates");
      console.log(err);
    }
  };

  // Renderer
  return (
    <React.Fragment>
      <Box>
        <Grid
          container
          columnSpacing={{ sm: "12px", md: "16px", lg: "24px", xl: "28px" }}
          rowSpacing={{ sm: "12px", md: "16px", lg: "24px", xl: "28px" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            {/* <Grid item xs={6} sm={7.5} md={7.5}> */}
            <Box sx={{ width: "100%" }}>
              <CustomCard
                borderRadius={"24px"}
                outlined={true}
                spacing={"0px"}
                content={
                  <Box
                    sx={{
                      width: "100%",
                      px: { sm: "20px", md: "24px", lg: "28px", xl: "32px" },
                      py: { sm: "20px", md: "24px", lg: "28px", xl: "32px" },
                    }}
                  >
                    <Grid container columnSpacing="16px" rowSpacing={"8px"}>
                      <Grid
                        key={FORM_FIELDS.location_name.key}
                        item
                        xs={FORM_FIELDS.location_name.width["xs"]}
                        sm={FORM_FIELDS.location_name.width["md"]}
                        md={FORM_FIELDS.location_name.width["md"]}
                      >
                        <Input
                          name={FORM_FIELDS.location_name.name || ""}
                          label={FORM_FIELDS.location_name.label || ""}
                          control={control}
                          fullWidth={true}
                          disabled={false}
                          required={FORM_FIELDS.location_name.required}
                          error={errors.location_name ? true : false}
                          errorMessage={errors?.location_name?.message}
                          placeholder={FORM_FIELDS.location_name.placeholder}
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.contact_person.key}
                        item
                        xs={FORM_FIELDS.contact_person.width["xs"]}
                        sm={FORM_FIELDS.contact_person.width["md"]}
                        md={FORM_FIELDS.contact_person.width["md"]}
                      >
                        <Input
                          name={FORM_FIELDS.contact_person.name || ""}
                          label={FORM_FIELDS.contact_person.label || ""}
                          value={
                            state.fields.contact_person.value.toString() || ""
                          }
                          fullWidth={true}
                          disabled={false}
                          required={FORM_FIELDS.contact_person.required}
                          placeholder={FORM_FIELDS.contact_person.placeholder}
                          onChange={(event) =>
                            handleTextFieldChange({
                              field: FORM_FIELDS.contact_person.name,
                              value: event.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.location_description.key}
                        item
                        xs={FORM_FIELDS.location_description.width["xs"]}
                        sm={FORM_FIELDS.location_description.width["md"]}
                        md={FORM_FIELDS.location_description.width["md"]}
                        marginBottom={"16px"}
                      >
                        <CustomTextAreaField
                          name={FORM_FIELDS.location_description.name || ""}
                          label={FORM_FIELDS.location_description.label || ""}
                          value={state.fields.location_description.value || ""}
                          disabled={false}
                          required={FORM_FIELDS.location_description.required}
                          error={false}
                          helperText={""}
                          placeholder={""}
                          size={"medium"}
                          maxRows={6}
                          minRows={1}
                          rows={5}
                          changeHandler={handleTextFieldChange}
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.email.key}
                        item
                        xs={FORM_FIELDS.email.width["xs"]}
                        sm={FORM_FIELDS.email.width["md"]}
                        md={FORM_FIELDS.email.width["md"]}
                      >
                        <Input
                          name={FORM_FIELDS.email.name || ""}
                          label={FORM_FIELDS.email.label || ""}
                          disabled={false}
                          control={control}
                          required={FORM_FIELDS.email.required}
                          error={errors.email ? true : false}
                          errorMessage={errors?.email?.message}
                          fullWidth={true}
                          placeholder={FORM_FIELDS.email.placeholder}
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.contact_number.key}
                        item
                        xs={FORM_FIELDS.contact_number.width["xs"]}
                        sm={FORM_FIELDS.contact_number.width["md"]}
                        md={FORM_FIELDS.contact_number.width["md"]}
                      >
                        <Input
                          name={FORM_FIELDS.contact_number.name || ""}
                          label={FORM_FIELDS.contact_number.label || ""}
                          value={
                            state.fields.contact_number.value.toString() || ""
                          }
                          fullWidth={true}
                          disabled={false}
                          required={FORM_FIELDS.contact_number.required}
                          placeholder={FORM_FIELDS.contact_number.placeholder}
                          onChange={(event) =>
                            handleTextFieldChange({
                              field: FORM_FIELDS.contact_number.name,
                              value: event.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.country.key}
                        item
                        xs={FORM_FIELDS.country.width["xs"]}
                        sm={FORM_FIELDS.country.width["md"]}
                        md={FORM_FIELDS.country.width["md"]}
                      >
                        <SelectField
                          name={FORM_FIELDS.country.name || ""}
                          label={`${FORM_FIELDS.country.label || ""}`}
                          options={countries || []}
                          required={FORM_FIELDS.country.required}
                          labelMappingKey="name"
                          valueMappingKey="id"
                          control={control}
                          error={errors.country ? true : false}
                          errorMessage={errors?.country?.message}
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.state.key}
                        item
                        xs={FORM_FIELDS.state.width["xs"]}
                        sm={FORM_FIELDS.state.width["md"]}
                        md={FORM_FIELDS.state.width["md"]}
                      >
                        <SelectField
                          name={FORM_FIELDS.state.name || ""}
                          label={`${FORM_FIELDS.state.label || ""}`}
                          value={
                            state.fields.state.value === -1
                              ? ""
                              : state.fields.state.value || ""
                          }
                          required={FORM_FIELDS.state.required}
                          placeholder={FORM_FIELDS.state.placeholder}
                          disabled={state?.fields?.state?.isDisabled}
                          labelMappingKey="label"
                          valueMappingKey="value"
                          options={state.fields.state.options || []}
                          // changeHandler={handleFieldChange}
                          onValueChange={(newValue) =>
                            handleFieldChange({
                              field: FORM_FIELDS.state.name,
                              value: newValue,
                            })
                          }
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.city.key}
                        item
                        xs={FORM_FIELDS.city.width["xs"]}
                        sm={FORM_FIELDS.city.width["md"]}
                        md={FORM_FIELDS.city.width["md"]}
                      >
                        <SelectField
                          name={FORM_FIELDS.city.name || ""}
                          label={`${FORM_FIELDS.city.label || ""}`}
                          value={state.fields.city.value || ""}
                          required={FORM_FIELDS.city.required}
                          placeholder={FORM_FIELDS.city.placeholder}
                          disabled={state?.fields?.city?.isDisabled}
                          labelMappingKey="label"
                          valueMappingKey="value"
                          options={state.fields.city.options || []}
                          // changeHandler={handleFieldChange}
                          onValueChange={(newValue) =>
                            handleFieldChange({
                              field: FORM_FIELDS.city.name,
                              value: newValue,
                            })
                          }
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.street.key}
                        item
                        xs={FORM_FIELDS.street.width["xs"]}
                        sm={FORM_FIELDS.street.width["md"]}
                        md={FORM_FIELDS.street.width["md"]}
                      >
                        <Input
                          name={FORM_FIELDS.street.name || ""}
                          label={FORM_FIELDS.street.label || ""}
                          value={state.fields.street.value.toString() || ""}
                          fullWidth={true}
                          disabled={false}
                          required={FORM_FIELDS.street.required}
                          placeholder={FORM_FIELDS.street.placeholder}
                          onChange={(event) =>
                            handleTextFieldChange({
                              field: FORM_FIELDS.street.name,
                              value: event.target.value,
                            })
                          }
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.postal_code.key}
                        item
                        xs={FORM_FIELDS.postal_code.width["xs"]}
                        sm={FORM_FIELDS.postal_code.width["md"]}
                        md={FORM_FIELDS.postal_code.width["md"]}
                      >
                        <Input
                          name={FORM_FIELDS.postal_code.name || ""}
                          label={FORM_FIELDS.postal_code.label || ""}
                          fullWidth={true}
                          disabled={false}
                          required={FORM_FIELDS.postal_code.required}
                          placeholder={FORM_FIELDS.postal_code.placeholder}
                          control={control}
                          error={errors.postal_code ? true : false}
                          errorMessage={errors?.postal_code?.message}
                        />
                      </Grid>

                      <Grid
                        key={FORM_FIELDS.timezone.key}
                        item
                        xs={FORM_FIELDS.timezone.width["xs"]}
                        sm={FORM_FIELDS.timezone.width["md"]}
                        md={FORM_FIELDS.timezone.width["md"]}
                      >
                        <SelectField
                          name={FORM_FIELDS.timezone.name || ""}
                          label={`${FORM_FIELDS.timezone.label || ""}`}
                          value={state.fields.timezone.value || ""}
                          required={FORM_FIELDS.timezone.required}
                          placeholder={FORM_FIELDS.timezone.placeholder}
                          disabled={false}
                          labelMappingKey="label"
                          valueMappingKey="value"
                          isGrouped={true}
                          groupedOptions={TimeZoneList}
                          // changeHandler={handleFieldChange}
                          onValueChange={(newValue) =>
                            handleFieldChange({
                              field: FORM_FIELDS.timezone.name,
                              value: newValue,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
            </Box>
          </Grid>
          {/* <Grid item xs={6} sm={4.5} md={4.5} >
            <Box sx={{ width: "100%" }}>
              <CustomCard
                borderRadius={"24px"}
                outlined={true}
                spacing={"0px"}
                content={
                  <Box
                    sx={{
                      width: "100%",
                      px: { sm: "20px", md: "24px", lg: "28px", xl: "32px" },
                      py: { sm: "20px", md: "24px", lg: "28px", xl: "32px" },
                    }}
                  >
                    <Stack
                      spacing={"16px"}
                      sx={{
                        width: "100%",
                        height: "488px",
                        borderRadius: "24px",
                      }}
                    >
                      <CustomTextField
                        name={"city"}
                        label={"City"}
                        value={state?.fields?.city["value"] || ""}
                        disabled={true}
                        required={false}
                        error={false}
                        helperText={""}
                        placeholder={"City"}
                        changeHandler={() => {}}
                      />
                      <MapComponent
                        latitude={Number(state?.fields?.latitude["value"])}
                        longitude={Number(state?.fields?.longitude["value"])}
                        label={state?.fields?.city["value"] || ""}
                      />
                    </Stack>
                  </Box>
                }
              />
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </React.Fragment>
  );
});

export { CreateNewFormComponent };
