import {
  Autocomplete,
  AutocompleteProps,
  Box,
  List,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { SearchIcon } from "components/icons";
import { Button } from "components/button";

interface CustomeListBoxCompoenenProps
  extends React.HTMLAttributes<HTMLUListElement> {
  clear: () => void;
  submit: () => void;
  cancelLabel?: string;
  submitLabel?: string;
}

const CustomListboxComponent = forwardRef((props: any, ref) => {
  const { children, clear, submit, cancelLabel, submitLabel, ...other } = props;
  return (
    <Box
      ref={ref}
      {...other}
      sx={{
        // maxHeight: "220px",
        // height: "fit-content",
        display: "inline-flex",
        width: "100%",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <List
        sx={{
          width: "100%",
          // maxHeight: "220px",
          overflow: "scroll",
          flexGrow: "1",
          flexShrink: "1",
          px: "24px",
          mt: "24px",
        }}
        subheader={<li />}
      >
        {children}
      </List>

      <Stack
        direction={"row-reverse"}
        width={"100%"}
        spacing={2}
        sx={{
          px: "24px",
          pb: "24px",
        }}
      >
        <Button variant="primary" onClick={submit}>
          {submitLabel}
        </Button>
        <Button variant="secondary" onClick={clear}>
          {cancelLabel}
        </Button>
      </Stack>
    </Box>
  );
});

const customPaperComponent = (props: any) => {
  const { children, ...other } = props;
  return (
    <Paper
      {...other}
      sx={{
        borderRadius: "0px 0px 16px 16px",
      }}
    >
      {children}
    </Paper>
  );
};

interface MultiSelectSearchTextFieldProps
  extends Omit<AutocompleteProps<any, false, false, false>, "renderInput"> {
  label?: string;
  onAddSelection: (selection: any) => void;
  onClear: () => void;
  selectedOptions: any[];
  cancelLabel?: string;
  submitLabel?: string;
  name?: string;
  optionLabelKey?: string;
  optionValueKey?: string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  customPlaceholder?: string;
}

export const MultiSelectSearchTextField = ({
  optionLabelKey,
  optionValueKey,
  isOptionEqualToValue,
  customPlaceholder,
  ...props
}: MultiSelectSearchTextFieldProps) => {
  const theme = useTheme();
  const autoCompleteRef = useRef();
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  let inputRef: any;

  const customStyles = {
    "& .MuiAutocomplete-inputRoot": {
      WebkitFlexWrap: "unset",
      flexWrap: "unset",
    },
    "& .MuiInputBase-input": {
      font: "inherit",
      width: "100%",
    },
    "& .MuiInputBase-root": {
      height: { xs: 32 },
      // height: 'fit-content',
    },
    "& .MuiOutlinedInput-root": {
      color: theme.palette.lightBg.main,
      "& > fieldset": {
        borderColor: theme.palette.neutral["020"],
        borderRadius: "4px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      color: theme.palette.lightBg.main,
      "& > fieldset": {
        borderColor: theme.palette.purple.main,
      },
    },
    "& .MuiInputLabel-root": {
      ...theme.typography["body-large"],
      transform: {
        xs: "translate(14px,5px) scale(1)",
      },
      "&.MuiFormLabel-filled": {
        transform: "translate(14px, -9px) scale(.75)",
      },
      "&.Mui-focused": {
        color: theme.palette.purple.main,
        // fontWeight: 700,
        transform: "translate(14px, -9px) scale(.75)",
      },
    },
  };

  useEffect(() => {
    setSelectedOptions(Array.from(props.selectedOptions));
  }, [props.selectedOptions]);

  const handleOptionChange = (event: any, value: any) => {
    setSelectedOptions(value);
  };

  const clearAllSelectedOptions = () => {
    setSelectedOptions([]);
  };

  const getAllSelectedOptions = () => {
    props.onAddSelection({ field: props.name, value: selectedOptions });
    if (inputRef) {
      inputRef.blur();
    }
  };

  const isOptionEqualToValueHandler = (option: any, value: any) => {
    return option.value === value.value;
  };

  return (
    <>
      <Autocomplete
        ref={autoCompleteRef}
        multiple
        disableCloseOnSelect
        value={selectedOptions}
        options={props.options}
        onBlur={() => setSelectedOptions([])}
        isOptionEqualToValue={
          isOptionEqualToValue
            ? isOptionEqualToValue
            : optionValueKey
            ? (option, value) =>
                option[optionValueKey] === value[optionValueKey]
            : isOptionEqualToValueHandler
        }
        getOptionLabel={(option) => option.label || ""}
        onChange={handleOptionChange}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={customStyles}
            placeholder={customPlaceholder || "Search resources"}
            inputRef={(input) => {
              inputRef = input;
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Fragment>
                  <SearchIcon />
                </Fragment>
              ),
              endAdornment: (
                <Fragment>
                  <Box></Box>
                </Fragment>
              ),
              sx: { ...theme.typography["body-medium"] },
            }}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        renderOption={
          props.renderOption
            ? props.renderOption
            : (props, option, { selected }) => (
                <li {...props}>
                  <Typography variant="body-small">{option.label}</Typography>
                </li>
              )
        }
        PaperComponent={customPaperComponent}
        ListboxComponent={CustomListboxComponent}
        ListboxProps={
          {
            clear: clearAllSelectedOptions,
            submit: getAllSelectedOptions,
            cancelLabel: props.cancelLabel,
            submitLabel: props.submitLabel,
          } as CustomeListBoxCompoenenProps
        }
      />
    </>
  );
};
