import React, { Component, useState } from "react";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

// HOC Imports
import {
  CustomCard,
  BottomStickyLayer,
} from "../../../../../../library/layers";
import { CustomTypography, CustomButton } from "../../../../../../library/base";
import {
  CustomTextField,
  CustomCheckBox,
} from "../../../../../../library/system/custom-form";

// Children Component Imports
import { MailServerComponent } from "./components";

// Services Imports

// Constants Imports
import { getMspId } from "utilz/helpers";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
import { Divider, Typography, useTheme } from "@mui/material";
import { toast } from "react-hot-toast";
import { CopyIcon, ExternalLinkIcon } from "components";
import { useGlobalStore } from "controller";

const EmailTab = () => {
  const mspId = useGlobalStore((state) => state.mspId);
  const theme = useTheme();

  const [state, setState] = useState({
    useDefaultEmail: true,
    display_name: "",
    support_desk_email: `Supportdesk@${mspId}.deskday.ai`,
    forward_email_address: `Supportdesk@${mspId}.deskday.ai`,
  });

  // Lifecycle Methods

  // API Calls

  // Event Handlers
  const checkboxEventHandler = (value) => {
    try {
      setState({
        ...state,
        useDefaultEmail: value,
      });
    } catch (err) {
      console.log("[ERROR] handling Checkbox event");
      console.log(err);
    }
  };
  const handleFieldChange = (data) => {
    try {
      const { field, value } = data;
      const currentState = state;
      currentState[`${field}`] = value;
      setState(currentState);
    } catch (err) {
      console.log("[ERROR] Updating text field change");
      console.log(err);
    }
  };
  const handleSubmit = () => {
    console.log("TODO Submit Handler");
  };

  // Renderer

  return (
    <Stack
      height={"100%"}
      gap={"24px"}
      sx={{ p: "24px 32px 96px 32px", overflowY: "scroll" }}
    >
      <Typography variant="h4">Setup Email based Ticketing</Typography>
      <Grid
        container
        sx={{
          height: "fit-content",
        }}
      >
        <Grid
          sx={{
            height: "max(fit-content,100%)",
            border: `1px solid  ${theme.palette.neutral["015"]}`,
            borderRadius: "24px",
            px: { sm: "24px", md: "32px", lg: "40px", xl: "48px" },
            py: "32px",
          }}
          item
          xs={12}
          sm={4.5}
          md={4.5}
        >
          <Stack gap={"16px"}>
            <Typography variant="h6">Support Desk Email Address</Typography>
            <Stack
              sx={{
                border: `1px solid  ${theme.palette.neutral["015"]}`,
                borderRadius: "8px",
              }}
              width={"100%"}
            >
              <Typography
                variant="body-medium"
                sx={{
                  px: "14px",
                  py: "21px",
                }}
              >
                {state["forward_email_address"]}
              </Typography>
              <Divider />
              <Box
                sx={{
                  width: "100%",
                  p: "14px",
                  textAlign: "right",
                }}
              >
                <Typography
                  className="intercom-supportdeskemail-copy"
                  variant="body-small"
                  sx={{
                    display: "inline",
                    cursor: "pointer",

                    // color: `${theme.palette.darkBg.low}`,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      state["forward_email_address"]
                    );
                    toast.success("Email copied to clipboard");
                  }}
                >
                  <CopyIcon
                    sx={{ mb: "-4px", px: "1px", mx: "4px" }}
                    fontSize="small"
                  />
                  copy
                </Typography>
              </Box>
            </Stack>

            <Typography
              variant="body-medium"
              sx={{
                px: "14px",
                py: "21px",
              }}
            >
              If you shoot an email to the address above, it'll get magically
              turned into a ticket. Check out our documentation
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              sx={{
                alignItems: "center",
                justifyContent: "end",
                cursor: "pointer",
                color: theme.palette.purple.main,
                "& .imageCardTitle": {
                  color: theme.palette.purple.main,
                },
                "&:hover > .imageBox img": {
                  marginTop: "24px !important",
                  marginLeft: "32px !important",
                  transform: "all 3s ease-in-out",
                },
              }}
              onClick={() => {
                window.open(
                  "https://support.deskday.com/en/articles/8235976-setting-up-support-mail",
                  "_blank"
                );
              }}
            >
              <Typography className="imageCardTitle" variant="link-small">
                Setup Support Mail
              </Typography>
              <ExternalLinkIcon sx={{ width: "20px", height: "20px" }} />
            </Stack>
          </Stack>
        </Grid>
        <Grid
          sx={{
            height: "max(fit-content,100%)",
            pl: { sm: "24px", md: "32px", lg: "40px", xl: "48px" },
          }}
          item
          xs={12}
          sm={7.5}
          md={7.5}
        >
          <Box
            sx={{
              height: "100%",
              border: `1px solid  ${theme.palette.neutral["015"]}`,
              borderRadius: "24px",
              minHeight: "max(50vh , fit-content)",
              py: "32px",
              px: { sm: "24px", md: "32px", lg: "40px", xl: "48px" },
            }}
          >
            <MailServerComponent />
          </Box>
        </Grid>
      </Grid>

      {/* <BottomStickyLayer
        content={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CustomButton
              varient="primary"
              size="sm"
              label="Save"
              onClick={handleSubmit}
            />
          </Box>
        }
      /> */}
    </Stack>
  );
};

export default EmailTab;
