import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CustomIcon from "../../../../../../../../../library/icon-library";
import { PRIORITY_CONSTANT } from "./constants";
import PropTypes from "prop-types";
import { CustomTypography } from "../../../../../../../../../library/base";
function PriorityItem(props) {
  const [state, setState] = useState({});

  useEffect(() => {
    setState(PRIORITY_CONSTANT[props.priority] || PRIORITY_CONSTANT.low);
  }, [props]);

  return (
    <Stack
      width={"fit-content"}
      direction={"row"}
      gap={"8px"}
      sx={{
        position: "relative",
        borderRadius: "8px",
        backgroundColor: state.backgroundColor,
        color: state.color,
        px: "12px",
        minWidth: "70px",
        alignItems: "center",
        justifyContent: "center",
        height: {
          xs: "32px",
          sm: "32px",
          md: "40px",
          lg: "40px",
        },
      }}
    >
      {/* <Box
            sx={{
                height: '70%',
                aspectRatio: '1',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <CustomIcon
                icon="flag_filled"
                color={state.color}
                sx={{
                    height: '70%',
                    aspectRatio: '1',
                }}
            />
        </Box> */}
      <CustomTypography
        content={state.label}
        size={"EXTRA-SMALL"}
        variant={"BOLD"}
      />
    </Stack>
  );
}

PriorityItem.propTypes = {
  priority: PropTypes.string.isRequired,
};

PriorityItem.defaultProps = {
  priority: "low",
};

export default PriorityItem;
