import { Box, Stack } from "@mui/material";
import {
  AttributeContainer,
  ServiceTypeContainer,
  VariableAttributeContainer,
} from "../Components";
import { useGetVariableAttributes } from "controller";

const TICKET_ATTRIBUTES: Record<string, any> = {
  DEFAULT: [
    {
      key: "ticket_status",
      isMulti: false,
      label: "Status",
      serviceType: "ticket",
      attributeName: "status",
      fieldName: "status_name",
    },
    {
      key: "ticket_type",
      isMulti: true,
      label: "Ticket Type",
      serviceType: "ticket",
      attributeName: "type",
      fieldName: "type_name",
    },
  ],
};

export const TicketAttributesTab = () => {
  const { data: allVariableAttributes } = useGetVariableAttributes({
    attributeName: "ticket",
  });

  return (
    <Box
      sx={{
        display: "block",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          position: "relative",
          overflowX: "auto",
          "&::-webkit-scrollbar:horizontal": {
            display: "block",
          },
          p: "24px",
          gap: "24px",
        }}
      >
        {TICKET_ATTRIBUTES.DEFAULT.map((item: any) =>
          item.isMulti ? (
            <ServiceTypeContainer
              key={`configuration-tickets-attributes-default${item.key}`}
              fieldName={item.fieldName}
              serviceType={item.serviceType}
              label={item.label}
            />
          ) : (
            <AttributeContainer
              key={`configuration-tickets-attributes-default${item.key}`}
              attributeName={item.attributeName}
              fieldName={item.fieldName}
              serviceType={item.serviceType}
              label={item.label}
            />
          )
        )}
        <Stack
          direction={"row"}
          sx={{
            gap: "24px",
          }}
        >
          {(Array.isArray(allVariableAttributes?.result)
            ? allVariableAttributes.result
            : []
          ).map((item: any) => (
            <VariableAttributeContainer
              key={item.attribute_id}
              value={item}
              attributeName="ticket"
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
