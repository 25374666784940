import { styled, useTheme } from "@mui/material/styles";
import MuiTableHead, { TableHeadProps } from "@mui/material/TableHead";
import React, { useEffect, useState } from "react";
import { TableRowRoot } from "./TableRowRoot";
import TableCell from "@mui/material/TableCell";
import CustomCheckBox from "../../library/base/custom-checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomIcon from "../../library/icon-library";
import Box from "@mui/material/Box";
import { VerticalArrowsIcon } from "components/icons";
import { IconButton, SxProps, Theme } from "@mui/material";
import { StyledTableCell } from "./StyledTableCell";

type HeaderProps = {
  name: string;
  sortable?: boolean;
  key: string;
  numeric?: boolean;
};

interface CustomTableHeadProps extends TableHeadProps {
  headers: Array<HeaderProps>;
  groupedHeaders?: Array<HeaderProps>;
  headerCellStyles?: SxProps<Theme>;
}
export const GroupedTableHeader = ({
  headers,
  groupedHeaders,
  headerCellStyles,
  ...rest
}: CustomTableHeadProps) => {
  // useEffect(() => {}, [selectedItems]);

  return (
    <TableHeadStyled {...rest}>
      <TableRowRoot header>
        {groupedHeaders?.map((headCell, index) => (
          <StyledTableCell
            rowSpan={index === 0 ? 2 : 1}
            colSpan={index === 0 ? 1 : headers?.length}
            sx={{
              padding: "6px 16px",
              whiteSpace: "nowrap",
              fontSize: "14px",
              "& .sort-button": {
                visibility: "hidden",
              },
              "&:hover": {
                "& .sort-button": {
                  visibility: "visible",
                },
              },
              borderRight: index === 0 ? "1px solid #FFF" : "none",
              ...headerCellStyles,
            }}
            key={headCell.name}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: index === 0 ? "flex-start" : "center",
                alignItems: index === 0 ? "flex-start" : "center",
                gap: "8px",
                // color: theme.palette.lightBg.high,
              }}
            >
              {headCell.name}
            </Box>
          </StyledTableCell>
        ))}
      </TableRowRoot>
      <TableRowRoot header>
        {headers.map((headCell, index) => {
          if (index === 0) {
            return;
          }
          return (
            <StyledTableCell
              colSpan={index === 0 ? 0 : 1}
              sx={{
                padding: "6px 16px",
                whiteSpace: "nowrap",
                fontSize: "14px",
                "& .sort-button": {
                  visibility: "hidden",
                },
                "&:hover": {
                  "& .sort-button": {
                    visibility: "visible",
                  },
                },
                borderRight:
                  index !== headers.length - 1 ? "1px solid #FFF" : "none",
                ...headerCellStyles,
              }}
              key={headCell.name}
              align={headCell.numeric ? "right" : "left"}
              // padding={headCell.disablePadding ? "none" : "normal"}
              // sortDirection={orderBy === headCell.id ? order : false}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "8px",
                  // color: theme.palette.lightBg.high,
                }}
              >
                {headCell.name}
                {/* TODO: add sorting */}
              </Box>
            </StyledTableCell>
          );
        })}
      </TableRowRoot>
    </TableHeadStyled>
  );
};
const TableHeadStyled = styled(MuiTableHead)<TableHeadProps>(({ theme }) => {
  return {
    ...theme.typography.caption,
    textTransform: "capitalize",
  };
});
