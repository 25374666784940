import colorPalette from "../../../common/colors";

const defaultButtonColor = {
  primary: {
    default: {
      backgroundColor: colorPalette.color.primary.primary,
      color: colorPalette.neutrals.lightBg.primary,
    },
    hover: {
      backgroundColor: colorPalette.color.primary.primaryHover,
      color: colorPalette.neutrals.lightBg.primary,
    },
    pressed: {
      backgroundColor: colorPalette.color.primary.primaryPressed,
      color: colorPalette.neutrals.lightBg.primary,
    },
    focused: {
      backgroundColor: colorPalette.color.primary.primary,
      color: colorPalette.neutrals.lightBg.primary,
      outlineColor: colorPalette.neutrals.lightBg.primary,
    },
    disabled: {
      backgroundColor: colorPalette.font.darkBg.secondary,
      color: colorPalette.font.darkBg.tertiary,
    },
  },
  secondary: {
    default: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.color.primary.stock,
      borderColor: colorPalette.color.primary.stock,
    },
    hover: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.color.primary.primary,
      borderColor: colorPalette.color.primary.primary,
    },
    pressed: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.color.primary.primary,
      borderColor: colorPalette.color.primary.primary,
    },
    focused: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.color.primary.primary,
      borderColor: colorPalette.color.primary.primary,
      outlineColor: colorPalette.color.primary.primary,
    },
    disabled: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.font.darkBg.tertiary,
      borderColor: colorPalette.neutrals.lightBg.quartary,
    },
  },

  // secondary_1: {
  //     default: {
  //         backgroundColor: colorPalette.neutrals.lightBg.tertiary,
  //         color: colorPalette.font.lightBg.primary
  //     },
  //     hover: {
  //         backgroundColor: colorPalette.neutrals.lightBg.quartary,
  //         color: colorPalette.font.lightBg.primary
  //     },
  //     pressed: {
  //         backgroundColor: colorPalette.neutrals.lightBg.quinary,
  //         color: colorPalette.font.lightBg.primary
  //     },
  //     focused: {
  //         backgroundColor: colorPalette.neutrals.lightBg.tertiary,
  //         color: colorPalette.font.lightBg.primary,
  //         outlineColor: colorPalette.neutrals.lightBg.quinary
  //     },
  //     disabled: {
  //         backgroundColor: colorPalette.font.darkBg.secondary,
  //         color: colorPalette.font.darkBg.tertiary
  //     }
  // },
  // secondary_2: {
  //     default: {
  //         backgroundColor: colorPalette.color.secondary.primaryTintTertiary,
  //         color: colorPalette.color.primary.primary
  //     },
  //     hover: {
  //         backgroundColor: colorPalette.color.primary.primaryHover,
  //         color: colorPalette.neutrals.lightBg.primary
  //     },
  //     pressed: {
  //         backgroundColor: colorPalette.color.primary.primaryPressed,
  //         color: colorPalette.neutrals.lightBg.primary
  //     },
  //     focused: {
  //         backgroundColor: colorPalette.color.secondary.primaryTintTertiary,
  //         color: colorPalette.color.primary.primary,
  //         outlineColor: colorPalette.color.primary.primary
  //     },
  //     disabled: {
  //         backgroundColor: colorPalette.font.darkBg.secondary,
  //         color: colorPalette.font.darkBg.tertiary
  //     }
  // },
  // tertiary: {
  //     default: {
  //         backgroundColor: colorPalette.neutrals.lightBg.primary,
  //         color: colorPalette.color.primary.primary,
  //         borderColor: colorPalette.color.primary.primary
  //     },
  //     hover: {
  //         backgroundColor: colorPalette.color.primary.primaryHover,
  //         color: colorPalette.neutrals.lightBg.primary
  //     },
  //     pressed: {
  //         backgroundColor: colorPalette.color.primary.primaryPressed,
  //         color: colorPalette.neutrals.lightBg.primary
  //     },
  //     focused: {
  //         backgroundColor: colorPalette.color.secondary.primaryTintTertiary,
  //         color: colorPalette.color.primary.primary,
  //         outlineColor: colorPalette.color.primary.primary
  //     },
  //     disabled: {
  //         backgroundColor: colorPalette.neutrals.lightBg.primary,
  //         color: colorPalette.font.darkBg.tertiary,
  //         borderColor: colorPalette.neutrals.lightBg.quartary
  //     }
  // },
  ghost: {
    default: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.color.primary.primary,
    },
    hover: {
      backgroundColor: colorPalette.neutrals.lightBg.tertiary,
      color: colorPalette.color.primary.primary,
    },
    pressed: {
      backgroundColor: colorPalette.neutrals.lightBg.quinary,
      color: colorPalette.color.primary.primary,
    },
    focused: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.color.primary.primary,
      outlineColor: colorPalette.color.primary.primary,
    },
    disabled: {
      backgroundColor: colorPalette.neutrals.lightBg.primary,
      color: colorPalette.font.darkBg.tertiary,
    },
  },
};

export default defaultButtonColor;
