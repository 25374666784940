import {
  CreateVariableAttributeValueInput,
  DeleteVariableAttributeValueInput,
  GetVariableAttributeInput,
  GetVariableAttributesInputProps,
  UpdateVariableAttributeInput,
  UpdateVariableAttributeValueInput,
} from "shared";
import { axiosClient } from "utilz";

import { apiErrorHandler, getMspId } from "utilz/helpers";
export const getVariableAttributes = async ({
  attributeName,
}: GetVariableAttributesInputProps) => {
  try {
    const url = `/configuration/get/variable/${attributeName}/${getMspId()}`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getVariableAttribute = async ({
  attributeId,
  attributeName,
}: GetVariableAttributeInput) => {
  try {
    const url = `/configuration/get/by_id/variable/${attributeName}/${attributeId}/${getMspId()}`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateVariableAttribute = async ({
  attributeId,
  attributeName,
  data,
}: UpdateVariableAttributeInput) => {
  try {
    const url = `v2/configuration/variable/${attributeName}/${attributeId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createVariableAttributeValue = async ({
  attributeId,
  attributeName,
  data,
}: CreateVariableAttributeValueInput) => {
  try {
    const url = `v2/configuration/attributes/variable/value/${attributeName}/${attributeId}`;
    const response = await axiosClient.post(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateVariableAttributeValue = async ({
  attributeName,
  data,
  attributeValueId,
}: UpdateVariableAttributeValueInput) => {
  try {
    const url = `v2/configuration/variable_value/${attributeName}/${attributeValueId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteVariableAttributeValue = async ({
  attributeName,
  attributeValueId,
}: DeleteVariableAttributeValueInput) => {
  try {
    const url = `/configuration/delete/variable_value/${attributeName}/${attributeValueId}/${getMspId()}`;
    const response = await axiosClient.delete(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
