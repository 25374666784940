import { SxProps, Theme, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import CustomIcon from "../../library/icon-library";

export interface BadgeProps extends BoxProps {
  leadingContent?: React.ReactNode;
  leadingIcon?: React.ReactNode;
  backgroundMode?: "dark" | "light";
  backgroundColor?: string;
  children?: React.ReactNode;
  containerStyles?: SxProps<Theme>;
  trailingIcon?: React.ReactNode;
}
export const BadgeBase = ({
  leadingContent,
  leadingIcon,
  backgroundMode = "dark",
  backgroundColor,
  children,
  containerStyles,
  trailingIcon,
  ...rest
}: BadgeProps) => {
  const theme = useTheme();
  return (
    <Box
      {...rest}
      sx={{
        flexDirection: "row",
        display: "flex",
        gap: "4px",
        backgroundColor: backgroundColor
          ? backgroundColor
          : theme.palette.neutral["005"],
        padding: "8px 12px 8px 8px",
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        ...containerStyles,
      }}
    >
      {leadingContent && <Box component="span">{leadingContent}</Box>}
      {leadingIcon && leadingIcon}
      <Typography
        sx={{ textTransform: "capitalize" }}
        fontFamily="SegoeBold"
        variant="caption-3"
        color={
          backgroundMode === "dark"
            ? `${theme.palette.neutral["005"]}`
            : `${theme.palette.lightBg.high}`
        }
      >
        {children}
      </Typography>
      {trailingIcon && trailingIcon}
    </Box>
  );
};
