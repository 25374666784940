import { commonApiCalls } from "../../../../../../../services/commonApiCalls";
import { v4 } from "uuid";

export const formFieldTypes = {
  RADIO_GROUP: "radio_group",
  SELECT: "select",
};

export const formField = {
  is_time_format_is_12_hr: {
    key: "is_time_format_is_12_hr",
    value: true,
    map: "is_time_format_is_12_hr",
    label: "Time format for tickets",
    show: true,
    TYPE: formFieldTypes.RADIO_GROUP,
    divide: true,
    radios: [
      {
        key: "TIME_FORMAT_FOR_TICKET_24_HOUR_RADIO_BUTTON",
        label: "24 hours",
        value: "24",
      },
      {
        key: "TIME_FORMAT_FOR_TICKET_12_HOUR_RADIO_BUTTON",
        label: "12 hours",
        value: "12",
      },
    ],
  },
  is_time_zone_is_resources: {
    key: "is_time_zone_is_resources",
    value: true,
    show: true,
    map: "is_time_zone_is_resources",
    label: "Time zone for tickets",
    divide: true,
    TYPE: formFieldTypes.RADIO_GROUP,
    radios: [
      {
        key: "TIME_ZONE_FOR_TICKETS_RESOURCE_TIME_ZONE",
        label: "Resource time zone",
        value: "Resource",
      },
      {
        key: "TIME_ZONE_FOR_TICKETS_CUSTOMER_TIME_ZONE",
        label: "Customer time zone",
        value: "Customer",
      },
    ],
  },
  default_font_for_tickets: {
    key: "default_font_for_tickets",
    value: "Product Sans",
    show: false,
    map: "default_font_for_tickets",
    label: "Default font for tickets",
    TYPE: formFieldTypes.SELECT,
    divide: true,
    placeholder: "Product Sans",
    dependency: false,
    trigger: [],
    getData: () => {
      return [
        { key: v4(), label: "Product Sans", value: "Product Sans" },
        { key: v4(), label: "Poppins", value: "Poppins" },
        { key: v4(), label: "Roboto", value: "Roboto" },
        { key: v4(), label: "Lato", value: "Lato" },
        { key: v4(), label: "Segeo UI", value: "Segeo UI" },
        { key: v4(), label: "Montserat", value: "Montserat" },
        { key: v4(), label: "Gllroy", value: "Gllroy" },
      ];
    },
  },
  default_font_size_for_tickets: {
    key: "default_font_size_for_tickets",
    value: "14",
    show: false,
    map: "default_font_size_for_tickets",
    label: "Default font size for tickets",
    TYPE: formFieldTypes.SELECT,
    placeholder: "14",
    divide: true,
    dependency: false,
    trigger: [],
    getData: () => {
      return [
        { key: v4(), label: "14", value: "14" },
        { key: v4(), label: "16", value: "16" },
        { key: v4(), label: "18", value: "18" },
        { key: v4(), label: "20", value: "20" },
        { key: v4(), label: "22", value: "22" },
        { key: v4(), label: "24", value: "24" },
        { key: v4(), label: "26", value: "26" },
        { key: v4(), label: "28", value: "28" },
        { key: v4(), label: "30", value: "30" },
        { key: v4(), label: "32", value: "32" },
      ];
    },
  },
  default_service_board_for_tickets: {
    key: "default_service_board_for_tickets",
    value: "Default board",
    show: true,
    map: "default_service_board_for_tickets",
    label: "Default board for tickets", //Default service board for tickets
    TYPE: formFieldTypes.SELECT,
    divide: true,
    placeholder: "Default board",
    dependency: true,
    trigger: [],
    getData: commonApiCalls.getAllTicketBoards,
  },
  default_service_board_for_request: {
    key: "default_service_board_for_request",
    value: "Default board",
    show: false,
    map: "default_service_board_for_request",
    label: "Default service board for request",
    TYPE: formFieldTypes.SELECT,
    divide: true,
    placeholder: "Default board",
    dependency: true,
    trigger: [],
    getData: commonApiCalls.getAllRequestBoard,
  },
  default_service_board_for_projects: {
    key: "default_service_board_for_projects",
    value: "Default board",
    show: false,
    map: "default_service_board_for_projects",
    label: "Default service board for project",
    TYPE: formFieldTypes.SELECT,
    divide: false,
    placeholder: "Default board",
    dependency: true,
    trigger: [],
    getData: commonApiCalls.getAllProjectBoard,
  },
};
