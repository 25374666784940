import { Box, Stack, Typography } from "@mui/material";
import { Button, Modal, Tab, Tabs } from "components";
import React, { useEffect } from "react";
import { InvoiceTab } from "./InvoiceTab";
import { OverviewTab } from "./OverviewTab";
import { PayNowCard } from "../create-subscription/PayNowCard";
import { useGlobalStore, useSubscriptionDetails } from "controller";
import { PaymentFailedBanner } from "components/banner/PaymentFailedBanner";

export const SubscriptionDetails = () => {
  const [value, setValue] = React.useState(0);
  const subscriptionStatus = useGlobalStore(
    (state) => state.subscriptionStatus
  );
  const { data: subscriptionDetails } = useSubscriptionDetails();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ py: "16px", px: "32px" }}
      >
        <Typography variant="h4">Subscription</Typography>
      </Stack>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", flexShrink: 0 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              px: "16px",
            }}
          >
            <Tab label="Overview" />
            <Tab label="Invoices" />
          </Tabs>
        </Box>
        {subscriptionStatus === "past_due" && (
          <PaymentFailedBanner showButton={false} />
        )}

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          {value === 0 ? (
            <>
              <OverviewTab />
            </>
          ) : (
            <>
              <InvoiceTab />
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
};
