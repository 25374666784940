import { useContext, useEffect, useState } from "react";
import { CustomCard } from "../../../../../../library/layers";
import PropTypes from "prop-types";
import { Box, Collapse, Stack } from "@mui/material";
import { CustomTypography } from "../../../../../../library/base";
import CustomIcon from "../../../../../../library/icon-library";
function ExpandableContainer(props) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    data: {},
  });

  useEffect(() => {
    setState({
      ...state,
      data: props.data,
    });
  }, [props]);

  const onEdithandler = (data) => {
    var newData = { ...state.data };
    newData[data.field] = data.value;
    props.onChange({
      field: props.form.name, value: newData
    });
  };


  return (
    <Stack
      width={"100%"}
      height={"fit-content"}
      sx={{
        borderBottom: "1px solid #cccccc",

      }}
    >
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          width: "100%",
          height: {
            xs: "48px",
            sm: "56px",
            md: "64px",
            lg: "72px",
            xl: "72px",
          },
          px: "32px",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "32px",
        }}

        onClick={() => {
          setOpen(!open);
        }}
      >
        <CustomTypography
          content={props.label}
          size="SMALL-ALPHA"
          variant="BOLD"
        />
        <Box
          sx={{
            height: "40px",
            width: "40px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CustomIcon icon={open === true ? "arrow_up" : "arrow_down"} color="black" />
        </Box>
      </Stack>
      <Collapse in={open} >
        {
          props.content
        }
      </Collapse>
    </Stack>
  );
}

ExpandableContainer.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired
};

ExpandableContainer.defaultTypes = {
  label: "",
  content: <></>,
};


export default ExpandableContainer;