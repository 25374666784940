import {
  AvatarProps,
  useTheme,
  Avatar as MuiAvatar,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import React from "react";

interface CustomAvatarProps extends AvatarProps {
  url?: string | null;
  fullname?: string | null;
  sx?: SxProps<Theme>;
  tooltip?: boolean;
  variants?: "xs" | "sm" | "md" | "lg" | "xl";
  contentSx?: SxProps<Theme>;
}
export const Avatar = ({
  url,
  fullname = "",
  sx,
  tooltip = false,
  variants = "md",
  contentSx,
  ...rest
}: CustomAvatarProps) => {
  const theme = useTheme();
  const splitFullname = fullname ? fullname.split(" ") : "";
  const getSize = (type: "size" | "font") => {
    switch (variants) {
      case "xs":
        return type === "size" ? "16px" : "6px";
      case "sm":
        return type === "size" ? "24px" : "10px";
      case "md":
        return type === "size" ? "32px" : "12px";
      case "lg":
        return type === "size" ? "40px" : "14px";
      case "xl":
        return type === "size" ? "48px" : "16px";
      default:
        break;
    }
  };
  return (
    <Tooltip
      title={tooltip ? fullname : ""}
      placement="top"
      followCursor={true}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: theme.palette.neutral["B-015"],
            color: theme.palette.lightBg.high,
            ...theme.typography["caption"],
          },
        },
      }}
    >
      <Box>
        <MuiAvatar
          alt={`${fullname}`}
          src={url ? url : undefined}
          sx={{
            borderRadius: "100px",
            border: "2px solid #FFF",
            backgroundColor: theme.palette.neutral["B-015"],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: getSize("size"),
            height: getSize("size"),
            textTransform: "uppercase",

            ...sx,
          }}
          {...rest}
        >
          <Typography
            variant="button-small"
            sx={{
              fontFamily: "SegoeBold",
              fontWeight: 700,
              fontSize: getSize("font"),
              textTransform: "uppercase",
              ...contentSx,
            }}
          >
            {splitFullname &&
            Array.isArray(splitFullname) &&
            splitFullname.length > 0
              ? `${splitFullname[0] ? splitFullname[0].charAt(0) : ""}${
                  splitFullname[1] ? splitFullname[1].charAt(0) : ""
                }`
              : ""}
          </Typography>
        </MuiAvatar>
      </Box>
    </Tooltip>
  );
};
