import { useEffect } from "react";
import { mixpanel } from "utilz/mixpanel";

type AnalyticsEventKeys =
  | "home_page_visited"
  | "company_profile_visited"
  | "company_profile_updated"
  | "resource_page_visited"
  | "resource_added"
  | "resource_updated"
  | "resource_deleted"
  | "subscription_page_visited"
  | "subscription_payment_initiated"
  | "subscription_payment_cancelled"
  | "subscription_payment_completed"
  | "subscription_payment_seat_count_updated"
  | "security_page_visited"
  | "mfa_settings_enabled"
  | "mfa_settings_disabled"
  | "system_app_page_visited"
  | "system_app_download_clicked"
  | "mobile_app_page_visited"
  | "teams_app_page_visited"
  | "teams_app_install_clicked"
  | "support_desk_email_copy_clicked"
  | "sla_page_visited"
  | "sla_added"
  | "sla_updated"
  | "quality_assurance_visited"
  | "quality_assurance_added"
  | "quality_assurance_deleted"
  | "custom_field_page_visited"
  | "custom_field_added"
  | "custom_field_updated"
  | "tags_page_visited"
  | "tags_page_added"
  | "tags_page_updated"
  | "boards_page_visited"
  | "boards_page_added"
  | "boards_page_updated"
  | "checklist_page_visited"
  | "checklist_added"
  | "checklist_updated"
  | "templates_page_visited"
  | "templates_added"
  | "templates_updated"
  | "canned_notes_page_visited"
  | "canned_notes_added"
  | "canned_notes_updated"
  | "archive_page_visited"
  | "archive_item_restored"
  | "tax_page_visited"
  | "tax_added"
  | "tax_updated"
  | "billing_templates_page_visited"
  | "billing_template_added"
  | "billing_template_updated"
  | "products_page_visited"
  | "products_added"
  | "products_updated"
  | "expense_page_visited"
  | "expense_added"
  | "expense_updated"
  | "billing_settings_page_visited"
  | "billing_settings_added"
  | "billing_settings_updated"
  | "integration_page_visited"
  | "integration_n_central_configuration_added"
  | "integration_n_central_configuration_disabled_enabled"
  | "how_to_clicked"
  | "homepage_team_filter_selection"
  | "homepage_buy_now_clicked"
  | "service_desk_overview_clicked"
  | "service_desk_tickets_clicked"
  | "alert_center_clicked"
  | "alert_center_redirected_to_ticket"
  | "add_ticket_clicked"
  | "ticket_created"
  | "ticket_creation_failed"
  | "tickets_list_page_all_clicked"
  | "tickets_list_page_me_clicked"
  | "tickets_list_page_status_changed"
  | "tickets_list_page_priority_changed"
  | "tickets_list_page_assign_clicked"
  | "tickets_list_page_merge_clicked"
  | "tickets_list_page_modify_clicked"
  | "tickets_list_page_archive_clicked"
  | "tickets_list_page_filters_clicked"
  | "tickets_list_page_filter_applied"
  | "tickets_list_page_boards_filter_clicked"
  | "tickets_list_page_boards_filter_applied"
  | "tickets_list_page_customer_filter_clicked"
  | "tickets_list_page_customer_filter_applied"
  | "tickets_list_page_sites_filter_clicked"
  | "tickets_list_page_sites_filter_applied"
  | "tickets_list_page_refresh_clicked"
  | "ticket_details_page_visited"
  | "tickets_detail_page_chat_mode_clicked"
  | "tickets_detail_page_chat_mode_selected"
  | "tickets_detail_page_conversation_filter_clicked"
  | "tickets_detail_page_conversation_filter_applied"
  | "tickets_detail_page_chat_action_plus_clicked"
  | "tickets_detail_page_chat_action_plus_canned_notes_clicked"
  | "tickets_detail_page_canned_notes_send"
  | "tickets_detail_page_chat_action_plus_attachments_clicked"
  | "tickets_detail_page_chat_action_plus_notes_time_clicked"
  | "tickets_detail_page_self_chat_message_created"
  | "tickets_detail_page_tags_clicked"
  | "tickets_detail_page_tags_added"
  | "tickets_detail_page_assignees_clicked"
  | "tickets_detail_page_assignees_schedule_applied"
  | "tickets_detail_page_assignees_added"
  | "tickets_detail_page_timer_started"
  | "tickets_detail_page_timer_stopped"
  | "tickets_detail_page_notes_added"
  | "tickets_detail_page_notes_time_clicked"
  | "tickets_detail_page_notes_time_applied"
  | "whats_new_clicked";
export const addMixpanelEvent = ({
  event,
  data,
}: {
  event: AnalyticsEventKeys;
  data?: Record<string, any>;
}) => {
  if (
    import.meta.env.VITE_APP_NODE_ENV === "UAT" ||
    import.meta.env.VITE_APP_NODE_ENV === "PROD"
  ) {
    mixpanel.track(event, data);
  }
};

export const useMixpanelPageViewTracker = ({
  event,
  data,
}: {
  event: AnalyticsEventKeys;
  data?: Record<string, any>;
}) => {
  useEffect(() => {
    if (
      import.meta.env.VITE_APP_NODE_ENV === "UAT" ||
      import.meta.env.VITE_APP_NODE_ENV === "PROD"
    ) {
      mixpanel.track(event, data);
    }
  }, []);
};
