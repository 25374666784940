function CheckBoxUncheckedIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="3.25"
        stroke="#CCCCCC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

export default CheckBoxUncheckedIcon;