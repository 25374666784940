import { Box, Stack, Typography } from "@mui/material";
import { CustomTypography } from "../../../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../../../library/icon-library";
import PropTypes from "prop-types";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
function CannedNotesTableTile(props) {
  return (
    <ThemeProvider>
      <Box
        sx={(theme) => ({
          width: "100%",
          height: "48px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          px: "40px",
          columnGap: "16px",
          alignItems: "center",
          borderTop: "1px solid #E3E3E3",
          transition: theme.transitions.create(["background-color"], {
            duration: 200,
          }),
          "&:hover": {
            backgroundColor: "#F3F5F9",
            cursor: "pointer",
          },
        })}
        onClick={props.onClick}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: "1",
          }}
        >
          <Typography
            variant="body-small"
            sx={{
              maxWidth: "500px",
              overflow: "clip",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {props.value}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            aspectRatio: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& :hover": {
              cursor: "pointer",
            },
          }}
          onClick={props.onDelete}
        >
          <CustomIcon icon="delete" color="black" size={"lg"} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
CannedNotesTableTile.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
CannedNotesTableTile.defaultProps = {
  value: "",
  onClick: () => {},
  onDelete: () => {},
};

export default CannedNotesTableTile;
