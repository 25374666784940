import { AddPinServiceInput, RemovePinServiceInput } from "shared";
import { axiosClient } from "utilz";
import { apiErrorHandler } from "utilz/helpers";

export const getAllPinnedService = async () => {
  try {
    const response = await axiosClient.get("/servicedesk/pin_services");
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (e: any) {
    apiErrorHandler(e);
  }
};

export const addPinService = async ({
  serviceId,
  serviceType,
  colorCodePrimary,
  colorCodeTint,
}: AddPinServiceInput) => {
  try {
    const response = await axiosClient.post(
      `/servicedesk/pin_services/${serviceType}/${serviceId}`,
      {
        color_code_primary: colorCodePrimary,
        color_code_tint: colorCodeTint,
      }
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (e: any) {
    apiErrorHandler(e);
  }
};

export const deletePinnedService = async ({
  serviceId,
  serviceType,
}: RemovePinServiceInput) => {
  try {
    const response = await axiosClient.delete(
      `/servicedesk/pin_services/${serviceType}/${serviceId}`
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (e: any) {
    apiErrorHandler(e);
  }
};
