import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AttachIcon,
  BulbIcon,
  BulbYellowIcon,
  Button,
  DateInput,
  DateTimeInput,
  DeleteConfirmationModal,
  DeleteIcon,
  Drawer,
  Input,
  Modal,
  PlayCircleIcon,
  PlayStopButton,
  SelectField,
  StopCircleIcon,
  TimeCounter,
  TimeInput,
} from "components";
import {
  useGetAllCannedNotes,
  useGetAttributes,
  useUpdateTaskStatus,
  useCreateTimeEntry,
  useDeleteTimeEntries,
  useEndTimer,
  useStartTimer,
  useUpdateTicketDetailsFromTimeEntries,
  useUpdateTimeEntry,
  useGetResourceById,
  useNonPersistantMainStore,
  useTimeEntryStore,
} from "controller";
import { useMainStore } from "controller/store/mainStore";
import dayjs from "dayjs";
import CustomCheckBox from "library/base/custom-checkbox";
import ToggleButton from "library/base/custom-toggle-button";
import { CustomRadioGroup } from "library/system/custom-form";
import { CustomQuillEditor } from "pages/modules/control-center/configurations/components/service-desk-templates/tabs/component";
import { useCallback, useEffect, useState } from "react";
import { Avatar } from "components/avatar";
import toaster from "react-hot-toast";
import {
  combineDateAndTimeV2,
  convertUTCtoTimezone,
  convertUTCtoTimezoneV2,
  dateTimeToUtc,
  dateTimeToUtcV2,
  debugConsole,
  getFullName,
  getProfilePictureUrl,
  getResourceName,
  isSuperAdmin,
} from "utilz/helpers";
import {
  convertDoubletoHourAndMinutes,
  convertToHourHandler,
} from "utilz/helpers/formatTimeDuration";
import { useGlobalStore } from "controller/store/globalStore";

import { AttachmentFileData } from "shared";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AttachmentsModal } from "./AttachmentsModal";
interface CreateTimeEntryModalProps {
  open: boolean;
  selectedTimeEntryId?: string;
  selectedResourceName?: string;
  selectedProfileImage?: string;
  selectedStartDateTime?: string | null;
  selectedEndDateTime?: string | null;
  selectedDeductTime?: string | any | null;
  selectedStatus?: string;
  selectedWorkType?: string;
  selectedWorkRole?: string;
  selectedCannedNotes?: string;
  selectedIsBillable?: boolean;
  selectedNote?: string;
  selectedAttachment?: Array<any>;
  selectedAccessSpecifier?: "public" | "private" | "self" | null;
  selectedResolution?: boolean;
  selectedResourceId?: string | null;
  selectedSendAsEmail?: boolean;
  isItActiveTimeEntry?: boolean;
  serviceType: "ticket" | "request" | "project" | "task";
  onClose: () => void;
  serviceId: string;
  isTimerDisabled?: boolean;
  isOperatingHoursInitial?: boolean;
  serviceDetails?: any;
}

const schema = yup.object({
  startDateTime: yup.string().required("Start date & time is required"),
  endDateTime: yup.string().required("End date & time is required"),
  workType: yup.string().required("Work type is required"),
  workRole: yup.string().required("Work role is required"),
});

export const CreateTimeEntryModal = ({
  open,
  serviceId,
  selectedResourceName = "",
  selectedProfileImage = "",
  selectedTimeEntryId = "",
  selectedStartDateTime = "",
  selectedEndDateTime = "",
  selectedDeductTime,
  selectedCannedNotes,
  selectedWorkRole,
  selectedWorkType,
  selectedIsBillable = true,
  selectedNote,
  selectedAttachment = [],
  selectedAccessSpecifier,
  selectedResolution,
  selectedResourceId,
  selectedSendAsEmail = false,
  isItActiveTimeEntry = false,
  isTimerDisabled = false,
  serviceType,
  selectedStatus,
  onClose,
  isOperatingHoursInitial,
  serviceDetails,
}: CreateTimeEntryModalProps) => {
  //

  const {
    handleSubmit,
    getValues,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDateTime: "",
      endDateTime: "",
      workType: "",
      workRole: "",
    },
  });

  // set timezone to this variable
  const { mutateAsync: deleteTimeEntries } = useDeleteTimeEntries();

  const resourceName =
    selectedTimeEntryId === "" || selectedTimeEntryId === null
      ? getResourceName()
      : selectedResourceName;

  const profileImage =
    selectedTimeEntryId === "" || selectedTimeEntryId === null
      ? getProfilePictureUrl()
      : selectedProfileImage;

  const [timeEntryId, setTimeEntryId] = useState(selectedTimeEntryId);
  const { mutateAsync: startTimer } = useStartTimer();
  const { mutateAsync: endTimer } = useEndTimer();
  const userRole = useGlobalStore((state) => state.role) || "";
  //for updating ticket status
  const { mutateAsync: updateTicketDetailsFromTimeEntries } =
    useUpdateTicketDetailsFromTimeEntries();
  //for updating project task status
  const { mutateAsync: updateTaskStatus } = useUpdateTaskStatus();

  const { mutateAsync: createTimeEntries, isLoading: isCreateLoading } =
    useCreateTimeEntry();
  const { mutateAsync: updateTimeEntries, isLoading: isUpdateLoading } =
    useUpdateTimeEntry();
  const timezone = useGlobalStore((state) => state.timezone);
  const currentResourceId = useGlobalStore((state) => state.resourceId);
  const resourceId = selectedResourceId || currentResourceId;
  const activeTimeEntry = useTimeEntryStore((state) =>
    state.activeTimeEntriesDetails.find(
      (item) => item.service_id == serviceId && item.service_type == serviceType
    )
  );
  const { data: resourceData } = useGetResourceById(resourceId || "");
  const globalStartTimeHandler = useTimeEntryStore(
    (state) => state.addTimeEntry
  );
  const globalEndTimeHandler = useTimeEntryStore(
    (state) => state.removeTimeEntry
  );
  const { data: workTypeData } = useGetAttributes({
    attributeName: "work_type",
    serviceType: "general",
  });
  const { data: workRoleData } = useGetAttributes({
    attributeName: "work_role",
    serviceType: "general",
  });

  const { data: statusData } = useGetAttributes({
    attributeName: "status",
    serviceType: serviceType,
  });
  const { data: cannedData } = useGetAllCannedNotes({});

  const theme = useTheme();

  const startDateTime = watch("startDateTime");
  const endDateTime = watch("endDateTime");
  const workType = watch("workType");
  const workRole = watch("workRole");

  const [deductTime, setDeductTime] = useState<string>(
    convertToHourHandler(selectedDeductTime) || ""
  );

  const [status, setStatus] = useState(selectedStatus || "");

  // useMainStore((state) => state.ticketDetails.workType)
  // const [timeCountData, setTimeCountData] = useState<any>();
  const ticketSource = useMainStore((state) => state.ticketSource);
  const [cannedNotes, setCannedNotes] = useState(selectedCannedNotes || "");
  const [isOperatingHours, setIsOperatingHours] = useState(
    isOperatingHoursInitial ?? true
  );
  const isOperatingHoursOptions = [
    {
      label: "Operating Hours",
      value: true,
    },
    { label: "Non-operating Hours", value: false },
  ];

  const [isBillable, setIsBillable] = useState(
    selectedIsBillable == false ? false : true
  );
  const [sendAsEmail, setSendAsEmail] = useState(selectedSendAsEmail);

  const addAttachment = useMainStore((state) => state.setAttachmentModalData);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const resetAttachment = useMainStore(
    (state) => state.resetAttachmentModalData
  );
  const attachment = useMainStore(
    (state) => state.attachmentModalData.attachments
  );

  useEffect(() => {
    reset({
      startDateTime: selectedStartDateTime || "",
      endDateTime: selectedEndDateTime || "",
      workRole: selectedWorkRole || resourceData?.resource_work_role,
      workType: selectedWorkType || "",
    });
  }, [resourceData]);

  useEffect(() => {
    const attachments: Array<AttachmentFileData> = selectedAttachment.map(
      (attachment) => ({
        key: attachment.attachment_mapping_id,
        name: attachment.original_name,
        size: attachment.size,
        type: attachment.file_type,
        status: "Uploaded",
        progress: 100,
        controller: null,
        file: "",
        responseData: {
          mapping_id: attachment.attachment_mapping_id,
          original_name: attachment.original_name,
          file_type: attachment.file_type,
          size: attachment.size,
          url: attachment.url,
        },
      })
    );

    addAttachment(attachments);
    return () => {
      resetAttachment();
    };
  }, []);

  const [noteType, setNoteType] = useState(
    selectedAccessSpecifier || "private"
  );
  const [note, setNote] = useState(
    selectedAccessSpecifier === "self" && selectedResourceId !== resourceId
      ? ""
      : selectedNote || ""
  ); //noteType
  const [resolution, setResolution] = useState(selectedResolution || false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const noteOptions = [
    {
      key: "notes_and_time_private",
      value: "private",
      disabled: false,
      label: "Private",
    },
    {
      key: "notes_and_time_public",
      value: "public",
      disabled: false,
      label: "Public",
    },
    {
      key: "notes_and_time_self",
      value: "self",
      disabled: false,
      label: "Self",
    },
  ];

  // api

  // create new time entry
  const createNewTimeEntry = async (data: any) => {
    try {
      const services = [
        createTimeEntries({
          serviceId: serviceId,
          resourceId: resourceId,
          serviceType: serviceType,
          ...data,
        }),
      ];
      const ticketData = {
        ...(!!status && status !== selectedStatus ? { status } : {}),
      };
      // checking if all these fields are empty  if true then dont need to update ticket details
      if (Object.entries(ticketData).length > 0) {
        if (serviceType === "ticket") {
          services.push(
            updateTicketDetailsFromTimeEntries({
              ticketId: serviceId,
              ...ticketData,
            })
          );
        } else if (serviceType === "task") {
          services.push(
            updateTaskStatus({
              taskId: serviceId,
              statusId: ticketData?.status || "",
            })
          );
        }
      }
      await Promise.all(services).then((res) => {
        toaster.success("Created successfully");
        onClose();
      });
    } catch (e) {
      toaster.error(`${e}` || "Unable to create");
    }
  };

  //update time entry
  const updateTimeEntry = async (data: any) => {
    try {
      const updateData = {
        serviceId: serviceId,
        timeEntryId: timeEntryId,
        resourceId: resourceId,
        serviceType: serviceType,
        ...data,
      };

      const services = [updateTimeEntries(updateData)];

      const ticketData = {
        ...(!!status && status !== selectedStatus ? { status } : {}),
      };
      // checking if all these fields are empty  if true then dont need to update ticket details
      if (Object.entries(ticketData).length > 0) {
        if (serviceType === "ticket") {
          services.push(
            updateTicketDetailsFromTimeEntries({
              ticketId: serviceId,
              ...ticketData,
            })
          );
        } else if (serviceType === "task") {
          services.push(
            updateTaskStatus({
              taskId: serviceId,
              statusId: ticketData?.status || "",
            })
          );
        }
      }

      await Promise.all(services).then((res) => {
        toaster.success("Updated successfully");
        onClose();
      });
    } catch (e) {
      toaster.error(`${e}` || "Unable to update");
    }
  };

  /// delete time entry

  const deleteTimeEntry = () => {
    setDeleteConfirmationOpen(false);
    deleteTimeEntries({ serviceType, ticketId: serviceId, timeEntryId }).then(
      (res) => {
        toaster.success("Deleted successfully");
        globalEndTimeHandler({
          serviceId: serviceId,
          timeEntryId: timeEntryId,
          serviceType: serviceType,
        });
        onClose();
      }
    );
  };

  const isTimerButtonDisabled = () => {
    if (activeTimeEntry && timeEntryId !== activeTimeEntry?.mapping_id) {
      // if a time entry running and its not this one
      return true;
    }
    if (selectedEndDateTime !== null && selectedStartDateTime !== null) {
      //if start date time and end date time are not empty
      return true;
    }
    if (activeTimeEntry && timeEntryId === activeTimeEntry.mapping_id) {
      // if a time entry running and its this one
      if (
        currentResourceId !== resourceId &&
        !["SUPERADMIN", "ADMIN"].includes(userRole)
      ) {
        // if this time entry is not created by this user and user is not super admin or admin
        return true;
      }
      return false;
    }
    if (
      (startDateTime && startDateTime !== null && startDateTime !== "") ||
      (endDateTime && endDateTime !== null && endDateTime !== "")
    ) {
      //if any of these fields are not empty
      return true;
    }
    return false;
  };

  // event handler

  const onSubmitHandler = handleSubmit((value) => {
    const data = validateData();
    if ((typeof data === "boolean" && !data) || !data.validate) {
      return;
    }
    if (
      timeEntryId !== "" &&
      timeEntryId !== null &&
      timeEntryId !== undefined
    ) {
      updateTimeEntry(data.newTimeEntry);
      return;
    }
    createNewTimeEntry(data.newTimeEntry);
  });

  // handle timer play/stop actions
  const timerActionHandler = async () => {
    if (activeTimeEntry && timeEntryId === activeTimeEntry.mapping_id) {
      endTimer({
        serviceId: serviceId,
        serviceType: serviceType,
        timeEntryId: activeTimeEntry.mapping_id,
      }).then((res) => {
        setValue("endDateTime", res.end_time);
        globalEndTimeHandler({
          serviceId: serviceId,
          timeEntryId: activeTimeEntry.mapping_id,
          serviceType: serviceType,
        });
      });
      return;
    }
    await startTimer({
      resourceId: resourceId || "",
      serviceId: serviceId,
      serviceType: serviceType,
    }).then((res) => {
      setTimeEntryId(res.mapping_id);
      setValue("startDateTime", res.start_time);
      globalStartTimeHandler({
        mapping_id: res.mapping_id,
        service_id: serviceId,
        service_type: serviceType,
        start_time: convertUTCtoTimezone(`${res.start_time}`, timezone) || "",
        activeTimerValueInSeconds: 0,
        service_summary:
          serviceType === "ticket"
            ? serviceDetails?.ticket_summary
            : serviceType === "task"
            ? serviceDetails?.task_summary
            : "",
        u_id: serviceDetails?.u_id,
        customer_id: serviceDetails?.customer_id,
        customer_name: serviceDetails?.customer_name,
        customer_user_name: getFullName(
          serviceDetails?.customer_user_first_name,
          serviceDetails?.customer_user_middle_name,
          serviceDetails?.customer_user_last_name
        ),
        customer_user_id: serviceDetails?.customer_user_id,
        email: serviceDetails?.customer_user_email,
        profile_image: serviceDetails?.customer_user_profile_image,
      });
    });
  };
  //        res.mapping_id,
  //  convertUTCtoTimezone(`${res.start_time}`, timezone) || ""
  // format deduct time field to hhh.mm format eg: 23.6
  const deductTimeChangeHandler = (event: any) => {
    const regex = /^\d{0,3}(\.\d{0,2})?$/;
    const inputValue = event.target.value;

    if ((regex.test(inputValue) || inputValue === "") && !isNaN(inputValue)) {
      setDeductTime(inputValue);
    }
  };

  //to validate  datas from all fields
  const validateData = () => {
    if (
      (startDateTime === null && endDateTime === null) ||
      dayjs(endDateTime).diff(dayjs(startDateTime)) <= 0
    ) {
      toaster.error("Invalid date and time");
      setError("endDateTime", {});
      setError("startDateTime", {});
      return false;
    }

    const newTimeEntry: Record<any, any> = {
      endTime: endDateTime,
      startTime: startDateTime,
      deductTime: convertDoubletoHourAndMinutes(deductTime),
      notesAttachments: attachment.map((data) => data.responseData),
      isBillable,
      accessSpecifier: noteType,
      markAsResolution: resolution,
      sendAsEmail: sendAsEmail,
      work_type: workType,
      work_role: workRole,
      is_operating_hours: isOperatingHours,
    };

    //validate all fields are empty or not
    const validate = Object.values(newTimeEntry).every(
      (value) =>
        !(
          value === null ||
          value === undefined ||
          (typeof value === "string" && value.trim().length <= 0)
        )
    );

    if (!validate) {
      toaster.error("Fill all required fields");
    }

    newTimeEntry["notes"] = note;

    return { validate, newTimeEntry };
  };

  const getTotalHours = () => {
    if (
      startDateTime &&
      endDateTime &&
      startDateTime !== "" &&
      startDateTime !== null &&
      endDateTime !== null &&
      endDateTime !== ""
    ) {
      const total = dayjs(endDateTime).diff(dayjs(startDateTime), "second");
      try {
        const deductionHourInSeconds = parseFloat(deductTime) * 3600;

        const timeAfterDeduction = total - deductionHourInSeconds;
        return isNaN(timeAfterDeduction)
          ? total
          : timeAfterDeduction <= 0
          ? 0
          : timeAfterDeduction;
      } catch (e) {
        debugConsole(
          "🚀 ~ file: CreateTimeEntryModal.tsx:519 ~ getTotalHours ~ e:",
          e
        );
      }
      return total;
    }
    return null;
  };

  const isDeleteDisabled = () => {
    if (activeTimeEntry && timeEntryId === activeTimeEntry.mapping_id) {
      return true;
    }
    if (selectedNote && selectedNote !== null && selectedNote !== "") {
      return true;
    }
    if (isSuperAdmin()) {
      return false;
    }
    if (
      selectedResourceId &&
      selectedResourceId !== null &&
      selectedResourceId !== "" &&
      resourceId === selectedResourceId
    ) {
      return false;
    }
    return true;
  };

  const validateDateTime = (
    field: "startDateTime" | "endDateTime",
    value: string
  ) => {
    let startDateTime = getValues("startDateTime");
    let endDateTime = getValues("endDateTime");
    if (field === "startDateTime") {
      startDateTime = value;
    } else {
      endDateTime = value;
    }

    if (
      (startDateTime === null && endDateTime === null) ||
      dayjs(endDateTime).diff(dayjs(startDateTime)) > 0
    ) {
      clearErrors("endDateTime");
      clearErrors("startDateTime");
    }
    setValue(field, value);
  };

  const handleAccessSpecifierChange = (
    value: "public" | "private" | "self"
  ) => {
    // if ticket is created from email then every public note will be sent as email
    if (value !== "public") {
      setSendAsEmail(false);
    } else {
      if (ticketSource === "Email") {
        setSendAsEmail(true);
      }
    }

    setNoteType(value);
  };

  const isEditable = () => {
    return currentResourceId !== resourceId &&
      !["SUPERADMIN", "ADMIN"].includes(userRole)
      ? true
      : false;
  };

  const blockedStatus = [
    "caf3b9ab-08a5-4014-938c-aacc021f043b",
    "953b24ef-2167-49f9-88d9-a06cca165753",
    "30f434a9-4e66-4bea-86e3-86bb945035b3",
    "da5be1f9-db9d-4c64-8e43-51b458746773",
  ];

  const filteredStatus = useCallback(
    () =>
      statusData?.filter((item: any) => {
        return !blockedStatus.includes(item.mapping_id);
      }),
    [statusData]
  );

  return (
    <>
      {/* <TimeEntryAttachmentsModal
        open={attachmentModalOpen}
        savedAttachments={[attachment, setAttachment]}
        onClose={() => {
          setAttachmentModalOpen(false);
        }}
      /> */}
      {attachmentModalOpen && (
        <AttachmentsModal
          open={attachmentModalOpen}
          onClose={() => setAttachmentModalOpen(false)}
          serviceType={serviceType as "ticket" | "project" | "task"}
          attachmentType="notes"
        />
      )}
      {deleteConfirmationOpen ? (
        <DeleteConfirmationModal
          open={deleteConfirmationOpen}
          title="Delete Notes & Time"
          tagline="Are you sure you want to delete ?"
          onClose={() => setDeleteConfirmationOpen(false)}
          onSecondaryButtonClick={() => setDeleteConfirmationOpen(false)}
          onPrimaryButtonClick={deleteTimeEntry}
        />
      ) : null}
      <Drawer
        open={open}
        onOpen={() => {}}
        title="Notes & time"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={onSubmitHandler}
        onSecondaryButtonClick={onClose}
        isLoading={isCreateLoading || isUpdateLoading}
        // isPrimaryButtonDisabled={isEditable()}
        onClose={onClose}
        headerRightActionComponent={
          <Stack direction={"row"} gap={"12px"}>
            <Button
              onClick={() => setResolution((prev) => !prev)}
              startIcon={resolution ? <BulbYellowIcon /> : <BulbIcon />}
              variant="secondary"
            >
              {resolution ? "Marked" : "Mark"} as resolution
            </Button>
            <Button
              disabled={isDeleteDisabled()}
              onClick={() => setDeleteConfirmationOpen(true)}
              variant="secondary"
              icon={<DeleteIcon />}
              isIconOnly={true}
            ></Button>
          </Stack>
        }
        additionalHeaderComponent={
          <Box
            sx={{ borderBottom: `1px solid ${theme.palette.neutral["015"]}` }}
          ></Box>
        }
        footerRightActionComponent={
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              gap: "11px",
            }}
          >
            {/* //attachment */}

            <Box sx={{ position: "relative" }}>
              {attachment && attachment?.length > 0 && (
                <Box
                  width={"20px"}
                  height={"16px"}
                  bgcolor={"red"}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    zIndex: 2,
                  }}
                >
                  <Typography variant="caption-3" color="#FFF">
                    {attachment?.length}
                  </Typography>
                </Box>
              )}
              <IconButton onClick={() => setAttachmentModalOpen(true)}>
                <AttachIcon htmlColor={theme.palette.purple.main} />
              </IconButton>
            </Box>
          </Stack>
        }
        footerLeftActionComponent={
          serviceType === "ticket" && (
            <Stack
              direction={"row"}
              sx={{
                alignItems: "center",
                gap: "11px",
              }}
            >
              <CustomRadioGroup
                value={`${noteType || ""}`}
                name=""
                options={noteOptions}
                handle={(data) => {
                  handleAccessSpecifierChange(data.value);
                }}
              />
              {/* If ticket is created via Email all public note will be sent through email */}
              {noteType === "public" && ticketSource !== "Email" && (
                <>
                  <CustomCheckBox
                    checked={sendAsEmail}
                    onChange={(e) => setSendAsEmail(e.target.checked)}
                  />
                  <Typography variant="body-medium">Send as email</Typography>
                </>
              )}
            </Stack>
          )
        }
      >
        <Stack sx={{ maxWidth: "600px" }}>
          <Stack>
            <Stack direction="row" sx={{ py: "24px", px: "24px", gap: "34px" }}>
              <Stack sx={{ width: "104px" }}>
                <Avatar
                  fullname={resourceName}
                  src={profileImage}
                  sx={{
                    width: "85px",
                    height: "85px",
                    aspectRatio: "100%",
                  }}
                />

                <Typography variant="h6">
                  {selectedTimeEntryId === null || selectedTimeEntryId === ""
                    ? resourceName
                    : selectedResourceName}
                </Typography>
              </Stack>

              <Stack sx={{ gap: "24px" }}>
                <Stack direction={"row"} sx={{ gap: "24px" }}>
                  <DateTimeInput
                    fullWidth
                    label="Start Date & Time"
                    value={convertUTCtoTimezoneV2(startDateTime, timezone)}
                    disabled={
                      activeTimeEntry &&
                      timeEntryId === activeTimeEntry.mapping_id
                    }
                    timezone={timezone}
                    onChangeHandler={(e) =>
                      validateDateTime(
                        "startDateTime",
                        dateTimeToUtcV2(e, timezone)
                      )
                    }
                    error={errors.startDateTime ? true : false}
                    sx={{
                      width: "100%",
                    }}
                  />
                  <DateTimeInput
                    fullWidth
                    label="End Date & Time"
                    value={convertUTCtoTimezoneV2(endDateTime, timezone)}
                    timezone={timezone}
                    disabled={
                      activeTimeEntry &&
                      timeEntryId === activeTimeEntry.mapping_id
                    }
                    onChangeHandler={(e) =>
                      validateDateTime(
                        "endDateTime",
                        dateTimeToUtcV2(e, timezone)
                      )
                    }
                    error={errors.endDateTime ? true : false}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Stack>

                <Stack direction={"row"} sx={{ gap: "24px " }}>
                  <Input
                    name=""
                    label="Deduct time"
                    value={deductTime}
                    size="small"
                    fullWidth
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 0.01,
                    }}
                    onChange={deductTimeChangeHandler}
                  />

                  <Stack direction={"row"} sx={{ width: "100%", gap: "12px" }}>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        height: {
                          sm: "32px",
                        },
                        border: `1px solid ${theme.palette.neutral["020"]}`,
                        borderRadius: "4px",
                        alignItems: "center",
                        justifyContent: "center",
                        px: "16px",
                      }}
                    >
                      <TimeCounter
                        value={
                          activeTimeEntry &&
                          timeEntryId === activeTimeEntry.mapping_id
                            ? activeTimeEntry.activeTimerValueInSeconds
                            : getTotalHours()
                        }
                      />
                    </Box>
                    <Stack
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.palette.neutral["010"],
                        borderRadius: "4px",
                        padding: "6px",
                        height: "32px",
                      }}
                    >
                      <PlayStopButton
                        isPlaying={Boolean(
                          activeTimeEntry &&
                            timeEntryId === activeTimeEntry.mapping_id
                        )}
                        onClick={timerActionHandler}
                        disabled={isTimerDisabled || isTimerButtonDisabled()}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              sx={{
                px: "24px",
                py: "24px",
                gap: "22px",
                borderTop: `1px solid ${theme.palette.neutral["015"]}`,
              }}
            >
              <SelectField
                name=""
                value={status}
                options={filteredStatus() || []}
                label="Status"
                labelMappingKey="status_name"
                valueMappingKey="mapping_id"
                onValueChange={(newValue) => setStatus(newValue)}
                hideHelperBox={true}
              />

              <SelectField
                name="work_type_id"
                label="Work type"
                options={workTypeData}
                labelMappingKey="work_type_name"
                valueMappingKey="mapping_id"
                value={workType}
                onValueChange={(e) => {
                  setValue("workType", e);
                }}
                hideHelperBox={true}
                error={errors.workType ? true : false}
              />
              <SelectField
                name="work_role_id"
                label="Work role"
                options={workRoleData}
                labelMappingKey="work_role_name"
                valueMappingKey="mapping_id"
                value={workRole}
                onValueChange={(e) => {
                  setValue("workRole", e);
                }}
                hideHelperBox={true}
                error={errors.workRole ? true : false}
              />
            </Stack>

            <Stack
              direction={"row"}
              sx={{
                px: "24px",
                py: "24px",
                gap: "22px",
                borderTop: `1px solid ${theme.palette.neutral["015"]}`,
              }}
            >
              <SelectField
                hideHelperBox={true}
                name=""
                value={cannedNotes}
                label="Canned notes"
                options={cannedData?.result}
                labelMappingKey="canned_notes_title"
                valueMappingKey="mapping_id"
                onValueChange={(newValue) => {
                  setCannedNotes(newValue);

                  const note = (cannedData?.result || []).find(
                    (item: any) => item.mapping_id === newValue
                  );
                  if (note) {
                    setNote(note.canned_notes_quil);
                  }
                }}
                sx={{
                  maxWidth: "248px",
                }}
              />
              <SelectField
                hideHelperBox={true}
                name="operating_hours_from"
                value={isOperatingHours}
                options={isOperatingHoursOptions}
                labelMappingKey="label"
                valueMappingKey="value"
                onValueChange={(newValue) => setIsOperatingHours(newValue)}
                sx={{
                  maxWidth: "248px",
                }}
              />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap="16px"
                width={"100%"}
              >
                <ToggleButton
                  // isDisabled={!isTicketBillable}
                  onChange={(e) => setIsBillable(e.value)}
                  isChecked={isBillable}
                />
                <Typography variant="body-medium" noWrap>
                  {isBillable ? "Billable" : "Non Billable"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/* Quill section */}
          <CustomQuillEditor value={note} onChange={(e) => setNote(e.value)} />
        </Stack>
      </Drawer>
    </>
  );
};
