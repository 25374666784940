import { axiosClient } from "utilz";

export const generateIntercomHash = async () => {
  try {
    const url = "/create/hash/intercom";
    const response = await axiosClient.post(url);

    const responseData = response.data;
    if (responseData) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addAttributeToIntercom = ({ event }: { event: string }) => {
  try {
    // @ts-ignore
    window.Intercom("trackEvent", event);
  } catch (error) {
    console.log(error);
  }
};
