import axios, { Axios } from "axios";
import { getAccessToken } from "./helpers";
import { reAuthenticateUser } from "./helpers/apiRequestAuthHelper";

export const axiosClient = axios.create({
  baseURL: import.meta.env["VITE_APP_BASE_URL"],
});
export const axiosQaClient = axios.create({
  baseURL: import.meta.env["VITE_APP_QA_BASE_URL"],
});
export const authAxiosClient = axios.create({
  baseURL: import.meta.env["VITE_APP_AUTH_BASE_URL"],
  // withCredentials: true,
});

authAxiosClient.interceptors.request.use(
  (req) => {
    const accessToken = getAccessToken();
    req.headers["Authorization"] = `Bearer ${accessToken}`;
    return req;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(
  (req) => {
    const accessToken = getAccessToken();
    req.headers["Authorization"] = `Bearer ${accessToken}`;

    return req;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  async (res) => {
    if (res.status === 401) {
      const response = await reAuthenticateUser(res);
      if (response && response !== null) {
        return axiosClient.request(response);
      }
    }
    return res;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      const response = await reAuthenticateUser(error);
      if (response && response !== null) {
        return axios.request(response);
      }
    }
    return Promise.reject(error);
  }
);

authAxiosClient.interceptors.response.use(
  async (res) => {
    if (res.status === 401) {
      const response = await reAuthenticateUser(res);
      if (response && response !== null) {
        return authAxiosClient.request(response);
      }
    }
    return res;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      const response = await reAuthenticateUser(error);
      if (response && response !== null) {
        return axios.request(response);
      }
    }
    return Promise.reject(error);
  }
);
