import { GENERAL_FORM_CONSTANT } from "../../components/general-info-form/constants";
import { TICKET_DETAILS_CONSTANT } from "../../components/ticket-details-form/constants";
export default function FormDataConverter(data) {
  var newData = { ...data };


  //Attachments
  newData[GENERAL_FORM_CONSTANT.attachments.name] = newData[
    GENERAL_FORM_CONSTANT.attachments.name
  ]
    .filter((item) => {


      return item.mapping_id !== "";
    })
    .map((item) =>
      item.response
        ? item.response
        : {
          mapping_id: item.mapping_id,
          original_name: item.original_name,
          file_type: item.file_type,
          url: item.url,
        }
    );

  //tags
  newData[TICKET_DETAILS_CONSTANT.tags.name] = newData[
    TICKET_DETAILS_CONSTANT.tags.name
  ].map((item) => ({
    mapping_id: item.value,
    tag_name: item.label,
  }));

  ///to convert time in eg:1.2 (hour) to hh hours mm minutes


  if (newData[TICKET_DETAILS_CONSTANT.top.budgeted_hours.name] !== "") {
    newData[TICKET_DETAILS_CONSTANT.top.budgeted_hours.name] =
      convertDoubletoHourAndMinutes(
        newData[TICKET_DETAILS_CONSTANT.top.budgeted_hours.name]
      );
  }
  return newData;
}

function convertDoubletoHourAndMinutes(durationInHours = 0) {
  try {
    if (typeof durationInHours === "string") {
      durationInHours = parseFloat(durationInHours);
    }
    const hours = Math.floor(durationInHours);
    const minutes = Math.floor((durationInHours - hours) * 60);
    const seconds = Math.floor(((durationInHours - hours) * 60 - minutes) * 60);
    return `${hours || 0} hours ${minutes || 0} minutes`;
  } catch (e) {
    return "";
  }
}
