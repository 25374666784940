import * as React from "react";
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
  SxProps,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TopBarComponent } from "./component";
import confetti from "assets/lottie/confetti.json";

// import
import ddlogo from "../assets/deskdaylogo.png";
import logoBig from "assets/logo/logoBig.png";
import {
  BillingInvoiceFilledIcon,
  BillingInvoiceIcon,
  CircularLoader,
  CloseIcon,
  ControlcenterFilledIcon,
  ControlcenterIcon,
  CustomersFilledIcon,
  CustomersIcon,
  DevicemanagementFilledIcon,
  DevicemanagementIcon,
  DocumentationFilledIcon,
  DocumentationIcon,
  DownArrowIcon,
  DownloadIcon,
  HomeFilledIcon,
  HomeIcon,
  InfoIcon,
  RMMFilledIcon,
  RMMIcon,
  ReportsFilledIcon,
  ReportsIcon,
  SDFilledIcon,
  SDIcon,
  Setting1Icon,
  ThunderBoltIcon,
  TimeCounter,
  Tooltip,
} from "components";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

//
import { ReturnContext } from "../contexts/return-context";
import { useState } from "react";

import { disabledRoutes, routerConstants } from "../constants/router-constants";
import { Collapse, Modal, Stack } from "@mui/material";
import { AppBarHeight, DownloadDrawer, Route } from "shared";
import Lottie from "lottie-react";
import { useGlobalStore } from "controller/store/globalStore";
import { FloatingActionButton } from "shared/features/notification";
import {
  useGetAllActiveTimeEntries,
  useGetWatchList,
  useMainStore,
  useNonPersistantMainStore,
} from "controller";
import { useQueryClient } from "@tanstack/react-query";
import PinnedChat from "shared/features/pinned-chat/PinnedChat";
import { ChatNotification } from "shared/features/chat-notification/ChatNotification";
import { addMixpanelEvent } from "utilz/helpers";
const drawerWidth = 272;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  background: "linear-gradient(185.95deg, #001E25 0%, #0D0F23 100%);",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 200,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: "linear-gradient(185.95deg, #001E25 0%, #0D0F23 100%);",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 200,
  }),
  overflowX: "hidden",
  width: "80px",
  [theme.breakpoints.up("sm")]: {
    width: "80px",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: "none",
  backgroundColor: "#fff",
  px: 0,
  width: "calc(100% - 80px)",
  borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: 200,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  background: "linear-gradient(185.95deg, #001E25 0%, #0D0F23 100%);",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  justifyContent: "center",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = useGlobalStore((state) => state.role) || "";
  const [enableReturn, setEnableReturn] = useState(true);

  const [open, setOpen] = React.useState(false);
  const [openedTab, setOpenedTab] = useState("");
  const [lastOpenedTab, setLastOpenedTab] = useState("");

  const toggleDrawer = () => {
    // setOpenedTab("");
    setOpen((prev) => {
      prev ? setOpenedTab("") : setOpenedTab(location.pathname);
      return !prev;
    });
  };

  const toggleCollapse = ({ route, menu }: { route: string; menu: Route }) => {
    if (!open) {
      // only open if menu item have children
      menu.hasChildren && setOpen(true);
      if (
        menu.route === "/app/control-center" &&
        ["ADMIN"].includes(userRole)
      ) {
        navigate("/app/control-center/configurations/home");
      }
    }
    if (menu.children?.length === 0) {
      navigate(menu.route);
    }
    setOpenedTab((prev) => (prev.includes(route) ? "" : route));
    // openedTab.includes(menu.route)
  };

  return (
    <>
      <ReturnContext.Provider value={{ enableReturn, setEnableReturn }}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: 0,
                margin: 0,
              }}
            >
              <Box width={"100%"}>
                <TopBarComponent enableReturn={true} />
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            // onMouseEnter={() => {
            //   setOpenedTab(lastOpenedTab);
            //   setOpen(true);
            // }}
            onMouseLeave={() => {
              setLastOpenedTab(openedTab);
              setOpenedTab("");
              setOpen(false);
            }}
            variant="permanent"
            open={open}
            PaperProps={{
              sx: {
                // INFO: Fixes the issue of opening sidemenu on moving cursor to right side edges
                // common drawer zindex is 900
                zIndex: 898,
              },
            }}
            sx={{
              overflowX: "hidden",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              // zIndex: 1000,
            }}
          >
            <DrawerHeader
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-start",
                pl: "24px",
                flexShrink: 0,
              }}
            >
              {open ? (
                <img src={logoBig} height="34px" width="auto" alt={"DeskDay"} />
              ) : (
                <img src={ddlogo} width="32px" height="32px" alt={"DeskDay"} />
              )}
            </DrawerHeader>
            <Divider />
            <Box
              sx={{
                overflowY: "auto",
                overflowX: "hidden",
                flexGrow: 1,
                "&:: -webkit-scrollbar": { display: "none" },
              }}
            >
              <List>
                {routerConstants.map((menu, index) => {
                  const isMainMenuActive =
                    location && location.pathname.includes(menu.route);

                  if (
                    menu.route === "/app/control-center" &&
                    !["SUPERADMIN", "ADMIN"].includes(userRole)
                  ) {
                    return null;
                  }
                  return (
                    <ListItem
                      key={menu.key}
                      disablePadding
                      sx={{
                        display: "block",
                        px: open ? "12px" : "16px",
                      }}
                    >
                      <Tooltip
                        title={menu.label}
                        popperStyle={{
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: theme.palette.neutral["005"],
                            ...theme.typography["caption"],
                            color: theme.palette.lightBg.high,
                            ...(open ? { display: "none" } : {}),
                          },
                        }}
                      >
                        <ListItemButton
                          className={`${menu?.intercomClassName || ""}`}
                          disableRipple
                          sx={{
                            display: "flex",
                            justifyContent: open ? "initial" : "center",
                            color: "#FFF",
                            //   background: "rgba(255, 255, 255, 0.25)",
                            background: isMainMenuActive
                              ? open
                                ? "rgba(255, 255, 255, 0.25)"
                                : theme.palette.purple.main
                              : "transparent",
                            borderRadius: "8px",
                            // pl: "16px",
                            // pr: "8px",
                            // py: "8px",
                            padding: open ? "8px 16px" : "8px 0px",
                            height: "40px",
                            mb: "24px",
                            ":hover": {
                              background: isMainMenuActive
                                ? !open
                                  ? theme.palette.purple.hover
                                  : "rgba(255, 255, 255, 0.25)"
                                : "rgba(255, 255, 255, 0.25)",
                            },
                          }}
                          onClick={() =>
                            // @ts-ignore
                            toggleCollapse({ route: menu.route, menu })
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? "12px" : "0px",
                              justifyContent: "center",
                            }}
                          >
                            <SidebarIcons
                              name={menu.key}
                              type={isMainMenuActive ? "filled" : "outline"}
                              htmlColor="#FFF"
                            />
                          </ListItemIcon>

                          {open && (
                            <>
                              <ListItemText
                                primary={
                                  menu?.isBeta && open ? (
                                    <Stack direction={"row"}>
                                      {menu.label}
                                      <Typography
                                        variant="caption-3"
                                        sx={{
                                          fontWeight: "600",
                                          transform: "translate(2px,-2px)",
                                          background:
                                            "linear-gradient(135deg, #ED25C1 -8.98%, #DA64ED 47.33%, #A74FFF 66.54%, #874FFF 82.24%, #8590ff 90.70%)",
                                          WebkitTextFillColor: "transparent",
                                          WebkitBackgroundClip: "text",
                                        }}
                                      >
                                        beta
                                      </Typography>
                                    </Stack>
                                  ) : (
                                    menu.label
                                  )
                                }
                                sx={{
                                  opacity: open ? 1 : 0,
                                  my: 0,
                                  py: 0,
                                  ...theme.typography["body-medium"],
                                  color: theme.palette.darkBg.high,
                                }}
                              />

                              {menu.children.length > 0 && open && (
                                <DownArrowIcon htmlColor="#FFF" />
                              )}
                              <Box
                                sx={{
                                  width: open ? "6px" : 0,
                                  height: open ? "6px" : 0,
                                  borderRadius: "100%",
                                  backgroundColor: menu.hasNew
                                    ? theme.palette.error.main
                                    : "transparent",
                                  opacity: open ? 1 : 0,
                                }}
                              ></Box>
                            </>
                          )}
                        </ListItemButton>
                      </Tooltip>
                      {menu.children.length > 0 && (
                        <Collapse
                          in={openedTab.includes(menu.route)}
                          sx={{ color: "#fff" }}
                        >
                          <List component="div" disablePadding>
                            {menu.children.map((childItem) => {
                              const isChildItemActive =
                                location &&
                                location.pathname.includes(childItem.route);

                              if (
                                menu.route === "/app/control-center" &&
                                ["ADMIN"].includes(userRole) &&
                                childItem.route !==
                                  "/app/control-center/configurations"
                              ) {
                                return null;
                              }

                              return (
                                <Link
                                  key={childItem.defaultRoute}
                                  style={{ textDecoration: "none" }}
                                  // @ts-ignore
                                  to={childItem.defaultRoute}
                                >
                                  <ListItemButton
                                    disableRipple
                                    sx={{
                                      pl: "28px",
                                      borderRadius: "8px",
                                      height: "40px",
                                      mb: "8px",
                                      pr: "8px",
                                      background: isChildItemActive
                                        ? theme.palette.purple.main
                                        : "transparent",

                                      ":hover": {
                                        background: isChildItemActive
                                          ? theme.palette.purple.hover
                                          : "rgba(255, 255, 255, 0.25)",
                                      },
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    ></ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        ...theme.typography["body-medium"],
                                        color: theme.palette.darkBg.high,
                                      }}
                                      primary={childItem.label}
                                    />
                                    {/* @ts-ignore */}
                                    {childItem?.isNew && (
                                      <Stack
                                        sx={{
                                          bgcolor: "#BAF4FC",
                                          padding: "2px 6px 3px 6px",
                                          borderRadius: "12px",
                                        }}
                                      >
                                        <Typography
                                          variant="caption-3"
                                          color={theme.palette.lightBg.high}
                                          sx={{
                                            fontWeight: "600",
                                          }}
                                        >
                                          New
                                        </Typography>
                                      </Stack>
                                    )}
                                  </ListItemButton>
                                </Link>
                              );
                            })}
                          </List>
                        </Collapse>
                      )}
                    </ListItem>
                  );
                })}
              </List>
              {/* <Divider sx={{ backgroundColor: "#6E717C" }} /> */}
              {/* disabled routes */}
              {/* <List>
                {disabledRoutes.map((menu, index) => {
                  const isMainMenuActive =
                    location && location.pathname.includes(menu.route);
                  return (
                    <ListItem
                      key={menu.key}
                      disablePadding
                      sx={{ display: "block", paddingX: "12px" }}
                    >
                      <ListItemButton
                        disableRipple
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          color: "#6E717C",
                          cursor: "default",
                          //   background: "rgba(255, 255, 255, 0.25)",
                          background: "transparent",
                          borderRadius: "8px",
                          px: "16px",
                          py: "8px",
                          height: "40px",
                          mb: "24px",
                          // ":hover": {
                          //   background: isMainMenuActive
                          //     ? !open
                          //       ? theme.palette.purple.hover
                          //       : "rgba(255, 255, 255, 0.25)"
                          //     : "rgba(255, 255, 255, 0.25)",
                          // },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <SidebarIcons
                            name={menu.key}
                            type={isMainMenuActive ? "filled" : "outline"}
                            htmlColor="#6E717C"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={menu.label}
                          sx={{
                            opacity: open ? 1 : 0,
                            my: 0,
                            py: 0,
                            ...theme.typography["body-medium"],
                            color: "#6E717C",
                          }}
                        />
                        {menu.children.length > 0 && open && (
                          <DownArrowIcon htmlColor="#FFF" />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List> */}
            </Box>

            <Box
              // component={"button"}

              sx={{
                cursor: "pointer",
                flexShrink: 0,
                height: "fit-content",
                bgcolor: "#0D0F23",

                border: 0,
                display: "flex",
                gap: "4px",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <WhatsNewButton open={open} />
              {userRole !== "RESOURCE" && (
                <Tooltip
                  title={"Control Center"}
                  popperStyle={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: theme.palette.neutral["005"],
                      ...theme.typography["caption"],
                      color: theme.palette.lightBg.high,
                    },
                  }}
                >
                  <Box>
                    <BottomMenuItem
                      open={open}
                      isActive={
                        location &&
                        location.pathname.includes("/app/control-center")
                      }
                      icon={<ControlcenterIcon />}
                      title="Control Center"
                      onClick={() => {
                        navigate("/app/control-center");
                        setOpenedTab((prev) =>
                          prev.includes("/app/control-center")
                            ? ""
                            : "/app/control-center"
                        );
                      }}
                      className="intercomV2-sideBar-controlCenterBtn"
                    />
                  </Box>
                </Tooltip>
              )}
              <Tooltip
                title={"IT-Connect"}
                popperStyle={{
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: theme.palette.neutral["005"],
                    ...theme.typography["caption"],
                    color: theme.palette.lightBg.high,
                  },
                }}
              >
                <Box>
                  <AgentDownloadButton open={open} />
                </Box>
              </Tooltip>
            </Box>
          </Drawer>
          <Box
            component="main"
            sx={{
              position: "absolute",
              left: "80px",
              flexGrow: 1,
              overflowY: "auto",
              overflowX: "hidden",
              width: "calc(100vw - 80px)",
              height: "100vh",
              //   width: open
              //     ? `calc(100vw - ${drawerWidth})`
              //     : `calc(100vw - 80px)`,
            }}
          >
            <DrawerHeader />
            <React.Suspense
              fallback={
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularLoader />
                </Stack>
              }
            >
              <Outlet />
            </React.Suspense>
          </Box>
          <PinnedChat />
          <ChatNotification />
          {/* <FloatingActionButton /> */}
        </Box>
      </ReturnContext.Provider>
    </>
  );
};

export const BottomMenuItem = ({
  open,
  onClick,
  isActive,
  icon,
  title,
  className,
  styleSx,
}: {
  isActive: boolean;
  open: boolean;
  onClick?: () => void;
  title: string;
  icon: React.ReactNode;
  className?: string;
  styleSx?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  return (
    <Box
      className={`${className || ""}`}
      onClick={onClick}
      sx={{
        height: "100%",
        display: "flex",

        flexDirection: "row",
        justifyContent: open ? "start" : "center",
        alignItems: "center",

        gap: "20px",
        padding: open ? "8px 16px" : "8px 0px",
        mx: open ? "12px" : "16px",
        borderRadius: "8px",
        color: "#FFF !important",
        background: isActive
          ? open
            ? "rgba(255, 255, 255, 0.25)"
            : theme.palette.purple.main
          : "transparent",
        ":hover": {
          backgroundColor:
            !open && isActive
              ? theme.palette.purple.hover
              : "rgba(255, 255, 255, 0.25)",
          borderRadius: "8px",
          color: "#FFF",
        },
        ...styleSx,
      }}
    >
      {icon}
      {/* <Lottie
                  style={{ position: "absolute" }}
                  animationData={confetti}
                  loop={true}
                /> */}
      {open && (
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant="body-medium" color="#FFF">
            {title}
          </Typography>
          {/* <Stack
            sx={{
              bgcolor: "#BAF4FC",
              padding: "2px 6px 3px 6px",
              borderRadius: "12px",
            }}
          >
            <Typography
              variant="caption-3"
              color={theme.palette.lightBg.high}
              sx={{
                fontWeight: "600",
              }}
            >
              New
            </Typography>
          </Stack> */}
        </Stack>
      )}
    </Box>
  );
};

export const WhatsNewButton = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <BottomMenuItem
          open={open}
          isActive={false}
          icon={<InfoIcon />}
          title="Whats New!"
          onClick={() => {
            setIsModalOpen(true);
          }}
          styleSx={{
            background: !open
              ? "transparent"
              : "linear-gradient(280.33deg, #ED25C1 5.8%, #FF96E2 35.01%, #A74FFF 63.86%, #874FFF 77.29%, #4D25ED 98.55%)",
          }}
        />
        {!open && (
          <Typography
            variant="caption-3"
            color={"#FFF"}
            sx={{
              position: "absolute",

              top: 0,
              right: 10,
              fontWeight: "600",
              transform: "translate(2px,-2px)",
              background:
                "linear-gradient(135deg, #ED25C1 -8.98%, #DA64ED 47.33%, #A74FFF 66.54%, #874FFF 82.24%, #8590ff 90.70%)",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
            }}
          >
            New
          </Typography>
        )}
      </Box>
      {isModalOpen && (
        <Modal open={isModalOpen}>
          <Stack
            height={"100%"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            sx={{ p: 2 }}
          >
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  border: "1px solid #fff",
                  borderRadius: "100%",
                  width: "26px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  addMixpanelEvent({ event: "whats_new_clicked" });
                  setIsModalOpen(false);
                }}
              >
                <CloseIcon
                  htmlColor="#FFF"
                  onClick={() => setIsModalOpen(false)}
                />
              </Box>

              <iframe
                width="710"
                height="400"
                src="https://www.youtube.com/embed/SLliC_37SsU?si=3wLxWIm4bCQLLdU4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
              ></iframe>
            </Box>
          </Stack>
        </Modal>
      )}
    </>
  );
};
export const AgentDownloadButton = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const [isDownloadDrawerOpen, setIsDownloadDrawerOpen] = useState(false);
  return (
    <>
      <Box
        className="intercom-downloadagent-btn intercomV2-sideBar-downloadAgenBtn"
        onClick={() => {
          setIsDownloadDrawerOpen(true);
        }}
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: open ? "start" : "center",
          alignItems: "center",
          gap: "20px",
          padding: open ? "16px 0px 16px 24px" : "16px 0px",
          ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.25)", //theme.palette.purple.main,
            color: "#FFF",
          },
        }}
      >
        <DownloadIcon htmlColor="#FFF" />
        {/* <Lottie
                  style={{ position: "absolute" }}
                  animationData={confetti}
                  loop={true}
                /> */}
        {open && (
          <Typography variant="body-medium" color="#FFF">
            IT-Connect
          </Typography>
        )}
      </Box>
      <DownloadDrawer
        open={isDownloadDrawerOpen}
        onClose={() => setIsDownloadDrawerOpen(false)}
      />
    </>
  );
};

export const SidebarIcons = ({
  name,
  type,
  htmlColor,
}: {
  name: string;
  type: "filled" | "outline";
  htmlColor?: string;
}) => {
  switch (name) {
    case "HOME-ROUTE-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <HomeFilledIcon />
      ) : (
        <HomeIcon htmlColor={htmlColor} />
      );
    case "SERVICE-DESK-ROUTE-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <SDFilledIcon />
      ) : (
        <SDIcon htmlColor={htmlColor} />
      );
    case "CUSTOMERS-ROUTE-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <CustomersFilledIcon />
      ) : (
        <CustomersIcon htmlColor={htmlColor} />
      );
    case "CONTROL-CENTER-ROUTE-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <ControlcenterFilledIcon />
      ) : (
        <ControlcenterIcon htmlColor={htmlColor} />
      );

    // disabled

    case "REMOTE-CONTROL-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <RMMFilledIcon />
      ) : (
        <RMMIcon htmlColor={htmlColor} />
      );
    case "DOCUMENTATION-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <DocumentationFilledIcon />
      ) : (
        <DocumentationIcon htmlColor={htmlColor} />
      );
    case "ASSET-MANAGEMENT-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <DevicemanagementFilledIcon />
      ) : (
        <DevicemanagementIcon htmlColor={htmlColor} />
      );
    case "BILLING-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <BillingInvoiceFilledIcon />
      ) : (
        <BillingInvoiceIcon htmlColor={htmlColor} />
      );
    case "REPORTS-ROUTER-CONFIG-KEY":
      return type === "filled" ? (
        <ReportsFilledIcon />
      ) : (
        <ReportsIcon htmlColor={htmlColor} />
      );

    default:
      return type === "filled" ? (
        <HomeFilledIcon htmlColor={htmlColor} />
      ) : (
        <HomeIcon htmlColor={htmlColor} />
      );
  }
};
