import React from "react";
import PropTypes from "prop-types";

// Legacy Imports
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CustomBox } from "../../layers";
import { CustomTypography, CustomIconButton, CustomButton } from "../../base";

const CustomModal = (props) => {
  return (
    <React.Fragment>
      <Dialog
        open={props?.open}
        onClose={props?.closeHandle}
        fullWidth={true}
        // TransitionComponent={Slide}
        // TransitionProps={{ direction: "left" }}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { borderRadius: "24px" } }}
      >
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomTypography
              size={"MEDIUM-ALPHA"}
              color={"PRIMARY-MAIN"}
              variant={"BOLD"}
              content={props?.modalTitle}
            />
            {props?.edit === true ? (
              <CustomIconButton
                icon={"customDocumentEditIcon"}
                value={"edit"}
                disabled={false}
                handle={() => {}}
              />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              margin: 0,
              padding: 0,
              mt: { xs: "16px", md: "24px", xl: "32px" },
            }}
          >
            {props?.modalBody}
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: "20px", mb: "20px" }}>
          <CustomButton endIcon={false} label={"Save"} handle={() => {}} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.element.isRequired,
  modalFooter: PropTypes.element.isRequired,
  closeHandle: PropTypes.func.isRequired,
};

CustomModal.defaultProps = {
  open: false,
  edit: false,
  modalTitle: "",
  modalBody: <></>,
  modalFooter: <></>,
  closeHandle: () => {},
};

export default CustomModal;
