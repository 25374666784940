import { Stack, Typography, useTheme } from "@mui/material";
import { Button, Modal } from "components";
import { deleteSampleData, useGlobalStore } from "controller";
import React from "react";
import { debugConsole } from "utilz/helpers";
// @ts-ignore
import CancelImage from "assets/images/cancelSample.svg";
import { useLocation } from "react-router-dom";

export const ClearSampleDataButton = () => {
  const theme = useTheme();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const sampleDataStatus = useGlobalStore((state) => state.sampleDataStatus);

  const handleClearSampleData = async () => {
    try {
      await deleteSampleData();
    } catch (error) {
      debugConsole(error);
    }
    window.location.href = `${window.location.origin}/app/home`;
  };
  if (
    sampleDataStatus !== "processed" ||
    location.pathname !== "/app/control-center"
  )
    return null;
  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        variant="secondary"
        size="small"
      >
        Clear Sample Data
      </Button>
      {isModalOpen && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          colseable
          headerStyles={{
            borderBottom: `1px solid ${theme.palette.neutral["010"]}`,
          }}
          footer={<></>}
          maxWidth="sm"
          onPrimaryButtonClick={handleClearSampleData}
          onSecondaryButtonClick={() => {
            setIsModalOpen(false);
          }}
          title="Clear sample data"
          open={isModalOpen}
          paperStyles={{
            width: "768px",
            height: "440px",
            borderRadius: "24px",
          }}
        >
          <Stack
            gap={"12px"}
            direction="row"
            // sx={{ borderTop: `1px solid ${theme.palette.neutral["010"]}` }}
          >
            <Stack sx={{ width: "50%", bgcolor: "#E7E9FE" }}>
              <img src={CancelImage} alt="cancel" />
            </Stack>
            <Stack
              p={"32px"}
              sx={{ width: "50%", justifyContent: "space-between" }}
            >
              <Stack gap={"24px"} pb={"32px"}>
                <Typography
                  variant="body-medium"
                  color={theme.palette.lightBg.low}
                >
                  We hope you've been enjoying exploring our product with the
                  handy sample data we provided.
                </Typography>
                <Typography
                  variant="body-medium"
                  color={theme.palette.lightBg.low}
                >
                  But if you ever want to start with a clean slate, you can
                  click the "Clear Sample Data" button below. However, once it's
                  gone, it's gone! We won't be able to load it again.
                </Typography>
                <Typography
                  variant="body-medium"
                  color={theme.palette.lightBg.low}
                >
                  Are you sure you want to clear the sample data?
                </Typography>
              </Stack>

              <Stack direction={"row"} gap={"16px"} justifySelf={"flex-end"}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    bgcolor: theme.palette.error.main,
                    "&:hover": { backgroundColor: "#A9050E" },
                  }}
                  variant="primary"
                  fullWidth
                  onClick={handleClearSampleData}
                >
                  Clear Sample Data
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      )}
    </>
  );
};
