import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export const ControlCenterLayout = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Outlet />
    </Box>
  );
};
