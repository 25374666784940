import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { BottomStickyLayer } from "../../../../../../../../library/layers";
import { PaginationControls } from "../../../../../../../../library/mounts";
import { useGlobalStore } from "controller/store/globalStore";
import { useCreateAttributeTag, useGetAttributeTags } from "controller";
import toast from "react-hot-toast";
import { PlusIcon } from "components";
import TagsTableTile from "./TagsTableTile";
import { CreateTagTile } from "./CreateTagTile";

export const TagsTab = () => {
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    isOpen: false,
    currentPage: 1,
  });

  const { data: tagData } = useGetAttributeTags({
    filter: [],
    sortDirection: "asc",
    sortColumn: "id",
    perPageCount: 10,
    pageNumber: state.currentPage,
  });

  const { mutateAsync: createAttributeTag } = useCreateAttributeTag();

  const createTag = (data: any) => {
    createAttributeTag(data)
      .then((response) => {
        toast.success("Created successfully");
        setState({
          ...state,
          isOpen: false,
          currentPage: 1,
        });
      })
      .catch((error) => {
        toast.error("Unable to create");
      });
  };

  return (
    <Stack
      sx={{
        p: "32px",
      }}
    >
      <Box pb={"56px"}>
        <Stack
          sx={{
            height: "calc(100vh - 330px)",
            width: "100%",
            border: "1px solid #cccccc",
            borderRadius: "24px",
            overflow: "clip",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              px: "24px",
              py: "24px",
              borderBottom: "1px solid #cccccc",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography variant="h6">Tags</Typography>
            </Box>
            <Box
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setState({
                  ...state,
                  isOpen: !state.isOpen,
                });
              }}
            >
              <PlusIcon />
            </Box>
          </Stack>
          {state.isOpen === true ? (
            <CreateTagTile
              data=""
              onSubmit={createTag}
              onCancel={() => {
                setState({
                  ...state,
                  isOpen: false,
                });
              }}
            />
          ) : null}
          <Stack
            sx={{
              height: "100%",
              overflow: "scroll",
            }}
          >
            {(Array.isArray(tagData?.result) ? tagData.result : []).map(
              (item: any) => (
                <TagsTableTile
                  key={item.mapping_id || ""}
                  data={item}
                  isEditable={item.msp_id !== null}
                />
              )
            )}
          </Stack>
        </Stack>
      </Box>
      <BottomStickyLayer
        content={
          <PaginationControls
            currentPage={state.currentPage}
            getNewPage={(newPageNumber: number) =>
              setState((oldData) => ({
                ...oldData,
                currentPage: newPageNumber,
              }))
            }
            totalPages={tagData?.totalPages || 0}
          />
        }
      />
    </Stack>
  );
};
