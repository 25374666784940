import { ReactNode, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { Vertical3DotsIcon } from "components";

type DropDownMenuProps = Omit<MenuProps, "open">;
export const DropdownMenu = ({
  rowData,
  options,
  onOptionClick,
  sx,
  children,
  menuListSx,
  paperSx,
  menuProps,
  menuListItemSx,
}: {
  rowData?: any;
  options: { name?: string; value?: string; type?: string; icon?: any }[];
  onOptionClick?: (optionValue: {
    name?: string;
    value?: string;
    type?: string;
  }) => void;
  sx?: SxProps<Theme>;
  menuListSx?: SxProps<Theme>;
  menuListItemSx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
  children: ReactNode;
  menuProps?: DropDownMenuProps;
}) => {
  const theme = useTheme();
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleClickAway = (e: any, option: any) => {
    e.stopPropagation();
    setOptionsAnchorEl(null);
    onOptionClick && onOptionClick(option);
  };

  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          setOptionsAnchorEl(e.currentTarget);
        }}
        sx={sx}
      >
        {children ? (
          children
        ) : (
          <IconButton>
            <Vertical3DotsIcon />
          </IconButton>
        )}
      </Box>

      <Menu
        open={Boolean(optionsAnchorEl)}
        anchorEl={optionsAnchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            minWidth: "175px",
            bgcolor: "#FFF",
            borderRadius: "8px",
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.06), 0px 0px 2px 0px rgba(0, 0, 0, 0.04)",
            border: `1px solid ${theme.palette.neutral["015"]}`,
            ...paperSx,
          },
        }}
        MenuListProps={{
          sx: {
            ...theme.typography["body-small"],
            width: optionsAnchorEl && optionsAnchorEl.offsetWidth,
            ...menuListSx,
          },
        }}
        {...menuProps}
      >
        {options?.map((option) => (
          <MenuItem
            key={option?.type}
            sx={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              textTransform: "capitalize",
              gap: "8px",
              ":hover": {
                bgcolor: theme.palette.neutral["B-005"],
              },
              ...menuListItemSx,
              //   borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              // option?.setState((prev: any) => ({
              //   ...prev,
              //   isOpen: true,
              //   data: rowData,
              // }));
              handleClickAway(e, option);
            }}
          >
            {option?.icon}
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
