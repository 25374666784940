import { Box, Divider, Typography } from "@mui/material";
import { Input, Modal, RadioButtonGroup } from "components";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import toast from "react-hot-toast";
import { useCreateTaxConfig, useUpdateTaxConfig } from "controller";
import CustomCheckBox from "library/base/custom-checkbox";
// import { CustomCheckBox } from "library/system/custom-form";

const schema = yup.object({
  tax_name: yup.string().required("Tax label is required"),
  tax_rate: yup
    .number()
    .typeError("Tax rate must be a number")
    .required("Tax rate is required")
    .min(0, "Tax rate must be 0 or higher")
    .max(100, "Tax rate should not exceed 100"),
  is_percentage: yup.boolean().required("Required"),
});

export const TaxCreatModal = ({
  openModal,
  data,
  mode,
  setIsAddModalOpen,
  taxAddOnOptionList,
  setTaxAddOnOptionList,
  setLatestTaxCreated,
}: any) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      tax_name: data?.tax_label || "",
      tax_rate: data?.tax_rate || undefined,
      is_percentage: data?.is_percentage || true,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (mode?.data) {
      setValue("tax_name", mode?.data?.tax_name);
      setValue("tax_rate", mode?.data?.tax_rate);
      setValue("is_percentage", mode?.data?.is_percentage);
    } else {
      reset();
    }
  }, [mode?.data, setValue]);

  const { mutateAsync: createTaxConfig, isLoading } = useCreateTaxConfig();
  const [checked, setChecked] = useState(false);

  const createTax = handleSubmit(
    async ({ tax_name, tax_rate, is_percentage }) => {
      const taxRate = Math.trunc(Number((tax_rate * 100).toFixed(2)));
      try {
        let res = await createTaxConfig({
          tax_name,
          tax_rate: taxRate,
          is_percentage,
        });
        setLatestTaxCreated(res?.[0]?.mapping_id);
        toast.success("Created successfully");
        setIsAddModalOpen(false);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  );

  const handleClick = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setIsAddModalOpen(false)}
        additionalHeaderComponent={<Divider />}
        title={"Add Tax"}
        maxWidth="sm"
        primaryButtonText={"Save"}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={createTax}
        onSecondaryButtonClick={() => setIsAddModalOpen(false)}
      >
        <Box
          sx={{
            padding: "24px 24px 0px 24px",
            display: "flex",
            flexDirection: "row",
            gap: "15px",
          }}
        >
          <Input
            label={"Tax label"}
            name="tax_name"
            control={control}
            error={errors?.tax_name ? true : false}
            isResponsive={false}
            // @ts-ignore
            errorMessage={errors?.tax_name?.message || ""}
            sx={{ flex: 2 }}
            inputProps={{
              maxLength: 20,
            }}
          />
          <Input
            type="number"
            label={"Tax Rate"}
            name="tax_rate"
            control={control}
            error={errors?.tax_rate ? true : false}
            isResponsive={false}
            // @ts-ignore
            errorMessage={errors?.tax_rate?.message || ""}
            sx={{ flex: 1 }}
            inputProps={{
              step: 0.01,
              min: 0,
              max: 100,
              maxLength: 3,
            }}
          />
        </Box>
        <Box
          sx={{
            padding: "0px 24px 24px 24px",
          }}
        >
          {/* <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CustomCheckBox checked={checked} onChange={handleClick} />
            <Typography variant="body-medium" marginLeft={"10px"}>
              Would you like to add this tax to configuration?
            </Typography>
          </Box> */}
        </Box>
        <Divider />
      </Modal>
    </>
  );
};
