import { AxiosResponse } from "axios";
import { sentryLogger } from "utilz/logger/sentryLogger";
import * as yup from "yup";
export const validateApiResponse = <T>({
  response,
  schema,
}: {
  response: AxiosResponse;
  schema: yup.Schema<T>;
}): T => {
  try {
    // Attempt to parse the response data with the yup schema
    return schema.validateSync(response?.data?.data);
  } catch (error) {
    console.log("🚀 ~ validateApiResponse error:", error);
    sentryLogger({
      error: {
        message: `${error}`,
        status: 422,
        traceId: response?.headers["x-request-id"] || "unknown",
      },
      data: error,
      workflow: "validator",
    });
    return response?.data?.data as T;
  }
};
