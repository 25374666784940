import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { DownArrowIcon } from "components";
import React from "react";

interface AccordianProps {
  icon?: React.ReactNode;
  iconStyles?: SxProps<Theme>;
  title?: string;
  titleContent?: React.ReactNode;
  accordianContainerStyles?: SxProps<Theme>;
  accordianSummaryStyles?: SxProps<Theme>;
  accordianDetailsStyles?: SxProps<Theme>;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  expandIcon?: React.ReactNode;
  expanded?: boolean;
  onChange?: (event: React.ChangeEvent<any>, expanded: boolean) => void;
}
export const Accordian = ({
  title,
  titleContent,
  icon,
  children,
  iconStyles,
  accordianContainerStyles,
  accordianSummaryStyles,
  accordianDetailsStyles,
  defaultExpanded = false,
  expandIcon,
  expanded,
  onChange,
}: AccordianProps) => {
  const theme = useTheme();
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      square
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      sx={{
        "&.Mui-expanded": {
          bgcolor: "#FFF",
        },
        borderBottom: `1px solid ${theme.palette.neutral["010"]}`,
        px: "16px",
        ...accordianContainerStyles,
      }}
    >
      <AccordionSummary
        expandIcon={
          expandIcon ? expandIcon : <DownArrowIcon fontSize="small" />
        }
        sx={{ minHeight: "48px", padding: "0px", ...accordianSummaryStyles }}
      >
        <Stack direction="row" alignItems="center" gap="8px" width="100%">
          {icon && (
            <Box
              sx={{
                borderRadius: "100%",
                width: "32px",
                height: "32px",
                backgroundColor: "#E7E9FE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...iconStyles,
              }}
            >
              {icon}
            </Box>
          )}
          {titleContent ? (
            titleContent
          ) : (
            <Typography variant="body-large" textTransform={"capitalize"}>
              {title}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{ paddingX: "0px", py: "8px", ...accordianDetailsStyles }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
