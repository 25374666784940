import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import {
  Avatar,
  Drawer,
  PlayStopButton,
  Tab,
  Tabs,
  TimeCounter,
} from "components";
import { useContext, useEffect, useMemo, useState } from "react";
import { WatchListItem } from "./WatchListItem";
import {
  useGetWatchList,
  useMainStore,
  useNonPersistantMainStore,
  useTimeEntryStore,
} from "controller";
import { ActiveTimeEntry } from "shared";
import { MqttContext } from "controller/context/MqttCreateContext";
import customerSurveyImg from "assets/images/chartImgs/customerSurveyImg.svg";
interface WatchListDrawerProps {
  open: boolean;
  onClose: () => void;
}
export const WatchListDrawer = ({ open, onClose }: WatchListDrawerProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const watchlist = useNonPersistantMainStore((state) => state.watchListData);
  // time entry with timercounter
  const timeEntriesDetails = useTimeEntryStore(
    (state) => state.activeTimeEntriesDetails
  );
  //active time entry with minimum required details(without timer)
  const timeEntries = useTimeEntryStore((state) => state.activeTimeEntries);
  // const [serviceTimeEntry,setServiceTimeEntry]=useState<Array<any>>([])
  // const [serviceWatchList,setSelectedWatchList]=useState<Array<any>>([])

  const serviceTimeEntry = useMemo(() => {
    return timeEntriesDetails
      ?.filter((item: any) => item.service_type === tabs[selectedTab].key)
      .map((item: any) => ({
        ...item,
        isWatchListed: Boolean(
          watchlist.find(
            (watchListItem: any) => watchListItem.service_id === item.service_id
          )
        ),
      }));
  }, [timeEntriesDetails, watchlist, selectedTab]);

  const serviceWatchList = useMemo(() => {
    return watchlist?.filter((item: any) => {
      return (
        item.service_type === tabs[selectedTab].key &&
        !timeEntries[item.service_id]
      );
    });
  }, [timeEntries, watchlist, selectedTab]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      drawerStyle={{ width: "100vw", maxWidth: "400px" }}
      title="Watchlist"
      additionalHeaderComponent={
        <Tabs
          sx={{ paddingLeft: "24px", height: "44px" }}
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          {tabs.map((item) => (
            <Tab key={item.key} label={item.title} />
          ))}
        </Tabs>
      }
      footer={<></>}
    >
      <Stack minHeight={"100%"}>
        {serviceTimeEntry.length <= 0 && serviceWatchList.length <= 0 ? (
          <Stack
            flex={1}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <img
              src={customerSurveyImg}
              alt=""
              width={"100%"}
              height={"100%"}
              style={{
                objectFit: "contain",
                maxHeight: "170px",
                maxWidth: "170px",
              }}
            />
            <Typography variant="button-small">
              Your watchlist is empty. No {tabs[selectedTab].key}s yet.
            </Typography>
          </Stack>
        ) : (
          <Stack divider={<Divider flexItem />}>
            <Stack>
              {serviceTimeEntry.map((item: any) => (
                <WatchListItem
                  key={item.mapping_id}
                  data={item}
                  isTimeEntry={true}
                  close={onClose}
                />
              ))}
            </Stack>
            <Stack>
              {serviceWatchList
                ?.filter(
                  (item: any) => item.service_type === tabs[selectedTab].key
                )
                .map((item: any) => (
                  <WatchListItem key={item.id} data={item} close={onClose} />
                ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};

const tabs = [
  {
    title: "Tickets",
    key: "ticket",
  },
  {
    title: "Tasks",
    key: "task",
  },
  {
    title: "Projects",
    key: "project",
  },
];
