import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getQaRules, updateServiceDeskQaRules } from "controller/api";
import { ApiErrorType, UpdateQaRulesInput } from "shared";
import { sentryLogger } from "utilz";

export const useGetQaRules = () => {
  return useQuery({
    queryKey: ["qaRules"],
    queryFn: () => getQaRules(),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getQaRules",
      }),
  });
};

export const useServiceDeskUpdateQaRules = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateQaRulesInput) => updateServiceDeskQaRules(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["qaRules"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateServiceDeskQaRules",
        });
      },
    }
  );
};
