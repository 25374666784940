import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import Doughnut from "assets/images/doughnut.png";
import { CheckCircleIcon, CheckCircleOutlinedIcon, InfoIcon } from "components";
// @ts-ignore
import SubscriptionOffer from "assets/images/subscriptionPageBanner.webp";
import SubscriptionIllustration from "assets/images/subscriptionIllustration.png";
import SubscriptionIllustrationBg from "assets/images/subscriptionIllustrationBg.png";

const Features = [
  "Multi-Channel Conversational Ticketing",
  "Microsoft Teams App for your Customers",
  "Mobile App for your Customers",
  "Customer SSO integration for auto user importing",
  "Project Management",
  "Timesheets",
  "Invoicing",
  "Reports",
  "Announcement",
  "Service Desk Quality Assurance Dashboard",
];

export const PlanDetailsCard = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary["purple-tint-3"],
      }}
    >
      <Stack p="32px" gap="24px">
        <Typography variant="h3">Simple Pricing for your business</Typography>
        <Typography variant="h5">Access these features</Typography>
        <Stack gap="20px">
          {Features.map((feature) => (
            <Stack
              key={feature}
              gap="12px"
              direction="row"
              alignItems={"center"}
              ml="-8px"
            >
              <CheckCircleIcon
                color="primary"
                fontSize="small"
                sx={{
                  width: "24px",
                  height: "24px",
                  aspectRatio: "1",
                }}
              />
              <Typography
                variant="body-medium"
                color={theme.palette.lightBg.medium}
              >
                {feature}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
