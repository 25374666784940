import MuiLink from "@mui/material/Link";
import PropTypes from "prop-types";
import defaultSizes from "./constants/defaultSizes";
import { styled } from "@mui/material/styles";
import colorPalette from "../../common/colors";
function Link(props) {

  return (
    <MuiLink
      onClick={props.onClick}
      sx={{
        fontSize: defaultSizes,
        color: colorPalette.sematics.lightBg.info,
        underline: "always",
        "&.MuiTypography-root": {
          fontWeight: "500"
        }
      }}
      href={props.href}
      style={{

      }}>

      {props.text}
    </MuiLink>
  );
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Link.defaultProps = {
  href: "",
  onClick: () => { },
};

export default Link;