import React, { useState, useEffect } from "react";
import { services } from "services";

import { ComingSoonPage } from "components";

import { Helmet } from "react-helmet";

// constants

export const WhiteLabelPage = () => {
  // Renderer
  return (
    <>
      <Helmet>
        <title>DeskDay | Branding</title>
      </Helmet>
      <ComingSoonPage page="branding" />
    </>
  );
};
