import React, { useState, useEffect } from "react";

import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// HOC Imports
import CustomIcon from "../../../icon-library";
import CheckBoxUncheckedIcon from "../../../icon-library/icons/checkBoxUncheckedIcon";
import CheckBoxCheckedIcon from "../../../icon-library/icons/checkBoxCheckedIcon";
import { CustomTypography } from "../../../base";

// Constants Import 
import colorPalette from "../../../common/colors";
import defaultSize from "./constants/defaultSizes";

function CustomCheckBox(props) {
  // Globals
  const size = defaultSize[props.size] ?? defaultSize.md;

  // State
  const [value, setValue] = useState(false);

  // Effects
  useEffect(() => {
    try {
      setValue(props?.checked);
    } catch (err) {
      console.log("[ERROR] Mounting Custom Checkbox Component");
      console.log(err);
    }
  }, [props]);

  // Handlers
  const handleChange = (event) => {
    const newValue = event.target.checked;
    setValue(newValue);
    props.changeHandler(newValue);
  };

  return (
    <Stack
      direction={"row"}
      spacing={{ sm: "8px", md: "12px" }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
      }}
    >
      <Checkbox
        checked={value}
        icon={<CheckBoxUncheckedIcon  {...props} />}
        checkedIcon={<CheckBoxCheckedIcon {...props} />}
        disabled={props.disabled}
        disableRipple
        sx={{
          height: {
            xs: defaultSize.sm,
            sm: defaultSize.sm,
            md: defaultSize.md,
            lg: defaultSize.lg,
            xl: defaultSize.xl,
          },
          width: {
            xs: defaultSize.sm,
            sm: defaultSize.sm,
            md: defaultSize.md,
            lg: defaultSize.lg,
            xl: defaultSize.xl,
          },
          padding: "3px",

        }}
        onChange={handleChange}
      />
      {
        props?.displayLabel === true ?
          <CustomTypography
            size={"SMALL-ALPHA"}
            color={"TERITARY-MAIN"}
            variant={"REGULAR"}
            content={props?.label || ""}
          />
          :
          ""
      }
    </Stack>

  );
}

CustomCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  displayLabel: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

CustomCheckBox.defaultProps = {
  checked: false,
  disabled: false,
  label: "",
  displayLabel: false,
  changeHandler: () => { },
};


export default CustomCheckBox;