import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Button,
  DeleteConfirmationModal,
  DeleteIcon,
  Drawer,
  Input,
  Modal,
  SelectField,
} from "components";
import {
  useCancelSubscription,
  useDeleteCard,
  useGetActiveResourceCount,
  useGetAllCountries,
  useGetAllStateByCountryId,
  useGetCards,
  useGetSetupIntent,
  useGlobalStore,
  useSubscriptionDetails,
  useUpdateMspBilling,
} from "controller";
import React, { useEffect, useState } from "react";
import { dayjs } from "utilz";

import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { AddPaymentMethod } from "./AddPaymentMethod";

import { PayNowCard } from "../create-subscription/PayNowCard";
import { PaymentDetailsCard } from "./PaymentDetailsCard";
import { debugConsole } from "utilz/helpers";

const schema = yup.object({
  // billingName: yup.string().required(),
  billingCountry: yup.number().required("Billing country is required"),
  billingState: yup.number().nullable(),
  billingPostalCode: yup.string().nullable(),
  billingAddressLine1: yup.string().required("Billing address is required"),
  billingAddressLine2: yup.string().nullable(),
});
export const OverviewTab = () => {
  const planAmount = 49;
  const subscriptionStatus = useGlobalStore(
    (state) => state.subscriptionStatus
  );
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { mutateAsync: cancelSubscription } = useCancelSubscription();

  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false);
  const {
    data: subscriptionDetails,
    refetch: refetchSubscriptionDetails,
    isLoading: isSubsLoading,
  } = useSubscriptionDetails();
  const { data: activeResourceCount } = useGetActiveResourceCount();
  const { data: countries } = useGetAllCountries();
  const { mutateAsync: updateBilling } = useUpdateMspBilling();
  const currentAccessBlockDate = useGlobalStore(
    (state) => state.currentAccessBlockDate
  );
  const dateFormat = useGlobalStore((state) => state.dateFormat);

  const mspId = useGlobalStore((state) => state.mspId);
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      // billingName: "",
      billingCountry: 0,
      billingState: 0,
      billingPostalCode: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
    },
    resolver: yupResolver(schema),
  });

  const countryCode = useWatch({ control, name: "billingCountry" });
  const { data: state } = useGetAllStateByCountryId(`${countryCode}` || "");

  useEffect(() => {
    if (subscriptionDetails) {
      // setFormValue("billingName", subscriptionDetails.billing_name);
      setFormValue("billingCountry", subscriptionDetails.billing_country);
      setFormValue("billingState", subscriptionDetails.billing_state);
      setFormValue(
        "billingPostalCode",
        subscriptionDetails.billing_postal_code
      );
      setFormValue("billingAddressLine1", subscriptionDetails.line1);
      setFormValue("billingAddressLine2", subscriptionDetails.line2);
    }
  }, [subscriptionDetails]);

  const onSubmitHandler = handleSubmit(
    async ({
      // billingName,
      billingAddressLine1,
      billingAddressLine2,
      billingCountry,
      billingPostalCode,
      billingState,
    }) => {
      await updateBilling({
        mspId: mspId || "",
        data: {
          billingAddressLine1,
          billingAddressLine2,
          billingCountry,
          // billingName,
          billingZipCode: billingPostalCode,
          billingState,
        },
      });
      toast.success("Billing address updated successfully");
      reset(undefined, { keepValues: true, keepDirty: false });
      refetchSubscriptionDetails();
    }
  );

  const handleSubscriptionCancel = async () => {
    try {
      await cancelSubscription();
      toast.success("Your subscription is cancelled!");
      setIsCancelSubscriptionModalOpen(false);
      refetchSubscriptionDetails();
      window.location.reload();
    } catch (error) {}
  };
  // TODO: add loader
  if (isSubsLoading) {
    return <></>;
  }
  return (
    <Stack sx={{ width: "90%", padding: "32px" }}>
      {subscriptionDetails?.is_custom_plan && (
        <Stack
          direction={"row"}
          sx={{
            borderRadius: "8px",
            border: `1px solid ${theme.palette.neutral["015"]}`,
            padding: "12px 22px",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "12px",
          }}
        >
          <Typography variant="subtitle1">
            You are currently on our customised annual plan. To manage license,
            please contact our support team.
          </Typography>
          <Button
            variant="secondary"
            isResponsive={false}
            size="small"
            onClick={() => {
              try {
                // @ts-ignore
                window.Intercom("show");
              } catch (error) {
                debugConsole(error);
              }
            }}
          >
            Contact Sales
          </Button>
        </Stack>
      )}

      <Stack
        direction="row"
        sx={{
          flex: 1,
          gap: "0px",
          borderRadius: "16px",
          backgroundColor: theme.palette.neutral["005"],
        }}
        // divider={<Divider orientation="vertical" flexItem />}
      >
        {/* column 1 */}
        <Stack
          direction={"row"}
          sx={{
            padding: "24px",
            width: "60%",
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ flex: 1 }}>
            <Stack direction={"row"} gap={"8px"} alignItems={"baseline"}>
              {/* <Typography
                variant={"h6"}
                sx={{
                  color: "#FFF",
                  padding: "2px 6px",
                  bgcolor: theme.palette.purple.main,
                  borderRadius: "4px",
                }}
              >
                Beta
              </Typography> */}
              <Typography variant="h6">
                {subscriptionDetails?.plan_name}
              </Typography>
            </Stack>

            <Typography
              mt={"32px"}
              variant="body-medium"
              color={theme.palette.lightBg.medium}
            >
              {activeResourceCount} of {subscriptionDetails?.seat_quantity}{" "}
              Active{" "}
              {subscriptionDetails?.seat_quantity > 1
                ? "Resources"
                : "Resource"}
            </Typography>
            <LinearProgress
              sx={{ mt: "16px" }}
              variant="determinate"
              color="success"
              value={
                (activeResourceCount / subscriptionDetails?.seat_quantity) * 100
              }
            />
            <Stack direction={"row"}>
              {!subscriptionDetails?.is_custom_plan && (
                <Button
                  fullWidth={false}
                  onClick={() => setOpen(true)}
                  variant="ghost"
                  sx={{
                    px: 0,
                    minWidth: 0,
                  }}
                >
                  Manage
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack
            padding={"12px"}
            alignItems={"flex-end"}
            sx={{
              borderRight: `1px solid ${theme.palette.neutral["020"]}`,
              paddingRight: "36px",
              borderRightStyle: "dashed",
            }}
          >
            {!isSubsLoading ? (
              <Typography variant="h1">
                $
                {(
                  (subscriptionDetails?.price / 100) *
                  ((100 - subscriptionDetails?.percent_off) / 100)
                ).toFixed(2)}
              </Typography>
            ) : (
              <Skeleton width={"80px"} height={"60px"}></Skeleton>
            )}

            {subscriptionDetails?.percent_off > 0 && (
              <Typography
                variant="h6"
                color={theme.palette.lightBg.medium}
                sx={{ textDecoration: "line-through" }}
              >
                ${subscriptionDetails?.price / 100}
              </Typography>
            )}

            <Typography variant="body-medium" color={theme.palette.lightBg.low}>
              {subscriptionDetails?.recurring_interval}/tech
            </Typography>
          </Stack>
        </Stack>
        {/* column 2 */}

        <Stack
          sx={{
            width: "40%",
            bgcolor: theme.palette.neutral["005"],
            borderRadius: "16px",
            padding: "32px",
            height: "100%",
          }}
        >
          {subscriptionStatus !== "past_due" ? (
            <Stack sx={{ gap: "24px" }}>
              <Typography variant="h6" color={theme.palette.lightBg.medium}>
                Next Billing
              </Typography>
              <Typography
                variant="body-large"
                color={theme.palette.lightBg.medium}
              >
                You will be charged{" "}
                <Typography
                  component={"span"}
                  variant="body-large"
                  sx={{ fontWeight: 700 }}
                  color={theme.palette.lightBg.medium}
                >
                  {" "}
                  $
                  {(
                    Number(subscriptionDetails?.seat_quantity) *
                    (subscriptionDetails?.price / 100) *
                    ((100 - Number(subscriptionDetails?.percent_off)) / 100)
                  ).toFixed(2)}
                </Typography>{" "}
                on{" "}
                <Typography
                  component={"span"}
                  variant="body-large"
                  sx={{ fontWeight: 700 }}
                  color={theme.palette.lightBg.medium}
                >
                  {dayjs(subscriptionDetails?.current_period_end).format(
                    "DD MMMM, YYYY"
                  )}
                </Typography>
              </Typography>
            </Stack>
          ) : (
            <Stack sx={{ gap: "24px" }}>
              <Typography variant="h6" color={theme.palette.error.main}>
                Payment Unsuccessful
              </Typography>
              <Stack>
                <Typography
                  variant="body-large"
                  color={theme.palette.lightBg.medium}
                >
                  Your next payment retry date is on{" "}
                  <Typography
                    component={"span"}
                    variant="body-large"
                    sx={{ fontWeight: 700 }}
                    color={theme.palette.lightBg.medium}
                  >
                    {dayjs(subscriptionDetails?.next_payment_attempt).format(
                      "DD MMMM, YYYY"
                    )}
                  </Typography>
                  .
                </Typography>
                <Typography
                  variant="body-large"
                  color={theme.palette.lightBg.medium}
                >
                  Please update your card details
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack direction="row" sx={{ flex: 1, mt: "24px" }}>
        {/* billing */}
        <Stack
          sx={{
            width: "60%",
            gap: "24px",
            paddingRight: "32px",
            height: "max-content",
          }}
        >
          <Typography variant="h6">Billing Address</Typography>
          <Stack
            direction={"column"}
            sx={{
              padding: "24px",
              flex: 1,
              borderRadius: "24px",
              border: `1px solid ${theme.palette.neutral["015"]}`,
              gap: "36px",
              justifyContent: "space-between",
            }}
          >
            <Stack sx={{ maxWidth: "80%", gap: "20px" }}>
              <Input
                required
                name="billingAddressLine1"
                label="Address line 1"
                control={control}
                error={!!errors.billingAddressLine1}
                errorMessage={errors.billingAddressLine1?.message}
              />
              <Input
                name="billingAddressLine2"
                label="Address line 2"
                control={control}
                error={!!errors.billingAddressLine2}
              />

              <SelectField
                required
                options={countries}
                labelMappingKey="name"
                valueMappingKey="id"
                name="billingCountry"
                label="Country"
                isSearchable
                control={control}
                error={!!errors.billingCountry}
                errorMessage={errors.billingCountry?.message}
              />
              <SelectField
                options={state}
                labelMappingKey="state_name"
                valueMappingKey="id"
                name="billingState"
                label="State"
                isSearchable
                control={control}
                error={!!errors.billingState}
              />
              <Input
                name="billingPostalCode"
                label="Zip code"
                control={control}
                error={!!errors.billingPostalCode}
              />
            </Stack>
            {isDirty && (
              <Stack direction={"row"} gap={"24px"} justifyContent={"flex-end"}>
                <Button onClick={onSubmitHandler}>Save</Button>
              </Stack>
            )}
          </Stack>
        </Stack>

        {/* payment details */}
        <Stack sx={{ width: "40%" }}>
          <PaymentDetailsCard defaultCard={subscriptionDetails?.source || ""} />
        </Stack>
      </Stack>
      <Stack direction={"row"}>
        <Button
          variant="secondary"
          sx={{ mt: "24px" }}
          onClick={() => setIsCancelSubscriptionModalOpen(true)}
        >
          Cancel Subscription
        </Button>
      </Stack>

      {isCancelSubscriptionModalOpen && (
        <DeleteConfirmationModal
          onClose={() => setIsCancelSubscriptionModalOpen(false)}
          onSecondaryButtonClick={() => setIsCancelSubscriptionModalOpen(false)}
          onPrimaryButtonClick={handleSubscriptionCancel}
          title="Are you sure you want to cancel?"
          paperStyles={{
            width: "520px",
          }}
          open={isCancelSubscriptionModalOpen}
          secondaryButtonText="Keep subscription"
          primaryButtonText="Cancel Subscription"
          tagline={`You'll lose access to all DeskDay features, we've kept the door open until ${dayjs(
            currentAccessBlockDate
          ).format(
            dateFormat
          )}. Feel at home and return anytime. Your ticketing is safe with us.`}
        />
      )}

      {open && (
        <Drawer
          // header={<></>}
          open={open}
          title="Manage"
          footer={<></>}
          onClose={() => setOpen(false)}
        >
          <Stack sx={{ height: "100%", width: "614px" }}>
            <PayNowCard
              type="EDIT"
              data={{
                promocode: subscriptionDetails?.promotion_code,
                seatQuantity: subscriptionDetails?.seat_quantity,
                discountPercentage: subscriptionDetails?.percent_off || "0",
                price: subscriptionDetails?.price,
                discountId: subscriptionDetails?.discount,
                planId: subscriptionDetails?.plan_id,
                recurringInterval: subscriptionDetails?.recurring_interval,
              }}
              onClose={() => setOpen(false)}
            />
          </Stack>
        </Drawer>
      )}
    </Stack>
  );
};
