import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { dayjs } from "utilz";
import React, { useEffect, useRef } from "react";
import { useGlobalStore } from "controller/store/globalStore";
import {
  useGetAllNotification,
  useUpdateNotificationStatus,
} from "controller/hooks/notification";

import { useNavigate } from "react-router-dom";
import { getFullName } from "utilz/helpers";
// @ts-ignore
import customerSurveyImg from "assets/images/chartImgs/customerSurveyImg.svg";
import { useNotificationStore } from "controller";
import { Button, CircularLoader } from "components";
import { InfiniteData } from "@tanstack/react-query";

export const NotificationList = ({
  onItemClick,
}: {
  onItemClick: () => void;
}) => {
  const scrollBoxRef = useRef(null);
  const resourceId = useGlobalStore((state) => state.resourceId);
  const setNotifications = useNotificationStore(
    (state) => state.setNotifications
  );
  const notificationData = useNotificationStore(
    (state) => state.allNotifications
  );
  const {
    data: allNotifications,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useGetAllNotification({
    resourceId: resourceId || "",
    pageNumber: 1,
    perPageCount: 50,
  });

  useEffect(() => {
    if (allNotifications) {
      handleSettingNotificationToState(allNotifications);
    }
    return () => {
      // resetNotification();
    };
  }, [allNotifications]);

  const handleSettingNotificationToState = async (
    notificationData: InfiniteData<any> | undefined
  ) => {
    if (notificationData && notificationData?.pages.length === 1) {
      setNotifications({
        value: notificationData?.pages[0].result,
        count: notificationData?.pages[0].unseenCount,
      });
    } else {
      setNotifications({
        value:
          notificationData?.pages[notificationData?.pages.length - 1].result,
        count: notificationData?.pages[0].unseenCount,
      });
    }
  };

  const handleScroll = () => {
    if (scrollBoxRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollBoxRef.current;

      if (
        scrollTop + clientHeight + 1 >= scrollHeight &&
        hasNextPage &&
        !isFetching
      ) {
        fetchNextPage();
      }
    }
  };

  return (
    <>
      <Stack
        ref={scrollBoxRef}
        height={"100%"}
        sx={{
          overflowY: "scroll",
        }}
        onScroll={handleScroll}
      >
        {!notificationData || notificationData.length <= 0 ? (
          <Stack
            flex={1}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={customerSurveyImg}
              alt=""
              width={"100%"}
              height={"100%"}
              style={{
                objectFit: "contain",
                maxHeight: "170px",
                maxWidth: "170px",
              }}
            />
            <Typography variant="button-small">
              All clear! No new notifications for you.
            </Typography>
          </Stack>
        ) : (
          <>
            {notificationData?.map((item: any, index) => (
              <NotificationCard
                index={index}
                key={item.notification_id}
                type={item.type}
                payload={item.payload}
                createdAt={item.payload.created_at}
                mappingId={item.notification_id}
                isSeen={item.is_seen}
                onClick={onItemClick}
              />
            ))}
            <Box sx={{ textAlign: "center", p: "8px" }}>
              {isFetching && <CircularLoader />}
            </Box>
          </>
        )}
      </Stack>
    </>
  );
};

export const NotificationCard = ({
  type,
  payload,
  createdAt,
  mappingId,
  isSeen,
  index,
  onClick,
}: {
  type: string;
  payload: Record<string, any>;
  createdAt: string;
  mappingId: string;
  isSeen: boolean;
  index: number;
  onClick?: () => void;
}) => {
  const setNotificationAsSeen = useNotificationStore(
    (state) => state.setNotificationAsSeen
  );
  const navigate = useNavigate();
  const timezone = useGlobalStore((state) => state.timezone);
  const theme = useTheme();
  // let message = "";
  const [message, setMessage] = React.useState("");
  const resourceId = useGlobalStore((state) => state.resourceId);
  const dateFormat = useGlobalStore((state) => state.dateFormat);
  const { mutateAsync: updateNotification } = useUpdateNotificationStatus();

  useEffect(() => {
    getMesssage(payload);
  }, [payload]);

  const checkIfAssignee = async (payload: any) => {
    return payload?.assignees?.some(
      (assignee: { assignee_id: string }) => assignee.assignee_id === resourceId
    );
  };

  const getDateFormatForTimesheetNotification = () => {
    let format = "DD MMMM";
    if (dateFormat === "MM/DD/YYYY") {
      format = "MMM DD";
    } else if (dateFormat === "YYYY/MM/DD") {
      format = "MMM DD";
    } else if (dateFormat === "DD/MM/YYYY") {
      format = "DD MMM";
    }
    return format;
  };

  const getMesssage = async (payload: any) => {
    const isAssignee = await checkIfAssignee(payload);
    switch (type) {
      case "ticket_created":
        setMessage(`New ticket created #${payload?.u_id}`);
        break;
      case "project_created":
        setMessage(`New project created #${payload?.u_id}`);
        break;
      case "task_created":
        setMessage(`New task created #${payload?.u_id}`);
        break;
      case "ticket_status_updated":
        // eslint-disable-next-line no-case-declarations
        // const assignee = payload?.assignees?.find(
        //   (assignee: { assignee_id: string }) =>
        //     assignee.assignee_id === resourceId
        // );

        if (isAssignee) {
          setMessage(
            `Your Assigned Ticket #${payload?.u_id} status changed to ${payload?.status_name}`
          );
        } else {
          setMessage(
            `Ticket #${payload?.u_id} status changed to ${payload?.status_name}`
          );
        }

        break;
      case "project_status_updated":
        // eslint-disable-next-line no-case-declarations
        const projectAssignee = payload?.assignees?.find(
          (assignee: { assignee_id: string }) =>
            assignee.assignee_id === resourceId
        );

        if (projectAssignee) {
          setMessage(
            `Your Assigned Project #${payload?.u_id} status changed to ${payload?.status_name}`
          );
        } else {
          setMessage(
            `Project #${payload?.u_id} status changed to ${payload?.status_name}`
          );
        }

        break;
      case "task_status_updated":
        // eslint-disable-next-line no-case-declarations
        const taskAssignee = payload?.assignees?.find(
          (assignee: { assignee_id: string }) =>
            assignee.assignee_id === resourceId
        );

        if (taskAssignee) {
          setMessage(
            `Your Assigned Task #${payload?.u_id} status changed to ${payload?.status_name}`
          );
        } else {
          setMessage(
            `Task #${payload?.u_id} status changed to ${payload?.status_name}`
          );
        }

        break;

      case "ticket_closed":
        isAssignee
          ? setMessage(`Your Assigned Ticket has been Closed #${payload?.u_id}`)
          : setMessage(`Ticket has been Closed #${payload?.u_id}`);
        break;
      case "project_closed":
        isAssignee
          ? setMessage(
              `Your Assigned Project has been Closed #${payload?.u_id}`
            )
          : setMessage(`Project has been Closed #${payload?.u_id}`);
        break;
      case "task_closed":
        isAssignee
          ? setMessage(`Your Assigned Task has been Closed #${payload?.u_id}`)
          : setMessage(`Task has been Closed #${payload?.u_id}`);
        break;
      case "assignee_added":
        if (payload?.assigned_resource === resourceId) {
          setMessage(`You have been assigned on ${payload?.u_id}`);
        } else {
          setMessage(
            `${getFullName(
              payload.assinged_resource_first_name,
              "",
              payload.assinged_resource_last_name
            )} has been assigned ${payload?.u_id}`
          );
        }
        break;

      case "assignee_removed":
        if (payload?.assigned_resource === resourceId) {
          setMessage(`You have been removed from ${payload?.u_id}`);
        } else {
          setMessage(`Assignee removed from to ${payload?.u_id}`);
        }
        break;

      case "schedule_added":
        if (payload?.scheduled_resource === resourceId) {
          setMessage(`You have been scheduled for ${payload?.u_id}`);
        } else {
          setMessage(
            `${getFullName(
              payload.scheduled_resource_first_name,
              "",
              payload.scheduled_resource_last_name
            )} has been scheduled for ${payload?.u_id}`
          );
        }

        break;

      case "schedule_removed":
        setMessage(`Schedule has been removed ${payload?.u_id}`);

        break;

      case "timesheet_approved":
        setMessage(
          `Your timesheet for ${dayjs(payload?.period_start).format(
            getDateFormatForTimesheetNotification()
          )} - ${dayjs(payload?.period_end).format(
            getDateFormatForTimesheetNotification()
          )} has been approved`
        );
        break;

      case "timesheet_rejected":
        setMessage(
          `Your timesheet for ${dayjs(payload?.period_start).format(
            getDateFormatForTimesheetNotification()
          )} - ${dayjs(payload?.period_end).format(
            getDateFormatForTimesheetNotification()
          )} has been rejected`
        );
        break;
      case "timesheet_submitted":
        setMessage(
          `${getFullName(
            payload?.resource_first_name,
            "",
            payload?.resource_last_name
          )} submitted timesheet from ${dayjs(payload?.period_start).format(
            getDateFormatForTimesheetNotification()
          )} - ${dayjs(payload?.period_end).format(
            getDateFormatForTimesheetNotification()
          )} for approval`
        );
        break;
      default:
        setMessage(`${payload.notification_message_resource}`);
        break;
    }
  };
  return (
    <Stack
      onClick={async () => {
        updateNotification({
          resourceId: resourceId || "",
          notificationId: mappingId,
        });
        setNotificationAsSeen(index);
        if (type.includes("timesheet")) {
          switch (type) {
            case "timesheet_approved":
              navigate(
                `/app/service-desk/timesheets/approved/details?startDate=${payload.period_start}&endDate=${payload.period_end}`
              );
              break;
            case "timesheet_rejected":
              navigate(
                `/app/service-desk/timesheets/rejected/details?startDate=${payload.period_start}&endDate=${payload.period_end}&submissionId=${payload.submission_id}`
              );
              break;
            case "timesheet_submitted":
              navigate(
                `/app/service-desk/timesheets/approvals/submitted/${payload.submission_id}/details?startDate=${payload.period_start}&endDate=${payload.period_end}`
              );
              break;
            default:
              break;
          }
        }

        switch (payload?.service_type) {
          case "task":
            navigate(
              `/app/service-desk/projects/${payload?.project_id}/task/${payload?.task_id}`
            );
            break;
          case "project":
            navigate(
              `/app/service-desk/${payload.service_type}s/${payload?.project_id}`
            );
            break;
          case "ticket":
            navigate(
              `/app/service-desk/${payload.service_type}s/${payload?.ticket_id}`
            );
            break;
          default:
            break;
        }
        onClick && onClick();
        return;
      }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap="20px"
      sx={{
        padding: "16px",
        cursor: "pointer",
        backgroundColor: !isSeen
          ? theme.palette.secondary["purple-tint-3"]
          : "transparent",
        // bgcolor: theme.palette.neutral["B-005"],
      }}
    >
      {/* <Avatar sx={{ width: "40px", height: "40px" }} fullname="A A"></Avatar> */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="body-medium"
          color={theme.palette.lightBg.low}
          //   dangerouslySetInnerHTML={{
          //     __html: message,
          //   }}
        >
          {message}
        </Typography>
        <Typography
          variant="body-small"
          color={theme.palette.lightBg.low}
          mt="8px"
        >
          {dayjs(createdAt).isToday()
            ? "Today"
            : dayjs(createdAt).format("DD MMM . hh:mm A")}{" "}
          {/* {dayjs(createdAt).tz(timezone).format("DD MMM . hh:mm A")} */}
        </Typography>
      </Box>
      <Box width={"10px"} sx={{ alignSelf: "flex-start" }}>
        {!isSeen && (
          <Box
            sx={{
              width: "6px",
              height: "6px",
              backgroundColor: theme.palette.purple.main,
              borderRadius: "100%",
            }}
          ></Box>
        )}
      </Box>
    </Stack>
  );
};
