import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CustomButton } from "../../../../../../../../library/base";
import { CustomTextField } from "../../../../../../../../library/system/custom-form";
import { Button } from "components";

interface CreateTagTileProps {
  data: string;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}
export const CreateTagTile = ({
  onCancel,
  onSubmit,
  data = "",
}: CreateTagTileProps) => {
  const [value, setValue] = useState(data);

  const onSubmitHandler = () => {
    if (value.trim().length > 0) {
      const data = {
        tag_name: value,
      };
      onSubmit(data);
    }
  };

  const onChangeHandler = (data: any) => {
    setValue(data.value);
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        justifyContent: "space-between",
        px: "24px",
        py: "24px",
        gap: "16px",
        borderBottom: "1px solid #cccccc",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "350px",
        }}
      >
        <CustomTextField
          placeholder="Enter new type"
          name={""}
          value={value || ""}
          changeHandler={onChangeHandler}
        />
      </Box>
      <Stack direction={"row"} gap={"24px"}>
        <Box
          sx={{
            flexGrow: "1",
            flexShrink: "1",
            justifyContent: "start",
            display: "flex",
          }}
        >
          <Button variant={"secondary"} onClick={onCancel}>
            Cancel
          </Button>
        </Box>
        <Box
          sx={{
            flexGrow: "1",
            flexShrink: "1",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button variant={"primary"} onClick={onSubmitHandler}>
            Save
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
