const defaultSizes = {
  sm: "16px",
  md: "20px",
  lg: "24px",
  xl: "32px",
};

const buttonDefaultSize = {
  sm: "22px",
  md: "24px",
  lg: "28px",
  xl: "32px",
};


export {
  defaultSizes,
  buttonDefaultSize
};