import { SvgIcon, SvgIconProps } from "@mui/material";

export const UnLockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#1C1E26"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M17 21H7a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z"
        clip-rule="evenodd"
      />
      <path
        stroke="#1C1E26"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M8 6a4 4 0 0 1 8 0v3m-4 8.09V14.5m.53-1.28a.75.75 0 1 1-1.06 1.06.75.75 0 0 1 1.06-1.06"
      />
    </svg>
  </SvgIcon>
);
