import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Tab, Tabs } from "components";
import React from "react";
import mobileApp from "assets/images/mobileApp.png";
import iosQr from "assets/images/iosQr.png";
import androidQr from "assets/images/androidQr.png";
export const MobileTab = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const TABS = [
    {
      label: "Android",
    },
    {
      label: "iOS",
    },
  ];
  const theme = useTheme();

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <Box
      sx={{
        width: "100%",
        overflow: "scroll",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          p: "32px",
          width: "100%",
          minWidth: "800px",
          height: "100%",
          display: "flex",
          overflow: "auto",
          gap: "24px",
        }}
      >
        <Typography variant="h4">Setup Chat-based Ticketing</Typography>
        <Box
          // height={"100%"}
          height={"fit-content"}
          width="100%"
          display="grid"
          gridTemplateColumns="4fr 6fr"
          rowGap={"16px"}
          p="40px"
          border={`1px solid ${theme.palette.neutral["015"]}`}
          borderRadius="8px"
          position="relative"
        >
          {/* haha */}
          <Stack gap="24px" paddingBottom={"32px"}>
            <Typography variant="h6">Download IT-Connect Mobile</Typography>
            <Typography variant="body-medium">
              Empower your users to raise and track issues on the go, ensuring
              uninterrupted support access even outside the office.
            </Typography>
            <Tabs onChange={handleTabChange} value={selectedTab} sx={{}}>
              {TABS.map((tab) => (
                <Tab
                  sx={{ textTransform: "unset !important" }}
                  key={`${tab.label}`}
                  label={tab.label}
                />
              ))}
            </Tabs>
            <Box
              sx={{
                width: "35%",
                display: "flex",
                minWidth: "150px",
                maxWidth: "400px",
                aspectRatio: "1",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  objectFit: "scale-down",
                }}
                src={selectedTab === 0 ? androidQr : iosQr}
              />
            </Box>
            <Typography variant="body-medium" color={theme.palette.lightBg.low}>
              Use your phone to scan the QR code and download the app.
            </Typography>
          </Stack>
          <Stack paddingBottom="2%" alignItems={"end"}>
            <img
              style={{
                width: "68%",
                height: "auto",
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
                marginLeft: "auto",
              }}
              src={mobileApp}
            />
          </Stack>
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right={"0"}
            p="12px 24px"
            bgcolor={theme.palette.secondary["purple-tint-3"]}
          >
            <Typography variant="body-medium">
              Once set up, you can also onboard your users smoothly through "SSO
              Integration"
            </Typography>
          </Box>
        </Box>
        {/* <Box
          sx={{
            flex: 1,
            justifyContent: "space-between",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "32px",
            height: "90%",
            bgcolor: "violet",
          }}
        >
          <Stack sx={{ flex: 1, gap: "16px" }}>
            <Typography variant="h6">Download IT-Connect Mobile</Typography>
            <Typography variant="body-medium" color={theme.palette.lightBg.low}>
              Empower your users to raise and track issues on the go, ensuring
              uninterrupted support access even outside the office.
            </Typography>

            <Tabs onChange={handleTabChange} value={selectedTab} sx={{}}>
              {TABS.map((tab) => (
                <Tab
                  sx={{ textTransform: "unset !important" }}
                  key={`${tab.label}`}
                  label={tab.label}
                />
              ))}
            </Tabs>
            <Box
              sx={{
                width: "35%",
                display: "flex",
                minWidth: "150px",
                maxWidth: "400px",
                aspectRatio: "1",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  objectFit: "scale-down",
                }}
                src={selectedTab === 0 ? androidQr : ios}
              />
            </Box>
            <Typography variant="body-medium" color={theme.palette.lightBg.low}>
              Use your phone to scan the QR code and download the app.
            </Typography>
          </Stack>

          <Box
            flex={1}
            height={"100%"}
            sx={{
              objectFit: "contain",
              minHeight: "400px",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
              }}
              src={mobileApp}
            />
          </Box>
        </Box> */}
      </Stack>
    </Box>
  );
};
