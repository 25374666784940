import { Box, Grid, Stack } from "@mui/material";
import CustomSelectField from "../../../../../../../../library/system/custom-form/custom-select";
import { getAvailablePriorities } from "./Utils";
import { DurationField } from "./DurationField";
import {
  Button,
  Input,
  MinusIcon,
  PlusIcon,
  SelectField,
  TimeInput,
} from "components";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

interface SlaRuleTileProps {
  isLastRow: boolean;
  onChangeHandler: (data: any) => void;
  onAction: (value: boolean) => void;
  data: Record<string, any>;
  selectedPriorities: Array<any>;
  allRules: Array<any>;
  validate: boolean;
}

export const SlaRuleTile = (props: SlaRuleTileProps) => {
  const {
    data,
    allRules,
    isLastRow,
    onAction,
    validate = false,
    onChangeHandler,
  } = props;
  const { key, data: ruleData } = data;

  const getselectedPriorities = () => {
    const selectedPriorities = [];
    for (const rules of allRules) {
      if (
        rules.key !== key &&
        rules.data.priority !== null &&
        rules.data.priority !== ""
      ) {
        selectedPriorities.push(rules.data.priority);
      }
    }

    return getAvailablePriorities(selectedPriorities);
  };

  const onChangesHandler = ({ field, value }: any) => {
    ruleData[field] = value;
    onChangeHandler(ruleData);
  };

  return (
    <ThemeProvider>
      <Stack
        width={"100%"}
        direction={"row"}
        sx={{
          px: "32px",
          pt: "24px",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={"24px"}
          sx={{
            flex: "1",
            pr: "24px",
            alignItems: "center",
          }}
        >
          <Grid item sm={3}>
            <SelectField
              fullWidth
              name="priority"
              label={"Priority"}
              value={ruleData["priority"]}
              options={getselectedPriorities()}
              labelMappingKey="label"
              valueMappingKey="value"
              onValueChange={(e: any) =>
                onChangesHandler({ field: "priority", value: e })
              }
              hideHelperBox
              error={
                validate &&
                (ruleData["priority"] == null || ruleData["priority"] === "")
              }
            />
          </Grid>

          <Grid item sm={2.5}>
            <DurationField
              label="First Response"
              duration={ruleData["first_response"]}
              onChange={(e: any) =>
                onChangesHandler({ field: "first_response", value: e })
              }
              error={
                validate &&
                (ruleData["first_response"] == null ||
                  ruleData["first_response"] === "")
              }
            />
          </Grid>

          <Grid item sm={2.5}>
            <DurationField
              label="Warning Time"
              duration={ruleData["warning_time"]}
              onChange={(e: any) =>
                onChangesHandler({ field: "warning_time", value: e })
              }
              error={
                validate &&
                (ruleData["warning_time"] == null ||
                  ruleData["warning_time"] === "")
              }
            />
          </Grid>
          <Grid item sm={2.5}>
            <DurationField
              label="Resolution Time"
              duration={ruleData["resolution_time"]}
              onChange={(e: any) =>
                onChangesHandler({ field: "resolution_time", value: e })
              }
              error={
                validate &&
                (ruleData["resolution_time"] == null ||
                  ruleData["resolution_time"] === "")
              }
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          // onClick={() => props.onAction(props.isPrimary === true)}
        >
          {allRules.length > 1 && (
            <Button
              onClick={() => onAction(false)}
              variant="secondary"
              icon={<MinusIcon />}
            />
          )}
        </Box>
      </Stack>
    </ThemeProvider>
  );
};
