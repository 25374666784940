export const TEXTFIELD_CONSTANTS = {
  height: { sm: 32, md: 40, lg: 48, xl: 56 },
  border_radius: "8px",
  palette: {
    outline: {
      DEFAULT: "#CCCCCC",
      SELECTED: "#5563F5",
      DISABLED: "#E3E3E3",
      ERROR: "#E3E3E3"
    },
    background: { ERROR: "#FFF3F2" },
    color: "#1C1E26",
    unfocus_color: "#767B85",
    bold_weight: 700,
    regular_weight: 400
  },
  transition: { sm: "translate(14px,4px) scale(1)", md: "translate(14px,8px) scale(1)", lg: "translate(14px,12px) scale(1)", xl: "translate(14px,16px) scale(1)" },
};