import { fetchCall } from "../../../utilz";
import { appConfig } from "../../../constants";

// Fetching Conversation Setting by MSPID
const fetchConversationSettingsByMspId = (id) =>
  fetchCall(
    "/controlCenter/channels/conversations",
    appConfig?.requestMethods.GET
  );

// Update Coversation settings for MSPID
const updateConversationSettingsByMspIp = (id, data) =>
  fetchCall(
    "/controlCenter/channels/conversations",
    appConfig?.requestMethods?.PATCH,
    data
  );

export const conversationServices = {
  fetchConversationSettingsByMspId,
  updateConversationSettingsByMspIp,
};
