import { Dayjs } from "dayjs";
import {
  AttachmentFileData,
  ChecklistInput,
  ResourceAutocompleteOptionsType,
} from "shared";
import { StateScheduleData } from "shared/features/assign/assign";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { useGlobalStore } from "../globalStore";

interface CreateTicketState {
  step: number;
  open: boolean;
  isBillable: boolean;
  checklist: Array<ChecklistInput>;
  board?: string | null;
  priority?: string;
  status?: string;
  summary: string;
  description: string;
  resources?: Array<ResourceAutocompleteOptionsType>;
  urgency?: string;
  workType?: string;
  ticketType?: string;
  ticketSubtype?: string;
  source?: string;
  impact?: string;
  sla?: string;
  customer?: string;
  site?: string;
  user?: string;
  schedule?: Array<Schedule>;
  budgetedHours?: string;
  location?: string;
  generalVariable?: Record<string, Attribute> | null;
  ticketVariable?: Record<string, Attribute> | null;
  attachments?: Array<AttachmentFileData>;
  estimatedEndTime?: string | null;
  tags?: Array<any> | null;
  assigneesAndSchedule?: StateScheduleData | null;
  contract?: string;
}
interface Attribute {
  attribute_id: string;
  attribute_value_id: string;
}
interface Schedule {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}
interface CreateTicketActions {
  changeModalStep: (step: number) => void;
  toggleModalOpen: () => void;
  toggleIsBillable: () => void;
  addChecklists: (checklists: Array<ChecklistInput>) => void;
  addValuesToFields: ({
    type,
    value,
  }: {
    type:
      | "board"
      | "priority"
      | "status"
      | "summary"
      | "description"
      | "urgency"
      | "workType"
      | "ticketType"
      | "ticketSubtype"
      | "source"
      | "impact"
      | "sla"
      | "customer"
      | "site"
      | "user"
      | "budgetedHours"
      | "location"
      | "estimatedEndTime"
      | "contract";
    value: string;
  }) => void;
  addResources: (value: Array<ResourceAutocompleteOptionsType>) => void;
  addSchedule: (value: Array<Schedule>) => void;
  addAssigneesAndSchedule: (value: StateScheduleData) => void;
  addAttributes: (value: Attribute, type: "ticket" | "general") => void;
  populateDataFromTemplate: (value: any) => void;
  resetCreateTicket: () => void;
  addAttachments: (value: Array<AttachmentFileData>) => void;
  addTags: (value: Array<any>) => void;
}

export interface CreateTicketInterface
  extends CreateTicketActions,
    CreateTicketState {}
export const createTicketStoreSlice = immer<
  CreateTicketState & CreateTicketActions
>((set) => ({
  board: useGlobalStore.getState().generalConfig?.defaultTicketBoardId,
  step: 1,
  open: false,
  isBillable: true,
  summary: "",
  description: "",
  priority: "low",
  status: "9dd09ea5-e30e-494a-9120-583caff52a3a",
  urgency: "low",
  impact: "low",
  checklist: [],
  changeModalStep: (step) =>
    set((state) => {
      state.step = step;
    }),
  toggleModalOpen: () =>
    set((state) => {
      console.log(state.open);
      state.open = !state.open;
    }),
  toggleIsBillable: () =>
    set((state) => {
      state.isBillable = !state.isBillable;
    }),

  addChecklists: (checklistArr) =>
    set((state) => {
      state.checklist = checklistArr;
    }),

  addValuesToFields: ({ type, value }) =>
    set((state) => {
      state[type] = value;
    }),
  // @deprecated
  addResources: (value) =>
    set((state) => {
      console.log(value);
      state.resources = value;
    }),
  // @deprecated
  addSchedule: (value) =>
    set((state) => {
      state.schedule = value;
    }),
  addAssigneesAndSchedule: (value) =>
    set((state) => {
      state.assigneesAndSchedule = value;
    }),
  addAttributes: (value, type) =>
    set((state) => {
      console.log(value);

      if (type === "ticket") {
        if (!state?.ticketVariable) {
          state.ticketVariable = { [value.attribute_id]: value };
        } else {
          state.ticketVariable[value.attribute_id] = value;
        }
      }
      if (type === "general") {
        if (!state?.generalVariable) {
          const newValue = { [value.attribute_id]: value };
          state.generalVariable = newValue;
        } else {
          state.generalVariable[`${value.attribute_id}`] = value;
        }
        console.log(state.generalVariable[value.attribute_id]);
      }
    }),
  populateDataFromTemplate: (value) =>
    set((state) => {
      state.description = value.template_description;
      state.summary = value.template_summary;
      state.board = value.board;
      state.priority = value.priority;
      state.isBillable = value.is_billable;
      state.urgency = value.urgency;
      state.workType = value.work_type;
      state.ticketType = value.ticket_type;
      state.ticketSubtype = value.ticket_subtype;
      state.source = value.source;
      state.impact = value.impact;
      state.location = value.location;
      state.sla = value.sla;
      state.checklist = value.checklist;
      if (value.attachments && value.attachments?.length > 0) {
        const attachmentArray = value.attachments?.map((attachment: any) => {
          return {
            key: attachment.mapping_id,
            name: attachment.original_name,
            progress: 100,
            size: parseInt(attachment.size),
            type: attachment.file_type,
            status: "Uploaded",
            responseData: attachment,
          };
        });
        state.attachments = attachmentArray;
      }
    }),
  resetCreateTicket: () =>
    set((state) => {
      state.open = false;
      state.step = 1;
      state.summary = "";
      state.description = "";
      state.checklist = [
        { checklist_item_name: "", order: 1, is_important: false },
      ];
      state.board = null;
      state.priority = "low";
      state.status = "9dd09ea5-e30e-494a-9120-583caff52a3a";
      state.resources = undefined;
      state.urgency = "low";
      state.workType = undefined;
      state.ticketType = undefined;
      state.ticketSubtype = undefined;
      state.source = undefined;
      state.impact = "low";
      state.sla = undefined;
      state.customer = undefined;
      state.site = undefined;
      state.user = undefined;
      state.schedule = undefined;
      state.budgetedHours = undefined;
      state.location = undefined;
      state.attachments = undefined;
      state.ticketVariable = null;
      state.generalVariable = null;
      state.tags = null;
      state.checklist = [];
      state.assigneesAndSchedule = null;
      state.estimatedEndTime = null;
      state.isBillable = true;
    }),
  addAttachments: (value) =>
    set((state) => {
      state.attachments = value;
    }),
  addTags: (value) =>
    set((state) => {
      state.tags = value;
    }),
}));
