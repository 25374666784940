import axios from "axios";
import jwtDecode from "jwt-decode";
import { cookieGenerator, getCredentialStorage } from "./CredentialData";
import { useGlobalStore } from "controller/store/globalStore";
import { getAuthAccessToken, useMainStore } from "controller";

let isLoading = false;

export const internalAxiosClient = axios.create({
  baseURL: import.meta.env["VITE_APP_AUTH_BASE_URL"],
  // withCredentials: true,
});

internalAxiosClient.interceptors.request.use((req) => {
  return req;
});

export const setSessionValues = async (
  accessToken: string,
  decodedData: any
) => {
  useGlobalStore.setState((state) => ({
    ...state,
    accessToken: accessToken,
    mspId: decodedData?.tenantId,
    resourceId: decodedData?.sub,
    name: state.name,
    email: decodedData?.email,
    profilePictureUrl: state.profilePictureUrl,
    roles: decodedData?.roles,
    permissions: decodedData?.permissions,
  }));
};

export const reAuthenticateUser = async (res: any) => {
  if (isLoading) {
    return null;
  }
  isLoading = true;

  try {
    const result = await getAuthAccessToken();
    if (
      result &&
      result?.status === 200 &&
      result?.data &&
      result?.data?.data &&
      result?.data?.data?.accessToken &&
      result?.data?.data?.refreshToken
    ) {
      const { accessToken, refreshToken, expires } = result.data.data;
      let decoded: any;
      try {
        decoded = jwtDecode(accessToken);
      } catch (e) {
        decoded = null;
      }
      if (decoded && decoded?.tenantId && decoded?.sub) {
        document.cookie = cookieGenerator(decoded?.aud, refreshToken);

        //set global values to session storage
        await setSessionValues(accessToken, decoded);
        res.config.__isRetryRequest = true;
        res.config.headers["Authorization"] = `Bearer ${accessToken}`;
        // res.config.baseURL = undefined;
        isLoading = false;
        return res.config;
      }
    } else {
      throw new Error("unauthorized");
    }
  } catch (e: any) {
    if (e?.message === "unauthorized") {
      //redirect to login with proper error message
      useGlobalStore.getState().resetCredentials();
      localStorage.clear();
      sessionStorage.clear();
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      //@ts-ignore
      const redirectUrl = import.meta.env["VITE_APP_AUTH_FRONT_URL"];
      //@ts-ignore
      if (import.meta.env.MODE !== "development") {
        setTimeout(function () {
          window.location.href = `${redirectUrl}`;
        }, 500);
      }
      isLoading = false;
      return null;
    }
  }

  isLoading = false;
};
