import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Lottie from "lottie-react";
import happy from "assets/lottie/happy.json";
import awesome from "assets/lottie/awesome.json";
import dissapointed from "assets/lottie/dissapointed.json";
import needToImprove from "assets/lottie/needToImprove.json";
import { Button, DownArrowIcon } from "components";
import { useState } from "react";
import { useGetTodaysTickets, useGlobalStore, useMainStore } from "controller";
import Rating1 from "assets/images/1rating.png";
import Rating2 from "assets/images/2rating.png";
import Rating3 from "assets/images/3rating.png";
import Rating4 from "assets/images/4rating.png";
import Rating5 from "assets/images/5rating.png";

const ratingList = [Rating1, Rating2, Rating3, Rating4, Rating5];

export const Feedback1 = ({ data }: { data: any }) => {
  const theme = useTheme();
  const homeScreenViewFilter = useMainStore((state) => state.homeScreenView);
  const setHomeScreenViewFilter = useMainStore(
    (state) => state.setHomeScreenView
  );
  const getTimeWorkedInHoursAndMinutesStr = (data: string) => {
    if (!data) return "0h 0m";
    const hoursMinutesAndSecsArray = data.split(":");
    const hoursStr = hoursMinutesAndSecsArray[0]
      ? `${hoursMinutesAndSecsArray[0]}h`
      : "";
    const minutesStr = hoursMinutesAndSecsArray[1]
      ? `${hoursMinutesAndSecsArray[1]}m`
      : "";
    return `${hoursStr} ${minutesStr}`;
  };
  const timeWorkedOnTicketsStr = getTimeWorkedInHoursAndMinutesStr(
    data?.total_work_hours?.tickets
  );
  const timeWorkedOnTasksStr = getTimeWorkedInHoursAndMinutesStr(
    data?.total_work_hours?.tasks
  );
  const cardsData = [
    {
      title: "Closed items",
      ticketsCount: data?.closed_count?.tickets || 0,
      tasksCount: data?.closed_count?.tasks || 0,
      bgcolor: theme.palette.success.tint,
    },
    {
      title: "Assigned items",
      ticketsCount: data?.assigned_count?.tickets || 0,
      tasksCount: data?.assigned_count?.tasks || 0,
      bgcolor: theme.palette.secondary["purple-tint-3"],
    },
    homeScreenViewFilter === "today"
      ? {
          title: "New Arrivals",
          ticketsCount: data?.new_arrivals?.tickets || 0,
          tasksCount: data?.new_arrivals?.tasks || 0,
          bgcolor: "#E1FAFC",
        }
      : {
          title: "Hours Worked",
          ticketsCount:
            timeWorkedOnTicketsStr === "00h 00m"
              ? "0h 0m"
              : timeWorkedOnTicketsStr || "0h 0m",
          tasksCount:
            timeWorkedOnTasksStr === "00h 00m"
              ? "0h 0m"
              : timeWorkedOnTasksStr || "0h 0m",
          bgcolor: "#FCF8CF",
        },
  ];
  const filterOptions = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "This Week",
      value: "last_week",
    },
    {
      label: "This Month",
      value: "last_month",
    },
    {
      label: "Overall",
      value: "all",
    },
  ];
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };
  const handleFilterMenuClose = async () => {
    setFilterMenuAnchorEl(null);
  };
  const selectedFilterOption = filterOptions?.find(
    (option) => option?.value === homeScreenViewFilter
  );
  const timezone = useGlobalStore((state) => state.timezone);
  const { data: todaysTicketData } = useGetTodaysTickets(timezone);

  return (
    <Stack direction="row" gap="16px">
      <Stack
        alignItems={"center"}
        width="232px"
        height="262px"
        borderRadius={"8px"}
        gap="24px"
        bgcolor={{
          background: "linear-gradient(185.95deg, #001E25 0%, #0D0F23 100%);",
        }}
        position="relative"
        px="24px"
      >
        <Typography
          variant="body-medium"
          py="8px"
          color={theme.palette.neutral.main}
        >
          Overall Feedback
        </Typography>
        <Stack gap="16px" alignItems={"center"}>
          {!isNaN(todaysTicketData?.feedbackResult) &&
          todaysTicketData?.feedbackResult > 0 ? (
            <>
              <Box width={"140px"} height={"140px"}>
                <LottieComponent rating={todaysTicketData?.feedbackResult} />
              </Box>
              <Typography variant="h6" color={theme.palette.neutral.main}>
                {getRatingText(todaysTicketData?.feedbackResult)}
              </Typography>
            </>
          ) : (
            <Stack
              gap={"8px"}
              justifyContent={"center"}
              alignItems={"center"}
              position="absolute"
              top="50%"
              left="50%"
              sx={{ transform: "translate(-50%, -50%)" }}
            >
              <Stack
                direction={"row"}
                maxWidth={"250px"}
                width={"100%"}
                spacing={0.5}
              >
                {ratingList.map((rating, index) => (
                  <img
                    key={`rating_${index}`}
                    src={rating}
                    style={{
                      maxWidth: "40px",
                      maxHeight: "40px",
                      flex: 1,
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                ))}
              </Stack>
              <Typography
                variant="body-medium"
                color={theme.palette.darkBg.main}
              >
                No Feedback Received Yet
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        flexGrow={"1"}
        borderRadius={"8px"}
        gap="16px"
        bgcolor={theme.palette.neutral.main}
      >
        <Typography
          onClick={handleFilterBtnClick}
          display={"flex"}
          alignItems={"center"}
          variant="h6"
          px="16px"
          pt="8px"
          sx={{
            ":hover": {
              color: theme.palette.primary.main,
              transition: "color 0.3s",
            },
            cursor: "pointer",
            display: "flex",
          }}
        >
          {/* @ts-ignore */}
          {selectedFilterOption.label}
          <DownArrowIcon />
        </Typography>
        <Menu
          sx={{ minWidth: "120px" }}
          id="basic-menu"
          anchorEl={filterMenuAnchorEl}
          open={!!filterMenuAnchorEl}
          onClose={handleFilterMenuClose}
          PaperProps={{
            sx: {
              minWidth: "140px",
              bgcolor: "#FFF",
              borderRadius: "8px",
              boxShadow: "none",
              border: `1px solid ${theme.palette.neutral["020"]}`,
            },
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {filterOptions.map((item, index) => (
            <MenuItem
              key={item.label}
              sx={{
                height: "42px",
                paddingBottom: "12px",
                ...(index !== filterOptions.length - 1 && {
                  borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
                }),
              }}
              onClick={() => {
                setHomeScreenViewFilter(item.value);
                handleFilterMenuClose();
              }}
            >
              <Stack
                direction={"row"}
                gap="16px"
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Typography variant="body-small">{item.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
        <Stack px="16px" direction="row" gap="16px">
          {cardsData.map(({ title, ticketsCount, tasksCount, bgcolor }) => (
            <Card
              title={title}
              ticketsCount={ticketsCount}
              tasksCount={tasksCount}
              bgcolor={bgcolor}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const Card = ({
  title,
  ticketsCount,
  tasksCount,
  bgcolor,
}: {
  title: string;
  ticketsCount: number;
  tasksCount: number;
  bgcolor?: string;
}) => {
  return (
    <Stack px="16px" borderRadius={"8px"} flexGrow={"1"} bgcolor={bgcolor}>
      <Typography variant="body-medium" py="8px">
        {title}
      </Typography>
      <Stack py="16px" gap="4px">
        <Typography variant="h4">{ticketsCount}</Typography>
        <Typography variant="caption-2">Tickets</Typography>
      </Stack>
      <Stack py="16px" gap="4px">
        <Typography variant="h4">{tasksCount}</Typography>
        <Typography variant="caption-2">Tasks</Typography>
      </Stack>
    </Stack>
  );
};

const LottieComponent = ({ rating, ...rest }: any) => {
  switch (Math.round(rating)) {
    case 0:
    case 1:
      return <Lottie animationData={dissapointed} loop={true} />;
    case 2:
      return <Lottie animationData={needToImprove} loop={true} />;
    case 3:
      return <Lottie animationData={happy} loop={true} />;
    case 4:
      return <Lottie animationData={happy} loop={true} />;
    case 5:
      return <Lottie animationData={awesome} loop={true} />;

    default:
      return <Lottie animationData={awesome} loop={true} />;
  }
};

const getRatingText = (rating: number) => {
  let value = "Awesome";
  switch (Math.round(rating)) {
    case 0:
    case 1:
      value = "Disappointed";
      break;
    case 2:
      value = "Need To Improve";
      break;
    case 3:
      value = "Satisfied";
      break;
    case 4:
      value = "Good";
      break;
    case 5:
      value = "Awesome";
      break;

    default:
      value = "Awesome";
      break;
  }
  return value;
};
