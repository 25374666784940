import React, { forwardRef } from "react";
import { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { ButtonRoot } from "./ButtonRoot";
import { useGetCurrentBreakpoint } from "../../utilz/helpers/getCurrentBreakpoint";
import { CircularProgress } from "@mui/material";

export interface CustomButtonProps extends Omit<ButtonProps, "variant"> {
  variant?: "primary" | "secondary" | "ghost";
  /**
   * set true if its icon only button (without text)
   * @default false
   */
  isIconOnly?: boolean;
  /**
   * if iconOnlyButton
   */
  icon?: React.ReactNode;
  /**
   * set to false if fixed button is needed and a size prop can be specified
   */
  isResponsive?: boolean;
  loading?: boolean;
}
type Ref = ButtonProps;
export const Button = forwardRef(
  (
    {
      children,
      variant = "primary",
      size = "small",
      isIconOnly = false,
      icon,
      isResponsive = false,
      loading = false,
      endIcon,
      disabled,
      ...rest
    }: CustomButtonProps,
    ref
  ) => {
    const theme = useTheme();
    const breakpoint = useGetCurrentBreakpoint(theme);
    let muiVariant: ButtonProps["variant"] = "contained";

    switch (variant) {
      case "primary":
        muiVariant = "contained";
        break;
      case "secondary":
        muiVariant = "outlined";
        break;
      case "ghost":
        muiVariant = "text";
        break;
      default:
        muiVariant = "contained";
        break;
    }

    if (isResponsive) {
      if (breakpoint === "lg") {
        size = "large";
      } else if (breakpoint === "md") {
        size = "medium";
      } else {
        size = "small";
      }
    }

    return (
      <ButtonRoot
        variant={muiVariant}
        size={size}
        disableElevation
        disabled={loading || disabled}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        isIconOnly={isIconOnly}
        // loading={loading}
        icon={icon}
        startIcon={icon}
        sx={{
          textTransform: "capitalize",
          "& .MuiButton-endIcon": {
            fontSize: "332px", // Adjust the size as needed
          },
        }}
        // @ts-ignore
        ref={ref}
        {...rest}
        endIcon={
          loading && !icon ? (
            <CircularProgress
              size={"16px"}
              sx={{
                "&.MuiCircularProgress-root": {
                  color: theme.palette.lightBg.low,
                },
              }}
            />
          ) : (
            endIcon
            // <span style={{ fontSize: "32px" }}>{endIcon}</span>
          )
        }
      >
        {!isIconOnly && !icon && children}
      </ButtonRoot>
    );
  }
);
