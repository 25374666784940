import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { v4 } from "uuid";

// Services imports
import { services } from "../../../../../../services";

// Legacy Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// HOC Imports
import {
  CustomTextField,
  CustomSelectField,
  CustomTextAreaField,
} from "../../../../../../library/system/custom-form";

// Utilities
import { defaultStateBuilder, mountStateBuilder } from "./utilz";

// Constants Imports
import { FORM_FIELDS } from "./constants";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectField } from "components";
import { TimeZoneList } from "shared";

const schema = yup.object({
  location_name: yup.string().required("Please enter location name"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Please enter mail"),
  country: yup.string().required("Please select country"),
  postal_code: yup.string().required("Please enter zip code"),
});

const LocationFormComponent = forwardRef((props, ref) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      location_name: "",
      email: "",
      postal_code: "",
      country: "",
    },
    resolver: yupResolver(schema),
  });
  const watchCountry = watch("country");
  // States
  const [state, setState] = useState({
    fields: defaultStateBuilder(FORM_FIELDS),
  });

  // Imperative Handle
  useImperativeHandle(
    ref,
    () => {
      return {
        submit() {
          const data = {};
          Object.values(state?.fields).forEach((element) => {
            data[`${element.mappingId}`] =
              state.fields[`${element.name}`].value;
          });
          return { formdata: data, id: props?.locationId };
        },
      };
    },
    [state]
  );
  // Effects
  useEffect(() => {
    if (props?.locationId.length > 0) {
      fetchLocationDetails();
    }
  }, [props]);

  // Event Handlers
  const handleTextFieldChange = (data) => {
    const { field, value } = data;
    try {
      const currentValues = state?.fields;
      currentValues[`${field}`] = {
        ...currentValues[`${field}`],
        value: value,
      };
      setState({ ...state, fields: currentValues });
    } catch (err) {
      console.log("[ERROR] Handling change in text-field");
      console.log(err);
    }
  };
  const handleFieldChange = (data) => {
    const { field, value } = data;
    try {
      const currentValues = state?.fields;
      currentValues[`${field}`] = {
        ...currentValues[`${field}`],
        value: value,
      };
      setState({ ...state, fields: currentValues });

      if (field === "country") {
        setState({
          ...state,
          fields: {
            ...state.fields,
            state: { ...state.fields.state, value: "" },
            city: { ...state.fields.city, value: "" },
          },
        });
      }
      if (field === "state") {
        setState({
          ...state,
          fields: {
            ...state.fields,
            // state: {...state.fields.state, value: '', },
            city: { ...state.fields.city, value: "" },
          },
        });
      }
      if (field === "city") {
        updateCityCoordinates();
      }
    } catch (err) {}
  };
  const updateCityCoordinates = () => {
    try {
      services.generalServices
        .fetchAllCitiesByStateCode(
          state?.fields["country"]?.value,
          state?.fields["state"]?.value
        )
        .subscribe({
          next: (response) => {
            console.log(response);
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true &&
              response?.data &&
              response?.data?.result &&
              Array.isArray(response?.data?.result)
            ) {
              const data = response?.data?.result.find(
                (element) => element?.name === state?.fields["city"]?.value
              );

              setState({
                ...state,
                fields: {
                  ...state?.fields,
                  latitude: {
                    ...state?.fields?.latitude,
                    value: data["latitude"],
                  },
                  longitude: {
                    ...state?.fields?.longitude,
                    value: data["longitude"],
                  },
                },
              });
            }
          },
          error: (error) => {},
        });
    } catch (err) {}
  };

  // API Calls
  const fetchLocationDetails = () => {
    try {
      services.controlCenterservices.locationServices
        .fetchLocationByLocationId(props?.locationId)
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.success === true &&
              response?.statusCode === 200 &&
              response?.data
            ) {
              setState({
                ...state,
                fields: mountStateBuilder(FORM_FIELDS, response?.data),
              });
            }
          },
          error: (error) => {},
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Renderer
  return (
    <React.Fragment>
      <Box
        sx={{
          mt: "16px",
        }}
      >
        <Grid
          container
          columnSpacing={{ sm: "12px", md: "16px", lg: "20px", xl: "24px" }}
          rowSpacing={{ sm: "12px", md: "16px", lg: "20px", xl: "24px" }}
        >
          {Object.values(state?.fields)
            .filter((element) => element?.hidden === false)
            .map((item) => {
              return (
                <Grid
                  key={item?.key}
                  item
                  xs={item?.width["xs"]}
                  md={item?.width["md"]}
                >
                  {item?.type === "text" ? (
                    <CustomTextField
                      name={item?.name || ""}
                      label={item?.label || ""}
                      value={`${item?.value}` || ""}
                      disabled={props?.isEdit}
                      required={item?.required}
                      error={false}
                      helperText={""}
                      placeholder={item?.placeholder}
                      changeHandler={handleTextFieldChange}
                    />
                  ) : item?.type === "select" ? (
                    item?.name === "timezone" ? (
                      <SelectField
                        groupedOptions={TimeZoneList}
                        isGrouped={true}
                        value={item?.value || ""}
                        labelMappingKey="label"
                        valueMappingKey="value"
                        label={item?.label}
                        disabled={props?.isEdit}
                        required={item.required}
                        placeholder={item?.placeholder}
                        onValueChange={(newValue) =>
                          handleFieldChange({
                            field: "timezone",
                            value: newValue,
                          })
                        }
                      />
                    ) : (
                      <CustomSelectField
                        name={item?.name || ""}
                        label={`${item?.label || ""}${
                          item?.required ? " *" : ""
                        }`}
                        value={item?.value || ""}
                        multi={false}
                        size={"small"}
                        placeholder={item?.placeholder}
                        disabled={props?.isEdit}
                        dependency={item?.dependency}
                        triggerValue={item.trigger.map(
                          (element) => state?.fields[`${element}`]?.value
                        )}
                        populator={item.getData}
                        changeHandler={handleFieldChange}
                      />
                    )
                  ) : item?.type === "textarea" ? (
                    <CustomTextAreaField
                      name={item?.name || ""}
                      label={item?.label || ""}
                      value={item?.value || ""}
                      disabled={props?.isEdit}
                      required={item?.required}
                      error={false}
                      helperText={""}
                      placeholder={""}
                      size={"medium"}
                      maxRows={6}
                      minRows={1}
                      rows={5}
                      changeHandler={handleTextFieldChange}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </React.Fragment>
  );
});

export default LocationFormComponent;
