import { useGlobalStore } from "controller/store/globalStore";
import jwtDecode from "jwt-decode";
import { MainLayout, MainWrapper } from "layouts";
import { Suspense, useEffect, useState } from "react";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

import { setSessionValues } from "utilz/helpers/apiRequestAuthHelper";
import { cookieGenerator } from "utilz/helpers";
import { getAuthAccessToken } from "controller";
import { Stack } from "@mui/material";
export const SecureWrapper = () => {
  const accessToken = useGlobalStore((state) => state.accessToken);
  const resetCredentials = useGlobalStore((state) => state.resetCredentials);
  let isApiLoading = false;
  const [state, setState] = useState({
    isValid: false,
    isLoading: false,
  });
  useEffect(() => {
    validateAccount();
  }, []);

  async function validateAccount() {
    if (isApiLoading) {
      return;
    }
    isApiLoading = true;
    if (accessToken) {
      let decoded: any;
      try {
        decoded = jwtDecode(accessToken);
      } catch (e) {
        decoded = null;
      }
      if (decoded && decoded?.tenantId) {
        setState({
          isValid: true,
          isLoading: false,
        });
        return;
      }
    }

    try {
      const result = await getAuthAccessToken();

      if (
        result.status === 200 &&
        result &&
        result?.data &&
        result?.data?.data &&
        result?.data?.data?.accessToken &&
        result?.data?.data?.refreshToken
      ) {
        let decoded: any;
        const accessToken = result?.data?.data?.accessToken;
        const refreshToken = result?.data?.data?.refreshToken;
        const expires = result?.data?.data?.expires;
        //decrypt token
        try {
          decoded = jwtDecode(accessToken);
        } catch (e) {
          decoded = null;
        }
        if (decoded && decoded?.tenantId && decoded?.sub) {
          document.cookie = cookieGenerator(decoded?.aud, refreshToken);
          // sessionStorage.clear();
          //set global values to session storage
          setSessionValues(accessToken, decoded);
          setState({
            isValid: true,
            isLoading: false,
          });
          isApiLoading = false;
          return;
        }
      }
    } catch (e) {}
    isApiLoading = false;

    resetCredentials();
    sessionStorage.clear();

    //clear refresh token cookie
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    //@ts-ignore
    const redirectUrl = import.meta.env["VITE_APP_AUTH_FRONT_URL"];
    //@ts-ignore
    if (import.meta.env.MODE !== "development") {
      setTimeout(function () {
        window.location.href = `${redirectUrl}`;
      }, 500);
    }
  }

  // return state.isLoading ? <>Loading</> : state.isValid ? <MainLayout /> : null;
  return !state.isLoading && state.isValid ? (
    <MainWrapper>
      <MainLayout />
    </MainWrapper>
  ) : null;
  // (
  //   <>Unauthorized user</>
  // );
};
