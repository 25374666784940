export const TABS = {
  GENERAL: {
    key: "GENERAL-TAB-KEY",
    label: "General",
    value: "GENERAL",
    route: "general",
  },
  ACCOUNTS: {
    key: "ACCOUNTS-TAB-KEY",
    label: "Accounts",
    value: "ACCOUNTS",
    route: "accounts",
  },
  //   SUBSCRIPTION: {key: 'SUBSCRIPTION-TAB-KEY', label: 'Subscription', value: 'SUBSCRIPTION', route: 'subscription'},
};
