import { SvgIcon, SvgIconProps } from "@mui/material";

export const MobileDeviceIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.368"
        d="M8.488 20.913h7.442c.822 0 1.489-.803 1.489-1.791V4.79c0-.988-.667-1.791-1.489-1.791H8.488C7.666 3 7 3.803 7 4.79v14.332c0 .988.666 1.79 1.488 1.79ZM12.21 5.005h-.012"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width=".913"
        d="M14.195 19.1h-3.971"
      />
    </svg>
  </SvgIcon>
);
