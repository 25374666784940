import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface QaBoardFilterState {
  selectedQaResources: Array<string>;
}

interface QaBoardFilterAction {
  setSelectedQaResources: (data: Array<string>) => void;
  clearSelectedQaFilterResources: () => void;
}
export interface QaBoardFilterInterface
  extends QaBoardFilterState,
    QaBoardFilterAction {}

export const qaBoardFilterSlice = immer<
  QaBoardFilterState & QaBoardFilterAction
>((set) => ({
  selectedQaResources: [],
  setSelectedQaResources: (data) =>
    set((state) => {
      state.selectedQaResources = data;
    }),
  clearSelectedQaFilterResources: () =>
    set((state) => {
      state.selectedQaResources = [];
    }),
}));
