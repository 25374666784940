import { Box, Stack } from "@mui/material";
import { CustomTypography } from "../../../../../../../library/base";
import CustomIcon from "../../../../../../../library/icon-library";
import { CustomCard } from "../../../../../../../library/layers";
import PropTypes from "prop-types";

import { useEffect, useState } from "react";

import { useGlobalStore } from "controller/store/globalStore";
import {
  useCreateAttribute,
  useDeleteAttribute,
  useGetAttributes,
  useGetServiceTypeSubtype,
  useUpdateAttribute,
} from "controller";
import toast from "react-hot-toast";
import { GetAttributesInput } from "shared";
import { AttributeItemTile } from "./AttributeItemTile";
import { CreateAttributeTile } from "./CreateAttributeTile";

interface AttributeContainerProps extends GetAttributesInput {
  label: string;
  fieldName?: string;
}
export const AttributeContainer = ({
  label,
  serviceType,
  attributeName,
  fieldName,
}: AttributeContainerProps) => {
  const mspId = useGlobalStore((state) => state.mspId);
  const { data: attributeList } = useGetAttributes({
    attributeName: attributeName,
    serviceType: serviceType,
  });

  const { mutateAsync: createAttribute } = useCreateAttribute();

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const onSubmitHandler = (data: any) => {
    createAttribute({
      attributeName: attributeName,
      serviceType: serviceType,
      data: data,
    })
      .then((response) => {
        setCreateModalOpen(false);
        toast.success("Created successfully");
      })
      .catch((error) => {
        toast.error("Unable to create");
      });
  };

  return (
    <Box>
      <CustomCard
        borderRadius="16px"
        content={
          <Stack
            sx={{
              position: "relative",
              width: "320px",
              height: "calc(100vh - 264px)",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                px: "16px",
                py: "16px",
                gap: "16px",
                borderBottom: "1px solid #CCCCCC",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <CustomTypography
                  content={label || ""}
                  size={"SMALL-ALPHA"}
                  variant={"BOLD"}
                />
              </Box>
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setCreateModalOpen((oldValue) => !oldValue);
                }}
              >
                <CustomIcon icon="plus" size={"xl"} color="black" />
              </Box>
            </Stack>
            {createModalOpen ? (
              <CreateAttributeTile
                name={fieldName}
                value=""
                onSubmit={onSubmitHandler}
                onCancel={() => {
                  setCreateModalOpen(false);
                }}
              />
            ) : (
              ""
            )}
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                overflow: "scroll",
              }}
            >
              {(Array.isArray(attributeList) ? attributeList : []).map(
                (item: any) => (
                  <AttributeItemTile
                    key={item.type_id}
                    value={item}
                    isEditable={item.msp_id !== null}
                    attributeName={attributeName}
                    fieldName={fieldName || ""}
                    serviceType={serviceType}
                    mappingId={item["mapping_id"] || item["type_id"]}
                  />
                )
              )}
            </Stack>
          </Stack>
        }
      />
    </Box>
  );
};
