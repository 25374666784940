import { SvgIcon, SvgIconProps } from "@mui/material";

export const PlaneFilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M19.832 13.079 5.24 20.373a1.203 1.203 0 0 1-1.701-1.383l1.697-6.407c.05.013.101.02.155.02h5.49a.6.6 0 0 0 0-1.2h-5.49a.603.603 0 0 0-.155.02L3.539 5.018a1.203 1.203 0 0 1 1.7-1.384l14.593 7.295a1.203 1.203 0 0 1 0 2.151Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
