import { Stack } from "@mui/material";
import { CircularLoader } from "components";
import { useGetTask, useGlobalStore } from "controller";
import { MqttContext } from "controller/context/MqttCreateContext";
import { useContext, useEffect } from "react";
import { statusData } from "shared/data";
import { Chat } from "shared/features/chat/Chat";
import { Assignees, ChatNotificationSchema } from "shared/types";

const disabledStatus: Array<string> = [statusData.CLOSED, statusData.CANCELLED];
export const TaskChatContainer = ({
  selectedChat,
  onClose,
}: {
  onClose: () => void;
  selectedChat: ChatNotificationSchema;
}) => {
  const {
    data: taskData,
    isLoading: isTaskApiLoading,
    isError: isTaskError,
  } = useGetTask(selectedChat.service_id);

  const mspId = useGlobalStore((state) => state.mspId);
  const resourceId = useGlobalStore((state) => state.resourceId);
  const {
    connectMqtt,
    subscribeRoom,
    isConnected,
    unSubscribeRoom,
    mqttMessage,
  } = useContext(MqttContext);

  useEffect(() => {
    if (!isConnected) {
      connectMqtt();
    } else {
      console.log(isConnected);
      subscribeRoom([
        `${selectedChat.service_type}s/${selectedChat.service_id}`,
        `${selectedChat.service_type}s/${selectedChat.service_id}/private`,
        `${selectedChat.service_type}s/${mspId}/${selectedChat.service_id}/resource/${resourceId}`,
      ]);
    }

    return () => {
      if (isConnected) {
        unSubscribeRoom([
          `${selectedChat.service_type}s/${selectedChat.service_id}`,
          `${selectedChat.service_type}s/${selectedChat.service_id}/private`,
          `${selectedChat.service_type}s/${mspId}/${selectedChat.service_id}/resource/${resourceId}`,
        ]);
      }
    };
  }, [isConnected]);
  const extractAssigneesFromSchedule = (schedules: Array<any>) => {
    const mappingArray: Array<string> = [];
    const assigneesArray: Array<Assignees> = [];
    schedules.forEach((schedule) => {
      if (!mappingArray.includes(schedule.resource_id)) {
        assigneesArray.push({
          mapping_id: schedule.resource_id,
          first_name: schedule.first_name,
          last_name: schedule.last_name,
          display_name: schedule.display_name
            ? schedule.display_name
            : `${schedule.first_name} ${
                schedule.last_name ? schedule.last_name : ""
              }`,
          profile_image: schedule.profile_image,
        });
      }
      mappingArray.push(schedule.resource_id);
    });

    return assigneesArray;
  };
  return isTaskApiLoading ? (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "white",
        borderRadius: "16px",
      }}
    >
      <CircularLoader />
    </Stack>
  ) : (
    <Chat
      isDisabled={disabledStatus.includes(selectedChat?.service_status)}
      serviceType={(selectedChat.service_type as any) || ""}
      serviceId={selectedChat.service_id}
      section="messageBar"
      onClose={onClose}
      serviceSource={taskData?.source_name}
      assignees={extractAssigneesFromSchedule(taskData?.schedules || []) || []}
    />
  );
};
