import { commonApiCalls } from "../../../../../../../services/commonApiCalls";

export const FORM_FIELDS = {
  location_name: {
    key: "form-field-location_name-key",
    name: "location_name",
    mappingId: "location_name",
    type: "text",
    label: "Location Name",
    required: true,
    placeholder: "Enter location name",
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  contact_person: {
    key: "form-field-contact_person-key",
    name: "contact_person",
    mappingId: "contact_person",
    type: "text",
    required: false,
    label: "Contact Person",
    placeholder: "Enter contact person's name",
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  location_description: {
    key: "form-field-location_description-key",
    name: "location_description",
    mappingId: "location_description",
    type: "textarea",
    required: false,
    label: "Description",
    placeholder: "Enter location description",
    width: { xs: 12, md: 12 },
    hidden: false,
  },
  email: {
    key: "form-field-email-key",
    name: "email",
    mappingId: "email",
    type: "text",
    label: "Email",
    placeholder: "Enter email",
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  contact_number: {
    key: "form-field-contact_number-key",
    name: "contact_number",
    mappingId: "contact_number",
    type: "text",
    label: "Contact Number",
    placeholder: "Enter contact number",
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  country: {
    key: "form-field-country-key",
    name: "country",
    mappingId: "country",
    type: "select",
    label: "Country",
    placeholder: "",
    required: true,
    dependency: false,
    trigger: [],
    getData: commonApiCalls.getAllCountries,
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  state: {
    key: "form-field-state-key",
    name: "state",
    mappingId: "state",
    type: "select",
    label: "State/Province",
    placeholder: "",
    required: false,
    dependency: true,
    trigger: ["country"],
    getData: commonApiCalls.getAllStatesByCountry,
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  city: {
    key: "form-field-city-key",
    name: "city",
    mappingId: "city",
    type: "select",
    label: "City",
    required: false,
    placeholder: "Pick city",
    dependency: true,
    trigger: ["country", "state"],
    getData: commonApiCalls.getAllCitiesByStateAndCountry,
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  street: {
    key: "form-field-street-key",
    name: "street",
    mappingId: "street",
    type: "text",
    required: false,
    label: "Street",
    placeholder: "Enter street",
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  postal_code: {
    key: "form-field-postal_code-key",
    name: "postal_code",
    mappingId: "postal_code",
    type: "text",
    required: true,
    label: "Zip Code",
    placeholder: "Enter zip code",
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  timezone: {
    key: "form-field-timezone-key",
    name: "timezone",
    mappingId: "timezone",
    type: "select",
    label: "Time Zone",
    placeholder: "Pick time zone",
    dependency: false,
    required: true,
    trigger: [],
    getData: commonApiCalls.getAllTimeZones,
    width: { xs: 6, md: 6 },
    hidden: false,
  },
  latitude: {
    key: "form-field-latitude-key",
    name: "latitude",
    mappingId: "latitude",
    type: "hidden",
    label: "",
    required: false,
    placeholder: "",
    width: { xs: 0, md: 0 },
    hidden: true,
  },
  longitude: {
    key: "form-field-latitude-key",
    name: "longitude",
    mappingId: "longitude",
    type: "hidden",
    label: "",
    required: false,
    placeholder: "",
    width: { xs: 0, md: 0 },
    hidden: true,
  },
};
