import { create } from "zustand";
import {
  ChatNotificationInterface,
  chatNotificationSlice,
} from "./notification";

export const useChatNotificationStore = create<ChatNotificationInterface>()(
  (...a) => ({
    ...chatNotificationSlice(...a),
  })
);
