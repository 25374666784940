import {
  ProjectTemplateTab,
  TemplateCannedNotes,
  TemplateTickets,
} from "../tabs";

export const TAB_CONSTANT = {
  TICKET: {
    key: "configuration_templates_tickets_tab",
    label: "Tickets",
    map: "TICKET",
    tab: <TemplateTickets />,
  },
  // REQUEST: {
  //     key: 'configuration_templates_requests_tab',
  //     label: 'Requests',
  //     map: 'REQUEST',
  //     tab: <TemplateRequests />
  // },
  PROJECT: {
    key: "configuration_templates_projects_tab",
    label: "Projects",
    map: "PROJECT",
    tab: <ProjectTemplateTab />,
  },
  // CANNED_NOTES: {
  //   key: "configuration_templates_canned_notes_tab",
  //   label: "Canned Notes",
  //   map: "CANNED_NOTES",
  //   tab: <TemplateCannedNotes />,
  // },
};
