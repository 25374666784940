import { styled } from "@mui/material/styles";
import { default as MuiTab } from "@mui/material/Tab";
import React from "react";

interface StyledTabProps {
  label: React.ReactNode | string;
  value?: any; // Make the value prop optional
  icon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  iconPosition?: "bottom" | "top" | "start" | "end" | undefined;
}

export const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))(({ theme }) => ({
  // textTransform: "capitalize",
  // mr: "12px",
  // ...theme.typography["body-large"],
  // "&:hover": {
  //   color: theme.palette.purple.main,
  // },
  // "&.Mui-selected": {
  //   color: theme.palette.purple.main,
  // },
  // color: theme.palette.lightBg.low,
}));
