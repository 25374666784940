import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getTimesheetConfigurations,
  updateTimesheetConfigurations,
} from "controller/api";
import { ApiErrorType, UpdateTimesheetConfigInput } from "shared";
import { sentryLogger } from "utilz";

export const useGetTimesheetConfigurations = () => {
  return useQuery({
    queryKey: ["timsheetConfig"],
    queryFn: () => getTimesheetConfigurations(),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getTimesheetConfigurations",
      }),
  });
};

export const useUpdateTimesheetConfigurations = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: UpdateTimesheetConfigInput) =>
      updateTimesheetConfigurations(values),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["timsheetConfig"]);
        queryClient.invalidateQueries(["msp"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateTimesheetConfigurations",
        });
      },
    }
  );
};
