import {
  ChatResultData,
  ChatResultItem,
  GetAllChatInput,
  MarkChatAsResolvedInput,
  SendChatMessageInput,
  UploadChatAttachmentInput,
} from "shared";
import { axiosClient } from "utilz";
import axiosUploadCall from "utilz/axios-call";
const BASE_URL = import.meta.env.VITE_APP_CHAT_BASE_URL;

export const getAllChatMessages = async ({
  serviceId,
  fetcherId,
  page = 1,
  limit = 100,
  accessSpecifier,
  filterMarkAsResolved,
  serviceType,
}: GetAllChatInput): Promise<ChatResultData> => {
  const isMarkedAsResolvedFilter = filterMarkAsResolved
    ? `&mark_as_resolved=${filterMarkAsResolved}`
    : "";
  try {
    const url = `${BASE_URL}/api/messages/service/chat/${serviceType}/${serviceId}/messages?${
      accessSpecifier ? `access_specifier=${accessSpecifier}` : ""
    }&fetcher_id=${fetcherId}&page=${page}&limit=${limit}&fetcher_type=resource${isMarkedAsResolvedFilter}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const sendChatMessage = async ({
  serviceId,
  serviceType,
  message,
  accessSpecifier,
  sender,
  attachments,
  messageType,
  assignedResources,
  originType,
}: SendChatMessageInput) => {
  try {
    const url = `${BASE_URL}/api/messages/send/message/${serviceType}/${serviceId}/`;

    const response = await axiosClient.post(`${url}`, {
      assigned_resources: assignedResources,
      message,
      access_specifier: accessSpecifier,
      sender,
      message_type: messageType,
      origin_type: originType ? originType : null,
      attachments: attachments ? attachments : [],
      message_platform: "web_app",
    });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const markChatAsResolved = async ({
  chatId,
  newValue,
}: MarkChatAsResolvedInput) => {
  try {
    const url = `${BASE_URL}/api/messages/update/mark_resolve/${chatId}/${newValue}`;

    const response = await axiosClient.patch(`${url}`);
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const uploadChatAttachments = ({
  formData,
  serviceType,
  serviceId,
}: UploadChatAttachmentInput) =>
  axiosUploadCall(
    `servicedesk/upload/chat_attachments/${serviceType}/${serviceId}`,
    formData
  );
