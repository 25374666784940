export const EDIT_MODAL_STEPS = {
  step_1: {
    key: "STEP-1-GENERAL-KEY",
    value: 1,
    label: "General",
  },
  step_2: {
    key: "STEP-2-MEMBERS-KEY",
    value: 2,
    label: "Members",
  },
  step_3: {
    key: "STEP-3-PERMISSIONS-KEY",
    value: 3,
    label: "Permissions",
  },
};