import Checkbox from "@mui/material/Checkbox";
import colorPalette from "../../common/colors";
import PropType from "prop-types";
import CustomIcon from "../../icon-library";
import { Box } from "@mui/system";
import CheckBoxUncheckedIcon from "../../icon-library/icons/checkBoxUncheckedIcon";
import CheckBoxCheckedIcon from "../../icon-library/icons/checkBoxCheckedIcon";
import defaultSize from "./constants/defaultSizes";
function CustomCheckBox(props) {
  const size = defaultSize[props.size] ?? defaultSize.md;

  return (
    <Checkbox
      checked={props.checked}
      onClick={(e)=>{
        e.stopPropagation();
        props.onClick(e);
      }}
      icon={<CheckBoxUncheckedIcon {...props} />}
      checkedIcon={<CheckBoxCheckedIcon {...props} />}
      disabled={props.disabled}
      disableRipple
      sx={{
        height:props.isResponsive? {
          xs: defaultSize.sm,
          sm: defaultSize.sm,
          md: defaultSize.md,
          lg: defaultSize.lg,
          xl: defaultSize.xl,
        }:size,
        width:props.isResponsive? {
          xs: defaultSize.sm,
          sm: defaultSize.sm,
          md: defaultSize.md,
          lg: defaultSize.lg,
          xl: defaultSize.xl,
        }:size,
        padding: "3px",
        ...props.sx,
      }}
      onChange={props.onChange}
    />
  );
}

CustomCheckBox.propTypes = {
  onChange: PropType.func,
  checked: PropType.bool,
  varient: PropType.string.isRequired,
  disabled: PropType.bool,
  size: PropType.string.isRequired,
  onClick: PropType.func,
  sx: PropType.any,
  isResponsive: PropType.bool,
};

CustomCheckBox.defaultProps = {
  varient: "secondary",
  disabled: false,
  size: "md",
  isResponsive: true,
};

export default CustomCheckBox;
