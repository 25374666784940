import { Box, Stack } from "@mui/material";
import { CustomButton } from "../../../../../../../../library/base";

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { v4 } from "uuid";
import {
  duplicateChecklistItem,
  SwapChecklistElements,
  manageLibraryFiles,
  removeChecklistItem,
} from "./Utils";
import { ChecklistLibraryModal, NewChecklistModalItem } from "./components";

import { Input, Modal } from "components";
import toast from "react-hot-toast";

interface NewCheckListModalProps {
  open: boolean;
  selectedData: any;
  isTemplate?: boolean;
  onSubmit: () => void;
  onClose: () => void;
  editable: boolean;
  isNewChecklist: boolean;
}

export const NewCheckListModal = forwardRef(
  ({ selectedData, ...props }: NewCheckListModalProps, ref) => {
    const [checklistData, setChecklistData] = useState<any>(
      defaultDataBuilder(selectedData)
    );
    const [isLibraryOpen, setIsLibraryOpen] = useState<boolean>(false);
    const [selectedLibraryData, setSelectedLibraryData] = useState<any>([]);

    const divRef = useRef<any>(null);

    useEffect(() => {
      setChecklistData(defaultDataBuilder(selectedData));
    }, [selectedData]);

    const defaultChecklistItemBuilder = (index?: number) => ({
      checklist_item_name: "",
      order: index,
      is_completed: false,
      is_important: false,
    });

    const onAddChecklistItem = () => {
      const lastData =
        checklistData.items[checklistData.items.length - 1]?.data;
      if (checklistData.items.length < 20) {
        setChecklistData((oldData: any) => ({
          ...oldData,
          items: [
            ...oldData.items,
            {
              key: v4(),
              data: defaultChecklistItemBuilder(),
            },
          ],
        }));
        setTimeout(() => {
          divRef?.current?.scrollIntoView?.({
            behavior: "smooth",
            block: "end",
            // inline: "nearest",
          });
        }, 100);
      }
    };

    const onRemoveChecklistItem = (item: any) => {
      if (checklistData.items.length <= 1) {
        setChecklistData((oldData: any) => ({
          ...oldData,
          items: [
            {
              key: v4(),
              data: defaultChecklistItemBuilder(),
            },
          ],
        }));
        return;
      }
      const checklistItems = removeChecklistItem(
        [...checklistData.items],
        item
      );

      setChecklistData((oldData: any) => ({
        ...oldData,
        items: checklistItems,
      }));
    };

    const onChangePosition = (item: any, isUp: boolean) => {
      const checklistItems = checklistData.items;
      const index = checklistItems.indexOf(item);
      if (
        (isUp === true && index === 0) ||
        (isUp === false && index === checklistItems.length - 1)
      ) {
        return;
      }
      let newArray: Array<any>;
      if (isUp) {
        newArray = SwapChecklistElements(
          [...checklistItems],
          "order",
          index - 1
        );
      } else {
        newArray = SwapChecklistElements([...checklistItems], "order", index);
      }

      setChecklistData((oldData: any) => ({
        ...oldData,
        items: newArray,
      }));
    };

    const onDuplicateAction = (item: any) => {
      if (checklistData.items.length >= 20) {
        return toast.error("You can not add more than 20 items");
      }
      const checklistItems = duplicateChecklistItem(checklistData.items, item);

      setChecklistData((oldData: any) => ({
        ...oldData,
        items: checklistItems,
      }));
    };

    const onChecklistItemDataChange = (oldData: any, newData: any) => {
      const checklistItems = checklistData.items;
      const index = checklistItems.indexOf(oldData);
      checklistItems[index].data = newData;

      setChecklistData((oldData: any) => ({
        ...oldData,
        items: checklistItems,
      }));
    };

    const onTitleChange = (event: any) => {
      setChecklistData((oldData: any) => ({
        ...oldData,
        checklist_title: event.target.value,
      }));
    };

    const onLibraryFileAdded = (libraryData: any) => {
      setIsLibraryOpen(false);
      setSelectedLibraryData(libraryData);
      setChecklistData((oldaData: any) => ({
        ...oldaData,
        items: manageLibraryFiles(oldaData, libraryData),
      }));
    };

    useImperativeHandle(ref, () => {
      return {
        submit() {
          const data = checklistData;
          const filteredChecklistItems = data.items
            .filter(
              (item: any) =>
                item.data.checklist_item_name !== null &&
                item.data.checklist_item_name !== ""
            )
            .map((item: any, index: number) => ({
              checklist_item_name: item.data.checklist_item_name,
              order: index,
              is_important: item.data.is_important,
            }));

          return {
            ...(data.mapping_id !== undefined
              ? { mapping_id: data.mapping_id }
              : {}),
            checklist_title: data.checklist_title,
            checklist_items: [...filteredChecklistItems],
          };
        },
      };
    });

    return (
      <>
        {isLibraryOpen && (
          <ChecklistLibraryModal
            key={"checklist_library_modal_key"}
            isOpen={isLibraryOpen}
            data={selectedLibraryData}
            onSubmit={onLibraryFileAdded}
            onCancel={() => setIsLibraryOpen(false)}
          />
        )}
        <Modal
          open={props.open}
          maxWidth="sm"
          height="sm"
          title={
            Object.values(selectedData).length <= 0
              ? "Create Checklist"
              : "Update Checklist"
          }
          onClose={props.onClose}
          tagline="You may enter upto 20 total items"
          primaryButtonText="Save"
          secondaryButtonText="Cancel"
          onPrimaryButtonClick={props.onSubmit}
          onSecondaryButtonClick={props.onClose}
        >
          <Stack
            sx={{
              gap: "24px",
              p: "32px",
            }}
          >
            {props.isTemplate === true ? null : (
              <Input
                name="checklist_title"
                placeholder="Enter a title for your checklist"
                value={checklistData?.checklist_title || ""}
                onChange={onTitleChange}
                hideHelperBox
                fullWidth
              />
            )}

            <Stack
              sx={{
                width: "100%",
              }}
              direction={"column"}
              gap={"16px"}
            >
              {(Array.isArray(checklistData?.items)
                ? checklistData.items
                : []
              ).map((item: any, index: number) => (
                <NewChecklistModalItem
                  key={item.key}
                  index={index}
                  totalItems={checklistData.items.length}
                  checklistItem={item.data}
                  onDuplicateAction={() => onDuplicateAction(item)}
                  onMoveAction={(isMoveUp: boolean) =>
                    onChangePosition(item, isMoveUp)
                  }
                  onRemoveAction={() => onRemoveChecklistItem(item)}
                  onChange={(newData: any) =>
                    onChecklistItemDataChange(item, newData)
                  }
                />
              ))}
            </Stack>
            <Stack
              ref={divRef}
              direction={"row"}
              gap={"16px"}
              width={"100%"}
              pb={"8px"}
            >
              {Array.isArray(checklistData?.items) &&
              checklistData.items.length >= 20 ? null : (
                <CustomButton
                  label="Add Checklist"
                  isTrailingIcon={true}
                  icon={"checklist"}
                  varient={"secondary"}
                  onClick={onAddChecklistItem}
                />
              )}
              {props.isTemplate === true ? (
                <CustomButton
                  label="Add from library"
                  isTrailingIcon={true}
                  icon={"checklist"}
                  varient={"secondary"}
                  onClick={() => {
                    setIsLibraryOpen(true);
                  }}
                />
              ) : null}
            </Stack>
          </Stack>
        </Modal>
      </>
    );
  }
);

const defaultDataBuilder = (data: any) => {
  return {
    ...data,
    checklist_title: data?.checklist_title || "",
    items:
      (data?.items || []).length > 0
        ? data?.items?.map((item: any) => ({
            key: v4(),
            data: {
              ...item,
              checklist_item_name: item.checklist_item_name || "",
              order: item.order || 1,
              is_important: item.is_important === true,
            },
          }))
        : [
            {
              key: v4(),
              data: {
                checklist_item_name: "",
                order: 1,
                is_important: false,
              },
            },
          ] || [
            {
              key: v4(),
              data: {
                checklist_item_name: "",
                order: 1,
                is_important: false,
              },
            },
          ],
  };
};
