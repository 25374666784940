import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Avatar, CloseIcon } from "components";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

interface ResourceMemberChipProps {
  data: any;
  onDelete: (data: any) => void;
  borderRadius?: string;
}

export const ResourceMemberChip = ({
  data,
  onDelete,
  borderRadius,
}: ResourceMemberChipProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction={"row"}
      gap={"8px"}
      sx={{
        py: "8px",
        alignItems: "center",
        borderRadius: borderRadius || "24px",
        border: "1px solid #CCCCCC",
        display: "flex",
        pl: "8px",
        pr: "12px",
      }}
    >
      <Avatar
        fullname={data.label}
        src={data.profile}
        sx={{
          width: "32px",
          height: "32px",
          aspectRatio: "1",
        }}
      />

      <Stack
        sx={{
          display: "flex",
          flex: "1",
          flexGrow: 1,
          flexShrink: 1,
          overflow: "hidden",
        }}
      >
        <Typography variant="body-medium">{data.label || ""}</Typography>
        <Typography
          variant="body-medium"
          sx={{
            color: theme.palette.lightBg.low,
          }}
        >
          {data.subLabel || ""}
        </Typography>
      </Stack>

      <Box
        sx={{
          aspectRatio: "1",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => onDelete(data)}
      >
        <CloseIcon />
      </Box>
    </Stack>
  );
};
