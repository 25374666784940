import { getRole } from "./CredentialData";

export const isSuperAdmin = () => {
  const role = getRole();
  return ["SUPERADMIN"].includes(role);
};

export const isAdmin = () => {
  const role = getRole();
  return ["ADMIN"].includes(role);
};

export const isUser = () => {
  const role = getRole();
  return !["SUPERADMIN", "ADMIN"].includes(role);
};
