import { v4 } from "uuid";

export const duplicateChecklistItem = (
  checklistItemList: Array<any>,
  item: any
) => {
  const index = checklistItemList.indexOf(item);
  const selectedData = { ...checklistItemList[index].data };
  let firstSection = checklistItemList.slice(0, index + 1);
  firstSection = [
    ...firstSection,
    {
      key: v4(),
      data: {
        checklist_item_name: selectedData.checklist_item_name,
        order: selectedData.order + 1,
        is_completed: selectedData.is_completed,
        is_important: selectedData.is_important,
      },
    },
  ];
  const secondSection = checklistItemList
    .slice(index + 1)
    ?.map((item: any) => ({
      ...item,
      data: {
        ...item.data,
        order: item.data.order + 1,
      },
    }));
  return [...firstSection, ...secondSection];
};

export const manageLibraryFiles = (data: any, libraryData: any) => {
  let newDataList = [];
  newDataList = data.items.filter(
    (item: any) =>
      item?.data?.item_id === undefined || validate(item, libraryData)
  );
  // console.log("🚀 ~ file: index.js:4 ~ manageLibraryFiles ~ newDataList:", newDataList)

  newDataList = [...fetchUnaddedItems(newDataList, libraryData)];
  // console.log("🚀 ~ file: index.js:5 ~ manageLibraryFiles ~ newDataList:", newDataList)
  if (newDataList.length > 20) {
    newDataList = newDataList.slice(0, 20);
  }
  return newDataList;
};

const fetchUnaddedItems = (
  newDataList: Array<any>,
  libraryDatalist: Array<any>
) => {
  let unAddedList = newDataList;

  for (const libraryData of libraryDatalist) {
    const lastOrder = unAddedList[unAddedList.length - 1]?.data.order || 0;
    //   console.log("🚀 ~ file: index.js:17 ~ fetchUnaddedItems ~ unAddedList[unAddedList.length - 1]:", unAddedList[unAddedList.length - 1].data.order)
    const result = libraryData.items
      .filter((item: any) =>
        unAddedList.every((newData) => item?.item_id !== newData?.data?.item_id)
      )
      .map((item: any, index: number) => ({
        key: item.item_id,
        data: {
          ...item,
          order: lastOrder + index + 1,
        },
      }));
    unAddedList = [...unAddedList, ...result];
  }
  return unAddedList;
};

const validate = (data: any, libraryDatalist: Array<any>) => {
  for (const libraryData of libraryDatalist) {
    if (
      libraryData.items.find(
        (item: any) => item.item_id === data?.data?.item_id
      ) !== undefined
    ) {
      return true;
    }
  }
  return false;
};

export const removeChecklistItem = (
  checklistItemList: Array<any>,
  item: any
) => {
  const index = checklistItemList.indexOf(item);
  const firstSection = checklistItemList.slice(0, index);
  const secondSection = checklistItemList.slice(index + 1).map((item) => ({
    ...item,
    data: {
      ...item.data,
      order: item.data.order - 1,
    },
  }));
  return [...firstSection, ...secondSection];
};

export const SwapChecklistElements = (
  array: Array<any>,
  field: any,
  index: number
) => {
  [array[index], array[index + 1]] = [
    {
      ...array[index + 1],
      data: { ...array[index + 1].data, [field]: array[index].data[field] },
    },
    {
      ...array[index],
      data: { ...array[index].data, [field]: array[index + 1].data[field] },
    },
  ];
  return array;
};
