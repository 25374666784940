import React from "react";

// Legacy Imports
import Box from "@mui/material/Box";

// Utilz
import { getResponsiveAppbarWidth } from "../../../layouts/helpers/app-bar-width";
import { useTheme } from "@mui/material";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

export const BottomStickyLayer = (props) => {
  const drawerWidth = 248;
  const theme = useTheme();
  return (
    <React.Fragment>
      <ThemeProvider>
        <Box
          sx={{
            width: "100%",
            height: "84px",
            position: "fixed",
            bottom: 0,
            right: 0,
            display: "block",
            zIndex: 1,
            backgroundColor: "#FFFFFF",
            borderTop: `1px solid ${theme.palette.neutral["010"]}`,
            boxShadow: "0px -2px 88px 0px rgba(0, 0, 0, 0.05)",
            // eslint-disable-next-line
            display: "flex",
            alignItems: "center",
            p: "0 32px 0 112px",
          }}
        >
          {props?.content}
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};
