import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Button, Drawer, BellDualIcon } from "components";
import React, { useContext, useEffect } from "react";
import { NotificationList } from "./components/NotificationList";
import { MqttContext } from "controller/context/MqttCreateContext";
import {
  useChatNotificationStore,
  useGlobalStore,
  useNotificationStore,
} from "controller";
import { debugConsole } from "utilz/helpers";
// @ts-ignore
import AudioFile from "assets/audio/notification.aac";
import {
  useClearAllNotifications,
  useMarkAllNotificationsAsSeen,
} from "controller/hooks/notification";
import { statusData } from "shared/data";
import { usePinnedStore } from "controller/store/pinned-chat/pinnedChatStore";

export const NotificationButton = () => {
  const audio = new Audio(AudioFile);
  const [open, setOpen] = React.useState(false);
  const mspId = useGlobalStore((state) => state.mspId);
  const resourceId = useGlobalStore((state) => state.resourceId);
  const addNewTicketId = useNotificationStore((state) => state.addNewTicketId);
  const closeOrReopenChatNotification = useChatNotificationStore(
    (state) => state.closeOrReopenChatNotification
  );
  const closeOrReopenPinnedChat = usePinnedStore(
    (state) => state.closeOrReopenPinnedChat
  );
  const addNotification = useNotificationStore(
    (state) => state.addNotification
  );
  const clearAllNotification = useNotificationStore(
    (state) => state.resetAllNotifications
  );
  const { mutateAsync: clearNotification, isLoading: isClearLoading } =
    useClearAllNotifications();
  const resetUnseenNotification = useNotificationStore(
    (state) => state.resetUnseenNotification
  );
  const setAllNotificationsAsSeen = useNotificationStore(
    (state) => state.setAllNotificationAsSeen
  );
  const unseenNotifications = useNotificationStore(
    (state) => state.unseenNotification
  );

  const {
    mutateAsync: markAllNotificationsAsSeen,
    isLoading: markAllAsReadLoading,
  } = useMarkAllNotificationsAsSeen();

  const theme = useTheme();

  const {
    connectMqtt,
    subscribeRoom,
    isConnected,
    unSubscribeRoom,
    mqttMessage,
  } = useContext(MqttContext);

  useEffect(() => {
    if (!isConnected) {
      connectMqtt();
    } else {
      subscribeRoom([
        `notification/${mspId}/all`,
        `notification/${mspId}/resource/${resourceId}`,
        `msp/${mspId}/count_new_tickets`,
      ]);
    }

    return () => {
      if (isConnected) {
        unSubscribeRoom([
          `notification/${mspId}/all`,
          `notification/${mspId}/resource/${resourceId}`,
          `msp/${mspId}/count_new_tickets`,
        ]);
      }
    };
  }, [isConnected, mspId, resourceId]);

  useEffect(() => {
    if (mqttMessage) {
      debugConsole(mqttMessage);
      if (
        mqttMessage.topic === `notification/${mspId}/all` ||
        mqttMessage.topic === `notification/${mspId}/resource/${resourceId}`
      ) {
        const newMessage = JSON.parse(mqttMessage?.message);
        debugConsole(newMessage);
        // All chat notifications are managed in Chat Notification component
        // Here we are just updating the notification list
        if (newMessage?.result?.type !== "new_chat_message") {
          addNotification({
            ...newMessage?.result,
            notification_id: newMessage?.result.mapping_id,
          });
          /// to disable chat feature in concurrency screen and pinned chat if service status is closed or cancelled
          /// and enable if current status is closed,cancelled and notification status is not
          disableorEnableChatNotification(newMessage?.result);
          // check if notification sound is enabled then only play the sound
          newMessage?.result?.payload?.resource_notification_sound &&
            audio.play();
        }
      }
      if (mqttMessage.topic === `msp/${mspId}/count_new_tickets`) {
        const newMessage = JSON.parse(mqttMessage?.message);
        addNewTicketId(newMessage?.service_details?.ticket_id);
      }
    }
  }, [mqttMessage]);

  const disableorEnableChatNotification = (message: any) => {
    try {
      const serviceId =
        message?.payload?.service_type === "ticket"
          ? message?.payload?.ticket_id
          : message?.payload?.task_id;

      const serviceStatus = message?.payload?.status_id;
      if (serviceId && serviceStatus) {
        closeOrReopenChatNotification(serviceId, serviceStatus);
        closeOrReopenPinnedChat(serviceId, serviceStatus);
      }
    } catch (e) {}
  };

  const onClear = async () => {
    try {
      await clearNotification({
        notificationType: "all",
        resourceId: resourceId || "",
      });

      clearAllNotification();
      resetUnseenNotification();
    } catch (error) {}
  };

  const markAllAsRead = async () => {
    try {
      await markAllNotificationsAsSeen({
        notificationType: "all",
        resourceId: resourceId || "",
      });
      resetUnseenNotification();
      setAllNotificationsAsSeen();
    } catch (error) {}
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        onClick={() => setOpen(true)}
        sx={{
          height: "40px",
          width: "40px",
          borderRadius: "6px",
          border: `1px solid ${theme.palette.neutral["020"]}`,
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          ":hover": {
            backgroundColor: theme.palette.neutral["010"],
          },
        }}
      >
        <BellDualIcon fontSize="large" />
      </Stack>

      {unseenNotifications > 0 && (
        <Box
          minWidth={"16px"}
          height={"16px"}
          bgcolor={
            unseenNotifications > 0 ? theme.palette.error.main : "transparent"
          }
          sx={{
            position: "absolute",
            top: "2px",
            right: "-6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            px: "4px",
            zIndex: 2,
          }}
        >
          <Typography variant="caption-3" color="#FFF">
            {unseenNotifications > 99 ? "99+" : unseenNotifications}
          </Typography>
        </Box>
      )}
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        title="Notifications"
        footer={
          <Stack
            direction={"row"}
            sx={{
              py: "8px",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: `1px solid ${theme.palette.neutral["015"]}`,
              boxShadow:
                " 0px -2px 4px 0px #0000000D, 0px -2px 2px 0px #00000005",
            }}
          >
            <Button
              variant="ghost"
              onClick={markAllAsRead}
              loading={markAllAsReadLoading}
            >
              Mark all as read
            </Button>
            <Button variant="ghost" onClick={onClear} loading={isClearLoading}>
              Clear all
            </Button>
          </Stack>
        }
      >
        <Stack sx={{ width: "400px", height: "100%", overflow: "hidden" }}>
          <NotificationList onItemClick={() => setOpen(false)} />
        </Stack>
      </Drawer>
    </Box>
  );
};
