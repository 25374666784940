import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoutIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#767B85"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M2.996 12h11.005M11 8.999 14 12l-3 3.001"
      />
      <path
        stroke="#767B85"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M5.997 9.136V7.398a2 2 0 0 1 1.61-1.96L18.61 3.03a2.147 2.147 0 0 1 2.393 2.167v13.806a2 2 0 0 1-2.34 1.973L7.659 19.083a2 2 0 0 1-1.662-1.973v-2.138"
      />
    </svg>
  </SvgIcon>
);
