import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getIntegrationSources,
  getRemoteDevicesList,
  updateIntegrationStatus,
} from "controller/api";
import {
  GetRemoteDeviceListInput,
  UpdateIntegrationStatusInput,
} from "shared/types/integrations";
import { debugConsole } from "utilz/helpers";

export const useGetIntegrationSources = () => {
  return useQuery({
    queryKey: ["integrationSources"],
    queryFn: () => getIntegrationSources(),
    onError: (err) => debugConsole(err),
  });
};

export const useUpdateIntegrationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateIntegrationStatusInput) => updateIntegrationStatus(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["integrationSources"]);
      },
    }
  );
};

export const useGetRemoteDevicesList = ({
  customerUserId,
  sourceName,
}: GetRemoteDeviceListInput) => {
  return useQuery({
    queryKey: ["remoteDevices", customerUserId, sourceName],
    queryFn: () => getRemoteDevicesList({ customerUserId, sourceName }),
    onError: (err) => debugConsole(err),
  });
};
