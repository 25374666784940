function notebookIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9907 17.5H4.9907C4.06987 17.5 3.32404 16.7542 3.32404 15.8333V4.16667C3.32404 3.24583 4.06987 2.5 4.9907 2.5H14.9907C15.9115 2.5 16.6574 3.24583 16.6574 4.16667V15.8333C16.6574 16.7542 15.9115 17.5 14.9907 17.5Z"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.48326 6.66667H13.3166"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.48326 13.3371H13.3166"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.48326 9.99626H13.3166"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M2.07404 6.66667H4.57404"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M2.07404 13.3371H4.4082"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M2.07404 9.99626H4.4082"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}

export default notebookIcon;