import React, { useState, useEffect } from "react";

// Services Imports
import { services } from "services";

// Legacy imports
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
// Other HOC Imports
import { CustomTypography } from "library/base";
import { Button, Input, SelectField, TimeInput } from "components";
import { TabSectionHeader } from "./general-tab-info/TabSectionHeader";
import { BottomStickyLayer, CustomCard } from "library/layers";
import { MapComponent } from "library/mounts";
import toast from "react-hot-toast";
// Utils Imports
import { ThemeProvider } from "utilz/theme/ThemeProvider";

// constants
import { useGlobalStore } from "controller/store/globalStore";
import { CustomRadioGroup } from "library/system/custom-form";
import dayjs from "dayjs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addAttributeToIntercom,
  useGetAllCityByStateId,
  useGetAllCountries,
  useGetAllCurrency,
  useGetAllStateByCountryId,
  useGetAllTimezone,
} from "controller";
import { TimeZoneList } from "shared";
import { debugConsole } from "utilz/helpers";
import { useQueryClient } from "@tanstack/react-query";
// Interface Component Props
// interface CountryFetchApiOptionProps {
//   id: string;
//   name: string;
//   iso3: string;
//   countrycode: string;
//   phone_code: string;
// }
// interface StateFetchApiOptionProps {
//   id: string;
//   state_name: string;
//   state_code: string;
//   country_name: string;
//   country_code: string;
// }
// interface CityFetchApiOptionProps {
//   name: string;
//   countryCode: string;
//   stateCode: string;
//   latitude: string;
//   longitude: string;
// }
// interface OptionProps {
//   key: string;
//   value: string;
//   label: string;
// }
interface CityOptionProps {
  key: string;
  value: string;
  label: string;
  latitude: string;
  longitude: string;
}
interface GeneralDataProps {
  company_name: string;
  brand_name: string;
  // business_email: string;
  address1: string;
  address2: string;
  country: string | number;
  state: string | number;
  city: string | number;
  postal_code: string;
  website: string;
  time_zone: string;
  operating_hours_from: string;
  operating_hours_to: string;
  default_currency: string;
  date_format: string;
  latitude: string;
  longitude: string;
  is_full_time: boolean;
}

const schema = yup.object({
  company_name: yup.string().required("Please enter coumpany name"),
  brand_name: yup.string().required("Please enter brand name"),
  // business_email: yup.string(),
  address1: yup.string().required("Please enter address"),
  address2: yup.string(),
  country: yup.string().required("Please select country"),
  postal_code: yup.string().required("Please enter address"),
  website: yup.string(),
  time_zone: yup.string().required("Please select timezone"),
  operating_hours_from: yup.string().required("Please select start time"),
  operating_hours_to: yup.string().required("Please select end time"),
  currency: yup.string().required("Currency is required"),
  date_format: yup.string(),
  is_full_time: yup.boolean(),
  state: yup.string(),
  city: yup.string(),
});

export const GeneralTabScreen = () => {
  const queryClient = useQueryClient();
  const time: string = dayjs().toISOString();
  /// useform section
  const currencyInitial = useGlobalStore((state) => state.currency);
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: "",
      brand_name: "",
      address1: "",
      address2: "",
      country: "",
      postal_code: "",
      website: "",
      time_zone: "",
      operating_hours_from: time,
      operating_hours_to: time,
      currency: currencyInitial?.defaultCurrency,
      date_format: "MM/DD/YYYY",
      is_full_time: true,
      state: "",
      city: "",
    },
    resolver: yupResolver(schema),
  });

  const resourceId = useGlobalStore((state) => state.resourceId);
  const mspId = useGlobalStore((state) => state.mspId);
  const theme = useTheme();
  // States
  const { data: timezones } = useGetAllTimezone();
  const { data: countries } = useGetAllCountries();
  // const [provinces, setProvinces] = useState<OptionProps[]>([]);
  const [edit, setEdit] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<any>({
    latitude: "",
    longitude: "",
  });
  const [isTimeInValid, setIsTimeInValid] = useState(false);
  const watchCountry = watch("country");
  const watchState = watch("state");
  const watchCity = watch("city");
  const watchDateFormat = watch("date_format");
  const { data: provinces } = useGetAllStateByCountryId(watchCountry);
  const { data: cities } = useGetAllCityByStateId(watchCountry, watchState);
  const isResourceTimeZone = useGlobalStore(
    (state) => state.isResourceTimezone
  );
  const setIsResourceTimeZone = useGlobalStore(
    (state) => state.setIsResourceTimeZone
  );
  const setTimezone = useGlobalStore((state) => state.setTimeZone);

  const [currentDetails, setCurrentDetails] = useState<any>({});
  // Effects
  useEffect(() => {
    fetchMspDetails();
  }, []);

  const { data: allCurrency } = useGetAllCurrency();

  useEffect(() => {
    if (loaded === true) {
      setValue("state", "");
      setValue("city", "");
    }
  }, [watchCountry]);

  useEffect(() => {
    if (loaded === true) {
      setValue("city", "");
    }
  }, [watchState]);

  useEffect(() => {
    if (
      watchCity &&
      watchCity != null &&
      Array.isArray(cities) &&
      cities.length > 0
    ) {
      updateGeoLocationByCitySelect();
    }
  }, [watchCity, cities]);

  const fetchState = () => {
    let isDisabled = false;
    if (Array.isArray(provinces) && provinces.length <= 0) {
      const value = -1;
      isDisabled = true;
      return value.toString();
    }
    return getValues().state;
  };

  // check if country or state list is empty
  // if true then that means there is no city to select
  //so we set -1 to state if the list is empty and
  // set country or state name to city based on available value

  const fetchCity = () => {
    if (cities === undefined || (Array.isArray(cities) && cities.length <= 0)) {
      let value: any = "";
      if (Array.isArray(provinces) && provinces.length > 0) {
        const states = (provinces || []).find(
          (item) => item.id === watchState.toString()
        );
        if (states !== undefined) {
          value = states.state_name;
        }
      } else {
        const country = (countries || []).find(
          (item: any) => item.id === watchCountry.toString()
        );

        if (country !== undefined) {
          value = country.name;
        }
      }
      return value;
    }
    return getValues().city;
  };

  function convertTimeStringToDayJs(timeString: string | null) {
    if (timeString !== undefined && timeString !== "" && timeString !== null) {
      return dayjs(
        `${dayjs().format("YYYY-MM-DD")} ${timeString}`
      ).toISOString();
    }
    return undefined;
  }

  // API Calls
  const fetchMspDetails = () => {
    setLoaded(false);
    try {
      services.controlCenterservices.mspServices.fetchMspById(mspId).subscribe({
        next: (response: any) => {
          if (
            response &&
            response?.success === true &&
            response?.statusCode === 200 &&
            response?.data
          ) {
            setData({
              latitude: response?.data?.latitude || "",
              longitude: response?.data?.longitude || "",
            });
            reset({
              company_name: response?.data?.company_name || "",
              brand_name: response?.data?.brand_name || "",
              address1: response?.data?.address1 || "",
              address2: response?.data?.address2 || "",
              country: response?.data?.country || "",
              state:
                response?.data?.state === -1 ? "" : response?.data?.state || "",
              city: response?.data?.city || "",
              postal_code: response?.data?.postal_code || "",
              website: response?.data?.website || "",
              time_zone: response?.data?.time_zone || "",
              operating_hours_from:
                (response?.data?.operating_hours_from &&
                  response?.data?.operating_hours_from !== null &&
                  convertTimeStringToDayJs(
                    response?.data?.operating_hours_from
                  )) ||
                time,
              operating_hours_to:
                (response?.data?.operating_hours_to &&
                  response?.data?.operating_hours_to !== null &&
                  convertTimeStringToDayJs(
                    response?.data?.operating_hours_to
                  )) ||
                time,
              currency: response?.data?.default_currency || "",
              date_format: response?.data?.date_format || "",
              is_full_time:
                response?.data?.is_full_time ||
                response?.data?.operating_hours_from ===
                  response?.data?.operating_hours_to,
            });
          }
          setCurrentDetails(response?.data);
          setTimeout(() => {
            setLoaded(true);
          }, 1000);
        },
        error: (error: any) => {
          setLoaded(true);
        },
      });
    } catch (err) {
      setLoaded(true);
      debugConsole(err);
    }
  };
  const updateMspDetails = () => {
    setLoaded(false);
    try {
      const data: GeneralDataProps = makePatchobject();
      services.controlCenterservices.mspServices
        .updateMspById(mspId, data)
        .subscribe({
          next: (response: any) => {
            if (
              response &&
              response?.success === true &&
              response?.statusCode === 200
            ) {
              if (
                !isResourceTimeZone &&
                data.time_zone &&
                data.time_zone !== null &&
                data.time_zone !== ""
              ) {
                setIsResourceTimeZone(false);
                setTimezone(data.time_zone);
              }
              queryClient.invalidateQueries(["msp", mspId]);
              toast.success("MSP details updated");
              // adding user attribute to intercom when company details are updated
              addAttributeToIntercom({
                event: "isCompanyUpdated",
              });
              setEdit(false);
              setLoaded(true);
            } else {
              toast.error(
                "Unable to update MSP details at the moment.Try again later"
              );
              setLoaded(true);
            }
          },
          error: (error: any) => {
            toast.error(
              "Unable to update MSP details at the moment.Try again later"
            );
            setLoaded(true);
          },
        });
    } catch (err) {
      toast.error("Unable to update MSP details at the moment.Try again later");
      setLoaded(true);
    }
  };

  // State Handlers
  const handleEdit = () => {
    if (edit !== false) {
      reset({
        company_name: currentDetails?.company_name || "",
        brand_name: currentDetails?.brand_name || "",
        address1: currentDetails?.address1 || "",
        address2: currentDetails?.address2 || "",
        country: currentDetails?.country || "",
        state: currentDetails?.state || "",
        city: currentDetails?.city || "",
        postal_code: currentDetails?.postal_code || "",
        website: currentDetails?.website || "",
        time_zone: currentDetails?.time_zone || "",
        operating_hours_from:
          (currentDetails?.operating_hours_from &&
            currentDetails?.operating_hours_from !== null &&
            convertTimeStringToDayJs(currentDetails?.operating_hours_from)) ||
          time,
        operating_hours_to:
          (currentDetails?.operating_hours_to &&
            currentDetails?.operating_hours_to !== null &&
            convertTimeStringToDayJs(currentDetails?.operating_hours_to)) ||
          time,
        currency: currentDetails?.default_currency || "",
        date_format: currentDetails?.date_format || "",
        is_full_time:
          currentDetails?.is_full_time ||
          currentDetails?.operating_hours_from ===
            currentDetails?.operating_hours_to,
      });
    }
    setEdit(edit === false);
  };

  // Event Handlers
  const updateGeoLocationByCitySelect = () => {
    try {
      const selected_city = cities.find(
        (item: Record<string, any>) => item?.name === watchCity
      );
      if (selected_city) {
        setData({
          ...data,
          latitude: selected_city?.latitude,
          longitude: selected_city?.longitude,
        });
      }
    } catch (err) {
      debugConsole(err);
    }
  };

  const onSubmitHandler = handleSubmit(
    ({ operating_hours_from, operating_hours_to }) => {
      try {
        if (!data.is_full_time) {
          if (dayjs(operating_hours_to).diff(operating_hours_from) < 0) {
            setIsTimeInValid(true);
            setError("operating_hours_from", {
              type: "custom",
              message: "Invalid operational hours",
            });
            setError("operating_hours_to", {
              type: "custom",
              message: "Invalid operational hours",
            });
            toast.error("Invalid operational hours");
            return;
          }
        }
        updateMspDetails();
      } catch (e) {
        toast.error("Invalid data");
      }
    },
    // eslint-disable-next-line no-empty-pattern
    ({}) => {
      toast.error("Invalid data");
    }
  );

  // };

  // Utilities
  const makePatchobject = (): GeneralDataProps => {
    const state = fetchState();
    const city = fetchCity();
    return {
      company_name: getValues().company_name || "",
      brand_name: getValues().brand_name || "",
      address1: getValues().address1 || "",
      address2: getValues().address2 || "",
      country: !Number.isNaN(parseInt(getValues().country))
        ? parseInt(getValues().country)
        : -1,
      state: !Number.isNaN(parseInt(state)) ? parseInt(state) : -1,
      city: city || "",
      postal_code: getValues().postal_code || "",
      website: getValues().website || "",
      time_zone: getValues().time_zone || "",
      operating_hours_from:
        dayjs(getValues().operating_hours_from).format("HH:mm:ss") || "",
      operating_hours_to:
        dayjs(getValues().operating_hours_to).format("HH:mm:ss") || "",
      default_currency: getValues().currency || "",
      date_format: getValues().date_format || "",
      latitude: data?.latitude || "",
      longitude: data?.longitude || "",
      is_full_time: getValues().is_full_time,
    };
  };

  const convertOperationalHours = (value: any) => {
    try {
      return dayjs(value).toISOString();
    } catch (e) {
      return "";
    }
  };

  // Renderer
  return (
    <React.Fragment>
      <ThemeProvider>
        <Box sx={{ margin: "24px 32px 82px 32px" }}>
          <Grid
            container
            columnSpacing={{ sm: "12px", md: "32px" }}
            rowSpacing={{
              xs: "56px",
              sm: "56px",
              md: "16px",
              lg: "24px",
              xl: "28px",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              {/* <Grid item xs={12} sm={7.5} md={7.5} sx={{ width: "100%" }}> */}
              <Stack
                spacing={{ sm: "12px", md: "16px", lg: "24px", xl: "28px" }}
              >
                <TabSectionHeader
                  loaded={true}
                  label={edit ? "Edit Company Details" : "Company Details"}
                  icon={edit ? "reply" : "edit"}
                  iconClick={handleEdit}
                />
                <CustomCard
                  borderRadius={"24px"}
                  outlined={true}
                  spacing={"0px"}
                  content={
                    <Box
                      sx={{
                        width: "100%",
                        height: "fit-content",
                        padding: "32px 32px 16px 32px",
                        overflow: "scroll",
                        "&:: -webkit-scrollbar": { display: "none" },
                      }}
                    >
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <Stack spacing={"32px"}>
                          <Grid
                            container
                            columnSpacing={"32px"}
                            rowSpacing={"16px"}
                          >
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <Input
                                fullWidth={true}
                                required={true}
                                name={"company_name"}
                                control={control}
                                label={"Company Name"}
                                disabled={edit === false}
                                placeholder={"Company name"}
                                error={errors.company_name ? true : false}
                                errorMessage={errors.company_name?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <Input
                                fullWidth={true}
                                required={true}
                                name={"brand_name"}
                                control={control}
                                label={"Brand Name"}
                                disabled={edit === false}
                                placeholder={"Brand name"}
                                error={errors.brand_name ? true : false}
                                errorMessage={errors.brand_name?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <Input
                                fullWidth={true}
                                name={"address1"}
                                required={true}
                                control={control}
                                label={"Address line 1"}
                                disabled={edit === false}
                                placeholder={"Address line 1"}
                                error={errors.address1 ? true : false}
                                errorMessage={errors.address1?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <Input
                                fullWidth={true}
                                name={"address2"}
                                required={false}
                                control={control}
                                label={"Address line 2"}
                                disabled={edit === false}
                                placeholder={"Address line 2"}
                                error={errors.address2 ? true : false}
                                errorMessage={errors.address2?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <SelectField
                                fullWidth={true}
                                name="country"
                                required={true}
                                control={control}
                                labelMappingKey="name"
                                valueMappingKey="id"
                                options={countries}
                                label={"Country"}
                                isSearchable
                                disabled={edit === false}
                                error={errors.country ? true : false}
                                errorMessage={errors.country?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <SelectField
                                fullWidth={true}
                                name="state"
                                required={false}
                                control={control}
                                options={provinces}
                                labelMappingKey="state_name"
                                valueMappingKey="id"
                                label={"State/Province"}
                                isSearchable
                                disabled={edit === false}
                                error={errors.state ? true : false}
                                errorMessage={errors.state?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <SelectField
                                fullWidth={true}
                                name="city"
                                required={false}
                                control={control}
                                options={cities}
                                labelMappingKey="name"
                                valueMappingKey="name"
                                label={"City"}
                                isSearchable
                                disabled={edit === false}
                                error={errors.city ? true : false}
                                errorMessage={errors.city?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <Input
                                fullWidth={true}
                                name={"postal_code"}
                                required={true}
                                control={control}
                                label={"Zip Code"}
                                disabled={edit === false}
                                placeholder={"Zip Code"}
                                error={errors.postal_code ? true : false}
                                errorMessage={errors.postal_code?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <Input
                                fullWidth={true}
                                name={"website"}
                                required={false}
                                control={control}
                                label={"Website"}
                                disabled={edit === false}
                                placeholder={"Website"}
                                error={errors.website ? true : false}
                                errorMessage={errors.website?.message}
                              />
                            </Grid>
                            <Grid item xs={6} md={6} sx={{ width: "100%" }}>
                              <SelectField
                                fullWidth={true}
                                name="time_zone"
                                required={true}
                                control={control}
                                isGrouped={true}
                                groupedOptions={TimeZoneList}
                                labelMappingKey="label"
                                valueMappingKey="value"
                                label={"Time Zone"}
                                isSearchable
                                disabled={edit === false}
                                error={errors.time_zone ? true : false}
                                errorMessage={errors.time_zone?.message}
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                      </Box>
                    </Box>
                  }
                />
                <CustomCard
                  borderRadius={"24px"}
                  outlined={true}
                  spacing={"0px"}
                  content={
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ padding: "32px" }}>
                        {/* <Stack spacing={"24px"}>
                          {
                            <CustomTypography
                              size={"EXTRA-SMALL"}
                              color={"PRIMARY-MAIN"}
                              variant={"REGULAR"}
                              content={"Operating Hours"}
                            />
                          }
                          {
                            <CustomRadioGroup
                              name={"operating_hours"}
                              disabled={edit === false}
                              value={
                                watch("is_full_time") === true
                                  ? "24hours"
                                  : "normal"
                              }
                              options={[
                                {
                                  key: "operating_hours-24_hours-key",
                                  label: "24 hours",
                                  value: "24hours",
                                },
                                {
                                  key: "operating_hours-normal-key",
                                  label: "Normal",
                                  value: "normal",
                                },
                              ]}
                              handle={({ field, value }) => {
                                if (value === "24hours") {
                                  const time = dayjs().toISOString();

                                  setValue("operating_hours_from", time);
                                  setValue("operating_hours_to", time);
                                  setValue("is_full_time", true);
                                  // setCustomOperatingHours(false);
                                }
                                if (value === "normal") {
                                  setValue("operating_hours_from", "");
                                  setValue("operating_hours_to", "");
                                  setValue("is_full_time", false);
                                }
                              }}
                            />
                          }

                          {!watch("is_full_time") && (
                            <Stack
                              spacing={"32px"}
                              direction={"row"}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <TimeInput
                                error={
                                  errors.operating_hours_from ? true : false
                                }
                                value={watch("operating_hours_from")}
                                disabled={edit === false}
                                onChangeHandler={(value) => {
                                  setIsTimeInValid(false);
                                  setValue(
                                    "operating_hours_from",
                                    convertOperationalHours(value)
                                  );
                                  setValue("operating_hours_to", "");
                                }}
                              />
                              <CustomTypography
                                size={"EXTRA-SMALL"}
                                color={"PRIMARY-MAIN"}
                                variant={"BOLD"}
                                content={"To"}
                              />
                              <TimeInput
                                value={watch("operating_hours_to")}
                                disabled={edit === false}
                                error={errors.operating_hours_to ? true : false}
                                onChangeHandler={(value) => {
                                  setIsTimeInValid(false);
                                  setValue(
                                    "operating_hours_to",
                                    convertOperationalHours(value)
                                  );
                                }}
                              />
                            </Stack>
                          )}
                        </Stack> */}
                        <Stack gap="24px">
                          <Box width="40%">
                            <SelectField
                              name="currency"
                              label="Currency"
                              required={true}
                              control={control}
                              options={allCurrency?.data}
                              labelMappingKey="currency_code"
                              valueMappingKey="mapping_id"
                              disabled={edit === false}
                              error={!!errors.currency}
                              errorMessage={errors.currency?.message}
                            />
                          </Box>
                        </Stack>
                      </Box>
                      <Divider />
                      <Box sx={{ padding: "32px" }}>
                        <Stack spacing={"24px"}>
                          {
                            <CustomTypography
                              size={"EXTRA-SMALL"}
                              color={"PRIMARY-MAIN"}
                              variant={"REGULAR"}
                              content={"Date Format"}
                            />
                          }
                          {
                            <CustomRadioGroup
                              name={"date_format"}
                              value={watchDateFormat}
                              disabled={edit === false}
                              options={[
                                {
                                  key: "date_format-dd_mm_yyyy-key",
                                  label: "DD/MM/YYYY",
                                  value: "DD/MM/YYYY",
                                },
                                {
                                  key: "date_format-mm_dd_yyyy-key",
                                  label: "MM/DD/YYYY",
                                  value: "MM/DD/YYYY",
                                },
                                {
                                  key: "date_format-yyyy_mm_dd-key",
                                  label: "YYYY/MM/DD",
                                  value: "YYYY/MM/DD",
                                },
                              ]}
                              handle={({ field, value }) =>
                                setValue("date_format", value)
                              }
                            />
                          }
                        </Stack>
                      </Box>
                    </Box>
                  }
                />
              </Stack>
            </Grid>
            {/* <Grid item xs={12} sm={4.5} md={4.5} sx={{ width: "100%" }}>
              <Stack
                spacing={{ sm: "12px", md: "16px", lg: "24px", xl: "28px" }}
              >
                <TabSectionHeader loaded={true} label={"Location Details"} />
                <CustomCard
                  borderRadius={"24px"}
                  outlined={true}
                  spacing={"0px"}
                  content={
                    <Stack
                      spacing={{
                        xs: "12px",
                        sm: "12px",
                        md: "16px",
                        lg: "24px",
                        xl: "28px",
                      }}
                      sx={{
                        width: "100%",
                        p: "32px",
                        height: "70vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Input
                        fullWidth={true}
                        name={"city"}
                        value={watchCity}
                        label={"City"}
                        disabled={true}
                        placeholder={"city"}
                        onChange={() => {}}
                      />
                      {loaded ? (
                        <MapComponent
                          latitude={Number(data?.latitude) || 0}
                          longitude={Number(data?.longitude) || 0}
                          label={`${watchCity}`}
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={"100%"}
                          sx={{
                            borderRadius: "16px",
                          }}
                        />
                      )}
                    </Stack>
                  }
                />
              </Stack>
            </Grid> */}
          </Grid>
        </Box>

        {edit && (
          <BottomStickyLayer
            content={
              <Stack
                spacing={"16px"}
                width={"100%"}
                direction={"row-reverse"}
                // sx={{
                //   position: "absolute",
                //   bottom: "0px",
                //   bgcolor: "white",
                //   pt: "14px",
                //   pb:'8px',
                //   px: "64px",
                //   borderTop: `1px solid ${theme.palette.neutral['010']}`,
                //   boxShadow:'0px -2px 88px 0px rgba(0, 0, 0, 0.05)',
                // }}
              >
                <Button
                  variant="primary"
                  disabled={loaded === false}
                  onClick={onSubmitHandler}
                >
                  Save
                </Button>
                <Button
                  variant="secondary"
                  disabled={loaded === false}
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </Button>
              </Stack>
            }
          />
        )}
      </ThemeProvider>
    </React.Fragment>
  );
};
