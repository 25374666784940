import { Stack, Typography, useTheme } from "@mui/material";
import { Button } from "components/button";
import { BellIcon } from "components/icons";
import { useGlobalStore } from "controller";
import React from "react";
import { useNavigate } from "react-router-dom";
import { debugConsole } from "utilz/helpers";

export const PaymentFailedBanner = ({
  showButton = true,
}: {
  showButton?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const role = useGlobalStore((state) => state.role);
  return (
    <Stack
      sx={{
        backgroundColor: "#F5E755",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        p: "8px 32px",
        position: "relative",
      }}
    >
      <Stack direction={"row"} gap={"16px"}>
        <BellIcon htmlColor={theme.palette.lightBg.main} />
        <Stack direction={"row"} gap={"4px"}>
          <Typography variant="body-medium">
            <Typography
              variant="body-medium"
              component={"span"}
              fontWeight={700}
            >
              Payment Failed
            </Typography>
            {role === "SUPERADMIN"
              ? " - Our last attempted charge was unsuccessful. Please update your payment method as soon as possible."
              : " - Please contact your admin to update your payment details as soon as possible."}
          </Typography>
        </Stack>
      </Stack>
      {role === "SUPERADMIN" && showButton && (
        <Button
          size="small"
          variant="ghost"
          isResponsive={false}
          sx={{
            ":hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              fontWeight: 700,
            },
          }}
          onClick={() => {
            navigate("/app/control-center/company-settings/subscription");
          }}
        >
          Update payment
        </Button>
      )}
    </Stack>
  );
};
