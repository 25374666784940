import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckCircleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M20 11a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7.47 9.97a.75.75 0 0 1 1.06 0l1.72 1.72 3.22-3.22a.75.75 0 1 1 1.06 1.06l-3.749 3.75a.75.75 0 0 1-1.061 0l-2.25-2.25a.75.75 0 0 1 0-1.06Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
