import { getResourceId } from "utilz/helpers";
import { immer } from "zustand/middleware/immer";

interface TimesheetState {
  timesheet: {
    notSubmitted: {
      status?: string;
      year?: number;
      month?: number;
    };
    submitted: {
      status?: string;
      year?: number;
      month?: number;
    };
    approvals: {
      status?: string;
      year?: number;
      month?: number;
      resources?: Array<string>;
    };
  };
}

interface TimesheetAction {
  setTimesheetFilter: ({
    type,
    key,
    value,
  }: {
    type: "submitted" | "notSubmitted" | "approvals";
    key: "status" | "year" | "month" | "resources";
    value: string;
  }) => void;
  resetTimesheetFilter: () => void;
}

export interface TimesheetInterface extends TimesheetState, TimesheetAction {}

export const timesheetSlice = immer<TimesheetState & TimesheetAction>(
  (set, get) => ({
    timesheet: {
      submitted: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        status: "all",
      },
      notSubmitted: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        status: "all",
      },
      approvals: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        status: "all",
        resources: [],
      },
    },
    setTimesheetFilter: ({ type, key, value }) =>
      set((state) => {
        state.timesheet = {
          ...state.timesheet,
          [type]: {
            ...state.timesheet[type],
            [key]: value,
          },
        };
      }),
    resetTimesheetFilter: () =>
      set((state) => {
        state.timesheet = {
          submitted: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            status: "all",
          },
          notSubmitted: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            status: "all",
          },
          approvals: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            status: "all",
            resources: [],
          },
        };
      }),
  })
);
