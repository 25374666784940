import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CustomTypography } from "../../../../../../library/base";
import { ReturnContext } from "../../../../../../contexts/return-context";
import { ProjectBoardTab, TicketBoardTab } from "./Tabs";
import { Tabs, Tab } from "components";

const tabs = [
  {
    label: "Tickets",
    tab: <TicketBoardTab />,
  },
  {
    label: "Projects",
    tab: <ProjectBoardTab />,
  },
  // REQUEST: {
  //     label: 'Requests',
  //     tab: <BoardRequestTab />
  // },
];
export const ServiceDeskConfigurationBoardsComponent = (prop: any) => {
  const returnContext: any = useContext(ReturnContext);

  useEffect(() => {
    returnContext?.setEnableReturn?.(true);
  }, [prop]);

  const [tab, setTab] = useState(0);

  const changeSelectedTab = (event: any, newValue: any) => {
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={
          {
            // marginBottom: {
            //   xl: "140px",
            //   lg: "80px",
            //   md: "80px",
            //   sm: "60px",
            // },
          }
        }
      >
        <Box
          sx={{
            px: "32px",
            py: "24px",
          }}
        >
          <CustomTypography
            content="Boards"
            size="MEDIUM-ALPHA"
            variant="BOLD"
          />
        </Box>

        <Tabs
          value={tab}
          onChange={changeSelectedTab}
          sx={{
            px: "16px",
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={`config_board_${index}_${tab.label}`} label={tab.label} />
          ))}
        </Tabs>
        <Box
          sx={{
            marginTop: "16px",
            px: "32px",
          }}
        >
          {tabs[tab]?.tab}
        </Box>
      </Box>
    </React.Fragment>
  );
};
