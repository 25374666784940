import { Box, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  CustomButton,
  CustomTypography,
} from "../../../../../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../../../../../library/icon-library";
import { CustomCheckBox } from "../../../../../../../../../../../../library/system/custom-form";
import {
  CustomAutoCompleteTextField,
  PriorityItem,
  TemplateAttchmentModal,
} from "../../../../../component";
import { TICKET_DETAILS_CONSTANT, tagBuilder } from "./constants";
import TimeRangeTextfield from "../../../../../../../Components/time-range-textField";
import { useGlobalStore } from "controller/store/globalStore";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

const TicketDetailsForm = forwardRef((props, ref) => {
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    data: {},
    isAttachmentModalopen: false,
  });

  useEffect(() => {
    setState({
      ...state,
      data: props.data,
    });
  }, [props]);

  const onChangeValueHandler = (data) => {
    var newData = state.data;
    newData[data.field] = data.value;

    setState({
      ...state,
      data: newData,
    });
  };

  const onSubmitHandler = () => {
    // var validate = validateFormDetails(state.data, TICKET_DETAILS_CONSTANT);
    // if (validate === true) {
    //     props.onsubmit(state.data);
    // }
    props.onsubmit(state.data);
  };

  useImperativeHandle(ref, () => ({ submit: onSubmitHandler }));

  const onAttachmentSubmitHandler = (data) => {
    var newData = state.data;
    newData[TICKET_DETAILS_CONSTANT.attachments.name] = data;
    setState({
      ...state,
      data: newData,
      isAttachmentModalopen: false,
    });
  };

  return (
    <Stack
      flex={1}
      sx={{
        width: "100%",
      }}
    >
      {state.isAttachmentModalopen ? (
        <TemplateAttchmentModal
          isOpen={state.isAttachmentModalopen}
          value={state.data[TICKET_DETAILS_CONSTANT.attachments.name] || []}
          onSubmit={onAttachmentSubmitHandler}
          onCancel={() => {
            setState({
              ...state,
              isAttachmentModalopen: false,
            });
          }}
        />
      ) : null}
      <Stack
        sx={{
          py: "40px",
          px: "32px",
          flex: "1",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            border: "1px solid #cccccc",
            borderRadius: "16px",
            height: "fit-content",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            px: "16px",
            py: "24px",
            gap: "24px",
          }}
        >
          <CustomTypography
            content="Ticket Details"
            size="SMALL-ALPHA"
            variant="BOLD"
          />
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
            columnSpacing={"24px"}
            rowSpacing={"24px"}
          >
            {Object.entries(TICKET_DETAILS_CONSTANT.top).map(([key, item]) => (
              <Grid
                item
                key={`template-ticket-details-${item.name}`}
                {...{ xs: 2, sm: 2.5, md: 3, lg: 3 }}
              >
                {(function () {
                  if (item.type === "select") {
                    const SelectorComponent = item.component;
                    return (
                      <SelectorComponent
                        label={item.label}
                        value={state.data[item.name] || ""}
                        name={item.name}
                        changeHandler={onChangeValueHandler}
                        populator={item.getData}
                        dependency={item.dependecy}
                        triggerValue={[mspId, state.data[item.triggerValue]]}
                        menuItemBuilder={(option) => (
                          <PriorityItem priority={option.value} />
                        )}
                      />
                    );
                  }
                  return (
                    <TimeRangeTextfield
                      supportDoubleValue={true}
                      label={item.label}
                      value={state.data[item.name] || ""}
                      name={item.name}
                      changeHandler={onChangeValueHandler}
                      placeholder={item.place_holder}
                    />
                  );
                })()}
              </Grid>
            ))}
            <Grid
              item
              key={`template-ticket-details-${TICKET_DETAILS_CONSTANT.tags.name}`}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <CustomAutoCompleteTextField
                dependency={TICKET_DETAILS_CONSTANT.tags.dependency}
                triggerValue={[mspId]}
                populator={TICKET_DETAILS_CONSTANT.tags.populator}
                label={TICKET_DETAILS_CONSTANT.tags.label}
                value={
                  tagBuilder(state.data[TICKET_DETAILS_CONSTANT.tags.name]) ||
                  []
                }
                name={TICKET_DETAILS_CONSTANT.tags.name}
                onChange={onChangeValueHandler}
                placeholder={TICKET_DETAILS_CONSTANT.tags.place_holder}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
});
TicketDetailsForm.propTypes = {
  data: PropTypes.object.isRequired,
  onsubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
TicketDetailsForm.defaultProps = {
  data: {},
  onsubmit: () => {},
  onCancel: () => {},
};

export default TicketDetailsForm;
