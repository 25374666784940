import React, { useState, useEffect, forwardRef, useRef } from "react";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Stack from "@mui/material/Stack";

// HOC Imports
import {
  CustomTypography,
  CustomButton,
} from "../../../../../../../../../library/base";

// Components
import {
  ResourceTypeCreateGeneralForm,
  PermissionsCreateForm,
} from "./components";
import { initialPermissionDataBuilder } from "pages/modules/control-center/resources-and-permissions/components/permission-component/Utils";
// Constants
import { STEPS } from "./steps";
import { PermissionComponent } from "../../../../../components";
import { Modal } from "components";
import { SlideModal } from "library/layers";
import { useGlobalStore } from "controller/store/globalStore";

const CreateResourceTypeModal = (props, ref) => {
  const mspId = useGlobalStore((state) => state.mspId);
  const resourceId = useGlobalStore((state) => state.resourceId);

  const [resourceType, setResourceType] = useState("");
  const [desciption, setDescription] = useState("");
  const [permissions, setPermissions] = useState(
    initialPermissionDataBuilder([])
  );

  // State
  const [state, setState] = useState({
    steps: {
      stepList: Object.values(STEPS).map((element, index) => {
        return {
          ...element,
          active: index === 0 ? true : false,
          completed: false,
        };
      }),
      activeStep: STEPS["step_1"].value,
    },
  });
  // Imperative handle

  //   // Effects
  //   useEffect(() => {
  //     console.log(state);
  //   }, [state]);
  //   useEffect(() => {
  //     console.log(props);
  //   }, [props]);

  // Event handlers
  const handleNext = () => {
    try {
      if (state?.steps["activeStep"] === state?.steps["stepList"].length) {
        console.log("Submitting");
        saveItem();
      } else {
        const current = state?.steps["stepList"].map((element, index) => {
          return {
            ...element,
            active:
              element?.active === true
                ? false
                : element?.value === state?.steps["activeStep"] + 1
                ? true
                : element?.active,
          };
        });
        const activeStep = state?.steps["activeStep"] + 1;
        setState({
          ...state,
          steps: {
            ...state?.steps,
            stepList: current,
            activeStep: activeStep,
          },
        });
      }
    } catch (err) {
      console.log("[ERROR] Handling Next");
      console.log(err);
    }
  };
  const handlePrev = () => {
    if (state?.steps["activeStep"] > 1) {
      const current = state?.steps["stepList"].map((element, index) => {
        return {
          ...element,
          active:
            element?.value + 1 === state.steps["activeStep"] ? true : false,
        };
      });
      const activeStep = state?.steps["activeStep"] - 1;
      setState({
        ...state,
        steps: {
          ...state?.steps,
          stepList: current,
          activeStep: activeStep,
        },
      });
    }
  };

  const resourcePermissionDataBuilder = (data, resourceId, mspId) => {
    return Object.values(data).map((item) => ({
      permission_name: item?.permission_name || "",
      permission_key: item?.permission_key || "",
      resource_id: resourceId,
      is_enabled: item?.is_enabled || false,
      msp_id: mspId,
    }));
  };

  const saveItem = () => {
    const data = resourcePermissionDataBuilder(permissions, resourceId, mspId);
    props.onSubmit({
      resource_type_name: resourceType,
      resource_type_description: desciption,
      permissions: data,
    });
  };

  // Renderer
  return (
    <SlideModal
      open={props?.open}
      modalSize={"medium"}
      modalTitle={"Add Resource Type"}
      maxHeight={true}
      isLoading={props.isLoading}
      headerBottomComponent={
        <Stepper
          nonLinear
          activeStep={state?.steps["activeStep"]}
          sx={{
            px: 0,
            "& .Mui-active": {
              "& .MuiStepConnector-line": {
                borderColor: "#198038",
              },
            },
          }}
        >
          {state?.steps["stepList"].map((element) => {
            return (
              <Step
                key={element?.key}
                // completed={completed[index]}
                sx={{ px: 0, "& .MuiStepIcon-active": { color: "red" } }}
              >
                <Stack
                  direction={"row"}
                  spacing={"2px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "32px",
                      height: "32px",
                      backgroundColor:
                        element?.active === true ? "#FFF" : "#E3E3E3",
                      border:
                        element?.active === true
                          ? "2px solid #198038"
                          : "2px solid#E3E3E3",
                      borderRadius: "50%",
                    }}
                  >
                    <CustomTypography
                      size={"SMALL-BETA"}
                      color={
                        element?.active === true
                          ? "SEMANTIC_GREEN"
                          : "PRIMARY-MAIN"
                      }
                      variant={"MEDIUM"}
                      content={element?.value.toString()}
                    />
                  </Box>
                  <CustomTypography
                    size={"SMALL-BETA"}
                    color={"PRIMARY-MAIN"}
                    variant={"MEDIUM"}
                    content={element?.label}
                  />
                </Stack>
              </Step>
            );
          })}
        </Stepper>
      }
      modalBody={
        state?.steps["activeStep"] === 1 ? (
          <ResourceTypeCreateGeneralForm
            values={[resourceType, desciption]}
            state={[setResourceType, setDescription]}
          />
        ) : state?.steps["activeStep"] === 2 ? (
          <PermissionComponent
            data={permissions}
            onChange={(data) => {
              setPermissions(data.value);
            }}
          />
        ) : (
          ""
        )
      }
      modalFooter={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            mt: "24px",
          }}
        >
          <Stack direction={"row"} spacing={"12px"}>
            {state?.steps["activeStep"] === 1 ? (
              ""
            ) : (
              <CustomButton
                varient={"secondary"}
                size="sm"
                label="Back"
                isTrailingIcon={false}
                icon=""
                onClick={handlePrev}
              />
            )}

            <CustomButton
              varient={"primary"}
              size="sm"
              label={
                state?.steps["activeStep"] === state?.steps?.stepList.length
                  ? "Save"
                  : "Next"
              }
              isTrailingIcon={false}
              icon=""
              onClick={handleNext}
            />
          </Stack>
        </Box>
      }
      closeHandle={() => props.closeHandle()}
      headerActionCallback={() => {}}
    />
  );
};

export default CreateResourceTypeModal;
