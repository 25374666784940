import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";

interface AttachmentDnDProps {
  onDrop: (attachmentFiles: any) => void;
  sx?: SxProps<Theme>;
}

const sizeValidator = (file: any) => {
  if (file.size > 10485760) {
    toast.error("File size larger than 10MB");
    return {
      code: "size-too-large",
      message: "Size is larger than expected",
    };
  }

  return null;
};
export const AttachmentDragAndDrop = ({ onDrop, sx }: AttachmentDnDProps) => {
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: (fileRejections) => {},
    // accept: {
    //   "image/*": [".png", ".jpe", ".jpeg", ".jpg"],
    //   "application/pdf": [".pdf", ".csv"],
    //   "application/msword": [".doc"],
    //   "application/vnd.ms-excel": [".xls", ".xlt", ".xla"],
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    //     ".xlsx",
    //   ],
    //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //     [".docs"],
    // },
    validator: sizeValidator,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        cursor: "pointer",
        backgroundColor: theme.palette.secondary["purple-tint-3"],
        height: "64px",
        borderRadius: "8px",
        border: `1px dashed ${theme.palette.purple.main}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <input {...getInputProps()} accept="image/*" />
      <Typography variant="body-medium">
        Drag & drop files here or{" "}
        <Typography
          component="span"
          color={theme.palette.primary.main}
          sx={{ textDecoration: "underline" }}
        >
          Browse
        </Typography>
      </Typography>
    </Box>
  );
};
