import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAuditTrail } from "controller/api";
import { GetAuditTrailInput } from "shared";

export const useGetAuditTrail = ({
  serviceId,
  serviceType,
}: GetAuditTrailInput) => {
  return useQuery({
    queryKey: ["audit_trail", serviceId, serviceType],
    queryFn: () => getAuditTrail({ serviceId, serviceType }),
    enabled: serviceId ? true : false,
    onError: (error) => console.log(error),
  });
};
