import { v4 } from "uuid";

const priority: Record<string, any> = {
  critical: {
    key: "configuraion_sla_priority_critical",
    value: "critical",
    label: "Critical",
  },
  high: {
    key: "configuraion_sla_priority_high",
    value: "high",
    label: "High",
  },
  medium: {
    key: "configuraion_sla_priority_medium",
    value: "medium",
    label: "Medium",
  },
  low: {
    key: "configuraion_sla_priority_low",
    value: "low",
    label: "Low",
  },
};
export const PRIORITIES = ["critical", "high", "medium", "low"];

export const getAvailablePriorities = (usedPriorities: Array<any> = []) => {
  const newPriorities = PRIORITIES.filter(
    (item: string) => !usedPriorities.includes(item)
  );
  return [...newPriorities].map((item: string) => priority[item]);
};

export const validateSlaDetails = (data: any) => {
  const isSlaValid =
    data?.sla_name &&
    data?.sla_name !== "" &&
    data?.description &&
    data?.description !== "";
  const durations = ["hours", "minutes"];
  const isSlaRuleValid = data.sla_rules.every(
    (rule: any) =>
      rule?.priority &&
      rule?.priority !== null &&
      rule?.priority !== "" &&
      rule?.first_response &&
      rule?.first_response !== "" &&
      !durations.includes(rule?.first_response.trim()) &&
      rule?.resolution_time &&
      rule?.resolution_time !== "" &&
      !durations.includes(rule?.resolution_time.trim()) &&
      rule?.warning_time &&
      rule?.warning_time !== "" &&
      !durations.includes(rule?.warning_time.trim())
  );

  return isSlaValid === true && isSlaRuleValid === true;
};

export const SlaItemBuilder = (data?: any) => {
  const item = {
    priority: data?.priority || "",
    first_response: data?.first_response || "",
    resolution_time: data?.resolution_time || "",
    warning_time: data?.warning_time || "",
  };
  if (data?.mapping_id !== undefined) {
    return {
      ...item,
      mapping_id: data?.mapping_id,
    };
  }
  return item;
};

export const SlaMainBuilder = (data: any) => {
  const rules = data?.sla_rules || data?.rules || [];
  const item = {
    sla_name: data?.sla_name || "",
    description: data?.description || "",
    sla_rules:
      rules.length > 0
        ? [
            ...rules.map((item: any) => ({
              key: v4(),
              data: SlaItemBuilder(item),
            })),
          ]
        : [
            {
              key: v4(),
              data: SlaItemBuilder(),
            },
          ],
  };

  if (data?.mapping_id !== undefined) {
    return {
      ...item,
      mapping_id: data?.mapping_id,
    };
  }

  return item;
};
