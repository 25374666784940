import { SvgIcon, SvgIconProps } from "@mui/material";

export const ExpenseTileIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="8.99884"
        cy="9.99933"
        rx="6.0025"
        ry="2.50104"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0013 13.5007C15.0013 14.882 12.3139 16.0018 8.99884 16.0018C5.68375 16.0018 2.99634 14.882 2.99634 13.5007"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.004 9.99927C21.004 11.3806 18.3166 12.5003 15.0015 12.5003"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0013 9.99927V17.5024C15.0013 18.8837 12.3139 20.0034 8.99884 20.0034C5.68375 20.0034 2.99634 18.8837 2.99634 17.5024V9.99927"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.004 6.4978V14.0009C21.004 15.3822 18.3166 16.502 15.0015 16.502"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.49899 7.50829C9.202 7.25335 9.02143 6.88861 8.99878 6.49787C8.99878 5.11729 11.6899 3.99683 15.0013 3.99683C18.3127 3.99683 21.0038 5.11729 21.0038 6.49787C21.0038 7.87844 18.3127 8.99891 15.0013 8.99891C14.8312 8.99891 14.6611 8.99891 14.5011 8.98891"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </SvgIcon>
);
