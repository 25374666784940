import { LinearProgress } from "@mui/material";
import PropTypes from "prop-types";

function CustomLinearProgress(props) {
  return (<LinearProgress variant="determinate" value={props.progress}
    sx={{
      "&.MuiLinearProgress-root": {
        height: "10px",
        backgroundColor: "#cccccc",
        "&	.MuiLinearProgress-bar1Determinate": {
          backgroundColor: "#198038",
        }
      },

    }}
  />);
}

CustomLinearProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};
CustomLinearProgress.defaultProps = {
  progress: 0,
};

export default CustomLinearProgress;