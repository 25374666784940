import { ChatNotificationSchema, statusData } from "shared";
import { immer } from "zustand/middleware/immer";
const disabledStatus: Array<string> = [statusData.CLOSED, statusData.CANCELLED];
interface ChatNotificationState {
  chatNotificationIds: Array<string>;
  unseenChatNotification: number;
  allChatNotifications: Array<any>;
  selectedChatNotification?: any;
}

interface ChatNotificationAction {
  setChatNotifications: (value: Array<any>) => void;
  addChatNotification: (
    value: Record<string, any>,
    addAsSeen?: boolean
  ) => void;
  setChatNotificationAsSeen: (serviceId: string) => void;
  resetAllChatNotifications: () => void;
  resetUnseenChatNotification: () => void;
  setSelectedChatNotification: (value: any) => void;
  closeOrReopenChatNotification: (serviceId: string, statusId: string) => void;
}

export interface ChatNotificationInterface
  extends ChatNotificationState,
    ChatNotificationAction {}

export const chatNotificationSlice = immer<
  ChatNotificationState & ChatNotificationAction
>((set, get) => ({
  allChatNotifications: [],
  chatNotificationIds: [],
  unseenChatNotification: 0,
  selectedChatNotification: null,

  setChatNotifications(value) {
    set((state) => {
      let count = 0;
      state.allChatNotifications = value.map((item) => {
        count += item.unseenCount || 0;
        return {
          ...item,
          notification:
            item.notification && item.notification.length > 0
              ? item.notification[0]
              : {},
        };
      });
      const ids: Array<string> = [];
      value.map((item) => {
        ids.push(item.service_id);
      });
      state.chatNotificationIds = ids;
      state.unseenChatNotification = count;
    });
  },
  addChatNotification(value, addAsSeen = false) {
    set((state) => {
      if (!state.chatNotificationIds.includes(value.mapping_id)) {
        const index = state.allChatNotifications.findIndex(
          (item) =>
            item.service_id === value.service_id &&
            item.service_type === value.payload.service_type
        );
        let existingNotification: ChatNotificationSchema =
          index > -1 ? state.allChatNotifications[index] : null;
        if (existingNotification) {
          existingNotification = {
            ...existingNotification,
            service_status: value?.payload?.status_id,
            unseenCount: addAsSeen ? 0 : existingNotification.unseenCount + 1,
            notification: {
              created_at: value.payload.created_at,
              sender_details: value.sender_details,
              is_seen: false,
              message: value.message,
              notification_id: value.mapping_id,
            },
          };
          state.allChatNotifications.splice(index, 1);
        } else {
          existingNotification = {
            u_id: value.payload.u_id,
            resource_notification_sound: true,
            assignees: value.payload.assignees || [],
            service_type: value.payload.service_type,
            customer_id: value.payload.customer_id,
            customer_user_id: value.payload.customer_user_id,
            notification_message_agent:
              value.payload.notification_message_agent,
            service_id: value.service_id,
            service_summary: value.payload.summary,
            service_status: value?.payload?.status_id,
            status_name: value.payload.status_name,
            unseenCount: addAsSeen ? 0 : 1,
            notification: {
              created_at: value.payload.created_at,
              sender_details: value.sender_details,
              is_seen: false,
              message: value.message,
              notification_id: value.mapping_id,
            },
            project_id: value?.payload?.project_id,
          };
        }

        state.allChatNotifications.unshift(existingNotification);
        if (!addAsSeen) {
          state.unseenChatNotification = state.unseenChatNotification + 1;
        }
      }
    });
  },
  resetAllChatNotifications() {
    set((state) => {
      state.allChatNotifications = [];
      state.chatNotificationIds = [];
    });
  },
  setChatNotificationAsSeen(serviceId: string) {
    set((state) => {
      const allChatNotifications = state.allChatNotifications;
      const index: number = allChatNotifications.findIndex(
        (item) => item.service_id === serviceId
      );
      if (index < 0) {
        return;
      }
      const unSeenCount =
        state.unseenChatNotification - allChatNotifications[index].unseenCount;

      state.unseenChatNotification = unSeenCount <= 0 ? 0 : unSeenCount;
      allChatNotifications[index] = {
        ...allChatNotifications[index],
        unseenCount: 0,
      };
      state.allChatNotifications = [...allChatNotifications];
    });
  },
  closeOrReopenChatNotification(serviceId: string, statusId: string) {
    set((state) => {
      try {
        const allChatNotifications = state.allChatNotifications;
        const index: number = allChatNotifications.findIndex(
          (item) => item.service_id === serviceId
        );
        if (index < 0) {
          return;
        }
        /// if chat is not closed or cancelled and alert notification status is closed or cancelled
        if (
          (disabledStatus.includes(
            allChatNotifications[index].service_status
          ) &&
            !disabledStatus.includes(statusId)) ||
          (!disabledStatus.includes(
            allChatNotifications[index].service_status
          ) &&
            disabledStatus.includes(statusId))
        ) {
          allChatNotifications[index].service_status = statusId;
        }
        state.allChatNotifications = [...allChatNotifications];
      } catch (e: any) {}
    });
  },
  //set selected chat in the chat notification drawer
  setSelectedChatNotification(value) {
    set((state) => {
      state.selectedChatNotification = value;
    });
  },
  resetUnseenChatNotification() {
    set((state) => {
      state.unseenChatNotification = 0;
    });
  },
}));
