import { useEffect, useRef, useState } from "react";
import { CustomTextField } from "../../../../../../../library/system/custom-form";
import PropTypes from "prop-types";
function TimeRangeTextfield(props) {
  const { changeHandler, ...otherProps } = props;

  const hourChangeHandler = (data) => {
    const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
    const inputValue = data.value;
    if (regex.test(inputValue) || inputValue === "") {
      data.value = inputValue;
      changeHandler(data);
    }
  };

  return (
    <CustomTextField
      {...otherProps}
      changeHandler={hourChangeHandler}
      disableAutoChange={true}
    />
  );
}

export default TimeRangeTextfield;
