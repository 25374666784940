import { SvgIcon, SvgIconProps } from "@mui/material";

export const AllignmentLeftIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <rect
        width="18"
        height="18"
        x="3"
        y="3"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        rx="5"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M12 15.5H7.5M7.5 8.5h9M16.5 12h-9"
      />
    </svg>
  </SvgIcon>
);
