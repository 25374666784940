import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DownArrowIcon, UpArrowIcon } from "components";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { AddRemoveIconButton } from "./Components/AddRemoveIconButton";
import { v4 } from "uuid";
import dayjs from "dayjs";

import { CertificateComponent } from "./Components/CertificateComponent";
import { ExperienceComponent } from "./Components/ExperienceComponent";
import SkillComponent from "./Components/SkillComponent";
import toast from "react-hot-toast";

export const ProficiencyTab = forwardRef(
  (
    {
      proficiencyData,
      resourceId,
      onSubmit,
    }: {
      proficiencyData: any;
      resourceId: string;
      onSubmit: (data: any) => void;
    },
    ref
  ) => {
    const [state, setState] = useState({
      expanded: false,
      panel: "",
    });
    const [isValidate, setIsValidate] = useState(false);
    const [experiences, setExperiences] = useState<Array<any>>([
      {
        key: v4(),
        job_role: "",
        company: "",
        start_date: "",
        end_date: "",
        delete: false,
      },
    ]);
    const [certificates, setCertificates] = useState<Array<any>>([
      {
        key: v4(),
        certification_version: "",
        certification_name: "",
        delete: false,
      },
    ]);
    const [skills, setSkills] = useState<Array<any>>([
      { key: v4(), skill_name: "", skill_level: "", delete: false },
    ]);

    useEffect(() => {
      const experience = [
        ...(Array.isArray(proficiencyData?.experience) &&
        proficiencyData?.experience.length > 0
          ? (proficiencyData?.experience || []).map((item: any) => ({
              ...item,
              key: item?.experience_id || item?.key,
              delete: item?.delete || false,
            }))
          : [
              {
                key: v4(),
                job_role: "",
                company: "",
                start_date: "",
                end_date: "",
                delete: false,
              },
            ]),
      ];

      const certificate = [
        ...(Array.isArray(proficiencyData?.certification) &&
        proficiencyData?.certification.length > 0
          ? (proficiencyData?.certification || []).map((item: any) => ({
              ...item,
              key: item?.certification_id || item?.key,
              delete: item?.delete || false,
            }))
          : [
              {
                key: v4(),
                certification_version: "",
                certification_name: "",
                delete: false,
              },
            ]),
      ];

      const skill = [
        ...(Array.isArray(proficiencyData?.skills) &&
        proficiencyData?.skills.length > 0
          ? (proficiencyData?.skills || []).map((item: any) => ({
              ...item,
              key: item?.skill_id || item?.key,
              delete: item?.delete || false,
            }))
          : [{ key: v4(), skill_name: "", skill_level: "", delete: false }]),
      ];

      setCertificates(certificate);
      setExperiences(experience);
      setSkills(skill);
    }, [proficiencyData]);

    const handleChange = (panel: string) =>
      setState({
        ...state,
        panel: state?.panel === panel ? "" : panel,
        expanded: state?.expanded === false,
      });

    const createNewExperience = () => {
      const data = [
        ...experiences,
        {
          key: v4(),
          job_role: "",
          company: "",
          start_date: "",
          end_date: "",
          delete: false,
        },
      ];

      setExperiences(data);
    };

    const createNewCertificate = () => {
      const data = [
        ...certificates,
        {
          key: v4(),
          certification_version: "",
          certification_name: "",
          delete: false,
        },
      ];

      setCertificates(data);
    };

    const createNewSkill = () => {
      const data = [
        ...skills,
        { key: v4(), skill_name: "", skill_level: "", delete: false },
      ];
      setSkills(data);
    };

    const getTotalExperience = () => {
      let totalExperience = 0;
      for (const singleExperience of experiences) {
        if (
          singleExperience.start_date !== null &&
          singleExperience.start_date !== "" &&
          singleExperience.end_date !== null &&
          singleExperience.end_date !== ""
        ) {
          totalExperience += dayjs(singleExperience.end_date).diff(
            singleExperience.start_date,
            "month"
          );
        }
      }
      totalExperience /= 12;
      return totalExperience % 1 === 0
        ? totalExperience
        : totalExperience.toFixed(1);
    };

    const onCertificateDataChange = (data: any, index: number) => {
      const certificateList = [...certificates];
      certificateList[index] = data;
      setCertificates(certificateList);
    };

    const onSkillDataChange = (data: any, index: number) => {
      const skillList = [...skills];
      skillList[index] = data;
      setSkills(skillList);
    };

    const onExperienceDataChange = (data: any, index: number) => {
      const experienceList = [...experiences];
      experienceList[index] = data;
      setExperiences(experienceList);
    };

    const onDeleteCertificate = (index: number) => {
      const certificateList = [...certificates];
      const certificate = certificateList[index];
      if (
        certificateList.length === 1 &&
        (certificate.certification_name === "" ||
          certificate.certification_name === null) &&
        (certificate.certification_version === "" ||
          certificate.certification_version === null)
      ) {
        return;
      }
      if (certificateList.length <= 1) {
        const newCertificate = [
          {
            key: v4(),
            certification_version: "",
            certification_name: "",
            delete: false,
          },
        ];
        if (certificate.certification_id !== undefined) {
          newCertificate.push({
            ...certificate,
            delete: true,
          });
        }

        setCertificates(newCertificate);
        return;
      }
      if (certificate.certification_id !== undefined) {
        certificate.delete = true;
        certificateList[index] = {
          ...certificate,
        };
      } else {
        certificateList.splice(index, 1);
      }
      setCertificates([...certificateList]);
    };

    const onDeleteExperience = (index: number) => {
      const experienceList = [...experiences];
      const experience = experienceList[index];
      if (
        experienceList.length === 1 &&
        (experience.company === "" || experience.company === null) &&
        (experience.job_role === "" || experience.job_role === null) &&
        (experience.start_date === "" || experience.start_date === null) &&
        (experience.end_date === "" || experience.end_date === null)
      ) {
        return;
      }

      if (experienceList.length <= 1) {
        const newExperience = [
          {
            key: v4(),
            job_role: "",
            company: "",
            start_date: "",
            end_date: "",
            delete: false,
          },
        ];
        if (experience.experience_id !== undefined) {
          newExperience.push({
            ...experience,
            delete: true,
          });
        }
        setExperiences(newExperience);
        return;
      }
      if (experience.experience_id !== undefined) {
        experience.delete = true;
        experienceList[index] = {
          ...experience,
        };
      } else {
        experienceList.splice(index, 1);
      }

      setExperiences([...experienceList]);
    };

    const onDeleteSkill = (index: number) => {
      const skillList = [...skills];
      const skill = skillList[index];
      if (
        skillList.length === 1 &&
        (skill.skill_name === "" || skill.skill_name === null) &&
        (skill.skill_level === "" || skill.skill_level === null)
      ) {
        return;
      }
      if (skillList.length <= 1) {
        const newSkills = [
          { key: v4(), skill_name: "", skill_level: "", delete: false },
        ];
        if (skill.skill_id !== undefined) {
          newSkills.push({
            ...skill,
            delete: true,
          });
        }
        setSkills(newSkills);
        return;
      }
      if (skill.skill_id !== undefined) {
        skill.delete = true;
        skillList[index] = {
          ...skill,
        };
      } else {
        skillList.splice(index, 1);
      }
      setSkills([...skillList]);
    };

    const onSubmitClick = () => {
      const proficiencyList = proficiencydataFormatter({
        certificates,
        experiences,
        skill: skills,
      });

      const validate = Object.entries(proficiencyList).every(
        ([key, proficiency]) =>
          proficiency.every((items) =>
            Object.entries(items).every(
              ([key, value]) => value !== "" && value !== null
            )
          )
      );

      if (validate) {
        onSubmit(proficiencyList);
      } else {
        toast.error("Fill data correctly");
      }
      setIsValidate(!validate);
    };

    useImperativeHandle(ref, () => ({ submit: onSubmitClick }));

    return (
      <Stack
        sx={{
          overflow: "scroll",
          "&:: -webkit-scrollbar": { display: "none" },
        }}
        divider={<Divider flexItem />}
      >
        <Stack
          direction={"row"}
          sx={{
            mb: "32px",
            mx: "42px",
            py: "16px",
            px: "32px",
            mt: "32px",
            justifyContent: "space-between",
            border: "1px solid #cccccc",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6">Total Experience</Typography>
          <Typography variant="h6">{getTotalExperience()} Years</Typography>
        </Stack>
        <Accordion
          expanded={state?.panel === PANELS?.experience["value"] ? true : false}
          onChange={() => handleChange(PANELS?.experience["value"])}
          // sx={{border: 'none', }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              state?.expanded === false &&
              state?.panel === PANELS?.experience["value"] ? (
                <UpArrowIcon />
              ) : (
                <DownArrowIcon />
              )
            }
            sx={{ px: "32px", py: "16px" }}
          >
            <Typography variant="body-large">
              {PANELS?.experience["label"]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              mx: "16px",
              mt: "-24px",
            }}
          >
            {experiences.map((element: any, index) => {
              return element.delete === false ? (
                <Box
                  key={`container-${element.key}`}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    py: "24px",

                    // my: "24px",
                  }}
                >
                  <ExperienceComponent
                    key={element.key}
                    experienceData={element}
                    isLastElement={experiences.length === 1}
                    onDataChange={(data) => onExperienceDataChange(data, index)}
                    onDelete={() => onDeleteExperience(index)}
                    validate={isValidate}
                  />
                </Box>
              ) : null;
            })}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <AddRemoveIconButton onClick={createNewExperience} />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={
            state?.panel === PANELS?.certificate["value"] ? true : false
          }
          onChange={() => handleChange(PANELS?.certificate["value"])}
          // sx={{border: 'none', }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              state?.expanded === false &&
              state?.panel === PANELS?.certificate["value"] ? (
                <UpArrowIcon />
              ) : (
                <DownArrowIcon />
              )
            }
            sx={{ px: "32px", py: "16px" }}
          >
            <Typography variant="body-large">
              {PANELS?.certificate["label"]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              mx: "16px",
              mt: "-24px",
            }}
          >
            {certificates.map((element: any, index) => {
              return element.delete === false ? (
                <Box
                  key={`container-${element.key}`}
                  sx={{
                    width: "100%",
                    borderRadius: "24px",
                    py: "24px",
                  }}
                >
                  <CertificateComponent
                    certificateData={element}
                    key={element.key}
                    onDataChange={(data: any) =>
                      onCertificateDataChange(data, index)
                    }
                    onDelete={() => onDeleteCertificate(index)}
                    isLastElement={certificates.length === 1}
                    validate={isValidate}
                  />
                </Box>
              ) : null;
            })}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <AddRemoveIconButton onClick={createNewCertificate} />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={state?.panel === PANELS?.skill["value"] ? true : false}
          onChange={() => handleChange(PANELS?.skill["value"])}
          // sx={{border: 'none', }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              state?.expanded === false &&
              state?.panel === PANELS?.skill["value"] ? (
                <UpArrowIcon />
              ) : (
                <DownArrowIcon />
              )
            }
            sx={{ px: "32px", py: "16px" }}
          >
            <Typography variant="body-large">
              {PANELS?.skill["label"]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              mx: "16px",
              mt: "-24px",
            }}
          >
            <Box>
              {skills.map((element: any, index) => {
                return element.delete === false ? (
                  <Box
                    key={`container-${element.key}`}
                    sx={{
                      width: "100%",
                      borderRadius: "24px",
                      py: "24px",
                    }}
                  >
                    <SkillComponent
                      key={element.key}
                      isLastElement={skills.length === 1}
                      onDataChange={(data: any) =>
                        onSkillDataChange(data, index)
                      }
                      onDelete={() => onDeleteSkill(index)}
                      skillData={element}
                      validate={isValidate}
                    />
                  </Box>
                ) : null;
              })}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <AddRemoveIconButton onClick={createNewSkill} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Stack>
    );
  }
);

const PANELS = {
  experience: {
    key: "accordion-experience-key",
    value: "experience",
    label: "Experience",
  },
  certificate: {
    key: "accordion-certificate-key",
    value: "certificate",
    label: "Certificates",
  },
  skill: { key: "accordion-skill-key", value: "skill", label: "Skills" },
};

const proficiencydataFormatter = ({
  certificates = [],
  experiences = [],
  skill = [],
}: {
  experiences: Array<any>;
  certificates: Array<any>;
  skill: Array<any>;
}) => {
  const experience = experiences
    .filter(
      (experience: any) =>
        !(
          (experience.company === "" || experience.company === null) &&
          (experience.job_role === "" || experience.job_role === null) &&
          (experience.start_date === "" || experience.start_date === null) &&
          (experience.end_date === "" || experience.end_date === null)
        )
    )
    .map((experience: any) => ({
      ...experience,
      ...(experience.experience_id
        ? { experience_id: experience.experience_id }
        : {}),
    }));
  const certification = certificates
    .filter(
      (certificate: any) =>
        !(
          (certificate.certification_version === "" ||
            certificate.certification_version === null) &&
          (certificate.certification_name === "" ||
            certificate.certification_name === null)
        )
    )
    .map((certificate: any) => ({
      ...certificate,
      ...(certificate.certification_id
        ? { certification_id: certificate.certification_id }
        : {}),
    }));
  const skills = skill
    .filter(
      (skill: any) =>
        !(
          (skill.skill_name === "" || skill.skill_name === null) &&
          (skill.skill_level === "" || skill.skill_level === null)
        )
    )
    .map((skill: any) => ({
      ...skill,
      ...(skill.skill_id ? { skill_id: skill.skill_id } : {}),
    }));

  return {
    experience,
    certification,
    skills,
  };
};
