function jpgDocumentIcon(props) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8335 13.3167H15.1682" stroke="#767B85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.8355 3.91357H5.16394C4.24308 3.91357 3.49658 4.66008 3.49658 5.58094V16.4188C3.49658 17.3396 4.24308 18.0861 5.16394 18.0861H16.8355C17.7563 18.0861 18.5028 17.3396 18.5028 16.4188V5.58094C18.5028 4.66008 17.7563 3.91357 16.8355 3.91357Z" stroke="#767B85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.83154 13.0883L7.5977 13.7786L9.14084 12.3896" stroke="#767B85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.8335 9.14829H15.1682" stroke="#767B85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.83154 8.91982L7.5977 9.6101L9.14 8.22119" stroke="#767B85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export default jpgDocumentIcon;