import {
  CreateLocationInput,
  GetAllLocationsResponseSchema,
  GetQueryProps,
  UpdateLocationInput,
} from "shared";
import { axiosClient } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
  validateApiResponse,
} from "utilz/helpers";

export const getAllLocations = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: "/controlCenter/msp_locations",
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      const result = validateApiResponse({
        response: response,
        schema: GetAllLocationsResponseSchema,
      });
      return result;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createLocation = async (data: CreateLocationInput) => {
  try {
    const response = await axiosClient.post(
      "/controlCenter/msp_location",
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
export const updateLocation = async ({
  locationId,
  ...data
}: UpdateLocationInput) => {
  try {
    const response = await axiosClient.patch(
      `/controlCenter/location/${locationId}`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
export const deleteLocation = async (data: { location_ids: string[] }) => {
  try {
    const response = await axiosClient.patch(
      "/controlCenter/delete/msp/location",
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
