import { styled } from "@mui/system";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import { useTheme } from "@mui/material";

export const StyledTableCell = ({ sx, ...rest }: TableCellProps) => {
  return (
    <TableCell
      {...rest}
      sx={{
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        py: "8px",
        // padding: "8px",
        ...sx,
      }}
    ></TableCell>
  );
};
