import { Box, Stack } from "@mui/material";
import {
  AttributeContainer,
  ServiceTypeContainer,
  VariableAttributeContainer,
} from "../Components";
import { useGetVariableAttributes } from "controller";

const GENERAL_ATTRIBUTES = {
  DEFAULT: [
    {
      key: "configuration-general-attributes-default-source",
      label: "Source",
      attributeName: "source",
      fieldName: "source_name",
    },
    {
      key: "configuration-general-attributes-default-work_type",
      label: "Work Type",
      attributeName: "work_type",
      fieldName: "work_type_name",
    },
    {
      key: "configuration-general-attributes-default-work_role",
      label: "Work Role",
      attributeName: "work_role",
      fieldName: "work_role_name",
    },
  ],
  VARIABLE_ATTRIBUTE_TYPE: "general",
};

function GeneralTab() {
  const { data: allVariableAttributes } = useGetVariableAttributes({
    attributeName: "general",
  });
  return (
    <Box
      sx={{
        display: "block",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          position: "relative",
          overflowX: "auto",
          "&::-webkit-scrollbar:horizontal": {
            display: "block",
          },
          p: "24px",
          gap: "24px",
        }}
      >
        {GENERAL_ATTRIBUTES.DEFAULT.map((item: any) => (
          <AttributeContainer
            key={`configuration-tickets-attributes-default${item.key}`}
            attributeName={item.attributeName}
            fieldName={item.fieldName}
            serviceType={"general"}
            label={item.label}
          />
        ))}
        <Stack
          direction={"row"}
          sx={{
            gap: "24px",
          }}
        >
          {(Array.isArray(allVariableAttributes?.result)
            ? allVariableAttributes.result
            : []
          ).map((item: any) => (
            <VariableAttributeContainer
              key={item.attribute_id}
              value={item}
              attributeName="general"
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}

//

export { GeneralTab };
