import { Box, Stack, Typography, useTheme } from "@mui/material";
import { CircularLoader, RightArrowIcon, TeamsAppIcon } from "components";

export const ProfileModalOption = ({
  onClick,
  icon,
  label,
  hasRightArrowIcon = true,
  isLoading = false,
}: {
  onClick: () => void;
  icon: any;
  label: string;
  hasRightArrowIcon?: boolean;
  isLoading?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Stack
      onClick={onClick}
      p="10px 16px"
      direction={"row"}
      gap="10px"
      alignItems={"center"}
      color={theme.palette.lightBg.low}
      sx={{
        cursor: isLoading ? "not-allowed" : "pointer",
        ":hover": {
          bgcolor: theme.palette.neutral["010"],
        },
        transition: "background-color 0.3s",
      }}
    >
      <Box
        display="grid"
        sx={{ placeItems: "center" }}
        width="20px"
        height="20px"
      >
        {isLoading ? (
          <Box
            width="100%"
            height="100%"
            display={"grid"}
            sx={{ placeItems: "center" }}
          >
            <CircularLoader size={16} />
          </Box>
        ) : (
          icon
        )}
      </Box>
      <Typography variant="body-medium" color="inherit">
        {label}
      </Typography>
      {hasRightArrowIcon && (
        <RightArrowIcon color="inherit" sx={{ ml: "auto" }} />
      )}
    </Stack>
  );
};
