function editIcon(props) {

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0449 4.979L5.04492 4.979C3.93992 4.979 3.04492 5.874 3.04492 6.979L3.04492 18.979C3.04492 20.084 3.93992 20.979 5.04492 20.979L17.0449 20.979C18.1499 20.979 19.0449 20.084 19.0449 18.979L19.0449 11.979"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2619 3.348L20.6759 4.762C21.0669 5.153 21.0669 5.786 20.6759 6.176L13.1449 13.707C12.9579 13.895 12.7029 14 12.4379 14L10.0239 14L10.0239 11.586C10.0239 11.321 10.1289 11.066 10.3169 10.879L17.8479 3.348C18.2379 2.957 18.8709 2.957 19.2619 3.348V3.348Z"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>


  );
}

export default editIcon;