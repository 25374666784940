import { Box, Fade, Paper, Slide, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomToggleButton, CustomTypography } from "../../../../../../../../../library/base";
import PropTypes from "prop-types";

function TemplateBillableToggleButton(props) {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(
      props.isChecked
    );
  }, [props]);

  const changeToggle = (data) => {
    props.onChange(data);
    setToggle(data.value);
  };

  return (<Box
    sx={{
      display: "flex",
      overflowY: "hidden",
      flexDirection: "row",
      gap: "16px",
      alignItems: "center",
    }}
  >
    <CustomToggleButton
      isChecked={toggle}
      onChange={changeToggle}
      name={props.name}
    />

    <Box sx={{
      width: "110px",
    }}>
      {
        toggle ? <CustomTypography
          size="EXTRA-SMALL"
          variant="REGULAR"
          content="Billable"
        /> : <CustomTypography
          size="EXTRA-SMALL"
          variant="REGULAR"
          content="Non-Billable"
        />
      }
    </Box>
  </Box>);
}
TemplateBillableToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

TemplateBillableToggleButton.defaultProps = {
  name: "",
  isChecked: false,
  onChange: () => { },
};

export default TemplateBillableToggleButton;