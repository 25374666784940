import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addPinService,
  deletePinnedService,
  getAllPinnedService,
} from "controller/api";
import { ApiErrorType } from "shared";
import { sentryLogger } from "utilz";

export const useGetAllPinnedService = () => {
  return useQuery({
    queryKey: ["pinnedChat"],
    queryFn: () => getAllPinnedService(),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "pinnedChat",
        functionName: "getAllPinnedService",
      });
    },
  });
};

export const useAddPinService = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addPinService,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["pinnedChat"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "pinnedChat",
        functionName: "addPinService",
        data: variables,
      });
    },
  });
};

export const useDeletePinService = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePinnedService,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["pinnedChat"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "pinnedChat",
        functionName: "deletePinnedService",
        data: variables,
      });
    },
  });
};
