import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from "@mui/material/TextField";
import React from "react";

import { Controller } from "react-hook-form";
import { InputRoot } from "./InputWithLabelRoot";
import {
  InputBaseProps,
  Typography,
  useTheme,
  Box,
  Theme,
} from "@mui/material";
import { useGetCurrentBreakpoint } from "utilz/helpers";

export type TextFieldProps =
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps;
export interface InputProps extends Omit<TextFieldProps, "size"> {
  size?: "large" | "medium" | "small";
  control?: any;
  name: string;
  label?: React.ReactNode;
  error?: boolean;
  errorMessage?: string | null;
  required?: boolean;
  noBorder?: boolean;
  /**
   * set to false if fixed input is needed and a size prop can be specified
   */
  isResponsive?: boolean;
  hideHelperBox?: boolean;
  inputFieledSx?: any;
}

export const Input = ({
  size = "small",
  control,
  name,
  label,
  error = false,
  value,
  errorMessage,
  required = false,
  // variant = "outlined",
  isResponsive = false,
  hideHelperBox = false,
  noBorder = false,
  sx,
  inputFieledSx,
  ...rest
}: InputProps) => {
  const theme = useTheme();
  const breakpoint = useGetCurrentBreakpoint(theme);

  if (isResponsive) {
    if (breakpoint === "lg") {
      size = "large";
    } else if (breakpoint === "md") {
      size = "medium";
    } else {
      size = "small";
    }
  }

  return control ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, onBlur, value, name } }) => (
        <CustomInput
          ref={ref}
          onBlur={onBlur}
          value={value}
          name={name}
          onChange={onChange}
          size={size}
          errorMessage={errorMessage}
          error={error}
          label={label}
          required={required}
          isResponsive={isResponsive}
          hideHelperBox={hideHelperBox}
          noBorder={noBorder}
          sx={sx}
          inputFieledSx={inputFieledSx}
          {...rest}
        />
      )}
    />
  ) : (
    <CustomInput
      size={size}
      errorMessage={errorMessage}
      value={value}
      error={error}
      label={label}
      required={required}
      isResponsive={isResponsive}
      sx={sx}
      name={name}
      hideHelperBox={hideHelperBox}
      noBorder={noBorder}
      inputFieledSx={inputFieledSx}
      {...rest}
    />
  );
};

const CustomInput = ({
  size,
  errorMessage,
  value,
  error,
  label,
  required,
  isResponsive,
  sx,
  name,
  hideHelperBox,
  onChange,
  ref,
  noBorder,
  inputFieledSx,
  ...rest
}: InputProps) => {
  const theme = useTheme();
  return (
    <InputRoot
      // disabled={true}

      ref={ref}
      autoComplete="off"
      name={name}
      size={size}
      variant="outlined"
      onChange={onChange}
      helperText={
        <Box
          sx={{
            mt: errorMessage ? "4px" : hideHelperBox ? "0px" : "4px",
          }}
          // height={hideHelperBox ? "0px" : "16px"}
        >
          <Typography variant="body-small" color="error">
            {errorMessage}
          </Typography>
        </Box>
      }
      // helperText={
      //   errorMessage ? (
      //     <Box
      //       height={hideHelperBox ? "0px" : "16px"}
      //       sx={{ bgcolor: "green" }}
      //     >
      //       <Typography variant="body-small" color="error">
      //         {errorMessage}
      //       </Typography>
      //     </Box>
      //   ) : null
      // }
      InputProps={{
        sx: {
          "& .MuiInputBase-input.MuiInputBase-inputMultiline": {
            p: "0px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: noBorder ? "none !important" : "",
            // backgroundColor: error ? theme.palette.error.tint : "transparent",
          },

          ...inputFieledSx,
        },
      }}
      InputLabelProps={{
        // shrink: true,
        sx: {
          fontFamily: theme.typography["body-medium"].fontFamily,
          fontWeight: theme.typography["body-medium"].fontWeight,
          fontSize: theme.typography["body-medium"].fontSize,
          transform: {
            xs: "translate(14px,6px) scale(1)",
          },
          "&.MuiFormLabel-filled": {
            transform: "translate(14px, -9px) scale(.75)",
          },
          "&.Mui-focused": {
            color: theme.palette.purple.main,
            // fontWeight: 700,
            transform: "translate(14px, -9px) scale(.75)",
          },
        },
      }}
      // label={label}
      label={
        label && (
          <Typography
            variant="body-medium"
            color={error ? "error" : "inherit"}
            textTransform={"capitalize"}
          >
            {label}
            {required && " *"}
          </Typography>
        )
      }
      sx={{
        // height: "32px",
        "& .MuiInputBase-root": {
          ...(isResponsive && { height: { sm: 32, md: 40, lg: 48, xl: 56 } }),
          height: "32px",
          ...theme.typography["body-medium"],
          overflow: "clip",
        },
        "& .Mui-disabled": {
          backgroundColor: theme.palette.neutral["B-005"],
          "&.MuiFormLabel-root": {
            backgroundColor: "transparent",
          },
          "&.MuiFormHelperText-root": {
            backgroundColor: "transparent",
          },
        },
        "& .Mui-error": {
          backgroundColor: error ? theme.palette.error.tint : "transparent",
          "&.MuiFormLabel-root": {
            backgroundColor: "transparent",
          },
          "&.MuiFormHelperText-root": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiFormHelperText-root": {
          mt: "0px",
        },
        ...sx,
      }}
      error={error}
      value={value}
      {...rest}
    />
  );
};
