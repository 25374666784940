import { GetQueryProps } from "shared";
import { axiosClient } from "utilz";

import { generateUrlString, getQueryDataObject } from "utilz/helpers";

export const getAllExpenseConfigs = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });
    const url = await generateUrlString({
      baseUrl: "/v1/configuration/billing/expenses",
      data,
    });
    const response = await axiosClient.get(url);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createExpenseConfig = async (data: any) => {
  try {
    const url = "/v1/configuration/billing/expenses";
    const response = await axiosClient.post(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteExpenseConfig = async (data: { expense_ids: string[] }) => {
  try {
    const url = "/v1/configuration/billing/expenses";
    const response = await axiosClient.put(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateExpenseConfig = async (data: any) => {
  try {
    const url = `/v1/configuration/billing/expenses/${data.id}`;
    const response = await axiosClient.patch(url, data?.data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
