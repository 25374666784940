import { CheckBox } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { Modal } from "components";

import { Input, SelectField } from "components/input";
import CustomCheckBox from "library/base/custom-checkbox";
import ToggleButton from "library/base/custom-toggle-button";
import { useState } from "react";

const BillingMethodOptions = [
  { label: "Actual rates", value: "actual" },
  { label: "Fixed rates", value: "fixed" },
  { label: "Not to exceed", value: "notToExceed" },
];
export const ProjectBillingModal = ({
  data,
  onSubmit,
  isOpen = false,
  onClose,
}: {
  data: any;
  onSubmit: (data: any) => void;
  isOpen: boolean;
  onClose?: () => void;
}) => {
  const [billData, setBillData] = useState<any>(data);

  const onSave = () => {
    onSubmit({
      billing_info: billData,
    });
  };

  // const onCancel = () => {
  //   onSubmit({
  //     billing_info: data,
  //   });
  // };

  return (
    <Modal
      maxWidth="sm"
      open={isOpen}
      title="Billing Info"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onSave}
      onSecondaryButtonClick={onClose}
      onClose={onClose}
    >
      <Stack px={"32px"} gap={"40px"} pt={"8px"}>
        <SelectField
          name="billingMethod"
          label="Billing method"
          options={BillingMethodOptions}
          labelMappingKey="label"
          valueMappingKey="value"
          value={billData?.billing_method}
          onValueChange={(value) => {
            setBillData((oldData: any) => ({
              ...oldData,
              billing_method: value,
            }));
          }}
        />
        <Stack direction={"row"} gap={"24px"} width={"80%"}>
          <Input
            name="downPayment"
            label="Down Payment"
            placeholder="0.00USD"
            fullWidth
            value={billData?.down_payment || 0}
            onChange={(event) => {
              setBillData((oldData: any) => ({
                ...oldData,
                down_payment: Number.parseFloat(event.target.value) || 0,
              }));
            }}
          />
          <Input
            name="po"
            label="PO Amount"
            placeholder="0.00USD"
            fullWidth
            value={billData?.po_amount || 0}
            onChange={(event) => {
              setBillData((oldData: any) => ({
                ...oldData,
                po_amount: Number.parseFloat(event.target.value) || 0,
              }));
            }}
          />
        </Stack>
        <Stack direction={"row"} gap={"24px"}>
          <BillBlock
            title="Bill time"
            inputLabel="Est. Time Cost"
            value={billData?.estimated_time_cost}
            isBillable={billData?.is_billable_time}
            onBillableChange={(isBillable, value) => {
              setBillData((oldData: any) => ({
                ...oldData,
                is_billable_time: isBillable,
                estimated_time_cost: value,
              }));
            }}
          />
          <BillBlock
            title="Bill expense"
            inputLabel="Est. Expense Cost"
            value={billData?.estimated_expense_cost}
            isBillable={billData?.is_billable_expenses}
            onBillableChange={(isBillable, value) => {
              setBillData((oldData: any) => ({
                ...oldData,
                is_billable_expenses: isBillable,
                estimated_expense_cost: value,
              }));
            }}
          />
          <BillBlock
            title="Bill products"
            inputLabel="Est. Product Cost"
            value={billData?.estimated_product_cost}
            isBillable={billData?.is_billable_products}
            onBillableChange={(isBillable, value) => {
              setBillData((oldData: any) => ({
                ...oldData,
                is_billable_products: isBillable,
                estimated_product_cost: value,
              }));
            }}
          />
        </Stack>
        <Stack gap={"16px"}>
          <Stack direction={"row"} gap={"16px"}>
            <CustomCheckBox
              checked={billData?.billing_after_closed}
              onChange={(event) => {
                setBillData((oldData: any) => ({
                  ...oldData,
                  billing_after_closed: event.target.checked,
                }));
              }}
            />

            <Typography variant="body-large">
              Bill this project only after it has been closed
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"16px"}>
            <CustomCheckBox
              checked={billData?.billing_unapproved_time_expenses}
              onChange={(event) => {
                setBillData((oldData: any) => ({
                  ...oldData,
                  billing_unapproved_time_expenses: event.target.checked,
                }));
              }}
            />
            <Typography variant="body-large">
              Bill unapproved time & expense records
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

const BillBlock = ({
  isBillable = true,
  value = 0,
  title,
  inputLabel,
  onBillableChange,
}: {
  isBillable?: boolean;
  title: string;
  value: number | string;
  inputLabel: string;
  onBillableChange?: (isBillable: boolean, amount: number | string) => void;
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        flex: "1",
        border: `1px solid ${theme.palette.neutral["015"]}`,
        borderRadius: "12px",
        padding: "16px 16px 0px 16px",
        gap: "12px",
        minHeight: "170px",
      }}
    >
      <Typography variant="body-large">{title}</Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="start"
        gap="24px"
      >
        <ToggleButton
          onChange={(data) => {
            onBillableChange?.(data.value, 0);
          }}
          isChecked={isBillable}
        />

        <Typography variant="body-medium" noWrap>
          {isBillable ? "Billable" : "Non Billable"}
        </Typography>
      </Stack>
      {isBillable && (
        <Input
          sx={{ mt: "6px" }}
          name="timeCost"
          value={value}
          label={inputLabel}
          inputMode="decimal"
          onChange={(event) => {
            onBillableChange?.(
              isBillable,
              Number.parseFloat(event.target.value) || 0
            );
          }}
        />
      )}
    </Stack>
  );
};
