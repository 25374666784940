export const mountStateBuilder = (FORM_FIELDS, data)=> {
  return{
    location_name: {...FORM_FIELDS.location_name, renderKey: "", value: data?.location_name},
    contact_person: {...FORM_FIELDS.contact_person, renderKey: "", value: data?.contact_person},
    location_description: {...FORM_FIELDS.location_description, renderKey: "", value: data?.location_description},
    email: {...FORM_FIELDS.email, renderKey: "", value: data?.email},
    contact_number: {...FORM_FIELDS.contact_number, renderKey: "", value: data?.contact_number},
    country: {...FORM_FIELDS.country, renderKey: "", value: data?.country},
    state: {...FORM_FIELDS.state, renderKey: "", value: data?.state},
    city: {...FORM_FIELDS.city, renderKey: "", value: data?.city},
    street: {...FORM_FIELDS.street, renderKey: "", value: data?.street},
    postal_code: {...FORM_FIELDS.postal_code, renderKey: "", value: data?.postal_code},
    timezone: {...FORM_FIELDS.timezone, renderKey: "", value: data?.timezone},
    latitude: {...FORM_FIELDS.latitude, renderKey: "", value: data?.latitude},
    longitude: {...FORM_FIELDS.longitude, renderKey: "", value: data?.longitude}
  };
};