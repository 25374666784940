import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CustomTextField } from "../../../../../../../library/system/custom-form";



function PositiveNumericTextField(props) {
  const { value, changeHandler, ...otherProps } = props;
  // State
  const [state, setState] = useState({
    value: "",
  });

  // Effects
  useEffect(() => {
    setState({
      value: props.value
    });
  }, [props]);

  // Event Handlers
  const handleChange = (event) => {
    var value = getNumberValue(event.value);
    if (1 > parseInt(value)) {
      value = "";
    }

    const count = countDecimals(value);
    const countBeforeDecimal = countDecimals(value, false);

    if ((props.length === null || countBeforeDecimal <= props.length) && count <= props.lengthAfterDecimal) {
      props?.changeHandler({ field: props.name, value: value });
      setState({
        value: value
      });
    }
  };
  const countDecimals = (value, isAfter = true) => {
    if (isAfter === true) {
      return value % 1 ? value.toString().split(".")[1].length : 0;
    }
    return (value.toString().split(".")[0] || value).length;
  };


  const getNumberValue = (newValue) => {
    if (newValue === "") {
      return newValue;
    }
    if (props.supportDoubleValue === true) {
      return newValue.match("^[0-9]*[.]?[0-9]*$") != null ? newValue : value;
    }
    return newValue.match("^[0-9]+$") != null ? newValue : value;
  };

  //
  return (
    <CustomTextField changeHandler={handleChange} value={state.value} disableAutoChange={true} />
  );
}


PositiveNumericTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  supportDoubleValue: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
  length: PropTypes.number,
  lengthAfterDecimal: PropTypes.number,
};

PositiveNumericTextField.defaultProps = {
  name: "",
  label: "",
  value: "",
  disabled: false,
  required: false,
  error: false,
  helperText: "",
  placeholder: "",
  size: "medium",
  length: null,
  lengthAfterDecimal: 2,
  supportDoubleValue: false,
  changeHandler: () => { }
};

export default PositiveNumericTextField;