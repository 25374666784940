import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Avatar, DownloadCurvedIcon, FileIcon } from "components";
import { ChatAttachmentResult } from "shared/types";

import {
  AttachmentMessageBlock,
  MessageFooter,
} from "shared/features/chat/MessageBlocks";

export const MessageBubble = ({
  message,
  isMarkedAsResolved,
  date,
  type,
  attachments,
  senderDisplayName,
  imageUrl,
  accessSpecifier,
  isEdited,
  messagePlatform,
  senderType,
}: {
  message: string;
  type?: string;
  isMarkedAsResolved: boolean;
  date: string;
  attachments?: Array<ChatAttachmentResult>;
  senderDisplayName: string;
  imageUrl: string;
  accessSpecifier: string;
  isEdited?: boolean;
  messagePlatform?: string | null;
  senderType: "resource" | "customer";
}) => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{
        position: "relative",
        alignSelf: senderType === "customer" ? "flex-start" : "flex-end",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        pt: "20px",
        minWidth: "200px",
        // minWidth: "50%",
        maxWidth: "100%",
      }}
    >
      <Stack
        justifyContent={senderType === "customer" ? "flex-start" : "flex-end"}
        direction="row"
        alignItems="center"
        gap="8px"
      >
        <Avatar fullname={senderDisplayName} url={imageUrl}></Avatar>
        <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
          {senderDisplayName}
        </Typography>
      </Stack>
      <Box
        sx={{
          mt: "4px",
          position: "relative",
          alignSelf: "flex-end",
          maxWidth: "100%",
          // overflowX: "auto",
          overflowWrap: "break-word",
          borderRadius:
            senderType === "customer"
              ? "0px 12px 12px 12px"
              : "12px 0px 12px 12px",
          backgroundColor:
            (attachments && attachments?.length > 0) ||
            senderType === "customer"
              ? "#FFF"
              : "#E7E9FE",
          minWidth: "200px",
          padding: "12px",
          border: `1px solid ${
            senderType === "customer"
              ? theme.palette.neutral["015"]
              : theme.palette.neutral["005"]
          }`,
        }}
      >
        {attachments && attachments?.length > 0 && (
          <AttachmentMessageBlock type={senderType} attachment={attachments} />
        )}

        <Box sx={{}}>
          <Typography sx={{ whiteSpace: "pre-line" }} variant="body-small">
            {message}
          </Typography>

          <MessageFooter
            senderType={senderType}
            accessSpecifier={accessSpecifier}
            isEdited={isEdited}
            messagePlatform={messagePlatform}
            date={date}
          />
        </Box>
      </Box>
    </Box>
  );
};
