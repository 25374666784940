import { useQuery } from "@tanstack/react-query";
import {
  getGeneralStatus,
  getHomeScreenV2,
  getOverview,
  getTodaysTickets,
  getTotalService,
  getTotalWorkHours,
  getUpcomingActivities,
} from "controller/api";
import { getHomeScreenV2Input } from "shared";
import { debugConsole } from "utilz/helpers";

export const useGetUpcomingActivities = (resourceId?: string | null) => {
  return useQuery({
    queryKey: ["home_screen", "upcoming_activities", resourceId],
    queryFn: () => getUpcomingActivities(resourceId || ""),
    enabled: !!resourceId,
    onError(err) {
      debugConsole("useGetUpcomingActivities", err);
    },
  });
};

export const useGetGeneralStatus = (filter: string,timezone:string) => {
  return useQuery({
    queryKey: ["home_screen", "general_status", filter,timezone],
    queryFn: () => getGeneralStatus(filter,timezone),
    onError(err) {
      debugConsole("useGetGeneralStatus", err);
    },
  });
};

export const useGetOverview = (resourceId: string, filter: string,timezone:string) => {
  return useQuery({
    queryKey: ["home_screen", "overview", resourceId, filter,timezone],
    enabled: !!resourceId && `${resourceId}`.trim().length > 0,
    queryFn: () => getOverview(resourceId, filter,timezone),
    onError(err) {
      debugConsole("useGetOverview", err);
    },
  });
};

export const useGetTotalWorkHours = (resourceId: string, filter: string,timezone:string) => {
  return useQuery({
    queryKey: ["home_screen", "total_work_hours", resourceId, filter,timezone],
    queryFn: () => getTotalWorkHours(resourceId, filter,timezone),
    enabled: !!resourceId,
    onError(err) {
      debugConsole("useGetTotalWorkHours", err);
    },
  });
};

export const useGetTotalService = (resourceId: string, filter: string,timezone:string) => {
  return useQuery({
    queryKey: ["home_screen", "total_services", resourceId, filter,timezone],
    queryFn: () => getTotalService(resourceId, filter,timezone),
    enabled: !!resourceId,
    onError(err) {
      debugConsole("useGetTotalService", err);
    },
  });
};

export const useGetTodaysTickets = (timezone:string) => {
  return useQuery({
    queryKey: ["home_screen", "todays_tickets",timezone],
    queryFn: () => getTodaysTickets(timezone),
    onError(err) {
      debugConsole("useGetTodaysTickets", err);
    },
  });
};

export const useGetHomeScreenV2 = ({
  resourceId,
  filter,
  timezone,
}: getHomeScreenV2Input) => {
  return useQuery({
    queryKey: ["home_screen_dashboard", resourceId, filter, timezone],
    queryFn: () => getHomeScreenV2({ resourceId, filter, timezone }),
  });
};
