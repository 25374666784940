import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { CustomToggleButton, CustomTypography } from "../../../../../../library/base";

function PermissionTile(props) {

  return (<Stack
    direction={"row"}
    gap={"16px"}
    sx={{
      px: "32px",
      minHeight: {
        xs: "48px",
        sm: "56px",
        md: "64px",
        lg: "72px",
        xl: "72px",
      },
      alignItems: "center",
      ...props.borderTop === true ? { borderTop: "1px solid #cccccc", } : { borderBottom: "1px solid #cccccc", }
    }}
  >
    <Box
      sx={{
        width: "100%",
      }}
    >
      <CustomTypography
        color={props.primary === true ? "PRIMARY-MAIN" : "TERITARY-MAIN"}
        size='SMALL-ALPHA'
        variant={props.primary === true ? "BOLD" : "REGULAR"}
        content={props.label} />
    </Box>
    <CustomToggleButton
      name={props.name}
      onChange={props.onChange}
      isDisabled={true}
      // isChecked={props.value}
      isChecked={true}
    />
  </Stack>);
}

PermissionTile.propTypes = {
  primary: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  borderTop: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

PermissionTile.defaultProps = {
  primary: false,
  label: "",
  name: "",
  value: false,
  borderTop: false,
  onChange: () => { }
};

export default PermissionTile;