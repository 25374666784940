export const TOASTER_CONSTANTS = {
  severity: {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
    INFO: "info"
  },
  anchor: {
    VERTICAL: {BOTTOM: "bottom", TOP: "top"},
    HORIZONTAL: {CENTER: "center", LEFT: "left", RIGHT: "right"}
  },
  autoHideDuration: 4500
};