import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  Accordian,
  Avatar,
  Drawer,
  EditListIcon,
  MinimiseIcon,
  UserCircleIcon,
} from "components";
import {
  useGetAuditTrail,
  useGlobalStore,
  useMainStore,
  useNonPersistantMainStore,
} from "controller";
import React from "react";
import { dayjs } from "utilz";
import theme from "utilz/theme/theme";

interface TimelineProps {
  open: boolean;
  onClose: () => void;
  serviceId: string;
  serviceType: "ticket" | "project" | "task";
}
export const TimelineDrawer = ({
  open,
  onClose,
  serviceId,
  serviceType,
}: TimelineProps) => {
  const theme = useTheme();
  const timezone = useGlobalStore((state) => state.timezone);

  const ticketId = useMainStore((state) => state.ticketId);
  const { data: auditTrailData } = useGetAuditTrail({ serviceId, serviceType });

  const getTrailDetail = ({
    auditType,
    dataType,
    length,
  }: {
    auditType: string;
    dataType: "icon" | "title";
    length: number;
  }) => {
    switch (auditType) {
      case `${serviceType}_created`:
        return dataType === "title" ? (
          `${serviceType} Created`
        ) : (
          <EditListIcon fontSize="small" />
        );
      case "assignee_added":
        return dataType === "title" ? (
          length > 1 ? (
            "Multiple Assignees Added"
          ) : (
            "Assignee Added"
          )
        ) : (
          <UserCircleIcon fontSize="small" />
        );
      case "assignee_removed":
        return dataType === "title" ? (
          length > 1 ? (
            "Multiple Assignees Removed"
          ) : (
            "Assignee Removed"
          )
        ) : (
          <UserCircleIcon fontSize="small" />
        );
      case "schedule_added":
        return dataType === "title" ? (
          length > 1 ? (
            "Multiple Schedule Added"
          ) : (
            "Schedule Added"
          )
        ) : (
          <UserCircleIcon fontSize="small" />
        );
      case "schedule_removed":
        return dataType === "title" ? (
          length > 1 ? (
            "Multiple Schedule Removed"
          ) : (
            "Schedule Removed"
          )
        ) : (
          <UserCircleIcon fontSize="small" />
        );
      case "schedule_updated":
        return dataType === "title" ? (
          "Schedule Updated"
        ) : (
          <UserCircleIcon fontSize="small" />
        );
      case `${serviceType}_status_updated`:
        return dataType === "title" ? (
          `${serviceType} Status Updated`
        ) : (
          <EditListIcon fontSize="small" />
        );
      case `${serviceType}_details_updated`:
        return dataType === "title" ? (
          `${serviceType} Status Updated`
        ) : (
          <EditListIcon fontSize="small" />
        );
      default:
        return dataType === "title" ? (
          `${serviceType} Updated`
        ) : (
          <EditListIcon fontSize="small" />
        );
        break;
    }
  };

  return (
    <>
      <Drawer
        title={`${serviceType} Timeline`}
        onClose={onClose}
        onOpen={() => {}}
        open={open}
        footer={<></>}
      >
        <Stack sx={{ minWidth: "392px" }}>
          {auditTrailData?.map((auditData: Record<string, any>) => {
            return (
              <Accordian
                title={`${serviceType} Timeline`}
                accordianSummaryStyles={{
                  minHeight: "66px",
                }}
                icon={getTrailDetail({
                  auditType: auditData.audit_type,
                  dataType: "icon",
                  length: auditData.audit_details?.length,
                })}
                titleContent={
                  <Stack>
                    <Typography
                      variant="body-large"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {getTrailDetail({
                        auditType: auditData.audit_type,
                        dataType: "title",
                        length: auditData.audit_details?.length,
                      })}
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"2px"}
                      >
                        <Avatar
                          variants="xs"
                          sx={{ width: "16px", height: "16px" }}
                          fullname={auditData.activity_by?.name}
                        ></Avatar>
                        <Typography
                          variant="body-xsmall"
                          sx={{ textTransform: "capitalize" }}
                          color={theme.palette.purple.main}
                        >
                          {auditData.activity_by?.name}
                        </Typography>
                      </Stack>

                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"8px"}
                      >
                        <Typography
                          variant="body-xsmall"
                          color={theme.palette.lightBg.low}
                        >
                          {dayjs(auditData.created_at)
                            .tz(timezone)
                            .format("DD/MM/YYYY")}
                        </Typography>
                        <Typography
                          variant="body-xsmall"
                          color={theme.palette.lightBg.low}
                        >
                          {dayjs(auditData.created_at)
                            .tz(timezone)
                            .format("hh:mm a")}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                }
              >
                {/* Details body */}
                <>
                  <TimelineDetails
                    serviceType={serviceType}
                    auditData={auditData.audit_details}
                    auditType={auditData.audit_type}
                  />
                </>
              </Accordian>
            );
          })}
        </Stack>
      </Drawer>
    </>
  );
};

const TimelineDetails = ({
  auditData,
  auditType,
  serviceType,
}: {
  auditData: Array<Record<string, any>>;
  auditType: string;
  serviceType: "ticket" | "project" | "task";
}) => {
  const getDetailText = (auditType: string, data: Record<string, any>) => {
    switch (auditType) {
      case `${serviceType}_created`:
        return `${serviceType} has been created"`;
      case "assignee_added":
        return `${data.activity_for?.name} has been added`;
      case "assignee_removed":
        return `${data.activity_for?.name} has been removed`;
      case "schedule_added":
        return `Schedule has been added for ${data.activity_for?.name}`;
      case "schedule_removed":
        return `Schedule has been removed for ${data.activity_for?.name}`;
      case "schedule_updated":
        return `Schedule has been updated for ${data.activity_for?.name}`;
      case `${serviceType}_status_updated`:
        return `Status has been updated from ${data.previous_state} to ${data.current_state}`;
      case `${serviceType}_details_updated`:
        return `${serviceType} has been updated`;
      default:
        return `Details updated by ${data.activity_by?.name}`;
        break;
    }
  };
  return (
    <Box>
      {auditData.map((data: Record<string, any>) => {
        return (
          <Stack
            ml={"4px"}
            direction={"row"}
            gap={"12px"}
            alignItems={"center"}
          >
            <svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="3" cy="3" r="3" fill="#383940" />
            </svg>
            <Typography variant="body-small" color={theme.palette.lightBg.low}>
              {getDetailText(auditType, data)}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );
};
