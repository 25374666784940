import {
  Dialog,
  DialogProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "components/button";
import {
  CheckCircleIcon,
  CheckIcon,
  CloseFilledIcon,
  ReportIcon,
} from "components/icons";
import React from "react";

interface ModalProps extends DialogProps {
  title?: string;
  tagline?: string | React.ReactNode;
  showIcon?: boolean;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  paperStyles?: React.CSSProperties;
  type?: "primary" | "error" | "warning" | "success" | string;
  isLoading?: boolean;
  customIcon?: React.ReactNode;
  hideSecondaryButton?: boolean;
  primaryButtonType?: "primary" | "error";
}

export const ConfirmationModal = ({
  title = "Delete Confirmation",
  tagline = "Are you sure you want to delete?",
  open,
  showIcon = true,
  paperStyles,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText = "Delete",
  secondaryButtonText = "Cancel",
  type = "error",
  isLoading = false,
  customIcon,
  hideSecondaryButton,
  primaryButtonType,
  ...rest
}: ModalProps) => {
  const theme = useTheme();

  const getTintColor = (type: string) => {
    switch (type) {
      case "error":
        return theme.palette.error.tint;

      case "success":
        return theme.palette.success.tint;

      case "warning":
        return theme.palette.warning.tint;

      case "primary":
        return theme.palette.secondary["purple-tint-3"];

      default:
        return theme.palette.success.tint;
    }
  };
  return (
    <Dialog
      open={open}
      // maxWidth={false}
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: "24px",
          width: "420px",
          ...paperStyles,
        },
      }}
      onClose={onClose}
      sx={{ display: "flex", flexDirection: "column" }}
      {...rest}
    >
      {showIcon && (
        <Stack
          sx={{
            width: "56px",
            height: "56px",
            backgroundColor: getTintColor(type),
            borderRadius: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {customIcon ? customIcon : <CardIcon type={type} />}
        </Stack>
      )}

      <Typography
        mt={showIcon ? "16px" : "0px"}
        variant="h5"
        textTransform={"capitalize"}
      >
        {title}
      </Typography>
      <Typography
        sx={{ mt: "16px" }}
        variant="body-large"
        color={theme.palette.lightBg.low}
      >
        {tagline}
      </Typography>
      <Stack direction="row" mt="24px" gap="16px" justifyContent="flex-end">
        {!hideSecondaryButton && (
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={onSecondaryButtonClick}
          >
            {secondaryButtonText}
          </Button>
        )}

        <Button
          loading={isLoading}
          variant="primary"
          onClick={onPrimaryButtonClick}
          sx={{
            ...(primaryButtonType === "error"
              ? {
                  bgcolor: theme.palette.error.main,
                  "&:hover": { backgroundColor: "#A9050E" },
                }
              : {}),
          }}
        >
          {primaryButtonText}
        </Button>
      </Stack>
    </Dialog>
  );
};

const CardIcon = ({ type }: { type: "success" | "error" | string }) => {
  const theme = useTheme();
  switch (type) {
    case "success":
      return <CheckCircleIcon htmlColor={theme.palette.success.main} />;

    case "error":
      return (
        <Stack
          sx={{
            borderRadius: "100%",
            width: "24px",
            height: "24px",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: theme.palette.error.main,
          }}
        >
          <ReportIcon
            fontSize="small"
            sx={{ width: "18px", height: "18px" }}
            htmlColor={"#fff"}
          />
        </Stack>
      );

    case "warning":
      return <ReportIcon htmlColor={theme.palette.warning.main} />;

    default:
      return <CheckCircleIcon htmlColor={theme.palette.success.main} />;
  }
};
