import { Box, Grow, Stack, Typography, useTheme } from "@mui/material";
import {
  AlarmIcon,
  Avatar,
  AvatarGroupCircle,
  CloseIcon,
  PlayStopButton,
  TimeCounter,
} from "components";
import {
  useChatNotificationStore,
  useDeletePinService,
  useGlobalStore,
  useNonPersistantMainStore,
  useTimeEntryStore,
} from "controller";
import { MqttContext } from "controller/context/MqttCreateContext";
import { usePinnedStore } from "controller/store/pinned-chat/pinnedChatStore";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ChatBox } from "./ChatBox";
import { ChatTimeEntryTimer } from "./ChatTimeEntryTimer";
import { useUpdateChatNotificationStatus } from "controller/hooks/notification";
import { DisabledStatus } from "shared/data";

const AvatarChat = ({
  serviceId,
  serviceType,
  assignees,
  serviceUid,
  customerName,
  customerUserName,
  summary,
  primaryColor,
  tintColor,
  profileImage,
  serviceStatus,
}: {
  serviceId: string;
  serviceType: "ticket" | "task";
  assignees?: Array<any>;
  serviceUid: string;
  customerName?: string;
  customerUserName?: string;
  summary: string;
  primaryColor: string;
  tintColor: string;
  profileImage?: string;
  serviceStatus: string;
}) => {
  const chatBoxRef = useRef();
  const theme = useTheme();
  const selectedPinnedChatServiceId = usePinnedStore(
    (state) => state.selectedPinnedChatServiceId
  );
  const setSelectedPinnedChat = usePinnedStore(
    (state) => state.setSelectedPinnedChat
  );

  const timeEntry = useTimeEntryStore(
    (state) => state.activeTimeEntries[serviceId]
  );
  // const {
  //   mutateAsync: deletePinnedService,
  //   isLoading: isRemovingPinnedService,
  // } = useDeletePinService();
  const mspId = useGlobalStore((state) => state.mspId);
  const resourceId = useGlobalStore((state) => state.resourceId);
  const {
    connectMqtt,
    subscribeRoom,
    isConnected,
    unSubscribeRoom,
    mqttMessage,
  } = useContext(MqttContext);

  const setChatNotificationAsSeen = useChatNotificationStore(
    (state) => state.setChatNotificationAsSeen
  );
  const { mutateAsync: updateChatNotification } =
    useUpdateChatNotificationStatus();

  const allChatMessages = useChatNotificationStore(
    (state) => state.allChatNotifications
  );
  const chatMessage = useMemo(() => {
    return allChatMessages.find(
      (item) =>
        item.service_id === serviceId && item.service_type === serviceType
    );
  }, [allChatMessages]);

  const isOpen = useMemo(() => {
    return selectedPinnedChatServiceId === serviceId;
  }, [selectedPinnedChatServiceId, serviceId]);

  useEffect(() => {
    if (!isConnected) {
      connectMqtt();
    } else {
      console.log(isConnected);
      subscribeRoom([
        `${serviceType}s/${serviceId}`,
        `${serviceType}s/${serviceId}/private`,
        `${serviceType}s/${mspId}/${serviceId}/resource/${resourceId}`,
      ]);
    }

    return () => {
      if (isConnected) {
        unSubscribeRoom([
          `${serviceType}s/${serviceId}`,
          `${serviceType}s/${serviceId}/private`,
          `${serviceType}s/${mspId}/${serviceId}/resource/${resourceId}`,
        ]);
      }
    };
  }, [isConnected]);

  useEffect(() => {
    if (mqttMessage) {
      const newMessage = JSON.parse(mqttMessage?.message);
      if (
        [
          `${serviceType}s/${serviceId}`,
          `${serviceType}s/${serviceId}/private`,
          `${serviceType}s/${mspId}/${serviceId}/resource/${resourceId}`,
        ].includes(mqttMessage?.topic)
      ) {
        // @ts-ignore
        chatBoxRef.current?.transformMessages({
          inputMessage: [newMessage?.savedChat],
          type: "new",
        });
      }
    }
  }, [mqttMessage]);

  // const handleRemovePinnedChat = async (serviceId: string) => {
  //   try {
  //     await deletePinnedService({ serviceId, serviceType });
  //     removePinnedChat(serviceId);
  //   } catch (e: any) {}
  // };

  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "flex-end",
        ml: isOpen ? "-8px" : "-16px",
        position: "relative",
        overflowY: "visible",
      }}
    >
      <ChatBox
        onMinimize={() => setSelectedPinnedChat()}
        isOpen={isOpen}
        serviceId={serviceId}
        serviceType={serviceType}
        ref={chatBoxRef}
      />
      {isOpen ? (
        <></>
      ) : (
        // <ChatBox onMinimize={() => setIsOpen(false)} isOpen={isOpen} />
        <Box
          onClick={() => {
            if (!isOpen && chatMessage) {
              console.log("🚀 ~ chatMessage:", chatMessage);
              updateChatNotification({
                resourceId: resourceId || "",
                notificationId: chatMessage.notification.notification_id,
              });
              setChatNotificationAsSeen(chatMessage.service_id);
            }
            setSelectedPinnedChat(serviceId);
          }}
          sx={{
            position: "relative",
            width: "48px",
            height: "48px",
            bgcolor: tintColor,
            borderRadius: "24px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            pr: "16px",
            display: "flex",
            transition:
              "border-radius 0s ease-in, width 0.3s ease-in-out 0.1s, padding 0.2s ease-in-out",
            cursor: "pointer",
            "& .avatar": {
              width: "44px",
              height: "44px",
              borderRadius: "100%",
              aspectRatio: "1",
              transition: "width 0.2s ease-in-out, height 0.2s ease-in-out",
              backgroundColor: "#ffffffc9",
              border: `2px solid ${primaryColor}`,
            },

            "& .close": {
              // display: "none",
              cursor: "pointer",
              opacity: 0,
              width: 0,
              height: 0,
              transition:
                "opacity 0.1s ease-in-out 0.4s, width 0.1s ease-in-out 0.3s, height 0.1s ease-in-out 0.3s",
            },
            "& .summary": {
              width: 0,
              opacity: 0,
              transition:
                "width 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out 0.3s",
              overflow: "clip",
            },
            ":hover": {
              width: "294px",
              height: "48px",
              borderRadius: "24px",
              padding: "8px",
              border: `2px solid ${primaryColor}`,
              "& .avatar": {
                width: "32px",
                height: "32px",
                borderRadius: "100%",
                aspectRatio: "1",
                border: "none",
              },

              "& .close": {
                // display: "block",
                opacity: 1,
                width: "20px",
                height: "20px",
              },

              "& .summary": {
                opacity: 1,
                width: "fit-content",
              },
            },
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap="8px">
            <Box
              sx={{
                bgcolor: primaryColor,
                borderRadius: "100%",
              }}
            >
              <Avatar
                className="avatar"
                src={profileImage}
                fullname={customerUserName}
              />
            </Box>

            {chatMessage && chatMessage.unseenCount > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: 0,
                  backgroundColor: "red",
                  borderRadius: "16px",
                  width: "fit-content",
                  height: "16px",
                  minWidth: "16px",
                  px: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: "2",
                }}
              >
                <Typography sx={{ color: "white" }} variant="caption-xs">
                  {chatMessage.unseenCount < 100
                    ? chatMessage.unseenCount
                    : "99+"}
                </Typography>
              </Box>
            )}
            {timeEntry && (
              <Box
                sx={{
                  position: "absolute",
                  top: "17px",
                  right: "-11px",
                  backgroundColor: primaryColor,
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AlarmIcon
                  sx={{ color: "white", width: "14px", height: "14px" }}
                />
              </Box>
            )}
            <Stack
              direction={"row"}
              className="summary"
              sx={{
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Stack
                direction={"column"}
                className="summary"
                sx={{
                  flexGrow: 1,
                  maxWidth: "120px",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography variant="subtitle1" noWrap sx={{}}>
                  {summary}
                </Typography>
                <Typography variant="caption-2" noWrap>
                  {serviceUid}
                </Typography>
              </Stack>
              <Box>
                <ChatTimeEntryTimer
                  serviceId={serviceId}
                  serviceType={serviceType}
                  disable={DisabledStatus.includes(serviceStatus)}
                />
              </Box>
            </Stack>
          </Stack>

          {/* <Avatar
        className="avatar"
        fullname={"John Doe"}
        onClick={() => setCount(0)}
      /> */}

          {/* <CloseIcon
            className="close"
            onClick={(e) => {
              e.stopPropagation();
              !isRemovingPinnedService && handleRemovePinnedChat(serviceId);
            }}
          /> */}
        </Box>
      )}
    </Stack>
  );
};

export default AvatarChat;
