import { Box, Collapse, Menu, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useGlobalStore } from "controller/store/globalStore";
import { toast } from "react-hot-toast";
import { CustomTypography } from "library/base";
import { Vertical3DotsIcon } from "components";
import { ConfigurationMenuItem, CustomDivider } from "../../Components";
import { GetAttributesInput } from "shared";
import { useDeleteAttribute, useUpdateAttribute } from "controller";
import { CreateAttributeTile } from "./CreateAttributeTile";

interface AttributeItemTileProps extends GetAttributesInput {
  isEditable: boolean;
  value: any;
  fieldName: string;
  mappingId: string;
}
export const AttributeItemTile = ({
  attributeName,
  isEditable = false,
  serviceType,
  value,
  fieldName,
  mappingId,
}: AttributeItemTileProps) => {
  const anchorRef = useRef();
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    isEdit: false,
    ismenuOpen: false,
    isExpanded: false,
    isCreateTileOpen: false,
    editEnabledSubType: "",
  });

  const { mutateAsync: updateAttribute } = useUpdateAttribute();
  const { mutateAsync: deleteAttribute } = useDeleteAttribute();

  const onUpdateAttribute = (data: any) => {
    updateAttribute({
      attributeId: mappingId,
      attributeName: attributeName,
      serviceType: serviceType,
      data: data,
    })
      .then(() => {
        toast.success("Updated successfully");
        setState({
          ...state,
          isEdit: false,
        });
      })
      .catch(() => {
        toast.error("Unable to update");
      });
  };

  const onDeleteAttribute = () => {
    deleteAttribute({
      attributeId: mappingId,
      attributeName: attributeName,
      serviceType: serviceType,
    })
      .then(() => {
        toast.success("Deleted successfully");
        setState({
          ...state,
          isEdit: false,
        });
      })
      .catch(() => {
        toast.error("Unable to delete");
      });
  };

  return (
    <Box width={"100%"}>
      {state.isEdit === true ? (
        <CreateAttributeTile
          name={fieldName}
          value={value[fieldName] || ""}
          onSubmit={onUpdateAttribute}
          onCancel={() => {
            setState({
              ...state,
              isEdit: false,
            });
          }}
        />
      ) : (
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            height: "55px",
            px: "16px",
            py: "18px",
            gap: "16px",
            borderBottom: "1px solid #CCCCCC",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <CustomTypography
              content={value[fieldName] || ""}
              size={"EXTRA-SMALL"}
              variant={"REGULAR"}
            />
          </Box>
          {isEditable === true ? (
            <Box
              ref={anchorRef}
              onClick={() => {
                setState({
                  ...state,
                  ismenuOpen: !state.ismenuOpen,
                });
              }}
            >
              <Vertical3DotsIcon />
            </Box>
          ) : null}

          <Menu
            open={isEditable === true && state.ismenuOpen === true}
            anchorEl={state.ismenuOpen === true ? anchorRef.current : null}
            onClose={() => {
              setState({
                ...state,
                ismenuOpen: false,
              });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                gap: "1px",
                border: "1px solid #EDEDED",
                minWidth: 200,
                borderRadius: "8px",
                color: "black",
                boxShadow:
                  "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                "& .MuiMenu-list": {
                  padding: "4px 0",
                },
                "&.MuiDivider-root": {
                  borderColor: "#EDEDED",
                },
              },
            }}
          >
            <ConfigurationMenuItem
              content="Edit"
              onClick={() => {
                setState({
                  ...state,
                  isEdit: true,
                  ismenuOpen: false,
                });
              }}
            />
            <CustomDivider />
            <ConfigurationMenuItem
              content="Remove"
              onClick={onDeleteAttribute}
            />
          </Menu>
        </Stack>
      )}
    </Box>
  );
};
