import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { styles } from "./styles";

// Legacy Imports
import Switch from "@mui/material/Switch";

const CustomToggleButton = (props)=> {
  // States
  const [state, setState] = useState({value: false, key: ""});

  // Effects
  useEffect(()=> {
    try{
      setState({
        ...state,
        value: props?.value,
        key: v4()
      });
    }catch(err) {
      console.log("[ERRROR] mounting Custom Toggle Button");
      console.log(err);
    }
  }, [props]);

  // Handlers
  const handleChange = (event)=> {
    props?.handle({field: props?.name, value:event.target.checked});        
  };

  // Renderer
  return (
    <Switch
      key = {state["key"]}
      checked={state["value"]}
      onChange={handleChange}
      sx= {styles}
    />
  );
};

CustomToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handle: PropTypes.func.isRequired
};

CustomToggleButton.defaultProps = {
  name: "",
  value: false,
  handle: ()=> {}
};

export default CustomToggleButton;