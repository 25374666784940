import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// HOC Imports
import {
  CustomTypography,
  CustomButton,
  CustomIconButton,
} from "../../../../../../library/base";
import { SlideModal } from "../../../../../../library/layers";

// Child Component Imports
import { CreateNewFormComponent } from "./create-new-form";
import { services } from "../../../../../../services";

// Constants
import { getMspId } from "utilz/helpers";
import { SearchTextField } from "pages/modules/control-center/configurations/components/Components";
import { toast } from "react-hot-toast";
import { FORM_FIELDS } from "./create-new-form/constants";

const LocationsHeadMount = (props) => {
  // Ref
  const formRef = useRef();
  // State
  const [state, setState] = useState({
    isFilter: false,
    search: "",
    modalOpen: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  // event Handlers
  const onCreateNew = () => setState({ ...state, modalOpen: true });
  const closeModal = () => setState({ ...state, modalOpen: false });
  const saveItem = (data) => {
    try {
      setIsLoading(true);
      services.controlCenterservices.locationServices
        .createNewLocation(getMspId(), data)
        .subscribe({
          next: (response) => {
            setIsLoading(false);
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true
            ) {
              setState({
                ...state,
                modalOpen: false,
              });
              toast.success("Location is ready");
              props?.onCreateNew();
            } else {
              toast.error(
                "Unable to add new location at the moment. Please try again."
              );
            }
          },
          error: (error) => {
            setIsLoading(false);
            toast.error(
              "Unable to add new location at the moment. Please try again."
            );
          },
        });
    } catch (err) {
      setIsLoading(false);
      toast.error(
        "Unable to add new location at the moment. Please try again."
      );
    }
  };
  const resetToaster = () =>
    setState({
      ...state,
      toaster: { open: false, severity: "success", message: "" },
    });
  const updateSearch = (value) => {
    try {
      const newValue = value;
      setState({ ...state, search: newValue });
      if (newValue.length === 0 || newValue.length > 2) {
        props.onUpdateFilter(newValue);
      }
    } catch (err) {
      console.log("[ERROR] Updating search");
      console.log(err);
    }
  };
  const resetFilters = () => {
    setState({ ...state, search: "", isFilter: false });
    props.onResetFilter();
  };

  const onSubmitHandler = (data) => {
    const validate = Object.entries(data).every(
      ([key, value]) =>
        !FORM_FIELDS[key].required ||
        (!isNaN(value) && value >= 0) ||
        value !== ""
    );
    if (validate) {
      saveItem(data);
      return;
    }
    toast.error("Fill all required fields");
  };

  // Renderer
  return (
    <React.Fragment>
      <SlideModal
        isLoading={isLoading}
        open={state?.modalOpen}
        isEdit={false}
        edit={false}
        modalSize={"medium"}
        modalTitle={"Add Location"}
        modalBody={
          <CreateNewFormComponent ref={formRef} onSubmit={onSubmitHandler} />
        }
        modalFooter={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <CustomButton
                varient={"secondary"}
                size="sm"
                label="Cancel"
                isTrailingIcon={false}
                icon=""
                onClick={closeModal}
              />
              <CustomButton
                varient={"primary"}
                size="sm"
                label="Create"
                isTrailingIcon={false}
                icon=""
                onClick={() => {
                  formRef.current.submit();
                }}
              />
            </Stack>
          </Box>
        }
        closeHandle={closeModal}
        headerActionCallback={() => {}}
      />
      <Box
        sx={{
          width: "100%",
          mb: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <CustomTypography
            size={"MEDIUM-ALPHA"}
            color={"PRIMARY-MAIN"}
            variant={"BOLD"}
            content={"Locations"}
          />
        </Box>
        <Box>
          <Box sx={{ px: "12px" }}>
            {state?.isFilter === false ? (
              <Box onClick={() => setState({ ...state, isFilter: true })}>
                <CustomIconButton icon="search" varient="secondary" size="sm" />
              </Box>
            ) : (
              <Box>
                {/* <TextField
                  name={"search"}
                  label={""}
                  type={"text"}
                  value={state?.search}
                  fullWidth={true}
                  disabled={false}
                  required={false}
                  error={false}
                  helperText={""}
                  placeholder={"Search"}
                  sx={SEARCH_FIELD_DESIGNS}
                  onChange={updateSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          sx={{ "&: hover": { cursor: "pointer" } }}
                          onClick={resetFilters}
                        >
                          <CustomIcon
                            size={`md`}
                            icon={"close"}
                            color="#767B85"
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                /> */}

                <SearchTextField
                  name={""}
                  label={""}
                  value={state?.search}
                  adornment={true}
                  adornmentPosition={"start"}
                  adornmentValue={"search"}
                  disabled={false}
                  required={true}
                  error={false}
                  helperText={""}
                  placeholder={"Search"}
                  size={"small"}
                  changeHandler={({ key, value }) => updateSearch(value)}
                  onReset={resetFilters}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <CustomButton
            varient={"primary"}
            size="sm"
            label="Location"
            isTrailingIcon={true}
            icon="plus"
            onClick={onCreateNew}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

LocationsHeadMount.propTypes = {
  onCreateNew: PropTypes.func.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
};
LocationsHeadMount.defaultProps = {
  onCreateNew: () => {},
  onUpdateFilter: () => {},
};

export default LocationsHeadMount;
