import { createTheme } from "@mui/material";
// @ts-ignore
import SegoeRegular from "../../assets/fonts/SegoeRegular.ttf";
// @ts-ignore
import ProductSans from "../../assets/fonts/SegoeRegular.ttf";
// @ts-ignore
import SegoeBold from "../../assets/fonts/SegoeBold.ttf";
// @ts-ignore
import SegoeSemibold from "../../assets/fonts/SegoeSemibold.ttf";
const theme = createTheme({
  zIndex: {
    modal: 999,
    drawer: 900,
    appBar: 800,
    fab: 2000,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 1024,
      md: 1440,
      lg: 1920,
      xl: 2560,
    },
  },
  palette: {
    primary: {
      main: "#5563F5",
      hover: "#3547FD",
      pressed: "#1021CB",
      tint: "#F1F2FE",
    },
    purple: {
      main: "#5563F5",
      hover: "#3547FD",
      pressed: "#1021CB",
      tint: "#F1F2FE",
    },
    darkBg: {
      main: "#FFFFFF",
      high: "#FFFFFF",
      medium: "#E0E0E0",
      low: "#A5A5A6",
    },
    lightBg: {
      main: "#1C1E26",
      high: "#1C1E26",
      medium: "#383940",
      low: "#767B85",
    },
    error: {
      main: "#DA1E28",
      tint: "#FFF3F2",
    },
    success: {
      main: "#198038",
      tint: "#E0FFE0",
    },
    warning: {
      main: "#FF832B",
      tint: "#FFE6D5",
    },
    info: {
      main: "#008DCA",
      tint: "#E9FCFF",
    },
    "warning-minor": {
      main: "#F1C21B",
      tint: "#F8F1CF",
    },
    secondary: {
      main: "#EBFCFE",
      "purple-tint": "#8791F8",
      "purple-tint-3": "#E7E9FE",
    },
    neutral: {
      main: "#FFFFFF",

      "005": "#F6F6F5",
      "010": "#EDEDED",
      "015": "#E3E3E3",
      "020": "#CCCCCC",
      "B-002": "#F7F9FC",
      "B-005": "#F0F4FC",
      "B-010": "#E7EBF3",
      "B-015": "#DDE3EE",
      "B-020": "#CCD5E6",
    },
  },
  typography: {
    fontFamily: "ProductSans",
    h1: {
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "48px",
      letterSpacing: "0px",
      fontFamily: "SegoeBold",
      color: "#1C1E26",
    },
    h2: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "40px",
      letterSpacing: "0px",
      fontFamily: "SegoeBold",
      color: "#1C1E26",
    },
    h3: {
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "32px",
      letterSpacing: "0px",
      fontFamily: "SegoeBold",
      color: "#1C1E26",

      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "24px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "28px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "32px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "40px",
      // },
    },
    h4: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "28px",
      letterSpacing: "0px",
      color: "#1C1E26",
      fontFamily: "SegoeBold",

      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "20px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "24px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "28px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "32px",
      // },
    },
    h5: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0px",
      fontFamily: "SegoeBold",
      color: "#1C1E26",

      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "16px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "20px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "24px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "28px",
      // },
    },
    h6: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "0px",
      fontFamily: "SegoeSemibold",
      color: "#1C1E26",

      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "16px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "16px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "18px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "22px",
      // },
    },
    caption: {
      fontWeight: 700,
      fontSize: "12px",
      letterSpacing: "0.5px",
      lineHeight: "16px",
      fontFamily: "SegoeBold",
      color: "#1C1E26",

      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "14px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    "caption-2": {
      fontWeight: 500,
      fontSize: "12px",
      letterSpacing: "0.5px",
      lineHeight: "16px",
      color: "#1C1E26",
      fontFamily: "SegoeMedium",

      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "14px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    "caption-3": {
      fontWeight: 700,
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: "16px",
      color: "#1C1E26",
      fontFamily: "SegoeBold",
      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "14px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    "caption-xs": {
      fontWeight: 600,
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: "16px",
      color: "#1C1E26",
      fontFamily: "Segoebold",
      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: "12px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: "14px",
      // },
      // [theme.breakpoints.up("xl")]: {
      //   fontSize: "16px",
      // },
    },
    "body-large": {
      fontWeight: 400,
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "24px",
      fontFamily: "SegoeRegular",
      color: "#1C1E26",
    },
    "body-medium": {
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "20px",
      fontFamily: "SegoeRegular",
      color: "#1C1E26",
    },
    "body-small": {
      fontWeight: 400,
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "16px",
      fontFamily: "SegoeRegular",
      color: "#1C1E26",
    },
    "body-xsmall": {
      fontWeight: 400,
      fontSize: "10px",
      letterSpacing: "0px",
      lineHeight: "16px",
      fontFamily: "SegoeRegular",
      color: "#1C1E26",
    },
    "button-large": {
      fontWeight: 600,
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "24px",
      color: "#1C1E26",
      fontFamily: "SegoeSemiBold",
    },
    "button-small": {
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "20px",
      color: "#1C1E26",
      fontFamily: "SegoeSemiBold",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "22px",
      fontFamily: "SegoeMedium",
      color: "#1C1E26",
    },
    "link-small": {
      fontWeight: 500,
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "24px",
      fontFamily: "SegoeMedium",
      color: "#5563F5",
      textDecoration: "underline",
    },
    // removing unused typography
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    overline: undefined,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          "body-large": "p",
          "body-small": "p",
          "body-medium": "p",
          "caption-2": "span",
          "caption-3": "span",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        // Customize scrollbar for webkit based browsers - Chrome, Edge, Safari, Opera etc.
        "::-webkit-scrollbar": {
          display: "none",
        },
        "::-webkit-scrollbar-button ": {
          display: "block",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#E7EBF3",
          border: "none",
          borderRadius: "8px",
          padding: "0 8px",
        },
        "&::-webkit-scrollbar-track-piece": {
          backgroundColor: "transparent",
          border: "none",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#FFF",
          borderRadius: "8px",
          border: "2px solid #CCD5E6",
          margin: "4px",
        },
        // Customize scrollbar for Firefox and others
        "*": {
          scrollbarWidth: "none",
          scrollbarColor: "#CCC #FFF",
        },
      },
      // styleOverrides: `
      //   @font-face {
      //     font-family: 'ProductSans';
      //     font-style: regular
      //     font-display: swap;
      //     font-weight: 400;
      //     src: local('ProductSans'), local('SegoeRegular'), url(${ProductSans}) format('ttf');
      //     unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      //   }
      // `,
    },

    // MuiTextField: {
    //   variants: {
    //     props: {
    //       variant:{}
    //     },
    //   },
    // },
  },
});

// theme.typography.h3 = {
//   ...theme.typography.h3,
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "24px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "28px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "32px",
//   },
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "40px",
//   },
// };
// theme.typography["body-large"] = {
//   ...theme.typography["body-large"],
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "18px",
//   },
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "22px",
//   },
// };
// theme.typography["body-medium"] = {
//   ...theme.typography["body-medium"],
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "20px",
//   },
// };
// theme.typography["body-small"] = {
//   ...theme.typography["body-small"],
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "12px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "12px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "16px",
//   },
// };

// theme.typography["body-xsmall"] = {
//   ...theme.typography["body-xsmall"],
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "10px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "10px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "10px",
//   },
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "10px",
//   },
// };

export default theme;
