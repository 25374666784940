import { Stack, Typography, useTheme } from "@mui/material";
import { Button } from "components/button";
import { BellIcon } from "components/icons";
import React from "react";
import { debugConsole } from "utilz/helpers";

export const NoSubscriptionBanner = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        backgroundColor: theme.palette["warning-minor"].tint,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        p: "16px",
        position: "relative",
      }}
    >
      <Stack direction={"row"} gap={"16px"}>
        <BellIcon htmlColor={theme.palette["warning-minor"].main} />
        <Stack direction={"row"} gap={"4px"}>
          <Typography variant="body-medium">
            Your plan has expired. To maintain access to our features, please
            renew your subscription.
          </Typography>
        </Stack>
      </Stack>

      <Button
        size="small"
        onClick={() => {
          try {
            // @ts-ignore
            window.Intercom("show");
          } catch (error) {
            debugConsole(error);
          }
        }}
      >
        Contact sales
      </Button>
    </Stack>
  );
};
