import { Circle } from "@mui/icons-material";
import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { ChartProps } from "../Utils";

export const TableChart = ({
  data = [],
  labelKey = "label",
  valueKey = "value",
  width = "100%",
  height = "100%",
  isLoading = false,
}: ChartProps) => {
  return isLoading ? (
    <Stack
      sx={{
        justifyContent: "center",
        // Replace with your desired box background color
        width: "100%",
        height: "100%",
        gap: "8px",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Stack flex={1} direction={"column"} width={"100%"}>
        {[...Array(6)].map((value, index) => (
          <Stack
            direction={"row"}
            gap={"32px"}
            width={"100%"}
            py={"16px"}
            borderBottom={"1px solid #e0e0e0"}
            justifyContent={"center"}
          >
            <Stack
              direction={"row"}
              gap={"32px"}
              height={"100%"}
              width={"100%"}
            >
              <Skeleton
                sx={{
                  height: "100%",
                  aspectRatio: "1",
                  WebkitTransform: "unset",
                }}
              />
              <Skeleton
                sx={{
                  height: "100%",
                  width: "40%",
                  WebkitTransform: "unset",
                }}
              />
            </Stack>
            <Box width={"20%"} height={"100%"}>
              <Skeleton
                sx={{
                  height: "100%",
                  width: value,
                  maxHeight: "16px",
                  WebkitTransform: "unset",
                }}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
    </Stack>
  ) : (
    <Stack
      width={width}
      height={height}
      sx={{
        overflow: "scroll",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          width: 0,
          height: 0,
        },
      }}
    >
      {data.map((item) => (
        <Stack
          direction={"row"}
          p={"16px"}
          gap={"12px"}
          sx={{
            borderTop: "1px solid #e0e0e0",
          }}
          alignItems={"center"}
        >
          <Circle
            sx={{
              width: "16px",
              height: "16px",
              color: item.color || "#0099ff",
            }}
          />
          <Typography
            variant="body-medium"
            flex={1}
            sx={{
              textTransform: "capitalize",
            }}
          >
            {item[labelKey]}
          </Typography>
          <Typography variant="body-medium">{item[valueKey]}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
