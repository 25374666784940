import { CustomSelectField } from "../../../../../../../../../../../../../../library/system/custom-form";
import { commonApiCalls } from "../../../../../../../../../../../../../../services/commonApiCalls";
import { CustomContentSelectField } from "../../../../../../../../../Components";

export const TICKET_DETAILS_CONSTANT = {
  top: {
    urgency: {
      name: "urgency",
      label: "Urgency",
      data_name: "urgency", //key from getTickettemplate is different
      place_holder: "Select Urgency",
      getData: commonApiCalls.getAllUrgencyTypes,
      type: "select",
      dependecy: false,
      triggerValue: [],
      component: CustomSelectField,
      defaultValue: "",
    },
    work_type: {
      name: "work_type_id",
      label: "Work type",
      data_name: "work_type",
      place_holder: "Select Work type",
      getData: commonApiCalls.getAllWorkTypes,
      type: "select",
      dependecy: true,
      triggerValue: [],
      component: CustomSelectField,
      defaultValue: "",
    },
    ticket_type: {
      name: "ticket_type_id",
      label: "Ticket type",
      data_name: "ticket_type",
      place_holder: "Select Ticket type",
      getData: commonApiCalls.getAllTicketTypes,
      type: "select",
      dependecy: true,
      component: CustomSelectField,
      defaultValue: "",
      triggerValue: [],
      multilevel: true,
    },
    ticket_sub_type: {
      name: "ticket_subtype_id",
      label: "Ticket subtype",
      data_name: "ticket_sub_type",
      place_holder: "Select Ticket subtype",
      getData: async (value) => {
        var filerData = value[1];
        var result = await commonApiCalls.getAllTicketTypes(value);
        let filteredData = [];
        filteredData = result.filter((item) => item.value === filerData);
        if (
          filteredData.length > 0 &&
          filteredData[0]?.children &&
          filteredData[0]?.children.length > 0
        ) {
          return filteredData[0]["children"];
        }
        return [];
      },
      type: "select",
      dependecy: true,
      triggerValue: "ticket_type_id",
      component: CustomSelectField,
      defaultValue: "",
      multilevel: true,
    },
    source: {
      name: "source_id",
      label: "Source",
      data_name: "source",
      place_holder: "Select Source",
      getData: commonApiCalls.getAllSource,
      type: "select",
      dependecy: true,
      triggerValue: [],
      component: CustomSelectField,
      defaultValue: "",
    },
    impact: {
      name: "impact",
      label: "Impact",
      data_name: "impact",
      place_holder: "Select Impact",
      getData: commonApiCalls.getAllUrgencyTypes,
      type: "select",
      dependecy: false,
      triggerValue: [],
      component: CustomSelectField,
      defaultValue: "",
    },
    location: {
      name: "location_id",
      label: "Location",
      data_name: "location",
      place_holder: "Select Location",
      getData: commonApiCalls.getAllLocation,
      type: "select",
      triggerValue: [],
      dependecy: true,
      component: CustomSelectField,
      defaultValue: "",
    },
    budgeted_hours: {
      name: "budgeted_hours",
      label: "Budgeted hours",
      data_name: "budgeted_hours",
      place_holder: "",
      type: "text",
      component: CustomSelectField,
      defaultValue: "",
    },
    sla: {
      name: "sla_id",
      label: "SLA",
      data_name: "sla",
      place_holder: "Select SLA",
      dependecy: true,
      getData: commonApiCalls.getAllSla,
      type: "select",
      triggerValue: [],
      component: CustomSelectField,
      defaultValue: "",
    },
  },

  tags: {
    name: "tags",
    label: "Add tags",
    data_name: "tags",
    place_holder: "",
    defaultValue: [],
    dependency: true,
    populator: commonApiCalls.getAllTags,
  },
  attachments: {
    name: "attachments",
    data_name: "attachments",
    defaultValue: [],
  },
  show_users: {
    name: "show_user",
    data_name: "show_user",
    label: "Show users while creating tickets",
    defaultValue: false,
  },
};
