import {
  Feedback1,
  Feedback2,
  GraphCards,
  HelloCard2,
  OnboardingModal,
} from "./components";
import { OnboardingModalProvider } from "./contexts/OnboardingModalContext";
import { Box, IconButton, Modal, Stack, useTheme } from "@mui/material";
import { HomeConfigBlocks } from "./components/configuration";
import { Helmet } from "react-helmet";
import { UpcomingActivities } from "./components/UpcomingActivities";
import { Helpdesk } from "./components/Helpdesk";
import { Overview } from "./components/Overview";
import { WorkDetails } from "./components/WorkDetails";
import { useEffect, useState } from "react";
import { useGetHomeScreenV2, useGlobalStore, useMainStore } from "controller";
import { TrialPeriodBanner } from "components/banner/TrialPeriodBanner";
// @ts-ignore
// import SubscriptionOffer from "assets/images/subscriptionBanner.svg";
import { Button, CloseIcon } from "components";
import { useLocation, useNavigate } from "react-router-dom";
// @ts-ignore
import HomeBg from "assets/images/mfaBg.webp";
// import OfferBanner from "assets/banners/25PercentOffer.png";
import { useMixpanelPageViewTracker } from "utilz/helpers";
import { dayjs } from "utilz";

const HomePage = () => {
  useMixpanelPageViewTracker({ event: "home_page_visited" });
  const navigate = useNavigate();
  const theme = useTheme();
  const subscriptionStatus = useGlobalStore(
    (state) => state.subscriptionStatus
  );
  const role = useGlobalStore((state) => state.role);
  const homeScreenResourceId = useMainStore(
    (state) => state.homeScreenResourceId
  );
  const loggedInResourceId = useGlobalStore((state) => state.resourceId);
  const setHomeScreenResourceId = useMainStore(
    (state) => state.setHomeScreenResourceId
  );
  const timezone = useGlobalStore((state) => state.timezone);
  const homeScreenViewFilter = useMainStore((state) => state.homeScreenView);
  const setHomeScreenViewFilter = useMainStore(
    (state) => state.setHomeScreenView
  );
  const { data: homeScreenDashboardData } = useGetHomeScreenV2({
    resourceId: homeScreenResourceId,
    filter: homeScreenViewFilter,
    timezone: timezone,
  });
  // const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);

  const trialPeriodEndDate = useGlobalStore((state) => state.trialEndDate);
  useEffect(() => {
    if (!!homeScreenResourceId || homeScreenResourceId === "")
      setHomeScreenResourceId(loggedInResourceId || "");
  }, []);
  // const MODAL_STORAGE_KEY = "25PercentBannerJuly24";
  // useEffect(() => {
  //   const hideOfferModal = localStorage.getItem(MODAL_STORAGE_KEY);
  //   if (
  //     hideOfferModal === "true" ||
  //     subscriptionStatus === "active" ||
  //     dayjs().isAfter(trialPeriodEndDate)
  //   ) {
  //     setIsOfferModalOpen(false);
  //   } else {
  //     if (role === "SUPERADMIN") {
  //       setIsOfferModalOpen(true);
  //     }
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        <title>DeskDay | Home</title>
      </Helmet>
      {subscriptionStatus !== "active" && <TrialPeriodBanner />}

      <Stack
        direction={"row"}
        width={"100%"}
        height={"calc(100% - 64px)"}
        columnGap={"32px"}
        px={"32px"}
        bgcolor={theme.palette.neutral["B-005"]}
        position="relative"
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${HomeBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            opacity: 1,
          }}
        />
        <Box
          zIndex={2}
          sx={{
            flex: 1,
            height: "100%",
            overflow: "scroll",
          }}
        >
          <Stack pt="32px" pb="16px" gap="16px">
            <HelloCard2 />
            <Feedback1 data={homeScreenDashboardData} />
            <Feedback2 data={homeScreenDashboardData} />
            <GraphCards />
          </Stack>
        </Box>
        <UpcomingActivities />
      </Stack>
      {/* <Modal
        slotProps={{
          backdrop: {
            sx: {
              background: "rgba(0, 0, 0, 0.40)",
              backdropFilter: "blur(2px)",
            },
          },
        }}
        open={isOfferModalOpen}
        onClose={(e, reason) => {
          localStorage.setItem(MODAL_STORAGE_KEY, "true");
          setIsOfferModalOpen(false);
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Stack
            onClick={() => {
              localStorage.setItem(MODAL_STORAGE_KEY, "true");
              setIsOfferModalOpen(false);
              navigate("/app/control-center/subscription");
            }}
            style={{ width: "45%", height: "45%", position: "relative" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                localStorage.setItem(MODAL_STORAGE_KEY, "true");
                setIsOfferModalOpen(false);
              }}
              sx={{
                position: "absolute",
                right: "16px",
                top: "16px",
                bgcolor: "transparent",
                cursor: "pointer",
                width: "42px",
                height: "42px",
              }}
            ></IconButton>

            <img src={OfferBanner} alt="subscription offer" />
          </Stack>
        </Box>
      </Modal> */}
    </>
  );
};

export { HomePage };
