import { Box, Divider, Stack } from "@mui/material";
import CustomIcon from "library/icon-library";
import Quill from "quill";
import ReactDOMServer from "react-dom/server";

const quillIcons = Quill.import("ui/icons");
function QuillToolBar() {
  quillIcons["align"][""] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="align_left" color={"black"} size={"lg"} />
  );
  quillIcons["align"]["center"] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="align_center" color={"black"} size={"lg"} />
  );
  quillIcons["align"]["right"] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="align_right" color={"black"} size={"lg"} />
  );
  quillIcons["list"]["ordered"] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="ordered_list" color={"black"} size={"lg"} />
  );
  quillIcons["list"]["bullet"] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="unordered_list" color={"black"} size={"lg"} />
  );
  quillIcons["bold"] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="bold" color={"black"} size={"lg"} />
  );
  quillIcons["underline"] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="underline" color={"black"} size={"lg"} />
  );
  quillIcons["italic"] = ReactDOMServer.renderToStaticMarkup(
    <CustomIcon icon="italic" color={"black"} size={"lg"} />
  );

  return (
    <Box>
      <Box
        id="quill-toolbar"
        sx={{
          p: "0px",
          m: "0px",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            px: "16px",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              py: "8px",
            }}
          >
            <select className="ql-size">
              <option value="small"></option>
              <option selected></option>
            </select>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Stack
            sx={{
              py: "8px",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack
            sx={{
              flexDirection: "row",
              py: "8px",
              gap: "16px",
            }}
          >
            <button type="button" className="ql-align" value=""></button>
            <button type="button" className="ql-align" value="center"></button>
            <button type="button" className="ql-align" value="right"></button>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack
            sx={{
              flexDirection: "row",
              py: "8px",
              gap: "16px",
            }}
          >
            <button type="button" className="ql-list" value="ordered"></button>
            <button type="button" className="ql-list" value="bullet"></button>
          </Stack>
          <Divider orientation="vertical" flexItem />
        </Stack>
      </Box>
    </Box>
  );
}

export default QuillToolBar;
