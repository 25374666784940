import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteMspLogo,
  getMspBillingDetails,
  getMspById,
  updateMsp,
  updateMspBilling,
  updateMspLogo,
} from "controller/api/control-center/msp";
import { ApiErrorType, UpdateMspBillingInput, UpdateMspInput } from "shared";
import { sentryLogger } from "utilz";

export const useGetMspById = (id?: string | null) => {
  return useQuery({
    queryKey: ["msp", id],
    queryFn: () => getMspById(),
    enabled: id ? true : false,
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getMspById",
      }),
  });
};

export const useUpdateMsp = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateMspInput) => updateMsp(data),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["msp"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateMsp",
      });
    },
  });
};

export const useUpdateMspLogo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (file: any) => updateMspLogo(file),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["msp"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateMspLogo",
      });
    },
  });
};

export const useDeleteMspLogo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deleteMspLogo(),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["msp"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteMspLogo",
      });
    },
  });
};

export const useUpdateMspBilling = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateMspBillingInput) => updateMspBilling(data),
    onSuccess: (_, variables, __) => {
      queryClient.invalidateQueries(["msp_billing"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateMspBilling",
      });
    },
  });
};

export const useGetMspBillingDetails = (id?: string) => {
  return useQuery({
    queryKey: ["msp_billing", id],
    queryFn: () => getMspBillingDetails(id || ""),
    enabled: id ? true : false,
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getMspBillingDetails",
      }),
  });
};
