import React from "react";
import { Outlet } from "react-router-dom";

// Legacy Imports
import Box from "@mui/material/Box";

export const CustomersLayout = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Outlet />
    </Box>
  );
};
