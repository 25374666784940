import { Box, Grid, Stack, Typography } from "@mui/material";
// @ts-ignore
import settingsImg from "assets/images/settingsImg.svg";
import { ErrorLayout } from "./ErrorLayout";
import { useNavigate } from "react-router-dom";
import { Button } from "components/button";
export const UnautherizedComponent = () => {
  const navigate = useNavigate();
  return (
    <ErrorLayout
      errorImg={settingsImg}
      leftSide={
        <Stack
          sx={{
            maxWidth: "360px",
            gap: "16px",
          }}
        >
          <Typography variant="h3">Unauthorized Access</Typography>
          <Typography
            variant="body-large"
            sx={{
              textAlign: "justify",
            }}
          >
            Please check the URL again or let us take you back to the homepage
          </Typography>
          <Button
            fullWidth
            onClick={() => {
              navigate("/app/home");
            }}
          >
            Back to home
          </Button>
        </Stack>
      }
    />
  );
};
