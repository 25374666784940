import { Box, Divider, Stack } from "@mui/material";
import Quill from "quill";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { QuillToolBar } from "./components";
import ReactQuill from "react-quill";
import { QUILL_DESIGN_CONSTANT } from "./design-constant";
import PropTypes from "prop-types";
import { extractStringFromHtml } from "utilz/helpers";
const Delta = Quill.import("delta");
function CustomQuillEditor(props) {
  const onChangeHandler = (data) => {
    const containsImgTag = /<img\b[^>]*>/i.test(data);
    props.onChange({
      field: props.name,
      value:
        extractStringFromHtml(data).length > 0 || containsImgTag ? data : "",
    });
  };

  return (
    // <ReactQuill theme="snow" value={value} onChange={onChangeHandler} />
    <Box sx={QUILL_DESIGN_CONSTANT}>
      <div
        id="quill-editor"
        style={{
          width: "100%",
          height: "100%",
          // maxHeight: "400px",
        }}
      >
        <QuillToolBar />
        <ReactQuill
          style={{
            height: "100%",
            overflow: "scroll",
            border: props.error ? "1px solid #DA1E28" : "1px solid #d9d9d9",
            whiteSpace: "pre-line",
          }}
          theme="snow"
          value={props.value}
          onChange={onChangeHandler}
          placeholder={`${props.placeholder}${props.isRequired ? "*" : ""}`}
          modules={{
            toolbar: {
              container: "#quill-toolbar",
            },
          }}
        />
      </div>
    </Box>
  );
}

CustomQuillEditor.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
};

CustomQuillEditor.defaultProps = {
  value: "",
  name: "",
  placeholder: "",
  onChange: () => {},
  isRequired: false,
  error: false,
};

export default CustomQuillEditor;
