import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ExternalLinkIcon, Tab, Tabs } from "components";
import teamsApp from "assets/images/teamsApp.webp";

export const TeamsTab = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        overflow: "scroll",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          p: "32px",
          width: "100%",
          minWidth: "800px",
          height: "100%",
          display: "flex",
          overflow: "auto",
          gap: "24px",
        }}
      >
        <Typography variant="h4">Setup Chat-based Ticketing</Typography>
        <Box
          height={"fit-content"}
          width="100%"
          display="grid"
          gridTemplateColumns="4fr 6fr"
          rowGap={"16px"}
          p="40px"
          border={`1px solid ${theme.palette.neutral["015"]}`}
          borderRadius="8px"
          position="relative"
        >
          <Stack gap="24px">
            <Typography
              variant="h6"
              sx={{
                fontWeight: "700 ! important",
              }}
            >
              Allow users to raise their next ticket right from their Teams app.
              No more switching tools to get the support they need.
            </Typography>
            <Typography variant="body-medium">
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                Signing into Teams :
              </span>{" "}
              Direct users to sign into the Teams app from the Home screen. If
              Single Sign-On (SSO) is activated, they'll be automatically logged
              in upon opening the app - no extra steps required.
            </Typography>
            <Typography variant="body-medium">
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                Service Ticket Creation :
              </span>{" "}
              Users can quickly start the process of creating a service ticket
              either through the chatbot or directly from the Home tab.
            </Typography>
            <Typography variant="body-medium">
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                Instant Tech Interaction :
              </span>{" "}
              As soon as a tech is assigned to their ticket, users can start a
              conversation with them immediately.
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              width={"fit-content"}
              sx={{
                alignItems: "center",
                justifyContent: "start",
                cursor: "pointer",
                marginBottom: "55px",
                color: theme.palette.purple.main,
                "& .imageCardTitle": {
                  color: theme.palette.purple.main,
                  fontWeight: "400",
                },
                "&:hover > .imageBox img": {
                  marginTop: "24px !important",
                  marginLeft: "32px !important",
                  transform: "all 3s ease-in-out",
                },
              }}
              onClick={() => {
                window.open(
                  "https://teams.microsoft.com/l/app/0c4388c5-bb07-433d-9ee1-d379351c6844?source=app-details-dialog",
                  "_blank"
                );
              }}
            >
              <Typography className="imageCardTitle" variant="link-small">
                Click here to Add
              </Typography>
              <ExternalLinkIcon sx={{ width: "20px", height: "20px" }} />
            </Stack>
          </Stack>
          <Stack paddingBottom="2%" alignItems={"end"}>
            <img
              style={{
                width: "95%",
                height: "auto",
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
                marginLeft: "auto",
                maxHeight: "90%",
              }}
              src={teamsApp}
            />
          </Stack>
          <Stack
            position="absolute"
            bottom="0"
            left="0"
            right={"0"}
            p="12px 24px"
            direction={"row"}
            justifyContent={"space-between"}
            bgcolor={theme.palette.secondary["purple-tint-3"]}
          >
            <Typography variant="body-medium">
              Once set up, you can also onboard your users smoothly through "SSO
              Integration"
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              width={"fit-content"}
              sx={{
                alignItems: "center",
                justifyContent: "start",
                cursor: "pointer",
                color: theme.palette.purple.main,
                "& .imageCardTitle": {
                  color: theme.palette.purple.main,
                  fontWeight: "400",
                },
                "&:hover > .imageBox img": {
                  marginTop: "24px !important",
                  marginLeft: "32px !important",
                  transform: "all 3s ease-in-out",
                },
              }}
              onClick={() => {
                window.open(
                  "https://support.deskday.com/en/articles/9053980-how-to-install-it-connect-ms-teams",
                  "_blank"
                );
              }}
            >
              <Typography className="imageCardTitle" variant="link-small">
                How to Install IT-Connect on Microsoft Teams
              </Typography>
              <ExternalLinkIcon sx={{ width: "20px", height: "20px" }} />
            </Stack>
          </Stack>
        </Box>
        {/* <Box
          sx={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "32px",
            p: "40px",
            border: `1px solid ${theme.palette.neutral["015"]}`,
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <Stack
            sx={{
              flex: 3,
              gap: "16px",
              alignItems: "start",
            }}
          >
            <Typography variant="body-medium" color={theme.palette.lightBg.low}>
              Allow users to raise their next ticket right from their Teams app.
              No more switching tools to get the support they need.
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              width={"fit-content"}
              sx={{
                alignItems: "center",
                justifyContent: "start",
                cursor: "pointer",
                color: theme.palette.purple.main,
                "& .imageCardTitle": {
                  color: theme.palette.purple.main,
                  fontWeight: "400",
                },
                "&:hover > .imageBox img": {
                  marginTop: "24px !important",
                  marginLeft: "32px !important",
                  transform: "all 3s ease-in-out",
                },
              }}
              onClick={() => {
                window.open(
                  "https://support.deskday.com/en/articles/9053980-how-to-install-it-connect-ms-teams",
                  "_blank"
                );
              }}
            >
              <Typography className="imageCardTitle" variant="link-small">
                How to Install IT-Connect on Microsoft Teams
              </Typography>
              <ExternalLinkIcon sx={{ width: "20px", height: "20px" }} />
            </Stack>
          </Stack>

          <Box flex={5} width="53%" bgcolor={"beige"} paddingBottom="2%">
            <img
              style={{
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat",
                objectFit: "contain",
                backgroundColor: "red",
              }}
              src={teamsApp}
            />
          </Box>
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right={"0"}
            p="12px 24px"
            bgcolor={theme.palette.secondary["purple-tint-3"]}
          >
            <Typography variant="body-medium">
              Once set up, you can also onboard your users smoothly through "SSO
              Integration"
            </Typography>
          </Box>
        </Box> */}
      </Stack>
    </Box>
  );
};
