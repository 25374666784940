import { UpdateMspNotificationInput } from "shared";
import { axiosClient } from "utilz";

export const getMspNotificationSettings = async () => {
  try {
    const url = "/controlCenter/msp_notifications";

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateMspNotificationSettings = async ({
  notificationType,
  notificationSound,
  notificationStatus,
  notifyMethods,
  notifyRoles,
}: UpdateMspNotificationInput) => {
  try {
    const url = `/controlCenter/update/msp_notification/notify_type/${notificationType}`;

    const data = {
      notification_status: notificationStatus,
      notification_sound: notificationSound,
      notify_roles: notifyRoles,
      notify_methods: notifyMethods,
    };
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
