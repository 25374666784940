import {
  CreateSlaInput,
  GetQueryProps,
  GetSlaRulesInput,
  UpdateSlaInput,
} from "shared";
import { axiosClient, fetchCall } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const getAllSla = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: "v2/configuration/sla",
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};

export const getSlaRules = async ({ slaId }: GetSlaRulesInput) => {
  try {
    const url = `/v2/configuration/sla_rules/${slaId}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};

export const createSla = async (data: CreateSlaInput) => {
  try {
    const url = "/v2/configuration/sla";
    const response = await axiosClient.post(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateSla = async ({ slaId, ...data }: UpdateSlaInput) => {
  try {
    const url = `/v2/configuration/sla/${slaId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteSlaPolicies = async (slaIds: string[]) => {
  try {
    const url = "/v2/configuration/sla";
    const response = await axiosClient.delete(`${url}`, {
      data: {
        sla: slaIds,
      },
    });
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
