import { GetBillingTemplateInput, GetQueryProps } from "shared";
import { axiosClient, fetchCall } from "utilz";
import { generateUrlString, getMspId, getQueryDataObject } from "utilz/helpers";

export const getAllBillingTemplate = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      // filter,
      // searchQuery,
    });
    const url = await generateUrlString({
      baseUrl: "/v1/configuration/billing/mail-templates",
      data,
    });
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    console.log(error, "response");
    throw error;
  }
};

export const createBillingMailTemplate = async (data: any) => {
  try {
    const url = "/v1/configuration/billing/mail-templates";
    const response = await axiosClient.post(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw resData?.message;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteBillingTemplate = async (data: {
  invoice_template_ids: string[];
}) => {
  try {
    const url = "/v1/configuration/billing/mail-templates";
    const response = await axiosClient.put(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateBillingMailTemplate = async (data: any) => {
  try {
    const url = `/v1/configuration/billing/mail-templates/${data.id}`;
    const response = await axiosClient.patch(url, data?.data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw resData?.message;
    }
  } catch (error) {
    throw error;
  }
};
