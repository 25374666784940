import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Avatar,
  BookmarkButton,
  BookmarkFilledIcon,
  PlayStopButton,
  TimeCounter,
} from "components";
import {
  useEndTimer,
  useMainStore,
  useNonPersistantMainStore,
  useRemoveWatchListItem,
  useTimeEntryStore,
} from "controller";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getFullName } from "utilz/helpers";

interface WatchListItemProps {
  data: any;
  isTimeEntry?: boolean;
  close: () => void;
}

export const WatchListItem = ({
  data,
  isTimeEntry = false,
  close,
}: WatchListItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { mutateAsync: unpinWatchListItem, isLoading: isRemoveInProgress } =
    useRemoveWatchListItem();
  const { mutateAsync: endTimer, isLoading: isStoppingTimer } = useEndTimer();
  const globalEndTimeHandler = useTimeEntryStore(
    (state) => state.removeTimeEntry
  );
  const removeWatchListItemFromStore = useNonPersistantMainStore(
    (state) => state.removeWatchListData
  );
  const onClick = (isTimer?: boolean) => {
    switch (data.service_type) {
      case "ticket":
        navigate(`/app/service-desk/tickets/${data.service_id}`, {
          state: isTimer ? { timeEntry: data?.mapping_id } : {},
        });
        break;
      case "project":
        navigate(`/app/service-desk/projects/${data.service_id}`);
        break;
      case "task":
        navigate(
          `/app/service-desk/projects/${data?.project_id}/task/${data?.service_id}`,
          {
            state: isTimer ? { timeEntry: data?.mapping_id } : {},
          }
        );
        break;
      default:
        return;
    }
    close();
  };

  const stopTimeEntry = async () => {
    try {
      await endTimer({
        serviceId: data.service_id,
        serviceType: data?.service_type,
        timeEntryId: data.mapping_id,
      });
      globalEndTimeHandler({
        serviceId: data.service_id,
        timeEntryId: data.mapping_id,
        serviceType: data?.service_type,
      });
      setTimeout(() => {
        onClick(true);
      }, 500);
      return;
    } catch (e: any) {
      toast.error("Unable to stop time entry");
    }
  };

  const onRemoveFromWatchlist = async () => {
    try {
      await unpinWatchListItem({
        serviceId: data.service_id,
        serviceType: data.service_type,
      });
      removeWatchListItemFromStore(data.service_type, data.service_id);
      toast.success("Removed from watchlist");
    } catch (e: any) {
      toast.error("Unable to remove from watchlist");
    }
  };
  return (
    <Stack
      direction={"row"}
      sx={{
        pl: "24px",
        pr: "16px",
        py: "12px",
        gap: "16px",
        "&:hover": {
          bgcolor: theme.palette.neutral["005"],
          cursor: "pointer",
        },
      }}
      onClick={() => onClick()}
      width={"100%"}
    >
      <Avatar
        url={data?.profile_image}
        variants="lg"
        fullname={data?.customer_user_name}
      />
      <Stack flex={1} gap={"8px"}>
        <Typography
          variant="body-large"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data.service_summary}
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          width={"100%"}
          maxWidth={"260px"}
          divider={
            <Box
              sx={{
                width: "2px",
                height: "2px",
                aspectRatio: "1",
                borderRadius: "50%",
                bgcolor: theme.palette.lightBg.low,
                mx: "8px",
                display: "inline-flex",
              }}
            ></Box>
          }
        >
          <Typography
            variant="body-small"
            sx={{
              color: theme.palette.lightBg.low,
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "100%",
            }}
          >
            {data?.u_id}
          </Typography>
        </Stack>
        {isTimeEntry && (
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              gap: "2px",
              border: `1px solid ${theme.palette.neutral["015"]}`,
              borderRadius: "16px",
              width: "fit-content",
              p: "2px",
              pr: "6px",
              cursor: "default",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <PlayStopButton
              sx={{ boxShadow: "unset" }}
              disabled={isStoppingTimer}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                stopTimeEntry();
              }}
              isPlaying={true}
            />
            <TimeCounter value={data?.activeTimerValueInSeconds || 0} />
          </Stack>
        )}
      </Stack>
      <Box sx={{ width: "28px" }}>
        {(!isTimeEntry || data?.isWatchListed) && (
          <BookmarkButton
            onClick={onRemoveFromWatchlist}
            isBookmarked={true}
            isLoading={isRemoveInProgress}
          />
        )}
      </Box>
    </Stack>
  );
};
