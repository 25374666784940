import { Stack } from "@mui/material";
import { Button, Input, Modal } from "components";
import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "utilz/stripe";
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from "./StripeTextFields";
import {
  CardElement,
  CardNumberElementComponent,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

export const AddPaymentMethod = ({
  open,
  onClose,
  onPaymentAdded,
}: {
  open: boolean;
  onClose: () => void;
  onPaymentAdded?: (id: string) => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const handleSubmit = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required",
    });
    onPaymentAdded &&
      onPaymentAdded((result?.setupIntent?.payment_method as string) || "");
    if (result?.error) {
      toast.error(`${result?.error.message}`);
    } else {
      queryClient.invalidateQueries(["cards"]);
      toast.success("Payment method added successfully");
      onClose();
    }

    setIsLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        maxWidth="xs"
        title="Update Payment Method"
        primaryButtonText="Update"
        secondaryButtonText="Cancel"
        onClose={onClose}
        // @ts-ignore
        onPrimaryButtonClick={handleSubmit}
        onSecondaryButtonClick={onClose}
        isPrimaryButtonLoading={isLoading}
      >
        <Stack px="56px">
          <PaymentElement id="payment-element" />
        </Stack>
      </Modal>
    </>
  );
};
