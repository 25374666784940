import { GetQueryProps } from "./common";
import z from "zod";
import * as yup from "yup";

//  get all customer sites start
export interface GetAllCustomerSitesInput extends GetQueryProps {
  customerId?: string;
}

const CustomerSiteResultSchema = yup.object().shape({
  mapping_id: yup.string().required(),
  site_name: yup.string().required(),
  email: yup.string().nullable(),
  is_contact_person_as_primary_contact: yup.boolean().nullable(),
  contact_person: yup.string().nullable(),
  contact_number: yup.string().nullable(),
  is_active: yup.boolean().required(),
  country_id: yup.number().nullable(),
  state_id: yup.number().nullable().nullable(),
  country: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  zip_code: yup.string().nullable(),
});
export const GetAllCustomerSitesResponseSchema = yup.object().shape({
  totalCount: yup.string().required(),
  totalPages: yup.number(),
  result: yup.array(CustomerSiteResultSchema),
});
export type CustomerSiteResultType = yup.InferType<
  typeof CustomerSiteResultSchema
>;
export type GetAllCustomerSitesResponseType = yup.InferType<
  typeof GetAllCustomerSitesResponseSchema
>;
export interface GetAllUserGroupsInput extends GetQueryProps {
  customerId?: string;
}
//  get all customer sites end

const CustomerGroupResultSchema = z.object({
  mapping_id: z.string(),
  group_name: z.string(),
  group_description: z.string(),
  group_owner_id: z.string(),
  customer_id: z.string(),
  msp_id: z.string(),
  users: z.array(z.any()),
});

export const GetAllUserGroupResponseSchema = z.object({
  totalCount: z.string(),
  totalPages: z.nullable(z.number()),
  result: z.array(CustomerGroupResultSchema),
});

export interface CustomerUserSearchInput {
  searchQuery: string;
  customerId?: string;
  siteId?: string;
}
export interface GetAllCustomerUsersInput extends GetQueryProps {
  customerId?: string;
}
export interface GetAllCustomerUsersByMspIdInput extends GetQueryProps {
  mspId?: string | null;
}

export interface GetCustomerDocumentsInput extends GetQueryProps {
  customerId?: string;
}

export interface GetCustomerUserDocumentsInput extends GetQueryProps {
  customerUserId?: string;
}

export interface GetCustomerVendorsInput extends GetQueryProps {
  customerId?: string;
}
export interface DeleteCustomerDocumentsInput {
  customerId: string;
  attachmentIds: Array<string>;
}

export interface DeleteCustomersInput {
  customerIds: Array<string>;
}
export interface UpdateCustomerNotificationInput {
  notificationType: string;
  notificationStatus: boolean;
  notificationSound: boolean;
  notifyMethods: Array<string>;
  notifyRoles: Array<Record<string, string | null>>;
  customerId: string;
}

export interface UpdpateCustomerGeneralSettingsInput {
  timezone: any;
  bussiness_hours_from?: string;
  bussiness_hours_tots?: string;
}

export interface SetCustomerUserInput {
  userId: string;
  customerId: string;
  managers: string[];
}

export interface BulkDeleteCustomerUserInput {
  userIds: string[];
}

export interface BulkCreateCustomerUserInput {
  customerId: string;
  file: any;
}
export interface CreateCustomerUserInput {
  customerId: string;
  data: {
    email: string;
    first_name: string;
    middle_name?: string;
    last_name: string;
    is_privileged_user?: boolean;
    customer_name: string;
    site: string;
    phone_number?: string;
    country: string;
    state?: string;
    city?: string;
  };
}

export interface UpdateCustomerUserInput {
  customerUserId: string;
  data: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    phone_number?: string;
    is_privileged_user?: boolean;
    designation?: string;
    customer_user_department?: string;
    country_id?: string;
    state_id?: string;
    city?: string;
    street?: string;
    zip_code?: string;
    contact_number?: string;
    is_active?: boolean;
    is_locked?: boolean;
  };
}

export interface CreateCustomerInput {
  customer_name: string;
  domain_name?: string[];
  country: string;
  state?: string;
  city?: string;
  street?: string;
}

export interface CusotomerBillingInvoice {
  template_id: string;
  invoice_number_settings: string;
  invoice_number_prefix: string;
  // invoice_number: string;
  // invoice_number_starts_from: string;
  invoice_number_starts_from: number;

  email_to_users: string[];
  email_cc_users: string[];
  address_1: string;
  address_2: string;
  street: string;
  country: string;
  state: string;
  postal_code: string;
  // city: any
  city: string | number | null; // Allow string, number, or null
}

export type NoteDataType = {
  title: string;
  note: string;
};

export type AddNewCustomerNoteInput = {
  customerId: string;
  noteData: NoteDataType;
};

export type AddNewCustomerUserNoteInput = {
  customerUserId: string;
  noteData: NoteDataType;
};

export type UpdateCustomerDefaultSla = {
  customerId: string;
  slaId: string;
};

export type UpdateCustomerContactsInput = {
  customerId: string;
  data: {
    primary_contact_mapping_id: string | null;
    secondary_contact_mapping_id: string | null;
    owner_mapping_id: string | null;
  };
};
