import React, { Component } from "react";

// Services
import { services } from "../../../../services";

// Legacy Imports
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";

// HOC Imports
import {
  BottomStickyLayer,
  CustomCard,
  SlideModal,
} from "../../../../library/layers";
import { PaginationControls } from "../../../../library/mounts";
import { StaticTable } from "../../../../library/system";
import { CustomButton } from "../../../../library/base";

// Child Mount Components
import { LocationsHeadMount, LocationFormComponent } from "./components";
import { locationsPageConstants } from "./constants";
import { getMspId } from "utilz/helpers";
import toast from "react-hot-toast";
import { FORM_FIELDS } from "./components/location-details/constants";

class LocationsPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      data: [],
      searchQuery: { status: false, queryString: "" },
      pagination: { current: 1, total: 0 },
      modal: false,
      selected: "",
      enableFormFields: false,
    };
  }

  // Lifecycle Methods
  componentDidMount() {
    this.fetchAllLocations(this.state?.pagination["current"]);
  }
  componentDidUpdate() {
    // console.log('Location component has updated');
    // console.log(this.state);
  }

  // API Calls
  fetchAllLocations = (page = this.state?.pagination["current"]) => {
    try {
      services.controlCenterservices.locationServices
        .fetchAllLocationsByMspId(
          getMspId(),
          10,
          page,
          "location_name",
          "asc",
          this.state?.searchQuery
        )
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.success === true &&
              response?.statusCode === 200 &&
              response?.data &&
              response?.data?.result &&
              Array.isArray(response?.data?.result)
            ) {
              this.setState({
                ...this.state,
                data: response?.data?.result,
                pagination: {
                  ...this.state?.pagination,
                  total:
                    response?.data["totalPages"] ||
                    this.state?.pagination["total"],
                  current: page,
                },
              });
            }
          },
          error: (error) => {
            console.log("[ERROR] Creating new location");
          },
        });
    } catch (err) {
      console.log("[ERROR] Creating new location");
      console.log(err);
    }
  };
  updateLocationDetails = (data) => {
    try {
      services.controlCenterservices.locationServices
        .updateLocationByLocationId(data?.id, data?.formdata)
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.success === true &&
              response?.statusCode === 200
            ) {
              this.setState({
                ...this.state,
                modal: false,
                enableFormFields: false,
              });
              toast.success(response?.message || "Location has been updated");
              this.fetchAllLocations(this.state?.pagination["current"]);
            } else {
              toast.error(
                "Unable to update location at the moment. Please try again"
              );
            }
          },
          error: (error) => {
            toast.error(
              "Unable to update location at the moment. Please try again"
            );
          },
        });
    } catch (err) {
      toast.error("Unable to update location at the moment. Please try again");
    }
  };
  deleteLocation = (data) => {
    try {
      const idList = { location_ids: data };
      services.controlCenterservices.locationServices
        .deleteLocationsByIdList(idList)
        .subscribe({
          next: (response) => {
            console.log(response);
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true
            ) {
              this.fetchAllLocations();

              toast.success(
                response?.message || "Selected location has been deleted"
              );
            } else {
              toast.error(
                "Unable to delete locations at the moment. Please try again"
              );
            }
          },
          error: (error) => {
            toast.error(
              "Unable to delete locations at the moment. Please try again"
            );
          },
        });
    } catch (err) {
      toast.error("Unable to delete locations at the moment. Please try again");
    }
  };

  // Event Handlers
  resetToaster = () =>
    this.setState({
      ...this.state,
      toasters: { open: false, severity: "success", message: "" },
    });
  closeModal = () => this.setState({ ...this.state, modal: false });
  openModal = () => this.setState({ ...this.state, modal: true });
  updateFilter = (newValue) => {
    this.setState(
      {
        ...this.state,
        searchQuery: { status: true, queryString: newValue },
      },
      () => this.fetchAllLocations(1)
    );
  };
  resetFilters = () => {
    this.setState(
      {
        ...this.state,
        searchQuery: { status: false, queryString: "" },
      },
      () => this.fetchAllLocations(1)
    );
  };
  triggerOnCreateNew = () =>
    this.fetchAllLocations(this.state?.pagination["current"]);

  onSubmitHandler = () => {
    const data = this.formRef.current.submit();
    const validate = Object.entries(data?.formdata).every(([key, value]) => {
      return (
        !FORM_FIELDS[key].required ||
        value !== "" ||
        (value !== "" && !isNaN(value) && value >= 0)
      );
    });
    if (validate) {
      this.updateLocationDetails(data);
      return;
    }
    toast.error("Fill all required fields");
  };

  // Renderer
  render() {
    return (
      <React.Fragment>
        <SlideModal
          open={this.state?.modal}
          isEdit={true}
          edit={this.state?.enableFormFields}
          modalSize={"small"}
          modalTitle={"Edit Location"}
          modalBody={
            <LocationFormComponent
              ref={this.formRef}
              locationId={this.state?.selected}
              isEdit={this.state?.enableFormFields === false}
            />
          }
          modalFooter={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <CustomButton
                  varient={"secondary"}
                  size="sm"
                  label="Cancel"
                  isTrailingIcon={false}
                  icon=""
                  onClick={this.closeModal}
                />
                <CustomButton
                  varient={"primary"}
                  size="sm"
                  label="Save"
                  isTrailingIcon={false}
                  icon=""
                  onClick={this.onSubmitHandler}
                />
              </Stack>
            </Box>
          }
          closeHandle={this.closeModal}
          headerActionCallback={(currentValue) => {
            console.log(currentValue);
            this.setState({ ...this.state, enableFormFields: currentValue });
          }}
        />
        <Box sx={{ margin: "24px 32px 96px 32px" }}>
          <LocationsHeadMount
            onCreateNew={this.triggerOnCreateNew}
            onUpdateFilter={this.updateFilter}
            onResetFilter={this.resetFilters}
            searchValue={this.state?.searchQuery["queryString"]}
          />
          <Box sx={{ width: "100%" }}>
            <CustomCard
              borderRadius={"24px"}
              outlined={true}
              spacing={"0px"}
              content={
                <StaticTable
                  headers={Object.values(locationsPageConstants.tableHeaders)}
                  data={this.state.data.map((element) => {
                    return { ...element, disableSelection: false };
                  })}
                  selectionKey={"mapping_id"}
                  onDeleteClicked={this.deleteLocation}
                  onSelectionClicked={(key) => {
                    this.setState({ ...this.state, selected: key }, () =>
                      this.openModal()
                    );
                  }}
                />
              }
            />
          </Box>
        </Box>

        <BottomStickyLayer
          content={
            <PaginationControls
              currentPage={this.state?.pagination["current"]}
              totalPages={this.state?.pagination["total"]}
              // perPageItems={10}
              // totalItems={300}
              getNewPage={(newPageNumber) =>
                this.fetchAllLocations(newPageNumber)
              }
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default LocationsPage;
