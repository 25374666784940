import React, { Component, useEffect, useState } from "react";

// Legacy Imports
import Box from "@mui/material/Box";

// HOC Imports
import {
  BottomStickyLayer,
  CustomCard,
  SlideModal,
} from "../../../../../../library/layers";
import { PaginationControls } from "../../../../../../library/mounts";
import { CustomToaster, StaticTable } from "../../../../../../library/system";

// Services Import
import { services } from "../../../../../../services";

// Components Imports
import {
  ResourcesTypeHeadMount,
  ResourceTypeDetails,
  ResourceTypeModal,
} from "./components";

// Constants Imports
import { ResourceTypeDataBuilder } from "../resource-types-tab/Utils";

import { useGlobalStore } from "controller/store/globalStore";
import toast from "react-hot-toast";
import { RESOURCE_TYPES } from "../../constants";
import { DataTable } from "components";

const headers = [
  {
    name: "Name",
    sortable: false,
    key: "resource_type_name",
  },
  {
    name: "Description",
    sortable: false,
    key: "resource_type_description",
  },
];

function ResourcesAndPermissionsPageResourceTypesTab() {
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    data: [],
    pagination: { current: 1, total: 0 },
    enableFormFields: false,
  });

  const [selectedData, setSelectedData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState({
    isFilter: false,
    queryString: "",
  });

  // Lifecycles
  useEffect(() => {
    fetchAllResourceType(1);
  }, []);
  // API Calls
  const fetchAllResourceType = (
    pageNumber = state.pagination.current,
    searchQueryData = searchQuery.queryString
  ) => {
    services.controlCenterservices.resourcesService
      .fetchAllResourceType(
        mspId,
        10,
        pageNumber,
        "created_at",
        "asc",
        searchQueryData
      )
      .subscribe({
        next: (response) => {
          if (
            response &&
            response?.success === true &&
            response?.statusCode === 200 &&
            response?.data &&
            Array.isArray(response?.data?.result)
          ) {
            setState({
              ...state,
              data: ResourceTypeDataBuilder(response.data.result),
              modal: false,
              selected: "",
              enableFormFields: false,
            });
          }
        },
        error: (error) => {},
      });
  };

  const deleteResourceTypesById = (ids) => {
    services.controlCenterservices.resourcesService
      .deleteBulkResourceTypes(mspId, ids)
      .subscribe({
        next: (response) => {
          if (
            response &&
            response?.success === true &&
            response?.statusCode === 200
          ) {
            toast.success(
              Array.isArray(ids) && ids.length > 1
                ? "Resource types are deleted"
                : "Resource type is deleted"
            );
            fetchAllResourceType(1);
            return;
          }
          toast.error("Unable to delete at the moment.Try again later");
        },
        error: (error) => {
          toast.error("Unable to delete at the moment.Try again later");
        },
      });
  };

  // const fetchResourceTypeById = () => {
  //   services.controlCenterservices.resourcesService.fetchResourceTypeById()
  // };

  // Event Handlers
  const openModal = () => setState({ ...state, modal: true });
  const closeModal = () => setState({ ...state, modal: false });

  const onDeleteHandler = (ids) => {
    if (
      Array.isArray(ids) &&
      Object.values(RESOURCE_TYPES).every((item) => !ids.includes(item))
    ) {
      deleteResourceTypesById({
        resource_type_ids: ids,
      });
      return;
    }
    toast.error("Cannot delete default resource types");
  };

  return (
    <React.Fragment>
      {/* {state?.modal ? (
        <SlideModal
          open={state?.modal}
          isEdit={true}
          edit={state?.enableFormFields}
          modalSize={"medium"}
          modalTitle={"Edit Resource Type"}
          modalBody={
            <ResourceTypeDetails
              resource_type_id={state?.selected}
              isEdit={state?.enableFormFields === false}
              onClose={closeModal}
            />
          }
          modalFooter={<></>}
          closeHandle={closeModal}
          headerActionCallback={(currentValue) => {
            console.log(currentValue);
            setState({ ...state, enableFormFields: currentValue });
          }}
        />
      ) : null} */}
      <ResourceTypeModal
        isOpen={modalOpen}
        data={selectedData}
        onClose={() => {
          setModalOpen(() => false);
          setSelectedData(() => ({}));
        }}
      />

      <Box sx={{ margin: "24px 32px 96px 32px" }}>
        <ResourcesTypeHeadMount
          onCreateNew={() => fetchAllResourceType(1)}
          data={[searchQuery, setSearchQuery]}
          onUpdateFilter={(newValue) => {
            fetchAllResourceType(1, newValue);
          }}
          onResetFilter={() => {}}
        />
        <Box sx={{ width: "100%" }}>
          <DataTable
            // cellBuilders={cellBuilders}
            multiselect={false}
            isDataLoading={false}
            headers={headers}
            data={state.data}
            onItemClick={(id) => {
              throw new Error("Function not implemented.");
            }}
            selectedItems={[]}
            currentPageNumber={state?.pagination["current"]}
            itemsPerPage={50}
            showPagination
            onPagination={(newPageNumber) =>
              fetchAllResourceType(newPageNumber)
            }
            totalPages={state?.pagination["total"] || 0}
            totalItems={3 || 0}
            handleRowClick={(rowData) => {
              setSelectedData(() => rowData);
              setModalOpen(() => true);
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default ResourcesAndPermissionsPageResourceTypesTab;
