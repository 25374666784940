import { commonApiCalls } from "../../../../../../../../services/commonApiCalls";
export const formFields = {
  billing_name: {
    key: "", 
    value: "", 
    map: "billing_name",
    type: "text",
    label: "Billing Name",
    placeholder: "Enter billing name",
  },
  billing_floor: {
    key: "", 
    value: "", 
    map: "billing_floor",
    type: "text",
    label: "Billing Floor",
    placeholder: "Enter billing floor",
  },
  billing_building_name: {
    key: "", 
    value: "", 
    map: "billing_building_name",
    type: "text",
    label: "Building Name",
    placeholder: "Enter building name",
  },
  billing_country: {
    key: "", 
    value: "", 
    map: "billing_country",
    type: "select",
    label: "Country",
    placeholder: "Select country",
    dependency: false,
    trigger: [],
    getData: commonApiCalls.getAllCountries
  },
  billing_state: {
    key: "", 
    value: "", 
    map: "billing_state",
    type: "select",
    label: "State",
    placeholder: "Select state",
    dependency: true,
    trigger: ["billing_country"],
    getData: commonApiCalls.getAllStatesByCountry
  },   
  billing_city: {
    key: "", 
    value: "", 
    map: "billing_city",
    type: "select",
    label: "City",
    placeholder: "Select city",
    dependency: true,
    trigger: ["billing_country","billing_state"],
    getData: commonApiCalls.getAllCitiesByStateAndCountry
  },
  billing_postal_code: {
    key: "", 
    value: "", 
    map: "billing_postal_code",
    type: "text",
    label: "Zip Code",
    placeholder: "Enter zipcode",
  },
};