import React, {useState, useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";

// Constants
import { companyProfileConstants } from "./constants";

// Legacy Imports
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import Box from "@mui/material/Box";


// Tab-Design Constants
const Tab = styled(TabUnstyled)`
  color: #767B85;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  padding: 16px 0px;
  margin-left: 32px;
  border: none;
  border-bottom : 4px solid transparent;

  &.${tabUnstyledClasses.selected} {
    border-bottom : 4px solid #5563F5;
    color: #5563F5;
    font-weight: 500;
  }
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: transparent;
  border-bottom: 1px solid #EDEDED;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  `,
);


const CompanyProfilePage = ()=> {
  // Routing Constants
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const [state, setState] = useState({selectedTab: "", });
  // effects
  useEffect(()=> {
    const pathList = location?.pathname.split("/");
    const mountRoute = pathList[pathList.length - 1];
    const reqTab = Object.values(companyProfileConstants.TABS).find((item)=> item.route === mountRoute).value;
    setState({...state, selectedTab: reqTab});
  }, []);

  useEffect(()=> {
    if(state?.selectedTab !== "") {
      const newRouteElement = Object.values(companyProfileConstants["TABS"]).find((item)=>item.value === state?.selectedTab);
      handleNavigate(newRouteElement?.route);
    }     
        
  }, [state?.selectedTab]);

  // handlers
  const handleTabChange = (event, newValue) => setState({...state, selectedTab: newValue});
  const handleNavigate = (newRoute)=> navigate(newRoute);
    

  // renderer
  return(
    <React.Fragment>
            
      <TabsUnstyled 
        value = {state?.selectedTab}
        onChange = {handleTabChange}
      >
        <TabsList>
          {Object.values(companyProfileConstants["TABS"]).map((tab)=> {
            return(
              <Tab 
                key = {tab?.key}
                className='typography-font-family-class-regular'
                value = {tab?.value}
              >
                {tab?.label}
              </Tab>
            );
          })}
        </TabsList>
      </TabsUnstyled>
      <Box sx={{width: "100%",m:0, p:0}}>
        <Outlet/>
      </Box>
    </React.Fragment>
  );
};

export default CompanyProfilePage;