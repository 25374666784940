import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import {
  Button,
  DataTable,
  DeleteConfirmationModal,
  Modal,
  PlusIcon,
  ReportIcon,
  SearchInput,
} from "components";
import {
  useBulkDeleteResources,
  useGetActiveResourceCount,
  useGetAllResources,
  useGetMspById,
  useGlobalStore,
  useSubscriptionDetails,
} from "controller";
import { useEffect, useState } from "react";
import { debugConsole, getFullName } from "utilz/helpers";
import { CreateResourceModal } from "./CreateResourceModal";
import { UpdateResourceModal } from "./update-resource/UpdateResourceModal";
import toast from "react-hot-toast";
import { RESOURCE_TYPES } from "shared";
import { dayjs } from "utilz";
import { useNavigate } from "react-router-dom";

export const ResourcesAndPermissionsPageResourcesTab = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isNoSeatModalOpen, setIsNoSeatModalOpen] = useState(false);
  const role = useGlobalStore((state) => state.role);
  const trialPeriodEndDate = useGlobalStore((state) => state.trialEndDate);
  const subscriptionStatus = useGlobalStore(
    (state) => state.subscriptionStatus
  );

  const { data: subscriptionDetails } = useSubscriptionDetails();
  const { data: activeResourceCount } = useGetActiveResourceCount();
  const perPageCount = 50;
  const [updateResourceModalState, setUpdateResourceModalState] = useState({
    open: false,
    resourceId: "",
  });
  const mspId = useGlobalStore((state) => state.mspId);
  const { data: mspDetails, isFetching: isMspLoading } = useGetMspById(
    mspId || ""
  );

  const resourceId = useGlobalStore((state) => state.resourceId);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedResources, setSelectedResources] = useState<Array<any>>([]);
  const { mutateAsync: deleteResources } = useBulkDeleteResources();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    data: resources,
    refetch,
    isFetching: isResourceLoading,
  } = useGetAllResources({
    filter: [],
    pageNumber: pageNumber,
    perPageCount: perPageCount,
    sortColumn: "email",
    sortDirection: "asc",
    searchQuery: searchQuery,
  });

  useEffect(() => {
    refetch();
  }, [pageNumber]);

  const cellBuilder = {
    resource_name: (rowData: any) => {
      const fullname = getFullName(
        rowData?.first_name,
        rowData?.middle_name,
        rowData?.last_name
      );
      return (
        <Typography component={"span"} sx={{ textTransform: "capitalize" }}>
          {fullname}
        </Typography>
      );
    },
    status: (rowData: any) => (
      <Typography component={"span"} sx={{ textTransform: "capitalize" }}>
        {rowData?.status === true ? "Inactive" : "Active"}
      </Typography>
    ),
  };

  const onDeleteHandler = async () => {
    const data = selectedResources;
    if (Array.isArray(data) && data.includes(resourceId)) {
      toast.error("Trying to delete your own account");
      setIsDeleteModalOpen(false);
      return;
    }

    const resourceIds = {
      resource_ids: data,
    };

    debugConsole(resourceIds);
    try {
      await deleteResources(resourceIds);
      toast.success("Resources deleted successfully");
      setSelectedResources([]);
    } catch (e: any) {
      toast.error(e.message || "Unable delete at the moment");
    }
    setIsDeleteModalOpen(false);
    return;
  };

  const handleItemClick = (_: any, rowData: any) => {
    // if (rowData.resource_type_id === RESOURCE_TYPES.SUPERADMIN) {
    //   toast.error("Cannot delete super admin");
    //   return;
    // }

    const oldSelectedData = selectedResources;
    const index = oldSelectedData.findIndex(
      (item: any) => item === rowData.mapping_id
    );

    if (index === -1) {
      setSelectedResources((oldData) => [...oldData, rowData.mapping_id]);
    } else {
      oldSelectedData.splice(index, 1);
      setSelectedResources([...oldSelectedData]);
    }
  };

  const onSelectAll = (currentState: "checked" | "unchecked") => {
    if (currentState === "checked") {
      const selectedArray = (
        Array.isArray(resources?.result) ? resources.result : []
      )
        .filter(
          (item: any) =>
            !(
              item.resource_type_id == RESOURCE_TYPES.SUPERADMIN &&
              item.email == mspDetails.business_email
            )
        )
        .map((item: any) => item.mapping_id);
      setSelectedResources(selectedArray);
    } else {
      setSelectedResources([]);
    }
  };

  const handleAddResourceClick = () => {
    if (subscriptionDetails) {
      // if trial period is over
      if (trialPeriodEndDate && dayjs().isAfter(trialPeriodEndDate)) {
        // check if subscription is active
        if (subscriptionStatus === "active") {
          // check if seat limit is reached
          if (
            Number(subscriptionDetails?.seat_quantity) <=
            Number(activeResourceCount)
          ) {
            setIsNoSeatModalOpen(true);
            return;
          }
          // if seat limit is not reached
          setIsCreateModalOpen(true);
          return;
        } else {
          // if subscription is not active
          setIsNoSeatModalOpen(true);
          return;
        }
      } else {
        // check if subscription is active
        if (subscriptionStatus === "active") {
          // check if seat limit is reached
          if (
            Number(subscriptionDetails?.seat_quantity) <=
            Number(activeResourceCount)
          ) {
            setIsNoSeatModalOpen(true);
            return;
          }
          // if seat limit is not reached
          setIsCreateModalOpen(true);
          return;
        }
        setIsCreateModalOpen(true);
        return;
      }
    }
    setIsCreateModalOpen(true);
  };

  return (
    <Stack
      sx={{
        px: "32px",
        pt: "12px",
        gap: "16px",
      }}
    >
      {isCreateModalOpen && (
        <CreateResourceModal
          open={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}
      {updateResourceModalState.open && (
        <UpdateResourceModal
          resourceId={updateResourceModalState.resourceId}
          onClose={() =>
            setUpdateResourceModalState({
              open: false,
              resourceId: "",
            })
          }
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          open={isDeleteModalOpen}
          onPrimaryButtonClick={onDeleteHandler}
          onSecondaryButtonClick={() => setIsDeleteModalOpen(false)}
          title="Delete Resources"
          tagline={"Are you sure you want to delete resources"}
        />
      )}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4">Resources</Typography>
        <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
          <SearchInput
            name={""}
            placeholder="Search"
            value={searchQuery}
            onChange={(event) => {
              setSearchQuery(event.target.value);
            }}
            sx={{
              pt: "3px",
            }}
            hideHelperBox
          />
          <Button endIcon={<PlusIcon />} onClick={handleAddResourceClick}>
            Resource
          </Button>
        </Stack>
      </Stack>
      <DataTable
        cellBuilders={cellBuilder}
        headers={headers}
        isCheckboxDisabled={(rowData) =>
          rowData.resource_type_id == RESOURCE_TYPES.SUPERADMIN &&
          rowData.email == mspDetails.business_email
        }
        handleRowClick={(rowData) =>
          setUpdateResourceModalState({
            open: true,
            resourceId: rowData.mapping_id,
          })
        }
        onAllSelect={onSelectAll}
        data={Array.isArray(resources?.result) ? resources.result : []}
        onItemClick={handleItemClick}
        selectedItems={selectedResources}
        currentPageNumber={pageNumber}
        itemsPerPage={perPageCount}
        totalPages={resources?.totalPages || 0}
        totalItems={resources?.totalCount || 0}
        showPagination
        isDataLoading={isMspLoading || isResourceLoading}
        onPagination={setPageNumber}
        actionContent={
          <Button
            variant="primary"
            onClick={() => setIsDeleteModalOpen(true)}
            sx={{
              bgcolor: theme.palette.error.main,
              "&:hover": { backgroundColor: "#A9050E" },
            }}
          >
            Delete
          </Button>
        }
      />

      {isNoSeatModalOpen && (
        <Modal
          maxWidth="xs"
          open={isNoSeatModalOpen}
          onClose={() => setIsNoSeatModalOpen(false)}
          footer={<></>}
        >
          <Stack
            sx={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                gap: "26px",
              }}
            >
              <Stack
                sx={{
                  width: "68px",
                  height: "68px",
                  bgcolor: theme.palette["warning-minor"].tint,
                  borderRadius: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReportIcon
                  sx={{ width: "48px", height: "48px" }}
                  htmlColor={theme.palette["warning-minor"].main}
                />
              </Stack>
              <Typography variant="h3">Uh, oh!</Typography>
            </Stack>
            <Typography
              mt={"12px"}
              variant="body-large"
              color={theme.palette.lightBg.medium}
            >
              You’ve hit your seat limit. Upgrade now.
            </Typography>

            <Button
              sx={{ marginY: "54px" }}
              onClick={() =>
                role === "SUPERADMIN"
                  ? navigate("/app/control-center/subscription")
                  : setIsNoSeatModalOpen(false)
              }
            >
              {role === "SUPERADMIN" ? " Manage Subscription" : " Close"}
            </Button>
          </Stack>
        </Modal>
      )}
    </Stack>
  );
};

const headers = [
  {
    name: "Resource Name",
    sortable: false,
    key: "resource_name",
  },
  {
    name: "Email ID",
    sortable: false,
    key: "email",
  },
  {
    name: "Location",
    sortable: false,
    key: "location_name",
  },
  {
    name: "Work Role",
    sortable: false,
    key: "work_role_name",
  },
  {
    name: "Resource Type",
    sortable: false,
    key: "resource_type_name",
  },
  {
    name: "Status",
    sortable: false,
    key: "status",
  },
  //   {
  //   name: "Manager",
  //   sortable: false,
  //   key: "manager",
  // }
];
