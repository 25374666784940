import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAllChatMessages,
  markChatAsResolved,
  sendChatMessage,
} from "controller/api";
import {
  GetAllChatInput,
  MarkChatAsResolvedInput,
  SendChatMessageInput,
} from "shared";

export const useGetAllChatMessages = ({
  serviceType,
  serviceId,
  fetcherId,
  limit,
  page,
  accessSpecifier,
  filterMarkAsResolved,
  enabled = true,
}: GetAllChatInput & { enabled?: boolean }) => {
  return useQuery({
    queryKey: [
      "chat",
      serviceId,
      limit,
      page,
      accessSpecifier,
      filterMarkAsResolved,
    ],
    queryFn: () =>
      getAllChatMessages({
        serviceType,
        serviceId,
        fetcherId,
        limit,
        page,
        accessSpecifier,
        filterMarkAsResolved,
      }),
    enabled: serviceId && enabled ? true : false,
    onError: (error) => console.log(error),
  });
};

export const useSendChatMessage = () => {
  const queryClient = useQueryClient();
  return useMutation((value: SendChatMessageInput) => sendChatMessage(value), {
    onSuccess(data, variables, context) {
      // queryClient.invalidateQueries(["chat"]);
    },
  });
};

export const useMarkChatAsResolved = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: MarkChatAsResolvedInput) => markChatAsResolved(value),
    {
      onSuccess(data, variables, context) {
        // queryClient.invalidateQueries(["chat"]);
      },
    }
  );
};
