import { Immer } from "immer";
import { immer } from "zustand/middleware/immer";
import { sliceResetFunctions } from "../resetStoreHelper";

type FilterProps = {
  column: string;
  operator: "=";
  value: string | Array<string>;
};

type Filter = {
  board?: FilterProps | null;
  customer?: FilterProps | null;
};
interface ProjectTableState {
  projectTable: {
    filter: Filter;
    boardIds?: Array<string>;
    customerIds?: Array<string>;
    pageNo: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
  };
}

interface ProjectTableAction {
  setProjectTableBoardIds: (boardArray: Array<string>) => void;
  setProjectTableCustomerIds: (customer: Array<string>) => void;
  setProjectTableSorting: (column: string) => void;
}

export interface ProjectTableInterface
  extends ProjectTableAction,
    ProjectTableState {}
const initialState: ProjectTableState = {
  projectTable: {
    pageNo: 1,
    sortColumn: "created_at",
    sortDirection: "desc",
    filter: {},
  },
};
export const projectTableSlice = immer<ProjectTableState & ProjectTableAction>(
  (set, get) => {
    sliceResetFunctions.add(() => set(initialState));
    return {
      ...initialState,
      setProjectTableBoardIds: (boardArray) =>
        set((state) => {
          state.projectTable.boardIds = boardArray;
          if (state.projectTable.filter) {
            if (boardArray.length > 0) {
              state.projectTable.filter.board = {
                column: "board",
                operator: "=",
                value: boardArray,
              };
            } else {
              delete state.projectTable.filter.board;
              // state.filter.board = null;
            }
          }
        }),

      setProjectTableCustomerIds: (customerArray) =>
        set((state) => {
          state.projectTable.customerIds = customerArray;
          if (state.projectTable.filter) {
            if (customerArray.length > 0) {
              state.projectTable.filter.customer = {
                column: "projects.customer_id",
                operator: "=",
                value: customerArray,
              };
            } else {
              delete state.projectTable.filter.customer;
            }
          }
        }),
      setProjectTableSorting: (column) =>
        set((state) => {
          if (column === state.projectTable.sortColumn) {
            state.projectTable.sortDirection =
              state.projectTable.sortDirection === "asc" ? "desc" : "asc";
          } else {
            state.projectTable.sortColumn = column;
            state.projectTable.sortDirection = "asc";
          }
        }),

      resetProjectTableSlice: () => set({ ...initialState }),
    };
  }
);
