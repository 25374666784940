import { styled } from "@mui/material/styles";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import React from "react";
import { BadgeBase, PriorityBadge, StatusBadge } from "../badges";
import { RatingImage } from "components/RatingImage";
import { QaHealthStatusBadge } from "components/badges/QaHealthStatusBadge";
import { Avatar, AvatarGroup } from "components/avatar";
import { dayjs } from "utilz";
import { useTheme, Stack, Typography } from "@mui/material";
import { StyledTableCell } from "./StyledTableCell";
import { claculateTimeElapsed, convertTimeObjectToString } from "utilz/helpers";

interface CustomTableCellProps extends TableCellProps {
  type?: "priority" | "impact" | "assignees" | "feedback" | string;
  value?: string | any;
  extraData?: any;
}
export const TableCellRoot = ({
  type,
  value = "",
  extraData,
  ...rest
}: CustomTableCellProps) => {
  const theme = useTheme();

  switch (type) {
    case "priority":
      return (
        <TableCell {...rest}>
          <PriorityBadge type={value}></PriorityBadge>
        </TableCell>
      );
    case "status_name":
      return (
        <TableCell {...rest}>
          <StatusBadge value={value}></StatusBadge>
        </TableCell>
      );
    case "qa_health_status": {
      return (
        <TableCell {...rest}>
          <QaHealthStatusBadge status={value.status} onClick={value.action} />
        </TableCell>
      );
    }
    case "uid":
      return (
        <TableCell {...rest}>
          <Typography variant="body-small" color={theme.palette.purple.main}>
            {value}
          </Typography>
        </TableCell>
      );

    case "customer":
      return (
        <TableCell {...rest}>
          <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
            <Avatar
              fullname={value}
              sx={{
                boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.08)",
              }}
            />
            <Typography variant="body-small">{value}</Typography>
          </Stack>
        </TableCell>
      );

    case "impact":
      return (
        <TableCell {...rest}>
          <BadgeBase backgroundMode="light">{value}</BadgeBase>
        </TableCell>
      );

    case "created_at":
      return <TableCell {...rest}>{claculateTimeElapsed(value)}</TableCell>;
    case "budgeted_hours":
      return (
        <TableCell {...rest}>{convertTimeObjectToString(value)}</TableCell>
      );
    case "actual_hours":
      return (
        <TableCell {...rest}>{convertTimeObjectToString(value)}</TableCell>
      );
    case "rating":
      return (
        <TableCell {...rest}>
          <RatingImage rating={parseInt(value)} />
        </TableCell>
      );
    case "assignees":
      return (
        <TableCell {...rest}>
          <AvatarGroup childWidthHeight="32px" max={2}>
            {/* @ts-ignore */}
            {value.map((item, index) => {
              return (
                <Avatar
                  key={index}
                  variants="md"
                  tooltip
                  fullname={
                    item.assignee_display_name
                      ? item.assignee_display_name
                      : `${item.assignee_first_name} ${
                          item.assignee_last_name ? item.assignee_last_name : ""
                        }`
                  }
                  url={item.assignee_profile_image}
                />
              );
            })}{" "}
          </AvatarGroup>
        </TableCell>
      );
    default:
      return (
        <StyledTableCell sx={{ maxWidth: "200px" }} {...rest}>
          <Typography
            variant="body-small"
            sx={{
              maxWidth: "200px",
              overflowX: "clip",
              textOverflow: "ellipsis",
              color: "inherit",
            }}
          >
            {value}
          </Typography>
        </StyledTableCell>
      );
      break;
  }
};
