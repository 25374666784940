import { fetchCall } from "../../utilz";
import { appConfig } from "../../constants";

// Fetch all Time Zones as List
const fetchTimeZones = ()=> 
  fetchCall(
    "/timezones/all",
    appConfig.requestMethods.GET
  );

// Fetch all Time Zones as List
const fetchAllCountries = ()=> 
  fetchCall(
    "/countries/all",
    appConfig.requestMethods.GET
  );

// Fetch all states by country code
const fetchAllStatesByCountryCode = (countryCode)=>
  fetchCall(
    `/states/country_code/${countryCode}`,
    appConfig.requestMethods.GET
  );

// Fetch all cities by states 
const fetchAllCitiesByStateCode = (countryCode, stateCode)=> 
  fetchCall(
    `/cities/country_code/${countryCode}/state_code/${stateCode}`,
    appConfig.requestMethods.GET
  ); 
    
    


export const generalServices = {
  fetchTimeZones,
  fetchAllCountries,
  fetchAllStatesByCountryCode,
  fetchAllCitiesByStateCode
};