import {
  Box,
  Stack,
  TextField,
  useTheme,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  Button,
  DownArrowIcon,
  DropdownMenu,
  GlobeCheckmarkIcon,
  InfoIcon,
  LockIcon,
  PlaneIcon,
  UpArrowIcon,
  UserIcon,
} from "components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AttachmentButton } from "./AttachmentButton";
import { useMainStore } from "controller/store/mainStore";
import {
  useGetAllChatMessages,
  useMarkChatAsResolved,
  useSendChatMessage,
} from "controller";
import { MqttContext } from "controller/context/MqttContext";
import { AccessSpecifier, Assignees, ChatResultItem } from "shared/types";
import {
  AttachmentMessageBlock,
  CustomerMessage,
  EmailAndNotesAttachmentBlock,
  ResourceMessage,
} from "./MessageBlocks";
import { ChatHeader } from "./ChatHeader";
// day js plugins
import dayjs from "dayjs";

import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";
import { FeedbackCard } from "./FeedbackCard";
import { useGlobalStore } from "controller/store/globalStore";
import NoChatImg from "assets/images/noChat.png";
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

const SendMenuList = [
  { name: "self", type: "self", icon: <UserIcon fontSize="small" /> },
  { name: "private", type: "private", icon: <LockIcon fontSize="small" /> },
  {
    name: "public",
    type: "public",
    icon: <GlobeCheckmarkIcon fontSize="small" />,
  },
];

export const Chat = ({
  serviceType,
  serviceId,
  serviceSource,
  isDisabled,
  metaData,
  section = "serviceDetails",
  onClose,
  assignees = [],
}: {
  isDisabled: boolean;
  serviceType: "ticket" | "task";
  serviceId: string;
  serviceSource?: string;
  metaData?: any;
  section?: "serviceDetails" | "messageBar";
  onClose?: () => void;
  assignees: Assignees[];
}) => {
  // refs
  const buttonRef = useRef();
  const theme = useTheme();
  // store
  //const assignees = useMainStore((state) => state.assignees);
  // const ticketId = useMainStore((state) => state.ticketId);
  const resourceId = useGlobalStore((state) => state.resourceId);
  const profilePic = useGlobalStore((state) => state.profilePictureUrl);
  const displayName = useGlobalStore((state) => state.name);
  // const ticketSource = useMainStore((state) => state.ticketSource);
  //
  const [isResourceAnAssignee, setisResourceAnAssignee] = useState(false);

  // Access Specifier for filtering messages
  const [filterAccessSpecifier, setFilterAccessSpecifier] = useState<
    AccessSpecifier | undefined | null
  >();
  const [filterMarkAsResolved, setFilterMarkAsResolved] =
    useState<boolean>(false);

  const [sendMessageAccessSpecifier, setSendMessageAccessSpecifier] =
    useState<AccessSpecifier>("private");
  const feedback = useMainStore((state) => state.feedback);
  const rating = useMainStore((state) => state.rating);
  const mspId = useGlobalStore((state) => state.mspId);
  const { mqttMessage } = useContext(MqttContext);

  // text field value
  const [value, setValue] = useState("");
  const [lastUpdated, setLastUpdated] = useState<string | undefined>();
  const [showNoChatScreen, setShowNoChatScreen] = useState(false);
  const {
    data: prevChatMessages,
    isLoading: isMessagesLoading,
    isFetching: isMessageFetching,
  } = useGetAllChatMessages({
    serviceType,
    serviceId: serviceId,
    fetcherId: resourceId || "",
    ...(filterAccessSpecifier && { accessSpecifier: filterAccessSpecifier }),
    ...(filterMarkAsResolved && { filterMarkAsResolved: filterMarkAsResolved }),
  });

  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const { mutateAsync: sendMessage } = useSendChatMessage();
  const { mutateAsync: markAsResolved } = useMarkChatAsResolved();
  // state of messages
  // const [messages, setMessages] = useState<ChatResultItem[]>([]);
  const [groupedMessages, setGroupedMessages] = useState<Record<
    string,
    ChatResultItem[]
  > | null>();

  useEffect(() => {
    checkIfAssignee();
  }, [assignees]);
  // check if resource is an assignee
  const checkIfAssignee = () => {
    let isAssignee = false;
    assignees.map((assignee) => {
      if (assignee.mapping_id === resourceId) {
        isAssignee = true;
      }
    });

    setisResourceAnAssignee(isAssignee);
  };

  // add prev messages to state
  useEffect(() => {
    if (prevChatMessages && !isMessagesLoading && !isMessageFetching) {
      setGroupedMessages(undefined);
      transformMessages({
        inputMessage: prevChatMessages?.messages,
        type: "initial",
      });
    } else if (!isMessagesLoading && !isMessageFetching) {
      !prevChatMessages && setShowNoChatScreen(true);
    }

    return () => {
      setShowNoChatScreen(false);
      setGroupedMessages(null);
    };
  }, [prevChatMessages, isMessagesLoading, isMessageFetching]);

  //  mqttmessage
  useEffect(() => {
    if (mqttMessage == null) {
    } else {
      const newMessage = JSON.parse(mqttMessage?.message);
      if (mqttMessage.topic === `${serviceType}s/${serviceId}`) {
        transformMessages({
          inputMessage: [newMessage?.savedChat],
          type: "new",
        });
      }
      // private
      if (mqttMessage.topic === `${serviceType}s/${serviceId}/private`) {
        transformMessages({
          inputMessage: [newMessage?.savedChat],
          type: "new",
        });
      }

      // self notes nad time
      if (
        mqttMessage.topic ===
        `${serviceType}s/${mspId}/${serviceId}/resource/${resourceId}`
      ) {
        transformMessages({
          inputMessage: [newMessage?.savedChat],
          type: "new",
        });
      }
    }
  }, [mqttMessage]);

  // group messages by date and add to state
  const transformMessages = ({
    inputMessage,
    type,
  }: {
    inputMessage: ChatResultItem[];
    type: "initial" | "new";
  }) => {
    const lastItem = inputMessage[inputMessage.length - 1];
    lastItem && setLastUpdated(lastItem?.created_at);
    if (inputMessage && type === "initial") {
      const groupedMessages = inputMessage.reduce(
        (groups: Record<string, Array<ChatResultItem>>, message) => {
          // @ts-ignore
          const date: string = message.created_at;
          let key = date;

          if (dayjs(date).isToday()) {
            key = "Today";
          } else if (dayjs(date).isYesterday()) {
            key = "Yesterday";
          } else {
            key = dayjs(date).format("DD MMM YYYY");
          }
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].unshift(message);
          return groups;
        },
        {}
      );
      setGroupedMessages(groupedMessages);
    } else {
      setGroupedMessages((prev) => {
        const newData = Object.assign({}, prev);
        const hasToday = newData.hasOwnProperty("Today");
        const today = [...(hasToday ? newData["Today"] : []), ...inputMessage];
        delete newData["Today"];
        return {
          Today: today,
          ...newData,
        };
      });
    }
    // scrollToBottom();
  };

  // Send button menu handler start
  const [sendMenuButtonanchorEl, setSendButtonAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openSendMenu = Boolean(sendMenuButtonanchorEl);

  const handleSendButtonMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSendButtonAnchorEl(event.currentTarget);
  };
  const handleSendButtonMenuClose = () => {
    setSendButtonAnchorEl(null);
  };
  const handleSendMessage = async ({
    type,
  }: {
    type: "self" | "private" | "public";
  }) => {
    if (isSendingMessage) return;
    setIsSendingMessage(true);

    const trimmedValue = value.trim();
    if (trimmedValue.length > 0 && !isDisabled) {
      const transformedAssignees = await transformAssignees();
      setSendButtonAnchorEl(null);
      const messageData = {
        assignedResources: transformedAssignees,
        message: trimmedValue,
        accessSpecifier: type,
        serviceId,
        serviceType: serviceType,
        originType: serviceSource,
        messageType: "normal",
        sender: {
          mapping_id: resourceId || "",
          display_name: displayName || "",
          profile_pic: profilePic || "",
          user_type: "resource",
        },
      };
      const data = await sendMessage(messageData);

      // if (
      //   data?.access_specifier === "self" &&
      //   (filterAccessSpecifier === "self" || !filterAccessSpecifier)
      // ) {
      //   transformMessages({ inputMessage: [data], type: "new" });
      // }
      setValue("");
    }
    setIsSendingMessage(false);
  };

  // fn to get assignees in required format
  const transformAssignees = async () => {
    return assignees.map((assignee) => {
      return {
        mapping_id: assignee.mapping_id,
        display_name: assignee.display_name
          ? assignee.display_name
          : `${assignee.first_name} ${assignee.last_name}`,
        profile_pic: assignee.profile_image || "",
      };
    });
  };

  const handleMarkAsResolved = async (
    chatId: string,
    newValue: boolean,
    key: string
  ) => {
    const chatData = await markAsResolved({
      chatId,
      newValue,
    });
    setGroupedMessages((prevMessages) => {
      const oldGroupedData = { ...prevMessages };
      const index = oldGroupedData[key].findIndex(
        (message) => message.chat_id === chatData.chat_id
      );
      if (index !== -1) {
        const oldMessages = oldGroupedData[key];
        oldMessages[index] = {
          ...oldMessages[index],
          mark_as_resolved: chatData.mark_as_resolved,
        };
        oldGroupedData[key] = [...oldMessages];
        return oldGroupedData;
      }
    });
  };

  const handleFilterChange = (type?: AccessSpecifier) => {
    // @ts-ignore
    if (type === "all") {
      setFilterMarkAsResolved(false);
      setFilterAccessSpecifier(null);

      // @ts-ignore
    } else if (type === "resolution") {
      setFilterAccessSpecifier(null);
      setFilterMarkAsResolved(true);
    } else {
      setFilterAccessSpecifier(type);
      setFilterMarkAsResolved(false);
    }
  };

  const CustomerResourceMessage = ({
    message,
    date,
    type,
  }: {
    message: ChatResultItem;
    date: string;
    type: "resource" | "customer";
  }) => {
    if (type === "resource") {
      return (
        <ResourceMessage
          type={message.access_specifier}
          isMarkedAsResolved={message.mark_as_resolved}
          onMarkAsResolvedClick={() =>
            handleMarkAsResolved(
              message.chat_id,
              !message.mark_as_resolved,
              date
            )
          }
          message={message.message}
          key={message._id}
          date={message.created_at}
          attachments={message.attachments}
          senderDisplayName={message.sender.display_name}
          imageUrl={message.sender.profile_pic}
          accessSpecifier={message.access_specifier}
          isEdited={message?.editing?.is_edited}
          messagePlatform={message.message_platform}
        />
      );
    } else {
      return (
        <CustomerMessage
          type={message.access_specifier}
          isMarkedAsResolved={message.mark_as_resolved}
          onMarkAsResolvedClick={() =>
            handleMarkAsResolved(
              message.chat_id,
              !message.mark_as_resolved,
              date
            )
          }
          senderDisplayName={message.sender.display_name}
          imageUrl={message.sender.profile_pic}
          message={message.message}
          key={message._id}
          attachments={message.attachments}
          accessSpecifier={message.access_specifier}
          isEdited={message?.editing?.is_edited}
          date={message.created_at}
          messagePlatform={message.message_platform}
        />
      );
    }
  };

  return (
    <>
      <Stack
        width={"100%"}
        height={"100%"}
        direction="column"
        sx={{
          backgroundColor: theme.palette.neutral["005"],
          // maxWidth: "300px",
        }}
        justifyContent="space-between"
      >
        <ChatHeader
          onFilterSelect={(type) => handleFilterChange(type)}
          lastUpdated={lastUpdated}
          serviceType={serviceType}
          serviceId={serviceId}
          section={section}
          assignees={assignees}
          onClose={() => onClose?.()}
        />
        {/* Messages */}
        <Stack
          direction="column-reverse"
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            padding: "48px",
            borderRight: "8px solid #FFF",
            borderLeft: "8px solid #FFF",
          }}
        >
          {rating && <FeedbackCard feedback={feedback} rating={rating} />}

          {groupedMessages ? (
            Object.entries(groupedMessages).map(([date, msgs], index) => (
              <Stack key={`${index}_${date}_${msgs.length}`}>
                <Box sx={{ paddingX: "15%" }}>
                  <Divider
                    sx={{
                      ...theme.typography["body-small"],
                      color: theme.palette.lightBg.low,
                    }}
                  >
                    {date !== "Today" && date !== "Yesterday"
                      ? dayjs(date).format("DD MMM YYYY")
                      : date}
                  </Divider>
                </Box>

                {msgs.map((message, index) => {
                  const newIndex = message._id;
                  if (message.message_platform === "email") {
                    if (!message.message?.startsWith("<")) {
                      if (message.sender.mapping_id === resourceId) {
                        return (
                          <CustomerResourceMessage
                            key={newIndex}
                            message={message}
                            date={date}
                            type="resource"
                          />
                        );
                      } else {
                        return (
                          <CustomerResourceMessage
                            key={newIndex}
                            message={message}
                            date={date}
                            type="resource"
                          />
                        );
                      }
                    }
                    return (
                      <EmailAndNotesAttachmentBlock
                        key={newIndex}
                        // @ts-ignore
                        type={message.sender.user_type}
                        source={message.message_type}
                        serviceId={serviceId}
                        serviceType={serviceType}
                        message={message.message}
                        mappingId={message.chat_id}
                        senderDisplayName={message.sender.display_name}
                        imageUrl={message.sender.profile_pic}
                        accessSpecifier={message.access_specifier}
                        isEdited={message?.editing?.is_edited}
                        date={message.created_at}
                        messagePlatform={message.message_platform}
                        isMarkedAsResolved={message.mark_as_resolved}
                        onMarkAsResolvedClick={() =>
                          handleMarkAsResolved(
                            message.chat_id,
                            !message.mark_as_resolved,
                            date
                          )
                        }
                      />
                    );
                  } else if (
                    message.message_type === "time_entry_notes" ||
                    message.message_type === "canned_notes"
                  ) {
                    return (
                      <EmailAndNotesAttachmentBlock
                        key={newIndex}
                        // @ts-ignore
                        type={message.sender.user_type}
                        source={message.message_type}
                        message={message.message}
                        metadata={{ ...metaData, ...message.meta_data }}
                        mappingId={message.chat_id}
                        senderDisplayName={message.sender.display_name}
                        imageUrl={message.sender.profile_pic}
                        accessSpecifier={message.access_specifier}
                        isEdited={message?.editing?.is_edited}
                        date={message.created_at}
                        messagePlatform={message.message_platform}
                        sentAsEmail={message.send_as_email}
                        isMarkedAsResolved={message.mark_as_resolved}
                        onMarkAsResolvedClick={() =>
                          handleMarkAsResolved(
                            message.chat_id,
                            !message.mark_as_resolved,
                            date
                          )
                        }
                      />
                    );
                  } else if (message.sender.user_type === "resource") {
                    if (serviceType === "task") {
                      if (message.sender.mapping_id === resourceId) {
                        return (
                          <CustomerResourceMessage
                            key={newIndex}
                            message={message}
                            date={date}
                            type="resource"
                          />
                        );
                      } else if (message.sender.mapping_id !== resourceId) {
                        return (
                          <CustomerResourceMessage
                            key={newIndex}
                            message={message}
                            date={date}
                            type="customer"
                          />
                        );
                      }
                    } else {
                      return (
                        <CustomerResourceMessage
                          key={newIndex}
                          message={message}
                          date={date}
                          type="resource"
                        />
                      );
                    }
                  } else {
                    return (
                      <CustomerResourceMessage
                        key={newIndex}
                        message={message}
                        date={date}
                        type="customer"
                      />
                    );
                  }
                })}
              </Stack>
            ))
          ) : (
            <>
              {!isMessagesLoading && showNoChatScreen && (
                <Stack
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <img
                    src={NoChatImg}
                    alt=""
                    style={{
                      width: "50%",
                      height: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    color={theme.palette.lightBg.low}
                  >
                    You’re about to start a new conversation
                  </Typography>
                </Stack>
              )}
            </>
          )}
          {/* feedback card */}

          {/* @ts-ignore */}
          <Box height={"20px"} />
        </Stack>
        {/* Input */}
        <Stack
          sx={{
            backgroundColor: theme.palette.neutral["005"],
            px: "40px",
            border: "8px solid #FFF",
            borderTop: 0,
            paddingBottom: "8px",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              borderRadius: "8px",
              border: `1px solid ${theme.palette.neutral["005"]}`,
              overflow: "hidden",
              boxShadow:
                "0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
            }}
          >
            {isDisabled && (
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  gap: "8px",
                  alignItems: "center",
                  px: "8px",
                  py: "4px",
                  bgcolor: theme.palette.secondary["purple-tint-3"],
                }}
              >
                <InfoIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                />
                <Typography variant="body-small">
                  The {serviceType} is now marked as closed.
                </Typography>
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                backgroundColor: "#FFF",
                minHeight: "56px",
                width: "100%",
                position: "relative",
                alignItems: "flex-end",
                padding: "12px 16px",
              }}
            >
              {serviceType === "ticket" && (
                <DropdownMenu
                  menuProps={{
                    transformOrigin: {
                      horizontal: "center",
                      vertical: "bottom",
                    },
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                  }}
                  paperSx={{
                    minWidth: "128px",
                  }}
                  menuListItemSx={{
                    width: "128px",
                  }}
                  options={SendMenuList}
                  onOptionClick={({ type }) => {
                    type &&
                      setSendMessageAccessSpecifier(type as AccessSpecifier);
                  }}
                >
                  <Stack
                    direction={"row"}
                    sx={{
                      height: "100%",
                      color: theme.palette.primary.main,
                      pb: "4px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minWidth: "60px",
                    }}
                  >
                    <Typography
                      variant="caption-2"
                      sx={{
                        color: theme.palette.primary.main,
                        textTransform: "capitalize",
                      }}
                    >
                      {sendMessageAccessSpecifier}
                    </Typography>
                    <UpArrowIcon
                      sx={{
                        "&:hover": { cursor: "pointer" },
                      }}
                    />
                  </Stack>
                </DropdownMenu>
              )}
              <TextField
                fullWidth
                multiline
                maxRows={7}
                disabled={isDisabled}
                type="text"
                autoComplete="off"
                autoCorrect="off"
                placeholder={`Messaging in ${sendMessageAccessSpecifier} mode`}
                value={value}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage({ type: sendMessageAccessSpecifier });
                  }
                }}
                onChange={(e) => setValue(e.target.value)}
                sx={{
                  alignSelf: "center",
                  ...theme.typography["body-medium"],
                  "& .MuiInputBase-root": {
                    height: "100%",
                    width: "100%",
                    flexGrow: 1,
                    border: 0,
                    p: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    color: "#1C1E26",
                    "& > fieldset": {
                      border: "0px",
                      fontWeight: 400,

                      ...theme.typography["body-small"],
                    },
                  },
                }}
              />
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <AttachmentButton
                  serviceId={serviceId}
                  serviceType={serviceType}
                  isDisabled={isDisabled}
                  serviceSource={serviceSource || ""}
                />
                <PlaneIcon
                  onClick={() =>
                    !(isDisabled || isSendingMessage) &&
                    handleSendMessage({
                      type: sendMessageAccessSpecifier,
                    })
                  }
                  sx={{ cursor: "pointer", ml: "16px" }}
                  htmlColor={
                    isDisabled || isSendingMessage
                      ? theme.palette.neutral["020"]
                      : "inherit"
                  }
                />
              </Stack>
              {/* <AttachmentButton
              serviceId={serviceId}
              serviceType={serviceType}
              isDisabled={isDisabled}
              serviceSource={serviceSource || ""}
              onSendSelfMessage={(data) => {
                transformMessages({ inputMessage: [data], type: "new" });
              }}
            /> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
