import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomTypography } from "../../../../../../library/base";
import Box from "@mui/material/Box";
import ConfigurationSubCategories from "./components/configuration-sub-categories";
import { Stack, Typography, useTheme } from "@mui/material";
import { generalConfig } from "./constants";
import {
  AttributesIcon,
  BillingTemplateTileIcon,
  BoardsIcon,
  BulletPointsIcon,
  ClockIcon,
  ExpenseTileIcon,
  GeneralSettingsIcon,
  LinkHorizontalIcon,
  ProductTileIcon,
  QaIcon,
  SlaTileIcon,
  TaxTileIcon,
  TemplateIcon,
  TriangleSelectIcon,
} from "components";

const ServiceDeskList = [
  {
    name: "General",
    description:
      "Customize your Service Desk settings and choose your user preferences in this section.",
    icon: <GeneralSettingsIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-general",
  },
  {
    name: "Custom Field",
    description:
      "Update your company's primary details and general settings in this section.",
    icon: <AttributesIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-attributes",
  },
  {
    name: "Boards",
    description:
      "Manage the list of configurable boards used to resolve the corresponding tickets.",
    icon: <BoardsIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-boards",
  },
  {
    name: "Checklist",
    description:
      "Manage the pre-defined checklists used to standardize your ticket resolution process.",
    icon: <BulletPointsIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-checklist",
  },
  {
    name: "Templates",
    description:
      "Create and maintain your list of pre-made ticket templates & canned notes to accelerate the ticket resolution time.",
    icon: <TemplateIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-templates",
  },
  {
    name: "Quality Assurance",
    description:
      "Configure Quality Assurance to align with your SLAs for each customer to improve the CSAT of your resources.",
    icon: <QaIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-qa",
  },
  {
    name: "Timesheets",
    description:
      "Efficiently track hours logged by every Tech across tickets and tasks.",
    icon: <ClockIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-timesheet",
    isNew: false,
  },
];

const CustomerList = [
  {
    name: "SLA",
    description:
      "Create and maintain your database of SLAs for your users with customizable settings.",
    icon: <GeneralSettingsIcon className="setting-icon" />,
    url: "/app/control-center/configurations/customers-sla",
  },
];

const BillingList = [
  // SLA
  {
    name: "SLA",
    description:
      "Create and maintain your database of SLAs for your users with customizable settings.",
    icon: <SlaTileIcon className="setting-icon" />,
    url: "/app/control-center/configurations/customers-sla",
  },
  {
    name: "Tax",
    description:
      "Centralize tax settings here. Easily add and manage taxes across your Tickets, Projects, Activities",
    icon: <TaxTileIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-billing-tax",
  },
  {
    name: "Billing Template",
    description:
      "Efficient billing template for tax, discounts, invoice type and personalized mail. Customize easily.",
    icon: <BillingTemplateTileIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-billing-email",
  },
  {
    name: "Product",
    description:
      "Effortlessly add and manage products across your Tickets & Projects",
    icon: <ProductTileIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-billing-product",
  },
  {
    name: "Expense",
    description:
      "Simpllify the addition and management of expenses across your Tickets and Projects.",
    icon: <ExpenseTileIcon className="setting-icon" />,
    url: "/app/control-center/configurations/service-desk-billing-expense",
  },
];

export const ConfigurationsHomeComponent = () => {
  // Globals
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = (route: string) =>
    navigate(`/app/control-center/configurations${route}`);

  return (
    <>
      <Box
        sx={{
          px: "32px",
          py: "24px",
          border: `1px solid ${theme.palette.neutral["015"]}`,
        }}
      >
        <Typography variant="h4">Configurations</Typography>
      </Box>
      <Stack
        gap={"40px"}
        sx={{
          padding: "40px 32px",
        }}
      >
        <Box>
          <Typography variant="h5">Service Desk</Typography>
          <Stack mt={"24px"} direction={"row"} gap="24px" flexWrap="wrap">
            {ServiceDeskList.map((item, index) => (
              <ConfigurationCard key={index} {...item} />
            ))}
          </Stack>
        </Box>

        {/* <Box>
          <Typography variant="h5">Customers</Typography>
          <Stack mt={"24px"} direction={"row"} gap="24px" flexWrap="wrap">
            {CustomerList.map((item, index) => (
              <ConfigurationCard key={index} {...item} />
            ))}
          </Stack>
        </Box> */}

        <Box>
          <Typography variant="h5">Billing & Contracts</Typography>
          <Stack mt={"24px"} direction={"row"} gap="24px" flexWrap="wrap">
            {BillingList.map((item, index) => (
              <ConfigurationCard key={index} {...item} />
            ))}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

const ConfigurationCard = (item: {
  name: string;
  description: string;
  icon: JSX.Element;
  url: string;
  isNew?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Stack
      onClick={() => {
        navigate(item.url);
      }}
      p="24px"
      sx={{
        aspectRatio: "1/1",
        width: "234px",
        border: `1px solid ${theme.palette.neutral["015"]}`,
        borderRadius: "12px",
        cursor: "pointer",
        transition: "all 0.5s ease",
        backgroundColor: "#FFF",
        justifyContent: "space-between",
        ":hover": {
          border: `1px solid ${theme.palette.purple.main}`,
          backgroundColor: "#EBFCFE",
          "& .setting-icon": {
            color: "#FFF",
          },
          "& .setting-box": {
            backgroundColor: theme.palette.purple.main,
          },
        },
      }}
    >
      <Box>
        <Stack direction={"row"} gap="12px" alignItems="center">
          <Typography variant="h6">{item.name}</Typography>
          {item.isNew && (
            <Stack
              sx={{
                bgcolor: "#BAF4FC",
                padding: "2px 6px 3px 6px",
                borderRadius: "12px",
              }}
            >
              <Typography
                variant="caption-3"
                color={theme.palette.lightBg.high}
                sx={{
                  fontWeight: "600",
                }}
              >
                New
              </Typography>
            </Stack>
          )}
        </Stack>
        <Typography
          mt={"8px"}
          variant="body-small"
          color={theme.palette.lightBg.low}
        >
          {item.description}
        </Typography>
      </Box>

      <Stack
        className="setting-box"
        sx={{
          mt: "32px",
          alignSelf: "flex-end",
          width: "48px",
          height: "48px",
          borderRadius: "12px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#E7E9FE",
          transition: "all 0.5s ease",
          "& .setting-icon": {
            color: theme.palette.purple.main,
            width: "32px",
            height: "32px",
          },
        }}
      >
        {item.icon}
      </Stack>
    </Stack>
  );
};
