const emptyStringHandler = (data?: string) => {
  return data && data !== null ? data : "";
};

const getFullName = (
  firstName?: string,
  middleName?: string,
  lastName?: string
) => {
  const firstNameNew = emptyStringHandler(firstName);
  const middleNameNew = emptyStringHandler(middleName);
  const lastNameNew = emptyStringHandler(lastName);
  const fullName = `${firstNameNew} ${middleNameNew} ${lastNameNew}`;
  return fullName.replaceAll("  ", " ");
};

export const stringToNumber = (data?: string) => {
  try {
    return data && data !== null ? parseInt(data) : 0;
  } catch (e) {
    return 0;
  }
};

export const extractStringFromHtml = (value: string) => {
  const span = document.createElement("span");
  span.innerHTML = value;
  return span.textContent || span.innerText;
};

export const capitalizeString = (inputString: string) => {
  return inputString[0].toUpperCase() + inputString.slice(1);
};

export { emptyStringHandler, getFullName };
