import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";
import React from "react";
import { Button } from "../button";
import { CloseIcon } from "components/icons";

export interface CustomDrawerProps
  extends Omit<SwipeableDrawerProps, "onOpen"> {
  anchor?: "left" | "right";
  drawerStyle?: SxProps<Theme>;
  children: React.ReactNode;
  title?: string;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
  isSecondaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  isPrimaryButtonDisabled?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  headerStyles?: SxProps<Theme>;
  footerStyles?: SxProps<Theme>;
  additionalHeaderComponent?: React.ReactNode;
  headerStyle?: SxProps<Theme>;
  headerTitleStyleSx?: SxProps<Theme>;
  showHeaderCloseBtn?: boolean;
  headerRightActionComponent?: React.ReactNode;
  footerLeftActionComponent?: React.ReactNode;
  footerRightActionComponent?: React.ReactNode;
  additionalFooterComponent?: React.ReactNode;
  onOpen?: () => void;
  isLoading?: boolean;
}
export const Drawer = ({
  anchor = "right",
  open,
  onClose,
  children,
  title,
  drawerStyle,
  secondaryButtonText = "Clear All",
  onSecondaryButtonClick,
  isSecondaryButtonDisabled = false,
  primaryButtonText = "Apply",
  onPrimaryButtonClick,
  isPrimaryButtonDisabled = false,
  onOpen,
  footer,
  header,
  headerStyles,
  footerStyles,
  additionalHeaderComponent,
  headerStyle,
  headerTitleStyleSx,
  showHeaderCloseBtn = true,
  headerRightActionComponent,
  footerLeftActionComponent,
  footerRightActionComponent,
  additionalFooterComponent,
  isLoading = false,
  ...rest
}: CustomDrawerProps) => {
  const theme = useTheme();

  return (
    <SwipeableDrawer
      transitionDuration={100}
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={() => {
        onOpen && onOpen();
      }}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minWidth: "296px",
          ...drawerStyle,
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            borderBottom: `1px solid ${theme.palette.neutral["B-015"]}`,
            ...headerStyle,
          }}
        >
          {header ? (
            header
          ) : (
            <>
              <Box
                px="24px"
                paddingY="12px"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`1px solid ${theme.palette.neutral["010"]}`}
                sx={{ ...headerStyles }}
              >
                <Typography
                  component={"span"}
                  variant={"h5"}
                  sx={{ textTransform: "capitalize", ...headerTitleStyleSx }}
                >
                  {title}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap="12px">
                  {headerRightActionComponent && headerRightActionComponent}
                  {showHeaderCloseBtn && (
                    <IconButton
                      onClick={onClose}
                      sx={{
                        "&: hover": {
                          cursor: "pointer",
                          backgroundColor: theme.palette.error.main,
                          transition: "all 0.5s",
                          borderRadius: "100px",
                          color: theme.palette.neutral.main,
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Stack>
              </Box>
              {additionalHeaderComponent && additionalHeaderComponent}
            </>
          )}
        </Box>
        <Box sx={{ overflowY: "scroll", flexGrow: 1 }}>{children}</Box>
        <Box flexShrink={0} display="flex" justifyContent="flex-end">
          {footer ? (
            footer
          ) : (
            <Stack width={"100%"}>
              {additionalFooterComponent && additionalFooterComponent}
              <Stack
                direction="row"
                bgcolor={theme.palette.neutral.main}
                width={"100%"}
                borderTop={
                  footer ? "0px" : `1px solid ${theme.palette.neutral["020"]}`
                }
                p={"12px 16px"}
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  ...footerStyles,
                }}
              >
                <Box>
                  {footerLeftActionComponent && footerLeftActionComponent}
                </Box>

                <Stack
                  gap="24px"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {footerRightActionComponent && footerRightActionComponent}
                  <Button
                    variant="secondary"
                    onClick={onSecondaryButtonClick}
                    disabled={isLoading || isSecondaryButtonDisabled}
                  >
                    {secondaryButtonText}
                  </Button>
                  <Button
                    loading={isLoading}
                    onClick={onPrimaryButtonClick}
                    disabled={isPrimaryButtonDisabled}
                  >
                    {primaryButtonText}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
