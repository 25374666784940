import { useQuery } from "@tanstack/react-query";
import { generateIntercomHash } from "controller/api";
import { debugConsole } from "utilz/helpers";

export const useGetIntercomHash = () => {
  return useQuery({
    queryKey: ["intercom_hash"],
    queryFn: () => generateIntercomHash(),
    onError: (err) => debugConsole(err),
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data) => {
      debugConsole("intercom hash", data);
      // @ts-ignore
      // window.Intercom("boot", {
      //   api_base: "https://api-iam.intercom.io",
      //   app_id: "vjbuario",
      //   email: "",
      //   user_hash: data?.hash, // HMAC using SHA-256  };
      // });
    },
  });
};
