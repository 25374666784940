import {
  CreateActivityInputType,
  createTimeEntriesInput,
  DeleteTimeEntryInput,
  endTimerInput,
  GetTimeEntryByIdInput,
  ServiceType,
  startTimerInput,
  ticketDetailsFromTimeEntriesInput,
  UpdateActivityByManagerInputType,
  UpdateActivityInputType,
  UpdatetimeEntriesInput,
} from "shared";
import { axiosClient } from "utilz";
import { apiErrorHandler } from "utilz/helpers";

// to create new time entry
export const createTimeEntries = async ({
  resourceId,
  serviceId,
  serviceType,
  startTime,
  endTime,
  isBillable,
  notes,
  deductTime,
  accessSpecifier,
  markAsResolution,
  notesAttachments,
  sendAsEmail,
  work_type,
  work_role,
  is_operating_hours,
}: createTimeEntriesInput) => {
  try {
    const data = {
      resource_id: resourceId,
      start_time: startTime,
      end_time: endTime,
      deduct_time: deductTime,
      is_billable: isBillable,
      notes: notes,
      access_specifier: accessSpecifier,
      mark_as_resolution: markAsResolution,
      notes_attachments: notesAttachments,
      send_email: sendAsEmail,
      work_type: work_type,
      work_role: work_role,
      is_operating_hours: is_operating_hours,
    };

    const response = await axiosClient.post(
      `/servicedesk/${serviceType}/${serviceId}/create/time_entry/`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateTimeEntries = async ({
  timeEntryId,
  serviceType,
  resourceId,
  startTime,
  endTime,
  isBillable,
  notes,
  deductTime,
  accessSpecifier,
  markAsResolution,
  notesAttachments,
  sendAsEmail,
  work_type,
  work_role,
  is_operating_hours,
  ...rest
}: UpdatetimeEntriesInput) => {
  try {
    const data = {
      resource_id: resourceId,
      start_time: startTime,
      end_time: endTime,
      is_billable: isBillable,
      notes: notes,
      deduct_time: deductTime,
      access_specifier: accessSpecifier,
      mark_as_resolution: markAsResolution,
      notes_attachments: notesAttachments,
      send_email: sendAsEmail,
      work_type: work_type,
      work_role: work_role,
      is_operating_hours: is_operating_hours,
    };

    const response = await axiosClient.patch(
      `/servicedesk/update/${serviceType}/time_entry/${timeEntryId}`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const startTime = async ({
  serviceId,
  resourceId,
  serviceType,
}: startTimerInput) => {
  try {
    const data = {
      resource_id: resourceId,
    };
    const response = await axiosClient.post(
      `/servicedesk/${serviceType}/${serviceId}/time_entry/start`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const endTime = async ({ serviceType, timeEntryId }: endTimerInput) => {
  try {
    const response = await axiosClient.post(
      `/servicedesk/${serviceType}/time_entry/${timeEntryId}/stop`
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateTicketDetailsfromTimeEntries = async ({
  ticketId,
  workType,
  isBillable,
  status,
}: ticketDetailsFromTimeEntriesInput) => {
  try {
    const data = {
      ...(workType ? { work_type: workType } : {}),
      ...(isBillable ? { is_billable: isBillable } : {}),
      ...(status ? { ticket_status: status } : {}),
    };

    const url = `/servicedesk/update/ticket_details/${ticketId}`;

    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
///

export const deleteTimEntryById = async ({
  ticketId,
  serviceType,
  timeEntryId,
}: DeleteTimeEntryInput) => {
  try {
    const url = `servicedesk/${serviceType}/delete/time_entry/${timeEntryId}/`;
    const response = await axiosClient.delete(`${url}`);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getTimEntryById = async ({
  serviceType,
  timeEntryId,
}: GetTimeEntryByIdInput) => {
  try {
    const url = `servicedesk/fetch/${serviceType}/time_entry/${timeEntryId}/`;
    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createActivity = async ({
  activityType,
  ...data
}: CreateActivityInputType) => {
  try {
    const url = `/servicedesk/activity/${activityType}`;
    const response = await axiosClient.post(`${url}`, data);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateActivity = async ({
  entryId,
  ...data
}: UpdateActivityInputType) => {
  try {
    const url = `/servicedesk/activity/${entryId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createTimeEntryByManmager = async ({
  resourceId,
  serviceId,
  serviceType,
  startTime,
  endTime,
  isBillable,
  notes,
  deductTime,
  accessSpecifier,
  markAsResolution,
  notesAttachments,
  sendAsEmail,
  work_type,
  work_role,
}: createTimeEntriesInput) => {
  try {
    const data = {
      resource_id: resourceId,
      start_time: startTime,
      end_time: endTime,
      deduct_time: deductTime,
      is_billable: isBillable,
      notes: notes,
      access_specifier: accessSpecifier,
      mark_as_resolution: markAsResolution,
      notes_attachments: notesAttachments,
      send_email: sendAsEmail,
      work_type: work_type,
      work_role: work_role,
    };

    const response = await axiosClient.post(
      `/servicedesk/${serviceType}/${serviceId}/manager/time_entry`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateTimeEntryByManager = async ({
  timeEntryId,
  serviceType,
  resourceId,
  startTime,
  endTime,
  isBillable,
  notes,
  deductTime,
  accessSpecifier,
  markAsResolution,
  notesAttachments,
  sendAsEmail,
  work_type,
  work_role,
  ...rest
}: UpdatetimeEntriesInput) => {
  try {
    const data = {
      resource_id: resourceId,
      start_time: startTime,
      end_time: endTime,
      is_billable: isBillable,
      notes: notes,
      deduct_time: deductTime,
      access_specifier: accessSpecifier,
      mark_as_resolution: markAsResolution,
      notes_attachments: notesAttachments,
      send_email: sendAsEmail,
      work_type: work_type,
      work_role: work_role,
    };

    const response = await axiosClient.patch(
      `/servicedesk/${serviceType}/time_entry/manager/${timeEntryId}`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createActivityByManager = async ({
  activityType,
  ...data
}: CreateActivityInputType) => {
  try {
    const url = `/servicedesk/activity/${activityType}/manager`;
    const response = await axiosClient.post(`${url}`, data);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateActivityByManager = async ({
  entryId,
  ...data
}: UpdateActivityByManagerInputType) => {
  try {
    const url = `/servicedesk/activity/manager/${entryId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return response.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getAllActiveTimeEntries = async () => {
  try {
    const url = "/active/time-entries";
    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData && resData.success === true && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw response;
    }
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
