import { Stack, Typography } from "@mui/material";
import { Button } from "components";
import { useCreateSampleData, useSkipSampleData } from "controller";
import { useNavigate } from "react-router-dom";

export const SampleDataConfirmation = ({
  onContinueClick,
}: {
  onContinueClick: () => void;
}) => {
  const { mutateAsync: createSampleData, isLoading: isCreateLoading } =
    useCreateSampleData();
  const { mutateAsync: skipSampleData, isLoading: isSkipLoading } =
    useSkipSampleData();
  const navigate = useNavigate();
  const handleButtonClick = async (type: "skip" | "continue") => {
    try {
      if (type === "skip") {
        await skipSampleData();
        window.location.reload();
      } else {
        await createSampleData();
        onContinueClick();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Stack
      sx={{
        maxWidth: "410px",
        gap: "36px",
      }}
    >
      <Typography variant="h4">
        Would you like to try DeskDay with sample data?
      </Typography>
      <Stack gap={"12px"}>
        <Typography
          variant="body-large"
          sx={{
            textAlign: "left",
          }}
        >
          To get the most out of your DeskDay trial, we recommend adding sample
          data.
        </Typography>
        <Typography variant="body-large" sx={{ textAlign: "left" }}>
          Not interested? Skip the data for now and add them later under Control
          Center.
        </Typography>
        <Typography variant="body-large" sx={{ textAlign: "left" }}>
          You can clear the data anytime using “Clear Sample Data”.
        </Typography>
      </Stack>

      <Stack direction={"row"} gap={"16px"}>
        <Button
          variant="secondary"
          onClick={() => handleButtonClick("skip")}
          disabled={isCreateLoading || isSkipLoading}
        >
          Skip
        </Button>
        <Button
          onClick={() => handleButtonClick("continue")}
          loading={isSkipLoading || isCreateLoading}
        >
          Add Sample Data
        </Button>
      </Stack>
    </Stack>
  );
};
