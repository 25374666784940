import { axiosClient } from "utilz";
import { apiErrorHandler, debugConsole, getMspId } from "utilz/helpers";

export const getMspGeneralConfig = async () => {
  try {
    const url = "/configuration/general";
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getGeneralActivities = async () => {
  try {
    const url = "/configuration/general/activities";
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;

    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
