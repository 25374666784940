import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";

export const LegendBuilder = ({
  data = [],
  labelKey = "label",
  color,
  selectedIndex = -1,
  onSelectedIndexChange,
  containerSx = {},
  direction = "row",
  listSx = {},
}: {
  data: Array<any>;
  labelKey: string;
  color?: string;
  selectedIndex?: number;
  onSelectedIndexChange?: (index: number) => void;
  containerSx?: SxProps<Theme>;
  direction?: "row" | "column";
  listSx?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        ...containerSx,
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
          margin: "auto",
          alignItems: direction === "row" ? "center" : "start",
          display: "flex",
          flexDirection: direction,
          flexWrap: "nowrap",
          overflowX: "auto",
          "&::-webkit-scrollbar:horizontal": {
            display: "block",
          },
          gap: "16px",
          ...listSx,
        }}
      >
        {Array.isArray(data) &&
          data.map((item, index) => {
            const percentage =
              item.value / Math.max(...data.map((item) => item.value));
            const colorOpacity = 0.3 * (1 - percentage) + percentage;
            return (
              <Stack
                key={index}
                direction={"row"}
                spacing={"8px"}
                width={"fit-content"}
                justifyContent={"start"}
                ml={"8px"}
                mb="4px"
                alignItems={"center"}
                onClick={() => {
                  onSelectedIndexChange && onSelectedIndexChange(index);
                }}
              >
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "4px",
                    aspectRatio: "1",

                    backgroundColor: item.color || color || "black",
                    opacity: item.color ? 1 : colorOpacity,
                    ...(selectedIndex === index
                      ? {
                          border: `1px solid ${theme.palette.lightBg.main}`,
                          boxShadow: `-2px 2px 5px 0px ${item.color || color}`,
                        }
                      : {}),
                  }}
                ></Box>

                <Typography
                  variant="body-small"
                  sx={{
                    textTransform: "capitalize",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    maxLines: 1,
                    color:
                      selectedIndex === index
                        ? theme.palette.lightBg.main
                        : theme.palette.lightBg.low,
                  }}
                >
                  {item[labelKey]}
                </Typography>
              </Stack>
            );
          })}
      </Box>
    </Box>
  );
};
