import { fetchCall } from "../../../utilz";
import { appConfig } from "../../../constants";

// Fetching Custom Name by MSPID
const fetchCustomNameByMspId = (id)=> 
  fetchCall(
    `/controlCenter/get/custom_name/msp/${id}`,
    appConfig?.requestMethods.GET
  );

// Updating Custom Name by MSPID
const updateCustomNameByMspIp = (id, data)=>
  fetchCall(
    `/controlCenter/update/custom_name/msp/${id}`,
    appConfig?.requestMethods?.PATCH,
    data
  );

export const whiteLabelServices = {
  fetchCustomNameByMspId,
  updateCustomNameByMspIp
};