import { GetQueryProps } from "shared";
import { axiosClient, fetchCall } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const getAllTags = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: `/configuration/fetch/tag/msp/${getMspId()}`,
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
