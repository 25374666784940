import TabsUnstyled from "@mui/base/TabsUnstyled";
import { Box, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CustomTypography } from "../../../../../../library/base";
import { ReturnContext } from "../../../../../../contexts/return-context";
import {
  GeneralTab,
  ProjectAttributeTab,
  TagsTab,
  TaskAttributesTab,
  TicketAttributesTab,
} from "./Tabs";
import { Tab, Tabs } from "components";

const TAB_CONSTANT: any = [
  {
    key: "configuration-attribute-tab-general",
    label: "General",
    map: "GENERAL",
    tab: <GeneralTab key={"configuration-attribute-tab-general-page"} />,
  },
  {
    key: "configuration-attribute-tab-tickets",
    label: "Tickets",
    map: "TICKETS",
    tab: (
      <TicketAttributesTab key={"configuration-attribute-tab-tickets-page"} />
    ),
  },
  {
    key: "configuration-attribute-tab-project",
    label: "Projects",
    map: "PROJECTS",
    tab: (
      <ProjectAttributeTab key={"configuration-attribute-tab-project-page"} />
    ),
  },

  // REQUESTS: {
  //     key: 'configuration-attribute-tab-requests',
  //     label: "Requests",
  //     map: 'REQUESTS',
  //     tab: <></>
  // },

  {
    key: "configuration-attribute-tab-tasks",
    label: "Tasks",
    map: "TASKS",
    tab: <TaskAttributesTab key={"configuration-attribute-tab-tasks-page"} />,
  },
  {
    key: "configuration-attribute-tab-tags",
    label: "Tags",
    map: "TAGS",
    tab: <TagsTab key={"configuration-attribute-tab-tags-page"} />,
  },
];
export default TAB_CONSTANT;

const ServiceDeskConfigurationAttributesComponent = () => {
  const returnContext: any = useContext(ReturnContext);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    returnContext?.setEnableReturn?.(true);
  }, []);

  const onTabChangeHandler = (event: any, tabIndex: any) => {
    setSelectedTab(tabIndex);
  };

  return (
    <React.Fragment>
      <Stack
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            px: "32px",
            py: "24px",
          }}
        >
          <CustomTypography
            content="Custom Fields"
            size="MEDIUM-ALPHA"
            variant="BOLD"
          />
        </Box>

        <Tabs value={selectedTab} onChange={onTabChangeHandler}>
          {TAB_CONSTANT.map((item: any) => (
            <Tab key={item.key} label={item.label} />
          ))}
        </Tabs>
        {TAB_CONSTANT[selectedTab].tab}
      </Stack>
    </React.Fragment>
  );
};

export { ServiceDeskConfigurationAttributesComponent };
