import { createContext, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  updateMsp,
  // updateMspCustomName,
} from "controller/api/control-center/msp";
import {
  Launch,
  CompanySettingsForm,
  // ChannelSettingsForm,
  // AddResourcesForm,
  OnboardFirstCustomer,
} from "../components";
import { bulkAddResources } from "controller";
import { getCompanySettingsFormValidationObject } from "../utilz";
import { getAddResourceDetailsValidationObject } from "../utilz/getAddResourceDetailsValidationObject";
// import { getMspId } from 'utilz/helpers';
// import { services } from 'services';
import { useGlobalStore } from "controller/store/globalStore";
// import toast from 'react-hot-toast';
const OnboardingModalContext = createContext();

const OnboardingModalProvider = ({ children }) => {
  const mspId = useGlobalStore((state) => state.mspId);
  // Variables
  const forms = {
    form1: {
      id: 1,
      formName: "Launch",
      component: <Launch />,
    },
    form2: {
      id: 2,
      formName: "Company Settings",
      component: <CompanySettingsForm />,
    },
    // form2: {
    //   id: 2,
    //   formName: 'Channel Settings',
    //   component: <ChannelSettingsForm />,
    // },
    // form3: {
    //   id: 3,
    //   formName: 'Add Resources',
    //   component: <AddResourcesForm />,
    // },
    form3: {
      id: 3,
      formName: "On-Board first customer",
      component: <OnboardFirstCustomer />,
    },
  };

  // States
  const isSkipBtnClickedOnInitialRender = JSON.parse(
    localStorage.getItem(`isDeskDayOnboardingSkipBtnClicked${mspId}`)
  );
  const [isSkipBtnClicked, setIsSkipBtnClicked] = useState(
    isSkipBtnClickedOnInitialRender ?? false
  );
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const [toaster, setToaster] = useState({
    open: false,
    severity: "SUCCESS",
    message: "",
  });
  const [currentForm, setCurrentForm] = useState(forms.form1);
  const [skippedStepsLabels, setSkippedStepsLabels] = useState(new Set());
  const [completedStepsLabels, setCompletedStepsLabels] = useState(new Set());
  const [companySettingsFormData, setCompanySettingsFormData] = useState({
    website: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    zipCode: "",
    state: "",
    timeZone: "",
  });
  const [companySettingsFormValidation, setCompanySettingsFormValidation] =
    useState({
      websiteValidation: {
        isValid: true,
        errorMessage: "",
      },
      addressLine1Validation: {
        isValid: true,
        errorMessage: "",
      },
      addressLine2Validation: {
        isValid: true,
        errorMessage: "",
      },
      cityValidation: {
        isValid: true,
        errorMessage: "",
      },
      countryValidation: {
        isValid: true,
        errorMessage: "",
      },
      zipCodeValidation: {
        isValid: true,
        errorMessage: "",
      },
      stateValidation: {
        isValid: true,
        errorMessage: "",
      },
      timeZoneValidation: {
        isValid: true,
        errorMessage: "",
      },
    });
  // const [singleResource, setSingleResource] = useState({
  //   id: uuidv4(),
  //   firstName: { value: '', isValid: true, errorMsg: '' },
  //   lastName: { value: '', isValid: true, errorMsg: '' },
  //   emailId: { value: '', isValid: true, errorMsg: '' },
  //   resourceType: {
  //     value: '',
  //     isValid: true,
  //     errorMsg: '',
  //   },
  // });
  const [resources, setResources] = useState([
    {
      id: uuidv4(),
      fullName: { value: "", isValid: true, errorMsg: "" },
      emailId: { value: "", isValid: true, errorMsg: "" },
      resourceType: {
        value: "",
        isValid: true,
        errorMsg: "",
      },
      isAddResourceDetailsDataFilled: true,
      isAddResourceDetailsDataValid: true,
    },
  ]);
  // const [resources, setResources] = useState([
  //   {
  //     id: uuidv4(),
  //     fullName: '',
  //     emailId: '',
  //     resourceType: '',
  //   },
  // ]);
  // const [resourcesValidation, setResourcesValidation] = useState({

  // })
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  // Handlers
  const handleLaunchSubmit = (e) => {
    e.preventDefault();
    setCurrentForm((prevForm) => forms[`form${prevForm.id + 1}`]);
    setCompletedStepsLabels((prev) => {
      const newCompletedStepsLabels = new Set(prev.values());
      newCompletedStepsLabels.add(currentForm.formName);
      return newCompletedStepsLabels;
    });
    setSkippedStepsLabels((prev) => {
      const newSkippedStepsLabels = new Set(prev.values());
      if (newSkippedStepsLabels.has(currentForm.formName)) {
        newSkippedStepsLabels.delete(currentForm.formName);
      }
      return newSkippedStepsLabels;
    });
  };
  const handleCompanySettingsFormSubmit = async (e) => {
    e.preventDefault();
    const {
      website,
      addressLine1,
      addressLine2,
      city,
      country,
      zipCode,
      state,
      timeZone,
    } = companySettingsFormData;
    const {
      isCompanySettingsMandatoryDataFilled,
      isCompanySettingsDataValid,
      websiteValidation,
      addressLine1Validation,
      addressLine2Validation,
      cityValidation,
      countryValidation,
      zipCodeValidation,
      stateValidation,
      timeZoneValidation,
    } = getCompanySettingsFormValidationObject(companySettingsFormData);
    setCompanySettingsFormValidation((prev) => ({
      ...prev,
      websiteValidation,
      addressLine1Validation,
      addressLine2Validation,
      cityValidation,
      countryValidation,
      zipCodeValidation,
      stateValidation,
      timeZoneValidation,
    }));
    if (isCompanySettingsMandatoryDataFilled) {
      if (isCompanySettingsDataValid) {
        let isUpdateMspDetailsSuccess = false;
        const isUpdateMspCustomNameSuccess = true;
        try {
          const updateMspDetailsData = {
            website: website,
            address1: addressLine1,
            address2: addressLine2,
            city: city,
            country: Number(country),
            postal_code: zipCode,
            state: Number(state),
            time_zone: timeZone,
            // Hard coded values - need to remove these below ones
            operating_hours_from: "10:00:00",
            operating_hours_to: "10:00:00",
            date_format: "MM/DD/YYYY",
            is_full_time: true,
          };

          const updateMspResponseData = await updateMsp(updateMspDetailsData);
          if (
            updateMspResponseData.statusCode === 200 &&
            updateMspResponseData.success
          ) {
            isUpdateMspDetailsSuccess = true;
            // updating msp complete
          } else {
            throw new Error("Adding details failed");
          }

          // const updateMspCustomNameData = {
          //   custom_name: displayName,
          // };
          // const updateMspCustomNameResponseData = await updateMspCustomName(
          //   updateMspCustomNameData
          // );
          // if (
          //   updateMspCustomNameResponseData.statusCode === 200 &&
          //   updateMspCustomNameResponseData.success
          // ) {
          //   isUpdateMspCustomNameSuccess = true;
          //   // updating display name complete
          // } else {
          //   throw new Error('Updating display name failed');
          // }

          if (isUpdateMspDetailsSuccess && isUpdateMspCustomNameSuccess) {
            setToaster({
              open: true,
              severity: "SUCCESS",
              message: "Successfully updated company details",
            });
            setCurrentForm((prevForm) => forms[`form${prevForm.id + 1}`]);
            setCompletedStepsLabels((prev) => {
              const newCompletedStepsLabels = new Set(prev.values());
              newCompletedStepsLabels.add(currentForm.formName);
              return newCompletedStepsLabels;
            });
            setSkippedStepsLabels((prev) => {
              const newSkippedStepsLabels = new Set(prev.values());
              if (newSkippedStepsLabels.has(currentForm.formName)) {
                newSkippedStepsLabels.delete(currentForm.formName);
              }
              return newSkippedStepsLabels;
            });
          }
        } catch (error) {
          setToaster({
            open: true,
            severity: "ERROR",
            message: "Unable to update company details!",
          });
        }
      }
    } else {
      setToaster({
        open: true,
        severity: "ERROR",
        message: "Please fill out all fields",
      });
    }
  };

  // const handleCompanySettingsFormSubmit = async (e) => {
  //   e.preventDefault();
  //   const {
  //     website,
  //     addressLine1,
  //     addressLine2,
  //     city,
  //     country,
  //     zipCode,
  //     state,
  //     displayName,
  //   } = companySettingsFormData;
  //   if (
  //     website.value &&
  //     addressLine1.value &&
  //     addressLine2.value &&
  //     city.value &&
  //     country.value &&
  //     zipCode.value &&
  //     state.value &&
  //     displayName.value
  //   ) {
  //     if (isUrlValid(website.value)) {
  //       setCompanySettingsFormData((prev) => ({
  //         ...prev,
  //         website: {
  //           ...prev.website,
  //           isValid: true,
  //           errorMsg: 'Invalid website address',
  //         },
  //       }));

  //       let isUpdateMspDetailsSuccess = false;
  //       let isUpdateMspCustomNameSuccess = false;
  //       try {
  //         const updateMspDetailsData = {
  //           website: website.value,
  //           address1: addressLine1.value,
  //           address2: addressLine2.value,
  //           city: city.value,
  //           country: Number(country.value),
  //           postal_code: zipCode.value,
  //           state: Number(state.value),
  //           // Hard coded values - need to remove these below ones
  //           operating_hours_from: '10:00:00',
  //           operating_hours_to: '22:00:00',
  //           date_format: 'YYYY/MM/DD',
  //         };

  //         const updateMspResponseData = await updateMsp(updateMspDetailsData);
  //         if (
  //           updateMspResponseData.statusCode === 200 &&
  //           updateMspResponseData.success
  //         ) {
  //           isUpdateMspDetailsSuccess = true;
  //           // updating msp complete
  //         } else {
  //           throw new Error('Adding details failed');
  //         }

  //         const updateMspCustomNameData = {
  //           custom_name: displayName.value,
  //         };
  //         const updateMspCustomNameResponseData = await updateMspCustomName(
  //           updateMspCustomNameData
  //         );
  //         if (
  //           updateMspCustomNameResponseData.statusCode === 200 &&
  //           updateMspCustomNameResponseData.success
  //         ) {
  //           isUpdateMspCustomNameSuccess = true;
  //           // updating display name complete
  //         } else {
  //           throw new Error('Updating display name failed');
  //         }

  //         if (isUpdateMspDetailsSuccess && isUpdateMspCustomNameSuccess) {
  //           setToaster({
  //             open: true,
  //             severity: 'SUCCESS',
  //             message: 'Successfully updated company details',
  //           });
  //           setCurrentForm((prevForm) => forms[`form${prevForm.id + 1}`]);
  //           setCompletedStepsLabels((prev) => {
  //             const newCompletedStepsLabels = new Set(prev.values());
  //             newCompletedStepsLabels.add(currentForm.formName);
  //             return newCompletedStepsLabels;
  //           });
  //           setSkippedStepsLabels((prev) => {
  //             const newSkippedStepsLabels = new Set(prev.values());
  //             if (newSkippedStepsLabels.has(currentForm.formName)) {
  //               newSkippedStepsLabels.delete(currentForm.formName);
  //             }
  //             return newSkippedStepsLabels;
  //           });
  //         }
  //       } catch (error) {
  //         setToaster({
  //           open: true,
  //           severity: 'ERROR',
  //           message: 'Unable to update company details',
  //         });
  //       }
  //     } else {
  //       console.error('Url Invalid');
  //       setCompanySettingsFormData((prev) => ({
  //         ...prev,
  //         website: {
  //           ...prev.website,
  //           isValid: false,
  //         },
  //       }));
  //     }
  //   } else {
  //     setToaster({
  //       open: true,
  //       severity: 'ERROR',
  //       message: 'Please fill out all fields',
  //     });
  //   }
  // };

  // const handleAddResource = async (e) => {
  //   e.preventDefault();
  //   services.controlCenterservices.resourcesService
  //     .createNewResource(mspId, data)
  //     .subscribe({
  //       next: (response) => {
  //         if (
  //           response &&
  //           response.status === 'success' &&
  //           response.statusCode === 200
  //         ) {
  //           //success
  //         }
  //       },
  //       error: (error) => {
  //         toast.error('Unable to create resource.Try again later');
  //       },
  //     });
  // };

  const handleAddResourcesFormSubmit = async (e) => {
    e.preventDefault();
    const newResources = resources.map((resource) => {
      const {
        isAddResourceDetailsDataFilled,
        isAddResourceDetailsDataValid,
        fullNameValidation,
        emailIdValidation,
        resourceTypeValidation,
      } = getAddResourceDetailsValidationObject(resource);
      return {
        ...resource,
        fullName: { value: resource.fullName.value, ...fullNameValidation },
        emailId: { value: resource.emailId.value, ...emailIdValidation },
        resourceType: {
          value: resource.resourceType.value,
          ...resourceTypeValidation,
        },
        isAddResourceDetailsDataFilled: isAddResourceDetailsDataFilled,
        isAddResourceDetailsDataValid: isAddResourceDetailsDataValid,
      };
    });
    setResources(newResources);
    const isFormFilled =
      newResources.filter(
        (resource) => resource.isAddResourceDetailsDataFilled === false
      ).length === 0;
    const isFormDataValid =
      newResources.filter(
        (resource) => resource.isAddResourceDetailsDataValid === false
      ).length === 0;
    if (isFormFilled) {
      if (isFormDataValid) {
        try {
          const bulkAddResourcesData = newResources.map((resource) => ({
            email: resource.emailId.value,
            resource_type: resource.resourceType.value,
            name: resource.fullName.value,
          }));
          const bulkAddResourcesResponseData = await bulkAddResources(
            bulkAddResourcesData
          );
          console.log("🐱‍🏍", bulkAddResourcesResponseData);
          if (
            bulkAddResourcesResponseData.statusCode === 200 &&
            bulkAddResourcesResponseData.status === "success"
          ) {
            setToaster({
              open: true,
              severity: "SUCCESS",
              message: "Successfully added resources!",
            });
            setCurrentForm((prevForm) => forms[`form${prevForm.id + 1}`]);
            setCompletedStepsLabels((prev) => {
              const newCompletedStepsLabels = new Set(prev.values());
              newCompletedStepsLabels.add(currentForm.formName);
              return newCompletedStepsLabels;
            });
            setSkippedStepsLabels((prev) => {
              const newSkippedStepsLabels = new Set(prev.values());
              if (newSkippedStepsLabels.has(currentForm.formName)) {
                newSkippedStepsLabels.delete(currentForm.formName);
              }
              return newSkippedStepsLabels;
            });
          } else {
            throw new Error("Adding resources failed!");
          }
        } catch (error) {
          console.error("bulkAddResources error from handler:", error);
        }
      }
    } else {
      setToaster({
        open: true,
        severity: "ERROR",
        message: "Please fill out all fields!",
      });
    }
  };

  // const handleAddResourcesFormSubmit = async (e) => {
  //   e.preventDefault();
  // const resourcesWithEmptyFields = resources.filter((resource) => {
  //   const isAllFieldsFilled =
  //     resource.fullName.value &&
  //     resource.emailId.value &&
  //     resource.resourceType.value;
  //   return !isAllFieldsFilled;
  // });

  //   if (resourcesWithEmptyFields.length === 0) {
  //     // Email Validation
  //     let newResources = resources.map((resource) => ({
  //       ...resource,
  //       emailId: {
  //         ...resource.emailId,
  //         isValid: isEmailValid(resource.emailId.value),
  //       },
  //     }));
  //     // Check if all fields are valid
  //     const isResourcesDataValid = newResources.reduce(
  //       (accumulator, currentResourceData) => {
  //         const { fullName, emailId, resourceType } = currentResourceData;
  //         const isResourceDataValid =
  //           fullName.isValid && emailId.isValid && resourceType.isValid;
  //         return accumulator && isResourceDataValid;
  //       },
  //       true
  //     );
  //     setResources([...newResources]);
  //     if (isResourcesDataValid) {
  //       try {
  //         const bulkAddResourcesData = newResources.map((resource) => ({
  //           email: resource.emailId.value,
  //           resource_type: resource.resourceType.value,
  //           name: resource.fullName.value,
  //         }));
  //         const bulkAddResourcesResponseData = await bulkAddResources(
  //           bulkAddResourcesData
  //         );
  //         if (
  //           bulkAddResourcesResponseData.statusCode === 200 &&
  //           bulkAddResourcesResponseData.status === 'success'
  //         ) {
  //           setToaster({
  //             open: true,
  //             severity: 'SUCCESS',
  //             message: 'Successfully added resources',
  //           });
  //           setCurrentForm((prevForm) => forms[`form${prevForm.id + 1}`]);
  //           setCompletedStepsLabels((prev) => {
  //             const newCompletedStepsLabels = new Set(prev.values());
  //             newCompletedStepsLabels.add(currentForm.formName);
  //             return newCompletedStepsLabels;
  //           });
  //           setSkippedStepsLabels((prev) => {
  //             const newSkippedStepsLabels = new Set(prev.values());
  //             if (newSkippedStepsLabels.has(currentForm.formName)) {
  //               newSkippedStepsLabels.delete(currentForm.formName);
  //             }
  //             return newSkippedStepsLabels;
  //           });
  //         } else {
  //           throw new Error('Adding resources failed');
  //         }
  //       } catch (error) {
  //         console.error('bulkAddResources error from handler:', error);
  //       }
  //     }
  //   } else {
  //     setToaster({
  //       open: true,
  //       severity: 'ERROR',
  //       message: 'Please fill out all fields',
  //     });
  //   }
  // };
  const handleChannelSettingsFormSubmit = () => {
    // submit form
    // remove from skipped if in skipped
    return 1;
  };
  const handleOnboardFirstCustomerFormSubmit = () => 1;
  const handleSaveBtnClick = (e) => {
    switch (currentForm.formName) {
    case "Launch":
      handleLaunchSubmit(e);
      break;
    case "Company Settings":
      handleCompanySettingsFormSubmit(e);
      break;
    case "Channel Settings":
      handleChannelSettingsFormSubmit(e);
      break;
    case "Add Resources":
      handleAddResourcesFormSubmit(e);
      break;
    case "On-Board first customer":
      handleOnboardFirstCustomerFormSubmit(e);
      break;
    default:
      console.error("Invalid form name");
      // gracefully handle this in the ui later
    }
  };
  const handleSkipBtnClick = () => {
    // setCurrentForm((prevForm) =>
    //   prevForm.id !== 4 ? forms[`form${prevForm.id + 1}`] : prevForm
    // );
    setCurrentForm(forms.form3);
    setIsSkipBtnClicked(true);
    localStorage.setItem(
      `isDeskDayOnboardingSkipBtnClicked${mspId}`,
      JSON.stringify(true)
    );
    // setSkippedStepsLabels((prev) => {
    //   const newSkippedStepsLabels = new Set(prev.values());
    //   newSkippedStepsLabels.add(currentForm.formName);
    //   return newSkippedStepsLabels;
    // });
  };
  const handleBackBtnClick = () => {
    setCurrentForm((prevForm) =>
      prevForm.id !== 1 ? forms[`form${prevForm.id - 1}`] : prevForm
    );
  };

  return (
    <OnboardingModalContext.Provider
      value={{
        isSkipBtnClicked,
        setIsSkipBtnClicked,
        isOnboardingModalOpen,
        setIsOnboardingModalOpen,
        forms,
        currentForm,
        setCurrentForm,
        completedStepsLabels,
        setCompletedStepsLabels,
        skippedStepsLabels,
        setSkippedStepsLabels,
        toaster,
        setToaster,
        handleSaveBtnClick,
        handleSkipBtnClick,
        handleBackBtnClick,
        companySettingsFormData,
        setCompanySettingsFormData,
        companySettingsFormValidation,
        resources,
        setResources,
        countryOptions,
        setCountryOptions,
        stateOptions,
        setStateOptions,
        cityOptions,
        setCityOptions,
        timeZoneOptions,
        setTimeZoneOptions,
        handleCompanySettingsFormSubmit,
        handleAddResourcesFormSubmit,
        handleChannelSettingsFormSubmit,
        handleOnboardFirstCustomerFormSubmit,
      }}
    >
      {children}
    </OnboardingModalContext.Provider>
  );
};

const useOnboardingModal = () => useContext(OnboardingModalContext);

export { OnboardingModalProvider, useOnboardingModal };
