import { Box, Stack, Typography, useTheme } from "@mui/material";
import { AppIcon, Button, MailIcon, SelectableChip } from "components";
import { useUpdateMspNotificationSettings } from "controller/hooks/control-center/notifications";
import ToggleButton from "library/base/custom-toggle-button";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";

const RoleChips = [
  //   { label: "All", value: "all" },
  {
    label: "Super Admin",
    value: "super_admin",
    role_id: "b110abc3-0709-491f-ac04-86eb25159386",
  },
  {
    label: "Admin",
    value: "admin",
    role_id: "9d6afd0e-b353-438d-a34d-1bb1cc5bbf03",
  },
  { label: "Assignees", value: "assignees", role_id: null },
  // { label: "Manager", value: "manager" },
];

type RoleType = {
  role_id: string | null;
  name: string;
};
const NotifyThroughChips = [
  { label: "Email", value: "email", icon: <MailIcon fontSize="small" /> },
  {
    label: "Alert Center",
    value: "web_app",
    icon: <AppIcon fontSize="small" />,
  },
];

interface NotificationCardProps {
  notifyThroughChips?: {
    label: string;
    value: string;
    icon: JSX.Element;
  }[];
  type: string;
  data: Array<any>;
  showAllOptionOnNotifyRoles?: boolean;
  roleChips?: {
    label: string;
    value: string;
    role_id: null | string;
  }[];
  onSaveClick?: (data: {
    notificationSound: boolean;
    notificationStatus: boolean;
    notifyRoles: Array<RoleType>;
    notifyMethods: Array<string>;
    notificationType: string;
  }) => void;
}
export const NotificationCard = ({
  notifyThroughChips = NotifyThroughChips,
  roleChips = RoleChips,
  showAllOptionOnNotifyRoles = true,
  type,
  data,
  onSaveClick,
}: NotificationCardProps) => {
  const theme = useTheme();
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [isSoundActive, setIsSoundActive] = useState(true);
  const [selectedRoles, setSelectedRoles] = useState<Array<RoleType>>([]);
  const [selectedMediums, setSelectedMediums] = useState<string[]>([]);
  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    if (data && type) {
      const item = data.find((item) => item.notify_type === type);
      if (item) {
        setDataToState(item);
      }
    }
    return () => {
      resetData();
    };
  }, [data, type]);

  const resetData = () => {
    setSelectedRoles([]);
    setSelectedMediums([]);
    setIsSoundActive(false);
  };

  const setDataToState = (item: any) => {
    if (item.notification_status) {
      const notifyRoles = item.notify_roles.filter(
        (role: RoleType) => role.name !== "all"
      );
      if (notifyRoles.length === 0) {
        setSelectedRoles(
          roleChips.map((item) => {
            return {
              role_id: item.role_id,
              name: item.value,
            };
          })
        );
      } else {
        setSelectedRoles(notifyRoles);
      }

      setSelectedMediums(item.notify_methods);
      setIsSoundActive(item.notification_sound);
    }

    setIsNotificationActive(item.notification_status);
  };

  const handleNotificationToggel = (type: "main" | "sound") => {
    if (type === "main") {
      setIsNotificationActive((prev) => !prev);
      resetData();
    } else {
      setIsSoundActive((prev) => !prev);
    }
    setIsDataChanged(true);
  };

  const toggleNotifyRoles = useCallback(
    ({ value, id }: { value: string; id: string | null }) => {
      // toggle all chips if all is clicked
      if (value === "all" && selectedRoles.length === roleChips.length) {
        setSelectedRoles([]);
      } else if (value === "all" && selectedRoles.length !== roleChips.length) {
        setSelectedRoles(
          roleChips.map((item) => {
            return {
              role_id: item.role_id,
              name: item.value,
            };
          })
        );
      } else {
        if (!selectedRoles.find((item) => item.name === value)) {
          setSelectedRoles((prev) => [...prev, { role_id: id, name: value }]);
        } else {
          setSelectedRoles((prev) =>
            prev.filter((item) => item.name !== value)
          );
        }
      }

      setIsDataChanged(true);
    },
    [selectedRoles]
  );

  const toggleMediums = useCallback(
    (value: string) => {
      if (!selectedMediums.includes(value)) {
        setSelectedMediums((prev) => [...prev, value]);
      } else {
        setSelectedMediums((prev) => prev.filter((item) => item !== value));
      }

      setIsDataChanged(true);
    },
    [selectedMediums]
  );

  const handleCancelClick = () => {
    if (data && type) {
      const item = data.find((item) => item.notify_type === type);
      if (item) {
        setDataToState(item);
      }
      setIsDataChanged(false);
    }
  };

  const onSaveButtonClick = async () => {
    try {
      onSaveClick &&
        (await onSaveClick({
          notificationSound: isSoundActive,
          notificationStatus: isNotificationActive,
          notifyRoles: selectedRoles,
          notifyMethods: selectedMediums,
          notificationType: type,
        }));

      toast.success("Notification settings updated successfully");
      setIsDataChanged(false);
    } catch (error) {
      toast.error("Unable to update notification settings");
    }
  };
  return (
    <Box p="32px" sx={{ height: "100%", position: "relative" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Notification</Typography>
        <ToggleButton
          onChange={() => handleNotificationToggel("main")}
          isChecked={isNotificationActive}
        />
      </Stack>

      <Box
        sx={{
          borderRadius: "24px",
          border: `1px solid ${theme.palette.neutral["015"]}`,
          width: "100%",
          mt: "24px",
        }}
      >
        {/* Sound active  start */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px="24px"
          py="18px"
        >
          <Typography variant="body-medium">Notification Sounds</Typography>
          <ToggleButton
            onChange={() => handleNotificationToggel("sound")}
            isChecked={isSoundActive}
            isDisabled={!isNotificationActive}
          />
        </Stack>
        {/* sound active  end */}
        {/* Notify user role start */}
        <Box
          px="24px"
          py="18px"
          sx={{ borderTop: `1px solid ${theme.palette.neutral["015"]}` }}
        >
          <Typography variant="body-medium">Notify</Typography>
          <Stack direction="row" spacing={2} mt="16px">
            {showAllOptionOnNotifyRoles && (
              <SelectableChip
                key={"all"}
                label={"All"}
                isSelected={selectedRoles.length === roleChips.length}
                onClick={() => toggleNotifyRoles({ value: "all", id: null })}
              />
            )}

            {roleChips.map((role) => (
              <SelectableChip
                key={role.value}
                label={role.label}
                isSelected={
                  !!selectedRoles.find((item) => item.name === role.value)
                }
                onClick={() =>
                  toggleNotifyRoles({ value: role.value, id: role.role_id })
                }
              />
            ))}
          </Stack>
        </Box>
        {/* notify through start */}
        <Box
          px="24px"
          py="18px"
          sx={{ borderTop: `1px solid ${theme.palette.neutral["015"]}` }}
        >
          <Typography variant="body-medium">Notify Through </Typography>
          <Stack direction="row" spacing={2} mt="16px">
            {notifyThroughChips.map((role) => (
              <SelectableChip
                key={role.value}
                label={role.label}
                startIcon={role.icon}
                isSelected={selectedMediums.includes(role.value)}
                onClick={() => toggleMediums(role.value)}
              />
            ))}
          </Stack>
        </Box>
      </Box>
      {isDataChanged && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap="24px"
          sx={{
            p: "32px",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Button variant="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSaveButtonClick}>
            Save
          </Button>
        </Stack>
      )}
    </Box>
  );
};
