import { useQuery } from "@tanstack/react-query";
import { getAllBoards, getGeneralAttributes } from "controller/api";
import { ApiErrorType, GetGeneralAttributesInputProps } from "shared";
import { sentryLogger } from "utilz";

export const useGetGeneralAttributes = ({
  attributeName,
}: GetGeneralAttributesInputProps) => {
  return useQuery({
    queryKey: ["attributes", "general", attributeName],
    queryFn: () => getGeneralAttributes({ attributeName }),
    enabled: attributeName ? true : false,
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getGeneralAttributes",
      }),
  });
};
