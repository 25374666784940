function messageIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1489 8.60187C10.2303 8.68323 10.2303 8.81514 10.1489 8.8965C10.0676 8.97786 9.93567 8.97786 9.85431 8.8965C9.77295 8.81514 9.77295 8.68323 9.85431 8.60187C9.93567 8.52051 10.0676 8.52051 10.1489 8.60187"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M13.479 8.60187C13.5604 8.68323 13.5604 8.81514 13.479 8.8965C13.3977 8.97786 13.2658 8.97786 13.1844 8.8965C13.103 8.81514 13.103 8.68323 13.1844 8.60187C13.2658 8.52051 13.3977 8.52051 13.479 8.60187"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6.81398 8.60187C6.89534 8.68323 6.89534 8.81514 6.81398 8.8965C6.73262 8.97786 6.60071 8.97786 6.51935 8.8965C6.43799 8.81514 6.43799 8.68323 6.51935 8.60187C6.60071 8.52051 6.73262 8.52051 6.81398 8.60187"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M10 17.5L6.66667 14.1675V14.1667H4.16667C3.24583 14.1667 2.5 13.4208 2.5 12.5V4.16667C2.5 3.24583 3.24583 2.5 4.16667 2.5H15.8333C16.7542 2.5 17.5 3.24583 17.5 4.16667V12.5C17.5 13.4208 16.7542 14.1667 15.8333 14.1667H13.3333L10 17.4992"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}

export default messageIcon;