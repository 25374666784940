import { axiosClient } from "utilz";
import {
  reportMetadataDummyData,
  reportsListDummyData,
} from "./reportDummyData";
import {
  GetReportGraphInputProps,
  GetReportTableInputProps,
  SendAsEmailInputType,
} from "shared";

const BASE_URL = import.meta.env.VITE_APP_REPORTS_BASE_URL;
export const getReportsList = async () => {
  try {
    const url = `${BASE_URL}/v1/reports/categories`;
    const response = await axiosClient.get(url);

    const responseData = response.data;
    if (responseData.success && responseData.statusCode === 200) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getReportMetaData = async (reportName: string) => {
  try {
    const url = `${BASE_URL}/v1/reports/meta-data/${reportName}`;
    const response = await axiosClient.get(url);

    const responseData = response.data?.data;
    if (response?.data.success && response?.data.statusCode === 200) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getReportGraphData = async ({
  reportName,
  timePeriod,
  timezone,
  serviceType,
  customer,
}: GetReportGraphInputProps) => {
  try {
    const url = `${BASE_URL}/v1/reports/${serviceType}/${reportName}?time_zone=${timezone}&time_period=${timePeriod}${
      customer ? `&customer=${customer}` : ""
    }`;
    const response = await axiosClient.get(url);

    const responseData = response.data?.data;
    if (response?.data.success && response?.data.statusCode === 200) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getReportTableData = async ({
  reportName,
  timePeriod,
  timezone,
  serviceType,
  customer,
}: GetReportTableInputProps) => {
  try {
    const url = `${BASE_URL}/v1/reports/table/${serviceType}/${reportName}?time_zone=${timezone}&time_period=${timePeriod}${
      customer ? `&customer=${customer}` : ""
    }`;
    const response = await axiosClient.get(url);

    const responseData = response.data?.data;
    if (responseData) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const sendAsEmail = async ({
  customerId,
  to,
  body,
  subject,
  reportName,
  reportType,
  file,
}: SendAsEmailInputType) => {
  try {
    console.log(file);
    const formData = new FormData();
    customerId && formData.append("customer_id", customerId);
    formData.append("to", JSON.stringify([`${to}`]));
    formData.append("email_body", body);
    formData.append("subject", subject);
    formData.append("report_name", reportName);
    formData.append("report_type", reportType);
    formData.append("files", file);
    const url = `${BASE_URL}/v1/reports/send-email`;
    const response = await axiosClient.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const responseData = response.data?.data;
    if (responseData) {
      return responseData;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
