import { fetchCall } from "../../../utilz";
import { appConfig } from "../../../constants";

// Fetch all notification settings by MspId
const fetchNotificationSettingsByMspId = (id)=> 
  fetchCall(
    `/controlCenter/fetch/msp_notification/${id}`,
    appConfig.requestMethods.GET
  );

// Update Notification Settings by MspId
const updateNotificationSettingsByMspId = (id, data)=> 
  fetchCall(
    `/controlCenter/update/msp_notification/${id}`,
    appConfig.requestMethods.PATCH,
    data
  );

export const notificationServices = {
  fetchNotificationSettingsByMspId,
  updateNotificationSettingsByMspId
};