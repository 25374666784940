import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Input, PlusIcon, RadioButtonGroup, SelectField } from "components";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateBillingTemplate,
  useUpdateBillingTemplate,
} from "controller/hooks/configurations/billingAndContracts/billingTemplate";
import { useGetAllTaxConfigs } from "controller";
import toast from "react-hot-toast";
import { TaxCreatModal } from "./TaxCreatModal";
import { PaymentTermOptions } from "shared";
import CustomQuillEditor from "components/quill/QuillEditor";

const schema = yup.object({
  template_name: yup.string().trim().required("Template name is required"),
  payment_term: yup
    .number()
    .typeError("Payment term is required")
    .required("Payment term is required"),
  invoice_type: yup.string().required("Invoice type is required"),
  discount_is_percentage: yup.boolean().required(""),
  discount_value: yup
    .number()
    .typeError("Value must be a number")
    .required("Required")
    .min(0, "Value must be at least 0%")
    .max(100, "Value cannot exceed 100%"),
  tax: yup.string().required("Tax percent is required"),
  email_subject: yup.string().trim().required("Email subject is required"),
  email_body: yup.string().required("Email body is required"),
});

export const BillingEmailFields = forwardRef(
  ({ closeDrawer, mode }: any, ref) => {
    useImperativeHandle(ref, () => ({
      saveButtonClick: () => {
        handleSubmit(async (data) => {
          const discountData = Math.trunc(
            Number((Number(data.discount_value) * 100).toFixed(2))
          );
          try {
            if (mode?.method === "create") {
              await createBillingMailTemplate({
                ...data,
                discount_value: discountData,
              });
              toast.success("Billing template created successfully");
            } else if (mode?.method === "update") {
              await updateBillingMailTemplate({
                data: {
                  ...data,
                  discount_value: discountData,
                },
                id: mode?.data?.mapping_id,
              });
              toast.success("Billing template updated successfully");
            }
            closeDrawer();
            reset();
          } catch (error) {
            toast.error(
              "Unable to add template at the moment. Please try again."
            );
          }
        })();
      },
      cancelButtonClick: () => {
        closeDrawer();
        reset();
      },
    }));

    const {
      handleSubmit,
      control,
      reset,
      formState: { errors },
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        template_name: mode?.data?.template_name || "",
        payment_term: mode?.data?.payment_term || "",
        invoice_type: mode?.data?.invoice_type || "",
        discount_is_percentage: mode?.data?.discount_is_percentage || true,
        discount_value: mode?.data?.discount_value
          ? (mode?.data?.discount_value / 100).toFixed(2)
          : "",
        tax: mode?.data?.tax || "",
        email_subject: mode?.data?.email_subject || "",
        email_body: mode?.data?.email_body || "",
      },
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      if (mode?.data) {
        // Manually set default values after mode?.data is fetched
        setValue("template_name", mode?.data.template_name || "");
        setValue("payment_term", mode?.data.payment_term || "");
        setValue("invoice_type", mode?.data.invoice_type || "");
        // setValue(
        //   "discount_is_percentage",
        //   mode?.data.discount_is_percentage || false
        // );
        setValue("discount_is_percentage", true);
        setValue(
          "discount_value",
          mode?.data.discount_value
            ? (mode?.data.discount_value / 100).toFixed(2)
            : ""
        );
        setValue("tax", mode?.data.tax || "");
        setValue("email_subject", mode?.data.email_subject || "");
        setValue("email_body", mode?.data.email_body || "");
      } else {
        reset();
      }
    }, [mode?.data, setValue, closeDrawer]);

    const { mutateAsync: createBillingMailTemplate, isLoading } =
      useCreateBillingTemplate();
    const { mutateAsync: updateBillingMailTemplate } =
      useUpdateBillingTemplate();
    const { data: taxData, isFetching: isTaxDataLoading } = useGetAllTaxConfigs(
      {
        filter: [],
        // sortColumn: "tax_name",
        sortColumn: "created_at",
        sortDirection: "desc",
      }
    );
    const theme = useTheme();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [discountPlaceholder, setDiscountPlaceholder] = useState("%");
    const emailBody = watch("email_body");
    const discountType = watch("discount_is_percentage");
    const [taxAddOnOptionList, setTaxAddOnOptionList] = useState<any>([]);
    const [latestTaxCreated, setLatestTaxCreated] = useState(null);

    useEffect(() => {
      if (discountType == "true") {
        setDiscountPlaceholder("%");
      } else if (discountType == "false") {
        setDiscountPlaceholder("$");
      }
    }, [discountType]);

    useEffect(() => {
      setValue("tax", latestTaxCreated);
    }, [latestTaxCreated]);
    return (
      <>
        <Box
          sx={{
            width: "637px",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Input
            label={"Template Name *"}
            name="template_name"
            control={control}
            error={errors?.template_name ? true : false}
            isResponsive={false}
            // @ts-ignore
            errorMessage={errors?.template_name?.message || ""}
          />
          <SelectField
            name="invoice_type"
            label="Invoice Type *"
            control={control}
            options={[
              { type_name: "consolidated", type_value: "consolidated" },
              { type_name: "summarized", type_value: "summarized" },
              { type_name: "detailed", type_value: "detailed" },
            ]}
            labelMappingKey="type_name"
            valueMappingKey="type_value"
            error={errors?.invoice_type ? true : false}
            isResponsive={false}
            // @ts-ignore
            errorMessage={errors?.invoice_type?.message || ""}
          />
          <SelectField
            name="payment_term"
            label="Payment Term *"
            control={control}
            options={PaymentTermOptions}
            labelMappingKey="label"
            valueMappingKey="value"
            error={errors?.payment_term ? true : false}
            isResponsive={false}
            // @ts-ignore
            errorMessage={errors?.payment_term?.message || ""}
          />
          <SelectField
            name="tax"
            label="Tax (%) *"
            control={control}
            options={taxData?.result || []}
            labelMappingKey="tax_name"
            valueMappingKey="mapping_id"
            error={errors?.tax ? true : false}
            renderValue={(tax_name, option) => {
              return (
                <Box sx={{ display: "flex", gap: "175px" }}>
                  <Typography variant="body-medium">
                    {option?.tax_name}
                  </Typography>
                  <Typography variant="body-medium">
                    | &nbsp;&nbsp;&nbsp;{" "}
                    {option?.tax_rate && option?.tax_rate / 100}
                  </Typography>
                </Box>
              );
            }}
            menuItemBuilder={({ item }) => (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body-medium">{item.tax_name}</Typography>
                <Typography variant="body-medium">
                  {item.tax_rate.toFixed(2) / 100}
                </Typography>
              </Box>
            )}
            isResponsive={false}
            // @ts-ignore
            errorMessage={errors?.tax?.message || ""}
            additionalPaperComponent={
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 20px 10px 20px",
                    position: "sticky",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsAddModalOpen(true);
                  }}
                >
                  <Box>
                    <Typography
                      variant="body-small"
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.lightBg.low,
                      }}
                    >
                      Add New
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlusIcon
                      sx={{
                        fontSize: "26px",
                        color: theme.palette.lightBg.low,
                      }}
                    />
                  </Box>
                </Box>
                <Divider />
              </>
            }
          />
          <Box sx={{ display: "flex", gap: "12px" }}>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Input
                fullWidth
                label={`Discount (${discountPlaceholder}) *`}
                name="discount_value"
                control={control}
                error={errors?.discount_value ? true : false}
                placeholder={discountPlaceholder}
                isResponsive={false}
                // hideHelperBox={true}
                type="number"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  max: 100,
                  maxLength: 3,
                }}
                errorMessage={
                  errors?.discount_value?.message &&
                  (errors?.discount_value?.message as string)
                }
              />
            </Box>
            {/* <Box>
              <RadioButtonGroup
                defaultValue={"percentage"}
                data={[
                  {
                    name: "%",
                    value: true,
                  },
                  {
                    name: "FIXED",
                    value: false,
                  },
                ]}
                row={true}
                labelMappingKey={"name"}
                valueMappingKey={"value"}
                value={discountType}
                onChange={function (value: any): void {
                  setValue("discount_is_percentage", value);
                }}
              />
            </Box> */}
          </Box>
          <Input
            label={"Subject *"}
            name="email_subject"
            control={control}
            error={errors?.email_subject ? true : false}
            // @ts-ignore
            errorMessage={errors?.email_subject?.message || ""}
            isResponsive={false}
          />
        </Box>
        <CustomQuillEditor
          name="email_body"
          placeholder="Type your Mail Body *"
          error={errors?.email_body ? true : false}
          onChange={(e) => {
            setValue("email_body", e?.value);
          }}
          value={emailBody}
        />
        {isAddModalOpen && (
          <TaxCreatModal
            openModal={isAddModalOpen}
            setIsAddModalOpen={setIsAddModalOpen}
            taxAddOnOptionList={taxAddOnOptionList}
            setTaxAddOnOptionList={setTaxAddOnOptionList}
            setLatestTaxCreated={setLatestTaxCreated}
            setTaxValue={setValue}
          />
        )}
      </>
    );
  }
);
