import { Box, Button, Stack, TextField, useTheme } from "@mui/material";
import { MinusIcon, PlusIcon } from "components/icons";
import React from "react";

export const DialerInput = ({
  value,
  onValueChange,
  disabled = false,
  maxValue,
  minValue = 0,
}: {
  value: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
}) => {
  const theme = useTheme();

  const handleValueChange = (type: "increment" | "decrement") => {
    if (disabled) return;
    const intValue = parseInt(value);
    if (type === "increment") {
      if (maxValue && intValue + 1 > maxValue) return;
      onValueChange(`${intValue + 1}`);
    } else {
      if (minValue && intValue - 1 < minValue) return;

      if (intValue > 0) {
        onValueChange(`${intValue - 1}`);
      }
    }
  };
  return (
    <Stack
      direction="row"
      sx={{
        height: "32px",
        border: `1px solid ${theme.palette.neutral["020"]}`,
        borderRadius: "8px",
        overflow: "clip",
      }}
    >
      <Stack
        onClick={() => handleValueChange("decrement")}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          borderRight: `1px solid ${theme.palette.neutral["020"]}`,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        <MinusIcon />
      </Stack>
      <TextField
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: 0,
          max: maxValue,
        }}
        type="number"
        value={value}
        disabled={disabled}
        onChange={(e) => {
          if (!disabled) {
            let value = Number(e.target.value);
            if (maxValue) {
              value = Math.max(0, Math.min(maxValue || 1, value));
            }
            if (minValue) {
              value = Math.max(0, Math.max(minValue || 1, value));
            }
            onValueChange(value.toString());
          }
        }}
        sx={{
          paddingX: "0px",
          width: "40px",
          outline: "none",
          border: "none",
          outlineWidth: "0px",
          backgroundColor: theme.palette.neutral["005"],
          justifyContent: "center",
          textAlign: "center",
          ...theme.typography["body-small"],

          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            { display: "none" },

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              padding: "0px",
              border: "none",
            },
            "& .MuiInputBase-input": {
              padding: "0px",
              border: "none",
              textAlign: "center",
            },
          },
        }}
      ></TextField>
      <Stack
        onClick={() => handleValueChange("increment")}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          borderLeft: `1px solid ${theme.palette.neutral["020"]}`,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        <PlusIcon />
      </Stack>
    </Stack>
  );
};
