import React, { useEffect } from "react";
import { NoSubscriptionScreen, SubscriptionDetails } from "./components";
import { useSearchParams } from "react-router-dom";
import { Button, CheckIcon, CloseIcon, Modal } from "components";
import { Stack, Typography, useTheme } from "@mui/material";
import { useGetMspById, useGlobalStore } from "controller";
import { useMixpanelPageViewTracker } from "utilz/helpers";
import { ConfirmationModal } from "components/modal/ConfirmationModal";

export const SubscriptionHomeScreen = () => {
  useMixpanelPageViewTracker({ event: "subscription_page_visited" });
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const resourceId = useGlobalStore((state) => state.resourceId);
  const { data: mspData } = useGetMspById(resourceId || "");
  const [modalType, setModalType] = React.useState<
    "success" | "error" | "update" | null
  >(null);

  useEffect(() => {
    if (searchParams.get("type")) {
      setModalType(searchParams.get("type") as "success" | "error" | "update");
    }
  }, []);

  const handleClose = () => {
    setModalType(null);
    searchParams.delete("type");
    setSearchParams(searchParams);
  };

  const checkSubscriptionStatus = () => {
    if (mspData?.status === "active" || mspData?.status === "past_due") {
      if (mspData?.cancel_at_period_end === true) {
        return false;
      }
      return true;
    }
    return false;
  };
  if (!mspData) return <></>;
  return (
    <div style={{ height: "100%" }}>
      {checkSubscriptionStatus() ? (
        <SubscriptionDetails />
      ) : (
        <NoSubscriptionScreen />
      )}

      {modalType && (
        <ConfirmationModal
          title={getConfirmationTitle(modalType)}
          open={modalType ? true : false}
          type={modalType}
          tagline={getConfirmationTagline(modalType)}
          hideSecondaryButton
          primaryButtonText="Close"
          onPrimaryButtonClick={handleClose}
        />
      )}
    </div>
  );
};

const getConfirmationTitle = (type: "success" | "error" | "update") => {
  switch (type) {
    case "success":
      return "Payment Successful";
    case "error":
      return "Payment Failed";
    case "update":
      return "Seat Count Updated";

    default:
      return "";
  }
};

const getConfirmationTagline = (type: "success" | "error" | "update") => {
  switch (type) {
    case "success":
      return "Your payment has been processed successfully.";
    case "error":
      return "It looks like there was an issue processing your payment. Please double-check your payment details and give it another shot.";
    case "update":
      return "Your seat count has been changed successfully.";
  }
};
