import { styled } from "@mui/material/styles";
import { default as MuiTabs } from "@mui/material/Tabs";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

export const Tabs = styled(MuiTabs)(({ theme, ...rest }) => {
  if (rest.orientation === "vertical") {
    return {
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.purple.main,
        height: "4px",
        width: "4px",
      },
      "& .MuiTabs-flexContainer": {
        gap: "4px",
      },
      "& .MuiTab-root": {
        textTransform: "capitalize",
        textAlign: "left",
        // alignItems: "flex-start",
        justifyContent: "start",
        minHeight: "48px",
        display: "flex",
        paddingLeft: "32px",
        backgroundColor: theme.palette.neutral["005"],
        ...theme.typography["body-large"],
        "&:hover": {
          // color: theme.palette.purple.main,
          backgroundColor: theme.palette.neutral["B-005"],
        },
        "&.Mui-selected": {
          backgroundColor: "#E7E9FE",
          ...theme.typography["h6"],
          color: theme.palette.purple.main,
        },
        color: theme.palette.lightBg.low,
      },
    };
  }
  return {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.purple.main,
      height: "4px",
    },
    "& .MuiTabs-flexContainer": {
      gap: "32px",
      padding: 0,
    },
    "& .MuiTab-root": {
      minWidth: "unset",
      padding: "0px",
      textTransform: "capitalize",
      ...theme.typography["body-large"],
      "&:hover": {
        color: theme.palette.purple.main,
      },
      "&.Mui-selected": {
        ...theme.typography["button-large"],
        color: theme.palette.purple.main,
      },
      color: theme.palette.lightBg.low,
    },
  };
});
