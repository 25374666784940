import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Button,
  DeleteConfirmationModal,
  DeleteIcon,
  Vertical3DotsIcon,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { AddPaymentMethod } from "./AddPaymentMethod";
import {
  useDefaultCard,
  useDeleteCard,
  useGetCards,
  useGetSetupIntent,
} from "controller";
// @ts-ignore
import VisaSvg from "assets/images/visa.svg";
// @ts-ignore
import MastercardSvg from "assets/images/mastercard.svg";
// @ts-ignore
import DefaultCard from "assets/images/defaultCard.svg";
import toast from "react-hot-toast";
import stripePromise from "utilz/stripe";
import { Elements } from "@stripe/react-stripe-js";
export const PaymentDetailsCard = ({
  defaultCard,
}: {
  defaultCard: string;
}) => {
  const theme = useTheme();
  const [defaultCardId, setDefaultCardId] = useState(defaultCard);
  const [isDefaultCardModalOpen, setIsDefaultCardModalOpen] = useState<
    string | null
  >(null);
  const [isAddCardsOpen, setIsAddCardsOpen] = useState(false);
  const [setupIntentId, setSetupIntentId] = useState();
  const [isDeleteCardModalOpen, setIsDeleteCardModalOpen] = useState<
    string | null
  >(null);
  const { data: cardList } = useGetCards();
  const { mutateAsync: deleteCard, isLoading: deletingCard } = useDeleteCard();
  const { mutateAsync: setDefaultCard, isLoading: isDefaultCardLoading } =
    useDefaultCard();
  const { mutateAsync: getSetupIntent, isLoading: loadingSetupIntent } =
    useGetSetupIntent();

  useEffect(() => {
    setDefaultCardId(defaultCard);
  }, [defaultCard]);

  const handleSetAsDefault = async (id: string) => {
    try {
      await setDefaultCard(id);
      setDefaultCardId(id);
    } catch (error) {
      toast.error("Unable to set default card");
    }
  };
  const handleAddCardClick = async () => {
    try {
      const data = await getSetupIntent();
      if (data && data.client_secret) setSetupIntentId(data.client_secret);
    } catch (error) {
      toast.error("Unable to add new card");
    }
    setIsAddCardsOpen((prev) => !prev);
  };

  const cardIcon = useCallback((type: string) => {
    switch (type) {
      case "visa":
        return VisaSvg;
      case "mastercard":
        return MastercardSvg;

      default:
        return DefaultCard;
    }
  }, []);

  const isDefault = (cardId: string) => {
    if (cardList?.length === 1 || cardId === defaultCardId) {
      return true;
    } else {
      false;
    }
  };
  return (
    <Stack sx={{ width: "100%" }}>
      <Stack sx={{ gap: "24px", width: "100%" }}>
        <Typography variant="h6">Payment Method</Typography>
        <Stack
          sx={{
            border: `1px solid ${theme.palette.neutral["015"]}`,
            borderRadius: "16px",
            width: "100%",
            paddingY: "16px",
          }}
          divider={
            <Divider
              sx={{ borderStyle: "dashed" }}
              orientation="horizontal"
              flexItem
            />
          }
        >
          {cardList?.map((card: any) => {
            return (
              <Stack
                key={card.id}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                px={"32px"}
                py="12px"
              >
                <Stack
                  direction={"row"}
                  sx={{ flex: 1, justifyContent: "flex-start", gap: "24px" }}
                >
                  <Stack
                    sx={{
                      border: `1px solid ${theme.palette.neutral["015"]}`,
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={cardIcon(card?.card?.brand)}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      variant="caption-2"
                      textTransform={"capitalize"}
                      noWrap
                    >
                      {card?.card?.brand} **** **** **** {card?.card?.last4}
                    </Typography>
                    <Typography
                      variant="body-small"
                      color={theme.palette.lightBg.medium}
                    >
                      Expires {card?.card?.exp_month}/{card?.card?.exp_year}
                    </Typography>
                  </Stack>

                  <Stack>
                    {isDefault(card?.id) && (
                      <Typography
                        variant="caption-xs"
                        sx={{
                          padding: "4px 12px 4px 8px",
                          borderRadius: "4px",
                          backgroundColor: theme.palette.neutral["020"],
                          width: "max-content",
                        }}
                      >
                        Default
                      </Typography>
                    )}

                    {/* <Button
                    onClick={async () => {
                      setIsDefaultCardModalOpen(card.id);
                    }}
                    variant="ghost"
                    size="small"
                    isResponsive={false}
                    disabled={defaultCardId === card?.id}
                  >
                    {defaultCardId !== card?.id
                      ? "Set as default"
                      : "Default card"}
                  </Button> */}
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  {!isDefault(card?.id) && (
                    <ThreeDotsMenu
                      deleteCard={() => setIsDeleteCardModalOpen(card.id)}
                      setAsDefault={() => setIsDefaultCardModalOpen(card.id)}
                    />
                  )}

                  {/* {defaultCardId !== card?.id && (
                  <IconButton onClick={() => setIsDeleteCardModalOpen(card.id)}>
                    <DeleteIcon />
                  </IconButton>
                )} */}
                </Stack>
              </Stack>
            );
          })}

          <Button
            sx={{ mt: "32px", mb: "16px", mx: "32px" }}
            variant="secondary"
            onClick={handleAddCardClick}
            loading={loadingSetupIntent}
          >
            Add payment method
          </Button>
          {isAddCardsOpen && setupIntentId && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: setupIntentId,
              }}
            >
              <AddPaymentMethod
                open={isAddCardsOpen}
                onClose={() => setIsAddCardsOpen(false)}
                onPaymentAdded={(id) => {
                  setDefaultCardId(id);
                  handleSetAsDefault(id);
                }}
              />
            </Elements>
          )}
        </Stack>
      </Stack>

      {isDeleteCardModalOpen && (
        <DeleteConfirmationModal
          onClose={() => setIsDeleteCardModalOpen(null)}
          onSecondaryButtonClick={() => setIsDeleteCardModalOpen(null)}
          onPrimaryButtonClick={async () => {
            await deleteCard(isDeleteCardModalOpen);
            setIsDeleteCardModalOpen(null);
          }}
          title="Are you sure?"
          paperStyles={{
            width: "520px",
          }}
          open={isDeleteCardModalOpen ? true : false}
          secondaryButtonText="Cancel"
          primaryButtonText="Delete"
          tagline={"Are you sure you want to delete this card"}
          isPrimaryButtonDisabled={deletingCard}
        />
      )}

      {isDefaultCardModalOpen && (
        <DeleteConfirmationModal
          type="primary"
          onClose={() => setIsDefaultCardModalOpen(null)}
          onSecondaryButtonClick={() => setIsDefaultCardModalOpen(null)}
          onPrimaryButtonClick={async () => {
            await handleSetAsDefault(isDefaultCardModalOpen);
            // setDefaultCard(isDefaultCardModalOpen);
            setIsDefaultCardModalOpen(null);
          }}
          title="Are you sure you want this as your default card?"
          paperStyles={{
            width: "520px",
          }}
          open={isDefaultCardModalOpen ? true : false}
          secondaryButtonText="Cancel"
          primaryButtonText="Confirm"
          tagline={
            "By confirming, you're selecting this card as your default for all future transactions"
          }
          isPrimaryButtonDisabled={isDefaultCardLoading}
        />
      )}
    </Stack>
  );
};

const ThreeDotsMenu = ({
  setAsDefault,
  deleteCard,
}: {
  setAsDefault: () => void;
  deleteCard: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuList = [
    {
      title: "Set as default",
      onClick: setAsDefault,
    },
    {
      title: "Delete",
      onClick: deleteCard,
    },
  ];
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Vertical3DotsIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "140px",
            bgcolor: "#FFF",
            border: `1px solid ${theme.palette.neutral["020"]}`,
            borderRadius: "8px",
            boxShadow: "none",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuList.map((menu) => (
          <MenuItem
            key={menu.title}
            sx={{
              my: "6px",
              // mx: "12px",
              py: "8px",
              px: "10px",
              textTransform: "capitalize",
              ...theme.typography["body-small"],
            }}
            onClick={menu.onClick}
          >
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
