import { Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { ChartProps, LegendBuilder, TickLabel, barChartColor } from "../Utils";
import {
  ResponsiveContainer,
  YAxis,
  Legend,
  Tooltip,
  XAxis,
  CartesianGrid,
  BarChart,
  Bar,
} from "recharts";
import { useState } from "react";

export const StackedBarChart = ({
  data = [],
  labelKey = "label",
  valueKey = "value",
  width,
  height,
  customTooltip,
  isLoading = false,
  showLegends = true,
  showAxisLines = false,
  showGrid = false,
}: ChartProps) => {
  const theme = useTheme();

  const [toolTipIndex, setToolTipIndex] = useState(-1);

  const chartData = data.flatMap((entry) => {
    const entrydata: Record<string, any> = {
      title: entry.title,
    };
    entry.data.map(
      (value: any, index: number) =>
        (entrydata[`element_${index}`] = value.value)
    );

    return entrydata;
  });

  const getMinData = () => {
    try {
      return data
        .map((item, index) => ({
          index: index,
          size: item.data.length,
        }))
        .reduce((a, b) => (a.size < b.size ? a : b));
    } catch (e) {
      return { index: -1, size: 0 };
    }
  };

  const minData = getMinData();
  //   console.log("🚀 ~ minLength:", minData);
  //   console.log("🚀 ~ chartData ~ chartData:", chartData, minData);

  return isLoading ? (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        // Replace with your desired box background color
        width: "100%",
        height: "100%",
        gap: "8px",
      }}
    >
      <Stack
        flex={1}
        direction={"row"}
        width={"100%"}
        columnGap={"16px"}
        justifyContent={"baseline"}
        alignItems={"end"}
      >
        <Skeleton
          sx={{
            flex: 1,
            height: "100%",
            WebkitTransform: "unset",
            clipPath:
              " polygon(52% 57%, 77% 78%, 100% 34%, 100% 100%, 0 100%, 0 0, 26% 94%)",
          }}
        />
      </Stack>

      <Stack
        direction={"row"}
        height={"32px"}
        width={"100%"}
        overflow={"clip"}
        gap={"16px"}
      >
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} height={"100%"} width={"80%"} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        width={500}
        height={300}
        data={chartData}
        maxBarSize={40}
        margin={{
          top: 20,
          right: 20,
          left: 10,
          bottom: 5,
        }}
      >
        {showGrid && (
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme.palette.neutral["010"]}
          />
        )}
        <YAxis
          width={40}
          padding={{ bottom: 0 }}
          tickCount={4}
          tickLine={false}
          axisLine={showAxisLines}
          //   dataKey={valueKey}
          tick={<TickLabel isXaxis={false} />}
          allowDecimals={false}
          stroke={theme.palette.neutral["010"]}
        />
        <XAxis
          dataKey={labelKey}
          tickLine={false}
          axisLine={showAxisLines}
          fontSize={theme.typography["body-small"].fontSize}
          fontWeight={theme.typography["body-small"].fontWeight}
          fontFamily={theme.typography["body-small"].fontFamily}
          color="black"
          allowDataOverflow={false}
          tick={<TickLabel isXaxis={true} />}
          stroke={theme.palette.neutral["010"]}
        />

        <Tooltip
          content={
            customTooltip || (
              <CustomTooltip
                labelKey={labelKey}
                valueKey={valueKey}
                index={toolTipIndex}
              />
            )
          }
          cursor={false}
        />

        {minData.index >= 0 &&
          data[minData.index].data.map((entry: any, index: number) => {
            //   console.log("🚀 ~ {chartData.map ~ entry:", entry);
            return (
              <Bar
                key={index}
                dataKey={`element_${index}`}
                stackId={"stack_bar_id"}
                //   data={entry}
                name={entry?.label}
                fill={entry?.color}
                onMouseLeave={() => setToolTipIndex(index)}
                onMouseOver={() => setToolTipIndex(index)}
              />
            );
          })}
        {showLegends && (
          <Legend
            content={LegendBuilder({
              data: data[minData.index].data,
              labelKey: "label",
              color: barChartColor,
            })}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload, label, labelKey = "", valueKey = "", index } = props;

  if (active && payload && payload.length && index >= 0) {
    return (
      <Stack
        direction={"row"}
        sx={{
          backgroundColor: "white",
          padding: "8px 16px",
          borderRadius: "8px",
          border: "2px solid #10172E",
          boxShadow: "-8px 8px 14px 0px rgba(0, 0, 0, 0.24)",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <Typography variant="body-small" sx={{ textTransform: "capitalize" }}>
          {`${payload[index].name}`}
        </Typography>
        <Typography variant="body-small" sx={{ textTransform: "capitalize" }}>
          {":"}
        </Typography>
        <Typography variant="button-small" sx={{ textTransform: "capitalize" }}>
          {` ${payload[index].value}`}
        </Typography>
      </Stack>
    );
  }

  return null;
};
