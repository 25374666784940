import { mspServices } from "./msp-services";
import { locationServices } from "./locations-services";
import { securityServices } from "./security";
import { notificationServices } from "./notification-services";
import { conversationServices } from "./conversation-services";
import { whiteLabelServices } from "./white-label-services";
import { configurationService } from "./configuration-service";
import { resourcesService } from "./resource-services";
export const controlCenterservices = {
  mspServices,
  locationServices,
  securityServices,
  notificationServices,
  conversationServices,
  whiteLabelServices,
  configurationService,
  resourcesService
};