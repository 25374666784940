import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ReturnContext } from "../../../../../../contexts/return-context";
import { useGlobalStore } from "controller/store/globalStore";
import {
  DataTable,
  DeleteConfirmationModal,
  DeleteIcon,
  HomeScreenLayout,
} from "components";
import {
  useCreateConfigChecklist,
  useDeleteConfigCheckList,
  useGetAllChecklists,
  useGetConfigCheckListByIdUsingMutation,
  useUpdateConfigChecklist,
} from "controller";
import { NewCheckListModal } from "./components/new-checklist-modal";
import { CheckListHeaderComponent } from "./components/CheckListHeaderComponent";

const headers = [
  {
    key: "checklist_title",
    name: "Checklist Name",
    sortable: false,
  },
  { key: "header_action", name: "", sortable: false },
];

export const ServiceDeskConfigurationChecklistsComponent = () => {
  const mspId = useGlobalStore((state) => state.mspId);
  const { setEnableReturn } = useContext<any>(ReturnContext);
  const modalRef = useRef<any>();
  const [checklistModal, setChecklistModal] = useState({
    isModalOpen: false,
    selectedData: {},
  });

  const [pagination, setPagination] = useState({ current: 1, searchQuery: "" });
  const { mutateAsync: createChecklist } = useCreateConfigChecklist();
  const { mutateAsync: updateChecklist } = useUpdateConfigChecklist();
  const { mutateAsync: deleteChecklist } = useDeleteConfigCheckList();
  const { mutateAsync: getChecklistById } =
    useGetConfigCheckListByIdUsingMutation();
  const { data: checklists, refetch } = useGetAllChecklists({
    filter: [],
    pageNumber: 1,
    perPageCount: 50,
    searchQuery: "",
    sortColumn: "id",
    sortDirection: "asc",
  });

  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    data: "",
  });

  useEffect(() => {
    setEnableReturn(true);
  }, []);

  const createChecklistHandler = async (data: any) => {
    try {
      await createChecklist(data);
      resetSeachAction();
      setChecklistModal({
        isModalOpen: false,
        selectedData: {},
      });
      toast.success("Your checklist is ready");
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to create at the moment. Please try again."
      );
    }
  };

  const updateChecklistHandler = async (checkListId: string, data: any) => {
    try {
      await updateChecklist({
        checklistId: checkListId,
        ...data,
      });
      resetSeachAction();
      setChecklistModal({
        isModalOpen: false,
        selectedData: {},
      });
      toast.success("Checklist updated");
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to update at the moment. Please try again."
      );
    }
  };

  const deleteChecklistHandler = async (checklistId: string) => {
    try {
      await deleteChecklist(checklistId);
      toast.success("Checklist deleted");
      resetSeachAction();
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to delete at the moment. Please try again."
      );
    }
  };

  const fetchChecklistById = async (id: string) => {
    try {
      const response = await getChecklistById(id);
      if (response) {
        return setChecklistModal({
          isModalOpen: true,
          selectedData: response,
        });
      }
      throw new Error();
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to fetch data at the moment. Please try again."
      );
    }
  };

  const handleModalVisibility = (isVisible: boolean) => {
    setChecklistModal({
      isModalOpen: isVisible,
      selectedData: {},
    });
  };

  const onSubmitModal = () => {
    const data = modalRef?.current?.submit?.();

    if (
      data?.checklist_title?.length <= 0 ||
      data.checklist_items.length <= 0
    ) {
      toast.error("Error : Empty field");
      return;
    }

    for (const item of data.checklist_items) {
      if (item?.checklist_item_name?.length <= 0) {
        toast.error("Error : Empty field");
        return;
      }
    }

    if (
      data.mapping_id !== undefined &&
      data.mapping_id !== null &&
      data.mapping_id !== ""
    ) {
      const { mapping_id, ...rest } = data;
      updateChecklistHandler(mapping_id, rest);
      return;
    }
    createChecklistHandler(data);
  };

  const handleSearchAction = (data: string) => {
    setPagination({
      current: 1,
      searchQuery: data,
    });
  };

  const resetSeachAction = () => {
    setPagination({
      current: 1,
      searchQuery: "",
    });
  };

  const onDeleteHandler = (id: string) => {
    setDeleteModalState({
      open: true,
      data: id,
    });
  };

  const onPagination = (index: number) => {
    setPagination((oldPagination) => ({ ...oldPagination, current: index }));
  };

  return (
    <>
      {deleteModalState.open && (
        <DeleteConfirmationModal
          open={deleteModalState.open}
          onClose={() =>
            setDeleteModalState({
              open: false,
              data: "",
            })
          }
          onSecondaryButtonClick={() =>
            setDeleteModalState({
              open: false,
              data: "",
            })
          }
          onPrimaryButtonClick={() => {
            deleteChecklistHandler(deleteModalState.data || "");
            setDeleteModalState({
              open: false,
              data: "",
            });
          }}
        />
      )}

      {checklistModal.isModalOpen && (
        <NewCheckListModal
          ref={modalRef}
          open={checklistModal.isModalOpen}
          selectedData={checklistModal.selectedData}
          editable={true}
          onSubmit={onSubmitModal}
          onClose={() => handleModalVisibility(false)}
          isNewChecklist={true}
        />
      )}

      <HomeScreenLayout
        title="Checklists"
        rightActionComponent={
          <CheckListHeaderComponent
            createNewHandler={() => handleModalVisibility(true)}
            updateSearchHandler={handleSearchAction}
            resetSearchHandler={resetSeachAction}
          />
        }
      >
        <DataTable
          headers={headers}
          data={Array.isArray(checklists?.result) ? checklists?.result : []}
          multiselect={false}
          selectedItems={[]}
          currentPageNumber={pagination.current}
          totalPages={checklists?.totalPages || 0}
          totalItems={checklists?.totalCount || 0}
          itemsPerPage={50}
          showPagination={true}
          handleRowClick={(rowData: any) =>
            fetchChecklistById(rowData?.mapping_id)
          }
          onPagination={onPagination}
          containerStyles={{
            flex: 1,
            px: "32px",
          }}
          cellBuilders={{
            header_action: (rowData?: any) => (
              <Box width={"100px"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& :hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteHandler(rowData?.mapping_id);
                  }}
                >
                  <DeleteIcon />
                </Box>
              </Box>
            ),
          }}
          cellProps={{
            header_action: {
              sx: {
                width: "100px",
              },
            },
          }}
        />
      </HomeScreenLayout>
    </>
  );
};
