import { Observable, BehaviorSubject } from "rxjs";
import { appConfig } from "../../constants";
import { authAxiosClient } from "utilz/axios";

const loaderObservable = new BehaviorSubject(false);

export const authCall = (
  url = "",
  method = appConfig.requestMethods.GET,
  data = {},
  header = {},
  isFormData = false
) => {
  // Options
  let options = {
    url: url,
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Checking if call is a GET call to add body
  if (method !== appConfig.requestMethods.GET) {
    options = {
      ...options,
      body: isFormData ? data : data,
    };
  }

  if (isFormData === true) {
    delete options.headers["Content-Type"];
    options.headers["redirect"] = "follow";
  }

  return Observable.create((observer) => {
    try {
      loaderObservable.next(true);
      authAxiosClient[options.method](options.url, options.body)
        .then((body) => {
          loaderObservable.next(false);
          observer.next(body.data);
          observer.complete();
        })
        .catch((err) => {
          observer.error(err);
          loaderObservable.next(false);
        });
    } catch (error) {
      loaderObservable.error(false);
      observer.error(error);
    }
  });

  // return Observable.create((observer)=> {
  //     try {
  //         loaderObservable.next(true);
  //         const finalUrl = appConfig.server['baseUrl']+ url;
  //         fetch(finalUrl, options)
  //             .then((res)=> {
  //                 if(
  //                     res.headers.get("Content-Type") &&
  //                     res.headers.get("Content-Type")?.includes("application/json")
  //                 ) {
  //                     try {
  //                         return res.json();
  //                     }catch(samp) {
  //                         console.log(samp);
  //                     }
  //                 }else {
  //                     return res.blob();
  //                 }
  //             })
  //             .then((body)=> {
  //                 loaderObservable.next(false);
  //                 observer.next(body);
  //                 observer.complete();
  //                 // TODO status code
  //             })
  //             .catch((err)=> {
  //                 observer.error(err);
  //                 loaderObservable.next(false);
  //             });
  //     } catch(error) {
  //         loaderObservable.error(false);
  //         observer.error(error);
  //     }
  // });
};
