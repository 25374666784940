import { CustomCard } from "../../../../../../../../../library/layers";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { services } from "../../../../../../../../../services";
import { Box, Stack } from "@mui/material";
import { CustomTypography } from "../../../../../../../../../library/base";
import { CustomCheckBox } from "../../../../../../../../../library/system/custom-form";

interface ChecklistLibraryItemProps {
  checklist: any;
  onSelectionChange: (isSelected: boolean) => void;
  isSelected: boolean;
}
export const ChecklistLibraryItem = ({
  onSelectionChange,
  ...props
}: ChecklistLibraryItemProps) => {
  const [state, setState] = useState<any>({
    data: {},
    isSelected: false,
  });

  useEffect(() => {
    setState({
      data: props.checklist,
      isSelected: props.isSelected,
    });
  }, [props]);

  const onChangeHandler = (isSelected: boolean) => {
    setState({
      ...state,
      isSelected: isSelected,
    });
    onSelectionChange(isSelected);
  };

  return (
    <Stack
      sx={{
        py: "24px",
        width: "100%",
        height: "250px",
        gap: "8px",
        borderRadius: "16px",
        border: `1px solid ${
          state.isSelected === true ? "#5563F5" : "#CCCCCC"
        }`,
        backgroundColor: state.isSelected === true ? "#EBFCFE" : "white",
        transition: "background-color 100ms linear,border 100ms linear",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          px: "16px",
          gap: "16px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomTypography
          content={state?.data?.checklist_title || ""}
          size="SMALL-ALPHA"
          variant="MEDIUM"
        />
        <CustomCheckBox
          checked={state.isSelected === true}
          changeHandler={onChangeHandler}
        />
      </Stack>
      <Box
        sx={{
          height: "100%",
          pb: "16px",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            px: "16px",
            overflow: "scroll",
            // backgroundColor: 'red',
          }}
        >
          {(state.data.items || []).map((item: any) => (
            <Box
              key={`checklists-checklist-item-${item.item_id}`}
              sx={{
                py: "16px",
                borderBottom: "1px solid #cccccc",
              }}
            >
              <CustomTypography
                content={item.checklist_item_name || ""}
                size="EXTRA-SMALL"
                variant="REGULAR"
              />
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};
ChecklistLibraryItem.propTypes = {
  checklist: PropTypes.object.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
ChecklistLibraryItem.defaultProps = {
  checklist: {},
  isSelected: false,
  onSelectionChange: () => {},
};

export default ChecklistLibraryItem;
