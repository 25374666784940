import { Stack, Typography } from "@mui/material";

export const CustomTooltip = (props: any) => {
  const { active, payload, label, labelKey = "", valueKey = "" } = props;
  if (active && payload && payload.length) {
    return (
      <Stack
        direction={"row"}
        sx={{
          backgroundColor: "white",
          padding: "8px 16px",
          borderRadius: "8px",
          border: "2px solid #10172E",
          boxShadow: "-8px 8px 14px 0px rgba(0, 0, 0, 0.24)",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <Typography variant="body-small" sx={{ textTransform: "capitalize" }}>
          {`${payload[0].payload[labelKey]}`}
        </Typography>
        <Typography variant="body-small" sx={{ textTransform: "capitalize" }}>
          {":"}
        </Typography>
        <Typography variant="button-small" sx={{ textTransform: "capitalize" }}>
          {` ${payload[0].payload[valueKey]}`}
        </Typography>
      </Stack>
    );
  }

  return null;
};
