import {
  Chip as MuiChip,
  ChipProps,
  useTheme,
  SxProps,
  Theme,
} from "@mui/material";
import { CloseIcon } from "components/icons";
import React from "react";

interface CustomChipProps extends ChipProps {
  sx?: SxProps<Theme>;
}
export const Chip = ({ sx, ...rest }: CustomChipProps) => {
  const theme = useTheme();
  return (
    <MuiChip
      size="medium"
      deleteIcon={
        <CloseIcon fontSize="small" htmlColor={theme.palette.lightBg.high} />
      }
      sx={{
        border: `1px solid ${theme.palette.neutral["010"]}`,

        borderRadius: "8px",
        "&.MuiChip-root": {
          padding: "10px",
          height: "auto",
          color: theme.palette.lightBg.high,
          ...theme.typography["body-medium"],
        },

        "& .MuiChip-icon": {
          color: theme.palette.lightBg.high,
        },
        "& .MuiChip-deleteIcon": {
          color: theme.palette.lightBg.high,
        },
        ...sx,
      }}
      {...rest}
    ></MuiChip>
  );
};
