import { Box } from "@mui/material";
import { Error404Component } from "components";

export const Error404Screen = () => {
  return (
    <Box width={"100vw"} height={"100vh"}>
      <Error404Component />
    </Box>
  );
};
