import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  useGetUpcomingActivities,
  useGlobalStore,
  useMainStore,
} from "controller";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { convertUTCtoTimezone, isoToTime } from "utilz/helpers";
// @ts-ignore
import searchWebImg from "assets/images/chartImgs/searchWebImg.svg";
export const UpcomingActivities = () => {
  const timezone = useGlobalStore((state) => state.timezone);
  const theme = useTheme();
  const resourceId = useGlobalStore((state) => state.resourceId);

  const {
    data: upcomingActivities,
    isLoading,
    refetch,
  } = useGetUpcomingActivities(resourceId);

  useEffect(() => {
    if (resourceId) {
      refetch();
    }
  }, [resourceId, timezone]);

  return (
    <Stack
      zIndex={2}
      sx={{
        mt: "32px",
        maxWidth: "360px",
        width: "30%",
        borderRadius: "16px",
        height: "calc(100% - 32px)",
        bgcolor: theme.palette.neutral.main,
      }}
    >
      <Typography variant="h5" p={"24px"}>
        Upcoming Activities
      </Typography>
      <Stack
        width={"100%"}
        height={"100%"}
        flex={1}
        sx={{
          overflowY: "scroll",
          px: "16px",
        }}
      >
        {typeof upcomingActivities === "object" &&
        Object.entries(upcomingActivities).length > 0
          ? Object.entries(upcomingActivities).map(([key, value], index) => (
              <ActivityComponent
                key={index}
                activities={Array.isArray(value) ? value : []}
                date={key}
              />
            ))
          : !isLoading && (
              <Stack
                width={"100%"}
                height={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    flex: "1",
                    maxHeight: "160px",
                    backgroundImage: `url(${searchWebImg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                  }}
                  width={"100%"}
                  position={"relative"}
                ></Box>
                <Typography
                  variant="body-medium"
                  width={"100%"}
                  textAlign={"center"}
                  pt={"16px"}
                >
                  Enjoy the calm!
                  <br />
                  No upcoming activities scheduled.
                </Typography>
              </Stack>
            )}
      </Stack>
    </Stack>
  );
};

const ActivityComponent = ({
  date = "",
  activities = [],
}: {
  date: string;
  activities: Array<any>;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const timezone = useGlobalStore((state) => state.timezone);
  const convertDate = (timestamp: string | null) => {
    try {
      const formattedDate = timestamp ? dayjs(date).tz(timezone) : dayjs(date);
      return formattedDate.format("MMM DD");
    } catch (e) {
      return "";
    }
  };

  const PRIORITY_COLORS: Record<string, string> = {
    critical: "#DA1E28",
    high: "#FF832B",
    medium: "#F1C21B",
    low: "#A5A5A6",
  };

  return (
    <Stack width={"100%"} direction={"row"} gap={"10px"}>
      <Box width={"55px"}>
        <Typography variant={"caption-2"}>
          {convertDate(convertUTCtoTimezone(date))}
        </Typography>
      </Box>
      <Stack
        sx={{
          flex: 1,
          gap: "8px",
        }}
      >
        {activities.map((activity, index) => (
          <Box
            key={`upcoming_activity_${activity.ticket_id}_${index}`}
            sx={{
              width: "100%",
              border: `1px solid ${theme.palette.neutral["015"]}`,
              borderRadius: "8px",
              overflow: "clip",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              if (activity.ticket_id) {
                navigate(`/app/service-desk/tickets/${activity.ticket_id}`);
              }
            }}
          >
            <Stack
              sx={{
                borderLeft: `4px solid ${
                  PRIORITY_COLORS[activity.priority] || "white"
                }`,
                bgcolor: theme.palette.darkBg.main,
                p: "8px",
              }}
            >
              <Typography
                variant={"body-small"}
                color={theme.palette.lightBg.low}
              >
                {isoToTime(activity.start_time, timezone)} -{" "}
                {isoToTime(activity.end_time, timezone)}
              </Typography>
              <Typography variant={"body-medium"}>
                {activity?.ticket_summary || ""}
              </Typography>
            </Stack>
          </Box>
        ))}
        <Box height={"8px"} />
      </Stack>
    </Stack>
  );
};
