import {
  UpdateMspInput,
  UpdateMspCustomNameInput,
  UpdateMspBillingInput,
} from "shared";
import { axiosClient } from "utilz";
import { apiErrorHandler, getMspId } from "utilz/helpers";

export const getMspById = async () => {
  try {
    const url = "/v2/control-center/msp";

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};

const updateMsp = async (data: UpdateMspInput) => {
  try {
    const url = "/v2/controlCenter/msp";
    const response = await axiosClient.patch(`${url}`, data);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

const updateMspCustomName = async (data: UpdateMspCustomNameInput) => {
  try {
    const url = `/controlCenter/update/custom_name/msp/${getMspId()}`;
    const response = await axiosClient.patch(`${url}`, data);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateMspBilling = async ({
  mspId,
  data,
}: UpdateMspBillingInput) => {
  try {
    const url = "/controlCenter/msp_billing";

    const payload = {
      ...data,
      line1: data.billingAddressLine1,
      line2: data.billingAddressLine2,
      billing_country: data.billingCountry,
      billing_state: data.billingState,
      billing_city: data.billingCity,
      billing_postal_code: data.billingZipCode,
    };

    delete payload.billingAddressLine1;
    delete payload.billingAddressLine2;

    const response = await axiosClient.patch(`${url}`, payload);
    const responseData = response.data;
    if (responseData.success && responseData.statusCode === 200) {
      return responseData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getMspBillingDetails = async (id: string) => {
  try {
    const url = "/controlCenter/msp_billing";

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};

export const updateMspLogo = async ({ file }: { file: any }) => {
  try {
    const url = "/v2/control-center/msp_logo";
    const response = await axiosClient.patch(url, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    const resdata = response.data;
    if (resdata?.statusCode === 200 && resdata?.success === true) {
      return resdata;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteMspLogo = async () => {
  try {
    const url = "/v2/control-center/msp_logo";
    const response = await axiosClient.delete(url);
    const resdata = response.data;
    if (resdata?.statusCode === 200 && resdata?.success === true) {
      return resdata;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export { updateMsp, updateMspCustomName };
