import { Box, Stack } from "@mui/material";
import { DataTable, DownloadIcon, FileIcon } from "components";
import { useGetAllInvoice } from "controller";
import React, { useCallback } from "react";
import { dayjs } from "utilz";

const InvoiceHeaders = [
  {
    name: "Invoice",
    sortable: false,
    key: "id",
  },
  {
    name: "Billing Date",
    sortable: false,
    key: "billingDate",
  },
  {
    name: "Amount",
    sortable: false,
    key: "amount",
  },
  {
    name: "",
    sortable: false,
    key: "actions",
  },
];

const cellBuilders = {
  id: (rowData: any) => {
    return (
      <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
        <FileIcon type="application/pdf" />
        Invoice #{rowData?.number}
      </Stack>
    );
  },
  billingDate: (rowData: any) => {
    return <span>{dayjs.unix(rowData?.created).format("LL")}</span>;
  },
  amount: (rowData: any) => {
    return <span>USD ${rowData?.amount_paid / 100}</span>;
  },
  actions: (rowData: any) => {
    return (
      <span>
        <DownloadIcon onClick={() => {}} />
      </span>
    );
  },
};
export const InvoiceTab = () => {
  const { data: invoices, isLoading } = useGetAllInvoice();

  const filteredData = useCallback(() => {
    return (
      invoices?.data.filter(
        (invoice: Record<string, any>) =>
          invoice?.status === "paid" || invoice?.status === "open"
      ) || []
    );
  }, [invoices?.data]);

  return (
    <Box sx={{ width: "100%", px: "32px", py: "24px" }}>
      <DataTable
        isDataLoading={isLoading}
        cellBuilders={cellBuilders}
        headers={InvoiceHeaders}
        data={filteredData() || []}
        selectedItems={[]}
        currentPageNumber={0}
        multiselect={false}
        itemsPerPage={0}
        handleRowClick={(rowData: any) => {
          window.open(rowData?.invoice_pdf);
        }}
      />
    </Box>
  );
};
