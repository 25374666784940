export const getResponsiveRows = ()=> {
  const width = window.screen.width;
  try{
    if (width <= 1024) {
      return 3;
    }
    if (width > 1024 && width <= 1440) {
      return 5;
    }
    if (width > 1440 && width <= 1604) {
      return 7;
    }
    if (width > 1604) {
      return 9;
    }
  }catch(err) {
    console.log("[ERROR] Getting Responsive number of rows for textfield");
    console.log(err);
  }
};