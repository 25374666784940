import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createProductConfig,
  deleteProductConfig,
  getAllProductConfigs,
  updateProductConfig,
} from "controller";
import { GetQueryProps } from "shared";

export const useGetAllProductConfigs = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["productConfigs", perPageCount, pageNumber, filter, searchQuery],
    queryFn: () =>
      getAllProductConfigs({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error) => console.log(error),
  });
};

export const useCreateProductConfig = () => {
  const queryClient = useQueryClient();
  //   return useMutation(createProductConfig, {
  return useMutation({
    mutationFn: (data: any) => createProductConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};

export const useDeleteProductConfig = () => {
  const queryClient = useQueryClient();
  //   return useMutation(deleteProductConfig, {
  return useMutation({
    mutationFn: (data: { product_ids: string[] }) => deleteProductConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};

export const useUpdateProductConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => updateProductConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};
