import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createSla,
  deleteSlaPolicies,
  getAllSla,
  getSlaRules,
  updateSla,
} from "controller/api";
import {
  ApiErrorType,
  CreateSlaInput,
  GetQueryProps,
  GetSlaRulesInput,
  UpdateSlaInput,
} from "shared";
import { sentryLogger } from "utilz";
interface CustomSlaProps extends GetQueryProps {
  enabled?: boolean;
}
export const useGetAllSla = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
  enabled = true,
}: CustomSlaProps) => {
  return useQuery({
    queryKey: ["sla", searchQuery],
    enabled: enabled,
    queryFn: () =>
      getAllSla({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllSla",
      }),
  });
};

export const useGetSlaRules = ({ slaId }: GetSlaRulesInput) => {
  return useQuery({
    queryKey: ["sla", slaId],
    queryFn: () => getSlaRules({ slaId }),
    enabled: slaId ? true : false,
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getSlaRules",
      }),
  });
};

export const useFetchSlaByIdUsingMutation = () => {
  return useMutation((slaId: string) => getSlaRules({ slaId }), {
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getSlaRules",
      });
    },
  });
};

export const useCreateSla = () => {
  const queryClient = useQueryClient();
  return useMutation((data: CreateSlaInput) => createSla(data), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["sla"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "createSla",
      });
    },
  });
};

export const useUpdateSla = () => {
  const queryClient = useQueryClient();
  return useMutation((data: UpdateSlaInput) => updateSla(data), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["sla"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateSla",
      });
    },
  });
};

export const useDeleteSlaPolicies = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteSlaPolicies,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sla"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteSlaPolicies",
      });
    },
  });
};
