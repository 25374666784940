import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { BellIcon, Button, CloseIcon } from "components";
import { useGlobalStore } from "controller";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dayjs } from "utilz";
import { debugConsole } from "utilz/helpers";

export const TrialPeriodBanner = ({
  trialEndDate,
}: {
  trialEndDate?: string | null;
}) => {
  const trialEnd = useGlobalStore((state) => state.trialEndDate);
  const role = useGlobalStore((state) => state.role);
  const dateFormat = useGlobalStore((state) => state.dateFormat);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    checkForTrial();
  }, []);

  const checkForTrial = () => {
    const trialBanner = localStorage.getItem("trialBanner");
    if (!trialBanner) {
      setShow(true);
    } else {
      const { date } = JSON.parse(trialBanner);
      if (dayjs(date).isToday()) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  };
  const handleOnClose = () => {
    const date = dayjs().toDate();
    localStorage.setItem("trialBanner", JSON.stringify({ date }));
    setShow(false);
  };

  const getContent = () => {
    const difference = dayjs(trialEndDate || trialEnd).diff(dayjs(), "day");
    // if (difference <= 10) {
    //   return (
    //     <>
    //       Heads up! Your trial is wrapping up in{" "}
    //       <Typography component={"span"} variant="subtitle1">
    //         {difference}{" "}
    //       </Typography>
    //       days.
    //     </>
    //   );
    // } else {
    //   return (
    //     <>
    //       Your  trial ends on
    //       <Typography component={"span"} variant="subtitle1">
    //         {trialEndDate || trialEnd
    //           ? ` ${dayjs(trialEndDate ? trialEndDate : trialEnd).format(
    //               "DD-MMM-YYYY"
    //             )}`
    //           : " soon"}
    //       </Typography>
    //     </>
    //   );
    // }

    return (
      <>
        <Typography component={"span"} variant="subtitle1">
          Your trial is wrapping up in {difference + 1} days
          {role === "SUPERADMIN" ? "." : ", contact your admin."}
        </Typography>
      </>
    );
  };
  if (!show) return null;
  if (dayjs().isAfter(trialEndDate || trialEnd)) return null;
  return (
    <Box>
      <Stack
        sx={{
          backgroundColor: theme.palette["warning-minor"].tint,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          p: "4px 32px",
          position: "relative",
        }}
      >
        <Stack direction={"row"} gap={"16px"}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.0438 15.9145C17.0039 15.9145 17.7831 15.1377 17.7862 14.1776C17.7856 13.689 17.5906 13.2208 17.2442 12.8763L16.0896 11.7208V8.28828C16.0894 6.9391 15.5529 5.64533 14.5983 4.69192C13.6436 3.7385 12.3492 3.20362 11 3.20508C8.19357 3.2066 5.91915 5.48184 5.91865 8.28828V11.718L4.76408 12.8735C4.41747 13.218 4.22214 13.6862 4.22119 14.1748C4.22422 15.135 5.00343 15.9117 5.96358 15.9117L16.0438 15.9145Z"
              fill="#EEBF17"
              stroke="#EEBF17"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.64355 18.7949H12.3553"
              stroke="#EEBF17"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Stack direction={"row"} gap={"4px"}>
            <Typography variant="body-medium">{getContent()}</Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={"16px"} alignItems={"center"}>
          {role === "SUPERADMIN" ? (
            <Button
              sx={{
                ":hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
              variant="ghost"
              size="small"
              onClick={() => {
                try {
                  navigate("/app/control-center/subscription");
                } catch (error) {
                  debugConsole(error);
                }
              }}
            >
              Buy now
            </Button>
          ) : (
            <Button
              sx={{
                ":hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
              size="small"
              variant="ghost"
              onClick={() => {
                // try {
                //   // @ts-ignore
                //   window.Intercom("show");
                // } catch (error) {
                //   debugConsole(error);
                // }
              }}
            ></Button>
          )}

          <IconButton
            onClick={handleOnClose}
            sx={{
              width: "20px",
              height: "20px",
              ":hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <CloseIcon
              sx={{ width: "20px", height: "20px" }}
              htmlColor={theme.palette.lightBg.high}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};
