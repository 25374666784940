import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Button, PlusIcon, SearchInput } from "components";
import { Tab, Tabs } from "components/tab";
import React from "react";

interface HomeScreenLayoutProps {
  children: React.ReactNode;
  containerStyle?: SxProps<Theme>;
  /**
   * @description tabs is an array of objects that will be used to render tabs
   */
  tabs?: Array<string>;
  tabValue?: number;
  onTabChange?: (newValue: number) => void;
  title?: string;
  /**
   * @description leftTitleComponent is a component that will be rendered on the left side in place of title
   */
  leftTitleComponent?: React.ReactNode;
  /**
   * @description rightTitleComponent is a component that will be rendered on the right side of title
   */
  rightActionComponent?: React.ReactNode;
  /**
   * @description additionalHeaderComponent is a component that will be rendered below the tabs
   */
  additionalHeaderComponent?: React.ReactNode;
  sx?: SxProps<Theme>;
  /**
   * @description hideFirstlayerHeaderComponent will hide the header component
   */
  hideFirstlayerHeaderComponent?: boolean;
  /**
   * @description rightActionComponentTabLayer is a component that will be rendered on the right side of tabs
   */
  rightActionComponentTabLayer?: React.ReactNode;
}
export const HomeScreenLayout = ({
  children,
  containerStyle,
  tabs,
  tabValue,
  onTabChange,
  title,
  leftTitleComponent,
  rightActionComponent,
  additionalHeaderComponent,
  sx,
  hideFirstlayerHeaderComponent = false,
  rightActionComponentTabLayer,
}: HomeScreenLayoutProps) => {
  const rightActionComponentTabLayerStyle = rightActionComponentTabLayer
    ? {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #E3E3E3",
      }
    : {};

  return (
    <Stack direction="column" sx={{ pt: "8px", height: "100%", ...sx }}>
      <Box sx={{ flexShrink: 0 }}>
        {!hideFirstlayerHeaderComponent && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ px: "32px", pb: "8px" }}
            textTransform={"capitalize"}
          >
            {leftTitleComponent ? (
              leftTitleComponent
            ) : (
              <Typography variant="h5">{title}</Typography>
            )}
            {rightActionComponent ? rightActionComponent : <Stack />}
          </Stack>
        )}
        {tabs && tabs.length > 0 && (
          <Box sx={rightActionComponentTabLayerStyle}>
            <Tabs
              sx={{ paddingLeft: "32px" }}
              value={tabValue}
              onChange={(e, value) => {
                onTabChange && onTabChange(value);
              }}
            >
              {tabs.map((tab, index) => (
                <Tab key={tab} label={tab} />
              ))}
            </Tabs>
            <Box sx={{ paddingRight: "32px" }}>
              {rightActionComponentTabLayer && rightActionComponentTabLayer}
            </Box>
          </Box>
        )}
        {additionalHeaderComponent && additionalHeaderComponent}
      </Box>
      <Stack sx={{ overflowY: "auto", flex: 1 }}>{children}</Stack>
    </Stack>
  );
};
