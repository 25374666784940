import {
  GetQaDetailedInput,
  GetQaFeedbackInput,
  GetQaHealthInput,
  GetQaScoreInput,
} from "shared";
import { axiosClient } from "utilz";
import { generateUrlString, getMspId, getQueryDataObject } from "utilz/helpers";
import { getTimeZone } from "utilz/helpers";

const BASE_URL = import.meta.env.VITE_APP_QA_BASE_URL;
export const getQaScore = async (data: GetQaScoreInput) => {
  const { resourceId, timePeriod, serviceType } = data;
  try {
    const url = `${BASE_URL}/qa_board/${serviceType}?resource_id=${JSON.stringify(
      resourceId
    )}&time_period=${timePeriod || "lastYear"}`;
    const response = await axiosClient.get(url);

    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData.data;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getQaDetailed = async (data: GetQaDetailedInput) => {
  const {
    resourceId,
    serviceType,
    // assignedOrClosed,
    qaHealthStatusFilter,
    timePeriod,
    boards,
    customers,
    page,
    limit,
  } = data;
  const selectedBoards = boards ? JSON.stringify(boards) : "";
  const selectedCustomers =
    customers?.length !== 0 ? JSON.stringify(customers) : "";
  try {
    const url = `${BASE_URL}/v1/qa_board/${serviceType}/all?resource_id=${JSON.stringify(
      resourceId
    )}&time_period=${
      timePeriod || "lastYear"
    }&page=${page}&board=${selectedBoards}&limit=${limit}&health=${qaHealthStatusFilter}`;
    const response = await axiosClient.get(url);
    const responseData = response?.data;
    if (
      response.status === 200 &&
      responseData.success === true &&
      responseData.statusCode === 200
    ) {
      return responseData?.data;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    console.error("getQaDetailed api error from catch", error);
    throw error;
  }
};

export const getQaFeedback = async ({
  timeInterval = "year",
  resourceId,
}: GetQaFeedbackInput) => {
  try {
    const filter = [
      { column: "resources.mapping_id", operator: "=", value: [resourceId] },
    ];
    const data = await getQueryDataObject({
      filter,
    });

    const url = await generateUrlString({
      baseUrl: `/controlCenter/feedback/ticket/${timeInterval}`,
      data,
    });
    // const url = `/controlCenter/feedback/ticket/${timeInterval}?filters=[{"column": "resources.mapping_id","operator": "=","value": [${resourceId}]}]`;
    const response = await axiosClient.get(`${url}`);

    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData.data;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getQaHealth = async ({
  serviceId,
  serviceType,
}: GetQaHealthInput) => {
  try {
    const url = `${BASE_URL}/qa_board/${serviceType}/qa_health?service_id=${serviceId}`;
    const response = await axiosClient.get(url);
    const resData = response?.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
  } catch (e) {
    throw e;
  }
};

export const getQaCustomerFeedbackForTickets = async (data: any) => {
  try {
    const url = `/v2/servicedesk/dashboard/feedback-count/ticket?filters={"resource_id":${JSON.stringify(
      data.resourceIds
    )}}&interval=${data?.interval}&time_zone=${getTimeZone()}`;
    const response = await axiosClient.get(url);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
