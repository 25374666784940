import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  cancelSubscription,
  createCheckoutSession,
  deleteCard,
  getActivePlans,
  getActiveResourceCount,
  getAllInvoice,
  getCards,
  getProratedAmount,
  getSetupIntent,
  getSubscriptionDetails,
  retryPayment,
  setDefaultCard,
  updateSubscription,
  validatePromoCode,
} from "controller/api";
import { ApiErrorType, CreateCheckoutSessionInput } from "shared";
import { sentryLogger } from "utilz";
import { debugConsole } from "utilz/helpers";

export const useGetActiveResourceCount = () => {
  return useQuery({
    queryKey: ["activeCount"],
    queryFn: () => getActiveResourceCount(),
    onError: (err) => debugConsole(err),
  });
};

export const useValidatePromoCode = () => {
  return useMutation(
    ({ code, planId }: { code: string; planId: string }) =>
      validatePromoCode({ code, planId }),
    {
      onSuccess(data, variables, context) {},
    }
  );
};

export const useCreateCheckoutSession = () => {
  return useMutation(
    (data: CreateCheckoutSessionInput) => createCheckoutSession(data),
    {
      onSuccess(data, variables, context) {},
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "subscription",
          functionName: "createCheckoutSession",
        });
      },
    }
  );
};

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateCheckoutSessionInput) => updateSubscription(data),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["subscriptionDetails"]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "subscription",
          functionName: "updateSubscription",
        });
      },
    }
  );
};

export const useSubscriptionDetails = () => {
  return useQuery({
    queryKey: ["subscriptionDetails"],
    queryFn: () => getSubscriptionDetails(),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "subscription",
        functionName: "getSubscriptionDetails",
      });
    },
  });
};

export const useGetAllInvoice = () => {
  return useQuery({
    queryKey: ["invoices"],
    queryFn: () => getAllInvoice(),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "subscription",
        functionName: "getAllInvoice",
      });
    },
  });
};

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation(() => cancelSubscription(), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["subscriptionDetails"]);
    },
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "subscription",
        functionName: "cancelSubscription",
      });
    },
  });
};

export const useGetProratedAmount = ({
  enabled,
  discountId,
  promoCode,
}: {
  discountId?: string;
  promoCode?: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: ["proratedAmount", promoCode, discountId],
    queryFn: () => getProratedAmount({ discountId, promoCode }),
    onError: (err) => debugConsole(err),
    enabled: enabled,
  });
};

export const useGetCards = () => {
  return useQuery({
    queryKey: ["cards"],
    queryFn: () => getCards(),
    onError: (err) => debugConsole(err),
  });
};

export const useDeleteCard = () => {
  const queryClient = useQueryClient();
  return useMutation((cardId: string) => deleteCard(cardId), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["cards"]);
    },
  });
};

export const useGetSetupIntent = () => {
  return useMutation(() => getSetupIntent(), {
    onSuccess(data, variables, context) {},
  });
};

export const useDefaultCard = () => {
  const queryClient = useQueryClient();
  return useMutation((paymentId: string) => setDefaultCard(paymentId), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["cards"]);
      // queryClient.invalidateQueries(["subscriptionDetails"]);
    },
  });
};

export const useRetryPayment = () => {
  const queryClient = useQueryClient();
  return useMutation(() => retryPayment(), {
    onSuccess(data, variables, context) {},
  });
};

export const useGetActivePlan = () => {
  return useQuery({
    queryKey: ["activePlans"],
    queryFn: () => getActivePlans(),
    onError: (err) => debugConsole(err),
  });
};
