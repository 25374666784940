import { SLATime } from "shared";
import { dayjs } from "../dayjs";
export const claculateTimeElapsed = (startTime: number) => {
  const totalHours = dayjs().diff(dayjs(startTime), "h");
  if (totalHours < 1) return `${dayjs().diff(dayjs(startTime), "m")}m`;
  if (totalHours < 24)
    return `${totalHours}h ${dayjs().diff(dayjs(startTime), "m") % 60}m`;
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  return `${days}d ${hours >= 1 ? `${hours}h` : ""}`;
};

export const claculateTimeInBetween = ({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}) => {
  const totalHours = dayjs(endTime).diff(dayjs(startTime), "h");
  if (totalHours < 1) return `${dayjs(endTime).diff(dayjs(startTime), "m")}m`;

  return `${totalHours}h ${dayjs(endTime).diff(dayjs(startTime), "m") % 60}m`;
};

export const convertTimeObjectToString = (timeObject: {
  hours?: number;
  minutes?: number;
  seconds?: number;
}) => {
  if (!timeObject.hours && !timeObject.minutes) return "0";
  let hours = timeObject.hours || 0;
  const minutes = timeObject.minutes || 0;
  if (hours > 24) {
    const days = Math.floor(hours / 24);
    hours = hours % 24;
    return `${days}d ${
      hours < 10 ? (hours > 0 ? `0${hours}h` : "") : `${hours}h`
    } ${minutes < 10 ? (minutes > 0 ? `0${minutes}m` : "") : `${minutes}m`}`;
  }
  return `${hours < 10 ? (hours > 0 ? `0${hours}h` : "") : `${hours}h`} ${
    minutes < 10 ? (minutes > 0 ? `0${minutes}m` : "") : `${minutes}m`
  }`;
};

export const parseTimeStringtoObject = (value: string): SLATime => {
  if (!value) return {};
  // if hour and minute
  const match =
    value.match(/(\d+)\s*hour[s]?\s*(\d*)\s*minute[s]?/i) ||
    value.match(/NaN\s+hour[s]?\s+NaN\s+minute[s]?/);
  if (match) {
    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    return { hours, minutes };
  }
  // if minute only
  const minutesMatch =
    value.match(/(\d+)\s*minute[s]?/i) || value.match(/NaN\s+minute[s]?/);
  if (minutesMatch) {
    const minutes = parseInt(minutesMatch[1], 10);
    return { minutes };
  }
  // if hour only
  const hoursMatch =
    value.match(/(\d+)\s*hour[s]?/i) || value.match(/NaN\s+hour[s]?/);
  if (hoursMatch) {
    const hours = parseInt(hoursMatch[1], 10);
    return { hours };
  }

  return {};
};

export const convertTimeObjectToDouble = (time: SLATime) => {
  if (time.hours && time.minutes) {
    const minutesInHours = time.minutes / 60;
    const totalHours = time.hours + minutesInHours;
    return totalHours;
  } else if (time.hours || time.minutes) {
    return time.hours || time.minutes;
  }
};

export const convertTimeObjectToMinutes = (time: SLATime) => {
  if (time.hours && time.minutes) {
    const hourInMinutes = time.hours * 60;
    return hourInMinutes + time.minutes;
  } else if (time.hours || time.minutes) {
    return time.hours ? time.hours * 60 : time.minutes;
  }
};

export const deductTime = ({
  totalTime,
  deductTime,
}: {
  totalTime: { hours: number; minutes: number };
  deductTime: { hours: number; minutes: number };
}) => {
  const totalMinutes =
    (totalTime?.hours ? totalTime?.hours * 60 : 0) +
    (totalTime?.minutes ? totalTime?.minutes : 0);

  const deductMinutes =
    (deductTime?.hours ? deductTime?.hours * 60 : 0) +
    (deductTime?.minutes ? deductTime?.minutes : 0);

  const difference = totalMinutes - deductMinutes;
  const hours = Math.floor(difference / 60);
  const minutes = difference % 60;
  return { hours, minutes };
};
