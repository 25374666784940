import axios from "axios";
import { Subject, from } from "rxjs";
import { appConfig } from "../../constants";
import { reAuthenticateUser } from "utilz/helpers/apiRequestAuthHelper";

import { getAccessToken } from "utilz/helpers";
import { axiosClient } from "utilz/axios";

export default function axiosUploadCall(url, data) {
  const uploadProgressSubject = new Subject();
  const controller = new AbortController();
  axiosClient
    .post(url, data, {
      // headers: {
      //     'Content-Type': 'multipart/form-data',
      // },
      signal: controller.signal,
      onUploadProgress: function (axiosProgressEvent) {
        console.log(axiosProgressEvent);
        const percentCompleted = Math.round(
          (axiosProgressEvent.loaded * 100) / axiosProgressEvent.total
        );
        uploadProgressSubject.next({
          response: axiosProgressEvent,
          status: "UPLOADING",
          percentage: percentCompleted,
          controller: controller,
        });
      },
    })
    .then((response) => {
      //  console.log("🚀 ~ file: axios-call.js:19 ~ .then ~ response:", response)
      uploadProgressSubject.next({
        response: response?.data,
        status: "COMPLETE",
        percentage: 100,
        controller: controller,
      });
      uploadProgressSubject.complete();
    })
    .catch((error) => {
      //      console.log("🚀 ~ file: axios-call.js:22 ~ upload file ~ error:", error)
      uploadProgressSubject.error(error);
      uploadProgressSubject.complete();
    });
  return uploadProgressSubject;
}
