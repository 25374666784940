import { SvgIcon, SvgIconProps } from "@mui/material";

export const UserFilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M18 18v-.563a3.188 3.188 0 0 0-3.188-3.187H9.189A3.188 3.188 0 0 0 6 17.438V18"
        clip-rule="evenodd"
      />
      <circle
        cx="12"
        cy="8.25"
        r="3"
        fill="currentColor"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </SvgIcon>
);
