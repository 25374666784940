import { Box, Divider, Stack, useTheme } from "@mui/material";
import { CustomTypography } from "../../../../../library/base";
import { useGlobalStore } from "controller/store/globalStore";
import { useNavigate } from "react-router-dom";
import { TopBarProfileButton } from "../TopBarProfileButton";
import Cookies from "js-cookie";
import { useLogoutAccount } from "controller";
import { Sentry } from "utilz";
import {
  BusinessUpIcon,
  LogoutIcon,
  StarIcon,
  UserCircleIcon,
} from "components";
import { ProfileModalOption } from "./ProfileModalOption";
import { resetAllStores } from "controller/store/resetStoreHelper";
const signoutBroadcast = new BroadcastChannel("signout_channel");
function ProfileModal({ handleClose }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const resetProfile = useGlobalStore((state) => state.resetCredentials);
  const profileName = useGlobalStore((state) => state.name) || "";
  const email = useGlobalStore((state) => state.email);
  const { mutateAsync: logoutAccount, isLoading: isLogoutLoading } =
    useLogoutAccount();
  const signOutHandler = async () => {
    try {
      const res = await logoutAccount();
      resetAllStores();
      if (
        res &&
        res?.data &&
        res?.data?.success === true &&
        res?.data?.statusCode === 200
      ) {
        signoutBroadcast.postMessage("signout");
        // clear sentry scope
        Sentry.setUser(null);
        sessionStorage.clear();
        localStorage.clear();
      }
    } catch (e) {
      resetAllStores();
      if (e?.message === "unauthorized") {
        signoutBroadcast.postMessage("signout");
        sessionStorage.clear();
        localStorage.clear();
      }
    }
  };

  const ProfileModalOptions = [
    {
      label: "My Account",
      icon: (
        <UserCircleIcon
          color="inherit"
          sx={{ width: "100%", height: "100%" }}
        />
      ),
      onClick: () => navigate("profile"),
    },
    {
      label: "Feedback",
      icon: <StarIcon color="inherit" sx={{ width: "100%", height: "100%" }} />,
      onClick: () =>
        window.open(
          "https://share.hsforms.com/1njS_nL2ETOS4IBGj_0Xd-Qnr2j7",
          "_blank"
        ),
    },
    {
      label: "Roadmap",
      icon: (
        <BusinessUpIcon
          color="inherit"
          sx={{ width: "100%", height: "100%" }}
        />
      ),
      onClick: () => {
        window.open("https://deskday.canny.io/", "_blank");
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "600px",
        backgroundColor: "white",
        overflow: "scroll",
        zIndex: "999",
        boxShadow:
          "0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={"24px"}
        p={"24px"}
        sx={{
          backgroundColor: "#E7E9FE",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("profile");
          handleClose();
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={"16px"}
        >
          <TopBarProfileButton isClickble={false} status={true} />
          <Stack alignItems="start">
            <CustomTypography
              size="SMALL-BETA"
              variant="BOLD"
              content={profileName}
            />
            <CustomTypography
              size="EXTRA-SMALL"
              variant="REGULAR"
              color="SECONDARY-MAIN"
              content={email}
            />
          </Stack>
        </Stack>
      </Stack>
      {ProfileModalOptions.map(({ label, icon, onClick }) => (
        <ProfileModalOption
          key={label}
          label={label}
          icon={icon}
          onClick={() => {
            onClick();
            handleClose();
          }}
        />
      ))}
      <Divider color={theme.palette.neutral["015"]} />
      <ProfileModalOption
        label={"Logout"}
        icon={
          <LogoutIcon color="inherit" sx={{ width: "100%", height: "100%" }} />
        }
        hasRightArrowIcon={false}
        onClick={() => !isLogoutLoading && signOutHandler()}
        isLoading={isLogoutLoading}
      />
    </Box>
  );
}

const deleteCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export { ProfileModal };
