import {
  Badge,
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Slide,
} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { defaultSizes, badgeSizes } from "./constants/defaultSizes";
import defaultColor from "./constants/defaultColors";
import PropsTypes from "prop-types";
import { Avatar } from "components";
import { useGlobalStore } from "controller/store/globalStore";
import { useGetMspById, useGetResourceById } from "controller";
import { emptyStringHandler } from "utilz/helpers";
import dayjs from "dayjs";
import { RESOURCE_TYPES } from "pages/modules/control-center/resources-and-permissions/constants";
import { useGetMspGeneralConfig } from "controller/hooks/configurations/general";
import { ProfileModal } from "./profile-modal";
function TopBarProfileButton(props) {
  const profilePictureUrl = useGlobalStore(
    (state) => state.profilePictureUrl || ""
  );

  const profileName = useGlobalStore((state) => state.name) || "";

  const anchorRef = useRef(null); //button refernece for dialog
  const [anchorEl, setAnchor] = useState(null); //to change anchor element onclick
  var open = Boolean(anchorEl); // convert every change in anchorEl to boolean (anchorEl value null or not)
  const handleClose = () => {
    setAnchor(null);
  };

  const handleOpen = () => {
    setAnchor(anchorRef.current);
  };

  return (
    <Box>
      {/* profile dialog */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-end"
        transition
        disablePortal
        sx={{
          position: "absolute",
          top: "0px",
          right: "0px",
          zIndex: "999",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Slide
            {...TransitionProps}
            timeout={400}
            style={{
              position: "absolute",
              top: "16px",
              right: "0px",
              zIndex: "999",
            }}
          >
            <Paper
              sx={{
                backgroundColor: "white",
                width: "min(90vw , 344px)",
                maxHeight: "600px",
                overflow: "hidden",
                boxShadow: "-10px 10px 32px rgba(0, 0, 0, 0.08)",
                borderRadius: "8px",
                zIndex: "99999999",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <ProfileModal handleClose={handleClose} />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Slide>
        )}
      </Popper>

      {/* profile dialog end */}

      <Badge
        badgeContent=""
        variant="dot"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiBadge-badge": {
            bottom: 6,
            right: 6,
            backgroundColor: props.status
              ? defaultColor.status.active.color
              : defaultColor.status.inactive.color,
            aspectRatio: "1",
            width: badgeSizes,
            height: badgeSizes,
            border: "2px solid white",
            borderRadius: "50%",
            boxSizing: "border-box",
          },
        }}
      >
        <Box ref={anchorRef} onClick={props.isClickble ? handleOpen : null}>
          <Avatar
            fullname={`${
              profileName && profileName !== null ? profileName : ""
            }`}
            src={profilePictureUrl} // || profilePictureUrl
            sx={{
              border: "none",
              padding: "0px",
              width: {
                xs: defaultSizes.sm,
                sm: defaultSizes.sm,
                md: defaultSizes.md,
                lg: defaultSizes.lg,
                xl: defaultSizes.xl,
              },
              height: {
                xs: defaultSizes.sm,
                sm: defaultSizes.sm,
                md: defaultSizes.md,
                lg: defaultSizes.lg,
                xl: defaultSizes.xl,
              },

              borderRadius: "8px",
              outline: "1px solid white",
              "&:hover": { cursor: `${props.isClickble ? "pointer" : ""}` },
            }}
          ></Avatar>
        </Box>
      </Badge>
    </Box>
  );
}

TopBarProfileButton.propTypes = {
  status: PropsTypes.bool,
  isClickble: PropsTypes.bool,
};

TopBarProfileButton.defaultProps = {
  status: false,
  isClickble: true,
};

export { TopBarProfileButton };

// width: {
//   xs: defaultSizes.sm,
//   sm: defaultSizes.sm,
//   md: defaultSizes.md,
//   lg: defaultSizes.lg,
//   xl: defaultSizes.xl,
// },
// height: {
//   xs: defaultSizes.sm,
//   sm: defaultSizes.sm,
//   md: defaultSizes.md,
//   lg: defaultSizes.lg,
//   xl: defaultSizes.xl,
// },
