export const TABS = {
  RESOURCES: {key: "RESOURCES-TAB-KEY", label: "Resources", value: "RESOURCES", route: "resources"},
  RESOURCE_GROUPS: {key: "RESOURCE_GROUPS-TAB-KEY", label: "Resource Groups", value: "RESOURCE_GROUPS", route: "resource-groups"},
  RESOURCE_TYPES: {key: "RESOURCE_TYPES-TAB-KEY", label: "Resource Types", value: "RESOURCE_TYPES", route: "resource-types"},
};


export const RESOURCE_TYPES={
  SUPERADMIN:"b110abc3-0709-491f-ac04-86eb25159386",
  ADMIN:"9d6afd0e-b353-438d-a34d-1bb1cc5bbf03",
  RESOURCE:"ff1088b8-d50b-4d89-a033-94bf3b9f450d"
};