import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// HOC Imports
import CustomIcon from "../../../library/icon-library";
import { useTheme } from "@mui/material";
import {
  BusinessUpIcon,
  Button,
  CircleQuestionMarkIcon,
  FeedbackIcon,
  InfoIcon,
  HeadphoneIcon,
  BookmarkIcon,
  LeftArrowIcon,
} from "components";
import { TopBarProfileButton } from "./top-bar-profile-button";

import { ClearSampleDataButton } from "./ClearSampleDataButton";
import {
  useGetAllActiveTimeEntries,
  useGetWatchList,
  useGlobalStore,
  useMainStore,
  useNonPersistantMainStore,
  useTimeEntryStore,
} from "controller";
import { dayjs } from "utilz";

import { useEffect, useState } from "react";
import { WatchListDrawer } from "./watchlist";
import { NotificationButton } from "shared/features/notification-v2/NotificationButton";
import { WatchListButton } from "./WatchListButton";

const signoutBroadcast = new BroadcastChannel("signout_channel");

const TopBarComponent = ({
  enableReturn = false,
}: {
  enableReturn?: boolean;
}) => {
  const theme = useTheme();
  const trialPeriodEndDate = useGlobalStore((state) => state.trialEndDate);
  const sampleDataStatus = useGlobalStore((state) => state.sampleDataStatus);
  const setSampleDataStatus = useGlobalStore(
    (state) => state.setSampleDataStatus
  );

  const navigate = useNavigate();
  const location = useLocation();
  const role = useGlobalStore((state) => state?.role);

  // const isArchivePage = document.location.href.split("/").includes("archive");
  const isControlCenterPage = document.location.href
    .split("/")
    .includes("control-center");

  // Renderer
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          flexGrow: 1,
          px: "8px",

          overflow: "scroll",
          "&:: -webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" spacing={"16px"}>
            {/* <MenuIconButton /> */}

            <Box
              component="button"
              onClick={() => {
                if (location.key === "default") return;
                if (
                  location.pathname.includes(
                    "/app/customers/customers/profile/"
                  )
                ) {
                  navigate("/app/customers/customers/home");
                } else if (
                  location.pathname.includes("/app/customers/users/profile/")
                ) {
                  navigate("/app/customers/users/home");
                } else {
                  navigate(-1);
                }
              }}
              sx={{
                ml: "20px",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                aspectRatio: "1",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "1px solid #CCCCCC",
                "&:hover": {
                  cursor: location.key === "default" ? "default" : "pointer",
                },
              }}
            >
              <LeftArrowIcon
                sx={{
                  color: location.key === "default" ? "#CCCCCC" : "#1C1E26",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <SizableTextField
                name={""}
                label={""}
                value={""}
                adornment={true}
                adornmentPosition={"start"}
                adornmentValue={"search"}
                disabled={true}
                required={true}
                error={false}
                helperText={""}
                placeholder={"Search"}
                size={"small"}
                changeHandler={() => {}}
              /> */}
            </Box>
          </Stack>
          <Stack direction="row" spacing={"0px"}>
            <Button
              className="intercomV2-topAppBar-howToBtn"
              startIcon={
                <CircleQuestionMarkIcon
                  sx={{ width: "24px", height: "24px" }}
                />
              }
              onClick={() => {
                navigate("how-to");
              }}
              variant="ghost"
              sx={{ color: theme.palette.lightBg.high }}
            >
              How To
            </Button>
            <Button
              className="intercomV2-topAppBar-supportBtn"
              startIcon={
                <HeadphoneIcon sx={{ width: "20px", height: "20px" }} />
              }
              onClick={() => {
                // @ts-ignore
                window.Intercom("show");
              }}
              variant="ghost"
              sx={{ color: theme.palette.lightBg.high }}
            >
              Support
            </Button>
            <WatchListButton />
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            spacing={"24px"}
            mr={"24px"}
            alignItems={"center"}
          >
            {/* {role === "SUPERADMIN" && isControlCenterPage && (
              <TopBarArchiveButton />
            )} */}
            {sampleDataStatus === "skipped" &&
              location.pathname === "/app/control-center" &&
              dayjs().isBefore(trialPeriodEndDate) && (
                <Button
                  variant="secondary"
                  onClick={() => setSampleDataStatus("pending")}
                >
                  Add Sample Data
                </Button>
              )}
            <ClearSampleDataButton />
            <NotificationButton />
            <TopBarProfileButton
              status={true}
              // imgurl='https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250'
            />
            {/* <CustomIconButton
              icon="plus"
              onClick={() => {
                setCreateNewDialogState(true);
              }}
            /> */}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default TopBarComponent;
