
function documentationIcon(props) {

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2683 17.2587C12.1121 17.415 11.9002 17.5029 11.6792 17.5029H10V15.8237C10 15.6027 10.0879 15.3908 10.2442 15.2345L15.885 9.58955C16.154 9.32056 16.5188 9.16943 16.8992 9.16943C17.2796 9.16943 17.6444 9.32056 17.9133 9.58955V9.58955C18.1825 9.8584 18.3338 10.2233 18.3338 10.6037C18.3338 10.9842 18.1825 11.349 17.9133 11.6179L12.2683 17.2587Z"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M13.8076 11.6758L15.8326 13.7008"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M17.2623 12.2637L18.0123 13.0137V13.0137C18.4071 13.4092 18.4071 14.0498 18.0123 14.4453L16.6448 15.8128"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.83887 7.49723H10.8389"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.83887 10.8361H9.1722"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.5 17.5H5C3.61929 17.5 2.5 16.3807 2.5 15V5C2.5 3.61929 3.61929 2.5 5 2.5H15C16.3807 2.5 17.5 3.61929 17.5 5V6.66667"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

export default documentationIcon;