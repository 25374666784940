import { useQuery } from "@tanstack/react-query";
import {
  getQaCustomerFeedbackForTickets,
  getQaDetailed,
  getQaFeedback,
  getQaHealth,
  getQaScore,
} from "controller/api";
import {
  GetQaDetailedInput,
  GetQaFeedbackInput,
  GetQaHealthInput,
  GetQaScoreInput,
} from "shared";
import { debugConsole } from "utilz/helpers";

export const useGetQaScore = ({
  timePeriod,
  resourceId,
  serviceType,
}: GetQaScoreInput) => {
  return useQuery({
    queryKey: ["qaScore", timePeriod, resourceId, serviceType],
    queryFn: () => getQaScore({ timePeriod, resourceId, serviceType }),
    enabled: resourceId ? true : false,
    onError: (err) => debugConsole(err),
  });
};

export const useGetQaDetails = (data: GetQaDetailedInput) => {
  return useQuery({
    queryKey: [
      "qaDetail",
      data.serviceType,
      data.resourceId,
      // data.assignedOrClosed,
      data.qaHealthStatusFilter,
      data.timePeriod,
      data.boards,
      data.customers,
      data.page,
      data.limit,
    ],
    queryFn: () => getQaDetailed(data),
    enabled: data.resourceId ? true : false,
    onError: (err) => debugConsole(err),
  });
};

export const useGetQACustomerFeedback = ({
  resourceId,
  timeInterval,
}: GetQaFeedbackInput) => {
  return useQuery({
    queryKey: ["qaScore_feedback", timeInterval, resourceId],
    queryFn: () => getQaFeedback({ timeInterval, resourceId }),
    enabled: resourceId ? true : false,
    onError: (err) => debugConsole(err),
  });
};

export const useGetQAHealth = ({
  serviceId,
  serviceType,
}: GetQaHealthInput) => {
  return useQuery({
    queryKey: ["qaScore_health", serviceId, serviceType],
    queryFn: () => getQaHealth({ serviceId, serviceType }),
    enabled: serviceId ? true : false,
    onError: (err) => debugConsole(err),
  });
};

export const useGetQaCustomerFeedbackForTickets = ({
  resourceIds,
  interval,
}: any) => {
  return useQuery({
    queryKey: ["qaCustomerFeedbackForTickets", resourceIds, interval],
    queryFn: () => getQaCustomerFeedbackForTickets({ resourceIds, interval }),
  });
};
