import {
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FileIcon } from "components/FileIcon";
import { CloseIcon } from "components/icons";
import { AttachmentFileData } from "shared";

export const AttachmentTile = ({
  file,
  status,
  percentage,
  onRemove,
}: {
  file: AttachmentFileData;
  status?: string;
  percentage: number;
  onRemove?: () => void;
}) => {
  const theme = useTheme();

  return (
    <Stack
      key={file.key}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py="12px"
      sx={{
        borderBottom: `1px solid ${theme.palette.neutral["020"]}`,
        borderBottomStyle: "dashed",
      }}
    >
      <Stack direction="row" gap="16px">
        <FileIcon type={file.type} />
        <Stack>
          <Typography variant="body-small">{file.name}</Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="body-small" color={theme.palette.lightBg.low}>
              {status}...
            </Typography>
            <Typography variant="body-small" color={theme.palette.success.main}>
              {percentage}%
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="32px"
      >
        <LinearProgress
          variant="determinate"
          color="success"
          value={percentage}
          sx={{ width: "200px", height: "8px" }}
        />
        <IconButton
          onClick={onRemove}
          sx={{ border: `1px solid ${theme.palette.neutral["020"]}` }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
