import { Box, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { CustomToggleButton } from "library/base";
import { Button, DialerInput } from "components";
import { useServiceDeskUpdateQaRules } from "controller";
import toast from "react-hot-toast";
interface QaCardProps {
  data: Record<string, any>;
  currentConfig: Record<string, any>;
  currentTab: string;
}
export const QaRuleCard = ({
  data,
  currentConfig,
  currentTab,
}: QaCardProps) => {
  const { mutateAsync: updateRules } = useServiceDeskUpdateQaRules();
  const [updatedConfig, setUpdatedConfig] = React.useState<any>(currentConfig);
  const [isUpdated, setIsUpdated] = React.useState<boolean>(false);
  useEffect(() => {
    if (currentConfig) {
      setUpdatedConfig(currentConfig);
    }
  }, [currentConfig]);

  useEffect(() => {
    setUpdatedConfig(currentConfig);
    setIsUpdated(false);
  }, [currentTab]);

  const handleSave = async () => {
    try {
      // @ts-ignore
      await updateRules({ type: currentTab, data: updatedConfig });
      setIsUpdated(false);
      toast.success("Rules updated successfully");
    } catch (error) {
      toast.error("Unable to update rules");
    }
  };

  const handleCancelClick = () => {
    setUpdatedConfig(currentConfig);
    setIsUpdated(false);
  };
  const theme = useTheme();
  return (
    <>
      <Box
        p="32px"
        sx={{
          height: "100%",
          position: "relative",
          overflow: "scroll",
        }}
      >
        <Stack
          divider={<Divider />}
          mb={isUpdated ? "84px" : "0px"}
          sx={{
            borderRadius: "24px",
            border: `1px solid ${theme.palette.neutral["015"]}`,
            width: "100%",
            mt: "24px",
          }}
        >
          {Object.entries(data).map(([key, value]) => {
            return (
              <Stack
                key={key}
                sx={{
                  p: "16px 36px 16px 24px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Typography variant="subtitle1">
                            {value.label}
                          </Typography>
                          {value.showDialer && (
                            <DialerInput
                              value={updatedConfig[value.dialerKey] || 0}
                              disabled={updatedConfig[key] === false}
                              maxValue={value.maxValue}
                              onValueChange={(newData: string) => {
                                setIsUpdated(true);
                                setUpdatedConfig({
                                  ...updatedConfig,
                                  // @ts-ignore
                                  [value.dialerKey]: newData,
                                });
                              }}
                            />
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body-small">
                          {value.tagline}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    justifyContent={"end"}
                    item
                    xs={2}
                    alignItems={"end"}
                    justifyItems={"end"}
                  >
                    <Stack alignItems={"flex-end"}>
                      <CustomToggleButton
                        isChecked={updatedConfig[key] && updatedConfig[key]}
                        onChange={(e) => {
                          setIsUpdated(true);
                          setUpdatedConfig({
                            ...updatedConfig,
                            [key]: e.value,
                          });
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            );
          })}
        </Stack>
      </Box>
      {isUpdated && (
        <Stack
          direction="row"
          sx={{
            bgcolor: "#FFF",
            p: "24px",
            gap: "16px",
            justifyContent: "flex-end",
            position: "sticky",
            bottom: 0,
            left: "-32px",
            right: "-32px",
            borderTop: `1px solid ${theme.palette.neutral["010"]}`,
            boxShadow: "0px -2px 88px 0px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Button variant="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      )}
    </>
  );
};
