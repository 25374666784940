import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createCannedNote,
  deleteCannedNotes,
  getAllCannedNotes,
  updateCannedNote,
} from "controller/api";
import { ApiErrorType, GetQueryProps } from "shared";
import { sentryLogger } from "utilz";

export const useGetAllCannedNotes = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["canned", searchQuery],
    queryFn: () =>
      getAllCannedNotes({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllCannedNotes",
      }),
  });
};

export const useCreateCannedNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCannedNote,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["canned"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "createCannedNote",
      });
    },
  });
};

export const useUpdateCannedNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCannedNote,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["canned"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateCannedNote",
      });
    },
  });
};

export const useDeleteCannedNotes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCannedNotes,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["canned"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteCannedNotes",
      });
    },
  });
};
