import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createAttribute,
  deleteAttribute,
  getAttributes,
  updateAttribute,
} from "controller/api";

import {
  ApiErrorType,
  CreateAttributeInput,
  DeleteAttributeInput,
  GetAttributesInput,
  UpdateAttributeInput,
} from "shared";
import { sentryLogger } from "utilz";

export const useGetAttributes = ({
  attributeName,
  serviceType,
}: GetAttributesInput) => {
  return useQuery({
    queryKey: ["attributes", serviceType, attributeName],
    queryFn: () => getAttributes({ attributeName, serviceType }),
    enabled: !!serviceType && !!attributeName ? true : false,
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        functionName: "getAttributes",
      });
    },
  });
};

export const useCreateAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation((value: CreateAttributeInput) => createAttribute(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([
        "attributes",
        variables.serviceType,
        variables.attributeName,
      ]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "createAttribute",
      });
    },
  });
};

export const useUpdateAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation((value: UpdateAttributeInput) => updateAttribute(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([
        "attributes",
        variables.serviceType,
        variables.attributeName,
      ]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateAttribute",
      });
    },
  });
};

export const useDeleteAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation((value: DeleteAttributeInput) => deleteAttribute(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([
        "attributes",
        variables.serviceType,
        variables.attributeName,
      ]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteAttribute",
      });
    },
  });
};
