export const STEPS = {
  step_1: {
    key: "STEP-1-GENERAL-KEY",
    value: 1,
    label: "General",
  },
  step_2: {
    key: "STEP-1-PERMISSIONS-KEY",
    value: 2,
    label: "Permissions",
  },
};