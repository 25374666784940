import { Stack, useTheme } from "@mui/material";

import { useState } from "react";
import { v4 } from "uuid";
import { SlaItemBuilder, SlaMainBuilder, validateSlaDetails } from "./Utils";
import { toast } from "react-hot-toast";
import { Button, Input, Modal, PlusIcon } from "components";
import { SlaRuleTile } from "./SlaRuleTile";
import { PRIORITIES } from "./Utils";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

interface CreateSlaModalProps {
  isOpen: boolean;
  selectedData: any;
  onClose: () => void;
  isEdit: boolean;
  onSaveHandler: (data: any) => void;
  isLoading?: boolean;
}

const schema = yup.object({
  sla_name: yup.string().required("SLA name is required"),
  description: yup.string().required("Description is required"),
});
export const CreateSlaModal = (props: CreateSlaModalProps) => {
  const theme = useTheme();
  const selectedData: any = SlaMainBuilder(props.selectedData);

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      sla_name: selectedData.sla_name || "",
      description: selectedData.description || "",
    },
    resolver: yupResolver(schema),
  });

  const [rules, setRules] = useState(selectedData.sla_rules);
  const [validate, setValidate] = useState(false);

  const onActionHandler = (isNew: boolean, index: number) => {
    const slaRuleList = rules;
    if (isNew === true) {
      if (PRIORITIES.length > slaRuleList.length) {
        slaRuleList.push({
          key: v4(),
          data: SlaItemBuilder(),
        });
      }
    } else {
      slaRuleList.splice(index, 1);
    }
    setRules([...slaRuleList]);
  };

  const onSlaRuleChange = (item: any, newSlaRule: any) => {
    const newList = rules;
    if (newList.includes(item) === false) {
      return;
    }
    const index = newList.indexOf(item);
    newList[index]["data"] = newSlaRule;
    setRules([...newList]);
  };

  //to avoid duplicate priority for rules
  //   const getSeletedPriorities = () => {
  //     return state.slaData?.sla_rules?.map((item) => item?.data?.priority);
  //   };

  const onSubmitAction = handleSubmit(
    (value) => {
      setValidate(true);
      const newSla = {
        ...(selectedData.mapping_id !== undefined
          ? { mapping_id: selectedData.mapping_id }
          : {}),
        sla_name: value.sla_name,
        description: value.description,
        sla_rules: rules.map((item: any) => ({
          ...item?.data,
          priority: item?.data?.priority || "",
          first_response: item?.data?.first_response,
          resolution_time: item?.data?.resolution_time,
          warning_time: item?.data?.warning_time,
        })),
      };

      if (true === validateSlaDetails(newSla)) {
        props.onSaveHandler(newSla);
        return;
      }
      toast.error("Fill all empty fields");
    },
    () => {
      setValidate(true);
    }
  );

  return (
    <Modal
      open={props.isOpen}
      fullWidth={true}
      maxWidth={"xl"}
      title={props.isEdit === true ? "Edit SLA" : "Create SLA"}
      paperStyles={{
        minHeight: "min(752px,80vh)",
      }}
      onClose={props.onClose}
      onPrimaryButtonClick={onSubmitAction}
      onSecondaryButtonClick={props.onClose}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      isPrimaryButtonLoading={props?.isLoading}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: " nowrap",
          justifyContent: "flex-start",
          aligItems: "stretch",
          alignContent: "flex-start",
          height: "100%",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            alignItems: "top",
            columnGap: "24px",
            px: "32px",
            py: "16px",
            borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
          }}
        >
          <Input
            name="sla_name"
            label={"SLA Name"}
            control={control}
            placeholder={"Enter SLA name here"}
            sx={{
              width: "max(200px,30%)",
            }}
            inputProps={{ maxLength: 100 }}
            error={errors?.sla_name ? true : false}
            helperText={errors?.sla_name?.message?.toString?.() || ""}
          />

          <Input
            label={"Description"}
            name={"description"}
            sx={{
              flexGrow: "1",
            }}
            inputProps={{ maxLength: 250 }}
            control={control}
            placeholder={"Enter description here"}
            error={errors?.description ? true : false}
            helperText={errors?.description?.message?.toString?.() || ""}
          />
        </Stack>

        <Stack
          sx={{
            width: "100%",
            overflow: "scroll",
            flexGrow: "1",
            flexShrink: "1",
            flex: "1",
          }}
          direction={"column"}
        >
          {rules.map((item: any, index: number) => (
            <SlaRuleTile
              // <CreateSlaRuleTile
              key={item.key}
              data={item}
              allRules={rules}
              isLastRow={index === rules?.length - 1 ? true : false}
              onAction={(isNew) => onActionHandler(isNew, index)}
              onChangeHandler={(data) => onSlaRuleChange(item, data)}
              selectedPriorities={[]}
              validate={validate}
            />
          ))}
          {PRIORITIES.length > rules.length && (
            <Button
              onClick={() => onActionHandler(true, 0)}
              sx={{ alignSelf: "flex-end", mx: "32px" }}
              variant="secondary"
              icon={<PlusIcon />}
            />
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
