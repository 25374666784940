import React from "react";
import { Input, InputProps } from "./Input";
import { InputAdornment, useTheme } from "@mui/material";
import { SearchIcon } from "components/icons";

export const SearchInput = ({ sx, ...rest }: InputProps) => {
  const theme = useTheme();
  return (
    <Input
      hideHelperBox
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" width={"14px"} height={"14px"} />
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiInputBase-input": {
          paddingLeft: 0,
        },
        "& .MuiOutlinedInput-root": {
          // bgcolor: "red",
          bgcolor: theme.palette.neutral["005"],

          "& > fieldset": {
            border: `1px solid ${theme.palette.neutral["010"]}`,
            paddingLeft: "0px",
            // bgcolor: theme.palette.neutral["005"],
          },
        },
        ...sx,
      }}
      {...rest}
    />
  );
};
