import { Box, Stack, Typography } from "@mui/material";
import { DownloadBtn } from "./DownloadBtn";

export const QrCodeComponent = ({
  qrCode,
  closeDownloadDrawer,
}: {
  qrCode: string;
  closeDownloadDrawer?: () => void;
}) => {
  return (
    <Stack
      gap="16px"
      sx={{
        minWidth: "154px",
        maxWidth: "312px",
        width: "50%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          aspectRatio: 1,
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            objectFit: "contain",
            marginLeft: "-6px",
          }}
          src={qrCode}
        />
      </Box>
      <Typography variant="body-medium" color="lightBg.low">
        Use your phone to scan the QR code and download the app.
      </Typography>
      {closeDownloadDrawer && (
        <DownloadBtn
          variant={"teams"}
          closeDownloadDrawer={closeDownloadDrawer}
        />
      )}
    </Stack>
  );
};
