import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createExpenseConfig,
  deleteExpenseConfig,
  getAllExpenseConfigs,
  updateExpenseConfig,
} from "controller";
import { GetQueryProps } from "shared";

// Hook for fetching all expense configurations
export const useGetAllExpenseConfigs = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["expenseConfigs", perPageCount, pageNumber, filter, searchQuery],
    queryFn: () =>
      getAllExpenseConfigs({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error) => console.log(error),
  });
};

// Hook for creating a new expense configuration
export const useCreateExpenseConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => createExpenseConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenseConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};

// Hook for deleting an expense configuration
export const useDeleteExpenseConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { expense_ids: string[] }) => deleteExpenseConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenseConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};

// Hook for updating an expense configuration
export const useUpdateExpenseConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => updateExpenseConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenseConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};
