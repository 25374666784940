export const defaultStateBuilder = ()=> {
  return {
    mfa: false,
    minimum_character_required: false,
    count_minimum_character: 0,
    password_not_same_as_last_password: false,
    count_password_not_same_as_last_password: 0,
    mixed_case_letters: false,
    special_charecter: false,
    first_letter_alphabet: false,
    one_alphaber_one_number: false,
    password_expires: false,
    password_expiry_days: 0,
  };
};