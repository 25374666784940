import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useGetCurrentBreakpoint = (theme: Theme) => {
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("xs"));

  if (lg) return "lg";
  if (md) return "md";
  if (sm) return "sm";
  return "md";
};
