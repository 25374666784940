import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createTaxConfig,
  deleteTaxConfig,
  getAllTaxConfigs,
  updateTaxConfig,
} from "controller";
import { GetQueryProps } from "shared";

export const useGetAllTaxConfigs = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: [
      "taxConfigs",
      perPageCount,
      pageNumber,
      filter,
      searchQuery,
      sortDirection,
      sortColumn,
    ],
    queryFn: () =>
      getAllTaxConfigs({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error) => console.log(error),
  });
};

export const useCreateTaxConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => createTaxConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["taxConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};

export const useDeleteTaxConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { tax_ids: string[] }) => deleteTaxConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["taxConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};

export const useUpdateTaxConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => updateTaxConfig(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["taxConfigs"] });
    },
    onError: (error) => console.log(error),
  });
};
