import { Box, Stack, Typography, useTheme } from "@mui/material";
import wave from "assets/images/wave.png";
import { useGetResourceById, useGlobalStore, useMainStore } from "controller";
import { ResourceFilter } from "shared";

export const HelloCard2 = () => {
  const theme = useTheme();
  const profileName = useGlobalStore((state) => state.name);
  const homeScreenResourceId = useMainStore(
    (state) => state.homeScreenResourceId
  );
  const setHomeScreenResourceId = useMainStore(
    (state) => state.setHomeScreenResourceId
  );
  const userRole = useGlobalStore((state) => state.role) || "";
  const loggedInResourceId = useGlobalStore((state) => state.resourceId);
  const isLoggedInResource = loggedInResourceId === homeScreenResourceId;
  const { data: getResourceData } = useGetResourceById(homeScreenResourceId);
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"flex-end"}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="start"
        gap="8px"
      >
        <img src={wave} height="20px" width="20px" />
        <Typography variant="body-large" sx={{ textTransform: "capitalize" }}>
          Hello,
        </Typography>
        {isLoggedInResource ? (
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
            {profileName?.split(" ")[0]}
          </Typography>
        ) : (
          <Typography variant="body-large">
            Take a look at{" "}
            <Typography
              color={theme.palette.purple.main}
              display={"inline-block"}
            >
              {getResourceData?.first_name}
            </Typography>
            's work
          </Typography>
        )}
      </Stack>
      {["SUPERADMIN", "ADMIN"].includes(userRole) && (
        <Box className="intercomV2-home-meOrAllFilter">
          <ResourceFilter
            isSingleSelect
            selectedResourceId={[homeScreenResourceId]}
            onTabChange={(id, data) => {
              if (id && id.length === 1 && data) {
                setHomeScreenResourceId(id[0]);
                // setSelectedResourceData(data[0]);
              } else {
                setHomeScreenResourceId(loggedInResourceId || "");
                // setSelectedResourceData(null);
              }
            }}
            isMinimal={true}
          />
        </Box>
      )}
    </Stack>
  );
};
