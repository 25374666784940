import React from "react";
import { BadgeBase, BadgeProps } from "./BadgeBase";
import useTheme from "@mui/material/styles/useTheme";
import { FlagFilledIcon } from "components/icons";
import { Typography } from "@mui/material";

interface PriorityProps extends BadgeProps {
  type: "high" | "medium" | "low" | "critical" | string;
}
export const PriorityBadge = ({ type, ...rest }: PriorityProps) => {
  const theme = useTheme();
  let backgroundColor;
  let backgroundMode: "light" | "dark";
  let borderColor;
  let textColor;
  switch (type) {
    case "high":
      backgroundColor = theme.palette.warning.tint;
      backgroundMode = "dark";
      borderColor = theme.palette.warning.main;
      textColor = "#D35800";
      break;
    case "medium":
      backgroundColor = theme.palette["warning-minor"].tint;
      backgroundMode = "light";
      borderColor = theme.palette["warning-minor"].main;
      textColor = "#B7910A";
      break;
    case "low":
      backgroundColor = theme.palette.neutral["015"];
      backgroundMode = "light";
      borderColor = theme.palette.lightBg.low;
      textColor = theme.palette.lightBg.low;
      break;
    case "critical":
      backgroundColor = theme.palette.error.tint;
      backgroundMode = "dark";
      borderColor = theme.palette.error.main;
      textColor = theme.palette.error.main;
      break;
    default:
      backgroundColor = theme.palette.neutral["015"];
      backgroundMode = "light";
      break;
  }
  return (
    <BadgeBase
      backgroundColor={backgroundColor}
      backgroundMode={backgroundMode}
      containerStyles={{
        width: "84px",
        padding: "4px 12px 4px 8px",
        justifyContent: "left",
        borderRadius: "4px",
      }}
      leadingIcon={<FlagFilledIcon fontSize="small" htmlColor={textColor} />}
      {...rest}
    >
      <Typography
        variant="caption-xs"
        textTransform={"capitalize"}
        sx={{ color: `${theme.palette.lightBg.high} !important` }}
      >
        {type}
      </Typography>
    </BadgeBase>
  );
};
