import React, {useRef, useState, useEffect, forwardRef, useImperativeHandle} from "react";

import profile from "../../../../../../../../../../assets/profile.png";

// Services
import { services } from "../../../../../../../../../../services";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

// HOC 
import { CustomTypography } from "../../../../../../../../../../library/base";
import { CustomSelectField } from "../../../../../../../../../../library/system/custom-form";
import { CustomCard } from "../../../../../../../../../../library/layers";
import CustomIcon from "../../../../../../../../../../library/icon-library";


const MembersEditForm = forwardRef((props, ref)=> {
  // State
  const [state, setState] = useState({
    members: [],
    selected: ""
  });

  // Effects

  // Event Handlers
  const handleChange = (data)=> {
    const { field, value} = data;
    setState({...state, members: [...state.members, value]});
  };
  const pop = (id)=> {
    const current = state.members.filter((item)=> item !== id);
    setState({...state, members: current});
  };

  // Renderer
  return(
    <Box 
      sx={{
        height: "320px",
        width: "100%",
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center'
      }}
    >
      <Stack spacing={"32px"} sx={{width: "100%", }}>
        <CustomSelectField
          key={""}
          name={"resources"}
          label={"Resources"}
          value={""}
          multi={false}
          size={"small"}
          placeholder={"Pick resources"}
          disabled={props.isEdit}
          dependency={false}
          triggerValue={[]}
          populator={services.populatorServices.resourcePopulatorServices.resourcePopulator}
          changeHandler={handleChange}
        />
        <Box 
          sx={{
            width: "100%", 
            height: "200px", 
            minHeight: "200px", 
            maxHeight: "200px", 
            overflow: "scroll"
          }}
        >
          {
            state?.members.length === 0?
              ""
              :
              <Box sx={{mb:"12px"}}>
                <CustomTypography
                  size ={"SMALL-ALPHA"}
                  color={"TERITARY-MAIN"}
                  variant={"MEDIUM"}
                  content={"Members added"}
                />
              </Box>
          }
                    
          <CustomCard
            borderRadius = {"24px"}
            outlined = {true}
            spacing = {"16px"}
            content = {
              <Grid 
                container
                columnSpacing={"24px"}
                rowSpacing={"24px"}
                sx={{height: "150px", minHeight: "150px",maxHeight: "150px", overflow: "scroll"}}
              >
                {
                  state.members.map((element)=> {
                    return(
                      <Grid key={element} item xs={4} >
                        <Box
                          sx={{
                            width: "100%",
                            height: "48px",
                            border: "1px solid #CCC",
                            padding: "4px 8px 4px 16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: "8px"
                          }}
                        >
                          <Stack direction={"row"} spacing={"8px"}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img 
                                src={profile}
                                width={"32px"}
                                height={"32px"}
                              />
                            </Box>
                                                        
                            <Stack
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <CustomTypography
                                  size ={"SMALL-ALPHA"}
                                  color={"PRIMARY-MAIN"}
                                  variant={"MEDIUM"}
                                  content={"Irese"}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                }}
                              >
                                <CustomTypography
                                  size ={"EXTRA-SMALL"}
                                  color={"TERITARY-MAIN"}
                                  variant={"REGULAR"}
                                  content={"Associate Analyst"}
                                />
                              </Box>
                                                            
                                                            
                            </Stack>
                          </Stack>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              height: "100%",
                              "&:hover": {cursor: "pointer"}
                            }}
                            onClick = {()=>pop(element)}
                          >
                            <CustomIcon
                              size={"small"}
                              icon={"close"}
                              color="#767B85"
                            />
                          </Box>
                        </Box>
                                                
                      </Grid>
                    );
                  })
                }
              </Grid>
            }
          />
                    
                    
        </Box>                
      </Stack>            
    </Box>
        
  );
});

export default MembersEditForm;