import { styled, useTheme } from "@mui/material/styles";
import MuiTableHead, { TableHeadProps } from "@mui/material/TableHead";
import React, { useState } from "react";
import { TableRowRoot } from "./TableRowRoot";
import TableCell from "@mui/material/TableCell";
import CustomCheckBox from "../../library/base/custom-checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomIcon from "../../library/icon-library";
import Box from "@mui/material/Box";
import { VerticalArrowsIcon } from "components/icons";
import { IconButton, SxProps, TableFooter } from "@mui/material";
import { TableCellRoot } from "./TableCellRoot";
import { StyledTableCell } from "./StyledTableCell";
interface HeaderProps {
  name: string;
  sortable?: boolean;
  key: string;
  numeric?: boolean;
  metadata?: Record<string, any>;
  clickable?: boolean;
}

interface StickyHeaderProps extends HeaderProps {
  width: string;
}

interface CustomTableHeadProps extends TableHeadProps {
  headers: Array<HeaderProps>;
  onHeaderItemClick?: (data: any) => void;
  leftHeaders?: Array<StickyHeaderProps>;
  rightHeaders?: Array<StickyHeaderProps>;
  onAllSelect?: (currentState: "checked" | "unchecked") => void;
  onSortingClick?: (column: string) => void;
  multiselect?: boolean;
  cellProps?: any;
  data: Array<any>;
  cellBuilders?: Record<string, (rowData?: any) => React.ReactNode>;
}
export const StickyTableFootRoot = ({
  headers,
  onHeaderItemClick,
  leftHeaders,
  rightHeaders,
  onAllSelect,
  onSortingClick,
  multiselect = true,
  cellProps,
  data,
  cellBuilders,
  ...rest
}: CustomTableHeadProps) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const theme = useTheme();
  const HandleCheckboxClick = () => {
    setIsAllSelected((prev) => {
      onAllSelect && onAllSelect(!prev === true ? "checked" : "unchecked");
      return !prev;
    });
  };
  return (
    <TableFooter
      sx={{
        borderTop: "2px solid #e0e0e0",
        position: "sticky",
        bottom: 0,
        zIndex: 500,
      }}
      {...rest}
    >
      {data &&
        data.length > 0 &&
        data.map((row, index) => {
          return (
            <TableRowRoot
              sx={{
                boxShadow: " 0px -4px 16px 0px rgba(0, 0, 0, 0.08)",
              }}
              header
              key={index}
            >
              {leftHeaders &&
                leftHeaders.map((headerItem, leftIndex) => {
                  if (cellBuilders && cellBuilders[headerItem.key]) {
                    return (
                      <StyledTableCell
                        className="left-sticky-cell"
                        key={headerItem.key}
                        sx={{
                          left:
                            leftIndex > 0
                              ? leftHeaders[leftIndex - 1].width
                              : 0,
                          position: "sticky",
                          bottom: 0,
                          zIndex: 700,
                          minWidth: headerItem.width,
                          maxWidth: headerItem.width,
                          backgroundColor: "#fff",
                          borderBottom: "0px",
                          ...cellProps?.[headerItem.key]?.sx,
                        }}
                      >
                        {cellBuilders[headerItem.key](row)}
                      </StyledTableCell>
                    );
                  } else {
                    return (
                      <TableCellRoot
                        className="left-sticky-cell"
                        key={headerItem.key}
                        sx={{
                          left:
                            leftIndex > 0
                              ? leftHeaders[leftIndex - 1].width
                              : 0,
                          position: "sticky",
                          backgroundColor: "#fff",
                          zIndex: 700,
                          bottom: 0,
                          minWidth: headerItem.width,
                          maxWidth: headerItem.width,
                          padding: "6px 16px",
                          borderBottom: "0px",
                          //   ...cellProps?.[headerItem?.key]?.sx,
                        }}
                        value={row ? row[`${headerItem.key}`] : ""}
                        type={""}
                      ></TableCellRoot>
                    );
                  }
                })}

              {headers.map((headerItem) => {
                if (cellBuilders && cellBuilders[`${headerItem?.key}`]) {
                  return (
                    <StyledTableCell
                      key={headerItem.key}
                      sx={{
                        position: "sticky",
                        zIndex: 500,
                        bottom: 0,
                        backgroundColor: "#fff",
                        borderBottom: "0px",
                        ...cellProps?.[headerItem.key]?.sx,
                      }}
                    >
                      {cellBuilders[`${headerItem.key}`](row)}
                    </StyledTableCell>
                  );
                } else {
                  return (
                    <TableCellRoot
                      key={headerItem.key}
                      sx={{
                        position: "sticky",
                        zIndex: 500,
                        bottom: 0,
                        backgroundColor: "#fff",
                        borderBottom: "0px",
                        padding: "6px 16px",
                        ...cellProps?.[headerItem.key]?.sx,
                      }}
                      value={row ? row[`${headerItem.key}`] : ""}
                      type={headerItem.key}
                    ></TableCellRoot>
                  );
                }
              })}

              {rightHeaders &&
                rightHeaders.map((headerItem, rightIndex) => {
                  if (cellBuilders && cellBuilders[headerItem.key]) {
                    return (
                      <StyledTableCell
                        className="right-sticky-cell"
                        key={headerItem.key}
                        sx={{
                          borderBottom: "0px",
                          right: `${
                            120 * (rightHeaders.length - 1 - rightIndex)
                          }px`,
                          //   rightIndex === rightHeaders.length - 1
                          //     ? 0
                          //     : rightHeaders[rightIndex + 1].width,
                          position: "sticky",
                          zIndex: 700,
                          bottom: 0,
                          minWidth: headerItem.width,
                          maxWidth: headerItem.width,
                          backgroundColor: "#fff",
                          ...cellProps?.[headerItem.key]?.sx,
                        }}
                      >
                        {cellBuilders[headerItem.key](row)}
                      </StyledTableCell>
                    );
                  } else {
                    return (
                      <TableCellRoot
                        className="right-sticky-cell"
                        key={headerItem.key}
                        sx={{
                          borderBottom: "0px",
                          // right:
                          //   rightIndex === rightHeaders.length - 1
                          //     ? 0
                          //     : rightHeaders[rightIndex + 1].width,
                          right: `${
                            120 * (rightHeaders.length - 1 - rightIndex)
                          }px`,

                          padding: "6px 16px",
                          position: "sticky",
                          zIndex: 700,
                          bottom: 0,
                          minWidth: headerItem.width,
                          maxWidth: headerItem.width,
                          backgroundColor: "#fff",
                          ...cellProps?.[headerItem.key]?.sx,
                          borderLeft:
                            rightIndex === 0
                              ? `1px solid ${theme.palette.neutral["015"]}`
                              : "none",
                        }}
                        value={row ? row[`${headerItem.key}`] : ""}
                        type={headerItem.key}
                      ></TableCellRoot>
                    );
                  }
                })}
            </TableRowRoot>
          );
        })}
    </TableFooter>
  );
};
const TableHeadStyled = styled(MuiTableHead)<TableHeadProps>(({ theme }) => {
  return {
    ...theme.typography.caption,
  };
});
