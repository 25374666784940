import React from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

// Legacy Imports
import { helpers } from "layouts/helpers";

import { MqttProvider } from "controller/context/MqttContext";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";

export const BillingLayout = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        width: "100%",
      }}
    >
      {" "}
      <Helmet>
        <title>DeskDay | Billing</title>
      </Helmet>
      <Outlet />
    </Box>
  );
};
