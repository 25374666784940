import { Box } from "@mui/material";
import {
  Avatar,
  AvatarGroup,
  AvatarGroupCircle,
  MembersModal,
} from "components";
import React, { useState } from "react";
import { Assignees } from "shared/types";

export const AssigneesBlock = ({
  assignees,
}: {
  assignees: Array<Assignees>;
}) => {
  const [isAssigneesModalOpen, setIsAssigneesModalOpen] = useState(false);
  return (
    <>
      <Box
        component="button"
        onClick={() => setIsAssigneesModalOpen(true)}
        sx={{ bgcolor: "transparent", border: 0, cursor: "pointer" }}
      >
        <AvatarGroupCircle
          dataList={assignees}
          mappingKeys={{
            firstName: "first_name",
            lastName: "last_name",
            src: "profile_image",
          }}
        />
      </Box>
      {isAssigneesModalOpen && (
        <MembersModal
          members={assignees}
          open={isAssigneesModalOpen}
          onClose={() => setIsAssigneesModalOpen(false)}
        />
      )}
    </>
  );
};
