import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, DeleteIcon, EditableChip, Input, Tab, Tabs } from "components";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// @ts-ignore
import searchWebImg from "assets/images/chartImgs/searchWebImg.svg";

export const ProjectTemplateStep3 = forwardRef(
  (
    {
      projectData,
      onSubmitData,
    }: { projectData: any; onSubmitData: (data: any) => void },
    ref
  ) => {
    const [milestones, setMilestones] = useState(
      Array.isArray(projectData?.milestones)
        ? projectData?.milestones
        : [...tempData]
    );
    const [milestoneIndex, setMilestoneIndex] = useState(0);
    const theme = useTheme();

    const onSwitchMilestone = (event: any, index: number) => {
      setMilestoneIndex(index);
    };

    const onDeleteMilestone = (index: number) => {
      const newMilestones = [...milestones];
      newMilestones.splice(index, 1);
      if (milestoneIndex > 0) {
        setMilestoneIndex((oldMilestoneIndex) => oldMilestoneIndex - 1);
      }
      setMilestones(newMilestones);
    };

    const onAddNewMilestone = () => {
      setMilestoneIndex(milestones.length - 1 > 0 ? milestones.length - 1 : 0);
      setMilestones([
        ...milestones,
        {
          milestone_name: `Milestone ${milestones.length + 1}`,
          tasks: [
            {
              task_name: "Task 1",
              status: "new",
            },
          ],
        },
      ]);
    };

    const onUpdateMilestoneLabel = (data: string, index: number) => {
      const newMilestones = [...milestones];
      newMilestones[index] = {
        ...newMilestones[index],
        milestone_name: data,
      };
      setMilestones(newMilestones);
    };

    const onSubmit = () => {
      const sortedData = milestones.map((milestone: any, index: number) => ({
        ...milestone,
        order: index,
        tasks: milestone.tasks.map((task: any, index: number) => ({
          ...task,
          order: index,
        })),
      }));

      onSubmitData({
        milestones: sortedData,
      });
    };

    useImperativeHandle(ref, () => ({ onSubmit }));

    return (
      <Stack
        direction="row"
        sx={{
          width: "100%",
          height: "100%",
          flex: 1,
          overflowY: "clip",
        }}
      >
        {/* left side */}
        <Stack
          sx={{
            width: "310px",
            overflowY: "clip",
            borderRight: `1px solid ${theme.palette.neutral["015"]}`,
          }}
          divider={<Divider flexItem />}
        >
          <Stack
            flex={1}
            sx={{
              overflowY: "scroll",
              display: "block",
            }}
            divider={<Divider flexItem />}
          >
            {milestones.length > 0 ? (
              <Tabs
                value={milestoneIndex}
                orientation="vertical"
                onChange={onSwitchMilestone}
              >
                {milestones.map((milestone: any, index: number) => (
                  <Tab
                    key={`milestones_tab_${index}`}
                    label={
                      <Stack width={"100%"} direction={"row"} gap={"8px"}>
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            textTransform: "none",
                          }}
                        >
                          <EditableChip
                            value={milestone.milestone_name}
                            onEdit={(newData) =>
                              onUpdateMilestoneLabel(newData, index)
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            pt: "4px",
                            cursor: "pointer",
                            height: "fit-content",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            onDeleteMilestone(index);
                          }}
                        >
                          <DeleteIcon sx={{ color: "black" }} />
                        </Box>
                      </Stack>
                    }
                  />
                ))}
              </Tabs>
            ) : (
              <Stack
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={searchWebImg}
                  alt="search web"
                  style={{ width: "100%", maxWidth: "110px", aspectRatio: 1 }}
                />
                <Typography variant={"body-medium"}>
                  No milestones added yet
                </Typography>
              </Stack>
            )}
          </Stack>
          <Box
            sx={{
              width: "100%",
              p: "16px",
            }}
          >
            <Button fullWidth onClick={onAddNewMilestone}>
              Add new milestone
            </Button>
          </Box>
        </Stack>

        {/* Right side - milestone body */}
        <Box
          sx={{
            flex: 1,
            // px: "24px",
            // overflowY: "scroll",
          }}
        >
          {milestones.length > 0 ? (
            <MilestoneContent
              onChange={(newTask) => {
                const newMilestones = [...milestones];
                newMilestones[milestoneIndex] = {
                  ...newMilestones[milestoneIndex],
                  tasks: newTask,
                };
                setMilestones([...newMilestones]);
              }}
              tasks={milestones?.[milestoneIndex]?.tasks || []}
            />
          ) : (
            <Stack
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                flex: 1,
              }}
            >
              <img
                src={searchWebImg}
                alt="search web"
                style={{ width: "100%", maxWidth: "110px", aspectRatio: 1 }}
              />
              <Typography variant={"body-medium"}>
                No tasks added yet
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
    );
  }
);

const MilestoneContent = ({
  tasks,
  onChange,
}: {
  tasks: Array<any>;
  onChange: (newTask: Array<any>) => void;
}) => {
  const theme = useTheme();

  const onAddTask = () => {
    onChange([
      ...tasks,
      { task_name: `Task ${tasks.length + 1}`, status: "new" },
    ]);
  };
  const onDeletetask = (index: number) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    onChange(newTasks);
  };

  const onUpdateTask = (data: string, index: number) => {
    const newTasks = [...tasks];
    newTasks[index] = {
      ...newTasks[index],
      task_name: data,
    };

    onChange(newTasks);
  };
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
      }}
      divider={<Divider flexItem />}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={"12px"}
        px={"32px"}
      >
        <Typography>Task</Typography>
        <Button onClick={onAddTask}>Add task</Button>
      </Stack>
      {tasks.length > 0 ? (
        <Stack
          sx={{
            overflowY: "scroll",
            borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
          }}
          divider={<Divider flexItem />}
        >
          {tasks.map((task, index) => (
            <Stack
              direction={"row"}
              key={`task_index_${index}`}
              sx={{
                py: "16px",
                px: "32px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <EditableChip
                value={task.task_name}
                onEdit={(newData) => onUpdateTask(newData, index)}
                bgColor={theme.palette.neutral["010"]}
              /> */}
              <Input
                name="task_name"
                value={task.task_name}
                onChange={(event) => onUpdateTask(event.target.value, index)}
                hideHelperBox={true}
                sx={{
                  flex: 1,
                  maxWidth: "50%",
                  minWidth: "220px",
                }}
                inputProps={{ maxLength: 100 }}
              />

              <IconButton onClick={() => onDeletetask(index)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            flex: 1,
          }}
        >
          <img
            src={searchWebImg}
            alt="search web"
            style={{ width: "100%", maxWidth: "110px", aspectRatio: 1 }}
          />
          <Typography variant={"body-medium"}>No tasks added yet</Typography>
        </Stack>
      )}
    </Stack>
  );
};

const tempData = [
  {
    milestone_name: "Milestone 1",
    tasks: [
      {
        task_name: "Task 1",
        status: "new",
      },
    ],
  },
];
