function devicesCountIcon(props) {
  return (
    <svg
      width="90%"
      height="83%"
      viewBox="0 0 90 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M69.525 56.959L90 21.7456V74.0512V83H0V2.46092H9V58.5698L33.75 15.8841L63 32.7973L82.08 0L89.865 4.47439L66.33 44.9677L37.035 28.1887L10.395 74.0512H20.565L40.32 40.2248L69.525 56.959Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
    </svg>

  );
}
export default devicesCountIcon;