import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createProjectTemplate,
  deleteProjectTemplates,
  getAllProjectTmeplates,
  getProjectTemplateById,
  updateProjectTemplate,
} from "controller/api";
import { ApiErrorType, GetQueryProps, UpdateTemplateInput } from "shared";
import { sentryLogger } from "utilz";

export const useGetAllProjectTemplates = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["projectTemplates", searchQuery],
    queryFn: () =>
      getAllProjectTmeplates({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllProjectTmeplates",
      }),
  });
};

export const useGetProjectTemplateById = (templateId: string) => {
  return useQuery({
    enabled: !!templateId,
    queryKey: ["projectTemplates", templateId],
    queryFn: () => getProjectTemplateById(templateId),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getProjectTemplateById",
      }),
  });
};

export const useGetProjectTemplatedByIdUsingMutation = () => {
  return useMutation(
    (templateId: string) => getProjectTemplateById(templateId),
    {
      onSuccess(data, variables, context) {},
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "getProjectTemplateById",
        });
      },
    }
  );
};

export const useCreateProjectTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => createProjectTemplate(data), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["projectTemplates"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "createProjectTemplate",
      });
    },
  });
};

export const useUpdateProjectTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UpdateTemplateInput) => updateProjectTemplate(data),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["projectTemplates"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateProjectTemplate",
        });
      },
    }
  );
};

export const useDeleteProjectTemplates = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteProjectTemplates, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["projectTemplates"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteProjectTemplates",
      });
    },
  });
};
