import dayjs from "dayjs";
import { AttachmentFileData, pinnedChatColors, DisabledStatus } from "shared";
import { create } from "zustand";

import { immer } from "zustand/middleware/immer";
import { createJSONStorage, persist } from "zustand/middleware";
import { getFullName } from "utilz/helpers";

interface PinneChatItemData {
  serviceId: string;
  serviceType: "ticket" | "task";
  messages?: Array<any>;
  assignees?: Array<any>;
  serviceUid: string;
  customerName?: string;
  customerUserName?: string;
  summary: string;
  messageType: "self" | "private" | "public";
  colorCodePrimary: string;
  colorCodeTint: string;
  projectId: string;
  serviceStatus: string;
  profileImage?: string;
  isOpen?: boolean;
  sourceName?: string;
  sourceId?: string;
}
interface pinColor {
  primary: string;
  tint: string;
}

interface PinnedChatState {
  pinnedChat: Record<string, PinneChatItemData>;
  nonPinnedChatColors: Array<pinColor>;
  showPinnedChats: boolean;
  selectedPinnedChatServiceId?: string | null;
}

interface PinnedChatAction {
  setPinnedChats: (data: Array<any>) => void;
  addPinnedChat: (data: PinneChatItemData) => void;
  closeOrReopenPinnedChat: (serviceId: string, statusId: string) => void;
  removePinnedChat: (serviceId: string) => void;
  updatePinnedChatData: ({
    data,
    serviceId,
  }: {
    serviceId: string;
    data: {
      key:
        | "serviceType"
        | "messages"
        | "assignees"
        | "serviceUid"
        | "customerName"
        | "customerUserName"
        | "summary"
        | "messageType"
        | "isOpen";
      value: any;
    };
  }) => void;
  toggleShowPinnedChats: () => void;
  setSelectedPinnedChat: (serviceId?: string) => void;
}

export interface PinnedDataInterface
  extends PinnedChatState,
    PinnedChatAction {}

export const pinnedChatSlice = immer<PinnedChatState & PinnedChatAction>(
  (set, get) => ({
    pinnedChat: {},
    nonPinnedChatColors: pinnedChatColors,
    showPinnedChats: true,
    selectedPinnedChatServiceId: null,
    setPinnedChats(data) {
      set((state) => {
        const pinnedChat: Record<string, PinneChatItemData> = {};
        const nonPinnedChatColors = [...pinnedChatColors];
        data.forEach((item) => {
          if (item?.service_id) {
            const index = nonPinnedChatColors.findIndex(
              (color) => color.primary === item?.color_code_primary
            );
            pinnedChat[item.service_id] = {
              serviceId: item?.service_id,
              serviceType: item?.service_type,
              serviceUid: item?.u_id,
              summary: item?.service_summary,
              assignees: item?.assignees || [],
              customerName: item?.customer_name,
              messages: [],
              profileImage: item?.profile_image,
              customerUserName: getFullName(
                item?.customer_user_first_name,
                item?.customer_user_middle_name,
                item?.customer_user_last_name
              ),
              messageType: "private",
              colorCodePrimary:
                index >= 0
                  ? nonPinnedChatColors[index].primary
                  : pinnedChatColors[0].primary,
              colorCodeTint:
                index >= 0
                  ? nonPinnedChatColors[index].tint
                  : pinnedChatColors[0].tint,
              projectId: item?.project_id,
              serviceStatus:
                item?.service_type === "task"
                  ? item?.task_status
                  : item?.ticket_status,
              isOpen: false,
              sourceId: item?.source_id,
              sourceName: item?.source_name,
            };
            index >= 0
              ? nonPinnedChatColors.splice(index, 1)
              : nonPinnedChatColors.splice(0, 1);
          }
        });
        state.nonPinnedChatColors = [...nonPinnedChatColors];
        state.pinnedChat = pinnedChat;
      });
    },

    updatePinnedChatData({ data, serviceId }) {
      set((state) => {
        state.pinnedChat[serviceId] = {
          ...state.pinnedChat[serviceId],
          [data.key]: data.value,
        };
      });
    },

    addPinnedChat: (data: PinneChatItemData) => {
      set((state) => {
        if (Object.entries(state.pinnedChat).length < 7) {
          state.pinnedChat[data.serviceId] = data;
          const nonPinnedChatColors = state.nonPinnedChatColors || [];
          nonPinnedChatColors?.splice(0, 1);
          state.nonPinnedChatColors = [...nonPinnedChatColors];
        }
      });
    },
    removePinnedChat: (serviceId: string) => {
      set((state) => {
        state.nonPinnedChatColors = [
          ...(state.nonPinnedChatColors || []),
          {
            primary: state.pinnedChat[serviceId].colorCodePrimary,
            tint: state.pinnedChat[serviceId].colorCodeTint,
          },
        ];
        if (state.selectedPinnedChatServiceId === serviceId) {
          state.selectedPinnedChatServiceId = undefined;
        }
        delete state.pinnedChat[serviceId];
      });
    },
    closeOrReopenPinnedChat(serviceId: string, statusId: string) {
      set((state) => {
        try {
          const pinnedChat = state.pinnedChat[serviceId];

          if (!pinnedChat) {
            return;
          }
          /// if chat is not closed or cancelled and alert notification status is closed or cancelled
          if (
            (DisabledStatus.includes(pinnedChat.serviceStatus) &&
              !DisabledStatus.includes(statusId)) ||
            (!DisabledStatus.includes(pinnedChat.serviceStatus) &&
              DisabledStatus.includes(statusId))
          ) {
            pinnedChat.serviceStatus = statusId;
          }
          state.pinnedChat[serviceId] = pinnedChat;
        } catch (e: any) {}
      });
    },
    toggleShowPinnedChats() {
      set((state) => {
        state.showPinnedChats = !state.showPinnedChats;
        state.selectedPinnedChatServiceId = undefined;
      });
    },
    setSelectedPinnedChat(serviceId) {
      set((state) => {
        state.selectedPinnedChatServiceId = serviceId;
      });
    },
  })
);

export const usePinnedStore = create<PinnedDataInterface>()((...a) => ({
  ...pinnedChatSlice(...a),
}));
