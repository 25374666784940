import { Stack, Typography, useTheme } from "@mui/material";
import { RadioButtonGroup } from "components";
import { useState } from "react";
import { DownloadBtn } from "./DownloadBtn";

export const DownloadDrawerRadioBtns = ({
  radioMenuData,
  closeDownloadDrawer,
}: {
  radioMenuData: {
    label: string;
    value: string;
    isComingSoon?: boolean;
  }[];
  closeDownloadDrawer: () => void;
}) => {
  const theme = useTheme();

  const [selectedDownloadOption, setSelectedDownloadOption] = useState(
    radioMenuData[0].value
  );

  const radioMenu = radioMenuData.map((menuItem) => ({
    label: (
      <Stack direction="row" gap="16px" alignItems={"center"}>
        <Typography variant="body-medium" ml="16px">
          {menuItem?.label}
        </Typography>
        {menuItem?.isComingSoon && (
          <Typography
            p="4px 16px"
            borderRadius={"6px"}
            border={`1px solid ${theme.palette.secondary["purple-tint"]}`}
            color={theme.palette.secondary["purple-tint"]}
          >
            Coming Soon
          </Typography>
        )}
      </Stack>
    ),
    value: menuItem?.value,
    ...(menuItem?.isComingSoon && {
      radioButtonProps: {
        disabled: true,
      },
    }),
  }));

  return (
    <>
      <Stack gap="24px">
        <RadioButtonGroup
          data={radioMenu}
          labelMappingKey="label"
          valueMappingKey="value"
          onChange={(value) => {
            console.log("value", value);
            setSelectedDownloadOption(value);
          }}
          value={selectedDownloadOption}
          radioButtonStyle={{
            p: "0px",
            ":hover": {
              bgcolor: "transparent",
            },
          }}
          RadioGroupProps={{
            gap: "24px",
            "& .MuiFormControlLabel-root": {
              mx: "0",
            },
          }}
        />
      </Stack>
      <DownloadBtn
        variant={selectedDownloadOption}
        closeDownloadDrawer={closeDownloadDrawer}
      />
    </>
  );
};
