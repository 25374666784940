import { SvgIcon, SvgIconProps } from "@mui/material";

export const StarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#767B85"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="m12 2.896 2.935 5.993 6.565.967-4.75 4.662 1.121 6.586L12 17.993l-5.871 3.111 1.121-6.586L2.5 9.856l6.564-.967L12 2.896Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
