export const tableHeaders = {
  name: {
    key: "control-center-locations-table-header-name-key",
    label: "Name",
    map: "location_name",
    sortable: false,
    size: { xs: "100px", sm: "100px", md: "100px", lg: "100px" },
    spacing: {
      left: { xs: "13px", sm: "13px", md: "13px", lg: "13px" },
      right: { xs: "32px", sm: "32px", md: "32px", lg: "32px" }
    }
  },
  contact_person: {
    key: "control-center-locations-table-header-contact_person-key",
    label: "Contact Person",
    map: "contact_person",
    sortable: false,
    size: { xs: "120px", sm: "120px", md: "120px", lg: "120px" },
    spacing: {
      left: { xs: "32px", sm: "32px", md: "32px", lg: "32px" },
      right: { xs: "32px", sm: "32px", md: "32px", lg: "32px" }
    }
  },
  email: {
    key: "control-center-locations-table-header-email-key",
    label: "Email",
    map: "email",
    sortable: false,
    size: { xs: "100px", sm: "100px", md: "100px", lg: "100px" },
    spacing: {
      left: { xs: "32px", sm: "32px", md: "32px", lg: "32px" },
      right: { xs: "32px", sm: "32px", md: "32px", lg: "32px" }
    }
  },
  city: {
    key: "control-center-locations-table-header-city-key",
    label: "City",
    map: "city",
    sortable: false,
    size: { xs: "100px", sm: "100px", md: "100px", lg: "100px" },
    spacing: {
      left: { xs: "32px", sm: "32px", md: "32px", lg: "32px" },
      right: { xs: "32px", sm: "32px", md: "32px", lg: "32px" }
    }
  },
  state: {
    key: "control-center-locations-table-header-state-key",
    label: "State",
    map: "state",
    sortable: false,
    size: { xs: "100px", sm: "100px", md: "100px", lg: "100px" },
    spacing: {
      left: { xs: "32px", sm: "32px", md: "32px", lg: "32px" },
      right: { xs: "32px", sm: "32px", md: "32px", lg: "32px" }
    }
  },
  country: {
    key: "control-center-locations-table-header-country-key",
    label: "Country",
    map: "country",
    sortable: false,
    size: { xs: "100px", sm: "100px", md: "100px", lg: "100px" },
    spacing: {
      left: { xs: "32px", sm: "32px", md: "32px", lg: "32px" },
      right: { xs: "0px", sm: "0px", md: "0px", lg: "0px" }
    }
  },
};