import { convertToHours } from "utilz/helpers/formatTimeDuration";
import { GENERAL_FORM_CONSTANT } from "../../components/general-info-form/constants";
import { TICKET_DETAILS_CONSTANT } from "../../components/ticket-details-form/constants";
export default function ticketTemplateFormBuilder(data = {}) {
  var newData = {};
  Object.values(GENERAL_FORM_CONSTANT).map((item) => {
    if (typeof item.defaultValue === "function") {
      newData[item.name] = data[item.data_name] || [];
    } else {
      newData[item.name] = data[item.data_name] || item.defaultValue;
    }
  });
  Object.values(TICKET_DETAILS_CONSTANT).map((item) => {
    if (item["data_name"] !== undefined) {
      newData[item.name] = data[item.data_name] || item.defaultValue;
    }
  });

  newData[TICKET_DETAILS_CONSTANT.tags.name] = (
    data[TICKET_DETAILS_CONSTANT.tags.data_name] ||
    TICKET_DETAILS_CONSTANT.tags.defaultValue
  ).map((item) => ({
    key: item.mapping_id,
    value: item.mapping_id,
    label: item.tag_name,
  }));

  Object.values(TICKET_DETAILS_CONSTANT.top).map((item) => {
    if (item["data_name"] !== undefined) {
      newData[item.name] = data[item.data_name] || item.defaultValue;
    }
  });


  newData[TICKET_DETAILS_CONSTANT.top.budgeted_hours.name] = convertToHours(
    data[TICKET_DETAILS_CONSTANT.top.budgeted_hours.name] || {}
  );

  if (data["mapping_id"] !== undefined) {
    newData["mapping_id"] = data["mapping_id"];
  }

  //ticket_sub_type
  // console.log("🚀 ~ file: index.js:27 ~ ticketTemplateFormBuilder ~ data:", data)
  return newData;
}
