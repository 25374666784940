import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  Avatar,
  AvatarGroupCircle,
  CloseIcon,
  FullScreenIcon,
  MinusIcon,
  PinFilledIcon,
  Tooltip,
} from "components";
import { usePinnedStore } from "controller/store/pinned-chat/pinnedChatStore";
import React from "react";
import { ChatTimeEntryTimer } from "./ChatTimeEntryTimer";
import {
  useDeletePinService,
  useNonPersistantMainStore,
  useTimeEntryStore,
} from "controller";
import { useNavigate } from "react-router-dom";
import { DisabledStatus } from "shared/data";

export const ChatHeader = ({
  onMinimize,
  serviceId,
  serviceType,
}: {
  onMinimize: () => void;
  serviceType: "ticket" | "task";
  serviceId: string;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const pinnedChat = usePinnedStore((state) => state.pinnedChat[serviceId]);
  const {
    mutateAsync: deletePinnedService,
    isLoading: isRemovingPinnedService,
  } = useDeletePinService();
  const removePinnedChat = usePinnedStore((state) => state.removePinnedChat);

  const onFullScreenButtonClick = () => {
    switch (serviceType) {
      case "ticket":
        navigate(`/app/service-desk/tickets/${serviceId}`);
        break;
      case "task":
        navigate(
          `/app/service-desk/projects/${pinnedChat.projectId}/task/${serviceId}`
        );
        break;
      default:
        return;
    }
    onMinimize();
  };

  const unPinChat = async () => {
    try {
      await deletePinnedService({ serviceId, serviceType });
      removePinnedChat(serviceId);
    } catch (e) {}
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        p: "12px 16px",
        backgroundColor: pinnedChat.colorCodeTint,
        height: "56px",
        position: "relative",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            bgcolor: pinnedChat.colorCodePrimary,
            borderRadius: "100%",
          }}
        >
          <Avatar
            className="avatar"
            src={pinnedChat.profileImage}
            fullname={pinnedChat.customerUserName}
            sx={{
              backgroundColor: "#ffffffc9",
              borderColor: pinnedChat.colorCodePrimary,
            }}
          />
        </Box>

        <Stack>
          <Typography
            variant="subtitle1"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {pinnedChat.summary}
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            width={"100%"}
            maxWidth={"260px"}
            divider={
              <Box
                sx={{
                  width: "2px",
                  height: "2px",
                  aspectRatio: "1",
                  borderRadius: "50%",
                  bgcolor: theme.palette.lightBg.low,
                  mx: "8px",
                  display: "inline-flex",
                }}
              ></Box>
            }
          >
            <Typography
              variant="caption-xs"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {pinnedChat.serviceUid}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        className="headers"
        direction={"row"}
        sx={{ gap: "8px", alignItems: "center" }}
      >
        <Tooltip title="Unpin Chat" placement="top" followCursor={true}>
          <Box>
            <PinFilledIcon
              sx={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                color: pinnedChat.colorCodePrimary,
              }}
              onClick={unPinChat}
            />
          </Box>
        </Tooltip>
        <Tooltip title="Maximize Chat" placement="top" followCursor={true}>
          <Box>
            <FullScreenIcon
              sx={{ cursor: "pointer", width: "22px", height: "22px" }}
              onClick={onFullScreenButtonClick}
            />
          </Box>
        </Tooltip>
        <Tooltip title="Minimize Chat" placement="top" followCursor={true}>
          <Box>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={onMinimize} />
          </Box>
        </Tooltip>
      </Stack>

      {
        <Box
          sx={{
            position: "absolute",
            bottom: "-34px",
            right: "8px",
            filter: `drop-shadow(0px 0px 2px ${theme.palette.neutral["020"]})`,
            zIndex: 2,
          }}
        >
          <ChatTimeEntryTimer
            disable={DisabledStatus.includes(pinnedChat.serviceStatus)}
            serviceId={serviceId}
            serviceType={serviceType}
          />
        </Box>
      }
    </Stack>
  );
};
