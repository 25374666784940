import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Constants imports
import { TOASTER_CONSTANTS } from "./constants";

const CustomToaster = (props)=> {
  // States
  const [state, setState] = useState({
    open: false,
    severity: TOASTER_CONSTANTS?.severity["SUCCESS"],
    anchorVertical: TOASTER_CONSTANTS?.anchor?.VERTICAL["TOP"],
    anchorHorizontal: TOASTER_CONSTANTS?.anchor?.HORIZONTAL["RIGHT"],
    message: "",
    key: ""
  });

  // Effects
  useEffect(()=> {
    try{
      setState({
        ...state,
        open: props?.open,
        severity: TOASTER_CONSTANTS?.severity[`${props?.severity}`] || TOASTER_CONSTANTS?.severity["SUCCESS"],
        message: props?.message,
        key: v4()
      });
    }catch(err) {
      console.log("[ERROR] Snackbar Toaster mounts");
      console.log(err);
    }
  }, [props]);

  // Handlers
  const hideToaster = ()=> {
    setState({
      ...state, 
      open: false, 
      severity:TOASTER_CONSTANTS?.severity["SUCCESS"], 
      message: "",
      key: v4()
    });
    props?.callback();
  };

  // Renderer
  return(
    <React.Fragment>
      <Snackbar 
        open={state["open"]} 
        anchorOrigin= {{vertical: state["anchorVertical"],horizontal: state["anchorHorizontal"],}}
        autoHideDuration = {TOASTER_CONSTANTS["autoHideDuration"]} 
        onClose = {hideToaster}
      >
        <Alert 
          onClose={hideToaster} 
          // variant = {'outlined'}
          severity = {state["severity"]} 
          sx={{ width: "100%" }}
        >
          {state["message"]}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

CustomToaster.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
}; 

CustomToaster.defaultProps = {
  open: false,
  severity: "SUCCESS",
  message: "",
  callback: ()=> {}
};

export default CustomToaster;