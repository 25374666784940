import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { MessageListCard } from "./MessageListCard";
import { Chat } from "shared/features/chat/Chat";
import { useChatNotificationStore, useGlobalStore } from "controller";
import customerSurveyImg from "assets/images/chartImgs/customerSurveyImg.svg";
import { Assignees } from "shared/types";
import { CloseIcon } from "components";
import { TicketChatContainer } from "./TicketChatContainer";
import { TaskChatContainer } from "./TaskChatContainer";
import { useUpdateChatNotificationStatus } from "controller/hooks/notification";
import NoChatImg from "assets/images/noChat.png";

export const MainChatNotificationContainer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const resourceId = useGlobalStore((state) => state.resourceId);
  const selectedChat = useChatNotificationStore(
    (state) => state.selectedChatNotification
  );

  const setSelectedChat = useChatNotificationStore(
    (state) => state.setSelectedChatNotification
  );
  const allChatMessages = useChatNotificationStore(
    (state) => state.allChatNotifications
  );
  const setChatNotificationAsSeen = useChatNotificationStore(
    (state) => state.setChatNotificationAsSeen
  );
  const { mutateAsync: updateChatNotification } =
    useUpdateChatNotificationStatus();
  useEffect(() => {
    !isOpen && setSelectedChat(null);
  }, [isOpen]);

  return (
    <Stack
      sx={{
        height: "100%",
        flexDirection: "row",
        gap: "12px",
        background: "#0000001A",
        boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 10.5px )",
        WebkitBackdropFilter: "blur( 10.5px )",
        py: "8px",
        pr: "8px",
      }}
    >
      <Stack
        sx={{
          height: "100%",
          width: "364px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
          }}
        >
          <Box
            px="24px"
            paddingY="12px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            // borderBottom={`1px solid ${theme.palette.neutral["010"]}`}
          >
            <Typography
              component={"span"}
              variant={"h5"}
              sx={{ textTransform: "capitalize" }}
            >
              Messages
            </Typography>
            {!selectedChat && (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box sx={{ overflowY: "scroll", flexGrow: 1 }}>
          {!allChatMessages || allChatMessages.length <= 0 ? (
            <Stack
              flex={1}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <img
                src={NoChatImg}
                alt=""
                width={"100%"}
                height={"100%"}
                style={{
                  objectFit: "contain",
                  maxHeight: "170px",
                  maxWidth: "170px",
                }}
              />
              <Typography variant="button-small">
                All clear! No new messages for you.
              </Typography>
            </Stack>
          ) : (
            allChatMessages?.map((item: any, index) => (
              <MessageListCard
                isSelected={selectedChat?.service_id === item.service_id}
                key={index}
                chatData={item}
                onClick={() => {
                  if (selectedChat?.service_id !== item.service_id) {
                    updateChatNotification({
                      resourceId: resourceId || "",
                      notificationId: item.notification.notification_id,
                    });
                    setChatNotificationAsSeen(item.service_id);
                    setSelectedChat(item);
                  }
                }}
                onClose={onClose}
              />
            ))
          )}
        </Box>
      </Stack>
      {selectedChat && (
        <Box
          sx={{
            width: "755px",
            height: "100%",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          {selectedChat.service_type === "ticket" ? (
            <TicketChatContainer
              selectedChat={selectedChat}
              onClose={() => setSelectedChat(null)}
            />
          ) : selectedChat.service_type === "task" ? (
            <TaskChatContainer
              selectedChat={selectedChat}
              onClose={() => setSelectedChat(null)}
            />
          ) : null}
        </Box>
      )}
    </Stack>
  );
};
