import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CustomTypography } from "../../../../../../library/base";
import { TabsList, Tab } from "./design-constants/";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { TAB_CONSTANT } from "./constants";
import { ReturnContext } from "../../../../../../contexts/return-context";
const ServiceDeskConfigurationTemplatesComponent = (props) => {
  const { setEnableReturn } = useContext(ReturnContext);

  useEffect(() => { setEnableReturn(true); }, [props]);

  const [state, setState] = useState({
    selectedTab: TAB_CONSTANT.TICKET.map,
  });


  const changeSelectedTab = (event, name) => {
    if (state.selectedTab !== name) {
      setState({ ...state, selectedTab: name });
    }
  };
  return (
    <React.Fragment>

      <Box
        sx={{
          marginBottom: {
            xl: "140px",
            lg: "80px",
            md: "80px",
            sm: "60px",
          }
        }}
      >
        <Box
          sx={{
            px: "32px",
            py: "24px",
          }}>
          <CustomTypography
            content="Templates"
            size="MEDIUM-ALPHA"
            variant="BOLD"
          />
        </Box>
        <TabsUnstyled
          value={state?.selectedTab}
          onChange={changeSelectedTab}
        >
          <TabsList>
            {Object.values(TAB_CONSTANT).map((tab) => {
              return (
                <Tab
                  key={tab?.key}
                  className='typography-font-family-class-regular'
                  value={tab?.map}
                >
                  {tab?.label}
                </Tab>
              );
            })}
          </TabsList>
        </TabsUnstyled>
        <Box
          sx={{
            marginTop: "16px",
            px: "32px",
          }}
        >
          {
            TAB_CONSTANT[`${state.selectedTab}`].tab
          }
        </Box>


      </Box>

    </React.Fragment>
  );
};

export default ServiceDeskConfigurationTemplatesComponent;