import React from "react";

// Legacy Imports
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

// HOC Imports
import { CustomTypography } from "library/base";
import CustomIcon from "library/icon-library";

// Interface
interface TabSectionHeader {
  loaded: boolean;
  label: string;
  icon?: string;
  iconClick?: () => void;
}

export const TabSectionHeader: React.FC<TabSectionHeader> = ({
  loaded,
  label,
  icon,
  iconClick,
}) => {
  // Renderer
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {loaded ? (
          <CustomTypography
            size={"MEDIUM-BETA"}
            color={"PRIMARY-MAIN"}
            variant={"BOLD"}
            content={label}
          />
        ) : (
          <Skeleton height={40} width={"164px"} />
        )}
        {icon &&
          (loaded ? (
            <Box
              // add intercom class just to "edit company details" and not "location details"
              className={
                label === "Company Details"
                  ? "intercom-editcompanydetails-btn"
                  : ""
              }
            >
              <CustomIcon
                icon={icon}
                size={"lg"}
                color={"#1C1E26"}
                onClick={iconClick}
                sx={{ mr: "44px", "& :hover": { cursor: "pointer" } }}
              />
            </Box>
          ) : (
            <Skeleton variant="circular" width={40} height={40} />
          ))}
      </Box>
    </React.Fragment>
  );
};
