import { controlCenterservices } from "./control-center";
import { server } from "./be-services";
import { generalServices } from "./general";
import { assetServices } from "./asset-services";
import { populatorServices } from "./populator-services";

export const services = {
  controlCenterservices,
  server,
  generalServices,
  assetServices,
  populatorServices
};