function microsoftIcon(props) {
  const overLayOpasity = props.disabled === true ? 100 : 0;
  return (
    <svg
      width="90%"
      height="90%"
      viewBox="0 0 30 30"
      fill="#70717163"
      filter={`grayscale(${overLayOpasity}%)`}
      xmlns="http://www.w3.org/2000/svg"

      xmlnsXlink="http://www.w3.org/1999/xlink">

      <rect width="30" height="30" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1">
          <use xlinkHref="#image0_7318_170021" transform="scale(0.0416667)" />
        </pattern>
        <image
          id="image0_7318_170021"
          width="24"
          height="24"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAZ0lEQVRIie3UoRWAMAwE0DvaVUDwHrOh8N2CddgC0TEwmPZY4R4CQ+IvPxEJr4mCXSrrPgNicROD3/xdBRBAAB8AGZB99ug41BKYmp3Jy1b9cQSc94hOP5IpfwORpROAEM8ugAB+BTyeihkoGC02OgAAAABJRU5ErkJggg==" />
      </defs>

    </svg>

  );
}

export default microsoftIcon;


