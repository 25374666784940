import { Box, Divider, Stack, Typography } from "@mui/material";
import Quill from "quill";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import QuillToolBar from "./quillToolbar";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import { extractStringFromHtml } from "utilz/helpers";

const Delta = Quill.import("delta");
function CustomQuillEditor(props) {
  const onChangeHandler = (data) => {
    props.onChange({
      field: props.name,
      value: extractStringFromHtml(data).length > 0 ? data : "",
    });
  };

  return (
    // <ReactQuill theme="snow" value={value} onChange={onChangeHandler} />
    <Box
      sx={{
        width: "100%",
        // height: "100%",
        // height: 'inherit',
        flexDirection: "column",
        "& .ql-editor": {
          height: "300px",
          // height: "100%",
          flex: 1,
          // maxHeight: "100%",
          overflow: "clip",
          border: props.error ? "1px solid #EF4C54" : "1px solid #EDEDED",
          padding: "16px",
          margin: props?.error ? "8px 16px 16px 16px" : "16px",
          backgroundColor: "#F6F6F5",
          borderRadius: "4px",
        },
        "& .ql-toolbar.ql-snow": {
          p: "0px !important",
          m: "0px !important",
          borderTop: "1px solid #E3E3E3",
          borderBottom: "1px solid #E3E3E3",
          borderLeft: "none",
          borderRight: "none",
        },

        "& .ql-snow.ql-toolbar button": {
          borderRadius: "6px",
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "7px",
        },

        "& .ql-snow.ql-toolbar button.ql-active,.ql-snow .ql-toolbar button.ql-active,.ql-snow.ql-toolbar .ql-picker-label.ql-active,.ql-snow .ql-toolbar .ql-picker-label.ql-active,.ql-snow.ql-toolbar .ql-picker-item.ql-selected,.ql-snow .ql-toolbar .ql-picker-item.ql-selected":
          {
            backgroundColor: "#EDEDED",
            color: "black",
          },
        "& .ql-snow.ql-toolbar.ql-stroke": {
          color: "black",
        },
        "& .ql-container": {
          border: "none !important",
          minHeight: 0,
        },
        "& .ql-container.ql-snow": {
          backgroundColor: "#FFF",
          overflow: "scroll",
          height: " 100%",
          display: "flex",
          border: "none",
        },
        "& .ql-editor > p": {
          color: "#767B85",
        },
        "& .ql-editor.ql-blank::before": {
          paddingLeft: "16px",
        },
      }}
    >
      <div
        id="quill-editor"
        style={{
          width: "100%",
          height: "100%",
          // maxHeight: "100px",
        }}
      >
        <QuillToolBar />
        {props?.error && (
          <Typography pl="16px" mt="16px" variant="body-small" color={"error"}>
            {props?.errorMessage
              ? props?.errorMessage
              : "This is required field"}
          </Typography>
        )}

        <ReactQuill
          style={{
            height: "100%",
            overflow: "scroll",
            // border: props.error ? "1px solid #DA1E28" : "1px solid #d9d9d9",
            whiteSpace: "pre-line",
          }}
          theme="snow"
          value={props.value}
          onChange={onChangeHandler}
          placeholder={`${props.placeholder} ${props.isRequired ? "*" : ""}`}
          modules={{
            toolbar: {
              container: "#quill-toolbar",
            },
          }}
        />
      </div>
    </Box>
  );
}

CustomQuillEditor.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
};

CustomQuillEditor.defaultProps = {
  value: "",
  name: "",
  placeholder: "",
  onChange: () => {},
  isRequired: false,
  error: false,
};

export default CustomQuillEditor;
