// Component Imports
import { GoogleMailServerComponent, MicrosoftMailServerComponent, CustomMailServerComponent } from "../components";
// HOC Imports
import CustomIcon from "../../../../../../../../../library/icon-library";

// Constants
const ICONS = {GOOGLE: "google", MICROSOFT: "microsoft", CUSTOM: "templated"};

<CustomIcon
  size = {"sm"}
  icon = {"info"}
  color = {"#323232"}
/>;

export const MAIL_SERVER_DOMAINS = {
  MICROSOFT: {
    key: "MICROSOFT-LOGO-KEY",
    name: "microsoft",
    label: "Microsoft",
    icon_element: <CustomIcon size = {"xl"} icon = {ICONS["MICROSOFT"]} color = {"#323232"}/>,
    render: <MicrosoftMailServerComponent/>
  },
  GOOGLE: {
    key: "GOOGLE-LOGO-KEY",
    name: "google",
    label: "Google",
    icon_element: <CustomIcon size = {"xl"} icon = {ICONS["GOOGLE"]} color = {"#323232"}/>,
    render: <GoogleMailServerComponent/>
  },    
  CUSTOM: {
    key: "CUSTOM-LOGO-KEY",
    name: "custom",
    label: "Custom",
    icon_element: <CustomIcon size = {"xl"} icon = {ICONS["CUSTOM"]} color = {"#5563F5"}/>,
    render: <CustomMailServerComponent/>
  },
};
