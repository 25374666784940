import { Skeleton, Stack, useTheme } from "@mui/material";
import {
  ChartProps,
  CustomTooltip,
  LegendBuilder,
  TickLabel,
  barChartColor,
} from "../Utils";
import {
  ResponsiveContainer,
  ComposedChart,
  YAxis,
  AreaChart as ReAreaChart,
  Legend,
  Tooltip,
  Area,
  XAxis,
  CartesianGrid,
} from "recharts";

export const AreaChart = ({
  data = [],
  labelKey = "label",
  valueKey = "value",
  width,
  height,
  customTooltip,
  isLoading = false,
  showLegends = false,
  showAxisLines = false,
  showGrid = false,
}: ChartProps) => {
  const theme = useTheme();

  return isLoading ? (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        // Replace with your desired box background color
        width: "100%",
        height: "100%",
        gap: "8px",
      }}
    >
      <Stack
        flex={1}
        direction={"row"}
        width={"100%"}
        columnGap={"16px"}
        justifyContent={"baseline"}
        alignItems={"end"}
      >
        <Skeleton
          sx={{
            flex: 1,
            height: "100%",
            WebkitTransform: "unset",
            clipPath:
              " polygon(52% 57%, 77% 78%, 100% 34%, 100% 100%, 0 100%, 0 0, 26% 94%)",
          }}
        />
      </Stack>

      <Stack
        direction={"row"}
        height={"32px"}
        width={"100%"}
        overflow={"clip"}
        gap={"16px"}
      >
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} height={"100%"} width={"80%"} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <ResponsiveContainer width={width} height={height}>
      <ReAreaChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 10,
          bottom: 5,
        }}
      >
        {showGrid && (
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme.palette.neutral["010"]}
          />
        )}
        <YAxis
          width={40}
          padding={{ bottom: 0 }}
          tickCount={4}
          tickLine={false}
          axisLine={showAxisLines}
          dataKey={valueKey}
          tick={<TickLabel isXaxis={false} />}
          allowDecimals={false}
          stroke={theme.palette.neutral["010"]}
        />
        <XAxis
          dataKey={labelKey}
          tickLine={false}
          axisLine={showAxisLines}
          fontSize={theme.typography["body-small"].fontSize}
          fontWeight={theme.typography["body-small"].fontWeight}
          fontFamily={theme.typography["body-small"].fontFamily}
          color="black"
          allowDataOverflow={false}
          tick={<TickLabel isXaxis={true} />}
          stroke={theme.palette.neutral["010"]}
        />

        <Tooltip
          content={
            customTooltip || (
              <CustomTooltip labelKey={labelKey} valueKey={valueKey} />
            )
          }
          cursor={false}
        />
        <Area
          dataKey={valueKey}
          fill="#E7E9FE"
          stroke="#5563F5"
          strokeWidth={"2px"}
        />
        {showLegends && (
          <Legend
            content={LegendBuilder({ data, labelKey, color: barChartColor })}
          />
        )}
      </ReAreaChart>
    </ResponsiveContainer>
  );
};
