import { styled, useTheme } from "@mui/material/styles";
import MuiTableHead, { TableHeadProps } from "@mui/material/TableHead";
import React, { useEffect, useState } from "react";
import { TableRowRoot } from "./TableRowRoot";
import TableCell from "@mui/material/TableCell";
import CustomCheckBox from "../../library/base/custom-checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomIcon from "../../library/icon-library";
import Box from "@mui/material/Box";
import { VerticalArrowsIcon } from "components/icons";
import { IconButton, SxProps, Theme } from "@mui/material";
import { StyledTableCell } from "./StyledTableCell";

type HeaderProps = {
  name: string;
  sortable?: boolean;
  key: string;
  numeric?: boolean;
};

interface CustomTableHeadProps extends TableHeadProps {
  headers: Array<HeaderProps>;
  onAllSelect?: (currentState: "checked" | "unchecked") => void;
  onSortingClick?: (column: string) => void;
  selectedItems?: Array<string>;
  multiselect?: boolean;
  cellProps?: any;
  headerCellStyles?: SxProps<Theme>;
  headerCellBuilders?: Record<string, (headerdata?: any) => React.ReactNode>;
}
export const TableHeadRoot = ({
  headers,
  onAllSelect,
  onSortingClick,
  multiselect = true,
  cellProps,
  selectedItems,
  headerCellStyles,
  headerCellBuilders,
  ...rest
}: CustomTableHeadProps) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const theme = useTheme();
  // useEffect(() => {}, [selectedItems]);

  const HandleCheckboxClick = () => {
    setIsAllSelected((prev) => {
      onAllSelect && onAllSelect(!prev === true ? "checked" : "unchecked");
      return !prev;
    });
  };
  return (
    <TableHeadStyled {...rest}>
      <TableRowRoot header>
        {multiselect ? (
          <StyledTableCell
            sx={{ paddingLeft: "16px", width: "45px", ...headerCellStyles }}
          >
            <CustomCheckBox
              varient="primary"
              onClick={HandleCheckboxClick}
              checked={isAllSelected}
            />
          </StyledTableCell>
        ) : null}

        {headers.map((headCell) => (
          <StyledTableCell
            sx={{
              padding: "6px 16px",
              whiteSpace: "nowrap",
              fontSize: "14px",
              "& .sort-button": {
                visibility: "hidden",
              },
              "&:hover": {
                "& .sort-button": {
                  visibility: "visible",
                },
              },
              ...cellProps?.[headCell.key]?.sx,
              ...headerCellStyles,
            }}
            key={headCell.name}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headerCellBuilders && headerCellBuilders[headCell.key] ? (
              headerCellBuilders[headCell.key](headCell)
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "8px",
                  // color: theme.palette.lightBg.high,
                }}
              >
                {headCell.name}
                {/* TODO: add sorting */}
                {headCell.sortable && (
                  <IconButton
                    className="sort-button"
                    onClick={() =>
                      onSortingClick && onSortingClick(headCell.key)
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      p: 0,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <VerticalArrowsIcon />
                  </IconButton>
                )}
              </Box>
            )}
          </StyledTableCell>
        ))}
      </TableRowRoot>
    </TableHeadStyled>
  );
};
const TableHeadStyled = styled(MuiTableHead)<TableHeadProps>(({ theme }) => {
  return {
    ...theme.typography.caption,
    textTransform: "capitalize",
  };
});
