import dayjs from "dayjs";
import { ActiveTimeEntry, AttachmentFileData, Watchlist } from "shared";

import { immer } from "zustand/middleware/immer";
interface CommonDataState {
  attachmentModalData: {
    attachments: Array<any>;
  };
  perPageCount: {
    ticket: number;
    ping: number;
    timeEntry: number;
    project: number;
    user: number;
    customer: number;
    contract: number;
    recurringInvoice: number;
    archive: number;
    billingProduct: number;
    billingTemplate: number;
    billingTax: number;
    billingExpense: number;
    customerVendor: number;
    customerSites: number;
    customerUserGroup: number;
    location: number;
    tags: number;
    resource: number;
  };
}

interface CommonDataAction {
  resetAllCommonData: () => void;
  resetAttachmentModalData: () => void;
  setAttachmentModalData: (attachments: Array<AttachmentFileData>) => void;
  setPerPageCount: ({
    type,
    count,
  }: {
    type:
      | "ticket"
      | "ping"
      | "timeEntry"
      | "project"
      | "user"
      | "customer"
      | "recurringInvoice"
      | "archive"
      | "contract"
      | "billingTemplate"
      | "billingProduct"
      | "billingTax"
      | "billingExpense"
      | "customerVendor"
      | "customerSites"
      | "customerUserGroup"
      | "location"
      | "tags"
      | "resource";

    count: number;
  }) => void;
}

export interface CommonDataInterface
  extends CommonDataState,
    CommonDataAction {}

export const CommonDataSlice = immer<CommonDataState & CommonDataAction>(
  (set, get) => ({
    attachmentModalData: {
      attachments: [],
    },
    watchListData: [],
    perPageCount: {
      ticket: 50,
      project: 50,
      timeEntry: 0,
      ping: 50,
      user: 50,
      customer: 50,
      contract: 10,
      recurringInvoice: 50,
      archive: 50,
      billingTemplate: 10,
      billingProduct: 10,
      billingTax: 10,
      billingExpense: 10,
      customerVendor: 10,
      customerSites: 10,
      customerUserGroup: 10,
      location: 50,
      tags: 50,
      resource: 50,
    },
    setAttachmentModalData(attachments) {
      set((state) => {
        state.attachmentModalData = {
          attachments: attachments,
        };
      });
    },
    resetAllCommonData() {
      set((state) => {
        state.attachmentModalData = {
          attachments: [],
        };
      });
    },
    resetAttachmentModalData() {
      set((state) => {
        state.attachmentModalData = {
          attachments: [],
        };
      });
    },
    setPerPageCount({ type, count }) {
      set((state) => {
        state.perPageCount[type] = count;
      });
    },
  })
);
