export const routerConstants = [
  {
    key: "HOME-ROUTE-ROUTER-CONFIG-KEY",
    name: "home",
    label: "Home",
    toolTipLabel: "Home",
    route: "/app/home",
    defaultRoute: "/app/home",
    hasChildren: false,
    children: [],
    hasTabs: false,
    tabs: [],
    icon: "home",
    disabled: false,
  },
  {
    key: "SERVICE-DESK-ROUTE-ROUTER-CONFIG-KEY",
    name: "service-desk",
    label: "Service Desk",
    toolTipLabel: "Service Desk",
    route: "/app/service-desk",
    defaultRoute: "/app/service-desk/tickets",
    hasChildren: true,
    hasNew: false,
    intercomClassName: "intercomV2-sideBar-serviceDeskBtn",
    children: [
      {
        key: "SERVICE-DESK-SERVICE-BOARD-ROUTE-ROUTER-CONFIG-KEY",
        name: "service-desk-service-board",
        label: "Overview",
        route: "/app/service-desk/overview",
        defaultRoute: "/app/service-desk/overview",
        hasTabs: false,
        tabs: [],
      },
      {
        key: "SERVICE-DESK-TICKETS-ROUTE-ROUTER-CONFIG-KEY",
        name: "service-desk-tickets",
        label: "Tickets",
        route: "/app/service-desk/tickets",
        defaultRoute: "/app/service-desk/tickets",
        hasTabs: false,
        tabs: [],
      },
      {
        key: "SERVICE-DESK-PROJECTS-ROUTE-ROUTER-CONFIG-KEY",
        name: "service-desk-projects",
        label: "Projects",
        route: "/app/service-desk/projects",
        defaultRoute: "/app/service-desk/projects",
        hasTabs: false,
        tabs: [],
      },
      {
        key: "SERVICE-DESK-ANNOUNCEMENT-ROUTER-CONFIG-KEY",
        name: "announcements",
        label: "Announcements",
        route: "/app/service-desk/announcement",
        defaultRoute: "/app/service-desk/announcement/sent",
        hasTabs: false,
        tabs: [],
      },
      {
        key: "SERVICE-DESK-QA-BOARD-ROUTE-ROUTER-CONFIG-KEY",
        name: "service-desk-qa-board",
        label: "Quality Assurance",
        route: "/app/service-desk/qa-board",
        defaultRoute: "/app/service-desk/qa-board",
        hasTabs: false,
        tabs: [],
      },

      // {
      //   key: "SERVICE-DESK-REQUESTS-ROUTE-ROUTER-CONFIG-KEY",
      //   name: "service-desk-requests",
      //   label: "Requests",
      //   route: "/app/service-desk/requests",
      //   defaultRoute: "/app/service-desk/requests",
      //   hasTabs: false,
      //   tabs: [],
      // },

      // {
      //   key: "SERVICE-DESK-RECURRING-TASKS-ROUTE-ROUTER-CONFIG-KEY",
      //   name: "service-desk-recurring-tasks",
      //   label: "Recurring Tasks",
      //   route: "/app/service-desk/recurring-tasks",
      //   defaultRoute: "/app/service-desk/recurring-tasks",
      //   hasTabs: false,
      //   tabs: [],
      // },
      {
        key: "SERVICE-DESK-TIMESHEETS-ROUTE-ROUTER-CONFIG-KEY",
        name: "service-desk-timesheets",
        label: "Timesheets",
        route: "/app/service-desk/timesheets",
        defaultRoute: "/app/service-desk/timesheets",
        hasTabs: false,
        tabs: [],
        isNew: false,
      },
      // {
      //   key: "SERVICE-DESK-CALENDAR-ROUTE-ROUTER-CONFIG-KEY",
      //   name: "service-desk-calendar",
      //   label: "Calendar",
      //   route: "/app/service-desk/calendar",
      //   defaultRoute: "/app/service-desk/calendar",
      //   hasTabs: false,
      //   tabs: [],
      // },
      // {
      //   key: "SERVICE-DESK-DISPATCH-PORTAL-ROUTE-ROUTER-CONFIG-KEY",
      //   name: "service-desk-dispatch-portal",
      //   label: "Dispatch Portal",
      //   route: "/app/service-desk/dispatch-portal",
      //   defaultRoute: "/app/service-desk/dispatch-portal",
      //   hasTabs: false,
      //   tabs: [],
      // },
    ],
    hasTabs: false,
    tabs: [],
    icon: "message",
    disabled: false,
  },
  {
    key: "CUSTOMERS-ROUTE-ROUTER-CONFIG-KEY",
    name: "customers",
    label: "Customers",
    toolTipLabel: "Customers",
    route: "/app/customers",
    defaultRoute: "/app/customers/customers",
    hasChildren: true,
    intercomClassName: "intercomV2-sideBar-customersBtn",
    children: [
      {
        key: "CUSTOMERS-OVERVIEW-ROUTE-ROUTER-CONFIG-KEY",
        name: "customers-overview",
        label: "Overview",
        route: "/app/customers/overview",
        defaultRoute: "/app/customers/overview",
        hasTabs: false,
        tabs: [],
      },
      {
        key: "CUSTOMERS-CUSTOMERS-ROUTE-ROUTER-CONFIG-KEY",
        name: "customers-customers",
        label: "Customers",
        route: "/app/customers/customers",
        defaultRoute: "/app/customers/customers",
        hasTabs: false,
        tabs: [],
        hasChildren: true,
        children: [
          {
            key: "CUSTOMERS-CUSTOMERS-HOME-ROUTE-ROUTER-CONFIG-KEY",
            name: "customers-home",
            label: "Customers Home",
            route: "/",
            hasTabs: false,
            tabs: [],
            hasChildren: false,
          },
          {
            key: "CUSTOMERS-CUSTOMERS-PROFILE-ROUTE-ROUTER-CONFIG-KEY",
            name: "customers-profile",
            label: "Customers Profile",
            route: "/profile",
            hasTabs: true,
            tabs: [
              {
                key: "CUSTOMER-DETAILS-TAB-KEY",
                label: "Customer Details",
                value: "CUSTOMER_DETAILS",
                route: "/customer-details",
                default: true,
              },
              {
                key: "SITES-TAB-KEY",
                label: "Sites",
                value: "SITES",
                route: "/sites",
                default: false,
              },
              {
                key: "USERS-TAB-KEY",
                label: "Users",
                value: "USERS",
                route: "/users",
                default: false,
              },
              {
                key: "USER-GROUP-TAB-KEY",
                label: "User Group",
                value: "USER_GROUP",
                route: "/user-group",
                default: false,
              },
              {
                key: "VENDORS-TAB-KEY",
                label: "Vendors",
                value: "VENDORS",
                route: "/vendors",
                default: false,
              },
              {
                key: "NOTES-TAB-KEY",
                label: "Notes",
                value: "NOTES",
                route: "/notes",
                default: false,
              },
              {
                key: "DOCUMENTS-TAB-KEY",
                label: "Documents",
                value: "DOCUMENTS",
                route: "/documents",
                default: false,
              },
              {
                key: "BILLING-AND-CONTRACTS-TAB-KEY",
                label: "Billing & Contracts",
                value: "BILLING_AND_CONTRACTS",
                route: "/billing-and-contracts",
                default: false,
              },
              {
                key: "SETTINGS-TAB-KEY",
                label: "Settings",
                value: "SETTINGS",
                route: "/settings",
                default: false,
              },
            ],
            hasChildren: false,
          },
        ],
      },
      {
        key: "CUSTOMERS-USERS-ROUTE-ROUTER-CONFIG-KEY",
        name: "customers-users",
        label: "Users",
        route: "/app/customers/users",
        defaultRoute: "/app/customers/users/home",
        hasTabs: false,
        tabs: [],
        hasChildren: true,
        children: [
          {
            key: "CUSTOMERS-USERS-HOME-ROUTE-ROUTER-CONFIG-KEY",
            name: "users-home",
            label: "Users Home",
            route: "/home",
            hasTabs: false,
            tabs: [],
            hasChildren: false,
          },
          {
            key: "CUSTOMERS-USERS-PROFILE-ROUTE-ROUTER-CONFIG-KEY",
            name: "users-profile",
            label: "Users Profile",
            route: "/profile/:id",
            hasTabs: true,
            tabs: [
              {
                key: "USER-DETAILS-TAB-KEY",
                label: "User Details",
                value: "USER_DETAILS",
                route: "/user-details",
                default: true,
              },
              {
                key: "MANAGERS-TAB-KEY",
                label: "Managers",
                value: "MANAGERS",
                route: "/managers",
                default: false,
              },
              {
                key: "DEVICES-TAB-KEY",
                label: "Devices",
                value: "DEVICES",
                route: "/devices",
                default: false,
              },
              {
                key: "NOTES-TAB-KEY",
                label: "Notes",
                value: "NOTES",
                route: "/notes",
                default: false,
              },
              {
                key: "DOCUMENTS-TAB-KEY",
                label: "Documents",
                value: "DOCUMENTS",
                route: "/documents",
                default: false,
              },
              {
                key: "PERMISSIONS-TAB-KEY",
                label: "Permissions",
                value: "PERMISSIONS",
                route: "/permissions",
                default: false,
              },
              {
                key: "SETTINGS-TAB-KEY",
                label: "Settings",
                value: "SETTINGS",
                route: "/settings",
                default: false,
              },
            ],
            hasChildren: false,
          },
        ],
      },
    ],
    hasTabs: false,
    tabs: [],
    icon: "user",
    disabled: false,
  },
  // {
  //   key: "CONTROL-CENTER-ROUTE-ROUTER-CONFIG-KEY",
  //   name: "control-center",
  //   label: "Control Center",
  //   toolTipLabel: "Control Center",
  //   route: "/app/control-center",
  //   defaultRoute: "/app/control-center/company-settings/locations",
  //   hasChildren: false,
  //   hasTabs: false,
  //   hasNew: false,
  //   children:[],
  //   tabs: [],
  //   icon: "settings",
  //   disabled: false,
  // children: [
  //   {
  //     key: "CONTROL-CENTER-LOCATIONS-ROUTE-ROUTER-CONFIG-KEY",
  //     name: "control-center-locations",
  //     label: "Company Settings",
  //     route: "/app/control-center/company-settings",
  //     defaultRoute: "/app/control-center/company-settings",
  //     hasTabs: false,
  //     tabs: [],
  //     isNew: false,
  //   },
  //   {
  //     key: "CONTROL-CENTER-RESOURCES-AND-PERMISSIONS-ROUTE-ROUTER-CONFIG-KEY",
  //     name: "control-center-resources-and-permissions",
  //     label: "Company Resources",
  //     route: "/app/control-center/resources-and-permissions",
  //     defaultRoute: "/app/control-center/resources-and-permissions",
  //     hasChildren: true,

  //     children: [
  //       {
  //         key: "CONTROL-CENTER-RESOURCES-AND-PERMISSIONS-RESOURCES-TAB-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-resources-and-permissions-resources",
  //         label: "Resources",
  //         default: false,
  //         route: "/resources",
  //       },
  //       {
  //         key: "CONTROL-CENTER-RESOURCES-AND-PERMISSIONS-RESOURCE_GROUPS-TAB-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-resources-and-permissions-resource_groups",
  //         label: "Resource Groups",
  //         default: false,
  //         route: "/resource-groups",
  //       },
  //       {
  //         key: "CONTROL-CENTER-RESOURCES-AND-PERMISSIONS-RESOURCE_TYPES-TAB-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-resources-and-permissions-resource_types",
  //         label: "Resource Types",
  //         default: false,
  //         route: "/resource-types",
  //       },
  //     ],
  //   },
  //   {
  //     key: "CONTROL-CENTER-CONFIGURATIONS-ROUTE-ROUTER-CONFIG-KEY",
  //     name: "control-center-configurations",
  //     label: "Configurations",
  //     route: "/app/control-center/configurations",
  //     defaultRoute: "/app/control-center/configurations/home",
  //     hasTabs: true,
  //     isNew: false,
  //     tabs: [
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-HOME-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-home",
  //         label: "Home",
  //         default: true,
  //         route: "/home",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-GENERAL-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-general",
  //         label: "General",
  //         default: false,
  //         route: "/service-desk-general",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-WIDGETS-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-widgets",
  //         label: "Widgets",
  //         default: false,
  //         route: "/service-desk-widgets",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-ATTRIBUTES-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-attributes",
  //         label: "Attributes",
  //         default: false,
  //         route: "/service-desk-attributes",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-BOARDS-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-boards",
  //         label: "Boards",
  //         default: false,
  //         route: "/service-desk-boards",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-TEMPLATES-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-templates",
  //         label: "Templates",
  //         default: false,
  //         route: "/service-desk-templates",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-CHECKLIST-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-checklist",
  //         label: "Checklist",
  //         default: false,
  //         route: "/service-desk-checklist",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-AUTOMATION-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-automation",
  //         label: "Automation",
  //         default: false,
  //         route: "/service-desk-automation",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-SERVICE_DESK-QA-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-service_desk-qa",
  //         label: "QA",
  //         default: false,
  //         route: "/service-desk-qa",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-CUSTOMERS-WIDGETS-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-customers-widgets",
  //         label: "Widgets",
  //         default: false,
  //         route: "/customers-widgets",
  //       },
  //       {
  //         key: "CONTROL-CENTER-CONFIGURATIONS-CUSTOMERS-SLA-SERVICE-ROUTE-ROUTER-CONFIG-KEY",
  //         name: "control-center-configurations-customers-sla",
  //         label: "SLA",
  //         default: false,
  //         route: "/customers-sla",
  //       },
  //     ],
  //   },

  //   // {
  //   //   key: "CONTROL-CENTER-WHITE-LABEL-ROUTE-ROUTER-CONFIG-KEY",
  //   //   name: "control-center-white-label",
  //   //   label: "Branding",
  //   //   route: "/app/control-center/white-label",
  //   //   defaultRoute: "/app/control-center/white-label",
  //   //   hasTabs: false,
  //   //   tabs: [],
  //   // },
  //   // {
  //   //   key: "CONTROL-CENTER-SECURITY-ROUTE-ROUTER-CONFIG-KEY",
  //   //   name: "control-center-security",
  //   //   label: "Security",
  //   //   route: "/app/control-center/security",
  //   //   defaultRoute: "/app/control-center/security/authentication-settings",
  //   //   hasTabs: true,
  //   //   tabs: [
  //   //     {
  //   //       key: "CONTROL-CENTER-SECURITY-SECURITY-TAB-ROUTE-ROUTER-CONFIG-KEY",
  //   //       name: "control-center-security-security",
  //   //       label: "Security",
  //   //       default: true,
  //   //       route: "/security-settings",
  //   //     },
  //   //     {
  //   //       key: "CONTROL-CENTER-SECURITY-AUTHENTICATION-TAB-ROUTE-ROUTER-CONFIG-KEY",
  //   //       name: "control-center-security-authentication",
  //   //       label: "Authentication",
  //   //       default: false,
  //   //       route: "/authentication-settings",
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   key: "CONTROL-CENTER-NOTIFICATIONS-ROUTE-ROUTER-CONFIG-KEY",
  //   //   name: "control-center-notifications",
  //   //   label: "Notifications",
  //   //   route: "/app/control-center/notifications",
  //   //   defaultRoute: "/app/control-center/notifications",
  //   //   hasTabs: false,
  //   //   tabs: [],
  //   // },
  //   // {
  //   //   key: "CONTROL-CENTER-INTEGRATIONS-ROUTE-ROUTER-CONFIG-KEY",
  //   //   name: "control-center-integrations",
  //   //   label: "Integrations",
  //   //   route: "/app/control-center/integrations",
  //   //   defaultRoute: "/app/control-center/integrations",
  //   //   disabled: true,
  //   //   hasTabs: false,
  //   //   tabs: [],
  //   // },
  // ],

  // },
  {
    key: "REPORTS-ROUTER-CONFIG-KEY",
    name: "Reports",
    label: "Reports",
    toolTipLabel: "Reports",
    route: "/app/reports",
    defaultRoute: "/app/reports",
    hasChildren: true,
    isBeta: false,
    children: [
      {
        key: "REPORT-DASHBOARD-ROUTER-CONFIG-KEY",
        name: "report-dashboard",
        label: "Overview",
        default: false,
        route: "reports/home",
        defaultRoute: "reports/home",
      },
      {
        key: "REPORT-HOME-ROUTER-CONFIG-KEY",
        name: "report-home",
        label: "Reports",
        default: false,
        route: "reports/list",
        defaultRoute: "reports/list",
      },
    ],
    hasTabs: false,
    tabs: [],
    icon: "home",
    disabled: true,
  },
  {
    key: "BILLING-ROUTER-CONFIG-KEY",
    name: "Billing",
    label: "Billing",
    toolTipLabel: "Billing",
    route: "/app/billing",
    defaultRoute: "/app/billing",
    hasChildren: true,
    isBeta: true,
    children: [
      {
        key: "BILLING-INVOICE-ROUTER-CONFIG-KEY",
        name: "billing-invoice",
        label: "Invoice",
        default: false,
        route: "billing/invoice",
        defaultRoute: "billing/invoice",
      },
      {
        key: "BILLING-READY-ROUTER-CONFIG-KEY",
        name: "billing-ready",
        label: "Ready To Bill",
        default: true,
        route: "billing/ready-to-bill",
        defaultRoute: "billing/ready-to-bill",
      },
      {
        key: "BILLING-CONTRACTS-ROUTER-CONFIG-KEY",
        name: "billing-contracts",
        label: "Contracts",
        default: false,
        route: "billing/contracts",
        defaultRoute: "billing/contracts",
      },
      // {
      //   key: "BILLING-RECURRING-ROUTER-CONFIG-KEY",
      //   name: "billing-recurring",
      //   label: "Recurring Invoices",
      //   default: false,
      //   route: "billing/recurring-invoice",
      //   defaultRoute: "billing/recurring-invoice",
      // },
    ],
    hasTabs: false,
    tabs: [],
    icon: "home",
    disabled: true,
  },
];

export const disabledRoutes = [
  // {
  //   key: "REMOTE-CONTROL-ROUTER-CONFIG-KEY",
  //   name: "RMM",
  //   label: "RMM",
  //   toolTipLabel: "Remote Control",
  //   route: "/app/rmm",
  //   defaultRoute: "/app/home",
  //   hasChildren: false,
  //   children: [],
  //   hasTabs: false,
  //   tabs: [],
  //   icon: "home",
  //   disabled: true,
  // },
  // {
  //   key: "DOCUMENTATION-ROUTER-CONFIG-KEY",
  //   name: "documentation",
  //   label: "Documentation",
  //   toolTipLabel: "Documentation",
  //   route: "/app/documentation",
  //   defaultRoute: "/app/home",
  //   hasChildren: false,
  //   children: [],
  //   hasTabs: false,
  //   tabs: [],
  //   icon: "home",
  //   disabled: true,
  // },
  // {
  //   key: "ASSET-MANAGEMENT-ROUTER-CONFIG-KEY",
  //   name: "Asset Management",
  //   label: "Asset Management",
  //   toolTipLabel: "Asset Management",
  //   route: "/app/assetmanagment",
  //   defaultRoute: "/app/home",
  //   hasChildren: false,
  //   children: [],
  //   hasTabs: false,
  //   tabs: [],
  //   icon: "home",
  //   disabled: true,
  // },
  // {
  //   key: "BILLING-ROUTER-CONFIG-KEY",
  //   name: "Billing & Invoice",
  //   label: "Billing & Invoice",
  //   toolTipLabel: "Billing & Invoice",
  //   route: "/app/billing",
  //   defaultRoute: "/app/home",
  //   hasChildren: false,
  //   children: [],
  //   hasTabs: false,
  //   tabs: [],
  //   icon: "home",
  //   disabled: true,
  // },
  // {
  //   key: "REPORTS-ROUTER-CONFIG-KEY",
  //   name: "Reports",
  //   label: "Reports",
  //   toolTipLabel: "Reports",
  //   route: "/app/reports",
  //   defaultRoute: "/app/home",
  //   hasChildren: false,
  //   children: [],
  //   hasTabs: false,
  //   tabs: [],
  //   icon: "home",
  //   disabled: true,
  // },
];
