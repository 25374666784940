import { Immer } from "immer";
import { immer } from "zustand/middleware/immer";

interface ReadyToBillFilterState {
  readyToBillFilter: {
    customerId: string | null;
    contractId: string[];
    serviceType: string[];
    billStatus: string[];
  };
}

interface ReadyToBillFilterAction {
  setReadyToBillFilterData: ({
    type,
    value,
  }: {
    type: "customerId" | "contractId" | "serviceType" | "billStatus";
    value: string | string[] | null;
  }) => void;
}

export interface ReadyToBillFilterInterface
  extends ReadyToBillFilterAction,
    ReadyToBillFilterState {}

export const readyToBillFilterSlice = immer<
  ReadyToBillFilterState & ReadyToBillFilterAction
>((set, get) => ({
  readyToBillFilter: {
    customerId: null,
    contractId: [],
    serviceType: [],
    billStatus: [],
  },

  setReadyToBillFilterData: ({ type, value }) =>
    set((state) => {
      // @ts-expect-error
      state.readyToBillFilter[type] = value;
    }),
}));
