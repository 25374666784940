import { immer } from "zustand/middleware/immer";

interface UserState {
  userDetails: any;
  userCustomerDetails: any;
}

interface UserAction {
  setUserDetails: (userDetails: any) => void;
  setUserCustomerDetails: (userCustomerDetails: any) => void;
  clearAllUserDetails: () => void;
}

export interface UserInterface extends UserState, UserAction {}

export const userSlice = immer<UserState & UserAction>((set, get) => ({
  userDetails: {},
  userCustomerDetails: {},
  setUserDetails: (userDetails) => {

    set((state) => {
      state.userDetails = { ...userDetails };
    });
  },
  setUserCustomerDetails: (userCustomerDetails) => {
    set((state) => {
      state.userCustomerDetails = { ...userCustomerDetails };
    });
  },
  clearAllUserDetails: () => {
    set((state) => {
      state.userDetails = {};
      state.userCustomerDetails = {};
    });
  },
}));
