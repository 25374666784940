import { Stack, Typography, useTheme } from "@mui/material";

export const Switch = ({
  tabValues,
  value,
  onChange,
  disabled = false,
}: {
  tabValues: string[];
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      sx={{
        display: "flex",
        position: "relative",
        p: "6px",
        height: "fit-content",
        borderRadius: "4px",
        gap: "12px",
        bgcolor: theme.palette.neutral["005"],
        "&:hover": {
          cursor: disabled ? "not-allowed" : "pointer",
        },
      }}
    >
      {tabValues.map((tabValue, index) => (
        <Typography
          key={tabValue}
          onClick={(e) => {
            e.stopPropagation();
            disabled ? null : onChange(index);
          }}
          variant="button-small"
          sx={{
            visibility: value === index ? "hidden" : "visible",
            color: theme.palette.lightBg.low,
            textAlign: "center",
            p: "2px 8px",
          }}
        >
          {tabValue} {index === 0 ? "" : "(Save 17%)"}
        </Typography>
      ))}

      <Typography
        variant="button-small"
        sx={{
          p: "2px 8px",
          position: "absolute",
          bgcolor: theme.palette.darkBg.high,
          boxShadow: "2px 2px 6px 0px #0000001F",
          textAlign: "center",
          borderRadius: "4px",
          transform:
            value === 0 ? "translateX(0%)" : "translateX(calc(100% - 44px))",
          transition: "transform 0.2s ease",
        }}
      >
        {tabValues[value]}{" "}
        <Typography
          variant="button-small"
          component={"span"}
          color={theme.palette.success.main}
        >
          {value === 0 ? "" : "(Save 17%)"}
        </Typography>
      </Typography>
    </Stack>
  );
};
