import { useContext } from "react";
import { ResourceTabContext } from "../Utils";
import { Box } from "@mui/material";
import { PermissionComponent } from "../../../components";

export const PermissionsTab = ({ role, data }: { role: string; data: any }) => {
  const onValueChangeHandler = useContext(ResourceTabContext);

  const onDataChange = (data: any) => {
    onValueChangeHandler("permissions", data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <PermissionComponent role={role} data={data} onChange={onDataChange} />
    </Box>
  );
};
