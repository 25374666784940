import { getResourceId } from "utilz/helpers";
import { immer } from "zustand/middleware/immer";

interface HomeScreenState {
  homeScreenResourceId: string;
  homeScreenView: string;
}

interface HomeScreenAction {
  setHomeScreenResourceId: (resourceId: string) => void;
  setHomeScreenView: (homeScreenIsWeekView: string) => void;
}

export interface HomeScreenInterface
  extends HomeScreenState,
    HomeScreenAction {}

export const homeScreenSlice = immer<HomeScreenState & HomeScreenAction>(
  (set, get) => ({
    homeScreenResourceId: getResourceId(), //get value from global store
    isLoggedInResource: true,
    // homeScreenView: "last_week",
    homeScreenView: "today",
    setHomeScreenResourceId: (homeScreenResourceId: string) =>
      set((state) => {
        state.homeScreenResourceId = homeScreenResourceId;
      }),
    setHomeScreenView: (homeScreenView: string) =>
      set((state) => {
        state.homeScreenView = homeScreenView;
      }),
  })
);
