import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Legacy Imports
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";

// Configurations Imports
import { appConfig } from "../../../constants/app-configurations";

const GOOGLE_MAPS_API_KEY = appConfig["google_maps_api_key"];
const MapComponent = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  // States
  const [state, setState] = useState({ geoLoc: { lat: 0, lng: 0 }, label: "" });

  // Effects
  useEffect(() => {
    setState({
      ...state,
      geoLoc: { lat: props?.latitude, lng: props.longitude },
      label: props?.label,
    });
  }, [props]);

  // trackers
  // useEffect(()=> {console.log(state)}, [state])

  // Renderer
  return (
    <React.Fragment>
      {isLoaded ? (
        <GoogleMap
          center={state?.geoLoc}
          zoom={12}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "24px",
          }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          <Marker position={state?.geoLoc} title={state?.label} />
        </GoogleMap>
      ) : (
        <h6>Loading</h6>
      )}
    </React.Fragment>
  );
};

MapComponent.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

MapComponent.defaultProps = {
  latitude: 0,
  longitude: 0,
  label: "",
};

export default MapComponent;
