import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createActivity,
  createActivityByManager,
  createTimeEntries,
  createTimeEntryByManmager,
  deleteTimEntryById,
  endTime,
  getAllActiveTimeEntries,
  getTimEntryById,
  startTime,
  updateActivity,
  updateActivityByManager,
  updateTicketDetailsfromTimeEntries,
  updateTimeEntries,
  updateTimeEntryByManager,
} from "controller/api/service-desk/time-entries";
import {
  ApiErrorType,
  CreateActivityInputType,
  DeleteTimeEntryInput,
  GetTimeEntryByIdInput,
  UpdateActivityByManagerInputType,
  UpdateActivityInputType,
  UpdatetimeEntriesInput,
  createTimeEntriesInput,
  endTimerInput,
  startTimerInput,
  ticketDetailsFromTimeEntriesInput,
} from "shared";
import { sentryLogger } from "utilz/logger/sentryLogger";

export const useStartTimer = () => {
  const queryClient = useQueryClient();
  return useMutation((value: startTimerInput) => startTime(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([
        variables.serviceType,
        variables.serviceId,
      ]);
      queryClient.invalidateQueries(["timeEntries"]);
    },
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "timeEntry",
        functionName: "startTime",
      });
    },
  });
};

export const useEndTimer = () => {
  const queryClient = useQueryClient();
  return useMutation((value: endTimerInput) => endTime(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries([variables.serviceType]);
    },

    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "timeEntry",
        functionName: "endTime",
      });
    },
  });
};

export const useCreateTimeEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: createTimeEntriesInput) => createTimeEntries(value),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries([
            variables.serviceType,
            variables.serviceId,
          ]);
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "createTimeEntries",
        });
      },
    }
  );
};

export const useCreateTimeEntryByManager = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: createTimeEntriesInput) => createTimeEntryByManmager(value),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries([
            variables.serviceType,
            variables.serviceId,
          ]);
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "createTimeEntryByManmager",
        });
      },
    }
  );
};

export const useUpdateTimeEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdatetimeEntriesInput) => updateTimeEntries(value),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries([
            variables.serviceType,
            variables.serviceId,
          ]);
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "updateTimeEntries",
        });
      },
    }
  );
};

export const useUpdateTimeEntryByManager = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdatetimeEntriesInput) => updateTimeEntryByManager(value),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries([
            variables.serviceType,
            variables.serviceId,
          ]);
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "updateTimeEntryByManager",
        });
      },
    }
  );
};

export const useUpdateTicketDetailsFromTimeEntries = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: ticketDetailsFromTimeEntriesInput) =>
      updateTicketDetailsfromTimeEntries(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["ticket", variables.ticketId]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "updateTicketDetailsfromTimeEntries",
        });
      },
    }
  );
};

export const useDeleteTimeEntries = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: DeleteTimeEntryInput) => deleteTimEntryById(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          variables.serviceType,
          variables.ticketId,
        ]);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "deleteTimEntryById",
        });
      },
    }
  );
};

export const useGetTimeEntrieByIdMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((value: GetTimeEntryByIdInput) => getTimEntryById(value), {
    onSuccess(data, variables, context) {},
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "timeEntry",
        functionName: "getTimEntryById",
      });
    },
  });
};

export const useCreateActivity = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: CreateActivityInputType) => createActivity(values),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "createActivity",
        });
      },
    }
  );
};

export const useUpdateActivity = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: UpdateActivityInputType) => updateActivity(values),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "updateActivity",
        });
      },
    }
  );
};

export const useCreateActivityByManager = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: CreateActivityInputType) => createActivityByManager(values),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "createActivityByManager",
        });
      },
    }
  );
};

export const useUpdateActivityByManager = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: UpdateActivityByManagerInputType) =>
      updateActivityByManager(values),
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.invalidateQueries(["timesheet"]);
        }, 3500);
      },
      onError: (error: ApiErrorType) => {
        sentryLogger({
          error: error,
          workflow: "timeEntry",
          functionName: "updateActivityByManager",
        });
      },
    }
  );
};

export const useGetAllActiveTimeEntries = () => {
  return useQuery({
    queryKey: ["timeEntries"],
    queryFn: () => getAllActiveTimeEntries(),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "timeEntry",
        functionName: "getAllActiveTimeEntries",
      });
    },
  });
};
