import { Box } from "@mui/material";
import { UnautherizedComponent } from "components";

export const UnautherizedScreen = () => {
  return (
    <Box width={"100vw"} height={"100vh"}>
      <UnautherizedComponent />
    </Box>
  );
};
