import { getAccessToken } from "utilz/helpers";
import { internalAxiosClient } from "utilz/helpers/apiRequestAuthHelper";

export const logOutAccount = async () => {
  try {
    const result = await internalAxiosClient.post(
      "/logout",
      {},
      {
        headers: {
          "x-refresh-token": `${document.cookie}`,
        },
      }
    );
    return result;
  } catch (e: any) {
    if (
      e &&
      e?.response &&
      (e?.response?.data?.message === "unauthorized" ||
        e?.response?.data?.error === "Refresh token not found")
    ) {
      throw new Error("unauthorized");
    }
    throw new Error(e);
  }
};

export const getAuthAccessToken = async () => {
  try {
    const result = await internalAxiosClient.get("/refresh/token", {
      headers: {
        "x-refresh-token": `${document.cookie}`,
      },
    });
    return result;
  } catch (e: any) {
    if (
      e &&
      e?.response &&
      (e?.response?.data?.message === "unauthorized" ||
        e?.response?.data?.error === "Refresh token not found")
    ) {
      throw new Error("unauthorized");
    }
    throw new Error(e);
  }
};
