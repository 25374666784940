import "./moreIcon.css";

function moreIcon(props) {
  return (
    <div className={props.vertical ? "vertical" : "horizontal"}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.9972 17.5022C11.7221 17.5022 11.497 17.7273 11.5 18.0024C11.5 18.2775 11.7251 18.5026 12.0002 18.5026C12.2754 18.5026 12.5004 18.2775 12.5004 18.0024C12.5004 17.7273 12.2754 17.5022 11.9972 17.5022"
          stroke={props.disabled === true ? props.disabledcolor : props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round" />
        <path
          d="M11.9972 11.4998C11.7221 11.4998 11.497 11.7248 11.5 12C11.5 12.2751 11.7251 12.5002 12.0002 12.5002C12.2754 12.5002 12.5004 12.2751 12.5004 12C12.5004 11.7248 12.2754 11.4998 11.9972 11.4998"
          stroke={props.disabled === true ? props.disabledcolor : props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round" />
        <path
          d="M11.9972 5.49731C11.7221 5.49731 11.497 5.72241 11.5 5.99752C11.5 6.27264 11.7251 6.49773 12.0002 6.49773C12.2754 6.49773 12.5004 6.27264 12.5004 5.99752C12.5004 5.72241 12.2754 5.49731 11.9972 5.49731"
          stroke={props.disabled === true ? props.disabledcolor : props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
    </div>




  );
}

export default moreIcon;