import { GetQueryProps } from "shared";
import { axiosClient } from "utilz";
import { generateUrlString, getQueryDataObject } from "utilz/helpers";

// Function to fetch all tax configurations
export const getAllTaxConfigs = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    // const url = "/v1/configuration/billing/taxes?per_page=10&page=1";
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });
    const url = await generateUrlString({
      baseUrl: "/v1/configuration/billing/taxes",
      data,
    });
    const response = await axiosClient.get(url);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

// Function to create a new tax configuration
export const createTaxConfig = async (data: any) => {
  try {
    const url = "/v1/configuration/billing/taxes";
    const response = await axiosClient.post(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

// Function to delete a tax configuration
export const deleteTaxConfig = async (data: { tax_ids: string[] }) => {
  try {
    const url = "/v1/configuration/billing/taxes";
    const response = await axiosClient.put(url, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

// Function to update a tax configuration
export const updateTaxConfig = async (data: any) => {
  try {
    const url = `/v1/configuration/billing/taxes/${data.id}`;
    const response = await axiosClient.patch(url, data.data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
