import { Box, Collapse, Menu, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useGlobalStore } from "controller/store/globalStore";
import { toast } from "react-hot-toast";
import { CustomTypography } from "library/base";
import { Vertical3DotsIcon } from "components";
import { ConfigurationMenuItem } from "../../Components";
import { GetAttributesInput, GetTypeSubtypeInput } from "shared";
import { ServiceSubTypeTile } from "./ServiceSubTypeTile";
import {
  useCreateServiceType,
  useCreateServiceTypeSubType,
  useDeleteAttribute,
  useDeleteServiceTypeSubType,
  useUpdateAttribute,
  useUpdateServiceTypeSubType,
} from "controller";
import { CreateAttributeTile } from "./CreateAttributeTile";

interface ServiceTypeTileProps extends GetTypeSubtypeInput {
  isEditable: boolean;
  value: any;
  fieldName: string;
  mappingId: string;
}
export const ServiceTypeTile = ({
  isEditable = false,
  serviceType,
  value,
  fieldName,
  mappingId,
}: ServiceTypeTileProps) => {
  const anchorRef = useRef();
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    isEdit: false,
    ismenuOpen: false,
    isExpanded: false,
    isCreateTileOpen: false,
    editEnabledSubType: "",
  });

  const { mutateAsync: createServiceTypeSubType } =
    useCreateServiceTypeSubType();
  const { mutateAsync: updateServiceTypeSubType } =
    useUpdateServiceTypeSubType();
  const { mutateAsync: deleteServiceTypeSubType } =
    useDeleteServiceTypeSubType();

  const onUpdateServiceType = (data: any) => {
    updateServiceTypeSubType({
      attributeName: "type",
      data: data,
      serviceType: serviceType,
      serviceTypeSubTypeId: mappingId,
    })
      .then(() => {
        toast.success("Updated successfully");
        setState({
          ...state,
          isEdit: false,
          ismenuOpen: false,
          isExpanded: false,
          isCreateTileOpen: false,
        });
      })
      .catch(() => {
        toast.error("Unable to update");
      });
  };

  const onDeleteServiceType = () => {
    deleteServiceTypeSubType({
      attributeName: "type",
      serviceTypeSubTypeId: mappingId,
      serviceType: serviceType,
    })
      .then(() => {
        toast.success("Deleted successfully");
        setState({
          ...state,
          isEdit: false,
          ismenuOpen: false,
          isExpanded: false,
          isCreateTileOpen: false,
        });
      })
      .catch(() => {
        toast.error("Unable to delete");
      });
  };

  const onCreateServiceTypeSubType = (data: any) => {
    createServiceTypeSubType({
      attributeName: "subtype",
      data,
      serviceType,
      serviceTypeSubTypeId: mappingId,
    })
      .then(() => {
        toast.success("Created successfully");
        setState({
          ...state,
          isCreateTileOpen: false,
        });
      })
      .catch(() => {
        toast.error("Unable to create");
      });
  };

  const onUpdateServiceTypeSubType = (subTypeId: string, data: any) => {
    updateServiceTypeSubType({
      attributeName: "subtype",
      data,
      serviceType,
      serviceTypeSubTypeId: subTypeId,
    })
      .then(() => {
        toast.success("Updated successfully");
        setState({
          ...state,
          editEnabledSubType: "",
        });
      })
      .catch(() => {
        toast.error("Unable to update");
      });
  };

  const onDeleteServiceTypeSubType = (subTypeId: string) => {
    deleteServiceTypeSubType({
      attributeName: "subtype",
      serviceType,
      serviceTypeSubTypeId: subTypeId,
    })
      .then(() => {
        toast.success("Updated successfully");
        setState({
          ...state,
          editEnabledSubType: "",
        });
      })
      .catch(() => {
        toast.error("Unable to update");
      });
  };

  return (
    <Box>
      {state.isEdit === true ? (
        <CreateAttributeTile
          name={fieldName}
          value={value?.[fieldName] || ""}
          onSubmit={onUpdateServiceType}
          onCancel={() => {
            setState({
              ...state,
              isEdit: false,
            });
          }}
        />
      ) : (
        <Stack
          direction={"row"}
          sx={{
            px: "16px",
            alignItems: "center",
            gap: "16px",
            borderBottom: "1px solid #CCCCCC",
            "&:hover": {
              cursor: "pointer",
              transition: "background-color 200ms linear",
              backgroundColor: "#F3F5F9",
            },
          }}
        >
          <Box
            sx={{
              py: "18px",
              height: "100%",
              flex: "1",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              setState({
                ...state,
                isEdit: false,
                ismenuOpen: false,
                isExpanded: !state.isExpanded,
                isCreateTileOpen: false, //(state.isExpanded === true && state.isCreateTileOpen === true) ? false : state.isCreateTileOpen
              });
            }}
          >
            <CustomTypography
              content={value?.[fieldName] || ""}
              size={"EXTRA-SMALL"}
              variant={"REGULAR"}
            />
          </Box>
          {
            <Box
              ref={anchorRef}
              sx={{
                AspectRatio: "1",
                height: "100%",
              }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setState({
                  ...state,
                  ismenuOpen: !state.ismenuOpen,
                });
              }}
            >
              <Vertical3DotsIcon />
            </Box>
          }
          <Menu
            open={state.ismenuOpen === true}
            anchorEl={state.ismenuOpen === true ? anchorRef.current : null}
            onClose={() => {
              setState({
                ...state,
                ismenuOpen: false,
              });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                gap: "1px",
                p: 0,
                m: 0,
                border: "1px solid #EDEDED",
                minWidth: "180px",
                borderRadius: "0px  0px 8px 8px",
                color: "black",
                boxShadow:
                  "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                "& .MuiMenu-list": {
                  padding: "4px 0",
                },
                "& .MuiMenuItem-root": {
                  borderTop: "1px solid #EDEDED",
                  p: "16px",
                  "&:hover": {
                    backgroundColor: "#F3F5F9",
                  },
                },
              },
            }}
          >
            {isEditable === true ? (
              <ConfigurationMenuItem
                content="Edit"
                onClick={() => {
                  setState({
                    ...state,
                    isEdit: true,
                    ismenuOpen: false,
                  });
                }}
              />
            ) : null}

            {isEditable === true ? (
              <ConfigurationMenuItem
                content="Remove"
                onClick={onDeleteServiceType}
              />
            ) : null}
            {
              <ConfigurationMenuItem
                content="Add Subtype"
                onClick={() => {
                  setState({
                    ...state,
                    isEdit: false,
                    ismenuOpen: false,
                    isExpanded: true,
                    editEnabledSubType: "",
                    isCreateTileOpen: true,
                  });
                }}
              />
            }
          </Menu>
        </Stack>
      )}
      {
        <Collapse in={state.isExpanded}>
          {state.isCreateTileOpen === true ? (
            <CreateAttributeTile
              name={"subtype_name"}
              value={""}
              onSubmit={onCreateServiceTypeSubType}
              onCancel={() => {
                setState({
                  ...state,
                  isCreateTileOpen: false,
                });
              }}
            />
          ) : null}
          {(Array.isArray(value?.subtype) ? value.subtype : []).map(
            (item: any, index: number) => (
              <ServiceSubTypeTile
                key={`attribute-service-subtype-${item?.subtype_id || index}`}
                data={item}
                onSubmit={(data: any) =>
                  onUpdateServiceTypeSubType(item.subtype_id, data)
                }
                onDelete={() => onDeleteServiceTypeSubType(item.subtype_id)}
                isEditable={state.editEnabledSubType === item?.subtype_id}
                onEditClick={(isEdit: boolean) => {
                  setState({
                    ...state,
                    editEnabledSubType: isEdit === true ? item?.subtype_id : "",
                  });
                }}
              />
            )
          )}
        </Collapse>
      }
    </Box>
  );
};
