import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import TextField from "@mui/material/TextField";

import { getResponsiveRows } from "./utilz";

// Constants
import { TEXTFIELD_CONSTANTS } from "./constants";
import { CustomTypography } from "../../../base";
import { Box } from "@mui/material";

// Customization 
const customStyles = {
  "& .MuiInputBase-root": {
    minHeight: TEXTFIELD_CONSTANTS["height"],
  },
  "& .MuiOutlinedInput-root": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["DEFAULT"],
      borderRadius: TEXTFIELD_CONSTANTS?.border_radius,
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": { borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"], }
  },
  "& label": {
    color: TEXTFIELD_CONSTANTS?.palette["unfocus_color"],

    "&.MuiFormLabel-filled": {
      transform: "translate(14px, -9px) scale(1)"
    },
    "&.Mui-focused": {
      color: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
      fontWeight: TEXTFIELD_CONSTANTS?.palette["bold_weight"],
      transform: "translate(14px, -9px) scale(1)",
    }
  }
};


const CustomInput = React.forwardRef((props, ref) => {
  const rows = getResponsiveRows();
  return (
    <Box
      sx={{
        width: "100%",
      }}
      className={" typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular"}>
      <textarea
        {...props}
        multiple
        rows={rows}
      ></textarea>
    </Box>

  );
});

const CustomTextAreaField = (props) => {
  // State
  const [value, setValue] = useState("");
  const [isFocus, setFocus] = useState(false);
  useEffect(() => { setValue(props.value); }, [props]);

  // Event Handlers
  const handleChange = (event) => {
    setValue(event.target.value);
    props?.changeHandler({ field: props.name, value: event.target.value });

  };



  const onFocusHandler = (event) => {
    if (event.type === "focus") {
      setFocus(true);
      return;
    }
    setFocus(false);
  };

  return (
    <TextField
      name={props.name || ""}
      label={props.label ? <CustomTypography
        variant={isFocus === true ? "BOLD" : "REGULAR"}
        size="EXTRA-SMALL"
        content={props.label || ""}
      /> : ""}
      onFocus={onFocusHandler}
      onBlur={onFocusHandler}
      multiline
      value={value}
      fullWidth={true}
      disabled={props.disabled || false}
      required={props.required || false}
      error={props.error || false}
      helperText={props.helperText || ""}
      placeholder={props.placeholder || ""}
      sx={customStyles}
      InputProps={{
        inputComponent: CustomInput
      }}
      onChange={handleChange}
    />
  );
};

CustomTextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  changeHandler: PropTypes.func.isRequired
};

CustomTextAreaField.defaultProps = {
  name: "",
  label: "",
  value: "",
  disabled: false,
  required: false,
  error: false,
  helperText: "",
  placeholder: "",
  size: "medium",
  maxRows: 6,
  minRows: 1,
  changeHandler: () => { }
};

export default CustomTextAreaField;
