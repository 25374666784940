import { Autocomplete, Box, Chip, Input, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TEXTFIELD_CONSTANTS } from "./constants";
import { CustomTypography } from "../../../../../../../../../library/base";
import CustomIcon from "../../../../../../../../../library/icon-library";

const customStyles = {
  "& .MuiInputBase-root": {
    minHeight: TEXTFIELD_CONSTANTS["height"],
    height: "fit-content",
  },
  "& .MuiOutlinedInput-root": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["DEFAULT"],
      borderRadius: TEXTFIELD_CONSTANTS?.border_radius,
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
    },
  },
  "& .MuiInputLabel-root": {
    //   transform: TEXTFIELD_CONSTANTS.transition, //'translate(14px,60%) scale(1)'
    color: TEXTFIELD_CONSTANTS?.palette["unfocus_color"],
    "&.MuiFormLabel-filled": {
      transform: "translate(14px, -9px) scale(1)",
    },
    "&.Mui-focused": {
      color: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
      fontWeight: TEXTFIELD_CONSTANTS?.palette["bold_weight"],
      transform: "translate(14px, -9px) scale(1)",
    },
  },
};

const CustomInput = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;
  return (
    <Box
      sx={{
        width: "100%",
        mx: "8px",
      }}
      className={
        "typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular"
      }
    >
      <input
        ref={ref}
        {...otherProps}
        className={`${className} typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular`}
      ></input>
    </Box>
  );
});

function CustomAutoCompleteTextField(props) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(props.value);
    console.log(
      "🚀 ~ file: index.js:70 ~ useEffect ~ props.value:",
      props.value
    );
    if (typeof props.populator === "function") {
      populateData();
    }
  }, [props]);

  async function populateData() {
    let result = [];
    if (props.dependency === true) {
      result = await props.populator(props.triggerValue);
    } else {
      result = await props.populator();
    }
    setOptions(result);
  }

  const onChange = (event, newValue) => {
    console.log("🚀 ~ file: index.js:83 ~ onChange ~ newValue:", newValue);
    props.onChange({ field: props.name, value: newValue });
    setValue(newValue);
  };

  return (
    <Autocomplete
      multiple
      // onOpen={() => {
      // }}
      // onClose={() => {
      // }}
      value={value}
      isOptionEqualToValue={props.isOptionEqualToValue}
      getOptionLabel={(option) => option.label}
      options={options}
      onChange={onChange}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <Chip
              label={
                <CustomTypography
                  content={`${option.label}`}
                  size={"EXTRA-SMALL"}
                  variant={"REGULAR"}
                />
              }
              {...getTagProps({ index })}
              variant="outlined"
              sx={{
                borderRadius: "8px",
                border: "1px solid black",
              }}
              deleteIcon={
                <CustomIcon icon="close" size={"lg"} color={"black"} />
              }
            />
          );
        });
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <CustomTypography
            size="EXTRA-SMALL"
            variant="REGULAR"
            content={option.label}
          />
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name || ""}
          label={
            props.label ? (
              <CustomTypography
                variant="REGULAR"
                size="EXTRA-SMALL"
                content={props.label || ""}
              />
            ) : (
              ""
            )
          }
          InputLabelProps={{
            sx: {
              transform:
                value.length > 0
                  ? "translate(14px, -9px) scale(1)"
                  : TEXTFIELD_CONSTANTS.transition,
            },
          }}
          sx={customStyles}
          InputProps={{
            ...params.InputProps,
            className:
              "typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular",
            inputComponent: CustomInput,
            endAdornment: (
              <Fragment>
                <Box></Box>
              </Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />

  // <Autocomplete
  //     multiple
  //     id="tags-outlined"
  //     options={options}
  //     getOptionLabel={(option) => option.label}
  //     // defaultValue={}
  //     onChange={onChange}
  //     filterSelectedOptions
  //     renderInput={(params) => {
  //         return (
  //             <TextField
  //                 {...params}

  //                 sx={customStyles}
  //                 label="Add tags"
  //                 placeholder="Favorites"
  //                 InputProps={
  //                     {
  //                         inputComponent: CustomInput
  //                     }
  //                 }
  //             />
  //         )
  //     }
  //     }
  // />
  );
}

CustomAutoCompleteTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  populator: PropTypes.func.isRequired,
  dependency: PropTypes.bool.isRequired,
  triggerValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func,
  multiple: PropTypes.bool.isRequired,
};

CustomAutoCompleteTextField.defaultProps = {
  label: "",
  name: "",
  value: [],
  // populator: () => {},
  dependency: false,
  triggerValue: [],
  populator: () => {},
  onChange: () => {},
  multiple: true,
  isOptionEqualToValue: (option, value) => option.value === value.value,
};

export default CustomAutoCompleteTextField;
