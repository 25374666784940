import {
  BulkAddResourcesInput,
  CreateResourceInput,
  GetQueryProps,
  ResourceResendInviteInput,
  UpdateResourceActiveInput,
  UpdateResourceInput,
  UpdateResourceLockInput,
  UploadProfilePictureInput,
} from "shared";
import { authAxiosClient, axiosClient } from "utilz";
import {
  apiErrorHandler,
  debugConsole,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const getAllResources = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: `/controlCenter/fetchall/resource/${getMspId()}`,
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createResource = async (data: CreateResourceInput) => {
  try {
    const url = "/v2/controlCenter/resource";
    const response = await axiosClient.post(url, data);
    const resData = response.data;
    if (resData?.status === "success" && resData?.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const bulkAddResources = async (data: BulkAddResourcesInput) => {
  try {
    const url = `/controlCenter/bulk/create/resource/${getMspId()}`;
    const response = await axiosClient.post(`${url}`, { userData: data });
    const responseData = response.data;
    if (
      response?.status === 200 &&
      responseData?.status === "success" &&
      responseData?.statusCode === 200 &&
      Array.isArray(responseData.data) &&
      responseData.data.length > 0
    ) {
      return responseData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const resourceResendInvite = async (data: ResourceResendInviteInput) => {
  try {
    const url = "/activate/resend";
    const response = await authAxiosClient.post(`${url}`, data);
    const resData = response?.data;
    if (resData?.statusCode === 200 && resData?.success) {
      return resData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const resourceResetPassword = async (email: string) => {
  try {
    const url = "/reset/password";
    const response = await authAxiosClient.post(`${url}`, { email });
    const resData = response?.data;
    if (resData?.statusCode === 200 && resData?.success) {
      return resData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getResourceById = async (resourceId: string) => {
  try {
    const url = `/controlCenter/fetch/resource/${resourceId}/${getMspId()}`;
    const response = await axiosClient.get(`${url}`);
    const responseData = response.data;
    if (
      responseData &&
      responseData?.status === "success" &&
      responseData?.data &&
      Array.isArray(responseData?.data) &&
      responseData?.data.length > 0
    ) {
      return responseData?.data[0];
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateResource = async ({
  resourceId,
  ...newResourceData
}: UpdateResourceInput) => {
  try {
    const url = `/v2/control-center/resource/${resourceId}`;
    const response = await axiosClient.patch(`${url}`, newResourceData);
    const responseData = response?.data;
    if (
      responseData &&
      responseData?.statusCode === 200 &&
      responseData?.success === true &&
      responseData?.data
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const uploadProfilePicture = async ({
  resourceId,
  formData,
}: UploadProfilePictureInput) => {
  try {
    const url = `/controlCenter/upload/resource/profilephoto/${resourceId}`;
    const response = await axiosClient.post(`${url}`, formData);
    const responseData = response?.data;
    if (
      responseData &&
      responseData?.statusCode === 200 &&
      responseData?.success === true &&
      responseData?.data
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteResourceProfilePicture = async ({
  resourceId,
}: {
  resourceId: string;
}) => {
  try {
    const url = `/resource/profilephoto/${resourceId}`;
    const response = await axiosClient.patch(`${url}`);
    const responseData = response?.data;
    if (
      responseData &&
      responseData?.statusCode === 200 &&
      responseData?.success === true &&
      responseData?.data
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getResourceStatusById = async (resourceId: string) => {
  try {
    const data = {
      userId: resourceId,
      tenantId: getMspId(),
    };
    const url = "/account/status";
    const response = await authAxiosClient.post(url, data);
    const resData = response.data;
    if (resData && resData.statusCode === 200) {
      return Boolean(resData?.data?.verified);
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getResourcePermissions = async (resourceId: string) => {
  try {
    const url = `/controlCenter/fetch/permissions/resource/${resourceId}`;
    const response = await axiosClient.get(`${url}`);
    const responseData = response.data;
    if (
      responseData &&
      responseData?.success === true &&
      responseData.statusCode === 200 &&
      responseData?.data
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getResourceProficiency = async (resourceId: string) => {
  try {
    const url = `/controlCenter/proficiency/${resourceId}`;
    const response = await axiosClient.get(`${url}`);
    const responseData = response.data;
    if (
      responseData &&
      responseData?.success === true &&
      responseData.statusCode === 200 &&
      responseData?.data
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateResourceProficiency = async ({
  data,
  resourceId,
}: {
  resourceId: string;
  data: any;
}) => {
  try {
    const url = `/controlCenter/proficiency/${resourceId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const responseData = response?.data;
    if (
      responseData &&
      responseData?.statusCode === 200 &&
      responseData?.success === true &&
      responseData?.data
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const bulkDeleteResource = async (data: {
  resource_ids: Array<string>;
}) => {
  try {
    const url = "/v2/controlCenter/resource";
    const response = await axiosClient.patch(`${url}`, data);
    const responseData = response?.data;
    if (
      responseData &&
      responseData?.statusCode === 200 &&
      responseData?.success === true &&
      responseData?.data
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateResourceActive = async ({
  resourceId,
  isActive,
}: UpdateResourceActiveInput) => {
  try {
    const url = `/v1/control-center/resource/${resourceId}/active/${isActive}`;
    const response = await axiosClient.patch(`${url}`);
    const responseData = response?.data;
    if (
      responseData &&
      responseData?.statusCode === 200 &&
      responseData?.success === true
    ) {
      return responseData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateResourceLock = async ({
  resourceId,
  isLocked,
}: UpdateResourceLockInput) => {
  try {
    const url = `/v1/control-center/resource/${resourceId}/lock/${isLocked}`;
    const response = await axiosClient.patch(`${url}`);
    const resData = response?.data;
    if (resData?.statusCode === 200 && resData?.success) {
      return resData;
    }
    throw resData?.message;
  } catch (error) {
    throw error;
  }
};
