import { styled, useTheme } from "@mui/material/styles";
import MuiTableHead, { TableHeadProps } from "@mui/material/TableHead";
import React, { useState } from "react";
import { TableRowRoot } from "./TableRowRoot";
import TableCell from "@mui/material/TableCell";
import CustomCheckBox from "../../library/base/custom-checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomIcon from "../../library/icon-library";
import Box from "@mui/material/Box";
import { VerticalArrowsIcon } from "components/icons";
import { IconButton, SxProps } from "@mui/material";
import { TableCellRoot } from "./TableCellRoot";
import { StyledTableCell } from "./StyledTableCell";
interface HeaderProps {
  name: string;
  sortable?: boolean;
  key: string;
  numeric?: boolean;
  metadata?: Record<string, any>;
  clickable?: boolean;
}

interface StickyHeaderProps extends HeaderProps {
  width: string;
}

interface CustomTableHeadProps extends TableHeadProps {
  headers: Array<HeaderProps>;
  onHeaderItemClick?: (data: any) => void;
  leftHeaders?: Array<StickyHeaderProps>;
  rightHeaders?: Array<StickyHeaderProps>;
  onAllSelect?: (currentState: "checked" | "unchecked") => void;
  onSortingClick?: (column: string) => void;
  multiselect?: boolean;
  cellProps?: any;
}
export const StickyTableHeadRoot = ({
  headers,
  onHeaderItemClick,
  leftHeaders,
  rightHeaders,
  onAllSelect,
  onSortingClick,
  multiselect = true,
  cellProps,
  ...rest
}: CustomTableHeadProps) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const theme = useTheme();
  const HandleCheckboxClick = () => {
    setIsAllSelected((prev) => {
      onAllSelect && onAllSelect(!prev === true ? "checked" : "unchecked");
      return !prev;
    });
  };
  return (
    <TableHeadStyled {...rest}>
      <TableRowRoot header>
        {/* {multiselect ? (
          <TableCell>
            <CustomCheckBox
              varient="primary"
              onClick={HandleCheckboxClick}
              checked={isAllSelected}
            />
          </TableCell>
        ) : null} */}

        {leftHeaders &&
          leftHeaders.map((headCell, leftIndex) => (
            <StyledTableCell
              sx={{
                whiteSpace: "nowrap",
                fontSize: "14px",
                left: leftIndex > 0 ? leftHeaders[leftIndex - 1].width : 0,
                position: "sticky",
                top: 0,
                zIndex: 300,
                minWidth: headCell.width,
                maxWidth: headCell.width,
                borderRight:
                  leftIndex === leftHeaders.length - 1
                    ? `1px solid ${theme.palette.neutral["015"]}`
                    : "none",
                "& .sort-button": {
                  visibility: "hidden",
                },
                "&:hover": {
                  "& .sort-button": {
                    visibility: "visible",
                  },
                },
                ...cellProps?.[headCell.key]?.sx,
              }}
              key={headCell.name}
              align={headCell.numeric ? "right" : "left"}
              // padding={headCell.disablePadding ? "none" : "normal"}
              // sortDirection={orderBy === headCell.id ? order : false}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "8px",
                  color: theme.palette.lightBg.high,
                }}
              >
                {headCell.name}
                {/* TODO: add sorting */}
                {headCell.sortable && (
                  <IconButton
                    className="sort-button"
                    onClick={() =>
                      onSortingClick && onSortingClick(headCell.key)
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      p: 0,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <VerticalArrowsIcon />
                  </IconButton>
                )}
              </Box>
            </StyledTableCell>
          ))}
        {headers.map((headCell) => (
          <StyledTableCell
            sx={{
              whiteSpace: "nowrap",
              fontSize: "14px",
              "& .sort-button": {
                visibility: "hidden",
              },
              "&:hover": {
                "& .sort-button": {
                  visibility: "visible",
                },
              },
              ...cellProps?.[headCell.key]?.sx,
            }}
            key={headCell.name}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
                onHeaderItemClick && onHeaderItemClick(headCell);
              }}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "8px",
                cursor: headCell.clickable ? "pointer" : "default",
                color: headCell.clickable
                  ? theme.palette.purple.main
                  : theme.palette.lightBg.high,
              }}
            >
              {headCell.name}
              {/* TODO: add sorting */}
              {headCell.sortable && (
                <IconButton
                  className="sort-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSortingClick && onSortingClick(headCell.key);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    p: 0,
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <VerticalArrowsIcon />
                </IconButton>
              )}
            </Box>
          </StyledTableCell>
        ))}

        {rightHeaders &&
          rightHeaders.map((headCell, rightIndex) => (
            <StyledTableCell
              sx={{
                right: `${120 * (rightHeaders.length - 1 - rightIndex)}px`,
                //   rightIndex === rightHeaders.length - 1
                //     ? 0
                //     : rightHeaders[rightIndex + 1].width,
                position: "sticky",
                zIndex: 300,
                minWidth: headCell.width,
                maxWidth: headCell.width,
                borderLeft:
                  rightIndex === 0
                    ? `1px solid ${theme.palette.neutral["015"]}`
                    : "none",
                whiteSpace: "nowrap",
                fontSize: "14px",
                "& .sort-button": {
                  visibility: "hidden",
                },
                "&:hover": {
                  "& .sort-button": {
                    visibility: "visible",
                  },
                },
                ...cellProps?.[headCell.key]?.sx,
              }}
              key={headCell.name}
              align={headCell.numeric ? "right" : "left"}
              // padding={headCell.disablePadding ? "none" : "normal"}
              // sortDirection={orderBy === headCell.id ? order : false}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "8px",
                  color: theme.palette.lightBg.high,
                }}
              >
                {headCell.name}
                {/* TODO: add sorting */}
                {headCell.sortable && (
                  <IconButton
                    className="sort-button"
                    onClick={() =>
                      onSortingClick && onSortingClick(headCell.key)
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      p: 0,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <VerticalArrowsIcon />
                  </IconButton>
                )}
              </Box>
            </StyledTableCell>
          ))}
      </TableRowRoot>
    </TableHeadStyled>
  );
};
const TableHeadStyled = styled(MuiTableHead)<TableHeadProps>(({ theme }) => {
  return {
    ...theme.typography.caption,
  };
});
