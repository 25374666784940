import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

// HOC imports
import { CustomCard } from "../../../../../../../../../../../library/layers";
import {
  CustomTextField,
  CustomTextAreaField,
} from "../../../../../../../../../../../library/system/custom-form";

// Constants
import { GENERAL_FORM_FIELDS } from "./constants";

const ResourceTypeCreateGeneralForm = (props) => {
  const [resourceType, desciption] = props.values;
  const [setResourceType, setDescription] = props.state;
  const dataFields = {
    fields: {
      resource_type_name: {
        ...GENERAL_FORM_FIELDS.resource_type_name,
        renderKey: "",
        value: "",
      },
      resource_type_description: {
        ...GENERAL_FORM_FIELDS.resource_type_description,
        renderKey: "",
        value: "",
      },
    },
  };

  // // Imperative Handle Configs
  // useImperativeHandle(ref, () => {
  //     return {
  //         submit() {
  //             let data = {};
  //             Object.values(state?.fields).forEach((element) => {
  //                 data[`${element.mappingId}`] = state.fields[`${element.name}`].value
  //             })
  //             return data;
  //         }
  //     };
  // }, [state]);

  // Effects

  // Renderer
  return (
    <React.Fragment>
      <Box sx={{ my: "24px" }}>
        <Grid container columnSpacing={"24px"} rowSpacing={"24px"}>
          {Object.values(dataFields.fields).map((item) => {
            return (
              <Grid
                key={item?.key}
                item
                xs={item?.width["xs"]}
                md={item?.width["md"]}
              >
                {item?.type === "text" ? (
                  <CustomTextField
                    key={item?.renderKey}
                    name={item?.name || ""}
                    label={item?.label || ""}
                    value={resourceType.toString() || ""}
                    disabled={false}
                    required={false}
                    error={false}
                    helperText={""}
                    placeholder={item?.placeholder}
                    changeHandler={(data) => {
                      setResourceType(data.value);
                    }}
                  />
                ) : item?.type === "textarea" ? (
                  <CustomTextAreaField
                    key={item?.renderKey}
                    name={item?.name || ""}
                    label={item?.label || ""}
                    value={desciption.toString() || ""}
                    disabled={false}
                    required={false}
                    error={false}
                    helperText={""}
                    placeholder={""}
                    size={"medium"}
                    maxRows={6}
                    minRows={1}
                    rows={5}
                    changeHandler={(data) => {
                      setDescription(data.value);
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ResourceTypeCreateGeneralForm;
