import { Stack, useTheme } from "@mui/material";
import { PlayStopButton, TimeCounter } from "components";
import {
  useEndTimer,
  useGlobalStore,
  useNonPersistantMainStore,
  useRemoveWatchListItem,
  useStartTimer,
  useTimeEntryStore,
} from "controller";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ActiveTimeEntry } from "shared/types";
import { convertUTCtoTimezone } from "utilz/helpers";

export const ChatTimeEntryTimer = ({
  serviceId,
  serviceType,
  disable,
}: {
  disable?: boolean;
  serviceId: string;
  serviceType: "ticket" | "request" | "project" | "task";
}) => {
  const navigate = useNavigate();
  const timeEntry = useTimeEntryStore((state) =>
    state.activeTimeEntriesDetails.find(
      (item) =>
        item.service_id === serviceId && item.service_type === serviceType
    )
  );
  const resourceId = useGlobalStore((state) => state.resourceId);
  const { mutateAsync: startTimer, isLoading: isStartingTimer } =
    useStartTimer();
  const { mutateAsync: endTimer, isLoading: isStoppingTimer } = useEndTimer();
  const globalEndTimeHandler = useTimeEntryStore(
    (state) => state.removeTimeEntry
  );

  const onClick = () => {
    if (!timeEntry) {
      return;
    }
    switch (serviceType) {
      case "ticket":
        navigate(`/app/service-desk/tickets/${serviceId}`, {
          state: { timeEntry: timeEntry.mapping_id },
        });
        break;
      case "task":
        navigate(
          `/app/service-desk/projects/${timeEntry.project_id}/task/${serviceId}`,
          {
            state: { timeEntry: timeEntry.mapping_id },
          }
        );
        break;
      default:
        return;
    }
    close();
  };

  const stopTimeEntry = async () => {
    try {
      if (!timeEntry) {
        throw "No time entry found";
      }
      await endTimer({
        serviceId: serviceId,
        serviceType: serviceType,
        timeEntryId: timeEntry.mapping_id,
      });
      globalEndTimeHandler({
        serviceId: serviceId,
        timeEntryId: timeEntry.mapping_id,
        serviceType: serviceType,
      });

      setTimeout(() => {
        onClick();
      }, 500);
      return;
    } catch (e: any) {
      toast.error("Unable to stop time entry");
    }
  };

  const startTimeEntry = async () => {
    try {
      await startTimer({
        resourceId: resourceId || "",
        serviceId: serviceId,
        serviceType: serviceType,
      }).then((res) => {});
    } catch (e: any) {
      toast.error("Unable to stop time entry");
    }
  };

  const theme = useTheme();
  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "center",
        gap: "2px",
        border: `1px solid ${theme.palette.neutral["015"]}`,
        borderRadius: "16px",
        width: "fit-content",
        pr: "6px",
        height: "fit-content",
        bgcolor: theme.palette.darkBg.high,
        cursor: "default",
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <PlayStopButton
        sx={{ boxShadow: "unset" }}
        disabled={disable || isStoppingTimer || isStartingTimer}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (timeEntry) {
            return stopTimeEntry();
          }
          startTimeEntry();
        }}
        isPlaying={Boolean(timeEntry)}
      />
      <TimeCounter
        value={disable ? 0 : timeEntry?.activeTimerValueInSeconds || 0}
      />
    </Stack>
  );
};
