import { Drawer, Tab, Tabs } from "components";
import { useState } from "react";
import { AppsForMspCustomers } from "./AppsForMspCustomers";
import { AppsForMsp } from "./AppsForMsp";

export const DownloadDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const TABS = [
    {
      label: "Apps for your customers",
      content: <AppsForMspCustomers closeDownloadDrawer={onClose} />,
    },
    {
      label: "Apps for you",
      content: <AppsForMsp />,
    },
  ];

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={"Download"}
      additionalHeaderComponent={
        <Tabs
          value={currentTabIndex}
          onChange={(event: any, index: number) => {
            setCurrentTabIndex(index);
          }}
          sx={{ pl: "24px" }}
        >
          {TABS.map(({ label }) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      }
      drawerStyle={{
        minWidth: "458px",
      }}
      footer={<></>}
    >
      {TABS[currentTabIndex].content}
    </Drawer>
  );
};
