import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import config from "./constants/config.json";





// const CustomTypography = (props)=> {
//     const [state, setState] = useState({size: '', color: '', variant: ''});
//     useEffect(()=> {
//         const size = config?.SIZE[`${props.size}`];
//         const variant = config?.VARIANT[`${props.variant}`];
//         const color = props?.palette === 'color'? config?.COLORS[`${props.color}`]: config?.FOCUS[`${props.color}`];
//         setState({
//             ...state,
//             size: size,
//             variant: variant,
//             color: color
//         });
//     }, []);

//     // Design Configs
//     const CustomTypographyWrapper = styled.p`
//         font-weight: ${state?.variant};
//         font-size: ${state?.size};
//         color: ${state?.color};
//         `;

//     // Renderer
//     return(
//         <CustomTypographyWrapper
//             className = {`typography-master-class-bold`}
//         >
//             {props?.content}
//         </CustomTypographyWrapper>
//     )
// }

const CustomTypography = (props) => {
  // States
  const [state, setState] = useState({
    sizeClass: "extra-small",
    colorClass: "primary-main",
    variantClass: "regular",
    faceClass: "regular"
  });

  // Effects
  useEffect(() => {
    try {
      let { sizeClass, colorClass, variantClass, faceClass } = state;
      sizeClass = config?.SIZE[`${props.size}`]?.map;
      colorClass = config?.COLORS[`${props.color}`]?.map;
      variantClass = config?.VARIANT[`${props.variant}`]?.map;
      faceClass = config?.VARIANT[`${props.variant}`]?.faceMap;
      setState({
        ...state,
        sizeClass: sizeClass,
        colorClass: colorClass,
        variantClass: variantClass,
        faceClass: faceClass
      });
    } catch (err) {
      console.log("[ERROR: TYPOGRAPHY] Setting props");
      console.log(err);
    }
  }, [props]);

  // Renderer
  return (
    <p
      className={
        `typography-font-family-class-${state?.faceClass} 
                 typography-font-size-class-${state?.sizeClass} 
                 typography-font-color-${state?.colorClass} 
                 typography-font-weight-class-${state?.variantClass}`
      }
    >
      {props?.content}
    </p>
  );
};

CustomTypography.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

CustomTypography.defaultProps = {
  size: "",
  color: "",
  variant: "",
  content: "",
};

export default CustomTypography;