import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import { Drawer, Input, RadioButtonGroup, SelectField } from "components";
import CustomQuillEditor from "components/quill/QuillEditor";
import {
  useGetAllCannedNotes,
  useGlobalStore,
  useMainStore,
  useSendChatMessage,
} from "controller";
import CustomCheckBox from "library/base/custom-checkbox";
import { CustomRadioGroup } from "library/system/custom-form";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AccessSpecifier } from "shared/types";
import { transformAssigneesForChat } from "utilz/helpers";
import theme from "utilz/theme/theme";
import * as yup from "yup";

interface CannedNotesModalProps {
  open: boolean;
  onClose: () => void;
  currentServiceId: string;
  serviceType: string;
  isDisabled: boolean;
  originSource: string;
}

// Custom debounce function
function useDebounce<P extends (...args: any[]) => any>(
  callback: P,
  delay: number
): (...args: Parameters<P>) => void {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (...args: Parameters<P>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
}

function CannedNoteDrawer({
  open,
  onClose,
  currentServiceId,
  serviceType,
  isDisabled,
  originSource,
}: CannedNotesModalProps) {
  const onSubmitHandler = () => {};
  const [cannedNotes, setCannedNotes] = useState("");
  const [note, setNote] = useState("");
  const { mutateAsync: sendMessage, isLoading } = useSendChatMessage();
  const [messageType, setMessageType] = useState<AccessSpecifier>("private");
  const resourceId = useGlobalStore((state) => state.resourceId);
  const profilePic = useGlobalStore((state) => state.profilePictureUrl);
  const displayName = useGlobalStore((state) => state.name);
  const assignees = useMainStore((state) => state.assignees);
  const { data: cannedData } = useGetAllCannedNotes({});

  const schema = yup.object({
    canned_note: yup.string().required("Canned note title is required"),
    canned_notes_quil: yup.string().required("Description is required"),
    message_type: yup.string().required("Message type is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      canned_note: "",
      canned_notes_quil: "",
      message_type: "private",
    },
    resolver: yupResolver(schema),
  });

  const RadioMenuData = [
    { label: "Private", value: "private" },
    { label: "Public", value: "public" },
    { label: "Self", value: "self" },
  ];

  const watch_canned_note = watch("canned_note");
  const watch_message_type = watch("message_type");
  useEffect(() => {
    if (watch_canned_note) {
      const note = (cannedData?.result || []).find(
        (item: any) => item.mapping_id === watch_canned_note
      );
      if (note) {
        setValue("canned_notes_quil", note.canned_notes_quil);
      }
    }
  }, [watch_canned_note]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSendMessageRaw = useCallback(
    async (value: any) => {
      if (!isDisabled) {
        const transformedAssignees = await transformAssigneesForChat(assignees);
        const messageData: any = {
          assignedResources: transformedAssignees,
          message: value?.canned_notes_quil,
          accessSpecifier: value?.message_type,
          serviceId: currentServiceId,
          serviceType,
          originType: originSource,
          messageType: "canned_notes",
          sender: {
            mapping_id: resourceId || "",
            display_name: displayName || "",
            profile_pic: profilePic || "",
            user_type: "resource",
          },
        };
        const data = await sendMessage(messageData);

        handleClose();
      }
    },
    [
      isDisabled,
      assignees,
      currentServiceId,
      serviceType,
      originSource,
      resourceId,
      displayName,
      profilePic,
      sendMessage,
      handleClose,
    ]
  );

  const handleSendMessage = useDebounce(handleSendMessageRaw, 300);

  const onSubmit = handleSubmit((value) => {
    handleSendMessage(value);
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={"Add new canned note"}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={handleClose}
      primaryButtonText={"Send"}
      headerStyles={{
        borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
      }}
      isLoading={isLoading}
      drawerStyle={{ width: "624px" }}
      //   footer={!isShowFooter && <></>}
      onPrimaryButtonClick={onSubmit}
      isPrimaryButtonDisabled={isDisabled || !isDirty}
      isSecondaryButtonDisabled={isDisabled || !isDirty}
      footerLeftActionComponent={
        serviceType === "ticket" && (
          <RadioButtonGroup
            row
            value={watch_message_type}
            data={RadioMenuData}
            labelMappingKey="label"
            valueMappingKey="value"
            onChange={(e: any) => {
              //   setMessageType(e);
              setValue("message_type", e);
            }}
          />
        )
      }
    >
      <Box sx={{ height: "100%", overflowY: "hidden" }}>
        <Box px="16px" py="16px">
          <SelectField
            name="canned_note"
            label="Canned notes"
            control={control}
            // value={cannedNotes}
            options={cannedData?.result}
            labelMappingKey="canned_notes_title"
            valueMappingKey="mapping_id"
            error={errors?.canned_note ? true : false}
            errorMessage={errors?.canned_note?.message}
          />
        </Box>
        <Box sx={{ display: "block", height: "100%" }}>
          <Controller
            control={control}
            name={"canned_notes_quil"}
            render={({ field }) => (
              <CustomQuillEditor
                {...field}
                key={"canned_notes_quil"}
                placeholder={"Description *"}
                name={"canned_notes_quil"}
                onChange={(event: { field: string; value: string }) => {
                  field.onChange(event.value);
                }}
                error={errors?.canned_notes_quil ? true : false}
              />
            )}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

export default CannedNoteDrawer;
