import React, {useRef, useState, useEffect, forwardRef, useImperativeHandle} from "react";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

// HOC Imports
import { CustomTextField, CustomTextAreaField } from "../../../../../../../../../../library/system/custom-form";

// CONSTANTS
import { GENERAL_FORM_FIELDS } from "./constants";
import { MOCK_DATA } from "../../../../constants";

const ResourceTypeGeneralEditForm = forwardRef((props, ref)=> {
  // States
  const [ state, setState] = useState({
    fields: {
      resource_type_name: {...GENERAL_FORM_FIELDS.resource_type_name, renderKey: "", value: ""},
      resource_type_description: {...GENERAL_FORM_FIELDS.resource_type_description, renderKey: "", value: ""},
    },

  });
    // Imperative Handle Configs
  useImperativeHandle(ref, () => {
    return {
      submit() {
        const data = {};
        Object.values(state?.fields).forEach((element) => {
          data[`${element.mappingId}`] = state.fields[`${element.name}`].value;
        });
        return data;
      }
    };
  }, [state]);

  // Effects
  useEffect(()=> {
    const data = MOCK_DATA.find((element)=> element?.mapping_id === props.resource_type_id);
    // console.log(data);
    setState({
      ...state,
      fields: {
        ...state.fields, 
        resource_type_name: {...state.fields.resource_type_name, value: data["resource_type_name"], renderKey: v4()},
        resource_type_description: {...state.fields.resource_type_description, value: data["resource_type_description"], renderKey: v4()},
      }
    });
  }, [props]);


  //  Event handlers
  const handleTextFieldChange = (data)=> {
    const { field, value } = data;
    try{
      const currentValues = state?.fields;
      currentValues[`${field}`] = {...currentValues[`${field}`], value: value};
      setState({...state, fields: currentValues});
    }catch(err) {
      console.log("[ERROR] Handling change in text-field");
      console.log(err);
    }
  };

  // Renderer
  return(
    <React.Fragment>
      <Box  sx={{my: "24px"}}>
        <Grid 
          container
          columnSpacing={"24px"}
          rowSpacing={"24px"}
        >
          {
            Object.values(state?.fields)
              .map((item)=> {
                return (
                  <Grid 
                    key={item?.key} 
                    item 
                    xs={item?.width["xs"]} 
                    md={item?.width["md"]}
                  >
                    {
                      item?.type === "text"?
                        <CustomTextField
                          key={item?.renderKey}
                          name={item?.name || ""}
                          label={item?.label || ""}
                          value={item?.value.toString() || ""}
                          disabled={props.isEdit}
                          required={false}
                          error={false}
                          helperText={""}
                          placeholder={item?.placeholder}
                          changeHandler={handleTextFieldChange}
                        />
                        :
                        item?.type === "textarea"?
                          <CustomTextAreaField
                            key={item?.renderKey}
                            name={item?.name || ""}
                            label={item?.label || ""}
                            value={item?.value || ""}
                            disabled={props.isEdit}
                            required={false}
                            error={false}
                            helperText={""}
                            placeholder={""}
                            size={"medium"}
                            maxRows={6}
                            minRows={1}
                            rows={5}
                            changeHandler={handleTextFieldChange}
                          />
                          :
                          ""
                    }
                  </Grid>
                );
              })
          }
        </Grid>
      </Box>
    </React.Fragment>
  );
});

export default ResourceTypeGeneralEditForm;
