import { SvgIcon, SvgIconProps } from "@mui/material";

export const BookmarkFilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M4.997 19.503V6.998a3.001 3.001 0 0 1 3.001-3.001h8.004a3.001 3.001 0 0 1 3 3v12.506a.5.5 0 0 1-.79.407l-5.92-4.23a.5.5 0 0 0-.583 0l-5.92 4.23a.5.5 0 0 1-.792-.407Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
