import { Grid, Stack, Typography } from "@mui/material";
import { Chip, Input, SelectField } from "components";
import {
  useFetchAllResourceGroupsById,
  useGetAllLocations,
  useGetAllResourceTypes,
  useGetAllResources,
  useGetGeneralAttributes,
  useGetResourceById,
  useGlobalStore,
} from "controller";
import { TimeZoneList } from "shared";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { getFullName } from "utilz/helpers";

const schema = yup.object({
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().nullable(),
  office_desk_number: yup.string().nullable(),
  phone_number: yup.string().nullable(),
  resource_type: yup.string().required("Resource type is required"),
  resource_work_role: yup.string().nullable(),
  resource_work_type: yup.string().nullable(),
  manager: yup.string().nullable(),
  department: yup.string().nullable(),
  location: yup.string().nullable(),
  time_zone: yup.string().required("Time zone is required"),
});
export const GeneralTab = forwardRef(
  (
    {
      resourceDetails,
      resourceId,
      onSubmit,
    }: {
      resourceDetails: any;
      resourceId: string;
      onSubmit: (data: any) => void;
    },
    ref
  ) => {
    const {
      reset,
      control,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        office_desk_number: "",
        phone_number: "",
        resource_type: "",
        resource_work_role: "",
        resource_work_type: "",
        manager: "",
        department: "",
        location: "",
        time_zone: "",
      },
      resolver: yupResolver(schema),
    });

    const mspId = useGlobalStore((state) => state.mspId);
    const { data: resourceGroups } = useFetchAllResourceGroupsById(resourceId);

    const { data: workType } = useGetGeneralAttributes({
      attributeName: "work_type",
    });
    const { data: workRole } = useGetGeneralAttributes({
      attributeName: "work_role",
    });
    const { data: resourceType } = useGetAllResourceTypes({
      mspId: mspId,
    });
    const { data: managers } = useGetAllResources({
      filter: [
        {
          column: "resource_type",
          operator: "=",
          value: [
            "b110abc3-0709-491f-ac04-86eb25159386",
            "9d6afd0e-b353-438d-a34d-1bb1cc5bbf03",
          ],
        },
      ],
    });
    const { data: location } = useGetAllLocations({});

    useEffect(() => {
      if (resourceDetails) {
        reset({
          department: resourceDetails?.department,
          email: resourceDetails?.email,
          first_name: resourceDetails?.first_name,
          last_name: resourceDetails?.last_name,
          location: resourceDetails?.location_id,
          manager: resourceDetails?.manager,
          middle_name: resourceDetails?.middle_name,
          office_desk_number: resourceDetails?.office_desk_number,
          phone_number: resourceDetails?.phone_number,
          resource_type: resourceDetails?.resource_type_id,
          resource_work_role: resourceDetails?.resource_work_role,
          resource_work_type: resourceDetails?.resource_work_type,
          time_zone: resourceDetails?.time_zone,
        });
      }
    }, [resourceDetails]);

    const onSubmitClick = handleSubmit((data) => {
      onSubmit(data);
    });

    useImperativeHandle(ref, () => ({ submit: onSubmitClick }));

    return (
      <Stack p={"32px"}>
        <Grid container columnSpacing={"24px"} rowSpacing={"16px"}>
          <Grid item xs={4} sm={4}>
            <Input
              name="first_name"
              label="First name"
              required
              fullWidth
              control={control}
              error={errors.first_name ? true : false}
              errorMessage={errors.first_name?.message || ""}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              label="Middle name"
              name="middle_name"
              fullWidth
              control={control}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              label="Last name"
              name="last_name"
              required
              fullWidth
              control={control}
              error={errors.last_name ? true : false}
              errorMessage={errors.last_name?.message || ""}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              label="Email"
              name="email"
              fullWidth
              control={control}
              disabled
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              label="Desk number"
              name="office_desk_number"
              fullWidth
              control={control}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              label="Phone number"
              name="phone_number"
              fullWidth
              control={control}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SelectField
              name="resource_type"
              label="Resource type"
              required
              fullWidth
              valueMappingKey="mapping_id"
              labelMappingKey="resource_type_name"
              options={
                Array.isArray(resourceType?.result) ? resourceType.result : []
              }
              control={control}
              error={errors.resource_type ? true : false}
              errorMessage={errors.resource_type?.message || ""}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SelectField
              label="Work role"
              name="resource_work_role"
              fullWidth
              valueMappingKey="mapping_id"
              labelMappingKey="work_role_name"
              control={control}
              options={Array.isArray(workRole) ? workRole : []}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SelectField
              name="resource_work_type"
              label="Work type"
              fullWidth
              control={control}
              valueMappingKey="mapping_id"
              labelMappingKey="work_type_name"
              options={Array.isArray(workType) ? workType : []}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SelectField
              label="Manager"
              name="manager"
              valueMappingKey="mapping_id"
              labelMappingKey="full_name"
              fullWidth
              isSearchable
              control={control}
              options={
                Array.isArray(managers?.result)
                  ? managers.result.map((item: any) => ({
                      ...item,
                      full_name:
                        getFullName(
                          item?.first_name,
                          item?.middle_name,
                          item?.last_name
                        ) || "",
                    }))
                  : []
              }
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              label="Department"
              name="department"
              fullWidth
              control={control}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SelectField
              label="Location"
              name="location"
              fullWidth
              valueMappingKey="mapping_id"
              labelMappingKey="location_name"
              options={
                location?.result && Array.isArray(location?.result)
                  ? location.result
                  : []
              }
              control={control}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SelectField
              name="time_zone"
              label="Time zone"
              isSearchable
              required
              isGrouped={true}
              groupedOptions={TimeZoneList}
              labelMappingKey="label"
              valueMappingKey="value"
              control={control}
              error={errors.time_zone ? true : false}
              errorMessage={errors.time_zone?.message || ""}
            />
          </Grid>
        </Grid>
        {Array.isArray(resourceGroups) && (
          <Stack gap={"16px"}>
            {resourceGroups.length > 0 ? (
              <Typography mt={"32px"} variant="caption">
                {"Resource Groups"}
              </Typography>
            ) : null}
            <Stack
              direction={"row"}
              sx={{
                columnGap: "12px",
                rowGap: "8px",
                flexWrap: "wrap",
              }}
            >
              {resourceGroups.map((item) => (
                <Chip
                  key={`resource_gropus_item_${item.mapping_id}`}
                  label={
                    <Typography variant="caption-3">
                      {item.group_name}
                    </Typography>
                  }
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }
);
