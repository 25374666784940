import { create } from "zustand";
import { GlobalInterface, GlobalSlice } from "./global";
import { persist, createJSONStorage } from "zustand/middleware";

export const useGlobalStore = create<GlobalInterface>()(
  persist((...a) => ({ ...GlobalSlice(...a) }), {
    name: "global-store",
    storage: createJSONStorage(() => sessionStorage),
  })
);
