import { Immer } from "immer";
import { immer } from "zustand/middleware/immer";

type FilterProps = {
  column: string;
  operator: "=";
  value: string | Array<string>;
};

type Filter = {
  template_name?: FilterProps | null;
  discount?: FilterProps | null;
};
interface billingTemplateTableState {
  billingTemplateTable: {
    filter: Filter;
    // boardIds?: Array<string>;
    // customerIds?: Array<string>;
    pageNo: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
  };
}

interface billingTemplateTableAction {
  setBillingTemplateTableSorting: (column: string) => void;
  setBillingPageNumber: (pageNo: number) => void;
}

export interface billingTemplateTableInterface
  extends billingTemplateTableAction,
    billingTemplateTableState {}

export const billingTemplateTableSlice = immer<
  billingTemplateTableState & billingTemplateTableAction
>((set, get) => ({
  billingTemplateTable: {
    pageNo: 1,
    sortColumn: "created_at",
    sortDirection: "desc",
    filter: {},
  },

  setBillingPageNumber: (pageNo) =>
    set((state) => {
      state.billingTemplateTable.pageNo = pageNo;
    }),

  setBillingTemplateTableSorting: (column) =>
    set((state) => {
      if (column === state.billingTemplateTable.sortColumn) {
        state.billingTemplateTable.sortDirection =
          state.billingTemplateTable.sortDirection === "asc" ? "desc" : "asc";
      } else {
        state.billingTemplateTable.sortColumn = column;
        state.billingTemplateTable.sortDirection = "asc";
      }
    }),
}));
