import { SvgIcon, SvgIconProps } from "@mui/material";

export const HeadphoneIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#1C1E26"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M19 17h-1a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2ZM6 17H5a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h1a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1Z"
        clip-rule="evenodd"
      />
      <path
        stroke="#1C1E26"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M18.5 10v-.5A6.5 6.5 0 0 0 12 3v0a6.5 6.5 0 0 0-6.5 6.5v.5"
      />
      <path
        stroke="#1C1E26"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M12.625 21.25h-1.25c-.69 0-1.25-.56-1.25-1.25v0c0-.69.56-1.25 1.25-1.25h1.25c.69 0 1.25.56 1.25 1.25v0c0 .69-.56 1.25-1.25 1.25Z"
        clip-rule="evenodd"
      />
      <path
        stroke="#1C1E26"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="M13.875 20H16a2 2 0 0 0 2-2v-1"
      />
    </svg>
  </SvgIcon>
);
