import z from "zod";

export const sortDirection = z.enum(["asc", "desc"]);
export const getTimesheetInputSchema = z.object({
  perPageCount: z.number().optional(),
  pageNumber: z.number().optional(),
  sortColumn: z.string().optional(),
  sortDirection: sortDirection.optional(),
  searchQuery: z.string().optional(),
  timezone: z.string(),
  billingCycle: z.string().optional(),
  filters: z.array(z.any()).optional(),
});

export type GetTimesheetInputType = z.infer<typeof getTimesheetInputSchema>;

export const getTimesheetByPeriodInputSchema = z.object({
  sortColumn: z.string().optional(),
  sortDirection: sortDirection.optional(),
  timezone: z.string(),
  periodStart: z.string(),
  periodEnd: z.string(),
  serviceType: z.string().optional().nullable(),
  customer: z.string().optional().nullable(),
});

export type GetTimesheetByPeriodInputType = z.infer<
  typeof getTimesheetByPeriodInputSchema
>;

export const getTimesheetTimeEntriesSchema = z.object({
  periodStart: z.string(),
  periodEnd: z.string(),
  resourceId: z.string().optional(),
  serviceId: z.string().optional(),
  activityId: z.string().optional(),
  timezone: z.string(),
});

export type GetTimesheetTimeEntriesInputType = z.infer<
  typeof getTimesheetTimeEntriesSchema
>;

export type GetTimesheetEntriesByIdInputType = {
  submissionId: string;
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
  serviceType?: string | null;
  customer?: string | null;
};

export const submitTimesheetSchema = z.object({
  periodStart: z.string(),
  periodEnd: z.string(),
});

export type SubmitTimesheetInput = z.infer<typeof submitTimesheetSchema>;

export const rejectTimesheetSchema = z.object({
  submissionId: z.string(),
  comment: z.string().optional(),
});
export type RejectTimesheetInput = z.infer<typeof rejectTimesheetSchema>;
export type ApproveTimesheetInput = z.infer<typeof rejectTimesheetSchema>;
export type ResubmitTimesheetInput = {
  submissionId: string;
};
