import {
  Box,
  InputBase,
  Stack,
  Typography,
  useTheme,
  Theme,
  SxProps,
  ClickAwayListener,
  Tooltip,
  InputBaseComponentProps,
  TextareaAutosize,
} from "@mui/material";
import { TextArea } from "components";
import { ElementRef, forwardRef, useEffect, useRef, useState } from "react";

interface EditableChipProps {
  isEditMode?: boolean;
  value: string;
  onEdit: (newValue: string) => void;
  inputSx?: SxProps<Theme> | any;
  typographySx?: SxProps<Theme>;
  disabled?: boolean;
  onChange?: (value: string | undefined, isEdit: boolean) => void;
  onNavigate?: () => void;
  placeholder?: string;
  inputProps?: InputBaseComponentProps | undefined;
  sx?: SxProps<Theme> | any;
  multiline?: boolean;
}

export const EditableChip = forwardRef(
  (
    {
      inputProps,
      isEditMode = false,
      onEdit,
      value = "",
      inputSx,
      typographySx,
      disabled = false,
      onChange,
      onNavigate,
      placeholder = "",
      sx,
      multiline,
    }: EditableChipProps,
    ref: any
  ) => {
    const inputRef = ref || useRef<ElementRef<"input">>(null);
    const [isEdit, setIsEdit] = useState(isEditMode);
    const [data, setData] = useState(value);
    const theme = useTheme();
    useEffect(() => {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }, [isEditMode]);

    const onSubmit = () => {
      if (data.trim().length <= 0) {
        setData(value);
        onEdit(value);
        onChange?.(value, false);
      } else {
        onEdit(data);
        onChange?.(data, false);
      }
      setIsEdit(false);
    };

    useEffect(() => {
      setIsEdit(isEditMode);
    }, [isEditMode]);

    useEffect(() => {
      setData(value);
    }, [value]);

    return (
      <Box
        sx={{
          overflow: "hidden",
          flex: 1,
          minWidth: "0px",
          width: "max-content",
          paddingRight: "24px",
          alignItems: "center",
          cursor: "text",
          "& .custom-input-area": {
            outline: "none",
            my: "4px",
            borderRadius: "4px",
            width: "100%",
            bgcolor: theme.palette.neutral["B-005"],
            ...theme.typography["subtitle1"],
            px: "8px",
            border: "none",
            resize: "none",
            ...inputSx,
          },
          ...sx,
        }}
        onClick={(e) => {
          if (!disabled) {
            e.preventDefault();
            e.stopPropagation();
          }

          setIsEdit(true);
          setTimeout(() => {
            // if (multiline) {
            //   const textInput = inputRef?.current;
            //   const len = data.length;
            //   textInput.setSelectionRange(len, len);
            // }
            inputRef?.current?.focus();
          }, 200);
        }}
      >
        {isEdit && !disabled ? (
          <ClickAwayListener
            onClickAway={() => {
              onSubmit();
            }}
          >
            {multiline ? (
              <TextareaAutosize
                className="custom-input-area"
                autoComplete="off"
                name={"task"}
                placeholder={placeholder}
                ref={inputRef}
                value={data}
                onBlur={() => {
                  onSubmit();
                }}
                onKeyDown={(ev) => {
                  ev.stopPropagation();
                  if (ev.key === "Enter") {
                    onSubmit();
                    ev.preventDefault();
                  }
                }}
                onChange={(event) => {
                  setData(event.target.value);
                  onChange?.(event.target.value, true);
                  // onEdit(event.target.value);
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            ) : (
              <InputBase
                placeholder={placeholder}
                inputRef={inputRef}
                sx={{
                  width: "100%",
                  bgcolor: theme.palette.neutral["B-005"],
                  // minwidth: "100px",
                  ...theme.typography["subtitle1"],
                  px: "8px",
                  ...inputSx,
                }}
                name={"task"}
                value={data}
                onBlur={() => {
                  onSubmit();
                }}
                onKeyDown={(ev) => {
                  ev.stopPropagation();
                  if (ev.key === "Enter") {
                    onSubmit();
                    ev.preventDefault();
                  }
                }}
                inputProps={inputProps}
                onChange={(event) => {
                  setData(event.target.value);
                  onChange?.(event.target.value, true);
                  // onEdit(event.target.value);
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            )}
          </ClickAwayListener>
        ) : (
          // <Tooltip
          //   title={"Click to edit"}
          //   placement="top"
          //   followCursor={true}
          //   PopperProps={{
          //     sx: {
          //       "& .MuiTooltip-tooltip": {
          //         backgroundColor: "white",
          //         color: theme.palette.lightBg.high,
          //         ...theme.typography["caption"],
          //         // boxShadow: "0px 0px 1px #bebebe",
          //       },
          //     },
          //   }}
          // >

          <Typography
            onClick={(e) => {
              if (!disabled) {
                e.stopPropagation();
              }
              if (onNavigate) {
                onNavigate();
                return;
              } else {
                setIsEdit(true);
                setTimeout(() => {
                  inputRef?.current?.focus();
                }, 200);
              }
            }}
            variant="subtitle1"
            sx={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: multiline ? "normal" : "nowrap",
              maxWidth: "min-content",
              my: multiline ? "4px" : "0px",
              px: "8px",
              ":hover": {
                ...(onNavigate && {
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                  cursor: "pointer",
                }),
              },
              ...typographySx,
            }}
          >
            {data ? data : placeholder}
          </Typography>

          // </Tooltip>
        )}
      </Box>
    );
  }
);
