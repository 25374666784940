import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

export const ReportsLayout = () => {
  return (
    <Box
      sx={{
        // height: {
        //   xs: "calc(100vh - 56px)",
        //   sm: "calc(100vh - 56px)",
        //   md: "calc(100vh - 72px)",
        //   lg: "calc(100vh - 72px)",
        //   xl: "calc(100vh - 88px)",
        // },
        height: "calc(100vh - 64px)",
        // overflow: "clip",
        width: "100%",
      }}
    >
      <Outlet />
    </Box>
  );
};
