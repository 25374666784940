// import smSettingsIcon from "../icons/settingsIcon/smSettingsIcon";
// import mdSettingsIcon from "../icons/settingsIcon/mdSettingsIcon";
// import lgSettingsIcon from "../icons/settingsIcon/lgSettingsIcon";

import calenderIcon from "../icons/calenderIcon/calenderIcon";
import clockIcon from "../icons/clockIcon/clockIcon";
import userIcon from "../icons/userIcon/userIcon";
import printerIcon from "../icons/printerIcon/printerIcon";
import deleteIcon from "../icons/deleteIcon/deleteIcon";
import searchIcon from "../icons/searchIcon/searchIcon";
import columnsIcon from "../icons/columnsIcon/columnsIcon";
import editIcon from "../icons/editIcon/editIcon";
import plusIcon from "../icons/plusIcon/plusIcon";
import burgerMenuIcon from "../icons/burgerMenuIcon/burgerMenuIcon";
import notificationIcon from "../icons/notificationIcon/notificationIcon";
import settingsIcon from "../icons/settingsIcon/settingsIcon";
import homeIcon from "../icons/homeIcon/homeIcon";
import receiptCheckmarkLineIcon from "../icons/receiptCheckmarkLineIcon/receiptCheckmarkLineIcon";
import messageIcon from "../icons/messageIcon/messageIcon";
import notebookIcon from "../icons/notebookIcon/notebookIcon";
import documentationIcon from "../icons/documentationIcon";
import serverDatabasesCursorSquareIcon from "../icons/serverDatabasesCursorSquareIcon/serverDatabasesCursorSquareIcon";
import computerIcon from "../icons/computerIcon";
import downloadIcon from "../icons/downloadIcon/downloadIcon";
import questionMarkIcon from "../icons/questionMarkIcon/questionMarkIcon";
import filtersIcon from "../icons/filtersIcon/filtersIcon";
import signoutIcon from "../icons/signoutIcon/signoutIcon";
import moreIcon from "../icons/moreIcon/moreIcon";
import TaregetIcon from "../icons/targetIcon";
import arrowLeftIcon from "../icons/arrowLeftIcon";
import arrowRightIcon from "../icons/arrowRightIcon";
import attributesIcon from "../icons/attributesIcon";
import boardsIcon from "../icons/boardsIcon";
import alignedRightIcon from "../icons/alignedRIghtIcon";
import alignedLeftIcon from "../icons/alignedLeftIcon";
import alignedCenterIcon from "../icons/alignedCenterIcon";
import checklistIcon from "../icons/checklistIcon";
import closeIcon from "../icons/closeIcon";
import customersIcon from "../icons/customersIcon";
import deviceHealthIssueIcon from "../icons/deviceHealthIssueIcon";
import devicesCountIcon from "../icons/devicesCountIcon";
import editPenIcon from "../icons/editPenIcon";
import flagFiledIcon from "../icons/flagFilledIcon";
import flagIcon from "../icons/flagIcon";
import googleIcon from "../icons/googleIcon";
import homeFilledIcon from "../icons/homeFilledIcon";
import infoIcon from "../icons/infoIcon";
import jpgDocumentIcon from "../icons/jpgDocumentIcon.js";
import messageFilledIcon from "../icons/messageFilledIcon";
import microsoftIcon from "../icons/microsoftIcon";
import notesIcon from "../icons/notesIcon";
import orederedListIcon from "../icons/orderedListIcon";
import pdfDocumentIcon from "../icons/pdfDocumentIcon";
import qaIcon from "../icons/qaIcon";
import slaIcon from "../icons/slaIcon";
import templateIcon from "../icons/templateIcon";
import templatedIcon from "../icons/templatedIcon";
import unorderedListIcon from "../icons/unorderedListIcon";
import uploadFileIcon from "../icons/uploadFileIcon";
import widgetIcon from "../icons/widgetIcon";
import ReplyIcon from "../icons/replyIcon";
import ArrowIcon from "../icons/arrowIcon";
import GeneralIcon from "../icons/generalIcon";
import AutomationIcon from "../icons/automationIcon";
import MinusIcon from "../icons/minusIcon";
import MaximizeIcon from "../icons/maximizeIcon";
import CheckBoxIcon from "../icons/checkBoxIcon";
import databaseIcon from "../icons/databaseIcon";
import filterIcon from "../icons/filterIcon";
import boardcastIcon from "../icons/broadcastIcon";
import RoundedCheckIcon from "../icons/roundedCheckIcon";
import HorizontalLineIcon from "../icons/horizontalLineIcon";
import cameraIcon from "../icons/cameraIcon";
import syncIcon from "../icons/syncIcon";
import office365logo from "../icons/office365logo";
import fullscreenIcon from "../icons/fullscreenIcon";
import attachmentIcon from "../icons/attachmentIcon";
import boldIcon from "../icons/boldIcon";
import italicIcon from "../icons/italicIcon";
import underlineIcon from "../icons/underlineIcon";
import saveIcon from "../icons/saveIcon";
import pngDocumentIcon from "../icons/pngDocumentIcon";
import recurringTaskIcon from "../icons/recurringTaskIcon";
import BoardIcon from "../icons/boardIcon";
import ListIcon from "../icons/listIcon";

const icons = {
  settings: { icon: settingsIcon, props: {} },
  calender: { icon: calenderIcon, props: {} },
  clock: { icon: clockIcon, props: {} },
  user: { icon: userIcon, props: {} },
  printer: { icon: printerIcon, props: {} },
  delete: { icon: deleteIcon, props: {} },
  search: { icon: searchIcon, props: {} },
  columns: { icon: columnsIcon, props: {} },
  edit: { icon: editIcon, props: {} },
  plus: { icon: plusIcon, props: {} },
  burger_menu: { icon: burgerMenuIcon, props: {} },
  notification: { icon: notificationIcon, props: {} },
  home: { icon: homeIcon, props: {} },
  receipt_checkmark_line: { icon: receiptCheckmarkLineIcon, props: {} },
  message: { icon: messageIcon, props: {} },
  notebook: { icon: notebookIcon, props: {} },
  documentation: { icon: documentationIcon, props: {} },
  server_databases_cursor_square: {
    icon: serverDatabasesCursorSquareIcon,
    props: {},
  },
  computer: { icon: computerIcon, props: {} },
  download: { icon: downloadIcon, props: {} },
  question_mark: { icon: questionMarkIcon, props: {} },
  filters: { icon: filtersIcon, props: {} },
  signout: { icon: signoutIcon, props: {} },
  more: { icon: moreIcon, props: {} },
  target: { icon: TaregetIcon, props: {} },
  attributes: { icon: attributesIcon, props: {} },
  boards: { icon: boardsIcon, props: {} },
  align_right: { icon: alignedRightIcon, props: {} },
  align_left: { icon: alignedLeftIcon, props: {} },
  align_center: { icon: alignedCenterIcon, props: {} },
  checklist: { icon: checklistIcon, props: {} },
  close: { icon: closeIcon, props: {} },
  customers: { icon: customersIcon, props: {} },
  device_health_issue: { icon: deviceHealthIssueIcon, props: {} },
  devices_count: { icon: devicesCountIcon, props: {} },
  edit_pen: { icon: editPenIcon, props: {} },
  flag: { icon: flagIcon, props: { isFilled: false } },
  flag_filled: { icon: flagIcon, props: { isFilled: true } },
  google: { icon: googleIcon, props: {} },
  home_filled: { icon: homeFilledIcon, props: {} },
  info: { icon: infoIcon, props: {} },
  jpg_document: { icon: jpgDocumentIcon, props: {} },
  message_filled: { icon: messageFilledIcon, props: {} },
  microsoft: { icon: microsoftIcon, props: {} },
  notes: { icon: notesIcon, props: {} },
  ordered_list: { icon: orederedListIcon, props: {} },
  pdf_document: { icon: pdfDocumentIcon, props: {} },
  qa: { icon: qaIcon, props: {} },
  sla: { icon: slaIcon, props: {} },
  template: { icon: templateIcon, props: {} },
  templated: { icon: templatedIcon, props: {} },
  unordered_list: { icon: unorderedListIcon, props: {} },
  upload_file: { icon: uploadFileIcon, props: {} },
  widget: { icon: widgetIcon, props: {} },
  reply: { icon: ReplyIcon, props: {} },
  arrow_left: { icon: ArrowIcon, props: { direction: "left" } },
  arrow_right: { icon: ArrowIcon, props: { direction: "right" } },
  arrow_up: { icon: ArrowIcon, props: { direction: "top" } },
  arrow_down: { icon: ArrowIcon, props: { direction: "bottom" } },
  general: { icon: GeneralIcon, props: {} },
  automation: { icon: AutomationIcon, props: {} },
  minus: { icon: MinusIcon, props: {} },
  maximize: { icon: MaximizeIcon, props: {} },
  checkbox: { icon: CheckBoxIcon, props: {} },
  attachment: { icon: attachmentIcon, props: {} },
  bold: { icon: boldIcon, props: {} },
  italic: { icon: italicIcon, props: {} },
  underline: { icon: underlineIcon, props: {} },
  flagFilled: { icon: flagFiledIcon, props: {} },
  database: { icon: databaseIcon, props: {} },
  filter: { icon: filterIcon, props: {} },
  broadcast: { icon: boardcastIcon, props: {} },
  rounded_check: { icon: RoundedCheckIcon, props: {} },
  horizontal_line: { icon: HorizontalLineIcon, props: {} },
  camera_icon: { icon: cameraIcon, props: {} },
  sync_icon: { icon: syncIcon, props: {} },
  office_logo: { icon: office365logo, props: {} },
  fullscreen_icon: { icon: fullscreenIcon, props: {} },
  save: { icon: saveIcon, props: {} },
  png_document: { icon: pngDocumentIcon, props: {} },
  recurring_icon: { icon: recurringTaskIcon, props: {} },
  board_icon: { icon: BoardIcon, props: {} },
  list_icon: { icon: ListIcon, props: {} },
};

export default icons;
