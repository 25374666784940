import { SvgIcon, SvgIconProps } from "@mui/material";

export const PinFilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="m4.997 20.003 4.326-4.326"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
        d="m11.33 9.505 2.377-2.377c.256-.25.383-.605.346-.962l-.173-1.515a.588.588 0 0 1 1.001-.48l5.95 5.948a.588.588 0 0 1-.482 1.002l-1.515-.173c-.357-.038-.71.09-.962.346l-2.377 2.376a1.17 1.17 0 0 0-.345.832v4.162a1.178 1.178 0 0 1-2.01.832L5.51 11.86a1.178 1.178 0 0 1 .831-2.01h4.162c.31 0 .608-.124.827-.344Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
