import colorPalette from "../../../../../library/common/colors";
const defaultColor = {
  backgroundColor: colorPalette.color.secondary.secondary,
  status: {
    active: {
      color: "#5ED46A",
    },
    inactive: {
      color: colorPalette.sematics.error,
    },
    busy: {
      color: colorPalette.sematics.warningMajor,
    }
  }
};

export default defaultColor;