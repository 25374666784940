function deviceHealthIssueIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.625 0V79.1667C15.4375 77.1875 0 60.1667 0 39.5833C0 19 15.4375 1.97917 35.625 0ZM43.5417 0V35.625H79.1667C77.1875 16.625 62.5417 1.97917 43.5417 0ZM43.5417 43.5417V79.1667C62.1458 77.1875 77.1875 62.5417 79.1667 43.5417H43.5417Z"
        fill={props.disabled === true ? props.disabledcolor : props.color} />
    </svg>

  );
}
export default deviceHealthIssueIcon;