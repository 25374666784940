import { useGlobalStore } from "controller/store/globalStore";

export const getCredentialStorage = () => {
  return useGlobalStore.getState();
};

export const getAccessToken = () => {
  const globalStore = getCredentialStorage();
  return globalStore.accessToken || "";
};

export const getMspId = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.mspId || "";
};

export const getResourceName = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.name || "";
};

export const getEmail = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.email || "";
};

export const getProfilePictureUrl = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.profilePictureUrl || "";
};

export const getRole = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.role || "";
};

export const getPermission = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.permissions || "";
};

export const getResourceId = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.resourceId || "";
};

export const getTimeZone = () => {
  const globalStore = getCredentialStorage();
  return globalStore?.timezone || "";
};

export const cookieGenerator = (aud: string, refreshToken: string) => {
  const d = new Date();
  const newAud = `${aud}`.replace("-", "");

  d.setTime(d.getTime() + 29 * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();

  return (
    `fe_refresh_${newAud}` +
    "=" +
    refreshToken +
    ";expires=" +
    expires +
    `;path=/;domain=${
      import.meta.env.MODE !== "development" ? ".deskday.ai" : "localhost"
    }`
  );
};
