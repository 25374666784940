import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import { Button, PlusIcon, SearchInput } from "components";
import { EditResourceGroupModal } from "./EditResourceGroupModal";
import { Typography } from "@mui/material";
import { debugConsole } from "utilz/helpers";

interface ResourcesGroupHeadMountProps {
  searchData: string;
  onUpdateFilter: any;
}
export const ResourcesGroupHeadMount = ({
  searchData,
  onUpdateFilter,
}: ResourcesGroupHeadMountProps) => {
  const [searchQuery, setSearchQuery] = useState(searchData);

  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const updateSearch = (event: any) => {
    try {
      const newValue = event.target.value;
      setSearchQuery((oldState) => newValue);
      if (newValue.length === 0 || newValue.length > 2) {
        onUpdateFilter(newValue);
      }
    } catch (err: any) {
      debugConsole(
        "🚀 ~ file: ResourceGroupHeader.tsx:60 ~ updateSearch ~ err:",
        err?.message
      );
    }
  };

  // Renderer
  return (
    <React.Fragment>
      {modalOpen ? (
        <EditResourceGroupModal open={modalOpen} onClose={closeModal} />
      ) : null}

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4">Resource Groups</Typography>
        <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
          <SearchInput
            name={""}
            placeholder="Search"
            value={searchQuery}
            onChange={updateSearch}
            sx={{
              pt: "3px",
            }}
            hideHelperBox
          />
          <Button endIcon={<PlusIcon />} onClick={() => setModalOpen(true)}>
            Group
          </Button>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
