import { GetCitiesByStatesInput } from "shared/types/general";
import { axiosClient } from "utilz";

const getCountries = async () => {
  try {
    const url = "/countries/all";
    const response = await axiosClient.get(url);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData?.success &&
      responseData?.statusCode === 200 &&
      responseData?.data?.result &&
      Array.isArray(responseData?.data?.result)
    ) {
      return responseData?.data?.result;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    throw error;
  }
};

const getStatesByCountryId = async (countryId?: string) => {
  try {
    const url = `/states/country_code/${countryId}`;
    const response = await axiosClient.get(url);

    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData?.success &&
      responseData?.statusCode === 200 &&
      responseData?.data?.result &&
      Array.isArray(responseData?.data?.result)
    ) {
      return responseData?.data?.result;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    throw error;
  }
};

const getCitiesByStates = async (data: GetCitiesByStatesInput) => {
  const { countryId, stateId } = data;
  try {
    const url = `/cities/country_code/${countryId}/state_code/${stateId}`;
    const response = await axiosClient.get(url);

    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData?.success &&
      responseData?.statusCode === 200 &&
      responseData?.data?.result &&
      Array.isArray(responseData?.data?.result)
    ) {
      return responseData?.data?.result;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    throw error;
  }
};

const getAllTimeZone = async () => {
  try {
    const url = "/timezones/all";
    const response = await axiosClient.get(url);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData?.success &&
      responseData?.statusCode === 200 &&
      responseData?.data?.result &&
      Array.isArray(responseData?.data?.result)
    ) {
      return responseData?.data?.result;
    } else {
      throw new Error(responseData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export {
  getCountries,
  getStatesByCountryId,
  getCitiesByStates,
  getAllTimeZone,
};
