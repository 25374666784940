import { Box, Grid, Stack, SxProps, Theme } from "@mui/material";
// @ts-ignore
import errorBg from "assets/images/mfaBg.webp";
import { CSSProperties } from "react";
// @ts-ignore
import logoBig from "assets/logo/deskdayLogo.svg";

interface ErrorLayoutProps {
  leftSide: JSX.Element;
  errorImg: string;
  imageSx?: CSSProperties;
  showLogo?: boolean;
}
export const ErrorLayout = ({
  errorImg,
  leftSide,
  imageSx,
  showLogo = false,
}: ErrorLayoutProps) => {
  return (
    <>
      <Grid container width={"100%"} height={"100%"}>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "40px",
            // flexDirection: "column",
            // bgcolor: "red",
          }}
        >
          {/* {showLogo && (
            <Box
              sx={{
                alignSelf: "flex-start",
                justifySelf: "flex-start",
                placeSelf: "flex-start",
                justifyItems: "flex-start",
                bgcolor: "pink",
              }}
            >
              <img src={logoBig} height="34px" width="auto" alt={"DeskDay"} />
            </Box>
          )} */}
          {leftSide}
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            backgroundImage: `url(${errorBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={errorImg}
            style={{
              width: "80%",
              height: "80%",
              maxWidth: "330px",
              maxHeight: "330px",
              aspectRatio: "1",
              ...imageSx,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
