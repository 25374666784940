import { Autocomplete, Box, Chip, Input, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TEXTFIELD_CONSTANTS } from "./constants";
import { CustomTypography } from "../../../base";
import CustomIcon from "../../../icon-library";

const customStyles = {
  "& .MuiInputBase-input": {
    font: "inherit",
  },
  "& .MuiInputBase-root": {
    height: TEXTFIELD_CONSTANTS["height"],
    // height: 'fit-content',
  },
  "& .MuiOutlinedInput-root": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["DEFAULT"],
      borderRadius: TEXTFIELD_CONSTANTS?.border_radius,
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
    },
  },
  "& .MuiInputLabel-root": {
    transform: TEXTFIELD_CONSTANTS.transition,
    color: TEXTFIELD_CONSTANTS?.palette["unfocus_color"],
    "&.MuiFormLabel-filled": {
      transform: "translate(14px, -9px) scale(1)",
    },
    "&.Mui-focused": {
      color: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
      fontWeight: TEXTFIELD_CONSTANTS?.palette["bold_weight"],
      transform: "translate(14px, -9px) scale(1)",
    },
  },
};

const CustomInput = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;
  return (
    <Box
      sx={{
        width: "100%",
        mx: "8px",
      }}
      className={
        "typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular"
      }
    >
      <input
        ref={ref}
        {...otherProps}
        className={`${className} typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular`}
      ></input>
    </Box>
  );
});

function CustomAutoCompleteTextField(props) {
  const [popularOption, setOptions] = useState([]);

  useEffect(() => {
    if (
      (props.options === undefined || props.options === null) &&
      typeof props.populator === "function"
    ) {
      populateData();
    }
  }, []);

  async function populateData() {
    let result = [];
    if (props.dependency === true) {
      result = await props.populator(props.triggerValue);
    } else {
      result = await props.populator();
    }
    setOptions(result);
  }

  const onChange = (event, newValue) => {
    props.onChange({ field: props.name, value: newValue });
  };

  return (
    <Autocomplete
      {...(props.multiple === true ? { multiple: true } : {})}
      // onOpen={() => {
      // }}
      // onClose={() => {
      // }}
      disableClearable
      value={props.value}
      isOptionEqualToValue={props.isOptionEqualToValue}
      getOptionLabel={(option) => {
        return option.label || "";
      }}
      disabled={props?.disabled || false}
      options={
        props.options === undefined || props.options === null
          ? popularOption
          : props.options
      }
      onChange={onChange}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <Chip
              label={
                <CustomTypography
                  content={`${option.label}`}
                  size={"EXTRA-SMALL"}
                  variant={"REGULAR"}
                />
              }
              {...getTagProps({ index })}
              variant="outlined"
              sx={{
                borderRadius: "8px",
                border: "1px solid black",
              }}
              deleteIcon={
                <CustomIcon icon="close" size={"lg"} color={"black"} />
              }
            />
          );
        });
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <CustomTypography
            size="EXTRA-SMALL"
            variant="REGULAR"
            content={option.label}
          />
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name || ""}
          label={
            props.label ? (
              <CustomTypography
                variant="REGULAR"
                size="EXTRA-SMALL"
                content={props.label || ""}
              />
            ) : (
              ""
            )
          }
          sx={customStyles}
          InputProps={{
            ...params.InputProps,
            className:
              "typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular",
            inputComponent: CustomInput,
            // endAdornment: (
            //   <Fragment>
            //     <Box></Box>
            //   </Fragment>
            // ),
          }}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
}

CustomAutoCompleteTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  populator: PropTypes.func,
  options: PropTypes.array, //options=[{key:'',value:'', label:''}]
  dependency: PropTypes.bool.isRequired,
  triggerValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func,
  multiple: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CustomAutoCompleteTextField.defaultProps = {
  label: "",
  name: "",
  value: "",
  dependency: false,
  triggerValue: [],
  disabled: false,
  populator: () => {},
  options: null,
  onChange: () => {},
  multiple: true,
  isOptionEqualToValue: (option, value) => option.value === value.value,
};

export default CustomAutoCompleteTextField;
