import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAgentDownloadLink,
  getAllTimeZone,
  getCitiesByStates,
  getCountries,
  getStatesByCountryId,
  setDownloadAcknowledgement,
} from "controller/api/general";
import { debugConsole } from "utilz/helpers";

export const useGetDownloadLink = () => {
  const queryClient = useQueryClient();

  return useMutation((type: string) => getAgentDownloadLink(type), {
    onSuccess(data, variables, context) {},
  });
};

export const useSetUserAcknowledgement = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (resourceId: string) => setDownloadAcknowledgement(resourceId),
    {
      onSuccess(data, variables, context) {},
    }
  );
};

export const useGetAllTimezone = () => {
  return useQuery({
    queryKey: ["timezones"],
    queryFn: () => getAllTimeZone(),
    onError: (err) => debugConsole(err),
    staleTime: Infinity,
  });
};
export const useGetAllCountries = () => {
  return useQuery({
    queryKey: ["countries"],
    queryFn: () => getCountries(),
    onError: (e) => debugConsole(e),
  });
};

export const useGetAllStateByCountryId = (countryId?: string) => {
  return useQuery({
    queryKey: ["state", countryId],
    queryFn: () => getStatesByCountryId(countryId || ""),
    onError: (e) => debugConsole(e),
    enabled: countryId && countryId !== null ? true : false,
  });
};

export const useGetAllCityByStateId = (
  countryId?: string,
  stateId?: string
) => {
  return useQuery({
    queryKey: ["countries", countryId, stateId],
    queryFn: () =>
      getCitiesByStates({ countryId: countryId || "", stateId: stateId || "" }),
    onError: (e) => debugConsole(e),
    enabled: !!countryId && !!stateId,
  });
};
