export const stateBuilder = (data) => {
  return {
    is_time_format_is_12_hr: data?.is_time_format_is_12_hr === true,
    is_time_zone_is_resources: data?.is_time_zone_is_resources === true,
    default_font_for_tickets: data?.default_font_for_tickets || "",
    default_font_size_for_tickets: data?.default_font_size_for_tickets || "",
    default_service_board_for_tickets: data?.default_service_board_for_tickets || "",
    ticket_board_id: data?.ticket_board_id || "",
    default_service_board_for_request: data?.default_service_board_for_request || "",
    request_board_id: data?.request_board_id || "",
    default_service_board_for_projects: data?.default_service_board_for_projects || "",
    project_board_id: data?.project_board_id || "",
  };
};