import { Box, Stack } from "@mui/material";
import { CustomButton } from "../../../../../../../../../library/base";
import { SearchTextField } from "../../../../Components";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, PlusIcon, SearchInput } from "components";
export default function TabHeadComponent(props) {
  const [searchQuery, setSearchQuery] = props.data;

  const handleUpdateSearch = (event) => {
    try {
      const newValue = event.target.value;
      setSearchQuery(newValue);
      if (newValue.length > 0 && newValue.length < 3) {
        return;
      }
      props.updateSearchHandler(newValue);
    } catch (err) {
      console.log("[ERROR] Updating search");
      console.log(err);
    }
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"right"}
      alignItems={"center"}
      gap={"16px"}
    >
      <SearchInput
        name={""}
        placeholder="Search"
        value={searchQuery}
        onChange={handleUpdateSearch}
        sx={{
          pt: "3px",
        }}
        hideHelperBox
      />

      <Button endIcon={<PlusIcon />} onClick={props.createNewHandler}>
        {props.buttonLabel}
      </Button>
    </Stack>
  );
}

TabHeadComponent.propTypes = {
  createNewHandler: PropTypes.func.isRequired,
  updateSearchHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  data: PropTypes.array.isRequired,
};

TabHeadComponent.defaultProps = {
  createNewHandler: () => {},
  updateSearchHandler: () => {},
  buttonLabel: "Template",
  data: ["", () => {}],
};
