import { immer } from "zustand/middleware/immer";
import { v4 } from "uuid";

interface GeneralConfigProps {
  defaultTicketBoard?: string | null;
  defaultTicketBoardId?: string | null;
  defaultProjectBoard?: string | null;
  defaultProjectBoardId?: string | null;
}
interface currencyType {
  defaultCurrency: string;
  currencyName: string;
  currencyCode: string;
  symbol: string;
  position: string;
}
interface GlobalState {
  timezone: string;
  accessToken: string | null;
  mspOwnerId: string | null;
  // refreshToken: string | null;
  isResourceTimezone: boolean;
  isTicketTImeFormat24Hour: boolean;
  isTicketTimeZoneIsResource: boolean;
  mspId: string | null;
  resourceId: string | null;
  name: string | null;
  email: string | null;
  profilePictureUrl: string | null;
  roles: Array<string> | null;
  permissions: Array<string> | null;
  fabPosition?: { x: number; y: number };
  role: "SUPERADMIN" | "ADMIN" | "RESOURCE" | null;
  isRefetching: boolean;
  dateFormat: string;
  // resourceData: Record<string, any>
  defaultCustomer?: string | null;
  generalConfig?: GeneralConfigProps | null;
  trialEndDate?: string | null;
  sampleDataStatus?: string | null;
  subscriptionStatus?: string | null;
  cancelAtPeriodEnd?: boolean | null;
  currentAccessBlockDate?: string | null;
  billingCycle: "weekly" | "monthly";
  currency: currencyType;
  mqttClientId: string;
}

interface GlobalAction {
  setTimeZone: (value: string) => void;
  setAccessToken: (value: string | null) => void;
  // setRefreshToken: (value: string | null) => void;
  setIsResourceTimeZone: (value?: boolean) => void;
  setIsTicketTImeFormat24Hour: (value?: boolean) => void;
  setIsTicketTimeZoneIsResource: (value?: boolean) => void;
  setMspId: (value: string | null) => void;
  setResourceId: (value: string | null) => void;
  setName: (value: string | null) => void;
  setEmail: (value: string | null) => void;
  setprofilePictureUrl: (value: string | null) => void;
  setRole: (value: Array<string> | null) => void;
  setPermission: (value: Array<string> | null) => void;
  setFabPosition: (value: { x: number; y: number }) => void;
  resetCredentials: () => void;
  setUserRole: (value: "SUPERADMIN" | "ADMIN" | "RESOURCE") => void;
  setIsRefreshing: (value: boolean) => void;
  setDateFormat: (value: string) => void;
  setDefaultData: ({
    type,
    value,
  }: {
    type: "customer";
    value: string;
  }) => void;
  setGeneralConfig: (value: GeneralConfigProps) => void;
  setTrialEndDate: (value: string) => void;
  setSampleDataStatus: (value: string) => void;
  setBillingCycle: (value: "weekly" | "monthly") => void;
  setSubscriptionData: ({
    subscriptionStatus,
    cancelAtPeriodEnd,
    currentAccessBlockDate,
  }: {
    subscriptionStatus?: string | null;
    cancelAtPeriodEnd?: boolean | null;
    currentAccessBlockDate?: string | null;
  }) => void;
  setCurrency: (value: currencyType) => void;
  setMspOwnerId: (value: string | null) => void;
}

export interface GlobalInterface extends GlobalState, GlobalAction {}

export const GlobalSlice = immer<GlobalState & GlobalAction>((set, get) => ({
  timezone: "America/Toronto",
  mspId: null,
  resourceId: null,
  accessToken: null,
  isResourceTimezone: false,
  // refreshToken: null,
  mspOwnerId: null,
  isTicketTImeFormat24Hour: true,
  isTicketTimeZoneIsResource: true,
  name: null,
  email: null,
  profilePictureUrl: null,
  roles: null,
  permissions: null,
  role: "SUPERADMIN",
  isRefetching: true,
  dateFormat: "MM/DD/YYYY",
  billingCycle: "weekly",
  mqttClientId: `sd_web_${v4()}`,
  currency: {
    defaultCurrency: "c1304796-099c-44f6-b761-b772123e3f84",
    currencyName: "United State Dollar",
    currencyCode: "USD",
    symbol: "$",
    position: "prefix",
  },
  setTimeZone(newTimeZone) {
    set((state) => {
      state.timezone = newTimeZone;
    });
  },
  setAccessToken(newAccessToken) {
    set((state) => {
      state.accessToken = newAccessToken;
    });
  },
  setMspId(newMspId) {
    set((state) => {
      state.mspId = newMspId;
      // FIXME: uncomment below code
      // state.mspId = newMspId;
    });
  },
  setResourceId(value) {
    set((state) => {
      state.resourceId = value;
    });
  },
  setName(value) {
    set((state) => {
      state.name = value;
    });
  },
  setEmail(value) {
    set((state) => {
      state.email = value;
    });
  },
  setprofilePictureUrl(value) {
    set((state) => {
      state.profilePictureUrl = value;
    });
  },
  setRole(value) {
    set((state) => {
      state.roles = value;
    });
  },
  setPermission(value) {
    set((state) => {
      state.permissions = value;
    });
  },
  setFabPosition(value) {
    set((state) => {
      state.fabPosition = value;
    });
  },
  setUserRole(value) {
    set((state) => {
      state.role = value;
    });
  },
  setIsRefreshing(value) {
    set((state) => {
      state.isRefetching = value;
    });
  },
  setDateFormat(value) {
    set((state) => {
      state.dateFormat = value;
    });
  },
  setIsTicketTImeFormat24Hour(value) {
    set((state) => {
      state.isTicketTImeFormat24Hour = value === false ? false : true;
    });
  },
  setIsTicketTimeZoneIsResource(value) {
    set((state) => {
      state.isTicketTimeZoneIsResource = value === false ? false : true;
    });
  },
  setIsResourceTimeZone(value) {
    set((state) => {
      state.isResourceTimezone = value === true;
    });
  },
  setDefaultData({ type, value }) {
    set((state) => {
      switch (type) {
        case "customer":
          state.defaultCustomer = value;
          break;

        default:
          break;
      }
    });
  },
  setTrialEndDate(value) {
    set((state) => {
      state.trialEndDate = value;
    });
  },
  setGeneralConfig(value) {
    set((state) => {
      state.generalConfig = value;
    });
  },
  setSampleDataStatus(value) {
    set((state) => {
      state.sampleDataStatus = value;
    });
  },
  setBillingCycle(value) {
    set((state) => {
      state.billingCycle = value;
    });
  },
  setSubscriptionData({
    subscriptionStatus,
    cancelAtPeriodEnd,
    currentAccessBlockDate,
  }) {
    set((state) => {
      state.subscriptionStatus = subscriptionStatus;
      state.cancelAtPeriodEnd = cancelAtPeriodEnd;
      state.currentAccessBlockDate = currentAccessBlockDate;
    });
  },
  setCurrency(value) {
    set((state) => {
      state.currency = value;
    });
  },
  setMspOwnerId(value) {
    set((state) => {
      state.mspOwnerId = value;
    });
  },
  resetCredentials() {
    set((state) => {
      // state.refreshToken = null;
      state.mspOwnerId = null;
      state.accessToken = null;
      state.mspId = null;
      state.resourceId = null;
      state.name = null;
      state.email = null;
      state.profilePictureUrl = null;
      state.roles = null;
      state.permissions = null;
      state.role = null;
      state.timezone = "America/Toronto";
      state.isResourceTimezone = false;
      state.isTicketTImeFormat24Hour = true;
      state.isTicketTimeZoneIsResource = true;
      state.isRefetching = true;
      state.dateFormat = "MM/DD/YYYY";
      state.defaultCustomer = null;
      state.generalConfig = null;
      state.trialEndDate = null;
      state.sampleDataStatus = null;
      state.subscriptionStatus = null;
      state.billingCycle = "weekly";
    });
  },
}));
