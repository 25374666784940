import React, { Component } from "react";

// Services Imports
import { services } from "../../../../../../services";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

// HOC Imports
import { CustomTypography, CustomButton } from "../../../../../../library/base";
import {
  CustomCard,
  BottomStickyLayer,
} from "../../../../../../library/layers";
import {
  CustomDialer,
  CustomToggleButton,
} from "../../../../../../library/system/custom-form";
import { CustomToaster } from "../../../../../../library/system";

// child Components
import { MultiFactorAuthenticationInfoComponent } from "./components";

// Utilities Imports[State Builders]
import { authenticationStateBuilder } from "./state-builder";

// Constants Imports
import { AUTHENTICATION_CONSTANTS } from "./constants";
import { getMspId } from "utilz/helpers";

class AuthenticationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: authenticationStateBuilder.defaultStateBuilder(),
      toast: { open: false, severity: "SUCCESS", message: "" },
    };
  }

  // Lifecycle Methods
  componentDidMount() {
    this.fetchAuthenticationSettingsByMspId();
  }
  componentDidUpdate() {
    // console.log('[MOUNT] Security/Authentication');
    // console.log(this.state);
  }

  // API Calls
  fetchAuthenticationSettingsByMspId = () => {
    try {
      services.controlCenterservices.securityServices.securityAuthenticationServices
        .fetchAuthenticationByMspId(getMspId())
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true &&
              response?.data
            ) {
              const data = authenticationStateBuilder.mountStateBuilder(
                response?.data
              );
              this.setState({
                ...this.state,
                data: data,
              });
            } else {
              this.setState({
                ...this.state,
                toast: {
                  open: true,
                  severity: "WARNING",
                  message:
                    response?.message ||
                    "Unable to fetch authentication settings at the moment. Please try again",
                },
              });
            }
          },
          error: (error) => {
            console.log("[ERROR] Fetching authentication settings by MspId");
            console.log(error);
            this.setState({
              ...this.state,
              toast: {
                open: true,
                severity: "ERROR",
                message:
                  "Unable to fetch authentication settings at the moment. Please try again",
              },
            });
          },
        });
    } catch (err) {
      console.log("[ERROR] Fetching authentication settings by MspId");
      console.log(err);
      this.setState({
        ...this.state,
        toast: {
          open: true,
          severity: "ERROR",
          message: "Unexpected error occured. Please try again",
        },
      });
    }
  };
  updateAuthenticationSettings = () => {
    try {
      services.controlCenterservices.securityServices.securityAuthenticationServices
        .updateAuthenticationByMspId(getMspId(), this.state?.data)
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true
            ) {
              this.setState({
                ...this.state,
                toast: {
                  open: true,
                  severity: "SUCCESS",
                  message:
                    response?.message ||
                    "Updated authentication settings for MSP",
                },
              });
            } else {
              this.setState({
                ...this.state,
                toast: {
                  open: true,
                  severity: "WARNING",
                  message:
                    response?.message ||
                    "Unable to update authentication settings for MSP. Please try again",
                },
              });
            }
          },
          error: (error) => {
            console.log("[ERROR] Updating authentication settings by MspId");
            console.log(error);
            this.setState({
              ...this.state,
              toast: {
                open: true,
                severity: "ERROR",
                message:
                  "Unable to update authentication settings for MSP. Please try again",
              },
            });
          },
        });
    } catch (err) {
      console.log("[ERROR] Updating authentication settings by MspId");
      console.log(err);
      this.setState({
        ...this.state,
        toast: {
          open: true,
          severity: "ERROR",
          message: "Unexpected error occured. Please try again",
        },
      });
    }
  };

  // Event Handlers
  handleMfaUpdate = (data) => {
    try {
      this.setState({
        ...this.state,
        data: {
          ...this.state?.data,
          mfa: data?.value,
        },
      });
    } catch (err) {
      console.log("[ERROR] Updating mfa settings");
      console.log(err);
    }
  };
  handleToggleAction = (data) => {
    try {
      const { field, value } = data;
      const currentState = this.state?.data;
      currentState[`${field}`] = value;
      this.setState({ ...this.state, data: currentState });
    } catch (err) {
      console.log("[ERROR] Updating Toggle action");
      console.log(err);
    }
  };
  handleDialerAction = (data) => {
    try {
      const { field, value } = data;
      const currentState = this.state?.data;
      currentState[`${field}`] = value;
      this.setState({ ...this.state, data: currentState });
    } catch (err) {
      console.log("[ERROR] Updating dialer action");
      console.log(err);
    }
  };

  // Renderer
  render() {
    return (
      <React.Fragment>
        <CustomToaster
          open={this.state?.toast["open"]}
          severity={this.state?.toast["severity"]}
          message={this.state?.toast["message"]}
          callback={() =>
            this.setState({
              ...this.state,
              toast: { open: false, severity: "SUCCESS", message: "" },
            })
          }
        />
        <Box
          sx={{
            margin: "24px 32px 96px 32px",
            filter: "grayscale(100%)",
            opacity: ".5",
          }}
        >
          <Box
            sx={{
              width: "100",
              px: "32px",
              mb: {
                xs: "16px",
                sm: "20px",
                md: "24px",
                lg: "28px",
                xl: "32px",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} spacing={"12px"}>
              <CustomTypography
                size={"SMALL-BETA"}
                color={"PRIMARY-MAIN"}
                variant={"REGULAR"}
                content={"Multi-factor authentication"}
              />
              <MultiFactorAuthenticationInfoComponent />
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CustomToggleButton
                name={"mfa"}
                value={this.state?.data["mfa"]}
                handle={() => {}}
                // handle={this.handleMfaUpdate}
              />
            </Box>
          </Box>

          <CustomCard
            borderRadius={"24px"}
            outlined={true}
            spacing={"0px"}
            content={
              <Box
                sx={{
                  width: "100%",
                  height: "60vh",
                  overflow: "scroll",
                  "&:: -webkit-scrollbar": { display: "none" },
                }}
              >
                <Box sx={{ width: "100%", px: "28px", py: "16px" }}>
                  <CustomTypography
                    size={"MEDIUM-ALPHA"}
                    color={"PRIMARY-MAIN"}
                    variant={"BOLD"}
                    content={"Password Policies"}
                  />
                </Box>
                <Stack spacing={0}>
                  {Object.values(AUTHENTICATION_CONSTANTS).map(
                    (field, index) => {
                      return (
                        <Box
                          key={field?.key}
                          sx={{
                            width: "100%",
                            borderBottom:
                              index !==
                              Object.keys(AUTHENTICATION_CONSTANTS).length - 1
                                ? "1px solid #CCC"
                                : "",
                          }}
                        >
                          <Box sx={{ width: "100%", p: "28px" }}>
                            <Stack spacing={"24px"}>
                              <Grid
                                container
                                columnSpacing={{
                                  sm: "12px",
                                  md: "16px",
                                  lg: "24px",
                                  xl: "28px",
                                }}
                                rowSpacing={{
                                  sm: "12px",
                                  md: "16px",
                                  lg: "24px",
                                  xl: "28px",
                                }}
                              >
                                <Grid item xs={12} md={9}>
                                  <CustomTypography
                                    size={"SMALL-ALPHA"}
                                    color={"PRIMARY-MAIN"}
                                    variant={"REGULAR"}
                                    content={field?.label}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <CustomToggleButton
                                      name={field?.switch_mapping_key}
                                      value={
                                        field?.default_value
                                        // this.state?.data[
                                        //   `${field?.switch_mapping_key}`
                                        // ]
                                      }
                                      handle={this.handleToggleAction}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                              {field?.dialer_present === true ? (
                                // this.state?.data[
                                //   `${field?.switch_mapping_key}`
                                // ] === true ?
                                field?.default_value ? (
                                  <Grid container>
                                    <Grid item xs={12} md={9}>
                                      <Box
                                        sx={{
                                          width: "100%",
                                          height: "100%",
                                          p: 0,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <CustomTypography
                                          size={"SMALL-ALPHA"}
                                          color={"TERITARY-MAIN"}
                                          variant={"REGULAR"}
                                          content={field?.dialer_label}
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Box
                                        sx={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <CustomDialer
                                          name={field["dialer_mapping_key"]}
                                          value={
                                            field?.default_dialer_value
                                            // this.state?.data[
                                            //   `${field?.dialer_mapping_key}`
                                            // ]
                                          }
                                          updateHandler={
                                            () => {}
                                            //   this.handleDialerAction
                                          }
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </Stack>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Stack>
              </Box>
            }
          />
        </Box>
        {false && (
          <BottomStickyLayer
            content={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <CustomButton
                  varient="primary"
                  size="sm"
                  label="Save"
                  onClick={this.updateAuthenticationSettings}
                />
              </Box>
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default AuthenticationTab;
