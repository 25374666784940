import { Box, Stack, Typography } from "@mui/material";
import { Button } from "components";
import { ErrorLayout } from "components/error-screen/ErrorLayout";

// @ts-ignore
import hourglassImage from "assets/images/hourglassImage.svg";
import React, { useContext, useEffect } from "react";
import { SampleDataList } from "./components/SampleDataList";
import SampleDataTicketImage from "assets/images/sampleDataTicketImage.webp";
import { SampleDataConfirmation } from "./components/SampleDataConfirmation";
import { useGlobalStore } from "controller";
import { MqttContext } from "controller/context/MqttContext";

export const SampleDataHomeScreen = () => {
  const sampleDataStatus = useGlobalStore((state) => state.sampleDataStatus);
  const [pageState, setPageState] = React.useState<"list" | "confirmation">(
    "confirmation"
  );
  const mspId = useGlobalStore((state) => state.mspId);

  const {
    connectMqtt,
    mqttMessage,
    subscribeRoom,
    isConnected,
    unSubscribeRoom,
  } = useContext(MqttContext);

  useEffect(() => {
    if (!isConnected) {
      connectMqtt();
    } else {
      if (mspId && isConnected) {
        subscribeRoom([`sample-data/progress/${mspId}`]);
      }
    }
    return () => {
      if (isConnected && mspId)
        unSubscribeRoom([`sample-data/progress/${mspId}`]);
    };
  }, [isConnected]);

  useEffect(() => {
    if (sampleDataStatus !== "pending") {
      setPageState("list");
    }
  }, [sampleDataStatus]);
  return (
    <Box sx={{ width: "100vw", height: "100vh" }}>
      <ErrorLayout
        showLogo
        imageSx={{
          width: "80%",
          height: "80%",
          maxWidth: "80%",
          maxHeight: "40%",
          //   aspectRatio: "1",
        }}
        // leftSide={<SampleDataList />}
        leftSide={
          pageState === "list" ? (
            <SampleDataList />
          ) : (
            <SampleDataConfirmation
              onContinueClick={() => setPageState("list")}
            />
          )
        }
        errorImg={pageState === "list" ? SampleDataTicketImage : hourglassImage}
      ></ErrorLayout>
    </Box>
  );
};
