import { SvgIcon, SvgIconProps } from "@mui/material";

export const DownloadCurvedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <g clip-path="url(#a)">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M14.964 11.259 12 14.223 9.035 11.26M12 5.33v8.893M18.67 15.705a2.965 2.965 0 0 1-2.965 2.964h-7.41a2.965 2.965 0 0 1-2.964-2.964"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="24" height="24" fill="#fff" rx="6" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
