import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
// ISO date string to format "15 March 2022, 09:30 AM"
interface isoFormatProps {
  format?: string;
  timezone?: string;
}

export const isoToDateAndTime = (isodate: string, props?: isoFormatProps) => {
  try {
    if (isodate) {
      let formattedDate = dayjs(isodate);
      if (props?.timezone) {
        formattedDate = formattedDate.tz(props?.timezone);
      }
      return formattedDate.format(props?.format || "DD MMMM YYYY, hh:mm A");
    }
    return "";
  } catch (e) {
    return "";
  }
};

export const isoToTime = (isodate: string, timezone: string) => {
  const formatttedTime = dayjs(isodate).tz(timezone).format("hh:mm A");

  return formatttedTime;
};

export const changeTimeZone = (utcTimestamp: string, timezone: string) => {
  const date = new Date(utcTimestamp);
  try {
    try {
      const localDateString = date.toLocaleString("en-US", {
        timeZone: timezone,
      });
      return localDateString;
    } catch (e) {
      const localDateString = date.toLocaleString("en-US");
      return localDateString;
    }
  } catch (e) {
    return "";
  }
};

export const convertUTCtoTimezone = (serverTime: string, timezone?: string) => {
  try {
    const date = dayjs(serverTime);
    try {
      if (
        timezone !== null &&
        timezone !== undefined &&
        timezone.trim().length > 0
      ) {
        return date.tz(timezone).toISOString();
      }
      return date.toISOString();
    } catch (e) {
      return date.toISOString();
    }
  } catch (e) {
    return null;
  }
};

export const convertUTCtoTimezoneV2 = (
  serverTime: string | Dayjs | null,
  timezone?: string
) => {
  try {
    const date = dayjs(serverTime);
    try {
      if (
        timezone !== null &&
        timezone !== undefined &&
        timezone.trim().length > 0
      ) {
        return date.tz(timezone);
      }

      return date;
    } catch (e) {
      return date;
    }
  } catch (e) {
    return null;
  }
};

export const dateTimeToUtc = (
  timeDate: string | dayjs.Dayjs | null,
  timezone?: string
) => {
  try {
    try {
      if (
        timezone !== null &&
        timezone !== undefined &&
        timezone.trim().length > 0
      ) {
        return dayjs(timeDate).tz(timezone).utc().toISOString();
      }

      return dayjs(timeDate).utc().toISOString();
    } catch (e) {
      return dayjs(timeDate).utc().toISOString();
    }
  } catch (e) {
    return "";
  }
};

export const dateTimeToUtcV2 = (
  timeDate: string | dayjs.Dayjs | null,
  timezone?: string
) => {
  try {
    try {
      if (
        timezone !== null &&
        timezone !== undefined &&
        timezone.trim().length > 0
      ) {
        return dayjs(timeDate).tz(timezone).utc().toISOString();
      }
      return dayjs(timeDate).utc().toISOString();
    } catch (e) {
      return dayjs(timeDate).utc().toISOString();
    }
  } catch (e) {
    return "";
  }
};

export const changeTimezone = (
  timeDate: string | dayjs.Dayjs | null,
  timezone?: string
) => {
  try {
    if (
      timezone !== null &&
      timezone !== undefined &&
      timezone.trim().length > 0
    ) {
      return dayjs(timeDate).tz(timezone);
    }
    throw "null data";
  } catch (e) {
    return null;
  }
};

export const getDateWithMonthText = ({
  date,
  fullMonth,
  time,
  format,
}: {
  date: string;
  fullMonth?: boolean;
  time?: boolean;
  format: string;
}) => {
  try {
    if (!date || !format) return "";

    const dateObj = dayjs(date);
    const month = dateObj.format("MMMM");
    const day = dateObj.format("DD");
    const year = dateObj.format("YYYY");
    const timeString = time ? `, ${dateObj.format("hh:mm A")}` : "";
    const monthString = fullMonth ? month : month.slice(0, 3);
    let dateString = `${day} ${monthString} ${year}${timeString}`;
    switch (format) {
      case "DD/MM/YYYY":
        dateString = `${day} ${monthString} ${year}${timeString}`;
        break;
      case "MM/DD/YYYY":
        dateString = `${monthString} ${day} ${year}${timeString}`;
        break;
      case "YYYY/MM/DD":
        dateString = `${year} ${monthString} ${day}${timeString}`;
        break;

      default:
        break;
    }

    return dateString;
  } catch (e) {
    return "";
  }
};

export const getFormattedDate = ({
  date,
  format,
  time,
}: {
  date: string;
  format: string;
  time?: boolean;
}) => {
  try {
    if (!date || !format) return "";

    const dateObj = dayjs(date);
    const month = dateObj.format("MM");
    const day = dateObj.format("DD");
    const year = dateObj.format("YYYY");
    let dateString = `${day}/${month}/${year}`;
    const timeString = time ? `, ${dateObj.format("hh:mm A")}` : "";
    switch (format) {
      case "DD/MM/YYYY":
        dateString = `${day}/${month}/${year}${timeString}`;
        break;
      case "MM/DD/YYYY":
        dateString = `${month}/${day}/${year}${timeString}`;
        break;
      case "YYYY/MM/DD":
        dateString = `${year}/${month}/${day}${timeString}`;
        break;

      default:
        break;
    }

    return dateString;
  } catch (e) {
    return "";
  }
};
