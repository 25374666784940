function googleIcon(props) {
  return (
    <svg
      width="90%"
      height="90%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.611 16.083H38V16H20V24H31.303C29.654 28.657 25.223 32 20 32C13.373 32 8 26.627 8 20C8 13.373 13.373 8 20 8C23.059 8 25.842 9.154 27.961 11.039L33.618 5.382C30.046 2.053 25.268 0 20 0C8.955 0 0 8.955 0 20C0 31.045 8.955 40 20 40C31.045 40 40 31.045 40 20C40 18.659 39.862 17.35 39.611 16.083Z"
        fill={props.disabled === true ? props.disabledcolor : "#FFC107"} />
      <path
        d="M1.81802 11.2794L8.30447 16.3636C10.0596 11.7194 14.3102 8.4403 19.2843 8.4403C22.304 8.4403 25.0512 9.65781 27.1429 11.6466L32.7271 5.67821C29.2011 2.16599 24.4846 0 19.2843 0C11.7012 0 5.12492 4.5757 1.81802 11.2794Z"
        fill={props.disabled === true ? props.disabledcolor : "#FF3D00"} />
      <path
        d="M19.4491 39.9999C24.5646 39.9999 29.2128 37.9711 32.7271 34.6719L26.5976 29.2967C24.6092 30.8575 22.1376 31.7903 19.4491 31.7903C14.2979 31.7903 9.92406 28.3865 8.27632 23.6362L1.81802 28.7928C5.09569 35.4395 11.752 39.9999 19.4491 39.9999Z"
        fill={props.disabled === true ? props.disabledcolor : "#4CAF50"} />
      <path
        d="M39.611 16.4439H38V16.3638H20V24.0891H31.303C30.511 26.2493 29.072 28.1121 27.216 29.4688L27.219 29.4669L33.409 34.5251C32.971 34.9094 40 29.8831 40 20.2264C40 18.9315 39.862 17.6674 39.611 16.4439Z"
        fill={props.disabled === true ? props.disabledcolor : "#1976D2"} />
    </svg>

  );
}
export default googleIcon;