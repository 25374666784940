import { ApiErrorType } from "shared";
import { Sentry } from "utilz/sentry";

type Workflow =
  | "sampleData"
  | "ticket"
  | "customer"
  | "user"
  | "contract"
  | "common"
  | "validator"
  | "controlCenter"
  | "timeEntry"
  | "watchlist"
  | "pinnedChat"
  | "subscription";
export const sentryLogger = ({
  error,
  message,
  workflow,
  data,
  functionName,
  level = "error",
}: {
  error: ApiErrorType;
  message?: string;
  workflow: Workflow;
  data?: any;
  functionName?: string;
  level?: "fatal" | "error" | "warning" | "log" | "info" | "debug";
}) => {
  Sentry.withScope((scope) => {
    scope.setLevel(level);
    scope.setTags({ workflow, functionName });
    scope.setTag("status", error.status);
    scope.setTag("traceId", error.traceId);
    scope.setTag("message", message ? message : error.message);
    scope.setTag("workflow", workflow);
    scope.setTransactionName(`${workflow}`);
    scope.setExtra("data", data);
    scope.setContext("data", data);
    Sentry.captureException(message ? message : error.message);
  });
};
