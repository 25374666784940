import { Box, Stack, Typography } from "@mui/material";
import { Input, SelectField } from "components";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ToggleButton from "library/base/custom-toggle-button";
import { CustomQuillEditor } from "pages/modules/control-center/configurations/components/service-desk-templates/tabs/component";
import {
  useGetAllBoard,
  useGetAttributes,
  useGlobalStore,
  useMainStore,
} from "controller";

const priorityData = [
  { label: "Critical", value: "critical" },
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

const schema = yup.object({
  template_name: yup.string().required("Please enter project title"),
  template_description: yup
    .string()
    .required("Please enter project description"),
  board_id: yup
    .string()
    .uuid("Please select board")
    .required("Please select board"),
  priority: yup.string().required("Please select priority"),
  template_summary: yup.string().required("Please enter project summary"),
});

export const ProjectTemplateStep1 = forwardRef(
  (
    {
      projectData,
      onSubmitData,
    }: { projectData: any; onSubmitData: (data: any, change: boolean) => void },
    ref
  ) => {
    const mspId = useGlobalStore((state) => state.mspId);
    const generalConfig = useGlobalStore((state) => state.generalConfig);
    const { data: boards } = useGetAllBoard({
      boardType: "project",
      mspId: mspId,
    });
    // const { data: status } = useGetAttributes({
    //   attributeName: "status",
    //   serviceType: "project",
    // });

    const {
      handleSubmit,
      control,
      watch,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: {
        template_name: projectData?.template_name || "",
        board_id:
          projectData?.board_id ||
          projectData?.board ||
          generalConfig?.defaultProjectBoardId ||
          "",
        priority: projectData?.priority || "low",
        template_description: projectData?.template_description || "",
        template_summary: projectData?.template_summary || "",
      },
      resolver: yupResolver(schema),
    });
    const description = watch("template_description");
    const [isBillable, setIsBillable] = useState(projectData.is_billable);

    useEffect(() => {
      onSubmitData(
        {
          is_billable: isBillable || false,
        },
        false
      );
    }, [isBillable]);

    const onSubmit = handleSubmit((data) => {
      const newData = {
        ...data,
        is_billable: isBillable || false,
      };
      onSubmitData(newData, true);
    });

    useImperativeHandle(ref, () => ({
      onSubmit,
    }));

    return (
      <Box pt="16px">
        <Box px={"32px"}>
          <Input
            name="template_name"
            control={control}
            placeholder="Enter project title"
            required
            fullWidth
            error={errors.template_name ? true : false}
            errorMessage={
              errors?.template_name?.message
                ? `${errors?.template_name?.message}`
                : ""
            }
            inputProps={{ maxLength: 100 }}
          />
        </Box>

        <Box px={"32px"}>
          <Input
            name="template_summary"
            control={control}
            placeholder="Enter project summary"
            required
            fullWidth
            error={errors.template_summary ? true : false}
            errorMessage={
              errors?.template_summary?.message
                ? `${errors?.template_summary?.message}`
                : ""
            }
            inputProps={{ maxLength: 100 }}
          />
        </Box>
        <Stack
          px={"32px"}
          py={"12px"}
          direction="row"
          gap="24px"
          justifyContent={"space-between"}
        >
          <Stack direction="row" gap="24px" flex={1} maxWidth={"550px"}>
            <SelectField
              name="board_id"
              label="Board"
              required
              options={Array.isArray(boards?.result) ? boards?.result : []}
              labelMappingKey="project_board_name"
              valueMappingKey="project_board_id"
              control={control}
              error={errors.board_id ? true : false}
              errorMessage={
                errors?.board_id?.message ? `${errors?.board_id?.message}` : ""
              }
            />

            <SelectField
              name="priority"
              label="Priority"
              required
              options={priorityData}
              labelMappingKey="label"
              valueMappingKey="value"
              control={control}
              error={errors.priority ? true : false}
              errorMessage={
                errors.priority?.message ? `${errors.priority?.message}` : ""
              }
            />
          </Stack>
          <Stack
            direction="row"
            gap="16px"
            pt={"8px"}
            minWidth={"248px"}
            justifyContent={"center"}
          >
            <ToggleButton
              name="is_billable"
              onChange={(data) => {
                setIsBillable(data.value);
              }}
              isChecked={isBillable}
            />
            <Typography variant="body-medium" noWrap>
              {projectData.is_billable ? "Billable" : "Non Billable"}
            </Typography>
          </Stack>
        </Stack>
        <CustomQuillEditor
          placeholder={"Tell us more about the issue"}
          value={description}
          name={"quill editor"}
          error={errors.template_description ? true : false}
          onChange={(e) => {
            setValue("template_description", e?.value);
            //   addValuesToField({ type: "description", value: e?.value });
          }}
        />
      </Box>
    );
  }
);
