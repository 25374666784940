import { Assignees } from "shared";

export const transformAssigneesForChat = async (
  assignees: Array<Assignees>
) => {
  return assignees.map((assignee) => {
    return {
      mapping_id: assignee.mapping_id,
      display_name: assignee.display_name
        ? assignee.display_name
        : `${assignee.first_name} ${assignee.last_name}`,
      profile_pic: assignee.profile_image || "",
    };
  });
};
