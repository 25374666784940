import React, { useEffect, useState } from "react";
import Rating1 from "../assets/images/rating-1.png";
import Rating2 from "../assets/images/rating-2.png";

import Rating3 from "../assets/images/rating-3.png";
import Rating4 from "../assets/images/rating-4.png";
import Rating5 from "../assets/images/rating-5.png";
import Rating1Disabled from "../assets/images/rating-1-disabled.png";
import Rating2Disabled from "../assets/images/rating-2-disabled.png";
import Rating3Disabled from "../assets/images/rating-3-disabled.png";
import Rating4Disabled from "../assets/images/rating-4-disabled.png";
import Rating5Disabled from "../assets/images/rating-5-disabled.png";

import { Box, BoxProps } from "@mui/material";

interface RatingImageProps extends BoxProps {
  rating?: number;
  disabled?: boolean;
}

const getImgUrl = (disabled: boolean, rating: number) => {
  switch (rating) {
    case 1:
      return disabled ? Rating1Disabled : Rating1;
      break;
    case 2:
      return disabled ? Rating2Disabled : Rating2;
      break;
    case 3:
      return disabled ? Rating3Disabled : Rating3;
      break;
    case 4:
      return disabled ? Rating4Disabled : Rating4;
      break;
    case 5:
      return disabled ? Rating5Disabled : Rating5;
      break;
    default:
      return disabled ? Rating1Disabled : Rating1;
      break;
  }
};
export const RatingImage = ({
  rating,
  disabled = false,
  ...rest
}: RatingImageProps) => {
  const [imgUrl, setimgUrl] = useState(getImgUrl(disabled, rating || 1));

  useEffect(() => {
    const url = getImgUrl(disabled, rating || 1);
    setimgUrl(url);
  }, [disabled, rating]);

  return (
    <>
      {rating ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          {...rest}
        >
          <img width="30px" height="30px" src={imgUrl} alt="" />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
