import { immer } from "zustand/middleware/immer";

interface ColumnListProps {
  column: string;
  is_default: boolean;
  label_name: string;
  table_order: number;
  field_order: number;
}
interface HeaderProps {
  name: string;
  sortable?: boolean;
  key: string;
  numeric?: boolean;
}
interface MetadataProps {
  chart_type: string;
  report_name: string;
  label: string;
  filters: Array<string>;
  column_list: Array<ColumnListProps>;
  table_columns?: Array<HeaderProps>;
}
interface ReportState {
  metadata: MetadataProps;
  reportViewType: "graph" | "table" | "grid";
  filter: Record<string, string>;
}

interface ReportAction {
  setReportMetadata: (value: MetadataProps) => void;
  updateMetadata: ({
    type,
    value,
  }: {
    type: "filters" | "column_list" | "label" | "report_name" | "chart_type";
    value: any;
  }) => void;
  setReportViewType: (value: "graph" | "table" | "grid") => void;
  resetReportData: () => void;
  updateFilter: (data: Record<string, string>) => void;
}

const initialReportValue = {
  metadata: {
    chart_type: "",
    report_name: "",
    label: "",
    filters: [],
    column_list: [],
    table_columns: [],
  },
  filter: {
    customer: "all",
    customerName: "All",
    timePeriod: "this_month",
    timePeriodLabel: "This Month",
  },
  reportViewType: "grid" as "graph" | "table" | "grid",
};
export interface ReportInterface extends ReportState, ReportAction {}
export const reportSlice = immer<ReportState & ReportAction>((set) => ({
  ...initialReportValue,
  setReportMetadata(value) {
    set((state) => {
      let defaultColumns: Array<any> = [];
      if (value.column_list) {
        value.column_list.map((column) => {
          if (column.is_default) {
            defaultColumns.push({
              name: column.label_name || "",
              sortable: false,
              key: column.column || "",
              numeric: false,
              table_order: column.table_order,
            });
          }
          defaultColumns = defaultColumns.sort(
            (a, b) => a.table_order - b.table_order
          );
        });
      }
      state.metadata = { ...value, table_columns: defaultColumns };
    });
  },

  setReportViewType(value) {
    set((state) => {
      state.reportViewType = value;
    });
  },
  updateMetadata({ type, value }) {
    set((state) => {
      if (type === "column_list") {
        let defaultColumns: Array<any> = [];
        value.map((column: ColumnListProps) => {
          if (column.is_default) {
            defaultColumns.push({
              name: column.label_name,
              sortable: false,
              key: column.column,
              numeric: false,
              table_order: column.table_order,
            });
          }
        });
        defaultColumns = defaultColumns.sort(
          (a, b) => a.table_order - b.table_order
        );
        state.metadata.table_columns = defaultColumns;
      }
      state.metadata[type] = value;
    });
  },
  updateFilter(data) {
    set((state) => {
      state.filter = data;
    });
  },
  resetReportData() {
    set(initialReportValue);
  },
}));
