import { Drawer, Input, SelectField } from "components";
import React, { useEffect } from "react";

import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Stack, useTheme } from "@mui/material";
import {
  useGetAllCountries,
  useGetAllStateByCountryId,
  useGetMspBillingDetails,
  useGlobalStore,
  useUpdateMspBilling,
} from "controller";
import toast from "react-hot-toast";
interface BillingDetailsDrawerProps {
  open: boolean;
  onClose: () => void;
  mspBillingDetails: Record<string, any>;
  type: "EDIT" | "CREATE";
  successCallback?: () => void;
}

const schema = yup.object({
  billingName: yup.string(),
  billingCountry: yup.number().required("Billing country is required"),
  billingState: yup.number().nullable(),
  billingPostalCode: yup.string().nullable(),
  billingAddressLine1: yup.string().required("Billing address is required"),
  billingAddressLine2: yup.string().nullable(),
});
export const BillingDetailsDrawer = ({
  open,
  onClose,
  mspBillingDetails,
  type = "EDIT",
  successCallback,
}: BillingDetailsDrawerProps) => {
  const theme = useTheme();
  const mspId = useGlobalStore((state) => state.mspId);
  const { data: countries } = useGetAllCountries();
  const { mutateAsync: updateBilling, isLoading } = useUpdateMspBilling();

  const {
    handleSubmit,
    control,
    watch,
    setValue: setFormValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      billingName: "",
      billingCountry: null,
      billingState: null,
      billingPostalCode: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
    },
    resolver: yupResolver(schema),
  });

  const countryCode = useWatch({ control, name: "billingCountry" });
  const { data: state } = useGetAllStateByCountryId(`${countryCode}` || "");

  React.useEffect(() => {
    if (mspBillingDetails) {
      mspBillingDetails.company_name &&
        setFormValue("billingName", mspBillingDetails.company_name);
      mspBillingDetails.line1 &&
        setFormValue("billingAddressLine1", mspBillingDetails.line1);
      mspBillingDetails.line2 &&
        setFormValue("billingAddressLine2", mspBillingDetails.line2);
      mspBillingDetails.billing_country &&
        setFormValue("billingCountry", mspBillingDetails.billing_country);
      mspBillingDetails.billing_postal_code &&
        setFormValue(
          "billingPostalCode",
          mspBillingDetails.billing_postal_code
        );
      mspBillingDetails.billing_state &&
        setFormValue("billingState", mspBillingDetails.billing_state);
    }
  }, [mspBillingDetails]);

  const onSubmitHandler = handleSubmit(
    async ({
      billingName,
      billingAddressLine1,
      billingAddressLine2,
      billingCountry,
      billingPostalCode,
      billingState,
    }) => {
      try {
        await updateBilling({
          mspId: mspId || "",
          data: {
            billingAddressLine1,
            billingAddressLine2: billingAddressLine2,
            billingCountry,
            billingName,
            billingZipCode: billingPostalCode,
            billingState,
          },
        });
        if (type === "EDIT") {
          toast.success("Billing details updated successfully");
          onClose();
        } else {
          successCallback && successCallback();
        }
      } catch (error) {
        toast.error("Error while updating billing details");
        return false;
      }
    },
    (error) => {
      console.log(error);
    }
  );

  return (
    <Drawer
      open={open}
      isLoading={isLoading}
      onClose={onClose}
      title="Billing Details"
      secondaryButtonText="Cancel"
      primaryButtonText="Save changes"
      onPrimaryButtonClick={onSubmitHandler}
      isPrimaryButtonDisabled={!isDirty}
      onSecondaryButtonClick={onClose}
    >
      <Stack sx={{ width: "452px", p: "24px", gap: "20px" }}>
        <Input
          name="billingName"
          label="Billing name"
          control={control}
          error={!!errors.billingName}
          disabled
        />
        <Input
          name="billingAddressLine1"
          label="Address line 1"
          control={control}
          error={!!errors.billingAddressLine1}
          errorMessage={errors.billingAddressLine1?.message || ""}
          required
        />
        <Input
          name="billingAddressLine2"
          label="Address line 2"
          control={control}
        />

        <SelectField
          options={countries}
          labelMappingKey="name"
          valueMappingKey="id"
          name="billingCountry"
          label="Country"
          isSearchable
          control={control}
          error={!!errors.billingCountry}
          required
          errorMessage={errors.billingCountry?.message || ""}
          isClearable
        />
        <SelectField
          options={state}
          labelMappingKey="state_name"
          valueMappingKey="id"
          name="billingState"
          label="State"
          isSearchable
          control={control}
          value={watch("billingState")}
        />
        <Input
          name="billingPostalCode"
          label="Zip code"
          control={control}
          error={!!errors.billingPostalCode}
        />
      </Stack>
    </Drawer>
  );
};
