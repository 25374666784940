import { Stack, Typography, useTheme } from "@mui/material";

export const Feedback2 = ({ data }: { data: any }) => {
  const cardsData = [
    {
      title: "Overdue",
      ticketsCount: data?.overdue_count?.tickets || 0,
    },
    {
      title: "Unassigned",
      ticketsCount: data?.unassigned_count?.tickets || 0,
    },
    {
      title: "Due today",
      ticketsCount: data?.due_today_count?.tickets || 0,
    },
    {
      title: "Unresolved",
      ticketsCount: data?.unresolved_count?.tickets || 0,
      isTicketsCountRed: false,
    },
  ];
  return (
    <Stack direction="row" gap="16px">
      {cardsData.map((card) => (
        <Card
          title={card.title}
          ticketsCount={card.ticketsCount}
          isTicketsCountRed={card.isTicketsCountRed}
        />
      ))}
    </Stack>
  );
};

const Card = ({
  title,
  ticketsCount,
  isTicketsCountRed = true,
}: {
  title: string;
  ticketsCount: number;
  isTicketsCountRed?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Stack
      borderRadius={"8px"}
      flexGrow="1"
      bgcolor={theme.palette.neutral.main}
    >
      <Typography
        variant="body-medium"
        p="8px 16px"
        borderBottom={`1px solid ${theme.palette.neutral["010"]}`}
      >
        {title}
      </Typography>
      <Stack p="16px">
        <Stack>
          <Typography
            variant="h4"
            color={
              isTicketsCountRed
                ? theme.palette.error.main
                : theme.palette.lightBg.main
            }
          >
            {ticketsCount}
          </Typography>
          <Typography variant="caption-2">Tickets</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
