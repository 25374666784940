import { v4 } from "uuid";
import { services } from "../../../../../../../../../../../services";


export const STATUS = { "UPLOADING": "uploading", "COMPLETE": "Uploaded", "ERROR": "Failed" };

export function ticketTemplateDataListBuilder(data = []) {
  if (Array.isArray(data)) {
    return data.map((item) => ticketTemplateDataBuilder(item));
  }
  return [];
}


export function ticketTemplateDataBuilder(data = {}) {
  return ({
    key: data["mapping_id"] || v4(),
    file: data["file"] || "",
    original_name: data["original_name"] || data["file"].name || "",
    file_type: data["file_type"] || data["file"].type || "",
    url: data["url"] || "",
    uploader: data["uploader"] || "",
    status: data["status"] || data["mapping_id"] === "" ? STATUS.UPLOADING : STATUS.COMPLETE,
    mapping_id: data["mapping_id"] || "",
  });


}


