import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { default as MuiTab } from "@mui/material/Tab";
import { default as MuiTabs } from "@mui/material/Tabs";
import { NotificationCard } from "./components/NotificationCard";
import {
  useGetMspNotificationSettings,
  useUpdateMspNotificationSettings,
} from "controller/hooks/control-center/notifications";
import { Tab, Tabs } from "components";

interface StyledTabProps {
  label: string;
}

const menuFileds = [
  {
    title: "tickets",
    menu: [
      { key: "new_ticket", title: "New Tickets" },
      { key: "assigned_ticket", title: "Assigned Tickets" },
      { key: "ticket_status_update", title: "Ticket Status Updates" },
      { key: "closed_ticket", title: "Closed Tickets" },
      { key: "reopen_ticket", title: "Re-open Tickets" },
    ],
  },
  {
    title: "projects",
    menu: [
      { key: "new_project", title: "New Projects" },
      { key: "assigned_project", title: "Assigned Projects" },
      { key: "project_status_update", title: "Project Status Updates" },
      { key: "closed_project", title: "Closed Projects" },
    ],
  },
  {
    title: "task",
    menu: [
      { key: "new_task", title: "New Tasks" },
      { key: "assigned_task", title: "Assigned Tasks" },
      { key: "task_status_update", title: "Task Status Updates" },
      { key: "closed_task", title: "Closed Tasks" },
    ],
  },
];

export const NotificationScreen = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { data: notificationSettings } = useGetMspNotificationSettings();
  const { mutateAsync: updateSettings } = useUpdateMspNotificationSettings();
  const [currentTypeIndex, setCurrentTypeIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleVerticalChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setCurrentTypeIndex(newValue);
    setValue(0);
  };

  return (
    <Stack
      sx={{
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box px="32px" py="22px">
        <Typography variant="h4">Notifications</Typography>
      </Box>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          flexGrow: 1,
          borderTop: `1px solid ${theme.palette.neutral["020"]}`,
        }}
      >
        <Box
          sx={{
            flex: 1,
            borderRight: `1px solid ${theme.palette.neutral["020"]}`,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentTypeIndex}
            onChange={handleVerticalChange}
            aria-label="Vertical tabs example"
          >
            {menuFileds.map((ruleType, index) => (
              <Tab
                key={`rule_type_sub_${ruleType.title}`}
                label={ruleType.title}
              />
            ))}
          </Tabs>
        </Box>
        <Stack sx={{ flex: 4, height: "100%" }}>
          <Tabs
            orientation="horizontal"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={
              {
                // px: "32px",
              }
            }
          >
            {menuFileds[currentTypeIndex].menu.map((ruleType, index) => (
              <Tab
                key={`rule_type_sub_${ruleType.key}`}
                label={ruleType.title}
              />
            ))}
          </Tabs>
          <NotificationCard
            type={menuFileds[currentTypeIndex].menu[value].key}
            data={notificationSettings?.result}
            onSaveClick={async (data) => {
              const roles =
                data.notifyRoles.length === 0
                  ? [{ role_id: null, name: "all" }]
                  : data.notifyRoles;

              await updateSettings({ ...data, notifyRoles: roles });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
