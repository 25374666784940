import {
  CreateConfigCheckListInput,
  GetQueryProps,
  UpdateConfigCheckListInput,
} from "shared";
import { axiosClient } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const getAllChecklist = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: "/v2/configuration/checklist",
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};

export const createConfigCheckList = async (
  data: CreateConfigCheckListInput
) => {
  try {
    const response = await axiosClient.post(
      "/v2/configuration/checklist",

      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateConfigCheckList = async ({
  checklistId,
  ...data
}: UpdateConfigCheckListInput) => {
  try {
    const response = await axiosClient.patch(
      `/v2/configuration/checklist/${checklistId}`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getConfigCheckListById = async (checklistId: string) => {
  try {
    const response = await axiosClient.get(
      `/v2/configuration/checklist/${checklistId}`
    );
    const resData = response.data;
    if (resData?.success && resData?.statusCode === 200 && resData?.data) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteConfigCheckList = async (checklistId: string) => {
  try {
    const response = await axiosClient.delete(
      `/configuration/delete/checklist/${checklistId}`
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
