import {
  Box,
  InputAdornment,
  InputAdornmentProps,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarIcon, InfoFilledIcon } from "components/icons";
import { useGlobalStore } from "controller/store/globalStore";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { dayjs } from "utilz";

interface DateTimeInputProps {
  sx?: SxProps<Theme>;
  value: Dayjs | string | null;
  label?: string;
  onChangeHandler: (value: Dayjs | null | string) => void;
  size?: "small" | "large" | "medium";
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: any;
  minTime?: any;
  minDateTime?: Dayjs | string | null;
  error?: boolean;
  errorMessage?: string;
  timezone?: string;
  fullWidth?: boolean;
  hideHelperBox?: boolean;
  backgourndColor?: string;
  disableOpenPicker?: boolean;
  slots?: Record<string, any>;
  slotProps?: Record<string, any>;
}

const EmptyIcon = () => <Box sx={{ width: "24px", height: "24px" }} />;
export const DateTimeInput = ({
  sx,
  value,
  onChangeHandler,
  label,
  size = "small",
  disabled = false,
  disableFuture = false,
  disablePast = false,
  minDate,
  error,
  minTime,
  errorMessage,
  minDateTime,
  timezone,
  fullWidth = false,
  hideHelperBox = true,
  backgourndColor,
  disableOpenPicker = false,
  slots,
  slotProps,
}: DateTimeInputProps) => {
  const theme = useTheme();
  const dateFormat =
    useGlobalStore((state) => state.dateFormat) || "MM/DD/YYYY";

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        disableOpenPicker={disableOpenPicker}
        value={value}
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={disabled}
        minDate={minDate ? dayjs(minDate) : undefined}
        minTime={minTime ? dayjs(minTime) : undefined}
        minDateTime={minDateTime ? dayjs(minDateTime) : undefined}
        // timezone={timezone}

        label={label}
        format={`${dateFormat} h:mm a`}
        timezone={timezone}
        onAccept={(newValue) => onChangeHandler(newValue)}
        slots={{
          openPickerIcon: disabled
            ? EmptyIcon
            : error
            ? InfoFilledIcon
            : CalendarIcon,

          ...slots,
        }}
        slotProps={{
          openPickerButton: {
            color: error ? "error" : "inherit",
          },
          actionBar: {
            sx: {
              "&.MuiDialogActions-root": {
                color: `${theme.palette.purple.main} !important`,
                "& .MuiButtonBase-root": {
                  ...theme.typography.caption,
                  color: `${theme.palette.purple.main} !important`,
                },
              },
            },
          },
          desktopPaper: {
            sx: {
              "& .MuiPickersDay-root": {
                borderRadius: "6px",
                ":hover": {
                  bgcolor: theme.palette.neutral["005"],
                },
                ...theme.typography["body-small"],
                "&.Mui-selected": {
                  backgroundColor: theme.palette.purple.main,
                  color: "#FFF",
                },
                "&.MuiPickersDay-today": {
                  border: `1px solid ${theme.palette.purple.main}`,
                },
              },
              "& .MuiMultiSectionDigitalClock-root": {
                ...theme.typography["body-medium"],
                "& .Mui-selected": {
                  backgroundColor: "#EBFCFE",
                  border: `1px solid ${theme.palette.purple.main}`,
                  color: theme.palette.purple.main,
                  borderRadius: "6px",
                },
              },
              borderRadius: "16px",
              boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
              // @ts-ignore
              boxShadow: "0px 8px 10px 1px rgba(0, 0, 0, 0.14)",
              // @ts-ignore
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
            },
          },
          textField: {
            helperText: (
              <Box
                sx={{
                  mt: errorMessage ? "4px" : hideHelperBox ? "0px" : "4px",
                }}
              >
                <Typography variant="body-small" color="error">
                  {errorMessage}
                </Typography>
              </Box>
            ),
            error: error ? true : false,
            size: size,
            fullWidth: fullWidth,
            // InputProps: {
            //   endAdornment: <CustomInputAdornment position="end" />,
            // },
            // InputProps: {
            //   endAdornment: <CalendarIcon />,
            // },
            sx: {
              ...theme.typography["body-medium"],
              "& .MuiOutlinedInput-root": {
                height: { sm: "32px" },
                backgroundColor: backgourndColor
                  ? backgourndColor
                  : error
                  ? theme.palette.error.tint
                  : "transparent",
                "& > fieldset": {
                  borderColor: theme.palette.neutral["020"],
                  borderRadius: "4px",
                },
                "& input::placeholder": { fontSize: "14px" },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                color: theme.palette.lightBg.high,
                "& > fieldset": { borderColor: theme.palette.purple.main },
              },
              "& label": {
                "&.Mui-focused": { color: theme.palette.purple.main },
              },
              "& .MuiFormHelperText-root": {
                mt: "0px",
              },
              ...sx,
            },
          },

          ...slotProps,
        }}
      />
    </LocalizationProvider>
  );
};

// function CustomInputAdornment(props: InputAdornmentProps) {
//   const { children, sx, ...other } = props;
//   return (
//     <InputAdornment {...other}>
//       <InfoFilledIcon color="error" />
//       {children}
//     </InputAdornment>
//   );
// }
