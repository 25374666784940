import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createVariableAttributeValue,
  deleteVariableAttributeValue,
  getVariableAttribute,
  getVariableAttributes,
  updateVariableAttribute,
  updateVariableAttributeValue,
} from "controller/api";
import {
  ApiErrorType,
  CreateVariableAttributeValueInput,
  DeleteVariableAttributeValueInput,
  GetVariableAttributeInput,
  GetVariableAttributesInputProps,
  UpdateVariableAttributeInput,
  UpdateVariableAttributeValueInput,
} from "shared";
import { sentryLogger } from "utilz";

export const useGetVariableAttributes = ({
  attributeName,
}: GetVariableAttributesInputProps) => {
  return useQuery({
    queryKey: ["variable_attribute", attributeName],
    queryFn: () => getVariableAttributes({ attributeName }),
    enabled: attributeName ? true : false,
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getVariableAttributes",
      }),
  });
};

export const useGetVariableAttribute = ({
  attributeName,
  attributeId,
}: GetVariableAttributeInput) => {
  return useQuery({
    queryKey: ["variable_attribute", attributeName, attributeId],
    queryFn: () => getVariableAttribute({ attributeName, attributeId }),
    enabled: attributeName && attributeId ? true : false,
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getVariableAttribute",
      }),
  });
};

export const useUpdatevariableAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateVariableAttributeInput) => updateVariableAttribute(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "variable_attribute",
          variables.attributeName,
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateVariableAttribute",
        });
      },
    }
  );
};

export const useCreateVariableAttributeValue = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: CreateVariableAttributeValueInput) =>
      createVariableAttributeValue(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "variable_attribute",
          variables.attributeName,
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "createVariableAttributeValue",
        });
      },
    }
  );
};

export const useUpdateVariableAttributeValue = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateVariableAttributeValueInput) =>
      updateVariableAttributeValue(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "variable_attribute",
          variables.attributeName,
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateVariableAttributeValue",
        });
      },
    }
  );
};

export const useDeleteVariableAttributeValue = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: DeleteVariableAttributeValueInput) =>
      deleteVariableAttributeValue(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "variable_attribute",
          variables.attributeName,
        ]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "deleteVariableAttributeValue",
        });
      },
    }
  );
};
