import { SvgIcon, SvgIconProps } from "@mui/material";

export const TaxTileIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 11V10"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 17V18"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.264 16.285C10.523 16.709 10.965 17.002 11.5 17.002H12H12.596C13.372 17.002 14 16.373 14 15.598C14 14.954 13.561 14.393 12.937 14.235L11.062 13.764C10.439 13.608 10 13.047 10 12.403C10 11.627 10.629 10.999 11.404 10.999H12H12.5C13.033 10.999 13.475 11.291 13.733 11.714"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 20.5H5C3.895 20.5 3 19.605 3 18.5V9.5C3 8.395 3.895 7.5 5 7.5H19C20.105 7.5 21 8.395 21 9.5V18.5C21 19.605 20.105 20.5 19 20.5Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1739 7.5V5.5C16.1739 4.395 15.2789 3.5 14.1739 3.5H9.82593C8.72093 3.5 7.82593 4.395 7.82593 5.5V7.5"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </SvgIcon>
);
