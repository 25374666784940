import React, { Component } from "react";

// Services Imports
import { services } from "../../../../../../services";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// HOC Imports
import { CustomCard } from "../../../../../../library/layers";
import { CustomTypography } from "../../../../../../library/base";
import { CustomTextField } from "../../../../../../library/system/custom-form";
import CustomIcon from "../../../../../../library/icon-library";
import { BottomStickyLayer } from "../../../../../../library/layers";
import { CustomButton } from "../../../../../../library/base";

// Form Imports
import BillingDetailsForm from "./forms";

// Constants imports
import { getMspId } from "utilz/helpers";
const severity = { ERROR: "error", SUCCESS: "success", WARNING: "warning" };

class CompanyProfileAccountsTab extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      accountDetails: {},
      billingDetails: {},
      enableForm: false,
      render: "",
      toaster: {
        open: false,
        severity: severity["SUCCESS"],
        message: "",
      },
    };
  }

  // Lifecycles
  componentDidMount() {
    this.fetchMspAccountDetails();
    this.fetchMspBillingDetails();
  }
  componentDidUpdate() {
    // console.log('[COMPONENT: UPDATED] Control-Center/ Company-Profile/ Accounts');
    // console.log(this.state);
  }

  // API Calls
  fetchMspAccountDetails = () => {
    try {
      services.controlCenterservices.mspServices
        .fetchMspById(getMspId())
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true &&
              response?.data
            ) {
              this.setState({
                ...this.state,
                accountDetails: {
                  account_owner: response?.data?.account_owner || "",
                  business_email: response?.data?.business_email || "",
                  data_center: response?.data?.data_center || "",
                },
              });
            }
          },
          error: (error) => {
            console.log("[ERROR] Fetching Account Details by Msp-Id");
            console.log(error);
          },
        });
    } catch (err) {}
  };
  fetchMspBillingDetails = () => {
    try {
      services.controlCenterservices.mspServices
        .fetchMspBillingDetailsById(getMspId())
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.statusCode === 200 &&
              response?.success === true &&
              response?.data
            ) {
              this.setState({
                ...this.state,
                billingDetails: {
                  billing_name: response?.data?.billing_name || "",
                  billing_building_name:
                    response?.data?.billing_building_name || "",
                  billing_floor: response?.data?.billing_floor || "",
                  billing_country: response?.data?.billing_country || "",
                  billing_state: response?.data?.billing_state || "",
                  billing_city: response?.data?.billing_city || "",
                  billing_postal_code:
                    response?.data?.billing_postal_code || "",
                },
              });
            }
          },
          error: (error) => {
            console.log("[ERROR] Fetching Billing Details by Msp-Id");
            console.log(error);
          },
        });
    } catch (err) {
      console.log("[ERROR] Fetching Billing Details by Msp-Id");
      console.log(err);
    }
  };
  updateBillingAddress = (data) => {
    try {
      services.controlCenterservices.mspServices
        .updateMspBillingById(getMspId(), data)
        .subscribe({
          next: (response) => {
            if (
              response &&
              response?.success === true &&
              response?.statusCode === 200
            ) {
              console.log(response);
              this.setState(
                {
                  ...this.state,
                  enableForm: false,
                  toaster: {
                    open: true,
                    severity: severity["SUCCESS"],
                    message: response?.message || "Populating MSP Details",
                  },
                },
                () => this.fetchMspBillingDetails()
              );
            } else {
              this.setState({
                ...this.state,
                toaster: {
                  open: true,
                  severity: severity["WARNING"],
                  message: response?.message || "Server Busy. Please try again",
                },
              });
            }
          },
          error: (error) => {
            console.log("[ERROR] Updating MSP Details by MSP-ID");
            console.log(error);
            this.setState({
              ...this.state,
              toaster: {
                open: true,
                severity: severity["ERROR"],
                message: "Unexpected error occurred",
              },
            });
          },
        });
    } catch (err) {
      console.log("[ERROR: UPDATE] Updating billing address by MSP-ID");
      console.log(err);
      this.setState({
        ...this.state,
        toaster: {
          open: true,
          severity: severity["ERROR"],
          message: "Unexpected error occurred",
        },
      });
    }
  };

  // Event Handlers
  handleSubmit = () => {
    const data = this.formRef.current.submit();
    this.updateBillingAddress(data);
  };
  handleClose = () =>
    this.setState({
      ...this.state,
      toaster: { open: false, severity: severity["SUCCESS"], message: "" },
    });

  // Renderer
  render() {
    return (
      <React.Fragment>
        <Snackbar
          open={this.state.toaster?.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={4500}
          onClose={this.handleClose}
        >
          <Alert
            onClose={this.handleClose}
            // variant = {'outlined'}
            severity={this.state.toaster?.severity}
            sx={{ width: "100%" }}
          >
            {this.state.toaster?.message}
          </Alert>
        </Snackbar>
        <Box sx={{ margin: "24px 32px 82px 32px" }}>
          <Box
            sx={{
              width: { xs: "100%", sm: "85%", md: "85%", lg: "70%", xl: "55%" },
              marginTop: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Stack
              spacing={"24px"}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomTypography
                  size={"MEDIUM-ALPHA"}
                  color={"PRIMARY-MAIN"}
                  variant={"BOLD"}
                  content={"Account Details"}
                />
                <Box sx={{ mt: "24px" }}>
                  <CustomCard
                    borderRadius={"24px"}
                    outlined={true}
                    spacing={"16px"}
                    content={
                      <Box sx={{ px: "24px", width: "100%", py: "12px" }}>
                        <Grid
                          container
                          columnSpacing={"32px"}
                          rowSpacing={"32px"}
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <CustomTextField
                              name={"account_owner"}
                              label={"Account Owner"}
                              value={
                                this.state?.accountDetails?.account_owner || ""
                              }
                              disabled={true}
                              required={false}
                              error={false}
                              helperText={""}
                              placeholder={"Account Owner"}
                              changeHandler={() => {}}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <CustomTextField
                              name={"business_email"}
                              label={"Email Address"}
                              value={
                                this.state?.accountDetails?.business_email || ""
                              }
                              disabled={true}
                              required={false}
                              error={false}
                              helperText={""}
                              changeHandler={() => {}}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  />
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <CustomTypography
                  size={"MEDIUM-ALPHA"}
                  color={"PRIMARY-MAIN"}
                  variant={"BOLD"}
                  content={"Data Center Location"}
                />
                <Box sx={{ mt: "24px" }}>
                  <CustomCard
                    borderRadius={"24px"}
                    outlined={true}
                    spacing={"16px"}
                    content={
                      <Box sx={{ px: "24px", width: "100%", py: "12px" }}>
                        {/* <CustomTypography
                          size={"SMALL-ALPHA"}
                          color={"PRIMARY-MAIN"}
                          variant={"REGULAR"}
                          content={
                            "We have securely placed your data in our data center location."
                          }
                        /> */}
                        <Grid
                          container
                          columnSpacing={"32px"}
                          rowSpacing={"32px"}
                          // sx={{ mt: "8px" }}
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <CustomTextField
                              name={"data_center"}
                              label={"Data Center"}
                              value={
                                this.state?.accountDetails?.data_center || ""
                              }
                              disabled={true}
                              required={false}
                              error={false}
                              helperText={""}
                              placeholder={"Data Center"}
                              changeHandler={() => {}}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  />
                </Box>
              </Box>
              {/* <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CustomTypography
                    size={"MEDIUM-ALPHA"}
                    color={"PRIMARY-MAIN"}
                    variant={"BOLD"}
                    content={"Billing Address"}
                  />
                  <Box
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        enableForm: this.state?.enableForm === false,
                      })
                    }
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <CustomIcon
                      icon={this.state?.enableForm === true ? "reply" : "edit"}
                      size={"lg"}
                      color={"#1C1E26"}
                    />
                  </Box>
                </Box>

                <Box sx={{ mt: "24px" }}>
                  <CustomCard
                    borderRadius={"24px"}
                    outlined={true}
                    spacing={"16px"}
                    content={
                      <BillingDetailsForm
                        ref={this.formRef}
                        data={this.state.billingDetails}
                        enable={this.state?.enableForm}
                      />
                    }
                  />
                </Box>
              </Box> */}
            </Stack>
          </Box>
        </Box>
        {this.state?.enableForm === true ? (
          <BottomStickyLayer
            content={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <CustomButton
                  varient="primary"
                  size="sm"
                  label="Save"
                  onClick={this.handleSubmit}
                />
              </Box>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default CompanyProfileAccountsTab;
