import { create } from "zustand";

import {
  ReadyToBillStateActionInterface,
  readyToBillSlice,
} from "./billingConfiguration";

export const useBillingStore = create<ReadyToBillStateActionInterface>()(
  (...a) => ({
    ...readyToBillSlice(...a),
  })
);
