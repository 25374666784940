import { Box } from "@mui/material";
import { Input } from "components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import toast from "react-hot-toast";
import {
  useCreateExpenseConfig,
  useGlobalStore,
  useUpdateExpenseConfig,
} from "controller";

const schema = yup.object({
  expense_name: yup.string().trim().required("Required"),
  price: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    )
    .typeError("Unit price must be a number")
    .required("Unit price is required"),
});

export const BillingExpenseFields = forwardRef(
  ({ data, closeDrawer, mode }: any, ref) => {
    useImperativeHandle(ref, () => ({
      saveButtonClick: () => {
        handleSubmit(async (data: any) => {
          const newData = { ...data, price: data.price * 100 }; // dollars converted to cents - backend saves all prices in cents
          try {
            if (mode?.method === "create") {
              await createExpenseConfig(newData);
            } else if (mode?.method === "update") {
              await updateExpenseConfig({
                data: newData,
                id: mode?.data?.mapping_id,
              });
            }
            closeDrawer();
            reset();
            toast.success("Expense added successfully");
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
          }
        })();
      },
      cancelButtonClick: () => {
        closeDrawer();
        reset();
      },
    }));

    const currency = useGlobalStore((state) => state.currency);

    const {
      handleSubmit,
      control,
      reset,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        expense_name: data?.expense_name || "",
        price: data?.price / 100 || "",
      },
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      if (mode?.data) {
        // Manually set default values after mode?.data is fetched
        setValue("expense_name", mode?.data.expense_name || "");
        setValue("price", mode?.data.price / 100 || "");
      } else {
        reset();
      }
    }, [mode?.data, setValue, closeDrawer]);

    const { mutateAsync: createExpenseConfig, isLoading } =
      useCreateExpenseConfig();
    const { mutateAsync: updateExpenseConfig } = useUpdateExpenseConfig();

    return (
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Input
          label={"Expense Name"}
          name="expense_name"
          control={control}
          error={errors?.expense_name ? true : false}
          isResponsive={false}
          errorMessage={errors?.expense_name && "Expense name is required"}
        />
        <Input
          label={"Amount"}
          name="price"
          control={control}
          placeholder={`${currency?.symbol || ""}0.00`}
          error={errors?.price ? true : false}
          isResponsive={false}
          errorMessage={errors?.price && "Amount is required"}
          type="number"
          onKeyDown={(e) => {
            if (
              e.key === "e" ||
              e.key === "E" ||
              e.key === "+" ||
              e.key === "-"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Box>
    );
  }
);
