import { Box, Skeleton, Stack, useTheme } from "@mui/material";
import {
  ChartProps,
  CustomTooltip,
  LegendBuilder,
  donutChartColor,
} from "../Utils";
import { useEffect, useRef, useState } from "react";
import {
  Legend,
  PieChart,
  RadialBar,
  ResponsiveContainer,
  Tooltip,
  RadialBarChart as ReRadialBarChart,
} from "recharts";

interface RadialBarChartProps extends ChartProps {
  innerRadius: number | string;
  outerRadius: number | string;
}

export const RadialBarChart = ({
  data = [],
  width,
  height,
  labelKey,
  valueKey = "value",
  innerRadius,
  outerRadius,
  customTooltip,
  isLoading = false,
  showLegends = true,
  selectedIndex = -1,
  onSelectedIndexChange,
}: RadialBarChartProps) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const boxRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    const updateSize = (): void => {
      if (boxRef.current && isLoading) {
        const box = boxRef.current;
        const boxSize = Math.min(box.offsetWidth, box.offsetHeight);
        setSize(boxSize);
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  return isLoading ? (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "8px",
      }}
    >
      <Box
        flex={1}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        position={"relative"}
      >
        <Box
          ref={boxRef}
          flex={1}
          width={"100%"}
          height={"100%"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: size - 45 + "px",
              height: size - 45 + "px",
              position: "relative",
            }}
          >
            <>
              {[...Array(6)].map((_, index: number) => (
                <>
                  <Box
                    sx={{
                      top: `${(50 / 6) * index}%`,
                      right: `${(50 / 6) * index}%`,
                      width: `${(100 / 6) * (6 - index)}%`,
                      height: `${(100 / 6) * (6 - index)}%`,
                      position: "absolute",
                      WebkitTransform: "unset",
                      bgcolor: "white",
                      borderRadius: "50%",
                      // opacity: "0.3",
                      p: "1px",
                    }}
                  >
                    <Skeleton
                      variant="circular"
                      sx={{
                        width: "100%",
                        height: "100%",
                        ...(index == 5 && {
                          bgcolor: "white",
                        }),
                      }}
                    ></Skeleton>
                  </Box>
                </>
              ))}
            </>
          </Box>
        </Box>
      </Box>
    </Stack>
  ) : (
    <Box
      width={width}
      height={height}
      sx={{
        outline: "none !important",
        border: "none !important",
        "& g,path": {
          outline: "none !important",
          border: "none !important",
          "&:focus": {
            outline: "none !important",
          },
        },
      }}
    >
      <ResponsiveContainer width={width} height={height}>
        <ReRadialBarChart
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          data={data}
          height={100}
          width={100}
          startAngle={450}
          endAngle={90}
          barGap={0}
          barCategoryGap={0.5}
          margin={{
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
          }}
        >
          <Tooltip
            content={
              customTooltip || (
                <CustomTooltip labelKey={labelKey} valueKey={valueKey} />
              )
            }
            cursor={{ fill: "white", stroke: "0px" }}
          />
          <RadialBar
            // label={{ position: "insideStart", fill: "#fff" }}
            fill="#8884d8"
            background
            dataKey={valueKey}
          />
          {showLegends && (
            <Legend
              content={LegendBuilder({
                data,
                labelKey,
                color: donutChartColor,
              })}
            />
          )}
        </ReRadialBarChart>
      </ResponsiveContainer>
    </Box>
  );
};
