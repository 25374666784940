function AutomationIcon(props) {
  return (

    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="11.8601"
        cy="12.001"
        r="3.5"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.8601 12L14.7001 14.03"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.8601 12V8.5"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25011 12C5.25011 12.297 5.27711 12.594 5.31311 12.882L3.72511 14.124C3.37311 14.4 3.27711 14.893 3.50111 15.28L4.91311 17.723C5.13611 18.11 5.61011 18.273 6.02511 18.107L7.44711 17.536C7.72811 17.423 8.04111 17.468 8.29311 17.635C8.51311 17.781 8.74111 17.915 8.97711 18.035C9.24711 18.172 9.44311 18.417 9.48611 18.717L9.70311 20.23C9.76611 20.672 10.1451 21 10.5911 21H13.4081C13.8541 21 14.2331 20.672 14.2961 20.23L14.5131 18.718C14.5561 18.418 14.7541 18.171 15.0251 18.035C15.2601 17.917 15.4871 17.784 15.7061 17.639C15.9601 17.471 16.2741 17.423 16.5561 17.537L17.9751 18.107C18.3891 18.273 18.8631 18.11 19.0871 17.723L20.4991 15.28C20.7231 14.893 20.6271 14.399 20.2751 14.124L18.6871 12.882C18.7231 12.594 18.7501 12.297 18.7501 12C18.7501 11.703 18.7231 11.406 18.6871 11.118L20.2751 9.876C20.6271 9.6 20.7231 9.107 20.4991 8.72L19.0871 6.277C18.8641 5.89 18.3901 5.727 17.9751 5.893L16.5561 6.463C16.2741 6.576 15.9601 6.529 15.7061 6.361C15.4871 6.216 15.2601 6.083 15.0251 5.965C14.7541 5.829 14.5561 5.582 14.5131 5.282L14.2971 3.77C14.2341 3.328 13.8551 3 13.4091 3H10.5921C10.1461 3 9.76711 3.328 9.70411 3.77L9.48611 5.284C9.44311 5.583 9.24611 5.829 8.97711 5.966C8.74111 6.086 8.51311 6.221 8.29311 6.366C8.04011 6.532 7.72711 6.577 7.44611 6.464L6.02511 5.893C5.61011 5.727 5.13611 5.89 4.91311 6.277L3.50111 8.72C3.27711 9.107 3.37311 9.601 3.72511 9.876L5.31311 11.118C5.27711 11.406 5.25011 11.703 5.25011 12V12Z"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.86 12L9.02002 14.03"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}
export default AutomationIcon;