import React, { useState, useEffect } from "react";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// HOC Imports
import {
  CustomToggleButton,
  CustomTypography,
} from "../../../../../../../../library/base";

// Constants Imports
import { MAIL_SERVER_DOMAINS } from "./constants";
import { Typography, useTheme } from "@mui/material";
import { BadgeBase, FlagFilledIcon } from "components";

const MailServerComponent = () => {
  const theme = useTheme();
  // State
  const [state, setState] = useState({ open: false, selected: <></> });
  const [enable, setEnable] = useState(false);
  const [key, setKey] = useState("");

  // Effects
  useEffect(() => {
    setKey(v4());
  }, [enable]);

  // Handlers
  const closeModal = () => setState({ ...state, open: false, selected: <></> });

  // Renderer
  return (
    <React.Fragment>
      <Dialog
        open={state?.open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={closeModal}
        PaperProps={{
          sx: {
            borderRadius: "28px",
            width: "640px",
            height: "545px",
          },
        }}
      >
        <DialogContent>{state?.selected}</DialogContent>
      </Dialog>

      <Box
        sx={{
          width: "100%",
          // py: '32px',
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
            <Typography variant="h6">Your own mail server</Typography>

            <BadgeBase
              containerStyles={{
                bgcolor: theme.palette.secondary.main,
                border: `1px solid ${theme.palette.secondary["purple-tint"]}`,
                ml: "16px",
              }}
              trailingIcon={
                <FlagFilledIcon
                  sx={{
                    color: theme.palette.purple.main,
                  }}
                />
              }
            >
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.purple.main,
                }}
              >
                Coming Soon
              </Typography>
            </BadgeBase>
          </Stack>

          <CustomToggleButton
            name={"enable"}
            isDisabled={true}
            isChecked={enable}
            onChange={(data) => setEnable(data?.value)}
          />
        </Box>
        <Grid
          container
          columnSpacing={{ sm: "12px", md: "16px", lg: "24px", xl: "28px" }}
          rowSpacing={{ sm: "12px", md: "16px", lg: "24px", xl: "28px" }}
          sx={{ pt: "32px" }}
        >
          {Object.values(MAIL_SERVER_DOMAINS).map((element) => {
            return (
              <Grid key={element?.key} item xs={12} sm={4} md={4}>
                <Stack
                  key={key}
                  spacing={"20px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    key={key}
                    sx={{
                      width: "100%",
                      height: "15vh",
                      border: "1px solid #E3E3E3",
                      borderRadius: "8px",
                      backgroundColor: "#FEFEFE",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      if (enable === true) {
                        setState({
                          ...state,
                          open: true,
                          selected: element?.render,
                        });
                      }
                    }}
                  >
                    {element?.icon_element}
                  </Box>
                  <CustomTypography
                    size={"SMALL-ALPHA"}
                    color={"TERITARY-MAIN"}
                    variant={"REGULAR"}
                    content={element?.label || "Nothing detected"}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default MailServerComponent;
