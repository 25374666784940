function burgerMenuIcon(props) {

  return (
    <svg
      width='100%'
      height='100%'
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.4978 12H18.5029"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.4978 16.0017H18.5029"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.49731 7.99838H18.5024"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>




  );
}

export default burgerMenuIcon;