import { GetQueryProps } from "shared";
import { axiosClient } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const fetchAllResourceGroupsById = async (resourceId: string) => {
  try {
    const url = `/controlCenter/get/resource_group/${resourceId}/${getMspId()}`;
    const response = await axiosClient.get(url);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const fetchAllResourceGroups = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: `/controlCenter/all/resource_groups/${getMspId()}`,
      data,
    });

    const response = await axiosClient.get(url);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const fetchResourceGroupById = async (id: string) => {
  try {
    const url = `/controlCenter/fetch/resource_group/${id}`;
    const response = await axiosClient.get(url);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createResourceGroup = async ({
  mspId,
  data,
}: {
  mspId?: string | null;
  data: any;
}) => {
  try {
    const url = `/controlCenter/create/resource_group/${mspId}`;
    const response = await axiosClient.post(url, data);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateResourceGroup = async (id: string, data: any) => {
  try {
    const url = `/controlCenter/update/resource_group/${id}`;
    const response = await axiosClient.patch(url, data);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteBulkResourceGroups = async ({
  mspId,
  data,
}: {
  mspId?: string | null;
  data: any;
}) => {
  try {
    const url = `/controlCenter/delete/resource_group/${mspId}`;
    const response = await axiosClient.patch(url, data);
    const responseData = response.data;
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
