export const Table_Model = {
  template_name: {
    key: "template_name",
    name: "template_name",
    expanded: false,
    label: "Template Name",
    size: "200px",
  },
  template_summary: {
    key: "template_summary",
    name: "template_summary",
    label: "Template Summary",
    expanded: false,
    size: "290px",
  },
  template_priority: {
    key: "template_priority",
    name: "priority",
    label: "Priority",
    expanded: false,
    size: "150px",
  },
  template_documents: {
    key: "template_documents",
    name: "template_attachments",
    label: "Documents",
    expanded: true,
    minWidth: "250px",
  },
};
