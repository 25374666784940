import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import { Stack } from "@mui/system";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";

// HOC Imports
import CustomCheckBox from "../../base/custom-checkbox";
import { CustomTypography } from "../../base";
import CustomIcon from "../../icon-library";
import { CustomTableMultiSelectToolbar } from "../../mounts";

// Constants
import { staticTableDesigns } from "./design-constants";
import { TableSpaceFillers } from "./components";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
import { Typography } from "@mui/material";

const StaticTable = (props) => {
  // States
  const [state, setState] = useState({
    headers: [],
    data: [],
    selectionKey: "",
    selectedRow: "",
    selected: [],
    toolbar: false,
    loaded: false,
  });
  // Effects
  useEffect(() => {
    try {
      setState({
        ...state,
        headers: props?.headers || [],
        data: props?.data || [],
        selectionKey: props?.selectionKey || "",
        selectedRow: "",
        selected: props.selected || [],
        toolbar: props.selected?.length > 0 ? true : false,
        loaded: true,
      });
    } catch (err) {
      console.log("[ERROR] Mounting Static Table");
      console.log(err);
    }
  }, [props]);
  useEffect(() => {
    if (state?.selectedRow.length > 0) {
      props?.onSelectionClicked(state?.selectedRow);
    }
  }, [state.selectedRow]);

  // Trackers
  // useEffect(() => { console.log(state) }, [state])

  // Event Handlers
  const selectAllRows = () => {
    try {
      let selectionList = [];
      if (state?.selected.length !== state?.data.length) {
        selectionList = state?.data
          .filter((item) => item.disableSelection === false)
          .map((element) => element["mapping_id"]);
      }
      setState({
        ...state,
        selected: selectionList,
        toolbar: selectionList.length > 0 ? true : false,
      });
    } catch (err) {
      console.log(err);
      console.log("[ERROR] Selecting all rows");
    }
  };

  const selectRow = (id) => {
    try {
      console.log(id);
      let updatedSelectionList = [];
      if (state?.selected.includes(id) === false) {
        updatedSelectionList = state?.selected;
        updatedSelectionList.push(id);
      } else {
        updatedSelectionList = state?.selected.filter((item) => item !== id);
      }

      setState({
        ...state,
        selected: updatedSelectionList,
        toolbar: updatedSelectionList.length > 0 ? true : false,
      });
    } catch (err) {
      console.log(err);
      console.log("[ERROR] Selecting particular row");
    }
  };

  const deselectAllRows = () => {
    try {
      setState({
        ...state,
        selected: [],
        toolbar: false,
      });
    } catch (err) {
      console.log("[ERROR] Deselecting all rows");
      console.log(err);
    }
  };

  const handleToolbarDeleteBtnClick = () => {
    props.onDeleteClicked(state?.selected);
    // deselectAllRows();
  };

  // Renderer
  return (
    <React.Fragment>
      <ThemeProvider>
        {/* 
                ToDo Sticky Toasters that serves as action toasters for when rows are selected 
                The delete clickhandler must be absorbed from props.onDeleteClicked function
                
            */}
        <CustomTableMultiSelectToolbar
          open={state?.toolbar}
          selection={state?.selected.length}
          actionTitle="Delete"
          onClick={handleToolbarDeleteBtnClick}
        />
        <Box sx={staticTableDesigns["canvasBoxDesigns"]}>
          <Table sx={staticTableDesigns["tableDesigns"]}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={staticTableDesigns["tableCheckboxContainerDesign"]}
                >
                  <CustomCheckBox
                    varient="primary"
                    checked={
                      state?.selected.length === state?.data.length
                        ? state?.selected.length === 0
                          ? false
                          : true
                        : false
                    }
                    onChange={selectAllRows}
                  />
                </TableCell>

                {state?.headers.map((header) => {
                  return (
                    <TableCell
                      key={header?.key}
                      align="left"
                      sx={{
                        width: header["size"],
                        marginLeft: header?.spacing.left,
                        marginRight: header?.spacing.right,
                      }}
                    >
                      <Stack
                        direction={"row"}
                        spacing={"12px"}
                        alignItems={"center"}
                      >
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                              maxWidth: "200px",
                              overflowX: "clip",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {header?.label}
                          </Typography>
                        </Box>
                        {header.sortable === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              py: 0,
                            }}
                          >
                            {/* <Stack spacing={0}> */}
                            {/* <Box sx={{py: 0, height:'8px'}}> */}
                            <CustomIcon
                              sx={{ "&:hover": { cursor: "pointer" } }}
                              size={"sm"}
                              onClick={() => {}}
                              icon="arrow_up"
                              color={"#1C1E26"}
                            />
                            {/* </Box> */}
                            {/* <Box sx={{py: 0, height:'8px'}}> */}
                            <CustomIcon
                              sx={{ "&:hover": { cursor: "pointer" } }}
                              size={"sm"}
                              onClick={() => {}}
                              icon="arrow_down"
                              color={"#1C1E26"}
                            />
                            {/* </Box> */}
                            {/* </Stack> */}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.data.map((element) => {
                return (
                  <TableRow
                    key={element?.mapping_id}
                    align="left"
                    sx={{
                      backgroundColor:
                        state?.selected.includes(element?.mapping_id) === true
                          ? "#EBFCFE"
                          : "transparent",
                      ...staticTableDesigns["tableBodyRowDesign"],
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={staticTableDesigns["tableCheckboxContainerDesign"]}
                    >
                      <CustomCheckBox
                        varient="secondary"
                        checked={
                          state?.selected.includes(element?.mapping_id) === true
                            ? true
                            : false
                        }
                        disabled={element?.disableSelection}
                        onChange={() => {
                          selectRow(element?.mapping_id);
                        }}
                      />
                    </TableCell>
                    {state?.headers.map((header) => {
                      return (
                        <TableCell
                          key={`data-${header?.key}-${element?.map}`}
                          align={"left"}
                          sx={{
                            marginLeft: header?.spacing.left,
                            marginRight: header?.spacing.right,
                            width: header?.size,
                            display: "flex",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() =>
                            setState({
                              ...state,
                              selectedRow: element[`${state?.selectionKey}`],
                            })
                          }
                        >
                          <Typography
                            noWrap
                            variant="body-small"
                            sx={{
                              maxWidth: "200px",
                              overflowX: "clip",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {element[`${header.map}`]}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TableSpaceFillers />
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
};

StaticTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selectionKey: PropTypes.string.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  onSelectionClicked: PropTypes.func.isRequired,
  selected: PropTypes.array,
};
StaticTable.defaultProps = {
  headers: [],
  data: [],
  selectionKey: "",
  onDeleteClicked: () => {},
  onSelectionClicked: () => {},
  selected: [],
};

export default StaticTable;
