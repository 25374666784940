import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomTypography } from "../../../../../../../library/base";
import CustomIcon from "../../../../../../../library/icon-library";
import PropTypes from "prop-types";
import { getMspId } from "utilz/helpers";
import { CreateAttributeTile } from "./CreateAttributeTile";

const SERVICE_TYPE_CONSTANT = {
  type_field_name: "type_name",
  sub_type_field_name: "subtype_name",
};

export const ServiceSubTypeTile = ({
  isEditable,
  data,
  onEditClick,
  onSubmit,
  onDelete,
}: {
  isEditable: boolean;
  data: any;
  onEditClick: (edit: boolean) => void;
  onSubmit: (data: any) => void;
  onDelete: () => void;
}) => {
  return isEditable === true ? (
    <CreateAttributeTile
      name={"subtype_name"}
      value={data.subtype_name}
      onSubmit={onSubmit}
      onCancel={() => {
        onEditClick(false);
      }}
    />
  ) : (
    <Stack
      direction={"row"}
      sx={{
        width: "100%",
        height: "52px",
        backgroundColor: "#F3F5F9",
        borderBottom: "1px solid #cccccc",
        gap: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        pl: "32px",
        pr: "16px",
        py: "16px",
      }}
    >
      <Box
        sx={{
          flex: "1",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <CustomTypography
          content={data?.subtype_name || ""}
          size="EXTRA-SMALL"
          variant="REGULAR"
          color="TERITARY-MAIN"
        />
      </Box>

      {data?.subtype_msp_id === getMspId() ? (
        <Stack direction={"row"} gap={"16px"}>
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              onEditClick(true);
            }}
          >
            <CustomIcon icon="edit" size={"lg"} color="#767B85" />
          </Box>
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={onDelete}
          >
            <CustomIcon icon="delete" size={"lg"} color="#767B85" />
          </Box>
        </Stack>
      ) : null}
    </Stack>
  );
};
