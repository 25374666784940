import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { TemplateBillableToggleButton } from "../../../../../component";
import { CustomQuillEditor } from "../../../../../component/custom-quill";
import { GENERAL_FORM_CONSTANT } from "./constants";
import { validateFormDetails } from "../../Utils";

//temp dependencies

import { useGlobalStore } from "controller/store/globalStore";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Input, SelectField } from "components";
import { useGetAllBoards } from "controller";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonApiCalls } from "services/commonApiCalls";
import { toast } from "react-hot-toast";

const schema = yup.object({
  template_name: yup.string().required("please enter template name"),
  template_summary: yup.string().required("please enter template summary"),
  priority: yup.string().required("please select priority"),
  template_description: yup.string().required(""),
});

const GeneralInfoForm = forwardRef((props, ref) => {
  const mspId = useGlobalStore((state) => state.mspId);
  const { data: ticketBoardData } = useGetAllBoards({
    serviceType: "ticket_board",
  });

  const checklistRef = useRef();

  const [state, setState] = useState({
    data: {},
    isAttachmentModalopen: false,
    isChecklistModalOpen: false,
  });

  const {
    getValues,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      template_name: props.data[GENERAL_FORM_CONSTANT.template_name.name] || "",
      template_summary:
        props.data[GENERAL_FORM_CONSTANT.template_summary.name] || "",
      priority: props.data[GENERAL_FORM_CONSTANT.priority.name] || "",
      template_description:
        props.data[GENERAL_FORM_CONSTANT.template_description.name] || "",
    },
  });

  const watchAllFields = watch();

  useEffect(() => {
    setState({
      ...state,
      data: props.data,
    });
    reset({
      template_name: props.data[GENERAL_FORM_CONSTANT.template_name.name] || "",
      template_summary:
        props.data[GENERAL_FORM_CONSTANT.template_summary.name] || "",
      priority: props.data[GENERAL_FORM_CONSTANT.priority.name] || "",
      template_description:
        props.data[GENERAL_FORM_CONSTANT.template_description.name] || "",
    });
  }, [props.data]);

  ///event handlers

  const onChangeValueHandler = (data) => {
    var newData = state.data;
    newData[data.field] = data.value;
    setState({
      ...state,
      data: newData,
    });
  };

  useImperativeHandle(ref, () => {
    const submit = handleSubmit((data) => {
      const newData = {
        ...state.data,
        [GENERAL_FORM_CONSTANT.template_name.name]: data.template_name,
        [GENERAL_FORM_CONSTANT.template_summary.name]: data.template_summary,
        [GENERAL_FORM_CONSTANT.priority.name]: data.priority,
        [GENERAL_FORM_CONSTANT.template_description.name]:
          data.template_description,
      };
      console.log("TEmplate data", state.data, newData);
      var validate = validateFormDetails(newData, GENERAL_FORM_CONSTANT);
      if (validate === true) {
        props.onsubmit(newData);
        return true;
      }

      toast.error("invalid data");
      return false;
    });

    return { submit };
  });

  const types = ["critical", "high", "medium", "low"];

  return (
    <Stack width={"100%"} flex={1}>
      <Stack
        flex={1}
        sx={{
          overflowY: "scroll",
        }}
      >
        <Stack
          sx={{
            px: "32px",
            pt: "24px",
            pb: "16px",
            gap: "8px",
          }}
        >
          <Input
            label={GENERAL_FORM_CONSTANT.template_name.label}
            placeholder={GENERAL_FORM_CONSTANT.template_name.placeholder}
            name={"template_name"}
            changeHandler={onChangeValueHandler}
            required={true}
            control={control}
            error={errors?.template_name}
            errorMessage={errors?.template_name?.message}
          />
          <Input
            label={GENERAL_FORM_CONSTANT.template_summary.label}
            placeholder={GENERAL_FORM_CONSTANT.template_summary.placeholder}
            control={control}
            name={"template_summary"}
            changeHandler={onChangeValueHandler}
            required={true}
            maxLength={100}
            error={errors?.template_summary}
            errorMessage={errors?.template_summary?.message}
          />
          <Stack
            direction={"row"}
            gap={"16px"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                gap: "16px",
                width: "100%",
                maxWidth: "530px",
                alignItems: "center",
              }}
            >
              <SelectField
                label={GENERAL_FORM_CONSTANT.board.label}
                value={state.data[GENERAL_FORM_CONSTANT.board.name] || ""}
                name={GENERAL_FORM_CONSTANT.board.name}
                onValueChange={(e) =>
                  onChangeValueHandler({
                    field: GENERAL_FORM_CONSTANT.board.name,
                    value: e,
                  })
                }
                options={
                  Array.isArray(ticketBoardData?.result)
                    ? ticketBoardData?.result
                    : []
                }
                autoWidth={false}
                labelMappingKey="ticket_board_name"
                valueMappingKey="ticket_board_id"
              />

              <SelectField
                label={GENERAL_FORM_CONSTANT.priority.label}
                name={"priority"}
                options={
                  Array.isArray(commonApiCalls.getAllUrgencyTypes())
                    ? commonApiCalls.getAllUrgencyTypes()
                    : []
                }
                control={control}
                autoWidth={false}
                valueMappingKey="value"
                labelMappingKey="label"
                error={errors?.priority}
                errorMessage={errors?.priority?.message}
              />
            </Stack>
            <TemplateBillableToggleButton
              isChecked={
                state.data[GENERAL_FORM_CONSTANT.billable.name] || false
              }
              name={GENERAL_FORM_CONSTANT.billable.name}
              onChange={onChangeValueHandler}
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "200px",
            display: "block",
            flex: "1",
            flexGrow: "1",
            flexShrink: "1",
          }}
        >
          <CustomQuillEditor
            placeholder={GENERAL_FORM_CONSTANT.template_description.placeholder}
            value={watchAllFields.template_description}
            name={GENERAL_FORM_CONSTANT.template_description.name}
            onChange={(e) => setValue("template_description", e.value)}
            isRequired={true}
            error={errors?.template_description}
          />
        </Box>
      </Stack>
    </Stack>
  );
});

GeneralInfoForm.propTypes = {
  data: PropTypes.object.isRequired,
  onsubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
GeneralInfoForm.defaultProps = {
  data: {},
  onsubmit: () => {},
  onCancel: () => {},
};

export default GeneralInfoForm;
