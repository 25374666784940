import { useRef, useState } from "react";

import { Modal } from "components";
import { ResourceGroupDetails } from "./ResourceGroupDetails";
import { ResourceGroupMembers } from "./ResourceGroupMembers";
import toast from "react-hot-toast";
import {
  useCreateResourceGroup,
  useGlobalStore,
  useUpdateResourceGroup,
} from "controller";
interface EditResourceGroupModalProps {
  open: boolean;
  onClose: () => void;
  selectedResourceGroup?: any;
  isEdit?: boolean;
}
export const EditResourceGroupModal = ({
  open,
  onClose,
  selectedResourceGroup,
  isEdit = false,
}: EditResourceGroupModalProps) => {
  const [resourceGroup, setResourceGroup] = useState({
    group_description: selectedResourceGroup?.group_description || "",
    group_members: selectedResourceGroup?.group_members || [],
    group_name: selectedResourceGroup?.group_name || "",
    group_owner: selectedResourceGroup?.group_owner || "",
  });

  const mspId = useGlobalStore((state) => state.mspId);

  const [currentTab, setCurrentTab] = useState(0);

  const detailsRef = useRef<any>(null);

  const tabs = isEdit
    ? ["Edit Group", "Add Group Members"]
    : ["New Group", "Add Group Members"];

  const { mutateAsync: createResourceGroup, isLoading: isCreateLoading } =
    useCreateResourceGroup();
  const { mutateAsync: updateResourceGroup, isLoading: isUpdateLoading } =
    useUpdateResourceGroup();
  const createResourceGroupHandler = async (data: any) => {
    try {
      await createResourceGroup({ mspId, data });
      onClose();
      toast.success("Resource group is ready");
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to create at the moment, please try again later"
      );
    }
  };

  const updateResourceGroupById = async (
    resourceGroupId: string,
    data: any
  ) => {
    try {
      await updateResourceGroup({
        id: resourceGroupId,
        data,
      });
      onClose();
      toast.success("Resource group is updated");
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to update resource group .try again later"
      );
    }
  };

  const navigationHandler = (step: number) => {
    if (currentTab + step >= tabs.length) {
      validateData();
      return;
    }
    if (currentTab + step < 0) {
      onClose();
      return;
    }
    if (currentTab + step === 1) {
      return detailsRef?.current?.submit?.();
    }
    setCurrentTab(currentTab + step);
  };

  const resourceGroupDatValidator = (data: any) => {
    return Object.entries(data).every(([key, item]) => {
      return (
        key === "group_description" ||
        (Array.isArray(item) && item.length > 0) ||
        item !== ""
      );
    });
  };

  const validateData = () => {
    if (resourceGroup?.["group_members"].length <= 0) {
      toast.error("Require minimum one group member");
      return;
    }
    const newData = {
      ...resourceGroup,
      group_members: resourceGroup?.["group_members"].map(
        (item: any) => item.value
      ),
    };
    const validate = resourceGroupDatValidator(newData);
    if (validate === true) {
      if (isEdit) {
        selectedResourceGroup?.mapping_id
          ? updateResourceGroupById(selectedResourceGroup?.mapping_id, newData)
          : toast.error("Unable to update resource group .try again later");
        return;
      }
      createResourceGroupHandler(newData);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={tabs[currentTab]}
      primaryButtonText={currentTab !== 0 ? "Save" : "Next"}
      secondaryButtonText={currentTab !== 0 ? "Back" : "Cancel"}
      onSecondaryButtonClick={() => navigationHandler(-1)}
      onPrimaryButtonClick={() => navigationHandler(1)}
      isPrimaryButtonDisabled={isCreateLoading || isUpdateLoading}
    >
      {currentTab === 0 ? (
        <ResourceGroupDetails
          ref={detailsRef}
          data={resourceGroup}
          onSubmit={(newData) => {
            setResourceGroup((oldData: any) => ({
              ...oldData,
              ...newData,
            }));
            setCurrentTab(1);
          }}
        />
      ) : currentTab === 1 ? (
        <ResourceGroupMembers
          groupMembers={
            Array.isArray(resourceGroup?.group_members)
              ? resourceGroup?.group_members
              : []
          }
          setGroupMembers={(members: Array<any>) =>
            setResourceGroup((oldData: any) => ({
              ...oldData,
              group_members: members,
            }))
          }
        />
      ) : null}
    </Modal>
  );
};
