import { Box, IconButton, Menu, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { ConfigurationMenuItem, CustomDivider } from "../../../../Components";
import { FlagFilledIcon, FlagIcon, Input, Vertical3DotsIcon } from "components";
interface ChecklistModalItemProps {
  checklistItem: any;
  onRemoveAction: () => void;
  onDuplicateAction: () => void;
  onMoveAction: (isMoveUp: boolean) => void;
  onChange: (data: any) => void;
  totalItems: number;
  index: number;
}
export const NewChecklistModalItem = ({
  checklistItem,
  onChange,
  onDuplicateAction,
  onMoveAction,
  onRemoveAction,
  totalItems,
  index,
}: ChecklistModalItemProps) => {
  const anchorRef = useRef(null);
  const [state, setState] = useState({
    isOpen: false,
    isEdited: false,
  });

  function changeChecked(isSelected: boolean) {
    console.log(isSelected);
    onChange({
      ...checklistItem,
      is_important: isSelected,
    });
  }

  function handleClose() {
    setState({
      ...state,
      isOpen: false,
    });
  }

  const handleTextChange = (event: any) => {
    const currentdata = checklistItem;
    currentdata["checklist_item_name"] = event.target.value;
    setState({
      ...state,
      isEdited: true,
    });
    onChange(checklistItem);
  };

  return (
    <Box
      sx={{
        width: "100%",

        display: "grid",
        gridTemplateColumns: "auto 150px",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          maxWidth: "320px",
        }}
      >
        <Input
          name="checklist"
          placeholder="Type checklist here..."
          value={checklistItem.checklist_item_name || ""}
          onChange={handleTextChange}
          hideHelperBox
          fullWidth
        />
      </Box>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={"30px"}
      >
        <IconButton onClick={() => changeChecked(!checklistItem.is_important)}>
          {checklistItem.is_important ? (
            <FlagFilledIcon
              fontSize="small"
              color={checklistItem.is_important ? "error" : "inherit"}
            />
          ) : (
            <FlagIcon fontSize="small" />
          )}
        </IconButton>

        <Box
          ref={anchorRef}
          sx={{
            "& :hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            setState({
              ...state,
              isOpen: !state.isOpen,
            });
          }}
        >
          <Vertical3DotsIcon />
        </Box>

        <Menu
          id="basic-menu"
          anchorEl={state.isOpen ? anchorRef.current : null}
          open={state.isOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              gap: "1px",
              border: "1px solid #EDEDED",
              minWidth: 200,
              borderRadius: "8px",
              color: "black",
              boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
              "& .MuiMenu-list": {
                padding: "4px 0",
              },
              "&.MuiDivider-root": {
                borderColor: "#EDEDED",
                p: 0,
                m: 0,
              },
            },
          }}
        >
          <ConfigurationMenuItem
            content="Move item above"
            disabled={index === 0}
            onClick={() => {
              onMoveAction(true);
              handleClose();
            }}
          />
          <CustomDivider />

          <ConfigurationMenuItem
            content="Move item below"
            disabled={index === totalItems - 1}
            onClick={() => {
              onMoveAction(false);
              handleClose();
            }}
          />
          <CustomDivider />
          <ConfigurationMenuItem
            content="Duplicate"
            disabled={totalItems >= 20}
            onClick={() => {
              onDuplicateAction();
              handleClose();
            }}
          />
          <CustomDivider />
          <ConfigurationMenuItem
            content="Delete"
            onClick={() => {
              onRemoveAction();
              handleClose();
            }}
          />
        </Menu>
      </Stack>
    </Box>
  );
};
