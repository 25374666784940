import { Box, Stack } from "@mui/material";
import { CustomTypography } from "../../../../../../../library/base";
import CustomIcon from "../../../../../../../library/icon-library";
import { CustomCard } from "../../../../../../../library/layers";
import { useEffect, useState } from "react";
import { CustomToaster } from "../../../../../../../library/system";
import { services } from "../../../../../../../services";
import { useGlobalStore } from "controller/store/globalStore";
import { AttributeItemTile } from "./AttributeItemTile";
import { CreateAttributeTile } from "./CreateAttributeTile";
import {
  useCreateVariableAttributeValue,
  useUpdateVariableAttributeValue,
  useUpdatevariableAttribute,
} from "controller";
import toast from "react-hot-toast";
import { VariableAttributeItemTile } from "./VariableAttributeItemTile";
import { GetVariableAttributesInputProps } from "shared";

interface VariableAttributeContainerProps
  extends GetVariableAttributesInputProps {
  value: any;
}
export const VariableAttributeContainer = ({
  attributeName,
  value,
}: VariableAttributeContainerProps) => {
  const [state, setState] = useState({
    isCreateFieldOpen: false,
    isEdit: false,
  });

  const { mutateAsync: createVAriableAttributeValue } =
    useCreateVariableAttributeValue();

  const { mutateAsync: updateVariableAttribute } = useUpdatevariableAttribute();

  const createVariableAttributeValue = (data: any) => {
    if (value?.attribute_id) {
      createVAriableAttributeValue({
        attributeId: value?.attribute_id,
        attributeName,
        data,
      })
        .then(() => {
          toast.success("Created successfully");
          setState({
            isEdit: false,
            isCreateFieldOpen: false,
          });
        })
        .catch((error) => {
          toast.error("Unable to create");
        });
    }
  };

  const onUpdateVariableAttribute = (data: any) => {
    if (value?.attribute_id) {
      updateVariableAttribute({
        attributeId: value?.attribute_id,
        attributeName,
        data,
      })
        .then(() => {
          toast.success("Updated successfully");
          setState({
            isEdit: false,
            isCreateFieldOpen: false,
          });
        })
        .catch(() => {
          toast.error("Unable to update");
        });
    }
  };

  return (
    <Box>
      <CustomCard
        borderRadius="16px"
        content={
          <Stack
            sx={{
              position: "relative",
              width: "320px",
              height: "calc(100vh - 264px)",
            }}
          >
            {state.isEdit === true ? (
              <CreateAttributeTile
                name={"variable_attribute_name"}
                value={value?.variable_attribute_name || ""}
                onSubmit={onUpdateVariableAttribute}
                onCancel={() => {
                  setState({
                    ...state,
                    isEdit: false,
                  });
                }}
              />
            ) : (
              <Stack
                direction={"row"}
                sx={{
                  px: "16px",
                  py: "16px",
                  gap: "16px",
                  borderBottom: "1px solid #CCCCCC",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <CustomTypography
                    content={value?.variable_attribute_name || ""}
                    size={"SMALL-ALPHA"}
                    variant={"BOLD"}
                  />
                </Box>
                <Box
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setState({
                      ...state,
                      isEdit: true,
                    });
                  }}
                >
                  <CustomIcon icon="edit" size={"lg"} color="black" />
                </Box>
                <Box
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setState({
                      ...state,
                      isCreateFieldOpen: !state.isCreateFieldOpen,
                    });
                  }}
                >
                  <CustomIcon icon="plus" size={"xl"} color="black" />
                </Box>
              </Stack>
            )}

            <Stack
              sx={{
                width: "100%",
                height: "100%",
                overflow: "scroll",
              }}
            >
              {state.isCreateFieldOpen ? (
                <CreateAttributeTile
                  name={"variable_attribute_value_name"}
                  value=""
                  onSubmit={createVariableAttributeValue}
                  onCancel={() => {
                    setState({
                      ...state,
                      isCreateFieldOpen: false,
                    });
                  }}
                />
              ) : null}
              {(Array.isArray(value?.variable_attribute_values)
                ? value.variable_attribute_values
                : []
              ).map((item: any) => (
                <VariableAttributeItemTile
                  key={item.attribute_value_id}
                  value={item}
                  mappingId={item["attribute_value_id"]}
                  isEditable={true}
                  fieldName={"variable_attribute_value_name"}
                  attributeName={attributeName}
                />
              ))}
            </Stack>
          </Stack>
        }
      />
    </Box>
  );
};
