import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { default as MuiTab } from "@mui/material/Tab";
import { default as MuiTabs } from "@mui/material/Tabs";

import { QaRuleCard } from "./components/QaRuleCard";
import {
  assignedTickets,
  closedTickets,
  feedback,
  assignedProjects,
  closedProjects,
  assignedTasks,
  closedTasks,
} from "./components/qaRuleDataList";
import { useGetQaRules } from "controller/hooks/configurations/qa";
import { Tab, Tabs } from "components";

interface StyledTabProps {
  label: string;
}

// export const VTab = styled((props: StyledTabProps) => (
//   <MuiTab disableRipple {...props} />
// ))(({ theme }) => ({
//   textTransform: "capitalize",
//   textAlign: "left",
//   alignItems: "flex-start",
//   borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
//   paddingLeft: "32px",
//   ...theme.typography["body-medium"],
//   "&:hover": {
//     color: theme.palette.purple.main,
//   },
//   "&.Mui-selected": {
//     color: theme.palette.purple.main,
//     backgroundColor: "#E7E9FE",
//   },
//   color: theme.palette.lightBg.low,
// }));

const rulesArray = [
  {
    type: "ticket",
    title: "Tickets",

    rulesTypeArray: [
      {
        title: "Assigned Tickets",
        key: "assigned_ticket",
        rules: assignedTickets,
      },
      {
        title: "Closed Tickets",
        key: "closed_ticket",
        rules: closedTickets,
      },
      {
        title: "Feedback",
        key: "feedback",
        rules: feedback,
      },
    ],
  },
  {
    type: "project",
    title: "Projects",
    rulesTypeArray: [
      {
        title: "Assigned Projects",
        key: "assigned_project",
        rules: assignedProjects,
      },
      {
        title: "Closed Projects",
        key: "closed_project",
        rules: closedProjects,
      },
    ],
  },

  {
    type: "task",
    title: "Tasks",

    rulesTypeArray: [
      {
        title: "Assigned Tasks",
        key: "assigned_task",
        rules: assignedTasks,
      },
      {
        title: "Closed Tasks",
        key: "closed_task",
        rules: closedTasks,
      },
    ],
  },
];

export const QaRulesScreen = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [data, setData] = useState<any>(assignedTickets);
  const [currentRuleTypeIndex, setCurrentRuleTypeIndex] = useState(0);
  const { data: qaConfigData } = useGetQaRules();

  useEffect(() => {
    if (qaConfigData) {
      handleChange("", value);
    }
  }, [qaConfigData]);

  useEffect(() => {
    setData(rulesArray[currentRuleTypeIndex].rulesTypeArray[value].rules);
  }, [value, currentRuleTypeIndex]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleRuleTabChange = (event: any, newValue: number) => {
    setValue(0);
    setCurrentRuleTypeIndex(newValue);
  };

  return (
    <Stack
      sx={{
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box px="32px" py="22px">
        <Typography variant="h4">Quality Assurance Rules</Typography>
      </Box>
      <Stack
        // spacing={2}
        direction="row"
        flex={1}
        sx={{
          borderTop: `1px solid ${theme.palette.neutral["020"]}`,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            borderRight: `1px solid ${theme.palette.neutral["020"]}`,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentRuleTypeIndex}
            onChange={handleRuleTabChange}
          >
            {rulesArray.map((ruleType, index) => (
              <Tab
                key={`rule_type_${ruleType.type}`}
                label={ruleType.title}
                sx={{ ...theme.typography["body-medium"] }}
              />
            ))}
          </Tabs>
        </Box>
        <Stack sx={{ flex: 4, height: "100%" }}>
          <Tabs
            orientation="horizontal"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              px: "32px",
            }}
          >
            {rulesArray[currentRuleTypeIndex].rulesTypeArray.map(
              (ruleType, index) => (
                <Tab
                  key={`rule_type_sub_${ruleType.key}`}
                  label={ruleType.title}
                />
              )
            )}
          </Tabs>
          <Box
            flex={1}
            sx={{
              overflowY: "scroll",
            }}
          >
            <QaRuleCard
              data={data}
              currentConfig={
                qaConfigData?.[
                  rulesArray[currentRuleTypeIndex].rulesTypeArray[value].key
                ] || {}
              }
              currentTab={
                rulesArray[currentRuleTypeIndex].rulesTypeArray[value].key
              }
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
