import React, { useEffect, useState } from "react";

// Legacy Imports

import { Grid, Stack } from "@mui/material";


import { useGlobalStore } from "controller/store/globalStore";
import {
  convertUTCtoTimezone,
  dateTimeToUtc,
  dateTimeToUtcV2,
} from "utilz/helpers";
import dayjs from "dayjs";
import { v4 } from "uuid";
import { AddRemoveIconButton } from "./AddRemoveIconButton";
import { DateInput, Input } from "components";

// Constant styles
const customStyles = {
  "& .MuiOutlinedInput-root": {
    width: "100%",
    "& > fieldset": { borderColor: "#CCCCCC", borderRadius: "8px" },
    "& input::placeholder": { fontSize: "14px" },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: "#1C1E26",
    "& > fieldset": { borderColor: "#5563F5" },
  },
  "& label": { "&.Mui-focused": { color: "#5563F5" } },
};

interface ExperienceComponentProps {
  experienceData: any;
  onDataChange: (data: any) => void;
  onDelete: () => void;
  isLastElement: boolean;
  validate: boolean;
}

export const ExperienceComponent = ({
  experienceData,
  isLastElement,
  onDataChange,
  onDelete,
  validate = false,
}: ExperienceComponentProps) => {
  const timezone = useGlobalStore((state) => state.timezone);

  const [errors, setErrors] = useState({
    company: false,
    role: false,
    startDate: false,
    endDate: false,
  });

  const onDateChangeHandler = (start?: string, end?: string) => {
    if (start !== "" && start !== null && end !== null && end !== "") {
      return dayjs(end).diff(dayjs(start)) >= 0;
    }
    return true;
  };

  useEffect(() => {
    if (validate) {
      const isDateValid = onDateChangeHandler(
        experienceData?.start_date,
        experienceData?.end_date
      );
      setErrors({
        company: !(
          experienceData?.company &&
          experienceData?.company !== null &&
          experienceData?.company !== ""
        ),
        role: !(
          experienceData?.job_role &&
          experienceData?.job_role !== null &&
          experienceData?.job_role !== ""
        ),
        endDate: !(
          experienceData?.end_date &&
          experienceData?.end_date !== null &&
          experienceData?.end_date !== "" &&
          isDateValid
        ),
        startDate: !(
          experienceData?.start_date &&
          experienceData?.start_date !== null &&
          experienceData?.start_date !== "" &&
          isDateValid
        ),
      });
    } else {
      setErrors({
        company: false,
        role: false,
        startDate: false,
        endDate: false,
      });
    }
  }, [validate, experienceData]);

  const onValueChange = (key: string, value: any) => {
    onDataChange({
      ...experienceData,
      [key]: value,
    });
  };

  // rendere
  return (
    <Stack direction={"row"} gap={"32px"} justifyContent={"space-between"}>
      <Grid
        container
        flex={1}
        maxWidth={"646px"}
        columnSpacing={"32px"}
        rowSpacing={"32px"}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Input
            name="company"
            label={"Company"}
            fullWidth
            value={experienceData?.company}
            onChange={(event) => onValueChange("company", event.target.value)}
            error={validate && errors.company}
            errorMessage={
              validate && errors.company
                ? "Company name is required"
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Input
            name="role"
            label={"Role"}
            fullWidth
            value={experienceData?.job_role}
            onChange={(event) => onValueChange("job_role", event.target.value)}
            error={validate && errors.role}
            errorMessage={
              validate && errors.role ? "Role is required" : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DateInput
            disableFuture
            label="Start date"
            sx={{
              width: "100%",
            }}
            value={convertUTCtoTimezone(
              `${experienceData.start_date}`,
              timezone
            )}
            onChangeHandler={(e) => {
              if (
                onDateChangeHandler(
                  dateTimeToUtc(e, timezone),
                  experienceData.end_date
                )
              ) {
                onValueChange("start_date", dateTimeToUtcV2(e, timezone));
              }
            }}
            error={errors.startDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DateInput
            disableFuture
            disabled={
              experienceData.start_date === "" ||
              experienceData.start_date === null
            }
            label="End date"
            sx={{
              width: "100%",
            }}
            minDate={convertUTCtoTimezone(
              `${experienceData.start_date}`,
              timezone
            )}
            value={convertUTCtoTimezone(`${experienceData.end_date}`, timezone)}
            onChangeHandler={(e) => {
              if (
                onDateChangeHandler(
                  experienceData.start_date,
                  dateTimeToUtc(e, timezone)
                )
              ) {
                onValueChange("end_date", dateTimeToUtcV2(e, timezone));
              }
            }}
            error={errors.endDate}
          />
        </Grid>
      </Grid>

      {isLastElement &&
      (experienceData.company === "" || experienceData.company === null) &&
      (experienceData.job_role === "" || experienceData.job_role === null) &&
      (experienceData.start_date === "" ||
        experienceData.start_date === null) &&
      (experienceData.end_date === "" ||
        experienceData.end_date === null) ? null : (
        <AddRemoveIconButton isDelete onClick={onDelete} />
      )}
    </Stack>
  );
};
