import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addAttributetag,
  deleteAttributeTags,
  getAttributeTags,
  updateAttributetag,
} from "controller/api";
import { ApiErrorType, GetQueryProps, UpdateAttributeTagInput } from "shared";
import { sentryLogger } from "utilz";
import { debugConsole } from "utilz/helpers";

export const useGetAttributeTags = (data: GetQueryProps) => {
  return useQuery({
    queryKey: ["attributeTags"],
    queryFn: () => getAttributeTags(data),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAttributeTags",
      }),
  });
};

export const useCreateAttributeTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (value) => addAttributetag(value),
    onSuccess: () => {
      queryClient.invalidateQueries(["attributeTags"]);
    },
    onError: (error: ApiErrorType, variables, context) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "addAttributetag",
      }),
  });
};

export const useUpdateAttributeTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: UpdateAttributeTagInput) => updateAttributetag(values),
    onSuccess: () => {
      queryClient.invalidateQueries(["attributeTags"]);
    },
    onError: (error: ApiErrorType, variables, context) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateAttributetag",
      }),
  });
};

export const useDeleteAttributeTags = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteAttributeTags,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["attributeTags"]);
    },
    onError: (error: ApiErrorType, variables, context) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteAttributeTags",
      }),
  });
};
