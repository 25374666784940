import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Vertical3DotsIcon } from "components";
import { ReactNode } from "react";

interface ChartLayoutBoxProps {
  children: ReactNode;
  title: string;
  subtitle: string;
  isLoading?: boolean;
  leagendItems?: Array<any>;
}

export const HomeChartLayoutBox = ({
  children,
  subtitle,
  title,
  isLoading,
  leagendItems,
}: ChartLayoutBoxProps) => {
  function add3Dots(value: string) {
    const dots = "...";
    if (value.length > 10) {
      value = value.substring(0, 10) + dots;
    }

    return value;
  }
  const theme = useTheme();
  return (
    <Stack
      sx={{
        height: "354px",
        flex: "1",
        minWidth: "max(30%, 300px)",
        maxWidth: "100%",
        bgcolor: "white",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.neutral["015"]}`,
        py: "16px",
        gap: "16px",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          px: "16px",
          alignItems: "center",
          // height: "48px",
        }}
        gap={"8px"}
      >
        <Typography
          variant="h6"
          flex={1}
          sx={{
            overflow: "Hidden",
            textOverflow: "ellipsis",
            display: "-webkit-boxHidden",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "block-axis",
          }}
        >
          {title}
        </Typography>
        <Typography variant="body-small">{subtitle}</Typography>
      </Stack>
      <Divider flexItem sx={{ bgcolor: theme.palette.neutral["005"] }} />
      <Box flex={1} width={"100%"} px={"16px"}>
        {children}
      </Box>
    </Stack>
  );
};
