import { fetchCall } from "../../../utilz/fetch-call";
import { appConfig } from "../../../constants";

// Upload MSP-LOGO
const uploadMspLogoByMspId = (id, data)=>
  fetchCall(
    `/controlCenter/msp_logo/upload/${id}`,
    appConfig?.requestMethods["POST"],
    data,
    {},
    true
  );

// Update MSP-LOGO
const updateMspLogoByMspId = (id, data)=>
  fetchCall(
    `/controlCenter/msp_logo/update/${id}`,
    appConfig?.requestMethods["PATCH"],
    data,
    {},
    true
  );

// Deleting MSP-LOGO
const deleteMspLogoByMspId = (id)=> 
  fetchCall(
    `/controlCenter/msp_logo/delete/${id}`,
    appConfig?.requestMethods["DELETE"],
    {},
    {},
    false
  );

// Uploading Msp Wordmark
const uploadMspWordmarkByMspId = (id, data)=>
  fetchCall(
    `/controlCenter/msp_wordmark/upload/${id}`,
    appConfig?.requestMethods["POST"],
    data,
    {},
    true
  );

// Updatimg Wordmark by MspId
const updateMspWordmarkByMspId = (id, data)=>
  fetchCall(
    `/controlCenter/msp_wordmark/update/${id}`,
    appConfig?.requestMethods["PATCH"],
    data,
    {},
    true
  );

// Deleting Msp Wordmark
const deleteMspWordmarkByMspId = (id)=>
  fetchCall(
    `/controlCenter/msp_wordmark/delete/${id}`,
    appConfig?.requestMethods["DELETE"],
    {},
    {},
    false
  );

export const whiteLabelServices = {
  uploadMspLogoByMspId,
  updateMspLogoByMspId,
  deleteMspLogoByMspId,
  uploadMspWordmarkByMspId,
  updateMspWordmarkByMspId,
  deleteMspWordmarkByMspId
};