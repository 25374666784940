import React, { useContext, useEffect, useState } from "react";
import Router from "./router";
import "./App.css";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import toast, { Toast, ToastBar, Toaster } from "react-hot-toast";

import { CloseIcon, ErrorFallbackComponent } from "components";
import { MainWrapper } from "layouts";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
import { Sentry, mixpanel } from "utilz";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  return (
    <ThemeProvider>
      <React.Fragment>
        <>
          <QueryClientProvider client={queryClient}>
            <div
              className="app"
              style={{
                position: "relative",
                minWidth: "100vw",
                minHeight: "100vh",
              }}
            >
              <Sentry.ErrorBoundary
                fallback={({ error }) => (
                  <ErrorFallbackComponent error={error} />
                )}
              >
                <Router />
              </Sentry.ErrorBoundary>
            </div>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
          <Toaster
            position="top-right"
            toastOptions={{
              style: {
                backgroundColor: "#10172E",
                borderRadius: "100px",
                minWidth: "300px",
                maxWidth: "500px",
                color: "#FFF",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between !important",
                minHeight: "48px",
              },
              success: {
                duration: 3000,
                style: {
                  border: "1px solid #198038",
                },
              },
              error: {
                duration: 3000,
                style: {
                  border: "1px solid #DA1E28",
                },
              },
              custom: {
                duration: 3000,

                style: {
                  border: "1px solid #F1C21B",
                },
              },
            }}
            // eslint-disable-next-line react/no-children-prop
            children={(t) => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "24px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div aria-label="Close Notification">{icon}</div>
                      {message}
                    </div>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => toast.dismiss(t.id)}
                      htmlColor="#A5A5A6"
                    />
                  </div>
                )}
              </ToastBar>
            )}
          ></Toaster>
        </>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
