import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createBoard,
  deleteBoards,
  getAllBoard,
  getBoardById,
  updateBoard,
} from "controller/api";

import {
  GetBoardByIdInput,
  GetBoardInput,
  GetQueryProps,
  CreateBoardInput,
  UpdateBoardInput,
  ApiErrorType,
} from "shared";
import { sentryLogger } from "utilz";
import { debugConsole } from "utilz/helpers";

export const useGetAllBoard = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
  boardType,
  mspId,
}: GetBoardInput) => {
  return useQuery({
    queryKey: ["boards", boardType, mspId, searchQuery],
    enabled: !!mspId,
    queryFn: () =>
      getAllBoard({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
        boardType,
        mspId,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllBoard",
      }),
  });
};

export const useGetBoardById = ({ boardId, boardType }: GetBoardByIdInput) => {
  return useQuery({
    enabled: !!boardId && boardId !== null,
    queryKey: ["boards", boardType, boardId],
    queryFn: () => getBoardById({ boardId, boardType }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getBoardById",
      }),
  });
};

export const useCreateBoard = () => {
  const queryClient = useQueryClient();
  return useMutation((values: CreateBoardInput) => createBoard(values), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["boards", variables.boardType]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "createBoard",
      });
    },
  });
};

export const useUpdateBoard = () => {
  const queryClient = useQueryClient();
  return useMutation((values: UpdateBoardInput) => updateBoard(values), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["boards"]);
      queryClient.invalidateQueries(["boards", variables.boardType]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateBoard",
      });
    },
  });
};

export const useDeleteBoard = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteBoards,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["boards", variables.boardType]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteBoards",
      });
    },
  });
};
