import { Box, Stack } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CustomTypography } from "../../../../../../../../library/base";
import { CustomCard } from "../../../../../../../../library/layers";
import React from "react";
import {
  CustomRadioGroup,
  CustomSelectField,
} from "../../../../../../../../library/system/custom-form";
import PropTypes from "prop-types";
import { formField, formFieldTypes } from "../../constants";
import { v4 } from "uuid";

import { useGlobalStore } from "controller/store/globalStore";

const ServicedeskGeneralForm = forwardRef((props, ref) => {
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    formData: formField,
  });

  useEffect(() => {
    // console.log("CHANGED");
    // console.log(props.data);
    setState({
      ...state,
      formData: {
        ...state.formData,
        is_time_format_is_12_hr: {
          ...state.formData.is_time_format_is_12_hr,
          key: "configyraion_general_is_time_format_is_12_hr",
          value: props.data?.is_time_format_is_12_hr === true,
        },
        is_time_zone_is_resources: {
          ...state.formData.is_time_zone_is_resources,
          key: "configuration_general_is_time_zone_is_resources",
          value: props.data?.is_time_zone_is_resources === true,
        },
        // default_font_for_tickets: { ...state.formData.default_font_for_tickets, key: v4(), value: props.data?.default_font_for_tickets || formField.default_font_for_tickets.getData()[0].value || '' },
        // default_font_size_for_tickets: { ...state.formData.default_font_size_for_tickets, key: v4(), value: props.data?.default_font_size_for_tickets || formField.default_font_size_for_tickets.getData()[0].value || '' },
        default_service_board_for_tickets: {
          ...state.formData.default_service_board_for_tickets,
          key: "configuration_general_default_service_board_for_tickets",
          value: props.data?.ticket_board_id || "",
          trigger: [mspId],
        },
        //  default_service_board_for_request: { ...state.formData.default_service_board_for_request, key: v4(), value: props.data?.request_board_id || '', trigger: [mspId] },
        //  default_service_board_for_projects: { ...state.formData.default_service_board_for_projects, key: v4(), value: props.data?.project_board_id || '', trigger: [mspId] },
      },
    });
    // console.log(props.data);
  }, [props.data]);

  useImperativeHandle(
    ref,
    () => {
      return {
        submit() {
          const data = {};
          Object.values(formField).forEach((element) => {
            data[`${element.map}`] = state.formData[`${element.map}`].value;
          });
          return data;
        },
      };
    },
    [state]
  );

  const handleRadioGroup = (data) => {
    const currentForm = state.formData;
    currentForm[`${data.name}`] = {
      ...currentForm[`${data.name}`],
      value:
        data.value === currentForm[`${data.name}`].radios[0].value
          ? true
          : false,
    };
    setState({
      ...state,
      formData: currentForm,
    });
    props.onChange();
  };

  const handleSelectField = (data) => {
    const currentForm = state.formData;
    currentForm[`${data.field}`] = {
      ...currentForm[`${data.field}`],
      value: data.value || "",
    };
    setState({
      ...state,
      formData: currentForm,
    });
    props.onChange();
  };

  return (
    <CustomCard
      content={
        <Box
          sx={{
            width: "100%",
          }}
        >
          {Object.values(state.formData).map(
            (item) =>
              item.show === true && (
                <Stack
                  key={`service_desk_general_form_Key_${item.map}`}
                  padding={"24px"}
                  gap={"12px"}
                  direction={
                    item.TYPE === formFieldTypes.RADIO_GROUP ? "column" : "row"
                  }
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems:
                      item.TYPE === formFieldTypes.RADIO_GROUP
                        ? "start"
                        : "center",
                    borderBottom:
                      item.divide === true ? "1px solid #E3E3E3" : "",
                  }}
                >
                  <CustomTypography
                    content={item.label}
                    size="SMALL-BETA"
                    variant="REGULAR"
                  />
                  <Box>
                    {
                      item.TYPE === formFieldTypes.RADIO_GROUP ? (
                        <CustomRadioGroup
                          key={item.key}
                          name={item.map || ""}
                          typographyProp={{
                            color: "TERITARY-MAIN",
                            size: "EXTRA-SMALL",
                          }}
                          options={item.radios.map((radio) => ({
                            key: radio.key,
                            value: radio.value,
                            disabled: false,
                            label: radio.label,
                          }))}
                          value={
                            item?.value === true
                              ? item.radios[0].value
                              : item.radios[1].value
                          }
                          handle={handleRadioGroup}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: "170px",
                          }}
                        >
                          <CustomSelectField
                            key={item?.key}
                            name={item?.map || ""}
                            label={""}
                            value={item?.value.toString() || ""}
                            multi={false}
                            size={"small"}
                            placeholder={item?.placeholder}
                            disabled={false}
                            dependency={item?.dependency}
                            triggerValue={[mspId]}
                            populator={item.getData}
                            changeHandler={handleSelectField}
                          />
                        </Box>
                      )
                      // <></>
                    }
                  </Box>
                </Stack>
              )
          )}
        </Box>
      }
    />
  );
});

ServicedeskGeneralForm.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

ServicedeskGeneralForm.defaultProps = {
  data: {},
  onChange: () => {},
};

export default ServicedeskGeneralForm;
