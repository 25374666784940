import React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
// import { CustomTypography } from "../../base";

interface ActionModalProps extends DialogProps {
  selectedCount?: number;
  actionContent?: React.ReactNode;
}
export const ActionModal = ({
  open,
  onClose,
  selectedCount,
  actionContent,
}: ActionModalProps) => {
  return (
    <Dialog
      //   key={state?.render}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      // disableEnforceFocus // Let the user focus on elements outside the dialog
      style={{ position: "initial" }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
      // maxWidth="sm"
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          backgroundColor: "#0000",
          position: "absolute",
          width: "80%",
          // left: "250px",
          left: "10%",
          right: "10%",
          bottom: 0,

          boxShadow: "none",
        },
      }}
    >
      <DialogContent
        sx={{
          width: "100%",
          borderRadius: "16px",
          backgroundColor: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        <Box>
          {selectedCount && (
            <Typography
              variant="body-large"
              color={"#fff"}
            >{`${selectedCount} ${
              selectedCount == 1 ? "item" : "items"
            } selected`}</Typography>
          )}
        </Box>
        {/* <CustomButton /> */}

        {actionContent && actionContent}
      </DialogContent>
    </Dialog>
  );
};
