import { Box, Stack, Typography, useTheme } from "@mui/material";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import happy from "assets/lottie/happy.json";
import satisfied from "assets/lottie/satisfied.json";
import awesome from "assets/lottie/awesome.json";
import dissapointed from "assets/lottie/dissapointed.json";
import needToImprove from "assets/lottie/needToImprove.json";

export const FeedbackCard = ({
  feedback,
  rating,
}: {
  feedback?: string | null;
  rating?: number | null;
}) => {
  const [ratingText, setRatingText] = useState("");
  const theme = useTheme();

  useEffect(() => {
    if (rating) {
      getRatingText(rating);
    }
  }, [rating]);

  const getRatingText = (rating: number) => {
    let value = "Awesome";
    switch (rating) {
    case 1:
      value = "Disappointed";
      break;
    case 2:
      value = "Need To Improve";
      break;
    case 3:
      value = "Satisfied";
      break;
    case 4:
      value = "Good";
      break;
    case 5:
      value = "Awesome";
      break;

    default:
      value = "Awesome";
      break;
    }
    setRatingText(value);
  };

  if (!rating) {
    return <></>;
  }
  return (
    <Stack
      sx={{
        width: "100%",
        height: "386px",
        mt: "20px",
        borderRadius: "16px",
        bgcolor: "#FFF",
        alignItems: "center",
        gap: "16px",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Typography variant="h5" textAlign="center">
        Customer Rating
      </Typography>
      <Box width="184px" height="184px">
        <LottieComponent
          width="184px"
          height="184px"
          //   animationData={getRatingText("file")}
          loop={true}
          rating={rating}
        />
      </Box>
      <Typography
        variant="h4"
        color={
          rating > 2 ? theme.palette.success.main : theme.palette.error.main
        }
      >
        {ratingText}
      </Typography>
      <Typography variant="body-medium">{feedback}</Typography>
    </Stack>
  );
};

const LottieComponent = ({ rating, ...rest }: any) => {
  switch (rating) {
  case 1:
    return <Lottie animationData={dissapointed} loop={true} />;
  case 2:
    return <Lottie animationData={needToImprove} loop={true} />;
  case 3:
    return <Lottie animationData={satisfied} loop={true} />;
  case 4:
    return <Lottie animationData={happy} loop={true} />;
  case 5:
    return <Lottie animationData={awesome} loop={true} />;

  default:
    return <Lottie animationData={awesome} loop={true} />;
  }
};
