import React from "react";
import Toolbar, { ToolbarProps } from "@mui/material/Toolbar";
import { SxProps, Theme, useTheme } from "@mui/material";

interface CustomToolbarProps extends ToolbarProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}
export const TableToolbarRoot = ({
  children,
  sx,
  ...rest
}: CustomToolbarProps) => {
  const theme = useTheme();
  return (
    <Toolbar
      disableGutters
      sx={{
        minHeight: "48px !important",
        backgroundColor: "red",
        borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Toolbar>
  );
};
