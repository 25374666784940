
function settingsIcon(props) {

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90007 15.647L5.29807 15.861C6.33007 16.019 7.06707 16.943 6.99107 17.984L6.88807 19.395C6.85807 19.806 7.08207 20.193 7.45307 20.372L8.48707 20.87C8.85807 21.049 9.30107 20.982 9.60407 20.703L10.6431 19.743C11.4091 19.035 12.5911 19.035 13.3581 19.743L14.3971 20.703C14.7001 20.983 15.1421 21.049 15.5141 20.87L16.5501 20.371C16.9201 20.193 17.1431 19.807 17.1131 19.397L17.0101 17.984C16.9341 16.943 17.6711 16.019 18.7031 15.861L20.1011 15.647C20.5081 15.585 20.8361 15.28 20.9281 14.878L21.1831 13.76C21.2751 13.358 21.1121 12.941 20.7721 12.709L19.6051 11.91C18.7441 11.32 18.4811 10.168 19.0011 9.26302L19.7061 8.03702C19.9111 7.68002 19.8771 7.23302 19.6201 6.91102L18.9051 6.01402C18.6481 5.69202 18.2201 5.55902 17.8261 5.68002L16.4741 6.09402C15.4751 6.40002 14.4101 5.88702 14.0261 4.91602L13.5081 3.60302C13.3561 3.21902 12.9851 2.96702 12.5721 2.96802L11.4261 2.97102C11.0131 2.97202 10.6431 3.22602 10.4931 3.61102L9.98807 4.90902C9.60807 5.88602 8.53807 6.40302 7.53607 6.09502L6.12807 5.66302C5.73307 5.54102 5.30307 5.67502 5.04607 5.99902L4.33607 6.89702C4.07907 7.22202 4.04807 7.67002 4.25707 8.02702L4.97807 9.25602C5.50907 10.162 5.24907 11.325 4.38307 11.918L3.23007 12.708C2.89007 12.941 2.72707 13.358 2.81907 13.759L3.07407 14.877C3.16507 15.28 3.49307 15.585 3.90007 15.647V15.647Z"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M13.916 10.084C14.974 11.142 14.974 12.858 13.916 13.916C12.858 14.974 11.142 14.974 10.084 13.916C9.02603 12.858 9.02603 11.142 10.084 10.084C11.142 9.02603 12.858 9.02603 13.916 10.084"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

export default settingsIcon;