import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ConfigurationTable } from "../../../Components";
import TabHeadComponent from "../component/tab-head-component";
import TemplateTableTile from "../component/template-table-tile";
import { Table_Model } from "../constants";
import { services } from "../../../../../../../../services";
//temp import
import { useGlobalStore } from "controller/store/globalStore";
import toast from "react-hot-toast";
import { BottomStickyLayer } from "library/layers";
import { PaginationControls } from "library/mounts";
import { DeleteConfirmationModal } from "components";
import {
  useCreateProjectTemplate,
  useGetAllProjectTemplates,
  useGetProjectTemplatedByIdUsingMutation,
  useUpdateProjectTemplate,
} from "controller";
import { CreateProjectTemplateModal } from "./component";
import {
  convertDoubletoHourAndMinutes,
  convertToHours,
} from "utilz/helpers/formatTimeDuration";
import { debugConsole } from "utilz/helpers";
export const ProjectTemplateTab = () => {
  const mspId = useGlobalStore((state) => state.mspId);

  const [modalData, setModalData] = useState({
    isModalOpen: false,
    selectedData: {},
  });

  const [pagination, setPagination] = useState({ currentPage: 1 });

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    data: null,
  });

  const { data: projectTemplateData, refetch } = useGetAllProjectTemplates({
    pageNumber: pagination.currentPage,
    sortColumn: "template_name",
    sortDirection: "asc",
    perPageCount: 10,
    searchQuery: searchQuery,
    filter: [],
  });

  useEffect(() => {
    refetch();
  }, [pagination]);

  const { mutateAsync: createProjectTemplate } = useCreateProjectTemplate();
  const { mutateAsync: getProjectTemplateById } =
    useGetProjectTemplatedByIdUsingMutation();
  const { mutateAsync: updateProjectTemplate } = useUpdateProjectTemplate();
  // const { mutateAsync: deleteProjectTemplate } = useDeleteProjectTemplate();

  const onSubmitHandler = (data: any) => {
    setIsLoading(true);
    try {
      data = {
        ...data,
        budgeted_hours:
          convertDoubletoHourAndMinutes(data.budgeted_hours, "colons") ||
          "00:00:00",
      };
    } catch (e) {
      debugConsole(
        "🚀 ~ file: ProjectTemplateTab.tsx:72 ~ onSubmitHandler ~ e:",
        e
      );
    }
    if (data["mapping_id"] === undefined) {
      createProjectTemplate(data)
        .then(() => {
          setModalData({
            selectedData: {},
            isModalOpen: false,
          });
          setIsLoading(false);
          toast.success("Created Successfully");
        })
        .catch(() => {
          setIsLoading(false);
          toast.error("Unable to create at the moment");
        });
      return;
    }
    const { mapping_id, ...templateData } = data;
    updateProjectTemplate({ templateId: mapping_id, data: templateData })
      .then(() => {
        setModalData({
          selectedData: {},
          isModalOpen: false,
        });
        setIsLoading(false);
        toast.success("Updated Successfully");
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Unable to update at the moment");
      });
  };

  const onEditHandler = (templateId: string) => {
    getProjectTemplateById(templateId)
      .then((res) => {
        setModalData({
          selectedData: {
            ...res,
            milestones: res?.milestoneTaskData || [],
            budgeted_hours: convertToHours(res?.budgeted_hours || {}),
          },
          isModalOpen: true,
        });
      })
      .catch(() => {
        toast.error("Unable to fetch data");
      });
  };

  const onDeleteHandler = (templateId: any) => {
    setDeleteModalState({
      open: true,
      data: templateId,
    });
  };

  const onDeleteConfirmed = () => {
    // const templateId = deleteModalState.data;
    // setDeleteModalState({
    //   open: false,
    //   data: null,
    // });
    // if (templateId !== null) {
    //   deleteProjectTemplate(templateId)
    //     .then((res) => {
    //       toast.success("Deleted Successfully");
    //     })
    //     .catch(() => {
    //       toast.error("Unable to fetch data");
    //     });
    // }
  };

  return (
    <Stack gap={"32px"}>
      {deleteModalState.open && (
        <DeleteConfirmationModal
          open={deleteModalState.open}
          onClose={() =>
            setDeleteModalState({
              open: false,
              data: null,
            })
          }
          onSecondaryButtonClick={() =>
            setDeleteModalState({
              open: false,
              data: null,
            })
          }
          onPrimaryButtonClick={onDeleteConfirmed}
        />
      )}
      {modalData.isModalOpen ? (
        <CreateProjectTemplateModal
          isProjectModalOpen={modalData.isModalOpen}
          data={modalData.selectedData}
          isLoading={isLoading}
          isEdit={Object.entries(modalData.selectedData).length > 0}
          onClose={() => {
            setModalData({
              selectedData: {},
              isModalOpen: false,
            });
          }}
          onSubmit={onSubmitHandler}
        />
      ) : null}

      <TabHeadComponent
        createNewHandler={() => {
          setModalData({
            selectedData: {},
            isModalOpen: true,
          });
        }}
        data={[searchQuery, setSearchQuery]}
        updateSearchHandler={
          (searchValue) => {}
          // fetchAllTicketTemplates(1, searchValue)
        }
      />
      <Box pb={"24px"}>
        <ConfigurationTable
          header={Table_Model}
          body={(Array.isArray(projectTemplateData?.result)
            ? projectTemplateData.result
            : []
          ).map((item: any, index: number) => (
            <TemplateTableTile
              key={`configuration_template_ticket_item_${item.mapping_id}`}
              value={item}
              onEditClicked={() => onEditHandler(item.mapping_id)}
              onDeleteClicked={() => onDeleteHandler(item.mapping_id)}
            />
          ))}
        />
      </Box>
      <BottomStickyLayer
        content={
          <PaginationControls
            currentPage={pagination.currentPage}
            totalPages={projectTemplateData?.totalPages || 1}
            getNewPage={(newPageNumber: number) =>
              setPagination({ ...pagination, currentPage: newPageNumber })
            }
          />
        }
      />
    </Stack>
  );
};
