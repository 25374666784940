import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// HOC Imports
import { CustomTextField, CustomSelectField } from "../../../../../../../library/system/custom-form";

// Constants Imports
import { formFields } from "./constants";

const BillingDetailsForm = forwardRef((props, ref) => {
  // State
  const [state, setState] = useState({
    formData: formFields,
    enableForm: { key: "", status: false },
  });


  // Imperative Handle Configs
  useImperativeHandle(ref, () => {
    return {
      submit() {
        const data = {};
        Object.values(formFields).forEach((element) => {
          data[`${element.map}`] = state.formData[`${element.map}`].value;
        });
        setState({
          ...state,
          enableForm: { ...state.enableForm, key: v4() },
          formData: {
            ...state?.formData,
            billing_name: { ...state?.formData?.billing_name, key: v4() },
            billing_floor: { ...state?.formData?.billing_floor, key: v4() },
            billing_building_name: { ...state?.formData?.billing_building_name },
            billing_city: { ...state?.formData?.billing_city, key: v4() },
            billing_state: { ...state?.formData?.billing_state, key: v4() },
            billing_country: { ...state?.formData?.billing_country, key: v4() },
            billing_postal_code: { ...state?.formData?.billing_postal_code, key: v4() },
          },
        });
        return data;

      }
    };
  }, [state]);


  // Effects

  useEffect(() => {
    console.log(props);
    try {
      setState({
        ...state,
        formData: {
          ...state?.formData,
          billing_name: { ...state?.formData?.billing_name, key: v4(), value: props?.data?.billing_name || "" },
          billing_floor: { ...state?.formData?.billing_floor, key: v4(), value: props?.data?.billing_floor || "" },
          billing_building_name: { ...state?.formData?.billing_building_name, key: v4(), value: props?.data?.billing_building_name || "" },
          billing_city: { ...state?.formData?.billing_city, key: v4(), value: props?.data?.billing_city || "" },
          billing_state: { ...state?.formData?.billing_state, key: v4(), value: props?.data?.billing_state || "" },
          billing_country: { ...state?.formData?.billing_country, key: v4(), value: props?.data?.billing_country || "" },
          billing_postal_code: { ...state?.formData?.billing_postal_code, key: v4(), value: props?.data?.billing_postal_code || "" },
        },
        enableForm: { key: v4(), status: props?.enable }
      });
    } catch (err) {
      console.log("[ERROR] Mounting Billing Details form");
      console.log(err);
    }
  }, [props]);

  // Handlers
  const handleFieldChange = (data) => {
    const currentForm = state.formData;
    currentForm[`${data.field}`] = { ...currentForm[`${data.field}`], value: data.value, key: v4() };
    setState({
      ...state,
      formData: currentForm
    });

    if (data?.field === "billing_country") {
      setState({
        ...state,
        formData: {
          ...state.formData,
          billing_state: { ...state.formData.billing_state, value: "", key: v4() },
          billing_city: { ...state.formData.billing_city, value: "", key: v4() },
        }
      });
    }
    if (data?.field === "billing_state") {
      setState({
        ...state,
        formData: {
          ...state.formData,
          billing_city: { ...state.formData.billing_city, value: "", key: v4() },
        }
      });
    }
  };

  const handleTextFieldChange = (data) => {
    try {
      const { field, value } = data;
      const currentForm = state.formData;
      currentForm[`${field}`] = { ...currentForm[`${field}`], value: value };
      setState({
        ...state,
        formData: currentForm
      });

    } catch (err) {
      console.log("[TEXTFIELD CHANGEHANDLER]");
      console.log(err);
    }
  };

  // Renderer
  return (
    <React.Fragment>
      <Box sx={{ px: "24px", width: "100%", py: "12px" }}>
        <Grid container columnSpacing={"32px"} rowSpacing={"32px"}>
          {
            Object.values(state?.formData).map((element) => {
              return (
                <Grid key={`${element?.map}-key`} item xs={12} sm={6} md={6}>
                  {
                    element?.type === "text" ?
                      <CustomTextField
                        key={element?.key}
                        name={element?.map || ""}
                        label={element?.label || ""}
                        value={element?.value.toString() || ""}
                        disabled={state?.enableForm?.status === false}
                        required={false}
                        error={false}
                        helperText={""}
                        placeholder={element?.placeholder}
                        changeHandler={handleTextFieldChange}
                      />
                      :
                      <CustomSelectField
                        key={element?.key}
                        name={element?.map || ""}
                        label={element?.label || ""}
                        value={element?.value.toString() || ""}
                        multi={false}
                        size={"small"}
                        placeholder={element?.placeholder}
                        disabled={state?.enableForm?.status === false}
                        dependency={element?.dependency}
                        triggerValue={element.trigger.map((item) => state?.formData[`${item}`]?.value)}
                        populator={element.getData}
                        changeHandler={handleFieldChange}
                      />
                  }

                </Grid>
              );
            })
          }
        </Grid>
      </Box>
    </React.Fragment>
  );
});

BillingDetailsForm.propTypes = {
  data: PropTypes.object.isRequired,
  enable: PropTypes.bool.isRequired
};
BillingDetailsForm.defaultProps = {
  data: {},
  enable: false
};

export default BillingDetailsForm;