import React from "react";
import { BadgeBase, BadgeProps } from "./BadgeBase";
import useTheme from "@mui/material/styles/useTheme";
import {
  CheckCircleOutlinedIcon,
  CloseIcon,
  FlagFilledIcon,
} from "components/icons";
import { Box, Typography } from "@mui/material";

interface QaHealthStatus extends BadgeProps {
  status: boolean;
  isBorderColorBasedOnStatus?: boolean;
}
export const QaHealthStatusBadge = ({
  status = false,
  isBorderColorBasedOnStatus = false,
  ...rest
}: QaHealthStatus) => {
  const theme = useTheme();
  const backgroundColor = status
    ? theme.palette.success.tint
    : theme.palette.error.tint;

  const color = status ? theme.palette.success.main : theme.palette.error.main;
  return (
    <Box
      border={`1px solid ${
        isBorderColorBasedOnStatus ? color : theme.palette.neutral["015"]
      }`}
      {...rest}
      sx={{
        width: "fit-content",
        display: "flex",
        gap: "4px",
        cursor: "pointer",
        padding: "4px 8px 4px 6px",
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        color: color,
      }}
    >
      {status ? (
        <CheckCircleOutlinedIcon fontFamily="small" color="inherit" />
      ) : (
        <CloseIcon fontFamily="small" color="inherit" />
      )}
      <Typography
        sx={{ textTransform: "capitalize" }}
        textTransform={"capitalize"}
        fontFamily="SegoeBold"
        variant="caption-3"
        color={color}
      >
        {status ? "Passed" : "Failed"}
      </Typography>
    </Box>
  );
};
