import { AttachmentResultType, Hours, PriorityType } from "./common";
import {
  ChecklistInput,
  ResourceAutocompleteOptionsType,
} from "./configuration";
import z from "zod";
export interface CreateTicketInput {
  isBillable: boolean;
  checklist: Array<ChecklistInput>;
  board?: string;
  priority?: string;
  status?: string;
  summary: string;
  description: string;
  resources?: Array<ResourceAutocompleteOptionsType>;
  urgency?: string;
  workType?: string;
  ticketType?: string;
  ticketSubtype?: string;
  source?: string;
  impact?: string;
  sla?: string;
  customer?: string;
  site?: string;
  user?: string;
  schedule?: Array<Schedule>;
  location?: string;
  generalVariable?: Array<Attribute>;
  ticketVariable?: Array<Attribute>;
  budgetedHours?: string;
  attachments?: Array<AttachmentResultType>;
  estimatedEndTime?: string;
  tags?: Array<any> | null;
  contract?: string;
}

export interface CreateChildTicketInput extends CreateTicketInput {
  ticketId: string;
}
export interface Attribute {
  attribute_id: string;
  attribute_value_id: string;
}
export interface Schedule {
  startDate: string | null;
  endDate: string | null;
  startTime: string | null;
  endTime: string | null;
}

export interface ParentTicketOptionProps {
  value: string;
  ticket_summary: string;
  customer_name: string;
  u_id: string;
  label: string;
}
export interface AllTicketResponse {
  mapping_id: string;
  u_id: string;
  ticket_summary: string;
  ticket_description: string;
  ticket_status: string;
  status_name: string;
  priority: string;
  urgency: string;
  impact: string;
  connect_time_from?: string | null;
  connect_time_to?: string | null;
  is_billable: boolean;
  msp_id: number;
  board_name: string;
  work_type_name: string;
  source_name: string;
  location_name?: string | null;
  sla_name?: string | null;
  created_at: string;
  feedback?: string | null;
  rating?: number | null;
  type_name: string;
  subtype_name: string;
}

export interface AttachmentProps {
  mapping_id: string;
  original_name: string;
  file_type: string;
  url: string;
}

export interface ServiceType {
  serviceType: "ticket" | "request" | "project" | string;
}

export interface ActiveTimeEntry {
  mapping_id: string;
  service_id: string;
  service_type: "ticket" | "request" | "project" | "task";
  start_time: string;
  activeTimerValueInSeconds?: number;
  service_summary?: string;
  u_id: string;
  customer_id?: string;
  customer_user_id?: string;
  customer_name?: string;
  customer_user_name?: string;
  email?: string;
  profile_image?: string;
  is_privileged_use?: boolean;
  project_id?: string;
}

export interface Watchlist {
  service_id: string;
  service_type: "ticket" | "request" | "project" | "task";
  service_summary?: string;
  u_id: string;
  customer_name?: string;
  customer_user_name?: string;
  is_privileged_use?: boolean;
  profile_image?: string;
  project_id?: string;
}

export interface createTimeEntriesInput extends ServiceType {
  resourceId: string;
  serviceId: string;
  startTime: string;
  endTime: string;
  isBillable: boolean;
  notes: string;
  deductTime: string;
  accessSpecifier: "public" | "private" | "self" | string;
  markAsResolution: boolean;
  notesAttachments: Array<AttachmentProps>;
  sendAsEmail: boolean;
  work_type?: string;
  work_role?: string;
  is_operating_hours?: boolean;
}

export interface UpdatetimeEntriesInput extends createTimeEntriesInput {
  timeEntryId: string;
}

export interface startTimerInput extends ServiceType {
  serviceId: string;
  resourceId: string;
}

export interface endTimerInput extends ServiceType {
  serviceId: string;
  timeEntryId: string;
}

// export interface TicketData {
//   mapping_id: string;
//   u_id: string;
//   ticket_summary: string;
//   ticket_description: string;
//   ticket_status: string;
//   status_name: string;
//   priority: string;
//   urgency: string;
//   impact: string;
//   connect_time_from: null;
//   connect_time_to: null;
//   is_billable: boolean;
//   msp_id: number;
//   budgeted_hours: TicketDataBudgetedHours;
//   scheduled_hours: ActualHours;
//   actual_hours: ActualHours;
//   board_id: string;
//   board_name: string;
//   estimated_end_date: string;
//   work_type: string;
//   work_type_name: string;
//   work_role: null;
//   work_role_name: null;
//   source_name: string;
//   location_name: string;
//   sla_name: string;
//   created_at: string;
//   profile_image: null;
//   first_name: string;
//   last_name: string;
//   display_name: string;
//   email: string;
//   ticketVariableAttribute: TicketVariableAttribute[];
//   generalVariableAttribute: GeneralVariableAttribute[];
//   checklists: Checklist[];
//   childTickets: ChildTicket[];
//   attachments: Attachment[];
//   mergeTickets: any[];
//   schedules: Schedule[];
//   tags: Tag[];
//   ticketTypeSubtype: TicketTypeSubtype[];
//   timeEntries: any[];
// }

// export interface ActualHours {
//   hours: number;
//   minutes: number;
//   seconds: number;
// }

// export interface Attachment {
//   id: string;
//   mapping_id: null;
//   ticket_id: number;
//   service_files_id: number;
//   created_by: null;
//   created_at: string;
//   updated_at: null;
//   updated_by: null;
//   is_deleted: boolean;
// }

// export interface TicketDataBudgetedHours {
//   minutes: number;
// }

// export interface Checklist {
//   id: string;
//   mapping_id: string;
//   ticket_id: number;
//   checklist_item_name: string;
//   order: number;
//   is_completed: boolean;
//   is_important: boolean;
//   created_by: null;
//   created_at: string;
//   updated_at: null;
//   updated_by: null;
//   is_deleted: boolean;
// }

export interface ChildTicketType {
  id: string;
  mapping_id: string;
  u_id?: string;
  ticket_name?: string;
  ticket_summary: string;
  ticket_description: string;
  ticket_status: string;
  board: string;
  priority: string;
  msp_id: number;
  is_billable: boolean;
  urgency: string;
  work_type: string;
  work_role: null;
  ticket_type?: string;
  source: string;
  impact: string;
  location: string;
  budgeted_hours?: Hours;
  scheduled_hours?: Hours | null;
  actual_hours?: Hours | null;
  sla: string;
  child_to?: number | null;
  merged_to?: number | null;
  customer_id?: any; // TODO: check this
  site_id?: null;
  customer_user_id: null;
  connect_time_from: null;
  connect_time_to: null;
  due_date: string;
  estimated_end_date: null;
  end_date: null;
  created_by?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
  is_deleted: boolean;
  ticket_owner?: null | string;
}

// export interface GeneralVariableAttribute {
//   mapping_id: null | string;
//   general_variable_attribute_name: null | string;
//   general_variable_attribute_value_name: null | string;
// }

// export interface Schedule {
//   mapping_id: string;
//   resource_id: string;
//   service_type: string;
//   service_id: string;
//   start_time: string;
//   end_time: string;
//   duration: ActualHours;
//   is_completed: null;
//   profile_image: null;
//   first_name: string;
//   last_name: string;
//   display_name: string;
//   email: string;
// }

// export interface Tag {
//   mapping_id: string;
//   tag_name: string;
// }

// export interface TicketTypeSubtype {
//   ticket_type_id: string;
//   type_name: string;
//   ticket_subtype_id: string;
//   subtype_name: string;
// }

// export interface TicketVariableAttribute {
//   mapping_id: null | string;
//   ticket_variable_attribute_name: null | string;
//   ticket_variable_attribute_value_name: null | string;
// }

export interface MergeTicketInput {
  parentTcketId: string;
  mergeTickets: Array<string>;
}

export interface BulkAssignTicketInputProps {
  service_id: string[];
  resource_id: string[];
  note: string;
}

// export interface ModifyTicketInputProps{
//   ticket_ids: string [];
//   tags: string [];
//   board: string;

// }

export interface ChildTicketInput {
  parentTcketId: string;
  childTickets: Array<string>;
}

export interface Attachment {
  mapping_id: string;
  original_name: string;
  url: string;
  file_type: string;
  size: number;
  attachment_type?: string;
}

export interface SingleChecklistResult {
  id?: number;
  mapping_id?: string;
  ticket_id?: 38;
  checklist_item_name: string;
  order: number;
  is_completed?: boolean;
  is_important: boolean;
  created_by?: any; // TODO: fix any
  created_at?: string;
  updated_at?: string | null;
  updated_by?: any | null;
  completed_by?: any | null;
  completed_at?: string | null;
  is_deleted?: boolean;
  first_name?: string;
  last_name?: string;
}

export interface ScheduleResult {
  mapping_id: string;
  resource_id: string;
  service_type: string;
  service_id: string;
  start_time?: string | null;
  end_time?: string | null;
  duration: Hours;
  is_completed?: boolean | null;
  profile_image: string | null;
  first_name: string;
  last_name: string;
  display_name: string | null;
  email: string;
}

export interface AddScheduleInput {
  serviceId: string;
  serviceType: "ticket" | "request" | "task" | "project";
  schedules: Array<{
    resource_id: string;
    schedule: Array<{
      start_time: string;
      end_time: string;
    }>;
  }>;
}

export interface AddTaskSchedule extends Omit<AddScheduleInput, "ticketId"> {
  taskId: string;
}
export interface UpdateAssigneesInput {
  serviceId: string;
  serviceType: string;
  scheduleId: string;
  scheduleData?: {
    start_time?: string | null;
    end_time?: string | null;
    is_completed?: boolean | null;
  };
}

export interface DeleteAssigneesInput {
  serviceId: string;
  serviceType: "ticket" | "request" | "task" | "project";
  scheduleId: string;
}
// export interface ScheduleTimes {
//   start_time: string;
//   end_time: string;
// }

export interface Assignees {
  mapping_id: string;
  first_name: string;
  last_name: string | null;
  display_name: string | null;
  profile_image: string | null;
}

export interface ChangeBillableStatusInput {
  ticketId: string;
  newValue: boolean;
}
export interface UpdateTicketStatusInput {
  ticketId: string;
  statusId: string;
}
export interface UpdateTicketPriorityInput {
  ticketId: string;
  priority: PriorityType;
}

export interface UpdateChecklistItmeInput {
  serviceType?: "ticket" | "request" | "tasks";
  serviceId: string;
  checklistId: string;
  checklist_item_name?: string;
  // "order"?: number,
  is_important?: boolean;
  is_completed?: boolean;
}
export interface UpdateChecklistInput {
  serviceType?: "ticket" | "request" | "tasks";
  serviceId: string;
  checklistArray: Array<any>;
}

export interface UpdateAdditionalTicketDetailsInput {
  ticketSummary?: string;
  ticketDescription?: string;
  ticketId?: string;
  workType?: string;
  workRole?: string;
  source?: string;
  location?: string;
  budgetedHours?: string;
  estimatedEndDate?: string;
  ticketType?: string;
  ticketSubtype?: string;
  ticketVariableAttribute?: Array<any>;
  generalVariableAttribute?: Array<any>;
  tags?: Array<any>;
  ticketOwner?: string;
  isSlaExpired?: boolean;
  customerId?: string;
  siteId?: string;
  userId?: string;
  board?: string;
  impact?: string;
  urgency?: string;
  sla?: string;
  attachments?: Array<any>;
}

export interface ticketDetailsFromTimeEntriesInput {
  ticketId: string;
  workType?: string;
  isBillable?: boolean;
  status?: string;
}

export interface DeleteTicketAttachmentInput {
  ticketId: string;
  attachmentId: Array<string>;
}

export interface DeleteTimeEntryInput {
  ticketId: string;
  serviceType: string;
  timeEntryId: string;
}

export interface GetTimeEntryByIdInput {
  serviceType: string;
  timeEntryId: string;
}

export interface UpdateScheduleConnectTimeInput {
  ticketId: string;
  connectTimeFrom: string;
  connectTimeTo: string;
}

interface TagProps {
  mapping_id: string;
  tag_name: string;
}
export interface UpdateProjectDetails {
  projectId: string;
  data: {
    project_title?: string;
    project_manager?: string;
    project_description?: string;
    project_status?: string;
    board?: string;
    priority?: string;
    is_billable?: boolean;
    urgency?: string;
    work_role?: string;
    work_type?: string;
    project_type?: string;
    project_subtype?: string;
    source?: string;
    impact?: string;
    location?: string;
    budgeted_hours?: string;
    scheduled_hours?: string;
    estimated_end_date?: string;
    customer_id?: string;
    site_id?: string;
    customer_user_id?: string;

    // project_variable_attributes: [];
    // general_variable_attributes: [];
    tags?: Array<TagProps>;
    attachments?: Array<any>;
  };
}

export interface UpdateProjectPriorityInput {
  projectId: string;
  priority: PriorityType;
}

export interface UpdateProjectStatusInput {
  projectId: string;
  status: string;
}

export interface UpdateTaskPriorityInput {
  taskId: string;
  priority: PriorityType;
}

export interface UpdateTaskDetailsInput {
  taskId: string;
  data: {
    task_name?: string;
    task_summary?: string;
    task_owner?: string;
    task_description?: string;
    task_status?: string;
    board?: string;
    priority?: string;
    is_billable?: boolean;
    urgency?: string;
    work_role?: string;
    work_type?: string;
    task_type?: string;
    task_subtype?: string;
    source?: string;
    impact?: string;
    location?: string;
    budgeted_hours?: string;
    scheduled_hours?: string;
    estimated_end_date?: string;
    customer_id?: string;
    site_id?: string;
    customer_user_id?: string;
    // project_variable_attributes: [];
    // general_variable_attributes: [];
    tags?: Array<TagProps>;
    // attachments: [];
    show_add_description?: string;
  };
}

export interface BulkUpdateTasksInput {
  projectId: string;
  tasks: Array<BulkTaskProp>;
}

type BulkTaskProp = {
  order: number;
  mapping_id: string;
};
export interface CreateTaskInput {
  milestoneId: string;
  projectId: string;
  data: {
    taskName: string;
    order: number;
    customerId: string;
    userId: string;
    siteId: string;
    contractId: string;
  };
}

export interface BulkDeleteTaskInput {
  taskIds: Array<string>;
  projectId: string;
  milestoneId: string;
}
export interface CreateMilestoneInput {
  projectId: string;
  data: {
    milestoneName: string;
    order: number;
    customerId: string;
  };
}

export interface UpdateMilestoneInput {
  milestoneId: string;
  projectId: string;
  data: {
    milestoneName: string;
    order?: number;
  };
}

interface BulkMilestoneProp {
  mapping_id: string;
  milestone_name: string;
  order: number;
}
export interface BulkUpdateMilestoneInput {
  projectId: string;
  milestones: Array<BulkMilestoneProp>;
}

export interface UpdateProjectBillingInfoInput {
  projectId: string;
  data: {
    billing_method?: string;
    hourly_rate?: number;
    down_payment?: number;
    is_billable_time?: boolean;
    estimated_time_cost?: number;
    is_billable_expenses?: boolean;
    estimated_expense_cost?: number;
    is_billable_products?: boolean;
    estimated_product_cost?: number;
    billing_after_closed?: boolean;
    billing_unapproved_time_expenses?: boolean;
    po_amount?: number;
  };
}

export interface UpdateTaskStatusInput {
  taskId: string;
  statusId: string;
}

export interface BulkAssignResourcesInputProps {
  service_id: string[];
  resource_id: string[];
  serviceType: "ticket" | "request" | "task" | "project";
  note?: string;
}

export interface CreateTemplateFromProjectInput {
  projectId: string;
  data: {
    templateName: string;
  };
}

export const CreateActivityInputSchema = z.object({
  activityType: z.string(),
  resource_id: z.string(),
  start_time: z.string(),
  end_time: z.string(),
  is_billable: z.boolean(),
  activity_comment: z.string().optional(),
  deduct_time: z.string(),
  customer_id: z.string(),
  activity_title: z.string(),
  activity_attachments: z.array(z.any()),
  contract_id: z.string(),
});

export type CreateActivityInputType = z.infer<typeof CreateActivityInputSchema>;

const UpdateActivitySchema = CreateActivityInputSchema.omit({
  activityType: true,
  resource_id: true,
}).extend({
  entryId: z.string(),
  activity_type: z.string(),
});

export type UpdateActivityInputType = z.infer<typeof UpdateActivitySchema>;

const UpdateActivityByManagerSchema = CreateActivityInputSchema.omit({
  activityType: true,
}).extend({
  entryId: z.string(),
  activity_type: z.string(),
});

export type UpdateActivityByManagerInputType = z.infer<
  typeof UpdateActivityByManagerSchema
>;
export interface BulkDeleteAssigneesInput {
  serviceType: "ticket" | "request" | "task" | "project";
  schedules: Array<string>;
  serviceId: string;
}

export interface UpdateWatchListInput {
  serviceType: "ticket" | "request" | "task" | "project";
  serviceId: string;
}
export interface RemovePinServiceInput {
  serviceType: "ticket" | "request" | "task" | "project";
  serviceId: string;
}
export interface AddPinServiceInput extends RemovePinServiceInput {
  colorCodePrimary: string;
  colorCodeTint: string;
}
