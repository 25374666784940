import React from "react";
import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { InputBaseProps, StandardTextFieldProps } from "@mui/material";
import { InputProps } from "./Input";

// interface InputRootProps extends Omit<OutlinedInputProps, "size"> {}
export const InputRoot = styled(TextField)<InputProps>(
  ({ theme, size, ...rest }) => {
    const { palette, typography } = theme;

    const getLargeStyle = () => {
      return {
        "& .MuiInputBase-input": {
          padding: "0px 24px",
          height: "48px",
        },
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            ...typography["body-large"],
          },
        },
        "& .MuiInputLabel-outlined": {
          padding: "0px 0px",

          ...typography["body-large"],
          "&.Mui-focused": {
            padding: "0px",
            // transform: 'translate(14px, -9px) scale(1)',
          },
        },
      };
    };

    const getMediumStyle = () => {
      return {
        "& .MuiInputBase-input": {
          padding: "0px 16px",
          height: "40px",
        },
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            ...typography["body-medium"],
          },
        },
        "& .MuiInputLabel-outlined": {
          ...typography["body-medium"],
        },
        "& .Mui-focused": {},
        "& .MuiInputLabel-shrink": {
          ...typography["caption"],
        },
      };
    };

    const getSmallStyle = () => {
      return {
        "& .MuiInputBase-input": {
          padding: "0px 16px",
          height: "32px",
        },
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            ...typography["body-small"],
          },
        },
        "& .MuiInputLabel-outlined": {
          padding: "0px 0px",

          ...typography["body-small"],
          "&.Mui-focused": {
            padding: "0px",
            // transform: 'translate(14px, -9px) scale(1)',
          },
        },
      };
    };

    return {
      ...(size === "large" && getLargeStyle()),
      ...(size === "medium" && getMediumStyle()),
      ...(size === "small" && getSmallStyle()),

      "& .MuiInput-root": {
        fontFamily: "SegoeRegular",
      },
      "& .MuiOutlinedInput-root": {
        fontFamily: "SegoeRegular",
        borderRadius: "4px",

        "& > fieldset": {
          border: `1px solid ${palette.neutral["020"]}`,
        },
      },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        color: palette.lightBg.high,
        "& > fieldset": { border: `1px solid ${palette.neutral["015"]}` },
      },
      "& .MuiOutlinedInput-root.Mui-focused": {
        color: palette.lightBg.high,
        "& > fieldset": { border: `1px solid ${palette.purple.main}` },
      },
      "& .MuiInputLabel-root": {
        color: palette.lightBg.low,
        fontFamily: "SegoeRegular",

        "&.Mui-focused": {
          color: palette.purple.main,
          fontFamily: "SegoeMedium",
          // ...typography.caption,
          // transform: 'translate(14px, -9px) scale(1)',
        },
      },
    };
  }
);
