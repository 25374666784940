import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { Input, InputMultiSelectField, SelectField } from "components";
import {
  useGetAllLocations,
  useGetAllResources,
  useGetAllTags,
  useGetServiceTypeSubtype,
  useGetVariableAttributes,
  useMainStore,
} from "controller";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { emptyStringHandler } from "utilz/helpers";
import * as yup from "yup";

const schema = yup.object({
  budgeted_hours: yup.number().min(0).nullable(),
  tags: yup.array().nullable(),
  time_approver: yup.string().nullable(),
  expense_approver: yup.string().nullable(),
  project_type: yup.string().nullable(),
  project_subtype: yup.string().nullable(),
  location: yup.string().nullable(),
  project_variable_attributes: yup.array().nullable(),
  general_variable_attributes: yup.array().nullable(),
});

export const ProjectTemplateStep2 = forwardRef<any, any>(
  (
    {
      projectData,
      onSubmitData,
    }: { projectData: any; onSubmitData: (data: any) => void },
    ref
  ) => {
    const theme = useTheme();

    const {
      watch,
      control,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues: {
        budgeted_hours: projectData?.budgeted_hours || 0,
        tags: projectData?.tags || [],
        time_approver: projectData?.time_approver || "",
        expense_approver: projectData?.expense_approver || "",
        project_type: projectData?.project_type || "",
        project_sub_type: projectData?.project_sub_type || "",
        location: projectData?.location || "",
        project_variable_attributes:
          projectData.project_variable_attributes || [],
        general_variable_attributes:
          projectData.general_variable_attributes || [],
      },
      resolver: yupResolver(schema),
    });

    const [managers, setManagers] = useState<any>([]);

    const projectVariableAttribute = watch("project_variable_attributes");
    const generalVariableAttribute = watch("general_variable_attributes");

    const tag = watch("tags");
    const projectType = watch("project_type");
    const { data: location } = useGetAllLocations({});
    const { data: resourceData } = useGetAllResources({
      filter: [
        {
          column: "resource_type",
          operator: "=",
          value: [
            "b110abc3-0709-491f-ac04-86eb25159386",
            "9d6afd0e-b353-438d-a34d-1bb1cc5bbf03",
          ],
        },
      ],
    });
    const { data: variableAttributeData } = useGetVariableAttributes({
      attributeName: "project",
    });

    const { data: generalVariableAttributeData } = useGetVariableAttributes({
      attributeName: "general",
    });

    const { data: projectTypeData } = useGetServiceTypeSubtype({
      serviceType: "project",
    });

    const { data: tagData } = useGetAllTags({});

    const getProjectSubType = (id: string) => {
      if (Array.isArray(projectTypeData?.result)) {
        const index = projectTypeData?.result.findIndex(
          (item: any) => item.type_id === id
        );
        if (index !== -1) {
          return Array.isArray(projectTypeData?.result[index]?.subtype)
            ? projectTypeData?.result[index]?.subtype
            : [];
        }
      }
      return [];
    };

    useEffect(() => {
      if (Array.isArray(resourceData?.result)) {
        const data = resourceData.result.map((option: any) => ({
          ...option,
          display_name: option.display_name
            ? option.display_name
            : `${emptyStringHandler(option["first_name"])} ${emptyStringHandler(
                option["middle_name"]
              )} ${emptyStringHandler(option["last_name"])}`,
        }));
        setManagers(data);
      }
    }, [resourceData]);

    const onSubmit = handleSubmit((data) => {
      const projectData = {
        ...data,
      };
      onSubmitData(projectData);
    });

    const onVariableAttributeChange = (
      value: any,
      type: "project" | "general"
    ) => {
      let variableAttribute;
      let action: any = "general_variable_attributes";
      if (type === "project") {
        variableAttribute = projectVariableAttribute;
        action = "project_variable_attributes";
      } else {
        variableAttribute = generalVariableAttribute;
        action = "general_variable_attributes";
      }

      let newVariableAttribute = variableAttribute.filter(
        (item: any) => item.attribute_id !== value.attribute_id
      );

      newVariableAttribute = [...newVariableAttribute, value];

      setValue(action, newVariableAttribute);
    };

    useImperativeHandle(ref, () => ({ onSubmit }));
    return (
      <Box
        sx={{
          px: "24px",
          py: "16px",
        }}
      >
        <Stack
          width={"100%"}
          gap={"16px"}
          sx={{
            borderRadius: "16px",
            border: `1px solid ${theme.palette.neutral["015"]}`,
            p: "24px",
          }}
        >
          <Grid
            container
            direction="row"
            rowSpacing={1}
            columnSpacing={2}
            mt="16px"
          >
            <Grid item xs={4}>
              <SelectField
                name="expense_approver"
                label="Expense approver"
                options={managers || []}
                control={control}
                labelMappingKey="display_name"
                valueMappingKey="mapping_id"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name="time_approver"
                label="Time approver"
                options={managers || []}
                control={control}
                labelMappingKey="display_name"
                valueMappingKey="mapping_id"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name="location"
                label="Location"
                options={
                  Array.isArray(location?.result) ? location?.result : []
                }
                control={control}
                labelMappingKey="location_name"
                valueMappingKey="mapping_id"
              />
            </Grid>

            <Grid item xs={4}>
              <SelectField
                name="project_type"
                label="Project type"
                control={control}
                options={
                  Array.isArray(projectTypeData?.result)
                    ? projectTypeData?.result
                    : []
                }
                labelMappingKey="type_name"
                valueMappingKey="type_id"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name="project_subtype"
                label="Project subtype"
                control={control}
                options={getProjectSubType(projectType)}
                labelMappingKey="subtype_name"
                valueMappingKey="subtype_id"
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Budgeted hours"
                name={"budgeted_hours"}
                size="small"
                fullWidth
                control={control}
                type="number"
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
              />
            </Grid>
            {/* {(Array.isArray(generalVariableAttributeData?.result)
              ? generalVariableAttributeData.result
              : []
            ).map(
              (attribute: any, index: number) =>
                Array.isArray(attribute?.variable_attribute_values) &&
                attribute?.variable_attribute_values.length > 0 && (
                  <Grid key={`${attribute?.attribute_id}`} item xs={4}>
                    <SelectField
                      name="projectManager"
                      label={attribute?.variable_attribute_name || ""}
                      value={
                        (Array.isArray(generalVariableAttribute)
                          ? generalVariableAttribute
                          : []
                        ).find(
                          (item: any) =>
                            item.attribute_id === attribute?.attribute_id
                        )?.attribute_value_id
                      }
                      options={attribute.variable_attribute_values}
                      labelMappingKey="variable_attribute_value_name"
                      valueMappingKey="attribute_value_id"
                      onValueChange={
                        (e) => {
                          onVariableAttributeChange(
                            {
                              attribute_value_id: e,
                              attribute_id: attribute.attribute_id,
                            },
                            "general"
                          );
                        }
                        //   addValuesToFields({ type: "location", value: e })
                      }
                    />
                  </Grid>
                )
            )}

            {(Array.isArray(variableAttributeData?.result)
              ? variableAttributeData.result
              : []
            ).map(
              (attribute: any, index: number) =>
                Array.isArray(attribute?.variable_attribute_values) &&
                attribute?.variable_attribute_values.length > 0 && (
                  <Grid key={`${attribute?.attribute_id}`} item xs={4}>
                    <SelectField
                      name="projectManager"
                      label={attribute?.variable_attribute_name || ""}
                      value={
                        (Array.isArray(projectVariableAttribute)
                          ? projectVariableAttribute
                          : []
                        ).find(
                          (item: any) =>
                            item.attribute_id === attribute?.attribute_id
                        )?.attribute_value_id
                      }
                      options={attribute.variable_attribute_values}
                      labelMappingKey="variable_attribute_value_name"
                      valueMappingKey="attribute_value_id"
                      onValueChange={(e) => {
                        onVariableAttributeChange(
                          {
                            attribute_value_id: e,
                            attribute_id: attribute.attribute_id,
                          },
                          "project"
                        );
                      }}
                    />
                  </Grid>
                )
            )} */}
          </Grid>
          <InputMultiSelectField
            options={tagData?.result || []}
            value={tag || []}
            label="Tags"
            labelMappingKey="tag_name"
            valueMappingKey="mapping_id"
            onChange={(e, newValue) => setValue("tags", newValue)}
          />
        </Stack>
      </Box>
    );
  }
);
