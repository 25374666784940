import React from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

// Legacy Imports
import Box from "@mui/material/Box";
import { helpers } from "layouts/helpers";

import { MqttProvider } from "controller/context/MqttContext";

export const ServiceDeskLayout = () => {
  return (
    <ThemeProvider>
      <MqttProvider>
        <Box
          sx={{
            height: "calc(100vh - 64px)",
          }}
        >
          <Outlet />
        </Box>
      </MqttProvider>
    </ThemeProvider>
  );
};
