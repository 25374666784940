import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  // addTaskSchedule,
  bulkDeleteTasks,
  bulkUpdateTasks,
  createTask,
  getTask,
  updateTaskDetails,
  updateTaskPriority,
  updateTaskStatus,
} from "controller/api";
import {
  AddTaskSchedule,
  BulkDeleteTaskInput,
  BulkUpdateTasksInput,
  CreateTaskInput,
  UpdateTaskDetailsInput,
  UpdateTaskPriorityInput,
  UpdateTaskStatusInput,
} from "shared";

export const useCreateTask = () => {
  const queryClient = useQueryClient();
  return useMutation((value: CreateTaskInput) => createTask(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["project", variables.projectId]);
      queryClient.invalidateQueries(["projectProgress", variables.projectId]);
    },
  });
};

export const useGetTask = (id?: string) => {
  return useQuery({
    queryKey: ["task", id],
    queryFn: () => getTask(id || ""),
    enabled: id ? true : false,
    onError: (error) => console.log(error),
  });
};

export const useUpdateTaskDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateTaskDetailsInput) => updateTaskDetails(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["task", variables.taskId]);
      },
    }
  );
};

export const useBulkUpdateTasks = () => {
  const queryClient = useQueryClient();
  return useMutation((value: BulkUpdateTasksInput) => bulkUpdateTasks(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["project", variables.projectId]);
      queryClient.invalidateQueries(["projectProgress", variables.projectId]);
    },
  });
};

export const useUpdateTaskPriority = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateTaskPriorityInput) => updateTaskPriority(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["task", variables.taskId]);
      },
    }
  );
};

// export const useAddTaskSchedule = () => {
//   const queryClient = useQueryClient();
//   return useMutation((value: AddTaskSchedule) => addTaskSchedule(value), {
//     onSuccess(data, variables, context) {
//       queryClient.invalidateQueries(["task", variables.taskId]);
//     },
//   });
// };

export const useBulkDeleteTask = () => {
  const queryClient = useQueryClient();
  return useMutation((value: BulkDeleteTaskInput) => bulkDeleteTasks(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["project", variables.projectId]);
      queryClient.invalidateQueries(["projectProgress", variables.projectId]);
    },
  });
};

export const useUpdateTaskStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateTaskStatusInput) => updateTaskStatus(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["task", variables.taskId]);
      },
    }
  );
};
