import { fetchCall } from "../../../utilz";
import { appConfig } from "../../../constants";

//-------------- RESOURCES ---------------------

// Fetch all resources by mspId
const fetchAllResources = (
  msp_id,
  per_page,
  page,
  sortColumn,
  sortDirection,
  filters,
  searchQuery
) =>
  fetchCall(
    `/controlCenter/fetchall/resource/${msp_id}?per_page=${per_page}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=[${filters}]&searchQuery=${searchQuery}`,
    appConfig?.requestMethods.GET
  );

const fetchCompleteResources = (msp_id) =>
  fetchCall(
    `/controlCenter/fetchall/resource/${msp_id}?filters=[]`,
    appConfig?.requestMethods.GET
  );

// fetch resource by id
const fetchResourceById = (msp_id, resource_id) =>
  fetchCall(
    `/controlCenter/fetch/resource/${resource_id}/${msp_id}`,
    appConfig.requestMethods.GET
  );

const createNewResource = (data) =>
  fetchCall("/v2/controlCenter/resource", appConfig.requestMethods.POST, data);

const updateResourceById = (resource_id, data) =>
  fetchCall(
    `/controlCenter/update/resource/${resource_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

const deleteResources = (data) =>
  fetchCall("/v2/controlCenter/resource", appConfig.requestMethods.PATCH, data);

const fetchResourceProficiency = (resource_id) =>
  fetchCall(
    `/controlCenter/proficiency/${resource_id}`,
    appConfig.requestMethods.GET
  );

const updateResourceProficiency = (resource_id, data) =>
  fetchCall(
    `/controlCenter/proficiency/${resource_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

const fetchResourcePermission = (resource_id) =>
  fetchCall(
    `/controlCenter/fetch/permissions/resource/${resource_id}`,
    appConfig.requestMethods.GET
  );

const updateResourcePermission = (resource_id, data) =>
  fetchCall(
    `/controlCenter/update/permissions/resource/${resource_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//-------------- RESOURCES END ---------------------

//-------------- RESOURCE GROUP --------------------

const fetchAllResourceGroup = (
  msp_id,
  per_page,
  page,
  sortColumn,
  sortDirection,
  filters,
  searchQuery
) =>
  fetchCall(
    `/controlCenter/all/resource_groups/${msp_id}?per_page=${per_page}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=[${filters}]&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );

const fetchResourceGroupById = (resource_group_id) =>
  fetchCall(
    `/controlCenter/fetch/resource_group/${resource_group_id}`,
    appConfig.requestMethods.GET
  );

const fetchCompleteResourceGroup = (msp_id) =>
  fetchCall(
    `/controlCenter/all/resource_groups/${msp_id}?filters=[]`,
    appConfig.requestMethods.GET
  );

const createNewResourceGroup = (msp_id, data) =>
  fetchCall(
    `/controlCenter/create/resource_group/${msp_id}`,
    appConfig.requestMethods.POST,
    data
  );

const updateResourceGroup = (resource_group_id, data) =>
  fetchCall(
    `/controlCenter/update/resource_group/${resource_group_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

const deleteResourceGroups = (msp_id, data) =>
  fetchCall(
    `/controlCenter/delete/resource_group/${msp_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//-------------- RESOURCE GROUP END ----------------

//---------------- RESOURCE TYPE --------------------

const fetchCompleteResourceType = (msp_id) =>
  fetchCall(
    `/controlCenter/fetch/resource_type/${msp_id}?filters=[]`,
    appConfig.requestMethods.GET
  );

const fetchAllResourceType = (
  msp_id,
  per_page,
  page,
  sortColumn,
  sortDirection,
  searchQuery
) =>
  fetchCall(
    `/controlCenter/fetch/resource_type/${msp_id}?filters=[]&per_page=${per_page}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`
  );

const fetchResourceTypeById = () => fetchCall("");

const createResourceType = (msp_id, data) =>
  fetchCall(
    `/controlCenter/create/resource_type/${msp_id}`,
    appConfig.requestMethods.POST,
    data
  );

const updateResourceType = (resource_type_id, data) =>
  fetchCall(
    `/controlCenter/update/resource_type/${resource_type_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

const deleteBulkResourceTypes = (msp_id, data) =>
  fetchCall(
    `/controlCenter/delete/resource_type/${msp_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//---------------- RESOURCE TYPE END --------------------

export const resourcesService = {
  //RESOURCE
  fetchAllResources,
  fetchCompleteResources,
  fetchResourceById,
  createNewResource,
  updateResourceById,
  deleteResources,
  fetchResourceProficiency,
  updateResourceProficiency,
  fetchResourcePermission,
  updateResourcePermission,
  // RESOURCE GROUP
  fetchAllResourceGroup,
  fetchResourceGroupById,
  fetchCompleteResourceGroup,
  createNewResourceGroup,
  updateResourceGroup,
  deleteResourceGroups,

  //RESOURCE TYPE
  fetchAllResourceType,
  fetchCompleteResourceType,
  createResourceType,
  updateResourceType,
  deleteBulkResourceTypes,
};
