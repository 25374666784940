import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";

// Legacy Imports
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import Box from "@mui/material/Box";

// HOC Imports
import { CustomTypography } from "../../../../library/base";

// Constants Imports
import { TABS } from "./constants";
import { SecurityTab } from "./tabs";

// Tab-Design Constants
const Tab = styled(TabUnstyled)`
  color: #767b85;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  padding: 16px 0px;
  margin-left: 32px;
  border: none;
  border-bottom: 4px solid transparent;

  &.${tabUnstyledClasses.selected} {
    border-bottom: 4px solid #5563f5;
    color: #5563f5;
    font-weight: 500;
  }
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: transparent;
  border-bottom: 1px solid #EDEDED;
  margin-bottom: 8px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  `
);

const SecurityPage = () => {
  // // Routing Constants
  // const location = useLocation();
  // const navigate = useNavigate();
  // // States
  // const [state, setState] = useState({selectedTab: "", });

  // // Effects
  // useEffect(()=> {
  //   const pathList = location?.pathname.split("/");
  //   const mountRoute = pathList[pathList.length - 1];
  //   const reqTab = Object.values(TABS).find((item)=> item.route === mountRoute).value;
  //   setState({...state, selectedTab: reqTab});
  // }, []);

  // useEffect(()=> {
  //   if(state?.selectedTab !== "") {
  //     const newRouteElement = Object.values(TABS).find((item)=>item.value === state?.selectedTab);
  //     handleNavigate(newRouteElement?.route);
  //   }

  // }, [state?.selectedTab]);

  // // Trackers

  // // Handlers
  // const handleTabChange = (event, newValue) => setState({...state, selectedTab: newValue});
  // const handleNavigate = (newRoute)=> navigate(newRoute);

  // Renderer
  return (
    <React.Fragment>
      {/* <Box 
        sx={{
          px: "32px",
          py: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%"
        }}
      >
        <CustomTypography 
          size = {"MEDIUM-ALPHA"}
          color = {"PRIMARY-MAIN"}
          variant = {"BOLD"}
          content = {
            Object.values(TABS).find((element)=> element?.route === location.pathname.split("/")[location.pathname.split("/").length - 1]).label
          }
        />
      </Box>
      <TabsUnstyled 
        value = {state?.selectedTab}
        onChange = {handleTabChange}
      >
        <TabsList>
          {Object.values(TABS).map((tab)=> {
            return(
              <Tab 
                key = {tab?.key}
                className='typography-font-family-class-regular'
                value = {tab?.value}
              >
                {tab?.label}
              </Tab>
            );
          })}
        </TabsList>
      </TabsUnstyled>
      <Box sx={{width: "100%",m:0, p:0, }}>
       
      </Box> */}
      <Outlet />
    </React.Fragment>
  );
};

export default SecurityPage;
