import { Box, Stack, useTheme } from "@mui/material";
import { Button, DeleteConfirmationModal, PlusIcon } from "components";
import { HomeScreenLayout } from "components/layout/HomeScreenLayout";
import { DataTable } from "components/table/DataTable";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DrawerComponent } from "./components/DrawerComponent";
import {
  useDeleteProductConfig,
  useGetAllProductConfigs,
  useMainStore,
} from "controller";
import toast from "react-hot-toast";

const BillingHeaders = [
  {
    name: "Name",
    sortable: true,
    key: "product_name",
  },
  {
    name: "Product Type",
    sortable: true,
    key: "product_type",
  },
  {
    name: "Price",
    sortable: true,
    key: "price",
  },
];
const cellBuilders = {
  price: (rowData: any) => rowData?.price / 100,
};

export const ServiceDeskBillingProduct = (data: any) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [mode, setMode] = useState<any>({ method: "create", data: null });
  const theme = useTheme();
  // const [perPageCount, setPerPageCount] = useState(10);
  const [pageNo, setPageNumber] = useState(1);
  const [sortColumn, setSortColume] = useState("");
  const [sortDirection, setSortDirection] = useState<
    "asc" | "desc" | undefined
  >("desc");
  const [searchValue, setSearchValue] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productDataResult, setProductDataResult] = useState<[]>([]);
  const perPageCount = useMainStore((state) => state.perPageCount);
  const setPerPageCount = useMainStore((state) => state.setPerPageCount);

  const {
    data: productData,
    isFetching: isproductDataLoading,
    refetch,
  } = useGetAllProductConfigs({
    filter: [],
    pageNumber: pageNo,
    perPageCount: perPageCount.billingProduct,
    sortColumn: "product_name",
    sortDirection: "asc",
    searchQuery: searchValue,
  });

  const { mutateAsync: deleteProductConfig } = useDeleteProductConfig();

  useEffect(() => {
    if (productData) {
      setProductDataResult(productData?.result);
    }
  }, [productData]);

  const openDrawer = (method: "create" | "update", id: string | null) => {
    // setIsDrawerOpen(true);
    if (method === "create") {
      setMode({ method: "create", data: null });
      setIsDrawerOpen(true);
    } else if (method === "update") {
      const findDataById = productDataResult.find(
        (item: any) => item?.mapping_id === id
      );
      setMode({ method: "update", data: findDataById });
      setIsDrawerOpen(true);
    }
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleItemClick = (id: string) => {
    const checkExistInTableData = productDataResult.findIndex(
      (item: any) => item?.mapping_id === id
    );
    if (checkExistInTableData !== -1) {
      const index = selectedRow.indexOf(id);
      // If ID is not in the selectedRow array, add it
      if (index === -1) {
        setSelectedRow([...selectedRow, id]);
      } else {
        // If ID is already in the selectedRow array, remove it
        const updatedSelectedRow = [...selectedRow];
        updatedSelectedRow.splice(index, 1);
        setSelectedRow(updatedSelectedRow);
      }
    }
  };

  const onHeaderCheckboxClick = (currentState: "checked" | "unchecked") => {
    // const selectedArray: any = [];
    if (currentState === "checked") {
      const selectedRowSet = new Set(selectedRow);
      const productIds = productDataResult
        .filter((item: any) => !selectedRowSet.has(item.mapping_id))
        .map((item: any) => item.mapping_id);
      setSelectedRow(productIds);
    } else {
      setSelectedRow([]);
    }
  };

  const handleDeleteClick = async () => {
    try {
      await deleteProductConfig({ product_ids: selectedRow });
      setIsDeleteModalOpen(false);
      setSelectedRow([]);
      toast.success("Product successfully deleted");
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const componentName = "Add Product";

  console.log(productData, "productDataResult");

  return (
    // <Box sx={{ padding: 3 }}>
    <HomeScreenLayout
      title="Product"
      rightActionComponent={
        <Stack direction={"row"} gap={"24px"}>
          {/* <SearchInput
            name="search"
            placeholder="Search"
            // value={searchQuery}
            value={""}
            // onChange={(e) => setSearchQuery(e.target.value)}
          /> */}
          <Button
            endIcon={<PlusIcon />}
            onClick={() => openDrawer("create", null)}
          >
            Product
          </Button>
        </Stack>
      }
    >
      <DrawerComponent
        {...{ isDrawerOpen, closeDrawer, componentName, mode }}
      />
      <Box sx={{ paddingX: "32px" }}>
        <DataTable
          cellBuilders={cellBuilders}
          showPagination={true}
          headers={BillingHeaders}
          data={isproductDataLoading ? [] : productDataResult || []}
          selectedItems={selectedRow}
          totalItems={productData?.totalCount || 0}
          totalPages={productData?.totalPages || 0}
          currentPageNumber={pageNo}
          onItemClick={handleItemClick} //check box
          onAllSelect={(currentState: any) => {
            onHeaderCheckboxClick(currentState);
          }}
          isDataLoading={isproductDataLoading}
          itemsPerPage={perPageCount.billingProduct}
          onPerPageChange={(itemsPerPage) => {
            setPerPageCount({ type: "billingProduct", count: itemsPerPage });
            // setPerPageCount(itemsPerPage);
          }}
          onPagination={(d) => {
            // changePageNo(d);
            setPageNumber(d);
          }}
          multiselect={true}
          handleRowClick={(id: any) => {
            openDrawer("update", id?.mapping_id);
          }}
          actionContent={
            <Stack direction={"row"} gap={"24px"}>
              <Button
                onClick={() => setIsDeleteModalOpen(true)}
                variant="primary"
                sx={{
                  bgcolor: theme.palette.error.main,
                  "&:hover": { backgroundColor: "#A9050E" },
                }}
              >
                Delete
              </Button>
            </Stack>
          }
          // isCheckboxDisabled={(item: any) => {
          //   return false;
          // }}
        />
        {isDeleteModalOpen && (
          <DeleteConfirmationModal
            open={isDeleteModalOpen}
            onPrimaryButtonClick={handleDeleteClick}
            // onPrimaryButtonClick={() => {}}
            onSecondaryButtonClick={() => setIsDeleteModalOpen(false)}
            title="Delete Product Template"
            tagline="Are you sure you want to delete?"
            // tagline={`Are you sure you want to delete ${
            //   selectedCustomers.length
            // } ${selectedCustomers.length > 1 ? "customers" : "customer"}?`}
          />
        )}
      </Box>
    </HomeScreenLayout>
  );
};
