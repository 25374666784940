import {
  Button,
  Modal,
  RecurringTaskIcon,
  ReportIcon,
  Tab,
  Tabs,
} from "components";
import ToggleButton from "library/base/custom-toggle-button";
import { useEffect, useRef, useState } from "react";
import { GeneralTab } from "./GeneralTab";
import {
  useGetActiveResourceCount,
  useGetResourceById,
  useGetResourcePermissions,
  useGetResourceProficiency,
  useGetResourceStatusById,
  useGlobalStore,
  useResourceResendInvite,
  useSubscriptionDetails,
  useUpdateResource,
  useUpdateResourceProficiency,
} from "controller";
import { Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SecurityTab } from "./SecurityTab";
import { PermissionsTab } from "./PermissionTab";
import { PERMISSION_CONSTANT, TYPE } from "shared";
import { ProficiencyTab } from "./ProficiencyTab";

export const UpdateResourceModal = ({
  resourceId,
  onClose,
}: {
  resourceId: string;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);

  const generalRef = useRef<any>(null);
  const proficiencyRef = useRef<any>(null);

  const { data: isAcivated } = useGetResourceStatusById(resourceId);

  const loggedInResourceId = useGlobalStore((state) => state.resourceId);
  const setTimezone = useGlobalStore((state) => state.setTimeZone);
  const mspOwnerId = useGlobalStore((state) => state.mspOwnerId);
  const {
    data: resourceDetails,
    isLoading: isResourceDetailsLoading,
    refetch: refetchResourceDetails,
  } = useGetResourceById(resourceId);
  const { data: resourcePermissions } = useGetResourcePermissions(resourceId);
  const { data: proficiencyData } = useGetResourceProficiency(resourceId);
  const [permissionData, setPermissionData] = useState({});

  const role = useGlobalStore((state) => state.role);
  const trialPeriodEndDate = useGlobalStore((state) => state.trialEndDate);
  const { data: subscriptionDetails } = useSubscriptionDetails();
  const { data: activeResourceCount } = useGetActiveResourceCount();
  const mspId = useGlobalStore((state) => state.mspId);
  const [isNoSeatModalOpen, setIsNoSeatModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActivationSent, setIsActivationSent] = useState(false);

  const { mutateAsync: resourceResendInvite } = useResourceResendInvite();
  const { mutateAsync: updateResource, isLoading: updatingResource } =
    useUpdateResource();
  const {
    mutateAsync: updateResourceProficiency,
    isLoading: updatingProficiency,
  } = useUpdateResourceProficiency();

  const setIsResourceTimeZone = useGlobalStore(
    (state) => state.setIsResourceTimeZone
  );
  const subscriptionStatus = useGlobalStore(
    (state) => state.subscriptionStatus
  );
  useEffect(() => {
    if (resourceDetails) {
      setIsActive(!resourceDetails?.status);
    }
  }, [resourceDetails]);

  //setup permissions
  useEffect(() => {
    if (resourcePermissions) {
      let perissions = {};

      PERMISSION_CONSTANT.forEach((page) => {
        Object.values(page).map((mainPermission) => {
          if (mainPermission.type === TYPE.SINGLE) {
            perissions = {
              ...perissions,
              [mainPermission.name]: {
                permission_name: mainPermission.name,
                permission_key: mainPermission.rule,
                is_enabled:
                  resourcePermissions?.find(
                    (item: any) => item.permission_name === mainPermission.name
                  )?.is_enabled || false,
              },
            };
          } else {
            Object.values(mainPermission.contents).map((permission: any) => {
              perissions = {
                [permission.name]: {
                  permission_name: permission.name,
                  permission_key: permission.rule,
                  is_enabled:
                    resourcePermissions?.find(
                      (item: any) => item.permission_name === permission.name
                    )?.is_enabled || false,
                },
              };
            });
          }
        });
      });
      setPermissionData(perissions);
    }
  }, [resourcePermissions]);

  const onSaveClick = () => {
    switch (tabIndex) {
      case 0:
        generalRef?.current?.submit?.();
        break;
      case 2:
        proficiencyRef?.current?.submit?.();
    }
  };

  const handleAddResourceActiveClick = (data: { key: string; value: any }) => {
    if (subscriptionDetails && data?.value) {
      // if trial period is over
      if (trialPeriodEndDate && dayjs().isAfter(trialPeriodEndDate)) {
        // check if subscription is active
        if (subscriptionStatus === "active") {
          // check if seat limit is reached
          if (
            Number(subscriptionDetails?.seat_quantity) <=
              Number(activeResourceCount) &&
            !resourceDetails?.status === false
          ) {
            setIsNoSeatModalOpen(true);
            return;
          }
          // if seat limit is not reached
          setIsActive(data.value);
          return;
        } else {
          // if subscription is not active
          setIsNoSeatModalOpen(true);
          return;
        }
      } else {
        // check if subscription is active
        if (subscriptionStatus === "active") {
          // check if seat limit is reached
          if (
            Number(subscriptionDetails?.seat_quantity) <=
              Number(activeResourceCount) &&
            !resourceDetails?.status === false
          ) {
            setIsNoSeatModalOpen(true);
            return;
          }
          // if seat limit is not reached
          setIsActive(data.value);
          return;
        }
        setIsActive(data.value);
        return;
      }
    }
    setIsActive(data.value);
  };

  const resetInvitationHandler = async () => {
    setIsActivationSent(true);
    const email = resourceDetails?.email || "";
    if (email !== "") {
      try {
        const res = await resourceResendInvite({
          email,
          tenantId: mspId || "",
        });
        if (res?.data?.errors === undefined) {
          toast.success(res?.message || "invitation has been send");
          return;
        }
        setIsActivationSent(false);
        toast.error(res?.data?.errors || "Unable to send invitation");
      } catch (e) {
        setIsActivationSent(false);
        toast.error("Unable to send invitation");
      }
    } else {
      setIsActivationSent(false);
      toast.error("Invalid email");
    }
  };

  const updateResourceHandler = async (data: any) => {
    try {
      await updateResource({
        ...data,
        resourceId,
        is_locked: !isActive,
      });
      if (
        loggedInResourceId === resourceId &&
        data?.time_zone &&
        data?.time_zone !== ""
      ) {
        setIsResourceTimeZone(true);
        setTimezone(data?.time_zone);
      }
      toast.success("Resource updated successfully");
    } catch (e: any) {
      toast.error(e?.message || "Unable to update resource");
    }
  };

  const updateProficiencyHandler = async (data: any) => {
    console.log(
      "🚀 ~ file: UpdateResourceModal.tsx:225 ~ updateProficiencyHandler ~ data:",
      data
    );
    try {
      await updateResourceProficiency({
        data,
        resourceId,
      });
      toast.success("Resource updated successfully");
    } catch (e: any) {
      toast.error(e?.message || "Unable to update resource");
    }
  };

  return (
    <>
      <Modal
        open
        title="Resource"
        paperStyles={{
          height: "min( 80vh, 900px)",
        }}
        headerActionComponent={
          <Stack direction={"row"} gap={"16px"} alignItems={"center"}>
            {resourceId !== mspOwnerId && tabIndex === 0 && (
              <>
                <Typography variant="body-large">Active</Typography>
                <ToggleButton
                  isChecked={isActive}
                  onChange={(data) => {
                    handleAddResourceActiveClick(data);
                  }}
                />
              </>
            )}
            {isAcivated ? null : (
              <Button
                disabled={isActivationSent}
                variant="secondary"
                startIcon={<RecurringTaskIcon />}
                onClick={resetInvitationHandler}
              >
                Resend Invite
              </Button>
            )}
          </Stack>
        }
        colseable={false}
        additionalHeaderComponent={
          <Tabs
            value={tabIndex}
            onChange={(_, index) => setTabIndex(index)}
            sx={{ paddingLeft: "32px" }}
          >
            <Tab label={"General"}></Tab>
            <Tab label={"Security"}></Tab>
            <Tab label={"Proficiency"}></Tab>
            <Tab label={"Permissions"}></Tab>
          </Tabs>
        }
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={onClose}
        onPrimaryButtonClick={onSaveClick}
        isPrimaryButtonDisabled={
          isResourceDetailsLoading || updatingResource || updatingProficiency
        }
        isPrimaryButtonLoading={updatingResource || updatingProficiency}
      >
        {tabIndex === 0 ? (
          <GeneralTab
            ref={generalRef}
            resourceId={resourceId}
            resourceDetails={resourceDetails}
            onSubmit={updateResourceHandler}
          />
        ) : tabIndex === 1 ? (
          <SecurityTab
            data={resourceDetails}
            isResourceDetailsLoading={isResourceDetailsLoading}
            refetchResourceDetails={refetchResourceDetails}
          />
        ) : tabIndex === 2 ? (
          <ProficiencyTab
            ref={proficiencyRef}
            proficiencyData={proficiencyData}
            onSubmit={updateProficiencyHandler}
            resourceId={resourceId}
          />
        ) : (
          <PermissionsTab
            role={resourceDetails?.resource_type}
            data={permissionData}
          />
        )}
      </Modal>
      {isNoSeatModalOpen && (
        <Modal
          maxWidth="xs"
          open={isNoSeatModalOpen}
          onClose={() => setIsNoSeatModalOpen(false)}
          footer={<></>}
        >
          <Stack
            sx={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                gap: "26px",
              }}
            >
              <Stack
                sx={{
                  width: "68px",
                  height: "68px",
                  bgcolor: theme.palette["warning-minor"].tint,
                  borderRadius: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReportIcon
                  sx={{ width: "48px", height: "48px" }}
                  htmlColor={theme.palette["warning-minor"].main}
                />
              </Stack>
              <Typography variant="h3">Uh, oh!</Typography>
            </Stack>
            <Typography
              mt={"12px"}
              variant="body-large"
              color={theme.palette.lightBg.medium}
            >
              You’ve hit your seat limit. Upgrade now.
            </Typography>

            <Button
              sx={{ marginY: "54px" }}
              onClick={() =>
                role === "SUPERADMIN"
                  ? navigate("/app/control-center/subscription")
                  : setIsNoSeatModalOpen(false)
              }
            >
              {role === "SUPERADMIN" ? " Manage Subscription" : " Close"}
            </Button>
          </Stack>
        </Modal>
      )}
    </>
  );
};
