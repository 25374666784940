import { SvgIcon, SvgIconProps } from "@mui/material";

export const LeftDoubleArrowIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m16 16-4-4 4-4M11 16l-4-4 4-4"
      />
    </svg>
  </SvgIcon>
);
