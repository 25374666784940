function pngDocumentIcon(props) {
  return (
    <svg width="27" height="33" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path d="M18.8727 7.31534C18.1437 7.31499 17.4446 7.02522 16.929 6.50969C16.4135 5.99417 16.1237 5.29507 16.1234 4.566V0.75H3.83803C3.36673 0.75 2.90006 0.842845 2.46465 1.02324C2.02925 1.20364 1.63362 1.46805 1.30043 1.80137C0.96723 2.13469 0.70299 2.53039 0.522755 2.96586C0.342519 3.40133 0.249825 3.86805 0.25 4.33934V25.9674C0.249825 26.4387 0.342519 26.9054 0.522755 27.3408C0.70299 27.7763 0.96723 28.172 1.30043 28.5053C1.63362 28.8386 2.02925 29.1031 2.46465 29.2835C2.90006 29.4639 3.36673 29.5567 3.83803 29.5567H18.5847C19.056 29.5567 19.5227 29.4639 19.9581 29.2835C20.3935 29.1031 20.7891 28.8386 21.1223 28.5053C21.4555 28.172 21.7197 27.7763 21.9 27.3408C22.0802 26.9054 22.1729 26.4387 22.1727 25.9674V7.31668L18.8727 7.31534Z" fill="#F1C21B" />
      </g>
      <path d="M22.1724 7.31534H18.8724C18.1433 7.31499 17.4442 7.02522 16.9287 6.50969C16.4132 5.99417 16.1234 5.29507 16.123 4.566V0.75L22.1724 7.31534Z" fill="#E5D41E" />
      <path d="M24.7743 23.4961H8.10228C6.91965 23.4961 5.96094 24.4548 5.96094 25.6374V30.6081C5.96094 31.7907 6.91965 32.7494 8.10228 32.7494H24.7743C25.9569 32.7494 26.9156 31.7907 26.9156 30.6081V25.6374C26.9156 24.4548 25.9569 23.4961 24.7743 23.4961Z" fill="#E5D41E" />
      <path d="M9.48 29.69C9.34667 29.69 9.23333 29.6433 9.14 29.55C9.04667 29.4567 9 29.3433 9 29.21C9 29.0767 9.04667 28.9633 9.14 28.87C9.23333 28.7767 9.34667 28.73 9.48 28.73C9.61333 28.73 9.72667 28.7767 9.82 28.87C9.91333 28.9633 9.96 29.0767 9.96 29.21C9.96 29.3433 9.91333 29.4567 9.82 29.55C9.72667 29.6433 9.61333 29.69 9.48 29.69Z" fill="white" />
      <path d="M11.0604 29.66V26.08H12.3404C12.5238 26.08 12.6921 26.1083 12.8454 26.165C13.0021 26.2217 13.1371 26.3017 13.2504 26.405C13.3638 26.505 13.4521 26.6267 13.5154 26.77C13.5788 26.9133 13.6104 27.0717 13.6104 27.245C13.6104 27.4183 13.5788 27.5767 13.5154 27.72C13.4521 27.8633 13.3638 27.9867 13.2504 28.09C13.1371 28.19 13.0021 28.2683 12.8454 28.325C12.6921 28.3817 12.5238 28.41 12.3404 28.41H11.8404V29.66H11.0604ZM12.3654 27.67C12.5288 27.67 12.6504 27.63 12.7304 27.55C12.8104 27.47 12.8504 27.3683 12.8504 27.245C12.8504 27.1217 12.8104 27.02 12.7304 26.94C12.6504 26.86 12.5288 26.82 12.3654 26.82H11.8404V27.67H12.3654Z" fill="white" />
      <path d="M14.5927 26.08H15.4927L16.7827 28.29H16.8327L16.7827 27.6V26.08H17.5527V29.66H16.7327L15.3627 27.32H15.3127L15.3627 28.01V29.66H14.5927V26.08Z" fill="white" />
      <path d="M20.4355 27.67H22.2005C22.2105 27.71 22.2188 27.7617 22.2255 27.825C22.2355 27.8883 22.2405 27.9483 22.2405 28.005C22.2405 28.2583 22.2038 28.4833 22.1305 28.68C22.0571 28.8767 21.9505 29.05 21.8105 29.2C21.6505 29.37 21.4571 29.5033 21.2305 29.6C21.0038 29.6933 20.7455 29.74 20.4555 29.74C20.1955 29.74 19.9488 29.6933 19.7155 29.6C19.4855 29.5067 19.2838 29.3783 19.1105 29.215C18.9371 29.0483 18.8005 28.8517 18.7005 28.625C18.6005 28.395 18.5505 28.1433 18.5505 27.87C18.5505 27.5967 18.6005 27.3467 18.7005 27.12C18.8005 26.89 18.9371 26.6933 19.1105 26.53C19.2838 26.3633 19.4855 26.2333 19.7155 26.14C19.9488 26.0467 20.1955 26 20.4555 26C20.7588 26 21.0221 26.0517 21.2455 26.155C21.4721 26.2583 21.6705 26.4 21.8405 26.58L21.3105 27.1C21.1938 26.9833 21.0688 26.895 20.9355 26.835C20.8055 26.7717 20.6421 26.74 20.4455 26.74C20.2921 26.74 20.1471 26.7683 20.0105 26.825C19.8771 26.8783 19.7588 26.955 19.6555 27.055C19.5555 27.1517 19.4755 27.27 19.4155 27.41C19.3588 27.5467 19.3305 27.7 19.3305 27.87C19.3305 28.04 19.3588 28.195 19.4155 28.335C19.4755 28.4717 19.5571 28.59 19.6605 28.69C19.7638 28.7867 19.8838 28.8633 20.0205 28.92C20.1571 28.9733 20.3038 29 20.4605 29C20.7671 29 21.0121 28.9183 21.1955 28.755C21.2521 28.705 21.3038 28.6433 21.3505 28.57C21.4005 28.4933 21.4405 28.41 21.4705 28.32H20.4355V27.67Z" fill="white" />
      <path d="M14.1578 20.2699H8.26843C7.69189 20.2692 7.13916 20.0399 6.73148 19.6322C6.3238 19.2245 6.09446 18.6718 6.09375 18.0953V12.2059C6.09446 11.6294 6.3238 11.0767 6.73148 10.669C7.13916 10.2613 7.69189 10.032 8.26843 10.0312H14.1578C14.7342 10.032 15.2868 10.2613 15.6942 10.6691C16.1017 11.0768 16.3307 11.6295 16.3311 12.2059V18.0953C16.3307 18.6717 16.1017 19.2244 15.6942 19.6321C15.2868 20.0398 14.7342 20.2692 14.1578 20.2699ZM8.26843 10.9926C7.94674 10.9929 7.63831 11.1209 7.41084 11.3484C7.18337 11.5758 7.05543 11.8842 7.05508 12.2059V18.0953C7.05543 18.4169 7.18337 18.7254 7.41084 18.9528C7.63831 19.1803 7.94674 19.3082 8.26843 19.3086H14.1578C14.4793 19.3082 14.7876 19.1803 15.0149 18.9527C15.2421 18.7252 15.3698 18.4168 15.3698 18.0953V12.2059C15.3698 11.8844 15.2421 11.5759 15.0149 11.3484C14.7876 11.1209 14.4793 10.9929 14.1578 10.9926H8.26843Z" fill="#E5D41E" />
      <path d="M15.8502 16.9772V18.0972C15.8499 18.5462 15.6713 18.9767 15.3539 19.2942C15.0364 19.6116 14.6059 19.7902 14.1569 19.7905H8.26755C7.81845 19.7905 7.38775 19.6121 7.07018 19.2946C6.75262 18.977 6.57422 18.5463 6.57422 18.0972V16.2172C7.51689 16.0145 8.95288 15.9505 10.6955 16.6465L11.7756 15.6172L12.5115 17.4839C12.5115 17.4839 12.7089 16.7972 13.3462 16.8945C13.9835 16.9919 15.0155 17.3372 15.4569 17.0425C15.5758 16.9739 15.7155 16.9507 15.8502 16.9772Z" fill="#E5D41E" />
      <path d="M13.7875 13.4109C14.149 13.4109 14.4422 13.1178 14.4422 12.7562C14.4422 12.3947 14.149 12.1016 13.7875 12.1016C13.4259 12.1016 13.1328 12.3947 13.1328 12.7562C13.1328 13.1178 13.4259 13.4109 13.7875 13.4109Z" fill="#E5D41E" />
    </svg>

  );
}

export default pngDocumentIcon;