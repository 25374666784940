import { Immer } from "immer";
import { convertToHours } from "utilz/helpers/formatTimeDuration";
import { immer } from "zustand/middleware/immer";

interface CreateProjectModalState {
  createProject: {
    createProjectModalData: Record<string, any>;
    step: number;
    isModalVisible: boolean;
  };
}

interface CreateProjectAction {
  onCreateProjectStepChange: (step: number) => void;
  onCreateProjectModalVisibilityChange: (isModalVisible: boolean) => void;
  onCreateProjectModalDataChange: (data: Record<string, any>) => void;
  resetCreateProjectModal: () => void;
  populateProjectTemplateData: (data: Record<string, any>) => void;
}

export interface CreateProjectModalInterface
  extends CreateProjectAction,
    CreateProjectModalState {}

export const CreateProjectModalSlice = immer<
  CreateProjectModalState & CreateProjectAction
>((set, get) => ({
  createProject: {
    createProjectModalData: {},
    step: 1,
    isModalVisible: false,
  },
  onCreateProjectModalDataChange(data) {
    set((state) => {
      state.createProject.createProjectModalData = {
        ...state.createProject.createProjectModalData,
        ...data,
      };
    });
  },
  onCreateProjectModalVisibilityChange(isModalVisible) {
    set((state) => {
      state.createProject.isModalVisible = isModalVisible;
    });
  },
  onCreateProjectStepChange(step) {
    set((state) => {
      state.createProject.step = step;
    });
  },
  populateProjectTemplateData: (value) => {
    set((state) => {
      state.createProject.createProjectModalData = {
        ...state.createProject.createProjectModalData,
        project_description: value?.template_description,
        project_title: value?.template_summary,
        board: value?.board,
        priority: value?.priority,
        is_billable: value?.is_billable,
        urgency: value?.urgency,
        project_type: value?.project_type,
        project_subtype: value?.project_sub_type,
        time_approver: value?.time_approver,
        expense_approver: value?.expense_approver,
        budgeted_hours: convertToHours(value?.budgeted_hours || {}),
        milestones: value?.milestoneTaskData,
        location: value?.location,
        attachment: (Array.isArray(value?.attachments)
          ? value.attachments
          : []
        ).map((attachment: any) => {
          return {
            key: attachment.mapping_id,
            name: attachment.original_name,
            progress: 100,
            size: parseInt(attachment.size),
            type: attachment.file_type,
            status: "Uploaded",
            responseData: attachment,
          };
        }),
      };
    });
  },
  resetCreateProjectModal() {
    set((state) => {
      state.createProject = {
        createProjectModalData: {},
        step: 1,
        isModalVisible: false,
      };
    });
  },
}));
