import z from "zod";

export interface UpdateIntegrationStatusInput {
  status: boolean;
  source: string;
}

export interface GetRemoteDeviceListInput {
  customerUserId: string;
  sourceName: string;
}

export const NCentralCustomersSchema = z.object({
  customer_id: z.string(),
  customer_name: z.string(),
  ncentral_customer_id: z.number(),
});

export const NCentralDeviceSchema = z.array(
  z.object({
    deviceId: z.number(),
    uri: z.string().nullable().optional(),
    remoteControlUri: z.string().nullable().optional(),
    sourceUri: z.string().nullable().optional(),
    longName: z.string(),
    deviceClass: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    isProbe: z.boolean().nullable().optional(),
    osId: z.string().nullable().optional(),
    supportedOs: z.string().nullable().optional(),
    discoveredName: z.string().nullable().optional(),
    deviceClassLabel: z.string().nullable().optional(),
    supportedOsLabel: z.string().nullable().optional(),
    lastLoggedInUser: z.string().nullable().optional(),
    stillLoggedIn: z.string().nullable().optional(),
    licenseMode: z.string().nullable().optional(),
    customerId: z.number(),
    soName: z.string().nullable().optional(),
    customerName: z.string().nullable().optional(),
    siteName: z.string().nullable().optional(),
  })
);

export interface AddNCentralConfigInput {
  webToken: string;
  baseUrl: string;
  board: string;
}

export interface NcentralCustomerMappingInput {
  customerData: Array<{
    external_customer_id: string;
    external_customer_name: string;
    deskday_customer_id: string;
    deskday_customer_name: string;
  }>;
  source: string;
}

export interface NcentralDeviceMappingInput {
  deviceMapping: Array<{
    mapping_id: string;
    devices: Array<{
      device_id: string;
      device_name: string;
      deskday_customer_user_id: string;
      deskday_customer_user_name: string;
      remote_control_uri: string;
      meta_data: Record<string, any>;
    }>;
  }>;
  source: string;
}

export interface UpdateNcentralRemoteCredentialsInput {
  username: string;
  password: string;
}
export const NCentralConfigSchema = z
  .object({
    id: z.string(),
    msp_id: z.string(),
    web_token: z.string(),
    base_url: z.string(),
    board: z.string(),
    created_by: z.string(),
    created_at: z.string(),
    updated_at: z.string(),
    updated_by: z.string(),
    is_deleted: z.boolean(),
  })
  .nullable();
