import { useQuery } from "@tanstack/react-query";
import { fetchAllResourceTypes } from "controller/api/control-center/resources/resource-type";
import { ApiErrorType } from "shared";
import { sentryLogger } from "utilz";

export const useGetAllResourceTypes = ({
  mspId,
}: {
  mspId?: string | null;
}) => {
  return useQuery({
    enabled: !!mspId,
    queryKey: ["resourceType", mspId],
    queryFn: () => fetchAllResourceTypes(),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "fetchAllResourceTypes",
      }),
  });
};
