import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Button,
  Avatar,
  CheckIcon,
  Drawer,
  Input,
  SingleUserIcon,
  UserGroupIcon,
} from "components";
import { useGetAllBoards, useGetAllResources } from "controller";
import { useGlobalStore } from "controller/store/globalStore";
import React, { useCallback, useEffect, useState } from "react";
import { emptyStringHandler } from "utilz/helpers";

export const ResourceFilter = ({
  selectedResourceId,
  onTabChange,
  isMinimal = false,
  isSingleSelect = false,
}: {
  selectedResourceId?: Array<string> | null;
  onTabChange: (e: Array<string> | null, data?: Array<any> | null) => void;
  isMinimal?: boolean;
  isSingleSelect?: boolean;
}) => {
  const theme = useTheme();
  const resourceId = useGlobalStore((state) => state.resourceId);
  const [selectedTab, setSelectedTab] = useState<"me" | "resource">("me");

  const [tab, setTab] = useState<"resource" | "board">("resource");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [selectedResource, setSelectedResource] = React.useState<
    Array<string> | null | undefined
  >(selectedResourceId ? selectedResourceId : null);

  const [selectedBoard, setSelectedBoard] =
    React.useState<Array<string> | null>();
  const { data: allResources } = useGetAllResources({
    perPageCount: 50,
    sortColumn: "users.first_name",
    ...(searchQuery && tab === "resource" && { searchQuery: searchQuery }),
  });
  // const { data: allBoards } = useGetAllBoards({
  //   perPageCount: 50,
  //   ...(searchQuery && tab === "board" && { searchQuery: searchQuery }),
  // });

  // useEffect(() => {
  //   if (selectedResourceId) {
  //     setSelectedTab("resource");
  //     setSelectedResource(selectedResourceId);
  //   } else {
  //     setSelectedResource(null);
  //   }
  // }, [selectedResourceId]);

  // TODO: when adding boards to this filter add a new argument "type"
  const handleItemClick = ({ id, data }: { id: string; data: any }) => {
    const idIsSelected = selectedResource?.includes(id);
    if (isSingleSelect) {
      if (idIsSelected && resourceId) {
        setSelectedResource([resourceId]);
        onTabChange([resourceId]);
      } else {
        setSelectedResource([id]);
        onTabChange([id], [data]);
      }
      setIsDrawerOpen(false);
    } else {
      if (idIsSelected) {
        setSelectedResource(
          selectedResource?.filter((resource) => resource !== id)
        );
      } else {
        setSelectedResource([...(selectedResource || []), id]);
      }
    }
  };

  const checkIfOnlyMeSelected = () => {
    if (selectedResource?.length === 1 && selectedResource[0] === resourceId) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (selectedResourceId) {
      if (
        selectedResourceId?.length === 0 ||
        (selectedResourceId?.length === 1 &&
          selectedResourceId[0] === resourceId)
      ) {
        setSelectedResource([]);
        setSelectedTab("me");
      } else {
        setSelectedResource(selectedResourceId);
        setSelectedTab("resource");
      }
    }
  }, [selectedResourceId]);

  // used only when isSingleSelect is false
  const handlePrimaryButtonClick = () => {
    if (!resourceId) return;
    if (selectedResource?.length === 0) {
      onTabChange([resourceId]);
    } else if (checkIfOnlyMeSelected()) {
      setSelectedResource([]);
      onTabChange([resourceId]);
      setSelectedTab("me");
    } else {
      selectedResource && onTabChange(selectedResource);
    }
    setIsDrawerOpen(false);
  };

  const handleSecondaryButtonClick = () => {
    if (!resourceId) return;
    setSelectedResource([resourceId]);
    setSelectedTab("me");
    onTabChange([resourceId]);
    setIsDrawerOpen(false);
  };

  const toggleSelectAll = () => {
    if (selectedResource?.length !== allResources?.result?.length) {
      setSelectedResource(
        allResources?.result?.map((item: any) => item.mapping_id)
      );
    } else {
      setSelectedResource([]);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "4px",
          border: `1px solid ${theme.palette.lightBg.high}`,
          overflow: "clip",
          height: {
            sm: "32px",
          },
        }}
      >
        <Stack
          direction="row"
          onClick={() => {
            setSelectedTab("me");
            // @ts-ignore
            setSelectedResource([resourceId]);
            resourceId && onTabChange([resourceId] || []);
          }}
          sx={{
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            padding: "10px 8px",
            ...(selectedTab === "me" && {
              bgcolor: "#E7E9FE",
              color: theme.palette.purple.main,
            }),
          }}
        >
          <SingleUserIcon fontSize="small" />
          {!isMinimal && (
            <Typography variant="button-small" color={"inherit"}>
              Me
            </Typography>
          )}
        </Stack>
        <Stack
          onClick={() => {
            setIsDrawerOpen(true);
            setSelectedTab("resource");
          }}
          direction="row"
          sx={{
            cursor: "pointer",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            padding: "10px 8px",

            ...(selectedTab === "resource" && {
              bgcolor: "#E7E9FE",
              color: theme.palette.purple.main,
            }),
          }}
        >
          <UserGroupIcon fontSize="small" />
          {!isMinimal && (
            <Typography variant="button-small" color={"inherit"}>
              Resources
            </Typography>
          )}
          {selectedResource && selectedResource?.length > 0 && !isMinimal && (
            <Box
              sx={{
                bgcolor: theme.palette.error.main,
                minHeight: "16px",
                px: "4px",
                borderRadius: "8px",
                minWidth: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="caption" color={"#FFF"}>
                {selectedResource?.length}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>

      <Drawer
        open={isDrawerOpen}
        onPrimaryButtonClick={handlePrimaryButtonClick}
        onSecondaryButtonClick={handleSecondaryButtonClick}
        onClose={() => {
          setIsDrawerOpen(false);
          if (!selectedResource || selectedResource.length === 0) {
            setSelectedTab("me");
            resourceId && onTabChange([resourceId]);
          }
        }}
        onOpen={() => {}}
        footer={isSingleSelect ? <></> : undefined}
        // footer={<></>}
        title="Resources"
        additionalHeaderComponent={
          <>
            {/* <Stack
              direction="row"
              sx={{ borderBottom: `1px solid ${theme.palette.neutral["015"]}` }}
            >
              <Box
                sx={{
                  borderBottom: `4px solid ${
                    tab === "resource"
                      ? theme.palette.purple.main
                      : "transparent"
                  }`,
                }}
              >
                <Button
                  variant="ghost"
                  onClick={() => {
                    setSearchQuery("");
                    setTab("resource");
                  }}
                  sx={{
                    paddingX: "0px",
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Typography
                    variant="body-large"
                    sx={{
                      color:
                        tab === "resource"
                          ? theme.palette.purple.main
                          : theme.palette.lightBg.low,
                    }}
                  >
                    Resources
                  </Typography>
                </Button>
              </Box>
              <Box
                sx={{
                  borderBottom: `4px solid ${
                    tab === "board" ? theme.palette.purple.main : "transparent"
                  }`,
                }}
              >
                <Button
                  variant="ghost"
                  onClick={() => {
                    setSearchQuery("");
                    setTab("board");
                  }}
                  sx={{
                    paddingX: "0px",
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        tab === "board"
                          ? theme.palette.purple.main
                          : theme.palette.lightBg.low,
                    }}
                    variant="body-large"
                  >
                    Boards
                  </Typography>
                </Button>
              </Box>
            </Stack> */}

            <Box
              sx={{
                paddingX: "24px",
                paddingTop: "16px",
                borderTop: `1px solid ${theme.palette.neutral["015"]}`,
              }}
            >
              <Input
                placeholder="Search"
                name={"search"}
                fullWidth
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </Box>
            {!isSingleSelect && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingLeft: "24px", paddingBottom: "16px" }}
              >
                <Typography variant="caption">Resources</Typography>
                <Button
                  onClick={toggleSelectAll}
                  variant="ghost"
                  color="primary"
                  sx={{
                    ":hover": {
                      bgcolor: "transparent",
                    },
                  }}
                >
                  {selectedResource?.length === allResources?.result?.length
                    ? "Clear all"
                    : "Select all"}
                </Button>
              </Stack>
            )}
          </>
        }
      >
        <Box>
          {/* Resource list */}
          {tab === "resource" ? (
            <DataList
              allData={allResources?.result}
              selectedData={selectedResource}
              onItemClick={(value, data) => {
                handleItemClick({ id: value, data: data });
              }}
              type="resource"
            />
          ) : (
            <DataList
              allData={[]}
              selectedData={selectedBoard}
              onItemClick={() => {}}
              type="board"
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

const DataList = ({
  allData,
  onItemClick,
  selectedData,
  type,
}: {
  allData: Array<any>;
  selectedData?: Array<string> | null;
  onItemClick: (value: string, data?: any) => void;
  type: "resource" | "board";
}) => {
  const theme = useTheme();

  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      {allData?.map((item: any) => {
        const mappingId =
          type === "board" ? item.ticket_board_id : item.mapping_id;
        const isSelected = selectedData?.includes(mappingId);
        return (
          <ListItem
            key={item.mapping_id}
            secondaryAction={
              <>
                {isSelected && (
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "100%",
                      bgcolor: theme.palette.success.main,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckIcon
                      sx={{ width: "12px", height: "12px" }}
                      fontSize="small"
                      htmlColor="#FFF"
                    />
                  </Box>
                )}
              </>
            }
            disablePadding
          >
            <ListItemButton
              onClick={() => onItemClick(mappingId, item)}
              disableRipple
              sx={{
                minHeight: "42px",
                bgcolor: isSelected
                  ? theme.palette.success.tint
                  : "transparent",
                ":hover": {
                  bgcolor: theme.palette.neutral["B-005"],
                },
              }}
            >
              {type === "board" ? (
                <Typography
                  variant="body-small"
                  sx={{ textTransform: "capitalize" }}
                >
                  {item.ticket_board_name}
                </Typography>
              ) : (
                <>
                  <ListItemAvatar>
                    <Avatar
                      src={""}
                      fullname={`${item?.first_name} ${item?.last_name}`}
                    />
                  </ListItemAvatar>
                  <Typography
                    variant="body-small"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {emptyStringHandler(item?.first_name)}{" "}
                    {emptyStringHandler(item?.last_name)}
                  </Typography>
                </>
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
