import { Drawer } from "components";
import { BillingEmailFields } from "./BillingEmailFields";
import { useState, useRef } from "react";
import { BillingExpenseFields } from "./BillingExpenseFields";
import { BillingProductFields } from "./BillingProductFields";
import { BillingTaxFields } from "./BillingTaxFields";
export const DrawerComponent = (data: any) => {
  const { isDrawerOpen, closeDrawer, componentName, mode } = data;
  const buttonRef = useRef<any>(null);

  let DrawerContent;
  switch (componentName) {
    case "Billing Templates":
      DrawerContent = BillingEmailFields;
      break;
    case "Add Expense":
      DrawerContent = BillingExpenseFields;
      break;
    case "Add Product":
      DrawerContent = BillingProductFields;
      break;
    case "Add Tax":
      DrawerContent = BillingTaxFields;
      break;
    default:
      DrawerContent = null;
  }
  return (
    <>
      <Drawer
        onOpen={() => {}}
        isPrimaryButtonDisabled={mode?.primaryButtonDisable ? true : false}
        open={isDrawerOpen}
        // onClose={closeDrawer}
        onClose={() => {
          buttonRef.current.cancelButtonClick();
        }}
        title={componentName}
        headerTitleStyleSx={{ marginLeft: "8px" }}
        primaryButtonText={mode?.method === "create" ? "Save" : "Update"}
        secondaryButtonText="Cancel"
        footerStyles={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        onPrimaryButtonClick={() => {
          buttonRef.current.saveButtonClick();
        }}
        onSecondaryButtonClick={() => {
          buttonRef.current.cancelButtonClick();
        }}
      >
        {componentName === "Billing Templates" && (
          <BillingEmailFields
            ref={buttonRef}
            closeDrawer={closeDrawer}
            mode={mode}
          />
        )}
        {componentName == "Add Expense" && (
          <BillingExpenseFields
            ref={buttonRef}
            closeDrawer={closeDrawer}
            mode={mode}
          />
        )}
        {componentName == "Add Product" && (
          <BillingProductFields
            ref={buttonRef}
            closeDrawer={closeDrawer}
            mode={mode}
          />
        )}

        {componentName == "Add Tax" && (
          <BillingTaxFields
            ref={buttonRef}
            closeDrawer={closeDrawer}
            mode={mode}
          />
        )}
        {/* {DrawerContent && (
          <DrawerContent ref={buttonRef} closeDrawer={closeDrawer} />
        )} */}
      </Drawer>
    </>
  );
};
