import React, { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import refreshLottie from "assets/lottie/loader.json";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  addAttributeToIntercom,
  useGetIntercomHash,
  useGetMspById,
  useGetResourceById,
  useGlobalStore,
} from "controller";
import { useGetMspGeneralConfig } from "controller/hooks/configurations/general";
import { debugConsole, emptyStringHandler } from "utilz/helpers";
import { Sentry, dayjs, mixpanel } from "utilz";
import { RESOURCE_TYPES } from "pages/modules/control-center/resources-and-permissions/constants";
import { NoSubscriptionScreen } from "pages/modules/control-center/subscription/components";
import { TrialPeriodBanner } from "components/banner/TrialPeriodBanner";
// @ts-ignore
import GearImgSvg from "assets/images/gearsImg.svg";
import { TopBarProfileButton } from "./component/top-bar/top-bar-profile-button";
import { NoSubscriptionBanner } from "components";
import { MqttContext } from "controller/context/MqttContext";
import { useQueryClient } from "@tanstack/react-query";
// @ts-ignore
import loaderBg from "assets/images/loaderBg.svg";
// @ts-ignore
import loaderGif from "assets/Loader.gif";
import { SampleDataHomeScreen } from "pages/sample-data";

const signoutBroadcast = new BroadcastChannel("signout_channel");

const sampleProgress = 0;
export const MainWrapper = ({ children }: { children: React.ReactNode }) => {
  const { data: intercomHashData } = useGetIntercomHash();
  // Globals

  // fetch global datas
  const queryClient = useQueryClient();
  const resourceId = useGlobalStore((state) => state.resourceId);
  const profilePictureUrl = useGlobalStore(
    (state) => state.profilePictureUrl || ""
  );
  const setprofilePictureUrl = useGlobalStore(
    (state) => state.setprofilePictureUrl
  );

  const [loggedIn, setLoggedIn] = useState(true);
  const setMspOwner = useGlobalStore((state) => state.setMspOwnerId);
  const setRefreshing = useGlobalStore((state) => state.setIsRefreshing);
  const setTimezone = useGlobalStore((state) => state.setTimeZone);
  const setDateFormat = useGlobalStore((state) => state.setDateFormat);
  const setUserRole = useGlobalStore((state) => state.setUserRole);
  const role = useGlobalStore((state) => state.role);
  const setName = useGlobalStore((state) => state.setName);
  const setIsResourceTimeZone = useGlobalStore(
    (state) => state.setIsResourceTimeZone
  );
  const resetProfile = useGlobalStore((state) => state.resetCredentials);
  const setGeneralConfig = useGlobalStore((state) => state.setGeneralConfig);
  const setTrialEndDate = useGlobalStore((state) => state.setTrialEndDate);
  const setSampleDataStatus = useGlobalStore(
    (state) => state.setSampleDataStatus
  );
  const setDefaultData = useGlobalStore((state) => state.setDefaultData);
  const setIsTicketTImeFormat24Hour = useGlobalStore(
    (state) => state.setIsTicketTImeFormat24Hour
  );
  const setIsTicketTimeZoneIsResource = useGlobalStore(
    (state) => state.setIsTicketTimeZoneIsResource
  );
  const mspId = useGlobalStore((state) => state.mspId);
  const { data: resourceData } = useGetResourceById(resourceId || "");
  const { data: mspData, refetch: refetchMspData } = useGetMspById(mspId || "");
  const { data: generalConfigData } = useGetMspGeneralConfig(mspId);
  const setSubscriptionData = useGlobalStore(
    (state) => state.setSubscriptionData
  );
  const setBillingCycle = useGlobalStore((state) => state.setBillingCycle);
  const sampleDataStatus = useGlobalStore((state) => state.sampleDataStatus);
  const setCurrency = useGlobalStore((state) => state.setCurrency);

  useEffect(() => {
    if (!!mspData && !!resourceData && !!generalConfigData) {
      setLoggedIn(true);
      setMspDetails(mspData, generalConfigData);
      setRefreshing(false);
    }
  }, [mspData, resourceData, generalConfigData]);

  // signout channel listener to signout from multiple tabs
  useEffect(() => {
    signoutBroadcast.onmessage = (ev) => {
      if (ev.data === "signout") {
        setLoggedIn(false);
        resetProfile();
        localStorage.clear();
        sessionStorage.clear();
        // eslint-disable-next-line no-undef
        window.location.href = `${import.meta.env.VITE_APP_AUTH_FRONT_URL}`;
      }
    };
  }, []);

  function setMspDetails(mspData: any, generalConfig: any) {
    try {
      setSubscriptionData({
        subscriptionStatus: mspData?.status,
        cancelAtPeriodEnd: mspData?.cancel_at_period_end,
        currentAccessBlockDate: mspData?.current_access_block_date,
      });

      setBillingCycle(mspData?.billing_cycle);
    } catch (error) {
      debugConsole(error);
    }

    let role = "SUPERADMIN";

    setDefaultData({ type: "customer", value: mspData?.sub_tenant_id });
    setMspOwner(mspData?.msp_owner_id);
    setName(
      `${emptyStringHandler(resourceData?.first_name)} ${emptyStringHandler(
        resourceData?.last_name
      )}`
    );

    // set timezone,
    // if resource timezone is not empty then uses it
    // else if msp tomezone is not empty then uses it
    // else set browser timezone

    const timezone =
      resourceData?.time_zone &&
      resourceData?.time_zone !== null &&
      resourceData?.time_zone !== ""
        ? resourceData?.time_zone
        : mspData?.time_zone &&
          mspData?.time_zone !== null &&
          mspData?.time_zone !== ""
        ? mspData?.time_zone
        : dayjs.tz.guess();

    setIsResourceTimeZone(
      resourceData?.time_zone &&
        resourceData?.time_zone !== null &&
        resourceData?.time_zone !== ""
    );

    /// set date format based on company details. if time format is set then uses it or uses the default date format
    if (
      mspData.date_format &&
      mspData.date_format !== null &&
      mspData.date_format !== ""
    ) {
      setDateFormat(mspData.date_format);
    }

    setIsTicketTImeFormat24Hour(generalConfig?.is_time_format_is_12_hr);
    setIsTicketTimeZoneIsResource(generalConfig?.is_time_zone_is_resources);
    setprofilePictureUrl(resourceData?.profile_image);

    setGeneralConfig({
      defaultTicketBoard: generalConfig?.default_ticket_board,
      defaultTicketBoardId: generalConfig?.ticket_board_id,
      defaultProjectBoard: generalConfig?.project_board,
      defaultProjectBoardId: generalConfig?.project_board_id,
    });

    setTrialEndDate(mspData?.trial_end_date);
    setSampleDataStatus(mspData?.sample_data);

    const currentRole = Object.keys(RESOURCE_TYPES).find(
      //@ts-ignore
      (item: any) => RESOURCE_TYPES[item] === resourceData.resource_type_id
    );

    if (currentRole) {
      role = currentRole;
    }
    //@ts-ignore
    setUserRole(role);
    setTimezone(timezone || "America/Toronto");
    setCurrency({
      defaultCurrency: mspData?.default_currency,
      currencyName: mspData?.currency_name,
      currencyCode: mspData?.currency_code,
      symbol: mspData?.symbol,
      position: mspData?.position,
    });
  }

  useEffect(() => {
    if (intercomHashData && resourceData) {
      activateIntercom(intercomHashData);
      addSentryScope();
      addMixpanelData({ resourceData, mspData });
    }
  }, [intercomHashData, resourceData]);

  // adding data to mixpanel
  const addMixpanelData = ({
    resourceData,
    mspData,
  }: {
    resourceData: Record<string, any>;
    mspData: Record<string, any>;
  }) => {
    mixpanel.identify(resourceData?.mapping_id);
    mixpanel.people.set({
      $email: resourceData?.email,
      $name: `${resourceData?.first_name} ${resourceData?.last_name}`,
      resourceType: resourceData?.resource_type_name,
      mspId: mspData?.mapping_id,
      mspEmail: mspData?.business_email,
      msp: mspData?.company_name,
    });
  };
  // adding user scope to sentry user
  const addSentryScope = () => {
    Sentry.setUser({ email: resourceData.email, id: resourceData?.mapping_id });
  };
  const activateIntercom = (data: any) => {
    try {
      setTimeout(() => {
        //@ts-ignore
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "vjbuario",
          email: resourceData.email,
          user_hash: data?.hash, // HMAC using SHA-256  };
        });
        const currentRole = Object.keys(RESOURCE_TYPES).find(
          //@ts-ignore
          (item: any) => RESOURCE_TYPES[item] === resourceData.resource_type_id
        );
        // adding role to intercom
        addAttributeToIntercom({ event: `${currentRole}` });
      }, 3400);

      // only relaod intercom if user is on home page
      const locationArr = window.location.href.split("/");
      if (locationArr[locationArr.length - 1] === "home") {
        setTimeout(() => {
          //@ts-ignore
          window.Intercom("update");
        }, 64000);
      }
    } catch (error) {
      debugConsole(error);
    }
  };

  const [isDataRefreshing, setIsDataRefreshing] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsDataRefreshing(false);
    }, 3200);
  }, []);

  const checkSubscriptionStatus = () => {
    // check if tried period is over
    if (dayjs(mspData?.server_time).isBefore(dayjs(mspData?.trial_end_date))) {
      return true;
    }
    // need to check if current access block date is over
    // sometimes status is not updated by stripe
    if (
      dayjs(mspData?.server_time).isAfter(
        dayjs(mspData?.current_access_block_date)
      )
    ) {
      return false;
    }
    if (mspData?.status === "active") {
      return true;
    }
    if (
      dayjs(mspData?.server_time).isBefore(
        dayjs(mspData?.current_access_block_date)
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!loggedIn || isDataRefreshing || !mspData ? (
        <Box
          sx={{
            position: "absolute",
            top: "225px",
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "#FFF",
            zIndex: 1000000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "520px", height: "520px" }}>
            <RefreshComponent />
          </Box>
        </Box>
      ) : (
        <>
          {sampleDataStatus === "pending" ? (
            <SampleDataHomeScreen />
          ) : checkSubscriptionStatus() ? (
            <>{children}</>
          ) : (
            <>
              {role === "SUPERADMIN" ? (
                <Stack>
                  <Stack
                    px={"32px"}
                    py="6px"
                    direction={"row"}
                    justifyContent={"flex-end"}
                  >
                    <TopBarProfileButton />
                  </Stack>
                  <NoSubscriptionBanner />
                  <NoSubscriptionScreen isFullPage={true} />
                </Stack>
              ) : (
                loggedIn && <NoSubscriptionForNonSuperAdmin />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const NoSubscriptionForNonSuperAdmin = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
        {/* <NoSubscriptionComponent /> */}
        <img src={GearImgSvg} alt="gear" />
        <Typography
          mt={"24px"}
          variant="h6"
          color={theme.palette.lightBg.medium}
        >
          Access temporarily suspended.
        </Typography>
        <Typography
          mt={"4px"}
          variant="body-medium"
          color={theme.palette.lightBg.medium}
        >
          Please contact your admin for plan renewal and account reactivation.
        </Typography>
      </Stack>
    </Stack>
  );
};

const RefreshComponent = () => {
  return <Lottie animationData={refreshLottie} loop={true} />;
};
