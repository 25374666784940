import { useQuery } from "@tanstack/react-query";
import {
  getGeneralActivities,
  getMspGeneralConfig,
} from "controller/api/configurations/general";
import { ApiErrorType } from "shared";
import { sentryLogger } from "utilz";

export const useGetMspGeneralConfig = (mspId: string | null) => {
  return useQuery({
    enabled: mspId && mspId !== null ? true : false,
    queryKey: ["general_configuration", mspId],
    queryFn: () => getMspGeneralConfig(),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getMspGeneralConfig",
      }),
  });
};

export const useGetgeneralActivities = (mspId: string | null) => {
  return useQuery({
    queryKey: ["general_activities", mspId],
    queryFn: () => getGeneralActivities(),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getGeneralActivities",
      }),
  });
};
