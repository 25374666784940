import { fetchCall } from "../../../utilz";
import { appConfig } from "../../../constants";
import axiosUploadCall from "../../../utilz/axios-call";

//-------- GENERAL ------------------

//fetch MSP Configuration
const fetchMspConfiguration = (id) =>
  fetchCall("/configuration/general", appConfig.requestMethods.GET);

//update msp Configuration
const updateMspConfiguration = (id, data) =>
  fetchCall("/configuration/general", appConfig.requestMethods.PATCH, data);

//-------- GENERAL END --------------

//---------- BOARD ---------------

//fetch All ticket boards
const fetchAllTicketBoards = (
  id,
  perPage,
  page,
  sortColumn,
  sortDirection,
  searchQuery
) =>
  fetchCall(
    `/configuration/fetch/ticket_board/msp/${id}?filters=[]&per_page=${perPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );
//fetch All ticket boards
const fetchAllTicketBoardsforList = (id) =>
  fetchCall(
    `/configuration/fetch/ticket_board/msp/${id}?filters=[]`,
    appConfig.requestMethods.GET
  );
//fetch ticket board by id
const fetchTicketBoardById = (board_id) =>
  fetchCall(
    `/configuration/fetch/ticket_board/${board_id}`,
    appConfig.requestMethods.GET
  );
// create new ticket board
const createTicketBoard = (id, data) =>
  fetchCall(
    `/configuration/create/ticket_board/${id}`,
    appConfig.requestMethods.POST,
    data
  );
//update ticket board
const updateTicketBoard = (board_id, data) =>
  fetchCall(
    `/configuration/update/ticket_board/${board_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
//delete ticket board
const deleteTicketBoard = (board_id) =>
  fetchCall(
    `/configuration/delete/ticket_board/${board_id}`,
    appConfig.requestMethods.DELETE
  );

//fetch All request boards
const fetchAllRequestBoards = (
  id,
  perPage,
  page,
  sortColumn,
  sortDirection,
  searchQuery
) =>
  fetchCall(
    `/configuration/fetch/request_board/msp/${id}?filters=[]&per_page=${perPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );
//fetch All request boards for  components like select field where all data needs to be listed without pagination
const fetchAllRequestBoardsforList = (id) =>
  fetchCall(
    `/configuration/fetch/request_board/msp/${id}?filters=[]`,
    appConfig.requestMethods.GET
  );
//fetch request board by id
const fetchRequestBoardById = (board_id) =>
  fetchCall(
    `/configuration/fetch/request_board/${board_id}`,
    appConfig.requestMethods.GET
  );
// create new request board
const createRequestBoard = (id, data) =>
  fetchCall(
    `/configuration/create/request_board/${id}`,
    appConfig.requestMethods.POST,
    data
  );
//update request board
const updateRequestBoard = (board_id, data) =>
  fetchCall(
    `/configuration/update/request_board/${board_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
//delete request board
const deleteRequestBoard = (board_id) =>
  fetchCall(
    `/configuration/delete/request_board/${board_id}`,
    appConfig.requestMethods.DELETE
  );

//fetch All project boards
const fetchAllProjectBoards = (
  id,
  perPage,
  page,
  sortColumn,
  sortDirection,
  searchQuery
) =>
  fetchCall(
    `/configuration/fetch/project_board/msp/${id}?filters=[]&per_page=${perPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );
//fetch All project boards for components like select field where all data needs to be listed without pagination
const fetchAllProjectBoardsforList = (id) =>
  fetchCall(
    `/configuration/fetch/project_board/msp/${id}?filters=[]`,
    appConfig.requestMethods.GET
  );
//fetch project board by id
const fetchProjectBoardById = (board_id) =>
  fetchCall(
    `/configuration/fetch/project_board/${board_id}`,
    appConfig.requestMethods.GET
  );
// create new project board
const createProjectBoard = (id, data) =>
  fetchCall(
    `/configuration/create/project_board/${id}`,
    appConfig.requestMethods.POST,
    data
  );
//update project board
const updateProjectBoard = (board_id, data) =>
  fetchCall(
    `/configuration/update/project_board/${board_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
//delete project board
const deleteProjectBoard = (board_id) =>
  fetchCall(
    `/configuration/delete/project_board/${board_id}`,
    appConfig.requestMethods.DELETE
  );

//----------- BOARD END ------------------

//----------- CHECKLIST ------------------

//Fetch all checklist
const fetchAllChecklist = (
  msp_id,
  perPage,
  page,
  sortColumn,
  sortDirection,
  filters,
  searchQuery
) =>
  fetchCall(
    `/v2/configuration/checklist?per_page=${perPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=[${filters}]&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );

const fetchCompleteChecklist = (msp_id) =>
  fetchCall(
    "/v2/configuration/checklist?filters=[]",
    appConfig.requestMethods.GET
  );

//Fetch a checklist
const fetchCheckListById = (checklist_id) =>
  fetchCall(
    `/v2/configuration/checklist/${checklist_id}`,
    appConfig.requestMethods.GET
  );

//create Checklist
const createChecklist = (msp_id, data) =>
  fetchCall(
    "/v2/configuration/checklist",
    appConfig.requestMethods.POST,
    data
  );

//create Checklist Item
const createChecklistItem = (checklist_id, data) =>
  fetchCall(
    `/configuration/create/checklist_item/${checklist_id}`,
    appConfig.requestMethods.POST,
    data
  );

//update Checklist
const updateChecklist = (checklist_id, data) =>
  fetchCall(
    `/v2/configuration/checklist/${checklist_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//update checklist item
const updateChecklistItem = (checklist_item_id, data) =>
  fetchCall(
    `/configuration/update/checklist_item/${checklist_item_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//delete checklist
const deleteChecklist = (checklist_id) =>
  fetchCall(
    `/configuration/delete/checklist/${checklist_id}`,
    appConfig.requestMethods.DELETE
  );

//delete checklist item
const deleteChecklistItem = (checklist_item_id) =>
  fetchCall(
    `}/configuration/delete/checklist_item/${checklist_item_id}`,
    appConfig.requestMethods.DELETE
  );

//----------- CHECKLIST END ---------------

//----------- SLA -------------------------

// fetch all SLA
const fetchAllSla = (
  msp_id,
  perPage,
  page,
  sortColumn,
  sortDirection,
  filters,
  searchQuery
) =>
  fetchCall(
    `/v2/configuration/sla?per_page=${perPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=[${filters}]&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );
// fetch complete SLA without pagenation (for selectfield)
const fetchCompleteSla = (msp_id) =>
  fetchCall(
    // `/fetch/msp/sla/${msp_id}?filters=[]`,
    "/v2/configuration/sla?filters=[]",
    appConfig.requestMethods.GET
  );

// fetch selected SLA by id
const fetchSlaById = (sla_id) =>
  fetchCall(`/v2/configuration/sla_rules/${sla_id}`, appConfig.requestMethods.GET);

//create SLA
const createSla = (msp_id, data) =>
  fetchCall("/v2/configuration/sla", appConfig.requestMethods.POST, data);

//update SLA
const updateSla = (sla_Id, data) =>
  fetchCall(`/v2/configuration/sla/${sla_Id}`, appConfig.requestMethods.PATCH, data);

//delete SLA
const deleteSlabyId = (sla_id) =>
  fetchCall(`/delete/msp/sla/${sla_id}`, appConfig.requestMethods.DELETE);

//----------- SLA END ---------------------

//----------- TEMPLATE --------------------

//fetch all ticket templates
const fetchAllTicketTemplates = (
  msp_id,
  per_page,
  page,
  sortColumn,
  direction,
  searchQuery = ""
) =>
  fetchCall(
    `/configuration/fetch/ticket_template/msp/${msp_id}?filters=[]&per_page=${per_page}&page=${page}&sortColumn=${sortColumn}&sortDirection=${direction}&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );

//fetch selected ticket template by id
const fetchTicketTemplateById = (template_id) =>
  fetchCall(
    `/configuration/fetch/ticket_template/${template_id}`,
    appConfig.requestMethods.GET
  );
//create ticket template
const createTicketTemplate = (msp_id, data) =>
  fetchCall(
    `/configuration/create/ticket_template/${msp_id}`,
    appConfig.requestMethods.POST,
    data
  );

// update ticket template
const updateTicketTemplate = (template_id, data) =>
  fetchCall(
    `/configuration/update/ticket_template/${template_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//upload attachment files for ticket template
const uploadticketTemplateAttachment = (mspId, formData) =>
  axiosUploadCall(
    `/configuration/attachments/ticket_template/${mspId}`,
    formData
  );

//delete ticket template
const deleteTicketTemplate = (mspId, templateId) =>
  fetchCall(
    `/configuration/delete/ticket_template/${templateId}`,
    appConfig.requestMethods.DELETE
  );

// fetch all canned notes
const fetchAllCannedNotes = (
  msp_id,
  perPage,
  page,
  sortColumn,
  sortDirection,
  filters,
  searchQuery
) =>
  fetchCall(
    `/configuration/fetch/canned_notes/msp/${msp_id}?per_page=${perPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=[${filters}]&searchQuery=${searchQuery}`,
    appConfig.requestMethods.GET
  );

//fetch selected canned note by id
const fetchCannedNotesById = (note_id) =>
  fetchCall(
    `/configuration/fetch/canned_notes/${note_id}`,
    appConfig.requestMethods.GET
  );

//create canned note
const createCannedNotes = (msp_id, data) =>
  fetchCall(
    `/configuration/create/canned_notes/${msp_id}`,
    appConfig.requestMethods.POST,
    data
  );

//update canned note
const updateCannedNote = (note_id, data) =>
  fetchCall(
    `/configuration/update/canned_notes/${note_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//delete canned note
const deleteCannedNote = (note_id) =>
  fetchCall(
    `/configuration/delete/canned_notes/${note_id}`,
    appConfig.requestMethods.DELETE
  );

///------------- END TEMPLATE ----------------

///------------- ATTRIBUTES --------------

//fetch all genaral attributes
const fetchAllGeneralAttributes = (msp_id, attribute_name) =>
  fetchCall(
    `/configuration/general/attributes/${attribute_name}`,
    appConfig.requestMethods.GET
  );
//create general attributes
const createGeneralAttributes = (msp_id, attribute_name, data) =>
  fetchCall(
    `/configuration/general/attributes/${attribute_name}`,
    appConfig.requestMethods.POST,
    data
  );

// update general attributes
const updateGeneralAttribute = (attribute_id, attribute_name, data) =>
  fetchCall(
    `/configuration/general/attributes/${attribute_name}/${attribute_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
// delete general attributes by id
const deleteGeneralAttribute = (attribute_id, attribute_name) =>
  fetchCall(
    `/configuration/general/attributes/${attribute_name}/${attribute_id}`,
    appConfig.requestMethods.DELETE
  );

// fetch all ticket attributes
const fetchAllTicketsAttributes = (msp_id, attribute_name) =>
  fetchCall(
    `/configuration/ticket/attributes/${attribute_name}`,
    appConfig.requestMethods.GET
  );

//create ticket attributes
const createTicketsAttributes = (msp_id, attribute_name, data) =>
  fetchCall(
    `/configuration/ticket/attributes/${attribute_name}`,
    appConfig.requestMethods.POST,
    data
  );

//update ticket attributes
const updateTicketsAttribute = (attribute_id, attribute_name, data) =>
  fetchCall(
    `configuration/ticket/attributes/${attribute_name}/${attribute_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
//delete ticket attributes
const deleteTicketsAttribute = (attribute_id, attribute_name) =>
  fetchCall(
    `configuration/ticket/attributes/${attribute_name}/${attribute_id}`,
    appConfig.requestMethods.DELETE
  );

// fetch all variable attribute
const fetchAllVariableAttributes = (msp_id, attribute_type) =>
  fetchCall(
    `/configuration/get/variable/${attribute_type}/${msp_id}`,
    appConfig.requestMethods.GET
  );
// fetch variable aattributes by id
const fetchVariableAttributesById = (msp_id, attribute_type, attribute_id) =>
  fetchCall(
    `/configuration/get/by_id/variable/${attribute_type}/${attribute_id}/${msp_id}`,
    appConfig.requestMethods.GET
  );
//create variable attribute value
const createVariableAttributeValue = (
  msp_id,
  attribute_type,
  variable_attribute_id,
  data
) =>
  fetchCall(
    `/configuration/add/attributes/variable/value/${attribute_type}/${variable_attribute_id}/${msp_id}`,
    appConfig.requestMethods.POST,
    data
  );
//update variable attributes value
const updateVariableAttributeValue = (
  msp_id,
  attribute_type,
  variable_attribute_values_id,
  data
) =>
  fetchCall(
    `/configuration/update/variable_value/${attribute_type}/${variable_attribute_values_id}/${msp_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
//update variable attribute
const updateVariableAttribute = (
  msp_id,
  attribute_type,
  variable_attribute_id,
  data
) =>
  fetchCall(
    `/configuration/update/variable/${attribute_type}/${variable_attribute_id}/${msp_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
//delete variable attrbute value
const deleteVariableAttributeValue = (
  msp_id,
  variable_attribute_values_id,
  attribute_type
) =>
  fetchCall(
    `/configuration/delete/variable_value/${attribute_type}/${variable_attribute_values_id}/${msp_id}`,
    appConfig.requestMethods.DELETE
  );

// fecth all attrubte tags
const fetchAllAttributeTags = (
  msp_id,
  perPage,
  page,
  sortColumn,
  sortDirection,
  filters
) =>
  fetchCall(
    `/configuration/fetch/tag/msp/${msp_id}?per_page=${perPage}&page=${page}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=[${filters}]`,
    appConfig.requestMethods.GET
  );

//fetch complete attribute tags withput pagination (for selectfield)
const fetchCompleteAttributeTags = (msp_id) =>
  fetchCall(
    `/configuration/fetch/tag/msp/${msp_id}?filters=[]`,
    appConfig.requestMethods.GET
  );

//create attibute tag
const createAttributeTag = (mspId, data) =>
  fetchCall(
    `/configuration/create/tag/${mspId}`,
    appConfig.requestMethods.POST,
    data
  );

// update attribute tag
const updateAttributeTag = (tag_id, data) =>
  fetchCall(
    `/configuration/update/tag/${tag_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//delete atttribute tag
const deleteAttributeTag = (tag_id) =>
  fetchCall(
    `/configuration/delete/tag/${tag_id}`,
    appConfig.requestMethods.DELETE
  );

// fetch service type
const fetchServiceType = (msp_id, service_type) =>
  fetchCall(
    `/v2/configuration/type/${service_type}`,

    appConfig.requestMethods.GET
  );
// create service type
const createServiceType = (msp_id, service_type, data) =>
  fetchCall(
    `/v2/configuration/type/${service_type}`,
    appConfig.requestMethods.POST,
    data
  );

// update service type
const updateServiceType = (msp_id, service_type, service_type_id, data) =>
  fetchCall(
    `/v2/configuration/type/${service_type}/${service_type_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

//delete service type
const deleteServiceType = (msp_id, service_type, type_id) =>
  fetchCall(
    `/configuration/delete/type/${service_type}/${type_id}/${msp_id}`,
    appConfig.requestMethods.DELETE
  );

//create service subtype
const createServiceSubType = (msp_id, service_type, service_type_id, data) =>
  fetchCall(
    `/configuration/add/subtype/${service_type}/${service_type_id}/${msp_id}`,
    appConfig.requestMethods.POST,
    data
  );
//update service subtype
const updateServiceSubType = (msp_id, service_type, service_subtype_id, data) =>
  fetchCall(
    `/configuration/update/subtype/${service_type}/${service_subtype_id}/${msp_id}`,
    appConfig.requestMethods.PATCH,
    data
  );
//delete service subtype
const deleteServiceSubType = (msp_id, service_type, service_subtype_id) =>
  fetchCall(
    `/configuration/delete/subtype/${service_type}/${service_subtype_id}/${msp_id}`,
    appConfig.requestMethods.DELETE
  );

///------------- END ATTRIBUTES ----------------

///------------- QA RULES ----------------

const fetchQARules = (msp_id) =>
  fetchCall(
    `/configuration/fetch/qa_rules/${msp_id}`,
    appConfig.requestMethods.GET
  );

const updateQARules = (msp_id, data) =>
  fetchCall(
    `/configuration/update/qa_rules/${msp_id}`,
    appConfig.requestMethods.PATCH,
    data
  );

///------------- END QA RULES ----------------

export const configurationService = {
  //GENERAL
  fetchMspConfiguration,
  updateMspConfiguration,

  //BOARD
  fetchAllTicketBoards,
  fetchAllTicketBoardsforList,
  fetchTicketBoardById,
  createTicketBoard,
  updateTicketBoard,
  deleteTicketBoard,

  fetchAllRequestBoards,
  fetchAllRequestBoardsforList,
  fetchRequestBoardById,
  createRequestBoard,
  updateRequestBoard,
  deleteRequestBoard,

  fetchAllProjectBoards,
  fetchAllProjectBoardsforList,
  fetchProjectBoardById,
  createProjectBoard,
  updateProjectBoard,
  deleteProjectBoard,

  //CHECKLIST
  fetchAllChecklist,
  fetchCompleteChecklist,
  fetchCheckListById,
  createChecklist,
  createChecklistItem,
  updateChecklist,
  updateChecklistItem,
  deleteChecklist,
  deleteChecklistItem,

  //SLA
  fetchAllSla,
  fetchCompleteSla,
  fetchSlaById,
  createSla,
  updateSla,
  deleteSlabyId,

  //TEMPLATES

  fetchAllTicketTemplates,
  fetchTicketTemplateById,
  createTicketTemplate,
  updateTicketTemplate,
  deleteTicketTemplate,
  uploadticketTemplateAttachment,

  fetchAllCannedNotes,
  fetchCannedNotesById,
  createCannedNotes,
  updateCannedNote,
  deleteCannedNote,

  //ATTRIBUTES

  fetchAllGeneralAttributes,
  createGeneralAttributes,
  updateGeneralAttribute,
  deleteGeneralAttribute,

  fetchAllTicketsAttributes,
  createTicketsAttributes,
  updateTicketsAttribute,
  deleteTicketsAttribute,

  fetchAllVariableAttributes,
  fetchVariableAttributesById,
  createVariableAttributeValue,
  updateVariableAttributeValue,
  updateVariableAttribute,
  deleteVariableAttributeValue,

  fetchServiceType,
  createServiceType,
  deleteServiceType,
  updateServiceType,
  createServiceSubType,
  updateServiceSubType,
  deleteServiceSubType,

  fetchAllAttributeTags,
  fetchCompleteAttributeTags,
  createAttributeTag,
  updateAttributeTag,
  deleteAttributeTag,

  // QA RULES

  fetchQARules,
  updateQARules,
};
