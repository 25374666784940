import { Box, CircularProgress } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { setSessionValues } from "utilz/helpers/apiRequestAuthHelper";
import { cookieGenerator } from "utilz/helpers";
import { getAuthAccessToken, useGlobalStore } from "controller";
export const AuthValidator = () => {
  const navigate = useNavigate();
  const resetCredentials = useGlobalStore((state) => state.resetCredentials);
  let isApiLoading = false;
  useEffect(() => {
    if (import.meta.env.MODE !== "development") {
    }
    authValidate();
  }, []);

  async function authValidate() {
    if (isApiLoading) {
      return;
    }
    isApiLoading = true;
    try {
      const result = await getAuthAccessToken();
      //validate if params available or not
      if (
        result.status === 200 &&
        result &&
        result?.data &&
        result?.data?.data &&
        result?.data?.data?.accessToken &&
        result?.data?.data?.refreshToken
      ) {
        let decoded: any;
        const accessToken = result?.data?.data?.accessToken;
        const refreshToken = result?.data?.data?.refreshToken;
        const expires = result?.data?.data?.expires;
        //decrypt token
        try {
          decoded = jwtDecode(accessToken);
        } catch (e) {
          decoded = null;
        }
        if (decoded && decoded?.tenantId && decoded?.sub) {
          sessionStorage.clear();
          document.cookie = cookieGenerator(decoded?.aud, refreshToken);

          await setSessionValues(accessToken, decoded);
          isApiLoading = false;
          navigate("/app/home");
          return;
        }
      }
    } catch (e: any) {}
    isApiLoading = false;
    // to handle login redirection in dev environmen.resetCredentials
    resetCredentials();
    sessionStorage.clear();

    //clear refresh token cookie
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    //@ts-ignore
    const redirectUrl = import.meta.env["VITE_APP_AUTH_FRONT_URL"];
    //@ts-ignore
    if (import.meta.env.MODE !== "development") {
      setTimeout(function () {
        window.location.href = `${redirectUrl}`;
      }, 500);
    }
  }
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
