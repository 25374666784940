import React, { useState, useEffect, forwardRef, useRef } from "react";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Stack from "@mui/material/Stack";

// HOC Imports
import {
  CustomButton,
  CustomTypography,
} from "../../../../../../../../library/base";

// Components
import {
  ResourceTypeGeneralEditForm,
  MembersEditForm,
  PermissionsEditForm,
} from "./components";

// Constants
import { EDIT_MODAL_STEPS } from "./edit-modal-steps";
import { PermissionComponent } from "pages/modules/control-center/resources-and-permissions/components";

const ResourceTypeDetails = forwardRef((props, ref) => {
  const generalEditForm = useRef();
  const membersEditForm = useRef();

  // State
  const [state, setState] = useState({
    steps: {
      stepList: Object.values(EDIT_MODAL_STEPS).map((element, index) => {
        return {
          ...element,
          active: index === 0 ? true : false,
          completed: false,
        };
      }),
      activeStep: EDIT_MODAL_STEPS["step_1"].value,
    },
  });

  // Imperative handle

  // Effects

  // Event handlers
  const handleNext = () => {
    try {
      if (state?.steps["activeStep"] === state?.steps["stepList"].length) {
        console.log("Submitting");
        props.onClose();
      } else {
        const current = state?.steps["stepList"].map((element, index) => {
          return {
            ...element,
            active:
              element?.active === true
                ? false
                : element?.value === state?.steps["activeStep"] + 1
                  ? true
                  : element?.active,
          };
        });
        const activeStep = state?.steps["activeStep"] + 1;
        setState({
          ...state,
          steps: {
            ...state?.steps,
            stepList: current,
            activeStep: activeStep,
          },
        });
      }
    } catch (err) {
      console.log("[ERROR] Handling Next");
      console.log(err);
    }
  };
  const handlePrev = () => {
    if (state?.steps["activeStep"] > 1) {
      const current = state?.steps["stepList"].map((element, index) => {
        return {
          ...element,
          active:
            element?.value + 1 === state.steps["activeStep"] ? true : false,
        };
      });
      const activeStep = state?.steps["activeStep"] - 1;
      setState({
        ...state,
        steps: {
          ...state?.steps,
          stepList: current,
          activeStep: activeStep,
        },
      });
    }
  };

  // Renderer
  return (
    <React.Fragment>
      <Stepper
        nonLinear
        activeStep={state?.steps["activeStep"]}
        sx={{ px: 0, pb: "32px" }}
      >
        {state?.steps["stepList"].map((element) => {
          return (
            <Step
              key={element?.key}
              // completed={completed[index]}
              sx={{ px: 0 }}
            >
              <Stack
                direction={"row"}
                spacing={"2px"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "32px",
                    height: "32px",
                    backgroundColor:
                      element?.active === true ? "#FFF" : "#E3E3E3",
                    border:
                      element?.active === true
                        ? "2px solid #198038"
                        : "2px solid#E3E3E3",
                    borderRadius: "50%",
                  }}
                >
                  <CustomTypography
                    size={"SMALL-BETA"}
                    color={"#19B1F7"}
                    variant={"MEDIUM"}
                    content={element?.value.toString()}
                  />
                </Box>
                <CustomTypography
                  size={"SMALL-BETA"}
                  color={"#19B1F7"}
                  variant={"MEDIUM"}
                  content={element?.label}
                />
              </Stack>
            </Step>
          );
        })}
      </Stepper>

      <Box sx={{ mt: "32px", width: "100%" }}>
        {state?.steps["activeStep"] === 1 ? (
          <ResourceTypeGeneralEditForm
            ref={generalEditForm}
            resource_type_id={props.resource_type_id}
            isEdit={props?.isEdit}
          />
        ) : state?.steps["activeStep"] === 2 ? (
          <MembersEditForm ref={membersEditForm} isEdit={props?.isEdit} />
        ) : state?.steps["activeStep"] === 3 ? (
          <PermissionComponent />
        ) : (
          ""
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mt: "24px",
        }}
      >
        <Stack direction={"row"} spacing={"12px"}>
          {state?.steps["activeStep"] === 1 ? (
            ""
          ) : (
            <CustomButton
              varient={"secondary"}
              size="sm"
              label="Back"
              isTrailingIcon={false}
              icon=""
              onClick={handlePrev}
            />
          )}

          <CustomButton
            varient={"primary"}
            size="sm"
            label={
              state?.steps["activeStep"] === state?.steps?.stepList.length
                ? "Save"
                : "Next"
            }
            isTrailingIcon={false}
            icon=""
            onClick={handleNext}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
});

export default ResourceTypeDetails;
