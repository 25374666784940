export const tableBodyRowDesign = {
  "&:last-child td, &:last-child th": { border: 0 },
  width: "100%",
  p: 0,
  m: 0,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#F3F5F9",
  },
  "&.Mui-selected": {
    backgroundColor: "#EBFCFE",
  },
  "&:active": {
    backgroundColor: "#F3F5F9",
  }
};