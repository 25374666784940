import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlProps,
  useTheme,
  Typography,
  Box,
  ListSubheader,
} from "@mui/material";
import React, { Ref, forwardRef, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useGetCurrentBreakpoint } from "utilz/helpers";
import { DownArrowIcon } from "components/icons";
import { Input, InputProps } from "./Input";

type OptionsProps = {
  label: string;
  value: string | number;
};
interface CustomSelectFieldProps extends Omit<FormControlProps, "size"> {
  options?: Array<any>;
  label?: string;
  name: string;
  control?: any;
  onValueChange?: (e: any) => void;
  //   FIXME: make this required
  labelMappingKey?: string;
  valueMappingKey?: string;
  value?: any;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  size?: "small" | "medium" | "large";
  isResponsive?: boolean;
  hideHelperBox?: boolean;
  isGrouped?: boolean;
  groupedOptions?: Record<string, Array<any>>;
  renderValue?: (value: any) => React.ReactNode;
  menuItemBuilder?: ({
    item,
    index,
  }: {
    item: any;
    index: number;
  }) => React.ReactNode;
  open?: boolean;
  onClose?: (...event: any) => void;
  isSearchable?: boolean;
  onSearchValueChange?: (value: string) => void;
  searchFieldProps?: Omit<InputProps, "name">;
}
export const TableSelectField = ({
  options,
  label,
  size = "medium",
  name,
  control,
  onChange,
  onValueChange,
  labelMappingKey,
  valueMappingKey,
  error,
  errorMessage,
  value,
  required = false,
  isResponsive = true,
  hideHelperBox = false,
  menuItemBuilder,
  open,
  isGrouped = false,
  groupedOptions = {},
  onClose,
  isSearchable = false,
  onSearchValueChange,
  searchFieldProps,
  ...rest
}: CustomSelectFieldProps) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <FormControl fullWidth {...rest}>
        {control ? (
          <Controller
            control={control}
            name={name}
            render={({ field: { ref, value, onBlur, onChange, name } }) => (
              <CustomSelectField
                ref={ref}
                name={name}
                error={error}
                value={value}
                label={label}
                onBlur={onBlur}
                onChange={onChange}
                onClose={onClose}
                {...(open ? { open: true } : {})}
                // onValueChange={onValueChange}
                isResponsive={isResponsive}
                size={size}
                options={options}
                valueMappingKey={valueMappingKey}
                labelMappingKey={labelMappingKey}
                required={required}
                type="controlled"
                menuItemBuilder={menuItemBuilder}
                isGrouped={isGrouped}
                groupedOptions={groupedOptions}
                isSearchable={isSearchable}
                onSearchValueChange={onSearchValueChange}
                searchFieldProps={searchFieldProps}
              />
            )}
          />
        ) : (
          <CustomSelectField
            name={name}
            error={error}
            value={value}
            label={label}
            onValueChange={onValueChange}
            onClose={onClose}
            isResponsive={isResponsive}
            size={size}
            options={options}
            open={open}
            valueMappingKey={valueMappingKey}
            labelMappingKey={labelMappingKey}
            required={required}
            type="uncontrolled"
            menuItemBuilder={menuItemBuilder}
            isGrouped={isGrouped}
            groupedOptions={groupedOptions}
            isSearchable={isSearchable}
            onSearchValueChange={onSearchValueChange}
            searchFieldProps={searchFieldProps}
          />
        )}
        <Box height={hideHelperBox ? "0px" : "16px"}>
          <Typography variant="caption-3" color="error">
            {errorMessage}
          </Typography>
        </Box>
      </FormControl>
    </React.Fragment>
  );
};

interface Props extends Omit<CustomSelectFieldProps, "onChange"> {
  onChange?: (...event: any) => void;
  type: "controlled" | "uncontrolled";
  isGrouped?: boolean;
  groupedOptions?: Record<string, Array<any>>;
  isSearchable?: boolean;
  onSearchValueChange?: (value: string) => void;
  searchFieldProps?: Omit<InputProps, "name">;
  onFilterValue?: (value: any) => boolean;
}
const CustomSelectField = ({
  ref,
  error,
  value,
  label,
  onValueChange,
  isResponsive,
  size,
  options,
  valueMappingKey,
  labelMappingKey,
  onChange,
  required,
  control,
  type,
  open,
  renderValue,
  menuItemBuilder,
  isGrouped = false,
  groupedOptions = {},
  onClose,
  isSearchable = false,
  onSearchValueChange,
  searchFieldProps,
  onFilterValue,
}: Props) => {
  const theme = useTheme();

  const breakpoint = useGetCurrentBreakpoint(theme);
  const [searchValue, setSearchValue] = useState("");

  if (isResponsive) {
    if (breakpoint === "lg") {
      size = "large";
    } else if (breakpoint === "md") {
      size = "medium";
    } else {
      size = "small";
    }
  }

  const getStyle = () => {
    switch (size) {
      case "small":
        return {
          height: "32px",
        };
      case "medium":
        return { height: "40px" };
      case "large":
        return { height: "48px" };
      default:
        return { height: "48px" };
    }
  };

  useEffect(() => {
    onSearchValueChange?.(searchValue);
  }, [searchValue]);

  const filteredOption = isSearchable
    ? options?.filter(
        onFilterValue
          ? onFilterValue
          : (option) =>
              `${option[labelMappingKey || ""]}`
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase())
      )
    : options;

  return (
    <>
      <InputLabel
        sx={{
          borderRadius: "8px",

          color: theme.palette.lightBg.low,
          mr: "4px",
          fontFamily: theme.typography["body-medium"].fontFamily,
          fontWeight: theme.typography["body-medium"].fontWeight,
          fontSize: theme.typography["body-medium"].fontSize,
          transform: {
            xs: "translate(14px,4px) scale(1)",
            sm: "translate(14px,4px) scale(1)",
            md: "translate(14px,8px) scale(1)",
            lg: "translate(14px,12px) scale(1)",
            xl: "translate(14px,16px) scale(1)",
          },
          "&.MuiFormLabel-filled": {
            transform: "translate(14px, -9px) scale(.75)",
          },
          "&.Mui-focused": {
            color: theme.palette.purple.main,
            // fontWeight: 700,
            transform: "translate(14px, -9px) scale(.75)",
          },
        }}
      >
        {label && (
          <Typography
            variant="body-medium"
            color={error ? "error" : "inherit"}
            textTransform={"capitalize"}
          >
            {label}
            {required && " *"}
          </Typography>
        )}
      </InputLabel>
      {/* @ts-ignore */}
      <Select
        ref={ref}
        error={error}
        value={value}
        label={label}
        open={open}
        onClose={() => {
          setSearchValue("");
          onClose?.();
        }}
        SelectDisplayProps={{}}
        IconComponent={DownArrowIcon}
        {...(renderValue
          ? { renderValue: (selected) => renderValue(selected) }
          : isSearchable
          ? {
              renderValue: (selected) => {
                const selectedOption = options?.find(
                  (option) => option?.[valueMappingKey || ""] === selected
                );
                return (
                  <Typography variant="body-medium">
                    {selectedOption?.[labelMappingKey || ""] || ""}
                  </Typography>
                );
              },
            }
          : {})}
        onChange={(e) => {
          type === "controlled"
            ? onChange && onChange(e)
            : onValueChange && onValueChange(e.target.value);
        }}
        sx={{
          textTransform: "capitalize",
          ...theme.typography["body-medium"],
          "& .MuiSelect-select": {
            borderRadius: "8px",
            py: 0,
          },

          "&.MuiInputBase-root": {
            minWidth: "110px",
            ...getStyle(),
          },

          borderRadius: "8px",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              margin: "16px 0px",
              padding: "0px",
              overflow: "scroll",
              maxHeight: "300px",
              maxWidth: "500px",
              backgroundColor: "#FFFFFF",
              borderRadius: "4px",
              boxShadow: "8px 8px 32px rgba(0, 0, 0, 0.07)",
              overflowX: "clip",
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: {
            "& .MuiList-root": {
              p: "0px",
              pb: "8px",
            },
          },
          disableAutoFocusItem: true,
        }}
      >
        {isSearchable && (
          <Box
            width={"100%"}
            position={"sticky"}
            bgcolor={"white"}
            top={"0px"}
            left={"0px"}
            zIndex={"2"}
            p={"4px"}
            borderBottom={`1px solid ${theme.palette.neutral["015"]}`}
            onKeyDownCapture={(event) => {
              event.stopPropagation();
            }}
            onKeyDown={(ev) => {
              ev.stopPropagation();
            }}
          >
            <Input
              name="select_search"
              fullWidth
              hideHelperBox
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              onChange={(event: any) => {
                event.stopPropagation();
                event.preventDefault();
                setSearchValue(event.target.value);
              }}
              {...searchFieldProps}
            />
          </Box>
        )}
        {isGrouped ? (
          groupedOptions &&
          Object.entries(groupedOptions).map((data, index) =>
            data && Array.isArray(data)
              ? data.map((value) =>
                  value && typeof value === "string" ? (
                    <ListSubheader
                      key={`options_sub_${index}`}
                      sx={{
                        ...theme.typography["caption"],
                        textTransform: "capitalize",
                        padding: "12px 16px",
                        backgroundColor: theme.palette.neutral["010"],
                      }}
                    >
                      {value}
                    </ListSubheader>
                  ) : value && Array.isArray(value) ? (
                    value.map((item: any, index) => (
                      <MenuItem
                        key={`options_${index}`}
                        value={item[`${valueMappingKey}`]}
                        sx={{
                          ...theme.typography["body-small"],
                          borderBottom: `${
                            index === (value?.length || 0) - 1
                              ? "0px"
                              : `1px solid ${theme.palette.neutral["010"]}`
                          }`,
                        }}
                      >
                        {item[`${labelMappingKey}`]}
                      </MenuItem>
                    ))
                  ) : (
                    ""
                  )
                )
              : ""
          )
        ) : (Array.isArray(filteredOption) ? filteredOption : []).length <=
          0 ? (
          <Box
            width={"100%"}
            p={"8px"}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography variant="body-medium" m={"auto"}>
              No options
            </Typography>
          </Box>
        ) : (
          (Array.isArray(filteredOption) ? filteredOption : []).map(
            (item, index) => (
              <MenuItem
                key={`options_${index}`}
                value={item[`${valueMappingKey}`]}
                sx={{
                  ...theme.typography["body-small"],
                  textTransform: "capitalize",
                  padding: "12px 16px",
                  wordWrap: "break-word",
                  borderBottom: `${
                    index === (options?.length || 0) - 1
                      ? "0px"
                      : `1px solid ${theme.palette.neutral["010"]}`
                  }`,
                }}
              >
                {(menuItemBuilder && menuItemBuilder({ item, index })) ||
                  item[`${labelMappingKey}`]}
              </MenuItem>
            )
          )
        )}
      </Select>
    </>
  );
};

const DefaultMenuBuilder = ({
  item,
  index,
  valueMappingKey,
  labelMappingKey,
  options,
}: {
  item: any;
  index: number;
  valueMappingKey?: string;
  labelMappingKey?: string;
  options?: Array<any>;
}) => {
  const theme = useTheme();
  return (
    <MenuItem
      value={item[`${valueMappingKey}`]}
      sx={{
        ...theme.typography["body-small"],
        textTransform: "capitalize",
        padding: "12px 16px",
        wordWrap: "break-word",
        borderBottom: `${
          index === (options?.length || 0) - 1
            ? "0px"
            : `1px solid ${theme.palette.neutral["010"]}`
        }`,
      }}
    >
      {item[`${labelMappingKey}`]}
    </MenuItem>
  );
};

const GroupedItems = forwardRef(
  (
    { title, valueMappingKey, labelMappingKey, options, ...props }: any,
    ref: any
  ) => {
    const theme = useTheme();
    return (
      <>
        <ListSubheader
          sx={{
            ...theme.typography["caption"],
            textTransform: "capitalize",
            padding: "12px 16px",
            backgroundColor: theme.palette.neutral["010"],
          }}
        >
          {title}
        </ListSubheader>
        {options && Array.isArray(options)
          ? options.map((item: any, index) => (
              <MenuItem
                key={`options_${index}`}
                ref={ref}
                {...props}
                value={item[`${valueMappingKey}`]}
                sx={{
                  ...theme.typography["body-small"],
                  borderBottom: `${
                    index === (options?.length || 0) - 1
                      ? "0px"
                      : `1px solid ${theme.palette.neutral["010"]}`
                  }`,
                }}
              >
                {item[`${labelMappingKey}`]}
              </MenuItem>
            ))
          : ""}
      </>
    );
  }
);
