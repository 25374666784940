import { useTheme } from "@mui/material";

export const TickLabel = (props: any) => {
  const theme = useTheme();
  const { x, y, payload, isXaxis = false } = props;

  return isXaxis ? (
    <g
      transform={`translate(${x},${y})`}
      width={10}
      style={{ width: 20, overflow: "clip", backgroundColor: "green" }}
    >
      <text
        fontSize={theme.typography["body-small"].fontSize}
        fontWeight={theme.typography["body-small"].fontWeight}
        fontFamily={theme.typography["body-small"].fontFamily}
        color="black"
        orientation="bottom"
        // x={0}
        // y={0}
        dy={12}
        stroke="none"
        fill={theme.palette.lightBg.low}
        className="recharts-text recharts-cartesian-axis-tick-value"
        textAnchor="middle"
        style={{
          textTransform: "capitalize",
        }}
      >
        {payload.value}
      </text>
    </g>
  ) : (
    <g transform={`translate(${0},${y})`}>
      <text
        x={0}
        y={0}
        // clipPath="url(#clipRect)"
        stroke="none"
        fill={theme.palette.lightBg.low}
        textAnchor="start"
        fontSize={theme.typography["body-small"].fontSize}
        fontWeight={theme.typography["body-small"].fontWeight}
        fontFamily={theme.typography["body-small"].fontFamily}
        style={{
          textTransform: "capitalize",
        }}
      >
        {`${String(payload?.value).substring(0, 14)}${
          String(payload?.value).length > 14 ? "..." : ""
        }`}
      </text>
    </g>
  );
};
