import { Box, useTheme } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Chat } from "shared/features/chat";
import { ChatHeader } from "./ChatHeader";
import { ChatMessages } from "./ChatMessages";
import { useGetAllChatMessages, useGlobalStore } from "controller";
import { ChatResultItem } from "shared/types";
import { dayjs } from "utilz";
import { usePinnedStore } from "controller/store/pinned-chat/pinnedChatStore";

export const ChatBox = forwardRef(
  (
    {
      onMinimize,
      isOpen,
      serviceType,
      serviceId,
    }: {
      onMinimize: () => void;
      isOpen: boolean;
      serviceType: "ticket" | "task";
      serviceId: string;
    },
    ref
  ) => {
    const theme = useTheme();
    const resourceId = useGlobalStore((state) => state.resourceId);
    const [showNoChatScreen, setShowNoChatScreen] = useState(false);
    const [groupedMessages, setGroupedMessages] = useState<Record<
      string,
      ChatResultItem[]
    > | null>(null);

    const {
      data: prevChatMessages,
      isLoading: isMessagesLoading,
      isFetching: isMessageFetching,
      isFetched,
    } = useGetAllChatMessages({
      serviceType,
      serviceId: serviceId,
      fetcherId: resourceId || "",
      enabled: isOpen && groupedMessages === null ? true : false,
    });

    // add prev messages to state
    useEffect(() => {
      if (prevChatMessages && !isMessagesLoading && !isMessageFetching) {
        setGroupedMessages(null);
        transformMessages({
          inputMessage: prevChatMessages?.messages,
          type: "initial",
        });
      } else if (!isMessagesLoading && !isMessageFetching) {
        !prevChatMessages && setShowNoChatScreen(true);
      }

      return () => {
        setShowNoChatScreen(false);
        setGroupedMessages(null);
      };
    }, [prevChatMessages, isMessagesLoading, isMessageFetching]);

    useImperativeHandle(ref, () => {
      return { transformMessages };
    });
    const transformMessages = ({
      inputMessage,
      type,
    }: {
      inputMessage: ChatResultItem[];
      type: "initial" | "new";
    }) => {
      const lastItem = inputMessage[inputMessage.length - 1];
      if (inputMessage && type === "initial") {
        const groupedMessages = inputMessage.reduce(
          (groups: Record<string, Array<ChatResultItem>>, message) => {
            // @ts-ignore
            const date: string = message.created_at;
            let key = date;

            if (dayjs(date).isToday()) {
              key = "Today";
            } else if (dayjs(date).isYesterday()) {
              key = "Yesterday";
            } else {
              key = dayjs(date).format("DD MMM YYYY");
            }
            if (!groups[key]) {
              groups[key] = [];
            }
            groups[key].unshift(message);
            return groups;
          },
          {}
        );
        setGroupedMessages(groupedMessages);
      } else {
        if (!isOpen && groupedMessages === null) {
          // stop propagating mqtt msgs if previous chats are not fetched and pinned chat is closed
          return;
        }
        setGroupedMessages((prev) => {
          const newData = Object.assign({}, prev);
          const hasToday = newData.hasOwnProperty("Today");
          const today = [
            ...(hasToday ? newData["Today"] : []),
            ...inputMessage,
          ];
          delete newData["Today"];
          return {
            Today: today,
            ...newData,
          };
        });
      }
      // scrollToBottom();
    };

    return (
      <Box
        ref={ref}
        sx={{
          width: isOpen ? "340px" : "0px",
          height: isOpen ? "calc(100vh - 120px)" : "0px",
          maxHeight: "500px",
          border: `1px solid ${theme.palette.darkBg.low}`,
          boxShadow: "0px 0px 8px 0px #00000021, 0px 0px 6px 0px #0000000F",
          backgroundColor: theme.palette.neutral["005"],
          borderRadius: "8px",
          overflow: "hidden",
          transition: "width 0.2s ease-in-out , height 0.2s ease-in-out",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isOpen && (
          <ChatHeader
            onMinimize={onMinimize}
            serviceType={serviceType}
            serviceId={serviceId}
          />
        )}
        <ChatMessages
          serviceType={serviceType}
          serviceId={serviceId}
          groupedMessages={groupedMessages}
          isMessagesLoading={isMessagesLoading}
          showNoChatScreen={showNoChatScreen}
        />
        {/* <Chat
        serviceType="ticket"
        serviceId={"2972bd6a-7ec8-474d-b734-c0fa24b821d0"}
        isDisabled={false}
        serviceSource={"web_app"}
      /> */}
      </Box>
    );
  }
);
