import { useQuery } from "@tanstack/react-query";
import { getAllBoards } from "controller/api";
import { ApiErrorType, GetBoardsInput } from "shared";
import { sentryLogger } from "utilz";

export const useGetAllBoards = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
  serviceType = "ticket_board",
}: GetBoardsInput) => {
  return useQuery({
    queryKey: ["boards", serviceType],
    queryFn: () =>
      getAllBoards({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
        serviceType,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllBoards",
      }),
  });
};
