import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  capitalize,
  useTheme,
} from "@mui/material";
import { Button, SelectField } from "components";
import { BarChart, DonutChart } from "components/charts";
import { useGetQaScore, useMainStore } from "controller";
import { useEffect, useState } from "react";

interface HomeQaProps {
  containerStyles?: SxProps<Theme>;
  qaData: any;
  serviceType?: "ticket" | "project";
  tab?: any;
}

export const HomeQa = () => {
  const theme = useTheme();
  const [totalData, setTotalData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>([]);
  const [timePeriod, setTimePeriod] = useState("lastSixMonths");
  const selectedQaResources = useMainStore(
    (state) => state.selectedQaResources
  );
  const [serviceType, setServiceType] = useState<"ticket" | "project" | "task">(
    "ticket"
  );
  const { data: qaData } = useGetQaScore({
    timePeriod: timePeriod,
    resourceId: selectedQaResources,
    serviceType: serviceType,
  });

  useEffect(() => {
    if (qaData && qaData.assigned?.count && qaData.closed?.count) {
      setData(qaData);
    }
  }, [qaData]);

  const setData = (data: any) => {
    if (data) {
      const total = [
        {
          name: "Passed",
          value: data.assigned?.count?.passed + data.closed?.count?.passed,
          color: theme.palette.success.main,
        },
        {
          name: "Failed",
          value: data.assigned?.count?.failed + data?.closed?.count?.failed,
          color: theme.palette.error.main,
        },
      ];
      const totalCount =
        data.assigned?.count?.total + data.closed?.count?.total;
      setTotalData(total);
      setTotalCount(totalCount);
    }
  };
  const serviceTypeOptions = [
    {
      label: "Tickets",
      value: "ticket",
    },
    {
      label: "Projects",
      value: "project",
    },
    {
      label: "Tasks",
      value: "task",
    },
  ];
  return (
    <Stack
      direction={"column"}
      border={`1px solid ${theme.palette.neutral["015"]}`}
      borderRadius={"20px"}
      overflow={"hidden"}
      bgcolor={theme.palette.darkBg.main}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack
        width={"100%"}
        p="12px 16px"
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={`1px solid ${theme.palette.neutral["015"]}`}
      >
        <Typography variant="h6">Quality Assurance</Typography>
        <Box>
          <SelectField
            isClearable={false}
            hideHelperBox
            name="serviceType"
            options={serviceTypeOptions}
            labelMappingKey="label"
            valueMappingKey="value"
            value={serviceType}
            onValueChange={(newValue) => setServiceType(newValue)}
          />
        </Box>
      </Stack>
      <Box width="300px" height="300px" p={"24px 16px"}>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            pb: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DonutChart
            centerContent={totalCount}
            centerSubContent={`${capitalize(serviceType)}s`}
            data={totalData}
            height={"90%"}
            width={"90%"}
            labelKey="name"
            valueKey="value"
            innerRadius={"60%"}
            outerRadius={"85%"}
          />
        </Box>
      </Box>
      {/* </Box> */}
    </Stack>
  );
};
