import { current } from "immer";
import { debugConsole } from "utilz/helpers";
import { immer } from "zustand/middleware/immer";
interface NotificationState {
  allNotifications: Array<any>;
  notificationIds: Array<string>;
  unseenNotification: number;
  newTicketIds: Array<string>;
}

interface NotificationAction {
  setNotifications: ({
    value,
    count,
  }: {
    value: Array<any>;
    count: number;
  }) => void;
  addNotification: (value: Record<string, any>) => void;
  resetAllNotifications: () => void;
  setNotificationAsSeen: (index: number) => void;
  setAllNotificationAsSeen: () => void;
  addNewTicketId: (id: string) => void;
  clearNewTicketIds: () => void;
  resetUnseenNotification: () => void;
}

export interface NotificationInterface
  extends NotificationState,
    NotificationAction {}

export const notificationSlice = immer<NotificationState & NotificationAction>(
  (set, get) => ({
    allNotifications: [],
    notificationIds: [],
    unseenNotification: 0,
    newTicketIds: [],
    setNotifications({ value, count }) {
      set((state) => {
        const notificationArray: Array<any> = [...state.allNotifications];
        const ids: Array<string> = [...state.notificationIds];
        value.map((item) => {
          if (!ids.includes(item.notification_id)) {
            notificationArray.push(item);
            ids.push(item.notification_id);
          }
        });
        state.allNotifications = notificationArray;
        state.notificationIds = ids;
        state.unseenNotification = count;
      });
    },

    addNotification(value) {
      set((state) => {
        if (!state.notificationIds.includes(value.mapping_id)) {
          state.allNotifications.unshift(value);
          state.unseenNotification = state.unseenNotification + 1;
        }
      });
    },
    setNotificationAsSeen(index) {
      set((state) => {
        state.allNotifications[index] = {
          ...state.allNotifications[index],
          is_seen: true,
        };

        state.unseenNotification = state.unseenNotification - 1;
      });
    },

    setAllNotificationAsSeen() {
      set((state) => {
        const noti = state.allNotifications.map((item) => {
          return {
            ...item,
            is_seen: true,
          };
        });
        console.log(noti);
        state.allNotifications = noti;

        state.unseenNotification = 0;
      });
    },
    resetAllNotifications() {
      set((state) => {
        state.allNotifications = [];
        state.notificationIds = [];
      });
    },
    addNewTicketId(id) {
      set((state) => {
        const idSet = new Set(state.newTicketIds);
        idSet.add(id);
        state.newTicketIds = Array.from(idSet);
      });
    },
    clearNewTicketIds() {
      set((state) => {
        state.newTicketIds = [];
      });
    },
    resetUnseenNotification() {
      set((state) => {
        state.unseenNotification = 0;
      });
    },
  })
);
