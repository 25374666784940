import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { v4 } from "uuid";
import PropTypes from "prop-types";
// Legacy Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Constants

import { useGlobalStore } from "controller/store/globalStore";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, SelectField } from "components";
import { useGetAllResources } from "controller";
import { getFullName } from "utilz/helpers";

const schema = yup.object({
  group_name: yup.string().required("Group name is required"),
  group_description: yup.string().nullable(),
  group_owner: yup.string().required("Group owner is required"),
});
export const ResourceGroupDetails = forwardRef(
  (
    {
      data,
      onSubmit,
    }: {
      data?: any;
      onSubmit: (data: any) => void;
    },
    ref
  ) => {
    const {
      handleSubmit,
      formState: { errors },
      control,
    } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        group_name: data?.group_name || "",
        group_description: data?.group_description || "",
        group_owner: data?.group_owner || "",
      },
    });

    const { data: groupOwners } = useGetAllResources({
      filter: [
        {
          column: "resource_type",
          operator: "=",
          value: [
            "b110abc3-0709-491f-ac04-86eb25159386",
            "9d6afd0e-b353-438d-a34d-1bb1cc5bbf03",
          ],
        },
      ],
    });

    const submit = handleSubmit((data) => {
      onSubmit(data);
    });

    useImperativeHandle(ref, () => ({
      submit,
    }));

    // Renderer
    return (
      <React.Fragment>
        <Box sx={{ my: "24px", px: "24px" }}>
          <Grid container columnSpacing={"24px"} rowSpacing={"24px"}>
            <Grid item xs={12} md={12}>
              <Input
                name="group_name"
                label={"Group name"}
                required
                fullWidth
                control={control}
                error={!!errors.group_name}
                errorMessage={errors?.group_name?.message?.toString?.()}
                // disabled={!isEdit}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectField
                label="Group owner"
                name="group_owner"
                valueMappingKey="mapping_id"
                labelMappingKey="full_name"
                fullWidth
                isSearchable
                // disabled={!isEdit}
                control={control}
                options={
                  Array.isArray(groupOwners?.result)
                    ? groupOwners.result.map((item: any) => ({
                        ...item,
                        full_name:
                          getFullName(
                            item?.first_name,
                            item?.middle_name,
                            item?.last_name
                          ) || "",
                      }))
                    : []
                }
                error={!!errors.group_owner}
                errorMessage={errors?.group_owner?.message?.toString?.()}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Input
                // disabled={!isEdit}
                name="group_description"
                label={"Group description"}
                control={control}
                fullWidth
                multiline
                isResponsive={false}
                minRows={4}
                maxRows={6}
                error={!!errors.group_description}
                errorMessage={errors?.group_description?.message?.toString?.()}
              />
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }
);
