import {
  CreateAttributeInput,
  DeleteAttributeInput,
  GetAttributesInput,
  UpdateAttributeInput,
} from "shared";
import { axiosClient } from "utilz";
import { apiErrorHandler } from "utilz/helpers";
export const getAttributes = async ({
  serviceType,
  attributeName,
}: GetAttributesInput) => {
  try {
    const url = `/configuration/${serviceType}/attributes/${attributeName}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createAttribute = async ({
  attributeName,
  data,
  serviceType,
}: CreateAttributeInput) => {
  try {
    const url = `/configuration/${serviceType}/attributes/${attributeName}`;
    const response = await axiosClient.post(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateAttribute = async ({
  attributeName,
  serviceType,
  data,
  attributeId,
}: UpdateAttributeInput) => {
  try {
    const url = `/configuration/${serviceType}/attributes/${attributeName}/${attributeId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteAttribute = async ({
  attributeId,
  attributeName,
  serviceType,
}: DeleteAttributeInput) => {
  try {
    const url = `/configuration/${serviceType}/attributes/${attributeName}/${attributeId}`;
    const response = await axiosClient.delete(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
