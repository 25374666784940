import { Grid, Stack } from "@mui/material";

// Import HOC

import { Input, SelectField } from "components";
import { AddRemoveIconButton } from "./AddRemoveIconButton";
import { useEffect, useState } from "react";

interface SkillComponentProps {
  skillData: any;
  onDataChange: (data: any) => void;
  onDelete: () => void;
  isLastElement: boolean;
  validate: boolean;
}
const SkillComponent = ({
  skillData,
  isLastElement,
  onDataChange,
  onDelete,
  validate = false,
}: SkillComponentProps) => {
  const options = [
    {
      label: "Beginner",
      value: "beginner",
    },
    {
      label: "Intermediate",
      value: "intermediate",
    },
    {
      label: "Advance",
      value: "advance",
    },
  ];
  const [errors, setErrors] = useState({
    skill: false,
    level: false,
  });

  const onValueChange = (key: string, value: any) => {
    onDataChange({
      ...skillData,
      [key]: value,
    });
  };

  useEffect(() => {
    if (validate) {
      setErrors({
        skill: !(
          skillData?.skill_name &&
          skillData?.skill_name !== null &&
          skillData?.skill_name !== ""
        ),
        level: !(
          skillData?.skill_level &&
          skillData?.skill_level !== null &&
          skillData?.skill_level !== ""
        ),
      });
    } else {
      setErrors({
        skill: false,
        level: false,
      });
    }
  }, [validate]);

  // Renderer
  return (
    <Stack direction={"row"} gap={"32px"} justifyContent={"space-between"}>
      <Grid
        container
        flex={1}
        maxWidth={"646px"}
        columnSpacing={"32px"}
        rowSpacing={"32px"}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Input
            name="skill"
            label={"Skill"}
            fullWidth
            value={skillData?.skill_name}
            onChange={(event) =>
              onValueChange("skill_name", event.target.value)
            }
            error={validate && errors.skill}
            errorMessage={
              validate && errors.skill ? "Skill is required" : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SelectField
            name="level"
            label="Level"
            value={skillData.skill_level}
            options={options}
            labelMappingKey="label"
            valueMappingKey="value"
            onValueChange={(value) => onValueChange("skill_level", value)}
            error={errors.level}
            errorMessage={
              validate && errors.skill ? "Level is required" : undefined
            }
          />
        </Grid>
      </Grid>

      {isLastElement &&
      (skillData.skill_name === "" || skillData.skill_name === null) &&
      (skillData.skill_level === "" ||
        skillData.skill_level === null) ? null : (
        <AddRemoveIconButton isDelete onClick={onDelete} />
      )}
    </Stack>
  );
};

export default SkillComponent;
