import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { PlanDetailsCard } from "./PlanDetailsCard";
import { PayNowCard } from "./PayNowCard";
import { Button } from "components";
import { useGlobalStore } from "controller";
import { dayjs } from "utilz";
import { debugConsole } from "utilz/helpers";
// @ts-ignore
import SubscriptionBg from "assets/images/mfaBg.webp";

export const NoSubscriptionScreen = ({
  isFullPage = false,
}: {
  isFullPage?: boolean;
}) => {
  const theme = useTheme();
  const isSubscriptionCancelled = useGlobalStore(
    (state) => state.cancelAtPeriodEnd
  );
  const currentAccessBlockDate = useGlobalStore(
    (state) => state.currentAccessBlockDate
  );
  const dateFormat = useGlobalStore((state) => state.dateFormat);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      {isSubscriptionCancelled && !isFullPage && (
        <Stack
          sx={{
            backgroundColor: theme.palette["warning-minor"].tint,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            p: "8px",
            position: "relative",
          }}
        >
          <Typography variant="body-medium">
            Your subscription was cancelled, but we've kept the door open until{" "}
            {dayjs(currentAccessBlockDate).format(dateFormat)}.Renew before this
            date to experience DeskDay without any interruption!
          </Typography>
          <Button
            size="small"
            onClick={() => {
              try {
                // @ts-ignore
                window.Intercom("show");
              } catch (error) {
                debugConsole(error);
              }
            }}
          >
            Contact sales
          </Button>
        </Stack>
      )}
      <Box sx={{ height: "100%", overflow: "scroll" }}>
        <Stack height={"100%"}>
          <Box
            display="grid"
            gridTemplateColumns={"398px 1fr"}
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <PlanDetailsCard />
            <PayNowCard isFullPage={isFullPage} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
