const defaultSizes = {
  sm: {
    height: 20,
    width: 36,
  },
  md: {
    height: 24,
    width: 40,
  },
  lg: {
    height: 28,
    width: 48,
  },
  xl: {
    height: 32,
    width: 56,
  },
};

export default defaultSizes;