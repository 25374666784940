import { SvgIcon, SvgIconProps } from "@mui/material";

export const SlaTileIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 5.99756C16.1441 5.99756 19.5033 9.35682 19.5033 13.5007C19.5033 17.6445 16.1441 21.0038 12.0002 21.0038C7.85633 21.0038 4.49707 17.6445 4.49707 13.5007C4.49707 9.35682 7.85633 5.99756 12.0002 5.99756"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0002 8.99878V13.0004"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99927 2.9963H14.0009"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0033 6.99798L19.0029 5.99756"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </SvgIcon>
);
