import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createContract,
  deleteContract,
  getAllContracts,
  getAllCurrency,
  getBilligDefaultContract,
  getBillingContractDropDownData,
  getContractById,
  getServiceEntries,
  getServicesByContractId,
  reassignContract,
  updateContract,
  updateDefaultContract,
} from "controller/api";
import {
  CreateContractInput,
  GetContractInput,
  GetServiceEntriesInput,
  ReassignContractInput,
  UpdateContractInput,
} from "shared";
import { debugConsole } from "utilz/helpers";

export const useGetAllCurrency = () => {
  return useQuery({
    queryKey: ["currency"],
    queryFn: () => getAllCurrency(),
    onError: (err) => debugConsole(err),
    staleTime: Infinity,
  });
};

export const useGetAllContracts = (data: GetContractInput) => {
  return useQuery({
    queryKey: [
      "contracts",
      data.isActive,
      data.contractType,
      data.customerId,
      data.pageNumber,
      data.perPageCount,
      data.sortColumn,
      data.sortDirection,
      data.searchQuery,
    ],
    queryFn: () => getAllContracts(data),
    onError: (err) => debugConsole(err),
    enabled: data.customerId ? true : false,
    staleTime: Infinity,
  });
};

export const useGetContractById = (id: string) => {
  return useQuery({
    queryKey: ["contracts", id],
    queryFn: () => getContractById(id),
    onError: (err) => debugConsole(err),
    enabled: id ? true : false,
  });
};

export const useCreateContract = () => {
  const queryClient = useQueryClient();
  return useMutation((value: CreateContractInput) => createContract(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["contracts"]);
    },
  });
};

export const useUpdateContract = () => {
  const queryClient = useQueryClient();
  return useMutation((value: UpdateContractInput) => updateContract(value), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["contracts"]);
    },
  });
};

export const useGetBillingContractDropDownData = (
  customerId: string,
  serviceType: string
) => {
  return useQuery({
    enabled: !!customerId,
    queryKey: [
      "contracts",
      "billingContractDropDownData",
      customerId,
      serviceType,
    ],
    queryFn: () => getBillingContractDropDownData(customerId, serviceType),
    onError: (error) => debugConsole(error),
  });
};

export const useGetBilligDefaultContract = (
  customerId: string,
  serviceType: string
) => {
  return useQuery({
    enabled: !!customerId,
    queryKey: ["contracts", "billingDefaultContract", customerId, serviceType],
    queryFn: () => getBilligDefaultContract(customerId, serviceType),
    onSuccess(data) {},
    onError: (error) => debugConsole(error),
  });
};

export const useUpdateDefaultContract = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: {
      serviceId: string;
      serviceType: string;
      contractId: string;
      ticketMappingId?: any;
      projectMappingId?: any;
    }) =>
      updateDefaultContract(
        value.serviceId,
        value.serviceType,
        value.contractId
      ),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["contracts", "billingDefaultContract"]);
        if (variables?.ticketMappingId) {
          queryClient.invalidateQueries(["ticket", variables?.ticketMappingId]);
        }
        if (variables?.projectMappingId) {
          queryClient.invalidateQueries([
            "project",
            variables?.projectMappingId,
          ]);
        }
      },
    }
  );
};

export const useGetServicesByContractId = (contractId: string) => {
  return useQuery({
    queryKey: ["service_by_contract", contractId],
    queryFn: () => getServicesByContractId(contractId),
    onSuccess(data) {},
    onError: (error) => debugConsole(error),
    enabled: contractId ? true : false,
  });
};

export const useGetServiceEntries = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: GetServiceEntriesInput) => getServiceEntries(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["service_by_contract"]);
      },
    }
  );
};

export const useReassignContract = () => {
  return useMutation(
    (value: ReassignContractInput) => reassignContract(value),
    {}
  );
};

export const useDeleteContract = () => {
  const queryClient = useQueryClient();
  return useMutation((contractId: string) => deleteContract(contractId), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["contracts"]);
    },
  });
};
