import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Avatar from "@mui/material/Avatar";

// HOC Imports
import { CustomTypography } from "../../../base";
import Icon from "../../../icon-library";

// Constants imports
import { DIALER_CONSTANTS } from "./constants";
import { Typography } from "@mui/material";

const CustomDialer = (props) => {
  // states
  const [state, setState] = useState({
    value: 0,
    key: "",
  });

  // Effects
  useEffect(() => {
    try {
      setState({
        ...state,
        value: props?.value || 0,
        key: v4(),
      });
    } catch (err) {
      console.log("[ERROR] Mounting Custom-Dialer");
      console.log(err);
    }
  }, [props]);

  // Event Handlers
  const handleIncrement = () => {
    try {
      if (props?.maxValue && state?.value >= props?.maxValue) {
        return;
      }
      props?.updateHandler({ field: props?.name, value: state?.value + 1 });
    } catch (err) {
      console.log("[ERROR] Handling increment of Custom-Dialer");
      console.log(err);
    }
  };
  const handleDecrement = () => {
    try {
      if (props?.minValue && state?.value <= props?.minValue) {
        return;
      }
      if (state?.value > 0) {
        props?.updateHandler({ field: props?.name, value: state?.value - 1 });
      }
    } catch (err) {
      console.log("[ERROR] Handling increment of Custom-Dialer");
      console.log(err);
    }
  };

  // Renderer
  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        disabled={props.disabled === true}
        sx={{
          borderRadius: "8px",
          border: "1px solid #CCCCCC",
          height: DIALER_CONSTANTS["height"],
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#FFF",
            width: DIALER_CONSTANTS["width"],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity:
              (state.minValue && state?.value <= props?.minValue) ||
              state["value"] === 0
                ? 0.3
                : 1,
            "&: hover": {
              cursor:
                (state.minValue && state?.value <= props?.minValue) ||
                state["value"] === 0
                  ? "default"
                  : "pointer",
            },
          }}
          onClick={handleDecrement}
        >
          <Icon disabled={false} size={"lg"} icon="minus" color="#323232" />
        </Box>
        <Avatar
          sx={{
            borderRadius: "0px",
            backgroundColor: "#F6F6F5",
            height: "100%",
            minWidth: DIALER_CONSTANTS["width"],
            width: "fit-content",
          }}
        >
          <Typography variant="body-large">{state.value.toString()}</Typography>
        </Avatar>
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "#FFF",
            width: DIALER_CONSTANTS["width"],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity:
              props?.maxValue && state?.value >= props?.maxValue ? 0.3 : 1,
            "&: hover": {
              cursor:
                props?.maxValue && state?.value >= props?.maxValue
                  ? "default"
                  : "pointer",
            },
          }}
          onClick={handleIncrement}
        >
          <Icon disabled={false} size={"lg"} icon="plus" color="#323232" />
        </Box>
      </ButtonGroup>
    </React.Fragment>
  );
};

CustomDialer.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  updateHandler: PropTypes.func.isRequired,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
};

CustomDialer.defaultProps = {
  name: "",
  value: 0,
  updateHandler: () => {},
};

export default CustomDialer;
