import { configurationService } from "../control-center/configuration-service";
import { generalServices } from "../general";
import { controlCenterservices } from "../control-center";
import { services } from "..";
import { emptyStringHandler } from "utilz/helpers";
import { RESOURCE_TYPES } from "pages/modules/control-center/resources-and-permissions/constants";
import { capitalize } from "@mui/material";

const getAllCountries = async () => {
  try {
    return await generalServices
      .fetchAllCountries()
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `options-data-country-${option?.name}-${option?.iso3}-key`,
            value: option?.id,
            label: option?.name,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all countries");
    console.log(err);
  }
};

const getAllStatesByCountry = async (id) => {
  try {
    return await generalServices
      .fetchAllStatesByCountryCode(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `options-data-state-${option?.state_name}-${option?.state_code}-key`,
            value: option?.id,
            label: option?.state_name,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all states by country code");
    console.log(err);
  }
};
const getAllCitiesByStateAndCountry = async (values) => {
  try {
    const country = values[0];
    const state = values[1];
    return await generalServices
      .fetchAllCitiesByStateCode(country, state)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `options-data-city-${option?.name}-${option?.latitude}-${option?.longitude}-key`,
            value: option?.name,
            label: option?.name,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all states by country code");
    console.log(err);
  }
};
const getAllCitiesAndCoordinatesByStateAndCountry = async (values) => {
  try {
    const country = values[0];
    const state = values[1];
    return await generalServices
      .fetchAllCitiesByStateCode(country, state)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `options-data-city-${option?.name}-${option?.latitude}-${option?.longitude}-key`,
            value: {
              valueLabel: option?.name,
              valueLatitude: option?.latitude,
              valueLongitude: option?.longitude,
            },
            label: option?.name,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all states by country code");
    console.log(err);
  }
};
const getAllTimeZones = async () => {
  try {
    return await generalServices
      .fetchTimeZones()
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `options-data-time_zones-${option?.zoneName}-${option?.gmtOffsetName}-key`,
            value: option?.gmtOffsetName,
            label: `${option?.zoneName} ${option?.gmtOffsetName}`,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all time zones");
    console.log(err);
  }
};
const fetchAllLocations = async (id) => {
  try {
    return await controlCenterservices.locationServices
      .fetchCompleteLocationsByMspId(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `options-data-time_zones-${option?.zoneName}-${option?.gmtOffsetName}-key`,
            value: option?.gmtOffsetName,
            label: `[${option?.gmtOffsetName}] ${option?.zoneName}`,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all time zones");
    console.log(err);
  }
};

//Configuration

const getAllTicketBoards = async (value) => {
  const id = value[0];
  try {
    return await configurationService
      .fetchAllTicketBoardsforList(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `configuration-general-all_ticket_board-${option["ticket_board_id"]}-key`,
            value: option["ticket_board_id"],
            label: option["ticket_board_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all ticket board");
    console.log(error);
  }
};

const getAllRequestBoard = async (value) => {
  const id = value[0];
  try {
    return await configurationService
      .fetchAllRequestBoardsforList(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `configuration-general-all_ticket_board-${option["request_board_id"]}-key`,
            value: option["request_board_id"],
            label: option["request_board_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all request board");
    console.log(error);
  }
  //temp data [NEED TO REMOVE] [NEED API]
  // let tempArray = ['Default Board', 'NOC Board', 'Outlook Issues', 'Hardware Issues', 'Booting Issues'];
  // return [...tempArray.map((item) => ({
  //     key: `configuration-general-all_request_board-${item}-key`,
  //     value: item,
  //     label: item,
  // }))];
};

const getAllProjectBoard = async (value) => {
  const id = value[0];
  try {
    return await configurationService
      .fetchAllProjectBoardsforList(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `configuration-general-all_ticket_board-${option["project_board_id"]}-key`,
            value: option["project_board_id"],
            label: option["project_board_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all project board");
    console.log(error);
  }
  //temp data [NEED TO REMOVE] [NEED API]
  // let tempArray = ['Default Board', 'NOC Board', 'Outlook Issues', 'Hardware Issues', 'Booting Issues'];
  // return [...tempArray.map((item) => ({
  //     key: `configuration-general-all_project_board-${item}-key`,
  //     value: item,
  //     label: item,
  // }))];
};

const getAllUrgencyTypes = () => {
  const types = ["critical", "high", "medium", "low"];

  var result = [
    ...types.map((item) => ({
      key: `issue_level_item_${item}`,
      value: item,
      label: capitalize(item),
    })),
  ];
  return result;
};

const getAllWorkTypes = async (value) => {
  const id = value[0];
  try {
    return await configurationService
      .fetchAllGeneralAttributes(id, "work_type")
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          Array.isArray(data?.data)
        ) {
          return data?.data?.map((option) => ({
            key: `configuration-attributes-general-work-type-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: option["work_type_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all project board");
    console.log(error);
  }
};

const getAllWorkRoles = async (value) => {
  const id = value[0];
  try {
    return await configurationService
      .fetchAllGeneralAttributes(id, "work_role")
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          Array.isArray(data?.data)
        ) {
          return data?.data?.map((option) => ({
            key: `configuration-attributes-general-work-role-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: option["work_role_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all project board");
    console.log(error);
  }
};

const getAllTicketTypes = async (value) => {
  const id = value[0];
  try {
    return await configurationService
      .fetchServiceType(id, "ticket")
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `configuration-attributes-ticket-type-${option["type_id"]}-key`,
            value: option["type_id"],
            label: option["type_name"],
            children: option["subtype"].map((item) => ({
              key: `configuration-attributes-ticket-sub-type-${item["subtype_id"]}-key`,
              value: item["subtype_id"],
              label: item["subtype_name"],
            })),
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all project board");
    console.log(error);
  }
};

const getAllSla = async (value) => {
  const id = value[0];

  try {
    return await configurationService
      .fetchCompleteSla(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `configuration-customer-sla-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: option["sla_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all project board");
    console.log(error);
  }
};

const getAllSource = async (value) => {
  const id = value[0];
  try {
    return await configurationService
      .fetchAllGeneralAttributes(id, "source")
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          Array.isArray(data?.data)
        ) {
          return data?.data.map((option) => ({
            key: `configuration-attributes-general-source-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: option["source_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (error) {
    console.log("[ERROR: POPULATOR] Fetching all project board");
    console.log(error);
  }
};

const getAllLocation = async (value) => {
  const id = value[0];
  try {
    return await controlCenterservices.locationServices
      .fetchCompleteLocationsByMspId(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `options-data-time_zones-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: option["location_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all time zones");
    console.log(err);
  }
};

const getAllTags = async (value) => {
  const id = value[0];
  try {
    return await controlCenterservices.configurationService
      .fetchCompleteAttributeTags(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `configuration-attributes-tags-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: option["tag_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all time zones");
    console.log(err);
  }
};

const getAllResourceType = async (value) => {
  const id = value[0];
  try {
    return await controlCenterservices.resourcesService
      .fetchCompleteResourceType(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `resource-and-permission-resource-type-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: option["resource_type_name"],
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all resource type");
    console.log(err);
  }
};

const getAllResources = async (value) => {
  const id = value[0];
  try {
    return await controlCenterservices.resourcesService
      .fetchCompleteResources(id)
      .toPromise()
      .then((data) => {
        // console.log("🚀 ~ file: index.js:603 ~ .then ~ data:", data);
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `resource-and-permission-resource-type-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            profile: option["profile_image"],
            subLabel: option["resource_work_roles"],
            label:
              //   option["display_name"] && option["display_name"] != null
              //     ? option["display_name"]
              //     :
              `${emptyStringHandler(option["first_name"])} ${emptyStringHandler(
                option["middle_name"]
              )} ${emptyStringHandler(option["last_name"])}`,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all resource type");
    console.log(err);
  }
};

const getAllResourceGroup = async (value) => {
  const id = value[0];
  try {
    return await controlCenterservices.resourcesService
      .fetchCompleteResourceGroup(id)
      .toPromise()
      .then((data) => {
        if (
          data &&
          data?.success === true &&
          data?.statusCode === 200 &&
          data?.data &&
          data?.data?.result &&
          Array.isArray(data?.data?.result)
        ) {
          return data?.data?.result.map((option) => ({
            key: `resource-and-permission-resource-group-${option["mapping_id"]}-key`,
            value: option["mapping_id"],
            label: `${option["group_name"]}`,
          }));
        } else {
          return [];
        }
      });
  } catch (err) {
    console.log("[ERROR: POPULATOR] Fetching all resource type");
    console.log(err);
  }
};

export const commonApiCalls = {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesByStateAndCountry,
  getAllCitiesAndCoordinatesByStateAndCountry,
  getAllTimeZones,

  //configuration
  getAllTicketBoards,
  getAllProjectBoard,
  getAllRequestBoard,
  fetchAllLocations,

  //templates
  getAllUrgencyTypes,
  getAllWorkTypes,
  getAllWorkRoles,
  getAllTicketTypes,
  getAllSource,
  getAllSla,
  getAllLocation,
  getAllTags,

  //resources
  getAllResourceType,
  getAllResources,
  getAllResourceGroup,
};
