import {
  CircularProgress,
  CircularProgressProps,
  useTheme,
} from "@mui/material";
import React from "react";

export const CircularLoader = ({ sx, ...rest }: CircularProgressProps) => {
  const theme = useTheme();
  return (
    <CircularProgress
      sx={{
        "&.MuiCircularProgress-root": {
          color: theme.palette.purple.main,
        },
        ...sx,
      }}
      thickness={5}
      {...rest}
    ></CircularProgress>
  );
};
