export const QUILL_DESIGN_CONSTANT = {
  width: "100%",
  // height: 'inherit',
  flexDirection: "column",
  "& .ql-editor": {
    height: "250px",
    maxHeight: "250px",
    overflow: "auto",
  },
  "& .ql-toolbar.ql-snow": {
    p: "0px !important",
    m: "0px !important",
  },
  "& .ql-snow.ql-toolbar button": {
    borderRadius: "6px",
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7px",
  },
  "& .ql-snow.ql-toolbar button.ql-active,.ql-snow .ql-toolbar button.ql-active,.ql-snow.ql-toolbar .ql-picker-label.ql-active,.ql-snow .ql-toolbar .ql-picker-label.ql-active,.ql-snow.ql-toolbar .ql-picker-item.ql-selected,.ql-snow .ql-toolbar .ql-picker-item.ql-selected": {
    backgroundColor: "#EDEDED",
    color: "black",
  },
  "& .ql-snow.ql-toolbar.ql-stroke": {
    color: "black",
  },
  "& .ql-container.ql-snow": {
    backgroundColor: "#F6F6F5",
    overflow: "scroll"
  },
};