
import { SvgIcon, SvgIconProps } from "@mui/material";
    
export const CheckCircleOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="m10 15-2-2m7-3-5 5m8.364-9.364A9 9 0 1 1 5.636 18.364 9 9 0 0 1 18.364 5.636"/>
</svg>
  </SvgIcon>
);
