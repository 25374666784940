import { Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { MainChatNotificationContainer } from "./components/MainChatNotificationContainer";
import { BellDualIcon, LeftDoubleArrowIcon } from "components";
import { MqttContext } from "controller/context/MqttCreateContext";
import { debugConsole } from "utilz/helpers";
import { useChatNotificationStore, useGlobalStore } from "controller";
import AudioFile from "assets/audio/notification.aac";
import { updateChatNotificationStatus } from "controller/api/notification";
import { useGetAllChatNotification } from "controller/hooks/notification";
import { usePinnedStore } from "controller/store/pinned-chat/pinnedChatStore";
export const ChatNotification = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();
  const audio = new Audio(AudioFile);
  const resourceId = useGlobalStore((state) => state.resourceId);
  const mspId = useGlobalStore((state) => state.mspId);
  const selectedPinnedChatServiceId = usePinnedStore(
    (state) => state.selectedPinnedChatServiceId
  );
  const { data: allChatNotifications } = useGetAllChatNotification({
    resourceId: resourceId || "",
    pageNumber: 1,
    perPageCount: 20,
  });

  const unseenChatNotifications = useChatNotificationStore(
    (state) => state.unseenChatNotification
  );

  const addChatNotification = useChatNotificationStore(
    (state) => state.addChatNotification
  );

  const resetChatNotification = useChatNotificationStore(
    (state) => state.resetAllChatNotifications
  );
  const setChatNotificationAsSeen = useChatNotificationStore(
    (state) => state.setChatNotificationAsSeen
  );

  const setChatNotifications = useChatNotificationStore(
    (state) => state.setChatNotifications
  );

  const { mqttMessage } = useContext(MqttContext);

  useEffect(() => {
    if (allChatNotifications) {
      setChatNotifications(allChatNotifications.result?.slice(0, 20));
    }
    return () => {
      resetChatNotification();
    };
  }, [allChatNotifications]);

  useEffect(() => {
    if (mqttMessage) {
      debugConsole(mqttMessage);
      if (
        mqttMessage.topic === `notification/${mspId}/all` ||
        mqttMessage.topic === `notification/${mspId}/resource/${resourceId}`
      ) {
        const newMessage = JSON.parse(mqttMessage?.message);
        debugConsole(newMessage);
        if (newMessage?.result?.type === "new_chat_message") {
          handleChatNotification(newMessage?.result?.service_id, newMessage);
        }
      }
    }
  }, [mqttMessage]);

  const handleChatNotification = (ticketId: string, newMessage: any) => {
    //disable notification if pinned chat is already open
    const serviceId = newMessage?.result?.service_id;

    // get opened chat in side chat notification bar
    const selectedChatNotification =
      useChatNotificationStore.getState().selectedChatNotification;

    // if page/tab is not in focus then play sound and show notification
    if (document.hidden || !document.hasFocus()) {
      // if pinned chat is not open then play sound and show notification
      // is opened chat notification is not same as new chat notification
      if (
        serviceId !== selectedPinnedChatServiceId &&
        (!selectedChatNotification ||
          selectedChatNotification?.service_id !==
            newMessage?.result?.service_id)
      ) {
        audio.play();
        if (!window.location.href.includes(ticketId)) {
          addChatNotification(newMessage?.result);
          return;
        }
      }
      updateChatNotificationStatus({
        resourceId: resourceId || "",
        notificationId: newMessage?.result.mapping_id,
      });
      addChatNotification(newMessage?.result, true);
    } else {
      if (!window.location.href.includes(ticketId)) {
        if (
          serviceId !== selectedPinnedChatServiceId &&
          (!selectedChatNotification ||
            selectedChatNotification?.service_id !==
              newMessage?.result?.service_id)
        ) {
          addChatNotification(newMessage?.result);
          audio.play();
          return;
        }
        updateChatNotificationStatus({
          resourceId: resourceId || "",
          notificationId: newMessage?.result.mapping_id,
        });
        addChatNotification(newMessage?.result, true);
      } else {
        addChatNotification(newMessage?.result, true);
        updateChatNotificationStatus({
          resourceId: resourceId || "",
          notificationId: newMessage?.result.mapping_id,
        });
        setChatNotificationAsSeen(newMessage?.result.mapping_id);
      }
    }
  };

  return (
    <Collapse
      in={isOpen}
      collapsedSize={24}
      orientation="horizontal"
      sx={{
        position: "absolute",
        zIndex: 899,
        height: "100vh",
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#FFF",
        width: "24px",
        bgcolor: "transparent",
        "&.MuiCollapse-entered": {
          //   borderRadius: "8px",
        },
      }}
    >
      <Stack direction={"row"} sx={{ height: "100%" }}>
        <Box
          sx={{
            cursor: "pointer",
            position: "relative",
            // position: "absolute",
            // top: "0px",
            // right: "0px",
            // height: "100vh",
            width: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            "& .drag_bar": {
              height: "100%",
              width: "100%",
              background: "#0000001A",
              boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
              backdropFilter: "blur( 10.5px )",
              WebkitBackdropFilter: "blur( 10.5px )",
              clipPath:
                "polygon(7.5px 0%, 7.5px calc(50% - 24px), 0% calc(50% - 16px),0% calc(50% + 16px), 7.5px calc(50% + 24px), 7.5px 100%, 100% 100%, 100% 0%)",
              ":hover": { cursor: "pointer" },

              "& .border": {
                position: "absolute",
                height: "100%",
                width: "100%",
                background: theme.palette.darkBg.low,
                clipPath:
                  "polygon(7.5px 0%, 7.5px calc(50% - 24px), 0% calc(50% - 16px),0% calc(50% + 16px), 7.5px calc(50% + 24px), 7.5px 100%, 8.5px 100%,8.5px calc(50% + 23.5px),1px calc(50% + 15.5px),1px calc(50% - 15.5px), 8.5px calc(50% - 23.5px),8.5px 0%)",
              },
            },
            "& .drag_thump": {
              height: "40px",
              width: "23.5px",
              top: "calc(50% - 12px)",
              position: "absolute",
              right: "1px",
            },
          }}
        >
          <div className="drag_bar" onClick={() => setIsOpen((prev) => !prev)}>
            <div className="border"></div>
          </div>

          {unseenChatNotifications > 0 && (
            <Box
              sx={{
                position: "absolute",
                backgroundColor: "red",
                borderRadius: "16px",
                top: "calc(50% - 21px)",
                right: "1px",
                width: "fit-content",
                minWidth: "16px",
                maxWidth: "32px",
                padding: "2px",
                height: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "2",
              }}
            >
              <Typography variant="caption-xs" color="white">
                {unseenChatNotifications < 100
                  ? unseenChatNotifications
                  : "99+"}
              </Typography>
            </Box>
          )}
          <Box
            // className="drag_thump"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <Box className="drag_thump">
              <Box
                className="intercomV2-mainChatNotificationsOpenBtn"
                sx={{
                  height: "fit-content",
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <LeftDoubleArrowIcon htmlColor={theme.palette.primary.main} />
              </Box>
            </Box>
          </Box>
        </Box>

        <MainChatNotificationContainer
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        />
      </Stack>
    </Collapse>
  );
};
