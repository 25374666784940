import { Box, Stack, Typography } from "@mui/material";

import TableChartImg from "assets/images/chartImgs/tableChartImg.svg?react";
import { Chart } from "components";
import {
  useGetQaScore,
  useGetReportGraphData,
  useGlobalStore,
  useMainStore,
} from "controller";
import { ReactNode, useEffect, useState } from "react";
import { ColorArray } from "shared";
import { HomeQa } from "./HomeQa";
import { HomeChartLayoutBox } from "./HomeChartLayoutBox";

export const GraphCards = () => {
  const timezone = useGlobalStore((state) => state.timezone);

  const graph = {
    title: "Active Tickets",
    subtitle: "Last 6 months",
    reportType: "tickets-by-priority",
    timePeriod: "last_six_months",
    serviceType: "ticket",
    chartType: "stacked_bar",
    chartImage: <TableChartImg />,
  };
  return (
    <Box display="grid" gridTemplateColumns={"1fr 1fr"} columnGap="16px">
      <GraphComponent
        title={graph.title}
        subtitle={graph.subtitle}
        reportType={graph.reportType}
        timePeriod={graph.timePeriod}
        serviceType={graph.serviceType}
        timezone={timezone}
        chartType={graph.chartType}
        chartImage={graph.chartImage}
      />
      <HomeQa />
    </Box>
  );
};

const GraphComponent = ({
  title,
  subtitle,
  reportType,
  timePeriod,
  serviceType,
  timezone,
  chartType,
  chartImage,
}: {
  title: string;
  subtitle: string;
  reportType: string;
  timePeriod: string;
  serviceType: string;
  timezone: string;
  chartType: string;
  chartImage: ReactNode;
}) => {
  const { data: graphData, isLoading } = useGetReportGraphData({
    reportName: reportType,
    timePeriod,
    serviceType,
    timezone,
  });

  const [transformedData, setTransformedData] = useState<
    Array<Record<any, any>>
  >([]);

  const [legendData, setLegendData] = useState<Array<Record<any, any>>>([]);

  useEffect(() => {
    if (graphData) {
      const d = (Array.isArray(graphData) ? graphData : []).map(
        (d: any, index: number) => {
          if (chartType === "stacked_bar") {
            const item = d.data?.map((data: any, i: number) => ({
              label: data.label,
              value: data.value,
              color: ColorArray[i % ColorArray.length],
            }));

            return { ...d, data: item };
          } else {
            return {
              label: d.label,
              value: d.value,
              color: ColorArray[index % ColorArray.length],
            };
          }
        }
      );
      setLegendData(d[0]?.data || []);
      setTransformedData(d);
    }
  }, [graphData]);
  return (
    <HomeChartLayoutBox
      key={1}
      title={title}
      subtitle={subtitle}
      // leagendItems={chartType === "stacked_bar" ? legendData : transformedData}
    >
      {!isLoading && transformedData.length === 0 ? (
        <Stack
          sx={{
            // height: "80%",
            mt: "-32px", // height of legend box in ChartLayoutBox
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {chartImage}
          <Typography variant="body-medium">
            No data source for this report
          </Typography>
        </Stack>
      ) : (
        <Chart
          data={transformedData}
          labelKey={chartType === "stacked_bar" ? "title" : "label"}
          valueKey={chartType === "stacked_bar" ? "values" : "value"}
          showLegends={false}
          showAxisLines={true}
          showGrid={true}
          type={chartType as "bar" | "line" | "pie" | "donut"}
        />
      )}
    </HomeChartLayoutBox>
  );
};
