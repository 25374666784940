import { Box } from "@mui/material";
import { Input } from "components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import toast from "react-hot-toast";
import {
  useCreateProductConfig,
  useGlobalStore,
  useUpdateProductConfig,
} from "controller";

const schema = yup.object({
  product_name: yup.string().trim().required("Required"),
  product_type: yup.string().trim().required("Required"),
  price: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    )
    .typeError("Unit price must be a number")
    .required("Unit price is required"),
});

export const BillingProductFields = forwardRef(
  ({ data, closeDrawer, mode }: any, ref) => {
    useImperativeHandle(ref, () => ({
      saveButtonClick: () => {
        handleSubmit(async (data: any) => {
          const newData = { ...data, price: data.price * 100 };
          try {
            if (mode?.method === "create") {
              await createProductConfig(newData);
            } else if (mode?.method === "update") {
              await updateProductConfig({
                data: newData,
                id: mode?.data?.mapping_id,
              });
            }
            closeDrawer();
            reset();
            toast.success("Product added successfully");
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
          }
        })();
      },
      cancelButtonClick: () => {
        closeDrawer();
        reset();
      },
    }));

    const currency = useGlobalStore((state) => state.currency);

    const {
      handleSubmit,
      control,
      reset,
      formState: { errors },
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        product_name: data?.product_name || "",
        product_type: data?.product_type || "",
        price: data?.price / 100 || "",
      },
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      if (mode?.data) {
        setValue("product_name", mode?.data?.product_name);
        setValue("product_type", mode?.data?.product_type);
        setValue("price", mode?.data?.price / 100);
      } else {
        reset();
      }
    }, [mode?.data, setValue, closeDrawer]);

    const { mutateAsync: createProductConfig, isLoading } =
      useCreateProductConfig();
    const { mutateAsync: updateProductConfig } = useUpdateProductConfig();

    return (
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          width: "296px",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Input
          label={"Name"}
          name="product_name"
          control={control}
          error={errors.product_name ? true : false}
          isResponsive={false}
          errorMessage={errors.product_name && "Product name is required"}
        />
        <Input
          label={"Product Type"}
          name="product_type"
          control={control}
          error={errors.product_type ? true : false}
          isResponsive={false}
          errorMessage={errors.product_type && "Product type is required"}
        />
        <Input
          label={"Price"}
          name="price"
          control={control}
          placeholder={`${currency?.symbol || ""}0.00`}
          error={errors.price ? true : false}
          isResponsive={false}
          errorMessage={errors.price && "Product price is required"}
          type="number"
          onKeyDown={(e) => {
            if (
              e.key === "e" ||
              e.key === "E" ||
              e.key === "+" ||
              e.key === "-"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Box>
    );
  }
);
