import { Stack, Typography, useTheme } from "@mui/material";
import { Accordian } from "components";

export const DownloadDrawerAccordion = ({
  logo,
  title,
  content,
  expanded,
  onChange,
}: {
  logo: string;
  title: string;
  content: any;
  expanded: boolean;
  onChange: (isExpanded: boolean) => void;
}) => {
  const theme = useTheme();
  return (
    <Accordian
      expanded={expanded}
      onChange={(_, expanded) => {
        onChange(expanded);
      }}
      titleContent={
        <Stack direction="row" gap="8px">
          <img src={logo} style={{ width: "24px", aspectRatio: 1 }} />
          <Typography variant="h6">{title}</Typography>
        </Stack>
      }
      accordianContainerStyles={{
        "&.MuiAccordion-root:before": {
          display: "none",
        },

        p: "0",
        borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
      }}
      accordianSummaryStyles={{
        p: "4px 16px",
        "&.Mui-expanded": {
          bgcolor: theme.palette.neutral["005"],
          borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
        },
        ":hover": {
          bgcolor: theme.palette.neutral["005"],
        },
      }}
      accordianDetailsStyles={{
        py: 0,
      }}
    >
      <Stack p="24px 16px" gap="24px">
        {content}
      </Stack>
    </Accordian>
  );
};
