import { AxiosError, AxiosResponse, isAxiosError } from "axios";

class APIRequestError extends Error {
  traceId: string;
  status: number;
  constructor(message: string, traceId: string, status: number) {
    super(message);
    this.name = "APIRequestError";
    this.traceId = traceId;
    this.status = status;
  }
}
export const apiErrorHandler = (error: AxiosError | AxiosResponse) => {
  let errorData = {
    traceId: "",
    message: "",
    status: 200,
  };
  if (isAxiosError(error)) {
    errorData = {
      traceId: error.response?.headers["x-request-id"],
      message: error?.message || "",
      status: error.response?.status || 0,
    };
  } else {
    errorData = {
      traceId: error.headers["x-request-id"],
      message: error.data?.message,
      status: error.data?.status,
    };
  }
  throw new APIRequestError(
    errorData.message,
    errorData.traceId,
    errorData.status
  );
};
