import { Stack, Typography, useTheme } from "@mui/material";
import {
  Button,
  ClockFilledIcon,
  LinkHorizontalIcon,
  TableChip,
} from "components";
import { CustomToggleButton } from "library/base";
import React from "react";
import { useNavigate } from "react-router-dom";

type IntegrationCardProps = {
  logo: string;
  label: string;
  description: string;
  isActive: boolean;
  isConnected: boolean;
  onClick?: ({ value, source }: { value: boolean; source: string }) => void;
  value: string;
  isAvailable: boolean;
};
export const IntegrationCard = ({
  logo,
  label,
  description,
  isActive,
  isConnected,
  onClick,
  value,
  isAvailable,
}: IntegrationCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        boxShadow:
          "0px 0px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.04)",
        padding: "24px",
        borderRadius: "8px",
        height: "240px",
        width: "274px",
        gap: "8px",
        position: "relative",
        justifyContent: "space-between",
      }}
    >
      <Stack gap="8px">
        <img
          src={logo}
          style={{ width: "226px", height: "40px", objectFit: "cover" }}
        />
        <Typography variant="caption-2">{label}</Typography>
        <Typography variant="body-small">{description}</Typography>
      </Stack>

      <Stack direction={"row"}>
        {isAvailable ? (
          <ButtonSection
            isConnected={isConnected}
            isActive={isActive}
            source={value}
            onClick={onClick}
          />
        ) : (
          <TableChip
            containerStyle={{
              backgroundColor: theme.palette.warning.tint,
              width: "120px",
            }}
            content={"Coming Soon"}
            type={"warning"}
            startIcon={
              <ClockFilledIcon
                htmlColor={theme.palette.warning.main}
                fontSize="small"
              />
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

const ButtonSection = ({
  isConnected,
  isActive,
  source,
  onClick,
}: {
  isConnected: boolean;
  isActive: boolean;
  source: string;
  onClick?: ({ value, source }: { value: boolean; source: string }) => void;
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ width: "100%" }}
    >
      <Button
        variant="secondary"
        sx={{
          alignSelf: "flex-start",
        }}
        onClick={() => navigate(`${source}`)}
        isResponsive={false}
        size="small"
      >
        {isConnected ? "Configure" : "Connect"}
      </Button>

      {isConnected && (
        <CustomToggleButton
          isChecked={isActive}
          onChange={({ field, value }) => {
            if (onClick) {
              onClick({ value, source });
            }
          }}
        />
      )}
    </Stack>
  );
};
