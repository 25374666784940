import { create } from "zustand";
import { GlobalInterface, GlobalSlice } from "./global";
import { persist, createJSONStorage } from "zustand/middleware";
import {
  ReadyToBillFilterInterface,
  readyToBillFilterSlice,
} from "./billingConfiguration";

export const useBillingPersistantStore = create<ReadyToBillFilterInterface>()(
  persist((...a) => ({ ...readyToBillFilterSlice(...a) }), {
    name: "billing-store",
    storage: createJSONStorage(() => sessionStorage),
  })
);
