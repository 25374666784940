import { Watchlist } from "shared";
import { getFullName } from "utilz/helpers";
import { immer } from "zustand/middleware/immer";

interface WatchListState {
  watchListData: Array<Watchlist>;
}

interface WatchListAction {
  setWatchListData: (data: Array<any>) => void;
  addWatchListData: (data: Watchlist) => void;
  removeWatchListData: (serviceType: string, serviceId: string) => void;
  clearWatchListData: () => void;
}

export interface WatchListInterface extends WatchListState, WatchListAction {}

export const WatchListSlice = immer<WatchListState & WatchListAction>(
  (set, get) => ({
    watchListData: [],
    addWatchListData(data) {
      set((state) => {
        state.watchListData = [...state.watchListData, data];
      });
    },
    setWatchListData(data) {
      set((state) => {
        state.watchListData = data.map((item) => ({
          ...item,
          customer_user_name: getFullName(
            item?.customer_user_first_name,
            item?.customer_user_middle_name,
            item?.customer_user_last_name
          ),
        }));
      });
    },
    removeWatchListData(serviceType, serviceId) {
      set((state) => {
        const newWatchListData = state.watchListData.filter(
          (item) =>
            item.service_type !== serviceType || item.service_id !== serviceId
        );
        state.watchListData = newWatchListData;
      });
    },
    clearWatchListData() {
      set((state) => {
        state.watchListData = [];
      });
    },
  })
);
