import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { sliceResetFunctions } from "../resetStoreHelper";
type FilterProps = {
  column: string;
  operator: "=";
  value: string | Array<string>;
};
type Filter = {
  board?: FilterProps | null;
  customer?: FilterProps | null;
  site_id?: FilterProps | null;
  priority?: FilterProps | null;
  ticket_status?: FilterProps | null;
  users?: FilterProps | null;
  assignees?: FilterProps | null;
  sla?: FilterProps | null;
  sla_status?: FilterProps | null;
  owner?: FilterProps | null;
  tag?: FilterProps | null;
  source?: FilterProps | null;
  impact?: FilterProps | null;
  urgency?: FilterProps | null;
  work_type?: FilterProps | null;
  work_role?: FilterProps | null;
};
interface State {
  boardIds: Array<string>;
  filter: Filter;
  tempSidebarFilter: SideBarFilterType;
  sidebarFilter: SideBarFilterType;
  pageNo: number;
  customerIds: Array<string>;
  siteIds: Array<string>;
  sortColumn: string;
  sortDirection: "asc" | "desc";
  searchQuery?: string;
}

type SideBarFilterType = {
  priority?: Array<string>;
  ticket_status?: Array<string>;
  users?: Array<string>;
  assignees?: Array<string>;
  sla?: Array<string>;
  sla_status?: Array<string>;
  owner?: Array<string>;
  tag?: Array<string>;
  source?: Array<string>;
  impact?: Array<string>;
  urgency?: Array<string>;
  work_type?: Array<string>;
  work_role?: Array<string>;
};

interface TempFilterProps {
  name:
    | "priority"
    | "ticket_status"
    | "users"
    | "assignees"
    | "sla"
    | "sla_status"
    | "owner"
    | "tag"
    | "source"
    | "impact"
    | "urgency"
    | "work_type"
    | "work_role";
  value: string;
}
interface Actions {
  addBoardItem: (board: string) => void;
  setBoard: (boardArray: Array<string>) => void;
  setCustomerIds: (customer: Array<string>) => void;
  setSiteIds: (customer: Array<string>) => void;
  setFilters: (sidebarFilter: SideBarFilterType) => void;
  setSidebarFilters: (sidebarFilter: SideBarFilterType) => void;
  setTempSidebarFilters: ({ name, value }: TempFilterProps) => void;
  // fn to replace tempsidebar with applied filters in sidebar state
  bulkReplaceTempSidebarFilter: () => void;
  clearSidebarFilters: () => void;
  clearTempSidebarFilters: () => void;
  setPageNumber: (pageNo: number) => void;
  setSearchQuery: (term: string) => void;
  changeSortColumnAndOrder: (column: string) => void;
}

export interface TicketFilterInterface extends State, Actions {}

const initialState: State = {
  boardIds: [],
  filter: {},
  tempSidebarFilter: {},
  sidebarFilter: {},
  pageNo: 1,
  customerIds: [],
  siteIds: [],
  sortColumn: "created_at",
  sortDirection: "desc",
};
export const createTicketFilterSlice = immer<State & Actions>((set, get) => {
  sliceResetFunctions.add(() => set(initialState));
  return {
    ...initialState,
    addBoardItem: (board) =>
      set((state) => {
        state.boardIds.push(board);
      }),
    setBoard: (boardArray) =>
      set((state) => {
        state.boardIds = boardArray;
        if (boardArray.length > 0) {
          state.filter.board = {
            column: "board",
            operator: "=",
            value: boardArray,
          };
        } else {
          delete state.filter.board;
          // state.filter.board = null;
        }
      }),
    setCustomerIds: (customerArray) =>
      set((state) => {
        state.customerIds = customerArray;
        if (customerArray.length > 0) {
          state.filter.customer = {
            column: "tickets.customer_id",
            operator: "=",
            value: customerArray,
          };
        } else {
          delete state.filter.customer;
        }
      }),
    setSiteIds: (siteArray) =>
      set((state) => {
        state.siteIds = siteArray;
        if (siteArray.length > 0) {
          state.filter.site_id = {
            column: "tickets.site_id",
            operator: "=",
            value: siteArray,
          };
        } else {
          delete state.filter.site_id;
        }
      }),
    setFilters: (sidebarFiltersData) =>
      set((state) => {
        const sidebarFilter: Filter = {};
        if (sidebarFiltersData) {
          let key:
            | "priority"
            | "ticket_status"
            | "users"
            | "assignees"
            | "sla"
            | "sla_status"
            | "owner"
            | "tag"
            | "source"
            | "impact"
            | "urgency"
            | "work_type"
            | "work_role";
          for (key in sidebarFiltersData) {
            const value = sidebarFiltersData[key];

            if (value && value.length > 0) {
              switch (key) {
                case "tag":
                  sidebarFilter["tag"] = {
                    column: "tags_ticket.ticket_tag_id",
                    operator: "=",
                    value,
                  };
                  break;
                case "priority":
                  sidebarFilter["priority"] = {
                    column: "tickets.priority",
                    operator: "=",
                    value,
                  };
                  break;
                case "assignees":
                  sidebarFilter["assignees"] = {
                    column: "resource_scheduling.resource_id",
                    operator: "=",
                    value,
                  };
                  break;

                default:
                  sidebarFilter[key] = { column: key, operator: "=", value };
                  break;
              }
            }
          }
          state.filter = { ...sidebarFilter };
        }
      }),
    setTempSidebarFilters: ({ name, value }) =>
      set((state) => {
        if (state.tempSidebarFilter[name]) {
          if (state.tempSidebarFilter[name]?.includes(value)) {
            // delete key if only one value is present in array
            if (state.tempSidebarFilter[name]?.length === 1) {
              delete state.tempSidebarFilter[name];
            } else {
              state.tempSidebarFilter[name] = state.tempSidebarFilter[
                name
              ]?.filter((id) => id != value);
            }
          } else {
            state.tempSidebarFilter[name]?.push(value);
          }
        } else {
          state.tempSidebarFilter[name] = [value];
        }
      }),
    bulkReplaceTempSidebarFilter: () =>
      set((state) => {
        state.tempSidebarFilter = state.sidebarFilter;
      }),
    setSidebarFilters: (sidebarFilters) =>
      set((state) => {
        state.sidebarFilter = { ...sidebarFilters };
      }),

    clearSidebarFilters: () =>
      set((state) => {
        state.tempSidebarFilter = {};
        state.sidebarFilter = {};
        state.filter = {
          ...(state.filter.board && { board: state.filter.board }),
          ...(state.filter.customer && { customer: state.filter.customer }),
        };
      }),
    clearTempSidebarFilters: () =>
      set((state) => {
        state.tempSidebarFilter = {};
      }),

    setPageNumber: (pageNo) =>
      set((state) => {
        state.pageNo = pageNo;
      }),

    setSearchQuery: (term) =>
      set((state) => {
        state.searchQuery = term;
      }),
    changeSortColumnAndOrder: (column) =>
      set((state) => {
        if (column === state.sortColumn) {
          state.sortDirection = state.sortDirection === "asc" ? "desc" : "asc";
        } else {
          state.sortColumn = column;
          state.sortDirection = "asc";
        }
      }),
  };
});
