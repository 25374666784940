import { GetQueryProps } from "shared";

type QueryObjectProps = Omit<GetQueryProps, "mspId">
export const getQueryDataObject = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  searchQuery,
  filter,
}: QueryObjectProps) => {
  return {
    ...(perPageCount && { per_page: perPageCount.toString() }),
    ...(pageNumber && { page: pageNumber.toString() }),
    ...(sortColumn && { sortColumn }),
    ...(sortDirection && { sortDirection }),
    ...(searchQuery && { searchQuery }),
    ...{ filters: filter ? JSON.stringify(filter) : JSON.stringify([]) },
  };
};
