import { SvgIcon, SvgIconProps } from "@mui/material";

export const ReportsFilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M22.154 24.5h-14a2.333 2.333 0 0 1-2.334-2.333V5.833A2.333 2.333 0 0 1 8.154 3.5h14a2.333 2.333 0 0 1 2.333 2.333v16.334a2.333 2.333 0 0 1-2.333 2.333Z"
        clipRule="evenodd"
      />
      <path
        fill="#1C1E26"
        fillRule="evenodd"
        d="M11.643 9.333h8.167Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M11.643 9.333h8.167"
      />
      <path
        fill="#1C1E26"
        fillRule="evenodd"
        d="M11.643 18.672h8.167Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M11.643 18.672h8.167"
      />
      <path
        fill="#1C1E26"
        fillRule="evenodd"
        d="M11.643 13.995h8.167Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M11.643 13.995h8.167"
      />
      <path
        fill="#1C1E26"
        fillRule="evenodd"
        d="M4.07 9.333h3.5Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M4.07 9.333h3.5"
      />
      <path
        fill="#1C1E26"
        fillRule="evenodd"
        d="M4.07 18.672h3.268Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M4.07 18.672h3.268"
      />
      <path
        fill="#1C1E26"
        fillRule="evenodd"
        d="M4.07 13.995h3.268Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M4.07 13.995h3.268"
      />
    </svg>
  </SvgIcon>
);
