import { GetQueryProps, UpdateTemplateInput } from "shared";
import { axiosClient } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getQueryDataObject,
} from "utilz/helpers";

export const getAllProjectTmeplates = async ({
  filter,
  pageNumber,
  perPageCount,
  searchQuery,
  sortColumn,
  sortDirection,
}: GetQueryProps) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: "/configuration/project_templates",
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getProjectTemplateById = async (templateId: string) => {
  try {
    const url = `/configuration/project_templates/${templateId}`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createProjectTemplate = async (data: any) => {
  try {
    const url = "/configuration/project_templates";

    const response = await axiosClient.post(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateProjectTemplate = async ({
  templateId,
  data,
}: UpdateTemplateInput) => {
  try {
    const url = `/configuration/project_templates/${templateId}`;

    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteProjectTemplates = async (data: {
  templates: Array<string>;
}) => {
  try {
    const url = "/v2/configuration/project-templates";
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
