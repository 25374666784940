import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

// HOC Imports
import { CustomTypography, CustomIconButton, CustomButton } from "../../base";
import CustomIcon from "../../icon-library";
import { Stack } from "@mui/material";

const MODAL_SIZE = {
  extra_small: "xs",
  small: "sm",
  medium: "md",
  large: "lg",
  extra_large: "xl",
};

const SlideModal = (props) => {
  // States
  const [state, setState] = useState({
    open: false,
    isEdit: false,
    title: "",
    render: "",
    edit: false,
    size: "md",
  });

  // Effects
  useEffect(() => {
    setState({
      ...state,
      open: props?.open,
      isEdit: props?.isEdit,
      edit: props?.edit,
      title: props?.modalTitle,
      size: MODAL_SIZE[`${props?.modalSize}`],
      render: v4(),
    });
  }, [props]);

  // Event Handlers
  const onCloseTrigger = () => props?.closeHandle();
  const onHeaderActionButtonClick = () =>
    props?.headerActionCallback(state?.edit === false);

  // Renderer
  return (
    <React.Fragment>
      <Dialog
        open={state?.open}
        {...(props.disableDropClose === true
          ? null
          : { onClose: onCloseTrigger })}
        fullWidth={true}
        // TransitionComponent={Slide}
        // TransitionProps={{direction: 'left'}}
        maxWidth={state?.size}
        PaperProps={{
          style: {
            borderRadius: "24px",
            ...(props.maxHeight ? { height: "min(720px , 80%)" } : {}),
            display: "flex",
          },
        }}
      >
        <DialogTitle>
          <Stack>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                pb: { xs: "16px", md: "24px", xl: "32px" },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <CustomTypography
                  size={"MEDIUM-ALPHA"}
                  color={"PRIMARY-MAIN"}
                  variant={"BOLD"}
                  content={props?.modalTitle}
                />
              </Box>
              {state?.isEdit === true ? (
                <Box
                  onClick={onHeaderActionButtonClick}
                  sx={{ "&: hover": { cursor: "pointer" } }}
                >
                  {state?.edit === false ? (
                    <CustomIcon size={"xl"} icon={"edit"} color="#5C677F" />
                  ) : (
                    <CustomIcon size={"xl"} icon={"reply"} color="#5C677F" />
                  )}
                </Box>
              ) : (
                <Box
                  onClick={onCloseTrigger}
                  sx={{ "&: hover": { cursor: "pointer" } }}
                >
                  <CustomIcon size={"xl"} icon={"close"} color="#5C677F" />
                </Box>
              )}
            </Box>
            {props.headerBottomComponent ? props.headerBottomComponent : null}
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            py: "0px",
            my: "0px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
            }}
          >
            {props?.modalBody}
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: "20px", mb: "20px" }}>
          <Box
            sx={{
              ...(props.isLoading && {
                filter: "grayscale(1)",
                opacity: "0.6",
                pointerEvents: "none",
              }),
            }}
          >
            {props?.modalFooter}
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

SlideModal.propTypes = {
  isLoading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  modalSize: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.element.isRequired,
  modalFooter: PropTypes.element.isRequired,
  closeHandle: PropTypes.func.isRequired,
  headerActionCallback: PropTypes.func.isRequired,
  disableDropClose: PropTypes.bool,
  headerBottomComponent: PropTypes.element,
  maxHeight: PropTypes.bool,
};

SlideModal.defaultProps = {
  isLoading: false,
  open: false,
  isEdit: false,
  edit: false,
  maxHeight: false,
  modalTitle: "",
  modalSize: "",
  modalBody: <></>,
  modalFooter: <></>,
  disableDropClose: false,
  headerBottomComponent: null,
  closeHandle: () => {},
  headerActionCallback: () => {},
};

export default SlideModal;
