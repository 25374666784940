import { fetchCall } from "../../../../utilz";
import { appConfig } from "../../../../constants";

// Fetch Authentication Policies by MSP-ID
const fetchAuthenticationByMspId = (id) =>
  fetchCall("/controlCenter/msp_auth_policies", appConfig.requestMethods.GET);

// update Authentication Policies by MSPID
const updateAuthenticationByMspId = (id, data) =>
  fetchCall(
    "/controlCenter/msp_auth_policies",
    appConfig.requestMethods.PATCH,
    data
  );

export const securityAuthenticationServices = {
  fetchAuthenticationByMspId,
  updateAuthenticationByMspId,
};
