import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// Constants
import { SELECTFIELD_CONSTANTS } from "./constants";
import { CustomTypography } from "../../../../../../../library/base";
import { Input } from "@mui/material";

// Customization 
const customStyles = {
  "& .MuiInputBase-root": {
    // height: SELECTFIELD_CONSTANTS['height']
    height: SELECTFIELD_CONSTANTS?.field["height"],

    "& .MuiSelect-select": {
      py: "4px"
    }
  },
  "& .MuiOutlinedInput-root": {
    color: SELECTFIELD_CONSTANTS?.field?.palette["unfocus_color"],
    "& > fieldset": {
      borderColor: SELECTFIELD_CONSTANTS?.field?.palette?.outline["DEFAULT"],
      borderRadius: SELECTFIELD_CONSTANTS?.field?.border_radius,
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: SELECTFIELD_CONSTANTS?.field?.palette["color"],
    "& > fieldset": { borderColor: SELECTFIELD_CONSTANTS?.field?.palette?.outline["SELECTED"], }
  },
  "& .MuiInputLabel-root": {
    color: SELECTFIELD_CONSTANTS?.field?.palette["unfocus_color"],
    fontWeight: SELECTFIELD_CONSTANTS?.field?.regular_weight,
    transform: SELECTFIELD_CONSTANTS.transition,
    "&.MuiFormLabel-filled": {
      transform: "translate(14px, -9px) scale(1)"
    },
    "&.Mui-focused": {
      color: SELECTFIELD_CONSTANTS?.field?.palette?.outline["SELECTED"],
      transform: "translate(14px, -9px) scale(1)",
    }
  }

};


const CustomContentSelectField = (props) => {
  // States
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [key, setKey] = useState("");
  const [isFocus, setFocus] = useState(false);

  // Effects


  useEffect(() => {
    setFocus(false);
    setValue(props?.value || "");
  }, [props]);

  useEffect(() => {
    // Setting value
    // Setting options
    async function fetchData() {
      let data = [];
      if (props?.dependency === true) {
        if (
          Array.isArray(props?.triggerValue) &&
                    props?.triggerValue.length > 0 &&
                    props?.triggerValue.includes("") === false
        ) {
          data = await props?.populator(props?.triggerValue);
        }
      }
      if (props?.dependency === false) {
        data = await props?.populator();

      }
      setOptions(data);
    }
    if (typeof props.populator === "function") {
      fetchData();
    }

    setKey(v4());
  }, [props.triggerValue]);

  // Trackers
  // useEffect(()=> {console.log(options)}, [options]);
  // useEffect(() => { console.log(value) }, [value]);
  // useEffect(() => { console.log(isFocus) }, [isFocus]);
  // Handlers
  const handleChange = (event) => {

    setValue(event.target.value);
    setKey(v4());
    props.changeHandler({
      field: props.name,
      value: isNaN(event.target.value) === true ? event.target.value : parseInt(event.target.value)
    });
  };

  const onFocusHandler = (event) => {
    if (event.type === "focus") {
      setFocus(true);
      return;
    }
    setFocus(false);
  };

  // Renderer
  return (
    <React.Fragment>
      <TextField
        // key={key}
        id={`${props?.name}-id`}
        variant={"outlined"}
        select={true}
        label={props.label ?
          <CustomTypography
            variant={isFocus === true ? "BOLD" : "REGULAR"}
            size="EXTRA-SMALL"
            content={props.label || ""}
          />
          : ""}
        onFocus={onFocusHandler}
        onBlur={onFocusHandler}
        SelectProps={{
          SelectDisplayProps: {
          },
          MenuProps: {

            PaperProps: {
              sx: {
                margin: "16px 0px",
                padding: "4px 2px",
                overflow: "auto",
                maxHeight: "200px",
                backgroundColor: "#FFFFFF",
                borderRadius: SELECTFIELD_CONSTANTS?.field["border_radius"]
              }
            },
          },
        }}
        fullWidth={true}
        disabled={props?.disabled || false}
        helperText={props?.helperText || ""}
        placeholder={props?.placeholder || ""}
        value={value}
        sx={customStyles}
        onChange={handleChange}
      >
        {options.map((option, index) => {

          return (
            <MenuItem
              key={option.key}
              value={option.value}
              sx={{
                m: "0px",
                py: "4px",
                height: { sm: 32, md: 40, lg: 48, xl: 56 },
              }}
            >
              {
                props.menuItemBuilder(option)
              }
            </MenuItem>
          );
        }
        )}



      </TextField>
    </React.Fragment>
  );
};

CustomContentSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  multi: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  dependency: PropTypes.bool.isRequired,
  triggerValue: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  menuItemBuilder: PropTypes.func,
  populator: PropTypes.func.isRequired,
};

CustomContentSelectField.defaultProps = {
  name: "",
  label: "",
  size: "",
  multi: false,
  value: null,
  disabled: false,
  dependency: false,
  triggerValue: [],
  menuItemBuilder: (option) => {
    return (
      <MenuItem
        key={option.key}
        value={option.value}
      >
        <CustomTypography
          size='EXTRA-SMALL'
          variant='REGULAR'
          content={option.label || ""}
        />
      </MenuItem>
    );
  },
  changeHandler: () => { },
  populator: () => { },
};

export default CustomContentSelectField;