import React from "react";
import PropTypes from "prop-types";

// Legacy Imports
import Paper from "@mui/material/Paper";

const CustomCard = (props) => {
  return (
    <React.Fragment>
      <Paper
        elevation={0}
        variant={props.outlined === true ? "outlined" : "elevation"}
        sx={{
          width: "100%",
          borderRadius: props?.borderRadius || "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: props?.spacing || "0px",
          border: "1px solid #E3E3E3",
          overflow: props?.overflow,
          ...props?.sx
        }}
      >
        {props?.content}
      </Paper>
    </React.Fragment>
  );
};

CustomCard.propTypes = {
  // width: PropTypes.string.isRequired,
  // fullWidth: PropTypes.bool.isRequired,
  borderRadius: PropTypes.string.isRequired,
  outlined: PropTypes.bool.isRequired,
  spacing: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  overflow: PropTypes.string.isRequired
};

CustomCard.defaultProps = {
  // width: '',
  // fullWidth: false,
  borderRadius: "",
  outlined: false,
  spacing: "",
  content: <></>,
  overflow: "hidden"
};

export default CustomCard;
