const defaultSizes = {
  sm: "32px",
  md: "40px",
  lg: "48px",
  xl: "56px",
};

const badgeSizes = {
  xs: "10px",
  sm: "12px",
  md: "14px",
  lg: "16px",
  xl: "18px",
};
export { defaultSizes, badgeSizes };
