import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import CustomIcon from "../../../../../../../library/icon-library";

function CustomRoundCloseButton(props) {

  const [state, setState] = useState(
    false
  );
  return (
    <Box
      onClick={props.onClick}
      sx={{
        width: "40px",
        height: "40px",
        aspectRatio: "1",
        borderRadius: "50%",
        border: props.outlined === true ? `1px solid ${state === true ? "#DA1E28" : "#cccccc"}` : "0px",
        backgroundColor: state === true ? "#DA1E28" : "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& :hover": {
          cursor: "pointer",
        }
      }}
      onPointerEnter={() => { setState(true); }}
      onPointerLeave={() => { setState(false); }}
    >
      <CustomIcon size={"xl"} icon={"close"} color={state === true ? "white" : "black"} />
    </Box>
  );
}

CustomRoundCloseButton.propTypes = {
  outlined: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

CustomRoundCloseButton.defaultProps = {
  outlined: false,
  onClick: () => { },
};

export default CustomRoundCloseButton;