export const mountStateBuilder = (data)=> {
  return {
    mfa: data?.mfa || false,
    minimum_character_required: data?.minimum_character_required || false,
    count_minimum_character: data?.count_minimum_character || 0,
    password_not_same_as_last_password: data?.password_not_same_as_last_password || false,
    count_password_not_same_as_last_password: data?.count_password_not_same_as_last_password || 0,
    mixed_case_letters: data?.mixed_case_letters || false,
    special_charecter: data?.special_charecter || false,
    first_letter_alphabet: data?.first_letter_alphabet || false,
    one_alphaber_one_number: data?.one_alphaber_one_number || false,
    password_expires: data?.password_expires || false,
    password_expiry_days: data?.password_expiry_days || 0,
  };
};