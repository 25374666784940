import { Pagination, Stack } from "@mui/material";
import PropTypes from "prop-types";
function BottomPaginationBar(props) {



  const handleChange = (event, value) => {
    props.onChange(value);
  };

  return (<Pagination
    sx={{
      ".MuiPaginationItem-outlined": {
        border: "1px solid #EDEDED",
        color: "#767B85",
        ":active": {
          backgroundColor: "white",
        },
        ":hover": {
          backgroundColor: "white",
        }

      },
      ".MuiPaginationItem-previousNext": {
        ":hover": {
          backgroundColor: "#5563F5",
          color: "white",
        }
      },
      ".Mui-selected": {
        backgroundColor: "white",
        border: "1px solid #5563F5",
        color: "#5563F5"
      }

    }}

    count={props.totalIndex}
    page={props.currentIndex}
    variant="outlined"
    onChange={handleChange} />);
}

BottomPaginationBar.propTypes = {
  totalIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
BottomPaginationBar.defaultProps = {
  totalIndex: 3,
  currentIndex: 0,
  onChange: (index) => { }
};

export default BottomPaginationBar;