import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { CustomDivider } from "../Components";
import { Button, DialerInput, RadioButtonGroup } from "components";
import { useEffect, useState } from "react";
import {
  useGetTimesheetConfigurations,
  useUpdateTimesheetConfigurations,
} from "controller";
import toast from "react-hot-toast";

export const ServiceDeskTimesheetComponent = () => {
  const theme = useTheme();
  const [billingCycle, setBillingCycle] = useState("weekly");
  const [expiryIn, setExpiryIn] = useState("7");
  const [inititalData, setInititalData] = useState({
    billing_cycle: "weekly",
    expiry_interval: 7,
  });
  const [isValueChanged, setIsValueChanged] = useState(false);

  const { data: timesheetConfigData } = useGetTimesheetConfigurations();

  const { mutateAsync: updateTimesheetConfig } =
    useUpdateTimesheetConfigurations();

  useEffect(() => {
    if (
      timesheetConfigData &&
      Array.isArray(timesheetConfigData) &&
      timesheetConfigData.length > 0
    ) {
      setInititalData({
        billing_cycle: timesheetConfigData[0].billing_cycle,
        expiry_interval: timesheetConfigData[0].expiry_interval,
      });
      setBillingCycle(timesheetConfigData[0].billing_cycle);
      setExpiryIn(timesheetConfigData[0].expiry_interval.toString());
    }
  }, [timesheetConfigData]);

  const resetData = () => {
    setBillingCycle(inititalData.billing_cycle);
    setExpiryIn(inititalData.expiry_interval.toString());
    setIsValueChanged(false);
  };

  const handleSave = () => {
    try {
      updateTimesheetConfig({
        billing_cycle: billingCycle,
        expiry_interval: parseInt(expiryIn),
      })
        .then((res) => {
          setIsValueChanged(false);
          toast.success("Timesheet configurations updated successfully");
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      toast.error("Unable to update timesheet configurations");
    }
  };

  return (
    <Stack p={"32px"} height={"100%"} gap={"24px"}>
      <Typography variant={"h4"}>Timesheets</Typography>
      <Stack
        width={"100%"}
        flex={1}
        border={`1px solid ${theme.palette.neutral["015"]}`}
        borderRadius={"24px"}
        divider={<Divider />}
      >
        <Stack flex={1} borderRadius={"24px"} divider={<Divider />}>
          <Stack p={"24px"} gap={"12px"}>
            <Typography variant={"body-medium"}>Billing Cycle</Typography>
            <RadioButtonGroup
              row
              data={billingCycleData}
              labelMappingKey={"label"}
              valueMappingKey={"value"}
              value={billingCycle}
              onChange={(value) => {
                setIsValueChanged(true);
                setBillingCycle(value);
              }}
            />
          </Stack>
          {/* <Stack
            p={"24px"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={`1px solid ${theme.palette.neutral["015"]}`}
          >
            <Typography variant={"body-medium"}>Expiry in</Typography>
            <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
              <DialerInput
                onValueChange={(newValue) => {
                  setIsValueChanged(true);
                  setExpiryIn(newValue);
                }}
                value={expiryIn}
                minValue={7}
                //   maxValue={20}
              />
              <Typography variant={"body-medium"}>Days</Typography>
            </Stack>
          </Stack> */}
        </Stack>
        {isValueChanged && (
          <Stack
            direction={"row"}
            justifyContent={"end"}
            gap={"16px"}
            px={"24px"}
            py={"16px"}
          >
            <Button variant="secondary" onClick={resetData}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const billingCycleData = [
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];
