interface ConvertToHourData {
  hours?: number | null | undefined;
  minutes?: number | null | undefined;
  seconds?: number | null | undefined;
}

export function convertToHours({
  hours = 0,
  minutes = 0,
  seconds = 0,
}: ConvertToHourData) {
  try {
    const durationInHours =
      (hours ? hours : 0) +
      (minutes ? minutes : 0) / 60 +
      (seconds ? seconds : 0) / 3600;
    return durationInHours.toFixed(2);
  } catch (e) {
    return null;
  }
}

export function convertHoursObject(durationInHours = 0) {
  const hours = Math.floor(durationInHours);
  const minutes = Math.floor((durationInHours - hours) * 60);
  const seconds = Math.floor(((durationInHours - hours) * 60 - minutes) * 60);
  return { hours, minutes, seconds };
}

export function convertDoubletoHourAndMinutes(
  durationInHours: string | number = 0,
  format: "colons" | "space" = "space"
) {
  try {
    if (typeof durationInHours === "string") {
      durationInHours = parseFloat(durationInHours);
    }
    const hours = Math.floor(durationInHours);
    const minutes = Math.floor((durationInHours - hours) * 60);
    return format === "space"
      ? `${hours || 0} Hours ${minutes || 0} Minutes`
      : `${hours}:${minutes}:00`;
  } catch (e) {
    return null;
  }
}

export function msToHHMMSSString(seconds: number) {
  if (seconds <= 0) {
    return "00:00:00";
  }
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);
  return (
    (hours < 10 ? `0${hours}` : hours) +
    ":" +
    (minutes < 10 ? `0${minutes}` : minutes) +
    ":" +
    (seconds < 10 ? `0${seconds}` : seconds)
  );
}

export const convertToHourHandler = (value: any) => {
  try {
    return convertToHours(value)||0;
  } catch (e) {
    return value||0;
  }
};

export const formatDecimalHoursToDaysAndHours = (decimalHours: number) => {
  // Calculate total minutes from decimal hours
  const totalMinutes = decimalHours * 60;

  // Calculate days, hours, and minutes
  const days = Math.floor(totalMinutes / (24 * 60));
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = Math.round(totalMinutes % 60);

  // Format the output
  if (days > 0) {
    return `${days}d ${hours}h`;
  } else {
    return `${hours}h ${minutes}m`;
  }
};
