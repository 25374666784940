import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createBillingMailTemplate,
  deleteBillingTemplate,
  getAllBillingTemplate,
  updateBillingMailTemplate,
} from "controller/api/configurations/billingAndContracts/billingTemplate";
import { GetBillingTemplateInput, GetQueryProps } from "shared";

export const useGetAllBillingTemplate = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: [
      "billingTemplates",
      perPageCount,
      pageNumber,
      filter,
      searchQuery,
    ],
    queryFn: () =>
      getAllBillingTemplate({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error) => console.log(error),
  });
};

export const useCreateBillingTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => createBillingMailTemplate(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["billingTemplates"] });
    },
    onError: (error) => console.log(error),
  });
};

export const useDeleteBillingTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { invoice_template_ids: string[] }) =>
      deleteBillingTemplate(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["billingTemplates"] });
    },
    onError: (error) => console.log(error),
  });
};

export const useUpdateBillingTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => updateBillingMailTemplate(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["billingTemplates"] });
    },
    onError: (error) => console.log(error),
  });
};
