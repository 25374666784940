import { authAxiosClient } from "utilz";

export const getMfa = async () => {
  try {
    const url = "/v1/mfa";
    const response = await authAxiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    } else if (resData?.statusCode === 404) {
      return {
        enforceMFAType: "NotSet",
      };
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const createMfa = async () => {
  try {
    const url = "v1/mfa";
    const response = await authAxiosClient.post(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteMfa = async () => {
  try {
    const url = "v1/mfa";
    const response = await authAxiosClient.delete(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateMfa = async (data: {
  enforceMFAType: "DontForce" | "Force";
  allowRememberMyDevice: boolean;
}) => {
  try {
    const url = "v1/mfa";
    const response = await authAxiosClient.patch(`${url}`, data);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const unenrollResourceMfa = async (data: { userId: string }) => {
  try {
    const url = `v1/mfa/unenroll/user/${data.userId}`;
    const response = await authAxiosClient.post(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
