import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addWatchListItem,
  getWatchlist,
  removeWatchListItem,
} from "controller/api";

import { ApiErrorType, UpdateWatchListInput } from "shared";
import { sentryLogger } from "utilz";

export const useGetWatchList = () => {
  return useQuery({
    queryKey: ["watchlist"],
    queryFn: () => getWatchlist(),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        workflow: "watchlist",
        functionName: "getWatchList",
      });
    },
  });
};

export const useRemoveWatchListItem = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateWatchListInput) => removeWatchListItem(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watchlist"] });
    },
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "watchlist",
        functionName: "removeWatchListItem",
      }),
  });
};

export const useAddWatchListItem = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateWatchListInput) => addWatchListItem(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watchlist"] });
    },
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "watchlist",
        functionName: "addWatchListItem",
      }),
  });
};
