import { SvgIcon, SvgIconProps } from "@mui/material";

export const HammerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m18.672 6.702-2.616-2.616a3.7 3.7 0 0 0-5.233 0L9.65 5.259a.462.462 0 0 0 .327.789h.734c.49 0 .961.195 1.308.542l.766.766v1.309l1.963 1.962 1.01-.3.952.95v1.309l.562.562c.413.412 1.08.412 1.493 0l2.431-2.432a1.054 1.054 0 0 0 0-1.493l-.56-.561h-1.31l-.95-.952.296-1.008Z"
        clip-rule="evenodd"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.194 20.304a2.374 2.374 0 0 0 3.49-.146l8.064-9.534-1.963-1.962-9.464 8.166a2.374 2.374 0 0 0-.127 3.476Z"
        clip-rule="evenodd"
      />
    </svg>
  </SvgIcon>
);
