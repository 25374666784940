import { SvgIcon, SvgIconProps } from "@mui/material";

export const EmailTickIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.267"
        d="M19.794 12.656V7.479A1.477 1.477 0 0 0 18.314 6H6.482a1.478 1.478 0 0 0-1.478 1.479v8.876c0 .814.667 1.478 1.478 1.478H12.4"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.267"
        d="m19.794 8.217-6.635 4.214a1.446 1.446 0 0 1-1.525 0L5 8.217M15.354 17.092l1.48 1.48 2.957-2.958"
      />
    </svg>
  </SvgIcon>
);
