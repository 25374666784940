import { Box, Stack } from "@mui/material";
import { CustomTextField } from "../../../../../../../library/system/custom-form";
import PropTypes from "prop-types";
import { CustomButton } from "../../../../../../../library/base";
import { useEffect, useState } from "react";

interface CreateAttributeTileProps {
  name?: string;
  value: string;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}
export const CreateAttributeTile = ({
  name,
  onCancel,
  onSubmit,
  value,
}: CreateAttributeTileProps) => {
  const [text, setText] = useState(value);

  const onSubmitHandler = () => {
    if (text.trim().length > 0 && name) {
      const data = {
        [name || ""]: text,
      };
      onSubmit(data);
    }
  };

  const onChangeHandler = (data: any) => {
    setText(data.value);
  };

  return (
    <Stack
      sx={{
        justifyContent: "stretch",
        px: "16px",
        py: "24px",
        gap: "16px",
        borderBottom: "1px solid #cccccc",
      }}
    >
      <CustomTextField
        placeholder="Enter new type"
        name={name || ""}
        value={text || ""}
        changeHandler={onChangeHandler}
      />
      <Stack direction={"row"} width={"100%"} gap={"24px"}>
        <Box
          sx={{
            flexGrow: "1",
            flexShrink: "1",
            justifyContent: "start",
            display: "flex",
          }}
        >
          <CustomButton
            fullWidth={true}
            label="Cancel"
            varient={"secondary"}
            onClick={onCancel}
          />
        </Box>
        <Box
          sx={{
            flexGrow: "1",
            flexShrink: "1",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <CustomButton
            fullWidth={true}
            label="Save"
            varient={"primary"}
            onClick={onSubmitHandler}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
