export const defaultStateBuilder = () => {
  return {
    is_time_format_is_12_hr: "",
    is_time_zone_is_resources: "",
    default_font_for_tickets: "",
    default_font_size_for_tickets: "",
    default_service_board_for_tickets: "",
    default_service_board_for_request: "",
    default_service_board_for_projects: "",
  };
};