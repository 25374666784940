import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createLocation,
  deleteLocation,
  getAllLocations,
  updateLocation,
} from "controller/api";
import {
  ApiErrorType,
  CreateLocationInput,
  GetQueryProps,
  UpdateLocationInput,
} from "shared";
import { sentryLogger } from "utilz";

export const useGetAllLocations = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: () =>
      getAllLocations({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) => {
      sentryLogger({
        error: error,
        data: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllLocations",
      });
    },
  });
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createLocation,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["locations"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        data: variables,
        workflow: "controlCenter",
        level: "error",
        functionName: "createLocation",
      });
    },
  });
};

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateLocation,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["locations"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        data: variables,
        workflow: "controlCenter",
        level: "error",
        functionName: "updateLocation",
      });
    },
  });
};

export const useDeleteLocations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteLocation,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["locations"]);
    },
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        data: variables,
        workflow: "controlCenter",
        level: "error",
        functionName: "deleteLocation",
      });
    },
  });
};
