import { SvgIcon, SvgIconProps } from "@mui/material";

interface PlusIconProps extends SvgIconProps {
  endIconSize?: string;
}
// export const PlusIcon = (props: SvgIconProps) => (
export const PlusIcon = (props: PlusIconProps) => {
  const { endIconSize, ...rest } = props;
  return (
    <SvgIcon {...props} style={{ fontSize: endIconSize }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <g clip-path="url(#a)">
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M12 8v8M16 12H8"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
