import { Stack } from "@mui/material";
import React from "react";
import { Avatar } from "./Avatar";

interface AvatarProps {
  total?: number;
  max?: number;
  children: React.ReactNode;
  //   height and width of child Avatar components
  childWidthHeight?: string;
  //   child left margin to control overlap
  overlapMargin?: string;
  showExtrasNumberingAvatar?: boolean;
}
export const AvatarGroup = ({
  total,
  max,
  children: childrenProp,
  childWidthHeight = "40px",
  overlapMargin = "-12px",
  showExtrasNumberingAvatar = true,
}: AvatarProps) => {
  const children = React.Children.toArray(childrenProp).filter((child) => {
    return React.isValidElement(child);
  });
  return (
    <Stack direction="row">
      {children.slice(0, max).map((child: any, index) => {
        return React.cloneElement(child, {
          style: {
            marginLeft: overlapMargin,
            width: childWidthHeight,
            height: childWidthHeight,
            borderWidth: "2px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.14)",
          },
        });
      })}
      {total || (max && max < children.length && showExtrasNumberingAvatar) ? (
        <Avatar
          fullname={`+ ${total ? total : max && children.length - max}`}
          sx={{
            marginLeft: overlapMargin,
            width: childWidthHeight,
            height: childWidthHeight,
            borderWidth: "2px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.14)",
            cursor: "pointer",
          }}
        ></Avatar>
      ) : (
        <></>
      )}
    </Stack>
  );
};
