import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import toast from "react-hot-toast";
import {
  CustomButton,
  CustomTypography,
} from "../../../../../../../../../../library/base";
import { CustomRoundCloseButton } from "../../../../../Components";
import PropTypes from "prop-types";
import { CustomTextField } from "../../../../../../../../../../library/system/custom-form";
import { FORM_CONSTANT, formDataBuilder } from "./constants";
import { useEffect, useState } from "react";
import { CustomQuillEditor } from "../../../component";
import { extractStringFromHtml } from "utilz/helpers";

function CreateCannedNotesModal(props) {
  const [state, setState] = useState({
    data: {},
  });

  useEffect(() => {
    setState({
      ...state,
      data: formDataBuilder(props.selectedData || {}),
    });
  }, []);

  const onChangeValueHandler = (data) => {
    var newData = state.data;
    newData[data.field] = data.value;
    setState({
      ...state,
      ...newData,
    });
  };

  const onSubmitHandler = () => {
    const quillStringdata = extractStringFromHtml(
      state.data[FORM_CONSTANT.canned_notes_quil.name]
    );
    if (
      state.data[FORM_CONSTANT.canned_notes_title.name] !== "" &&
      quillStringdata !== undefined &&
      quillStringdata != null &&
      quillStringdata !== ""
    ) {
      props.onSubmit(state.data);
      return;
    }
    toast.error("Fill all fields");
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          borderRadius: "24px",
          maxWidth: "900px",
          height: {
            md: "min(80%,600px)",
            lg: "min(80%,615px)",
            xl: "min(80%,630px)",
          },
        },
      }}
    >
      {/* <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: ' nowrap',
                justifyContent: 'flex-start',
                aligItems: 'stretch',
                alignContent: 'flex-start',
                height: '100%',
            }}
        > */}
      <DialogTitle>
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            alignItems: "center",
            columnGap: "40px",
          }}
        >
          <Box
            direction={"column"}
            sx={{
              flexShrink: "1",
              flexGrow: "1",
              justifyContent: "center",
            }}
          >
            <CustomTypography
              content={props.title}
              size="MEDIUM-ALPHA"
              variant="BOLD"
            />
          </Box>
          <CustomRoundCloseButton onClick={props.onClose} />
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          m: "0px",
          p: "0px",
        }}
      >
        <Stack gap={"32px"}>
          <Box
            sx={{
              px: "32px",
              mt: "32px",
            }}
          >
            <CustomTextField
              label={FORM_CONSTANT.canned_notes_title.label}
              required={true}
              name={FORM_CONSTANT.canned_notes_title.name}
              changeHandler={onChangeValueHandler}
              maxLength={200}
              placeholder={FORM_CONSTANT.canned_notes_title.placeholder}
              value={state.data[FORM_CONSTANT.canned_notes_title.name] || ""}
            />
          </Box>

          <CustomQuillEditor
            label={FORM_CONSTANT.canned_notes_quil.label}
            name={FORM_CONSTANT.canned_notes_quil.name}
            value={state.data[FORM_CONSTANT.canned_notes_quil.name] || ""}
            onChange={onChangeValueHandler}
            placeholder={FORM_CONSTANT.canned_notes_quil.placeholder}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          flexGrow={0}
          flexShrink={0}
          flexDirection={"row-reverse"}
          columnGap={"16px"}
          sx={{
            width: "100%",
            py: "16px",
            px: "32px",
            ...(props.isLoading && {
              filter: "grayscale(1)",
              opacity: "0.6",
              pointerEvents: "none",
            }),
          }}
        >
          <CustomButton label="Save" onClick={onSubmitHandler} />
          <CustomButton
            label="Cancel"
            varient="secondary"
            onClick={props.onClose}
          />
        </Stack>
      </DialogActions>
      {/* </Stack> */}
    </Dialog>
  );
}
CreateCannedNotesModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  selectedData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

CreateCannedNotesModal.defaultProps = {
  title: "Create new Canned notes",
  open: false,
  selectedData: null,
  onSubmit: () => {},
  onClose: () => {},
};

export default CreateCannedNotesModal;
