import { Box, Stack } from "@mui/material";
import { CustomButton } from "../../../../../../../library/base";
import { SearchTextField } from "../../Components";
import { Button, PlusIcon, SearchInput } from "components";
import { debugConsole } from "utilz/helpers";

export const BoardHeader = ({
  data,
  createNewHandler,
  updateSearchHandler,
  resetSearchHandler,
}: {
  data: Array<any>;
  createNewHandler: () => void;
  updateSearchHandler: (value: string) => void;
  resetSearchHandler: () => void;
}) => {
  const [searchQuery, setSearchQuery] = data;

  const handleUpdateSearch = (event: any) => {
    try {
      const newValue = event.target.value;
      setSearchQuery(newValue);
      if (newValue.length > 0 && newValue.length <= 3) {
        return;
      }
      updateSearchHandler(newValue);
    } catch (err) {
      debugConsole(
        "🚀 ~ file: BoardHeader.tsx:28 ~ handleUpdateSearch ~ err:",
        err
      );
    }
  };

  // const handleResetSearch = () => {
  //   setSearchQuery("");
  //   resetSearchHandler();
  // };

  return (
    <Stack
      direction={"row"}
      justifyContent={"right"}
      alignItems={"center"}
      gap={"16px"}
    >
      <SearchInput
        name={""}
        placeholder="Search"
        value={searchQuery}
        onChange={handleUpdateSearch}
        sx={{
          pt: "3px",
        }}
        hideHelperBox
      />

      <Button endIcon={<PlusIcon />} onClick={createNewHandler}>
        Board
      </Button>
    </Stack>
  );
};
