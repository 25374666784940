import {
  BulkDeleteTaskInput,
  BulkUpdateTasksInput,
  CreateTaskInput,
  UpdateTaskDetailsInput,
  UpdateTaskPriorityInput,
  UpdateTaskStatusInput,
} from "shared";
import { axiosClient } from "utilz";

export const createTask = async ({
  milestoneId,
  data,
  projectId,
}: CreateTaskInput) => {
  try {
    const url = `/servicedesk/project/tasks/${milestoneId}/${projectId}`;

    const payload = {
      task_name: data.taskName,
      order: data.order,
      customer_id: data.customerId,
      contract_id: data.contractId,
    };
    const response = await axiosClient.post(`${url}`, payload);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

// get task by id
export const getTask = async (id: string) => {
  try {
    const url = `/servicedesk/project/tasks/${id}`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateTaskDetails = async ({
  taskId,
  data,
}: UpdateTaskDetailsInput) => {
  try {
    const url = `/servicedesk/project/tasks/${taskId}`;

    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const bulkUpdateTasks = async ({ tasks }: BulkUpdateTasksInput) => {
  try {
    const url = "/servicedesk/project/bulk/tasks";

    const data = {
      tasks,
    };
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const bulkDeleteTasks = async ({ taskIds }: BulkDeleteTaskInput) => {
  try {
    const url = "/servicedesk/project/tasks";

    const data = {
      task_ids: taskIds,
    };
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateTaskStatus = async ({
  statusId,
  taskId,
}: UpdateTaskStatusInput) => {
  try {
    const url = `/servicedesk/project/tasks/${taskId}/status/${statusId}`;
    const response = await axiosClient.patch(`${url}`, {});
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};

export const updateTaskPriority = async ({
  taskId,
  priority,
}: UpdateTaskPriorityInput) => {
  try {
    const url = `/servicedesk/project/tasks/${taskId}/priority/${priority}`;

    const response = await axiosClient.patch(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    } else {
      throw new Error(resData?.message);
    }
  } catch (error) {
    throw error;
  }
};
