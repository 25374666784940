import { SvgIcon, SvgIconProps } from "@mui/material";

export const FlagFilledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.996 4.546v16.458"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M3.997 16.502a7.965 7.965 0 0 1 7.187-.363l1.633.726a7.965 7.965 0 0 0 7.187-.363V4.546a7.965 7.965 0 0 1-7.187.363l-1.633-.726a7.965 7.965 0 0 0-7.187.363"
        clip-rule="evenodd"
      />
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width=".5"
        d="M3.997 16.502a7.965 7.965 0 0 1 7.187-.363l1.633.726a7.965 7.965 0 0 0 7.187-.363V4.546a7.965 7.965 0 0 1-7.187.363l-1.633-.726a7.965 7.965 0 0 0-7.187.363"
      />
    </svg>
  </SvgIcon>
);
