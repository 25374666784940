import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { set } from "date-fns";
import { useState } from "react";

export const Stepper = ({
  step = 1,
  totalSteps = 2,
}: {
  step?: number;
  totalSteps?: number;
}) => {
  const theme = useTheme();
  const [isAnimationStart, setIsAnimationStart] = useState<boolean>(false);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap="24px"
      width={"100%"}
    >
      {[...Array(totalSteps - 1)].map((_, index) => (
        <Stack
          direction={"row"}
          key={`stepper_counter_${index}`}
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          gap="24px"
        >
          <StepperValue
            step={index + 1}
            status={step >= index + 1 ? "active" : "disabled"}
            totalSteps={totalSteps}
          />
          <Stack flex={1}>
            <LinearProgress
              color="success"
              variant="determinate"
              value={step < index + 1 ? 0 : step === index + 1 ? 50 : 100}
              sx={{
                ".MuiLinearProgress-bar": {
                  transitionDelay:
                    step === index + 2 || step === index ? "0ms" : "400ms",
                },
              }}
            />
          </Stack>
        </Stack>
      ))}
      <StepperValue
        step={totalSteps}
        status={step >= totalSteps ? "active" : "disabled"}
        totalSteps={totalSteps}
      />
    </Stack>
  );
};

const StepperValue = ({
  step,
  status,
  totalSteps = 2,
}: {
  step: number;
  status: "active" | "disabled";
  totalSteps?: number;
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="16px"
    >
      <Box
        height="32px"
        width="32px"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: "100%",
          backgroundColor:
            status !== "active" ? theme.palette.neutral["015"] : "transparent",
          color:
            status === "active"
              ? theme.palette.success.main
              : theme.palette.lightBg.high,
          border: `2px solid ${
            status === "active"
              ? theme.palette.success.main
              : theme.palette.neutral["015"]
          }`,
        }}
      >
        <Typography variant="body-medium">{step}</Typography>
      </Box>
      <Typography
        variant="body-medium"
        noWrap
        sx={{
          color:
            status === "active"
              ? theme.palette.success.main
              : theme.palette.lightBg.low,
        }}
      >
        Step {step} of {totalSteps}
      </Typography>
    </Stack>
  );
};
