import { Immer } from "immer";
import { immer } from "zustand/middleware/immer";

interface ReadyToBillState {
  readyToBill: {
    products: Array<Record<string, any>>;
    expenses: Array<Record<string, any>>;
    timeEntries: Array<Record<string, any>>;
    totalItems: number;
    customerId: string;
  };
}

interface ReadyToBillAction {
  setReadyToBillData: ({
    products,
    expenses,
    timeEntries,
    customerId,
  }: {
    products: Array<Record<string, any>>;
    expenses: Array<Record<string, any>>;
    timeEntries: Array<Record<string, any>>;
    customerId: string;
  }) => void;
}

export interface ReadyToBillStateActionInterface
  extends ReadyToBillAction,
    ReadyToBillState {}

export const readyToBillSlice = immer<ReadyToBillState & ReadyToBillAction>(
  (set, get) => ({
    readyToBill: {
      products: [],
      expenses: [],
      timeEntries: [],
      totalItems: 0,
      customerId: "",
    },

    setReadyToBillData: (data: {
      products: Array<Record<string, any>>;
      expenses: Array<Record<string, any>>;
      timeEntries: Array<Record<string, any>>;
      customerId: string;
    }) =>
      set((state) => {
        state.readyToBill = {
          ...data,
          totalItems:
            data.products.length +
            data.expenses.length +
            data.timeEntries.length,
        };
      }),
  })
);
