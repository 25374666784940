

export const resourcePopulator = async()=> {
  // try {
  //     return await generalServices
  //         .fetchAllCountries()
  //         .toPromise()
  //         .then((data) => {
  //             if (
  //                 data &&
  //                 data?.success === true &&
  //                 data?.statusCode === 200 &&
  //                 data?.data &&
  //                 data?.data?.result &&
  //                 Array.isArray(data?.data?.result)
  //             ) {
  //                 return data?.data?.result.map((option) => ({
  //                     key: `options-data-country-${option?.name}-${option?.iso3}-key`,
  //                     value: option?.id,
  //                     label: option?.name
  //                 }))
  //             } else {
  //                 return []
  //             }
  //         })
  // } catch (err) {
  //     console.log('[ERROR: POPULATOR] Fetching all countries');
  //     console.log(err)
  // }
  return await [
    {
      key: "options-resource-1-key",
      value: "resource-1",
      label: "Resource 1"
    },
    {
      key: "options-resource-2-key",
      value: "resource-2",
      label: "Resource 2"
    },
    {
      key: "options-resource-3-key",
      value: "resource-3",
      label: "Resource 3"
    },
    {
      key: "options-resource-4-key",
      value: "resource-4",
      label: "Resource 4"
    },
    {
      key: "options-resource-5-key",
      value: "resource-5",
      label: "Resource 5"
    },
  ];
};

const resourceTypePopulator = async ()=> {
  return await [
    {
      key: "options-resource-type-1-key",
      value: "development",
      label: "Development"
    },
    {
      key: "options-resource-type-2-key",
      value: "design",
      label: "Design"
    },
    {
      key: "options-resource-type-3-key",
      value: "marketing",
      label: "Marketing"
    },
    {
      key: "options-resource-type-4-key",
      value: "sales",
      label: "Sales"
    },
    {
      key: "options-resource-type-5-key",
      value: "administration",
      label: "Administration"
    },
  ];
};
const resourceWorkTypePopulator = async ()=> {
  return await [
    {
      key: "options-resource-work-type-1-key",
      value: "operations",
      label: "Operations"
    },
    {
      key: "options-resource-work-type-2-key",
      value: "delivery",
      label: "Delivery"
    },
    {
      key: "options-resource-work-type-3-key",
      value: "development",
      label: "Development"
    },
  ];
};
const tempLocationPopulator = async ()=> {
  return await [
    {
      key: "options-loc-1-key",
      value: "newyork",
      label: "New York"
    },
    {
      key: "options-loc-2-key",
      value: "london",
      label: "London"
    },
    {
      key: "options-loc-3-key",
      value: "tokyo",
      label: "Tokyo"
    },
    {
      key: "options-loc-4-key",
      value: "sydney",
      label: "Sydney"
    },
    {
      key: "options-loc-5-key",
      value: "beirut",
      label: "Beirut"
    },
    {
      key: "options-loc-6-key",
      value: "hamburg",
      label: "Hamburg"
    },
    {
      key: "options-loc-7-key",
      value: "madrid",
      label: "Madrid"
    },
  ];
};

export const resourcePopulatorServices = {
  resourcePopulator,
  resourceTypePopulator,
  resourceWorkTypePopulator,
  tempLocationPopulator
};
