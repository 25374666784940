import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Button, DeleteConfirmationModal, PlusIcon } from "components";
import { HomeScreenLayout } from "components/layout/HomeScreenLayout";
import { DataTable } from "components/table/DataTable";
import { useEffect, useState } from "react";
import { DrawerComponent } from "./components/DrawerComponent";
import {
  useCreateBillingTemplate,
  useGetAllBillingTemplate,
  useDeleteBillingTemplate,
} from "controller";
import toast from "react-hot-toast";
import { useMainStore } from "controller/store/mainStore";

const BillingHeaders = [
  {
    name: "Template Name",
    sortable: true,
    key: "template_name",
  },
  {
    name: "Payment Term",
    sortable: true,
    key: "payment_term",
  },
  {
    name: "Invoice Type",
    sortable: true,
    key: "invoice_type",
  },
  {
    name: "Discount",
    sortable: true,
    key: "discount_value",
  },
  {
    name: "Tax",
    sortable: true,
    key: "tax_name",
  },
  {
    name: "Mail Subject",
    sortable: true,
    key: "email_subject",
  },
];

export const ServiceDeskBillingEmailTemplateComponent = (data: any) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [mode, setMode] = useState<any>({
    method: "create",
    data: null,
    primaryButtonDisable: false,
  });
  const theme = useTheme();
  const [pageNo, setPageNumber] = useState(1);
  const [sortColumn, setSortColume] = useState("");
  const [sortDirection, setSortDirection] = useState<
    "asc" | "desc" | undefined
  >("desc");
  const [searchValue, setSearchValue] = useState("");
  const [billingTemplateResult, setBillingTemplateResult] = useState<[]>([]);
  const [changeSortColumnAndOrder, setChangeSortColumnAndOrder] = useState<any>(
    []
  );
  const perPageCount = useMainStore((state) => state.perPageCount);
  const setPerPageCount = useMainStore((state) => state.setPerPageCount);
  const { mutateAsync: deleteBillingTemplate } = useDeleteBillingTemplate();
  const {
    data: billingTemplateData,
    isFetching: isBillingTemplateDataLoading,
    refetch,
  } = useGetAllBillingTemplate({
    filter: [],
    pageNumber: pageNo,
    perPageCount: perPageCount.billingTemplate,
    sortColumn: "template_name",
    sortDirection: "asc",
    searchQuery: searchValue,
  });

  const openDrawer = (method: "create" | "update", id: string | null) => {
    if (method === "create") {
      setMode({ method: "create", data: null, primaryButtonDisable: false });
      setIsDrawerOpen(true);
    } else if (method === "update") {
      const findDataById: any = billingTemplateResult.find(
        (item: any) => item?.mapping_id === id
      );
      setMode({
        method: "update",
        data: findDataById,
        // primaryButtonDisable: findDataById?.meta?.is_default ? true : false,
        primaryButtonDisable: false,
      });
      setIsDrawerOpen(true);
    }
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (billingTemplateData) {
      setBillingTemplateResult(billingTemplateData?.result);
    }
  }, [billingTemplateData]);

  const handleItemClick = (id: string) => {
    const checkExistInTableData = billingTemplateResult.findIndex(
      (item: any) => item?.mapping_id === id
    );
    if (checkExistInTableData !== -1) {
      const index = selectedRow.indexOf(id);
      // If ID is not in the selectedRow array, add it
      if (index === -1) {
        setSelectedRow([...selectedRow, id]);
      } else {
        // If ID is already in the selectedRow array, remove it
        const updatedSelectedRow = [...selectedRow];
        updatedSelectedRow.splice(index, 1);
        setSelectedRow(updatedSelectedRow);
      }
    }
  };

  const onHeaderCheckboxClick = (currentState: "checked" | "unchecked") => {
    // const selectedArray: any = [];
    if (currentState === "checked") {
      const selectedRowSet = new Set(selectedRow);
      const templateIds = billingTemplateResult
        .filter(
          (item: any) =>
            !selectedRowSet.has(item.mapping_id) && !item.meta?.is_default
        )
        .map((item: any) => item.mapping_id);
      setSelectedRow(templateIds);
    } else {
      setSelectedRow([]);
    }
  };

  const handleDeleteClick = async () => {
    try {
      // await deleteSelectedCustomers({ customerIds: selectedCustomers });
      await deleteBillingTemplate({ invoice_template_ids: selectedRow });
      setIsDeleteModalOpen(false);
      setSelectedRow([]);
      toast.success("Template successfully deleted");
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const componentName = "Billing Templates";

  const cellBuilders = {
    payment_term: (rowData: any) => {
      return `NET ${rowData.payment_term}`;
    },
    invoice_type: (rowData: any) => {
      return (
        <Typography variant="body-small" textTransform={"capitalize"}>
          {rowData.invoice_type}
        </Typography>
      );
    },
    discount_value: (rowData: any) => {
      return (
        <Typography variant="body-small" textTransform={"capitalize"}>
          {rowData.discount_value / 100}
        </Typography>
      );
    },
  };

  const checkIsCheckboxDisabled = (rowData: any) => {
    // default template
    return rowData?.meta?.is_default === true;
  };
  return (
    // <Box sx={{ padding: 3 }}>
    <HomeScreenLayout
      title="Billing Templates"
      rightActionComponent={
        <Stack direction={"row"} gap={"24px"}>
          <Button
            endIcon={<PlusIcon />}
            onClick={() => openDrawer("create", null)}
          >
            Template
          </Button>
        </Stack>
      }
    >
      <DrawerComponent
        {...{ isDrawerOpen, closeDrawer, componentName, mode }}
      />
      <Box sx={{ paddingX: "32px" }}>
        <DataTable
          cellBuilders={cellBuilders}
          isCheckboxDisabled={checkIsCheckboxDisabled}
          showPagination={true}
          headers={BillingHeaders}
          data={isBillingTemplateDataLoading ? [] : billingTemplateResult || []}
          selectedItems={selectedRow}
          totalItems={billingTemplateData?.totalCount}
          totalPages={billingTemplateData?.totalPages}
          currentPageNumber={pageNo}
          onItemClick={handleItemClick} //check box
          onAllSelect={(currentState: any) => {
            onHeaderCheckboxClick(currentState);
          }}
          isDataLoading={isBillingTemplateDataLoading}
          itemsPerPage={perPageCount.billingTemplate}
          onPerPageChange={(itemsPerPage) => {
            setPerPageCount({ type: "billingTemplate", count: itemsPerPage });
          }}
          onPagination={(d) => {
            setPageNumber(d);
            // changePageNo(d);
          }}
          multiselect={true}
          handleRowClick={(id: any) => {
            openDrawer("update", id?.mapping_id);
          }}
          onSortingClick={(column) => setChangeSortColumnAndOrder(column)}
          actionContent={
            <Stack direction={"row"} gap={"24px"}>
              <Button
                onClick={() => setIsDeleteModalOpen(true)}
                variant="primary"
                sx={{
                  bgcolor: theme.palette.error.main,
                  "&:hover": { backgroundColor: "#A9050E" },
                }}
              >
                Delete
              </Button>
            </Stack>
          }
          // isCheckboxDisabled={(item: any) => {
          //   return false;
          // }}
          // containerStyles={{
          //   height: {
          //     xs: "calc(100vh - 300px)",
          //     sm: "calc(100vh - 320px)",
          //     md: "calc(100vh - 320px)",
          //     lg: "calc(100vh - 320px)",
          //   },
          //   borderTop: "0px",
          // }}
        />
        {isDeleteModalOpen && (
          <DeleteConfirmationModal
            open={isDeleteModalOpen}
            onPrimaryButtonClick={handleDeleteClick}
            onSecondaryButtonClick={() => setIsDeleteModalOpen(false)}
            title="Delete Billing Template"
            tagline="Are you sure you want to delete?"
          />
        )}
      </Box>
    </HomeScreenLayout>
  );
};
