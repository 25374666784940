import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import { Button, PlusIcon, SearchInput } from "components";
interface CheckListHeaderComponentProps {
  createNewHandler: () => void;
  updateSearchHandler: (value: string) => void;
  resetSearchHandler: () => void;
}
export const CheckListHeaderComponent = (
  props: CheckListHeaderComponentProps
) => {
  const [state, setState] = useState({
    search: "",
  });

  const handleUpdateSearch = (event: any) => {
    try {
      const newValue = event.target.value;
      setState({ ...state, search: newValue });
      if (newValue.length > 0 && newValue.length <= 3) {
        return;
      }
      props.updateSearchHandler(newValue);
    } catch (err) {
      console.log("[ERROR] Updating search");
      console.log(err);
    }
  };

  // const handleResetSearch = () => {
  //   setState({ ...state, search: "" });
  //   props.resetSearchHandler();
  // };

  return (
    <Stack direction={"row"} columnGap={"24px"}>
      <SearchInput
        name={""}
        placeholder="Search"
        value={state.search}
        onChange={handleUpdateSearch}
        sx={{
          pt: "3px",
        }}
        hideHelperBox
      />

      <Button endIcon={<PlusIcon />} onClick={props.createNewHandler}>
        Checklist
      </Button>
    </Stack>
  );
};
