import { IconButton, useTheme } from "@mui/material";
import { BookmarkFilledIcon, BookmarkIcon } from "components/icons";

export const BookmarkButton = ({
  onClick,
  isLoading = false,
  isBookmarked = false,
}: {
  onClick: () => void;
  isLoading?: boolean;
  isBookmarked?: boolean;
}) => {
  const theme = useTheme();
  return (
    <IconButton
      sx={{
        p: "4px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      disabled={isLoading}
    >
      {isBookmarked ? (
        <BookmarkFilledIcon
          sx={{
            width: "20px",
            height: "20px",
            color: theme.palette.primary.main,
          }}
        />
      ) : (
        <BookmarkIcon
          sx={{
            width: "20px",
            height: "20px",
            color: theme.palette.lightBg.high,
          }}
        />
      )}
    </IconButton>
  );
};
