import {
  Dialog,
  DialogProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "components/button";
import React from "react";

interface ModalProps extends DialogProps {
  title?: string;
  tagline?: string | React.ReactNode;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  paperStyles?: React.CSSProperties;
  type?: "primary" | "error";
  isLoading?: boolean;
}

export const DeleteConfirmationModal = ({
  title = "Delete Confirmation",
  tagline = "Are you sure you want to delete?",
  open,
  paperStyles,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText = "Delete",
  secondaryButtonText = "Cancel",
  type = "error",
  isLoading = false,
  ...rest
}: ModalProps) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      // maxWidth={false}
      PaperProps={{
        style: {
          borderRadius: "20px",
          padding: "32px",
          width: "317px",
          ...paperStyles,
        },
      }}
      onClose={onClose}
      sx={{ display: "flex", flexDirection: "column" }}
      {...rest}
    >
      <Typography variant="h5" textTransform={"capitalize"}>
        {title}
      </Typography>
      <Typography
        sx={{ mt: "10px" }}
        variant="body-large"
        color={theme.palette.lightBg.low}
      >
        {tagline}
      </Typography>
      <Stack direction="row" mt="24px" gap="16px" justifyContent="flex-end">
        <Button
          disabled={isLoading}
          variant="secondary"
          onClick={onSecondaryButtonClick}
        >
          {secondaryButtonText}
        </Button>
        <Button
          loading={isLoading}
          variant="primary"
          onClick={onPrimaryButtonClick}
          sx={{
            ...(type === "error"
              ? {
                  bgcolor: theme.palette.error.main,
                  "&:hover": { backgroundColor: "#A9050E" },
                }
              : {}),
          }}
        >
          {primaryButtonText}
        </Button>
      </Stack>
    </Dialog>
  );
};
