import {
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useGetAllChatMessages,
  useGlobalStore,
  useSendChatMessage,
} from "controller";
import React, { useMemo } from "react";
import { ChatResultItem } from "shared/types";
import { dayjs } from "utilz";
import { MessageBubble } from "./ChatMessageBlocks";
import {
  Button,
  DropdownMenu,
  GlobeCheckmarkIcon,
  InfoIcon,
  LockIcon,
  PlaneFilledIcon,
  PlaneIcon,
  UpArrowIcon,
  UserIcon,
} from "components";
import { usePinnedStore } from "controller/store/pinned-chat/pinnedChatStore";
import { DisabledStatus, statusData } from "shared/data";
import { EmailAndNotesAttachmentBlock } from "shared/features/chat/MessageBlocks";
import NoChatImg from "assets/images/noChat.png";
export const ChatMessages = ({
  serviceType,
  serviceId,
  groupedMessages,
  showNoChatScreen,
  isMessagesLoading,
}: {
  serviceType: "ticket" | "task";
  serviceId: string;
  showNoChatScreen: boolean;
  isMessagesLoading: boolean;
  groupedMessages?: Record<string, ChatResultItem[]> | null;
}) => {
  const [isSendingMessage, setIsSendingMessage] = React.useState(false);
  const [value, setValue] = React.useState("");
  const resourceId = useGlobalStore((state) => state.resourceId);
  const profilePic = useGlobalStore((state) => state.profilePictureUrl);
  const displayName = useGlobalStore((state) => state.name);
  const theme = useTheme();
  const pinnedChat = usePinnedStore((state) => state.pinnedChat[serviceId]);
  const messageType = usePinnedStore(
    (state) => state.pinnedChat?.[serviceId]?.messageType || "private"
  );

  const isDisabled = useMemo(() => {
    return DisabledStatus.includes(pinnedChat?.serviceStatus);
  }, [pinnedChat]);

  const setMessageType = usePinnedStore((state) => state.updatePinnedChatData);
  const pinnedChatArray = usePinnedStore((state) => state.pinnedChat);
  const { mutateAsync: sendMessage } = useSendChatMessage();
  const SendMenuList = [
    { name: "self", type: "self", icon: <UserIcon fontSize="small" /> },
    { name: "private", type: "private", icon: <LockIcon fontSize="small" /> },
    {
      name: "public",
      type: "public",
      icon: <GlobeCheckmarkIcon fontSize="small" />,
    },
  ];

  const getMessageSenderType = (message: ChatResultItem) => {
    if (serviceType === "task") {
      return message.sender.mapping_id === resourceId ? "resource" : "customer";
    } else {
      return message.sender.user_type === "resource" ? "resource" : "customer";
    }
  };

  const transformAssignees = async (assignees: Array<Record<string, any>>) => {
    return assignees.map((assignee) => {
      return {
        mapping_id: assignee.mapping_id,
        display_name: assignee.display_name
          ? assignee.display_name
          : `${assignee.first_name} ${assignee.last_name}`,
        profile_pic: assignee.profile_image || "",
      };
    });
  };

  const handleSendMessage = async ({
    type,
  }: {
    type: "self" | "private" | "public";
  }) => {
    const pinnedChatItem = pinnedChatArray[serviceId];
    if (!pinnedChatItem) return null;
    if (isSendingMessage) return;

    setIsSendingMessage(true);

    const trimmedValue = value.trim();
    if (trimmedValue.length > 0 && !isDisabled) {
      const transformedAssignees = await transformAssignees(
        pinnedChatItem?.assignees || []
      );
      const messageData = {
        assignedResources: transformedAssignees,
        message: trimmedValue,
        accessSpecifier: type,
        serviceId,
        serviceType: serviceType,
        originType: pinnedChat?.sourceName || "web_app",
        messageType: "normal",
        sender: {
          mapping_id: resourceId || "",
          display_name: displayName || "",
          profile_pic: profilePic || "",
          user_type: "resource",
        },
      };
      const data = await sendMessage(messageData);

      // if (
      //   data?.access_specifier === "self" &&
      //   (filterAccessSpecifier === "self" || !filterAccessSpecifier)
      // ) {
      //   transformMessages({ inputMessage: [data], type: "new" });
      // }
      setValue("");
    }
    setIsSendingMessage(false);
  };

  return (
    <Stack
      width={"100%"}
      flex={1}
      direction="column"
      justifyContent="space-between"
      sx={{
        borderWidth: "0px 4px 6px 4px",
        borderStyle: "solid",
        borderColor: theme.palette.darkBg.high,
        borderRadius: "0px 0px 8px 8px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflowY: "hidden",
        }}
      >
        <Stack
          direction="column-reverse"
          sx={{
            overflowY: "scroll",
            padding: "16px",
            height: "100%",
          }}
        >
          {groupedMessages ? (
            Object.entries(groupedMessages).map(([date, msgs], index) => (
              <Stack key={`${index}_${date}_${msgs.length}`}>
                <Box sx={{ paddingX: "15%" }}>
                  <Divider
                    sx={{
                      ...theme.typography["body-small"],
                      color: theme.palette.lightBg.low,
                    }}
                  >
                    {date !== "Today" && date !== "Yesterday"
                      ? dayjs(date).format("DD MMM YYYY")
                      : date}
                  </Divider>
                </Box>
                {msgs.map((message, index) => {
                  const newIndex = message._id;
                  if (
                    message.message_type === "time_entry_notes" ||
                    message.message_type === "canned_notes" ||
                    message.message_platform === "email" ||
                    message.message?.startsWith("<")
                  ) {
                    return (
                      <EmailAndNotesAttachmentBlock
                        key={newIndex}
                        // @ts-ignore
                        type={message.sender.user_type}
                        source={message.message_type}
                        serviceId={serviceId}
                        serviceType={serviceType}
                        message={message.message}
                        mappingId={message.chat_id}
                        senderDisplayName={message.sender.display_name}
                        imageUrl={message.sender.profile_pic}
                        accessSpecifier={message.access_specifier}
                        isEdited={message?.editing?.is_edited}
                        date={message.created_at}
                        messagePlatform={message.message_platform}
                        isMarkedAsResolved={message.mark_as_resolved}
                        onMarkAsResolvedClick={
                          () => {}
                          // handleMarkAsResolved(
                          //   message.chat_id,
                          //   !message.mark_as_resolved,
                          //   date
                          // )
                        }
                      />
                    );
                  }
                  return (
                    <MessageBubble
                      type={message.access_specifier}
                      isMarkedAsResolved={message.mark_as_resolved}
                      message={message.message}
                      key={message._id}
                      date={message.created_at}
                      attachments={message.attachments}
                      senderDisplayName={message.sender.display_name}
                      imageUrl={message.sender.profile_pic}
                      accessSpecifier={message.access_specifier}
                      isEdited={message?.editing?.is_edited}
                      messagePlatform={message.message_platform}
                      senderType={getMessageSenderType(message)}
                    />
                  );
                })}
              </Stack>
            ))
          ) : (
            <>
              {!isMessagesLoading && showNoChatScreen && (
                <Stack
                  sx={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <img
                    src={NoChatImg}
                    alt=""
                    style={{
                      width: "50%",
                      height: "50%",
                      objectFit: "contain",
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    color={theme.palette.lightBg.low}
                  >
                    You’re about to start a new conversation
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Box>
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          // backgroundColor: "red",
          minHeight: "56px",
          width: "100%",
          position: "relative",
          alignItems: "flex-end",
          borderRadius: "8px",
          padding: "8px 12px",
        }}
      >
        <Stack
          direction="column"
          sx={{
            minHeight: "40px",
            width: "100%",
            alignItems: "end",
            boxShadow:
              "0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "6px",
            bgcolor: theme.palette.darkBg.high,
          }}
        >
          {isDisabled && (
            <Stack
              direction={"row"}
              sx={{
                width: "100%",
                gap: "8px",
                alignItems: "center",
                px: "8px",
                py: "4px",
                bgcolor: pinnedChat.colorCodeTint,
              }}
            >
              <InfoIcon
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
              <Typography variant="body-small">
                The {serviceType} is now marked as closed.
              </Typography>
            </Stack>
          )}
          <Stack
            direction={"row"}
            sx={{
              px: "12px",
              py: "8px",
              width: "100%",
              alignItems: "end",
            }}
          >
            {serviceType === "ticket" && (
              <DropdownMenu
                menuProps={{
                  transformOrigin: { horizontal: "center", vertical: "bottom" },
                  anchorOrigin: { horizontal: "right", vertical: "top" },
                }}
                paperSx={{
                  minWidth: "128px",
                }}
                menuListItemSx={{
                  width: "128px",
                }}
                options={SendMenuList}
                onOptionClick={({ type }) => {
                  setMessageType({
                    serviceId,
                    data: {
                      key: "messageType",
                      value: type,
                    },
                  });
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    height: "100%",
                    color: theme.palette.primary.main,
                    pb: "4px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minWidth: "60px",
                  }}
                >
                  <Typography
                    variant="caption-2"
                    sx={{
                      color: theme.palette.primary.main,
                      textTransform: "capitalize",
                    }}
                  >
                    {messageType}
                  </Typography>
                  <UpArrowIcon
                    sx={{
                      "&:hover": { cursor: "pointer" },
                    }}
                  />
                </Stack>
              </DropdownMenu>
            )}
            <TextField
              fullWidth
              multiline
              maxRows={7}
              // disabled={!isResourceAnAssignee}
              disabled={isDisabled}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              placeholder={`Messaging in ${messageType} mode`}
              value={value}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage({
                    type: pinnedChatArray[serviceId]?.messageType,
                  });
                }
              }}
              onChange={(e) => setValue(e.target.value)}
              sx={{
                alignSelf: "center",
                ...theme.typography["body-medium"],
                "& .MuiInputBase-root": {
                  height: "100%",
                  width: "100%",
                  flexGrow: 1,
                  border: 0,
                  p: "0px",
                },
                "& .MuiOutlinedInput-root": {
                  color: "#1C1E26",
                  "& > fieldset": {
                    border: "0px",
                    fontWeight: 400,

                    ...theme.typography["body-small"],
                  },
                },
              }}
            />
            <IconButton
              size="small"
              disabled={isDisabled}
              sx={{
                alignSelf: "flex-end",
                textTransform: "capitalize",
              }}
              onClick={() =>
                handleSendMessage({
                  type: pinnedChatArray[serviceId]?.messageType,
                })
              }
            >
              <PlaneFilledIcon
                sx={{
                  color: isDisabled
                    ? theme.palette.lightBg["015"]
                    : theme.palette.primary.main,
                  width: "20px",
                  height: "20px",
                }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
