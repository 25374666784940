import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Chip,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "components/icons";
import { Input } from "./Input";

interface InputMultiSelectField
  extends Omit<AutocompleteProps<any, false, false, false>, "renderInput"> {
  label?: string;
  options: Array<any>;
  labelMappingKey?: string;
  valueMappingKey?: string;
  chipStyle?: SxProps<Theme>;
  textfieldStyle?: SxProps<Theme>;
  disablePortal?: boolean;
  placeholder?: string;
}

export function InputMultiSelectField({
  label,
  labelMappingKey,
  valueMappingKey,
  options = [],
  chipStyle,
  textfieldStyle,
  disablePortal = true,
  placeholder,
  ...rest
}: InputMultiSelectField) {
  const theme = useTheme();
  return (
    <Autocomplete
      disablePortal={disablePortal}
      options={options}
      multiple
      size="small"
      {...rest}
      isOptionEqualToValue={(option, value) =>
        option[valueMappingKey || "value"] === value[valueMappingKey || "value"]
      }
      getOptionLabel={(option) => option[labelMappingKey || "label"]}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return (
            <Chip
              label={
                <Typography variant="inherit">
                  {option[labelMappingKey || "label"]}
                </Typography>
              }
              {...getTagProps({ index })}
              variant="outlined"
              sx={{
                borderRadius: "2px",
                border: `1px solid ${theme.palette.neutral["B-020"]}`,
                backgroundColor: theme.palette.neutral["B-005"],
                height: "100%",
                ...theme.typography["caption-xs"],
                ...chipStyle,
              }}
              deleteIcon={<CloseIcon />}
            />
          );
        });
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{
            borderBottom: `${
              (options?.findIndex((item) => item === option) || 0) ===
              (options?.length || 0) - 1
                ? "0px"
                : `1px solid ${theme.palette.neutral["010"]}`
            }`,
          }}
        >
          <Typography
            variant="caption-3"
            sx={{
              padding: "12px 16px",
            }}
          >
            {option[labelMappingKey || "label"]}
          </Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Box></Box>,
          }}
          sx={{
            ...theme.typography["body-medium"],

            "& .MuiOutlinedInput-root": {
              height: { sm: "32px" },
              "& > fieldset": {
                borderColor: theme.palette.neutral["020"],
                borderRadius: "4px",
              },
              "& input::placeholder": { fontSize: "14px" },
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              color: theme.palette.lightBg.high,
              "& > fieldset": { borderColor: theme.palette.purple.main },
            },
            "& label": {
              "&.Mui-focused": { color: theme.palette.purple.main },
            },

            ...textfieldStyle,
          }}
        />
      )}
    />
  );
}
