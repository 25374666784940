import { Box, Typography } from "@mui/material";
import { useMainStore } from "controller/store/mainStore";
import { msToHHMMSSString } from "utilz/helpers/formatTimeDuration";

export function TimeCounter({
  value,
  disable = false,
}: {
  value?: number | null;
  disable?: boolean;
}) {
  return (
    <Box minWidth={"60px"}>
      <Typography variant="body-medium">
        {value && value !== null && !disable
          ? msToHHMMSSString(value)
          : "00:00:00"}
      </Typography>
    </Box>
  );
}
