import { Box, Slider, Stack, Typography, useTheme } from "@mui/material";
import {
  Accordian,
  Button,
  DeleteConfirmationModal,
  Drawer,
  Input,
  Modal,
  SelectField,
} from "components";
import {
  useCreateCheckoutSession,
  useGetActivePlan,
  useGetActiveResourceCount,
  useGetAllCountries,
  useGetAllStateByCountryId,
  useGetMspBillingDetails,
  useGetProratedAmount,
  useGlobalStore,
  useUpdateMspBilling,
  useUpdateSubscription,
  useValidatePromoCode,
} from "controller";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { addMixpanelEvent, debugConsole } from "utilz/helpers";
import { ManageResourceModal } from "../ManageResourceModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BillingDetailsDrawer } from "../BillingDetailsDrawer";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { Switch } from "components/switch";
const MIN_COUNT = 1;

interface PaynowProps {
  type?: "EDIT" | "CREATE";
  data?: {
    promocode?: string | null;
    seatQuantity: number;
    discountPercentage?: string | null;
    price?: number;
    discountId?: string | null;
    planId: string | null;
    recurringInterval: "month" | "year";
  };
  isFullPage?: boolean;
  /**
   * used when type is EDIT and this component is in a modal and you want to close the modal on click of cancel
   */
  onClose?: () => void;
}
export const PayNowCard = ({
  type = "CREATE",
  data,
  isFullPage = false,
  onClose,
}: PaynowProps) => {
  const [billingCycle, setBillingCycle] = React.useState<"month" | "year">(
    "month"
  );
  const mspId = useGlobalStore((state) => state.mspId);
  const [planAmount, setPlanAmount] = React.useState<number>(59);
  const [planId, setPlanId] = React.useState<Record<string, string> | null>(
    null
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: activePlans } = useGetActivePlan();
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState<boolean>(false);
  const [openManageResource, setOpenManageResource] =
    React.useState<boolean>(false);
  const [openBillingDetails, setOpenBillingDetails] = React.useState<
    Record<string, any>
  >({
    open: false,
    source: "EDIT",
  });
  const [value, setValue] = React.useState<number>(1);
  const [minValue, setMinValue] = React.useState<number>(1);
  const [promoCode, setPromoCode] = React.useState<string | null | undefined>();
  const [discountPercentage, setDiscountPercentage] = React.useState<number>(0);

  const [discountedPrice, setDiscountedPrice] = React.useState<number>(0);
  const [redeemError, setRedeemError] = React.useState<string | null>(null);
  const [minCountError, setMinCountError] = React.useState<string | null>(null);
  const { data: activeResourceCount, refetch: refetchActiveCount } =
    useGetActiveResourceCount();
  const { mutateAsync: validateCode, isError: promocodeError } =
    useValidatePromoCode();
  const {
    mutateAsync: createSubscription,
    isError: createSubscriptionError,
    isLoading: loadingCreateSubscription,
  } = useCreateCheckoutSession();

  const { mutateAsync: updateSubscription, isLoading: isUpdatingSubscription } =
    useUpdateSubscription();
  const { data: mspBillingDetails } = useGetMspBillingDetails(mspId || "");
  const { data: proratedAmount } = useGetProratedAmount({
    enabled: type === "EDIT",
    // @ts-ignore
    promoCode: promoCode,
    ...(promoCode === data?.promocode ? { discountId: data?.discountId } : {}),
  });
  React.useEffect(() => {
    if (activeResourceCount) {
      setMinValue(
        Number(activeResourceCount) > MIN_COUNT
          ? Number(activeResourceCount)
          : MIN_COUNT
      );
      setValue(
        Number(activeResourceCount) > MIN_COUNT
          ? Number(activeResourceCount)
          : MIN_COUNT
      );

      // TODO: uncomment below code when removing the below if condition for custome appled promocode

      setDiscountedPrice(
        activeResourceCount > MIN_COUNT
          ? Number(activeResourceCount) * planAmount
          : MIN_COUNT * planAmount
      );

      // INFO: below code can be uncommented when removing the above if condition for custome appled promocode
      // and comment the above code block

      // if (type !== "EDIT" && !isFullPage) {
      //   setPromoCode("GAJULY25");
      //   setDiscountPercentage(25);
      //   setDiscountedPrice(
      //     activeResourceCount > MIN_COUNT
      //       ? Number(activeResourceCount) * planAmount * ((100 - 25) / 100)
      //       : MIN_COUNT * planAmount * ((100 - 25) / 100)
      //   );
      // }
    }
  }, [activeResourceCount]);

  React.useEffect(() => {
    if (data) {
      setValue(data.seatQuantity);
      setPromoCode(data.promocode || null);
      setDiscountPercentage(Number(data.discountPercentage) || 1);
      setDiscountedPrice(0);
      setPlanAmount(data.price ? data?.price / 100 : planAmount);
      setPlanId({
        planId: data?.planId || "",
        stripePriceId: "", // stripePriceId not passed in mspSubscriptionDetails
      });
      setBillingCycle(data?.recurringInterval || "month");
      // TODO: set plan id here
    }
  }, [data]);

  React.useEffect(() => {
    if (activePlans && type !== "EDIT" && activePlans.length > 0) {
      let index = 0;
      index = activePlans?.findIndex(
        (item: Record<string, any>) => item.recurring_interval === billingCycle
      );

      if (index > -1) {
        setPlanAmount(Number(activePlans[index]?.price) / 100);
        setPlanId({
          planId: activePlans[index]?.mapping_id,
          stripePriceId: activePlans[index]?.stripe_price_id,
        });
        // const discount =
        //   discountPercentage > 1
        //     ? value * planAmount * ((100 - discountPercentage) / 100)
        //     : value * planAmount;

        // setDiscountedPrice(discount);
      }
    }
  }, [activePlans, billingCycle]);

  React.useEffect(() => {
    if (type !== "EDIT") {
      const discount =
        discountPercentage > 1
          ? value * planAmount * ((100 - discountPercentage) / 100)
          : value * planAmount;

      setDiscountedPrice(discount);
    }
  }, [planAmount]);

  const handleChange = (value: number) => {
    setValue(value);
    let discount = value * planAmount;

    if (type === "EDIT" && data?.seatQuantity) {
      value = value - data?.seatQuantity;

      if (proratedAmount?.amount) {
        const proratedData = (proratedAmount?.amount / 10000).toFixed(2);
        discount = value * Number(proratedData);
      } else {
        discount =
          discountPercentage > 1
            ? value * planAmount * ((100 - discountPercentage) / 100)
            : value * planAmount;
      }
    } else {
      discount =
        discountPercentage > 1
          ? value * planAmount * ((100 - discountPercentage) / 100)
          : value * planAmount;
    }

    discount >= 0 && setDiscountedPrice(discount);
  };

  const handleRedeem = async (code: string) => {
    const newValue =
      type === "EDIT" && data?.seatQuantity
        ? value - data?.seatQuantity
        : value;
    setRedeemError(null);
    try {
      const data = await validateCode({
        code: code,
        planId: planId?.planId || "",
      });

      if (data?.success) {
        const percentOff = data?.data?.percent_off;
        setPromoCode(code);
        setDiscountPercentage(percentOff ? Number(percentOff) : 1);
        if (percentOff) {
          setDiscountedPrice(
            newValue * planAmount * ((100 - percentOff) / 100)
          );
        } else {
          setDiscountedPrice(newValue * planAmount);
        }
      } else {
        setRedeemError("Invalid code");
      }
      debugConsole(data);
    } catch (error) {
      debugConsole(error);
    }
  };

  const handlePayClick = async () => {
    if (value < activeResourceCount) {
      toast.error("Seat count can't be less than active resources");
      return;
    }
    if (value < MIN_COUNT) {
      setMinCountError(`Minimum ${MIN_COUNT} seats`);
      return;
    }

    setMinCountError(null);
    try {
      if (type === "EDIT") {
        setOpenConfirmationModal(true);
      } else {
        try {
          if (checkIfBillingAddressExists(mspBillingDetails)) {
            addMixpanelEvent({
              event: "subscription_payment_initiated",
            });
            await createSubscriptionIntent();
          } else {
            setOpenBillingDetails({
              open: true,
              type: "CREATE",
            });
          }
        } catch (error) {
          toast.error("Unable to create subscription");
        }
      }
    } catch (error) {
      debugConsole(error);
    }
  };

  const onUpdateSubscription = () => {
    handleUpdateSubscription();
  };
  const handleUpdateSubscription = async () => {
    try {
      await updateSubscription({
        seatQuantity: value,
        ...(promoCode !== data?.promocode && { promocode: promoCode }),
        planId: planId ? planId.planId : "",
        stripePriceId: planId ? planId.stripePriceId : "",
      });
      setOpenConfirmationModal(false);
      setSearchParams({
        type: data?.seatQuantity
          ? value < data?.seatQuantity
            ? "update"
            : "success"
          : "success",
      });

      window.location.reload();
    } catch (error) {
      debugConsole(error);
    }
  };

  const createSubscriptionIntent = async () => {
    try {
      const data = await createSubscription({
        promocode: promoCode,
        seatQuantity: value,
        planId: planId ? planId.planId : "",
        stripePriceId: planId ? planId.stripePriceId : "",
      });
      if (data) window.location.href = data;
    } catch (error) {
      toast.error("Unable to create subscription");
      setOpenBillingDetails({
        open: false,
        type: "EDIT",
      });
    }
  };
  const checkIfBillingAddressExists = (billingDetails: Record<string, any>) => {
    if (
      billingDetails &&
      billingDetails.line1 &&
      billingDetails.billing_country
    ) {
      return true;
    }
    return false;
  };
  const handleActiveModalClose = () => {
    refetchActiveCount();
    setOpenManageResource(false);
  };

  const handleManageClick = () => {
    if (!isFullPage) {
      navigate("/app/control-center/resources");
    } else {
      setOpenManageResource(true);
    }
  };

  const handlePromocodeRemove = () => {
    setPromoCode(null);
    if (type === "CREATE") {
      setDiscountPercentage(1);
      setDiscountedPrice(value * planAmount);
    } else if (data) {
      const newSeatQuantity = value - data.seatQuantity;
      const proratedData = (proratedAmount?.amount / 10000).toFixed(2);
      setDiscountedPrice(
        newSeatQuantity * Number(proratedData) > 0
          ? newSeatQuantity * Number(proratedData)
          : 0
      );
    }
  };

  const handleBillingCycleChange = (value: number) => {
    setBillingCycle(value === 0 ? "month" : "year");
    handlePromocodeRemove();
  };

  return (
    <Stack
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          padding: type === "EDIT" ? "24px" : "32px",
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        {type !== "EDIT" && (
          <Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                mt: "16px",
              }}
            >
              <Typography variant="subtitle1">
                Start your essential plan today
              </Typography>
              <Switch
                tabValues={["Month", "Year"]}
                value={billingCycle === "month" ? 0 : 1}
                onChange={handleBillingCycleChange}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                mt: "16px",
                p: "16px 24px",
                border: `1px solid ${theme.palette.secondary["purple-tint"]}`,
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6">
                No contract. No minimum seats.
              </Typography>
              <Stack
                direction={"row"}
                sx={{ alignItems: "center", gap: "6px" }}
              >
                <Typography variant="h3">
                  $
                  {billingCycle === "month"
                    ? planAmount.toFixed(2)
                    : (planAmount / 12).toFixed(2)}
                </Typography>
                <Typography variant="body-small">per resource/month</Typography>
              </Stack>
            </Stack>
          </Stack>
        )}

        <Stack
          mt={type === "EDIT" ? "0px" : "48px"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="subtitle1">Select Team Size</Typography>
          <Button
            variant="ghost"
            sx={{ minWidth: "0px" }}
            onClick={handleManageClick}
          >
            Manage
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          sx={{
            mt: "16px",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: `${minValue}%`,
              height: "4px",
              bgcolor: theme.palette.neutral["020"],
              borderRadius: "8px 0px 0px 8px",
            }}
          ></Box>
          <Box sx={{ width: "100%" }}>
            <Slider
              // size="small"
              // defaultValue={30}
              value={value}
              aria-label="Small"
              valueLabelDisplay="auto"
              onChange={(e, value: any) => handleChange(value)}
              min={minValue}
              sx={{
                mt: "3px",
              }}
            />
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          gap={"120px"}
          alignItems={"center"}
          mt={"16px"}
        >
          <Input
            label="Seats"
            type="number"
            error={minCountError ? true : false}
            errorMessage={minCountError || ""}
            InputProps={{ inputProps: { min: activeResourceCount, max: 100 } }}
            value={value}
            helperText={
              <Typography
                variant="body-medium"
                color={
                  minCountError
                    ? theme.palette.error.main
                    : theme.palette.lightBg.low
                }
              >
                {minCountError
                  ? minCountError
                  : Number(activeResourceCount) > MIN_COUNT
                  ? `${activeResourceCount} active resources`
                  : `Minimum ${MIN_COUNT} seats`}
              </Typography>
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                // bgcolor: "red",
                bgcolor: "#FFF",
              },
            }}
            name="teamSize"
            onChange={(e) => {
              handleChange(Number(e.target.value));
            }}
          ></Input>
          <Stack direction={"row"} gap={"4px"} alignItems={"baseline"}>
            <Typography variant="h6">
              $
              {type === "EDIT"
                ? discountPercentage > 1
                  ? (
                      value *
                      planAmount *
                      ((100 - discountPercentage) / 100)
                    ).toFixed(2)
                  : (value * planAmount).toFixed(2)
                : discountedPrice.toFixed(2)}
            </Typography>
            <Typography
              variant="body-small"
              noWrap
              color={theme.palette.lightBg.medium}
            >
              / {billingCycle === "month" ? "month" : "year"}
            </Typography>
          </Stack>
        </Stack>
        <PromoCodeCard
          appliedCoupon={promoCode}
          onRedeem={(code) => {
            // setPromoCode(code);
            handleRedeem(code);
          }}
          onRemove={handlePromocodeRemove}
          totalSaving={
            promoCode
              ? `${
                  value * planAmount -
                  value * planAmount * ((100 - discountPercentage) / 100)
                }`
              : ""
          }
          errorMessage={redeemError}
        />
        {type !== "EDIT" && (
          <Stack
            direction={"row"}
            sx={{
              mt: "16px",
              justifyContent: "space-between",
              p: "12px 0px 12px 16px",
              borderRadius: "8px",
              border: `1px solid ${theme.palette.neutral["015"]}`,
            }}
          >
            <Typography variant="body-medium">
              Bill To :{" "}
              <Typography component={"span"} variant="subtitle1">
                {mspBillingDetails?.company_name}
              </Typography>
            </Typography>
            <Button
              variant="ghost"
              onClick={() =>
                setOpenBillingDetails({
                  open: true,
                  type: "EDIT",
                })
              }
              sx={{ ":hover": { backgroundColor: "transparent" } }}
            >
              Edit
            </Button>
          </Stack>
        )}
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          flexShrink: 1,
          backgroundColor: "#FFF",
          borderTop: `1px dashed ${theme.palette.neutral["015"]}`,
          p: "8px 32px 12px 32px",
        }}
      >
        <Stack sx={{ gap: "6px" }}>
          <Typography variant="subtitle1" color={theme.palette.lightBg.low}>
            To pay{"  "}
            <Typography
              component={"span"}
              variant="h4"
              color={theme.palette.lightBg.high}
            >
              ${discountedPrice.toFixed(2)}
            </Typography>
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={"12px"}>
          <Button
            disabled={
              type === "EDIT" && Number(value) === Number(data?.seatQuantity)
            }
            variant="primary"
            onClick={handlePayClick}
            loading={loadingCreateSubscription}
          >
            {type === "EDIT" && data?.seatQuantity && value < data?.seatQuantity
              ? "Update"
              : "Proceed"}
          </Button>
        </Stack>
      </Stack>
      {openManageResource && (
        <Drawer
          open={openManageResource}
          onClose={handleActiveModalClose}
          footer={<></>}
          title="Manage Resource"
        >
          <ManageResourceModal />
        </Drawer>
      )}
      {openBillingDetails.open && (
        <BillingDetailsDrawer
          open={openBillingDetails.open}
          onClose={() => setOpenBillingDetails(openBillingDetails.open)}
          mspBillingDetails={mspBillingDetails}
          type={openBillingDetails.type}
          successCallback={() => {
            addMixpanelEvent({
              event: "subscription_payment_initiated",
            });
            createSubscriptionIntent();
          }}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          open={openConfirmationModal}
          showIcon={false}
          isLoading={isUpdatingSubscription}
          type="primary"
          primaryButtonText={
            data?.seatQuantity && value < data?.seatQuantity
              ? "Update Seat Count"
              : "Pay Now"
          }
          onClose={() => setOpenConfirmationModal(false)}
          onSecondaryButtonClick={() => setOpenConfirmationModal(false)}
          onPrimaryButtonClick={onUpdateSubscription}
          title="Confirm Seat Change"
          tagline={getConfirmationContent({
            seat: data?.seatQuantity || 1,
            value,
            price: discountedPrice,
          })}
        />
      )}
    </Stack>
  );
};

const getConfirmationContent = ({
  seat,
  value,
  price,
}: {
  seat: number;
  value: number;
  price: number;
}) => {
  if (seat > value) {
    return `Your seat count has been adjusted from ${seat} to ${value}. Click Update to confirm.`;
  }

  return `Your seat count has been adjusted from ${seat} to ${value}. You will now be charged $${price.toFixed(
    2
  )}. Click 'Pay Now' to confirm.`;
};
const PromoCodeCard = ({
  appliedCoupon,
  onRedeem,
  onRemove,
  errorMessage,
  totalSaving,
}: {
  totalSaving?: string;
  appliedCoupon?: string | null;
  errorMessage?: string | null;
  onRedeem: (code: string) => void;
  onRemove: () => void;
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState<string | null | undefined>(null);

  return (
    <Stack gap="12px">
      <Stack
        direction={"row"}
        sx={{
          py: "16px",
          mt: "16px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1">Promo Code</Typography>
        {!appliedCoupon && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            gap={"12px"}
            alignItems={"flex-start"}
          >
            <Input
              placeholder="Promo code"
              fullWidth
              hideHelperBox
              value={value}
              error={errorMessage ? true : false}
              errorMessage={errorMessage || ""}
              sx={{
                "& .MuiOutlinedInput-root": {
                  // bgcolor: "red",
                  bgcolor: "#FFF",
                },
              }}
              name="teamSize"
              onChange={(e) => setValue(e.target.value?.toUpperCase())}
            ></Input>
            <Button
              variant="secondary"
              onClick={() => value && onRedeem(value)}
            >
              Redeem
            </Button>
          </Stack>
        )}
      </Stack>
      {appliedCoupon ? (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignContent={"center"}
          sx={{
            border: `1px solid ${theme.palette.neutral["015"]}`,
            borderRadius: "8px",
            bgcolor: "#FFF",
            p: "16px 0px 16px 20px",
          }}
        >
          <Box>
            <Typography variant="body-medium">
              {appliedCoupon} applied
            </Typography>
            <Typography variant="body-medium">
              ${Number(totalSaving).toFixed(2)} promo saving
            </Typography>
          </Box>
          <Button
            variant="ghost"
            sx={{
              color: theme.palette.error.main,
              ":hover": { bgcolor: "transparent" },
            }}
            onClick={onRemove}
          >
            Remove
          </Button>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};
