import { MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { CustomTypography } from "../../../../../../library/base";

interface ConfigurationMenuItemProps {
  onClick: () => void;
  content: string;
  disabled?: boolean;
}
export const ConfigurationMenuItem = (props: ConfigurationMenuItemProps) => {
  return (
    <MenuItem
      disabled={props.disabled}
      disableRipple
      onClick={props.onClick}
      sx={{
        p: "16px",
        "&:hover": {
          backgroundColor: "#F3F5F9",
        },
      }}
    >
      <Typography variant="body-small">{props.content}</Typography>
    </MenuItem>
  );
};
