import { ActiveTimeEntry } from "shared";
import { getFullName } from "utilz/helpers";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface TimeEntryInfo {
  mapping_id: string;
  service_id: string;
  service_type: "ticket" | "request" | "project" | "task";
}
interface TimeEntryState {
  activeTimerInstanceId: any;
  isTimeEntryModalOpen: boolean;
  activeTimeEntriesDetails: Array<ActiveTimeEntry>;
  activeTimeEntries: Record<string, TimeEntryInfo>;
}

interface TimeEntryAction {
  toggleTimeEntryModalOpen: (value: boolean) => void;
  setActiveTimeEntries: (timeEntries: Array<ActiveTimeEntry>) => void;
  addTimeEntry: (timeEntry: ActiveTimeEntry) => void;
  removeTimeEntry: ({
    serviceId,
    timeEntryId,
    serviceType,
  }: {
    timeEntryId: string;
    serviceId: string;
    serviceType: "ticket" | "request" | "project" | "task";
  }) => void;

  resetTimeEntryData: () => void;
}

export interface TimeEntryInterface extends TimeEntryState, TimeEntryAction {}

const initialTimeEntryData = {
  activeTimerInstanceId: null,
  isTimeEntryModalOpen: false,
  activeTimeEntriesDetails: [],
  activeTimeEntries: {},
};

export const timeEntrySlice = immer<TimeEntryState & TimeEntryAction>(
  (set, get) => ({
    ...initialTimeEntryData,

    resetTimeEntryData() {
      set((state) => {
        if (get().activeTimerInstanceId != null) {
          clearInterval(get().activeTimerInstanceId);
          state.activeTimerInstanceId = null;
        }
        state = {
          ...state,
          ...initialTimeEntryData,
        };
      });
    },

    toggleTimeEntryModalOpen(value) {
      set((state) => {
        state.isTimeEntryModalOpen = value;
      });
    },

    setActiveTimeEntries(timeEntries: Array<any>) {
      set((state) => {
        const timeEntryIds: Record<string, TimeEntryInfo> = {};
        const activeTimeEntriesDetails = timeEntries.map((item) => {
          timeEntryIds[item.service_id] = {
            mapping_id: item.mapping_id,
            service_id: item.service_id,
            service_type: item.service_type,
          };
          return {
            ...item,
            customer_user_name: getFullName(
              item?.customer_user_first_name || item?.first_name,
              item?.customer_user_middle_name || item?.middle_name,
              item?.customer_user_last_name || item?.last_name
            ),
          };
        });
        state.activeTimeEntriesDetails = [...activeTimeEntriesDetails];
        state.activeTimeEntries = timeEntryIds;
        if (get().activeTimerInstanceId != null) {
          clearInterval(get().activeTimerInstanceId);
          state.activeTimerInstanceId = null;
        }
        if (activeTimeEntriesDetails.length > 0) {
          startAllTimeEntryCounter(set, get);
        }
      });
    },
    addTimeEntry(timeEntry) {
      set((state) => {
        const timeEntries = [...state.activeTimeEntriesDetails, timeEntry];
        state.activeTimeEntriesDetails = timeEntries;
        state.activeTimeEntries[timeEntry.service_id] = {
          mapping_id: timeEntry.mapping_id,
          service_id: timeEntry.service_id,
          service_type: timeEntry.service_type,
        };
      });
    },
    removeTimeEntry({ timeEntryId, serviceId, serviceType }) {
      set((state) => {
        const timeEntries = [...state.activeTimeEntriesDetails];
        const index = timeEntries.findIndex(
          (item) =>
            item.mapping_id === timeEntryId &&
            item.service_id === serviceId &&
            item.service_type === serviceType
        );

        timeEntries.splice(index, 1);
        state.activeTimeEntriesDetails = timeEntries;
        delete state.activeTimeEntries[serviceId];
      });
    },
  })
);

const startAllTimeEntryCounter = (set: any, get: any) => {
  set((state: any) => {
    const interval = setInterval(() => {
      const timeEntries: Array<ActiveTimeEntry> = [
        ...(Array.from(get().activeTimeEntriesDetails) as any),
      ];
      if (timeEntries.length <= 0 && get().activeTimerInstanceId !== null) {
        clearInterval(get().activeTimerInstanceId);
        set((state: any) => {
          state.activeTimerInstanceId = null;
        });
        return;
      }

      for (let i = 0; i < timeEntries.length; i++) {
        let timeEntry = timeEntries[i];
        const startTimeValue = timeEntry.start_time;
        if (startTimeValue != null) {
          const currentTime = new Date();
          const timeInSecond = Math.floor(
            (currentTime.getTime() - new Date(`${startTimeValue}`).getTime()) /
              1000
          );
          timeEntry = {
            ...timeEntry,
            activeTimerValueInSeconds: timeInSecond,
          };
          timeEntries[i] = timeEntry;
        }
      }
      set((state: any) => {
        state.activeTimeEntriesDetails = [...timeEntries];
        state.activeTimerInstanceId = `${interval}`;
      });
    }, 1000);
  });
};

export const useTimeEntryStore = create<TimeEntryInterface>()((...a) => ({
  ...timeEntrySlice(...a),
}));
