import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { CustomTypography } from "../../base";
import { PaginationButton } from "components/pagination/PaginationButton";

const PaginationControls = (props) => {
  // State
  const [state, setState] = useState({
    error: false,
    disabledIconButtons: { prev: false, next: false },
  });

  const { currentPage, perPageItems, totalItems } = props;

  const from =
    totalItems <= 0
      ? 0
      : currentPage * perPageItems < totalItems
        ? currentPage * perPageItems
        : totalItems;

  // Effects
  useEffect(() => {
    try {
      if (typeof props?.currentPage === "number") {
        setState({
          ...state,
          currentPage: props?.currentPage,

          disabledIconButtons: {
            ...state?.disabledIconButtons,
            prev: props?.currentPage === 1 ? true : false,
            next: props?.currentPage === props?.totalPages ? true : false,
          },
        });
      }
    } catch (err) {
      console.log("[ERROR] Mounting Pagination component");
      console.log(err);
    }
  }, []);
  useEffect(() => {
    try {
      setState({
        ...state,
        currentPage: props?.currentPage,
        disabledIconButtons: {
          ...state?.disabledIconButtons,
          prev: props?.currentPage === 1 ? true : false,
          next: props?.currentPage === props?.totalPages ? true : false,
        },
      });
    } catch (err) {
      console.log("[ERROR] Refreshing new table data");
      console.log(err);
    }
  }, [props?.currentPage, props?.totalPages]);

  // handler
  const handlePickerChange = (event) => {
    try {
      if (typeof parseInt(event?.target["value"]) === "number") {
        const value = parseInt(event?.target["value"]);
        setState({
          ...state,
          currentPage: value,
          error: value > props["totalPages"] ? true : false,

          disabledIconButtons: {
            ...state?.disabledIconButtons,
            prev: value === 1 ? true : false,
            next: value === props?.totalPages ? true : false,
          },
        });
      } else {
        setState({
          ...state,
          currentPage: state["currentPage"],
          error: true,
        });
      }
    } catch (err) {
      console.log("[ERROR] Picking Page number");
      console.log(err);
    }
  };
  const handleGetPage = () => {
    try {
      if (
        typeof state["currentPage"] === "number" &&
        state["currentPage"] <= props["totalPages"] &&
        state["error"] === false
      ) {
        props?.getNewPage(state["currentPage"]);
      }
    } catch (err) {
      console.log("[ERROR] Fetching Location table data with page number");
      console.log(err);
    }
  };
  const handleControls = (signal) => {
    console.log(signal);
    if (signal === "PREVIOUS") {
      if (props?.currentPage !== 1) {
        props?.getNewPage(props?.currentPage - 1);
      }
    }
    if (signal === "NEXT") {
      if (props?.currentPage !== props?.totalPages) {
        props?.getNewPage(props?.currentPage + 1);
      }
    }
  };

  // Render
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Box sx={{ pr: { xs: "4px", lg: "8px", xl: "12px" } }}>
          <CustomTypography
            size={"SMALL-BETA"}
            color={"PRIMARY-MAIN"}
            variant={"REGULAR"}
            content={"Page"}
          />
        </Box>
        <Box sx={{ pr: { xs: "4px", lg: "8px", xl: "12px" } }}>
          <TextField
            label={""}
            variant={"outlined"}
            fullWidth={true}
            type={"number"}
            value={state?.currentPage || 0}
            error={state["error"] || false}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                { display: "none" },
              "& input[type=number]": {
                MozAppearance: "textfield",
                padding: 0,
                margin: 0,
                width: "48px",
                height: "48px",
                textAlign: "center",
                borderRadius: "8px",
                "& > fieldset": {
                  borderColor: "#CCCCCC",
                  borderRadius: "8px",
                  width: "48px",
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                color: "#1C1E26",
                "& > fieldset": { borderColor: "#5563F5" },
              },
              "& label": { "&.Mui-focused": { color: "#5563F5" } },
            }}
            onChange={handlePickerChange}
          />
        </Box>
        <Box sx={{ pr: { xs: "4px", lg: "8px", xl: "12px" } }}>
          <CustomTypography
            size={"SMALL-BETA"}
            color={"PRIMARY-MAIN"}
            variant={"REGULAR"}
            content={`of ${
              props["totalPages"] && props["totalPages"] > 1
                ? props["totalPages"]
                : 1
            }`}
          />
        </Box>
        <Box
          sx={{
            pr: { xs: "4px", lg: "8px", xl: "12px" },
            "& :hover": { cursor: "pointer" },
          }}
          onClick={handleGetPage}
        >
          <CustomTypography
            size={"SMALL-BETA"}
            color={"BUTTON-MAIN"}
            variant={"MEDIUM"}
            content={"Go"}
          />
        </Box>
      </Box>
      <Stack direction="row" spacing={"12px"} alignItems={"center"}>
        <CustomTypography
          size={"SMALL-BETA"}
          color={"PRIMARY-MAIN"}
          variant={"REGULAR"}
          content={`  ${
            (currentPage - 1) * (totalItems <= 0 ? 0 : perPageItems + 1)
          } - ${from} of ${totalItems}`}
        />

        <PaginationButton
          disabled={currentPage <= 1 ? true : false}
          type="prev"
          onClick={() => handleControls("PREVIOUS")}
        />
        <PaginationButton
          disabled={currentPage >= props?.totalPages ? true : false}
          type="next"
          onClick={() => handleControls("NEXT")}
        />
      </Stack>
    </Box>
  );
};

PaginationControls.defaultProps = {
  currentPage: 0,
  totalPages: 0,
  perPageItems: 0,
  totalItems: 0,
  getNewPage: () => {},
};

export default PaginationControls;
