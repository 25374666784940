import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { ButtonGroup, Divider, Paper } from "@mui/material";
// HOC Imports
import { CustomTypography } from "../../base";
import CustomModal from "../custom-modal";
// import CustomForm from '../custom-form';
import { CustomTableMultiSelectToolbar } from "../../mounts";
import CustomCheckBox from "../../base/custom-checkbox";

// Services
import { services } from "../../../services";
import CustomIcon from "../../icon-library";

// Constants

const TABLE_COMPONENT_HEIGHT = { xs: "368px", md: "512px", xl: "816px" };
const MODAL_TYPE = { VIEW: "VIEW", EDIT: "EDIT" };

const CustomTable = (props) => {
  // State
  const [state, setState] = useState({
    data: { render: "", data: [] },
    modal: { open: false, type: MODAL_TYPE["VIEW"] },
    selectedLocation: {},
    toolbar: false
  });


  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(Object.values(props?.headers)[0].map);

  // Effects
  useEffect(() => {
    try {
      if (props?.data && Array.isArray(props?.data)) {
        setState({
          ...state,
          data: {
            render: v4(),
            data: props?.data?.map((element) => {
              return {
                ...element,
                selected: false
              };
            })
          }
        });
      }
    } catch (err) {
      console.log("[ERROR] Mounting CustomTable");
      console.log(err);
    }
  }, [props]);


  useEffect(() => {
    const getSelectionCount = state?.data.data.filter((item) => item?.selected === true);
    if (getSelectionCount.length > 1) {
      setState({ ...state, toolbar: true });
    }
  }, [state?.data]);

  // Handlers
  const handleSelect = (event, id) => {
    event.stopPropagation();
    const currentDataSet = state?.data?.data;
    const reqIndex = currentDataSet.findIndex((item) => item?.mapping_id === id);
    currentDataSet.splice(
      reqIndex,
      1,
      {
        ...currentDataSet[reqIndex],
        selected: currentDataSet[reqIndex]?.selected === true ? false : true
      }
    );
    setState({
      ...state,
      data: {
        render: v4(), data: currentDataSet
      }
    });
    const getSelectionCount = state?.data.data.filter((item) => item?.selected === true);
    // if (
    //     getSelectionCount?.length === 1 &&
    //     getSelectionCount[0]?.mapping_id === id &&
    //     getSelectionCount[0]?.selected === true
    // ) {
    //     getLocationDetailsById(getSelectionCount[0]?.mapping_id)
    // }
  };

  const selectAll = () => {
    setState({
      ...state,
      data: {
        render: v4(),
        data: state?.data?.data.map((item) => {
          return {
            ...item,
            selected: item?.selected === true ? false : true
          };
        })
      }
    });
  };

  const closeModal = () => {
    setState({
      ...state,
      modal: { open: false, type: MODAL_TYPE["VIEW"] },
      selectedLocation: {}
    });
  };


  const handleMultiSectionAction = () => {

    setState({
      ...state,
      data: {
        render: v4(),
        data: state?.data?.data.map((item) => {
          return {
            ...item,
            selected: false,
          };
        })
      },
      selectedLocation: {},
      toolbar: false
    });
  };


  function handleSingleRowSelection(event, mapping_id) {
    event.stopPropagation();
    getLocationDetailsById(mapping_id);
  }

  // API Calls
  const getLocationDetailsById = (id) => {
    try {
      services
        .controlCenterservices
        .locationServices
        .fetchLocationByLocationId(id)
        .subscribe({
          next: (response) => {
            if (
              response &&
                            response?.success === true &&
                            response?.statusCode === 200 &&
                            response?.data
            ) {
              setState({
                ...state,
                modal: { ...state?.modal, open: true },
                selectedLocation: response?.data
              });
            }
          },
          error: (error) => {
            console.log("[ERROR: API] Fetching Location details by ID");
            console.log(error);
          },
        });
    } catch (err) {
      console.log(["ERROR] Fetching Location details by ID"]);
      console.log(err);
    }
  };

  // To fill empty space in the table

  function getSpaceFillers() {
    var menuItems = [];
    for (var i = 0; i < 20; i++) {

      menuItems.push(<Divider
        // color="#E3E3E3"
        key={`space_filler_${i}`} sx={{
          // backgroundColor: 'grey',
          // color: 'red',
          marginTop: {
            xs: "48px", md: "52px", xl: "56px",
          },
        }} />);
    }
    return <Box style={{ minWidth: "100%", overflow: "hidden", }}>
      {menuItems}
    </Box>;
  }


  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  // returns a comparison function based on order
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (newOrderBy, isAsc) => {
    setOrder(isAsc === true ? "desc" : "asc");
    setOrderBy(newOrderBy);
  };


  // Renderer
  return (

    <React.Fragment>
      <CustomTableMultiSelectToolbar
        open={state?.toolbar && state?.data?.data.filter((item) => item?.selected === true).length > 0}
        selection={state?.data?.data.filter((item) => item?.selected === true).length}
        actionTitle='Delete'
        onClick={() => {
          props.onDeleteClicked();
          handleMultiSectionAction();
        }}
      />
      <CustomModal
        open={state?.modal?.open}
        edit={false}
        modalTitle={"Edit Location"}
        modalBody={
          // <CustomForm
          //     fields={props?.selectionFormFields}
          //     data={state?.selectedLocation}
          // />
          <></>
        }
        modalFooter={<></>}
        closeHandle={closeModal}
      />
      <Box
        sx={{
          margin: 0,
          padding: 0,
          width: "100%",
          height: { xs: TABLE_COMPONENT_HEIGHT["xs"], md: TABLE_COMPONENT_HEIGHT["md"], xl: TABLE_COMPONENT_HEIGHT["xl"] },
          overflowX: "auto",
          display: "grid",
          borderRadius: "24px",
        }}
      >

        <Table
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            p: 0, m: 0,
            textAlign: "left",
            borderBottom: "none",
            "& .MuiTableRow-root": {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #E3E3E3",
              textOverflow: "ellipsis",
              alignContent: "center",
              height: {
                xs: "48px", md: "52px", xl: "56px",
              },
            },
            "& .MuiTableCell-root": {
              border: "none",
              textOverflow: "ellipsis",
              p: "0px",
              display: "flex",
              alignItems: "center"
            }
          }}>
          <TableHead
            sx={{
              width: "100%",
              p: 0,
              m: 0,
              // position: 'absolute',
            }}
          >
            <TableRow

              sx={{
                width: "100%",
                p: 0,
                m: 0,
              }}
            >
              <TableCell
                align='left'
                sx={{
                  mx: 0,
                  marginLeft: "32px",
                  marginRight: "13px",
                }}>
                <CustomCheckBox
                  varient="primary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={rowCount > 0 && numSelected === rowCount}
                  checked={state.data.data.length > 0 && (state.data.data.length === state?.data.data.filter((item) => item?.selected === true).length)}
                  onChange={selectAll}
                />
              </TableCell>
              {props?.headers.map((header) => {
                return (
                  <TableCell
                    key={header.key}
                    align='left'
                    sx={{
                      height: "inherit",
                      display: "inline-flex",
                      flexDirection: "row",
                      position: "relative",
                      justifyContent: "normal",
                      flexWrap: "nowrap",
                      alignItems: "normal",
                      alignContent: "normal",
                      width: header?.size,
                      marginLeft: header?.spacing.left,
                      marginRight: header?.spacing.right,
                      gap: "8px",
                    }}
                  >
                    <Box sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "block",
                      flexGrow: "0",
                      flexShrink: "1",
                      flexBasis: "auto",
                      alignSelf: "auto",
                    }}>
                      <CustomTypography
                        size={"EXTRA-SMALL"}
                        color={"PRIMARY-MAIN"}
                        variant={"BOLD"}
                        content={header?.label}
                      />
                    </Box>

                    {
                      header.sortable === true ?
                        <Box
                          sx={{
                            p: 0, m: 0,
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: "0",
                            flexShrink: "0",
                            flexBasis: "auto",
                            alignSelf: "auto",
                            order: "0",
                            height: {
                              xl: "38%",
                              lg: "34%",
                              md: "30%",
                              sm: "28%",
                              xs: "20%",
                            },
                            width: {
                              xl: "26px",
                              lg: "24px",
                              md: "22px",
                              sm: "20px",
                            },
                          }}
                        >

                          <CustomIcon sx={{
                            height: "40%",
                            width: "100%",
                            flex: "1",
                            "&:hover": {
                              cursor: "pointer",
                            }
                          }}
                          onClick={() => {
                            console.log("ascenting");
                            handleRequestSort(header.map, true);
                          }}
                          icon='arrow_up' color={"#1C1E26"} />


                          <CustomIcon sx={{
                            height: "40%",
                            width: "100%",
                            flex: "1",
                            "&:hover": {
                              cursor: "pointer",
                            },

                          }}
                          onClick={() => {
                            console.log("decenting");
                            handleRequestSort(header.map, false);
                          }}
                          icon='arrow_down' color={"#1C1E26"} />

                        </Box> : ""
                    }

                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody
            key={state?.data?.render}
            sx={{
              width: "100%", p: 0, m: 0,
              maxHeight: { xs: `calc(${TABLE_COMPONENT_HEIGHT["xs"]} - 48px)`, md: `calc(${TABLE_COMPONENT_HEIGHT["md"]} - 52px)`, xl: `calc(${TABLE_COMPONENT_HEIGHT["xl"]} - 56px)` },
              overflow: "scroll",
            }}
          >
            {
              stableSort(state?.data.data, getComparator(order, orderBy))
                .slice()
                .map((element) => {
                  return (
                    <TableRow
                      hover
                      selected={element?.selected}
                      key={element?.mapping_id}
                      sx={{
                        width: "100%",
                        p: 0,
                        m: 0,
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "#F3F5F9",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#EBFCFE",
                        },
                        "&:active": {
                          backgroundColor: "#F3F5F9",
                        }

                      }}
                      onClick={(event) =>
                        handleSingleRowSelection(event, element?.mapping_id)
                      }
                    >

                      <TableCell
                        align='left'
                        sx={{
                          mx: 0,
                          marginLeft: "32px",
                          marginRight: "13px",
                          paddingX: "none",
                        }}
                      >
                        <CustomCheckBox
                          varient="secondary"
                          // color="primary"
                          // indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={element?.selected}
                          onChange={(event) =>
                            handleSelect(event, element?.mapping_id)
                          }
                          // onChange={onSelectAllClick}
                          // inputProps={{
                          // 'aria-label': 'select all desserts',
                          // }}
                        />

                      </TableCell>

                      {
                        props?.headers.map((item) => {
                          return (
                            <TableCell
                              key={`data-${item?.key}`}
                              align={"left"}
                              // padding={'checkbox'}
                              // sx={{width: header?.width}}
                              sx={{
                                marginLeft: item?.spacing.left,
                                marginRight: item?.spacing.right,
                                width: item?.size,
                                display: "flex"
                              }}
                              // sortDirection={orderBy === headCell.id ? order : false}
                            >
                              <CustomTypography
                                size={"EXTRA-SMALL"}
                                color={"PRIMARY-MAIN"}
                                variant={"REGULAR"}
                                content={element[`${item.map}`]}
                              />
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  );
                })}

          </TableBody>
        </Table>

        {
          getSpaceFillers()
        }

      </Box>
    </React.Fragment >
  );
};

CustomTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selectionFormFields: PropTypes.array.isRequired,
  onDeleteClicked: PropTypes.func,
};

CustomTable.defaultProps = {
  headers: [],
  data: [],
  selectionFormFields: [],
  onDeleteClicked: () => { }
};

export default CustomTable;