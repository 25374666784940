function plusIcon(props) {

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 8V16"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M16 12H8"
        stroke={props.disabled === true ? props.disabledcolor : props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

export default plusIcon;