import { BookmarkIcon, Button } from "components";
import { WatchListDrawer } from "./watchlist/WatchListDrawer";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import {
  useGetAllActiveTimeEntries,
  useGetWatchList,
  useNonPersistantMainStore,
  useTimeEntryStore,
} from "controller";

import { useQueryClient } from "@tanstack/react-query";
export const WatchListButton = () => {
  const [isWatchListOpen, setIsWatchListOpen] = useState(false);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const timeEntries = useTimeEntryStore((state) => state.activeTimeEntries);

  const [activeTimeEntryCount, setActiveTimeEntryCount] = useState(0);
  const timerInstanceId = useTimeEntryStore(
    (state) => state.activeTimerInstanceId
  );

  const setActiveTimeEntries = useTimeEntryStore(
    (state) => state.setActiveTimeEntries
  );

  const setWatchList = useNonPersistantMainStore(
    (state) => state.setWatchListData
  );

  const { data: allActiveTimeEntries, isLoading: isLoadingTImeEntries } =
    useGetAllActiveTimeEntries(); /// all active time entries of the resource

  const { data: watchList, isLoading: isLoadingWatchList } = useGetWatchList();
  // set all active time entries to commonStore
  useEffect(() => {
    if (
      !isLoadingTImeEntries &&
      !!allActiveTimeEntries &&
      allActiveTimeEntries?.timeEntries &&
      Array.isArray(allActiveTimeEntries?.timeEntries)
    ) {
      setActiveTimeEntries(allActiveTimeEntries?.timeEntries || []);
    }
  }, [isLoadingTImeEntries, allActiveTimeEntries]);

  ///// set Watchlist data to commonStore
  useEffect(() => {
    if (!isLoadingWatchList) {
      setWatchList(watchList && Array.isArray(watchList) ? watchList : []);
    }
  }, [isLoadingWatchList, watchList]);

  useEffect(() => {
    if (
      !isLoadingTImeEntries &&
      !timerInstanceId &&
      activeTimeEntryCount <= 0 &&
      Object.entries(timeEntries).length > 0
    ) {
      queryClient.invalidateQueries(["timeEntries"]);
    }
    setActiveTimeEntryCount(Object.entries(timeEntries).length);
  }, [timeEntries]);
  return (
    <>
      <Button
        className="intercomV2-topAppBar-watchlistBtn"
        startIcon={<BookmarkIcon sx={{ width: "20px", height: "20px" }} />}
        onClick={() => setIsWatchListOpen(true)}
        variant="ghost"
        sx={{ color: theme.palette.lightBg.high }}
      >
        Watchlist
      </Button>
      <WatchListDrawer
        open={isWatchListOpen}
        onClose={() => setIsWatchListOpen(false)}
      />
    </>
  );
};
