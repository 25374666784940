import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Legacy Imports
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// Constants
import { SELECTFIELD_CONSTANTS } from "./constants";
import { CustomTypography } from "../../../base";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
import { Box, Typography, useTheme } from "@mui/material";
import CustomIcon from "library/icon-library";

// Customization

const CustomSelectField = (props) => {
  // States
  const [populatorOption, setOptions] = useState([]);
  const [isFocus, setFocus] = useState(false);
  const theme = useTheme();

  const customStyles = {
    "& .MuiInputBase-root": {
      // height: SELECTFIELD_CONSTANTS['height']
      height: SELECTFIELD_CONSTANTS?.field["height"],
    },
    "& .MuiOutlinedInput-root": {
      color: SELECTFIELD_CONSTANTS?.field?.palette["unfocus_color"],
      "& > fieldset": {
        borderColor: SELECTFIELD_CONSTANTS?.field?.palette?.outline["DEFAULT"],
        borderRadius: SELECTFIELD_CONSTANTS?.field?.border_radius,
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      color: SELECTFIELD_CONSTANTS?.field?.palette["color"],
      "& > fieldset": {
        borderColor: SELECTFIELD_CONSTANTS?.field?.palette?.outline["SELECTED"],
      },
    },
    "& .MuiInputLabel-root": {
      fontWeight: SELECTFIELD_CONSTANTS?.field?.regular_weight,
      transform: SELECTFIELD_CONSTANTS.transition,
      "&.MuiFormLabel-filled": {
        transform: "translate(14px, -9px) scale(1)",
      },
      "&.Mui-focused": {
        // color: SELECTFIELD_CONSTANTS?.field?.palette?.outline["SELECTED"],
        transform: "translate(14px, -9px) scale(0.8)",
        ...theme.typography.caption,
        color: theme.palette.purple.main,
        // "&.Mui-focused": {
        //   ...theme.typography.caption,
        //   transform: "translate(14px, -12px) scale(0.75)",
        //   backgroundColor: "#fff",
        //   pr: "2px",
        //   //   fontSize: "12px !important",
        //   color: theme.palette.purple.main,
        // },
      },
    },
  };

  // Effects

  useEffect(() => {
    // Setting value
    setFocus(false);
  }, [props]);

  useEffect(() => {
    // Setting options
    async function fetchData() {
      let data = [];
      if (props?.dependency === true) {
        if (
          Array.isArray(props?.triggerValue) &&
          props?.triggerValue.length > 0 &&
          props?.triggerValue.includes("") === false
        ) {
          // console.log("🚀 ~ file: index.js:62 ~ fetchData ~ props?.triggerValue:", props?.triggerValue)
          data = await props?.populator(props?.triggerValue);
        }
      }
      if (props?.dependency === false) {
        data = await props?.populator();
      }
      setOptions(data);
    }
    if (
      (props.options === undefined || props.options === null) &&
      typeof props.populator === "function"
    ) {
      fetchData();
    }
  }, [props.triggerValue]);

  // Trackers
  // useEffect(()=> {console.log(options)}, [options]);
  // useEffect(()=> {console.log(value)}, [value]);

  // Handlers
  const handleChange = (event) => {
    // console.log(typeof event.target.value)
    props.changeHandler({
      field: props.name,
      value:
        isNaN(event.target.value) === true
          ? event.target.value
          : parseInt(event.target.value),
    });
  };

  const onFocusHandler = (event) => {
    if (event.type === "focus") {
      setFocus(true);
      return;
    }
    setFocus(false);
  };

  // Renderer
  return (
    <ThemeProvider>
      <TextField
        id={`${props?.name}-id`}
        variant={"outlined"}
        select={true}
        label={
          props.label ? (
            <CustomTypography
              variant={isFocus === true ? "BOLD" : "REGULAR"}
              size="EXTRA-SMALL"
              content={props.label || ""}
            />
          ) : (
            ""
          )
        }
        onFocus={onFocusHandler}
        onBlur={onFocusHandler}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                margin: "16px 0px",
                padding: "4px 2px",
                overflow: "auto",
                maxHeight: "200px",
                backgroundColor: "#FFFFFF",
                borderRadius: SELECTFIELD_CONSTANTS?.field["border_radius"],
              },
            },
          },
          IconComponent: () => (
            <Box sx={{ marginRight: "7px" }}>
              <CustomIcon icon={"arrow_down"} size={"medium"} color="#000" />{" "}
            </Box>
          ),
        }}
        fullWidth={true}
        disabled={props?.disabled || false}
        // helperText={props?.helperText || ""}
        error={props.error}
        helperText={
          <Box height={props.errorMessage ? "16px" : "0px"}>
            <Typography variant="caption-3" color="error">
              {props.errorMessage}
            </Typography>
          </Box>
        }
        placeholder={props?.placeholder || ""}
        value={props?.value}
        sx={customStyles}
        onChange={handleChange}
      >
        {(
          (props.options === undefined || props.options === null
            ? populatorOption
            : props.options) || []
        ).map((option) => {
          return (
            <MenuItem key={option.key} value={option.value}>
              <CustomTypography
                size="EXTRA-SMALL"
                variant="REGULAR"
                content={option.label || ""}
              />
            </MenuItem>
          );
        })}
      </TextField>
    </ThemeProvider>
  );
};

CustomSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  multi: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  dependency: PropTypes.bool.isRequired,
  triggerValue: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  populator: PropTypes.func,
  options: PropTypes.array, //options=[{key:'',value:'', label:''}]
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

CustomSelectField.defaultProps = {
  name: "",
  label: "",
  size: "",
  multi: false,
  value: null,
  options: null,
  disabled: false,
  dependency: false,
  triggerValue: [],
  error: false,
  changeHandler: () => {},
  populator: () => {},
};

export default CustomSelectField;
