import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

// legacy Imports
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CustomTypography } from "../../base";

import Button from "@mui/material/Button";
import colorPalette from "../../common/colors";

const CustomTableMultiSelectToolbar = (props) => {
  // states
  // const [state, setState] = useState({
  //     open: false,
  //     selection: 0,
  //     render: ''
  // });
  // Effects
  // useEffect(() => {
  //     setState({
  //         ...state,
  //         open: props?.open,
  //         selection: props?.selection,
  //         render: v4()
  //     })
  // }, [props])

  return (
    <Dialog
      open={props?.open}
      onClose={props?.handleClose}
      // disableEnforceFocus // Let the user focus on elements outside the dialog
      style={{ position: "initial" }} // This was the key point, reset the position of the dialog, so the user can interact with other elements
      fullWidth
      maxWidth="md"
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          backgroundColor: "#0000",
          position: "absolute",
          bottom: {
            sm: "66px",
            md: "82px",
            lg: "82px",
            xl: "122px",
          },

          left: "50%",
          // left: {
          //     sm: 'min(50% , 191px)',
          //     md: 'min(50% , 247px)',
          //     lg: 'min(50% , 315px)',
          //     xl: 'min(50% , 420px)',
          // },
          transform: "translate(-27%, 0%)",
          height: "60px",

          maxWidth: { xs: "60%", md: "80%", xl: "80%" },
          boxShadow: "none",
          "&:hover": {
            cursor: "pointer",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          borderRadius: "16px",
          backgroundColor: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          width: "80%",
        }}
      >
        <Box>
          <CustomTypography
            size={"SMALL-BETA"}
            color={"PRIMARY-FOCUS"}
            variant={"REGULAR"}
            content={`${props?.selection} items selected`}
          />
        </Box>
        {/* <CustomButton /> */}
        <Button
          variant="text"
          color="warning"
          onClick={props.onClick}
          disableRipple
          TouchRippleProps={{
            color: "#FFFF",
          }}
          sx={{
            height: "36px",
            borderRadius: "8px",
            backgroundColor: colorPalette.sematics.lightBg.error,
            textTransform: "none",
            px: "16px",
            py: "8px",

            "&:Hover": {
              backgroundColor: colorPalette.sematics.lightBg.error,
            },
          }}
        >
          <CustomTypography
            size={"SMALL-ALPHA"}
            color={"PRIMARY-FOCUS"}
            variant={"MEDIUM"}
            content={props.actionTitle}
          />
        </Button>
        {/* <Box>
                    <CustomTypography
                        size={'SMALL-BETA'}
                        color={'PRIMARY-FOCUS'}
                        variant={'REGULAR'}
                        content={`${state?.selection} items selected`}
                    />
                </Box> */}
      </DialogContent>
    </Dialog>
  );
};

export default CustomTableMultiSelectToolbar;
