import { axiosClient } from "utilz";
import {
  apiErrorHandler,
  debugConsole,
  getAccessToken,
  getMspId,
} from "utilz/helpers";

const fetchAllResourceTypes = async () => {
  try {
    const url = `/controlCenter/fetch/resource_type/${getMspId()}?filters=[]`;
    const headers = {
      Authorization: `Bearer ${getAccessToken()}`,
    };
    const response = await axiosClient.get(url, { headers });
    const responseData = response.data;
    debugConsole("🤳", responseData);
    if (
      response.status === 200 &&
      responseData.success &&
      responseData.statusCode === 200
    ) {
      return responseData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export { fetchAllResourceTypes };
