function boardcastIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 13.762L12.194 17.928C12.419 19.281 11.376 20.512 10.005 20.512V20.512C8.92 20.512 7.994 19.728 7.816 18.657L7 13.762" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.75 13.762H5.875C4.011 13.762 2.5 12.251 2.5 10.387V10.387C2.5 8.52302 4.011 7.01202 5.875 7.01202H13.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.75 7.01199L18.751 3.67799C19.499 3.17999 20.5 3.71599 20.5 4.61499V16.16C20.5 17.059 19.499 17.594 18.751 17.096L13.75 13.762" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.75 13.76V7.01001" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
export default boardcastIcon;