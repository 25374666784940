import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { CustomTypography } from "../../../../../../../../../../../library/base";
import { CustomRoundCloseButton } from "../../../../../../Components";
import { useEffect, useState } from "react";
import CustomLinearProgress from "../../../../../../../../../../../library/base/custom-linear-progress";
import { STATUS } from "../../constant";
import { FileIcon } from "components";
import { ThemeProvider } from "utilz/theme/ThemeProvider";
function AttachmentItemTile(props) {
  const [state, setState] = useState({
    progress: 0,
    controller: null,
    status: STATUS.UPLOADING,
  });
  useEffect(() => {
    /// console.log("🚀 ~ file: index.js:18 ~ useEffect ~ props.data:", props.value)
    setState({
      ...state,
      status: props.value.status,
    });
    if (typeof props.value.uploader === "object") {
      props.value.uploader.subscribe({
        next: ({ response, status, percentage, controller }) => {
       
          if (status === "COMPLETE") {
            if (
              response?.success &&
              response?.statusCode === 200 &&
              response?.data &&
              Array.isArray(response?.data) &&
              response?.data.length > 0
            ) {
              setState({
                progress: 100,
                controller: state.controller || controller,
                status: STATUS.COMPLETE,
              });
              props.onUploadComplete(response?.data[0]);
              return;
            }
            setState({
              ...state,
              status: STATUS.ERROR,
            });
            props.onError();
            return;
          }
          setState({
            progress: percentage,
            controller: state.controller || controller,
            status: STATUS.UPLOADING,
          });
        },
        error: (error) => {
        
          props.onError();
          setState({
            ...state,
            status: STATUS.ERROR,
          });
        },
      });
    }
  }, []);

  const onDeleteHandler = () => {
    if (state.controller !== null) {
      state.controller.abort();
    }
    props.onDelete();
  };

  return (
    <Stack
      direction={"row"}
      gap={"14px"}
      sx={{
        alignItems: "center",
        py: "24px",
        px: "32px",
        borderBottom: "2px dashed",
        borderColor: "#CCCCCC",
      }}
    >
      {/* jpg_document,pdf_document */}
      <ThemeProvider>
        <FileIcon type={props?.value?.file_type} />
      </ThemeProvider>
      <Stack
        sx={{
          width: "70%",
        }}
      >
        <CustomTypography
          content={props?.value?.original_name || ""}
          size="EXTRA-SMALL"
          variant="MEDIUM"
        />
        <Stack
          direction={"row"}
          color={state.status === STATUS.ERROR ? "#DA1E28" : "#198038"}
        >
          {state.status === STATUS.UPLOADING ? (
            <CustomTypography
              content={"Uploading..."}
              color="TERITARY-MAIN"
              size="EXTRA-SMALL"
              variant="MEDIUM"
            />
          ) : null}
          <CustomTypography
            content={
              state.status === STATUS.UPLOADING
                ? `${state.progress}%`
                : state.status
            }
            size="EXTRA-SMALL"
            variant="MEDIUM"
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "30%",
        }}
      >
        {state.status === STATUS.UPLOADING ? (
          <CustomLinearProgress progress={50} />
        ) : null}
      </Box>
      <CustomRoundCloseButton outlined={true} onClick={onDeleteHandler} />
    </Stack>
  );
}

AttachmentItemTile.propTypes = {
  value: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

AttachmentItemTile.defaultProps = {
  value: {},
  onDelete: () => {},
  onUploadComplete: () => {},
  onError: () => {},
};

export default AttachmentItemTile;
