import { commonApiCalls } from "../../../../../../../../../../../../../services/commonApiCalls";

export const GENERAL_FORM_CONSTANT = {
  template_name: {
    name: "template_name",
    label: "Template name",
    data_name: "template_name",
    placeholder: "Enter template name",
    defaultValue: "",
    required: true,
  },
  template_summary: {
    name: "template_summary",
    label: "Template summary",
    data_name: "template_summary",
    placeholder: "Enter template summary",
    defaultValue: "",
    required: true,
  },
  template_description: {
    name: "template_description",
    label: "Template description",
    data_name: "template_description",
    placeholder: "Tell us more about issue",
    defaultValue: "",
    required: true,
  },
  board: {
    name: "board_id",
    label: "Board",
    data_name: "board",
    placeholder: "",
    width: "120px",
    dependency: true,
    getData: commonApiCalls.getAllTicketBoards,
    defaultValue: "",
    required: false,
  },
  priority: {
    name: "priority",
    label: "Priority *",
    data_name: "priority",
    placeholder: "",
    width: "120px",
    dependency: false,
    getData: commonApiCalls.getAllUrgencyTypes,
    defaultValue: "",
    required: true,
  },
  billable: {
    name: "is_billable",
    data_name: "is_billable",
    options: {
      billable: "Billable",
      non_billable: "Non-Billable",
    },
    defaultValue: true,
    required: false,
  },
  checklists: {
    name: "checklists",
    label: "Checklists",
    data_name: "checklist",
    defaultValue: (data = []) => {
      return {
        items: data || [],
      };
    },
    required: false,
  },

  attachments: {
    name: "attachments",
    data_name: "attachments",
    defaultValue: [],
    required: false,
  },
};
