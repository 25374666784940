import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createSampleData,
  deleteSampleData,
  skipSampleData,
} from "controller/api/sample-data";

export const useDeleteSampleData = () => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteSampleData(), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["msp"]);
    },
  });
};

export const useCreateSampleData = () => {
  const queryClient = useQueryClient();
  return useMutation(() => createSampleData(), {
    onSuccess(data, variables, context) {},
  });
};

export const useSkipSampleData = () => {
  const queryClient = useQueryClient();
  return useMutation(() => skipSampleData(), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(["msp"]);
    },
  });
};
