import React from "react";

const CustomMailServerComponent = (props)=> {
  return(
    <React.Fragment>
      <h6>Custom server Sign in</h6>
    </React.Fragment>
  );
};

export default CustomMailServerComponent;