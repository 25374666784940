import {
  CreateServiceTypeInput,
  CreateServiceTypeSubTypeInput,
  DeleteServiceTypeSubTypeInput,
  GetTypeSubtypeInput,
  UpdateServiceTypeSubTypeInput,
} from "shared";
import { axiosClient } from "utilz";

import { apiErrorHandler, getMspId } from "utilz/helpers";
export const getServiceTypeSubtype = async ({
  serviceType,
}: GetTypeSubtypeInput) => {
  try {
    // const url = `/configuration/get/type/${serviceType}/${getMspId()}`;
    const url = `/v2/configuration/type/${serviceType}`;

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};

export const createServiceType = async ({
  serviceType,
  data,
}: CreateServiceTypeInput) => {
  try {
    const url = `/v2/configuration/type/${serviceType}`;
    const response = await axiosClient.post(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createServiceTypeSubtype = async ({
  serviceType,
  serviceTypeSubTypeId,
  data,
  attributeName,
}: CreateServiceTypeSubTypeInput) => {
  try {
    const url = `/v2/configuration/subtype/${serviceType}/${serviceTypeSubTypeId}`;
    const response = await axiosClient.post(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
export const updateServiceTypeSubtype = async ({
  attributeName,
  data,
  serviceTypeSubTypeId,
  serviceType,
}: UpdateServiceTypeSubTypeInput) => {
  try {
    // const url = `/configuration/update/${attributeName}/${serviceType}/${serviceTypeSubTypeId}/${getMspId()}`;
    const url = `/v2/configuration/subtype/${serviceType}/${serviceTypeSubTypeId}`;
    const response = await axiosClient.patch(`${url}`, data);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteServiceTypeSubtype = async ({
  attributeName,
  serviceTypeSubTypeId,
  serviceType,
}: DeleteServiceTypeSubTypeInput) => {
  try {
    const url = `/configuration/delete/${attributeName}/${serviceType}/${serviceTypeSubTypeId}/${getMspId()}`;
    const response = await axiosClient.delete(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
