import Box, { BoxProps } from "@mui/material/Box";
import React, { useState } from "react";
import { BottomStickyLayer } from "../../library/layers";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Divider, SxProps, Theme, useTheme } from "@mui/material";
import { Button } from "../button";
import { PaginationButton } from "./PaginationButton";
import { useSearchParams } from "react-router-dom";
import { SelectField } from "components/input";

const PerPageOptions = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
interface PaginationProps extends BoxProps {
  totalPages?: number;
  totalItems?: number;
  currentPage: number;
  itemsPerPage: number;
  onPerPageChange?: (itemsPerPage: number) => void;
  onPaginationClick: (pageNo: number) => void;
  pageNationStyles?: SxProps<Theme>;
  isDisabled?: boolean;
}
export const Pagination = ({
  currentPage = 1,
  totalPages = 1,
  totalItems,
  itemsPerPage = 50,
  onPaginationClick,
  onPerPageChange,
  pageNationStyles,
  isDisabled = false,
}: PaginationProps) => {
  // const [currentPageNo, setCurrentPageNo] = useState(currentPage);
  const [newPageNo, setNewPageNo] = useState(currentPage);
  const pageQueryParams: URLSearchParams = new URLSearchParams();
  const theme = useTheme();
  const from =
    // @ts-ignore
    currentPage * itemsPerPage < totalItems
      ? currentPage * itemsPerPage
      : totalItems;
  const [currentQueryParameters, setSearchParams] = useSearchParams();

  const onPaginationButtonClick = (type: "next" | "prev") => {
    if (type === "next") {
      pageQueryParams.set("page", `${currentPage + 1}`);
      setSearchParams(pageQueryParams);
      onPaginationClick(currentPage + 1);
      setNewPageNo(currentPage + 1);
    } else {
      pageQueryParams.set("page", `${currentPage - 1}`);
      setSearchParams(pageQueryParams);
      onPaginationClick(currentPage - 1);
      setNewPageNo(currentPage - 1);
    }
  };
  return (
    <Box
      sx={{
        // position: "absolute",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "space-between",
        // backgroundColor: "#FFFFFF",
        // borderBottom: "1px solid #EDEDED",
        // py: "8px",
        borderBottom: "1px solid #EDEDED",
      }}
    >
      <Box
        sx={{
          // position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#FFFFFF",
          // borderBottom: "1px solid #EDEDED",
          py: "8px",
          ...pageNationStyles,
        }}
      >
        <Stack
          direction="row"
          gap="16px"
          justifyContent="flex-start"
          alignItems="center"
          width={"100%"}
        >
          {onPerPageChange && (
            <Stack direction="row" gap="16px" alignItems="center">
              <Typography variant="body-medium" noWrap>
                Items per page
              </Typography>
              <SelectField
                isClearable={false}
                isResponsive={false}
                size="small"
                name="perPage"
                disabled={isDisabled}
                value={itemsPerPage}
                options={PerPageOptions}
                labelMappingKey="label"
                valueMappingKey="value"
                onValueChange={(value) => {
                  onPerPageChange && onPerPageChange(value);
                }}
                sx={{
                  "&.MuiInputBase-root": {
                    width: "20px",
                  },
                }}
                fullWidth={false}
                hideHelperBox
              />

              <Divider orientation="vertical" flexItem />
            </Stack>
          )}

          <Typography variant="body-medium">
            {/* @ts-ignore */}
            {!totalItems || totalItems < 1
              ? "0 - 0 of 0"
              : `${
                  (currentPage - 1) * itemsPerPage + 1
                } - ${from} of ${totalItems}`}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          gap="16px"
          justifyContent="space-between"
          alignItems="center"
        >
          <TextField
            label={""}
            variant={"outlined"}
            fullWidth={false}
            type={"number"}
            value={newPageNo}
            disabled={isDisabled}
            // error = {state['error'] || false}
            sx={{
              ...theme.typography["body-medium"],
              "&.MuiFormControl-root": {
                minWidth: "42px",
                width: "fit-content",
                maxWidth: "70px",
                height: "32px",
                maxHeight: "40px",
                padding: "0px",
              },

              "& .MuiOutlinedInput-root": {
                minWidth: "42px",
                width: "fit-content",
                maxWidth: "70px",
                height: "32px",
                maxHeight: "40px",
                padding: 0,
                borderRadius: "8px",
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                { display: "none" },
            }}
            onChange={(e) =>
              // parseInt(e.target.value) <= totalPages &&
              setNewPageNo(parseInt(e.target.value))
            }
          />
          <Typography sx={{ whiteSpace: "nowrap" }} variant="body-medium">
            of {totalPages < 1 ? 1 : totalPages}
          </Typography>
          <Button
            disabled={
              !newPageNo ||
              newPageNo > totalPages ||
              newPageNo < 1 ||
              isDisabled
            }
            variant="ghost"
            sx={{
              minWidth: "0px",
              ...theme.typography["body-medium"],
              color: theme.palette.purple.main,
            }}
            onClick={() => {
              if (newPageNo > 0 && newPageNo <= totalPages) {
                pageQueryParams.set("page", `${newPageNo}`);
                setSearchParams(pageQueryParams);
                onPaginationClick(newPageNo);
              }
            }}
          >
            Go
          </Button>
          <PaginationButton
            disabled={currentPage <= 1 || isDisabled ? true : false}
            type="prev"
            onClick={() => onPaginationButtonClick("prev")}
          />
          <PaginationButton
            disabled={currentPage == totalPages || isDisabled ? true : false}
            type="next"
            onClick={() => onPaginationButtonClick("next")}
          />
        </Stack>
      </Box>
    </Box>
  );
};
