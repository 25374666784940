import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Legacy Imports
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

// HOC
import CustomIcon from "../../../../../../../library/icon-library";

// Constants
import { TEXTFIELD_CONSTANTS } from "./constants";
import { Box } from "@mui/material";

// Customization
const customStyles = {
  "& .MuiInputBase-root": {
    height: TEXTFIELD_CONSTANTS["height"],
  },
  "& .MuiOutlinedInput-root": {
    color: TEXTFIELD_CONSTANTS?.palette["unfocus_color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["DEFAULT"],
      borderRadius: TEXTFIELD_CONSTANTS?.border_radius,
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
    },
  },
  "& label": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    fontWeight: TEXTFIELD_CONSTANTS?.palette["bold_weight"],
    "&.Mui-focused": {
      color: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
    },
  },
};

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <Box
      sx={{
        width: "calc(100% - 32px)",
      }}
      className={
        "typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular"
      }
    >
      <input ref={ref} {...props}></input>
    </Box>
  );
});

function SearchTextField(props) {
  // Event Handlers
  const handleChange = (event) => {
    props?.changeHandler({ field: props.name, value: event.target.value });
  };

  const handleReset = (event) => {
    props?.onReset();
  };

  // const onKeyPress = (event) => {
  //     if (event.key === "Enter") {
  //         // console.log('Input value', e.target.value);
  //         props?.onSubmit({ field: props.name, value: event.target.value });
  //     }
  // }

  return (
    <TextField
      name={props.name || ""}
      label={props.label || ""}
      type={props.type || "text"}
      value={props.value}
      // size={props?.size}
      fullWidth={true}
      disabled={props.disabled || false}
      required={props.required || false}
      error={props.error || false}
      helperText={props.helperText || ""}
      placeholder={props.placeholder || ""}
      sx={customStyles}
      InputProps={{
        inputComponent: CustomInput,
        ...(props?.adornment === true
          ? {
            startAdornment: (
              <InputAdornment position={props?.adornmentPosition}>
                <CustomIcon
                  icon={props?.adornmentValue}
                  size={"sm"}
                  color={"#1C1E26"}
                />
              </InputAdornment>
            ),

            endAdornment: (
              <InputAdornment position="end">
                <Box
                  sx={{ "&: hover": { cursor: "pointer" } }}
                  onClick={handleReset}
                >
                  <CustomIcon size={"md"} icon={"close"} color="#767B85" />
                </Box>
              </InputAdornment>
            ),
          }
          : {}),
      }}
      onChange={handleChange}
    />
  );
}

SearchTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  adornment: PropTypes.bool.isRequired,
  adornmentPosition: PropTypes.string.isRequired,
  adornmentValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

SearchTextField.defaultProps = {
  name: "",
  type: "",
  label: "",
  value: "",
  adornment: false,
  adornmentPosition: "",
  adornmentValue: "",
  disabled: false,
  required: false,
  error: false,
  helperText: "",
  placeholder: "",
  size: "medium",
  changeHandler: () => {},
  onReset: () => {},
};

export default SearchTextField;
