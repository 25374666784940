
import { SvgIcon, SvgIconProps } from "@mui/material";
    
export const ClockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <circle cx="12" cy="12" r="9.004" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M15.455 13.152 12 12V5.997"/>
</svg>
  </SvgIcon>
);
