/* eslint-disable no-undef */
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Button, DownloadIcon, ExternalLinkIcon } from "components";
import { DownloadDrawer } from "shared";
import { useState } from "react";
import systemApp from "assets/images/systemApp.png";
export const ChatTab = () => {
  const theme = useTheme();
  const [isDownloadDrawerOpen, setIsDownloadDrawerOpen] = useState(false);
  return (
    <Stack
      height={"100%"}
      gap={"24px"}
      sx={{ p: "32px 32px 16px 32px", overflowY: "scroll" }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant={"h4"}>Setup Chat-based Ticketing</Typography>
      </Stack>
      <Box
        height={"100%"}
        width="100%"
        display="grid"
        gridTemplateColumns="4fr 6fr"
        rowGap={"16px"}
        p="40px"
        border={`1px solid ${theme.palette.neutral["015"]}`}
        borderRadius="8px"
        position="relative"
      >
        <Stack gap="24px">
          <Typography variant="h6">Download IT-Connect</Typography>
          <Typography variant="body-medium">
            Download and Install the IT-Connect app (our End-user application
            for chat-based ticketing) on your customer systems.
          </Typography>
          <Box>
            <Button
              variant="primary"
              endIcon={<DownloadIcon />}
              onClick={() => {
                setIsDownloadDrawerOpen(true);
              }}
            >
              Download Now
            </Button>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            width={"fit-content"}
            sx={{
              alignItems: "center",
              justifyContent: "start",
              cursor: "pointer",
              color: theme.palette.purple.main,
              "& .imageCardTitle": {
                color: theme.palette.purple.main,
                fontWeight: "400",
              },
              "&:hover > .imageBox img": {
                marginTop: "24px !important",
                marginLeft: "32px !important",
                transform: "all 3s ease-in-out",
              },
            }}
            onClick={() => {
              window.open(
                "https://support.deskday.com/en/articles/8235973-installing-the-it-connect",
                "_blank"
              );
            }}
          >
            <Typography className="imageCardTitle" variant="link-small">
              How to Install IT-Connect
            </Typography>
            <ExternalLinkIcon sx={{ width: "20px", height: "20px" }} />
          </Stack>
        </Stack>
        <Stack paddingBottom="2%" alignItems={"end"}>
          <img
            style={{
              width: "95%",
              height: "auto",
              backgroundRepeat: "no-repeat",
              objectFit: "contain",
              marginLeft: "auto",
            }}
            src={systemApp}
          />
        </Stack>
        <Box
          position="absolute"
          bottom="0"
          left="0"
          right={"0"}
          p="12px 24px"
          bgcolor={theme.palette.secondary["purple-tint-3"]}
        >
          <Typography variant="body-medium">
            Once set up, you can also onboard your users smoothly through "SSO
            Integration"
          </Typography>
        </Box>
      </Box>
      {/* <Stack
        sx={{
          border: `1px solid ${theme.palette.neutral["015"]}`,
          borderRadius: "24px",
        }}
        divider={<Divider />}
      >
        <OptionContainer
          title="Download IT-Connect"
          description="Begin by downloading and installing the IT-Connect App (our end-user app) on your users' systems. This app serves as our prime chat-based ticketing medium."
          index={1}
          CustomButton={
            <Button
              variant="primary"
              endIcon={<DownloadIcon />}
              onClick={() => {
                setIsDownloadDrawerOpen(true);
              }}
            >
              Download Now
            </Button>
          }
        />
        <OptionContainer
          title="Onboard Users"
          description='The most efficient login method we always recommend is through "SSO Integration", where users can conveniently log in using their Office 365 credentials. You can enable this integration in the Customer settings. Alternatively, you can also let your customers log in by creating User accounts manually.'
          index={2}
          linkTitle="How to Smart Onboard"
          onClick={() => {
            window.open(
              "https://support.deskday.com/en/articles/8412888-adding-end-users-manually-and-via-single-sign-on-sso",
              "_blank"
            );
          }}
        />
        <OptionContainer
          title="Create Tickets"
          description="Once the User logs in, they can effortlessly create a ticket in just 3 clicks. Good news: you'll only receive chats once a ticket is raised and assigned to techs, so no unwanted chats their way. Happy supporting! 😊"
          index={3}
          linkTitle="How to Create Ticket"
          onClick={() => {
            window.open(
              "https://support.deskday.com/en/articles/8235975-creating-tickets-from-it-connect",
              "_blank"
            );
          }}
        />
      </Stack> */}
      <DownloadDrawer
        open={isDownloadDrawerOpen}
        onClose={() => setIsDownloadDrawerOpen(false)}
      />
    </Stack>
  );
};

// const OptionContainer = ({
//   description,
//   index,
//   linkTitle,
//   title,
//   CustomButton,
//   titleAdditionalComponent,
//   onClick,
// }: {
//   title: string;
//   description: string;
//   linkTitle?: string;
//   index: number;
//   titleAdditionalComponent?: JSX.Element;
//   CustomButton?: JSX.Element;
//   onClick?: () => void;
// }) => {
//   const theme = useTheme();
//   return (
//     <Stack
//       direction={"row"}
//       sx={{
//         py: "40px",
//         px: "48px",
//       }}
//       gap={"16px"}
//     >
//       <Box
//         sx={{
//           width: "24px",
//           height: "24px",
//           bgcolor: `${theme.palette.neutral["015"]}`,
//           borderRadius: "50%",
//           justifyContent: "center",
//           alignItems: "center",
//           display: "flex",
//         }}
//       >
//         <Typography variant={"button-small"}>{index}</Typography>
//       </Box>

//       <Stack flex={1} gap={"16px"}>
//         <Stack direction={"row"} gap={"8px"}>
//           <Typography variant={"button-large"}>{title}</Typography>
//           {titleAdditionalComponent && titleAdditionalComponent}
//         </Stack>

//         <Typography
//           variant={"body-medium"}
//           sx={{
//             color: `${theme.palette.lightBg.low}`,
//             maxWidth: "700px",
//           }}
//         >
//           {description}
//         </Typography>
//       </Stack>
//       <Box
//         height={"100%"}
//         sx={{
//           display: "flex",
//           alignItems: "end",
//         }}
//       >
//         {CustomButton ? (
//           CustomButton
//         ) : (
//           <Stack
//             direction={"row"}
//             sx={{
//               color: theme.palette.purple.main,
//               cursor: "pointer",
//               alignItems: "center",
//             }}
//             onClick={onClick}
//           >
//             <Typography variant={"link-small"}>{linkTitle}</Typography>
//             <ExternalLinkIcon sx={{ width: "20px", height: "20px" }} />
//           </Stack>
//         )}
//       </Box>
//     </Stack>
//   );
// };
