import { Box, useTheme, Stack } from "@mui/material";
import {
  AttachmentDragAndDrop,
  AttachmentTile,
  Modal,
  RadioButtonGroup,
} from "components";
import { uploadChatAttachments, useSendChatMessage } from "controller";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { useMainStore } from "controller/store/mainStore";
import { v4 as uuidv4 } from "uuid";
import { AccessSpecifier, AttachmentFileData } from "shared/types";
import { useGlobalStore } from "controller/store/globalStore";
import { transformAssigneesForChat } from "utilz/helpers";

const RadioMenuData = [
  { label: "Private", value: "private" },
  { label: "Public", value: "public" },
  { label: "Self", value: "self" },
];
interface AttachmentModalProps {
  open: boolean;
  onClose: () => void;
  currentServiceId: string;
  serviceSource?: string;
  isDisabled: boolean;
  serviceType: string;
}

export const ChatAttachmentsModal = ({
  open,
  onClose,
  currentServiceId,
  isDisabled,
  serviceSource,
  serviceType,
}: AttachmentModalProps) => {
  const theme = useTheme();
  const [messageType, setMessageType] = useState<AccessSpecifier>("private");
  const [messageValue, setMessageValue] = useState("");
  const [fileData, setFileData] = useState<Array<AttachmentFileData>>([]);
  const assignees = useMainStore((state) => state.assignees);
  const resourceId = useGlobalStore((state) => state.resourceId);
  const profilePic = useGlobalStore((state) => state.profilePictureUrl);
  const displayName = useGlobalStore((state) => state.name);
  // const ticketSource = useMainStore((state) => state.ticketSource);
  // const isDisabled = useMainStore((state) => state.isDisabled);

  const { mutateAsync: sendMessage, isLoading } = useSendChatMessage();

  const removeImageFromList = (itemIndex: number) => {
    setFileData((prev) => prev.filter((item, index) => itemIndex != index));
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (!isDisabled) {
        const data: Array<AttachmentFileData> = acceptedFiles?.map(
          (file: any) => {
            const key = uuidv4();
            return {
              key: key,
              name: file.name,
              size: file.size,
              type: file.type,
              status: "Uploading",
              progress: 0,
              file,
            };
          }
        );
        setFileData((prev) => {
          return [...prev, ...data];
        });
        data.map((file) => {
          uploadImage({ file: file.file, key: file.key });
        });
      }
    },
    [currentServiceId, isDisabled]
  );

  const uploadImage = ({ file, key }: { file: any; key: string }) => {
    const formData = new FormData();
    formData.append("files", file);
    uploadChatAttachments({
      formData,
      serviceId: currentServiceId,
      serviceType,
    }).subscribe({
      next: ({ response, status, percentage, controller }) => {
        if (status === "COMPLETE") {
          if (response?.success && response?.statusCode === 200) {
            setFileData((prev) => {
              const updatedData = [...prev];
              const index = updatedData.findIndex((file) => file.key === key);
              if (index !== -1) {
                updatedData[index] = {
                  ...updatedData[index],
                  status: "Uploaded",
                  progress: 100,
                  responseData: response.data[0],
                };
                return updatedData;
              }
              return updatedData;
            });
          } else {
            setFileData((prev) => {
              const updatedData = [...prev];
              const index = updatedData.findIndex((file) => file.key === key);
              if (index !== -1) {
                updatedData[index] = {
                  ...updatedData[index],
                  status: "Error",
                  progress: 0,
                };
                return updatedData;
              }
              return updatedData;
            });
          }
        } else {
          setFileData((prev) => {
            const updatedData = [...prev];
            const index = updatedData.findIndex((file) => file.key === key);
            if (index !== -1) {
              updatedData[index] = {
                ...updatedData[index],
                status: "Uploading",
                progress: percentage,
              };
              return updatedData;
            }
            return updatedData;
          });
        }
      },
    });
  };

  const handleSendMessage = async () => {
    const transformedAssignees = await transformAssigneesForChat(assignees);
    if (
      fileData.length <= 0 ||
      !fileData.every((file) => file.status === "Uploaded")
    ) {
      return;
    }

    const attachments = fileData.map((file) => file.responseData);

    const messageData = {
      assignedResources: transformedAssignees,
      message: messageValue,
      accessSpecifier: messageType,
      serviceId: currentServiceId,
      serviceType,
      originType: serviceSource,
      messageType: "normal",
      sender: {
        mapping_id: resourceId || "",
        display_name: displayName || "",
        profile_pic: profilePic || "",
        user_type: "resource",
      },
      attachments: attachments ? attachments : [],
    };
    // @ts-ignore
    const data = await sendMessage(messageData);

    setFileData([]);

    setMessageValue("");
    onClose();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <Modal
      open={open}
      maxWidth="sm"
      height="sm"
      title="Attach files"
      onClose={onClose}
      primaryButtonText="Save"
      onPrimaryButtonClick={handleSendMessage}
      isPrimaryButtonDisabled={
        isDisabled ||
        isLoading ||
        fileData.length <= 0 ||
        !fileData.every((file) => file.status === "Uploaded")
      }
      //   onPrimaryButtonClick={handlePrimaryButtonClick}
      secondaryButtonText="Cancel"
      onSecondaryButtonClick={onClose}
      footerLeftActionComponent={
        serviceType === "ticket" && (
          <RadioButtonGroup
            row
            data={RadioMenuData}
            labelMappingKey="label"
            valueMappingKey="value"
            onChange={(e) => setMessageType(e)}
            value={messageType}
          />
        )
      }
    >
      <Box px="32px">
        <AttachmentDragAndDrop onDrop={onDrop} />
        <Box>
          <TextareaAutosize
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            minRows={4}
            placeholder="Description"
            style={{
              border: `1px solid ${theme.palette.neutral["015"]}`,
              backgroundColor: theme.palette.neutral["005"],
              width: "100%",
              borderRadius: "8px",
              marginTop: "16px",
              padding: "12px",
              ...theme.typography["body-medium"],
            }}
          />
        </Box>
        <Box minHeight={"200px"} mt="16px">
          <Stack>
            {fileData.map((file, index) => (
              <AttachmentTile
                key={file.key}
                file={file}
                percentage={file.progress}
                status={file.status}
                onRemove={() => {
                  removeImageFromList(index);
                }}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
