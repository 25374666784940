import { Box, CircularProgress } from "@mui/material";
import { BarChart, HorizontalBarChart, StackedBarChart } from "./bar-chart";
import { DonutChart } from "./donut-chart";
import { TableChart } from "./table-chart";
import { PieChart } from "./pie-chart";
import { AreaChart } from "./area-chart";
import { ChartProps } from "./Utils";
import { RadialBarChart } from "./radial-bar-chart";

interface MainChartsProps extends Omit<ChartProps, "width" | "height"> {
  type:
    | "bar"
    | "horizontalBar"
    | "donut"
    | "table"
    | "pie"
    | "area"
    | "radialBar"
    | "stacked_bar"
    | "line";
  centerContent?: string;
  centerSubContent?: string;
  barSize?: number;
}

export const Chart = ({
  type,
  data,
  labelKey,
  valueKey,
  centerContent,
  centerSubContent,
  isLoading = false,
  customLegend,
  selectedIndex,
  onSelectedIndexChange,
  customTooltip,
  showAxisLines = false,
  showGrid = false,
  showLegends = true,
  barSize,
}: MainChartsProps) => {
  switch (type) {
    case "bar":
      return (
        <BarChart
          width={"100%"}
          height={"100%"}
          data={data}
          labelKey={labelKey}
          valueKey={valueKey}
          isLoading={isLoading}
          customLegend={customLegend}
          showGrid={showGrid}
          showLegends={showLegends}
          showAxisLines={showAxisLines}
          barSize={barSize}
        />
      );
    case "horizontalBar":
      return (
        <BarChart
          width={"100%"}
          height={"100%"}
          data={data}
          labelKey={labelKey}
          valueKey={valueKey}
          isLoading={isLoading}
          customLegend={customLegend}
          showGrid={showGrid}
          showLegends={showLegends}
          showAxisLines={showAxisLines}
          layout="vertical"
          barSize={barSize}
        />
      );
    case "donut":
      return (
        <DonutChart
          width={"100%"}
          height={"100%"}
          data={data}
          labelKey={labelKey}
          valueKey={valueKey}
          centerContent={centerContent || ""}
          centerSubContent={centerSubContent || ""}
          innerRadius={"55%"}
          outerRadius={"80%"}
          isLoading={isLoading}
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
          showGrid={showGrid}
          showLegends={showLegends}
          showAxisLines={showAxisLines}
        />
      );
    case "table":
      return (
        <TableChart
          height={"100%"}
          width={"100%"}
          data={data}
          labelKey={labelKey}
          valueKey={valueKey}
          isLoading={isLoading}
          showGrid={showGrid}
          showLegends={showLegends}
          showAxisLines={showAxisLines}
        />
      );
    case "area":
      return (
        <AreaChart
          height={"100%"}
          width={"100%"}
          data={data}
          labelKey={labelKey}
          valueKey={valueKey}
          isLoading={isLoading}
          showGrid={showGrid}
          showLegends={showLegends}
          showAxisLines={showAxisLines}
        />
      );
    case "stacked_bar":
      return (
        <StackedBarChart
          height={"100%"}
          width={"100%"}
          data={data}
          labelKey={labelKey}
          valueKey={valueKey}
          isLoading={isLoading}
          showGrid={showGrid}
          showLegends={showLegends}
          showAxisLines={showAxisLines}
        />
      );

    case "radialBar":
      return (
        <RadialBarChart
          height={"100%"}
          width={"100%"}
          data={data}
          innerRadius={"30%"}
          outerRadius={"110%"}
          labelKey={labelKey}
          valueKey={valueKey}
          isLoading={isLoading}
          showGrid={showGrid}
          showLegends={showLegends}
          showAxisLines={showAxisLines}
        />
      );

    default:
      return (
        <PieChart
          width={"100%"}
          height={"100%"}
          data={data}
          labelKey={labelKey}
          valueKey={valueKey}
          radius={"85%"}
          isLoading={isLoading}
          selectedIndex={selectedIndex}
          onSelectedIndexChange={onSelectedIndexChange}
          customTooltip={customTooltip}
          showLegends={showLegends}
        />
      );
  }
};
