import * as React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadRoot } from "./TableHeadRoot";
import { TableToolbarRoot } from "./TableToolbarRoot";
import CustomCheckBox from "../../library/base/custom-checkbox";
import { TableRowRoot } from "./TableRowRoot";
import { TableCellRoot } from "./TableCellRoot";
import { ActionModal } from "../modal";
import { Pagination } from "../pagination";
// @ts-ignore
import NoDataImage from "assets/images/noData.svg";
import { Skeleton, SxProps, Theme, Typography } from "@mui/material";
import { StyledTableCell } from "./StyledTableCell";
import { useCallback, useEffect } from "react";

type HeaderProps = {
  name: string;
  sortable?: boolean;
  key: string;
  numeric?: boolean;
};
interface CellProps {
  sx: SxProps<Theme> | undefined;
}
interface DataTableProps extends BoxProps {
  containerStyles?: BoxProps;
  tableStyles?: SxProps<Theme>;
  headers: Array<HeaderProps>;
  data: Array<any>;
  totalPages?: number;
  totalItems?: number;
  onItemClick?: (id: string, meta?: any) => void;
  onPerPageChange?: (itemsPerPage: number) => void;
  onAllSelect?: (currentState: "checked" | "unchecked") => void;
  /**
   *  just the id of selected items (only parent)
   */
  selectedItems: Array<string>;
  /**
   * this is the array of selected data (parent + child)
   */
  selectedData?: Array<any>;
  /**
   * fn to set the selected data into a state in parent component
   */
  setSelectedData?: React.Dispatch<React.SetStateAction<any>>;
  actionContent?: React.ReactNode;
  customActionModal?: React.ReactNode;
  headerToolbar?: React.ReactNode;
  onPagination?: (pageNo: number) => void;
  currentPageNumber: number;
  itemsPerPage: number;
  handleRowClick?: (rowData: any, parentRowData?: any) => void;
  showPagination?: boolean;
  showActionToolbar?: boolean;
  onSortingClick?: (column: string) => void;
  multiselect?: boolean;
  cellBuilders?: Record<
    string,
    (rowData?: any, index?: number) => React.ReactNode
  >;
  headerCellBuilders?: Record<string, (headerdata?: any) => React.ReactNode>;
  isDataLoading?: boolean;
  cellProps?: Record<string, CellProps>;
  rowStyles?: SxProps<Theme>;
  isCheckboxDisabled?: (rowData: any) => boolean;
  headerCellStyles?: SxProps<Theme>;
  isExpandableTable?: boolean;
  childRowKeys?: string[]; // array of keys of for child rows
  childRowHeaders?: Record<string, Array<HeaderProps>>;
  showNoData?: boolean;
  uniqueKey?: string;
  pageNationStyles?: SxProps<Theme>;
}
export const DataTable = ({
  containerStyles,
  tableStyles,
  headers,
  data,
  totalPages,
  totalItems,
  onItemClick,
  onAllSelect,
  selectedItems,
  selectedData,
  setSelectedData,
  headerToolbar,
  actionContent,
  customActionModal,
  onPagination,
  currentPageNumber,
  itemsPerPage,
  handleRowClick,
  showPagination = true,
  showActionToolbar = true,
  onSortingClick,
  multiselect = true,
  cellBuilders,
  isDataLoading = false,
  rowStyles,
  cellProps,
  isCheckboxDisabled,
  onPerPageChange,

  headerCellStyles,
  isExpandableTable,
  childRowKeys,
  childRowHeaders,
  showNoData = true,
  uniqueKey = "mapping_id",
  pageNationStyles,
  headerCellBuilders,
  ...rest
}: DataTableProps) => {
  const theme = useTheme();
  // const [selectedData, setSelectedData] = React.useState<Array<any>>([]);
  const handleCheckboxClick = ({
    isParent,
    parent,
    child,
    childKey,
  }: {
    isParent: boolean;
    parent: Record<string, any>;
    child?: Record<string, any>;
    childKey?: string;
  }) => {
    if (!setSelectedData) return;
    // if is parent and parent exist in selected data ---> remove full item
    // else add full item and all children data to selected array
    if (isParent) {
      setSelectedData((prev: Array<Record<string, any>>) => {
        const newPrev = [...prev];
        const index = newPrev.findIndex(
          (item) => item[uniqueKey] === parent[uniqueKey]
        );
        if (index >= 0) {
          newPrev.splice(index, 1);
        } else {
          newPrev.push(parent);
        }

        return newPrev;
      });
    } else {
      setSelectedData((prev: Array<Record<string, any>>) => {
        const newPrev = [...prev];
        const index = newPrev.findIndex(
          (item) => item[uniqueKey] === parent[uniqueKey]
        );
        if (index >= 0) {
          if (child && childKey) {
            // check if child already exist
            // FIXME: change mapping_id to unique child keys
            const childIndex = newPrev[index][childKey].findIndex(
              (item: Record<string, any>) =>
                item.mapping_id === child.mapping_id
            );
            if (childIndex >= 0) {
              newPrev[index][childKey].splice(childIndex, 1);
            } else {
              newPrev[index][childKey] = [...newPrev[index][childKey], child];
            }
          }
        } else {
          let newParent = { ...parent };
          childRowKeys?.map((key) => {
            newParent[key] = [];
          });
          if (child && childKey) {
            newParent = { ...newParent, [childKey]: [child] };
          }
          newPrev.push(newParent);
        }
        return newPrev;
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          overflow: "scroll",
          height: {
            xs: "calc(100vh - 120px)",
            sm: "calc(100vh - 120px)",
            md: "calc(100vh - 140px)",
            lg: "calc(100vh - 140px)",
          },
          width: "100%",
          position: "relative",
          borderTop: `1px solid ${theme.palette.neutral["015"]}`,
          borderBottom: `1px solid ${theme.palette.neutral["015"]}`,
          "&:: -webkit-scrollbar:horizontal": { display: "none" },
          ...containerStyles,
        }}
        {...rest}
      >
        {headerToolbar && (
          <TableToolbarRoot
            sx={{
              position: "sticky",
              top: 0,
              right: 0,
              left: 0,
              zIndex: 10,
              bgcolor: "#FFF",
            }}
          >
            {headerToolbar}
          </TableToolbarRoot>
        )}
        {showPagination && onPagination && (
          <Pagination
            currentPage={currentPageNumber}
            onPaginationClick={(page) => {
              onPagination(page);
              onAllSelect && onAllSelect("unchecked");
            }}
            isDisabled={selectedItems.length > 0}
            totalPages={totalPages}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPerPageChange={onPerPageChange}
            pageNationStyles={pageNationStyles}
          />
        )}
        <TableContainer
          sx={{
            "&:: -webkit-scrollbar:horizontal": {
              display: "block",
            },
            scrollbarWidth: "thin",
            overflowX: "auto",
            borderBottom: "none",
            height: headerToolbar ? "calc(100% - 120px)" : "100%",
            // width: "100%",
            minWidth: 0,
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750, ...tableStyles }}
            aria-labelledby="tableTitle"
          >
            <TableHeadRoot
              headers={headers}
              multiselect={multiselect}
              onAllSelect={(status) => onAllSelect && onAllSelect(status)}
              onSortingClick={onSortingClick}
              headerCellStyles={headerCellStyles}
              headerCellBuilders={headerCellBuilders}
              cellProps={cellProps}
            />
            {!isDataLoading && data?.length === 0 && showNoData && (
              <TableCell
                sx={{ height: "100%", border: "0px" }}
                colSpan={headers.length}
              >
                <Box
                  sx={{
                    mt: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={NoDataImage}
                    style={{ width: "10%", height: "20%" }}
                  />
                  <Typography sx={{}} variant="body-large">
                    No Data Found
                  </Typography>
                </Box>
              </TableCell>
            )}

            {isDataLoading ? (
              <TableBody>
                {[...Array(itemsPerPage)].map((value) => (
                  <TableRowRoot key={value}>
                    {[{ value: "checkbox", key: "checkbox" }, ...headers].map(
                      (headerItem) => {
                        return (
                          <StyledTableCell
                            key={headerItem.key}
                            sx={{
                              padding: "6px 16px",
                              borderBottom: "0px",
                              ...cellProps?.[headerItem.key]?.sx,
                            }}
                          >
                            <Typography component="div" variant={"body-large"}>
                              <Skeleton
                                variant="text"
                                // width={210}
                                animation="wave"
                                sx={{
                                  maxWidth: "200px",
                                  bgcolor: theme.palette.neutral["015"],
                                }}
                              />
                            </Typography>
                          </StyledTableCell>
                        );
                      }
                    )}
                  </TableRowRoot>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {data
                  ? data.map((row, rowIndex) => {
                      // const isItemSelected = selectedItems?.includes(
                      //   row[uniqueKey]
                      // );

                      return (
                        <CustomRowGroup
                          key={row[uniqueKey]}
                          row={row}
                          rowIndex={rowIndex}
                          // isItemSelected={isItemSelected}
                          headers={headers}
                          onItemClick={onItemClick}
                          handleRowClick={handleRowClick}
                          multiselect={multiselect}
                          cellBuilders={cellBuilders}
                          rowStyles={rowStyles}
                          cellProps={cellProps}
                          isCheckboxDisabled={isCheckboxDisabled}
                          isExpandableTable={isExpandableTable}
                          childRowKeys={childRowKeys}
                          childRowHeaders={childRowHeaders}
                          handleCheckboxClick={handleCheckboxClick}
                          uniqueKey={uniqueKey}
                          selectedItems={selectedItems}
                          selectedData={selectedData || []}
                        />
                      );
                    })
                  : null}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {showActionToolbar &&
          (customActionModal ? (
            customActionModal
          ) : (
            <ActionModal
              selectedCount={selectedItems.length}
              open={selectedItems?.length > 0 ? true : false}
              actionContent={actionContent}
            />
          ))}
      </Box>
    </>
  );
};

interface RowDataProps {
  row: Record<string, any>;
  rowIndex: number;
  parentRow?: Record<string, any>;
  headers: Array<HeaderProps>;
  onItemClick?: (id: string, isChildRow?: boolean, meta?: any) => void;
  handleRowClick?: (rowData: any) => void;
  multiselect?: boolean;
  cellBuilders?: Record<
    string,
    (rowData?: any, index?: number) => React.ReactNode
  >;
  isDataLoading?: boolean;
  cellProps?: Record<string, CellProps>;
  rowStyles?: SxProps<Theme>;
  isCheckboxDisabled?: (rowData: any) => boolean;
  headerCellStyles?: SxProps<Theme>;
  isExpandableTable?: boolean;
  childRowKeys?: string[]; // array of keys of for child rows
  uniqueKey?: string;
}
const CustomRowGroup = ({
  selectedItems,
  selectedData,
  row,
  rowIndex,
  headers,
  onItemClick,
  handleRowClick,
  multiselect = true,
  cellBuilders,
  rowStyles,
  cellProps,
  isCheckboxDisabled,
  isExpandableTable,
  childRowKeys,
  childRowHeaders,
  handleCheckboxClick,
  uniqueKey = "mapping_id",
}: RowDataProps & {
  selectedItems: Array<string>;
  selectedData: Array<Record<string, any>>;
  isExpandableTable?: boolean;
  childRowKeys?: string[];
  childRowHeaders?: Record<string, HeaderProps[]>;
  handleCheckboxClick?: ({
    isParent,
    parent,
    child,
    childKey,
  }: {
    isParent: boolean;
    parent: Record<string, any>;
    child?: Record<string, any>;
    childKey?: string;
  }) => void;
}) => {
  const [showChildren, setShowChildren] = React.useState(false);
  const [isItemSelected, setIsItemSelected] = React.useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = React.useState<
    number | null
  >(null);

  useEffect(() => {
    if (isExpandableTable) {
      const index = selectedData.findIndex(
        (item) => item[uniqueKey] === row[uniqueKey]
      );
      if (index > -1) {
        setIsItemSelected(true);
        setSelectedItemIndex(index);
      } else {
        setIsItemSelected(false);
        setSelectedItemIndex(null);
      }
    } else {
      if (selectedItems.includes(row[uniqueKey])) {
        setIsItemSelected(true);
      } else {
        setIsItemSelected(false);
      }
    }
  }, [selectedData, selectedItems]);

  const handleItemClick = () => {
    isExpandableTable
      ? setShowChildren((prev) => !prev)
      : handleRowClick && handleRowClick(row);
  };
  const onCheckboxClick = ({
    isParent,
    parent,
    child,
    childKey,
    id,
    isChildRow,
    meta,
  }: {
    isParent: boolean;
    parent: Record<string, any>;
    child?: Record<string, any>;
    childKey?: string;
    id: string;
    isChildRow?: boolean;
    meta?: any;
  }) => {
    if (isExpandableTable) {
      handleCheckboxClick &&
        handleCheckboxClick({ isParent, parent, child, childKey });
    } else {
      onItemClick && onItemClick(id, meta);
    }
  };

  const isChildSelected = ({
    childRowKey,
    childRow,
  }: {
    childRow: Record<string, any>;
    childRowKey: string;
  }) => {
    if (isItemSelected && selectedItemIndex !== null) {
      const index = selectedData[selectedItemIndex]?.[childRowKey].findIndex(
        (item: Record<string, any>) => item.mapping_id === childRow.mapping_id
      );
      if (index > -1) {
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <CustomRow
        key={row[uniqueKey]}
        row={row}
        rowIndex={rowIndex}
        isItemSelected={isItemSelected}
        headers={headers}
        onItemClick={(id, isChildRow, meta) => {
          onCheckboxClick({
            isParent: true,
            id,
            isChildRow,
            meta,
            parent: row,
          });
        }}
        handleRowClick={handleItemClick}
        multiselect={multiselect}
        cellBuilders={cellBuilders}
        rowStyles={rowStyles}
        cellProps={cellProps}
        isCheckboxDisabled={isCheckboxDisabled}
        uniqueKey={uniqueKey}
      />
      {showChildren &&
        isExpandableTable &&
        childRowKeys &&
        childRowKeys?.length > 0 &&
        childRowKeys.map((childRowKey: string) =>
          row[childRowKey]?.map(
            (childRow: Record<string, any>, childRowIndex: number) => {
              // const isChildSelected =
              //   isItemSelected &&
              //   selectedItemIndex &&
              //   selectedData[selectedItemIndex]?.[childRowKey].findIndex(
              //     (item: Record<string, any>) =>
              //       item.mapping_id === childRow.mapping_id
              //   ) > -1
              //     ? true
              //     : false;
              return (
                <CustomRow
                  key={childRow[uniqueKey]}
                  row={childRow}
                  rowIndex={childRowIndex}
                  parentRow={row}
                  isItemSelected={
                    isChildSelected({
                      childRowKey,
                      childRow,
                    })
                      ? true
                      : false
                  }
                  headers={
                    childRowHeaders && childRowHeaders[childRowKey]
                      ? childRowHeaders[childRowKey]
                      : headers
                  }
                  onItemClick={(id, isChildRow, meta) => {
                    onCheckboxClick({
                      isParent: false,
                      id,
                      isChildRow,
                      meta,
                      parent: row,
                      child: childRow,
                      childKey: childRowKey,
                    });
                  }}
                  handleRowClick={handleRowClick}
                  multiselect={multiselect}
                  cellBuilders={cellBuilders}
                  rowStyles={{
                    ...rowStyles,
                    ...(!showChildren
                      ? { display: "none", backgroundColor: "red !important" }
                      : {}),
                  }}
                  cellProps={cellProps}
                  isCheckboxDisabled={isCheckboxDisabled}
                  isChildRow
                  uniqueKey={uniqueKey}
                />
              );
            }
          )
        )}
    </>
  );
};
const CustomRow = ({
  row,
  rowIndex,
  isItemSelected,
  headers,
  onItemClick,
  handleRowClick,
  multiselect = true,
  cellBuilders,
  rowStyles,
  cellProps,
  isCheckboxDisabled,
  isChildRow,
  parentRow,
  uniqueKey = "mapping_id",
}: RowDataProps & { isChildRow?: boolean; isItemSelected: boolean }) => {
  const theme = useTheme();
  return (
    <TableRowRoot
      hover
      onClick={(e) => {
        // @ts-ignore
        isChildRow ? handleRowClick?.(row, parentRow) : handleRowClick?.(row);
      }}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row[uniqueKey]}
      sx={{
        backgroundColor: isItemSelected
          ? `${theme.palette.secondary.main}`
          : "transparent",
        "&.MuiTableRow-root": {},
        height: "48px",
        ...rowStyles,
      }}
    >
      {multiselect ? (
        <StyledTableCell
          sx={{
            paddingY: 0,
            borderBottom: "0px",
            paddingLeft: "16px",
            backgroundColor: isChildRow
              ? theme.palette.neutral["B-005"]
              : "inherit",
          }}
        >
          {!isCheckboxDisabled?.(row) ? (
            <CustomCheckBox
              checked={isItemSelected}
              onClick={(e) => {
                e.stopPropagation();
                onItemClick?.(row[uniqueKey], isChildRow, row);
              }}
            />
          ) : (
            <></>
          )}
        </StyledTableCell>
      ) : null}

      {headers.map((headerItem) => {
        if (cellBuilders && cellBuilders[headerItem.key]) {
          return (
            <StyledTableCell
              key={headerItem.key}
              sx={{
                borderBottom: "0px",
                ...cellProps?.[headerItem.key]?.sx,
                backgroundColor: isChildRow
                  ? theme.palette.neutral["B-005"]
                  : "inherit",
              }}
            >
              {cellBuilders[headerItem.key](row, rowIndex)}
            </StyledTableCell>
          );
        } else {
          return (
            <TableCellRoot
              key={headerItem.key}
              sx={{
                padding: "6px 16px",
                borderBottom: "0px",
                ...cellProps?.[headerItem.key]?.sx,
                backgroundColor: isChildRow
                  ? theme.palette.neutral["B-005"]
                  : "inherit",
              }}
              value={row[`${headerItem.key}`] || " "}
              type={headerItem.key}
            >
              {"-"}
            </TableCellRoot>
          );
        }
      })}
    </TableRowRoot>
  );
};
