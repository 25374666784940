import React, { useEffect, useState } from "react";

// Legacy Imports
import Box from "@mui/material/Box";

import { useGlobalStore } from "controller/store/globalStore";
import toast from "react-hot-toast";
import { Button, DataTable, DeleteConfirmationModal } from "components";
import { Stack, useTheme } from "@mui/material";
import {
  useDeleteBulkResourceGroups,
  useFetchAllResourceGroups,
  useFetchResourceGroupByIdUsingMutation,
} from "controller";
import { getFullName } from "utilz/helpers";
import { EditResourceGroupModal, ResourcesGroupHeadMount } from "./component";

const headers = [
  {
    name: "Name",
    sortable: false,
    key: "group_name",
  },
  {
    name: "Description",
    sortable: false,
    key: "group_description",
  },
];

export const ResourcesAndPermissionsPageResourceGroupsTab = () => {
  // constructor(props) {
  //     super(props);
  //     formRef = React.createRef();
  //     state =
  // }
  const theme = useTheme();
  const mspId = useGlobalStore((state) => state.mspId);

  const [modal, setModal] = useState({
    open: false,
    selectedData: {},
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [resourceGroups, setResourceGroups] = useState<Array<any>>([]);
  const { mutateAsync: fetchResourceGroupById } =
    useFetchResourceGroupByIdUsingMutation();

  const { mutateAsync: deleteBulkResourceGroup } =
    useDeleteBulkResourceGroups();
  const { data: resourceGroupResp, isLoading: isResourceGroupLoading } =
    useFetchAllResourceGroups({
      filter: [],
      pageNumber: currentPage,
      perPageCount: 50,
      searchQuery: searchQuery,
      sortColumn: "group_name",
      sortDirection: "asc",
    });
  const [checkedItems, setCheckedItem] = useState<Array<any>>([]);

  useEffect(() => {
    if (Array.isArray(resourceGroupResp?.result)) {
      setResourceGroups(
        resourceGroupResp?.result.map((item: any) => ({
          mapping_id: item["mapping_id"] || "",
          group_name: item["group_name"] || "",
          group_description: item["group_description"] || "",
          disableSelection: false,
        }))
      );
    }
  }, [resourceGroupResp]);

  // API Calls

  const fetchResourceGroupByIdHandler = async (id: string) => {
    try {
      const data = await fetchResourceGroupById(id);
      openModal({
        mapping_id: data["mapping_id"] || "",
        group_name: data["group_name"] || "",
        group_description: data["group_description"] || "",
        group_owner: data["group_owner"] || "",
        group_members: data["group_members"]
          ? data["group_members"].map((option: any) => ({
              key: `resource-and-permission-resource-type-${option["resource_id"]}-key`,
              value: option["resource_id"],
              profile: option["profile_image"],
              subLabel: option["resource_work_roles"],
              label: `${getFullName(
                option?.["first_name"] || "",
                option?.["middle_name"] || "",
                option?.["last_name"] || ""
              )}`,
            }))
          : [],
      });
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to fetch selected resource group .try again later"
      );
    }
  };

  const deleteResourceGroups = async (data: any) => {
    try {
      await deleteBulkResourceGroup({ mspId, data });
      toast.success("Resource group is deleted");
      setIsDeleteModalOpen(false);
    } catch (e: any) {
      toast.error(
        e?.message || "Unable to delete resource group .try again later"
      );
    }
  };

  // Event Handlers
  const openModal = (data: any) => {
    setModal({
      open: true,
      selectedData: data,
    });
  };
  const closeModal = () => {
    setModal({
      open: false,
      selectedData: {},
    });
  };

  const onDeleteHandler = () => {
    const data = checkedItems;

    setCheckedItem(() => []);
    if (Array.isArray(data) && data.length > 0) {
      const deleteData = {
        resource_groups: data,
      };
      deleteResourceGroups(deleteData);
    }
  };

  const onAllSelectHandler = (checked: string) => {
    if (checked === "checked") {
      return setCheckedItem(() => [
        ...(Array.isArray(resourceGroups)
          ? resourceGroups.map((value) => value?.mapping_id || "")
          : []),
      ]);
    }
    setCheckedItem(() => []);
  };

  const onItemClickHandler = (id: string) => {
    const result = checkedItems.filter((item) => item !== id);
    if (result.length < checkedItems.length) {
      return setCheckedItem(() => [...result]);
    }
    setCheckedItem(() => [...checkedItems, id]);
  };

  return (
    <React.Fragment>
      {modal.open && (
        <EditResourceGroupModal
          open={modal.open}
          onClose={closeModal}
          selectedResourceGroup={modal.selectedData}
          isEdit
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          open={isDeleteModalOpen}
          onPrimaryButtonClick={onDeleteHandler}
          onSecondaryButtonClick={() => setIsDeleteModalOpen(false)}
          title="Delete Resource Groups"
          tagline={"Are you sure you want to delete resource groups"}
        />
      )}
      <Stack sx={{ margin: "24px 32px 96px 32px" }} gap={"16px"}>
        <ResourcesGroupHeadMount
          searchData={searchQuery}
          onUpdateFilter={setSearchQuery}
        />
        <Box sx={{ width: "100%" }}>
          <DataTable
            // cellBuilders={cellBuilders}
            multiselect={true}
            isDataLoading={isResourceGroupLoading}
            showPagination
            headers={headers}
            data={resourceGroups}
            onItemClick={onItemClickHandler}
            onAllSelect={onAllSelectHandler}
            selectedItems={checkedItems}
            currentPageNumber={currentPage}
            totalPages={resourceGroupResp?.totalPages || 0}
            totalItems={resourceGroupResp?.totalCount || 0}
            itemsPerPage={50}
            handleRowClick={(rowData) => {
              fetchResourceGroupByIdHandler(rowData?.mapping_id);
            }}
            onPagination={(pageNo) => {
              setCurrentPage(pageNo);
            }}
            actionContent={
              <Button
                variant="primary"
                sx={{
                  bgcolor: theme.palette.error.main,
                  "&:hover": {
                    bgcolor: theme.palette.error.main,
                  },
                }}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </Button>
            }
          />
        </Box>
      </Stack>
    </React.Fragment>
  );
};
