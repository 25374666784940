import React, { useEffect, useState } from "react";
import { BadgeBase, BadgeProps } from "./BadgeBase";
import useTheme from "@mui/material/styles/useTheme";
import {
  AssignedIcon,
  CheckBadgeFilledIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  ClockFilledIcon,
  HammerIcon,
  UserFilledIcon,
} from "components/icons";
import { Typography } from "@mui/material";

interface StatusProps extends BadgeProps {
  value?: string;
  id?: string;
}
export const StatusBadge = ({
  id,
  value: inputValue,
  ...rest
}: StatusProps) => {
  const theme = useTheme();
  const defaultData = {
    value: inputValue || "New",
    backgroundColor: "#E9FCFF",
    backgroundMode: "light",
    borderColor: theme.palette.purple.hover,
    textColor: theme.palette.purple.hover,
    icon: (
      <CheckBadgeFilledIcon
        fontSize="small"
        htmlColor={theme.palette.purple.hover}
      />
    ),
  };
  const [setstatusData, setSetstatusData] = useState<any>(defaultData);

  useEffect(() => {
    if (id) {
      const statusData = StatusBadgeArray.find((item) => item.id === id);
      statusData ? setSetstatusData(statusData) : setSetstatusData(defaultData);
    } else if (inputValue) {
      const statusData = StatusBadgeArray.find(
        (item) => item.value === inputValue
      );
      statusData ? setSetstatusData(statusData) : setSetstatusData(defaultData);
    }
  }, [id, inputValue]);

  const StatusBadgeArray = [
    {
      id: "c302d5e0-176c-4396-9f2c-e6742af81a43",
      value: "Pending",
      backgroundColor: theme.palette.warning.tint,
      borderColor: theme.palette.warning.main,
      textColor: "#D35800",
      backgroundMode: "dark",
      icon: <ClockFilledIcon fontSize="small" htmlColor="#D35800" />,
    },
    {
      id: "789c8882-2f6b-495c-ad91-3c9bcbbcc3c7",
      value: "Assigned",
      backgroundColor: "#E7E9FE",
      borderColor: "#907BFF",
      textColor: "#907BFF",
      backgroundMode: "dark",
      icon: <UserFilledIcon fontSize="small" htmlColor="#907BFF" />,
    },
    {
      id: "e246583c-8714-4c90-a7cc-e3e2d576e780",
      value: "In Progress",
      backgroundColor: "#E7E9FE",
      borderColor: theme.palette.purple.hover,
      textColor: theme.palette.purple.hover,
      backgroundMode: "dark",
      icon: (
        <HammerIcon fontSize="small" htmlColor={theme.palette.purple.hover} />
      ),
    },
    {
      id: "da5be1f9-db9d-4c64-8e43-51b458746773",
      value: "Closed",
      backgroundColor: theme.palette.success.tint,
      borderColor: theme.palette.success.main,
      textColor: theme.palette.success.main,
      backgroundMode: "dark",
      icon: (
        <CheckCircleIcon
          fontSize="small"
          htmlColor={theme.palette.success.main}
        />
      ),
    },
    {
      id: "9dd09ea5-e30e-494a-9120-583caff52a3a",
      value: "New",
      backgroundColor: "#E9FCFF",
      backgroundMode: "light",
      borderColor: "#008DCA",
      textColor: "#008DCA",
      icon: <CheckBadgeFilledIcon fontSize="small" htmlColor="#008DCA" />,
    },
    {
      id: "caf3b9ab-08a5-4014-938c-aacc021f043b",
      value: "Merged",
      backgroundColor: "#F5E755",
      backgroundMode: "light",
      icon: (
        <CheckBadgeFilledIcon
          fontSize="small"
          htmlColor={theme.palette.lightBg.high}
        />
      ),
    },
    {
      id: "953b24ef-2167-49f9-88d9-a06cca165753",
      value: "Cancelled",
      backgroundColor: "#8791F8",
      backgroundMode: "dark",
      icon: (
        <CheckBadgeFilledIcon
          fontSize="small"
          htmlColor={theme.palette.lightBg.high}
        />
      ),
    },
    {
      id: "30f434a9-4e66-4bea-86e3-86bb945035b3",
      value: "Reopened",
      backgroundColor: "#BAF4FC",
      backgroundMode: "light",
      icon: (
        <CheckBadgeFilledIcon
          fontSize="small"
          htmlColor={theme.palette.lightBg.high}
        />
      ),
    },
    
  ];

  return (
    <BadgeBase
      {...rest}
      backgroundColor={setstatusData.backgroundColor}
      backgroundMode={setstatusData.backgroundMode}
      leadingIcon={setstatusData.icon}
      containerStyles={{
        // border: `1px solid ${setstatusData.borderColor}`,
        minWidth: "84px",
        justifyContent: "left",
        padding: "4px 12px 4px 8px",
        borderRadius: "4px",
      }}
    >
      <Typography
        variant="caption-xs"
        sx={{ color: `${theme.palette.lightBg.high} !important` }}
      >
        {setstatusData.value}
      </Typography>
    </BadgeBase>
  );
};
