import { Box, Stack, Typography, useTheme } from "@mui/material";
import comingSoon from "assets/images/comingSoonBg.png"; //comingSoon
import requestBg from "assets/images/requestBg.png"; //request
import projectBg from "assets/images/projectBg.png";
import calendarBg from "assets/images/calendarBg.png";
import broadcastBg from "assets/images/broadcastBg.png";
import integrationBg from "assets/images/integrationBg.png";
import brandingBg from "assets/images/brandingBg.png";
import integration from "assets/images/integration.png";
import branding from "assets/images/branding.png";
import thinkingIcon from "assets/images/thinkingIcon.png";
import integrationNew from "assets/images/integrationNew.png";
import question from "assets/images/question.png";
import { Button } from "components/button";
import { RightArrowIcon } from "components/icons";
import { Input } from "components/input";
import { Avatar } from "components/avatar";
export const ComingSoonPage = ({ page = "request" }: { page: string }) => {
  const theme = useTheme();

  const pageDetailsList: Record<string, any> = {
    request: {
      title: "Accelerate your requests.",
      subtitle:
        "Streamline your requests through a dedicated tab now, where you can create, validate, and track your requirements without any slowdown.",
      isVideo: true,
      contentBg: requestBg,
      url: "https://deskday-public.s3.amazonaws.com/Requests.mp4",
      coversation: [
        "Hey, getting our requests validated & accepted on time using these legacy tools is becoming unbearable! It’s slowing our work down big time!",
        "You’re telling me! And the approval process is adding to the frustration. It’s like we hit roadblocks every time because they keep insisting on more proof of benefits.",
        "And don’t get me started on the inefficient tracking! It’s nearly impossible to accurately monitor the status of requests, leading to delays and miscommunications",
        "Seriously! We can’t keep working like this, it’s affecting our productivity and service delivery",
      ],

      feedbackUrl:
        "https://deskday.canny.io/feature-requests/p/service-desk-requests-projects",
    },

    project: {
      title: "Powering your projects.",
      subtitle:
        "Make the most of your resources and Speed up your projects using our advanced Project Management feature.",
      isVideo: true,
      contentBg: projectBg,
      url: "https://deskday-public.s3.amazonaws.com/Coming+Soon+Vids/Projects.mp4",
      coversation: [
        "PSA's handling of projects is confusing, impacting task management and tracking processes.",
        "True, the Project Management tools are challenging to navigate and utilize, leading to a backlog.",
        "Even tracking time accurately on various projects is an uphill task, making it tough to bill users.",
        "Yes, the tool also lacks timely progress updates. We are in the dark when it comes to reviewing tasks.",
      ],
      feedbackUrl:
        "https://deskday.canny.io/feature-requests/p/service-desk-requests-projects",
    },

    calendar: {
      title: "Time-track every task",
      subtitle:
        "Enhance your productivity by organizing your team's tasks with the help of an advanced Calendar and efficient Timesheets.",
      isVideo: true,
      contentBg: calendarBg,
      url: "https://deskday-public.s3.amazonaws.com/Coming+Soon+Vids/Calandar.mp4",
      coversation: [
        "I’ve been feeling overwhelmed with keeping track of tickets, managing my billable time, and constantly updating timesheets. It’s becoming a real tiring task!",
        "It feels like we spend more time managing these things than actually doing our work. The approvals of updated timesheets are another layer of hassle on top of everything.",
        "Seriously! The current system is so cumbersome, it’s affecting our productivity and focus on our core tasks.",
        "A more automated and integrated system would save us so much time and energy. We need a solution that can accurately track our work and billable time without us having to manually update everything.",
      ],
      feedbackUrl:
        "https://deskday.canny.io/feature-requests/p/service-desk-calendar-timesheets",
    },

    broadcast: {
      title: "Reach your users effortlessly.",
      subtitle:
        "Revolutionize your communication strategy with our cutting-edge Broadcast Messaging feature, enabling you to engage with your users seamlessly.",
      isVideo: true,
      contentBg: broadcastBg,
      url: "https://deskday-public.s3.amazonaws.com/BM2.mp4",
      coversation: [
        "Delivering messages within specific groups can be complex, especially for crucial information.",
        "Yes, missing critical announcements can lead to issues if not careful. The key is to ensure everyone is in the loop.",
        "Moreover, figuring out who has received the message, who's aligned, and who has input is a next-level challenge.",
        "Precisely, it's like sending out messages and then being left in the dark, without any delivery confirmation.",
      ],
      feedbackUrl: "https://deskday.canny.io/feature-requests/p/broadcasting",
    },

    integrations: {
      title: "Unifying seamlessly.",
      subtitle:
        "Navigate through complexities for seamless integration across tools, unlocking enhanced productivity.",
      isVideo: false,
      url: integration,
      contentBg: integrationBg,
      coversation: [
        "Integrating tools into the PSA is complex, with data inconsistencies that distort information.",
        "It's frustrating when data fails to sync across platforms.",
        "The distinct communication methods of tools add a layer of challenge to achieve seamless integration.",
        "Finding integrations that support all our needs is a struggle, but the eventual payoff will be worth it.",
      ],
      feedbackUrl:
        "https://deskday.canny.io/feature-requests/p/ai-integration-with-service-desk",
    },

    branding: {
      title: "Augmenting best of experiences.",
      subtitle:
        "Crafting an enriching user experience by stitching together branding elements throughout the user interface.",
      isVideo: false,
      contentBg: brandingBg,
      url: branding,
      coversation: [
        "Hey! I think we really need to consider white-labeling to provide a more uniform and cohesive experience to all our users. What do you think?",
        "Absolutely. A uniform experience is crucial for brand consistency and user satisfaction. White-labeling could indeed be the key to aligning the users with our brand identity.",
        "Exactly! It’s all about creating a seamless and branded experience. It can also help in building user trust and loyalty.",
        "You’re spot on! It would also allow us to have more control over the app's customization, ensuring that it meets our users' needs and expectations.",
      ],
      feedbackUrl:
        "https://deskday.canny.io/feature-requests/p/white-labelling",
    },
  };
  const pageDetails = pageDetailsList[page];

  return pageDetails === undefined ? (
    <></>
  ) : (
    <Stack width={"100%"} height={"100%"}>
      <Box width={"100%"} bgcolor={"#10172E"} px={"53px"} py={"10px"}>
        <Typography variant={"caption-2"} color={theme.palette.neutral.main}>
          COMING SOON...
        </Typography>
      </Box>
      <Stack
        direction={"row"}
        width={"100%"}
        flex={1}
        sx={{
          backgroundImage: "url(\"" + comingSoon + "\")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          p: "17px",
        }}
      >
        {/* left side */}
        <Stack
          flex={1}
          minHeight={"100%"}
          position={"relative"}
          py={"20px"}
          sx={{
            overflowY: "auto",
            justifyContent: "center",
          }}
        >
          <Stack width={"70%"} maxWidth={"400px"} pl={"27px"} gap={"42px"}>
            <Stack gap={"20px"}>
              {pageDetails.coversation.map((item: string, index: number) => (
                <Box
                  key={`conversation-${index}`}
                  width={"70%"}
                  p={"17px 8px 11px 16px"}
                  alignSelf={index % 2 === 0 ? "end" : "start"}
                  bgcolor={
                    index % 2 === 0
                      ? theme.palette.neutral.main
                      : theme.palette.secondary.main
                  }
                  borderRadius={"8px"}
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    filter: "drop-shadow(0px 4px 10px 0px rgba(0, 0, 0, 0.25))",
                    position: "relative",
                  }}
                >
                  {index === 1 && (
                    <Box
                      width="127px"
                      height="127px"
                      sx={{
                        position: "absolute",
                        top: "-114px",
                        left: "-28px",
                        zIndex: -1,
                      }}
                    >
                      <img
                        src={question}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: "20px" }}
                      />
                    </Box>
                  )}
                  {index % 2 === 0 && (
                    <Avatar
                      src={thinkingIcon}
                      sx={{
                        p: "4px",
                        border: `1px solid ${theme.palette.primary.main}`,
                        bgcolor: theme.palette.neutral.main,
                        position: "absolute",
                        top: "-17px",
                        right: "11px",
                      }}
                    />
                  )}
                  <Typography variant="body-medium">{item}</Typography>
                </Box>
              ))}

              {/* <Box
                width={"70%"}
                p={"17px 8px 11px 16px"}
                alignSelf={"start"}
                bgcolor={theme.palette.secondary.main}
                borderRadius={"8px"}
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  filter: "drop-shadow(0px 4px 10px 0px rgba(0, 0, 0, 0.25))",
                  position: "relative",
                }}
              >
                <Box
                  width="127px"
                  height="127px"
                  sx={{
                    position: "absolute",
                    top: "-114px",
                    left: "-28px",
                    zIndex: -1,
                  }}
                >
                  <img
                    src={question}
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "20px" }}
                  />
                </Box>

                <Typography variant="body-medium">
                  Getting a laptop has become a struggle now! The process just
                  can’t get more confusing.
                </Typography>
              </Box> */}
            </Stack>
            <Stack
              bgcolor={"white"}
              borderRadius={"8px"}
              border={`1px solid ${theme.palette.primary.main}`}
              overflow={"clip"}
              onClick={() => {
                window.open(pageDetails.feedbackUrl, "_blank");
              }}
            >
              <Input
                placeholder="Thoughts...."
                name=""
                disabled={true}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                hideHelperBox={true}
              />
              <Button
                variant="primary"
                endIcon={<RightArrowIcon />}
                sx={{
                  borderRadius: "0px",
                }}
              >
                <Box width={"100%"} textAlign={"start"}>
                  Write in your suggestions
                </Box>
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {/* Right side */}

        <Stack flex={1} height={"100%"} justifyContent={"center"}>
          <Stack
            flex={2}
            minHeight={"100px"}
            maxHeight={"250px"}
            height={"100%"}
            justifyContent={"center"}
            gap={"8px"}
          >
            <Typography variant={"h3"}>{pageDetails.title}</Typography>
            <Typography variant={"body-medium"}>
              {pageDetails.subtitle}
            </Typography>
          </Stack>
          {page === "integrations" ? (
            <Box
              sx={{
                flex: 6,
                width: "70%",
                maxWidth: "400px",
                backgroundImage: "url(\"" + integrationNew + "\")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                position: "relative",
              }}
            ></Box>
          ) : (
            <Box
              sx={{
                flex: 6,
                width: "100%",
                aspectRatio: "16/9",
                backgroundImage: "url(\"" + pageDetails.contentBg + "\")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "70%",
                  aspectRatio: "16/9",
                  right: "10%",
                  top: "10%",
                  filter: "drop-shadow(16px 16px 45px rgba(0, 0, 0, 0.25))",
                  //   bgcolor: "red",
                  background: "linear-gradient(to right, #4652CD, #46CBEA)",
                  borderRadius: "23px",
                  p: "4px",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#FFF",
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                  }}
                >
                  {pageDetails.isVideo ? (
                    <video
                      src={pageDetails.url}
                      width="100%"
                      height="100%"
                      autoPlay={true}
                      controls={true}
                      style={{ borderRadius: "20px" }}
                    />
                  ) : (
                    <img
                      src={pageDetails.url}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "20px" }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
