import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Legacy Imports
import TextField from "@mui/material/TextField";

// Constants
import { TEXTFIELD_CONSTANTS } from "./constants";
import { CustomTypography } from "../../../base";
import { Box } from "@mui/material";
import { Input } from "components";
import { ThemeProvider } from "utilz/theme/ThemeProvider";

// Customization
const customStyles = {
  "& .MuiInputBase-root": {
    height: TEXTFIELD_CONSTANTS["height"],
  },
  "& .MuiOutlinedInput-root": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["DEFAULT"],
      borderRadius: TEXTFIELD_CONSTANTS?.border_radius,
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
    },
  },
  "& .MuiInputLabel-root": {
    transform: TEXTFIELD_CONSTANTS.transition,
    color: TEXTFIELD_CONSTANTS?.palette["unfocus_color"],
    "&.MuiFormLabel-filled": {
      transform: "translate(14px, -9px) scale(1)",
    },
    "&.Mui-focused": {
      color: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
      fontWeight: TEXTFIELD_CONSTANTS?.palette["bold_weight"],
      transform: "translate(14px, -9px) scale(1)",
    },
  },
};

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <Box
      sx={{
        width: "100%",
        mx: "8px",
      }}
      className={
        "typography-font-size-class-extra-small typography-font-weight-class-regular typography-font-family-class-regular"
      }
    >
      <input ref={ref} {...props}></input>
    </Box>
  );
});

const CustomTextField = (props) => {
  // Event Handlers
  const handleChange = (event) => {
    props?.changeHandler({ field: props.name, value: event.target.value });
  };

  //
  return (
    <ThemeProvider>
      <Input
        fullWidth
        name={props.name || "name"}
        label={props.label}
        value={props.value}
        onChange={handleChange}
        disabled={props.disabled}
        placeholder={props.placeholder}
        error={props.error}
        errorMessage={props.errorMessage}
        ref={props.inputRef}
        required={props.required}
        hideHelperBox={props.helperText || props.errorMessage ? false : true}
        inputProps={{ maxLength: props.maxLength }}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
    </ThemeProvider>
    // <TextField
    //   name={props.name || ""}
    //   label={
    //     props.label ? (
    //       <CustomTypography
    //         variant={isFocus === true ? "BOLD" : "REGULAR"}
    //         size="EXTRA-SMALL"
    //         content={props.label || ""}
    //       />
    //     ) : (
    //       ""
    //     )
    //   }
    //   onFocus={onFocusHandler}
    //   onBlur={onFocusHandler}
    //   type={props.type || "text"}
    //   value={value}
    //   fullWidth={true}
    //   {...(props.inputRef != null ? { inputRef: props.inputRef } : {})}
    //   disabled={props.disabled || false}
    //   required={props.required || false}
    //   error={props.error || false}
    //   helperText={props.helperText || ""}
    //   placeholder={props.placeholder || ""}
    //   sx={customStyles}
    //   InputProps={{
    //     inputComponent: CustomInput,
    //     readOnly: props.readOnly,
    //   }}
    //   onChange={handleChange}
    // />
  );
};

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.any,
  disableAutoChange: PropTypes.bool,
  helperText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
};

CustomTextField.defaultProps = {
  name: "",
  type: "",
  label: "",
  value: "",
  disableAutoChange: false,
  disabled: false,
  required: false,
  error: false,
  inputRef: null,
  helperText: "",
  placeholder: "",
  size: "medium",
  changeHandler: () => {},
  readOnly: false,
};

export default CustomTextField;
