function RecurringTaskIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.375 12L4.125 14.25L1.875 12"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.12518 12C4.12518 12.694 4.21118 13.366 4.36018 14.015"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.125 12C22.125 7.029 18.096 3 13.125 3C8.154 3 4.125 7.029 4.125 12"
        stroke="#1C1E26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 21C18.096 21 22.125 16.971 22.125 12"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75391 17.155C7.38091 19.478 10.0729 21 13.1249 21"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4962 14.8711L12.8442 12.6981V7.98511"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RecurringTaskIcon;
