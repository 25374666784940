import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createResourceGroup,
  deleteBulkResourceGroups,
  fetchAllResourceGroups,
  fetchAllResourceGroupsById,
  fetchResourceGroupById,
  updateResourceGroup,
} from "controller/api";
import { ApiErrorType, GetQueryProps } from "shared";
import { sentryLogger } from "utilz";

export const useFetchAllResourceGroupsById = (resourceId: string) => {
  return useQuery({
    queryKey: ["resourceGroup", resourceId],
    enabled: !!resourceId,
    queryFn: () => fetchAllResourceGroupsById(resourceId),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "fetchAllResourceGroupsById",
      }),
  });
};

export const useFetchAllResourceGroups = ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: [
      "resourceGroups",
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    ],

    queryFn: () =>
      fetchAllResourceGroups({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "fetchAllResourceGroups",
      }),
  });
};

export const useFetchResourceGroupById = (id: string) => {
  return useQuery({
    queryKey: ["resourceGroup", id],
    enabled: !!id,
    queryFn: () => fetchResourceGroupById(id),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "fetchResourceGroupById",
      }),
  });
};

export const useFetchResourceGroupByIdUsingMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => fetchResourceGroupById(id), {
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "fetchResourceGroupById",
      });
    },
  });
};

export const useCreateResourceGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ mspId, data }: { mspId?: string | null; data: any }) =>
      createResourceGroup({ mspId, data }),
    {
      onSuccess: (_, variable, __) => {
        queryClient.invalidateQueries(["resourceGroup"]);
        queryClient.invalidateQueries(["resourceGroups"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "createResourceGroup",
        });
      },
    }
  );
};

export const useUpdateResourceGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }: { id: string; data: any }) => updateResourceGroup(id, data),
    {
      onSuccess: (_, variable, __) => {
        queryClient.invalidateQueries(["resourceGroup", variable.id]);
        queryClient.invalidateQueries(["resourceGroups"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateResourceGroup",
        });
      },
    }
  );
};

export const useDeleteBulkResourceGroups = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ mspId, data }: { mspId?: string | null; data: any }) =>
      deleteBulkResourceGroups({
        mspId,
        data,
      }),
    {
      onSuccess: (_, variable, __) => {
        queryClient.invalidateQueries(["resourceGroup"]);
        queryClient.invalidateQueries(["resourceGroups"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "deleteBulkResourceGroups",
        });
      },
    }
  );
};
