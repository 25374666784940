export const assignedTickets = {
  specify_tag: {
    tagline:
      "Tickets not meeting the minimum tag count will be marked as failed.",
    label: "Ticket Tag",
    showDialer: true,
    dialerKey: "specify_tag_count",
  },
  estimated_end_time: {
    tagline: "Tickets without an estimated end time will be marked as failed.",
    label: "Estimated End Time",
    showDialer: false,
  },
  specify_ticket_type: {
    tagline: "Tickets without a type mentioned will be marked as failed.",
    label: "Ticket Type",
    showDialer: false,
  },
  specify_ticket_subtype: {
    tagline: "Tickets without a subtype mentioned will be marked as failed.",
    label: "Ticket Subtype",
    showDialer: false,
  },
  specify_work_type: {
    tagline:
      "If there's no work type mentioned on the tickets, they will be marked as failed.",
    label: "Work Type",
    showDialer: false,
  },
  specify_work_role: {
    tagline:
      "If there's no work role mentioned on the tickets, they will be marked as failed.",
    label: "Work Role",
    showDialer: false,
  },
  incorporate_checklist: {
    tagline:
      "If there's no checklist added to the tickets, they will be marked as failed.",
    label: "Ticket Checklist",
    showDialer: false,
  },
  specify_location: {
    tagline: "Tickets without a specified location will be marked as failed.",
    label: "Ticket Location",
    showDialer: false,
  },
  specify_budget_hours: {
    tagline: "Tickets without budget hours added will be marked as failed.",
    label: "Budget Hours",
    showDialer: false,
  },
  minimum_word_for_description: {
    tagline:
      "Tickets not meeting the minimum word count for description will be marked as failed.",
    label: "Minimum Ticket Description (words)",
    showDialer: true,
    dialerKey: "description_word_count",
  },
  attachments_exceeds: {
    tagline:
      "Tickets with attachments exceeding the specified size limit will be marked as failed.",
    label: "Attachment Size Exceeds (MB)",
    showDialer: true,
    dialerKey: "attachments_max_size",
    maxValue: 10,
  },
};

export const closedTickets = {
  is_active_maximum_minutes_time_entry_notes: {
    tagline:
      "If the time entry for the note exceeds the preset duration, the ticket will be marked as failed.",
    label: "Maximum Time Entry for a Note (mins)",
    showDialer: true,
    dialerKey: "maximum_minutes_time_entry_notes",
  },
  is_active_minimum_minutes_time_entry_notes: {
    tagline:
      "If the time entry for the note is under the preset duration, the ticket will be marked as failed.",
    label: "Minimum Time Entry for a Note (mins)",
    showDialer: true,
    dialerKey: "minimum_minutes_time_entry_notes",
  },
  actual_time_not_exceed_budgeted_hours: {
    tagline:
      "If the actual time to close a ticket exceeds the budgeted hours, the ticket will be marked as failed.",
    label: "Time Should not Exceed Budgeted Hours",
    showDialer: false,
  },
  is_active_minimum_minutes_time_entry_ticket: {
    tagline:
      "If the time entry for a ticket is under the preset duration, the ticket will be marked as failed.",
    label: "Minimum Time Entry for a Ticket (mins)",
    showDialer: true,
    dialerKey: "minimum_minutes_time_entry_ticket",
  },
  required_checklists_completion: {
    tagline:
      "The tickets that have not completed the required checklist will be marked as failed.",
    label: "Checklist Completion",
    showDialer: false,
  },
  child_tickets_closed: {
    tagline:
      "The tickets for which the associated child tickets are not closed will be marked as failed.",
    label: "Child Tickets",
    showDialer: false,
  },
};

export const feedback = {
  is_active: {
    tagline:
      "If the rating dips below the set threshold, it's considered a failure.",
    showDialer: true,
    dialerKey: "minimum_rating",
    label: "Feedback",
    maxValue: 5,
  },
};

export const assignedProjects = {
  specify_tag: {
    tagline:
      "projects not meeting the minimum tag count will be marked as failed.",
    label: "Project Tag",
    showDialer: true,
    dialerKey: "specify_tag_count",
  },
  estimated_end_time: {
    tagline: "Projects without an estimated end time will be marked as failed.",
    label: "Estimated End Time",
    showDialer: false,
  },
  specify_project_type: {
    tagline: "Projects without a type mentioned will be marked as failed.",
    label: "Project Type",
    showDialer: false,
  },
  specify_project_subtype: {
    tagline: "Projects without a subtype mentioned will be marked as failed.",
    label: "Project Subtype",
    showDialer: false,
  },
  // specify_work_type: {
  //   tagline:
  //     "If there's no work type mentioned on the projects, they will be marked as failed.",
  //   label: "Work Type",
  //   showDialer: false,
  // },
  // specify_work_role: {
  //   tagline:
  //     "If there's no work role mentioned on the projects, they will be marked as failed.",
  //   label: "Work Role",
  //   showDialer: false,
  // },
  // specify_location: {
  //   tagline: "Projects without a specified location will be marked as failed.",
  //   label: "Project Location",
  //   showDialer: false,
  // },
  specify_budget_hours: {
    tagline: "Projects without budget hours added will be marked as failed.",
    label: "Budget Hours",
    showDialer: false,
  },
  minimum_word_for_description: {
    tagline:
      "Projects not meeting the minimum word count for description will be marked as failed.",
    label: "Minimum Project Description (words)",
    showDialer: true,
    dialerKey: "description_word_count",
  },
  attachments_exceeds: {
    tagline:
      "Projects with attachments exceeding the specified size limit will be marked as failed.",
    label: "Attachment Size Exceeds (MB)",
    showDialer: true,
    dialerKey: "attachments_max_size",
    maxValue: 10,
  },
  // close_prior_estimated_end_date: {
  //   tagline: "Close Project prior to estimated end date",
  //   label: "Close project",
  //   showDialer: false,
  // },
};

export const closedProjects = {
  actual_time_not_exceed_budgeted_hours: {
    tagline: "Actual time should not exceed Budgeted hours",
    label: "Actual time",
    showDialer: false,
  },
};

export const assignedTasks = {
  specify_tag: {
    tagline:
      "Tasks not meeting the minimum tag count will be marked as failed.",
    label: "Task Tag",
    showDialer: true,
    dialerKey: "specify_tag_count",
  },
  estimated_end_time: {
    tagline: "Tasks without an estimated end time will be marked as failed.",
    label: "Estimated End Time",
    showDialer: false,
  },
  specify_task_type: {
    tagline: "Tasks without a type mentioned will be marked as failed.",
    label: "Task Type",
    showDialer: false,
  },
  specify_task_subtype: {
    tagline: "Tasks without a subtype mentioned will be marked as failed.",
    label: "Task Subtype",
    showDialer: false,
  },
  specify_work_type: {
    tagline:
      "If there's no work type mentioned on the tasks, they will be marked as failed.",
    label: "Work Type",
    showDialer: false,
  },
  specify_work_role: {
    tagline:
      "If there's no work role mentioned on the tasks, they will be marked as failed.",
    label: "Work Role",
    showDialer: false,
  },
  incorporate_checklist: {
    tagline:
      "If there's no checklist added to the tasks, they will be marked as failed.",
    label: "Task Checklist",
    showDialer: false,
  },
  specify_location: {
    tagline: "Tasks without a specified location will be marked as failed.",
    label: "Task Location",
    showDialer: false,
  },
  specify_budget_hours: {
    tagline: "Tasks without budget hours added will be marked as failed.",
    label: "Budget Hours",
    showDialer: false,
  },
  minimum_word_for_description: {
    tagline:
      "Tasks not meeting the minimum word count for description will be marked as failed.",
    label: "Minimum Task Description (words)",
    showDialer: true,
    dialerKey: "description_word_count",
  },
  attachments_exceeds: {
    tagline:
      "Tasks with attachments exceeding the specified size limit will be marked as failed.",
    label: "Attachment Size Exceeds (MB)",
    showDialer: true,
    dialerKey: "attachments_max_size",
    maxValue: 10,
  },

  // close_prior_estimated_end_date: {
  //   tagline: "Close task prior to estimated end date",
  //   label: "Close Task",
  //   showDialer: false,
  // },
};

export const closedTasks = {
  is_active_maximum_minutes_time_entry_notes: {
    tagline:
      "If the time entry for the note exceeds the preset duration, the task will be marked as failed.",
    label: "Maximum Time Entry for a Note (mins)",
    showDialer: true,
    dialerKey: "maximum_minutes_time_entry_notes",
  },
  is_active_minimum_minutes_time_entry_notes: {
    tagline:
      "If the time entry for the note is under the preset duration, the task will be marked as failed.",
    label: "Minimum Time Entry for a Note (mins)",
    showDialer: true,
    dialerKey: "minimum_minutes_time_entry_notes",
  },
  actual_time_not_exceed_budgeted_hours: {
    tagline:
      "If the actual time to close a task exceeds the budgeted hours, the task will be marked as failed.",
    label: "Time Should not Exceed Budgeted Hours",
    showDialer: false,
  },
  is_active_minimum_minutes_time_entry_task: {
    tagline:
      "If the time entry for a task is under the preset duration, the task will be marked as failed.",
    label: "Minimum Time Entry for a Task (mins)",
    showDialer: true,
    dialerKey: "minimum_minutes_time_entry_task",
  },
  // is_active_minimum_words_time_entry_notes: {
  //   tagline:
  //     "If the Word Count of a time entry for a task is under the preset count, the task will be marked as failed.",
  //   label: "Minimum Word Count for Time Entry",
  //   showDialer: true,
  //   dialerKey: "minimum_words_time_entry_notes",
  // },
  required_checklists_completion: {
    tagline:
      "The tasks that have not completed the required checklist will be marked as failed.",
    label: "Checklist Completion",
    showDialer: false,
  },
  // resolution_notes_marked: {
  //   tagline:
  //     "The tasks that have not marked resolutions will be marked as failed.",
  //   label: "Resolutions Marked",
  //   showDialer: false,
  // },
};
