import { TabsListUnstyled } from "@mui/base";
import { styled } from "@mui/material";
const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: transparent;
  border-bottom: 1px solid #EDEDED;
  margin-bottom: 8px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  `,
);


export default TabsList;