import { Box, SxProps, Theme } from "@mui/material";
import { Avatar } from "components";
import { useEffect, useState } from "react";
import { getFullName } from "utilz/helpers";

interface MappingProps {
  src: string;
  firstName: string;
  lastName: string;
}
interface AvatarProps {
  dataList: Array<Record<string, any>>;
  mappingKeys: MappingProps;
  sx?: SxProps<Theme>;
}

export const AvatarGroupCircle = ({
  dataList,
  mappingKeys,
  sx,
}: AvatarProps) => {
  const [dataState, setDataState] = useState<Array<any>>([]);
  useEffect(() => {
    setDataState(getWidthAndSize(dataList));
  }, [dataList]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "45px",
        height: "45px",
        aspectRatio: "1/1",
        position: "relative",
        clipPath: "circle(50% at 50% 50%)",
        ...sx,
      }}
    >
      {dataState.map((item: any, index: number, list) => (
        <Avatar
          key={index}
          src={item[mappingKeys.src]}
          fullname={getFullName(
            item[mappingKeys.firstName],
            "",
            item[mappingKeys.lastName]
          )}
          sx={{
            position: "absolute",
            // bgcolor: cssColors[index],
            width: item.width,
            height: item.height,
            left: item.left,
            top: item.top,
            borderRadius: "0px",
            border: "none",
          }}
        />
      ))}
    </Box>
  );
};

const getWidthAndSize = (list: Array<any>) => {
  try {
    let totalSize = 360;

    const result = list
      .slice(0, 4)
      .map((item: any, index: number, listTemp) => {
        if (listTemp.length === 1) {
          return {
            ...item,
            width: "105%",
            height: "105%",
            left: "-5%",
            top: "-5%",
          };
        }
        const portionSize = totalSize / (listTemp.length - index);

        if ([180, 90, 0].includes(portionSize)) {
          totalSize -= portionSize;
          return {
            ...item,
            width: "50%",
            height: portionSize === 180 ? "100%" : "50%",
            left: index < listTemp.length / 2 ? "-1px" : "calc( 50% + 1px )",
            top:
              portionSize !== 180 && index > 0 && index < 3
                ? "calc( 50% + 1px )"
                : "-1px",
          };
        }
        totalSize -= 90;
        return {
          ...item,
          width: "50%",
          height: "50%",
          left: index < listTemp.length / 2 ? "-1px" : "calc( 50% + 1px )",
          top: index > 0 ? "calc( 50% + 1px )" : "-1px",
        };
      });
    return result || [];
  } catch (e: any) {
    return [];
  }
};
