import { DownloadIcon, FileIcon, Input, Modal } from "components";
import React from "react";
import { Attachment } from "shared";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CustomQuillEditor } from "pages/modules/control-center/configurations/components/service-desk-templates/tabs/component";
import { toast } from "react-hot-toast";

interface UpdateSummaryProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  data: {
    mappingId: string;
    uid: string;
    attachments?: Array<Attachment>;
    summary: string;
    description: string;
  };
}

const schema = yup.object({
  summary: yup.string().required("Please enter summary"),
  description: yup.string(),
});

export const UpdateSummaryModal = ({
  open,
  onClose,
  onSave,
  data,
}: UpdateSummaryProps) => {
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      summary: data.summary,
      description: data.description || "",
    },
    resolver: yupResolver(schema),
  });

  const updateEditor = (event: { field: string; value: string }) => {
    setValue("description", event.value);
  };
  const onSubmit = handleSubmit(async ({ summary, description }) => {
    console.log(description);
    try {
      onSave({
        summary,
        description,
      });
    } catch (error) {}
  });
  return (
    <>
      <Modal
        maxWidth="sm"
        open={open}
        onClose={onClose}
        title={`ID : ${data.uid}`}
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={onClose}
        onPrimaryButtonClick={onSubmit}
        headerStyles={{
          borderBottom: `1px solid ${theme.palette.neutral["010"]}`,
        }}
      >
        <Box>
          <Box px="32px" py="16px">
            <Input
              name="summary"
              control={control}
              placeholder="Summary"
              fullWidth
              error={errors.summary ? true : false}
              errorMessage={errors.summary?.message}
              inputProps={{ maxLength: 100 }}
            />
          </Box>

          <CustomQuillEditor
            key={"description"}
            placeholder={"Tell us more about the issue"}
            value={data.description}
            name={"quill editor"}
            onChange={updateEditor}
          />
        </Box>
      </Modal>
    </>
  );
};
