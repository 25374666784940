import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createConfigCheckList,
  deleteConfigCheckList,
  getAllChecklist,
  getConfigCheckListById,
  updateConfigCheckList,
} from "controller/api";
import {
  ApiErrorType,
  CreateConfigCheckListInput,
  GetQueryProps,
  UpdateConfigCheckListInput,
} from "shared";
import { sentryLogger } from "utilz";
import { debugConsole } from "utilz/helpers";

export const useGetAllChecklists = ({
  perPageCount,
  pageNumber,
  sortColumn = "id",
  sortDirection,
  filter,
  searchQuery,
}: GetQueryProps) => {
  return useQuery({
    queryKey: ["checklist", searchQuery],
    queryFn: () =>
      getAllChecklist({
        perPageCount,
        pageNumber,
        sortColumn,
        sortDirection,
        filter,
        searchQuery,
      }),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getAllChecklist",
      }),
  });
};

export const useCreateConfigChecklist = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: CreateConfigCheckListInput) => createConfigCheckList(data),
    {
      onSuccess: (_, variable, __) => {
        queryClient.invalidateQueries(["checklist"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "createConfigCheckList",
        });
      },
    }
  );
};

export const useUpdateConfigChecklist = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UpdateConfigCheckListInput) => updateConfigCheckList(data),
    {
      onSuccess: (_, variable, __) => {
        queryClient.invalidateQueries(["checklist"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "updateConfigCheckList",
        });
      },
    }
  );
};

export const useGetConfigCheckListByIdUsingMutation = () => {
  return useMutation((id: string) => getConfigCheckListById(id), {
    onError(error: ApiErrorType, variables, context) {
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getConfigCheckListById",
      });
    },
  });
};

export const useGetConfigCheckListById = (id: string) => {
  return useQuery({
    queryKey: ["checklist", id],
    queryFn: () => getConfigCheckListById(id),
    onError: (error: ApiErrorType) =>
      sentryLogger({
        error: error,
        workflow: "controlCenter",
        level: "error",
        functionName: "getConfigCheckListById",
      }),
  });
};

export const useDeleteConfigCheckList = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (checklistId: string) => deleteConfigCheckList(checklistId),
    {
      onSuccess: (_, variable, __) => {
        queryClient.invalidateQueries(["checklist"]);
      },
      onError(error: ApiErrorType, variables, context) {
        sentryLogger({
          error: error,
          workflow: "controlCenter",
          level: "error",
          functionName: "deleteConfigCheckList",
        });
      },
    }
  );
};
