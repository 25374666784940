import React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import {
  Box,
  Breakpoint,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import CustomIcon from "../../library/icon-library";
import { Button } from "../button";
import { CloseIcon } from "components/icons";

interface ModalProps extends DialogProps {
  header?: React.ReactNode;
  /**
   * component to add extra action components to header near close button
   */
  headerActionComponent?: React.ReactNode;
  additionalHeaderComponent?: React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
  titleStyles?: SxProps<Theme>;
  tagline?: string;
  expandable?: boolean;
  colseable?: boolean;
  footerLeftActionComponent?: React.ReactNode;
  footerRightActionComponent?: React.ReactNode;
  isPrimaryButtonDisabled?: boolean;
  isPrimaryButtonLoading?: boolean;
  isPrimaryButtonCallToActionRed?: boolean;
  isSecondaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: (e?: any) => void;
  onSecondaryButtonClick?: () => void;
  maxWidth?: Breakpoint;
  height?: Breakpoint;
  paperStyles?: React.CSSProperties;
  headerStyles?: SxProps<Theme>;
  footerStyles?: SxProps<Theme>;
  modalBodyStyles?: SxProps<Theme>;
  headerActionBarStyles?: SxProps<Theme>;
  customWidth?: string;
  customHeight?: string;
}

const getFullScreenHeight = () => {
  return window.innerHeight < 700 ? true : false;
};
export const Modal = ({
  header,
  additionalHeaderComponent,
  headerActionComponent,
  footer,
  title,
  titleStyles,
  tagline,
  expandable = false,
  colseable = true,
  onClose,
  fullScreen = false,
  children,
  footerLeftActionComponent,
  footerRightActionComponent,
  isPrimaryButtonDisabled = false,
  isPrimaryButtonLoading = false,
  isPrimaryButtonCallToActionRed = false,
  isSecondaryButtonDisabled = false,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  maxWidth = "md",
  // height = 'md',
  height,
  paperStyles,
  headerStyles,
  footerStyles,
  modalBodyStyles,
  headerActionBarStyles,
  customWidth,
  customHeight,
  ...rest
}: ModalProps) => {
  const [isFullScreen, setIsFullScreen] = React.useState(getFullScreenHeight);
  // const getFullScreenWidth = () => {
  //   if(window.innerWidth > getHeight()) {
  //     return true
  //   } else {
  //     return fullScreen
  //   }
  // }
  const theme = useTheme();
  const modalMinHeight = height ?? "";
  const getMaxWidth = () => {
    if (isFullScreen) return "100%";
    switch (maxWidth) {
      case "xs":
        return "612px";
      case "sm":
        return "768px";
      case "md":
        return "1128px";
      case "lg":
        return "1128px";
      case "xl":
        return "1128px";
      default:
        return "1128px";
    }
  };

  const getHeight = (height: string) => {
    if (isFullScreen) return "100%";
    switch (height) {
      case "xs":
        return "min(456px,85%)";
      case "sm":
        return "min(456px,85%)";
      case "md":
        return "min(704px,85%)";
      case "lg":
        return "min(752px,85%)";
      case "xl":
        return "min(752px,85%)";
      default:
        return "auto";
    }
  };
  const handleClose = () => {
    onClose && onClose({}, "backdropClick");
  };

  return (
    <>
      <Dialog
        {...rest}
        fullWidth
        fullScreen={isFullScreen}
        maxWidth={false}
        PaperProps={{
          style: {
            borderRadius: isFullScreen ? "0px" : "24px",
            maxWidth: customWidth ? customWidth : getMaxWidth(),
            minHeight: customHeight ? customHeight : getHeight(modalMinHeight),
            ...paperStyles,
          },
        }}
        onClose={onClose}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ flexShrink: 0 }}>
          {header ? (
            header
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "14px 32px",
                backgroundColor: "#fff",
                ...headerStyles,
              }}
            >
              <Stack>
                <Typography
                  variant="h5"
                  sx={{ textTransform: "capitalize", ...titleStyles }}
                >
                  {title}
                </Typography>
                {tagline && (
                  <Typography
                    variant="body-medium"
                    color={theme.palette.lightBg.low}
                  >
                    {tagline}
                  </Typography>
                )}
              </Stack>
              {/* right side actions */}
              <Stack
                direction="row"
                alignItems="center"
                gap="24px"
                sx={headerActionBarStyles}
              >
                {headerActionComponent && headerActionComponent}
                {expandable && (
                  <Box
                    sx={{
                      "&: hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <CustomIcon
                      size={"lg"}
                      icon={"maximize"}
                      color={theme.palette.lightBg.medium}
                      onClick={() => setIsFullScreen((prev) => !prev)}
                    />
                  </Box>
                )}
                {colseable && (
                  <IconButton
                    onClick={handleClose}
                    sx={{
                      "&: hover": {
                        cursor: "pointer",
                        backgroundColor: theme.palette.error.main,
                        transition: "all 0.5s",
                        borderRadius: "100px",
                        color: theme.palette.neutral.main,
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Stack>
            </Box>
          )}
          {additionalHeaderComponent && additionalHeaderComponent}
        </Box>
        {/* content */}
        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            ...modalBodyStyles,
          }}
        >
          {children}
        </Box>

        {/* Footer */}
        <Box sx={{ flexShrink: 0 }}>
          {footer ? (
            footer
          ) : (
            <Box minHeight="72px" px="32px" py="16px" sx={{ ...footerStyles }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  {footerLeftActionComponent && footerLeftActionComponent}
                </Box>
                <Stack
                  gap="24px"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {footerRightActionComponent && footerRightActionComponent}
                  <Button
                    disabled={isSecondaryButtonDisabled}
                    variant="secondary"
                    onClick={onSecondaryButtonClick}
                  >
                    {secondaryButtonText}
                  </Button>
                  <Button
                    disabled={isPrimaryButtonDisabled}
                    variant="primary"
                    onClick={onPrimaryButtonClick}
                    loading={isPrimaryButtonLoading}
                    sx={{
                      bgcolor: isPrimaryButtonCallToActionRed
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                      "&:hover": {
                        bgcolor: isPrimaryButtonCallToActionRed
                          ? "#A9050E"
                          : theme.palette.primary.main,
                      },
                    }}
                  >
                    {primaryButtonText}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};
