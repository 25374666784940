export const defaultStateBuilder = (FORM_FIELDS)=> {
  return{
    location_name: {...FORM_FIELDS.location_name, renderKey: "", value: ""},
    contact_person: {...FORM_FIELDS.contact_person, renderKey: "", value: ""},
    location_description: {...FORM_FIELDS.location_description, renderKey: "", value: ""},
    email: {...FORM_FIELDS.email, renderKey: "", value: ""},
    contact_number: {...FORM_FIELDS.contact_number, renderKey: "", value: ""},
    country: {...FORM_FIELDS.country, renderKey: "", value: ""},
    state: {...FORM_FIELDS.state, renderKey: "", value: ""},
    city: {...FORM_FIELDS.city, renderKey: "", value: ""},
    street: {...FORM_FIELDS.street, renderKey: "", value: ""},
    postal_code: {...FORM_FIELDS.postal_code, renderKey: "", value: ""},
    timezone: {...FORM_FIELDS.timezone, renderKey: "", value: ""},
    latitude: {...FORM_FIELDS.latitude, renderKey: "", value: ""},
    longitude: {...FORM_FIELDS.longitude, renderKey: "", value: ""}
  };
};