function cameraIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3562_101708)">
        <path d="M15.2364 12.6055C15.2364 12.9545 15.0978 13.2892 14.851 13.5359C14.6042 13.7827 14.2696 13.9213 13.9206 13.9213H2.07848C1.72952 13.9213 1.39484 13.7827 1.14808 13.5359C0.901323 13.2892 0.762695 12.9545 0.762695 12.6055V5.3687C0.762695 5.01973 0.901323 4.68505 1.14808 4.43829C1.39484 4.19154 1.72952 4.05291 2.07848 4.05291H4.71006L6.02585 2.07922H9.97322L11.289 4.05291H13.9206C14.2696 4.05291 14.6042 4.19154 14.851 4.43829C15.0978 4.68505 15.2364 5.01973 15.2364 5.3687V12.6055Z" stroke="white" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.00218 11.2894C9.45557 11.2894 10.6338 10.1112 10.6338 8.65782C10.6338 7.20444 9.45557 6.02625 8.00218 6.02625C6.5488 6.02625 5.37061 7.20444 5.37061 8.65782C5.37061 10.1112 6.5488 11.2894 8.00218 11.2894Z" stroke="white" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3562_101708">
          <rect width="15.7895" height="15.7895" fill="white" transform="translate(0.105469 0.105225)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default cameraIcon;