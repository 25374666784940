import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "components";
import { useGlobalStore } from "controller";
import { MqttContext } from "controller/context/MqttContext";
import React, { useContext, useEffect } from "react";
interface DataListProps {
  title: string;
  description: string;
  percentage: number;
}
const dataListData = [
  {
    title: "ticket",
    description:
      "Creating sample tickets for you explore chat-based ticketing experience",
    percentage: 0,
  },
  {
    title: "project",
    description:
      "Creating sample project with objectives, timelines, and resource allocations",
    percentage: 0,
  },
  {
    title: "task",
    description:
      "Adding sample tasks into project to showcase potential task management",
    percentage: 0,
  },
  {
    title: "timesheet",
    description:
      "Creating sample data for the timesheet with logged hours to illustrate tracking capabilities",
    percentage: 0,
  },
];
export const SampleDataList = () => {
  const theme = useTheme();
  const mspId = useGlobalStore((state) => state.mspId);
  const [dataList, setDataList] = React.useState(dataListData);
  const [progressData, setProgressData] = React.useState<
    Record<string, number>
  >({
    tickets: 0,
    projects: 0,
    task: 0,
    timesheet: 0,
  });

  const { mqttMessage } = useContext(MqttContext);

  useEffect(() => {
    if (mspId && mqttMessage?.topic === `sample-data/progress/${mspId}`) {
      const data = JSON.parse(mqttMessage?.message);
      setProgressData(data);
      if (
        data.ticket === 100 &&
        data.project === 100 &&
        data.task === 100 &&
        data.timesheet === 100
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }, [mqttMessage]);

  return (
    <Stack gap="56px" sx={{ maxWidth: "80%" }}>
      <Stack gap="16px">
        <Typography variant="h4">We’re setting up your data</Typography>
        <Typography variant="body-large" color={theme.palette.lightBg.low}>
          This may take up to a minute.
        </Typography>
      </Stack>
      <Stack gap={"24px"}>
        {dataList.map((data, index) => (
          <DataList
            key={data.title}
            title={data.title}
            description={data.description}
            percentage={progressData[data.title]}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const DataList = ({ title, description, percentage }: DataListProps) => {
  const theme = useTheme();
  return (
    <Stack direction={"row"} gap={"36px"}>
      <Stack
        sx={{
          width: "28px",
          height: "28px",
          position: "relative",
          aspectRatio: "1/1",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "grid",
            placeItems: "center",
            width: "28px",
            height: "28px",
          }}
        >
          <CircularProgress
            thickness={5}
            variant="determinate"
            value={100}
            size="100%"
            sx={{
              "&.MuiCircularProgress-root": {
                color: "#E7E9FE",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "grid",
            placeItems: "center",
            width: "28px",
            height: "28px",
          }}
        >
          <CircularProgress
            thickness={5}
            variant="determinate"
            value={percentage}
            size="100%"
            sx={{
              transform: "translate(-50%, -50%)",
              "&.MuiCircularProgress-root": {
                color: theme.palette.success.main,
              },
            }}
          />
        </Box>
        {percentage === 100 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "grid",
              placeItems: "center",
              width: "28px",
              height: "28px",
            }}
          >
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.83682 1.69727L4.04324 6.30253L1.16138 3.54052"
                stroke="#198038"
                strokeWidth="1.47368"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        )}
      </Stack>

      <Stack gap="4px">
        <Typography variant="body-large" sx={{ textTransform: "capitalize" }}>
          {title}
        </Typography>
        <Typography variant="body-large" color={theme.palette.lightBg.low}>
          {description}
        </Typography>
      </Stack>
    </Stack>
    // <Stack direction={"row"} gap={"36px"}>
    //   <Stack
    //     sx={{
    //       width: "28px",
    //       height: "28px",
    //       position: "relative",
    //       aspectRatio: "1/1",
    //     }}
    //   >
    //     <CircularProgress
    //       thickness={5}
    //       variant="determinate"
    //       value={100}
    //       size="100%"
    //       sx={{
    //         position: "absolute",
    //         "&.MuiCircularProgress-root": {
    //           color: "#E7E9FE",
    //         },
    //         //   "& .MuiCircularProgress-circle": { width: "300px", color: "red" },
    //       }}
    //     />
    //     <CircularProgress
    //       thickness={5}
    //       variant="determinate"
    //       value={percentage}
    //       size="100%"
    //       sx={{
    //         "&.MuiCircularProgress-root": {
    //           color: theme.palette.success.main,
    //         },
    //         //   "& .MuiCircularProgress-circle": { width: "300px", color: "red" },
    //       }}
    //     />
    //     {percentage === 100 && (
    //       <Stack
    //         sx={{
    //           position: "absolute",
    //           width: "28px",
    //           height: "28px",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         <svg
    //           width="10"
    //           height="8"
    //           viewBox="0 0 10 8"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M8.83682 1.69727L4.04324 6.30253L1.16138 3.54052"
    //             stroke="#198038"
    //             strokeWidth="1.47368"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //           />
    //         </svg>
    //       </Stack>
    //     )}
    //   </Stack>

    //   <Stack gap="4px">
    //     <Typography variant="body-large" sx={{ textTransform: "capitalize" }}>
    //       {title}
    //     </Typography>
    //     <Typography variant="body-large" color={theme.palette.lightBg.low}>
    //       {description}
    //     </Typography>
    //   </Stack>
    // </Stack>
  );
};
