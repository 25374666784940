import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import BoardCreateModal from "../components/board-create-modal";
import { useGlobalStore } from "controller/store/globalStore";
import toast from "react-hot-toast";
import { DeleteConfirmationModal } from "components";
import {
  useCreateBoard,
  useDeleteBoard,
  useGetAllBoard,
  useGetBoardById,
  useUpdateBoard,
} from "controller";
import { BoardHeader } from "./BoardHeader";
import { BoardTable } from "./BoardTable";

const headers = [
  {
    name: "Board name",
    sortable: false,
    key: "ticket_board_name",
  },
  {
    name: "Action",
    sortable: false,
    key: "header_action",
  },
];

export const TicketBoardTab = () => {
  const defaultBoard = "a6e692ea-39c9-418e-8e9b-649e262a5100";
  const mspId = useGlobalStore((state) => state.mspId);
  const [state, setState] = useState({
    isModalOpen: false,
  });

  const [pagination, setPagination] = useState({ searchQuery: "", current: 1 });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [deleteModalState, setDeleteModalState] = useState({
    open: false,
    boardId: null,
  });

  const {
    data: ticketBoards,
    isLoading,
    refetch: refetchBoard,
  } = useGetAllBoard({
    boardType: "ticket",
    mspId: mspId,
    pageNumber: pagination.current,
    perPageCount: 10,
    sortColumn: "board_name",
    sortDirection: "asc",
    searchQuery: pagination.searchQuery,
  });

  const { data: selectedTicketBoard } = useGetBoardById({
    boardId: selectedBoardId,
    boardType: "ticket",
  });

  const { mutateAsync: createTicketBoard } = useCreateBoard();
  const { mutateAsync: updateTicketBoard } = useUpdateBoard();
  const { mutateAsync: deleteTicketBoard } = useDeleteBoard();

  useEffect(() => {
    refetchBoard;
  }, [pagination]);

  useEffect(() => {
    if (selectedTicketBoard) {
      setState({
        ...state,
        isModalOpen: true,
      });
    }
  }, [selectedTicketBoard]);

  const handleEditAction = (data: any) => {
    setSelectedBoardId(data.ticket_board_id);
  };

  const handleAddAction = (status: boolean) => {
    setState({
      ...state,
      isModalOpen: status,
    });
    if (selectedBoardId) {
      setSelectedBoardId(null);
    }
  };

  const handleSaveAction = (data: any) => {
    if ("mapping_id" in data) {
      const boardId = data["mapping_id"];
      delete data["mapping_id"];
      updateTicketBoard({ boardType: "ticket", boardId: boardId, data: data })
        .then((res) => {
          handleAddAction(false);
          setPagination({ ...pagination, current: 1 });
          setSelectedBoardId(null);
          toast.success("Updated successfully");
        })
        .catch((err) => {
          toast.error("Unable to update at the moment. Please try again");
        });
      return;
    }
    createTicketBoard({
      boardType: "ticket",
      mspId: mspId,
      data: { ...data, board_filters: [] },
    })
      .then((res) => {
        handleAddAction(false);
        setPagination({ ...pagination, current: 1 });
        setSelectedBoardId(null);
        toast.success("Created successfully");
      })
      .catch((err) => {
        toast.error("Unable to create at the moment. Please try again");
      });
  };

  const onDeleteBoard = () => {
    // deleteTicketBoard({
    //   boardType: "ticket",
    //   boardId: deleteModalState.boardId,
    // })
    //   .then((res) => {
    //     setPagination({ ...pagination, current: 1 });
    //     setSelectedBoardId(null);
    //     toast.success("Deleted successfully");
    //   })
    //   .catch((err) => {
    //     toast.error("Unable to delete at the moment. Please try again");
    //   });
    // closeDeleteModal();
  };

  const closeDeleteModal = () => {
    setDeleteModalState({
      open: false,
      boardId: null,
    });
  };

  const handleSearchAction = (data: any) => {
    setPagination({ searchQuery: data, current: 1 });
  };

  const resetSeachAction = () => {
    setPagination({ searchQuery: "", current: 1 });
  };

  return (
    <Box
      sx={{
        display: "flex",
        rowGap: "24px",
        flexDirection: "column",
      }}
    >
      {deleteModalState.open && (
        <DeleteConfirmationModal
          open={deleteModalState.open}
          onClose={closeDeleteModal}
          onSecondaryButtonClick={closeDeleteModal}
          onPrimaryButtonClick={onDeleteBoard}
        />
      )}

      {state.isModalOpen && (
        <BoardCreateModal
          isDisabled={defaultBoard === selectedBoardId}
          selectedData={selectedTicketBoard}
          edit={selectedBoardId !== undefined}
          isOpen={state.isModalOpen}
          onClose={() => handleAddAction(false)}
          onSave={handleSaveAction}
        />
      )}
      <BoardHeader
        data={[searchQuery, setSearchQuery]}
        createNewHandler={() => handleAddAction(true)}
        updateSearchHandler={handleSearchAction}
        resetSearchHandler={resetSeachAction}
      />

      <BoardTable
        headers={headers}
        data={Array.isArray(ticketBoards?.result) ? ticketBoards.result : []}
        currentPageNumber={pagination.current}
        totalItems={ticketBoards?.totalCount || 1}
        totalPages={ticketBoards?.totalPages || 1}
        itemsPerPage={10}
        handleRowClick={handleEditAction}
        onDeleteClicked={(item) => {
          setDeleteModalState({
            open: true,
            boardId: item.ticket_board_id,
          });
        }}
        onPagination={(newPageNumber: number) =>
          setPagination({ ...pagination, current: newPageNumber })
        }
      />
    </Box>
  );
};
