import { Box, Stack, Typography } from "@mui/material";
import windowsLogo from "./assets/images/windowsLogo.webp";
import appleLogo from "./assets/images/appleLogo.webp";
import androidLogo from "./assets/images/androidLogo.webp";
import msTeamsLogo from "./assets/images/msTeamsLogo.webp";
import { DownloadDrawerAccordion } from "./DownloadDrawerAccordion";
import { DownloadDrawerRadioBtns } from "./DownloadDrawerRadioBtns";
import { QrCodeComponent } from "./QrCodeComponent";
import iosQr from "assets/images/iosQr.png";
import androidQr from "assets/images/androidQr.png";
import teamsDownloadQr from "assets/images/teamsDownloadQr.png";
import { useState } from "react";

export const AppsForMspCustomers = ({
  closeDownloadDrawer,
}: {
  closeDownloadDrawer: () => void;
}) => {
  const [currentAccordionIndex, setCurrentAccordionIndex] = useState(-1);
  const WindowsRadioMenuData = [
    {
      label: "EXE",
      value: "win_exe",
    },
    // {
    //   label: "MSI",
    //   value: "win_msi",
    //   isComingSoon: true,
    // },
  ];
  const AppleRadioMenuData = [
    {
      label: "Mac with Apple Silicon",
      value: "apple_m",
    },
    {
      label: "Mac with Intel chip",
      value: "apple_intel",
    },
  ];

  const CHANNELS = [
    {
      logo: windowsLogo,
      title: "Windows",
      content: (
        <DownloadDrawerRadioBtns
          radioMenuData={WindowsRadioMenuData}
          closeDownloadDrawer={closeDownloadDrawer}
        />
      ),
    },
    {
      logo: appleLogo,
      title: "macOS",
      content: (
        <DownloadDrawerRadioBtns
          radioMenuData={AppleRadioMenuData}
          closeDownloadDrawer={closeDownloadDrawer}
        />
      ),
    },
    {
      logo: androidLogo,
      title: "Android",
      content: <QrCodeComponent qrCode={androidQr} />,
    },
    {
      logo: appleLogo,
      title: "iOS",
      content: <QrCodeComponent qrCode={iosQr} />,
    },
    {
      logo: msTeamsLogo,
      title: "MS Teams",
      content: (
        <QrCodeComponent
          qrCode={teamsDownloadQr}
          closeDownloadDrawer={closeDownloadDrawer}
        />
      ),
    },
  ];

  return (
    <Stack px="24px" height="100%">
      <Box sx={{ overflowY: "scroll", flexGrow: 1 }}>
        {CHANNELS.map(({ logo, title, content }, index) => (
          <DownloadDrawerAccordion
            key={title}
            logo={logo}
            title={title}
            content={content}
            expanded={currentAccordionIndex === index}
            onChange={(expanded) =>
              setCurrentAccordionIndex(expanded ? index : -1)
            }
          />
        ))}
      </Box>
      <Box py="16px" flexShrink={0} display="flex">
        <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://support.deskday.com/en/articles/8235973-installing-the-it-connect",
              "_blank"
            );
          }}
          variant="link-small"
        >
          Discover IT-Connect
        </Typography>
      </Box>
    </Stack>
  );
};
