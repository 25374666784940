import { Box } from "@mui/material";
import { DataTable, DeleteIcon } from "components";

interface BoardTableProps {
  data: Array<any>;
  headers: Array<any>;
  currentPageNumber: number;
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  handleRowClick: (data: any) => void;
  onDeleteClicked: (data: any) => void;
  onPagination: (data: any) => void;
}

export const BoardTable = ({
  data,
  headers,
  currentPageNumber,
  handleRowClick,
  itemsPerPage,
  onDeleteClicked,
  onPagination,
  totalPages,
  totalItems,
}: BoardTableProps) => {
  return (
    <DataTable
      headers={headers}
      data={data}
      multiselect={false}
      selectedItems={[]}
      currentPageNumber={currentPageNumber}
      totalPages={totalPages}
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      showPagination={true}
      handleRowClick={handleRowClick}
      onPagination={onPagination}
      containerStyles={{
        height: {
          sm: "calc(100vh - 260px)",
          md: "calc(100vh - 280px)",
          lg: "calc(100vh - 290px)",
          xl: "calc(100vh - 300px)",
        },
      }}
      cellBuilders={{
        header_action: (rowData?: any) => (
          <Box width={"100px"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& :hover": {
                  cursor: "pointer",
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
                onDeleteClicked(rowData);
              }}
            >
              <DeleteIcon />
            </Box>
          </Box>
        ),
      }}
      cellProps={{
        header_action: {
          sx: {
            width: "100px",
          },
        },
      }}
    />
  );
};
