import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getMspNotificationSettings,
  updateMspNotificationSettings,
} from "controller/api";
import { getMspById } from "controller/api/control-center/msp";
import { UpdateMspNotificationInput } from "shared";

export const useGetMspNotificationSettings = () => {
  return useQuery({
    queryKey: ["msp_notification_settings"],
    queryFn: () => getMspNotificationSettings(),
    onError: (error) => console.log(error),
  });
};

export const useUpdateMspNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (value: UpdateMspNotificationInput) => updateMspNotificationSettings(value),
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["msp_notification_settings"]);
      },
    }
  );
};
