import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  BarChart as ReBarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Rectangle,
  CartesianGrid,
  Legend,
  Label,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { stringToNumber } from "utilz/helpers";
import {
  ChartProps,
  CustomTooltip,
  LegendBuilder,
  TickLabel,
  barChartColor,
} from "../Utils";

export const BarChart = ({
  data = [],
  labelKey = "label",
  valueKey = "value",
  width,
  height,
  customTooltip,
  isLoading = false,
  customLegend,
  showLegends,
  showAxisLines = false,
  showGrid = false,
  hideXaxis = false,
  hideYAxis = false,
  layout = "horizontal",
  barSize = 40,
}: ChartProps & { layout?: "vertical" | "horizontal"; barSize?: number }) => {
  const theme = useTheme();
  return isLoading ? (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        // Replace with your desired box background color
        width: "100%",
        height: "100%",
        gap: "8px",
      }}
    >
      <Stack
        flex={1}
        direction={"row"}
        width={"100%"}
        columnGap={"16px"}
        justifyContent={"baseline"}
        alignItems={"end"}
      >
        {["100%", "50%", "60%", "20%", "40%", "80%"].map((value, index) => (
          <Skeleton
            key={index}
            sx={{
              flex: 1,
              height: value,
              WebkitTransform: "unset",
            }}
          />
        ))}
      </Stack>

      <Stack
        direction={"row"}
        height={"32px"}
        width={"100%"}
        overflow={"clip"}
        gap={"16px"}
      >
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} height={"100%"} width={"80%"} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <ResponsiveContainer width={width} height={height}>
      <ReBarChart layout={layout} data={data}>
        {showGrid && <CartesianGrid stroke={theme.palette.neutral["010"]} />}
        {!hideYAxis && (
          <YAxis
            width={layout === "horizontal" ? 40 : 100}
            padding={{ bottom: 0 }}
            tickCount={4}
            tickLine={false}
            axisLine={showAxisLines}
            dataKey={layout === "horizontal" ? valueKey : labelKey}
            type={layout === "horizontal" ? "number" : "category"}
            tick={<TickLabel isXaxis={false} />}
            allowDecimals={false}
            stroke={theme.palette.neutral["010"]}
          />
        )}
        <XAxis
          dataKey={layout === "horizontal" ? labelKey : valueKey}
          type={layout === "horizontal" ? "category" : "number"}
          tickLine={false}
          axisLine={showAxisLines}
          fontSize={theme.typography["body-small"].fontSize}
          fontWeight={theme.typography["body-small"].fontWeight}
          fontFamily={theme.typography["body-small"].fontFamily}
          color="black"
          allowDataOverflow={true}
          tick={<TickLabel isXaxis={true} />}
          stroke={theme.palette.neutral["010"]}
          interval={0}
        />
        <Tooltip
          content={
            customTooltip || (
              <CustomTooltip labelKey={labelKey} valueKey={valueKey} />
            )
          }
          cursor={{ fill: "white" }}
        />
        <Bar
          dataKey={valueKey}
          shape={<CurvedRectangleBar />}
          barSize={barSize}
          maxBarSize={barSize}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
        {showLegends && (
          <Legend
            content={
              customLegend ||
              LegendBuilder({ data, labelKey, color: barChartColor })
            }
          />
        )}
      </ReBarChart>
    </ResponsiveContainer>
  );
};

const CurvedRectangleBar = (props: any) => {
  const {
    fill,
    x,
    y,
    width,
    height,
    background: { height: totalHeight },
  } = props;
  const percentage = height / totalHeight;
  const colorOpacity = 0.3 * (1 - percentage) + percentage;
  return (
    <Rectangle
      x={x}
      y={y}
      radius={4}
      width={width}
      height={height}
      fill={fill || barChartColor}
      dy={10}
      // opacity={fill ? 1 : colorOpacity}
    />
  );
};
