import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Avatar,
  BulbIcon,
  BulbYellowIcon,
  ComputerIcon,
  DownloadCurvedIcon,
  EmailTickIcon,
  FileIcon,
  GlobeCheckmarkIcon,
  LockIcon,
  MobileDeviceIcon,
  Modal,
  RightArrowIcon,
  SingleUserIcon,
  TeamsAppIcon,
  Tooltip,
  UserIcon,
} from "components";
import { memo, useCallback, useState } from "react";
import dayjs from "dayjs";
import { ChatAttachmentResult } from "shared/types";
import EmailIcon from "assets/images/email.png";
import NotesIcon from "assets/images/notes.png";
import { debugConsole, emptyStringHandler } from "utilz/helpers";
import { useGetTimeEntrieByIdMutation } from "controller/hooks/service-desk/time-entries/useTimeEntries";
import { CreateTimeEntryModal } from "components/modal/CreateTimeEntryModal";
import ReactQuill from "react-quill";

// message block for resource messages
export const ResourceMessage = ({
  message,
  isMarkedAsResolved,
  onMarkAsResolvedClick,
  date,
  type,
  attachments,
  senderDisplayName,
  imageUrl,
  accessSpecifier,
  isEdited,
  messagePlatform,
}: {
  message: string;
  type?: string;
  isMarkedAsResolved: boolean;
  onMarkAsResolvedClick: () => void;
  date: string;
  attachments?: Array<ChatAttachmentResult>;
  senderDisplayName: string;
  imageUrl: string;
  accessSpecifier: string;
  isEdited?: boolean;
  messagePlatform?: string | null;
}) => {
  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      component="div"
      sx={{
        mt: "20px",
        position: "relative",
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        pt: "20px",
        minWidth: "200px",
        // minWidth: "50%",
        maxWidth: "min(480px , 100%)",
      }}
    >
      <Stack
        justifyContent={"flex-end"}
        direction="row"
        alignItems="center"
        gap="8px"
      >
        <Avatar fullname={senderDisplayName} url={imageUrl}></Avatar>
        <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
          {senderDisplayName}
        </Typography>
      </Stack>
      <Box
        sx={{
          mt: "4px",
          position: "relative",
          alignSelf: "flex-end",
          maxWidth: "100%",
          // overflowX: "auto",
          overflowWrap: "break-word",
          borderRadius: "12px 0px 12px 12px",
          backgroundColor:
            attachments && attachments?.length > 0 ? "#FFF" : "#E7E9FE",
          minWidth: "200px",
          padding: "16px",
          border: `1px solid ${theme.palette.neutral["005"]}`,
        }}
      >
        {attachments && attachments?.length > 0 && (
          <AttachmentMessageBlock type="resource" attachment={attachments} />
        )}

        <Box sx={{}}>
          <MarkAsResolutionIcon
            isHovered={isHovered}
            onClick={onMarkAsResolvedClick}
            markedAsResolved={isMarkedAsResolved}
          />

          <Typography sx={{ whiteSpace: "pre-line" }} variant="body-medium">
            {message}
          </Typography>

          <MessageFooter
            senderType={"resource"}
            accessSpecifier={accessSpecifier}
            isEdited={isEdited}
            messagePlatform={messagePlatform}
            date={date}
          />
        </Box>
      </Box>
    </Box>
  );
};

// message block for customer messages
export const CustomerMessage = ({
  message,
  senderDisplayName,
  imageUrl,
  isMarkedAsResolved,
  onMarkAsResolvedClick,
  type,
  attachments,
  accessSpecifier,
  isEdited,
  date,
  messagePlatform,
}: {
  message: string;
  senderDisplayName: string;
  imageUrl: string;
  isMarkedAsResolved: boolean;
  type?: string;
  attachments?: Array<ChatAttachmentResult>;
  accessSpecifier: string;
  isEdited?: boolean;
  date: string;
  onMarkAsResolvedClick: () => void;
  messagePlatform: string | null;
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box>
      <Stack
        component="div"
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        sx={{
          // position: "relative",
          alignSelf: "flex-start",
          maxWidth: `${isMarkedAsResolved ? "50%" : "50%"}`,
          gap: "8px",
          mt: "20px",
          width: "100%",
          minWidth: "420px",
        }}
      >
        <Stack direction="row" alignItems="center" gap="8px">
          <Avatar fullname={senderDisplayName} url={imageUrl}></Avatar>
          <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
            {senderDisplayName}
          </Typography>
        </Stack>
        <Box
          sx={{
            position: "relative",
            alignSelf: "flex-start",
            // minWidth: "300px",
            maxWidth: "100%",
            overflowX: "auto",
            overflowWrap: "break-word",
            borderRadius: "0px 12px 12px 12px",
            backgroundColor: "#FFFFFF",
            minWidth: "200px",
            padding: "16px",
            border: `1px solid ${theme.palette.neutral["010"]}`,
          }}
        >
          {attachments && attachments?.length > 0 && (
            <AttachmentMessageBlock type="customer" attachment={attachments} />
          )}

          <Box sx={{}}>
            <Typography variant="body-medium" sx={{ whiteSpace: "pre-line" }}>
              {message}
            </Typography>
            {/* <MarkAsResolutionIcon
              isHovered={isHovered}
              onClick={onMarkAsResolvedClick}
              markedAsResolved={isMarkedAsResolved}
            /> */}

            <MessageFooter
              senderType={"customer"}
              accessSpecifier={accessSpecifier}
              isEdited={isEdited}
              messagePlatform={messagePlatform}
              date={date}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

// message section for attachments
export const AttachmentMessageBlock = ({
  attachment,
  type,
}: {
  attachment: Array<ChatAttachmentResult>;
  type: "customer" | "resource";
}) => {
  const theme = useTheme();
  return (
    <Box mb={"16px"}>
      {attachment.map((attachment) => {
        return (
          <Stack
            key={attachment._id}
            component="a"
            target="_blank"
            href={attachment.url}
            direction="row"
            gap={"8px"}
            sx={{
              my: "8px",
              position: "relative",
              alignSelf: type === "resource" ? "flex-end" : "flex-start",
              alignItems: "center",
              height: "auto",
              justifyContent: "space-between",
              bgcolor: theme.palette.purple.tint,
              padding: "8px",
              borderRadius: "8px",
              border: 0,
              cursor: "pointer",
              width: "min(100%, 520px)",
              textDecoration: "none",
            }}
          >
            <Stack
              maxWidth={"80%"}
              direction={"row"}
              alignItems={"center"}
              gap="8px"
            >
              <Box sx={{ width: "28px", height: "28px" }}>
                <FileIcon type={attachment.file_type} />
              </Box>
              <Typography variant="caption-2" noWrap textTransform={"none"}>
                {attachment.file_name}
              </Typography>
            </Stack>

            <DownloadCurvedIcon htmlColor={theme.palette.purple.main} />
          </Stack>
        );
      })}
    </Box>
  );
};

const ReactQuillComponent = memo(function ReactQuillComponent({
  message,
}: {
  message: string;
}) {
  return (
    <ReactQuill
      readOnly
      style={{
        height: "100%",
        border: "none",
      }}
      theme="snow"
      value={message}
      onChange={() => {}}
      modules={{
        toolbar: false,
      }}
    />
  );
});
// message section for email and notes
export const EmailAndNotesAttachmentBlock = ({
  source,
  mappingId,
  message,
  type,
  metadata,
  senderDisplayName,
  imageUrl,
  accessSpecifier,
  isEdited,
  date,
  messagePlatform,
  sentAsEmail,
  serviceType,
  serviceId,
  onMarkAsResolvedClick,
  isMarkedAsResolved,
}: {
  mappingId: string;
  message: string;
  source: string;
  type: "customer" | "resource";
  metadata?: Record<string, any>;
  senderDisplayName?: string;
  imageUrl?: string;
  accessSpecifier: string;
  isEdited?: boolean;
  date: string;
  messagePlatform?: string | null;
  sentAsEmail?: boolean | null;
  serviceType: any;
  serviceId: string;
  onMarkAsResolvedClick: () => void;
  isMarkedAsResolved: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedTimeEntry, setSelectedTimeEntry] = useState<any>(null);
  const [selectedMessage, setselectedMessage] = useState<
    string | null | undefined
  >();
  const { mutateAsync: getTimeEntry } = useGetTimeEntrieByIdMutation();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        mt: "20px",
        alignItems: type === "resource" ? "flex-end" : "flex-start",
        minWidth: "200px",
      }}
    >
      <Stack
        component={"div"}
        onMouseEnter={() => {
          type === "resource" && setIsHovered(true);
        }}
        onMouseLeave={() => {
          type === "resource" && setIsHovered(false);
        }}
        sx={{ maxWidth: "min(480px , 100%)" }}
      >
        <Stack
          sx={{
            alignSelf: type === "resource" ? "flex-end" : "flex-start",
          }}
          direction="row"
          alignItems="center"
          gap="8px"
        >
          {senderDisplayName && (
            <>
              <Avatar fullname={senderDisplayName} url={imageUrl}></Avatar>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {senderDisplayName}
              </Typography>
            </>
          )}
        </Stack>

        <Stack
          sx={{
            position: "relative",
            borderRadius:
              type === "customer" ? "0px 12px 12px 12px" : "12px 0px 12px 12px",
            backgroundColor: type === "customer" ? "#FFF" : "#E7E9FE",
            minWidth: "200px",
            padding: "16px",
            marginY: "6px",
            border: `1px solid ${
              isMarkedAsResolved
                ? theme.palette.neutral["015"]
                : theme.palette.neutral["005"]
            }`,
            "& .ql-editor": {
              padding: "0px !important",
              margin: "0px !important",
              ...theme.typography["body-medium"],
              color: theme.palette.lightBg.high,
              height: "auto",

              // height: "20px !important",
            },

            "& .ql-container.ql-snow": {
              height: "auto",
              minHeight: "0px",
              backgroundColor: "transparent",
              // overflow: "scroll",
              border: "none !important",
              overflow: "hidden",
            },
          }}
        >
          <ReactQuillComponent message={message} />
          <MessageFooter
            senderType={type}
            accessSpecifier={accessSpecifier}
            isEdited={isEdited}
            messagePlatform={messagePlatform}
            sentAsEmail={sentAsEmail}
            type={source as "canned_notes" | "email" | "time_entry_notes"}
            date={date}
          />
          <MarkAsResolutionIcon
            isHovered={isHovered && type === "resource"}
            onClick={onMarkAsResolvedClick}
            markedAsResolved={isMarkedAsResolved}
          />
        </Stack>

        {/* time entry modal */}
        {/* TODO: use only one modal in ticket header -- remove this */}
        {source === "time_entry_notes" && selectedMessage ? (
          <CreateTimeEntryModal
            serviceId={serviceId}
            serviceType={serviceType}
            open={selectedMessage ? true : false}
            onClose={() => {
              setSelectedTimeEntry({});
              setselectedMessage(null);
            }}
            selectedResourceId={selectedTimeEntry?.resource_id || ""}
            selectedResourceName={
              `${emptyStringHandler(
                selectedTimeEntry?.first_name
              )} ${emptyStringHandler(selectedTimeEntry?.last_name)}` || ""
            }
            selectedProfileImage={selectedTimeEntry?.profile_image || ""}
            selectedTimeEntryId={selectedTimeEntry?.mapping_id || null}
            selectedDeductTime={selectedTimeEntry?.deduct_time || ""}
            selectedStartDateTime={selectedTimeEntry?.start_time || null}
            selectedEndDateTime={selectedTimeEntry?.end_time || null}
            selectedIsBillable={
              selectedTimeEntry?.is_billable == false ? false : true
            }
            selectedResolution={selectedTimeEntry?.mark_as_resolution || false}
            selectedAccessSpecifier={
              selectedTimeEntry?.access_specifier || null
            }
            selectedNote={selectedTimeEntry?.notes || null}
            selectedAttachment={selectedTimeEntry?.attachments || []}
            selectedSendAsEmail={selectedTimeEntry?.send_email}
            selectedWorkRole={selectedTimeEntry?.work_role || null}
            selectedWorkType={
              selectedTimeEntry?.work_type || metadata?.work_type || null
            }
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export const MarkAsResolutionIcon = ({
  onClick,
  markedAsResolved,
  isHovered = false,
}: {
  onClick: () => void;
  markedAsResolved: boolean;
  isHovered: boolean;
}) => {
  const theme = useTheme();
  if (!markedAsResolved) {
    return (
      <IconButton
        onClick={onClick}
        sx={{
          display: isHovered ? "flex" : "none",
          ":hover": { backgroundColor: "#FFF" },
          position: "absolute",
          top: "-40px",
          padding: "4px 8px",
          borderRadius: "20px",
          alignSelf: "flex-start",
          left: 0,
          bgcolor: "#fff",
          boxShadow:
            "0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
          width: "40px",
          height: "32px",
          // mb: "4px",
        }}
      >
        <BulbIcon htmlColor={theme.palette.purple.main} />
      </IconButton>
    );
  } else {
    return (
      <IconButton
        onClick={onClick}
        sx={{
          ":hover": { backgroundColor: "#FFF" },
          position: "absolute",
          bottom: "-40px",
          padding: "4px 8px",
          borderRadius: "20px",
          alignSelf: "flex-start",
          left: 0,
          bgcolor: "#fff",
          boxShadow:
            "0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
          mb: "-4px",
        }}
      >
        <BulbYellowIcon sx={{ mr: "4px" }} />
        {isHovered && (
          <Typography variant="body-medium"> Marked as resolution</Typography>
        )}
      </IconButton>
    );
  }
};

export const MessageFooter = ({
  senderType,
  accessSpecifier,
  isEdited = false,
  messagePlatform,
  sentAsEmail,
  type,
  date,
}: {
  senderType: "resource" | "customer";
  accessSpecifier: string;
  isEdited?: boolean;
  messagePlatform?: string | null;
  sentAsEmail?: boolean | null;
  type?: "canned_notes" | "email" | "time_entry_notes";
  date: any;
}) => {
  const theme = useTheme();
  type = messagePlatform === "email" || sentAsEmail ? "email" : type;

  const getMessage = (
    type: "caption" | "icon",
    source: "canned_notes" | "email" | "time_entry_notes"
  ) => {
    switch (source) {
      case "email":
        return type === "caption"
          ? senderType === "resource"
            ? "Sent to Email"
            : "From email"
          : EmailIcon;

      case "time_entry_notes":
        return type === "caption" ? "Time Entry Note" : NotesIcon;

      case "canned_notes":
        return type === "caption" ? "Canned Note" : NotesIcon;
      default:
        return type === "caption"
          ? senderType === "resource"
            ? "Sent to Email"
            : "From email"
          : EmailIcon;
    }
  };

  const getIcon = useCallback(
    (accessSpecifier: "private" | "self" | "public") => {
      switch (accessSpecifier) {
        case "private":
          return (
            <LockIcon
              sx={{ width: "16px", height: "16px" }}
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          );
        case "public":
          return (
            <GlobeCheckmarkIcon
              sx={{ width: "16px", height: "16px" }}
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          );
        case "self":
          return (
            <UserIcon
              sx={{ width: "16px", height: "16px" }}
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          );

        default:
          return (
            <LockIcon
              sx={{ width: "16px", height: "16px" }}
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          );
      }
    },
    []
  );

  const isImageShown = useCallback(
    (type: "email" | "time_entry_notes" | "canned_notes", source: string) => {
      if (type === "email") {
        return false;
      }

      return true;
    },
    [type, accessSpecifier]
  );
  return (
    <Stack
      mt={"8px"}
      direction="row"
      alignItems="center"
      justifyContent={"space-between"}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap="12px"
        justifyContent={"flex-start"}
      >
        <PlatformIcon
          platform={
            type === "email" && senderType === "resource"
              ? type
              : messagePlatform
          }
          senderType={senderType}
        />
        {type && isImageShown(type, accessSpecifier) && (
          <Stack direction="row" alignItems="center" gap="12px">
            <Tooltip
              title={getMessage("caption", type)}
              placement="top"
              followCursor={true}
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: theme.palette.neutral["B-015"],
                    color: theme.palette.lightBg.high,

                    ...theme.typography["caption"],
                    textTransform: "capitalize",
                  },
                },
              }}
            >
              {/* if email is sent as email
              and is public
               */}

              <img
                alt=""
                src={getMessage("icon", type)}
                width={"20px"}
                height={"20px"}
              />
            </Tooltip>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" gap="12px">
          {senderType === "resource" && (
            <Tooltip
              title={
                accessSpecifier.charAt(0).toUpperCase() +
                accessSpecifier.slice(1)
              }
              placement="top"
              followCursor={true}
              PopperProps={{
                sx: {
                  textTransform: "capitalize",
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: theme.palette.neutral["B-015"],
                    color: theme.palette.lightBg.high,
                    textTransform: "capitalize",
                    ...theme.typography["caption"],
                  },
                },
              }}
            >
              <Stack sx={{ width: "16px", height: "16px" }}>
                {getIcon(accessSpecifier as "private" | "public" | "self")}
              </Stack>
            </Tooltip>
          )}

          {isEdited && (
            <>
              <svg
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2" r="2" fill="#767B85" />
              </svg>{" "}
              <Typography
                variant="body-small"
                sx={{ textTransform: "capitalize" }}
                color={theme.palette.lightBg.low}
              >
                Edited
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Typography
        textAlign="right"
        variant="body-small"
        color={theme.palette.lightBg.low}
      >
        {dayjs(date).format("hh:mm A")}
      </Typography>
    </Stack>
  );
};

const PlatformIcon = ({
  platform,
  senderType,
}: {
  platform?: string | null;
  senderType?: string;
}) => {
  const theme = useTheme();

  switch (platform) {
    case "agent_app":
      return (
        <Tooltip title="From Desktop">
          <Box sx={{ height: "20px" }}>
            <ComputerIcon
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          </Box>
        </Tooltip>
      );
    case "mobile_app":
      return (
        <Tooltip title="From Mobile">
          <Box sx={{ height: "20px" }}>
            <MobileDeviceIcon
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          </Box>
        </Tooltip>
      );
    case "teams_app":
      return (
        <Tooltip title="From Teams">
          <Box sx={{ height: "20px" }}>
            <TeamsAppIcon
              sx={{ width: "16px", height: "16px" }}
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          </Box>
        </Tooltip>
      );
    case "email":
      return (
        <Tooltip
          title={senderType === "resource" ? "Sent to Email" : "From Email"}
        >
          <Box sx={{ height: "20px" }}>
            <EmailTickIcon
              fontSize="small"
              htmlColor={theme.palette.lightBg.low}
            />
          </Box>
        </Tooltip>
      );

    default:
      return null;
  }
};
