import {
  CreateBoardInput,
  DeleteBoardInput,
  GetBoardByIdInput,
  GetBoardInput,
  GetBoardsInput,
  UpdateBoardInput,
} from "shared";
import { axiosClient, fetchCall } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const getAllBoards = async ({
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
  serviceType = "ticket_board",
}: GetBoardsInput) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: `/configuration/fetch/${serviceType}/msp/${getMspId()}`,
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }

  // console.log(response.data);
};
export const getAllBoard = async ({
  boardType,
  perPageCount,
  pageNumber,
  sortColumn,
  sortDirection = "asc",
  filter,
  searchQuery,
  mspId,
}: GetBoardInput) => {
  try {
    const data = await getQueryDataObject({
      perPageCount,
      pageNumber,
      sortColumn,
      sortDirection,
      filter,
      searchQuery,
    });

    const url = await generateUrlString({
      baseUrl: `/configuration/fetch/${boardType}_board/msp/${mspId}`,
      data,
    });

    const response = await axiosClient.get(`${url}`);
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const getBoardById = async ({
  boardId,
  boardType,
}: GetBoardByIdInput) => {
  try {
    const url = `/configuration/fetch/${boardType}_board/${boardId}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const createBoard = async ({
  data = {},
  mspId,
  boardType,
}: CreateBoardInput) => {
  try {
    const response = await axiosClient.post(
      `/configuration/create/${boardType}_board/${mspId}`,
      data
    );
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const updateBoard = async ({
  data,
  boardId,
  boardType,
}: UpdateBoardInput) => {
  try {
    const response = await axiosClient.patch(
      `/configuration/update/${boardType}_board/${boardId}`,
      data
    );
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData?.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};

export const deleteBoards = async ({
  boardIds,
  boardType,
}: DeleteBoardInput) => {
  try {
    const url = `/v2/configuration/${boardType}-boards`;
    const response = await axiosClient.delete(`${url}`, {
      data: { [`${boardType}Boards`]: boardIds },
    });
    const resData = response?.data;
    if (resData?.success && resData?.statusCode === 200) {
      return resData;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
