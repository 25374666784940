import { GetGeneralAttributesInputProps } from "shared";
import { axiosClient, fetchCall } from "utilz";
import {
  apiErrorHandler,
  generateUrlString,
  getMspId,
  getQueryDataObject,
} from "utilz/helpers";

export const getGeneralAttributes = async ({
  attributeName,
}: GetGeneralAttributesInputProps) => {
  try {
    const url = `/configuration/general/attributes/${attributeName}`;
    const response = await axiosClient.get(`${url}`);
    const resData = response.data;
    if (resData.success && resData.statusCode === 200) {
      return resData.data;
    }
    throw response;
  } catch (error: any) {
    apiErrorHandler(error);
  }
};
