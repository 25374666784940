import * as yup from "yup";

export interface TicketTemplateResult {
  mapping_id: string;
  template_name: string;
  template_description: string;
  template_summary: string;
  board: string;
  priority: string;
  msp_id: number;
  is_billable: boolean;
  urgency: string;
  work_type: string;
  ticket_type: string;
  source: string;
  impact: string;
  location: string;
  budgeted_hours: {
    seconds: number;
  };
  sla: string;
  show_user: boolean;
}

const TicketTemplateResultSchema = yup.object().shape({
  mapping_id: yup.string().required(),
  board: yup.string().nullable(),
  budgeted_hours: yup.object().nullable(),
  impact: yup.string().nullable(),
  is_billable: yup.boolean().required(),
  location: yup.string().nullable(),
  priority: yup.string().required(),
  show_user: yup.boolean().nullable(),
  sla: yup.string().nullable(),
  source: yup.string().nullable(),
  template_attachments: yup.array().nullable(),
  template_description: yup.string().required(),
  template_name: yup.string().required(),
  template_summary: yup.string().required(),
  ticket_sub_type: yup.string().nullable(),
  ticket_type: yup.string().nullable(),
  urgency: yup.string().nullable(),
  work_type: yup.string().nullable(),
});

export const GetAllTicketsTemplateResponseSchema = yup.object().shape({
  totalCount: yup.string().required(),
  totalPages: yup.number().nullable(),
  result: yup.array(TicketTemplateResultSchema),
});

export type TicketTemplateResultType = yup.InferType<
  typeof TicketTemplateResultSchema
>;
